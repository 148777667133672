import React, { useEffect, useState } from 'react'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import VWButton from '../../../../components/vwButton/VWButton'
import { connect } from 'react-redux';
import { DeleteDocument } from '../../../../store/Modules/crm/Opportunity/actions';
import { Button, FormGroup } from 'reactstrap';

const OppDeleteDocuments = (props) => {

    const [oppDocumentDeleteAction, setOppDocumentDelete] = useState([]);
    const { oppDocsId, detailId } = props;

    console.log("oppDocsId:", oppDocsId);
    console.log("detailId:", detailId);
    useEffect(() => {  
        setOppDocumentDelete([oppDocsId && oppDocsId.id])
    }, [])

    const handleDeleteActionTimeSheet = () => {
        props.deleteOppDocument(oppDocumentDeleteAction, detailId);
    };
    console.log("oppDocumentDeleteAction:", oppDocumentDeleteAction);

  return (
      <React.Fragment>
          {props.errorMessage && (
              <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
          )}
          <h5>Are you sure you want to delete ?</h5>
          <br></br>
          {/* <VWButton
              messageKey="deleteOppDocumentActionButton"
              defaultMessage="  Delete  "
              buttonType="success"
              baseClassName="btn"
              className="me-2"
              onClick={handleDeleteActionTimeSheet}
          // isLoading={props.isPending}
          // isDisabled={props.isPending}
          ></VWButton>
          <VWButton
              messageKey="cancelOppdeleteButton"
              defaultMessage="Cancel"
              buttonType="danger"
              baseClassName="btn"
              onClick={props.handleClose}
          // isDisabled={props.isPending}
          ></VWButton> */}
          <div className="form-group">
              <FormGroup>
                  <Button
                      className="me-2"
                      color="success"
                      onClick={handleDeleteActionTimeSheet}
                      disabled={props.isPending}
                  >
                      {props.isPending ? <>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                          &nbsp; Submitting...
                      </>
                          : <>Submit</>}
                  </Button>
                  <Button
                      color="danger"
                      onClick={props.handleClose}
                  >
                      Cancel
                  </Button>
              </FormGroup>
          </div>
      </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
    errorMessage: state.crmOpportunity.errorMessage,
    isPending: state.crmOpportunity.isPending,

});
const mapDispatchToProps = (dispatch) => ({
    deleteOppDocument: (oppDeleteAction, detailId) => dispatch(DeleteDocument(oppDeleteAction, detailId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OppDeleteDocuments);