import { getToken, modifyCrmUserList } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    OPPORTUNITY_CURRENT_PHASE_CREATE_CLOSE,
    OPPORTUNITY_CURRENT_PHASE_CREATE_ERROR,
    OPPORTUNITY_CURRENT_PHASE_CREATE_ERROR_CLEAR,
    OPPORTUNITY_CURRENT_PHASE_CREATE_OPEN,
    OPPORTUNITY_CURRENT_PHASE_CREATE_PENDING,
    OPPORTUNITY_CURRENT_PHASE_CREATE_SUCCESS,
    OPPORTUNITY_CURRENT_PHASE_LIST_ERROR,
    OPPORTUNITY_CURRENT_PHASE_LIST_ERROR_CLEAR,
    OPPORTUNITY_CURRENT_PHASE_LIST_PENDING,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_CLOSE,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_ERROR,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_OPEN,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_PENDING,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_SUCCESS,
    SET_OPPORTUNITY_CURRENT_PHASE_DETAILS,
    SET_OPPORTUNITY_CURRENT_PHASE_LIST,
} from "./constants";

//--------------------Opportunity-CurrentPhase-CREATE-----------------------------------
export const openAddOpportunityCurrentPhaseModal = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_CREATE_OPEN
})
export const closeAddOpportunityCurrentPhaseModal = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_CREATE_CLOSE
})
export const createOpportunityCurrentPhasePending = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_CREATE_PENDING
})
export const createOpportunityCurrentPhaseError = (errorMessage) => ({
    type: OPPORTUNITY_CURRENT_PHASE_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateOpportunityCurrentPhaseError = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_CREATE_ERROR_CLEAR,
})
export const createOpportunityCurrentPhaseSuccess = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_CREATE_SUCCESS,
})

export const createNewOpportunityCurrentPhase = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createOpportunityCurrentPhaseError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createOpportunityCurrentPhasePending());
    dispatch(clearCreateOpportunityCurrentPhaseError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createOpportunityCurrentPhase(accessToken, dataToSend)
            .then(() => {
                dispatch(getOpportunityCurrentPhaseList());
                dispatch(createOpportunityCurrentPhaseSuccess());
                dispatch(closeAddOpportunityCurrentPhaseModal());
                dispatch(showSnackbarStatus('Opportunity current phase created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createOpportunityCurrentPhaseError(err.response && err.response.text));
                    return;
                }
                console.log('Create OpportunityCurrentPhase server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Opportunity-CurrentPhase-UPDATE-----------------------------------
export const openEditOpportunityCurrentPhaseModal = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_UPDATE_OPEN
})
export const closeEditOpportunityCurrentPhaseModal = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_UPDATE_CLOSE
})
export const updateOpportunityCurrentPhasePending = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_UPDATE_PENDING
})
export const updateOpportunityCurrentPhaseError = (errorMessage) => ({
    type: OPPORTUNITY_CURRENT_PHASE_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateOpportunityCurrentPhaseError = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_UPDATE_ERROR_CLEAR,
})
export const updateOpportunityCurrentPhaseSuccess = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_UPDATE_SUCCESS,
})
export const setOpportunityCurrentPhaseDetails = (opportunityCurrentPhaseDetails) => ({
    type: SET_OPPORTUNITY_CURRENT_PHASE_DETAILS,
    payload: { opportunityCurrentPhaseDetails }
})

export const updateOpportunityCurrentPhase = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateOpportunityCurrentPhaseError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateOpportunityCurrentPhasePending());
    dispatch(clearUpdateOpportunityCurrentPhaseError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateOpportunityCurrentPhase(accessToken, dataToSend)
            .then(() => {
                dispatch(getOpportunityCurrentPhaseList());
                dispatch(updateOpportunityCurrentPhaseSuccess());
                dispatch(closeEditOpportunityCurrentPhaseModal());
                dispatch(showSnackbarStatus('Opportunity current phase updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateOpportunityCurrentPhaseError(err.response && err.response.text));
                    return;
                }
                console.log('Create OpportunityCurrentPhase server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Opportunity-CurrentPhase-LIST-----------------------------------
export const opportunityCurrentPhaseListPending = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_LIST_PENDING
})
export const opportunityCurrentPhaseListError = (errorMessage) => ({
    type: OPPORTUNITY_CURRENT_PHASE_LIST_ERROR,
    payload: { errorMessage }
})
export const clearOpportunityCurrentPhaseListError = () => ({
    type: OPPORTUNITY_CURRENT_PHASE_LIST_ERROR_CLEAR,
})
export const setOpportunityCurrentPhaseList = (opportunityCurrentPhaseList) => ({
    type: SET_OPPORTUNITY_CURRENT_PHASE_LIST,
    payload: { opportunityCurrentPhaseList }
})

export const getOpportunityCurrentPhaseList = (page, PageSize) => (dispatch) => {
    dispatch(opportunityCurrentPhaseListPending());
    dispatch(clearOpportunityCurrentPhaseListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getOpportunityCurrentPhaseList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setOpportunityCurrentPhaseList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                } 
                    dispatch(opportunityCurrentPhaseListError(error));
                console.log('Create OpportunityCurrentPhase server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

