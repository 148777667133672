import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import UpdateTask from './UpdateTask'
import { clearUpdateTaskStatusError, closeCloneTaskModal, CloseEditTaskModalAction, closeEditTaskStatus, getTaskDetails, getTaskStatusAsyncMilestoneSelectAction, getTaskStatusAsyncSelectAction, getTaskStatusAsyncUserSelectAction, isStatusTaskEditOpen, isTaskStatusLoadingOpen, openCloneTaskModal, openEditTaskModalAction, openEditTaskStatus, updateRating, updateRatingErrorClear, UpdateTasks, UpdateTaskStatus } from '../../../../store/Modules/ProjectPortal/Task/actions';
import { Badge, Button, Card, CardBody, CardTitle, Col, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import VWModel from '../../../../components/Modal/VWModal';
import moment from 'moment-timezone';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import CloneTask from './CloneTask';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Async from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HOURS, MINUTES, STATUS_OPTION_USER_EDIT } from '../../../../store/Modules/ProjectPortal/Task/constants';
import Select from 'react-select';
import { ElementToolTip } from '../vwToolTip';

// Rating Plugin
import Rating from "react-rating";
// import RatingTooltip from "react-rating-tooltip";

const customStylesTime = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
    container: (provided) => ({
        ...provided,
        minWidth: '50px',
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: '0px 5px 5px 0px',
    }),
};

const TaskDetails = (props) => {
    const [spentHours, setSpentHours] = useState(null);
    const [spentMinutes, setSpentMinutes] = useState(null);
    const [formFields, setFormFields] = useState({});
    const [statusType, setStatusType] = useState([]);
    const [editSpentHours, setEditSpentHours] = useState(false);
    const [isRatingEditale, setIsRatingEditale] = useState(false);
    const [taskRating, setTaskRating] = useState();
    const { isGlobalReadOnlyUser, userAppDetails } = props.currentProfile || {};

    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })
    const params = useParams();
    const { id } = params;

    useEffect(() => {
        props.isStatusTaskEditOpen(null);
        props.clearUpdateTaskError();
        props.getTaskDetails(id);
        props.ClearPath();
        props.clearSetPath();
        props.updateRatingErrorClear();
    }, [id]);
    const { TaskDetails } = props;

    useEffect(() => {
        if (TaskDetails) {
            setStatusType({ label: `${TaskDetails && TaskDetails.taskStatus} - ${TaskDetails && TaskDetails.currentStatusPercentage || 0}%`, value: TaskDetails && TaskDetails.taskStatus });
            setFormFields({ ...formFields, taskId: id, hours: 0, mint: 0, taskStatus: TaskDetails && TaskDetails.taskStatus });
            setTaskRating({
                rating: props.TaskDetails && props.TaskDetails.rating,
                ratingFeedback: props.TaskDetails && props.TaskDetails.ratingFeedback,
            });
        }
    }, [TaskDetails])

    useEffect(() => {
        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();
            props.closeEditTaskStatus();
        }
    }, []);
    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleOpenEditTaskModel = () => {
        props.openEditTaskModal();
    }
    const handleOpenCloneTaskModel = () => {
        props.openCloneTaskModal();
    }
    const handleCloseEditTaskModal = () => {
        props.closeEditTaskModal();
    };
    const handleCloseCloneTaskModal = () => {
        props.closeCloneTaskModal();
    };

    const { userId } = props.currentProfile;
    const isStatusUpdate = props.TaskDetails && props.TaskDetails.assignedUserId === userId;
    console.log("isStatusUpdate:", isStatusUpdate);

    const isUserUpdateStatus = () => {
        setSpentHours(null);
        setSpentMinutes(null);
        props.openEditTaskStatus();
    }
    const handleCloseStatus = () => {
        setFormFields({ ...formFields, hours: 0, mint: 0, taskStatus: TaskDetails && TaskDetails.taskStatus, currentStatusPercentage: TaskDetails && TaskDetails.currentStatusPercentage });
        setSpentHours(null);
        setSpentMinutes(null);
        setStatusType({ label: `${TaskDetails && TaskDetails.taskStatus} - ${TaskDetails && TaskDetails.currentStatusPercentage || 0}%`, value: TaskDetails && TaskDetails.taskStatus });
        props.closeEditTaskStatus();
        props.clearUpdateTaskError();
        setEditSpentHours(false);
    }

    const handleHours = (value) => {
        setFormFields({ ...formFields, hours: value.value });
        setSpentHours(value);
    }
    const handleMinutes = (value) => {
        setFormFields({ ...formFields, mint: value.value });
        setSpentMinutes(value);
    }
    const handleTaskStatus = (value) => {
        setFormFields({ ...formFields, taskStatus: value.value, currentStatusPercentage: value.Percentage });
        setStatusType(value);
        setEditSpentHours(true);
    }

    const handleTaskStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncSelectAction } = props;
        return getTaskStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleTaskUserStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncUserSelectAction } = props;
        return getTaskStatusAsyncUserSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    //-----------------------------------------------------------update status-----------------------------------------------
    const handleUpdateTask = () => {
        // props.UpdateTaskAction(formFields, props.PROJECTID);
        props.UpdateTaskAction(formFields, null, null, App[0] && App[0].appId);
    }

    const isTimesheetApp = userAppDetails && userAppDetails.find((app) => (app.appName && app.appName.toLowerCase()) === "timesheets") || {};
    const IsUserTimeSheetAccess = (isTimesheetApp && isTimesheetApp.appName && isTimesheetApp.appName.toLowerCase()) === "timesheets";

    console.log("isTimesheetApp:", isTimesheetApp);
    console.log("IsUserTimeSheetAccess:", IsUserTimeSheetAccess);

    //---------------------------------------------------------Update Rating-----------------------------------------------------------
    const openUpdateRating = (e) => {
        e.preventDefault();
        setIsRatingEditale(true);
    }
    const handleCloseRating = (e) => {
        e.preventDefault();
        setIsRatingEditale(false);
    }
    const handleRating = (rating) => {
        const { id } = TaskDetails || {};
        console.log(rating);
        setTaskRating({
            ...taskRating,
            rating: rating
        });
        //props.updateRating(id, rating, taskRating);
    }
    const handleUpdateRating = (e) => {
        const { id } = TaskDetails || {};
        e.preventDefault();
        props.updateRating(id, taskRating);
        setIsRatingEditale(false);
    }
    const handleFeedBack = (e) => {
        const { name, value } = e.target || {};
        setTaskRating({
            ...taskRating,
            [name]: value,
        })
    }

    const customStyles = {
        container: (provided) => ({
            ...provided,
            fontWeight: 'normal'
        }),
        control: (provided) => ({
            ...provided,
            fontWeight: 'normal',
            minWidth: '200px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontWeight: 'normal'
        }),
    };
    //---------------------------------dropdown close action for outside click------------------------------------------
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                props.isStatusTaskEditOpen(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    //----------------------------------------------------------------------------------------------------------------------------
    const handleTaskMilestoneStatusPercentage = (inputValue, callback) => {
        const { getTaskStatusAsyncMilestoneSelect } = props;
        return getTaskStatusAsyncMilestoneSelect(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const [taskStatusValues, setTaskStatusValues] = useState({});
    // const handleTaskSelectChange = (selectedOption, Id, milestoneId) => {
    //     setTaskStatusValues(selectedOption);
    //     const updatedStatus = selectedOption ? selectedOption.value : '';
    //     props.isTaskStatusLoadingOpen();
    //     props.UpdateTaskAction({ TaskStatus: updatedStatus, taskId: Id, MileStoneId: milestoneId, CurrentStatusPercentage: selectedOption.Percentage });
    // };

    const handleTaskEditClick = (itemId, TaskStatus, CurrentStatusPercentage) => {
        props.isStatusTaskEditOpen(itemId);
        setTaskStatusValues({ label: `${TaskStatus} - ${CurrentStatusPercentage || 0}%`, value: TaskStatus });
    };

    console.log("isStatusTaskEditID", props.isStatusTaskEditID);
    console.log("Details:", props.TaskDetails);

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <div style={{ height: "85vh", overflowX: "auto" }}>
                        <CardTitle
                            tag="h4"
                            // className="border-bottom px-4 py-3 mb-0 d-flex  align-items-center text-capitalize"
                            className="border-bottom px-4 py-3 mb-0 d-flex align-items-center text-capitalize overflow-hidden sticky-top"
                            style={{ backgroundColor: "white", overflow: "hidden", zIndex: "30" }}
                        >
                            <strong>
                                TASK DETAILS&nbsp;&gt;&nbsp;<Link id={'ProjectsName'}
                                    to={`/projectportal/projects/details/${props.TaskDetails && props.TaskDetails.projectId}`}
                                >{props.isTaskDetailsPending ? <>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                                    &nbsp; loading...
                                </>
                                    :
                                    <span>{props.TaskDetails && props.TaskDetails.projectName || "N/A"}</span>
                                    }
                                </Link>&nbsp;&gt;&nbsp;<Link id={'MilestonesName'}
                                    to={`/projectportal/milestones/details/${props.TaskDetails && props.TaskDetails.mileStoneId}`}
                                >{props.isTaskDetailsPending ? <>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                                    &nbsp; loading...
                                </>
                                    :
                                    <span>{props.TaskDetails && props.TaskDetails.milestoneName || "N/A"}</span>
                                    }
                                </Link>&nbsp;&gt;&nbsp;
                                {props.isTaskDetailsPending ? <>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                                    &nbsp; loading...
                                </>
                                    : <span id={'TasksName'}>{props.TaskDetails && props.TaskDetails.taskName && props.TaskDetails.taskName.trim() || "N/A"}</span>}
                                <ElementToolTip id={'ProjectsName'} name={'Project Name'} />
                                <ElementToolTip id={'MilestonesName'} name={'Milestone Name'} />
                                <ElementToolTip id={'TasksName'} name={'Task Name'} />
                            </strong>
                            <div className="ms-auto">
                                {(!props.isTaskDetailsPending) && (props.TaskDetails && props.TaskDetails.isEditable) && (!isGlobalReadOnlyUser) ?
                                    <Link type="button"
                                        className="btn btn-info mt-2"
                                        onClick={handleOpenCloneTaskModel}
                                    >
                                        CLONE TASK
                                    </Link>
                                    : ""}&nbsp;
                                {(props.TaskDetails && props.TaskDetails.isEditable) && (!props.errorMessage) && (!props.isTaskDetailsPending) && (!isGlobalReadOnlyUser) ?
                                    <Link type="button"
                                        className="btn btn-info mt-2"
                                        onClick={handleOpenEditTaskModel}
                                    >
                                        EDIT
                                    </Link>
                                    : ""}
                            </div>
                        </CardTitle>
                        <CardBody className="p-4 sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                            {props.errorMessage || props.updateTaskErrorMessage ? <div className='w-100'>
                                {(props.errorMessage || props.updateTaskErrorMessage) && (
                                    <VWMessage type="danger" defaultMessage={props.errorMessage || props.updateTaskErrorMessage} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            {props.updateRatingErrorMessage &&
                                <VWMessage type="danger" defaultMessage={props.updateRatingErrorMessage} baseClassName="alert" />
                            }
                            {props.isTaskDetailsPending ?
                                <div className="d-flex justify-content-center mt-2">
                                    <VWSpinner />
                                </div>
                                :
                                <Row>
                                    <div>
                                        <div className="p-3 bg-light fw-bold fs-4">
                                            <span className='text-capitalize'>{props.TaskDetails && props.TaskDetails.taskName || "N/A"}</span>
                                        </div>
                                    </div>
                                    <Col xl="6">
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td >
                                                                    <strong>Task Name</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.taskName || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Milestone Name</strong>
                                                                </td>
                                                                <td><Link
                                                                    to={`/projectportal/milestones/details/${props.TaskDetails && props.TaskDetails.mileStoneId}`}
                                                                >{props.TaskDetails && props.TaskDetails.milestoneName || "N/A"}
                                                                </Link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Type</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.taskTypeName || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Scope</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.taskScopeName || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Priority</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.priority || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Effort</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.effort || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Planned Hours</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.plannedHours || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Status</strong>
                                                                </td>
                                                                <td>{props.isTaskStatusOpen ? '' : <>{`${props.TaskDetails && props.TaskDetails.taskStatus || "N/A"} - ${props.TaskDetails && props.TaskDetails.currentStatusPercentage || 0}%`}&nbsp;&nbsp;&nbsp;
                                                                    {(props.TaskDetails && props.TaskDetails.isUserEdit) ?
                                                                        <FontAwesomeIcon icon={faEdit} style={{ color: "black", }} onClick={isUserUpdateStatus} />
                                                                        : ''}</>}
                                                                    {props.isTaskStatusOpen ?
                                                                        <>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Task Status
                                                                                </Label>
                                                                                {/* {(props.TaskDetails && props.TaskDetails.isEditable) ?
                                                                                <Async
                                                                                    name="taskStatus"
                                                                                    placeholder="Select task status"
                                                                                    isSearchable
                                                                                    defaultOptions
                                                                                    loadOptions={handleTaskStatusPercentage}
                                                                                    onChange={handleTaskStatus}
                                                                                    value={statusType}
                                                                                />
                                                                                :
                                                                                <Async
                                                                                    name="taskStatus"
                                                                                    placeholder="Select task status"
                                                                                    isSearchable
                                                                                    defaultOptions
                                                                                    loadOptions={handleTaskUserStatusPercentage}
                                                                                    onChange={handleTaskStatus}
                                                                                    value={statusType}
                                                                                />} */}
                                                                                {props.TaskDetails && props.TaskDetails.isEditable ? (
                                                                                    <>
                                                                                        {(props.TaskDetails && props.TaskDetails.isMilestoneOwnerUpdateStatus) ? (
                                                                                            <Async
                                                                                                name="TaskStatus"
                                                                                                placeholder="Select status"
                                                                                                isSearchable
                                                                                                defaultOptions
                                                                                                loadOptions={handleTaskMilestoneStatusPercentage}
                                                                                                onChange={handleTaskStatus}
                                                                                                value={statusType}
                                                                                            />
                                                                                        ) : (

                                                                                            (props.TaskDetails && props.TaskDetails.isUserUpdateStatus) && (userId === (props.TaskDetails && props.TaskDetails.assignedUserId)) ? (
                                                                                                <Async
                                                                                                    name="taskStatus"
                                                                                                    placeholder="Select status"
                                                                                                    isSearchable
                                                                                                    defaultOptions
                                                                                                    loadOptions={handleTaskUserStatusPercentage}
                                                                                                    onChange={handleTaskStatus}
                                                                                                    value={statusType}
                                                                                                />
                                                                                            ) : (
                                                                                                <Async
                                                                                                    name="taskStatus"
                                                                                                    placeholder="Select status"
                                                                                                    isSearchable
                                                                                                    defaultOptions
                                                                                                    loadOptions={handleTaskStatusPercentage}
                                                                                                    onChange={handleTaskStatus}
                                                                                                    value={statusType}
                                                                                                />
                                                                                            )
                                                                                        )}
                                                                                    </>
                                                                                ) :
                                                                                    props.TaskDetails && props.TaskDetails.isUserEdit ? (
                                                                                        <Async
                                                                                            name="taskStatus"
                                                                                            placeholder="Select status"
                                                                                            isSearchable
                                                                                            defaultOptions
                                                                                            loadOptions={handleTaskUserStatusPercentage}
                                                                                            onChange={handleTaskStatus}
                                                                                            value={statusType}
                                                                                        />
                                                                                    ) : (
                                                                                        <span>{`${props.TaskDetails && props.TaskDetails.taskStatus} - ${props.TaskDetails && props.TaskDetails.currentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                    )}
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label>Spent Hours</Label>
                                                                                <div className='d-flex gap-3 w-100'>
                                                                                    <div className='w-50'>
                                                                                        <InputGroup className='w-100'>
                                                                                            <InputGroupText className='w-25'>
                                                                                                HH
                                                                                            </InputGroupText>
                                                                                            <Select
                                                                                                className='w-75'
                                                                                                styles={customStylesTime}
                                                                                                name="hour"
                                                                                                options={HOURS}
                                                                                                placeholder="00"
                                                                                                menuPlacement='top'
                                                                                                onChange={handleHours}
                                                                                                value={spentHours}
                                                                                                isDisabled={((props.TaskDetails) && !(props.TaskDetails.allowTimesheetCreate)) || !editSpentHours || (!IsUserTimeSheetAccess) || (!isStatusUpdate)}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </div>
                                                                                    <div className='w-50'>
                                                                                        <InputGroup className='w-100'>
                                                                                            <InputGroupText className='w-25'>
                                                                                                MM
                                                                                            </InputGroupText>
                                                                                            <Select
                                                                                                className='w-75'
                                                                                                styles={customStylesTime}
                                                                                                name="minute"
                                                                                                placeholder="00"
                                                                                                options={MINUTES}
                                                                                                menuPlacement='top'
                                                                                                onChange={handleMinutes}
                                                                                                value={spentMinutes}
                                                                                                isDisabled={((props.TaskDetails) && !(props.TaskDetails.allowTimesheetCreate)) || !editSpentHours || (!IsUserTimeSheetAccess) || (!isStatusUpdate)}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </div>
                                                                                </div>
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Button
                                                                                    className="me-2"
                                                                                    color="success"
                                                                                    onClick={handleUpdateTask}
                                                                                    disabled={props.isPending}
                                                                                >
                                                                                    {(props.isPending) ? <>
                                                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                                                        &nbsp; Submitting...
                                                                                    </>
                                                                                        : <>Submit</>}
                                                                                </Button>
                                                                                <Button
                                                                                    color="danger"
                                                                                    onClick={handleCloseStatus}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                            </FormGroup>
                                                                        </>
                                                                        : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Dependencies Name</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.dependenciesName || "N/A"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="6">
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <strong>Assigned Username</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.assignedUserName || 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Assigned User Email</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.assignedUserEmail || 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Reviewer</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.reviewerUserName || 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Reviewer Email</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.reviewerUserEmail || 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Start Date</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.startDate ? moment(props.TaskDetails && props.TaskDetails.startDate).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>End Date</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.endDate ? moment(props.TaskDetails && props.TaskDetails.endDate).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Completed Date</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.completedDate ? moment(props.TaskDetails && props.TaskDetails.completedDate).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Created By</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.createdBy || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Created Date</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.createdTimeStamp ? moment(props.TaskDetails && props.TaskDetails.createdTimeStamp).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div className='fs-4 fw-bold mb-3'>
                                                    Task Description
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <tbody>

                                                            <tr>
                                                                <td style={{ width: "150px" }}>
                                                                    <strong>Description</strong>
                                                                </td>
                                                                <td>{props.TaskDetails && props.TaskDetails.description || "N/A"}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/* </div>
                                    <div className="row pl-4"> */}
                                            {/*---------------Update Rating--------------------------------*/}
                                            <div className="col-lg-12">
                                                <div className='fs-4 fw-bold mb-3 d-flex align-items-center gap-2'>
                                                    Task Ratings  {(!isRatingEditale && (TaskDetails && TaskDetails.userCanUpdateRating)) && <FontAwesomeIcon icon={faEdit} style={{ color: "black", cursor: "pointer" }} onClick={openUpdateRating} />}
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-hover">
                                                        <tbody>
                                                            <tr className='align-middle'>
                                                                <td>
                                                                    <strong>Rating</strong>
                                                                </td>
                                                                <td>
                                                                    <div className="rating-star">
                                                                        <Rating
                                                                            className="rating"
                                                                            emptySymbol="mdi mdi-star-outline text-primary fa-2x"
                                                                            fullSymbol="mdi mdi-star text-primary fa-2x"
                                                                            onChange={handleRating}
                                                                            initialRating={(taskRating && taskRating.rating) || 0}
                                                                            // readonly={!(TaskDetails && TaskDetails.userCanUpdateRating)}
                                                                            readonly={!isRatingEditale}
                                                                        />
                                                                        {/* {isRatingEditale &&
                                                                        <FormGroup>
                                                                            <Button
                                                                                className="me-2"
                                                                                color="success"
                                                                                onClick={handleUpdateRating}
                                                                                disabled={props.isUpdateRatingPending}
                                                                            >
                                                                                {(props.isUpdateRatingPending) ? <>
                                                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                                                    &nbsp; Submitting...
                                                                                </>
                                                                                    : <>Submit</>}
                                                                            </Button>
                                                                            <Button
                                                                                color="danger"
                                                                                onClick={handleCloseRating}
                                                                            >
                                                                                Cancel
                                                                            </Button>
                                                                        </FormGroup>} */}
                                                                    </div>
                                                                    {/* {(!isRatingEditale && (TaskDetails && TaskDetails.userCanUpdateRating)) &&
                                                                    <FontAwesomeIcon icon={faEdit} style={{ color: "black", cursor: "pointer" }} onClick={openUpdateRating} />
                                                                } */}
                                                                </td>
                                                            </tr>
                                                            <tr className='align-middle'>
                                                                <td>
                                                                    <strong>Feedback</strong>
                                                                </td>
                                                                {console.log("isRatingEditale: ", isRatingEditale)}
                                                                <td>
                                                                    {isRatingEditale ?
                                                                        <>
                                                                            <FormGroup>
                                                                                <Input
                                                                                    type="textarea"
                                                                                    name="ratingFeedback"
                                                                                    onChange={handleFeedBack}
                                                                                    placeholder='Enter your feedback'
                                                                                    value={(taskRating && taskRating.ratingFeedback)}
                                                                                />
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Button
                                                                                    className="me-2"
                                                                                    color="success"
                                                                                    onClick={handleUpdateRating}
                                                                                    disabled={props.isUpdateRatingPending}
                                                                                >
                                                                                    {(props.isUpdateRatingPending) ? <>
                                                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                                                        &nbsp; Submitting...
                                                                                    </>
                                                                                        : <>Submit</>}
                                                                                </Button>
                                                                                <Button
                                                                                    color="danger"
                                                                                    onClick={handleCloseRating}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                            </FormGroup>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {(taskRating && taskRating.ratingFeedback) || "N/A"}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </CardBody>
                    </div>
                    <VWModel
                        header="EDIT TASK"
                        modalSize="large"
                        isOpen={props.isEditTaskModalOpen}
                        handleClose={handleCloseEditTaskModal}
                    >
                        <UpdateTask handleClose={handleCloseEditTaskModal} />
                    </VWModel>
                    <VWModel
                        header="CLONE TASK"
                        modalSize="large"
                        isOpen={props.isCloneTaskModalOpen}
                        handleClose={handleCloseCloneTaskModal}
                    >
                        <CloneTask handleClose={handleCloseCloneTaskModal} />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = (state) => ({
    isStatusTaskEditID: state.task.isStatusTaskEditID,
    isEditTaskModalOpen: state.task.isEditTaskModalOpen,
    TaskDetails: state.task.TaskDetails,
    errorMessage: state.task.taskDetailsError,
    isTaskDetailsPending: state.task.isTaskDetailsPending,
    currentProfile: state.login.currentProfile,
    isCloneTaskModalOpen: state.task.isCloneTaskModalOpen,
    isPending: state.task.isUpdateTaskStatusPending,
    isTaskStatusOpen: state.task.isTaskStatusOpen,
    updateTaskErrorMessage: state.task.updateTaskStatusErrorMessage,
    //---------------Update Rating--------------------------------
    updateRatingErrorMessage: state.task.updateRatingErrorMessage,
    isUpdateRatingPending: state.task.isUpdateRatingPending,
    isTaskEditStatusLoadingOpen: state.task.isTaskEditStatusLoadingOpen,
});

const mapDispatchToProps = (dispatch) => ({
    isStatusTaskEditOpen: (id) => dispatch(isStatusTaskEditOpen(id)),
    isTaskStatusLoadingOpen: () => dispatch(isTaskStatusLoadingOpen()),
    closeEditTaskModal: () => dispatch(CloseEditTaskModalAction()),
    openEditTaskModal: () => dispatch(openEditTaskModalAction()),
    openCloneTaskModal: () => dispatch(openCloneTaskModal()),
    closeCloneTaskModal: () => dispatch(closeCloneTaskModal()),
    getTaskDetails: (Id) => dispatch(getTaskDetails(Id)),
    clearSetPath: () => dispatch(clearSetPath()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
    UpdateTaskAction: (formFields, filter, APIList, AppId) => dispatch(UpdateTaskStatus(formFields, filter, APIList, AppId)),
    openEditTaskStatus: () => dispatch(openEditTaskStatus()),
    closeEditTaskStatus: () => dispatch(closeEditTaskStatus()),
    clearUpdateTaskError: () => dispatch(clearUpdateTaskStatusError()),
    getTaskStatusAsyncUserSelectAction: (AppId, searchValue, callback) => dispatch(getTaskStatusAsyncUserSelectAction(AppId, searchValue, callback)),
    getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectAction(appId, searchValue, callback)),
    //---------------Update Rating--------------------------------
    updateRating: (taskId, rating) => dispatch(updateRating(taskId, rating)),
    updateRatingErrorClear: () => dispatch(updateRatingErrorClear()),
    getTaskStatusAsyncMilestoneSelect: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncMilestoneSelectAction(appId, searchValue, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails)