//--------------------INDUSTRY-CREATE-----------------------------------
export const INDUSTRY_CREATE_OPEN = "INDUSTRY_CREATE_OPEN";
export const INDUSTRY_CREATE_CLOSE = "INDUSTRY_CREATE_CLOSE";
export const INDUSTRY_CREATE_PENDING = "INDUSTRY_CREATE_PENDING";
export const INDUSTRY_CREATE_ERROR = "INDUSTRY_CREATE_ERROR";
export const INDUSTRY_CREATE_ERROR_CLEAR = "INDUSTRY_CREATE_ERROR_CLEAR";
export const INDUSTRY_CREATE_SUCCESS = "INDUSTRY_CREATE_SUCCESS";

//--------------------INDUSTRY-UPDATE-----------------------------------
export const INDUSTRY_UPDATE_OPEN = "INDUSTRY_UPDATE_OPEN";
export const INDUSTRY_UPDATE_CLOSE = "INDUSTRY_UPDATE_CLOSE";
export const INDUSTRY_UPDATE_PENDING = "INDUSTRY_UPDATE_PENDING";
export const INDUSTRY_UPDATE_ERROR = "INDUSTRY_UPDATE_ERROR";
export const INDUSTRY_UPDATE_SUCCESS = "INDUSTRY_UPDATE_SUCCESS";
export const INDUSTRY_UPDATE_ERROR_CLEAR = "INDUSTRY_UPDATE_ERROR_CLEAR";
export const SET_INDUSTRY_DETAILS = "SET_INDUSTRY_DETAILS";

//--------------------INDUSTRY-LIST-----------------------------------
export const SET_INDUSTRY_LIST = "SET_INDUSTRY_LIST";
export const INDUSTRY_LIST_PENDING = "INDUSTRY_LIST_PENDING";
export const INDUSTRY_LIST_ERROR = "INDUSTRY_LIST_ERROR";
export const INDUSTRY_LIST_ERROR_CLEAR = "INDUSTRY_LIST_ERROR_CLEAR";

