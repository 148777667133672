import {
    MAIL_ERROR,
    MAIL_ERROR_CLEAR,
    MAIL_PENDING,
    SET_MAIL,
    SUBMIT_UNSUBSCRIBE_ERROR,
    SUBMIT_UNSUBSCRIBE_ERROR_CLEAR,
    SUBMIT_UNSUBSCRIBE_PENDING,
    SUBMIT_UNSUBSCRIBE_SUCCESS
} from "./constants";

const initialState = {
    //--------------------GET-EMAIL-------------------------------------
    mail: null,
    isMailPending: false,
    mailErrorMessage: null,
    //----------------------------MAIL-UNSUBSCRIBE-----------------------------------
    submitUnsubscribeErrorMessage: null,
    isSubmitUnsubscribePending: false,
    unsubscribedSuccesss: false
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //-----------------------------------------------GET-EMAIL-----------------------------------------------------------------------------------
        case SET_MAIL: {
            const { mail } = action.payload;
            return {
                ...localState,
                mail: mail,
                isMailPending: false,
                mailErrorMessage: null,
                unsubscribedSuccesss: false,
            }
        }
        case MAIL_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                mailErrorMessage: errorMessage,
                isMailPending: false,
                mail: null
            }
        }
        case MAIL_ERROR_CLEAR: {
            return {
                ...localState,
                mailErrorMessage: null,
            }
        }
        case MAIL_PENDING: {
            return {
                ...localState,
                isMailPending: true,
            }
        }

        //-------------------------------------------------------------MAIL-UNSUBSCRIBE-----------------------------------------------------------------------
        case SUBMIT_UNSUBSCRIBE_SUCCESS: {
            return {
                ...localState,
                unsubscribedSuccesss: true,
                isSubmitUnsubscribePending: false,
                submitUnsubscribeErrorMessage: null,
            }
        }
        case SUBMIT_UNSUBSCRIBE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                submitUnsubscribeErrorMessage: errorMessage,
                isSubmitUnsubscribePending: false,
                unsubscribedSuccesss: false,
            }
        }
        case SUBMIT_UNSUBSCRIBE_ERROR_CLEAR: {
            return {
                ...localState,
                submitUnsubscribeErrorMessage: null,
            }
        }
        case SUBMIT_UNSUBSCRIBE_PENDING: {
            return {
                ...localState,
                isSubmitUnsubscribePending: true,
            }
        }

        default: {
            return localState;
        }
    }
};