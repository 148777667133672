import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Row, Table } from 'reactstrap'
import { getWeeklyOffDetailsAction, updateWeeklyOffDays } from '../../../../store/Modules/LeaveTracker/WeeklyOffDays/actions';
import VWButton from '../../../../components/vwButton/VWButton';
import { clearSetPath } from '../../../../store/actions';
import VWMessage from '../../../../components/vwMessage/vwMessage';

const WeeklyoffDays = (props) => {
    const [formFields, setFormFields] = useState();
    // const [initialForm, setInitialForm] = useState(true);
    const [saveDisable, setSaveDisable] = useState(true);

    useEffect(() => {
        props.getWeeklyOffDetailsAction();
        props.ClearPath();
    }, [])
    useEffect(() => {
        if (props.weeklyOffList) {
            setFormFields(props.weeklyOffList);
        }
    }, [props.weeklyOffList])

    const handleCheckbox = (e, selectedDay) => {
        const isChecked = e.target.checked;
        setSaveDisable(false)
        if (isChecked) {
            const arr = formFields && formFields.dayOfWeek;
            arr && arr.push(selectedDay);
            setFormFields({ ...formFields, dayOfWeek: arr });
        }
        else {
            console.log("unchecked Days");
            const arr = formFields.dayOfWeek || [];
            const index = arr.indexOf(selectedDay);
            if (index > -1) {
                arr.splice(index, 1);
            }
            setFormFields({ ...formFields, dayOfWeek: arr });
            console.log("updatedFormFields:", arr);
        }
    };

    const handleUpdateWeeklyOff = () => {
        console.log("update", formFields && formFields.dayOfWeek)
        setSaveDisable(true)
        props.UpdateWeeklyOffAction(formFields && formFields.dayOfWeek);
    }

    console.log("weeklyOffList:", props.weeklyOffList);
    console.log("formFields:", formFields);
    return (
        <React.Fragment>
            <div className="page-content">
                <>
                    <Card >
                        <CardTitle
                            tag="h4"
                            className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                        >
                            <strong> WEEKLY OFF </strong>
                        </CardTitle>
                    </Card>
                    <Card>
                        {props.errorMessage ? <div className='w-100'>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        <CardBody>
                            <div style={{ minHeight: "300px", overflowX: "auto", width: '200px' }}>
                                <Container>
                                    <Table striped>
                                        <tbody>

                                            <tr>
                                                <td>
                                                    <Input
                                                        name='monday'
                                                        type='checkbox'
                                                        onChange={(e) => handleCheckbox(e, 'monday')}
                                                        checked={(formFields && formFields.dayOfWeek && formFields.dayOfWeek.filter((i) => i === 'monday').length) > 0}
                                                    />
                                                </td>
                                                <td>Monday</td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <Input
                                                        name='tuesday'
                                                        type='checkbox'
                                                        onChange={(e) => handleCheckbox(e, 'tuesday')}
                                                        checked={(formFields && formFields.dayOfWeek && formFields.dayOfWeek.filter((i) => i === 'tuesday').length) > 0}
                                                    /></td>
                                                <td>Tuesday</td>


                                            </tr>
                                            <tr>
                                                <td> <Input
                                                    name='wednesday'
                                                    type='checkbox'
                                                    onChange={(e) => handleCheckbox(e, 'wednesday')}
                                                    checked={(formFields && formFields.dayOfWeek && formFields.dayOfWeek.filter((i) => i === 'wednesday').length) > 0}
                                                /></td>
                                                <td>Wednesday</td>

                                            </tr>
                                            <tr>
                                                <td> <Input
                                                    name='thursday'
                                                    type='checkbox'
                                                    onChange={(e) => handleCheckbox(e, 'thursday')}
                                                    checked={(formFields && formFields.dayOfWeek && formFields.dayOfWeek.filter((i) => i === 'thursday').length) > 0}
                                                /></td>
                                                <td>Thrusday</td>
                                            </tr>
                                            <tr>

                                                <td> <Input
                                                    name='friday'
                                                    type='checkbox'
                                                    onChange={(e) => handleCheckbox(e, 'friday')}
                                                    checked={(formFields && formFields.dayOfWeek && formFields.dayOfWeek.filter((i) => i === 'friday').length) > 0}
                                                /></td>
                                                <td>Friday</td>
                                            </tr>
                                            <tr>
                                                <td> <Input
                                                    name='saturday'
                                                    type='checkbox'
                                                    onChange={(e) => handleCheckbox(e, 'saturday')}
                                                    checked={(formFields && formFields.dayOfWeek && formFields.dayOfWeek.filter((i) => i === 'saturday').length) > 0}
                                                /></td>
                                                <td>Saturday</td>
                                            </tr>
                                            <tr>
                                                <td> <Input
                                                    type='checkbox'
                                                    name='sunday'
                                                    onChange={(e) => handleCheckbox(e, 'sunday')}
                                                    checked={(formFields && formFields.dayOfWeek && formFields.dayOfWeek.filter((i) => i === 'sunday').length) > 0}
                                                />
                                                </td>
                                                <td>Sunday</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Container>
                            </div>
                            {/* <div style={{ marginLeft: '150px' }}>
                                <VWButton
                                    messageKey="createWeeklyOffButton"
                                    defaultMessage="Save"
                                    buttonType="success"
                                    baseClassName="btn"
                                    className="me-2"
                                    isDisabled={saveDisable}
                                    onClick={handleUpdateWeeklyOff}
                                ></VWButton>
                            </div> */}
                            <div className="form-group">
                                <FormGroup>
                                    <Button
                                        className="me-2"
                                        color="success"
                                        onClick={handleUpdateWeeklyOff}
                                        disabled={saveDisable}
                                    >
                                        {props.isUpdateweeklyOffPending ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            &nbsp; Submitting...
                                        </>
                                            : <>Submit</>}
                                    </Button>
                                </FormGroup>
                            </div>
                        </CardBody>
                    </Card>
                </>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.weeklyoffDays.isWeeklyOffPending,
    isUpdateweeklyOffPending: state.weeklyoffDays.isUpdateweeklyOffPending,
    errorMessage: state.weeklyoffDays.weeklyOffListError,
    weeklyOffList: state.weeklyoffDays.weeklyOffList,
});

const mapDispatchToProps = (dispatch) => ({
    getWeeklyOffDetailsAction: () => dispatch(getWeeklyOffDetailsAction()),
    UpdateWeeklyOffAction: (formfield) => dispatch(updateWeeklyOffDays(formfield)),
    ClearPath: () => dispatch(clearSetPath())
});

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyoffDays)