import { CRM_DASHBOARD_CLEAR_ERROR, DASHBOARD_CRM_CLEAR_ERROR, DASHBOARD_CRM_ERROR_MESSAGE, SET_CRM_DASHBOARD_PENDING, SET_DASHBOARD_CRM } from "./constants";

const initialState = {
    DashboardCounts: {},
    isDashboardPending: false,
    dashboardErrorMessage: null
};
export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_DASHBOARD_CRM: {
            const { dashboard } = action.payload;
            console.log("dashboard:", dashboard);
            
            return {
                ...localState,
                isDashboardPending: false,
                DashboardCounts: dashboard,
            }
        }
        case DASHBOARD_CRM_ERROR_MESSAGE: {
            const { dashboardErrorMessage } = action.payload;
            return {
                ...localState,
                isDashboardPending: false,
                dashboardErrorMessage: dashboardErrorMessage,
            }
        }
        case DASHBOARD_CRM_CLEAR_ERROR: {
            return {
                ...localState,
                dashboardErrorMessage: null,
                // DashboardCounts:{}
            }
        }
        case CRM_DASHBOARD_CLEAR_ERROR: {
            return {
                ...localState,
                dashboardErrorMessage: null,
                DashboardCounts:{}
            }
        }

        case SET_CRM_DASHBOARD_PENDING: {
            return {
                ...localState,
                dashboardErrorMessage: null,
                isDashboardPending: true
            }
        }
        default: {
            return localState;
        }
    }
};