import React, { useEffect, useState } from 'react'
import VWMessage from '../../../components/vwMessage/vwMessage';
import { Col, FormGroup, Input, Row, Table } from 'reactstrap';
import Async from 'react-select/async';
import VWButton from '../../../components/vwButton/VWButton';
import { connect } from 'react-redux';
import Select from 'react-select';
import { clearUpdateVisionDocsError, getOrgUserNameAsyncSelect, getUsersNameAsyncSelectAction, updateVisionDocs } from '../../../store/Modules/VisionDocs/actions';

const EditDocsModal = (props) => {
    const [formFields, setFormFields] = useState({ "assignUsers": [] });
    const [prevVisionDocsUser, setprevVisionDocsUser] = useState([{ label: '', value: '' }]);
    const [prevDocumentOwner, setprevDocumentOwner] = useState([{ label: '', value: '' }]);
    const [UserPermission, setUserPermission] = useState();
    const [isReadWrite, setisReadWrite] = useState([]);
    const [errors, setErrors] = useState({});
    const [existingUsers, setExistingUsers] = useState([]);


    useEffect(() => {
        props.clearUpdateVisionDocsError();

        const { data } = props;
        const { contentText, assignUsers } = data;
        if (data) {
            setFormFields(data);
            setprevDocumentOwner({ label: data && data.ownerUserName, value: data && data.ownerUserId });
            if (data && data.ownerUserId) {
                props.getUsersNameAsyncSelectAction(data && data.ownerUserId, '', (UserTypeList) => {
                    setExistingUsers(UserTypeList)
                })
            }
        }
        if (assignUsers) {
            console.log("assignUsers:", assignUsers);
            const UserTypeList = assignUsers.map((item) => {
                return {
                    label: item.userName,
                    value: item.userId,
                    permission: item.permission,
                };
            });
            setUserPermission(UserTypeList);
            setprevVisionDocsUser(UserTypeList);
            const UserID = UserTypeList.map((item) => item.value)
            setFormFields({ ...data, assignUsers: UserID });

            const prevReadWrite = data.assignUsers && data.assignUsers.map((item) => {
                return {
                    userId: item.userId,
                    userName: item.userName,
                    permission: item.permission,
                };
            });
            setisReadWrite(prevReadWrite);
            setFormFields({ ...data, assignUsers: prevReadWrite });
        }
    }, [])

    //-------------------------------Read / write Status Change----------------------------------------------------------
    const handleActiveStatusChange = (userId, Permission) => {
        const updatedUserList = [...isReadWrite];
        const existingIndex = isReadWrite.findIndex((item) => item.userId === userId);
        if (existingIndex !== -1) {
            updatedUserList[existingIndex].permission = Permission
        }
        setisReadWrite(updatedUserList);
        setFormFields({ ...formFields, assignUsers: updatedUserList })
    };

    const handleUserName = (inputValue, callback) => {
        const { getUserNameAsyncSelectAction } = props;
        return getUserNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };


    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'title') {
            setErrors({ ...errors, title: '' });
        }
    };

    // ----------------------------------- select Assigned users---------------------------------------------------------
    const handleAddUserName = (value) => {
        console.log("value:", value);
        const userID = value.map((item) => ({
            userId: item.value,
            userName: item.label,
            permission: isReadWrite && isReadWrite.find((i) => (i.userId === item.value)) ? isReadWrite && isReadWrite.filter((i) => i.userId === item.value)[0].permission : "Read",
        }))
        setisReadWrite(userID);
        setFormFields({ ...formFields, assignUsers: userID });
        setUserPermission(value)
        setprevVisionDocsUser(value);
    }
    const handleAddDocsOwner = (value) => {
        console.log("value:", value);
        setFormFields({ ...formFields, ownerUserId: value.value, ownerUserName: value.label, assignUsers: [] });
        setprevDocumentOwner(value);
        setprevVisionDocsUser([]);
        setUserPermission([]);
        setisReadWrite([])
        if (value) {
            console.log("value:", value.value);
            props.getUsersNameAsyncSelectAction(value && value.value, '', (existingMilestone) => {
                setExistingUsers(existingMilestone)
            })
        }
    }

    const handleUpdateVisionDocs = (e) => {
        e.preventDefault();
        props.handleDone(formFields);
    };

    console.log("UserPermission:", UserPermission);
    console.log("formFields:", formFields);
    console.log("prevDocumentOwner:", prevDocumentOwner);
    return (
        <>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <div className="form-group">
                <label className="col-form-label">
                    Document Owner
                </label>
                <Async
                    name="ownerUserId"
                    placeholder="Select Document Owner"
                    //   className="vm-react-select"
                    isSearchable
                    defaultOptions
                    loadOptions={handleUserName}
                    value={prevDocumentOwner}
                    onChange={handleAddDocsOwner}
                    //   isDisabled={formFields && !formFields.isOwner}
                    isDisabled
                />
            </div>
            <div className="form-group">
                <label className="col-form-label">
                    Select Users
                </label>
                <Select
                    name="UserId"
                    placeholder="Select users"
                    //   className="vm-react-select"
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 30,
                        }),
                    }}
                    isSearchable
                    isMulti
                    defaultOptions={existingUsers}
                    options={existingUsers}
                    onChange={handleAddUserName}
                    value={prevVisionDocsUser}
                />
                {<p className='text-danger mt-2' style={{ lineHeight: '.9em' }}><small><strong>Note</strong>: By default, administrators have read access, even if you don't select administrators.</small></p>}
            </div>
            {UserPermission && UserPermission.length > 0 ?
                <Row>
                    <Col md={12}>
                        <Table className='table-width' responsive borderless>
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th style={{ width: "200px" }}>Permission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {UserPermission && UserPermission.map((item, index) => (
                                    <tr key={item.value}>
                                        <>
                                            <td>{item.label}</td>
                                            <td style={{ minWidth: "150px" }}>
                                                <FormGroup>
                                                    <ul className="forms-unstyled-list form-status-list">
                                                        <li
                                                            onClick={() => { handleActiveStatusChange(item.value, "Read") }}
                                                            className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Read")).length > 0 ? 'active' : '') : ""}`}
                                                        >
                                                            Read
                                                        </li>
                                                        <li
                                                            onClick={() => handleActiveStatusChange(item.value, "Write")}
                                                            className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Write")).length > 0 ? 'active' : '') : ""}`}
                                                        >
                                                            Write
                                                        </li>
                                                    </ul>
                                                </FormGroup>
                                            </td>
                                        </>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                : ""}
            <div className="form-group pt-2">
                <VWButton
                    messageKey="updateVisionDocsButton"
                    defaultMessage="Done"
                    buttonType="success"
                    baseClassName="btn"
                    className="me-2"
                    onClick={handleUpdateVisionDocs}
                    // isLoading={props.isPending}
                    isDisabled={props.isPending}
                ></VWButton>
                <VWButton
                    messageKey="cancelVisionDocsButton"
                    defaultMessage="Cancel"
                    buttonType="danger"
                    baseClassName="btn"
                    onClick={props.handleClose}
                // isDisabled={props.isPending}
                ></VWButton>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    errorMessage: state.visionDocs.updateErrorMessage,
    isPending: state.visionDocs.isPendingUpdate,

});
const mapDispatchToProps = (dispatch) => ({

    getUserNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getOrgUserNameAsyncSelect(searchValue, callback)),
    getUsersNameAsyncSelectAction: (userid, searchValue, callback) =>
        dispatch(getUsersNameAsyncSelectAction(userid, searchValue, callback)),
    updateVisionDocsAction: (formFields) => dispatch(updateVisionDocs(formFields)),
    clearUpdateVisionDocsError: () => dispatch(clearUpdateVisionDocsError()),
    // clearCreatereateVisionDocsError: () => dispatch(clearCreatereateVisionDocsError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditDocsModal)