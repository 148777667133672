import {
    TASK_SCOPE_CREATE_CLOSE,
    TASK_SCOPE_CREATE_ERROR,
    TASK_SCOPE_CREATE_ERROR_CLEAR,
    TASK_SCOPE_CREATE_OPEN,
    TASK_SCOPE_CREATE_PENDING,
    TASK_SCOPE_CREATE_SUCCESS,
    TASK_SCOPE_LIST_ERROR,
    TASK_SCOPE_LIST_ERROR_CLEAR,
    TASK_SCOPE_UPDATE_CLOSE,
    TASK_SCOPE_UPDATE_ERROR,
    TASK_SCOPE_UPDATE_ERROR_CLEAR,
    TASK_SCOPE_UPDATE_OPEN,
    TASK_SCOPE_UPDATE_PENDING,
    TASK_SCOPE_UPDATE_SUCCESS,
    SET_TASK_SCOPE_DETAILS,
    SET_TASK_SCOPE_LIST,
    TASK_SCOPE_LIST_PENDING,
} from "./constants";

const initialState = {
    //--------------------TASK SCOPE-CREATE-----------------------------------
    isCreateTaskScopeOpen: false,
    TaskScopeCreateErrorMessage: null,
    isTaskScopeCreateSuccess: false,
    isTaskScopeCreatePending: false,
    //--------------------SCOPE-UPDATE-----------------------------------
    taskScopeDetails: null,
    isUpdateTaskScopeOpen: false,
    taskScopeUpdateErrorMessage: null,
    isTaskScopeUpdateSuccess: false,
    isTaskScopeUpdatePending: false,
    //--------------------SCOPE-LIST-------------------------------------
    taskScopeList: [],
    taskScopeListCount: 0,
    isTaskScopeListPending: false,
    taskScopeListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------TASK SCOPE-CREATE-----------------------------------
        case TASK_SCOPE_CREATE_OPEN: {
            return {
                ...localState,
                isCreateTaskScopeOpen: true
            }
        }
        case TASK_SCOPE_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateTaskScopeOpen: false
            }
        }
        case TASK_SCOPE_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                TaskScopeCreateErrorMessage: null,
            }
        }
        case TASK_SCOPE_CREATE_PENDING: {
            return {
                ...localState,
                isTaskScopeCreatePending: true,
            }
        }
        case TASK_SCOPE_CREATE_SUCCESS: {
            return {
                ...localState,
                isTaskScopeCreateSuccess: true,
                isTaskScopeCreatePending: false
            }
        }
        case TASK_SCOPE_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                TaskScopeCreateErrorMessage: errorMessage,
                isTaskScopeCreatePending: false,
            }
        }

        // //--------------------TASK SCOPE-UPDATE-----------------------------------
        case SET_TASK_SCOPE_DETAILS: {
            const { scopeDetails } = action.payload;
            console.log("taskScopeDetails:", scopeDetails);
            
            return {
                ...localState,
                taskScopeDetails: scopeDetails
            }
        }
        case TASK_SCOPE_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateTaskScopeOpen: true
            }
        }
        case TASK_SCOPE_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateTaskScopeOpen: false
            }
        }
        case TASK_SCOPE_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                taskScopeUpdateErrorMessage: null,
            }
        }
        case TASK_SCOPE_UPDATE_PENDING: {
            return {
                ...localState,
                isTaskScopeUpdatePending: true,
            }
        }
        case TASK_SCOPE_UPDATE_SUCCESS: {
            return {
                ...localState,
                isTaskScopeUpdateSuccess: true,
                isTaskScopeUpdatePending: false
            }
        }
        case TASK_SCOPE_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                taskScopeUpdateErrorMessage: errorMessage,
                isTaskScopeUpdatePending: false
            }
        }
        //--------------------TASK_SCOPE-LIST-------------------------------------
        case SET_TASK_SCOPE_LIST: {
            const { taskScopeList } = action.payload;
            return {
                ...localState,
                taskScopeList: taskScopeList && taskScopeList['value'],
                taskScopeListCount: taskScopeList && taskScopeList['@odata.count'],
                isTaskScopeListPending: false,
                taskScopeListErrorMessage: null,
            }
        }
        case TASK_SCOPE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                taskScopeListErrorMessage: errorMessage,
                isTaskScopeListPending: false,
                taskScopeList: []
            }
        }
        case TASK_SCOPE_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                taskScopeListErrorMessage: null,
            }
        }
        case TASK_SCOPE_LIST_PENDING: {
            return {
                ...localState,
                isTaskScopeListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};