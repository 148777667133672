
import {
  SET_DEVICE_DETAILS_INITIAL_STATE,
  GET_DEVICE_DETAILS_PENDING,
  GET_DEVICE_DETAILS_SUCCESS,
  SET_DEVICE_DETAILS,
  CLOSE_EDIT_DEVICE_MODAL,
  OPEN_EDIT_DEVICE_MODAL,
  UPDATE_DEVICE_PENDING,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_ERROR,
  CLEAR_UPDATE_DEVICE_ERROR,
  SET_MASTER_DEVICE_DETAILS,
  GET_MASTER_DEVICE_DETAILS_PENDING,
  GET_MASTER_DEVICE_DETAILS_SUCCESS,
  CLOSE_EDIT_MASTER_DEVICE_MODAL,
  OPEN_EDIT_MASTER_DEVICE_MODAL,
  CLEAR_UPDATE_MASTER_DEVICE_ERROR,
  UPDATE_MASTER_DEVICE_ERROR,
  UPDATE_MASTER_DEVICE_SUCCESS,
  UPDATE_MASTER_DEVICE_PENDING,
  OPEN_MAP_NEW_DEVICE_MODAL,
  CLOSE_MAP_NEW_DEVICE_MODAL,
  MAP_NEW_DEVICE_PENDING,
  MAP_NEW_DEVICE_SUCCESS,
  MAP_NEW_DEVICE_ERROR,
  CLEAR_MAP_NEW_DEVICE_ERROR,
  UPDATE_ROOM_DETAILS_ERROR,
  CLOSE_UPDATE_ROOM_DETAILS_MODAL,
  OPEN_UPDATE_ROOM_DETAILS_MODAL,
  CLEAR_UPDATE_ROOM_DETAILS_ERROR,
  UPDATE_ROOM_DETAILS_PENDING,
  UPDATE_ROOM_DETAILS_SUCCESS,
  OPEN_CREATE_GROUP_MODAL,
  CLOSE_CREATE_GROUP_MODAL,
  CREATE_GROUP_PENDING,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  CLEAR_CREATE_GROUP_ERROR,
  UPDATE_SELECTED_DEVICE_SWITCHLIST,
  UPDATE_SWITCH_DETAILS,
  OPEN_UPDATE_GROUP_MODAL,
  CLOSE_UPDATE_GROUP_MODAL,
  UPDATE_GROUP_PENDING,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_ERROR,
  CLEAR_UPDATE_GROUP_ERROR,
  ADD_GROUP_CREATE,
  SET_SELECTED_GROUP,
  ADD_FORM_MAP_DEVICE,
} from "../deviceDetails/actionTypes";

const initialState = {
  isGetDeviceDetailsPending: false,
  device: null,
  updateDeviceErrorMessage: null,
  isUpdateDevicePending: false,
  isEditOrganisationDeviceModalOpen: false,
  isGetMasterDeviceDetailsPending: false,
  masterDevice: null,
  isEditMasterDeviceModalOpen: false,
  isUpdateMasterDevicePending: false,
  errorMessage: null,
  isMapNewDeviceModalOpen: false,
  mapNewDeviceErrorMessage: null,
  isMapNewDevicePending: false,
  isUpdateDeviceFriendlyNameModalOpen: false,
  updateRoomDetailsErrorMessage: null,
  isUpdateRoomDetailsPending: false,
  isCreateGroupModalOpen: false,
  createGroupErrorMessage: null,
  isCreateGroupPending: false,
  switchList: [],
  isUpdateGroupModalOpen: false,
  isUpdateGroupPending: false,
  updateGroupErrorMessage: null,
  AddGroup : {},
  selectedGroup:null,
  AddFormMap:{}
};

export default (
  localState = initialState,
  action: Action
)=> {
  let switchList = {};

  switch (action.type) {
    case SET_DEVICE_DETAILS_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case ADD_GROUP_CREATE: {
      const{AddGroup}=action.payload;
      console.log(AddGroup)
      return {
        ...localState,
        AddGroup : AddGroup,
      };
    }
    case ADD_FORM_MAP_DEVICE : {
      const{AddFormMap}=action.payload;
      console.log(AddFormMap)
      return {
        ...localState,
        AddFormMap : AddFormMap
      }
    }
    case SET_SELECTED_GROUP: {
      const{selectedGroup}=action.payload;
      console.log(selectedGroup)
      return {
        ...localState,
        selectedGroup : selectedGroup,
      };
    }
    case SET_DEVICE_DETAILS: {
      return {
        ...localState,
        device: action.payload,
      };
    }
    case GET_DEVICE_DETAILS_PENDING: {
      return {
        ...localState,
        isGetDeviceDetailsPending: true,
      };
    }
    case GET_DEVICE_DETAILS_SUCCESS: {
      return {
        ...localState,
        isGetDeviceDetailsPending: false,
      };
    }
    case OPEN_EDIT_DEVICE_MODAL: {
      return {
        ...localState,
        isEditDeviceModalOpen: true,
      };
    }
    case CLOSE_EDIT_DEVICE_MODAL: {
      return {
        ...localState,
        isEditDeviceModalOpen: false,
        updateDeviceErrorMessage: null,
      };
    }
    case UPDATE_DEVICE_PENDING: {
      return {
        ...localState,
        isUpdateDevicePending: true,
      };
    }
    case UPDATE_DEVICE_SUCCESS: {
      return {
        ...localState,
        isUpdateDevicePending: false,
      };
    }
    case UPDATE_DEVICE_ERROR: {
      const { errorMessage } = action.payload;
      console.log(errorMessage)
      return {
        ...localState,
      errorMessage: errorMessage,
        isUpdateDevicePending: false,
      };
    }
    case CLEAR_UPDATE_DEVICE_ERROR: {
      return {
        ...localState,
        updateDeviceErrorMessage: null,
        isUpdateDevicePending: false,
      };
    }
    case SET_MASTER_DEVICE_DETAILS: {
      return {
        ...localState,
        masterDevice: action.payload,
      };
    }
    case GET_MASTER_DEVICE_DETAILS_PENDING: {
      return {
        ...localState,
        isGetMasterDeviceDetailsPending: true,
      };
    }
    case GET_MASTER_DEVICE_DETAILS_SUCCESS: {
      return {
        ...localState,
        isGetMasterDeviceDetailsPending: false,
      };
    }
    case OPEN_EDIT_MASTER_DEVICE_MODAL: {
      return {
        ...localState,
        isEditMasterDeviceModalOpen: true,
      };
    }
    case CLOSE_EDIT_MASTER_DEVICE_MODAL: {
      return {
        ...localState,
        isEditMasterDeviceModalOpen: false,
        updateMasterDeviceErrorMessage: null,
      };
    }
    case UPDATE_MASTER_DEVICE_PENDING: {
      return {
        ...localState,
        isUpdateMasterDevicePending: true,
      };
    }
    case UPDATE_MASTER_DEVICE_SUCCESS: {
      return {
        ...localState,
        isUpdateMasterDevicePending: false,
      };
    }
    case UPDATE_MASTER_DEVICE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage: errorMessage,
        isUpdateMasterDevicePending: false,
      };
    }
    case CLEAR_UPDATE_MASTER_DEVICE_ERROR: {
      return {
        ...localState,
        updateMasterDeviceErrorMessage: null,
        isUpdateMasterDevicePending: false,
      };
    }
    case OPEN_MAP_NEW_DEVICE_MODAL: {
      return {
        ...localState,
        isMapNewDeviceModalOpen: true,
      };
    }
    case CLOSE_MAP_NEW_DEVICE_MODAL: {
      return {
        ...localState,
        isMapNewDeviceModalOpen: false,
        mapNewDeviceErrorMessage: null,
      };
    }
    case MAP_NEW_DEVICE_PENDING: {
      return {
        ...localState,
        isMapNewDevicePending: true,
      };
    }
    case MAP_NEW_DEVICE_SUCCESS: {
      return {
        ...localState,
        isMapNewDevicePending: false,
      };
    }
    case MAP_NEW_DEVICE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        mapNewDeviceErrorMessage: errorMessage,
        isMapNewDevicePending: false,
      };
    }
    case CLEAR_MAP_NEW_DEVICE_ERROR: {
      return {
        ...localState,
        mapNewDeviceErrorMessage: null,
      };
    }
    case OPEN_UPDATE_ROOM_DETAILS_MODAL: {
      return {
        ...localState,
        isUpdateDeviceFriendlyNameModalOpen: true,
      };
    }
    case CLOSE_UPDATE_ROOM_DETAILS_MODAL: {
      return {
        ...localState,
        isUpdateDeviceFriendlyNameModalOpen: false,
        updateRoomDetailsErrorMessage: null,
      };
    }
    case UPDATE_ROOM_DETAILS_PENDING: {
      return {
        ...localState,
        isUpdateRoomDetailsPending: true,
      };
    }
    case UPDATE_ROOM_DETAILS_SUCCESS: {
      return {
        ...localState,
        isUpdateRoomDetailsPending: false,
      };
    }
    case UPDATE_ROOM_DETAILS_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateRoomDetailsErrorMessage: errorMessage,
        isUpdateRoomDetailsPending: false,
      };
    }
    case CLEAR_UPDATE_ROOM_DETAILS_ERROR: {
      return {
        ...localState,
        updateRoomDetailsErrorMessage: null,
      };
    }
    case OPEN_CREATE_GROUP_MODAL: {
      return {
        ...localState,
        isCreateGroupModalOpen: true,
      };
    }
    case CLOSE_CREATE_GROUP_MODAL: {
      return {
        ...localState,
        isCreateGroupModalOpen: false,
        createGroupErrorMessage: null,
      };
    }
    case CREATE_GROUP_PENDING: {
      return {
        ...localState,
        isCreateGroupPending: true,
      };
    }
    case CREATE_GROUP_SUCCESS: {
      return {
        ...localState,
        isCreateGroupPending: false,
      };
    }
    case CREATE_GROUP_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        createGroupErrorMessage: errorMessage,
        isCreateGroupPending: false,
      };
    }
    case CLEAR_CREATE_GROUP_ERROR: {
      return {
        ...localState,
        createGroupErrorMessage: null,
      };
    }
    case UPDATE_SELECTED_DEVICE_SWITCHLIST: {
      const { switchList } = action.payload;
      return {
        ...localState,
        switchList,
      };
    }
    case UPDATE_SWITCH_DETAILS: {
      switchList = [...localState.switchList];
      const index = localState.switchList.findIndex(
        (switchdata) => switchdata.id === action.payload.id
      );
      if (switchList[index]) switchList[index] = action.payload;
      return {
        ...localState,
        switchList: switchList,
      };
    }
    case OPEN_UPDATE_GROUP_MODAL: {
      return {
        ...localState,
        isUpdateGroupModalOpen: true,
      };
    }
    case CLOSE_UPDATE_GROUP_MODAL: {
      return {
        ...localState,
        isUpdateGroupModalOpen: false,
        updateGroupErrorMessage: null,
      };
    }
    case UPDATE_GROUP_PENDING: {
      return {
        ...localState,
        isUpdateGroupPending: true,
      };
    }
    case UPDATE_GROUP_SUCCESS: {
      return {
        ...localState,
        isUpdateGroupPending: false,
      };
    }
    case UPDATE_GROUP_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateGroupErrorMessage: errorMessage,
        isUpdateGroupPending: false,
      };
    }
    case CLEAR_UPDATE_GROUP_ERROR: {
      return {
        ...localState,
        updateGroupErrorMessage: null,
      };
    }
    default: {
      return localState;
    }
  }
};
