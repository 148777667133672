import {
    CONTACTS_CREATE_CLOSE,
    CONTACTS_CREATE_ERROR,
    CONTACTS_CREATE_ERROR_CLEAR,
    CONTACTS_CREATE_OPEN,
    CONTACTS_CREATE_PENDING,
    CONTACTS_CREATE_SUCCESS,
    CONTACTS_DETAILS_ERROR,
    CONTACTS_DETAILS_ERROR_CLEAR,
    CONTACTS_DETAILS_PENDING,
    CONTACTS_EXPORT_LIST_ERROR,
    CONTACTS_EXPORT_LIST_ERROR_CLEAR,
    CONTACTS_EXPORT_LIST_PENDING,
    CONTACTS_LIST_ERROR,
    CONTACTS_LIST_ERROR_CLEAR,
    CONTACTS_LIST_PENDING,
    CONTACTS_UPDATE_CLOSE,
    CONTACTS_UPDATE_ERROR,
    CONTACTS_UPDATE_ERROR_CLEAR,
    CONTACTS_UPDATE_OPEN,
    CONTACTS_UPDATE_PENDING,
    CONTACTS_UPDATE_SUCCESS,
    SET_CONTACTS_DETAILS,
    SET_CONTACTS_EXPORT_LIST,
    SET_CONTACTS_LIST
} from "./constants";

const initialState = {
    //--------------------CONTACT-CREATE-----------------------------------
    isCreateContactsOpen: false,
    contactsCreateErrorMessage: null,
    isContactsCreateSuccess: false,
    isContactsCreatePending: false,
    //--------------------CONTACT-UPDATE-----------------------------------
    isUpdateContactsOpen: false,
    contactsUpdateErrorMessage: null,
    isContactsUpdateSuccess: false,
    isContactsUpdatePending: false,
    //--------------------CONTACT-LSIT-------------------------------------
    contactsList: [],
    contactsListCount: 0,
    isContactsListPending: false,
    contactsListErrorMessage: null,
    //--------------------CONTACT-DETAILS----------------------------------
    contactsDetails: [],
    isContactsDetailPending: false,
    contactsDetailErrorMessage: null,
    isContactsExportListPending: false,
    contactsExportListErrorMessage: null,
    contactsExportList:[],
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------CONTACTS-CREATE-----------------------------------
        case CONTACTS_CREATE_OPEN: {
            return {
                ...localState,
                isCreateContactsOpen: true
            }
        }
        case CONTACTS_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateContactsOpen: false
            }
        }
        case CONTACTS_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                contactsCreateErrorMessage: null,
            }
        }
        case CONTACTS_CREATE_PENDING: {
            return {
                ...localState,
                isContactsCreatePending: true,
            }
        }
        case CONTACTS_CREATE_SUCCESS: {
            return {
                ...localState,
                isContactsCreateSuccess: true,
                isContactsCreatePending: false
            }
        }
        case CONTACTS_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                contactsCreateErrorMessage: errorMessage,
                isContactsCreatePending: false,
            }
        }

        //--------------------CONTACTS-UPDATE-----------------------------------
        case CONTACTS_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateContactsOpen: true
            }
        }
        case CONTACTS_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateContactsOpen: false
            }
        }
        case CONTACTS_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                contactsUpdateErrorMessage: null,
            }
        }
        case CONTACTS_UPDATE_PENDING: {
            return {
                ...localState,
                isContactsUpdatePending: true,
            }
        }
        case CONTACTS_UPDATE_SUCCESS: {
            return {
                ...localState,
                isContactsCreateSuccess: true,
                isContactsUpdatePending: false
            }
        }
        case CONTACTS_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                contactsUpdateErrorMessage: errorMessage,
                isContactsUpdatePending: false
            }
        }
        //--------------------CONTACTS-LSIT-------------------------------------
        case SET_CONTACTS_LIST: {
            const { contactsList } = action.payload;
            return {
                ...localState,
                contactsList: contactsList && contactsList['value'],
                contactsListCount: contactsList && contactsList['@odata.count'],
                isContactsListPending: false,
                contactsListErrorMessage: null,
            }
        }
        case SET_CONTACTS_EXPORT_LIST: {
            const { contactsExportList } = action.payload;
            return {
                ...localState,
                contactsExportList: contactsExportList,
                // contactsListCount: contactsList && contactsList['@odata.count'],
                isContactsExportListPending: false,
                contactsExportListErrorMessage: null,
            }
        }
        case CONTACTS_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                contactsListErrorMessage: errorMessage,
                isContactsListPending: false,
                contactsList: []
            }
        }
        case CONTACTS_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                contactsExportListErrorMessage: errorMessage,
                isContactsExportListPending: false,
                contactsExportList: []
            }
        }
        case CONTACTS_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                contactsListErrorMessage: null,
            }
        }
        case CONTACTS_EXPORT_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                contactsExportListErrorMessage: null,
                isContactsExportListPending: false,
            }
        }
        case CONTACTS_LIST_PENDING: {
            return {
                ...localState,
                isContactsListPending: true,
            }
        }
        case CONTACTS_EXPORT_LIST_PENDING: {
            return {
                ...localState,
                isContactsExportListPending: true,
            }
        }
        //--------------------CONTACTS-DETAILS----------------------------------
        case SET_CONTACTS_DETAILS: {
            const { contactsDetails } = action.payload;
            return {
                ...localState,
                contactsDetails: contactsDetails,
                contactsDetailErrorMessage: null,
                isContactsDetailPending: false
            }
        }
        case CONTACTS_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                contactsDetailErrorMessage: errorMessage,
                isContactsDetailPending: false,
                contactsDetails: []
            }
        }
        case CONTACTS_DETAILS_ERROR_CLEAR: {
            return {
                ...localState,
                contactsDetailErrorMessage: null,
            }
        }
        case CONTACTS_DETAILS_PENDING: {
            return {
                ...localState,
                isContactsDetailPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};