import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, InputGroup, Table } from 'reactstrap'
import VWModel from '../../../../components/Modal/VWModal'
import moment from "moment";
import VWPagination from '../../../../components/vwPagination/vwPagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCalendarAlt, faFont } from "@fortawesome/free-solid-svg-icons";
import CreateLeaveMaster from './leaveMasterCreate'
import { getPager } from '../../../../helper/common'
import { connect } from 'react-redux'
import { closeCreateLeaveMasterModalAction, getleaveMasterExportAction, getleaveMasterListAction, openCreateLeaveMasterModalAction } from '../../../../store/Modules/LeaveTracker/LeaveMaster/actions';
import { clearForm } from '../../../../store/form/actions';
import NoRecord from '../../../../components/NoRecords/noRecords';
import VWSkeleton from '../../../../components/vwSkeleton/vwSkeleton';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetPath } from '../../../../store/actions';
import Select from "react-select";
import { getEmployeeUserAsyncSelect, getLeaveTypeAsyncSelect, getLeaveTypeListAsyncSelect } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions';
import Async from "react-select/async"
import '../../../Modules/viewList.css'
import UITooltip from '../../../../CommonData/Data/UITooltip';
import { excelPrint, excelSheetExport } from '../../../../helper/export';
import { LEAVE_MASTER_EXPORT_HEADERS } from '../../../../store/Modules/LeaveTracker/LeaveMaster/constants';

const FrequencyOptions = [
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Yearly', value: 'Yearly' },
]

const customStyles = {
  container: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'normal'
  }),
};
const LeaveMaster = (props) => {
  const [PageSize] = useState(20);
  const [Pager, setPager] = useState({ currentPage: 1 });
  const [filter, setFilter] = useState();
  const [filterSelectionOption, setFilterSelectionOption] = useState('name');
  const { isLeaveMasterExportPending } = props;

  const buildQueryUserName = () => {
    const { Username } = filter || {};
    let searchUserName = '';
    if (Username) {
      searchUserName += `${Username}`;
    }
    return searchUserName
  }

  const buildQueryQty = () => {
    const { Qty } = filter || {};
    let searchQty = '';
    if (Qty) {
      searchQty += `${Qty}`;
    }
    return (searchQty)
  }
  const buildQueryCreatedBy = () => {
    const { CreatedBy } = filter || {};
    let searchCreatedBy = '';
    if (CreatedBy) {
      searchCreatedBy += `${CreatedBy}`;
    }
    return (searchCreatedBy)
  }
  const buildQueryCreatedDateTime = () => {
    const { CreatedDateTime } = filter || {};
    let searchCreatedDateTime = '';
    if (CreatedDateTime) {
      searchCreatedDateTime += `${CreatedDateTime}`;
    }
    return (searchCreatedDateTime)
  }
  const buildQueryFrequency = () => {
    const { Frequency } = filter || {};
    let searchFrequency = '';
    if (Frequency) {
      searchFrequency += `${Frequency}`;
    }
    return (searchFrequency)
  }
  const buildQueryLeaveTypeName = () => {
    const { LeaveTypeName } = filter || {};
    let searchLeaveTypeName = '';
    if (LeaveTypeName) {
      searchLeaveTypeName += `${LeaveTypeName}`;
    }
    return (searchLeaveTypeName)
  }
  const buildQueryConsecutiveDaysQty = () => {
    const { ConsecutiveDaysQty } = filter || {};
    let searchConsecutiveDaysQty = '';
    if (ConsecutiveDaysQty) {
      searchConsecutiveDaysQty += `${ConsecutiveDaysQty}`;
    }
    return (searchConsecutiveDaysQty)
  }

  const searchFrequency = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      Frequency: value,
    })
  }

  const handleLoadEmployeeUserList = (inputValue, callback) => {
    const { getEmployeeUserAsyncSelectAction } = props;
    return getEmployeeUserAsyncSelectAction(App ? App[0] && App[0].appId : '', inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleSearchEmployeeUser = (Value) => {
    console.log("value:", Value);
    const { label, UserEmail } = Value || {};
    setFilter({
      ...filter,
      UserName: UserEmail,
    })
  }
  const setPage = (page = 1) => {
    props.getleaveMasterAction(filter, page, PageSize, buildQueryUserName(), buildQueryQty(), buildQueryCreatedBy(), buildQueryCreatedDateTime(), buildQueryFrequency(), buildQueryLeaveTypeName(), buildQueryConsecutiveDaysQty());

    setPager(prevPager => {
      const totalPages = Math.ceil(props.totalCount / PageSize);
      const visiblePages = 4;
      const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
      const newPages = [];
      for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
        newPages.push(i);
      }
      return {
        ...prevPager,
        currentPage: page,
        totalPages,
        pages: newPages,
      }
    })
  }

  useEffect(() => {
    const currentPager = getPager(props.totalCount, 1, PageSize)
    setPager(currentPager)
  }, [props.totalCount])

  useEffect(() => {
    const currentPageNumber = Pager && Pager.currentPage;
    setPage(currentPageNumber);
    props.ClearPath();
  }, [])
  //------------------------------------------Filter-----------------------------------------------------------
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const searchInputValidation = (event) => {
    const { value, name } = event.target;
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const handleSearchClick = () => {
    setPage();
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }
  const debounceTime = window.__ENV && window.__ENV.debounceTime;
  useEffect(() => {
    if (filter) {
      const timeout = setTimeout(() => {
        handleSearchClick();
      }, debounceTime);
      setDebounceTimeout(timeout);
    }

  }, [filter])

  //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
  const handleTextSelect = (e) => {
    e.preventDefault();
    setFilterSelectionOption('date');
    setFilter({
      ...filter,
      CreatedBy: null,
      CreatedDateTime: null,
    });
  }

  const handleDateSelect = (e) => {
    e.preventDefault();
    setFilterSelectionOption('name');
    setFilter({
      ...filter,
      CreatedBy: null,
      CreatedDateTime: null,
    });
  }

  // ----------------------------------------SORTING-----------------------------------------------------------//
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = (column) => {
    if (sortOrder === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder(column);
      setSortDirection('asc');
    }
  };

  const sortedLeaveMasterList = [...props.LeaveMasterList].sort((a, b) => {
    const aValue = a[sortOrder] || 'NA';
    const bValue = b[sortOrder] || 'NA';
    if (sortOrder === 'Qty') {
      const aVal = parseFloat(a[sortOrder] || 0);
      const bVal = parseFloat(b[sortOrder] || 0);

      return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
    }
    return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  //-----------------------------------------Modal open & close-----------------------------------------------------

  const handleOpenLeaveMaster = () => {
    props.openCreateLeaveMasterModal();
  };
  const handleClose = () => {
    props.clearFormAction()
    props.closeCreateLeaveMasterModal();
  };
  const style = {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };
  const handleLoadLeaveType = (inputValue, callback) => {
    const { getLeaveTypeAsyncSelectAction } = props;
    return getLeaveTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleSearchLeaveType = (value) => {
    const { label } = value || {};
    setFilter({
      ...filter,
      LeaveTypeName: label,
    })
  }

  const navigate = useNavigate();
  const OnDoubleClickAction = (Id) => {
    navigate(`/leavetracker/leavemaster/details/${Id}`);
  };

  //-------------------------------------------------Export---------------------------------------------------------------------------
  const handleExports = () => {
    props.getleaveMasterExportAction(buildQueryUserName(), buildQueryQty(), buildQueryCreatedBy(), buildQueryCreatedDateTime(), buildQueryFrequency(), buildQueryLeaveTypeName(), buildQueryConsecutiveDaysQty())
      .then((response) => {
        if (response) {
          const exportLeaveMasterExportList = [];
          const headers = LEAVE_MASTER_EXPORT_HEADERS;
          const headerExcel = [
            { headerName: "EMPLOYEE", key: "Username", headerWidhth: 30 },
            { headerName: "EMPLOYEE EMAIL", key: "UserEmail", headerWidhth: 50 },
            { headerName: "LEAVE TYPE NAME", key: "LeaveTypeName", headerWidhth: 30 },
            { headerName: "FREQUENCY", key: "Frequency", headerWidhth: 20 },
            { headerName: "QTY", key: "Qty", headerWidhth: 20 },
            { headerName: "CONSECUTIVE DAYS DAYS", key: "ConsecutiveDaysQty", headerWidhth: 40 },
            { headerName: "CREATED BY", key: "CreatedBy", headerWidhth: 20 },
            { headerName: "CREATED DATE", key: "CreatedDateTime", headerWidhth: 20 },
          ]
          const headerSizes = [30, 50, 30, 30, 20, 20, 40, 20];
          response && response.forEach((element) => {
            exportLeaveMasterExportList.push({
              Username: element.username || "N/A",
              UserEmail: element.userEmail || "N/A",
              LeaveTypeName: element.leaveTypeName || "N/A",
              Frequency: element.frequency || "N/A",
              Qty: element.qty || 0,
              ConsecutiveDaysQty: element.consecutiveDaysQty || 0,
              CreatedBy: element.createdBy || "N/A",
              CreatedDateTime: element.createdDateTime ? moment(element.createdDateTime).format('DD MMM YYYY') : 'N/A',
            });
          });
          excelSheetExport(headerExcel, exportLeaveMasterExportList, "Leave Master-List");
          // excelPrint(exportLeaveMasterExportList, "Leave Master-List", headers, headerSizes);
        }
      })
  }

  const { userAppDetails } = props && props.currentProfile;
  const App = userAppDetails.filter((i) => {
    return (i.appName === "LeaveTracker") ? i.appId : ""
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Card >
          <CardTitle
            tag="h4"
            className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
          >
            <strong className='d-flex align-items-center'>CUSTOM LEAVE MASTER -&nbsp;<Badge color="danger">{props && props.totalCount}</Badge></strong>
            <div className="content-heading">
              <div className="ml-auto">
                <Link
                  type="button"
                  className="btn btn-info"
                  onClick={handleOpenLeaveMaster}
                >
                  CREATE
                </Link> &nbsp; <Button
                  className="me-4"
                  color="success"
                  onClick={handleExports}
                  disabled={props.LeaveMasterList && props.LeaveMasterList.length <= 0 || isLeaveMasterExportPending}
                >
                  {isLeaveMasterExportPending ? 'EXPORTING...' : 'EXPORT'}
                </Button>
              </div>
            </div>
          </CardTitle>
        </Card>
        <Card>
          <CardBody className="p-4">
            {props.errorMessage ? <div className='w-100'>
              {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
              )}
            </div> : ""}
            <div style={{ height: "60vh", overflowX: "auto" }}>
              <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                  <tr>
                    <th
                      onClick={() => handleSort('Username')} className='hand-cursor'
                    >
                      Employee &nbsp;
                      {sortOrder === 'Username' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('LeaveTypeName')} className='hand-cursor'
                      style={{ minWidth: '150px' }}
                    >
                      Type &nbsp;
                      {sortOrder === 'LeaveTypeName' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th style={{ minWidth: '150px' }}
                      onClick={() => handleSort('Frequency')} className='hand-cursor'
                    >
                      Frequency &nbsp;
                      {sortOrder === 'Frequency' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>

                    <th
                      onClick={() => handleSort('Qty')} className='hand-cursor'

                    >Qty &nbsp;
                      {sortOrder === 'Qty' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                    </th>
                    <th
                      onClick={() => handleSort('Qty')} className='hand-cursor'
                    >Consecutive Days&nbsp;
                      {sortOrder === 'ConsecutiveDaysQty' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                    </th>
                    <th
                      onClick={() => handleSort('CreatedBy')} className='hand-cursor'
                    >
                      Created &nbsp;
                      {sortOrder === 'CreatedBy' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                      View
                    </th>
                  </tr>
                  <tr>
                    <th >
                      {/* <Input
                        type='text'
                        placeholder='Search'
                        name='Username'
                        onChange={searchInputValidation}
                        value={filter && filter.Username}
                        onKeyPress={handleKeyPress}
                      /> */}
                      <Async
                        styles={customStyles}
                        name="UserName"
                        isClearable
                        defaultOptions
                        placeholder="Search"
                        loadOptions={handleLoadEmployeeUserList}
                        onChange={handleSearchEmployeeUser}
                      />
                    </th>
                    <th >
                      <Async
                        styles={customStyles}
                        name="LeaveTypeName"
                        isClearable
                        defaultOptions
                        placeholder="Search"
                        loadOptions={handleLoadLeaveType}
                        onChange={handleSearchLeaveType}
                      />
                    </th>
                    <th>
                      <Select
                        styles={customStyles}
                        name="Frequency"
                        isClearable
                        defaultOptions
                        placeholder="Search"
                        options={FrequencyOptions}
                        onChange={searchFrequency}
                      />
                    </th>
                    <th >
                      <Input
                        onKeyDown={
                          (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}
                        type='number'
                        placeholder='Search'
                        name='Qty'
                        onChange={searchInputValidation}
                        value={filter && filter.Qty}
                        onKeyPress={handleKeyPress}
                      /></th>
                    <th >
                      <Input
                        onKeyDown={
                          (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}
                        type='number'
                        placeholder='Search'
                        name='ConsecutiveDaysQty'
                        onChange={searchInputValidation}
                        value={filter && filter.ConsecutiveDaysQty}
                        onKeyPress={handleKeyPress}
                      /></th>
                    <th>
                      <InputGroup style={{ width: "200px" }}>
                        {filterSelectionOption === 'name' ?
                          <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                            <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                          </UITooltip>
                          : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                            <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                          </UITooltip>
                        }
                        <Input
                          style={{ width: "120px" }}
                          type={filterSelectionOption === 'name' ? 'text' : 'date'}
                          placeholder='Search'
                          name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedDateTime'}
                          onChange={searchInputValidation}
                          onKeyPress={handleKeyPress}
                          value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedDateTime || '' : ""}
                          max={filterSelectionOption !== "name" && "9999-12-31"}
                        />
                      </InputGroup>
                    </th>
                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {!props.isLeaveMasterPending && (props.LeaveMasterList && props.LeaveMasterList.length > 0) ?
                    sortedLeaveMasterList && sortedLeaveMasterList.map((data) => (
                      <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                        <td className='ps-3'>
                          {data.Username}<br />
                          <small>{data.UserEmail}</small>
                        </td>
                        <td className='ps-3'>{data.LeaveTypeName || 'N/A'}</td>
                        <td className='ps-3'>{data.Frequency || 'N/A'}</td>
                        <td className='text-center'>{data.Qty || 0}</td>
                        <td className='text-center'>{data.ConsecutiveDaysQty || 0}</td>
                        <td>
                          {data.CreatedBy || "N/A"}<br />
                          <small>{data.CreatedDateTime ? moment(data.CreatedDateTime).format('DD MMM YYYY') : "N/A"}</small>
                        </td>
                        <td className="sticky-column" style={{ backgroundColor: "white" }}> <Link
                          type="button"
                          className="btn btn-sm  btn-light"
                          to={`/leavetracker/leavemaster/details/${data.Id}`}
                        >
                          <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                        </Link></td>
                      </tr>
                    ))
                    : props.isLeaveMasterPending ?
                      <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <VWSpinner />
                          </div>
                        </div>
                      </div>
                      :
                      <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <NoRecord />
                          </div>
                        </div>
                      </div>
                  }
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardFooter>
            {props.LeaveMasterList && props.LeaveMasterList.length > 0 ?
              <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
            }
          </CardFooter>
        </Card>
        <VWModel
          header="CREATE LEAVE MASTER"
          isOpen={props.isLeaveMasterModalOpen}
          handleClose={handleClose}
          modalSize='large'
        >
          <CreateLeaveMaster
            handleClose={handleClose}
            filteredData={filter}
            appId={App[0] && App[0].appId}
          />
        </VWModel>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => ({
  isLeaveMasterModalOpen: state.leaveMaster.isCreateLeaveMasterModalOpen,
  LeaveMasterList: state.leaveMaster.LeaveMasterList,
  errorMessage: state.leaveMaster.leaveMasterListError,
  totalCount: state.leaveMaster.totalRecords,
  LeaveMasterExportList: state.leaveMaster.LeaveMasterExportList,
  isLeaveMasterPending: state.leaveMaster.isLeaveMasterPending,
  isLeaveMasterExportPending: state.leaveMaster.isLeaveMasterExportPending,
  currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
  getleaveMasterAction: (filter, page, PageSize, searchUserName, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty) => dispatch(getleaveMasterListAction(filter, page, PageSize, searchUserName, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty)),
  getleaveMasterExportAction: (searchUserName, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty) => dispatch(getleaveMasterExportAction(searchUserName, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty)),
  closeCreateLeaveMasterModal: () => dispatch(closeCreateLeaveMasterModalAction()),
  openCreateLeaveMasterModal: () => dispatch(openCreateLeaveMasterModalAction()),
  clearFormAction: () => dispatch(clearForm()),
  ClearPath: () => dispatch(clearSetPath()),
  getLeaveTypeAsyncSelectAction: (searchValue, callback) =>
    dispatch(getLeaveTypeListAsyncSelect(searchValue, callback)),
  getEmployeeUserAsyncSelectAction: (appId, searchValue, callback) =>
    dispatch(getEmployeeUserAsyncSelect(appId, searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveMaster)