import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Alert, Card, CardBody, CardFooter, CardTitle, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faCode, faFileWord, faHouseMedical, faEnvelope, faUserTie, faUsers, faCopy, faHeadset } from '@fortawesome/free-solid-svg-icons'
import ProfileMenu from '../../components/Common/TopbarDropdown/ProfileMenu';
import { connect } from 'react-redux';
import { clearSetPath, getCurrentProfile, logoutUser, userLogin } from '../../store/actions';
// import Footer from '../../Layout/VerticalLayout/Footer';
import './Dashboard.css'
import { getPendingSubmittedCount } from '../../store/Modules/LeaveTracker/MyTeamLD/actions';
import { getTimesheetApprovalCount } from '../../store/Modules/Timesheet/TimesheetApproval/actions';
import { sendToggleView } from '../../store/Modules/VisionDocs/actions';
import { clearAllDocsList, sendToggleViewDocs } from '../../store/Modules/OverAllDocs/actions';
import { getOrganizationLogo } from '../../store/Modules/Admin/PortalManagement/actions';
const Dashboard = (props) => {
  const { profile, OrgLogoDetails } = props;
  const { userAppDetails, orgName, isManager, orgId, orgLogo, orgPortalName } = profile || {};
  // console.log(props.profile)
  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  // useEffect(() => {
  //   props.getOrganizationLogo(orgId);
  // }, [orgId]);

  useEffect(() => {
    // props.getCurrentProfile();
    props.sendToggleView(true);
    props.sendToggleViewDocs(true);
    props.clearAllDocsList();
    props.ClearPath();
    const isLeaveTracker = userAppDetails && userAppDetails.filter((i) => (i.appName && i.appName.toLowerCase()) === 'leavetracker').length
    const isTimesheet = userAppDetails && userAppDetails.filter((i) => (i.appName && i.appName.toLowerCase()) === 'timesheets').length
    if ((isLeaveTracker > 0) && isManager) {
      props.getPendingSubmittedCount();
    }

    if ((isTimesheet > 0) && isManager) {
      props.getTimesheetApprovalCount();
    }
  }, [])

  const allowedRoles = [
    "administrator",
    "user"
  ];

  const superAdminAllowedRoles = [
    "super admin",
    "administrator",
  ]

  const dbSyncAllowedRoles = [
    'administrator'
  ]

  return (
    <>
      <div className="bg-overlay"></div>
      <br />

      {props.currentProfileErrorMessage && (
        // <Alert color="danger">{props.currentProfileErrorMessage}</Alert>
        (<Navigate to='/' />)
      )}
      {!props.currentProfileErrorMessage && (
        <>

          <div className='header-container'>
            <div className="org-name-wrapper" style={{ 'width': '100%', 'textAlign': 'center' }}>
              <div className="org-name">{profile && profile.orgPortalName ? orgPortalName : window.__ENV && window.__ENV.project_name && window.__ENV.project_name.toUpperCase()} - {orgName && orgName.toUpperCase()}
              </div>
            </div>
            <ProfileMenu />
          </div>

          {/* className='d-flex flex-row-reverse p-2' */}
          <Container className='pt-4'>
            <Row>
              {userAppDetails && userAppDetails
                .filter(item => item.appName.toLowerCase() === "admin")
                .map(userApp => {
                  const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => superAdminAllowedRoles.includes(role.roleName.toLowerCase()));
                  if (allowedModulesRoles.length > 0) {
                    return (
                      <Col md={3} className="d-flex justify-content-center">
                        <Card className="custom-card" style={{ backgroundColor: "#424bf5" }}>
                          <Link to="/admin/organization">
                            <CardBody className='d-flex justify-content-center'>
                              <FontAwesomeIcon icon={faUserTie} color='white' style={{ minHeight: "50px" }} />
                            </CardBody>
                            <CardFooter><CardTitle className='text-center' style={{ color: "#424bf5" }}>Admin</CardTitle></CardFooter>
                          </Link>
                        </Card>
                      </Col>
                    );
                  }
                  return null;
                })
              }

              {userAppDetails && userAppDetails
                .filter(item => item.appName.toLowerCase() === "employee")
                .map(userApp => {
                  const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                  if (allowedModulesRoles.length > 0) {
                    return (
                      <Col md={3} className="d-flex justify-content-center">
                        <Card color='primary' className="custom-card" >
                          < Link to="/employees/employee">
                            <CardBody className='d-flex justify-content-center'>
                              <FontAwesomeIcon icon={faUsers} color='white' style={{ height: "50px" }} />
                            </CardBody>
                            <CardFooter><CardTitle className='text-center text-primary'>Employee</CardTitle></CardFooter>
                          </Link>
                        </Card>
                      </Col>
                    );
                  }
                  return null;
                })
              }

              {userAppDetails && userAppDetails
                .filter(item => item.appName.toLowerCase() === "leavetracker")
                .map(userApp => {
                  const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                  if (allowedModulesRoles.length > 0) {
                    return (
                      <Col md={3} className="d-flex justify-content-center">
                        <Card color='danger' className="custom-card" >
                          <Link to="/leavetracker/dashboard" >
                            <CardBody className='d-flex justify-content-center'>
                              <FontAwesomeIcon icon={faHouseMedical} color='white' style={{ height: "50px" }} />
                            </CardBody>
                            <CardFooter><CardTitle className='text-center' style={{ color: "red" }}>Leave Tracker</CardTitle></CardFooter>
                          </Link>
                        </Card>
                      </Col>
                    );
                  }
                  return null;
                })
              }

              {userAppDetails && userAppDetails
                .filter(item => item.appName.toLowerCase() === "timesheets")
                .map(userApp => {
                  const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                  if (allowedModulesRoles.length > 0) {
                    return (
                      <Col md={3} key={userApp.id} className="d-flex justify-content-center">
                        <Card className="custom-card" style={{ backgroundColor: "indigo" }} >
                          <Link to="/timesheets/dashboard">
                            <CardBody className='d-flex justify-content-center'>
                              <FontAwesomeIcon icon={faCalendarDays} color='white' style={{ height: "50px" }} />
                            </CardBody>
                            <CardFooter><CardTitle className='text-center' style={{ color: "indigo" }}>Timesheets</CardTitle></CardFooter>
                          </Link>
                        </Card>
                      </Col>
                    );
                  }
                  return null;
                })
              }

              {userAppDetails && userAppDetails
                .filter(item => item.appName.toLowerCase() === "projectportal")
                .map(userApp => {
                  const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                  if (allowedModulesRoles.length > 0) {
                    return (
                      <Col md={3} className="d-flex justify-content-center">
                        <Card className="custom-card" style={{ backgroundColor: "green" }} >
                          <Link to="/projectportal/dashboard">
                            <CardBody className='d-flex justify-content-center'>
                              <FontAwesomeIcon icon={faCode} color='white' style={{ height: "50px" }} />
                            </CardBody>
                            <CardFooter><CardTitle className='text-center' style={{ color: "green" }}>Project Portal</CardTitle></CardFooter>
                          </Link>
                        </Card>
                      </Col>

                    );
                  }
                  return null;
                })
              }

              {userAppDetails && userAppDetails
                .filter(item => item.appName.toLowerCase() === "massmailer")
                .map(userApp => {
                  const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                  if (allowedModulesRoles.length > 0) {
                    return (
                      <Col md={3} className="d-flex justify-content-center">
                        <Card className="custom-card" style={{ backgroundColor: "darkred" }} >
                          <Link to="/massmailer/dashboard">
                            <CardBody className='d-flex justify-content-center'>
                              <FontAwesomeIcon icon={faEnvelope} color='white' style={{ height: "50px" }} />
                            </CardBody>
                            <CardFooter><CardTitle className='text-center' style={{ color: "darkred" }}>Mass Mailer</CardTitle></CardFooter>
                          </Link>
                        </Card>
                      </Col>

                    );
                  }
                  return null;
                })
              }

              {userAppDetails && userAppDetails
                .filter(item => item.appName.toLowerCase() === "db sync")
                .map(userApp => {
                  const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => dbSyncAllowedRoles.includes(role.roleName.toLowerCase()));
                  if (allowedModulesRoles.length > 0) {
                    return (
                      <Col md={3} className="d-flex justify-content-center">
                        <Card className="custom-card" style={{ backgroundColor: "#f23e18" }} >
                          <Link to="/dbsync/list">
                            <CardBody className='d-flex justify-content-center'>
                              <FontAwesomeIcon icon={faCopy} color='white' style={{ height: "50px" }} />
                            </CardBody>
                            <CardFooter><CardTitle className='text-center' style={{ color: "#f23e18" }}>DB Sync</CardTitle></CardFooter>
                          </Link>
                        </Card>
                      </Col>
                    );
                  }
                  return null;
                })
              }

              {userAppDetails && userAppDetails
                .map(item => item["appName"]).find((item) => item.toLowerCase() === "vision docs") &&
                (
                  <Col md={3} className="d-flex justify-content-center">
                    <Card className="custom-card" style={{ backgroundColor: "orange" }} >
                      <Link to="/visiondocs/documents">
                        <CardBody className='d-flex justify-content-center'>
                          <FontAwesomeIcon icon={faFileWord} color='white' style={{ height: "50px" }} />
                        </CardBody>
                        <CardFooter><CardTitle className='text-center' style={{ color: "orange" }}>Vision Docs</CardTitle></CardFooter>
                      </Link>
                    </Card>
                  </Col>
                )
              }

              {
                (userAppDetails && userAppDetails
                  .map(item => item["appName"]).find((item) => item.toLowerCase() === "crm") &&
                  <Col md={3} className="d-flex justify-content-center">
                    <Card className="custom-card" style={{ backgroundColor: "#e85c90" }} >
                      <Link to="/crm/dashboard">
                        <CardBody className='d-flex justify-content-center'>
                          <FontAwesomeIcon icon={faHeadset} color='white' style={{ height: "50px" }} />
                        </CardBody>
                        <CardFooter><CardTitle className='text-center' style={{ color: "#e85c90" }}> CRM</CardTitle></CardFooter>
                      </Link>
                    </Card>
                  </Col>
                )
              }

            </Row>
            <div className='d-flex justify-content-center pt-4'>
              <div className='dashboard-footer text-white'>
                <Container fluid={true}>
                  {new Date().getFullYear()} &copy; {window.__ENV && window.__ENV.credits}
                </Container>
              </div>
            </div>
          </Container >
        </>
      )}
    </>

  )

};

const mapStateToProps = (state) => ({
  profile: state.login.currentProfile,
  currentProfileErrorMessage: state.login.currentProfileErrorMessage,
  isPending: state.login.isPending,
  isAuth: state.login.isAuth,
  OrgLogoDetails: state.OrgPortalManagement.OrgLogoDetails,
})
const mapDispatchToProps = (dispatch) => ({
  userLogin: () => dispatch(userLogin()),
  getCurrentProfile: () => dispatch(getCurrentProfile()),
  getPendingSubmittedCount: () => dispatch(getPendingSubmittedCount()),
  getTimesheetApprovalCount: () => dispatch(getTimesheetApprovalCount()),
  sendToggleView: (toggleView) => dispatch(sendToggleView(toggleView)),
  sendToggleViewDocs: (toggleView) => dispatch(sendToggleViewDocs(toggleView)),
  clearAllDocsList: () => dispatch(clearAllDocsList()),
  ClearPath: () => dispatch(clearSetPath()),
  logoutUser: () => dispatch(logoutUser()),
  getOrganizationLogo: (orgId) => dispatch(getOrganizationLogo(orgId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

