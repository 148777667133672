import React, { useEffect, useState } from 'react'
import VWMessage from '../../../../../../components/vwMessage/vwMessage'
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { connect } from 'react-redux';
import { clearCreateVisitTypeError, createNewVisitType, createVisitTypeSuccess } from '../../../../../../store/Modules/crm/Settings/VisitType/actions';

const AddVisit = (props) => {

    const { isVisitTypeCreatePending, errorMessage } = props;
    const [visitTypeDetails, setVisitTypeDetails] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        props.clearCreateVisitTypeError();
        props.createVisitTypeSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setVisitTypeDetails({
            ...visitTypeDetails,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: null
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { visitTypeName } = visitTypeDetails || {};

        if (!visitTypeName) {
            currentErrors.visitTypeName = 'Visit type is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createVisitType(visitTypeDetails)
        }
    }
    console.log("errorMessage:", errorMessage);

    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label>Visit Type  <span className='text-danger'>*</span></Label>
                <Input
                    type="text"
                    name="visitTypeName"
                    placeHolder="Enter visit type"
                    onChange={validateOnchange}
                    value={visitTypeDetails && visitTypeDetails.visitTypeName}
                    invalid={errors && errors.visitTypeName}
                />
                <FormFeedback>{errors && errors.visitTypeName}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isVisitTypeCreatePending}
                >
                    {isVisitTypeCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    isVisitTypeCreatePending: state.VisitType.isVisitTypeCreatePending,
    errorMessage: state.VisitType.visitTypeCreateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    createVisitType: (visitTypeDetails) => dispatch(createNewVisitType(visitTypeDetails)),
    clearCreateVisitTypeError: () => dispatch(clearCreateVisitTypeError()),
    createVisitTypeSuccess: () => dispatch(createVisitTypeSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddVisit)