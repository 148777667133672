import { getToken, modifyExistingProjectUserList, modifyProjectList } from "../../../../helper/common"
import trimmedValues from "../../../../helper/trim"
import { clearForm } from "../../../form/actions"
import agent from "../../../services/agent"
import { showSnackbarStatus } from "../../../snackbar/actions"
import { getProjectDetails } from "../Projects/actions"
import { closeMilestoneCreate, getMilestoneSelectAction } from "../Task/actions"
import { CLEAR_CREATED_MIESTONE_NAME, CLEAR_MILESTONE_DETAILS, CLEAR_MILESTONE_DETAILS_ERROR, CLEAR_MILESTONE_ERROR, CLEAR_MILESTONE_EXPORT_LIST_ERROR, CLEAR_MILESTONE_LIST, CLEAR_MILESTONE_LIST_ERROR, CLEAR_MILESTONE_LISTS, CLEAR_MILESTONE_PROJECT_DATE, CLEAR_MILESTONE_PROJECT_DATES_ERROR, CLEAR_PROJECT_MILESTONE_LIST_ERROR, CLEAR_STATUS_UPDATE_MILESTONE_ERROR, CLEAR_UPDATE_MILESTONE_ERROR, CLOSE_CLONE_MILESTONE_MODAL, CLOSE_CREATE_MILESTONE_MODAL, CLOSE_EDIT_MILESTONE_MODAL, CREATE_MILESTONE_ERROR, CREATE_MILESTONE_PENDING, CREATE_MILESTONE_SUCCESS, CREATED_MILESTONE_NAME, IS_STATUS_MILESTONE_EDIT, IS_STATUS_MILESTONE_EDIT_CLOSE, IS_STATUS_MILESTONE_EDIT_OPEN, IS_STATUS_TASK_EDIT_OPEN, MILESTONE_EXPORT_LIST_ERROR, MILESTONE_EXPORT_SUCCESS, MILESTONE_LIST_ERROR, OPEN_CLONE_MILESTONE_MODAL, OPEN_CREATE_MILESTONE_MODAL, OPEN_EDIT_MILESTONE_MODAL, PROJECT_MILESTONE_LIST_ERROR, SET_MILESTONE_DETAILS, SET_MILESTONE_DETAILS_ERROR, SET_MILESTONE_DETAILS_PENDING, SET_MILESTONE_EXPORT_LIST, SET_MILESTONE_EXPORT_PENDING, SET_MILESTONE_LIST, SET_MILESTONE_PENDING, SET_MILESTONE_PROJECT_DATE_ERROR, SET_MILESTONE_PROJECT_DATES, SET_MILESTONE_PROJECT_DATES_PENDING, SET_PROJECT_MILESTONE_LIST, SET_PROJECT_MILESTONE_PENDING, UPDATE_MILESTONE_ERROR, UPDATE_MILESTONE_PENDING, UPDATE_MILESTONE_SUCCESS, UPDATE_STATUS_MILESTONE_ERROR, UPDATE_STATUS_MILESTONE_PENDING, UPDATE_STATUS_MILESTONE_SUCCESS } from "./constants"

export const isStatusMilestoneEditOpen = (id) => ({
    type: IS_STATUS_MILESTONE_EDIT_OPEN,
    payload: { id }
})

export const openCreateMilestoneModalAction = () => ({
    type: OPEN_CREATE_MILESTONE_MODAL
})
export const closeCreateMilestoneModalAction = () => ({
    type: CLOSE_CREATE_MILESTONE_MODAL
})
export const openEditMilestoneModalAction = () => ({
    type: OPEN_EDIT_MILESTONE_MODAL
})
export const openCloneMilestoneModalAction = () => ({
    type: OPEN_CLONE_MILESTONE_MODAL
})
export const closeCloneMilestoneModal = () => ({
    type: CLOSE_CLONE_MILESTONE_MODAL
})

export const closeEditMilestoneModalAction = () => ({
    type: CLOSE_EDIT_MILESTONE_MODAL
})

export const createMilestoneError = (errorMessage) => ({
    type: CREATE_MILESTONE_ERROR,
    payload: { errorMessage },
});

export const createMilestonePending = () => ({
    type: CREATE_MILESTONE_PENDING,
});
export const clearCreateMilestoneError = () => ({
    type: CLEAR_MILESTONE_ERROR,
});
export const createMilestoneSuccess = () => ({
    type: CREATE_MILESTONE_SUCCESS,
});
export const setMilestonePending = () => ({
    type: SET_MILESTONE_PENDING
})
export const setMilestoneExportPending = () => ({
    type: SET_MILESTONE_EXPORT_PENDING
})
export const setProjectMilestonePending = () => ({
    type: SET_PROJECT_MILESTONE_PENDING
})
export const setMilestoneDetailsPending = () => ({
    type: SET_MILESTONE_DETAILS_PENDING
})
export const setMilestoneProjectDatePending = () => ({
    type: SET_MILESTONE_PROJECT_DATES_PENDING
})
export const setMilestoneList = (milestoneList) => ({
    type: SET_MILESTONE_LIST,
    payload: { milestoneList }
})
export const clearMilestoneLists = () => ({
    type: CLEAR_MILESTONE_LISTS,
})
export const setMilestoneExportSuccess = () => ({
    type: MILESTONE_EXPORT_SUCCESS,
})
export const setProjectMilestoneLists = (milestoneList) => ({
    type: SET_PROJECT_MILESTONE_LIST,
    payload: { milestoneList }
})
export const clearMilestoneList = () => ({
    type: CLEAR_MILESTONE_LIST
})
export const clearMilestoneListError = () => ({
    type: CLEAR_MILESTONE_LIST_ERROR
})
export const clearMilestoneExportListError = () => ({
    type: CLEAR_MILESTONE_EXPORT_LIST_ERROR
})
export const clearProjectMilestoneListError = () => ({
    type: CLEAR_PROJECT_MILESTONE_LIST_ERROR
})
export const milestoneListError = (errorMessage) => ({
    type: MILESTONE_LIST_ERROR,
    payload: { errorMessage },
})
export const milestoneExportListError = (errorMessage) => ({
    type: MILESTONE_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const projectMilestoneListError = (errorMessage) => ({
    type: PROJECT_MILESTONE_LIST_ERROR,
    payload: { errorMessage },
})
export const setMilestoneDetails = (milestoneDetails) => ({
    type: SET_MILESTONE_DETAILS,
    payload: { milestoneDetails },
})
export const setMilestoneProjectDetails = (ProjectDates) => ({
    type: SET_MILESTONE_PROJECT_DATES,
    payload: { ProjectDates },
})
export const clearMilestoneProjectDatesError = () => ({
    type: CLEAR_MILESTONE_PROJECT_DATES_ERROR,
})
export const clearMilestoneDetailsError = () => ({
    type: CLEAR_MILESTONE_DETAILS_ERROR,
})
export const clearMilestoneDetails = () => ({
    type: CLEAR_MILESTONE_DETAILS,
})
export const projectMilestoneError = (errorMessage) => ({
    type: SET_MILESTONE_DETAILS_ERROR,
    payload: { errorMessage },
})
export const MilestoneprojectDatesError = (errorMessage) => ({
    type: SET_MILESTONE_PROJECT_DATE_ERROR,
    payload: { errorMessage },
})
export const clearUpdateMilestoneError = () => ({
    type: CLEAR_UPDATE_MILESTONE_ERROR,
});
export const clearStatusUpdateMilestoneError = () => ({
    type: CLEAR_STATUS_UPDATE_MILESTONE_ERROR,
});
export const updateMilestonePending = () => ({
    type: UPDATE_MILESTONE_PENDING,
});
export const updateStatusMilestonePending = () => ({
    type: UPDATE_STATUS_MILESTONE_PENDING,
});
export const updateMilestoneSuccess = () => ({
    type: UPDATE_MILESTONE_SUCCESS,
});
export const updateStatusMilestoneSuccess = () => ({
    type: UPDATE_STATUS_MILESTONE_SUCCESS,
});
export const ClearProjectDate = () => ({
    type: CLEAR_MILESTONE_PROJECT_DATE,
});
export const updateMilestoneError = (errorMessage) => ({
    type: UPDATE_MILESTONE_ERROR,
    payload: { errorMessage },
});
export const updateStatusMilestoneError = (errorMessage) => ({
    type: UPDATE_STATUS_MILESTONE_ERROR,
    payload: { errorMessage },
});
export const createdMilestoneName = (createdMilestoneName) => ({
    type: CREATED_MILESTONE_NAME,
    payload: { createdMilestoneName },
});
export const ClearMilestoneName = () => ({
    type: CLEAR_CREATED_MIESTONE_NAME,
});
export const getMilestoneExportList = (searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive) => (dispatch) => {
    dispatch(setMilestoneExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.ProjectTracker.getMilestoneExportList(accessToken, searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive)
            .then((milestoneLists) => {
                dispatch(setMilestoneExportSuccess());
                // dispatch(clearMilestoneExportListError());
                return Promise.resolve(milestoneLists);
            })
            .catch((err) => {
                console.log('getMilestoneExportList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(milestoneExportListError(error))
            });
    });
}
export const getMilestoneList = (filter, page, PageSize, searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive) => (dispatch) => {
    dispatch(setMilestonePending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getMilestoneList(accessToken, filter, page, PageSize, searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive)
            .then((milestoneLists) => {
                dispatch(setMilestoneList(milestoneLists));
                dispatch(clearMilestoneListError());
            })
            .catch((err) => {
                console.log('getMilestoneList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(milestoneListError(error))
            });
    });
}
export const getProjectMilestoneList = (id, sortName, sortOrder, filter) => (dispatch) => {
    dispatch(setProjectMilestonePending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getProjectMilestoneList(accessToken, id, sortName, sortOrder, filter)
            .then((milestonesList) => {
                dispatch(setProjectMilestoneLists(milestonesList));
                dispatch(clearProjectMilestoneListError());
            })
            .catch((err) => {
                console.log('getProjectMilestoneList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(projectMilestoneListError(error))
            });
    })
}
export const getMilestoneDetails = (id) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        dispatch(setMilestoneDetailsPending());
        agent.ProjectTracker.getMilestonesDetails(accessToken, id)
            .then((milestoneDetails) => {
                dispatch(setMilestoneDetails(milestoneDetails))
                dispatch(clearMilestoneDetailsError());
            })
            .catch((err) => {
                console.log('getMilestoneDetails server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(projectMilestoneError(error))
            });
    })
}
export const getProjectDate = (id) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        dispatch(setMilestoneProjectDatePending());
        agent.ProjectTracker.getMilestonesProjectDate(accessToken, id)
            .then((ProjectDates) => {
                dispatch(setMilestoneProjectDetails(ProjectDates))
                dispatch(clearMilestoneProjectDatesError());
            })
            .catch((err) => {
                console.log('getProjectDate server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(MilestoneprojectDatesError(error))
            });
    })
}
export const getAdminProjectNameAsyncSelectAction = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getProjectName(accessToken, searchValue)
                .then((ProjectList) => {
                    const { value } = ProjectList;
                    console.log("value:", value);

                    const updatedProjectList = modifyProjectList(value);
                    console.log("updatedProjectList:", updatedProjectList);

                    //eslint-disable-next-line
                    callback && callback(updatedProjectList);
                })
                .catch((err) => {
                    console.log('getProjectNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getProjectNameAsyncSelect = (searchValue, callback, userId) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getProjectName(accessToken, searchValue)
                .then((ProjectList) => {
                    const { value } = ProjectList;
                    console.log("value:", value);
                    const Project = value && value.filter((data) => {
                        return data.ProjectManagerUserId === userId;
                    });
                    const updatedProjectList = modifyProjectList(Project);
                    console.log("updatedProjectList:", updatedProjectList);
                    //eslint-disable-next-line
                    callback && callback(updatedProjectList);
                })
                .catch((err) => {
                    console.log('getProjectNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };


export const CreateMilestone = (formFields, projId, filteredData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createMilestoneError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(createMilestonePending());
    dispatch(clearCreateMilestoneError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.createMilestone(accessToken, dataToSend)
            .then((cloneId) => {
                dispatch(createdMilestoneName({ Id: cloneId.id, MileStoneName: formFields.MileStoneName }))
                dispatch(createMilestoneSuccess());
                dispatch(clearForm());
                dispatch(closeCreateMilestoneModalAction());
                dispatch(getMilestoneList(filteredData));
                dispatch(closeMilestoneCreate());
                dispatch(closeCloneMilestoneModal());
                if (cloneId && cloneId.id) {
                    dispatch(getMilestoneDetails(cloneId && cloneId.id));
                }
                if (projId) {
                    dispatch(getProjectMilestoneList(projId));
                }
                if (formFields.ProjectId) {
                    dispatch(getProjectDetails(formFields.ProjectId));
                    dispatch(getMilestoneSelectAction(formFields.ProjectId));
                }
                dispatch(showSnackbarStatus('Milestone created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createMilestoneError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createMilestoneError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Create Milestone server error', err);
                dispatch(clearForm());
                dispatch(closeCreateMilestoneModalAction());
            });
    });
};

export const getMilestoneOwnerAsyncSelect = (projectId, searchValues, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getexistingProjectUserlist(accessToken, projectId, searchValues)
            .then((projectUsers) => {
                const { value } = projectUsers;
                const updatedProjectUserList = modifyExistingProjectUserList(value);
                callback && callback(updatedProjectUserList);
            })
            .catch((err) => {
                console.log('getMilestoneOwnerAsyncSelect  server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                callback && callback();
            });
    });
};

export const UpdateMilestone = (formFields) => (dispatch) => {
    const dataToSend = trimmedValues({
        ...formFields,
        Id: formFields.id || formFields.Id,
    })
    dispatch(clearUpdateMilestoneError());
    dispatch(updateMilestonePending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.editMilestone(accessToken, dataToSend)
            .then(() => {
                dispatch(updateMilestoneSuccess());
                dispatch(closeEditMilestoneModalAction());
                dispatch(getMilestoneDetails(formFields.id));
                dispatch(showSnackbarStatus('Milestone details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateMilestoneError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateMilestoneError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Edit Update server error', err);
                dispatch(closeEditMilestoneModalAction());
            });
    });
};
export const UpdateStatusMilestone = (formFields, sortName, sortOrder, filter, APIList) => (dispatch) => {
    console.log("APIList:", APIList);
    console.log("filter:", filter);

    const dataToSend = trimmedValues({
        ...formFields,
    })
    dispatch(clearStatusUpdateMilestoneError());
    dispatch(updateStatusMilestonePending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.editMilestoneStatus(accessToken, dataToSend)
            .then(() => {
                dispatch(isStatusMilestoneEditOpen(null));
                dispatch(updateStatusMilestoneSuccess());
                if (APIList === 'List') {
                    dispatch(getMilestoneList(filter)); // shown in milestone list.
                } else {
                    dispatch(getProjectMilestoneList(formFields.ProjectId, sortName, sortOrder, filter)); // shown in project details milestone list.
                }
                dispatch(showSnackbarStatus('Milestone status updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateStatusMilestoneError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateStatusMilestoneError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Edit Update server error', err);
            });
    });
};