import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearUpdateIndustryError,
    updateIndustry,
    updateIndustrySuccess
} from '../../../../../../store/Modules/crm/Settings/Industry/actions';

const EditIndustry = (props) => {
    const { industryDetails, isIndustryUpdatePending, errorMessage } = props;
    const { Id, Name, IsActive } = industryDetails || {};

    const [industryDetail, setIndustryDetail] = useState();
    const [Active, setActive] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        setIndustryDetail({
            ...industryDetail,
            id: Id,
            name: Name,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateIndustryError();
        props.updateIndustrySuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setIndustryDetail({
            ...industryDetail,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        })
    }
    const handleActiveStatusChange = () => {
        setIndustryDetail({
            ...industryDetail,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { name } = industryDetail || {};

        if (!name) {
            currentErrors.name = 'Industry type is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateIndustry(industryDetail)

        }
    }

    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label>Industry Type <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="name"
                        placeHolder="Enter industry type"
                        onChange={validateOnchange}
                        value={industryDetail && industryDetail.name}
                        invalid={errors && errors.name}
                    />
                    <FormFeedback>{errors && errors.name}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isIndustryUpdatePending}
                    >
                        {isIndustryUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    industryDetails: state.crmIndustry.industryDetails,
    isIndustryUpdatePending: state.crmIndustry.isIndustryUpdatePending,
    errorMessage: state.crmIndustry.industryUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateIndustry: (industryDetail) => dispatch(updateIndustry(industryDetail)),
    clearUpdateIndustryError: () => dispatch(clearUpdateIndustryError()),
    updateIndustrySuccess: () => dispatch(updateIndustrySuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditIndustry);