import React from 'react'
import { connect } from 'react-redux'

const GeneralTaskImageModal = (props) => {
    console.log("imgIndex:", props.imgIndex)
    const index = props.imgIndex

    console.log("props.GeneralTaskLists:", props.GeneralTaskLists);
    const { ImageData } = props.GeneralTaskLists[index];
    console.log("index:", index);
    console.log("ImageData:", ImageData);

    return (
        <>
            {/* <div className='d-flex justify-content-center'> */}

            <img
                src={ImageData}
                width="100%"
                // src={props.GeneralTaskLists.props.imgIndex.ImageData}
                alt
            />
            {/* </div> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    GeneralTaskLists: state.GeneralTasks.GeneralTaskLists,

    imgIndex: state.GeneralTasks.imgIndex,

})

export default connect(mapStateToProps, null)(GeneralTaskImageModal)