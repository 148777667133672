import { FORM_CLEAR } from "./actionTypes";

const initialState = {};

const formReducer = (
  localState=initialState,
  action: any
)=> {
  switch (action.type) {
    case "FORM_UPDATE":
      if (action.payload)
        return {
          ...localState,
          ...action.payload,
        };
      return initialState;
    case FORM_CLEAR:
      return localState;
    default:
      return localState;
  }
};
export default formReducer;

// const initialState = {
//   users: {
//     Addform: {},
//     // other user data
//   },
// };

//  const userReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case "UPDATE_USERS_DATA":
//       return {
//         ...state,
//         users: {
//           ...state.users,
//           ...action.payload,
//         },
//       };
//     // other cases
//     default:
//       return state;
//   }
// };
// export default userReducer;