const settingsSidebarData = [

    {
        label: "Tasks",
        icon: "mdi mdi mdi-briefcase-outline",
        isHasArrow: true,
        url: "/projectportal/settings/tasks/task-scope",
        issubMenubadge: true,
        subItem: [
            {
                label: "Task Scope",
                parent: "Tasks",
                icon: "mdi mdi-briefcase-account-outline",
                isHasArrow: true,
                url: "/projectportal/settings/tasks/task-scope",
            },
            {
                label: "Task Type",
                parent: "Tasks",
                icon: "mdi mdi-format-list-bulleted-type",
                isHasArrow: true,
                url: "/projectportal/settings/tasks/task-type",
            },
            {
                label: "Task Status Percentage",
                parent: "Tasks",
                icon: "mdi mdi-percent",
                isHasArrow: true,
                url: "/projectportal/settings/tasks/task-status-percentage",
            },
        ]
    },


]
export { settingsSidebarData };