import React from "react";
import './style.css'
// import logo from './only-switch-icon.png';
import logo from '../../assets/images/VizoLogo-Photoroom (1).png';

const VWSpinner = (props) => {
    const { width, height } = props;
    return (
        <div className="spinner-container" style={{
            position: 'relative',
            width: width ? `${width + 30}px` : '60px',
            height: height ? `${height + 30}px` : '60px',

        }}>
            <div className="spinner"></div>
            <img src={logo} alt="Icon" className="icon" style={{
                width: width ? `${width}px` : '40px',
                height: height ? `${height}px` : '40px'
            }} />
        </div>
    )
}

export default VWSpinner;