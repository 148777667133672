import { SET_VISIT_TYPE_DETAILS, VISIT_TYPE_CREATE_CLOSE, VISIT_TYPE_CREATE_ERROR, VISIT_TYPE_CREATE_ERROR_CLEAR, VISIT_TYPE_CREATE_OPEN, VISIT_TYPE_CREATE_PENDING, VISIT_TYPE_CREATE_SUCCESS, VISIT_TYPE_LIST, VISIT_TYPE_LIST_ERROR, VISIT_TYPE_LIST_ERROR_CLEAR, VISIT_TYPE_LIST_PENDING, VISIT_TYPE_UPDATE_CLOSE, VISIT_TYPE_UPDATE_ERROR, VISIT_TYPE_UPDATE_ERROR_CLEAR, VISIT_TYPE_UPDATE_OPEN, VISIT_TYPE_UPDATE_PENDING, VISIT_TYPE_UPDATE_SUCCESS } from "./constants";

const initialState = {
    //--------------------VISIT TYPE-CREATE-----------------------------------
    isCreateVisitTypeOpen: false,
    visitTypeCreateErrorMessage: null,
    isVisitTypeCreateSuccess: false,
    isVisitTypeCreatePending: false,

    //--------------------VISIT TYPE -LIST-------------------------------------
    visitTypeList: [],
    visitTypeListCount: 0,
    isVisitTypeListPending: false,
    visitTypeListErrorMessage: null,

    //--------------------REGION-UPDATE-----------------------------------
    visitTypeDetails: null,
    isUpdateVisitTypeOpen: false,
    visitTypeUpdateErrorMessage: null,
    isVisitTypeUpdateSuccess: false,
    isVisitTypeUpdatePending: false,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------VISIT TYPE-CREATE-----------------------------------
        case VISIT_TYPE_CREATE_OPEN: {
            return {
                ...localState,
                isCreateVisitTypeOpen: true
            }
        }
        case VISIT_TYPE_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateVisitTypeOpen: false
            }
        }
        case VISIT_TYPE_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                visitTypeCreateErrorMessage: null,
            }
        }
        case VISIT_TYPE_CREATE_PENDING: {
            return {
                ...localState,
                isVisitTypeCreatePending: true,
            }
        }
        case VISIT_TYPE_CREATE_SUCCESS: {
            return {
                ...localState,
                isVisitTypeCreateSuccess: true,
                isVisitTypeCreatePending: false
            }
        }
        case VISIT_TYPE_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                visitTypeCreateErrorMessage: errorMessage,
                isVisitTypeCreatePending: false,
            }
        }

        //---------------------VISIT TYPE LIST----------------------------------------
        case VISIT_TYPE_LIST: {
            const { visitTypeList } = action.payload;
            return {
                ...localState,
                visitTypeList: visitTypeList && visitTypeList['value'],
                visitTypeListCount: visitTypeList && visitTypeList['@odata.count'],
                isVisitTypeListPending: false,
                visitTypeListErrorMessage: null,
            }
        }
        case VISIT_TYPE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                visitTypeListErrorMessage: errorMessage,
                isVisitTypeListPending: false,
                visitTypeList: []
            }
        }
        case VISIT_TYPE_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                visitTypeListErrorMessage: null,
            }
        }
        case VISIT_TYPE_LIST_PENDING: {
            return {
                ...localState,
                isVisitTypeListPending: true,
            }
        }
        //--------------------VISIT TYPE-UPDATE-----------------------------------
        case SET_VISIT_TYPE_DETAILS: {
            const { visitTypeDetails } = action.payload;
            return {
                ...localState,
                visitTypeDetails: visitTypeDetails
            }
        }
        case VISIT_TYPE_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateVisitTypeOpen: true
            }
        }
        case VISIT_TYPE_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateVisitTypeOpen: false
            }
        }
        case VISIT_TYPE_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                visitTypeUpdateErrorMessage: null,
            }
        }
        case VISIT_TYPE_UPDATE_PENDING: {
            return {
                ...localState,
                isVisitTypeUpdatePending: true,
            }
        }
        case VISIT_TYPE_UPDATE_SUCCESS: {
            return {
                ...localState,
                isVisitTypeUpdateSuccess: true,
                isVisitTypeUpdatePending: false
            }
        }
        case VISIT_TYPE_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                visitTypeUpdateErrorMessage: errorMessage,
                isVisitTypeUpdatePending: false
            }
        }
        default: {
            return localState;
        }
    }
};