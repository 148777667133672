import { DASHBOARD_TIMESHEETS_CLEAR_ERROR, DASHBOARD_TIMESHEETS_ERROR_MESSAGE, SET_DASHBOARD, SET_TIMESHEETS_DASHBOARD_PENDING } from "./constants";

const initialState = {
    DashboardCounts: {},
    isDashboardTimesheetsPending: false,
    dashboardTimesheetsErrorMessage: null,
};
export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_DASHBOARD: {
            const { dashboard } = action.payload;
            return {
                ...localState,
                DashboardCounts: dashboard,
                isDashboardTimesheetsPending: false,
            }
        }
        case DASHBOARD_TIMESHEETS_ERROR_MESSAGE: {
            const { dashboardTimesheetsErrorMessage } = action.payload;
            return {
                ...localState,
                isDashboardTimesheetsPending: false,
                dashboardTimesheetsErrorMessage: dashboardTimesheetsErrorMessage,
            }
        }
        case DASHBOARD_TIMESHEETS_CLEAR_ERROR: {
            return {
                ...localState,
                dashboardTimesheetsErrorMessage: null,
            }
        }

        case SET_TIMESHEETS_DASHBOARD_PENDING: {
            return {
                ...localState,
                dashboardTimesheetsErrorMessage: null,
                isDashboardTimesheetsPending: true
            }
        }

        default: {
            return localState;
        }
    }
};