/* eslint-disable no-lonely-if */
/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import agent from '../store/services/agent';
import { logoutUser } from '../store/actions';

export const setTokenDetails = (authData) => {
  console.log("authData:", authData);
  //eslint-disable-next-line
  const { access_token, expires_in, token_type, id_token, refresh_token } = authData;
  //eslint-disable-next-line
  const expires = moment().unix() + expires_in;
  localStorage.setItem("id_token", id_token);
  localStorage.setItem("accessToken", access_token);
  localStorage.setItem("expires_in", expires);
  localStorage.setItem("token_type", token_type);
  localStorage.setItem("refresh_token", refresh_token);
};



let isRefreshing = false;
let refreshQueue = [];

console.log("refreshQueue:", refreshQueue);
export const getToken = (dispatch) => {
  const Token = localStorage.getItem('id_token');
  const expiresIn = localStorage.getItem('expires_in');
  const refresh_token = localStorage.getItem('refresh_token');

  return new Promise((resolve, reject) => {
    const diff = moment.unix(Number(expiresIn)).diff(moment(), 'hours');
    // const diff = expiresIn;
    console.log("diff: ", diff);
    if (Token && diff > 5) {
      console.log("Id token working");
      resolve(Token);
    }
    else {
      if (!isRefreshing) {
        console.log("Regenerate Token:");
        isRefreshing = true;
        agent.Auth.getRefreshToken(refresh_token).then((resp) => {
          setTokenDetails(resp);
          isRefreshing = false;
          resolve(localStorage.getItem('id_token'));
          // Process the queued API calls
          while (refreshQueue.length > 0) {
            refreshQueue.shift()(localStorage.getItem('id_token'));
          }
        }).catch((err) => {
          console.log("Refresh Token Failure", err);
          isRefreshing = false;
          reject(err);
          dispatch && dispatch(logoutUser());
        });
      } else {
        // If a refresh is already in progress, queue the API call
        refreshQueue.push(resolve);
      }
    }
    // else {
    //   console.log("calling Refresh Token");
    //   agent.Auth.getRefreshToken(refresh_token).then((resp) => {
    //     console.log('refresh_token:', refresh_token);
    //     console.log("resp:", resp);
    //     setTokenDetails(resp);
    //     resolve(localStorage.getItem('id_token')); // resolve - id_token
    //   }).catch((err) => {
    //     console.log("Refresh Token failed Logout");
    //     console.log("error:", err.response);
    //     resolve(err)
    //     // eslint-disable-next-line no-unused-expressions
    //     dispatch && dispatch(logoutUser());

    //   })
    //   // console.log('getToken ', diff);
    //   // // eslint-disable-next-line no-unused-expressions
    //   // dispatch && dispatch(logoutUser());
    //   // resolve(Token);
    // }
  });
};

export const getPager = (totalItems, currentPage, pagesize) => {
  // default to first page
  currentPage = currentPage || 1;

  // default page size is 10
  pagesize = pagesize || 10;

  // calculate total pages
  const totalPages = Math.ceil(totalItems / pagesize);

  let startPage;
  let endPage;
  if (totalPages <= 10) {
    // less than 10 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // more than 10 total pages so calculate start and end pages
    if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pagesize;
  const endIndex = Math.min(startIndex + pagesize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  const pages = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);

  // return object with all pager properties required by the view

  return {
    totalItems,
    currentPage,
    pagesize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages,
  };
};

export const removeEmptyKeys = (obj) => {
  if (obj) {
    const keys = Object.keys(obj)?.filter((key) => obj[key] === null || obj[key] === undefined || obj[key] === "" || obj[key] === '');
    keys.forEach((key) => {
      delete obj[key];
    })
    return obj;
  }
}

export const modifyOrganisationsList = (organisationOdata: any) => {
  const { value } = organisationOdata;
  const organisationItems = [];
  value.forEach((organisation) => {
    const { name, id } = organisation;
    organisationItems.push({
      label: name,
      value: id,
    });
  });
  return organisationItems;
};

export const modifyUsersList = (users: any) => {
  const userItems = [];
  users.forEach((user) => {
    const { displayName, id, email } = user;
    userItems.push({
      label: `${displayName} | ${email}`,
      value: id,
    });
  });
  return userItems;
};

export const modifyDevicesList = (devices: any) => {
  const deviceItems = [];
  devices.forEach((device) => {
    const { name, id, deviceKey } = device;
    deviceItems.push({
      label: `${name} | ${deviceKey}`,
      value: id,
    });
  });
  return deviceItems;
};

export const modifyGroupsList = (groups: any) => {
  const groupItems = [];
  groupItems.unshift({
    value: null,
    label: "None",
  });
  groups.forEach((group) => {
    const { groupName, id } = group;
    groupItems.push({
      label: groupName,
      value: id,
    });
  });
  return groupItems;
};

export const modifyProductList = (products: any) => {
  const productItems = [];
  products.forEach((product) => {
    const { productName } = product;
    productItems.push({
      label: productName,
      value: productName,
    });
  });
  return productItems;
};

export const modifyZoneList = (zones: any) => {
  const zoneItems = [];
  zones.forEach((zone) => {
    const { zoneName } = zone;
    zoneItems.push({
      label: zoneName,
      value: zoneName,
    });
  });
  return zoneItems;
};

export const modifyStateList = (states: any) => {
  const stateItems = [];
  states.forEach((state) => {
    const { stateName } = state;
    stateItems.push({
      label: stateName,
      value: stateName,
    });
  });
  return stateItems;
};

export const modifyProjectList = (Project) => {
  const ProjectListItems = [];
  Project.forEach((item) => {
    const { Id, ProjectName, EndTimeStamp, StartTimeStamp, ProjectManagerUserId, AllowFlexibleDueDates } = item;
    ProjectListItems.push({
      label: ProjectName || 'N/A',
      value: Id,
      StartDate: StartTimeStamp || '',
      EndDate: EndTimeStamp || '',
      ProjectManagerUserId: ProjectManagerUserId || '',
      AllowFlexibleDueDates: AllowFlexibleDueDates || ''
    })
  })
  return ProjectListItems;
}
export const modifyTaskTypeList = (TaskType) => {
  const TaskTypeListItems = [];
  TaskType.forEach((item) => {
    const { Id, TypeName } = item;
    TaskTypeListItems.push({
      label: TypeName || 'N/A',
      value: Id
    })
  })
  return TaskTypeListItems;
}
export const modifyTaskStatusPercentageList = (TaskStatus) => {
  const TaskStatuspercentageListItems = [];
  TaskStatus.forEach((item) => {
    const { Id, StatusName, Percentage } = item;
    TaskStatuspercentageListItems.push({
      label: `${StatusName || 'N/A'} - ${Percentage || 0}%`,
      value: StatusName,
      Percentage: Percentage
    })
  })
  return TaskStatuspercentageListItems;
}
export const modifyTaskStatusList = (TaskStatus) => {
  const TaskStatuspercentageListItems = [];
  TaskStatus.forEach((item) => {
    const { Id, StatusName, Percentage } = item;
    TaskStatuspercentageListItems.push({
      label: StatusName || 'N/A',
      value: StatusName,
      Percentage: Percentage
    })
  })
  return TaskStatuspercentageListItems;
}
export const modifyTaskScopeList = (Taskscope) => {
  const TaskScopeListItems = [];
  Taskscope.forEach((item) => {
    const { Id, ScopeName } = item;
    TaskScopeListItems.push({
      label: ScopeName || 'N/A',
      value: Id
    })
  })
  return TaskScopeListItems;
}
export const modifyTaskList = (Task) => {
  const TaskListItems = [];
  Task.forEach((item) => {
    const { Id, TaskName } = item;
    TaskListItems.push({
      label: TaskName || 'N/A',
      value: Id
    })
  })
  return TaskListItems;
}
export const modifyLeaveTypeList = (LeaveType) => {
  const LeaveTypeListItems = [];
  LeaveType.forEach((item) => {
    if (item.IsEnable) {
      const { Id, LeavetypeName } = item;
      LeaveTypeListItems.push({
        label: LeavetypeName,
        value: Id
      })
    }
  })
  return LeaveTypeListItems;
}
export const modifyLeaveTypeListLeaveMaster = (LeaveType) => {
  const LeaveTypeListItems = [];
  LeaveType.forEach((item) => {
    const { Id, LeavetypeName } = item;
    LeaveTypeListItems.push({
      label: LeavetypeName,
      value: Id
    })
  })
  return LeaveTypeListItems;
}
export const modifyEnableOrgLeaveTypeList = (LeaveType) => {
  const LeaveTypeListItems = [];
  LeaveType.forEach((item) => {
    const { LeavetypeId, LeavetypeName } = item;
    LeaveTypeListItems.push({
      label: LeavetypeName,
      value: LeavetypeId
    })
  })
  return LeaveTypeListItems;
}
export const modifyOrgLeaveTypeList = (LeaveType) => {
  const LeaveTypeListItems = [];
  LeaveType.forEach((item) => {
    if (item.IsEnable) {
      const { LeavetypeId, LeavetypeName } = item;
      LeaveTypeListItems.push({
        label: LeavetypeName,
        value: LeavetypeId
      })
    }
  })
  return LeaveTypeListItems;
}
export const modifyAppTypeList = (appType) => {
  const AppListItems = [];
  appType.forEach((item) => {
    const { Id, AppName } = item;
    AppListItems.push({
      label: AppName,
      value: Id
    })
  })
  return AppListItems;
}
export const modifyOrgListList = (OrgNameType) => {
  const OrgNameListItems = [];
  OrgNameType.forEach((item) => {
    const { Id, OrgName } = item;
    OrgNameListItems.push({
      label: OrgName,
      value: Id
    })
  })
  return OrgNameListItems;
}
export const modifyRoleList = (RoleType) => {
  const RoleListItems = [];
  RoleType.forEach((item) => {
    const { Id, RoleName } = item;
    RoleListItems.push({
      label: RoleName,
      value: Id
    })
  })
  return RoleListItems;
}
export const modifyAppList = (AppType) => {
  const AppListItems = [];
  AppType.forEach((item) => {
    const { Id, AppName, IsActive } = item;
    if (IsActive) {
      AppListItems.push({
        label: AppName,
        value: Id
      })
    }
  })
  return AppListItems;
}
export const modifyUserList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, DisplayName, UserEmail, IsGlobalReadOnlyUser, FirstName, LastName, EnableMailNotification } = item;
    UserListItems.push({
      label: IsGlobalReadOnlyUser ? DisplayName : `${DisplayName} | ${UserEmail}`,
      value: Id,
      LastName: LastName,
      FirstName: FirstName,
      LastName: LastName,
      UserEmail: UserEmail,
      EnableMailNotification: EnableMailNotification,
    })
  })
  return UserListItems;
}
export const modifyCompanyNameList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, LeadName } = item;
    UserListItems.push({
      label: LeadName || 'N/A',
      value: Id
    })
  })
  return UserListItems;
}
export const modifyIndustryNameList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, Name } = item;
    UserListItems.push({
      label: Name || 'N/A',
      value: Id
    })
  })
  return UserListItems;
}
export const modifyAccountCategory = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, TypeName, Range } = item;
    UserListItems.push({
      label: `${TypeName || 'N/A'} | ${Range || 'N/A'}`,
      value: Id
    })
  })
  return UserListItems;
}
export const modifyAccountCategoryList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, TypeName } = item;
    UserListItems.push({
      label: TypeName || 'N/A',
      value: Id
    })
  })
  return UserListItems;
}
export const modifyAccountCategoryValue = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, Range } = item;
    UserListItems.push({
      label: Range || 'N/A',
      value: Id
    })
  })
  return UserListItems;
}
export const modifyRegionList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, RegionName } = item;
    UserListItems.push({
      label: RegionName || 'N/A',
      value: Id
    })
  })
  return UserListItems;
}
export const modifyUserLists = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, UserName, UserEmail, IsGlobalReadOnlyUser } = item;
    UserListItems.push({
      label: IsGlobalReadOnlyUser ? UserName : `${UserName} | ${UserEmail}`,
      value: Id,
      IsGlobalReadOnlyUser: IsGlobalReadOnlyUser
    })
  })
  return UserListItems;
}
export const modifyEmployeeList = (EmployeeType) => {
  const EmployeeListItems = [];
  EmployeeType.forEach((item) => {
    const { Id, DisplayName, UserEmail } = item;
    EmployeeListItems.push({
      label: `${DisplayName} | ${UserEmail}`,
      value: Id
    })
  })
  return EmployeeListItems;
}
export const modifyEmployeeManagerList = (EmployeeType) => {
  const EmployeeListItems = [{ label: 'Managerless Employees', value: 'N/A' }];
  EmployeeType.forEach((item) => {
    const { Id, DisplayName, UserEmail } = item;
    EmployeeListItems.push({
      label: `${DisplayName} | ${UserEmail}`,
      value: Id
    })
  })
  return EmployeeListItems;
}
export const modifyProjectDropDownUserList = (EmployeeType) => {
  const EmployeeListItems = [];
  EmployeeType.forEach((item) => {
    const { Id, UserName, UserEmail } = item;
    EmployeeListItems.push({
      label: `${UserName} | ${UserEmail}`,
      value: Id,
      assignedUserName: UserName,
      assignedUserEmail: UserEmail,
    })
  })
  return EmployeeListItems;
}
export const modifyProjectDropDownList = (EmployeeType) => {
  const EmployeeListItems = [{ label: 'Open Tasks', value: 'N/A' }];
  EmployeeType.forEach((item) => {
    const { Id, UserName, UserEmail } = item;
    EmployeeListItems.push({
      label: `${UserName} | ${UserEmail}`,
      value: Id,
      assignedUserName: UserName,
      assignedUserEmail: UserEmail,
    })
  })
  return EmployeeListItems;
}
export const modifyProjectUserList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { AssignedUserId, AssignedUserName, AssignedUserEmail, IsUserAdmin, IsGlobalReadOnlyUser } = item;
    UserListItems.push({
      label: IsGlobalReadOnlyUser ? AssignedUserName : `${AssignedUserName || 'N/A'} | ${AssignedUserEmail || 'N/A'}`,
      value: AssignedUserId,
      assignedUserName: AssignedUserName,
      assignedUserEmail: AssignedUserEmail,
      isUserAdmin: IsUserAdmin,
      IsGlobalReadOnlyUser: IsGlobalReadOnlyUser
    })
  })
  return UserListItems;
}
export const modifyProjectNonUserList = (userType) => {
  const UserListItems = [{ label: 'Open Tasks', value: 'N/A' }];
  userType.forEach((item) => {
    const { AssignedUserId, AssignedUserName, AssignedUserEmail, IsUserAdmin, IsGlobalReadOnlyUser } = item;
    UserListItems.push({
      label: IsGlobalReadOnlyUser ? AssignedUserName : `${AssignedUserName || 'N/A'} | ${AssignedUserEmail || 'N/A'}`,
      value: AssignedUserId,
      assignedUserName: AssignedUserName,
      assignedUserEmail: AssignedUserEmail,
      isUserAdmin: IsUserAdmin,
      IsGlobalReadOnlyUser: IsGlobalReadOnlyUser
    })
  })
  return UserListItems;
}
export const modifyProjectUsersList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { AssignedUserId, AssignedUserName, AssignedUserEmail } = item;
    UserListItems.push({
      label: `${AssignedUserName || 'N/A'} | ${AssignedUserEmail || 'N/A'}`,
      value: AssignedUserId
    })
  })
  return UserListItems;
}
export const modifydepenciesTaskList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, TaskName } = item;
    UserListItems.push({
      label: TaskName || 'N/A',
      value: Id
    })
  })
  return UserListItems;
}
export const modifyMilestoneList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, IsMilestoneOwnerUpdateStatus, MilestoneName, isCurrentUserManagerOrMilestoneOwner, MilestoneError, AllowFlexibleDueDates, IsUserUpdateStatus } = item;
    UserListItems.push({
      label: MilestoneName || 'N/A',
      value: Id,
      isCurrentUserManagerOrMilestoneOwner: isCurrentUserManagerOrMilestoneOwner,
      MilestoneError: MilestoneError,
      AllowFlexibleDueDates: AllowFlexibleDueDates,
      IsUserUpdateStatus: IsUserUpdateStatus,
      IsMilestoneOwnerUpdateStatus: IsMilestoneOwnerUpdateStatus
    })
  })
  return UserListItems;
}
export const modifyExistingProjectUserList = (userType) => {
  const ProjectUserListItems = [];
  userType.forEach((item) => {
    const { AssignedUserId, AssignedUserName, AssignedUserEmail } = item;
    ProjectUserListItems.push({
      label: `${AssignedUserName || 'N/A'} | ${AssignedUserEmail || 'N/A'}`,
      value: AssignedUserId
    })
  })
  return ProjectUserListItems;
}
export const modifyLeavetypeMasterList = (userType) => {
  const LeavetypeMasterListItems = [];
  userType.forEach((item) => {
    const { Id, LeaveType } = item;
    LeavetypeMasterListItems.push({
      label: LeaveType,
      value: Id
    })
  })
  return LeavetypeMasterListItems;
}

export const modifyCrmAccountList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, CompanyName } = item;
    UserListItems.push({
      label: CompanyName || 'N/A',
      value: Id
    })
  })
  return UserListItems;
}
export const modifyCrmUserList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, DisplayName, UserEmail } = item;
    UserListItems.push({
      label: `${DisplayName} | ${UserEmail || 'N/A'}`,
      value: Id
    })
  })
  return UserListItems;
}

export const modifyCrmLeadCategoryList = (categoryList) => {
  const categoryListItems = [];
  categoryList.forEach((item) => {
    const { Id, LeadCategoryName } = item;
    categoryListItems.push({
      label: `${LeadCategoryName || 'N/A'}`,
      value: Id
    })
  })
  return categoryListItems;
}
export const modifyCrmLeadSourceList = (categoryList) => {
  const categoryListItems = [];
  categoryList.forEach((item) => {
    const { Id, LeadSourceName } = item;
    categoryListItems.push({
      label: `${LeadSourceName || 'N/A'}`,
      value: Id
    })
  })
  return categoryListItems;
}
export const modifyCrmFollowUpActionList = (categoryList) => {
  const categoryListItems = [];
  categoryList.forEach((item) => {
    const { Id, FollowUpActionName } = item;
    categoryListItems.push({
      label: `${FollowUpActionName || 'N/A'}`,
      value: Id
    })
  })
  return categoryListItems;
}
export const modifyContactsUserList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, FirstName, ContactEmail, DisplayName } = item;
    UserListItems.push({
      label: `${FirstName || 'N/A'} | ${ContactEmail || 'N/A'}`,
      value: Id,
      ContactEmail: ContactEmail,
      DisplayName: DisplayName
    })
  })
  return UserListItems;
}
export const modifyTypeList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, OppTypeName } = item;
    UserListItems.push({
      label: OppTypeName || 'N/A',
      value: Id
    })
  })
  return UserListItems;
}
export const modifyActionList = (userType) => {
  const UserListItems = [];
  userType.forEach((item) => {
    const { Id, OppActionName } = item;
    UserListItems.push({
      label: OppActionName || 'N/A',
      value: Id
    })
  })
  return UserListItems;
}
export const modifyCurrentPhaseList = (userType) => {
  const CurrentListItems = [];
  userType.forEach((item) => {
    const { Id, PhaseName, Value } = item;
    CurrentListItems.push({
      label: `${PhaseName || 'N/A'} - ${Value || 0}%`,
      value: Id
    })
  })
  return CurrentListItems;
}
export const modifyMainCategoryList = (userType) => {
  const MainCategoryListItems = [];
  userType.forEach((item) => {
    const { OppCategory } = item;
    MainCategoryListItems.push({
      label: OppCategory || 'N/A',
      value: OppCategory
    })
  })
  return MainCategoryListItems;
}
export const modifySubCategoryList = (userType) => {
  const MainCategoryListItems = [];
  userType.forEach((item) => {
    const { Id, OppSubCategory } = item;
    MainCategoryListItems.push({
      label: OppSubCategory || 'N/A',
      value: Id
    })
  })
  return MainCategoryListItems;
}

export const modifyFromAddressList = (fromAddressList) => {
  const fromAddressListItems = [];
  fromAddressList.forEach((item) => {
    const { Id, EmailAddress, FromDisplayName } = item;
    fromAddressListItems.push({
      label: `${FromDisplayName || 'N/A'} | ${EmailAddress || 'N/A'}`,
      value: Id
    })
  })
  return fromAddressListItems;
}
export const modifyVisitNameList = (visitNameList) => {
  const VisitNameListItems = [];
  visitNameList.forEach((item) => {
    const { Id, VisitSourceName, VisitSourceCategory, Address } = item;
    VisitNameListItems.push({
      label: `${VisitSourceCategory || 'N/A'} - ${VisitSourceName || 'N/A'}`,
      value: Id,
      sourceName: VisitSourceName,
      sourceCategory: VisitSourceCategory,
      Address: Address
    })
  })
  return VisitNameListItems;
}
export const modifyVisitTypeList = (VisitTypeList) => {
  const visitTypeListItems = [];
  VisitTypeList.forEach((item) => {
    const { Id, VisitTypeName } = item;
    visitTypeListItems.push({
      label: VisitTypeName || 'N/A',
      value: Id
    })
  })
  return visitTypeListItems;
}
export const modifyContactList = (VisitTypeList) => {
  const visitTypeListItems = [];
  VisitTypeList.forEach((item) => {
    const { Id, DisplayName, ContactEmail } = item;
    visitTypeListItems.push({
      label: `${DisplayName || 'N/A'} - ${ContactEmail || 'N/A'}`,
      value: Id
    })
  })
  return visitTypeListItems;
}