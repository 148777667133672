import { getToken, modifyOrgListList, modifyUserList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_LEAVE_MASTER_DETAILS_ERROR, CLEAR_LEAVE_MASTER_ERROR, CLEAR_LEAVE_MASTER_EXPORT_LIST_ERROR, CLEAR_LEAVE_MASTER_LIST_ERROR, CLEAR_UPDATE_LEAVE_MASTER_ERROR, CLOSE_CREATE_LEAVE_MASTER_MODAL, CLOSE_EDIT_LEAVE_MASTER_MODAL, CREATE_LEAVE_MASTER_ACTION_SUCCESS, CREATE_LEAVE_MASTER_ERROR, CREATE_LEAVE_MASTER_PENDING, OPEN_CREATE_LEAVE_MASTER_MODAL, OPEN_EDIT_LEAVE_MASTER_MODAL, SET_LEAVE_MASTER_DETAILS, SET_LEAVE_MASTER_DETAILS_ERROR, SET_LEAVE_MASTER_DETAILS_PENDING, SET_LEAVE_MASTER_EXPORT_LIST, SET_LEAVE_MASTER_EXPORT_LIST_ERROR, SET_LEAVE_MASTER_EXPORT_PENDING, SET_LEAVE_MASTER_LIST, SET_LEAVE_MASTER_LIST_ERROR, SET_LEAVE_MASTER_PENDING, UPDATE_LEAVE_MASTER_ERROR, UPDATE_LEAVE_MASTER_PENDING, UPDATE_LEAVE_MASTER_SUCCESS } from "./constants";

export const setLeaveMasterList = (leaveMasterList) => ({
    type: SET_LEAVE_MASTER_LIST,
    payload: { leaveMasterList },
})
export const setLeaveMasteExportList = (leaveMasterExportList) => ({
    type: SET_LEAVE_MASTER_EXPORT_LIST,
    payload: { leaveMasterExportList },
})
export const clearLeaveMasterListError = () => ({
    type: CLEAR_LEAVE_MASTER_LIST_ERROR,
})
export const clearLeaveMasterExportListError = () => ({
    type: CLEAR_LEAVE_MASTER_EXPORT_LIST_ERROR,
})
export const leaveMasterListError = (errorMessage) => ({
    type: SET_LEAVE_MASTER_LIST_ERROR,
    payload: { errorMessage },
})
export const leaveMasterExportListError = (errorMessage) => ({
    type: SET_LEAVE_MASTER_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const setLeaveMasterDetails = (leaveMasterDetails) => ({
    type: SET_LEAVE_MASTER_DETAILS,
    payload: { leaveMasterDetails },
})
export const clearLeaveMasterDetailsError = () => ({
    type: CLEAR_LEAVE_MASTER_DETAILS_ERROR,
})
export const leaveMasterDetailsError = (errorMessage) => ({
    type: SET_LEAVE_MASTER_DETAILS_ERROR,
    payload: { errorMessage },
})
export const openCreateLeaveMasterModalAction = () => ({
    type: OPEN_CREATE_LEAVE_MASTER_MODAL,
});
export const openEditLeaveMasterModal = () => ({
    type: OPEN_EDIT_LEAVE_MASTER_MODAL,
});
export const closeEditLeaveMasterModal = () => ({
    type: CLOSE_EDIT_LEAVE_MASTER_MODAL,
});
export const closeCreateLeaveMasterModalAction = () => ({
    type: CLOSE_CREATE_LEAVE_MASTER_MODAL,
});
export const createLeaveMasterError = (errorMessage) => ({
    type: CREATE_LEAVE_MASTER_ERROR,
    payload: { errorMessage },
});
export const createLeaveMasterPending = () => ({
    type: CREATE_LEAVE_MASTER_PENDING,
});
export const clearCreateLeaveMasterError = () => ({
    type: CLEAR_LEAVE_MASTER_ERROR,
});
export const createLeaveMasterActionSuccess = () => ({
    type: CREATE_LEAVE_MASTER_ACTION_SUCCESS,
});
export const clearUpdateLeaveMasterError = () => ({
    type: CLEAR_UPDATE_LEAVE_MASTER_ERROR,
});
export const updateLeaveMasterPending = () => ({
    type: UPDATE_LEAVE_MASTER_PENDING,
});
export const updateLeaveMasterSuccess = () => ({
    type: UPDATE_LEAVE_MASTER_SUCCESS,
});
export const updateLeaveMasterError = (errorMessage) => ({
    type: UPDATE_LEAVE_MASTER_ERROR,
    payload: { errorMessage },
});
export const setLeaveMasterPending = () => ({
    type: SET_LEAVE_MASTER_PENDING,
});
export const setLeaveMasterDetailsPending = () => ({
    type: SET_LEAVE_MASTER_DETAILS_PENDING,
});
export const setLeaveMasterExportPending = () => ({
    type: SET_LEAVE_MASTER_EXPORT_PENDING,
});



export const getleaveMasterExportAction = (searchUserName, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty) => (dispatch) => {
    dispatch(setLeaveMasterExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.LeaveMaster.getLeaveMasterExportList(accessToken, searchUserName, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty)
            .then((LeaveMasterLists) => {
                // dispatch(setLeaveMasteExportList(LeaveMasterLists));
                dispatch(clearLeaveMasterExportListError());
                return Promise.resolve(LeaveMasterLists);
            })
            .catch((err) => {
                console.log('getleaveMasterExportAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leaveMasterExportListError(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
}
export const getleaveMasterListAction = (filteredData,page, PageSize, searchUserName, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty) => (dispatch) => {
    dispatch(setLeaveMasterPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveMaster.getLeaveMasterList(accessToken, filteredData, page, PageSize, searchUserName, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty)
            .then((LeaveMasterLists) => {
                dispatch(setLeaveMasterList(LeaveMasterLists));
                dispatch(clearLeaveMasterListError());
            })
            .catch((err) => {
                console.log('getleaveMasterListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leaveMasterListError(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
}
export const getleaveMasterDetailsAction = (id) => (dispatch) => {
    dispatch(setLeaveMasterDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveMaster.getLeaveMasterDetails(accessToken, id)
            .then((leaveMasterDetails) => {
                dispatch(setLeaveMasterDetails(leaveMasterDetails))
                dispatch(clearLeaveMasterDetailsError());
            })
            .catch((err) => {
                console.log('getleaveMasterDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leaveMasterDetailsError(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    })
}

export const createLeaveMaster = (formFields, filteredData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createLeaveMasterError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
            consecutiveDaysQty: Number(formFields && formFields.consecutiveDaysQty),
            Qty: Number(formFields && formFields.Qty),
        });
    }
    dispatch(createLeaveMasterPending());
    dispatch(clearCreateLeaveMasterError());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveMaster.createLeaveMaster(accessToken, dataToSend)
            .then(() => {
                dispatch(createLeaveMasterActionSuccess());
                dispatch(clearForm());
                dispatch(closeCreateLeaveMasterModalAction());
                dispatch(getleaveMasterListAction(filteredData));
                // dispatch(getleaveMasterExportAction());
                dispatch(showSnackbarStatus('Leave Master created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createLeaveMasterError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createLeaveMasterError('Network Error'));
                }
                console.log('Create Leave server error', err);
                dispatch(clearForm());
                dispatch(closeCreateLeaveMasterModalAction());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

export const updateLeaveMaster = (formFields) => (dispatch) => {
    const dataToSend = trimmedValues({
        ...formFields,
        Id: formFields.id
    });
    dispatch(clearUpdateLeaveMasterError());
    dispatch(updateLeaveMasterPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveMaster.editLeaveMaster(accessToken, dataToSend)
            .then(() => {
                dispatch(updateLeaveMasterSuccess());
                dispatch(closeEditLeaveMasterModal());
                dispatch(getleaveMasterDetailsAction(formFields.id));
                dispatch(showSnackbarStatus('Leave Master updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    // dispatch(updateLeaveMasterError("Please fill all required fields"));
                    dispatch(updateLeaveMasterError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateLeaveMasterError('Network Error'));
                }
                console.log('Update Leave Master server error', err);
                dispatch(updateLeaveMasterError(null));
                dispatch(closeEditLeaveMasterModal());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

export const getOrgNameAsyncSelectActions =
    (searchValue, callback, orgName) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.OrgUser.getOrgNameList(accessToken, searchValue)
                    .then((OrganisationList) => {
                        const { value } = OrganisationList;
                        // console.log("value: ", value)
                        // console.log("orgName: ", orgName)
                        let orgList;
                        orgList = value && value.filter((item) => orgName === item.OrgName)
                        // console.log("orgList: ", orgList)
                        const updatedOrgList = modifyOrgListList(orgList);
                        //eslint-disable-next-line
                        callback && callback(updatedOrgList);
                    })
                    .catch((err) => {
                        console.log('getAppTypeAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                        //eslint-disable-next-line
                        callback && callback();
                    });
            });
        };

export const getUserNameAsyncSelectAction = (appId,searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Groups.getUserName(accessToken, appId,searchValue)
                .then((UserList) => {
                    const { value } = UserList;
                    const updatedUserList = modifyUserList(value);
                    //eslint-disable-next-line
                    callback && callback(updatedUserList);
                })
                .catch((err) => {
                    console.log('getUserNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
