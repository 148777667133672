import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { DASHBOARD_CLEAR_ERROR, DASHBOARD_ERROR_MESSAGE, DONUT_DASHBOARD_CLEAR_ERROR, DONUT_DASHBOARD_ERROR_MESSAGE, SET_DASHBOARD_LEAVETRACKER, SET_DASHBOARD_PENDING, SET_DONUT_DASHBOARD_LEAVETRACKER, SET_DONUT_DASHBOARD_PENDING } from "./constants";

export const setDashboard = (dashboard) => ({
    type: SET_DASHBOARD_LEAVETRACKER,
    payload: { dashboard },
});

export const setDonutDashboard = (dashboard) => ({
    type: SET_DONUT_DASHBOARD_LEAVETRACKER,
    payload: { dashboard },
});

export const setDashboardPending = () => ({
    type: SET_DASHBOARD_PENDING,
})
export const setDonutDashboardPending = () => ({
    type: SET_DONUT_DASHBOARD_PENDING,
})

export const dashboardErrorMessage = (dashboardErrorMessage) => ({
    type: DASHBOARD_ERROR_MESSAGE,
    payload: { dashboardErrorMessage },
});
export const donutDashboardErrorMessage = (dashboardErrorMessage) => ({
    type: DONUT_DASHBOARD_ERROR_MESSAGE,
    payload: { dashboardErrorMessage },
});

export const dashboardClearError = () => ({
    type: DASHBOARD_CLEAR_ERROR,
})
export const donutDashboardClearError = () => ({
    type: DONUT_DASHBOARD_CLEAR_ERROR,
})

export const getLeaveMasterDashboardCounts = () => (dispatch) => {
    dispatch(setDashboardPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTrackerDashboard.getDashboardLeaveTrackerCounts(accessToken)
            .then((dashboardCounts) => {
                dispatch(setDashboard(dashboardCounts));
                dispatch(dashboardClearError());
            })
            .catch((err) => {
                console.log('get Dashboard server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(dashboardErrorMessage(error))
            });
    })
}
export const getDonutDashboardCounts = (leaveType) => (dispatch) => {
    dispatch(setDonutDashboardPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTrackerDashboard.getDonutDashboardLeaveCounts(accessToken, leaveType)
            .then((dashboardCounts) => {
                console.log("dashboardCounts:", dashboardCounts);
                dispatch(setDonutDashboard(dashboardCounts));
                dispatch(donutDashboardClearError());
            })
            .catch((err) => {
                console.log('get Donut Dashboard server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(donutDashboardErrorMessage(error))
            });
    })
}