import React, { useEffect, useState } from 'react'
import VWMessage from '../../../../../../components/vwMessage/vwMessage'
import { Button, FormGroup, Input, Label, Form, FormFeedback } from 'reactstrap'
import { connect } from 'react-redux';
import { clearCreateTaskTypeError, createNewTaskType, createTaskTypeSuccess } from '../../../../../../store/Modules/ProjectPortal/Settings/TaskType/actions';

const AddTaskType = (props) => {
    const { isTaskTypeCreatePending, errorMessage } = props;

    const [typeDetails, setTypeDetails] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        props.clearCreateTaskTypeError();
        props.createTaskTypeSuccess();
        setTypeDetails({ ...typeDetails, isActive: true });
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setTypeDetails({
            ...typeDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { TypeName } = typeDetails || {};

        if (!TypeName) {
            currentErrors.TypeName = 'Type name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createNewTaskType(typeDetails)
        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label> Type Name <span className='text-danger'>*</span></Label>
                <Input
                    type="text"
                    name="TypeName"
                    placeHolder="Enter type name"
                    onChange={validateOnchange}
                    value={typeDetails && typeDetails.TypeName}
                    invalid={errors && errors.TypeName}
                />
                <FormFeedback>{errors && errors.TypeName}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isTaskTypeCreatePending}
                >
                    {isTaskTypeCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}
const mapStateToProps = (state) => ({
    isTaskTypeCreatePending: state.TaskType.isTaskTypeCreatePending,
    errorMessage: state.TaskType.TaskTypeCreateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    createNewTaskType: (typeDetails) => dispatch(createNewTaskType(typeDetails)),
    clearCreateTaskTypeError: () => dispatch(clearCreateTaskTypeError()),
    createTaskTypeSuccess: () => dispatch(createTaskTypeSuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(AddTaskType)