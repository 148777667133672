import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import Select from 'react-select';
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import VWMessage from "../../../../components/vwMessage/vwMessage"
import {
    clearCampaignCreateError,
    createNewCampaign,
    getFromAddressAsyncSelectAction
} from "../../../../store/Modules/MassMailer/Campaign/actions";
import { MAIL_CATEGORY_OPTIONS } from "../../../../store/Modules/MassMailer/Campaign/constants";
import Async from 'react-select/async';

const CreateCampaign = (props) => {
    const { isCreateCampaignPending, errorMessage, ErrorMessage } = props
    const [campaignDetails, setCampaignDetails] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        props.clearCampaignCreateError();
    }, []);

    //-------------------------------------------------------------DOWNLOAD----------------------------------------------------------------------------
    const handleSampleList = () => {
        const ws = XLSX.utils.aoa_to_sheet([["Email"]]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'contactList.xlsx');
    }

    //----------------------------------------------------------------FORM-INPUTS------------------------------------------------------------------------
    const handleChange = (e) => {
        const input = e.target;
        const value = input.type === 'file' ? input.files[0] : input.value;

        setCampaignDetails({
            ...campaignDetails,
            [input.name]: value,
        })
        setErrors({
            ...errors,
            [input.name]: "",
        })
    }

    const handleMailCategory = (e) => {
        setCampaignDetails({
            ...campaignDetails,
            Type: e ? e.value : "",
        })
        setErrors({
            ...errors,
            Type: "",
        })
    }

    const getFromAddressOption = (inputValue, callback) => {
        const { getFromAddressAsyncSelectAction } = props;
        return getFromAddressAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    }

    const handleFromAddressSelect = (Value) => {
        setCampaignDetails({
            ...campaignDetails,
            FromAddress: Value ? Value.value : "",
        })
        setErrors({
            ...errors,
            FromAddress: "",
        })
    }

    //-------------------------------------------------------------------FORM-SUBMISSION----------------------------------------------------------------
    const handleSubmit = (e) => {
        e.preventDefault();
        const { createNewCampaign } = props;
        const newErrors = {};
        const { CampaignName, Type, Subject, FromAddress } = campaignDetails || {};

        if (!CampaignName || !CampaignName.trim()) {
            newErrors.CampaignName = 'Campaign name is required';
        }
        if (!Type || !Type.trim()) {
            newErrors.Type = 'Mail category is required';
        }

        if (!Subject || !Subject.trim()) {
            newErrors.Subject = 'Subject is required';
        }
        if (!FromAddress || !FromAddress.trim()) {
            newErrors.FromAddress = 'From address is required';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            createNewCampaign(campaignDetails);
        }
    }
    return (
        <>
            {(errorMessage || ErrorMessage) && (
                <VWMessage type="danger" defaultMessage={errorMessage || ErrorMessage} baseClassName="alert" />
            )}
            <Form>
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <FormGroup>
                            <Label>Campaign Name <span className="text-danger"> *</span></Label>
                            <Input
                                type="text"
                                name="CampaignName"
                                placeHolder='Enter campaign name'
                                onChange={handleChange}
                                invalid={errors && errors.CampaignName}
                            />
                            <FormFeedback>{errors && errors.CampaignName}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <FormGroup>
                            <Label>Mail Category <span className="text-danger"> *</span></Label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.Type) ? 'red' : ""
                                    })
                                }}
                                isSearchable
                                placeholder='Select mail categroy'
                                defaultOptions
                                options={MAIL_CATEGORY_OPTIONS}
                                onChange={handleMailCategory}
                            />
                            <FormText color="danger">{errors && errors.Type}</FormText>
                        </FormGroup>
                    </Col>
                </Row >
                <FormGroup>
                    <Label>From Address <span className="text-danger"> *</span></Label>
                    <Async
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: (errors && errors.FromAddress) ? 'red' : ""
                            })
                        }}
                        name="FromAddress"
                        placeholder="Select from address"
                      //  className="vm-react-select"
                        isSearchable
                        defaultOptions
                        loadOptions={getFromAddressOption}
                        onChange={handleFromAddressSelect}
                    />
                    <FormText color="danger">{errors && errors.FromAddress}</FormText>
                </FormGroup>
                <FormGroup>
                    <Label>Subject <span className="text-danger"> *</span></Label>
                    < Input
                        type="text"
                        name="Subject"
                        placeHolder='Enter subject'
                        onChange={handleChange}
                        invalid={errors && errors.Subject}
                    />
                    <FormFeedback>{errors && errors.Subject}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Contact List</Label>
                    < Input
                        type="file"
                        name="ContactList"
                        placeHolder='Select file'
                        onChange={handleChange}
                        accept=".xls,.xlsx"
                    />
                    <div className="p-1">
                        <FormText>Refer this template file for import</FormText>&nbsp;
                        <Link onClick={handleSampleList}>
                            <small>download.</small>
                        </Link>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>HTML File</Label>
                    < Input
                        type="file"
                        name="IndexFile"
                        onChange={handleChange}
                        accept=".htm,.html"
                    />
                </FormGroup>
                <FormGroup>
                    <Button
                        color="success"
                        onClick={handleSubmit}
                        disabled={isCreateCampaignPending}
                    >
                        {isCreateCampaignPending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        className='ms-2'
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </Form >
        </>
    )
}

const mapStateToProps = (state) => ({
    isCreateCampaignPending: state.campaign.isCreateCampaignPending,
    errorMessage: state.campaign.createCampaignErrorMessage,
    ErrorMessage: state.campaign.CampaignFromAddressErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    createNewCampaign: (campaignDetails) => dispatch(createNewCampaign(campaignDetails)),
    getFromAddressAsyncSelectAction: (searchValue, callback) => dispatch(getFromAddressAsyncSelectAction(searchValue, callback)),
    clearCampaignCreateError: () => dispatch(clearCampaignCreateError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);