import { getToken, modifyUserList, modifyUserLists } from "../../../helper/common";
import trimmedValues from "../../../helper/trim";
import { clearForm } from "../../form/actions";
import agent from "../../services/agent";
import { showSnackbarStatus } from "../../snackbar/actions";
import { CLEAR_CREATE_VISION_DOCS, CLEAR_CREATE_VISION_DOCS_ERROR, CLEAR_DELETE_VISION_DOCS_ERROR, CLEAR_UPDATE_VISION_DOCS_ERROR, CLEAR_VISION_DOCS_DELETE_ERROR, CLEAR_VISION_DOCS_DETAILS, CLEAR_VISION_DOCS_DETAILS_ERROR, CLEAR_VISION_DOCS_LIST_ERROR, CLOSE_CREATE_VISION_DOCS_HEADER_MODAL, CLOSE_CREATE_VISION_DOCS_MODAL, CLOSE_UPDATE_VISION_DOCS_HEADER_MODAL, CLOSE_UPDATE_VISION_DOCS_MODAL, CLOSE_VISION_DOCS_DELETE, CLOSE_VISION_DOCS_MODAL, CREATE_VISION_DOCS_PENDING, CREATE_VISION_DOCS_SUCCESS, CREATE_VISIONDOCS_CONTENT, CREATE_VISIONDOCS_ERROR, CREATE_VISIONDOCS_TITLE, IS_CREATE_VISION_DOCS_PENDING, IS_CREATE_VISION_DOCS_SUCCESS, IS_UPDATE__VISION_DOCS_SUCCESS, IS_UPDATE_VISION_DOCS_PENDING, IS_UPDATE_VISION_DOCS_SUCCESS, OPEN_CREATE_VISION_DOCS_HEADER_MODAL, OPEN_CREATE_VISION_DOCS_MODAL, OPEN_UPDATE_VISION_DOCS_HEADER_MODAL, OPEN_UPDATE_VISION_DOCS_MODAL, OPEN_VISION_DOCS_DELETE, OPEN_VISION_DOCS_MODAL, SET_DOCS_LIST, SET_TOGGLE_VIEW, SET_VISION_DOCS_DETAILS, SET_VISION_DOCS_LIST, UPDATE_VISION_DOCS_PENDING, UPDATE_VISION_DOCS_SUCCESS, UPDATE_VISIONDOCS_ERROR, VISION_DOCS_CREATE_ID, VISION_DOCS_DELETE_LIST_ERROR, VISION_DOCS_DELETE_PENDING, VISION_DOCS_DETAILS_ERROR, VISION_DOCS_DETAILS_PENDING, VISION_DOCS_LIST_ERROR, VISION_DOCS_LIST_PENDING } from "./constants";


// export const getDocsListAction = (docsList) => ({
//     type: SET_DOCS_LIST,
//     payload: { docsList },
// })

// export const createDocs = (notes) => (dispatch) => {
//     // console.log("Notes:", notes);
//     dispatch(getDocsListAction(notes));
//     window.location.href = '/visiondocs/list'
// };
export const updateDocs = (notes) => (dispatch) => {
    // console.log("Notes:", notes);
    // dispatch(getDocsListAction(notes));
    window.location.href = '/visiondocs/list'
};
export const createVisionDocsError = (errorMessage) => ({
    type: CREATE_VISIONDOCS_ERROR,
    payload: { errorMessage },
});
export const updateVisionDocsError = (errorMessage) => ({
    type: UPDATE_VISIONDOCS_ERROR,
    payload: { errorMessage },
});
export const docContent = (content) => ({
    type: CREATE_VISIONDOCS_CONTENT,
    payload: { content },
});

export const docTitle = (title) => ({
    type: CREATE_VISIONDOCS_TITLE,
    payload: { title },
});
export const visionDocsListError = (errorMessage) => ({
    type: VISION_DOCS_LIST_ERROR,
    payload: { errorMessage },
});
export const visionDocsDeleteListError = (errorMessage) => ({
    type: VISION_DOCS_DELETE_LIST_ERROR,
    payload: { errorMessage },
});
export const visionDocsDetailsError = (errorMessage) => ({
    type: VISION_DOCS_DETAILS_ERROR,
    payload: { errorMessage },
});
export const createVisionDocsID = (id) => ({
    type: VISION_DOCS_CREATE_ID,
    payload: { id },
});
export const createVisionDocsPending = () => ({
    type: CREATE_VISION_DOCS_PENDING,
});
export const updateVisionDocsPending = () => ({
    type: UPDATE_VISION_DOCS_PENDING,
});
export const visionDocsDetailsPending = () => ({
    type: VISION_DOCS_DETAILS_PENDING,
});
export const clearCreatereateVisionDocsError = () => ({
    type: CLEAR_CREATE_VISION_DOCS_ERROR,
});
export const clearUpdateVisionDocsError = () => ({
    type: CLEAR_UPDATE_VISION_DOCS_ERROR,
});
export const clearDeleteVisionDocsError = () => ({
    type: CLEAR_DELETE_VISION_DOCS_ERROR,
});
export const ClearVisionDocsListError = () => ({
    type: CLEAR_VISION_DOCS_LIST_ERROR,
});
export const clearVisionDocsDetailsError = () => ({
    type: CLEAR_VISION_DOCS_DETAILS_ERROR,
});

export const createVisionDocsSuccess = () => ({
    type: CREATE_VISION_DOCS_SUCCESS,
});
export const updateVisionDocsSuccess = () => ({
    type: UPDATE_VISION_DOCS_SUCCESS,
});

export const visionDocsListPending = () => ({
    type: VISION_DOCS_LIST_PENDING,
});

export const openCreateVisionDocsHeaderModal = () => ({
    type: OPEN_CREATE_VISION_DOCS_HEADER_MODAL,
});
export const openUpdateVisionDocsHeaderModalAction = () => ({
    type: OPEN_UPDATE_VISION_DOCS_HEADER_MODAL,
});
export const closeCreateVisionDocsHeaderModal = () => ({
    type: CLOSE_CREATE_VISION_DOCS_HEADER_MODAL,
});
export const closeUpdateVisionDocsHeaderModal = () => ({
    type: CLOSE_UPDATE_VISION_DOCS_HEADER_MODAL,
});
export const openCreateVisionDocsModal = () => ({
    type: OPEN_CREATE_VISION_DOCS_MODAL,
});
export const openUpdateVisionDocsModal = () => ({
    type: OPEN_UPDATE_VISION_DOCS_MODAL,
});
export const closeCreateVisionDocsModal = () => ({
    type: CLOSE_CREATE_VISION_DOCS_MODAL,
});
export const closeUpdateVisionDocsModal = () => ({
    type: CLOSE_UPDATE_VISION_DOCS_MODAL,
});
export const isUpdateEditDocsSuccess = () => ({
    type: IS_UPDATE_VISION_DOCS_SUCCESS,
});
export const isCreateEditDocsSuccess = () => ({
    type: IS_CREATE_VISION_DOCS_SUCCESS,
});
export const isUpdateEditDocspending = () => ({
    type: IS_UPDATE_VISION_DOCS_PENDING,
});
export const isCreateEditDocspending = () => ({
    type: IS_CREATE_VISION_DOCS_PENDING,
});
export const ClearCreateVisionDocsReducer = () => ({
    type: CLEAR_CREATE_VISION_DOCS,
});

export const setVisionDocsList = (list) => ({
    type: SET_VISION_DOCS_LIST,
    payload: { list },
})
export const sendToggleView = (toggleView) => ({
    type: SET_TOGGLE_VIEW,
    payload: { toggleView },
})
export const setVisionDocsDetails = (details) => ({
    type: SET_VISION_DOCS_DETAILS,
    payload: { details },
})
export const clearVisionDocsDetails = () => ({
    type: CLEAR_VISION_DOCS_DETAILS,
})
export const openDeleteDocsModalAction = () => ({
    type: OPEN_VISION_DOCS_DELETE,
})
export const closeDeleteDocsModalAction = () => ({
    type: CLOSE_VISION_DOCS_DELETE,
})
export const visionDocsDeletePending = () => ({
    type: VISION_DOCS_DELETE_PENDING,
})
export const ClearVisionDocsDeleteError = () => ({
    type: CLEAR_VISION_DOCS_DELETE_ERROR,
})
export const ClearVisionDocsDetails = () => ({
    type: CLEAR_VISION_DOCS_DETAILS,
})


export const deleteDocsList = (id) => (dispatch) => {
    dispatch(visionDocsDeletePending());
    getToken(dispatch).then((accessToken) => {
        agent.VisionDocs.getVisionDocsDelete(accessToken, id)
            .then(() => {
                dispatch(getvisionDocsList());
                dispatch(closeDeleteDocsModalAction());
                dispatch(showSnackbarStatus('Document deleted successfully'));
                dispatch(ClearVisionDocsDeleteError());
            })
            .catch((err) => {
                console.log('deleteDocsList server error', err);
                console.log('deleteDocsList server error', err.response);
                let error;
                if (err.status) {
                    error = err.response && err.response.text
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log("error", error);

                dispatch(visionDocsDeleteListError(error))
            });
    });
}

export const getvisionDocsList = (page, PageSize, searchTitle, searchAssignUserCount, searchCreatedBy, searchCreatedTimeStamp, searchAccess, searchUpdatedBy, searchUpdatedTimeStamp) => (dispatch) => {
    dispatch(visionDocsListPending());
    getToken(dispatch).then((accessToken) => {
        agent.VisionDocs.getVisionDocsList(accessToken, page, PageSize, searchTitle, searchAssignUserCount, searchCreatedBy, searchCreatedTimeStamp, searchAccess, searchUpdatedBy, searchUpdatedTimeStamp)
            .then((visionDocsLists) => {
                dispatch(setVisionDocsList(visionDocsLists));
                dispatch(ClearVisionDocsListError());
            })
            .catch((err) => {
                console.log('getvisionDocsList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(visionDocsListError(error))
            });
    });
}

export const getVisionDocsDetailsAction = (id) => (dispatch) => {
    dispatch(visionDocsDetailsPending())
    getToken(dispatch).then((accessToken) => {
        // console.log("accessToken:", accessToken)
        agent.VisionDocs.getVisionDocsDetails(accessToken, id).then(
            (visionDocsDetails) => {
                console.log("visionDocsDetails:", visionDocsDetails)
                dispatch(setVisionDocsDetails(visionDocsDetails))
                dispatch(clearVisionDocsDetailsError())
            }
        )
            .catch((err) => {
                console.log('getVisionDocsDetailsAction server error', err);
                let error;
                if (err.status === 400) {
                    error = err.response && err.response && err.response.text;
                    console.log(error);
                    dispatch(showSnackbarStatus(error));
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(visionDocsDetailsError(error))

            });
    })

}

export const createVisionDocs = (formFields) => (dispatch) => {
    console.log("formFields:", formFields);
    let dataToSend = {}
    if (!formFields) {
        dispatch(createVisionDocsError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(createVisionDocsPending());
    dispatch(clearCreatereateVisionDocsError());
    getToken(dispatch).then((accessToken) => {
        agent.VisionDocs.createDocs(accessToken, dataToSend)
            .then((response) => {
                console.log("response id:", response);
                dispatch(createVisionDocsID(response));
                dispatch(createVisionDocsSuccess());
                dispatch(clearForm());
                dispatch(closeCreateVisionDocsModal());
                dispatch(closeCreateVisionDocsHeaderModal());
                dispatch(getvisionDocsList());
                dispatch(showSnackbarStatus('Document created successfully'));
                // window.location.href = '/visiondocs/list'
                // dispatch(isCreateEditDocsSuccess());
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createVisionDocsError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createVisionDocsError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('create Vision Docs server error', err);
                dispatch(isCreateEditDocspending());
                dispatch(clearForm());
                // dispatch(CloseCreateTaskModalAction());
            });
    });
};
export const updateVisionDocs = (formFields) => (dispatch) => {
    console.log("formFields:", formFields);
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateVisionDocsError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(updateVisionDocsPending());
    dispatch(clearUpdateVisionDocsError());
    getToken(dispatch).then((accessToken) => {
        agent.VisionDocs.updateDocs(accessToken, dataToSend)
            .then(() => {
                dispatch(updateVisionDocsSuccess());
                dispatch(clearForm());
                dispatch(closeUpdateVisionDocsModal());
                dispatch(getvisionDocsList());
                dispatch(showSnackbarStatus('Document updated successfully'));
                // window.location.href = '/visiondocs/list';
                // dispatch(isUpdateEditDocsSuccess());

            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateVisionDocsError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateVisionDocsError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Update Vision Docs server error', err);
                dispatch(isUpdateEditDocspending());
                dispatch(clearForm());
                // dispatch(CloseCreateTaskModalAction());
            });
    });
};

export const getOrgUserNameWithoutLoginuserAsyncSelect =
    (userId, searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.VisionDocs.getUserNameList(accessToken, searchValue)
                    .then((UserLists) => {
                        const { value } = UserLists;
                        const updatedUserList = modifyUserLists(value);
                        console.log("updatedUserList:", updatedUserList);
                        console.log("userId:", userId);
                        const onlyUsers = updatedUserList.filter((item) => {
                            return (item.value !== userId)
                        })
                        console.log("onlyUsers:", onlyUsers);
                        //eslint-disable-next-line
                        callback && callback(onlyUsers);
                    })
                    .catch((err) => {
                        console.log('getUserNameAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };
export const getOrgUserNameAsyncSelect =
    (searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.VisionDocs.getUserNameList(accessToken, searchValue)
                    .then((UserLists) => {
                        const { value } = UserLists;
                        const updatedUserList = modifyUserLists(value);
                        console.log("updatedUserList:", updatedUserList);
                        //eslint-disable-next-line
                        callback && callback(updatedUserList);
                    })
                    .catch((err) => {
                        console.log('getUserNameAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };
export const getUsersNameAsyncSelectAction =
    (userid, searchValue, callback) =>
        (dispatch) => {
            console.log("userid:", userid);
            getToken(dispatch).then((accessToken) => {
                agent.VisionDocs.getUserNameList(accessToken, searchValue)
                    .then((UserLists) => {
                        const { value } = UserLists;
                        const updatedUserList = modifyUserLists(value);
                        console.log("updatedUserList:", updatedUserList);
                        const onlyUsers = updatedUserList.filter((item) => {
                            return (item.value !== userid)
                        })
                        console.log("onlyUsers:", onlyUsers);
                        //eslint-disable-next-line
                        callback && callback(onlyUsers);
                    })
                    .catch((err) => {
                        console.log('getUserNameAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };