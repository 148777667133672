//--------------------ACCOUNTS-CREATE-----------------------------------
export const LEAD_SOURCE_CREATE_OPEN = "LEAD_SOURCE_CREATE_OPEN";
export const LEAD_SOURCE_CREATE_CLOSE = "LEAD_SOURCE_CREATE_CLOSE";
export const LEAD_SOURCE_CREATE_PENDING = "LEAD_SOURCE_CREATE_PENDING";
export const LEAD_SOURCE_CREATE_ERROR = "LEAD_SOURCE_CREATE_ERROR";
export const LEAD_SOURCE_CREATE_ERROR_CLEAR = "LEAD_SOURCE_CREATE_ERROR_CLEAR";
export const LEAD_SOURCE_CREATE_SUCCESS = "LEAD_SOURCE_CREATE_SUCCESS";

//--------------------ACCOUNTS-UPDATE-----------------------------------
export const LEAD_SOURCE_UPDATE_OPEN = "LEAD_SOURCE_UPDATE_OPEN";
export const LEAD_SOURCE_UPDATE_CLOSE = "LEAD_SOURCE_UPDATE_CLOSE";
export const LEAD_SOURCE_UPDATE_PENDING = "LEAD_SOURCE_UPDATE_PENDING";
export const LEAD_SOURCE_UPDATE_ERROR = "LEAD_SOURCE_UPDATE_ERROR";
export const LEAD_SOURCE_UPDATE_SUCCESS = "LEAD_SOURCE_UPDATE_SUCCESS";
export const LEAD_SOURCE_UPDATE_ERROR_CLEAR = "LEAD_SOURCE_UPDATE_ERROR_CLEAR";
export const SET_LEAD_SOURCE_DETAILS = "SET_LEAD_SOURCE_DETAILS";

//--------------------ACCOUNTS-LIST-----------------------------------
export const SET_LEAD_SOURCE_LIST = "SET_LEAD_SOURCE_LIST";
export const LEAD_SOURCE_LIST_PENDING = "ACCOUNTS_CATEGORY_LIST_PENDING";
export const LEAD_SOURCE_LIST_ERROR = "ACCOUNTS_CATEGORY_LIST_ERROR";
export const LEAD_SOURCE_LIST_ERROR_CLEAR = "ACCOUNTS_CATEGORY_LIST_ERROR_CLEAR";

//--------------------ACCOUNTS-DETAILS-----------------------------------
export const SET_ACCOUNTS_DETAILS = "SET_ACCOUNTS_DETAILS";
export const ACCOUNTS_DETAILS_PENDING = "ACCOUNTS_DETAILS_PENDING";
export const ACCOUNTS_DETAILS_ERROR = "ACCOUNTS_DETAILS_ERROR";
export const ACCOUNTS_DETAILS_ERROR_CLEAR = "ACCOUNTS_DETAILS_ERROR_CLEAR";
