import { CLEAR_GANTT_CHART_LIST, CLEAR_GANTT_LIST_ERROR, CLEAR_GANTT_MILESTONE_UPDATE_ERROR, SELECTED_GANTT_PROJECT, SET_GANTT_CHART_LIST, SET_GANTT_LIST_ERROR, SET_GANTT_LIST_PENDING, SET_GANTT_MILESTONE_UPDATE_ERROR, SET_GANTT_MILESTONE_UPDATE_PENDING, UPDATE_GANTT_CHART_LIST_SUCCESS } from "./constants";

const initialState = {
    isGanttListPending: false,
    GanttProjectList: [],
    isGanttListError: null,
    isGanttMilestoneUpdateError: null,
    isGanttMilestoneUpdatePending: false,
    SelectedGanttProject: {},
    updateGanttListsuccess: false,
}

export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_GANTT_LIST_PENDING: {
            return {
                ...localState,
                isGanttListPending: true,
            };
        }
        case UPDATE_GANTT_CHART_LIST_SUCCESS: {
            console.log("it's hitting");
            return {
                ...localState,
                updateGanttListsuccess: true,
            };
        }
        case SET_GANTT_MILESTONE_UPDATE_PENDING: {
            return {
                ...localState,
                isGanttMilestoneUpdatePending: true,
            };
        }
        case SET_GANTT_CHART_LIST: {
            const { ganttList } = action.payload;
            return {
                ...localState,
                isGanttListPending: false,
                updateGanttListsuccess: false,
                GanttProjectList: ganttList['value'],
            }
        }
        case CLEAR_GANTT_CHART_LIST: {
            return {
                ...localState,
                isGanttListPending: false,
                updateGanttListsuccess: false,
                GanttProjectList: [],
            };
        }
        case CLEAR_GANTT_LIST_ERROR: {
            return {
                ...localState,
                isGanttListError: null,
            };
        }
        case CLEAR_GANTT_MILESTONE_UPDATE_ERROR: {
            return {
                ...localState,
                isGanttMilestoneUpdateError: null,
            };
        }
        case SET_GANTT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isGanttListPending: false,
                isGanttListError: errorMessage,
            };
        }
        case SET_GANTT_MILESTONE_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isGanttMilestoneUpdatePending: false,
                isGanttMilestoneUpdateError: errorMessage,
            };
        }
        case SELECTED_GANTT_PROJECT: {
            const { project } = action.payload;
            return {
                ...localState,
                SelectedGanttProject: project,

            };
        }
        default: {
            return localState;
        }
    }
}