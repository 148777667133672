import { getToken } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../../../crm/Settings/SettingsInlineUpdate/actions";
import {
    TASK_TYPE_CREATE_CLOSE,
    TASK_TYPE_CREATE_ERROR,
    TASK_TYPE_CREATE_ERROR_CLEAR,
    TASK_TYPE_CREATE_OPEN,
    TASK_TYPE_CREATE_PENDING,
    TASK_TYPE_CREATE_SUCCESS,
    TASK_TYPE_LIST_ERROR,
    TASK_TYPE_LIST_ERROR_CLEAR,
    TASK_TYPE_LIST_PENDING,
    TASK_TYPE_UPDATE_CLOSE,
    TASK_TYPE_UPDATE_ERROR,
    TASK_TYPE_UPDATE_ERROR_CLEAR,
    TASK_TYPE_UPDATE_OPEN,
    TASK_TYPE_UPDATE_PENDING,
    TASK_TYPE_UPDATE_SUCCESS,
    SET_TASK_TYPE_DETAILS,
    SET_TASK_TYPE_LIST,
} from "./constants";

//--------------------TASK_SCOPE-CREATE-----------------------------------
export const openAddTaskTypeModal = () => ({
    type: TASK_TYPE_CREATE_OPEN
})
export const closeAddTaskTypeModal = () => ({
    type: TASK_TYPE_CREATE_CLOSE
})
export const createTaskTypePending = () => ({
    type: TASK_TYPE_CREATE_PENDING
})
export const createTaskTypeError = (errorMessage) => ({
    type: TASK_TYPE_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateTaskTypeError = () => ({
    type: TASK_TYPE_CREATE_ERROR_CLEAR,
})
export const createTaskTypeSuccess = () => ({
    type: TASK_TYPE_CREATE_SUCCESS,
})


export const createNewTaskType = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createTaskTypeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createTaskTypePending());
    dispatch(clearCreateTaskTypeError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.createTaskType(accessToken, dataToSend)
            .then(() => {
                dispatch(getTaskTypeList());
                dispatch(createTaskTypeSuccess());
                dispatch(closeAddTaskTypeModal());
                dispatch(showSnackbarStatus('Task type created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createTaskTypeError(err.response && err.response.text));
                    return;
                }
                console.log('Create Task type server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

// //--------------------TASK TYPE-UPDATE-----------------------------------
export const openEditTaskTypeModal = () => ({
    type: TASK_TYPE_UPDATE_OPEN
})
export const closeEditTaskTypeModal = () => ({
    type: TASK_TYPE_UPDATE_CLOSE
})
export const updateTaskTypePending = () => ({
    type: TASK_TYPE_UPDATE_PENDING
})
export const updateTaskTypeError = (errorMessage) => ({
    type: TASK_TYPE_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateTaskTypeError = () => ({
    type: TASK_TYPE_UPDATE_ERROR_CLEAR,
})
export const updateTaskTypeSuccess = () => ({
    type: TASK_TYPE_UPDATE_SUCCESS,
})
export const setTaskTypeDetails = (typeDetails) => ({
    type: SET_TASK_TYPE_DETAILS,
    payload: { typeDetails }
})

export const updateTaskType = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateTaskTypeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateTaskTypePending());
    dispatch(clearUpdateTaskTypeError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.updateTaskType(accessToken, dataToSend)
            .then(() => {
                dispatch(getTaskTypeList());
                dispatch(updateTaskTypeSuccess());
                dispatch(closeEditTaskTypeModal());
                dispatch(showSnackbarStatus('Task type updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateTaskTypeError(err.response && err.response.text));
                    return;
                }
                console.log('Create task type server error', err)
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------TASK SCOPE-LIST-----------------------------------
export const taskTypeListPending = () => ({
    type: TASK_TYPE_LIST_PENDING
})
export const taskTypeListError = (errorMessage) => ({
    type: TASK_TYPE_LIST_ERROR,
    payload: { errorMessage }
})
export const clearTaskTypeListError = () => ({
    type: TASK_TYPE_LIST_ERROR_CLEAR,
})
export const setTaskTypeList = (taskTypeList) => ({
    type: SET_TASK_TYPE_LIST,
    payload: { taskTypeList }
})

export const getTaskTypeList = (page, PageSize) => (dispatch) => {
    dispatch(taskTypeListPending());
    dispatch(clearTaskTypeListError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getSettingsTaskTypeList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setTaskTypeList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(taskTypeListError(error));
                console.log('Task type list server error', error);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

