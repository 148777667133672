import {
    INDUSTRY_CREATE_CLOSE,
    INDUSTRY_CREATE_ERROR,
    INDUSTRY_CREATE_ERROR_CLEAR,
    INDUSTRY_CREATE_OPEN,
    INDUSTRY_CREATE_PENDING,
    INDUSTRY_CREATE_SUCCESS,
    INDUSTRY_LIST_ERROR,
    INDUSTRY_LIST_ERROR_CLEAR,
    INDUSTRY_LIST_PENDING,
    INDUSTRY_UPDATE_CLOSE,
    INDUSTRY_UPDATE_ERROR,
    INDUSTRY_UPDATE_ERROR_CLEAR,
    INDUSTRY_UPDATE_OPEN,
    INDUSTRY_UPDATE_PENDING,
    INDUSTRY_UPDATE_SUCCESS,
    SET_INDUSTRY_DETAILS,
    SET_INDUSTRY_LIST,
} from "./constants";

const initialState = {
    //--------------------Industry-CREATE-----------------------------------
    isCreateIndustryOpen: false,
    industryCreateErrorMessage: null,
    isIndustryCreateSuccess: false,
    isIndustryCreatePending: false,
    //--------------------Industry-UPDATE-----------------------------------
    industryDetails: null,
    isUpdateIndustryOpen: false,
    industryUpdateErrorMessage: null,
    isIndustryUpdateSuccess: false,
    isIndustryUpdatePending: false,
    //--------------------Industry-LIST-------------------------------------
    industryList: [],
    industryListCount: 0,
    isIndustryListPending: false,
    industryListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------LEADS-CREATE-----------------------------------
        case INDUSTRY_CREATE_OPEN: {
            return {
                ...localState,
                isCreateIndustryOpen: true
            }
        }
        case INDUSTRY_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateIndustryOpen: false
            }
        }
        case INDUSTRY_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                industryCreateErrorMessage: null,
            }
        }
        case INDUSTRY_CREATE_PENDING: {
            return {
                ...localState,
                isIndustryCreatePending: true,
            }
        }
        case INDUSTRY_CREATE_SUCCESS: {
            return {
                ...localState,
                isIndustryCreateSuccess: true,
                isIndustryCreatePending: false
            }
        }
        case INDUSTRY_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                industryCreateErrorMessage: errorMessage,
                isIndustryCreatePending: false,
            }
        }

        //--------------------LEADS-UPDATE-----------------------------------
        case SET_INDUSTRY_DETAILS: {
            const { industryDetails } = action.payload;
            return {
                ...localState,
                industryDetails: industryDetails
            }
        }
        case INDUSTRY_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateIndustryOpen: true
            }
        }
        case INDUSTRY_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateIndustryOpen: false
            }
        }
        case INDUSTRY_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                industryUpdateErrorMessage: null,
            }
        }
        case INDUSTRY_UPDATE_PENDING: {
            return {
                ...localState,
                isIndustryUpdatePending: true,
            }
        }
        case INDUSTRY_UPDATE_SUCCESS: {
            return {
                ...localState,
                isIndustryUpdateSuccess: true,
                isIndustryUpdatePending: false
            }
        }
        case INDUSTRY_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                industryUpdateErrorMessage: errorMessage,
                isIndustryUpdatePending: false
            }
        }
        //--------------------LEADS-CATEGORY-LIST-------------------------------------
        case SET_INDUSTRY_LIST: {
            const { industryList } = action.payload;
            return {
                ...localState,
                industryList: industryList && industryList['value'],
                industryListCount: industryList && industryList['@odata.count'],
                isIndustryListPending: false,
                industryListErrorMessage: null,
            }
        }
        case INDUSTRY_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                industryListErrorMessage: errorMessage,
                isIndustryListPending: false,
                industryList: []
            }
        }
        case INDUSTRY_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                industryListErrorMessage: null,
            }
        }
        case INDUSTRY_LIST_PENDING: {
            return {
                ...localState,
                isIndustryListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};