import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import { clearUpdateTaskScopeError, updateTaskScope, updateTaskScopeSuccess } from '../../../../../../store/Modules/ProjectPortal/Settings/TaskScope/actions';

const EditTaskScope = (props) => {
    const { taskScopeDetails, isTaskScopeUpdatePending, errorMessage } = props;
    const { Id, ScopeName, IsActive } = taskScopeDetails || {};

    const [scopeDetails, setScopeDetails] = useState();
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();

    const { userAppDetails } = props.currentProfile || {};
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })

    console.log('app:', App);

    useEffect(() => {
        setScopeDetails({
            ...scopeDetails,
            id: Id,
            scopeName: ScopeName,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateTaskScopeError();
        props.updateTaskScopeSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setScopeDetails({
            ...scopeDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        });
    }
    const handleActiveStatusChange = () => {
        setScopeDetails({
            ...scopeDetails,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { scopeName } = scopeDetails || {};

        if (!scopeName) {
            currentErrors.scopeName = 'Scope name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateTaskScope(scopeDetails, App[0] && App[0].appId || '')
        }
    }

    console.log("taskScopeDetails:", taskScopeDetails);
    console.log('App[0] && App[0].appId:', App[0] && App[0].appId);

    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label> Scope Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="scopeName"
                        placeHolder="Enter scope name"
                        onChange={validateOnchange}
                        value={scopeDetails && scopeDetails.scopeName}
                        invalid={errors && errors.scopeName}
                    />
                    <FormFeedback>{errors && errors.scopeName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isTaskScopeUpdatePending}
                    >
                        {isTaskScopeUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}
const mapStateToProps = (state) => ({
    taskScopeDetails: state.TaskScope.taskScopeDetails,
    isTaskScopeUpdatePending: state.TaskScope.isTaskScopeUpdatePending,
    errorMessage: state.TaskScope.taskScopeUpdateErrorMessage,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    updateTaskScope: (scopeDetails, appId) => dispatch(updateTaskScope(scopeDetails, appId)),
    clearUpdateTaskScopeError: () => dispatch(clearUpdateTaskScopeError()),
    updateTaskScopeSuccess: () => dispatch(updateTaskScopeSuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(EditTaskScope)