import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardTitle, Input, Table, Badge, Row, Col } from 'reactstrap'
import { getPager } from '../../../../helper/common';
import moment from "moment";
import Badges from '@mui/material/Badge';
import VWPagination from '../../../../components/vwPagination/vwPagination';
import { connect } from 'react-redux';
import { checkEmployeeUserAccess, clearAvailableLeaveCountError, clearLeaveCountError, clearLeaveTrackerActionError, closeCreateApplyLeaveModalAction, closeLeaveTrackerModalAction, getDisableCalenderDates, getEmployeeUserAsyncSelect, getLeaveTrackerListActionModal, getLeaveTypeAsyncSelect, getLeaveTypeCount, getLeaveTypeListAsyncSelect, getMyLeavetrackerExportList, openActionLeaveTrackerModal, openCreateApplyLeaveModalAction } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions';
import VWModel from '../../../../components/Modal/VWModal';
import LeaveTrackerStatusAction from "./leavetrackerStatus"
import { clearForm } from '../../../../store/form/actions';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CreateLeave from './leaveCreate'
import NoRecord from '../../../../components/NoRecords/noRecords';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetPath } from '../../../../store/actions';
import Async from "react-select/async"
import { getEmployeeList, getEmployeeListAction } from '../../../../store/Modules/Employees/actions';
import UITooltip from '../../../../CommonData/Data/UITooltip';
import '../../../Modules/viewList.css'
import { excelPrint, excelSheetExport } from '../../../../helper/export';
import { MYLEAVE_TRACKER_EXPORT_HEADERS } from '../../../../store/Modules/LeaveTracker/LeaveTracking/constants';
import { ElementToolTip } from '../../Projects/vwToolTip';

const customStyles = {
  container: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'normal'
  }),
};
const LeaveMonitoringList = (props) => {

  const path = window.location.search;
  const params = new URLSearchParams(path);
  const id = params.get('filter')

  console.log("id:", id);
  const { currentProfile, isLeaveTrackerExportPending } = props || {};
  const { roles, userId } = currentProfile || {};

  const [PageSize] = useState(20);
  const [Pager, setPager] = useState({ currentPage: 1 });
  // const [filter, setFilter] = useState({ LeaveStatus: id || "All", });
  const [filter, setFilter] = useState();
  const [actionList, setActionsList] = useState({});
  const [all, setAll] = useState(true);
  const [Submitted, setSubmitted] = useState(false);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [filterparams, setFilterParams] = useSearchParams();
  const [badgeCount, setBadgeCount] = useState(0);

  const buildQueryuserName = () => {
    const { UserName } = filter || {};
    let searchuserName = '';
    if (UserName) {
      searchuserName += `${UserName}`;
    }
    return searchuserName
  }
  const buildQueryFromDateTime = () => {
    const { FromDateTime } = filter || {};
    let searchFromDateTime = '';
    if (FromDateTime) {
      searchFromDateTime += `${FromDateTime}`;
    }
    return searchFromDateTime
  }
  const buildQueryToDateTime = () => {
    const { ToDateTime } = filter || {};
    let searchToDateTime = '';
    if (ToDateTime) {
      searchToDateTime += `${ToDateTime}`;
    }
    return searchToDateTime
  }
  const buildQueryDays = () => {
    const { TotalLeaveCount } = filter || {};
    let searchDays = '';
    if (TotalLeaveCount) {
      searchDays += `${TotalLeaveCount}`;
    }
    return searchDays
  }
  const buildQueryReason = () => {
    const { LeaveReason } = filter || {};
    let searchReason = '';
    if (LeaveReason) {
      searchReason += `${LeaveReason}`;
    }
    return (searchReason)
  }
  const buildQueryLeaveType = () => {
    const { LeaveType } = filter || {};
    let searchLeaveType = '';
    if (LeaveType) {
      searchLeaveType += `${LeaveType}`;
    }
    return (searchLeaveType)
  }
  const buildQueryCategory = () => {
    const { Category } = filter || {};
    let searchCategory = '';
    if (Category) {
      searchCategory += `${Category}`;
    }
    return (searchCategory)
  }
  const buildQueryLeaveStatus = () => {
    const { LeaveStatus } = filter || {};
    let searchLeaveStatus = '';
    if (LeaveStatus) {
      searchLeaveStatus += `${LeaveStatus}`;
    }
    return (searchLeaveStatus)
  }
  const buildQueryStatusDescription = () => {
    const { StatusDescription } = filter || {};
    let searchStatusDescription = '';
    if (StatusDescription) {
      searchStatusDescription += `${StatusDescription}`;
    }
    return (searchStatusDescription)
  }


  const setPage = (page = 1) => {
    props.getleaveListAction(filter, page, PageSize, buildQueryuserName(), buildQueryFromDateTime(), buildQueryToDateTime(), buildQueryDays(), buildQueryReason(), buildQueryLeaveType(), buildQueryCategory(), buildQueryLeaveStatus(), buildQueryStatusDescription(), id);
    setPager(prevPager => {
      const totalPages = Math.ceil(props.totalCount / PageSize);
      const visiblePages = 4;
      const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
      const newPages = [];
      for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
        newPages.push(i);
      }
      return {
        ...prevPager,
        currentPage: page,
        totalPages,
        pages: newPages,
      }
    })
  }

  useEffect(() => {
    const currentPager = getPager(props.totalCount, 1, PageSize)
    setPager(currentPager);
    setBadgeCount(props.totalCount);
  }, [props.totalCount])

  // let EmployeeActive;

  const [EmployeeActive, setEmployeeActive] = useState(0);
  console.log("EmployeeActive:", EmployeeActive);
  useEffect(() => {
    props.checkEmployeeUserAccess(userId).then((res) => {
      console.log("res", res);
      console.log("res", res["@odata.count"]);
      const count = res["@odata.count"] > 0;
      setEmployeeActive(count);
    })
    props.getLeaveTypeCount(); // to check leave Type for login user count is greater than zero
    // props.getEmployeeList(); // to check if the login user is present in employee list.
    // const currentPageNumber = Pager && Pager.currentPage;
    // setPage(currentPageNumber);
    props.ClearPath();
  }, [])

  //------------------------------------------Filter-----------------------------------------------------------
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const searchInputValidation = (event) => {
    const { value, name } = event.target;
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const handleSearchClick = () => {
    setPage();
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }
  const debounceTime = window.__ENV && window.__ENV.debounceTime;
  useEffect(() => {
    if (filter) {
      const timeout = setTimeout(() => {
        handleSearchClick();
      }, debounceTime);
      setDebounceTimeout(timeout);
    }
  }, [filter])
  // ----------------------------------------SORTING-----------------------------------------------------------//
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = (column) => {
    if (sortOrder === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder(column);
      setSortDirection('asc');
    }
  };

  const sortedLeaveTrackerList = [...props.LeaveTrackerList].sort((a, b) => {
    const aValue = a[sortOrder] || 'NA';
    const bValue = b[sortOrder] || 'NA';
    if (sortOrder === 'TotalLeaveCount') {
      const aVal = parseFloat(a[sortOrder] || 0);
      const bVal = parseFloat(b[sortOrder] || 0);

      return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
    }
    return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });
  //-------------------------------------------------------------------------------------------------------------
  const handleCloseAction = () => {
    window.location.reload()
    props.clearFormAction();
    props.clearLeaveTrackerActionError();
    props.closeLeaveTrackerModal();

  }; //------------------------------------------Status filter -----------------------------------------------------
  const handleAllAction = () => {
    setBadgeCount(0);
    setAll(true);
    setRejected(false);
    setApproved(false);
    setSubmitted(false);
    setFilterParams({
      filter: "All"
    })
    setFilter({
      ...filter,
      LeaveStatus: "All",
    })
  };
  const handleSubmittedAction = () => {
    setBadgeCount(0);
    setAll(false);
    setRejected(false);
    setApproved(false);
    setSubmitted(true);
    setFilterParams({
      filter: "Submitted"
    })
    setFilter({
      ...filter,
      LeaveStatus: "Submitted",
    })
  };
  const handleRejectedAction = () => {
    setBadgeCount(0);
    setAll(false);
    setRejected(true);
    setApproved(false);
    setSubmitted(false);
    setFilterParams({
      filter: "Rejected"
    })
    setFilter({
      ...filter,
      LeaveStatus: "Rejected",
    })

  };
  const handleApprovedAction = () => {
    setBadgeCount(0);
    setAll(false);
    setRejected(false);
    setApproved(true);
    setSubmitted(false);
    setFilterParams({
      filter: "Approved"
    })
    setFilter({
      ...filter,
      LeaveStatus: "Approved",
    })
  };

  //-------------------------------------------Default Status---------------------------------------------------
  useEffect(() => {
    if (id === "Submitted") {
      setAll(false)
      setSubmitted(true)
      setRejected(false);
      setApproved(false);
      setFilter({
        ...filter,
        LeaveStatus: "Submitted",
      })
    }
    else if (id === "Approved") {
      setAll(false);
      setSubmitted(false);
      setRejected(false);
      setApproved(true);
      setFilter({
        ...filter,
        LeaveStatus: "Approved",
      })
    }
    else if (id === "Rejected") {
      setAll(false);
      setSubmitted(false);
      setRejected(true);
      setApproved(false);
      setFilter({
        ...filter,
        LeaveStatus: "Rejected",
      })
    }
    else if (id === "All") {
      setAll(true);
      setSubmitted(false);
      setRejected(false);
      setApproved(false);
      setFilter({
        ...filter,
        LeaveStatus: "All",
      })
    }
    else {
      setFilterParams('filter=All');
      setFilter({
        ...filter,
        LeaveStatus: "All",
      })
    }
  }, [])

  //-----------------------------------------Modal open & close-----------------------------------------------------

  const handleOpenLeaveApply = () => {
    props.openCreateApplyLeaveModal();
    props.getDisableCalenderDates();
  };
  const handleClose = () => {
    props.clearFormAction()
    props.closeCreateApplyLeaveModal();
    props.clearLeaveCountError();
    props.clearAvailableLeaveCountError();
    setPage();
  };

  const style = {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };

  const { userAppDetails } = props && props.currentProfile || {};
  const App = userAppDetails && userAppDetails.filter((i) => {
    return (i.appName === "LeaveTracker") ? i.appId : ""
  })

  const handleLoadLeaveType = (inputValue, callback) => {
    const { getLeaveTypeAsyncSelectAction } = props;
    return getLeaveTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleLoadEmployeeUserList = (inputValue, callback) => {
    const { getEmployeeUserAsyncSelectAction } = props;
    return getEmployeeUserAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };

  const handleSearchLeaveType = (value) => {
    const { label } = value || {};
    setFilter({
      ...filter,
      LeaveType: label,
    })
  }
  const handleSearchEmployeeUser = (Value) => {
    console.log("value:", Value);
    const { label, UserEmail } = Value || {};
    setFilter({
      ...filter,
      UserName: UserEmail,
    })
  }

  console.log("LeaveTypeCount:", props.LeaveTypeCount);
  console.log("userId:", userId);
  console.log("EmployeeList:", props.EmployeeList);

  // const EmployeeActive = props.EmployeeList && props.EmployeeList.filter((data) => data.UserId === userId).length
  // console.log("EmployeeActive:", EmployeeActive);

  const navigate = useNavigate();
  const OnDoubleClickAction = (Id) => {
    navigate(`/leavetracker/applyleave/details/${Id}`);
  };


  //-------------------------------------------------Export---------------------------------------------------------------------------
  const handleExports = () => {
    props.getMyLeavetrackerExportList(buildQueryuserName(), buildQueryFromDateTime(), buildQueryToDateTime(), buildQueryDays(), buildQueryReason(), buildQueryLeaveType(), buildQueryCategory(), buildQueryLeaveStatus(), buildQueryStatusDescription(), id)
      .then((response) => {
        if (response) {
          const exportLeavetrackerExportList = [];
          const headerExcel = [
            { headerName: "EMPLOYEE", key: "userName", headerWidhth: 30 },
            { headerName: "EMPLOYEE EMAIL", key: "userEmail", headerWidhth: 50 },
            { headerName: "FROM DATE", key: "fromDateTime", headerWidhth: 30 },
            { headerName: "TO DATE", key: "toDateTime", headerWidhth: 20 },
            { headerName: "LEAVE DAYS", key: "totalLeaveCount", headerWidhth: 20 },
            { headerName: "REASON", key: "leaveReason", headerWidhth: 40 },
            { headerName: "TYPE", key: "leaveType", headerWidhth: 20 },
            { headerName: "STATUS", key: "leaveStatus", headerWidhth: 20 },
          ]
          response && response.forEach((element) => {
            exportLeavetrackerExportList.push({
              userName: element.userName || "N/A",
              userEmail: element.userEmail || "N/A",
              fromDateTime: element.fromDateTime ? moment(element.fromDateTime).format('DD MMM YYYY') : 'N/A',
              toDateTime: element.toDateTime ? moment(element.toDateTime).format('DD MMM YYYY') : 'N/A',
              totalLeaveCount: element.totalLeaveCount || 0,
              leaveReason: element.leaveReason || "N/A",
              leaveType: element.leaveType || "N/A",
              leaveStatus: element.leaveStatus || "N/A",

            });
          });
          excelSheetExport(headerExcel, exportLeavetrackerExportList, "My Leave Tracker-List");
          // excelPrint(exportLeavetrackerExportList, "My Leave Tracker-List", headers, headerSizes);
        }
      })
  }

  // console.log("LeaveTrackerExportList:", LeaveTrackerExportList);


  return (
    <div className="page-content">
      <Card >
        <CardTitle
          tag="h4"
          className="border-bottom px-4 py-3 mb-0"
        >
          <Row>
            <Col lg='3' md='4' sm='4' className='d-flex align-items-center ps-0'>
              <strong className='d-flex align-items-center gap-2'>
                <div> MY LEAVE TRACKER</div>
                <div>-</div>
                <Badge color="danger">{props && props.totalCount}</Badge>
              </strong>
            </Col>
            <Col lg='7' md='5' sm='6' className='d-flex align-items-center justify-content-around ps-0 '>
              <Row>
                <Col xl='3' lg='3' md='6' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                  <Badges badgeContent={props.isLeaveTrackerPending ? null : badgeCount} color="primary" invisible={all !== true}>
                    <Button
                      style={{ width: '100px' }}
                      onClick={handleAllAction}
                      className={all !== true ? "btn btn-outline-secondary" : 'btn btn-outline-secondary active'}
                      outline color="secondary"
                    ><b>All</b>
                    </Button>
                  </Badges>
                </Col>
                <Col xl='3' lg='3' md='6' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                  <Badges badgeContent={props.isLeaveTrackerPending ? null : badgeCount} color="primary" invisible={Submitted !== true}>
                    <Button
                      style={{ width: '100px' }}
                      onClick={handleSubmittedAction}
                      className={Submitted !== true ? "btn btn-outline-primary " : 'btn btn-outline-primary  active'}
                      outline color="primary"
                    ><b>Submitted</b>
                    </Button>
                  </Badges>
                </Col>
                <Col xl='3' lg='3' md='6' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                  <Badges badgeContent={props.isLeaveTrackerPending ? null : badgeCount} color="primary" invisible={approved !== true}>
                    <Button
                      style={{ width: '100px' }}
                      onClick={handleApprovedAction}
                      className={approved !== true ? "btn btn-outline-success" : 'btn btn-outline-success active'}
                      outline color="success"
                    ><b>Approved</b>
                    </Button>
                  </Badges>
                </Col>
                <Col xl='3' lg='3' md='6' sm='6' xs='6' className='d-flex align-items-center pt-1'>
                  <Badges badgeContent={props.isLeaveTrackerPending ? null : badgeCount} color="primary" invisible={rejected !== true}>
                    <Button
                      style={{ width: '100px' }}
                      onClick={handleRejectedAction}
                      className={rejected !== true ? "btn btn-outline-danger " : 'btn btn-outline-danger  active'}
                      outline color="danger"
                    ><b>Rejected</b>
                    </Button>
                  </Badges>
                </Col>
              </Row>
            </Col>

            <Col lg='2' md='3' sm='2' xs='6' className='d-flex align-items-center justify-content-end pe-0'>
              <div className="content-heading">
                <div className="ml-auto d-flex align-items-center pt-1 flex-wrap">
                  {((props.LeaveTypeCount > 0) && (EmployeeActive > 0)) ?
                    <Button
                      color="primary"
                      className="btn btn-info me-2 mb-2"
                      onClick={handleOpenLeaveApply}
                      disabled={(props.LeaveTypeCount <= 0) && (EmployeeActive <= 0)}
                    >
                      CREATE
                    </Button>
                    :
                    <UITooltip id='applyLeave' key='applyLeave' content="You need to register in the employee module and the leave type should be activated.">
                      <div className="btn btn-light-primary me-2 mb-2"
                        style={{ cursor: "no-drop" }}>
                        CREATE
                      </div>
                    </UITooltip>
                  }
                  &nbsp; <Button
                    className="me-2 mb-2"
                    color="success"
                    onClick={handleExports}
                    disabled={props.LeaveTrackerList && props.LeaveTrackerList.length <= 0 || isLeaveTrackerExportPending}
                  >
                    {isLeaveTrackerExportPending ? 'EXPORTING...' : 'EXPORT'}
                  </Button>
                </div>

              </div>
            </Col>

          </Row>
        </CardTitle>
      </Card>
      <Card>
        <>
          <CardBody className="p-4">
            {props.errorMessage ? <div className='w-100'>
              {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
              )}
            </div> : ""}
            <div style={{ height: "60vh", overflowX: "auto" }}>
              <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                  <tr>
                    <th
                      onClick={() => handleSort('UserName')} className='hand-cursor'
                    >
                      Employee &nbsp;
                      {sortOrder === 'UserName' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('FromDateTime')} className='hand-cursor'
                    >
                      From Date &nbsp;
                      {sortOrder === 'FromDateTime' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('ToDateTime')} className='hand-cursor'
                    >To Date &nbsp;
                      {sortOrder === 'ToDateTime' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                    </th>
                    <th
                      onClick={() => handleSort('TotalLeaveCount')} className='hand-cursor'
                    >
                      Leave Days &nbsp;
                      {sortOrder === 'TotalLeaveCount' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('LeaveReason')} className='hand-cursor'
                    >
                      Reason &nbsp;
                      {sortOrder === 'LeaveReason' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('LeaveType')} className='hand-cursor'
                      style={{ minWidth: '150px' }}
                    >
                      Type &nbsp;
                      {sortOrder === 'LeaveType' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('LeaveStatus')} className='hand-cursor'
                    >
                      Status &nbsp;
                      {sortOrder === 'LeaveStatus' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                      View
                    </th>

                  </tr>
                  <tr>
                    <th>
                      {/* <Input
                        type='text'
                        placeholder='Search'
                        name='UserName'
                        onChange={searchInputValidation}
                        value={filter && filter.UserName}
                        onKeyPress={handleKeyPress}
                      /> */}
                      <Async
                        styles={customStyles}
                        name="UserName"
                        isClearable
                        defaultOptions
                        placeholder="Search"
                        loadOptions={handleLoadEmployeeUserList}
                        onChange={handleSearchEmployeeUser}
                      />
                    </th>
                    <th >
                      <Input
                        type='date'
                        placeholder='Search'
                        name='FromDateTime'
                        onChange={searchInputValidation}
                        value={filter && filter.FromDateTime}
                        onKeyPress={handleKeyPress}
                        max="9999-12-31"
                      /></th>
                    <th >
                      <Input
                        type='date'
                        placeholder='Search'
                        name='ToDateTime'
                        onChange={searchInputValidation}
                        value={filter && filter.ToDateTime}
                        onKeyPress={handleKeyPress}
                        max="9999-12-31"
                      /></th>
                    <th >
                      <Input
                        onKeyDown={
                          (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}
                        style={{ 'min-width': '100px' }}
                        type='number'
                        min='0'
                        placeholder='Search'
                        name='TotalLeaveCount'
                        onChange={searchInputValidation}
                        value={filter && filter.TotalLeaveCount}
                        onKeyPress={handleKeyPress}
                      /></th>
                    <th >
                      <Input
                        type='text'
                        placeholder='Search'
                        name='LeaveReason'
                        onChange={searchInputValidation}
                        value={filter && filter.LeaveReason}
                        onKeyPress={handleKeyPress}
                      /></th>
                    <th >
                      <Async
                        styles={customStyles}
                        name="LeaveType"
                        isClearable
                        defaultOptions
                        placeholder="Search"
                        loadOptions={handleLoadLeaveType}
                        onChange={handleSearchLeaveType}
                      />
                    </th>
                    <th >
                      <Input
                        type='text'
                        placeholder='Search'
                        name='LeaveStatus'
                        value={filter && filter.LeaveStatus}
                        disabled
                      />
                    </th>
                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {!props.isLeaveTrackerPending && (props.LeaveTrackerList && props.LeaveTrackerList.length > 0) ?
                    sortedLeaveTrackerList && sortedLeaveTrackerList.map((data) => (

                      <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                        <td>
                          {data.UserName || ""}<br />
                          <small>{data.UserEmail || ""}</small>
                        </td>
                        <td>{data.FromDateTime ? moment(data.FromDateTime).format('DD MMM YYYY') : 'N/A'}</td>
                        <td>{data.ToDateTime ? moment(data.ToDateTime).format('DD MMM YYYY') : 'N/A'}</td>
                        <td className='text-center'>{data.TotalLeaveCount || 0}</td>
                        <td className='ps-3'>{data.LeaveReason ? (data.LeaveReason && data.LeaveReason.length > 20) ? <><span id={`description-${data.Id}`}>{data.LeaveReason && data.LeaveReason.substring(0, 20) + '...'}</span>
                          <ElementToolTip
                            id={`description-${data.Id}`}
                            name={data.LeaveReason && data.LeaveReason.length > 20 ? data.LeaveReason : ''}
                          >
                            {data.LeaveReason && data.LeaveReason.length > 20
                              ? data.LeaveReason && data.LeaveReason.substring(0, 20) + '...'
                              : data.LeaveReason}
                          </ElementToolTip>
                        </>
                          : data.LeaveReason : "N/A"}
                        </td>
                        <td>{data.LeaveType || 'N/A'}</td>
                        <td className='text-capitalize'>
                          {data.LeaveStatus.toLowerCase() || "N/A"}
                        </td>
                        <td className='sticky-column' style={{ backgroundColor: "white" }}> <Link
                          type="button"
                          className="btn btn-sm  btn-light"
                          to={`/leavetracker/applyleave/details/${data.Id}`}
                        >
                          <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                        </Link></td>
                      </tr>
                    ))
                    : props.isLeaveTrackerPending ?
                      <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <VWSpinner />
                          </div>
                        </div>
                      </div>
                      :
                      <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <NoRecord />
                          </div>
                        </div>
                      </div>
                  }</tbody>
              </Table>
            </div>
          </CardBody>
          <CardFooter>
            {props.LeaveTrackerList && props.LeaveTrackerList.length > 0 ?
              <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
            }
          </CardFooter>
        </>
      </Card>
      <VWModel
        header="LEAVE TRACKER STATUS"
        isOpen={props.isActionLeaveTrackerModalOpen}
        handleClose={handleCloseAction}
      >
        <LeaveTrackerStatusAction data={actionList}
          handleClose={handleCloseAction}
        />
      </VWModel>
      <VWModel
        // modalSize="large"
        header="APPLY LEAVE"
        isOpen={props.isCreateleaveModalOpen}
        handleClose={handleClose}
      >
        <CreateLeave
          filteredData={filter}
          handleClose={handleClose}
        />
      </VWModel>
    </div>
  );
}
const mapStateToProps = (state) => ({
  isActionLeaveTrackerModalOpen: state.leaveTracker.isLeaveTrackerModalOpen,
  isCreateleaveModalOpen: state.leaveTracker.isCreateleaveModalOpen,
  LeaveTrackerList: state.leaveTracker.LeaveTrackerList,
  errorMessage: state.leaveTracker.leaveTrackerListError,
  totalCount: state.leaveTracker.totalRecords,
  currentProfile: state.login.currentProfile,
  isLeaveTrackerPending: state.leaveTracker.isLeaveTrackerPending,
  LeaveTypeCount: state.leaveTracker.LeaveTypeCount,
  EmployeeList: state.Employee.EmployeeList,
  // LeaveTrackerExportList: state.leaveTracker.LeaveTrackerExportList,
  isLeaveTrackerExportPending: state.leaveTracker.isLeaveTrackerExportPending,
});
const mapDispatchToProps = (dispatch) => ({
  getleaveListAction: (filter, page, PageSize, searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id) => dispatch(getLeaveTrackerListActionModal(filter, page, PageSize, searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id)),
  getMyLeavetrackerExportList: (searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id) => dispatch(getMyLeavetrackerExportList(searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id)),
  checkEmployeeUserAccess: (userId) => dispatch(checkEmployeeUserAccess(userId)),
  closeLeaveTrackerModal: () => dispatch(closeLeaveTrackerModalAction()),
  openLeaveTrackerModal: () => dispatch(openActionLeaveTrackerModal()),
  clearFormAction: () => dispatch(clearForm()),
  clearLeaveTrackerActionError: () => dispatch(clearLeaveTrackerActionError()),
  closeCreateApplyLeaveModal: () => dispatch(closeCreateApplyLeaveModalAction()),
  openCreateApplyLeaveModal: () => dispatch(openCreateApplyLeaveModalAction()),
  getDisableCalenderDates: () => dispatch(getDisableCalenderDates()),
  clearLeaveCountError: () => dispatch(clearLeaveCountError()),
  clearAvailableLeaveCountError: () => dispatch(clearAvailableLeaveCountError()),
  ClearPath: () => dispatch(clearSetPath()),
  getLeaveTypeCount: () =>
    dispatch(getLeaveTypeCount()),
  getEmployeeList: () => dispatch(getEmployeeList()),
  getLeaveTypeAsyncSelectAction: (searchValue, callback) =>
    dispatch(getLeaveTypeListAsyncSelect(searchValue, callback)),
  getEmployeeUserAsyncSelectAction: (appId, searchValue, callback) =>
    dispatch(getEmployeeUserAsyncSelect(appId, searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveMonitoringList)