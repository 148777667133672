import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
} from "reactstrap";
import {
    clearCampaignEditError,
    closeEditCampaignModal,
    editCampaignDetails,
    getFromAddressAsyncSelectAction
} from "../../../../store/Modules/MassMailer/Campaign/actions";
import Select from 'react-select';
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import * as FileSaver from "file-saver";
import {
    CAMPAIGN_CONTACT_EXPORT_HEADERS,
    MAIL_CATEGORY_OPTIONS
} from "../../../../store/Modules/MassMailer/Campaign/constants";
import Async from 'react-select/async';
import { excelPrint } from "../../../../helper/export";
import UITooltip from "../../../../CommonData/Data/UITooltip";

const EditCampaign = (props) => {

    const { campaignDetails, contactListCount, campaignContactList, HTMLFile, isEditCampaignPending, errorMessage, ErrorMessage } = props;
    const [updatedCampaignDetails, setUpdatedCampaignDetails] = useState();
    const [mailCategory, setMailCategory] = useState();
    const [fromAddress, setFromAddress] = useState();
    const [listCount, setListCount] = useState();
    const [htmlFile, setHtmlFile] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        if (campaignDetails) {
            setUpdatedCampaignDetails({
                ...campaignDetails,
                FromAddress: campaignDetails.mailSettingId
            });
            const sendType = {
                label: campaignDetails.type || 'Select...',
                value: campaignDetails.type || null,
            };
            setMailCategory(sendType);
            const fromMail = {
                label: `${campaignDetails.fromDisplayName} | ${campaignDetails.emailAddress}` || 'Select...',
                value: campaignDetails.mailSettingId || null,
            };
            setFromAddress(fromMail);
            if (HTMLFile) {
                const link = campaignDetails && campaignDetails.indexFileName ? true : false;
                setHtmlFile(link);
            }
        }

        setListCount(contactListCount);
        return () => {
            props.clearErrorMessage();
        }
    }, [])

    //----------------------------------------------------DOWNLOAD---------------------------------------------------------------------------------------
    const handleSampleList = () => {
        const ws = XLSX.utils.aoa_to_sheet([["Email"]]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'contactList.xls');
    }

    const handleDownloadContact = () => {
        const exportCampaignContactList = [];
        const headers = CAMPAIGN_CONTACT_EXPORT_HEADERS;
        const fileName = `${campaignDetails && campaignDetails.campaignName}ContactList`
        const headerSizes = [50];
        campaignContactList.forEach((element) => {
            exportCampaignContactList.push({
                email: element.email || "NA",
            });
        });
        excelPrint(exportCampaignContactList, fileName, headers, headerSizes);
    }

    const handleDownload = () => {
        const fileName = campaignDetails && campaignDetails.indexFileName;
        if (HTMLFile) {
            const html = new Blob([HTMLFile]);
            FileSaver.saveAs(html, fileName);
        }
    }

    //----------------------------------------------------------VIEW-NEW-PAGE------------------------------------------------------------------------
    const handleHTMLView = () => {
        if (HTMLFile) {
            const html = new Blob([HTMLFile], { type: 'text/html' });;
            const url = URL.createObjectURL(html);
            window.open(url, '_blank');
        }
    }

    //-------------------------------------------------------------CLEAR-BUTTONS----------------------------------------------------------------------
    const clearCustomerList = () => {
        setListCount(0)
    }

    const clearHTMLFile = () => {
        setHtmlFile(false)
    }

    //--------------------------------------------------FORM-INPUTS----------------------------------------------------------------------------------------
    const handleChange = (e) => {
        const input = e.target;
        const value = input.type === 'file' ? input.files[0] : input.value;

        setUpdatedCampaignDetails({
            ...updatedCampaignDetails,
            [input.name]: value,
        })
        setErrors({
            ...errors,
            [input.name]: "",
        })
    }

    const handleMailCategory = (e) => {
        setMailCategory(e)
        setUpdatedCampaignDetails({
            ...updatedCampaignDetails,
            type: e.value,
        })
        setErrors({
            ...errors,
            type: "",
        })
    }

    const getFromAddressOption = (inputValue, callback) => {
        const { getFromAddressAsyncSelectAction } = props;
        return getFromAddressAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    }

    const handleFromAddressSelect = (Value) => {
        setUpdatedCampaignDetails({
            ...updatedCampaignDetails,
            FromAddress: Value ? Value.value : "",
        })
        setErrors({
            ...errors,
            FromAddress: "",
        })
    }

    //-------------------------------------------------------------------FORM-SUBMISSION----------------------------------------------------------------
    const handleSubmit = (e) => {
        const { editCampaignDetails } = props;
        e.preventDefault();
        const newErrors = {};
        const { campaignName, type, subject, FromAddress } = updatedCampaignDetails || {};

        if (!campaignName || !campaignName.trim()) {
            newErrors.campaignName = 'Campaign name is required';
        }
        if (!type || !type.trim()) {
            newErrors.type = 'Mail category is required';
        }
        if (!subject || !subject.trim()) {
            newErrors.subject = 'Subject is required';
        }
        if (!FromAddress || !FromAddress.trim()) {
            newErrors.FromAddress = 'From address is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            editCampaignDetails(updatedCampaignDetails);
        }
    }

    console.log("errorMessage: ", errorMessage)
    return (
        <>
            {(errorMessage || ErrorMessage) && (
                <VWMessage type="danger" defaultMessage={errorMessage || ErrorMessage} baseClassName="alert" />
            )}
            <Form>
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <FormGroup>
                            <Label>
                                Campaign Name <span className="text-danger"> *</span>
                            </Label>
                            < Input
                                type="text"
                                name="campaignName"
                                placeHolder='Enter campaign name'
                                onChange={handleChange}
                                value={updatedCampaignDetails && updatedCampaignDetails.campaignName}
                                invalid={errors && errors.campaignName}
                            />
                            <FormFeedback>{errors && errors.campaignName}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <FormGroup>
                            <Label>
                                Mail Category <span className="text-danger"> *</span>
                            </Label>
                            <Select
                              //  className="vm-react-select"
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.type) ? 'red' : ""
                                    })
                                }}
                                placeholder='Select mail categroy'
                                isSearchable
                                value={mailCategory}
                                options={MAIL_CATEGORY_OPTIONS}
                                onChange={handleMailCategory}
                            />
                            <FormText color="danger">{errors && errors.type}</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label>From Address <span className="text-danger"> *</span></Label>
                    <Async
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: (errors && errors.FromAddress) ? 'red' : ""
                            })
                        }}
                        name="FromAddress"
                        placeholder="Select from address"
                        isSearchable
                        defaultOptions
                        loadOptions={getFromAddressOption}
                        onChange={handleFromAddressSelect}
                        value={fromAddress}
                    />
                    <FormText>
                        <div className="text-danger">{errors && errors.FromAddress}</div>
                    </FormText>
                </FormGroup>
                <FormGroup>
                    <Label>Subject <span className="text-danger"> *</span></Label>
                    <Input
                        type="text"
                        name="subject"
                        placeHolder='Enter subject'
                        onChange={handleChange}
                        value={updatedCampaignDetails && updatedCampaignDetails.subject}
                        invalid={errors && errors.subject}
                    />
                    <FormFeedback>{errors && errors.subject}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Contact List</Label>
                    {listCount === 0 ?
                        <div>
                            < Input
                                type="file"
                                name="ContactList"
                                placeHolder='Select file'
                                onChange={handleChange}
                            />
                            <div className="p-1">
                                <small>Refer this template file for import</small>&nbsp;
                                <Link
                                    onClick={handleSampleList}
                                >
                                    <small>download.</small>
                                </Link>
                            </div>
                        </div> :
                        <div className="d-flex align-items-center">
                            <div
                                className="text-primary"
                                style={{ fontSize: "14px" }}
                            >
                                {`No. of contacts ${props.contactListCount}`}
                            </div>
                            <UITooltip id='downloadContact' key='downloadContact' content="Download as .xls">
                                <Button
                                    className='p-0'
                                    color='link'
                                    style={{ fontSize: "30px", marginLeft: "5px" }}
                                    onClick={handleDownloadContact}
                                >
                                    <i className='mdi mdi-download-box text-success' />
                                </Button>
                            </UITooltip>
                            <UITooltip id='clearContact' key='clearContact' content="Clear">
                                <Button
                                    className='p-0'
                                    color='link'
                                    style={{ fontSize: "30px" }}
                                    onClick={clearCustomerList}
                                >
                                    <i className='mdi mdi-close-box text-danger' />
                                </Button>
                            </UITooltip>
                        </div>
                    }
                </FormGroup>
                <FormGroup>
                    <Label>HTML File</Label>
                    {!htmlFile ?
                        <div>
                            < Input
                                type="file"
                                name="IndexFile"
                                onChange={handleChange}
                                accept=".htm,.html"
                            />
                        </div> :
                        <div className="py-0">
                            <Button
                                className='p-0'
                                color="link"
                                onClick={handleHTMLView}
                            >
                                {updatedCampaignDetails.indexFileName}
                            </Button>
                            <UITooltip id='downloadHTML' key='downloadHTML' content="Download as .html">
                                <Button
                                    className='p-0'
                                    color='link'
                                    style={{ fontSize: "30px", marginLeft: "5px" }}
                                    onClick={handleDownload}
                                >
                                    <i className='mdi mdi-download-box text-success' />
                                </Button>
                            </UITooltip>
                            <UITooltip id='clearHTML' key='clearHTML' content="Clear">
                                <Button
                                    className='p-0'
                                    color='link'
                                    style={{ fontSize: "30px" }}
                                    onClick={clearHTMLFile}
                                >
                                    <i className='mdi mdi-close-box text-danger' />
                                </Button>
                            </UITooltip>
                        </div>
                    }
                </FormGroup>
                <FormGroup>
                    <Button
                        color="success"
                        onClick={handleSubmit}
                        disabled={isEditCampaignPending}
                    >
                        {isEditCampaignPending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        className='ms-2'
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    campaignDetails: state.campaign.campaignDetails,
    HTMLFile: state.campaign.HTMLFile,
    campaignContactList: state.campaign.campaignContactList,
    contactListCount: state.campaign.contactListCount,
    isEditCampaignPending: state.campaign.isEditCampaignPending,
    errorMessage: state.campaign.campaignEditErrorMessage,
    ErrorMessage: state.campaign.CampaignFromAddressErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    editCampaignDetails: (campaignDetails) => dispatch(editCampaignDetails(campaignDetails)),
    clearErrorMessage: () => dispatch(clearCampaignEditError()),
    getFromAddressAsyncSelectAction: (searchValue, callback) => dispatch(getFromAddressAsyncSelectAction(searchValue, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaign);