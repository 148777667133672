import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    List,
    Row
} from 'reactstrap';
import { settingsSidebarData } from './settingsSidebar';
import { Link, useLocation } from 'react-router-dom';
import './style.css'

const CRMSettings = (props) => {
    const location = useLocation();
    const { pathname } = location || {};
    const separatedPath = pathname && pathname.split('/');
    const activeParams = (separatedPath && separatedPath.length > 0) ? separatedPath[3] : null;

    const [menuOpen, setMenuOpen] = useState(false);
    const [activeParent, setActiveParent] = useState();
    const [pageWidth, setPageWidth] = useState();

    const pageRef = useRef();

    useEffect(() => {
        const updateWidth = () => {
            if (pageRef.current) {
                setPageWidth(pageRef.current.offsetWidth);
            }
        }
        updateWidth();
        window.addEventListener('resize', updateWidth);
    }, [pageRef.current])

    useEffect(() => {
        if (activeParams) {
            if (activeParams === 'leads') {
                setActiveParent('Leads');
                setMenuOpen(true);
            }
            if (activeParams === 'accounts') {
                setActiveParent('Accounts');
                setMenuOpen(true);
            }
            if (activeParams === 'opportunity') {
                setActiveParent('Opportunity');
                setMenuOpen(true);
            }
        }
    }, [])

    const handleMenuOpen = (e, value) => {
        if (value === activeParent) {
            setMenuOpen(!menuOpen);
        }
        else {
            setMenuOpen(true);
        }
        setActiveParent(value)
    }
    return (
        <>
            <div className='page-content' ref={pageRef}>
                <Row className='pt-2 px-3 d-flex justify-content-between' style={{ minHeight: '70vh' }}>
                    <Col xl={3} lg={3} md={4} sm={12} className='mb-1 ms-0 rounded-3' style={{ backgroundColor: '#fff', minHeight: '70vh', display: 'flex', flexDirection: 'column' }}>
                        <Card className='shadow-none' style={{ backgroundColor: "transparent" }}>
                            <CardBody style={{ flexGrow: 1 }}>
                                <List type="unstyled">
                                    {settingsSidebarData && settingsSidebarData.map((content) =>
                                        <li className='pb-1' style={{ fontSize: "15px" }}>
                                            <Link
                                                className={(activeParent === content.label) || (pathname === content.url) ? 'active-state align-button' : 'normal-state align-button'}
                                                to={content.url ? content.url : "/#"}
                                                onClick={(e) => handleMenuOpen(e, content.label)}
                                            >
                                                <div className='d-flex align-items-center'>
                                                    <i className={`${content.icon} icon-size`} />
                                                    {content.label}
                                                </div>
                                                <span className={(menuOpen && (activeParent === content.label)) ? "mdi mdi-chevron-down fs-3" : "mdi mdi-chevron-up fs-3"} />
                                            </Link>
                                            {(content.subItem && menuOpen && (activeParent === content.label)) &&
                                                <List type="unstyled">
                                                    {content.subItem.map((item, key) => (
                                                        <li className='ms-4' key={key}>
                                                            <Link
                                                                className={pathname === item.url ? 'active-state align-button-subCategory pe-4' : 'normal-state align-button-subCategory'}
                                                                to={item.url}
                                                            >
                                                                <div className='d-flex align-items-center'>
                                                                    <i className={`${item.icon} icon-size`} />
                                                                    {item.label}
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </List>
                                            }
                                        </li>
                                    )}
                                </List>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={9} lg={9} md={8} sm={12} className='ps-1' style={{ minHeight: '70vh', display: 'flex', flexDirection: 'column' }}>
                        <Card style={{ height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>{props.children}</div>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default CRMSettings;