import React, { useEffect, useState } from 'react'
import {
    Card,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSuitcase,
    faAddressCard,
    faTags,
    faIdCardAlt,
    faPerson,
    faEye,
    faCalendarWeek,
    faCalendarDays,
    faScrewdriverWrench,
    faFilePen,
    faArrowTrendDown,
    faArrowTrendUp
} from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';
import { crmDashboardClearError, dashboardClearError, getCrmDashboardCount } from '../../../../store/Modules/crm/Dashboard/actions';
import { Link, useNavigate } from 'react-router-dom';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import FunnelChart from '../funnelChart';
import './Dashboard.css';
import moment from 'moment-timezone';

const Dashboard = (props) => {
    const { profile, DashboardCounts } = props;
    const { displayName } = profile || {};

    const [toggle, setToggle] = useState('week')
    const [fromDate, setFromDate] = useState(moment().startOf('week').format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState(moment().endOf('week').format('YYYY-MM-DD'))

    useEffect(() => {
        if (fromDate && toDate && toggle === 'custom') {
            props.crmDashboardCount(null, moment(fromDate).format('MM/DD/YYYY'), moment(toDate).format('MM/DD/YYYY'));
            setToggle('custom');
        }
        else if (toggle !== 'custom') {
            props.crmDashboardCount(toggle);
        }

    }, [fromDate, toDate, toggle])

    const handleDateFilter = (e) => {
        const { name, value } = e.target || {};
        if (name === 'fromDate') {
            setFromDate(value);
        }
        if (name === 'toDate') {
            setToDate(value);
        }
    }

    useEffect(() => {
        // props.crmDashboardCount();
        props.crmDashboardClearError();
    }, [])
    console.log("DashboardCounts:", DashboardCounts);
    console.log("dashboardErrorMessage:", props.dashboardErrorMessage);
    console.log("isDashboardPending:", props.isDashboardPending);

    //---------------Leads----------------------------
    const leadsCurrentWeek = DashboardCounts && DashboardCounts.currentLeadsCount;
    const leadsLastWeek = DashboardCounts && DashboardCounts.previousLeadsCount;
    const LeadsWeekDiff = DashboardCounts && DashboardCounts.diffLeadValue;
    const LeadsWeekDiffType = DashboardCounts && DashboardCounts.diffLeadType;
    const leadsCurrentMonth = DashboardCounts && DashboardCounts.currentLeadsCount;
    const leadsLastMonth = DashboardCounts && DashboardCounts.previousLeadsCount;
    const LeadsMonthDiff = DashboardCounts && DashboardCounts.diffLeadValue;
    const LeadsMonthDiffType = DashboardCounts && DashboardCounts.diffLeadType;
    const leadsCustomDate = DashboardCounts && DashboardCounts.customLeadsCount;

    //--------------Accounts---------------------------------------------------
    const accountsCurrentWeek = DashboardCounts && DashboardCounts.currentAccountsCount;
    const accountsLastWeek = DashboardCounts && DashboardCounts.previousAccountsCount;
    const accountsWeekDiff = DashboardCounts && DashboardCounts.diffAccountValue;
    const accountsWeekDiffType = DashboardCounts && DashboardCounts.diffAccountType;
    const accountsCurrentMonth = DashboardCounts && DashboardCounts.currentAccountsCount;
    const accountsLastMonth = DashboardCounts && DashboardCounts.previousAccountsCount;
    const accountsMonthDiff = DashboardCounts && DashboardCounts.diffAccountValue;
    const accountsMonthDiffType = DashboardCounts && DashboardCounts.diffAccountType;
    const accountsCustomDate = DashboardCounts && DashboardCounts.customAccountsCount;
    //--------------Contacts---------------------------------------------------
    const contactsCurrentWeek = DashboardCounts && DashboardCounts.currentContactsCount;
    const contactsLastWeek = DashboardCounts && DashboardCounts.previousContactsCount;
    const contactsWeekDiff = DashboardCounts && DashboardCounts.diffContactValue;
    const contactsWeekDiffType = DashboardCounts && DashboardCounts.diffContactType;
    const contactsCurrentMonth = DashboardCounts && DashboardCounts.currentContactsCount;
    const contactsLastMonth = DashboardCounts && DashboardCounts.previousContactsCount;
    const contactsMonthDiff = DashboardCounts && DashboardCounts.diffContactValue;
    const contactsMonthDiffType = DashboardCounts && DashboardCounts.diffContactType;
    const contactsCustomDate = DashboardCounts && DashboardCounts.customContactsCount;
    //--------------Opportunity---------------------------------------------------
    const opportunityCurrentWeek = DashboardCounts && DashboardCounts.currentOpportunitiesCount;
    const opportunityLastWeek = DashboardCounts && DashboardCounts.previousOpportunitiesCount;
    const opportunityWeekDiff = DashboardCounts && DashboardCounts.diffOpportunityValue;
    const opportunityWeekDiffType = DashboardCounts && DashboardCounts.diffOpportunityType;
    const opportunityCurrentMonth = DashboardCounts && DashboardCounts.currentOpportunitiesCount;
    const opportunityLastMonth = DashboardCounts && DashboardCounts.previousOpportunitiesCount;
    const opportunityMonthDiff = DashboardCounts && DashboardCounts.diffOpportunityValue;
    const opportunityMonthDiffType = DashboardCounts && DashboardCounts.diffOpportunityType;
    const opportunityCustomDate = DashboardCounts && DashboardCounts.customOpportunitiesCount;
    //--------------Visits---------------------------------------------------
    const visitsCurrentWeek = DashboardCounts && DashboardCounts.currentVisitsCount;
    const visitsLastWeek = DashboardCounts && DashboardCounts.previousVisitsCount;
    const visitsWeekDiff = DashboardCounts && DashboardCounts.diffVisitValue;
    const visitsWeekDiffType = DashboardCounts && DashboardCounts.diffVisitType;
    const visitsCurrentMonth = DashboardCounts && DashboardCounts.currentVisitsCount;
    const visitsLastMonth = DashboardCounts && DashboardCounts.previousVisitsCount;
    const visitsMonthDiff = DashboardCounts && DashboardCounts.diffVisitValue;
    const visitsMonthDiffType = DashboardCounts && DashboardCounts.diffVisitType;
    const visitsCustomDate = DashboardCounts && DashboardCounts.customVisitsCount;

    const navigate = useNavigate();
    const handleLeads = (e) => {
        navigate(`/crm/leads?startDate=${fromDate}&endDate=${toDate}`)
    }
    const handleAccounts = (e) => {
        navigate(`/crm/accounts?startDate=${fromDate}&endDate=${toDate}`)
    }
    const handleContacts = (e) => {
        navigate(`/crm/contacts?startDate=${fromDate}&endDate=${toDate}`)
    }
    const handleOpportunity = (e) => {
        navigate(`/crm/opportunity?startDate=${fromDate}&endDate=${toDate}`)
    }
    const handleVisits = (e) => {
        navigate(`/crm/visits?startDate=${fromDate}&endDate=${toDate}`)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>DASHBOARD</strong>
                    </CardTitle>
                </Card>
                {props.dashboardErrorMessage ? <div className='w-100'>
                    {props.dashboardErrorMessage && (
                        <VWMessage type="danger" defaultMessage={props.dashboardErrorMessage} baseClassName="alert" ></VWMessage>
                    )}
                </div> : ""}
                <>
                    <Row>
                        <Col xl="4" lg="4" md="12">
                            <Card className="p-4">
                                <div className='fs-5 fw-bold mb-3'>
                                    Filters
                                </div>
                                <div>
                                    <Row>
                                        <Col xl="7" lg="12" md="6" className="d-flex align-items-center justify-content-center">
                                            <div className="button-container">
                                                <button
                                                    className={toggle === 'week' ? 'button-design-active' : 'button-design'}
                                                    onClick={() => {
                                                        setToggle('week');
                                                        setFromDate(moment().startOf('week').format('YYYY-MM-DD'));
                                                        setToDate(moment().endOf('week').format('YYYY-MM-DD'));
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCalendarWeek} style={{ paddingRight: "10px" }} />
                                                    Week
                                                </button>
                                                <button
                                                    className={toggle === 'month' ? 'button-design-active' : 'button-design'}
                                                    onClick={() => {
                                                        setToggle('month');
                                                        setFromDate(moment().startOf('month').format('YYYY-MM-DD'));
                                                        setToDate(moment().endOf('month').format('YYYY-MM-DD'));
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faCalendarDays} style={{ paddingRight: "10px" }} />
                                                    Month
                                                </button>
                                                <button
                                                    className={toggle === 'custom' ? 'button-design-active' : 'button-design d-flex  align-items-center'}
                                                    onClick={() => setToggle('custom')}
                                                >
                                                    <FontAwesomeIcon icon={faScrewdriverWrench} style={{ paddingRight: "10px" }} />
                                                    Custom
                                                </button>
                                            </div>
                                        </Col>
                                        <Col xl="5" lg="10" md="6">
                                            <div className='d-grid gap-2'>
                                                <FormGroup>
                                                    <Label>Start Date</Label>
                                                    <Input
                                                        type='date'
                                                        name='fromDate'
                                                        placeHolder='search'
                                                        onChange={handleDateFilter}
                                                        value={fromDate ? moment(fromDate).format('YYYY-MM-DD') : 'N/A'}
                                                        disabled={toggle !== 'custom'}
                                                        max={toDate}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>End Date</Label>
                                                    <Input
                                                        type='date'
                                                        name='toDate'
                                                        placeHolder='search'
                                                        onChange={handleDateFilter}
                                                        value={toDate ? moment(toDate).format('YYYY-MM-DD') : 'N/A'}
                                                        disabled={toggle !== 'custom'}
                                                        min={fromDate}
                                                        max="9999-12-31"
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        {props.isDashboardPending ? (
                            <tr className="d-flex justify-content-center align-items-center mt-2">
                                <td colSpan={8}>
                                    <div className="d-flex justify-content-center mt-2">
                                        <VWSpinner />
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            <Col xl="8" lg="8" md="12">
                                <Card className="p-4">
                                    <div className='fs-5 fw-bold mb-3'>
                                        Your Crm info
                                    </div>
                                    <div>
                                        <Row>
                                            <Col xl="4" lg="6" md="4" className="mb-3">
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                                                    onClick={(e) => handleLeads(e)}
                                                >
                                                    <div className='section-1'>
                                                        <div className='d-flex'>
                                                            <div className='fs-1 fw-bold mt-2 card-1-color'>
                                                                {toggle === "week" ? leadsCurrentWeek || '0' : toggle === "month" ? leadsCurrentMonth || '0' : leadsCustomDate || '0'}
                                                            </div>
                                                        </div>
                                                        <div className='badge-icon bg-secondary'>
                                                            <FontAwesomeIcon icon={faSuitcase} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    {toggle !== "custom" &&
                                                        <>
                                                            <div className='d-flex align-items-center ps-3'>
                                                                <FontAwesomeIcon
                                                                    icon={(toggle === "week" ? (LeadsWeekDiffType === 'Decrement') : (LeadsMonthDiffType === 'Decrement')) ? faArrowTrendDown : faArrowTrendUp}
                                                                    style={{ color: (LeadsWeekDiffType === 'Decrement') ? "red" : "green", fontSize: "18px" }}
                                                                />
                                                                <div className='ps-2'>
                                                                    {(toggle === "week" ? (LeadsWeekDiffType === 'Decrement') : (LeadsMonthDiffType === 'Decrement')) ?
                                                                        <div className='fw-bold text-danger'> - </div>
                                                                        : <div className='fw-bold text-success'> + </div>
                                                                    }
                                                                </div>
                                                                <div className={(toggle === "week" ? (LeadsWeekDiffType === 'Decrement') : (LeadsMonthDiffType === 'Decrement')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                                                                    {toggle === "week" ? LeadsWeekDiff : LeadsMonthDiff}
                                                                </div>
                                                            </div>
                                                            <div className='d-flex gap-2 ps-3'>
                                                                {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                                                                <div className='fs-6 fw-bold'>
                                                                    {toggle === "week" ? leadsLastWeek || '0' : leadsLastMonth || '0'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className='p-2 fw-bold rounded-bottom bg-secondary text-white'>
                                                        Leads
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xl="4" lg="6" md="4" className="mb-3">
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                                                    onClick={(e) => handleAccounts(e)}
                                                >
                                                    <div className='section-1'>
                                                        <div className='d-flex'>
                                                            <div className='fs-1 fw-bold mt-2 card-1-color'>
                                                                {toggle === "week" ? accountsCurrentWeek || '0' : toggle === "month" ? accountsCurrentMonth || '0' : accountsCustomDate || '0'}
                                                            </div>
                                                        </div>
                                                        <div className='badge-icon bg-primary'>
                                                            <FontAwesomeIcon icon={faIdCardAlt} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    {toggle !== "custom" &&
                                                        <>
                                                            <div className='d-flex align-items-center ps-3'>
                                                                <FontAwesomeIcon
                                                                    icon={(toggle === "week" ? (accountsWeekDiffType === 'Decrement') : (accountsMonthDiffType === 'Decrement')) ? faArrowTrendDown : faArrowTrendUp}
                                                                    style={{ color: (accountsWeekDiffType === 'Decrement') ? "red" : "green", fontSize: "18px" }}
                                                                />
                                                                <div className='ps-2'>
                                                                    {(toggle === "week" ? (accountsWeekDiffType === 'Decrement') : (accountsMonthDiffType === 'Decrement')) ?
                                                                        <div className='fw-bold text-danger'> - </div>
                                                                        : <div className='fw-bold text-success'> + </div>
                                                                    }
                                                                </div>
                                                                <div className={(toggle === "week" ? (accountsWeekDiffType === 'Decrement') : (accountsMonthDiffType === 'Decrement')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                                                                    {toggle === "week" ? accountsWeekDiff : accountsMonthDiff}
                                                                </div>
                                                            </div>
                                                            <div className='d-flex gap-2 ps-3'>
                                                                {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                                                                <div className='fs-6 fw-bold'>
                                                                    {toggle === "week" ? accountsLastWeek || '0' : accountsLastMonth || '0'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className='p-2 fw-bold rounded-bottom bg-primary text-white'>
                                                        Accounts
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xl="4" lg="6" md="4" className="mb-3">
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                                                    onClick={(e) => handleContacts(e)}
                                                >
                                                    <div className='section-1'>
                                                        <div className='d-flex'>
                                                            <div className='fs-1 fw-bold mt-2 card-1-color'>
                                                                {toggle === "week" ? contactsCurrentWeek || '0' : toggle === "month" ? contactsCurrentMonth || '0' : contactsCustomDate || '0'}
                                                            </div>
                                                        </div>
                                                        <div className='badge-icon bg-success'>
                                                            <FontAwesomeIcon icon={faAddressCard} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    {toggle !== "custom" &&
                                                        <>
                                                            <div className='d-flex align-items-center ps-3'>
                                                                <FontAwesomeIcon
                                                                    icon={(toggle === "week" ? (contactsWeekDiffType === 'Decrement') : (contactsMonthDiffType === 'Decrement')) ? faArrowTrendDown : faArrowTrendUp}
                                                                    style={{ color: (contactsWeekDiffType === 'Decrement') ? "red" : "green", fontSize: "18px" }}
                                                                />
                                                                <div className='ps-2'>
                                                                    {(toggle === "week" ? (contactsWeekDiffType === 'Decrement') : (contactsMonthDiffType === 'Decrement')) ?
                                                                        <div className='fw-bold text-danger'> - </div>
                                                                        : <div className='fw-bold text-success'> + </div>
                                                                    }
                                                                </div>
                                                                <div className={(toggle === "week" ? (contactsWeekDiffType === 'Decrement') : (contactsMonthDiffType === 'Decrement')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                                                                    {toggle === "week" ? contactsWeekDiff : contactsMonthDiff}
                                                                </div>
                                                            </div>
                                                            <div className='d-flex gap-2 ps-3'>
                                                                {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                                                                <div className='fs-6 fw-bold'>
                                                                    {toggle === "week" ? contactsLastWeek || '0' : contactsLastMonth || '0'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className='p-2 fw-bold rounded-bottom bg-success text-white'>
                                                        Contacts
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xl="4" lg="6" md="4" className="mb-3">
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                                                    onClick={(e) => handleOpportunity(e)}
                                                >
                                                    <div className='section-1'>
                                                        <div className='d-flex'>
                                                            <div className='fs-1 fw-bold mt-2 card-1-color'>
                                                                {toggle === "week" ? opportunityCurrentWeek || '0' : toggle === "month" ? opportunityCurrentMonth || '0' : opportunityCustomDate || '0'}
                                                            </div>
                                                        </div>
                                                        <div className='badge-icon bg-danger'>
                                                            <FontAwesomeIcon icon={faTags} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    {toggle !== "custom" &&
                                                        <>
                                                            <div className='d-flex align-items-center ps-3'>
                                                                <FontAwesomeIcon
                                                                    icon={(toggle === "week" ? (opportunityWeekDiffType === 'Decrement') : (opportunityMonthDiffType === 'Decrement')) ? faArrowTrendDown : faArrowTrendUp}
                                                                    style={{ color: (opportunityWeekDiffType === 'Decrement') ? "red" : "green", fontSize: "18px" }}
                                                                />
                                                                <div className='ps-2'>
                                                                    {(toggle === "week" ? (opportunityWeekDiffType === 'Decrement') : (opportunityMonthDiffType === 'Decrement')) ?
                                                                        <div className='fw-bold text-danger'> - </div>
                                                                        : <div className='fw-bold text-success'> + </div>
                                                                    }
                                                                </div>
                                                                <div className={(toggle === "week" ? (opportunityWeekDiffType === 'Decrement') : (opportunityMonthDiffType === 'Decrement')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                                                                    {toggle === "week" ? opportunityWeekDiff : opportunityMonthDiff}
                                                                </div>
                                                            </div>
                                                            <div className='d-flex gap-2 ps-3'>
                                                                {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                                                                <div className='fs-6 fw-bold'>
                                                                    {toggle === "week" ? opportunityLastWeek || '0' : opportunityLastMonth || '0'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className='p-2 fw-bold rounded-bottom bg-danger text-white'>
                                                        Opportunities
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col xl="4" lg="6" md="4" className="mb-3">
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                                                    onClick={(e) => handleVisits(e)}
                                                >
                                                    <div className='section-1'>
                                                        <div className='d-flex'>
                                                            <div className='fs-1 fw-bold mt-2 card-1-color'>
                                                                {toggle === "week" ? visitsCurrentWeek || '0' : toggle === "month" ? visitsCurrentMonth || '0' : visitsCustomDate || '0'}
                                                            </div>
                                                        </div>
                                                        <div className='badge-icon bg-warning'>
                                                            <FontAwesomeIcon icon={faEye} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    {toggle !== "custom" &&
                                                        <>
                                                            <div className='d-flex align-items-center ps-3'>
                                                                <FontAwesomeIcon
                                                                    icon={(toggle === "week" ? (visitsWeekDiffType === 'Decrement') : (visitsMonthDiffType === 'Decrement')) ? faArrowTrendDown : faArrowTrendUp}
                                                                    style={{ color: (visitsWeekDiffType === 'Decrement') ? "red" : "green", fontSize: "18px" }}
                                                                />
                                                                <div className='ps-2'>
                                                                    {(toggle === "week" ? (visitsWeekDiffType === 'Decrement') : (visitsMonthDiffType === 'Decrement')) ?
                                                                        <div className='fw-bold text-danger'> - </div>
                                                                        : <div className='fw-bold text-success'> + </div>
                                                                    }
                                                                </div>
                                                                <div className={(toggle === "week" ? (visitsWeekDiffType === 'Decrement') : (visitsMonthDiffType === 'Decrement')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                                                                    {toggle === "week" ? visitsWeekDiff : visitsMonthDiff}
                                                                </div>
                                                            </div>
                                                            <div className='d-flex gap-2 ps-3'>
                                                                {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                                                                <div className='fs-6 fw-bold'>
                                                                    {toggle === "week" ? visitsLastWeek || '0' : visitsLastMonth || '0'}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className='p-2 fw-bold rounded-bottom bg-warning text-white'>
                                                        Visits
                                                    </div>
                                                </Card>

                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        )
                        }
                    </Row>
                </>
            </div >
        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    profile: state.login.currentProfile,
    DashboardCounts: state.CrmDashboard.DashboardCounts,
    isDashboardPending: state.CrmDashboard.isDashboardPending,
    dashboardErrorMessage: state.CrmDashboard.dashboardErrorMessage,
})
const mapDispatchToProps = (dispatch) => ({
    crmDashboardCount: (toggle, fromDate, toDate) => dispatch(getCrmDashboardCount(toggle, fromDate, toDate)),
    crmDashboardClearError: () => dispatch(crmDashboardClearError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)