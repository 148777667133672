import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import {
    clearCreateLeadCategoryError,
    createLeadCategorySuccess,
    createNewLeadCategory,
} from '../../../../../../store/Modules/crm/Settings/LeadCategory/actions';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';

const AddLeadCategory = (props) => {
    const { isLeadCategoryCreatePending, errorMessage } = props;

    const [categoryDetails, setCategoryDetails] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        props.clearCreateLeadCategoryError();
        props.createLeadCategorySuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        // e.preventDefault();
        setCategoryDetails({
            ...categoryDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { leadCategoryName } = categoryDetails || {};

        if (!leadCategoryName) {
            currentErrors.leadCategoryName = 'Category name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createLeadCategory(categoryDetails)
        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label> Category Name <span className='text-danger'>*</span></Label>
                <Input
                    type="text"
                    name="leadCategoryName"
                    placeHolder="Enter category name"
                    onChange={validateOnchange}
                    value={categoryDetails && categoryDetails.leadCategoryName}
                    invalid={errors && errors.leadCategoryName}
                />
                <FormFeedback>{errors && errors.leadCategoryName}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isLeadCategoryCreatePending}
                >
                    {isLeadCategoryCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    isLeadCategoryCreatePending: state.crmLeadCategory.isLeadCategoryCreatePending,
    errorMessage: state.crmLeadCategory.leadCategoryCreateErrorMessage,

})

const mapDispatchToProps = (dispatch) => ({
    createLeadCategory: (categoryDetails) => dispatch(createNewLeadCategory(categoryDetails)),
    clearCreateLeadCategoryError: () => dispatch(clearCreateLeadCategoryError()),
    createLeadCategorySuccess: () => dispatch(createLeadCategorySuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddLeadCategory);