import {
    OPPORTUNITY_TYPE_CREATE_CLOSE,
    OPPORTUNITY_TYPE_CREATE_ERROR,
    OPPORTUNITY_TYPE_CREATE_ERROR_CLEAR,
    OPPORTUNITY_TYPE_CREATE_OPEN,
    OPPORTUNITY_TYPE_CREATE_PENDING,
    OPPORTUNITY_TYPE_CREATE_SUCCESS,
    OPPORTUNITY_TYPE_LIST_ERROR,
    OPPORTUNITY_TYPE_LIST_ERROR_CLEAR,
    OPPORTUNITY_TYPE_LIST_PENDING,
    OPPORTUNITY_TYPE_UPDATE_CLOSE,
    OPPORTUNITY_TYPE_UPDATE_ERROR,
    OPPORTUNITY_TYPE_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_TYPE_UPDATE_OPEN,
    OPPORTUNITY_TYPE_UPDATE_PENDING,
    OPPORTUNITY_TYPE_UPDATE_SUCCESS,
    SET_OPPORTUNITY_TYPE_DETAILS,
    SET_OPPORTUNITY_TYPE_LIST,
} from "./constants";

const initialState = {
    //--------------------Industry-CREATE-----------------------------------
    isCreateOpportunityTypeOpen: false,
    opportunityTypeCreateErrorMessage: null,
    isOpportunityTypeCreateSuccess: false,
    isOpportunityTypeCreatePending: false,
    //--------------------Industry-UPDATE-----------------------------------
    opportunityTypeDetails: null,
    isUpdateOpportunityTypeOpen: false,
    opportunityTypeUpdateErrorMessage: null,
    isOpportunityTypeUpdateSuccess: false,
    isOpportunityTypeUpdatePending: false,
    //--------------------Industry-LIST-------------------------------------
    opportunityTypeList: [],
    opportunityTypeListCount: 0,
    isOpportunityTypeListPending: false,
    opportunityTypeListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------LEADS-CREATE-----------------------------------
        case OPPORTUNITY_TYPE_CREATE_OPEN: {
            return {
                ...localState,
                isCreateOpportunityTypeOpen: true
            }
        }
        case OPPORTUNITY_TYPE_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateOpportunityTypeOpen: false
            }
        }
        case OPPORTUNITY_TYPE_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityTypeCreateErrorMessage: null,
            }
        }
        case OPPORTUNITY_TYPE_CREATE_PENDING: {
            return {
                ...localState,
                isOpportunityTypeCreatePending: true,
            }
        }
        case OPPORTUNITY_TYPE_CREATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityTypeCreateSuccess: true,
                isOpportunityTypeCreatePending: false
            }
        }
        case OPPORTUNITY_TYPE_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityTypeCreateErrorMessage: errorMessage,
                isOpportunityTypeCreatePending: false,
            }
        }

        //--------------------LEADS-UPDATE-----------------------------------
        case SET_OPPORTUNITY_TYPE_DETAILS: {
            const { opportunityTypeDetails } = action.payload;
            return {
                ...localState,
                opportunityTypeDetails: opportunityTypeDetails
            }
        }
        case OPPORTUNITY_TYPE_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateOpportunityTypeOpen: true
            }
        }
        case OPPORTUNITY_TYPE_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateOpportunityTypeOpen: false
            }
        }
        case OPPORTUNITY_TYPE_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityTypeUpdateErrorMessage: null,
            }
        }
        case OPPORTUNITY_TYPE_UPDATE_PENDING: {
            return {
                ...localState,
                isOpportunityTypeUpdatePending: true,
            }
        }
        case OPPORTUNITY_TYPE_UPDATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityTypeUpdateSuccess: true,
                isOpportunityTypeUpdatePending: false
            }
        }
        case OPPORTUNITY_TYPE_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityTypeUpdateErrorMessage: errorMessage,
                isOpportunityTypeUpdatePending: false
            }
        }
        //--------------------LEADS-Type-LIST-------------------------------------
        case SET_OPPORTUNITY_TYPE_LIST: {
            const { opportunityTypeList } = action.payload;
            return {
                ...localState,
                opportunityTypeList: opportunityTypeList && opportunityTypeList['value'],
                opportunityTypeListCount: opportunityTypeList && opportunityTypeList['@odata.count'],
                isOpportunityTypeListPending: false,
                opportunityTypeListErrorMessage: null,
            }
        }
        case OPPORTUNITY_TYPE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityTypeListErrorMessage: errorMessage,
                isOpportunityTypeListPending: false,
                opportunityTypeList: []
            }
        }
        case OPPORTUNITY_TYPE_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityTypeListErrorMessage: null,
            }
        }
        case OPPORTUNITY_TYPE_LIST_PENDING: {
            return {
                ...localState,
                isOpportunityTypeListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};