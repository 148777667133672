export const SET_MY_TEAM_LEAVE_DETAILS_LIST = "SET_MY_TEAM_LEAVE_DETAILS_LIST"
export const CLEAR_MY_TEAM_LEAVE_DETAILS_LIST_ERROR = 'CLEAR_MY_TEAM_LEAVE_DETAILS_LIST_ERROR'
export const MY_TEAM_LEAVE_DETAILS_LIST_ERROR = "MY_TEAM_LEAVE_DETAILS_LIST_ERROR"
export const SET_MY_TEAM_LEAVE_DETAILS_PENDING = "SET_MY_TEAM_LEAVE_DETAILS_PENDING"
export const PENDING_SUBMITTED_COUNT = "PENDING_SUBMITTED_COUNT"
export const SET_MY_TEAM_LEAVE_EXPORT_PENDING = "SET_MY_TEAM_LEAVE_EXPORT_PENDING"
export const SET_MY_TEAM_LEAVE_EXPORT_LIST = "SET_MY_TEAM_LEAVE_EXPORT_LIST"
export const CLEAR_MY_TEAM_LEAVE_EXPORT_LIST_ERROR = "CLEAR_MY_TEAM_LEAVE_EXPORT_LIST_ERROR"
export const MY_TEAM_LEAVE_EXPORT_LIST_ERROR = "MY_TEAM_LEAVE_EXPORT_LIST_ERROR"
export const SET_MY_TEAM_LEAVE_EXPORT_SUCCESS = "SET_MY_TEAM_LEAVE_EXPORT_SUCCESS"




export const MYTEAM_LEAVE_EXPORT_HEADERS = [
    "EMPLOYEE",
    "EMPLOYEE EMAIL",
    "FROM DATE",
    "TO DATE",
    "LEAVE DAYS",
    "LEAVE REASON",
    "LEAVE TYPE",
    "LEAVE STATUS",
];