import { getToken } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_HOLIDAY_MASTER_DETAILS_ERROR, CLEAR_HOLIDAY_MASTER_ERROR, CLEAR_HOLIDAY_MASTER_EXPORT_LIST_ERROR, CLEAR_HOLIDAY_MASTER_LIST_ERROR, CLEAR_UPDATE_HOLIDAY_MASTER_ERROR, CLOSE_CREATE_HOLIDAY_MODAL, CLOSE_DELETE_HOLIDAY_MODAL, CLOSE_EDIT_HOLIDAY_MODAL, CREATE_HOLIDAY_MASTER_ACTION_SUCCESS, CREATE_HOLIDAY_MASTER_ERROR, CREATE_HOLIDAY_MASTER_PENDING, DELETE_HOLIDAY_ERROR, DELETE_HOLIDAY_PENDING, OPEN_CREATE_HOLIDAY_MODAL, OPEN_DELETE_HOLIDAY_MODAL, OPEN_EDIT_HOLIDAY_MODAL, SET_HOLIDAY_MASTER_DETAILS, SET_HOLIDAY_MASTER_DETAILS_ERROR, SET_HOLIDAY_MASTER_DETAILS_PENDING, SET_HOLIDAY_MASTER_EXPORT_LIST, SET_HOLIDAY_MASTER_EXPORT_LIST_ERROR, SET_HOLIDAY_MASTER_EXPORT_PENDING, SET_HOLIDAY_MASTER_LIST, SET_HOLIDAY_MASTER_LIST_ERROR, SET_HOLIDAY_MASTER_PENDING, UPDATE_HOLIDAY_MASTER_ERROR, UPDATE_HOLIDAY_MASTER_PENDING, UPDATE_HOLIDAY_MASTER_SUCCESS } from "./constants";

export const setHolidayMasterPending = () => ({
    type: SET_HOLIDAY_MASTER_PENDING,
});

export const setHolidayMasterExportPending = () => ({
    type: SET_HOLIDAY_MASTER_EXPORT_PENDING,
});
export const setHolidayMasterList = (holidayMasterList) => ({
    type: SET_HOLIDAY_MASTER_LIST,
    payload: { holidayMasterList },
})
export const setHolidayMasterExportList = (holidayMasterExportList) => ({
    type: SET_HOLIDAY_MASTER_EXPORT_LIST,
    payload: { holidayMasterExportList },
})
export const clearHolidayMasterListError = () => ({
    type: CLEAR_HOLIDAY_MASTER_LIST_ERROR,
})
export const clearHolidayMasterExportListError = () => ({
    type: CLEAR_HOLIDAY_MASTER_EXPORT_LIST_ERROR,
})
export const holidayMasterListError = (errorMessage) => ({
    type: SET_HOLIDAY_MASTER_LIST_ERROR,
    payload: { errorMessage },
})
export const holidayMasterExportListError = (errorMessage) => ({
    type: SET_HOLIDAY_MASTER_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const setHolidayMasterDetailsPending = () => ({
    type: SET_HOLIDAY_MASTER_DETAILS_PENDING,
});
export const setHolidayMasterDetails = (holidayMasterDetails) => ({
    type: SET_HOLIDAY_MASTER_DETAILS,
    payload: { holidayMasterDetails },
})
export const clearHolidayMasterDetailsError = () => ({
    type: CLEAR_HOLIDAY_MASTER_DETAILS_ERROR,
})
export const holidayMasterDetailsError = (errorMessage) => ({
    type: SET_HOLIDAY_MASTER_DETAILS_ERROR,
    payload: { errorMessage },
})
export const openCreateHolidayModalAction = () => ({
    type: OPEN_CREATE_HOLIDAY_MODAL,
});
export const openDeleteHolidayModalAction = () => ({
    type: OPEN_DELETE_HOLIDAY_MODAL,
});
export const closeDeleteHolidayModalAction = () => ({
    type: CLOSE_DELETE_HOLIDAY_MODAL,
});
export const openEditHolidayModal = () => ({
    type: OPEN_EDIT_HOLIDAY_MODAL,
});
export const closeCreateHolidayModalAction = () => ({
    type: CLOSE_CREATE_HOLIDAY_MODAL,
});
export const closeEditHolidayModal = () => ({
    type: CLOSE_EDIT_HOLIDAY_MODAL,
});
export const createHolidayMasterError = (errorMessage) => ({
    type: CREATE_HOLIDAY_MASTER_ERROR,
    payload: { errorMessage },
});
export const createHolidayMasterPending = () => ({
    type: CREATE_HOLIDAY_MASTER_PENDING,
});
export const clearCreateHolidayMasterError = () => ({
    type: CLEAR_HOLIDAY_MASTER_ERROR,
});
export const createHolidayMasterActionSuccess = () => ({
    type: CREATE_HOLIDAY_MASTER_ACTION_SUCCESS,
});
export const clearUpdateHolidayMasterError = () => ({
    type: CLEAR_UPDATE_HOLIDAY_MASTER_ERROR,
});
export const updateHolidayMasterPending = () => ({
    type: UPDATE_HOLIDAY_MASTER_PENDING,
});
export const updateHolidayMasterSuccess = () => ({
    type: UPDATE_HOLIDAY_MASTER_SUCCESS,
});
export const updateHolidayMasterError = (errorMessage) => ({
    type: UPDATE_HOLIDAY_MASTER_ERROR,
    payload: { errorMessage },
});
export const deleteholidayError = (errorMessage) => ({
    type: DELETE_HOLIDAY_ERROR,
    payload: { errorMessage },
});
export const deleteHolidayMasterPending = () => ({
    type: DELETE_HOLIDAY_PENDING,
});


export const getHolidaymasterExport = (searchbuildHolidayName, searchbuildHolidayDate) => (dispatch) => {
    dispatch(setHolidayMasterExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.HolidayMaster.getHolidayMasterExportList(accessToken, searchbuildHolidayName, searchbuildHolidayDate)
            .then((HolidayMasterLists) => {
                dispatch(clearHolidayMasterExportListError());
                return Promise.resolve(HolidayMasterLists);
            })
            .catch((err) => {
                console.log('getHolidaymasterExport server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(holidayMasterExportListError(error))
            });
    });
}

export const getHolidayMasterListAction = (filteredData, page, PageSize, searchbuildHolidayName, searchbuildHolidayDate) => (dispatch) => {
    dispatch(setHolidayMasterPending());
    getToken(dispatch).then((accessToken) => {
        agent.HolidayMaster.getHolidayMasterList(accessToken, filteredData, page, PageSize, searchbuildHolidayName, searchbuildHolidayDate)
            .then((HolidayMasterLists) => {
                dispatch(setHolidayMasterList(HolidayMasterLists));
                dispatch(clearHolidayMasterListError());
                console.log("HolidayMasterLists:", HolidayMasterLists);
            })
            .catch((err) => {
                console.log('getHolidayMasterListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(holidayMasterListError(error))
            });
    });
}

export const getHolidayDetailsAction = (id) => (dispatch) => {
    dispatch(setHolidayMasterDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.HolidayMaster.getHolidayMasterDetails(accessToken, id)
            .then((holidayMasterDetails) => {
                dispatch(setHolidayMasterDetails(holidayMasterDetails))
                dispatch(clearHolidayMasterDetailsError());
                console.log("holidayMasterDetails:", holidayMasterDetails);
            })
            .catch((err) => {
                console.log('getHolidayDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(holidayMasterDetailsError(error))
            });
    })
}

export const createHoliday = (formFields, filteredData) => (dispatch) => {

    let dataToSend = {}
    if (!formFields) {
        dispatch(createHolidayMasterError('Please fill required details.'));
        return;
    }
    else {
        // dataToSend = {...formFields }
        dataToSend = trimmedValues(formFields);
    }
    dispatch(createHolidayMasterPending());
    dispatch(clearCreateHolidayMasterError());
    getToken(dispatch).then((accessToken) => {
        agent.HolidayMaster.createHolidayMaster(accessToken, dataToSend)
            .then(() => {
                dispatch(createHolidayMasterActionSuccess());
                dispatch(clearForm());
                dispatch(closeCreateHolidayModalAction());
                dispatch(getHolidayMasterListAction(filteredData));
                // dispatch(getHolidaymasterExport());
                dispatch(showSnackbarStatus('Holiday created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createHolidayMasterError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createHolidayMasterError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Create Holiday server error', err);
                dispatch(clearForm());
                dispatch(closeCreateHolidayModalAction());
            });
    });
};

export const updateHoliday = (formFields) => (dispatch) => {
    // const dataToSend = { ...formFields}
    const dataToSend = trimmedValues(formFields);
    dispatch(clearUpdateHolidayMasterError());
    dispatch(updateHolidayMasterPending());
    getToken(dispatch).then((accessToken) => {
        agent.HolidayMaster.editHolidayMaster(accessToken, dataToSend)
            .then(() => {
                dispatch(updateHolidayMasterSuccess());
                dispatch(closeEditHolidayModal());
                dispatch(getHolidayDetailsAction(formFields.id));
                dispatch(showSnackbarStatus('Holiday updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    // dispatch(updateHolidayMasterError("Please fill all required fields"));
                    dispatch(updateHolidayMasterError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateHolidayMasterError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Update Holiday Master server error', err);
                dispatch(updateHolidayMasterError(null));
                dispatch(closeEditHolidayModal());
            });
    });
};

export const deleteHoliday = (id, filteredData) => (dispatch) => {
    dispatch(deleteHolidayMasterPending());
    getToken(dispatch).then((accessToken) => {
        agent.HolidayMaster.getDeleteHolidayList(accessToken, id).then(
            () => {
                dispatch(closeDeleteHolidayModalAction());
                dispatch(getHolidayMasterListAction(filteredData));
                // window.location.reload()
            }
        ).catch((err) => {
            console.log("err:", err.response && err.response.text);
            console.log('deleteHoliday server error', err);
            dispatch(showSnackbarStatus(err.response && err.response.text));
            dispatch(deleteholidayError(err.response && err.response.text));
        })
    })
}