import {
    CAMPAIGN_DETAILS_ERROR,
    CAMPAIGN_EDIT_ERROR,
    CAMPAIGN_LIST_ERROR,
    CLEAR_CAMPAIGN_DETAILS_ERROR,
    CLEAR_CAMPAIGN_EDIT_ERROR,
    CLEAR_CAMPAIGN_LIST_ERROR,
    CLOSE_CREATE_CAMPAIGN_MODAL,
    CLOSE_EDIT_CAMPAIGN_MODAL,
    OPEN_CREATE_CAMPAIGN_MODAL,
    OPEN_EDIT_CAMPAIGN_MODAL,
    SET_CAMPAIGN_DETAILS,
    SET_CAMPAIGN_DETAILS_PENDING,
    SET_CAMPAIGN_LIST,
    SET_CONTACT_COUNT,
    SET_EXPORTED_CAMPAIGN_LIST,
    CAMPAIGN_CREATE_PENDING,
    SET_HTML_FILE,
    SET_CAMPAIGN_CONTACT_LIST,
    SET_EXPORT_CAMPAIGN_CONTACT_LIST,
    CLEAR_CAMPAIGN_CONTACT_LIST_ERROR,
    CAMPAIGN_CONTACT_LIST_ERROR,
    SET_CAMPAIGN_CONTACT_LIST_PENDING,
    CAMPAIGN_CREATE_ERROR,
    CAMPAIGN_CREATE_ERROR_CLEAR,
    CAMPAIGN_EDIT_PENDING,
    SET_CAMPAIGN_LIST_PENDING,
    CLEAR_CAMPAIGN_LIST_EXPORT_ERROR,
    CAMPAIGN_LIST_EXPORT_ERROR,
    CAMPAIGN_FROM_ADDRESS_ERROR,
    CAMPAIGN_FROM_ADDRESS_ERROR_CLEAR,
    HTML_FILE_ERROR,
    CLEAR_HTML_FILE_ERROR,
    CREATE_CAMPAIGN_SUCCESSS,
    EDIT_CAMPAIGN_SUCCESSS,
} from "./constants";
import { getToken, modifyFromAddressList } from "../../../../helper/common"
import agent from "../../../services/agent"
import { showSnackbarStatus } from "../../../snackbar/actions";
import trimmedValues from "../../../../helper/trim";

//---------------------------------------------MM-CAMPAIGN-CREATE--------------------------------------------------------------------------------
export const openCreateCampaignModal = () => ({
    type: OPEN_CREATE_CAMPAIGN_MODAL,
})
export const closeCreateCampaignModal = () => ({
    type: CLOSE_CREATE_CAMPAIGN_MODAL,
})
export const campaignCreatePending = () => ({
    type: CAMPAIGN_CREATE_PENDING,
})
export const campaignCreateError = (errorMessage) => ({
    type: CAMPAIGN_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCampaignCreateError = () => ({
    type: CAMPAIGN_CREATE_ERROR_CLEAR,
})
export const campaignFromAddressAsyncError = (errorMessage) => ({
    type: CAMPAIGN_FROM_ADDRESS_ERROR,
    payload: { errorMessage }
})
export const campaignFromAddressAsyncErrorClear = () => ({
    type: CAMPAIGN_FROM_ADDRESS_ERROR_CLEAR,
})
export const createCampaignSuccess = () => ({
    type: CREATE_CAMPAIGN_SUCCESSS,
})

export const createNewCampaign = (campaignData) => (dispatch) => {
    let dataToSend = {}
    if (!campaignData) {
        dispatch(campaignCreateError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...campaignData, })
    }
    dispatch(campaignCreatePending());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.createCampaign(accessToken, dataToSend)
            .then(() => {
                dispatch(closeCreateCampaignModal());
                dispatch(createCampaignSuccess());
                dispatch(getCampaignList());
                dispatch(clearCampaignCreateError());
                dispatch(showSnackbarStatus('Campaign created successfully'));
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                console.log("Get Contact Error: ", err);
                dispatch(campaignCreateError(err && err.response && err.response.text));
            })
    })
}

export const getFromAddressAsyncSelectAction = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getFromAddressAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyFromAddressList(value);
                callback && callback(updatedUserList);
                dispatch(campaignFromAddressAsyncErrorClear());
            })
            .catch((err) => {
                dispatch(campaignFromAddressAsyncError(err.response && err.response.text));
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                callback && callback();
            });
    });
};
//---------------------------------------------MM-CAMPAIGN-UPDATE--------------------------------------------------------------------------------
export const openEditCampaignModal = () => ({
    type: OPEN_EDIT_CAMPAIGN_MODAL,
})
export const closeEditCampaignModal = () => ({
    type: CLOSE_EDIT_CAMPAIGN_MODAL,
})
export const campaignEditPending = () => ({
    type: CAMPAIGN_EDIT_PENDING,
})
export const campaignEditError = (errorMessage) => ({
    type: CAMPAIGN_EDIT_ERROR,
    payload: { errorMessage },
})
export const clearCampaignEditError = () => ({
    type: CLEAR_CAMPAIGN_EDIT_ERROR
})
export const editCampaignSuccess = () => ({
    type: EDIT_CAMPAIGN_SUCCESSS
})

export const editCampaignDetails = (campaignDetails) => (dispatch) => {
    let dataToSend = {}
    if (!campaignDetails) {
        dispatch(campaignEditError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...campaignDetails, })
    }
    dispatch(campaignEditPending());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getCampaignEdit(accessToken, dataToSend)
            .then(() => {
                dispatch(closeEditCampaignModal());
                dispatch(editCampaignSuccess());
                dispatch(getCampaignDetails(campaignDetails.id));
                dispatch(getCampaignContactList(campaignDetails.id));
                dispatch(getHTMLFile(campaignDetails.id));
                dispatch(clearCampaignEditError());
                dispatch(showSnackbarStatus('Campaign details updated successfully'));
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                console.log("Get Contact Error: ", err);
                dispatch(campaignEditError(error));
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            })
    })
}
//---------------------------------------------MM-CAMPAIGN-LIST----------------------------------------------------------------------------------
export const setCampaignList = (campaignList) => ({
    type: SET_CAMPAIGN_LIST,
    payload: { campaignList },
})
export const setExportedCampaignList = (campaignList) => ({
    type: SET_EXPORTED_CAMPAIGN_LIST,
    payload: { campaignList },
})
export const setCampaignListPending = () => ({
    type: SET_CAMPAIGN_LIST_PENDING
})
export const campaignListError = (errorMessage) => ({
    type: CAMPAIGN_LIST_ERROR,
    payload: { errorMessage },
})
export const clearCampaignListError = () => ({
    type: CLEAR_CAMPAIGN_LIST_ERROR
})
export const campaignListExportError = (errorMessage) => ({
    type: CAMPAIGN_LIST_EXPORT_ERROR,
    payload: { errorMessage },
})
export const clearCampaignListExportError = () => ({
    type: CLEAR_CAMPAIGN_LIST_EXPORT_ERROR
})

export const getCampaignList = (page, pageSize, searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) => (dispatch) => {
    dispatch(setCampaignListPending());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getCampaignList(accessToken, page, pageSize, searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder)
            .then((campaignList) => {
                dispatch(setCampaignList(campaignList));
                dispatch(clearCampaignListError());
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                console.log("Get Contact Error: ", error);
                dispatch(campaignListError(error));
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            })
    })
}

export const exportCampaignList = (searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.exportCampaignList(accessToken, searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder)
            .then((campaignList) => {
                dispatch(setExportedCampaignList(campaignList));
                dispatch(clearCampaignListExportError());
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                console.log("Get Contact Error: ", error);
                dispatch(campaignListExportError(error));
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            })

    })
}

//---------------------------------------------MM-CAMPAIGN-DETAILS-------------------------------------------------------------------------------
export const setCampaignDetails = (campaignDetails) => ({
    type: SET_CAMPAIGN_DETAILS,
    payload: { campaignDetails }
})
export const setCampaignDetailsPending = () => ({
    type: SET_CAMPAIGN_DETAILS_PENDING
})
export const campaignDetailsError = (errorMessage) => ({
    type: CAMPAIGN_DETAILS_ERROR,
    payload: { errorMessage },
})
export const clearCampaignDetailsError = () => ({
    type: CLEAR_CAMPAIGN_DETAILS_ERROR,
})
export const setContactCount = (contactList) => ({
    type: SET_CONTACT_COUNT,
    payload: { contactList }
})
export const setHTMLFile = (HTMLFile) => ({
    type: SET_HTML_FILE,
    payload: { HTMLFile }
})
export const HTMLFileError = (errorMessage) => ({
    type: HTML_FILE_ERROR,
    payload: { errorMessage }
})
export const clearHTMLFileError = () => ({
    type: CLEAR_HTML_FILE_ERROR,
})

export const getCampaignDetails = (id) => (dispatch) => {
    dispatch(setCampaignDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getCampaignDetails(accessToken, id)
            .then((campaignDetails) => {
                dispatch(setCampaignDetails(campaignDetails));
                dispatch(clearCampaignDetailsError());
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                console.log("Get Contact Error: ", err);
                dispatch(campaignDetailsError(error));
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            })
    })
}

export const getCampaignContactsCount = (id) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getCampaignContactsCount(accessToken, id)
            .then((contactList) => {
                dispatch(setContactCount(contactList));
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                console.log("Get Contact Error: ", error);
            })
    })
}

export const getHTMLFile = (id) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getHTMLFile(accessToken, id)
            .then((result) => {
                console.log("result: ", result);
                dispatch(setHTMLFile(result));
                dispatch(clearHTMLFileError());
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.statusText;
                }
                else {
                    error = "Network Error"
                }
                dispatch(HTMLFileError(error));
                console.log("Get Contact Error: ", error);
            })
    })
}

//------------------------------------------------CAMPAIGN-CONTACT-LIST--------------------------------------------------------------------------------
export const setCampaignContactList = (CampaignContactList) => ({
    type: SET_CAMPAIGN_CONTACT_LIST,
    payload: { CampaignContactList },
})
export const setExportCampaignContactList = (CampaignContactList) => ({
    type: SET_EXPORT_CAMPAIGN_CONTACT_LIST,
    payload: CampaignContactList,
})
export const setCampaignContactPending = () => ({
    type: SET_CAMPAIGN_CONTACT_LIST_PENDING
})
export const CampaignContactListError = (errorMessage) => ({
    type: CAMPAIGN_CONTACT_LIST_ERROR,
    payload: { errorMessage },
})
export const clearCampaignContactListError = () => ({
    type: CLEAR_CAMPAIGN_CONTACT_LIST_ERROR
})

export const getCampaignContactList = (campaignId, page, pageSize, searchEmail, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, sortDirection, sortOrder) => (dispatch) => {
    dispatch(setCampaignContactPending());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getCampaignContactList(accessToken, page, pageSize, campaignId, searchEmail, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, sortDirection, sortOrder)
            .then((CampaignContactList) => {
                dispatch(setCampaignContactList(CampaignContactList));
                dispatch(clearCampaignContactListError());
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                console.log("Get Contact Error: ", error);
                dispatch(CampaignContactListError(error));
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            })
    })
}