import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import visionware_logo from '../../assets/images/visionware_logo.png'
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';
import Select from "react-select";

const Register = props => {
    document.title = "Register | Upzet - React Admin & Dashboard Template";

    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            username: Yup.string().required("Please Enter Your Username"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values));
        }
    });

    const { user, registrationError } = useSelector(state => ({
        user: state.account.user,
        registrationError: state.account.registrationError,
    }));

    // handleValidSubmit
    // const handleValidSubmit = values => {
    //   dispatch(registerUser(values));
    // };

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    return (
        <div className="bg-pattern" style={{ height: "200vh" }}>
            <div className="bg-overlay"></div>
            <div className="account-pages pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card className='mt-5'>
                                <CardBody className="p-4">
                                    {/* <Container fluid> */}
                                        <Row className="justify-content-center align-items-center" style={{ height: '10vh' }}>
                                            <Col xs="auto" className="logo-lg">
                                                <img src={visionware_logo} alt="logo-light" height="30" width="150" />
                                            </Col>
                                            <h4 className="font-size-15 text-muted text-center mt-2">Register Your Account</h4>
                                        </Row>
                                    {/* </Container> */}<br/>
                                   
                                    <Form>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Organization Name</Label>
                                                    <Input
                                                        name="organization name"
                                                        className="form-control"
                                                        placeholder="Enter Organization Name"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <Label className="form-label">Organization Type</Label>
                                                    <Select
                                                        id="new_user_role"
                                                        name="organization type"
                                                        backspaceRemovesValue={false}
                                                        placeholder="Select Organization Type"
                                                        isSearchable={false}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <Label className="form-label">Email</Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter Email Address"
                                                        type="email"
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <Label className="form-label">Contact No</Label>
                                                    <Input
                                                        name="contact number"
                                                        type="number"
                                                        placeholder="Enter Contact No"
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <Label className="form-label">Communication Address</Label>
                                                    <Input
                                                        name="address"
                                                        type="textarea"
                                                        placeholder="Enter Communication Address"
                                                    />
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="term-conditionCheck" />
                                                    <label className="form-check-label fw-normal" htmlFor="term-conditionCheck">I accept <Link to="#" className="text-primary">Acknowledgement</Link></label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="term-conditionCheck" />
                                                    <label className="form-check-label fw-normal" htmlFor="term-conditionCheck">I accept <Link to="#" className="text-primary">Terms and Conditions</Link></label>
                                                </div>
                                                <div className="d-grid mt-4">
                                                    <Button color="success">Register</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p className="text-white-50">Already have an account ?<Link to="/login" className="fw-medium text-primary"> Login </Link> </p>
                                <p className="text-white-50">© {new Date().getFullYear()} Visionware Technologies</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Register;
