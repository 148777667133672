import React from "react";
import { Card } from "reactstrap";
import userNotFound from '../../../../assets/images/Mass-Mailer/user-not-found.gif'

const InvalidUser = () => {
    return (
        // <div className="d-flex justify-content-center align-items-center bg-danger vh-100">
        <Card className='p-4 shadow-lg'>
            <div className="d-flex align-items-center">
                <img src={userNotFound} alt="no-user-found" style={{ height: "300px", width: "auto" }} />
                <div>
                    <h4 className="text-danger ps-5 pt-2 fw-bold">
                        It seems to be an invalid user.
                    </h4>
                </div>
            </div>
        </Card>
        // </div >
    )
}

export default InvalidUser;