import React from 'react'
import OverAllDocsList from '../../OverAllDocs/OverAllDocsList'
import { connect } from 'react-redux'

const AdminDocs = (props) => {
  const { userAppDetails } = props.profile;
  console.log("userAppDetails:", userAppDetails);
    // const AppID = '3001241807314221870'

  const App = userAppDetails.filter((i) => {
    return (i.appName === "Admin") ? i.appId : ""
  })

  return (
    <OverAllDocsList data={App[0] && App[0].appId} />
  )
}

const mapStateToProps = (state) => ({
  profile: state.login.currentProfile,
})
export default connect(mapStateToProps, null)(AdminDocs)