import React from "react";
import { Card, FormGroup, Input, Label } from "reactstrap";
import UnsubscribedImg from '../../../../assets/images/Mass-Mailer/already-unsubscribed.gif'

const AlreadyUnsubscribed = (props) => {
    const { email } = props;
    return (
        <Card className='p-4 shadow-lg'>
            <div className=" d-flex align-items-center">
                <img src={UnsubscribedImg} alt="no-user-found" style={{ height: "300px", width: "auto" }} />
                <div className="ps-3">
                    <h4 style={{ color: '#992df7' }} className="fw-bold">You are already unsubscribed.</h4><br />
                    <FormGroup>
                        <Label>Email Address</Label>
                        <Input value={email} disabled />
                    </FormGroup>
                </div>
            </div>
        </Card>
    )
}

export default AlreadyUnsubscribed;