import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row,
    FormText
} from "reactstrap";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import {
    clearUpdateContactsError,
    getAccountsAsyncSelect,
    updateContacts,
    updateContactsSuccess
} from "../../../../store/Modules/crm/Contacts/actions";
import Async from "react-select/async"
import Select from "react-select";
import FormValidator from "../../../../helper/formValidator";

const TitleOptions = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Miss.', value: 'Miss.' },

]

const UpdateContacts = (props) => {
    const { errorMessage, contactsDetails, isContactsUpdatePending } = props;
    const [formFields, setFormFields] = useState();
    const [companyName, setCompanyName] = useState([{ label: '', value: '' }]);
    const [errors, setErrors] = useState();
    const [Title, setprevTitle] = useState([{ label: '', value: '' }]);

    useEffect(() => {
        props.clearUpdateContactsError();
        props.updateContactsSuccess();
        if (contactsDetails) {
            setFormFields({ ...contactsDetails, email: contactsDetails && contactsDetails.contactEmail });
            setCompanyName({ label: contactsDetails.companyName, value: contactsDetails.companyId });
            setprevTitle({ label: contactsDetails.title || "Select", value: contactsDetails.title });
        }
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        let result = {};
        if (name === 'email') {
            console.log("email: ", value);
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            result = {
                isValid: emailRegex.test(value),
                message: 'Enter valid email',
            }
        }
        else if (name === 'phoneNo') {
            if (value) {
                const contactNumberRegex = /^\d{10}$/;
                result = {
                    isValid: contactNumberRegex.test(value),
                    message: 'Enter valid mobile number',
                };
            }
        }
        else if (name === 'landLineNo') {
            if (value) {
                const contactNumberRegex = /^[0-9]\d{2,4}-\d{6,8}$/;
                result = {
                    isValid: contactNumberRegex.test(value),
                    message: 'Enter valid landline number',
                };
            }
        }
        else {
            result = FormValidator.validate(e.target);
        }

        setFormFields({
            ...formFields,
            [name]: value || null
        })
        setErrors({
            ...errors,
            [name]: result.isValid ? null : result.message,
        })
    }

    const handleAccountSelect = (value) => {
        setCompanyName(value)
        setFormFields({
            ...formFields,
            companyId: value.value
        })
        setErrors({
            ...errors,
            companyId: null
        })
    }

    const handleAccounts = (inputValue, callback) => {
        const { getAccountsAsyncSelect } = props;
        return getAccountsAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }

    const handleChangeTitleSelect = (value) => {
        setprevTitle(value);
        setFormFields({
            ...formFields,
            title: value && value.value
        })
        setErrors({
            ...errors,
            title: null
        })
    }

    const handleContactsUpdate = (e) => {
        const { firstName, email, companyId, title, city, landLineNo } = formFields || {};
        let currentErrors = {};

        // if (!landLineNo) {
        //     currentErrors.landLineNo = "Landline number is required";
        // }
        if (!firstName) {
            currentErrors.firstName = "First name is required";
        }
        if (!city) {
            currentErrors.city = "City is required";
        }
        if (!email) {
            currentErrors.email = "Email address is required";
        }
        if (errors && errors.email) {
            currentErrors.email = "Enter valid email";
        }
        if ((errors && errors.phoneNo) && (formFields && formFields.phoneNo && formFields.phoneNo.length > 0)) {
            currentErrors.phoneNo = "Enter valid mobile Number";
        }
        if (!companyId) {
            currentErrors.companyId = "Contact owner is required";
        }
        if (!title) {
            currentErrors.title = "Title is required";
        }
        setErrors(currentErrors);

        if (Object.keys(currentErrors).length === 0) {
            props.updateContacts(formFields);
        }
        e.preventDefault();
    }

    console.log("errors:", errors);

    return (
        <div>
            <Form>
                {errorMessage &&
                    (<VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />)
                }
                <div className="fw-bold text-uppercase fs-5 text-dark border-start border-primary border-5 mb-3"> &nbsp;Contact Information</div>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Title <span className="text-danger">*</span></Label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.title) ? 'red' : "silver",
                                        // cursor: 'pointer'
                                    })
                                }}
                                name='title'
                                placeholder='Select your title'
                                // styles={customStyles}
                                options={TitleOptions}
                                value={Title}
                                onChange={handleChangeTitleSelect}
                            />
                            {(errors && errors.title) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.title}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>First Name <span className="text-danger">*</span></Label>
                            <Input
                                type="text"
                                name="firstName"
                                placeHolder="Enter your first name"
                                onChange={validateOnchange}
                                value={formFields && formFields.firstName}
                                invalid={errors && errors.firstName}
                            />
                            <FormFeedback>{errors && errors.firstName}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Last Name</Label>
                            <Input
                                type="text"
                                name="lastName"
                                placeHolder="Enter your last name"
                                onChange={validateOnchange}
                                value={formFields && formFields.lastName}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Email Address <span className="text-danger">*</span></Label>
                            <Input
                                type="text"
                                name="email"
                                placeHolder="Enter your email address"
                                onChange={validateOnchange}
                                value={formFields && formFields.email}
                                invalid={errors && errors.email}
                            />
                            <FormFeedback>{errors && errors.email}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Mobile Number</Label>
                            <Input
                                onKeyDown={
                                    (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}
                                min='0'
                                type="number"
                                name="phoneNo"
                                placeHolder="Enter your mobile number"
                                onChange={validateOnchange}
                                value={formFields && formFields.phoneNo}
                                invalid={errors && errors.phoneNo}
                            />
                            <FormFeedback>
                                {errors && errors.phoneNo}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Landline Number </Label>
                            <Input
                                onKeyDown={(e) => {
                                    // Allow Backspace (8), Delete (46), Arrow keys (37-40) and hyphen (45)
                                    const isControlKey = [8, 46, 37, 39, 45].includes(e.keyCode);

                                    // Block alphabets (a-zA-Z) and invalid characters (anything other than numbers and hyphen)
                                    const isInvalidKey = /[a-zA-Z]/.test(e.key);

                                    if (isInvalidKey && !isControlKey) {
                                        e.preventDefault();
                                    }
                                }}
                                onWheel={(e) => e.target.blur()}
                                type="text"
                                name="landLineNo"
                                placeHolder="Enter your landline number"
                                onChange={validateOnchange}
                                value={formFields && formFields.landLineNo}
                                invalid={errors && errors.landLineNo}
                            />
                            <FormFeedback>
                                {errors && errors.landLineNo}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row >
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Designation</Label>
                            <Input
                                type="text"
                                name="designation"
                                placeHolder="Enter your designation"
                                onChange={validateOnchange}
                                value={formFields && formFields.designation}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Department</Label>
                            <Input
                                type="text"
                                name="department"
                                placeHolder="Enter your department"
                                onChange={validateOnchange}
                                value={formFields && formFields.department}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>City <span className="text-danger">*</span></Label>
                            <Input
                                type="text"
                                name="city"
                                placeHolder="Enter your city"
                                onChange={validateOnchange}
                                value={formFields && formFields.city}
                                invalid={errors && errors.city}
                            />
                            <FormFeedback>
                                {errors && errors.city}
                            </FormFeedback>
                        </FormGroup>
                    </Col >
                </Row>
                <hr className="mt-0" />
                <div className="fw-bold text-uppercase fs-5 text-dark border-start border-danger border-5 mb-3">&nbsp;Account Information</div>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <Label>Account Name <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.companyId) ? 'red' : "silver",
                                        // cursor: 'pointer'
                                    })
                                }}
                                name="company"
                                defaultOptions
                                placeholder="Select your company"
                                loadOptions={handleAccounts}
                                onChange={handleAccountSelect}
                                value={companyName}
                            />
                            {(errors && errors.companyId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.companyId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                </Row >

                <hr className="mt-0" />
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleContactsUpdate}
                        disabled={isContactsUpdatePending}
                    >
                        {isContactsUpdatePending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </Form >
        </div >
    )
}

const mapStateToProps = (state) => ({
    errorMessage: state.crmContacts.contactsUpdateErrorMessage,
    isContactsUpdatePending: state.crmContacts.isContactsUpdatePending,
    isContactsUpdateSuccess: state.crmContacts.isContactsUpdateSuccess,
})
const mapDispatchToProps = (dispatch) => ({
    updateContacts: (data) => dispatch(updateContacts(data)),
    clearUpdateContactsError: () => dispatch(clearUpdateContactsError()),
    updateContactsSuccess: () => dispatch(updateContactsSuccess()),
    getAccountsAsyncSelect: (searchValue, callback) => dispatch(getAccountsAsyncSelect(searchValue, callback)),
})
export default connect(mapStateToProps, mapDispatchToProps)(UpdateContacts);