import { CLEAR_ORG_LOGO_ERROR, CLEAR_UPDATE_ORG_LOGO_ERROR, SET_ORG_LOGO, SET_ORG_LOGO_ERROR, SET_ORG_LOGO_PENDING, UPDATE_ORG_LOGO_ERROR, UPDATE_ORG_LOGO_PENDING, UPDATE_ORG_LOGO_SUCCESS } from "./constants";

const initialState = {
    orgLogoError: null, 
    isOrgLogoPending:false,
    OrgLogoDetails:{},
    updateOrgLogoErrorMessage: null,
    isUpdateOrgLogoPending: false,
};


export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_ORG_LOGO_PENDING: {
            return {
                ...localState,
                orgLogoError: null,
                isOrgLogoPending: true
            }
        }
        case SET_ORG_LOGO: {
            const { Orglogo } = action.payload;
            return {
                ...localState,
                isOrgLogoPending: false,
                OrgLogoDetails: Orglogo,
            }
        }
        case CLEAR_ORG_LOGO_ERROR: {
            return {
                ...localState,
                orgLogoError: null,
            }
        }
        case SET_ORG_LOGO_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                OrgLogoDetails: {},
                isOrgLogoPending: false,
                orgLogoError: errorMessage,
            }
        }
        case CLEAR_UPDATE_ORG_LOGO_ERROR: {
            return {
                ...localState,
                updateOrgLogoErrorMessage: null,
                isUpdateOrgLogoPending: false,
            };
        }
        case UPDATE_ORG_LOGO_PENDING: {
            return {
                ...localState,
                isUpdateOrgLogoPending: true,
            };
        } case UPDATE_ORG_LOGO_SUCCESS: {
            return {
                ...localState,
                isUpdateOrgLogoPending: false,
            };
        } case UPDATE_ORG_LOGO_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateOrgLogoErrorMessage: errorMessage,
                isUpdateOrgLogoPending: false,
            };
        }
        default: {
            return localState;
        }
    }
};