import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { EditEmployeeMaster, getEmployeeIDlist, getManagerNameAsyncSelect, getUserNameAsyncSelect, updateEmployeeSuccess } from '../../../../store/Modules/Employees/actions';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import Async from 'react-select/async';
import moment from 'moment-timezone';
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import VWButton from '../../../../components/vwButton/VWButton';
import Select from "react-select";

const GenderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
]
const EmployeeTyeOptions = [
  { label: 'Full Time', value: 'Full Time' },
  { label: 'Part Time', value: 'Part Time' },
  { label: 'Consultant', value: 'Consultant' },
]
const EmployeeStatusOptions = [
  { label: 'Active', value: false },
  { label: 'Terminated', value: true },
]

const TitleOptions = [
  { label: 'Miss', value: 'Miss' },
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Mrs.', value: 'Mrs.' },
]
const EditEmployee = (props) => {

  const [formFields, setFormFields] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [isTerminated, setIsTerminated] = useState(false);
  const [isRehired, setIsRehired] = useState(false);
  const [isPeopleManager, setIsPeopleManager] = useState(false);
  const [prevUser, setprevUser] = useState([{ label: '', value: '' }]);
  const [prevEmployee, setprevEmployee] = useState([]);
  const [prevGender, setprevGender] = useState([{ label: '', value: '' }]);
  const [prevTitle, setprevTitle] = useState([{ label: '', value: '' }]);
  const [empType, setEmployeeType] = useState([{ label: '', value: '' }]);
  const [errors, setErrors] = useState({});
  const [isMailNotificationEnable, setIsMailNotificationEnable] = useState();

  useEffect(() => {
    props.updateEmployeeSuccess();
    if (props.EmployeeDetails) {
      setFormFields(props.EmployeeDetails);
      setEmployeeType(props.EmployeeDetails && props.EmployeeDetails.employeeType ? { label: props.EmployeeDetails && props.EmployeeDetails.employeeType, value: props.EmployeeDetails && props.EmployeeDetails.employeeType } : 'Select employee type')
      setprevUser({ label: `${props.EmployeeDetails && props.EmployeeDetails.userName} | ${props.EmployeeDetails && props.EmployeeDetails.userEmail}`, value: props.EmployeeDetails && props.EmployeeDetails.userId });
      setprevGender({ label: props.EmployeeDetails && props.EmployeeDetails.gender || '', value: props.EmployeeDetails && props.EmployeeDetails.gender || '' });
      setprevTitle({ label: props.EmployeeDetails && props.EmployeeDetails.employeeTitle || '', value: props.EmployeeDetails && props.EmployeeDetails.employeeTitle || '' });
      setprevEmployee((props.EmployeeDetails && props.EmployeeDetails.managerUserId) ? { label: `${props.EmployeeDetails && props.EmployeeDetails.managerUserName} | ${props.EmployeeDetails && props.EmployeeDetails.managerUserEmail}`, value: props.EmployeeDetails && props.EmployeeDetails.managerUserId } : 'Select manager name');
      setIsActive(props.EmployeeDetails && props.EmployeeDetails.isActive);
      setIsTerminated(props.EmployeeDetails && props.EmployeeDetails.isTerminated);
      setIsRehired(props.EmployeeDetails && props.EmployeeDetails.isRehired);
      setIsPeopleManager(props.EmployeeDetails && props.EmployeeDetails.isPeopleManager);
      setIsMailNotificationEnable(props.EmployeeDetails && props.EmployeeDetails.enableMailNotification);
    }
  }, [])

  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
    setFormFields({ ...formFields, [input.name]: value });
    if (input.name === 'employeeId') {
      setErrors({ ...errors, employeeId: '' });
    }
    if (input.name === 'employeeFirstName') {
      setErrors({ ...errors, employeeFirstName: '' });
    }
    if (input.name === 'employeeLastName') {
      setErrors({ ...errors, employeeLastName: '' });
    }
    if (input.name === 'terminationReason') {
      setErrors({ ...errors, terminationReason: '' });
    }
  };

  const validateOnChangeDate = (e) => {
    const { name, value } = e.target;
    const formattedDate = value ? moment(value).format() : null;
    setFormFields({ ...formFields, [name]: formattedDate });
    if (name === 'dateOfBirth') {
      setErrors({ ...errors, dateOfBirth: '' });
    }
    if (name === 'dateOfJoin') {
      setErrors({ ...errors, dateOfJoin: '' });
    }
    if (name === 'dateOfTermination') {
      setErrors({ ...errors, dateOfTermination: '' });
    }
  };

  const handleIsTerminatedStatusChange = () => {
    setIsTerminated(!isTerminated);
    setFormFields({ ...formFields, isTerminated: !isTerminated })
  };
  const handleIsRehiredStatusChange = () => {
    const newIsRehired = !isRehired;
    setIsRehired(newIsRehired);
    setFormFields({ ...formFields, isRehired: newIsRehired });
  };
  const handleIsPeopleManagerStatusChange = () => {
    const newIsPeopleManager = !isPeopleManager;
    setIsPeopleManager(newIsPeopleManager);
    setFormFields({ ...formFields, isPeopleManager: newIsPeopleManager });
  };

  const handleChangeGenderSelect = (value) => {
    setFormFields({ ...formFields, gender: value.value });
    setErrors({ ...errors, gender: '' });
    setprevGender(value)
  }
  const handleChangeTitleSelect = (value) => {
    setFormFields({ ...formFields, employeeTitle: value.value });
    setprevTitle(value)
  }
  const handleChangeEmployeeTypeSelect = (options) => {
    setFormFields({ ...formFields, employeeType: options.value });
    setEmployeeType(options);
  }
  const handleEmailNotificationStatusChange = () => {
    setIsMailNotificationEnable(!isMailNotificationEnable);
    setFormFields({ ...formFields, enableMailNotification: !isMailNotificationEnable })
  };

  //--------------------------------------------EDIT ---------------------------------------------------------
  const handleEditEmployeeMaster = () => {
    const newErrors = {};
    if (!formFields.userId || !formFields.userId.trim()) {
      newErrors.userId = 'Username is required';
    }
    if (errors && errors.employeeId) {
      newErrors.employeeId = errors && errors.employeeId;
    }
    if (!formFields.employeeId || !formFields.employeeId.trim()) {
      newErrors.employeeId = 'Employee ID is required';
    }
    if (!formFields.employeeFirstName || !formFields.employeeFirstName.trim()) {
      newErrors.employeeFirstName = 'Employee first name is required';
    }
    if (!formFields.employeeLastName || !formFields.employeeLastName.trim()) {
      newErrors.employeeLastName = 'Employee last name is required';
    }
    if (!formFields.dateOfBirth || !formFields.dateOfBirth.trim()) {
      newErrors.dateOfBirth = 'Date of birth is required';
    }
    if (!formFields.dateOfJoin || !formFields.dateOfJoin.trim()) {
      newErrors.dateOfJoin = 'Date of join is required';
    }
    if (!formFields.gender || !formFields.gender.trim()) {
      newErrors.gender = 'Gender is required';
    }
    if (isTerminated) {
      if (!formFields.dateOfTermination || !formFields.dateOfTermination.trim()) {
        newErrors.dateOfTermination = 'Date of termination is required';
      }
      if (!formFields.terminationReason || !formFields.terminationReason.trim()) {
        newErrors.terminationReason = 'Termination reason is required';
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      props.EditEmployeeMasterAction(formFields);
    }
  };
  const handleEmployeeName = (inputValue, callback) => {
    const { getManagerNameAsyncSelect } = props;
    return getManagerNameAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
  };

  const handleAddEmployeeName = (value) => {
    if (value === null) {
      setprevEmployee(value);
      setFormFields({ ...formFields, managerUserId: null });
    } else {
      setprevEmployee(value);
      setFormFields({ ...formFields, managerUserId: value.value });
      setErrors({ ...errors, managerUserId: '' });
    }
  }

  const { userAppDetails } = props && props.currentProfile;
  const App = userAppDetails.filter((i) => {
    return (i.appName === "Employee") ? i.appId : ""
  })

  const ValidateEmployeeId = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
    setFormFields({
      ...formFields,
      employeeId: value,
    });
    props.getEmployeeIDlist(App[0] && App[0].appId, value, formFields.id)
      .then(() => {
        setErrors({ ...errors, employeeId: '' });
      })
      .catch((error) => {
        console.error("Error checking new user:", error);
        setErrors({
          ...errors,
          employeeId: error || 'Failed to fetch user data. Please try again.'
        });
      })
  }

  console.log("form:", formFields);
  console.log("errors:", errors);

  return (
    <React.Fragment>
      {props.errorMessage && (
        <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
      )}
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              User (Email ID)<span className="text-danger"> *</span>
            </Label>
            <Async
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.userEmail) ? '#FF3D60' : "#CED4DA"
                })
              }}
              name="userEmail"
              placeholder="Select user"
              isSearchable
              defaultOptions
              isDisabled
              value={prevUser}
            />
            <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.userId}
              </div>
            </FormText>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Manager Name
            </Label>
            <Async
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: (errors && errors.managerUserId) ? '#FF3D60' : "#CED4DA"
                })
              }}
              name="managerUserId"
              placeholder="Select manager name"
              isSearchable
              defaultOptions
              loadOptions={handleEmployeeName}
              onChange={handleAddEmployeeName}
              value={prevEmployee ? (prevEmployee.label === '' && prevEmployee.value === '' ? 'N/A' : prevEmployee) : null}
              isClearable
            />
            <FormText sx={{ marginLeft: "10px" }}>
              <div className="text-danger">
                {errors && errors.managerUserId}
              </div>
            </FormText>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Employee ID <span className="text-danger"> *</span>
            </Label>
            <Input
              type="text"
              name="employeeId"
              placeholder="Enter employee ID"
              onChange={ValidateEmployeeId}
              value={formFields && formFields.employeeId}
              invalid={errors && errors.employeeId}
            />
            {<FormFeedback>{errors && errors.employeeId}</FormFeedback>}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>
              Employee First Name <span className="text-danger"> *</span>
            </Label>
            <Input
              type="text"
              name="employeeFirstName"
              placeholder="Enter employee first name"
              onChange={validateOnChange}
              value={formFields && formFields.employeeFirstName}
              invalid={errors && errors.employeeFirstName}
            />
            {<FormFeedback>{errors && errors.employeeFirstName}</FormFeedback>}
          </FormGroup>
        </Col>

      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>
              Employee Last Name <span className="text-danger"> *</span>
            </Label>
            <Input
              type="text"
              name="employeeLastName"
              placeholder="Enter employee last name"
              onChange={validateOnChange}
              value={formFields && formFields.employeeLastName}
              invalid={errors && errors.employeeLastName}
            />
            {<FormFeedback>{errors && errors.employeeLastName}</FormFeedback>}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>
              Employee Type
            </Label>
            <Select
              name="employeeType"
              placeholder="Select employee type"
              options={EmployeeTyeOptions}
              onChange={handleChangeEmployeeTypeSelect}
              value={empType}
            />
          </FormGroup>
        </Col>

      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Employee Status <span className="text-danger"> *</span>
            </Label>
            <Select
              name="isTerminated"
              placeholder="Select"
              options={EmployeeStatusOptions}
              // onChange={handleIsTerminatedStatusChange}
              onChange={(selectedOption) => {
                setIsTerminated(selectedOption.value);
                setFormFields({ ...formFields, isTerminated: selectedOption.value });
              }}
              value={isTerminated ? { label: 'Terminated', value: true } : { label: 'Active', value: false }}
            // value={isTerminated}
            />
            {<FormFeedback>{errors && errors.isTerminated}</FormFeedback>}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              Employee Designation
            </Label>
            <Input
              type="text"
              name="employeeTitle"
              placeholder="Enter employee designation"
              onChange={validateOnChange}
              value={formFields && formFields.employeeTitle}
            />
            {/* <Select
              name="employeeTitle"
              placeholder="Select title"
              options={TitleOptions}
              onChange={e => handleChangeTitleSelect(e)}
              value={prevTitle.label === "" && prevTitle.value === "" ? null : prevTitle}
            /> */}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Date Of Birth <span className="text-danger">*</span></Label>
            <Input
              type="date"
              name="dateOfBirth"
              placeholder="Select date"
              onChange={validateOnChangeDate}
              value={moment(formFields.dateOfBirth).format("YYYY-MM-DD")}
              invalid={errors && errors.dateOfBirth}
              max={new Date().toISOString().split('T')[0]}
            />
            {<FormFeedback>{errors && errors.dateOfBirth}</FormFeedback>}
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Date Of Join <span className="text-danger">*</span></Label>
            <Input
              type="date"
              name="dateOfJoin"
              placeholder="Select date"
              onChange={validateOnChangeDate}
              value={moment(formFields.dateOfJoin).format("YYYY-MM-DD")}
              invalid={errors && errors.dateOfJoin}
              max={new Date().toISOString().split('T')[0]}
            />
            {<FormFeedback>{errors && errors.dateOfJoin}</FormFeedback>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Gender <span className="text-danger">*</span></Label>
            <Select
              name="gender"
              placeholder="Select gender"
              defaultOptions
              options={GenderOptions}
              onChange={e => handleChangeGenderSelect(e)}
              value={prevGender.label === "" && prevGender.value === "" ? null : prevGender}
              invalid={errors && errors.gender}
            />
            {<FormFeedback>{errors && errors.gender}</FormFeedback>}
          </FormGroup>
        </Col>
        <Col md={3} className='mt-4 pt-2 d-flex align-items-center'>
          <FormGroup>
            <div>
              <Label>
                <Input
                  type="checkbox"
                  checked={isRehired}
                  onClick={handleIsRehiredStatusChange}
                />
                &nbsp;Is Rehired
              </Label>
            </div>
          </FormGroup>
        </Col>
        <Col md={3} className='mt-4 pt-2 d-flex align-items-center'>
          <FormGroup>
            <div>
              <Label>
                <Input
                  type="checkbox"
                  checked={isPeopleManager}
                  onClick={handleIsPeopleManagerStatusChange}
                />
                &nbsp;Is People Manager
              </Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
      {isTerminated ? (
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Date Of Termination <span className="text-danger"> *</span>
              </Label>
              <Input
                type="date"
                name="dateOfTermination"
                placeholder="Select Date"
                onChange={validateOnChangeDate}
                value={moment(formFields.dateOfTermination).format("YYYY-MM-DD")}
                invalid={errors && errors.dateOfTermination}
                max={new Date().toISOString().split('T')[0]}
              />
              {<FormFeedback>{errors && errors.dateOfTermination}</FormFeedback>}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>
                Termination Reason <span className="text-danger"> *</span>
              </Label>
              <Input
                type="textarea"
                name="terminationReason"
                placeholder="Enter Termination Reason"
                onChange={validateOnChange}
                value={formFields && formFields.terminationReason}
                invalid={errors && errors.terminationReason}
              />
              {<FormFeedback>{errors && errors.terminationReason}</FormFeedback>}
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col lg="6" md="6" sm="12">
          <FormGroup>
            <Label>Email Notification Status</Label>
            <ul className="form-unstyled-list form-status-list">
              <li
                onClick={handleEmailNotificationStatusChange}
                className={`form-status-item ${!isMailNotificationEnable ? 'active' : ''}`}
              >
                Disable
              </li>
              <li
                onClick={handleEmailNotificationStatusChange}
                className={`form-status-item ${isMailNotificationEnable ? 'active' : ''}`}
              >
                Enable
              </li>
            </ul>
          </FormGroup>
        </Col>
      </Row>
      <br></br>
      <FormGroup>
        <Button
          className="me-2"
          color="success"
          onClick={handleEditEmployeeMaster}
          disabled={props.isPending}
        >
          {props.isPending ? <>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            &nbsp; Submitting...
          </>
            : <>Submit</>}
        </Button>
        <Button
          color="danger"
          onClick={props.handleClose}
        >
          Cancel
        </Button>
      </FormGroup>
    </React.Fragment >
  )
}
const mapStateToProps = (state) => ({
  isPending: state.Employee.isUpdateEmployeePending,
  errorMessage: state.Employee.updateEmployeeErrorMessage,
  EmployeeDetails: state.Employee.EmployeeDetails,
  currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({

  getUserNameAsyncSelectAction: (appId, searchValue, callback) =>
    dispatch(getUserNameAsyncSelect(appId, searchValue, callback)),
  getManagerNameAsyncSelect: (searchValue, callback) =>
    dispatch(getManagerNameAsyncSelect(searchValue, callback)),
  EditEmployeeMasterAction: (formFields) => dispatch(EditEmployeeMaster(formFields)),
  updateEmployeeSuccess: () => dispatch(updateEmployeeSuccess()),
  getEmployeeIDlist: (appId, empId, ID) => dispatch(getEmployeeIDlist(appId, empId, ID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployee)