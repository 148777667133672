import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row
} from 'reactstrap';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import {
    clearCreateMailSettingsError,
    createNewMailSettings,
    updateMailSettings
} from '../../../../store/Modules/MassMailer/MailSettings/actions';
import FormValidator from '../../../../helper/formValidator';

const CreateMailConfiguration = (props) => {

    const { isMailSettingsCreatePending, errorMessage, mailSettingsDetails } = props;
    const { Id, EmailAddress, FromDisplayName, SMTPPort, UserName, Password, IsActive } = mailSettingsDetails || {};

    const [mailSettingDetails, setMailSettingDetails] = useState();
    const [errors, setErrors] = useState();
    const [viewPassword, setViewPassword] = useState(false);
    const [Active, setActive] = useState();

    useEffect(() => {
        props.clearCreateMailSettingsError();
        setMailSettingDetails({
            ...mailSettingDetails,
            id: Id,
            emailAddress: EmailAddress,
            fromDisplayName: FromDisplayName,
            smtpPort: SMTPPort,
            userName: UserName,
            password: Password,
            isActive: IsActive
        })
        setActive(IsActive)
    }, [])

    //-------------------------------------------------------------FORM-INPUTS----------------------------------------------------------------------------
    const validateOnchange = (e) => {
        const { name, value } = e.target;
        let result = {};
        if (name === 'emailAddress') {
            if (value) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                result = {
                    isValid: emailRegex.test(value),
                    message: 'Enter valid email',
                }
            }
        }
        else if (name === 'bcc') {
            if (value) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                result = {
                    isValid: emailRegex.test(value),
                    message: 'Enter valid email',
                }
            }
        }
        else {
            result = FormValidator.validate(e.target);
        }
        setMailSettingDetails({
            ...mailSettingDetails,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: result.isValid ? null : result.message,
        })
    }

    const handleActiveStatusChange = () => {
        setMailSettingDetails({
            ...mailSettingDetails,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }

    //------------------------------------------------------------FORM-SUBMISSION--------------------------------------------------------------------
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { emailAddress, fromDisplayName, smtpPort, userName, password } = mailSettingDetails || {};

        if (!emailAddress || !emailAddress.trim()) {
            currentErrors.emailAddress = 'Email address is required';
        }
        if (errors && errors.emailAddress) {
            currentErrors.emailAddress = 'Enter valid email';
        }
        if (errors && errors.bcc) {
            currentErrors.bcc = 'Enter valid email';
        }
        if (!fromDisplayName || !fromDisplayName.trim()) {
            currentErrors.fromDisplayName = 'From name is required';
        }
        if (!smtpPort || !smtpPort.trim()) {
            currentErrors.smtpPort = 'Port number is required';
        }
        if (!userName || !userName.trim()) {
            currentErrors.userName = 'Username is required';
        }
        if (!password || !password.trim()) {
            currentErrors.password = 'Password is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateMailSettings(mailSettingDetails)
        }
    }
    return (
        <>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label>Email Address <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="emailAddress"
                        placeHolder="Enter email address"
                        onChange={validateOnchange}
                        value={mailSettingDetails && mailSettingDetails.emailAddress}
                        invalid={errors && errors.emailAddress}
                    />
                    <FormFeedback>{errors && errors.emailAddress}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>BCC </Label>
                    <Input
                        type="text"
                        name="bcc"
                        placeHolder="Enter BCC"
                        onChange={validateOnchange}
                        value={mailSettingDetails && mailSettingDetails.bcc}
                        invalid={errors && errors.bcc}
                    />
                    <FormFeedback>{errors && errors.bcc}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>From Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="fromDisplayName"
                        placeHolder="Enter from name"
                        onChange={validateOnchange}
                        value={mailSettingDetails && mailSettingDetails.fromDisplayName}
                        invalid={errors && errors.fromDisplayName}
                    />
                    <FormFeedback>{errors && errors.fromDisplayName}</FormFeedback>
                </FormGroup>
                <Row>
                    <Col md='4'>
                        <FormGroup>
                            <Label>Port Number <span className='text-danger'>*</span></Label>
                            <Input
                                type="text"
                                name="smtpPort"
                                placeHolder="Enter port no."
                                onChange={validateOnchange}
                                value={mailSettingDetails && mailSettingDetails.smtpPort}
                                invalid={errors && errors.smtpPort}
                            />
                            <FormFeedback>{errors && errors.smtpPort}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md='8'>
                        <FormGroup>
                            <Label>Username <span className='text-danger'>*</span></Label>
                            <Input
                                type="text"
                                name="userName"
                                placeHolder="Enter username"
                                onChange={validateOnchange}
                                value={mailSettingDetails && mailSettingDetails.userName}
                                invalid={errors && errors.userName}
                            />
                            <FormFeedback>{errors && errors.userName}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label>Password <span className='text-danger'>*</span></Label>
                    <InputGroup>
                        <Input
                            className='border-end-0'
                            type={viewPassword ? "text" : "password"}
                            name="password"
                            placeHolder="Enter password"
                            onChange={validateOnchange}
                            value={mailSettingDetails && mailSettingDetails.password}
                            invalid={errors && errors.password}
                        />
                        <Button
                            className='border-start-0 rounded-end'
                            color='white'
                            style={errors && errors.password ? { border: '1px solid red' } : { border: '1px solid #C7C9CE' }}
                            onClick={(e) => {
                                e.preventDefault();
                                setViewPassword(!viewPassword);
                            }}>
                            {viewPassword ?
                                <i className="fas fa-eye text-secondary" />
                                :
                                <i class="fas fa-eye-slash text-secondary" />
                            }
                        </Button>
                        <FormFeedback>{errors && errors.password}</FormFeedback>
                    </InputGroup>
                </FormGroup> <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isMailSettingsCreatePending}
                    >
                        {isMailSettingsCreatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    mailSettingsDetails: state.mailSetting.mailSettingsDetails,
    isMailSettingsCreatePending: state.mailSetting.isMailSettingsCreatePending,
    errorMessage: state.mailSetting.mailSettingsCreateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateMailSettings: (mailSettingDetails) => dispatch(updateMailSettings(mailSettingDetails)),
    clearCreateMailSettingsError: () => dispatch(clearCreateMailSettingsError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateMailConfiguration);