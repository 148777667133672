
import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { ALL_TIMESHEET_EXPORT_LIST_ERROR, ALL_TIMESHEET_EXPORT_LIST_PENDING, ALL_TIMESHEET_LIST_ERROR, ALL_TIMESHEET_LIST_PENDING, CLEAR_ALL_TIMESHEET_EXPORT_LIST_ERROR, CLEAR_ALL_TIMESHEET_LIST_ERROR, SET_ALL_TIMESHEET_EXPORT_LIST, SET_ALL_TIMESHEET_LIST } from "./constants";

export const setAllTimeSheetList = (AllTimeSheet) => ({
    type: SET_ALL_TIMESHEET_LIST,
    payload: { AllTimeSheet },
})
export const setAllTimeSheetExportList = (AllTimeSheet) => ({
    type: SET_ALL_TIMESHEET_EXPORT_LIST,
    payload: { AllTimeSheet },
})

export const AllTimesheetListError = (allTimesheetErrorMessage) => ({
    type: ALL_TIMESHEET_LIST_ERROR,
    payload: { allTimesheetErrorMessage },
});
export const AllTimesheetExportListError = (allTimesheetErrorMessage) => ({
    type: ALL_TIMESHEET_EXPORT_LIST_ERROR,
    payload: { allTimesheetErrorMessage },
});

export const AllTimesheetListPending = () => ({
    type: ALL_TIMESHEET_LIST_PENDING,
});
export const AllTimesheetExportListPending = () => ({
    type: ALL_TIMESHEET_EXPORT_LIST_PENDING,
});

export const ClearAllTimesheetListError = () => ({
    type: CLEAR_ALL_TIMESHEET_LIST_ERROR,
});
export const ClearAllTimesheetExportListError = () => ({
    type: CLEAR_ALL_TIMESHEET_EXPORT_LIST_ERROR,
});


export const getAllTimeSheetExportListAction = (searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchworkDate, searchworkHours, searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedTimeStamp, id) => (dispatch) => {
    dispatch(AllTimesheetExportListPending())
    return getToken(dispatch).then((accessToken) => {
        return agent.AllTimeSheet.getAllTimeSheetExportList(accessToken, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchworkDate, searchworkHours, searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedTimeStamp, id)
            .then((AllTaskList) => {
                // console.log("AllTaskList:", AllTaskList);
                dispatch(setAllTimeSheetExportList(AllTaskList));
                dispatch(ClearAllTimesheetExportListError());
                return Promise.resolve(AllTaskList);
            })
            .catch((err) => {
                console.log('getAllTimeSheetExportListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(AllTimesheetExportListError(error))
            });
    });
};
export const getAllTimeSheetListActionModal = (page, PageSize, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchworkDate, searchworkHours, searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedTimeStamp, id) => (dispatch) => {
    dispatch(AllTimesheetListPending())
    getToken(dispatch).then((accessToken) => {
        agent.AllTimeSheet.getAllTimeSheetList(accessToken, page, PageSize, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchworkDate, searchworkHours, searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedTimeStamp, id)
            .then((AllTaskList) => {
                // console.log("AllTaskList:", AllTaskList);
                dispatch(setAllTimeSheetList(AllTaskList));
                dispatch(ClearAllTimesheetListError());
            })
            .catch((err) => {
                console.log('getAllTimesheetsList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(AllTimesheetListError(error))
            });
    });
};