import React, { useEffect, useState } from 'react'
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import VWMessage from '../../../../../../components/vwMessage/vwMessage'
import { connect } from 'react-redux';
import { clearUpdateVisitTypeError, updateVisitType, updateVisitTypeSuccess } from '../../../../../../store/Modules/crm/Settings/VisitType/actions';

const EditVisit = (props) => {

    const { visitTypeDetails, isVisitTypeUpdatePending, errorMessage } = props;
    const { Id, VisitTypeName, IsActive } = visitTypeDetails || {};

    const [visitTypeDetail, setVisitTypeDetail] = useState();
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();

    useEffect(() => {
        setVisitTypeDetail({
            ...visitTypeDetail,
            id: Id,
            VisitTypeName: VisitTypeName,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateVisitTypeError();
        props.updateVisitTypeSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setVisitTypeDetail({
            ...visitTypeDetail,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        })
    }
    const handleActiveStatusChange = () => {
        setVisitTypeDetail({
            ...visitTypeDetail,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { VisitTypeName } = visitTypeDetail || {};

        if (!VisitTypeName) {
            currentErrors.VisitTypeName = 'Visit type is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateVisitType(visitTypeDetail)

        }
    }
  return (
      <>
          {errorMessage && (
              <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
          )}
          <Form>
              <FormGroup>
                  <Label>Visit Type  <span className='text-danger'>*</span></Label>
                  <Input
                      type="text"
                      name="VisitTypeName"
                      placeHolder="Enter visit type"
                      onChange={validateOnchange}
                      value={visitTypeDetail && visitTypeDetail.VisitTypeName}
                      invalid={errors && errors.VisitTypeName}
                  />
                  <FormFeedback>{errors && errors.VisitTypeName}</FormFeedback>
              </FormGroup>
              <FormGroup>
                  <Label className="col-form-label">Status</Label>
                  <ul className="form-unstyled-list form-status-list">
                      <li
                          onClick={handleActiveStatusChange}
                          className={`form-status-item ${!Active ? 'active' : ''}`}
                      >
                          Inactive
                      </li>
                      <li
                          onClick={handleActiveStatusChange}
                          className={`form-status-item ${Active ? 'active' : ''}`}
                      >
                          Active
                      </li>
                  </ul>
              </FormGroup>
              <FormGroup>
                  <Button
                      color='success'
                      className='me-2'
                      onClick={handleSubmit}
                      disabled={isVisitTypeUpdatePending}
                  >
                      {isVisitTypeUpdatePending ?
                          <div>
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                              Submitting...
                          </div>
                          : "Submit"}
                  </Button>
                  <Button
                      color='danger'
                      onClick={props.handleClose}
                  >Cancel
                  </Button>
              </FormGroup>
          </Form>
      </>
  )
}

const mapStateToProps = (state) => ({
    visitTypeDetails: state.VisitType.visitTypeDetails,
    isVisitTypeUpdatePending: state.VisitType.isVisitTypeUpdatePending,
    errorMessage: state.VisitType.visitTypeUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateVisitType: (visitTypeDetail) => dispatch(updateVisitType(visitTypeDetail)),
    clearUpdateVisitTypeError: () => dispatch(clearUpdateVisitTypeError()),
    updateVisitTypeSuccess: () => dispatch(updateVisitTypeSuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(EditVisit)