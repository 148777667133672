import React, { useEffect, useState } from 'react'
import { getPager } from '../../../../helper/common';
import { connect } from 'react-redux';
import {
    closeVisitCreate,
    getVisitExportListAction,
    getVisitList,
    getVisitTypeListAsyncSelect,
    openVisitCreate
} from '../../../../store/Modules/crm/Visit/actions';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Table,
    Tooltip
} from 'reactstrap';
import VWPagination from '../../../../components/vwPagination/vwPagination';
import NoRecord from '../../../../components/NoRecords/noRecords';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { Link, useNavigate } from 'react-router-dom';
import UITooltip from '../../../../CommonData/Data/UITooltip';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faFont,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import Async from "react-select/async"
import Select from 'react-select';
import moment from 'moment-timezone';
import Create from './create';
import VWModel from '../../../../components/Modal/VWModal';
import { VISIT_EXPORT_HEADERS } from '../../../../store/Modules/crm/Visit/constants';
import { excelPrint } from '../../../../helper/export';
import { ElementToolTip } from '../../Projects/vwToolTip';

const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const transportModeOptions = [
    { label: 'Bike', value: 'Bike' },
    { label: 'Bus', value: 'Bus' },
    { label: 'Car', value: 'Car' },
    { label: 'Flight', value: 'Flight' },
    { label: 'Train', value: 'Train' },
    { label: 'Others', value: 'Others' },
]

const visitStatusOptions = [
    { label: 'Completed', value: 'Completed' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'In Progress', value: 'InProgress' },
]

const List = (props) => {

    const { visitListCount, crmVisitList, isVisitListPending, visitListErrorMessage, isCreateVisitOpen, isVisitExportListPending, currentProfile } = props;

    //----------------------------ROLE-------------------------------------------
    const { userAppDetails } = currentProfile || [];
    const appData = userAppDetails && userAppDetails.filter((app) => app.appName === "CRM");
    const { appRoleDetails } = appData.length > 0 ? appData[0] : null;
    const adminRoleData = appRoleDetails && appRoleDetails.filter((role) => role.roleName === "Administrator");
    const isAdmin = adminRoleData && adminRoleData.length > 0;

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();

    const path = window.location.search;
    const params = new URLSearchParams(path);
    console.log("path:", path);
    console.log("params:", params);

    const StartDate = params.get('startDate');
    const EndDate = params.get('endDate');

    const buildQueryVisitSource = () => {
        const { VisitSourceCategory } = filter || {};
        let searchvisitSourceName = '';
        if (VisitSourceCategory) {
            searchvisitSourceName += `${VisitSourceCategory}`;
        }
        return searchvisitSourceName
    }
    const buildQueryOwnerUserName = () => {
        const { OwnerUserName } = filter || {};
        let searchOwnerUserName = '';
        if (OwnerUserName) {
            searchOwnerUserName += `${OwnerUserName}`;
        }
        return searchOwnerUserName
    }
    const buildQueryPrimaryContactEmail = () => {
        const { PrimaryContactEmail } = filter || {};
        let searchPrimaryContactEmail = '';
        if (PrimaryContactEmail) {
            searchPrimaryContactEmail += `${PrimaryContactEmail}`;
        }
        return searchPrimaryContactEmail
    }
    const buildQuerySubject = () => {
        const { Subject } = filter || {};
        let searchSubject = '';
        if (Subject) {
            searchSubject += `${Subject}`;
        }
        return searchSubject
    }
    const buildQueryVisitTypeName = () => {
        const { VisitTypeName } = filter || {};
        let searchVisitTypeName = '';
        if (VisitTypeName) {
            searchVisitTypeName += `${VisitTypeName}`;
        }
        return searchVisitTypeName
    }
    const buildQueryStartDate = () => {
        const { StartDate } = filter || {};
        let searchStartDate = '';
        if (StartDate) {
            searchStartDate += `${StartDate}`;
        }
        return searchStartDate
    }
    const buildQueryEndDate = () => {
        const { EndDate } = filter || {};
        let searchEndDate = '';
        if (EndDate) {
            searchEndDate += `${EndDate}`;
        }
        return searchEndDate
    }
    const buildQueryStartLocation = () => {
        const { StartLocation } = filter || {};
        let searchStartLocation = '';
        if (StartLocation) {
            searchStartLocation += `${StartLocation}`;
        }
        return searchStartLocation
    }
    const buildQueryEndLocation = () => {
        const { EndLocation } = filter || {};
        let searchEndLocation = '';
        if (EndLocation) {
            searchEndLocation += `${EndLocation}`;
        }
        return searchEndLocation
    }
    const buildQueryDistance = () => {
        const { Distance } = filter || {};
        let searchDistance = '';
        if (Distance) {
            searchDistance += `${Distance}`;
        }
        return searchDistance
    }
    const buildQueryCreatedBy = () => {
        const { CreatedBy } = filter || {};
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }
    const buildQueryCreatedTimeStamp = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedTimeStamp = '';
        if (CreatedTimeStamp) {
            searchCreatedTimeStamp += `${CreatedTimeStamp}`;
        }
        return searchCreatedTimeStamp
    }
    const buildQueryTransportMode = () => {
        const { TransportMode } = filter || {};
        let searchTransportMode = '';
        if (TransportMode) {
            searchTransportMode += `${TransportMode}`;
        }
        return searchTransportMode
    }

    const buildQueryFromDate = () => {
        const { fromDate } = filter || {};
        let searchFromDate = '';
        if (fromDate) {
            searchFromDate += `${fromDate}`;
        }
        return searchFromDate
    }

    const buildQueryToDate = () => {
        const { toDate } = filter || {};
        let searchToDate = '';
        if (toDate) {
            searchToDate += `${toDate}`;
        }
        return searchToDate
    }
    const buildQueryVisitStatus = () => {
        const { VisitStatus } = filter || {};
        let searchVisitStatus = '';
        if (VisitStatus) {
            searchVisitStatus += `${VisitStatus}`;
        }
        return searchVisitStatus
    }
    const buildQueryVisitAddress = () => {
        const { VisitAddress } = filter || {};
        let searchVisitAddress = '';
        if (VisitAddress) {
            searchVisitAddress += `${VisitAddress}`;
        }
        return searchVisitAddress
    }

    const setPage = (page = 1) => {
        props.getVisitList(filter, page, PageSize, buildQueryVisitSource(), buildQueryOwnerUserName(), buildQueryPrimaryContactEmail(), buildQuerySubject(), buildQueryVisitTypeName(),
            buildQueryStartDate(), buildQueryEndDate(), buildQueryStartLocation(), buildQueryEndLocation(), buildQueryDistance(), buildQueryCreatedBy(),
            buildQueryCreatedTimeStamp(), buildQueryTransportMode(), buildQueryFromDate(), buildQueryToDate(), buildQueryVisitStatus(), buildQueryVisitAddress());
        setPager(prevPager => {
            const totalPages = Math.ceil(visitListCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(visitListCount, 1, PageSize)
        setPager(currentPager)
    }, [visitListCount])

    useEffect(() => {
        if (StartDate || EndDate) {
            setFilter({
                ...filter,
                CreatedTimeStamp: StartDate,
                UpdatedTimeStamp: EndDate,
            })
        } else {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        }
    }, [])
    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }
    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }

    const searchVisitType = (option) => {
        const { label } = option || {};
        setFilter({
            ...filter,
            VisitTypeName: label,
        })
    }
    const searchtransportModeOptions = (option) => {
        const { label } = option || {};
        setFilter({
            ...filter,
            TransportMode: label,
        })
    }
    const searchVisitStatus = (option) => {
        const { value } = option || {};
        setFilter({
            ...filter,
            VisitStatus: value,
        })
    }

    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])
    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const [filterSelectionOption, setFilterSelectionOption] = useState('name');
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    //-----------------------------------------On Double Click--------------------------------------------------
    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/crm/visits/details/${Id}`);
    };
    // ----------------------------------------SORTING-----------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedVisitList = [...crmVisitList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';


        if (sortOrder === 'Distance') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        if (sortOrder === 'IsTerminated') {
            return sortDirection === 'asc' ? b.IsTerminated - a.IsTerminated : a.IsTerminated - b.IsTerminated;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //----------------------------------------------Filter Visit Type -----------------------------------------------

    const handleVisitType = (inputValue, callback) => {
        const { getVisitTypeAsyncSelect } = props;
        return getVisitTypeAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    //------------------------------------------VISIT-CREATE-MODAL------------------------------------------------
    const createVisitOpen = () => {
        props.openVisitCreate();
    }
    const handleClose = () => {
        props.closeVisitCreate();
    }

    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {

        props.getVisitExportListAction(filter,buildQueryVisitSource(), buildQueryOwnerUserName(), buildQueryPrimaryContactEmail(), buildQuerySubject(), buildQueryVisitTypeName(),
            buildQueryStartDate(), buildQueryEndDate(), buildQueryStartLocation(), buildQueryEndLocation(), buildQueryDistance(), buildQueryCreatedBy(),
            buildQueryCreatedTimeStamp(), buildQueryTransportMode(), buildQueryFromDate(), buildQueryToDate(), buildQueryVisitStatus(), buildQueryVisitAddress())
            .then((response) => {
                if (response) {
                    const exportVisitListExportList = [];
                    const headers = VISIT_EXPORT_HEADERS;
                    const headerSizes = [30, 30, 50, 30, 50, 50, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 50];
                    response && response.forEach((element) => {
                        exportVisitListExportList.push({
                            visitSourceName: `${element.visitSourceName || "N/A"} - ${element.visitSourceCategory || "N/A"}`,
                            ownerUserName: element.ownerUserName || "N/A",
                            ownerUserEmail: element.ownerUserEmail || "N/A",
                            primaryContactName: element.primaryContactName || "N/A",
                            primaryContactEmail: element.primaryContactEmail || "N/A",
                            subject: element.subject || "N/A",
                            visitTypeName: element.visitTypeName || "N/A",
                            startDate: element.startDate ? moment(element.startDate).format('DD MMM YYYY') : 'N/A',
                            endDate: element.endDate ? moment(element.endDate).format('DD MMM YYYY') : 'N/A',
                            startLocation: element.startLocation || "N/A",
                            endLocation: element.endLocation || "N/A",
                            distance: element.distance || 0,
                            transportMode: element.transportMode || "N/A",
                            createdBy: element.createdBy || "N/A",
                            createdTimeStamp: element.createdTimeStamp ? moment(element.createdTimeStamp).format('DD MMM YYYY') : 'N/A',
                            visitStatus: element.visitStatus || "N/A",
                            visitAddress: element.visitAddress || "N/A",
                        });
                    });
                    excelPrint(exportVisitListExportList, "Visit-List", headers, headerSizes);
                }
            })
    }
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    return (
        <div className="page-content">
            <Card style={{ overflow: 'auto' }}>
                <CardTitle tag="h4"
                    className="border-bottom px-4 mb-0 d-flex justify-content-between align-items-center gap-2">
                    <strong className='d-flex align-items-center text-nowrap'>VISITS  -&nbsp;<Badge color="danger" id={'VisitsCount'}>{props && props.visitListCount || 0}</Badge>
                        <ElementToolTip id={'VisitsCount'} name={
                            'Visits Count'
                        } />
                    </strong>
                    <div className='d-flex gap-2 align-items-center align-items-center'>
                        <FormGroup>
                            <Label>From Date</Label>
                            <Input
                                type='date'
                                name='fromDate'
                                onChange={searchInputValidation}
                                max={(filter && filter.toDate) ? moment(filter.toDate).format('YYYY-MM-DD') : "9999-12-31"}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>To Date</Label>
                            <Input
                                type='date'
                                name='toDate'
                                onChange={searchInputValidation}
                                min={(filter && filter.fromDate) ? moment(filter.fromDate).format('YYYY-MM-DD') : null}
                                max="9999-12-31"
                            />
                        </FormGroup>
                        <Button
                            className='mt-2'
                            color="primary"
                            onClick={createVisitOpen}
                        >
                            CREATE
                        </Button>
                        {isAdmin &&
                            <Button
                                className="mt-2"
                                color="success"
                                onClick={handleExports}
                                disabled={isVisitExportListPending || !props.visitListCount}
                            >
                                {isVisitExportListPending ? 'EXPORTING...' : 'EXPORT'}
                            </Button>}
                    </div>
                </CardTitle>
            </Card>
            <Card>
                <CardBody className="p-4">
                    {visitListErrorMessage && (
                        <VWMessage type="danger" defaultMessage={visitListErrorMessage} baseClassName="alert" ></VWMessage>
                    )}
                    <div style={{ height: "60vh", overflowX: "auto" }}>
                        <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                            <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                <tr>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('VisitSourceCategory')} className='hand-cursor'>
                                        Visit Name &nbsp;
                                        {sortOrder === 'VisitSourceCategory' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('OwnerUserName')} className='hand-cursor'>
                                        Visit User &nbsp;
                                        {sortOrder === 'OwnerUserName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('PrimaryContactEmail')} className='hand-cursor'>
                                        Contact &nbsp;
                                        {sortOrder === 'PrimaryContactEmail' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Subject')} className='hand-cursor'>
                                        Subject &nbsp;
                                        {sortOrder === 'Subject' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('VisitTypeName')} className='hand-cursor'>
                                        Visit Type &nbsp;
                                        {sortOrder === 'VisitTypeName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('StartDate')} className='hand-cursor'>
                                        Start Date &nbsp;
                                        {sortOrder === 'StartDate' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('EndDate')} className='hand-cursor'>
                                        End Date &nbsp;
                                        {sortOrder === 'EndDate' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('StartLocation')} className='hand-cursor'>
                                        Start Location &nbsp;
                                        {sortOrder === 'StartLocation' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('EndLocation')} className='hand-cursor'>
                                        End Location &nbsp;
                                        {sortOrder === 'EndLocation' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Distance')} className='hand-cursor'>
                                        Distance &nbsp;
                                        {sortOrder === 'Distance' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('TransportMode')} className='hand-cursor'>
                                        Transport Mode &nbsp;
                                        {sortOrder === 'TransportMode' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('VisitStatus')} className='hand-cursor'>
                                        Visit Status &nbsp;
                                        {sortOrder === 'VisitStatus' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('VisitAddress')} className='hand-cursor'>
                                        Visit Address &nbsp;
                                        {sortOrder === 'VisitAddress' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('CreatedBy')} className='hand-cursor'>
                                        Created By &nbsp;
                                        {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                        View
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <Input
                                            type="text"
                                            name="VisitSourceCategory"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="OwnerUserName"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="PrimaryContactEmail"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="Subject"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Async
                                            styles={customStyles}
                                            name="VisitTypeName"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            loadOptions={handleVisitType}
                                            onChange={searchVisitType}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="date"
                                            name="StartDate"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                            value={filter && filter.StartDate}
                                            onKeyPress={handleKeyPress}
                                            max="9999-12-31"
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="date"
                                            name="EndDate"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                            value={filter && filter.EndDate}
                                            onKeyPress={handleKeyPress}
                                            max="9999-12-31"
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="StartLocation"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="EndLocation"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            onKeyDown={
                                                (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                            }
                                            onWheel={(e) => e.target.blur()}
                                            min='0'
                                            type="number"
                                            name="Distance"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            name="TransportMode"
                                            isClearable
                                            defaultOptions
                                            placeholder="Search"
                                            options={transportModeOptions}
                                            onChange={searchtransportModeOptions}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            name='VisitStatus'
                                            placeholder='Select your visit status'
                                            options={visitStatusOptions}
                                            onChange={searchVisitStatus}
                                            isClearable
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="VisitAddress"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <InputGroup style={{ width: "200px" }}>
                                            {filterSelectionOption === 'name' ?
                                                <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                    <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                </UITooltip>
                                                : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                    <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                </UITooltip>
                                            }
                                            <Input
                                                style={{ width: "120px" }}
                                                type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                placeholder='Search'
                                                name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                onChange={searchInputValidation}
                                                onKeyPress={handleKeyPress}
                                                value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                                max={filterSelectionOption !== "name" && "9999-12-31"}
                                            />
                                        </InputGroup>
                                    </th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isVisitListPending && (crmVisitList && crmVisitList.length > 0) ?
                                    sortedVisitList && sortedVisitList.map((data, index) => (
                                        <tr key={index} onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                            <td className='ps-3'>{`${data.VisitSourceCategory || 'N/A'} - ${data.VisitSourceName || 'N/A'}`}</td>
                                            <td className='ps-3'>
                                                {data.OwnerUserName || "N/A"}<br />
                                                <small>{data.OwnerUserEmail || 'N/A'}</small>
                                            </td>
                                            <td className='ps-3'>
                                                {data.PrimaryContactName || "N/A"}<br />
                                                <small>{data.PrimaryContactEmail || 'N/A'}</small>
                                            </td>
                                            {/* <td className='ps-3'>{data.Subject ? (data.Subject && data.Subject.length > 20) ? data.Subject && data.Subject.substring(0, 20) + '...' : data.Subject : "N/A"}</td> */}
                                            <td className='ps-3'>{data.Subject ? (data.Subject && data.Subject.length > 20) ? <><span id={`SubjectName-${data.Id}`}>{data.Subject && data.Subject.substring(0, 20) + '...'}</span>
                                                <ElementToolTip
                                                    id={`SubjectName-${data.Id}`}
                                                    name={data.Subject && data.Subject.length > 20 ? data.Subject : ''}
                                                >
                                                    {data.Subject && data.Subject.length > 20
                                                        ? data.Subject && data.Subject.substring(0, 20) + '...'
                                                        : data.Subject}
                                                </ElementToolTip>
                                            </>
                                                : data.Subject : "N/A"}
                                            </td>
                                            <td className='ps-3'>{data.VisitTypeName || "N/A"}</td>
                                            <td className='ps-3'>{data.StartDate ? moment(data.StartDate).format('DD MMM YYYY') : 'N/A'}</td>
                                            <td className='ps-3'>{data.EndDate ? moment(data.EndDate).format('DD MMM YYYY') : 'N/A'}</td>
                                            <td className='ps-3'>{data.StartLocation || 'N/A'}</td>
                                            <td className='ps-3'>{data.EndLocation || 'N/A'}</td>
                                            <td className='ps-3'>{`${data.Distance} KM` || 'N/A'}</td>
                                            <td className='ps-3'>{data.TransportMode || 'N/A'}</td>
                                            <td className='ps-3'>{data.VisitStatus || 'N/A'}</td>
                                            <td className='ps-3'>{data.VisitAddress || 'N/A'}</td>
                                            <td className='ps-3'>
                                                {data.CreatedBy || "N/A"}<br />
                                                {data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : 'N/A'}
                                            </td>
                                            <td className='sticky-column' style={{ backgroundColor: "white" }}>
                                                <Link className="btn btn-sm btn-light" to={`/crm/visits/details/${data.Id}`}>
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link></td>
                                        </tr>)) :
                                    isVisitListPending ?
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <VWSpinner />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <NoRecord />
                                                </div>
                                            </div>
                                        </div>}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <CardFooter>
                    {crmVisitList && crmVisitList.length > 0 ?
                        <VWPagination
                            pager={Pager}
                            setPage={setPage}
                            totalRecords={visitListCount}
                            pageSize={PageSize}
                        /> : null
                    }
                </CardFooter>
            </Card>
            <VWModel
                header="CREATE VISIT"
                modalSize="extra-large"
                isOpen={isCreateVisitOpen}
                handleClose={handleClose}
            >
                <Create handleClose={handleClose} filteredData={filter} />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isCreateVisitOpen: state.CrmVisit.isCreateVisitOpen,
    crmVisitList: state.CrmVisit.crmVisitList,
    visitListCount: state.CrmVisit.visitListCount,
    isVisitListPending: state.CrmVisit.isVisitListPending,
    visitListErrorMessage: state.CrmVisit.visitListErrorMessage,
    visitExportList: state.CrmVisit.visitExportList,
    isVisitExportListPending: state.CrmVisit.isVisitExportListPending,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    openVisitCreate: () => dispatch(openVisitCreate()),
    closeVisitCreate: () => dispatch(closeVisitCreate()),
    getVisitTypeAsyncSelect: (searchValue, callback) => dispatch(getVisitTypeListAsyncSelect(searchValue, callback)),
    getVisitList: (filter, page, PageSize, searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName, searchStartDate, searchEndDate,
        searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp, searchTransportMode, searchFromDate,
        searchToDate, searchVisitStatus, searchVisitAddress) => dispatch(getVisitList(filter, page, PageSize, searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName,
            searchStartDate, searchEndDate, searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp,
            searchTransportMode, searchFromDate, searchToDate, searchVisitStatus, searchVisitAddress)),
    getVisitExportListAction: (filter,searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName, searchStartDate, searchEndDate,
        searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp, searchTransportMode, searchFromDate,
        searchToDate, searchVisitStatus, searchVisitAddress) => dispatch(getVisitExportListAction(filter,searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName,
            searchStartDate, searchEndDate, searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp,
            searchTransportMode, searchFromDate, searchToDate, searchVisitStatus, searchVisitAddress)),
})
export default connect(mapStateToProps, mapDispatchToProps)(List)