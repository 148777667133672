import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Badge, Button, Card, CardFooter, CardTitle, FormGroup, Input, Label, Table } from 'reactstrap'
import { clearForm } from '../../../../store/form/actions';
import { clearSetPath } from '../../../../store/actions';
import { getPager } from '../../../../helper/common';
import { clearLeaveTrackerReportExportListError, getLeaveReportListAction, getLeaveTrackerReportExport } from '../../../../store/Modules/LeaveTracker/LeaveTrackerReport/actions';
import { getOrgLeaveTypeListAction } from '../../../../store/Modules/LeaveTracker/LeaveType/actions';
import VWPagination from '../../../../components/vwPagination/vwPagination';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import NoRecord from '../../../../components/NoRecords/noRecords';
import { excelPrint, excelSheetExport } from '../../../../helper/export';
import { LEAVE_TRACKER_REPORT_EXPORT_HEADERS } from '../../../../store/Modules/LeaveTracker/LeaveTrackerReport/constants';
import moment from 'moment-timezone';

const LeaveReports = (props) => {

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
        if (name === 'startDate') {
            setStartDate(value);
        } else if (name === 'endDate') {
            setEndDate(value);
        }
    }

    const handleSearchClick = () => {
        setPage();
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])
    //--------------------------------------------------------------------------------------------------------------------------------------

    const setPage = (page = 1) => {
        props.getLeaveReportList(page, PageSize, filter);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        console.log("currentPageNumber:", currentPageNumber);
        // setPage(currentPageNumber);
        props.ClearPath();
        props.getOrgLeaveTypeList();
        props.clearLeaveTrackerReportExportListError();
        //---------------------date filter--------------------------
        const currentYear = moment().year();
        const startOfYear = moment(`${currentYear}-01-01`).format("YYYY-MM-DD") || null;
        const endOfYear = moment(`${currentYear}-12-31`).format("YYYY-MM-DD") || null;
        console.log("startOfYear:", startOfYear);
        console.log("endOfYear:", endOfYear);
        setStartDate(startOfYear);
        setEndDate(endOfYear);
        setFilter({ ...filter, startDate: startOfYear, endDate: endOfYear }); // initially it trigger api call
    }, [])

    // --------------------------------------- Enable Leave Type --------------------------------------------------------------------
    const Type = props.OrgLeaveTypeList && props.OrgLeaveTypeList.filter((i) => (
        i.IsEnable === true));
    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {
        props.getLeaveReportExport(filter)
            .then((response) => {
                if (response) {
                    const exportLeavetrackerReportExportList = [];
                    // let headers = ["USER NAME"];
                    // const headerSizes = [30];
                    let headerExcel = [
                        { headerName: "USER NAME", key: "userName", headerWidhth: 60 },
                    ];
                    Type.forEach((enabledType) => {
                        switch (enabledType.LeavetypeName) {
                            case "Privilege Leave":
                                headerExcel.push(
                                    { headerName: "PRIVILAGE TOTAL LEAVE COUNT", key: "privilageTotalLeaveCount", headerWidhth: 60 },
                                    { headerName: "PRIVILAGE TAKEN LEAVE COUNT", key: "privilageTakenLeaveCount", headerWidhth: 60 },
                                    { headerName: "PRIVILAGE PENDING LEAVE COUNT", key: "privilagePendingLeaveCount", headerWidhth: 60 },
                                    { headerName: "PRIVILAGE PAID LEAVE COUNT", key: "privilageUnPaidLeaveCount", headerWidhth: 60 },
                                    { headerName: "PRIVILAGE LOP LEAVE COUNT", key: "privilageLOPLeaveCount", headerWidhth: 60 }
                                );
                                // headerSizes.push(...Array(5).fill(30));
                                break;
                            case "Sick Leave":
                                headerExcel.push(
                                    { headerName: "SICK TOTAL LEAVE COUNT", key: "sickTotalLeaveCount", headerWidhth: 60 },
                                    { headerName: "SICK TAKEN LEAVE COUNT", key: "sickTakenLeaveCount", headerWidhth: 60 },
                                    { headerName: "SICK PENDING LEAVE COUNT", key: "sickPendingLeaveCount", headerWidhth: 60 },
                                    { headerName: "SICK PAID LEAVE COUNT", key: "sickUnPaidLeaveCount", headerWidhth: 60 },
                                    { headerName: "SICK LOP LEAVE COUNT", key: "sickLOPLeaveCount", headerWidhth: 60 }
                                );
                                // headerSizes.push(...Array(5).fill(30));
                                break;
                            case "Maternal Leave":
                                headerExcel.push(
                                    { headerName: "MATERNAL TOTAL LEAVE COUNT", key: "maternalTotalLeaveCount", headerWidhth: 60 },
                                    { headerName: "MATERNAL TAKEN LEAVE COUNT", key: "maternalTakenLeaveCount", headerWidhth: 60 },
                                    { headerName: "MATERNAL PENDING LEAVE COUNT", key: "maternalPendingLeaveCount", headerWidhth: 60 },
                                    { headerName: "MATERNAL PAID LEAVE COUNT", key: "maternalUnPaidLeaveCount", headerWidhth: 60 },
                                    { headerName: "MATERNAL LOP LEAVE COUNT", key: "maternalLOPLeaveCount", headerWidhth: 60 }
                                );
                                // headerSizes.push(...Array(5).fill(30));
                                break;
                            case "Paternal Leave":
                                headerExcel.push(
                                    { headerName: "PATERNAL TOTAL LEAVE COUNT", key: "paternalTotalLeaveCount", headerWidhth: 60 },
                                    { headerName: "PATERNAL TAKEN LEAVE COUNT", key: "paternalTakenLeaveCount", headerWidhth: 60 },
                                    { headerName: "PATERNAL PENDING LEAVE COUNT", key: "paternalPendingLeaveCount", headerWidhth: 60 },
                                    { headerName: "PATERNAL PAID LEAVE COUNT", key: "paternalUnPaidLeaveCount", headerWidhth: 60 },
                                    { headerName: "PATERNAL LOP LEAVE COUNT", key: "paternalLOPLeaveCount", headerWidhth: 60 }
                                );
                                // headerSizes.push(...Array(5).fill(30));
                                break;
                            case "Other Leave":
                                headerExcel.push(
                                    { headerName: "OTHER TOTAL LEAVE COUNT", key: "otherTotalLeaveCount", headerWidhth: 60 },
                                    { headerName: "OTHER TAKEN LEAVE COUNT", key: "otherTakenLeaveCount", headerWidhth: 60 },
                                    { headerName: "OTHER PENDING LEAVE COUNT", key: "otherPendingLeaveCount", headerWidhth: 60 },
                                    { headerName: "OTHER PAID LEAVE COUNT", key: "otherUnPaidLeaveCount", headerWidhth: 60 },
                                    { headerName: "OTHER LOP LEAVE COUNT", key: "otherLOPLeaveCount", headerWidhth: 60 }
                                );
                                // headerSizes.push(...Array(5).fill(30));
                                break;
                            default:
                                break;
                        }
                    });
                    headerExcel.push(
                        { headerName: "OVERALL TOTAL LEAVE COUNT", key: "overallTotalLeaveCount", headerWidhth: 60 },
                        { headerName: "OVERALL TAKEN LEAVE COUNT", key: "overallTakenLeaveCount", headerWidhth: 60 },
                        { headerName: "OVERALL PENDING LEAVE COUNT", key: "overallPendingLeaveCount", headerWidhth: 60 },
                        { headerName: "OVERALL PAID LEAVE COUNT", key: "overallUnPaidLeaveCount", headerWidhth: 60 },
                        { headerName: "OVERALL LOP LEAVE COUNT", key: "overallLOPLeaveCount", headerWidhth: 60 }
                    );
                    // headerSizes.push(...Array(5).fill(30));

                    const userLeaveData = {};

                    response.forEach((element) => {
                        let userData = userLeaveData[element.userName] || { userName: element.userName || "N/A" };

                        let overallTotal = 0;
                        let overallTaken = 0;
                        let overallPending = 0;
                        let overallUnpaid = 0;
                        let overallLOP = 0;

                        Type.forEach((enabledType) => {
                            switch (enabledType.LeavetypeName) {
                                case "Privilege Leave":
                                    userData.privilageTotalLeaveCount = element.privilageTotalLeaveCount || 0;
                                    userData.privilageTakenLeaveCount = element.privilageTakenLeaveCount || 0;
                                    userData.privilagePendingLeaveCount = element.privilagePendingLeaveCount || 0;
                                    userData.privilageUnPaidLeaveCount = element.privilageUnPaidLeaveCount || 0;
                                    userData.privilageLOPLeaveCount = element.privilageLOPLeaveCount || 0;

                                    // Add to overall totals
                                    overallTotal += userData.privilageTotalLeaveCount;
                                    overallTaken += userData.privilageTakenLeaveCount;
                                    overallPending += userData.privilagePendingLeaveCount;
                                    overallUnpaid += userData.privilageUnPaidLeaveCount;
                                    overallLOP += userData.privilageLOPLeaveCount;
                                    break;
                                case "Sick Leave":
                                    userData.sickTotalLeaveCount = element.sickTotalLeaveCount || 0;
                                    userData.sickTakenLeaveCount = element.sickTakenLeaveCount || 0;
                                    userData.sickPendingLeaveCount = element.sickPendingLeaveCount || 0;
                                    userData.sickUnPaidLeaveCount = element.sickUnPaidLeaveCount || 0;
                                    userData.sickLOPLeaveCount = element.sickLOPLeaveCount || 0;

                                    // Add to overall totals
                                    overallTotal += userData.sickTotalLeaveCount;
                                    overallTaken += userData.sickTakenLeaveCount;
                                    overallPending += userData.sickPendingLeaveCount;
                                    overallUnpaid += userData.sickUnPaidLeaveCount;
                                    overallLOP += userData.sickLOPLeaveCount;
                                    break;
                                case "Maternal Leave":
                                    userData.maternalTotalLeaveCount = element.maternalTotalLeaveCount || 0;
                                    userData.maternalTakenLeaveCount = element.maternalTakenLeaveCount || 0;
                                    userData.maternalPendingLeaveCount = element.maternalPendingLeaveCount || 0;
                                    userData.maternalUnPaidLeaveCount = element.maternalUnPaidLeaveCount || 0;
                                    userData.maternalLOPLeaveCount = element.maternalLOPLeaveCount || 0;

                                    // Add to overall totals
                                    overallTotal += userData.maternalTotalLeaveCount;
                                    overallTaken += userData.maternalTakenLeaveCount;
                                    overallPending += userData.maternalPendingLeaveCount;
                                    overallUnpaid += userData.maternalUnPaidLeaveCount;
                                    overallLOP += userData.maternalLOPLeaveCount;
                                    break;
                                case "Paternal Leave":
                                    userData.paternalTotalLeaveCount = element.paternalTotalLeaveCount || 0;
                                    userData.paternalTakenLeaveCount = element.paternalTakenLeaveCount || 0;
                                    userData.paternalPendingLeaveCount = element.paternalPendingLeaveCount || 0;
                                    userData.paternalUnPaidLeaveCount = element.paternalUnPaidLeaveCount || 0;
                                    userData.paternalLOPLeaveCount = element.paternalLOPLeaveCount || 0;

                                    // Add to overall totals
                                    overallTotal += userData.paternalTotalLeaveCount;
                                    overallTaken += userData.paternalTakenLeaveCount;
                                    overallPending += userData.paternalPendingLeaveCount;
                                    overallUnpaid += userData.paternalUnPaidLeaveCount;
                                    overallLOP += userData.paternalLOPLeaveCount;
                                    break;
                                case "Other Leave":
                                    userData.otherTotalLeaveCount = element.otherTotalLeaveCount || 0;
                                    userData.otherTakenLeaveCount = element.otherTakenLeaveCount || 0;
                                    userData.otherPendingLeaveCount = element.otherPendingLeaveCount || 0;
                                    userData.otherUnPaidLeaveCount = element.otherUnPaidLeaveCount || 0;
                                    userData.otherLOPLeaveCount = element.otherLOPLeaveCount || 0;

                                    // Add to overall totals
                                    overallTotal += userData.otherTotalLeaveCount;
                                    overallTaken += userData.otherTakenLeaveCount;
                                    overallPending += userData.otherPendingLeaveCount;
                                    overallUnpaid += userData.otherUnPaidLeaveCount;
                                    overallLOP += userData.otherLOPLeaveCount;
                                    break;
                                default:
                                    break;
                            }
                        });

                        // Add the overall totals to the user's data
                        userData.overallTotalLeaveCount = overallTotal;
                        userData.overallTakenLeaveCount = overallTaken;
                        userData.overallPendingLeaveCount = overallPending;
                        userData.overallUnPaidLeaveCount = overallUnpaid;
                        userData.overallLOPLeaveCount = overallLOP;

                        // Update the userLeaveData with the user data
                        userLeaveData[element.userName] = userData;
                    });

                    // Convert the grouped data into an array for export
                    Object.values(userLeaveData).forEach((userData) => {
                        exportLeavetrackerReportExportList.push(userData);
                    });

                    excelSheetExport(headerExcel, exportLeavetrackerReportExportList, "Leave Tracker Report -List");
                }
            });
    }
    //-----------------------------------------------------------------------------------------------------------------------------------
    console.log("Type:", Type);
    console.log("filter:", filter);

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    return (
        <div className="page-content">
            <Card>
                <CardTitle
                    tag="h4"
                    className="border-bottom px-4 py-1 mb-0 d-flex flex-wrap justify-content-between align-items-center"
                >
                    <div className="d-flex flex-wrap justify-content-center align-items-center gap-2">
                        <strong className="d-flex gap-2 align-items-center">
                            <div>LEAVE TRACKER REPORT</div>
                            <div>-</div>
                            <Badge color="danger">{props && props.totalCount || 0}</Badge>
                        </strong>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center align-items-center gap-2">
                        <FormGroup className="d-flex mx-2 my-3 col-12 col-sm-6 col-md-auto">
                            <Label className="d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                From Date
                                <Input
                                    className="mx-2"
                                    type="date"
                                    name="startDate"
                                    onChange={searchInputValidation}
                                    value={startDate}
                                    max={filter ? filter.endDate : null}
                                />
                            </Label>
                        </FormGroup>
                        <FormGroup className="d-flex mx-2 my-3 col-12 col-sm-6 col-md-auto">
                            <Label className="d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                                To Date
                                <Input
                                    className="mx-2"
                                    type="date"
                                    name="endDate"
                                    onChange={searchInputValidation}
                                    value={endDate}
                                    min={filter ? filter.startDate : null}
                                    max="9999-12-31"
                                />
                            </Label>
                        </FormGroup>
                        <FormGroup className="d-flex mx-2 my-2 col-12 col-sm-6 col-md-auto">
                            <Button
                                className="me-2"
                                color="success"
                                onClick={handleExports}
                                disabled={props.LeaveTrackerReportList && props.LeaveTrackerReportList.length <= 0 || props.isLeaveTrackerRecordExportPending}
                            >
                                {props.isLeaveTrackerRecordExportPending ? 'EXPORTING...' : 'EXPORT'}
                            </Button>
                        </FormGroup>
                    </div>
                </CardTitle>

            </Card>
            <Card>
                {(props.errorMessage || props.leaveTrackerRecordExportListError) ? <div className='w-100'>
                    {props.errorMessage && (
                        <VWMessage type="danger" defaultMessage={props.errorMessage || props.leaveTrackerRecordExportListError} baseClassName="alert" ></VWMessage>
                    )}
                </div> : ""}
                <div style={{ height: "60vh", overflowX: "auto" }}>
                    <Table
                        className="table table-striped table-hover table-nowrap w-100"
                        border="1"
                        style={{
                            ...style.table,
                            // borderCollapse: "collapse",
                            width: "100%",
                            textAlign: "center",

                        }}
                    >
                        <thead
                            className="overflow-hidden sticky-top"
                            style={{
                                backgroundColor: "white",
                                overflow: "hidden",
                                zIndex: "1",
                            }}
                        >
                            <tr>
                                <th
                                    rowSpan="2"
                                    style={{
                                        padding: "10px",
                                        borderBottom: "1px solid silver",
                                        borderRight: "2px solid silver",
                                    }}
                                >
                                    Employee
                                </th>
                                {Type.some(i => i.LeavetypeName === 'Privilege Leave') && (
                                    <th
                                        colSpan="5"
                                        style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver",
                                        }}
                                    >
                                        Privilege Leave
                                    </th>)}
                                {Type.some(i => i.LeavetypeName === 'Sick Leave') && (
                                    <th
                                        colSpan="5"
                                        style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver",
                                        }}
                                    >
                                        Sick Leave
                                    </th>)}
                                {Type.some(i => i.LeavetypeName === 'Maternal Leave') && (
                                    <th
                                        colSpan="5"
                                        style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver",
                                        }}
                                    >
                                        Maternal Leave
                                    </th>)}
                                {Type.some(i => i.LeavetypeName === 'Paternal Leave') && (
                                    <th
                                        colSpan="5"
                                        style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver",
                                        }}
                                    >
                                        Paternal Leave
                                    </th>)}
                                {Type.some(i => i.LeavetypeName === 'Other Leave') && (
                                    <th
                                        colSpan="5"
                                        style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver",
                                        }}
                                    >
                                        Others Leave
                                    </th>)}
                                <th
                                    colSpan="5"
                                    style={{
                                        padding: "10px",
                                        borderBottom: "1px solid silver",
                                        borderRight: "2px solid silver",
                                    }}
                                >
                                    OverAll Leave
                                </th>
                            </tr>
                            <tr>
                                {Type.some(i => i.LeavetypeName === 'Privilege Leave') && (
                                    <>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Total
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Taken
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Pending
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Paid
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "2px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            LOP
                                        </th>
                                    </>)}
                                {Type.some(i => i.LeavetypeName === 'Sick Leave') && (
                                    <>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Total
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Taken
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Pending
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Paid
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "2px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            LOP
                                        </th>
                                    </>)}
                                {Type.some(i => i.LeavetypeName === 'Maternal Leave') && (
                                    <>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Total
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Taken
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Pending
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Paid
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "2px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            LOP
                                        </th>
                                    </>)}
                                {Type.some(i => i.LeavetypeName === 'Paternal Leave') && (
                                    <>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Total
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Taken
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Pending
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Paid
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "2px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            LOP
                                        </th>
                                    </>)}
                                {Type.some(i => i.LeavetypeName === 'Other Leave') && (
                                    <>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Total
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Taken
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Pending
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            Paid
                                        </th>
                                        <th
                                            style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "2px solid silver",
                                                minWidth: '100px'
                                            }}
                                        >
                                            LOP
                                        </th>
                                    </>)}
                                <th
                                    style={{
                                        padding: "10px",
                                        borderBottom: "1px solid silver",
                                        borderRight: "1px solid silver",
                                        minWidth: '100px'
                                    }}
                                >
                                    Total
                                </th>
                                <th
                                    style={{
                                        padding: "10px",
                                        borderBottom: "1px solid silver",
                                        borderRight: "1px solid silver",
                                        minWidth: '100px'
                                    }}
                                >
                                    Taken
                                </th>
                                <th
                                    style={{
                                        padding: "10px",
                                        borderBottom: "1px solid silver",
                                        borderRight: "1px solid silver",
                                        minWidth: '100px'
                                    }}
                                >
                                    Pending
                                </th>
                                <th
                                    style={{
                                        padding: "10px",
                                        borderBottom: "1px solid silver",
                                        borderRight: "1px solid silver",
                                        minWidth: '100px'
                                    }}
                                >
                                    Paid
                                </th>
                                <th
                                    style={{
                                        padding: "10px",
                                        borderBottom: "1px solid silver",
                                        borderRight: "2px solid silver",
                                        minWidth: '100px'
                                    }}
                                >
                                    LOP
                                </th>
                            </tr>
                            <tr>
                                <th style={{
                                    borderBottom: "1px solid silver",
                                    borderRight: "2px solid silver"
                                }}>
                                    <Input
                                        size="sm"
                                        type='text'
                                        placeholder='Search'
                                        name='UserName'
                                        onChange={searchInputValidation}
                                        value={filter && filter.UserName}
                                        onKeyPress={handleKeyPress}
                                    />
                                </th>
                                {Type.some(i => i.LeavetypeName === 'Privilege Leave') && (
                                    <>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PrivilageTotalLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PrivilageTotalLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PrivilageTakenLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PrivilageTakenLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PrivilagePendingLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PrivilagePendingLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PrivilageUnPaidLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PrivilageUnPaidLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PrivilageLOPLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PrivilageLOPLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                    </>
                                )}
                                {Type.some(i => i.LeavetypeName === 'Sick Leave') && (
                                    <>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='SickTotalLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.SickTotalLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='SickTakenLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.SickTakenLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='SickPendingLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.SickPendingLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>

                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='SickUnPaidLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.SickUnPaidLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='SickLOPLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.SickLOPLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                    </>)}
                                {Type.some(i => i.LeavetypeName === 'Maternal Leave') && (
                                    <>

                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='MaternalTotalLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.MaternalTotalLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='MaternalTakenLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.MaternalTakenLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='MaternalPendingLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.MaternalPendingLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>

                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='MaternalUnPaidLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.MaternalUnPaidLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='MaternalLOPLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.MaternalLOPLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                    </>)}
                                {Type.some(i => i.LeavetypeName === 'Paternal Leave') && (
                                    <>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PaternalTotalLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PaternalTotalLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PaternalTakenLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PaternalTakenLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PaternalPendingLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PaternalPendingLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>

                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PaternalUnPaidLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PaternalUnPaidLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='PaternalLOPLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.PaternalLOPLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                    </>
                                )}
                                {Type.some(i => i.LeavetypeName === 'Other Leave') && (
                                    <>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='OtherTotalLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.OtherTotalLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='OtherTakenLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.OtherTakenLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='OtherPendingLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.OtherPendingLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>

                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='OtherUnPaidLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.OtherUnPaidLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th style={{
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver"
                                        }}>
                                            <Input
                                                size="sm"
                                                type='number'
                                                placeholder='Search'
                                                name='OtherLOPLeaveCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.OtherLOPLeaveCount}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                    </>
                                )}
                                <>
                                    <th style={{
                                        borderBottom: "1px solid silver",
                                        borderRight: "1px solid silver"
                                    }}>
                                        <Input
                                            size="sm"
                                            type='number'
                                            placeholder='Search'
                                            name='OverAllTotalLeaveCount'
                                            onChange={searchInputValidation}
                                            value={filter && filter.OverAllTotalLeaveCount}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </th>
                                    <th style={{
                                        borderBottom: "1px solid silver",
                                        borderRight: "1px solid silver"
                                    }}>
                                        <Input
                                            size="sm"
                                            type='number'
                                            placeholder='Search'
                                            name='OverAllTakenLeaveCount'
                                            onChange={searchInputValidation}
                                            value={filter && filter.OverAllTakenLeaveCount}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </th>
                                    <th style={{
                                        borderBottom: "1px solid silver",
                                        borderRight: "1px solid silver"
                                    }}>
                                        <Input
                                            size="sm"
                                            type='number'
                                            placeholder='Search'
                                            name='OverAllPendingLeaveCount'
                                            onChange={searchInputValidation}
                                            value={filter && filter.OverAllPendingLeaveCount}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </th>

                                    <th style={{
                                        borderBottom: "1px solid silver",
                                        borderRight: "1px solid silver"
                                    }}>
                                        <Input
                                            size="sm"
                                            type='number'
                                            placeholder='Search'
                                            name='OverAllUnPaidLeaveCount'
                                            onChange={searchInputValidation}
                                            value={filter && filter.OverAllUnPaidLeaveCount}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </th>
                                    <th style={{
                                        borderBottom: "1px solid silver",
                                        borderRight: "2px solid silver"
                                    }}>
                                        <Input
                                            size="sm"
                                            type='number'
                                            placeholder='Search'
                                            name='OverAllLOPLeaveCount'
                                            onChange={searchInputValidation}
                                            value={filter && filter.OverAllLOPLeaveCount}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </th>
                                </>
                            </tr>
                        </thead>
                        <tbody>
                            {!props.isPending && (props.LeaveTrackerReportList && props.LeaveTrackerReportList.length > 0) ?
                                props.LeaveTrackerReportList && props.LeaveTrackerReportList.map((data) => (
                                    <tr>
                                        <td
                                            style={{
                                                // padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "2px solid silver",
                                            }}
                                        >
                                            {data.UserName}<br />
                                            <small>{data.UserEmail}</small>
                                        </td>

                                        {Type.some(i => i.LeavetypeName === 'Privilege Leave') && (
                                            <>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.PrivilageTotalLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.PrivilageTakenLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.PrivilagePendingLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.PrivilageUnPaidLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "2px solid silver",
                                                }}>{data.PrivilageLOPLeaveCount || 0}</td>
                                            </>)}
                                        {Type.some(i => i.LeavetypeName === 'Sick Leave') && (<>
                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                            }}>{data.SickTotalLeaveCount || 0}</td>
                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                            }}>{data.SickTakenLeaveCount || 0}</td>
                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                            }}>{data.SickPendingLeaveCount || 0}</td>

                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                            }}>{data.SickUnPaidLeaveCount || 0}</td>
                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "2px solid silver",
                                            }}>{data.SickLOPLeaveCount || 0}</td>
                                        </>)}
                                        {Type.some(i => i.LeavetypeName === 'Maternal Leave') && (<>
                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                            }}>{data.MaternalTotalLeaveCount || 0}</td>
                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                            }}>{data.MaternalTakenLeaveCount || 0}</td>
                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                            }}>{data.MaternalPendingLeaveCount || 0}</td>

                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "1px solid silver",
                                            }}>{data.MaternalUnPaidLeaveCount || 0}</td>
                                            <td style={{
                                                padding: "10px",
                                                borderBottom: "1px solid silver",
                                                borderRight: "2px solid silver",
                                            }}>{data.MaternalLOPLeaveCount || 0}</td>
                                        </>)}
                                        {Type.some(i => i.LeavetypeName === 'Paternal Leave') && (
                                            <>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.PaternalTotalLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.PaternalTakenLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.PaternalPendingLeaveCount || 0}</td>

                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.PaternalUnPaidLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "2px solid silver",
                                                }}>{data.PaternalLOPLeaveCount || 0}</td>
                                            </>)}
                                        {Type.some(i => i.LeavetypeName === 'Other Leave') && (
                                            <>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.OtherTotalLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.OtherTakenLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.OtherPendingLeaveCount || 0}</td>

                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "1px solid silver",
                                                }}>{data.OtherUnPaidLeaveCount || 0}</td>
                                                <td style={{
                                                    padding: "10px",
                                                    borderBottom: "1px solid silver",
                                                    borderRight: "2px solid silver",
                                                }}>{data.OtherLOPLeaveCount || 0}</td>
                                            </>)}
                                        <td style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver",
                                        }}>{data.OverAllTotalLeaveCount || 0}</td>
                                        <td style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver",
                                        }}>{data.OverAllTakenLeaveCount || 0}</td>
                                        <td style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver",
                                        }}>{data.OverAllPendingLeaveCount || 0}</td>
                                        <td style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "1px solid silver",
                                        }}>{data.OverAllUnPaidLeaveCount || 0}</td>
                                        <td style={{
                                            padding: "10px",
                                            borderBottom: "1px solid silver",
                                            borderRight: "2px solid silver",
                                        }}>{data.OverAllLOPLeaveCount || 0}</td>

                                    </tr>
                                ))
                                :
                                props.isPending ?
                                    <div className="sticky-spinner-row">
                                        <div className="position-relative">
                                            <div className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    position: 'fixed',
                                                    top: '50%',
                                                    left: '60%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 1,
                                                    width: '100vw',
                                                    height: '100vh',
                                                    pointerEvents: 'none',
                                                }}>
                                                <VWSpinner />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="sticky-spinner-row">
                                        <div className="position-relative">
                                            <div className="d-flex justify-content-center align-items-center"
                                                style={{
                                                    position: 'fixed',
                                                    top: '50%',
                                                    left: '60%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 1,
                                                    width: '100vw',
                                                    height: '100vh',
                                                    pointerEvents: 'none',
                                                }}>
                                                <NoRecord />
                                            </div>
                                        </div>
                                    </div>
                            }
                        </tbody>
                    </Table>
                </div>
                <CardFooter>
                    {props.LeaveTrackerReportList && props.LeaveTrackerReportList.length > 0 ?
                        <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                    }
                </CardFooter>
            </Card>
        </div>
    )
}

const mapStateToProps = (state) => ({
    LeaveTrackerReportList: state.LeaveTrackerRecord.LeaveTrackerReportList,
    errorMessage: state.LeaveTrackerRecord.leaveTrackerRecordListError,
    isPending: state.LeaveTrackerRecord.isLeaveTrackerRecordPending,
    totalCount: state.LeaveTrackerRecord.totalRecords,
    leaveTrackerRecordExportListError: state.LeaveTrackerRecord.leaveTrackerRecordExportListError,
    isLeaveTrackerRecordExportPending: state.LeaveTrackerRecord.isLeaveTrackerRecordExportPending,
    currentProfile: state.login.currentProfile,
    OrgLeaveTypeList: state.leaveType.OrgLeaveTypeList,

});
const mapDispatchToProps = (dispatch) => ({
    getLeaveReportList: (page, PageSize, filter) => dispatch(getLeaveReportListAction(page, PageSize, filter)),
    getLeaveReportExport: (filter) => dispatch(getLeaveTrackerReportExport(filter)),
    clearFormAction: () => dispatch(clearForm()),
    ClearPath: () => dispatch(clearSetPath()),
    getOrgLeaveTypeList: () => dispatch(getOrgLeaveTypeListAction()),
    clearLeaveTrackerReportExportListError: () => dispatch(clearLeaveTrackerReportExportListError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeaveReports)