import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Input,
    InputGroup,
    Table
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendarAlt, faFont } from "@fortawesome/free-solid-svg-icons";
import {
    exportContactList,
    getContactList
} from "../../../../store/Modules/MassMailer/ContactList/actions";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import { getPager } from "../../../../helper/common";
import {
    CONTACT_EXPORT_HEADERS,
    CONTACT_STATUS_FILTER_OPTIONS,
    MAIL_CATEGORY_FILTER_OPTIONS,
    SUBSCRIPTION_STATUS_FILTER_OPTIONS
} from "../../../../store/Modules/MassMailer/ContactList/constants";
import { Link, useParams } from "react-router-dom";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import { excelPrint } from "../../../../helper/export";
import moment from "moment-timezone";
import Select from 'react-select';
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import UITooltip from "../../../../CommonData/Data/UITooltip";
import '../../viewList.css';

const style = {
    table: {
        width: '100%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'separate',
    },
    th: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#fff',
    },
};

const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const ContactList = (props) => {
    const [pageSize] = useState(10);
    const [pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState({});
    const [filterSelectionOption, setFilterSelectionOption] = useState('name');

    //--------------------------------------------------PARAM-FILTER-----------------------------------------------------------------------------------
    const path = window.location.search;
    const searchParams = new URLSearchParams(path);
    const statusParams = searchParams.get('filter');

    const params = useParams();
    const { id } = params || {};

    useEffect(() => {
        if (statusParams) {
            setFilter({
                ...filter,
                SubscriptionStatus: statusParams.toUpperCase(),
            })
        }
        if (id) {
            setFilter({
                ...filter,
                Id: id,
            })
        }
    }, [])

    //----------------------------------------------------------------------------FILTER-----------------------------------------------------------------
    const buildQueryId = () => {
        const { Id } = filter;
        let searchId = '';
        if (Id) {
            searchId += `${Id}`;
        }
        return searchId
    }

    const buildQueryEmail = () => {
        const { Email } = filter;
        let searchEmail = '';
        if (Email) {
            searchEmail += `${Email}`;
        }
        return searchEmail
    }

    const buildQueryCampaignName = () => {
        const { CampaignName } = filter;
        let searchCampaignName = '';
        if (CampaignName) {
            searchCampaignName += `${CampaignName}`;
        }
        return searchCampaignName
    }

    const buildQueryType = () => {
        const { Type } = filter;
        let searchType = '';
        if (Type) {
            searchType += `${Type}`;
        }
        return searchType
    }
    const buildQueryIsActive = () => {
        const { IsActive } = filter;
        let searchIsActive = '';
        if (IsActive) {
            searchIsActive += `${IsActive}`;
        }
        return searchIsActive
    }
    const buildQuerySubscriptionStatus = () => {
        const { SubscriptionStatus } = filter;
        let searchSubscriptionStatus = '';
        if (SubscriptionStatus) {
            searchSubscriptionStatus += `${SubscriptionStatus}`;
        }
        return searchSubscriptionStatus
    }
    const buildQueryCreatedBy = () => {
        const { CreatedBy } = filter;
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }
    const buildQueryCreatedTimeStamp = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedTimeStamp = '';
        if (CreatedTimeStamp) {
            searchCreatedTimeStamp += `${CreatedTimeStamp}`;
        }
        return searchCreatedTimeStamp
    }

    useEffect(() => {
        const currentPager = getPager(props.contactListCount, 1, pageSize);
        setPager(currentPager)
    }, [props.contactListCount])

    const setPage = (page = 1) => {
        props.getContactList(page, pageSize, buildQueryId(), buildQueryEmail(), buildQueryCampaignName(), buildQueryType(),
            buildQueryIsActive(), buildQuerySubscriptionStatus(), buildQueryCreatedBy(), buildQueryCreatedTimeStamp(), sortDirection, sortOrder);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.contactListCount / pageSize);
            const visiblePages = 10;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
                pageSize
            };
        });
    }

    useEffect(() => {
        const currentPageNumber = pager && pager.currentPage
        if ((!id) && (Object.keys(filter).length === 0)) {
            setPage(currentPageNumber)
        }
    }, [])

    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (e) => {
        const input = e.target;
        const value = input.value;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [input.name]: value
        })
    }

    const searchMailCategoryDD = (Value) => {
        const { value, label } = Value;
        setFilter({
            ...filter,
            Type: value
        })
    }
    const searchContactStatusDD = (Value) => {
        const { value, label } = Value;
        setFilter({
            ...filter,
            IsActive: value
        })
    }

    const searchSubscriptionStatusDD = (Value) => {
        const { value, label } = Value;
        setFilter({
            ...filter,
            SubscriptionStatus: value
        })
    }

    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }

    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }

    // -----------------------------------------------------------SORTING--------------------------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedContactList = [...props.contactList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //--------------------------------------------------------------EXPORTS---------------------------------------------------------------------------
    useEffect(() => {
        exportContactDetails();
    }, [props.contactListCount])

    const exportContactDetails = () => {
        props.exportContactList(buildQueryId(), buildQueryEmail(), buildQueryCampaignName(), buildQueryType(), buildQueryIsActive(),
            buildQuerySubscriptionStatus(), buildQueryCreatedBy(), buildQueryCreatedTimeStamp(), sortDirection, sortOrder);
    }
    const handleExports = () => {
        const contactListsExported = [];
        const headers = CONTACT_EXPORT_HEADERS;
        const headerSizes = [40, 30, 10, 30, 30, 30];
        props.contactExportList.forEach((element) => {
            contactListsExported.push({
                Email: element.email || "NA",
                // CampaignName: element.campaignName || "NA",
                MailCategory: element.type || "NA",
                Status: (element.isActive ? "Active" : "Inactive") || "NA",
                SubscriptionStatus: element.subscriptionStatus || "NA",
                CreatedBy: element.createdBy || "NA",
                CreatedTimeStamp: element.createdTimeStamp ? moment(element.createdTimeStamp).format('DD/MM/YYYY') : "NA",
            });
        });
        excelPrint(contactListsExported, "Contact-List", headers, headerSizes);
    }
    return (
        <div className="page-content">
            <Card >
                <CardTitle className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center">
                    <strong className='d-flex align-items-center'>
                        CONTACTS -&nbsp;<Badge color="danger">{props.contactListCount}</Badge>
                    </strong>
                    {(props.contactListCount > 0) &&
                        <div className="content-heading">
                            <div className="ml-auto">
                                <Button
                                    className="me-4"
                                    color="success"
                                    onClick={handleExports}>
                                    Export
                                </Button>
                            </div>
                        </div>}
                </CardTitle>
            </Card>
            <Card>
                <CardBody>
                    <div style={{ height: "60vh", overflowX: "auto" }}>
                        {props.errorMessage && (
                            <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />
                        )}
                        <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                            <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: '10' }}>
                                <tr className="sticky-top">
                                    <th onClick={() => handleSort('Email')}>
                                        Email Address &nbsp;
                                        {sortOrder === 'Email' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th onClick={() => handleSort('Type')}>
                                        Mail Category &nbsp;
                                        {sortOrder === 'Type' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th onClick={() => handleSort('IsActive')}>
                                        Status &nbsp;
                                        {sortOrder === 'IsActive' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th onClick={() => handleSort('SubscriptionStatus')}>
                                        Subscription Status &nbsp;
                                        {sortOrder === 'SubscriptionStatus' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th onClick={() => handleSort('CreatedBy')}>
                                        Created By&nbsp;
                                        {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                        View
                                    </th>
                                </tr>
                                <tr style={{ backgroundColor: "white" }}>
                                    <th>
                                        <Input
                                            type='text'
                                            placeholder='Search'
                                            name='Email'
                                            onChange={searchInputValidation}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            placeholder='Search'
                                            name="Type"
                                            onChange={searchMailCategoryDD}
                                            options={MAIL_CATEGORY_FILTER_OPTIONS}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            placeholder='Search'
                                            name="IsActive"
                                            onChange={searchContactStatusDD}
                                            options={CONTACT_STATUS_FILTER_OPTIONS}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            placeholder='Search'
                                            name="SubscriptionStatus"
                                            onChange={searchSubscriptionStatusDD}
                                            options={SUBSCRIPTION_STATUS_FILTER_OPTIONS}
                                            value={filter && filter.SubscriptionStatus ?
                                                { label: filter.SubscriptionStatus, value: filter.SubscriptionStatus }
                                                : null}
                                        />
                                    </th>
                                    <th>
                                        <InputGroup style={{ width: "200px" }}>
                                            {filterSelectionOption === 'name' ?
                                                <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                    <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                </UITooltip>
                                                : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                    <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                </UITooltip>
                                            }
                                            <Input
                                                style={{ width: "120px" }}
                                                type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                placeholder='Search'
                                                name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                onChange={searchInputValidation}
                                                onKeyPress={handleKeyPress}
                                                value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                            />
                                        </InputGroup>
                                    </th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!props.isContactListPending && (props.contactList && props.contactList.length > 0) ?
                                    sortedContactList && sortedContactList.map((i, index) =>
                                        <tr key={index}>
                                            <td>{i.Email || "N/A"}
                                                <UITooltip id={`Campaignlst-${index}`} key={`Campaignlst-${index}`} content={`No. of Campaign Assigned`}>
                                                    <Badge color='primary' className='ms-1'>
                                                        {i.Campaignlst && i.Campaignlst.length}
                                                    </Badge>
                                                </UITooltip>
                                            </td>
                                            <td>{i.Type || "N/A"}</td>
                                            <td>
                                                {i.IsActive ? (
                                                    <Badge color="success">Active</Badge>
                                                ) : (
                                                    <Badge color="danger">Inactive</Badge>
                                                )}
                                            </td>
                                            <td>{i.SubscriptionStatus || "N/A"}</td>
                                            <td>
                                                {i.CreatedBy || "N/A"}<br />
                                                <small>{i.CreatedTimeStamp ? moment(i.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                            </td>
                                            <td className="sticky-column" style={{ backgroundColor: "white" }}>
                                                <Link
                                                    type="button"
                                                    className="btn btn-sm  btn-light"
                                                    to={`/massmailer/contacts/details/${i.Id}`}
                                                >
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                    : props.isContactListPending ?
                                        <tr>
                                            <td colSpan={7}>
                                                <div className="d-flex justify-content-center mt-2">
                                                    <VWSpinner />
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        <tr>
                                            <td colSpan={50}>
                                                <NoRecord />
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <CardFooter>
                {props.contactListCount > 0 ?
                    <VWPagination
                        pager={pager}
                        setPage={setPage}
                        totalRecords={props.contactListCount}
                        pageSize={pageSize}
                    />
                    : ""}
                    </CardFooter>
            </Card>
        </div >
    )
}
const mapStateToProps = (state) => ({
    contactListCount: state.massmailerContactList.contactListCount,
    contactList: state.massmailerContactList.contactList,
    contactExportList: state.massmailerContactList.contactExportList,
    isContactListPending: state.massmailerContactList.isContactListPending,
    isContactCreateOpen: state.massmailerContactList.isContactCreateOpen,
    errorMessage: state.massmailerContactList.contactListError,
})

const mapDispatchToProps = (dispatch) => ({
    getContactList: (page, pageSize, searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) =>
        dispatch(getContactList(page, pageSize, searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder)),
    exportContactList: (searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) =>
        dispatch(exportContactList(searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ContactList);