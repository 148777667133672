export const SET_LEAVE_TRACKER_LIST = "SET_LEAVE_TRACKER_LIST";
export const SET_LEAVE_TRACKER_LIST_ERROR = "SET_LEAVE_TRACKER_LIST_ERROR";
export const CLEAR_LEAVE_TRACKER_LIST_ERROR = "CLEAR_LEAVE_TRACKER_LIST_ERROR";
export const OPEN_LEAVE_TRACKER_MODAL = "OPEN_LEAVE_TRACKER_MODAL";
export const CLOSE_LEAVE_TRACKER_MODAL = "CLOSE_LEAVE_TRACKER_MODAL";
export const CLEAR_LEAVE_TRACKER_ACTION_ERROR = "CLEAR_LEAVE_TRACKER_ACTION_ERROR";
export const CREATE_LEAVE_TRACKER_STATUS_PENDING = "CREATE_LEAVE_TRACKER_STATUS_PENDING";
export const CLEAR_LEAVE_TRACKER_ERROR = "CLEAR_LEAVE_TRACKER_ERROR";
export const CREATE_LEAVE_TRACKER_ACTION_SUCCESS = "CREATE_LEAVE_TRACKER_ACTION_SUCCESS";
export const CREATE_LEAVE_TRACKER_ACTION_ERROR = "CREATE_LEAVE_TRACKER_ACTION_ERROR";
export const SET_LEAVE_DETAILS = "SET_LEAVE_DETAILS";
export const SET_LEAVE_TRACKER_DETAILS_ERROR = "SET_LEAVE_TRACKER_DETAILS_ERROR";
export const CLEAR_LEAVE_TRACKER_DETAILS_ERROR = "CLEAR_LEAVE_TRACKER_DETAILS_ERROR";
export const OPEN_CREATE_APPLY_LEAVE_MODAL = "OPEN_CREATE_APPLY_LEAVE_MODAL";
export const CLOSE_CREATE_APPLY_LEAVE_MODAL = "CLOSE_CREATE_APPLY_LEAVE_MODAL";
export const CREATE_LEAVE_ERROR = "CREATE_LEAVE_ERROR";
export const CREATE_LEAVE_PENDING = "CREATE_LEAVE_PENDING";
export const CLEAR_LEAVE_ERROR = "CLEAR_LEAVE_ERROR";
export const CREATE_LEAVE_SUCCESS = "CREATE_LEAVE_SUCCESS";
export const OPEN_UPDATE_APPLY_LEAVE_MODAL = "OPEN_UPDATE_APPLY_LEAVE_MODAL";
export const CLOSE_UPDATE_APPLY_LEAVE_MODAL = "CLOSE_UPDATE_APPLY_LEAVE_MODAL";
export const CLEAR_UPDATE_LEAVE_ERROR = "CLEAR_UPDATE_LEAVE_ERROR";
export const UPDATE_LEAVE_PENDING = "UPDATE_LEAVE_PENDING";
export const UPDATE_LEAVE_SUCCESS = "UPDATE_LEAVE_SUCCESS";
export const UPDATE_LEAVE_ERROR = "UPDATE_LEAVE_ERROR";
export const SET_LEAVE_TRACKER_PENDING = "SET_LEAVE_TRACKER_PENDING";
export const SET_LEAVE_TRACKER_DETAILS_PENDING = "SET_LEAVE_TRACKER_DETAILS_PENDING";
export const SET_CALENDER_DATES = "SET_CALENDER_DATES"
export const SET_LEAVE_COUNT_PENDING = "SET_LEAVE_COUNT_PENDING"
export const SET_LEAVE_COUNT = "SET_LEAVE_COUNT"
export const CLEAR_LEAVE_COUNT_ERROR = "CLEAR_LEAVE_COUNT_ERROR"
export const CREATE_LEAVE_COUNT_ERROR = "CREATE_LEAVE_COUNT_ERROR"
export const CLEAR_LEAVE_COUNT = "CLEAR_LEAVE_COUNT"
export const SET_AVAILABLE_LEAVE_COUNT_PENDING = "SET_AVAILABLE_LEAVE_COUNT_PENDING"
export const CLEAR_AVAILABLE_LEAVE_COUNT = "CLEAR_AVAILABLE_LEAVE_COUNT"
export const SET_AVAILABLE_LEAVE_COUNT = "SET_AVAILABLE_LEAVE_COUNT"
export const CLEAR_AVAILABLE_LEAVE_COUNT_ERROR = "CLEAR_AVAILABLE_LEAVE_COUNT_ERROR"
export const CREATE_AVAILABLE_LEAVE_COUNT_ERROR = "CREATE_AVAILABLE_LEAVE_COUNT_ERROR"
export const SET_LEAVE_TRACKER_COUNT_LIST = "SET_LEAVE_TRACKER_COUNT_LIST"
export const SET_LEAVE_TRACKER_EXPORT_PENDING = "SET_LEAVE_TRACKER_EXPORT_PENDING"
export const SET_LEAVE_TRACKER_EXPORT_LIST = "SET_LEAVE_TRACKER_EXPORT_LIST"
export const CLEAR_LEAVE_TRACKER_EXPORT_LIST_ERROR = "CLEAR_LEAVE_TRACKER_EXPORT_LIST_ERROR"
export const SET_LEAVE_TRACKER_EXPORT_LIST_ERROR = "SET_LEAVE_TRACKER_EXPORT_LIST_ERROR"
export const LEAVE_TRACKER_EXPORT_SUCCESS = "LEAVE_TRACKER_EXPORT_SUCCESS"
export const SET_ENABLE_ORG_LEAVE_COUNT = "SET_ENABLE_ORG_LEAVE_COUNT"

export const MYLEAVE_TRACKER_EXPORT_HEADERS = [
    "EMPLOYEE",
    "EMPLOYEE EMAIL",
    "FROM DATE",
    "TO DATE",
    "LEAVE DAYS",
    "REASON",
    "TYPE",
    "STATUS",
];