export const SET_LEAVE_MASTER_LIST = "SET_LEAVE_MASTER_LIST";
export const SET_LEAVE_MASTER_LIST_ERROR = "SET_LEAVE_MASTER_LIST_ERROR";
export const CLEAR_LEAVE_MASTER_LIST_ERROR = "CLEAR_LEAVE_MASTER_LIST_ERROR";
export const SET_LEAVE_MASTER_DETAILS = "SET_LEAVE_MASTER_DETAILS";
export const SET_LEAVE_MASTER_DETAILS_ERROR = "SET_LEAVE_MASTER_DETAILS_ERROR";
export const CLEAR_LEAVE_MASTER_DETAILS_ERROR = "CLEAR_LEAVE_MASTER_DETAILS_ERROR";
export const OPEN_CREATE_LEAVE_MASTER_MODAL = "OPEN_CREATE_LEAVE_MASTER_MODAL";
export const CLOSE_CREATE_LEAVE_MASTER_MODAL = "CLOSE_CREATE_LEAVE_MASTER_MODAL";
export const CREATE_LEAVE_MASTER_ERROR = "CREATE_LEAVE_MASTER_ERROR";
export const CREATE_LEAVE_MASTER_PENDING = "CREATE_LEAVE_MASTER_PENDING";
export const CLEAR_LEAVE_MASTER_ERROR = "CLEAR_LEAVE_MASTER_ERROR";
export const CREATE_LEAVE_MASTER_ACTION_SUCCESS = "CREATE_LEAVE_MASTER_ACTION_SUCCESS";
export const OPEN_EDIT_LEAVE_MASTER_MODAL = "OPEN_EDIT_LEAVE_MASTER_MODAL";
export const CLOSE_EDIT_LEAVE_MASTER_MODAL = "CLOSE_EDIT_LEAVE_MASTER_MODAL";
export const CLEAR_UPDATE_LEAVE_MASTER_ERROR = "CLEAR_UPDATE_LEAVE_MASTER_ERROR";
export const UPDATE_LEAVE_MASTER_PENDING = "UPDATE_LEAVE_MASTER_PENDING";
export const UPDATE_LEAVE_MASTER_SUCCESS = "UPDATE_LEAVE_MASTER_SUCCESS";
export const UPDATE_LEAVE_MASTER_ERROR = "UPDATE_LEAVE_MASTER_ERROR";
export const SET_LEAVE_MASTER_PENDING = "SET_LEAVE_MASTER_PENDING";
export const SET_LEAVE_MASTER_DETAILS_PENDING = "SET_LEAVE_MASTER_DETAILS_PENDING";
export const SET_LEAVE_MASTER_EXPORT_PENDING = "SET_LEAVE_MASTER_EXPORT_PENDING";
export const SET_LEAVE_MASTER_EXPORT_LIST = "SET_LEAVE_MASTER_EXPORT_LIST";
export const CLEAR_LEAVE_MASTER_EXPORT_LIST_ERROR = "CLEAR_LEAVE_MASTER_EXPORT_LIST_ERROR";
export const SET_LEAVE_MASTER_EXPORT_LIST_ERROR = "SET_LEAVE_MASTER_EXPORT_LIST_ERROR";

export const LEAVE_MASTER_EXPORT_HEADERS = [
    "EMPLOYEE",
    "EMPLOYEE EMAIL",
    "LEAVE TYPE NAME",
    "FREQUENCY",
    "QTY",
    "CONSECUTIVE DAYS DAYS",
    "CREATED BY",
    "CREATED DATE",
];