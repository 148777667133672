//--------------------MAIL-SETTING-CREATE-----------------------------------
export const MAIL_SETTINGS_CREATE_OPEN = "MAIL_SETTINGS_CREATE_OPEN";
export const MAIL_SETTINGS_CREATE_CLOSE = "MAIL_SETTINGS_CREATE_CLOSE";
export const MAIL_SETTINGS_CREATE_PENDING = "MAIL_SETTINGS_CREATE_PENDING";
export const MAIL_SETTINGS_CREATE_ERROR = "MAIL_SETTINGS_CREATE_ERROR";
export const MAIL_SETTINGS_CREATE_ERROR_CLEAR = "MAIL_SETTINGS_CREATE_ERROR_CLEAR";
export const MAIL_SETTINGS_CREATE_SUCCESS = "MAIL_SETTINGS_CREATE_SUCCESS";

//--------------------MAIL-SETTING-UPDATE-----------------------------------
export const MAIL_SETTINGS_UPDATE_OPEN = "MAIL_SETTINGS_UPDATE_OPEN";
export const MAIL_SETTINGS_UPDATE_CLOSE = "MAIL_SETTINGS_UPDATE_CLOSE";
export const MAIL_SETTINGS_UPDATE_PENDING = "MAIL_SETTINGS_UPDATE_PENDING";
export const MAIL_SETTINGS_UPDATE_ERROR = "MAIL_SETTINGS_UPDATE_ERROR";
export const MAIL_SETTINGS_UPDATE_SUCCESS = "MAIL_SETTINGS_UPDATE_SUCCESS";
export const MAIL_SETTINGS_UPDATE_ERROR_CLEAR = "MAIL_SETTINGS_UPDATE_ERROR_CLEAR";
export const SET_MAIL_SETTINGS_DETAILS = "SET_MAIL_SETTINGS_DETAILS";

//--------------------MAIL-SETTING-LIST-----------------------------------
export const SET_MAIL_SETTINGS_LIST = "SET_MAIL_SETTINGS_LIST";
export const MAIL_SETTINGS_LIST_PENDING = "ACCOUNTS_CATEGORY_LIST_PENDING";
export const MAIL_SETTINGS_LIST_ERROR = "ACCOUNTS_CATEGORY_LIST_ERROR";
export const MAIL_SETTINGS_LIST_ERROR_CLEAR = "ACCOUNTS_CATEGORY_LIST_ERROR_CLEAR";


export const MAIL_SETTINGS_STATUS_OPTIONS = [
    { label: "Select...", value: null },
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
]