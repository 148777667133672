import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, FormFeedback, FormGroup, Input, Table } from 'reactstrap'
import VWMessage from '../../../../../../components/vwMessage/vwMessage'
import VWSpinner from '../../../../../../components/vwSpinner/vwSpinner'
import NoRecord from '../../../../../../components/NoRecords/noRecords'
import VWPagination from '../../../../../../components/vwPagination/vwPagination'
import VWModel from '../../../../../../components/Modal/VWModal'
import { connect } from 'react-redux'
import { clearUpdateVisitTypeError, closeAddVisitTypeModal, closeEditVisitTypeModal, getVisitTypeList, openAddVisitTypeModal, openEditVisitTypeModal, setVisitTypeDetails, updateVisitType, updateVisitTypeSuccess } from '../../../../../../store/Modules/crm/Settings/VisitType/actions'
import AddVisit from './addVisit'
import { getPager } from '../../../../../../helper/common'
import moment from 'moment-timezone'
import EditVisit from './editVisit'
import { updateOpportunityCurrentPhase } from '../../../../../../store/Modules/crm/Settings/OpportunityCurrentPhase/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faXmark } from '@fortawesome/free-solid-svg-icons'
import { setIsInlineUpdate } from '../../../../../../store/Modules/crm/Settings/SettingsInlineUpdate/actions'

const Visit = (props) => {

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });

    const setPage = (page = 1) => {
        props.getVisitTypeList(page, PageSize);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.setIsInlineUpdate();
        props.clearUpdateVisitTypeError();
        props.updateVisitTypeSuccess();
    }, [])
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    }; // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedVisitTypeList = [...props.visitTypeList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleAddOpenVisitType = () => {
        props.openAddVisitTypeModal();
    }
    const handleClose = () => {
        props.closeAddVisitTypeModal();
    };

    const handleVisitTypeEdit = (id) => {
        const { setVisitTypeDetails, openEditVisitTypeModal } = props;
        const selected_Visit_Type = sortedVisitTypeList.find((visitType) => {
            return visitType.Id === id;
        });
        setVisitTypeDetails(selected_Visit_Type);
        openEditVisitTypeModal();
    }
    const handleEditClose = () => {
        props.closeEditVisitTypeModal();
    };

    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();
    const [editInlineList, setEditInlineList] = useState({ VisitTypeName: '' });

    const handleEditClick = (itemId, IsActive, VisitTypeName) => {
        props.setIsInlineUpdate(itemId);
        setActive(IsActive);
        setEditInlineList({ ...editInlineList, VisitTypeName: VisitTypeName, isActive: IsActive, Id: itemId });
        setErrors({});
    };
    const handleClearClick = () => {
        props.setIsInlineUpdate(null);
    };

    const handleSave = (e) => {
        let currentErrors = {};
        const { VisitTypeName } = editInlineList || {};

        if (!VisitTypeName) {
            currentErrors.VisitTypeName = 'Visit type is required';
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [editInlineList.Id]: currentErrors
        }));

        if (Object.keys(currentErrors).length === 0) {
            props.updateVisitType(editInlineList);
        }
    }


    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setEditInlineList({
            ...editInlineList,
            [name]: value
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            [editInlineList.Id]: {
                ...prevErrors[editInlineList.Id],
                [name]: value ? null : `Visit type is required`
            }
        }));
    }
    const handleActiveStatusChange = () => {
        setEditInlineList({
            ...editInlineList,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }

    console.log("error:", errors);

    return (
        <React.Fragment>
            <div>
                <Card >
                    <CardTitle className="fs-5 fw-bold border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center">
                        VISIT TYPE
                        <Button
                            className='fw-bold'
                            size='sm'
                            color='info'
                            onClick={handleAddOpenVisitType}
                        >
                            ADD
                        </Button>
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody>
                        <div style={{ height: "380px", overflowX: "auto" }}>
                            {props.errorMessage ? <div className='w-100'>
                                {props.errorMessage && (
                                    <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <tr>
                                        <th onClick={() => handleSort('VisitTypeName')} className='hand-cursor'>
                                            Visit Type &nbsp;
                                            {sortOrder === 'VisitTypeName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('IsActive')} className='hand-cursor'>
                                            Status &nbsp;
                                            {sortOrder === 'IsActive' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('CreatedBy')} className='hand-cursor'>
                                            Created By &nbsp;
                                            {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.visitTypeList && props.visitTypeList.length > 0 ?
                                        sortedVisitTypeList && sortedVisitTypeList.map((data) => (
                                            <tr class="middle-align">
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <FormGroup>
                                                            <Input
                                                                size='md'
                                                                type="text"
                                                                name="VisitTypeName"
                                                                placeHolder="Enter visit type"
                                                                onChange={validateOnchange}
                                                                value={editInlineList.VisitTypeName}
                                                                invalid={errors && errors[editInlineList.Id]?.VisitTypeName}
                                                            />
                                                            <FormFeedback>{errors && errors[editInlineList.Id]?.VisitTypeName}</FormFeedback>
                                                        </FormGroup>
                                                    </td>
                                                    :
                                                    <td>{data.VisitTypeName || "N/A"}</td>
                                                }
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <FormGroup>
                                                            <ul className="form-settings-unstyled-list form-settings-status-list">
                                                                <li
                                                                    onClick={handleActiveStatusChange}
                                                                    className={`form-settings-status-item ${!Active ? 'active' : ''}`}
                                                                >
                                                                    Inactive
                                                                </li>
                                                                <li
                                                                    onClick={handleActiveStatusChange}
                                                                    className={`form-settings-status-item ${Active ? 'active' : ''}`}
                                                                >
                                                                    Active
                                                                </li>
                                                            </ul>
                                                        </FormGroup>
                                                    </td>
                                                    :
                                                    <td>
                                                        {data.IsActive ? (
                                                            <Badge color="success">Active</Badge>
                                                        ) : (
                                                            <Badge color="danger">Inactive</Badge>
                                                        )}
                                                    </td>
                                                }
                                                <td>
                                                    {data.CreatedBy || "N/A"}<br />
                                                    <small>{data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                                </td>
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <>
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                style={{ color: "green", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleSave()}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faXmark}
                                                                style={{ color: "red", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleClearClick(data.Id)}
                                                            />
                                                        </>
                                                    </td>
                                                    :
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                            onClick={() => handleEditClick(data.Id, data.IsActive, data.VisitTypeName)}
                                                        />
                                                    </td>
                                                }
                                            </tr>
                                        ))
                                        : props.isVisitTypeListPending ?
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <VWSpinner />
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {props.visitTypeList && props.visitTypeList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="ADD VISIT TYPE"
                    modalSize="medium"
                    isOpen={props.isCreateVisitTypeOpen}
                    handleClose={handleClose}
                >
                    <AddVisit
                        handleClose={handleClose}
                    />
                </VWModel>
                <VWModel
                    header="EDIT VISIT TYPE"
                    modalSize="medium"
                    isOpen={props.isUpdateVisitTypeOpen}
                    handleClose={handleEditClose}
                >
                    <EditVisit
                        handleClose={handleEditClose}
                    />
                </VWModel>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    visitTypeList: state.VisitType.visitTypeList,
    totalCount: state.VisitType.visitTypeListCount,
    errorMessage: state.VisitType.visitTypeListErrorMessage,
    isVisitTypeListPending: state.VisitType.isVisitTypeListPending,
    isCreateVisitTypeOpen: state.VisitType.isCreateVisitTypeOpen,
    isUpdateVisitTypeOpen: state.VisitType.isUpdateVisitTypeOpen,
    isInlineEditID: state.SettingsInlineEdit.isInlineEditID,
})

const mapDispatchToProps = (dispatch) => ({
    getVisitTypeList: (page, PageSize) => dispatch(getVisitTypeList(page, PageSize)),
    openAddVisitTypeModal: () => dispatch(openAddVisitTypeModal()),
    closeAddVisitTypeModal: () => dispatch(closeAddVisitTypeModal()),
    openEditVisitTypeModal: () => dispatch(openEditVisitTypeModal()),
    closeEditVisitTypeModal: () => dispatch(closeEditVisitTypeModal()),
    setVisitTypeDetails: (visitTypeDetails) => dispatch(setVisitTypeDetails(visitTypeDetails)),
    updateOpportunityCurrentPhase: (oppCurrentPhaseDetails) => dispatch(updateOpportunityCurrentPhase(oppCurrentPhaseDetails)),
    setIsInlineUpdate: (itemId) => dispatch(setIsInlineUpdate(itemId)),
    updateVisitType: (visitTypeDetail) => dispatch(updateVisitType(visitTypeDetail)),
    clearUpdateVisitTypeError: () => dispatch(clearUpdateVisitTypeError()),
    updateVisitTypeSuccess: () => dispatch(updateVisitTypeSuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(Visit)