import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    ListGroupItem,
    Row,
    Table
} from "reactstrap";
import VWModel from "../../../../components/Modal/VWModal";
import UpdateLeads from "./edit";
import OppDeleteDocument from './deleteDocument'
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import { closeLeadsUpdate, getLeadsDetails, openLeadsUpdate } from "../../../../store/Modules/crm/Leads/actions";
import { clearOpportunityDetailsDownloadError, clearOpportunityDetailsDownloadZipError, clearOpportunityDetailsError, closeDeleteOpportunityModal, closeOpportunityUpdate, getOpportunityDetails, getOpportunityDetailsDownload, getOpportunityDetailsDownloadZip, openDeleteDocsModalAction, openOpportunityUpdate } from "../../../../store/Modules/crm/Opportunity/actions";
import moment from "moment-timezone";
import Edit from "./edit";
import FileSaver from "file-saver";
import JSZip from "jszip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";


const CrmLeadsDetail = (props) => {
    const params = useParams();
    const { Id } = params || {};
    console.log("params:", params);


    const { opportunityDetails, opportunityDetailErrorMessage, isOpportunityDetailPending, isUpdateOpportunityOpen } = props || {};
    const { leadOwnerUserName, companyName, ownerUserEmail, ownerUserName, createdTimeStamp, stage, weightedValue, description, opCurrentPhaseValue
        , endDate, startDate, expectedValue, contactEmail, contactName, opportunityName, opCategoryName, opSubCategory, opCurrency, opTypeName, opActionName, opStatus, opCurrentPhase, opportunityDocuments
    } = opportunityDetails || {};

    useEffect(() => {
        props.getOpportunityDetails(Id);
        // props.getOpportunityDetailsDownloadZip(Id);   
        return () => {
            props.clearOpportunityDetailsDownloadError();
            props.clearOpportunityDetailsDownloadZipError();
            props.clearOpportunityDetailsError();
        }
    }, [])

    useEffect(() => {
        if (opportunityDetails && opportunityDetails.opportunityDocuments) {
            console.log("start useEffect opportunityDocuments");
            console.log("ID:", Id);
            console.log("opportunityDetails:", opportunityDetails);
            console.log("id:", opportunityDetails.id);
            // props.getOpportunityDetailsDownloadZip(Id)
            props.getOpportunityDetailsDownloadZip(Id);
            props.getOpportunityDetailsDownload(opportunityDocuments || []);
        }
    }, [opportunityDetails])

    const updateOpportunityOpen = () => {
        props.openOpportunityUpdate();
    }

    const handleClose = () => {
        props.closeOpportunityUpdate();
        props.closeDeleteOpportunityModal();
    }

    console.log("opportunityDetails:", opportunityDetails);


    const handleDownloadZIP = () => {
        const fileName = opportunityName;
        if (props.opportunityDetailsDownloadZip) {
            console.log("opportunityDetailsDownloadZip:", props.opportunityDetailsDownloadZip);
            const blob = props.opportunityDetailsDownloadZip;
            FileSaver.saveAs(blob, fileName);
        }
    };
    const handleDownload = async (file) => {

        const blobIndex = opportunityDocuments.findIndex(doc => doc.opportunityDocumentId === file.opportunityDocumentId);
        console.log("blobIndex:", blobIndex);

        if (blobIndex !== -1 && props.opportunityDetailsDownload && props.opportunityDetailsDownload[blobIndex]) {
            const blob = props.opportunityDetailsDownload[blobIndex];
            const fileName = file.fileName;

            // Use FileSaver to trigger the download
            FileSaver.saveAs(blob, fileName);
        } else {
            console.error("Blob not found for the selected document.");
        }
    };
    //----------------------------------------------------------------------------------------------------------------------------

    const handleView = (file) => {
        const byteCharacters = atob(file.fileData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: getMimeType(file.fileName) });
        const url = URL.createObjectURL(blob);

        // Open the URL in a new tab
        const newTab = window.open(url);
        if (!newTab) {
            // If the tab is blocked, alert the user
            alert('Please allow popups for this website');
        }
    };

    //---------------------------------------------------------------------------------------------------------------------------------  
    // Helper function to determine MIME type based on file extension
    const getMimeType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf': return 'application/pdf';
            case 'jpg':
            case 'jpeg': return 'image/jpeg';
            case 'png': return 'image/png';
            case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case 'pptx': return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            default: return 'application/octet-stream';
        }
    };
    console.log("opportunityDetailsDownload:", props.opportunityDetailsDownload);

    const [deleteDocsId, SetDeleteDocsId] = useState({});
    const handleDelete = (id) => {
        SetDeleteDocsId({ id });
        props.openDeleteDocsModalAction();
        // props.DeleteDocument(id, Id);


    }
    console.log("deleteDocsId:", deleteDocsId);

    return (
        <div className="page-content">
            <Card>
                <CardTitle className="p-2 mb-0 fw-bold fs-4 text-dark d-flex justify-content-between"> OPPORTUNITY DETAILS
                    <div>
                        <Button color="primary" onClick={updateOpportunityOpen}>
                            EDIT
                        </Button>
                    </div>

                </CardTitle>
            </Card>
            <Card>
                {opportunityDetailErrorMessage &&
                    <VWMessage type="danger" defaultMessage={opportunityDetailErrorMessage} baseClassName="alert" ></VWMessage>
                }
                {isOpportunityDetailPending ?
                    <div className="d-flex justify-content-center mt-2">
                        <VWSpinner />
                    </div>
                    :
                    <CardBody>
                        <Row>
                            <Col lg="6" md="12" sm="12">
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span> Opportunity Information</div>
                                <Table responsive>
                                    <tbody>
                                        <tr>
                                            <th>Opportunity Name</th>
                                            <td>{opportunityName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Account Name</th>
                                            <td>{companyName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Currency</th>
                                            <td>{opCurrency || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Opportunity Value</th>
                                            <td>{expectedValue || "N/A"}</td>
                                        </tr>

                                        <tr>
                                            <th>Weighted Value</th>
                                            <td>{weightedValue || "N/A"}</td>
                                        </tr>

                                        <tr>
                                            <th>Current Phase</th>
                                            <td>{opCurrentPhase || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Current Phase Value</th>
                                            <td>{opCurrentPhaseValue || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>{opStatus || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Type</th>
                                            <td>{opTypeName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Action</th>
                                            <td>{opActionName || "N/A"}</td>
                                        </tr>

                                        <tr>
                                            <th>Start Date</th>
                                            <td>{startDate ? moment(startDate).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>End Date</th>
                                            <td>{endDate ? moment(endDate).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg="6" md="12" sm="12">
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span> Contact Information</div>
                                <Table className="mb-4" responsive>
                                    <tbody>

                                        <tr>
                                            <th>Contact Name</th>
                                            <td>{contactName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Contact Email</th>
                                            <td>{contactEmail || "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span>Owner Information</div>
                                <Table className="mb-2" responsive>
                                    <tbody>
                                        <tr>
                                            <th>Owner Username</th>
                                            <td>{ownerUserName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Owner User Email</th>
                                            <td>{ownerUserEmail || "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span>Category Information</div>
                                <Table className="mb-2">
                                    <tbody>
                                        <tr>
                                            <th>Category</th>
                                            <td>{opCategoryName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Sub-Category</th>
                                            <td>{opSubCategory || "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1">
                                    <span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span>  About this opportunity
                                </div>
                                <div className="fs-6 ms-5">
                                    {description || "No information available"}
                                </div>

                                <hr />
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex justify-content-between gap-1 align-items-center">
                                    <div className="d-flex align-items-center">
                                        <span className="mdi mdi-bullseye-arrow fs-3 text-danger"></span>&nbsp;Documents
                                    </div>
                                    {(opportunityDocuments && opportunityDocuments.length > 0) ? (
                                        <Button color="primary" onClick={handleDownloadZIP}>
                                            Download All Files
                                        </Button>
                                    ) : ''}
                                </div>
                                <div className="fs-6 ms-5">
                                    {(opportunityDocuments && opportunityDocuments.length > 0) ? '' : 'NA'}
                                    <Table className="mb-2">
                                        <tbody>
                                            {opportunityDocuments && opportunityDocuments.map((file) => {
                                                const mimeType = getMimeType(file.fileName);
                                                const isViewable = ['application/pdf', 'image/jpeg', 'image/png'].includes(mimeType);
                                                return (
                                                    <tr key={file.opportunityDocumentId} >

                                                        <td><strong>{file.fileName}</strong></td>
                                                        <td>
                                                            <div>
                                                                <Button
                                                                    color="primary"
                                                                    size="sm"
                                                                    onClick={() => handleDownload(file)}
                                                                    className="me-2"
                                                                >
                                                                    <FontAwesomeIcon icon={faDownload} />
                                                                </Button>

                                                                {/* <Button
                                                                    color="secondary"
                                                                    size="sm"
                                                                    onClick={() => isViewable && handleView(file)}
                                                                    disabled={!isViewable}
                                                                >
                                                                    <FontAwesomeIcon icon={faSearch} />
                                                                </Button> */}
                                                            </div>
                                                        </td>
                                                        <td> <Button type="submit" color="danger" size="sm"
                                                            onClick={() => handleDelete(file.opportunityDocumentId)}
                                                        > <FontAwesomeIcon icon={faTrash} /></Button></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>


                            </Col>

                        </Row>
                    </CardBody>
                }
            </Card>
            <VWModel
                header="EDIT OPPORTUNITY"
                modalSize="extra-large"
                isOpen={props.isUpdateOpportunityOpen}
                handleClose={handleClose}
            >
                <Edit handleClose={handleClose} opportunityDetail={opportunityDetails} />
            </VWModel>
            <VWModel
                header="OPPORTUNITY DOCUMENT DELETE"
                // modalSize="extra-large"
                isOpen={props.isDeleteOpportunityOpen}
                handleClose={handleClose}
            >
                <OppDeleteDocument handleClose={handleClose} oppDocsId={deleteDocsId} detailId={Id} />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    opportunityDetails: state.crmOpportunity.opportunityDetails,
    isUpdateOpportunityOpen: state.crmOpportunity.isUpdateOpportunityOpen,
    opportunityDetailErrorMessage: state.crmOpportunity.opportunityDetailErrorMessage,
    isOpportunityDetailPending: state.crmOpportunity.isOpportunityDetailPending,
    opportunityDetailsDownload: state.crmOpportunity.opportunityDetailsDownload,
    opportunityDetailsDownloadZip: state.crmOpportunity.opportunityDetailsDownloadZip,
    isDeleteOpportunityOpen: state.crmOpportunity.isDeleteOpportunityOpen,
})

const mapDispatchToProps = (dispatch) => ({
    getOpportunityDetails: (id) => dispatch(getOpportunityDetails(id)),
    openOpportunityUpdate: () => dispatch(openOpportunityUpdate()),
    closeOpportunityUpdate: () => dispatch(closeOpportunityUpdate()),
    getOpportunityDetailsDownload: (opportunityDocuments) => dispatch(getOpportunityDetailsDownload(opportunityDocuments)),
    getOpportunityDetailsDownloadZip: (id) => dispatch(getOpportunityDetailsDownloadZip(id)),
    clearOpportunityDetailsDownloadZipError: () => dispatch(clearOpportunityDetailsDownloadZipError()),
    clearOpportunityDetailsDownloadError: () => dispatch(clearOpportunityDetailsDownloadError()),
    clearOpportunityDetailsError: () => dispatch(clearOpportunityDetailsError()),
    // DeleteDocument: (id, detailId) => dispatch(DeleteDocument(id, detailId)),
    openDeleteDocsModalAction: () => dispatch(openDeleteDocsModalAction()),
    closeDeleteOpportunityModal: () => dispatch(closeDeleteOpportunityModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CrmLeadsDetail);