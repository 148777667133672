import { getToken, modifydepenciesTaskList, modifyMilestoneList, modifyProjectList, modifyProjectUserList, modifyProjectUsersList, modifyTaskScopeList, modifyTaskStatusList, modifyTaskStatusPercentageList, modifyTaskTypeList, modifyUserList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { getProjectGanttListAction, updateGanttChartListSuccess } from "../GanttChart/actions";
import { getMilestoneDetails, getProjectMilestoneList } from "../Milestones/actions";
import { getProjectDetails, getProjectPieChartLists } from "../Projects/actions";
import { CLEAR_MILESTONE_DATE, CLEAR_MILESTONE_PIE_CHART_LIST_ERROR, CLEAR_MILESTONE_TASK_DATE_ERROR, CLEAR_MILESTONE_TASK_LIST, CLEAR_MILESTONE_TASK_LIST_ERROR, CLEAR_PROJECT_VALIDATION_ERROR, CLEAR_TASK_DETAILS_ERROR, CLEAR_TASK_ERROR, CLEAR_TASK_EXPORT_LIST_ERROR, CLEAR_TASK_LIST, CLEAR_TASK_LIST_ERROR, CLEAR_UPDATE_TASK_ERROR, CLEAR_UPDATE_TASK_STATUS_ERROR, CLOSE_CLONE_TASK_MODAL, CLOSE_CREATE_TASK_MODAL, CLOSE_EDIT_TASK_MODAL, CLOSE_EDIT_TASK_STATUS, CLOSE_KANBAN_EDIT_TASK_MODAL, CREATE_TASK_ERROR, CREATE_TASK_PENDING, CREATE_TASK_SUCCESS, IS_STATUS_TASK_EDIT_OPEN, IS_TASK_STATUS_LOADING_BOOLEAN, IS_TASK_STATUS_LOADING_BOOLEAN_CLOSE, IS_TASK_STATUS_LOADING_BOOLEAN_OPEN, KANBAN_TASK_LIST, MILESTONE_CREATE_CLOSE, MILESTONE_CREATE_OPEN, MILESTONE_PIE_CHART_LIST_ERROR, MILESTONE_TASK_DATE_ERROR, MILESTONE_TASK_LIST_ERROR, OPEN_CLONE_TASK_MODAL, OPEN_CREATE_TASK_MODAL, OPEN_EDIT_TASK_MODAL, OPEN_EDIT_TASK_STATUS, PROJECT_ACCESS_VALIDATION_ERROR, PROJECT_ACCESS_VALIDATION_PENDING, SET_GANTT_PROJECT_LIST, SET_KANBAN_LIST_PENDING, SET_MILESTONE_PIE_CHART_LIST, SET_MILESTONE_PIE_CHART_TASK_PENDING, SET_MILESTONE_TASK_DATE, SET_MILESTONE_TASK_DATE_PENDING, SET_MILESTONE_TASK_LIST, SET_MILESTONE_TASK_PENDING, SET_PROJECT_USER_LIST, SET_TASK_DETAILS, SET_TASK_DETAILS_ERROR, SET_TASK_DETAILS_PENDING, SET_TASK_EXPORT_LIST, SET_TASK_EXPORT_PENDING, SET_TASK_LIST, SET_TASK_PENDING, TASK_EXPORT_LIST_ERROR, TASK_EXPORT_SUCCESS, TASK_LIST_ERROR, UPDATE_KANBAN_TASK_ERROR, UPDATE_RATING_ERROR, UPDATE_RATING_ERROR_CLEAR, UPDATE_RATING_PENDING, UPDATE_RATING_SUCCESS, UPDATE_TASK_ERROR, UPDATE_TASK_PENDING, UPDATE_TASK_STATUS_ERROR, UPDATE_TASK_STATUS_PENDING, UPDATE_TASK_STATUS_SUCCESS, UPDATE_TASK_SUCCESS } from "./constants";

export const setTaskPending = () => ({
    type: SET_TASK_PENDING
});
export const setTaskExportPending = () => ({
    type: SET_TASK_EXPORT_PENDING
});
export const setMilestoneTaskPending = () => ({
    type: SET_MILESTONE_TASK_PENDING
});
export const setMilestoneTaskDatePending = () => ({
    type: SET_MILESTONE_TASK_DATE_PENDING
});
export const setMilestonePieChartTaskPending = () => ({
    type: SET_MILESTONE_PIE_CHART_TASK_PENDING
});
export const setTaskDetailsPending = () => ({
    type: SET_TASK_DETAILS_PENDING
});
export const setKanbanListPending = () => ({
    type: SET_KANBAN_LIST_PENDING
});
export const setTaskList = (taskList) => ({
    type: SET_TASK_LIST,
    payload: { taskList }
});
export const clearTaskList = () => ({
    type: CLEAR_TASK_LIST,
});
export const setTaskExportSuccess = () => ({
    type: TASK_EXPORT_SUCCESS,
});
export const setMilestoneTaskList = (taskList) => ({
    type: SET_MILESTONE_TASK_LIST,
    payload: { taskList }
});
export const setMilestoneTaskDate = (taskDate) => ({
    type: SET_MILESTONE_TASK_DATE,
    payload: { taskDate }
});
export const setMilestonePieChartList = (taskCountList) => ({
    type: SET_MILESTONE_PIE_CHART_LIST,
    payload: { taskCountList }
});
export const clearTaskListError = () => ({
    type: CLEAR_TASK_LIST_ERROR
});
export const clearTaskExportListError = () => ({
    type: CLEAR_TASK_EXPORT_LIST_ERROR
});
export const clearMilestoneTaskDateError = () => ({
    type: CLEAR_MILESTONE_TASK_DATE_ERROR
});
export const clearMilestoneTaskListError = () => ({
    type: CLEAR_MILESTONE_TASK_LIST_ERROR
});
export const clearMilestonePieChartListError = () => ({
    type: CLEAR_MILESTONE_PIE_CHART_LIST_ERROR
});
export const taskListError = (errorMessage) => ({
    type: TASK_LIST_ERROR,
    payload: { errorMessage },
})
export const taskExportListError = (errorMessage) => ({
    type: TASK_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const MilestoneTaskListError = (errorMessage) => ({
    type: MILESTONE_TASK_LIST_ERROR,
    payload: { errorMessage },
})
export const MilestoneTaskDateError = (errorMessage) => ({
    type: MILESTONE_TASK_DATE_ERROR,
    payload: { errorMessage },
})
export const MilestonePieChartListError = (errorMessage) => ({
    type: MILESTONE_PIE_CHART_LIST_ERROR,
    payload: { errorMessage },
})
export const openCreateTaskModalAction = () => ({
    type: OPEN_CREATE_TASK_MODAL
});
export const openEditTaskModalAction = () => ({
    type: OPEN_EDIT_TASK_MODAL
});
export const openCloneTaskModal = () => ({
    type: OPEN_CLONE_TASK_MODAL
});
export const openEditTaskStatus = () => ({
    type: OPEN_EDIT_TASK_STATUS
});
export const closeEditTaskStatus = () => ({
    type: CLOSE_EDIT_TASK_STATUS
});
export const closeCloneTaskModal = () => ({
    type: CLOSE_CLONE_TASK_MODAL
});
export const CloseCreateTaskModalAction = () => ({
    type: CLOSE_CREATE_TASK_MODAL
});
export const CloseEditTaskModalAction = () => ({
    type: CLOSE_EDIT_TASK_MODAL
});
export const CloseEditKanbanTaskModalAction = () => ({
    type: CLOSE_KANBAN_EDIT_TASK_MODAL
});
export const setProjectUserList = (updatedUserList) => ({
    type: SET_PROJECT_USER_LIST,
    payload: updatedUserList
});
export const createTaskError = (errorMessage) => ({
    type: CREATE_TASK_ERROR,
    payload: { errorMessage },
});
export const createTakPending = () => ({
    type: CREATE_TASK_PENDING,
});
export const clearCreateTaskError = () => ({
    type: CLEAR_TASK_ERROR,
});
export const createTaskSuccess = () => ({
    type: CREATE_TASK_SUCCESS,
});
export const setTaskDetails = (taskDetails) => ({
    type: SET_TASK_DETAILS,
    payload: { taskDetails },
})
export const clearTaskDetailsError = () => ({
    type: CLEAR_TASK_DETAILS_ERROR,
})
export const taskDetailsError = (errorMessage) => ({
    type: SET_TASK_DETAILS_ERROR,
    payload: { errorMessage },
})
export const clearUpdateTaskError = () => ({
    type: CLEAR_UPDATE_TASK_ERROR,
});
export const clearUpdateTaskStatusError = () => ({
    type: CLEAR_UPDATE_TASK_STATUS_ERROR,
});
export const clearMilestoneTaskList = () => ({
    type: CLEAR_MILESTONE_TASK_LIST,
});
export const updateTaskStatusPending = () => ({
    type: UPDATE_TASK_STATUS_PENDING,
});
export const updateTaskPending = () => ({
    type: UPDATE_TASK_PENDING,
});
export const updateTaskSuccess = () => ({
    type: UPDATE_TASK_SUCCESS,
});
export const updateTaskStatusSuccess = () => ({
    type: UPDATE_TASK_STATUS_SUCCESS,
});
export const updateTaskError = (errorMessage) => ({
    type: UPDATE_TASK_ERROR,
    payload: { errorMessage },
});
export const updateTaskStatusError = (errorMessage) => ({
    type: UPDATE_TASK_STATUS_ERROR,
    payload: { errorMessage },
});
export const updateKanbanTaskError = (errorMessage) => ({
    type: UPDATE_KANBAN_TASK_ERROR,
    payload: { errorMessage },
});
export const setKanbanTaskList = (KanbantaskList) => ({
    type: KANBAN_TASK_LIST,
    payload: { KanbantaskList },
});
export const setGanttProjectList = (ganttProjectList) => ({
    type: SET_GANTT_PROJECT_LIST,
    payload: { ganttProjectList },
});

export const openMilestoneCreate = () => ({
    type: MILESTONE_CREATE_OPEN
})
export const closeMilestoneCreate = () => ({
    type: MILESTONE_CREATE_CLOSE
})
export const ClearMilestoneDate = () => ({
    type: CLEAR_MILESTONE_DATE
})
export const isStatusTaskEditOpen = (id) => ({
    type: IS_STATUS_TASK_EDIT_OPEN,
    payload: { id }
})
export const isTaskStatusLoadingOpen = () => ({
    type: IS_TASK_STATUS_LOADING_BOOLEAN_OPEN
})
export const isTaskStatusLoadingClose = () => ({
    type: IS_TASK_STATUS_LOADING_BOOLEAN_CLOSE
})

export const setProjectValidationPending = () => ({
    type: PROJECT_ACCESS_VALIDATION_PENDING,
});
export const clearProjectValidationListError = () => ({
    type: CLEAR_PROJECT_VALIDATION_ERROR,
});
export const projectAccessValidationError = (errorMessage) => ({
    type: PROJECT_ACCESS_VALIDATION_ERROR,
    payload: { errorMessage },
})
export const getProjectIDValidationlist = (ProjectId) => (dispatch) => {
    dispatch(setProjectValidationPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.ProjectTracker.getProjectAccessValidation(accessToken, ProjectId)
            .then((projectAccess) => {
                dispatch(clearProjectValidationListError());
                return Promise.resolve(projectAccess);
            })
            .catch((err) => {
                console.log('getProjectIDlist server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(projectAccessValidationError(error))
                return Promise.reject(error);
            });
    });
};

export const getTaskListExportAction = (appId, searchTaskName, searchMilestoneName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription) => (dispatch) => {
    dispatch(setTaskExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.ProjectTracker.getTaskExportList(accessToken, appId, searchMilestoneName, searchTaskName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription)
            .then((taskList) => {
                dispatch(setTaskExportSuccess());
                // dispatch(clearTaskExportListError());
                return Promise.resolve(taskList);
            })
            .catch((err) => {
                console.log('getTaskListExportAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(taskExportListError(error))
            });
    });
}
export const getTaskList = (appId, filter, page, PageSize, searchTaskName, searchMilestoneName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription) => (dispatch) => {
    dispatch(setTaskPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getTaskList(accessToken, appId, filter, page, PageSize, searchMilestoneName, searchTaskName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription)
            .then((taskList) => {
                dispatch(setTaskList(taskList));
                dispatch(clearTaskListError());
                dispatch(isStatusTaskEditOpen(null));
                dispatch(isTaskStatusLoadingClose());
            })
            .catch((err) => {
                console.log('getTaskList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(taskListError(error))
            });
    });
}

export const getMilestoneDate = (Id) => (dispatch) => {
    dispatch(setMilestoneTaskDatePending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getMilestoneTaskDate(accessToken, Id).then((taskDate) => {
            dispatch(setMilestoneTaskDate(taskDate));
            dispatch(clearMilestoneTaskDateError());
        }).catch((err) => {
            console.log('getMilestoneDate server error', err);
            let error;
            if (err.status) {
                error = err.response && err.response.body && err.response.body.error.message;
                console.log(error)
            }
            else {
                error = "Network Error"
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            }
            dispatch(MilestoneTaskDateError(error));
        });
    })

}
export const getmilestoneTaskLists = (Id, filterTask, appId) => (dispatch) => {
    console.log("filterTask:", filterTask);
    dispatch(setMilestoneTaskPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getMilestoneTaskList(accessToken, Id, filterTask, appId).then((taskList) => {
            dispatch(setMilestoneTaskList(taskList));
            dispatch(clearMilestoneTaskListError());
        }).catch((err) => {
            console.log('getmilestoneTaskLists server error', err);
            let error;
            if (err.status) {
                error = err.response && err.response.body && err.response.body.error.message;
                console.log(error)
            }
            else {
                error = "Network Error"
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            }
            dispatch(MilestoneTaskListError(error));
        });
    })

}
export const getmilestonesPieChart = (Id) => (dispatch) => {
    dispatch(setMilestonePieChartTaskPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getMilestonePieChartList(accessToken, Id).then((taskCountList) => {
            dispatch(setMilestonePieChartList(taskCountList));
            dispatch(clearMilestonePieChartListError());
        }).catch((err) => {
            console.log('getmilestonesPieChart server error', err);
            let error;
            if (err.status) {
                error = err.response && err.response.body && err.response.body.error.message;
                console.log(error)
            }
            else {
                error = "Network Error"
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            }
            dispatch(MilestonePieChartListError(error));
        });
    })

}

export const getTaskTypeAsyncSelectAction = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getTaskType(accessToken, searchValue)
                .then((TaskTypeList) => {
                    const { value } = TaskTypeList;
                    const updateTaskTypeList = modifyTaskTypeList(value);
                    console.log("updateTaskTypeList", updateTaskTypeList);
                    //eslint-disable-next-line
                    callback && callback(updateTaskTypeList);
                })
                .catch((err) => {
                    console.log('getTaskTypeAsyncSelectAction server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getTaskTypeListAsyncSelectAction = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getTaskTypeList(accessToken, searchValue)
                .then((TaskTypeList) => {
                    const { value } = TaskTypeList;
                    const updateTaskTypeList = modifyTaskTypeList(value);
                    console.log("updateTaskTypeList", updateTaskTypeList);
                    //eslint-disable-next-line
                    callback && callback(updateTaskTypeList);
                })
                .catch((err) => {
                    console.log('getTaskTypeAsyncSelectAction server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };

export const getTaskScopeListAsyncSelectAction = (appId, searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getTaskScopeAsyncList(accessToken, appId, searchValue)
                .then((ProjectList) => {
                    const { value } = ProjectList;
                    const updateTaskScopeList = modifyTaskScopeList(value);
                    console.log("updateTaskScopeList", updateTaskScopeList);
                    //eslint-disable-next-line
                    callback && callback(updateTaskScopeList);
                })
                .catch((err) => {
                    console.log('getTaskScopeAsyncSelectAction server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getTaskScopeAsyncSelectAction = (appId, searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getTaskScope(accessToken, searchValue, appId)
                .then((ProjectList) => {
                    const { value } = ProjectList;
                    const updateTaskScopeList = modifyTaskScopeList(value);
                    console.log("updateTaskScopeList", updateTaskScopeList);
                    //eslint-disable-next-line
                    callback && callback(updateTaskScopeList);
                })
                .catch((err) => {
                    console.log('getTaskScopeAsyncSelectAction server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getTaskStatusAsyncSelectAction = (appId, searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getTaskStatusPercentage(accessToken, searchValue, appId)
                .then((TaskStatusList) => {
                    const { value } = TaskStatusList;
                    console.log("value:", value);
                    const updateTaskStatusList = modifyTaskStatusPercentageList(value);
                    console.log("updateTaskStatusList", updateTaskStatusList);
                    //eslint-disable-next-line
                    callback && callback(updateTaskStatusList);
                })
                .catch((err) => {
                    console.log('getTaskStatusAsyncSelectAction server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getTaskMilestoneOwnerStatusAsyncSelectAction = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getTaskMilestoneOwnerStatusPercentage(accessToken, searchValue)
                .then((TaskStatusList) => {
                    const { value } = TaskStatusList;
                    console.log("value:", value);
                    const updateTaskStatusList = modifyTaskStatusPercentageList(value);
                    console.log("updateTaskStatusList", updateTaskStatusList);
                    //eslint-disable-next-line
                    callback && callback(updateTaskStatusList);
                })
                .catch((err) => {
                    console.log('getTaskStatusAsyncSelectAction server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getTaskUserStatusAsyncSelectAction = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getTaskUserStatusPercentage(accessToken, searchValue)
                .then((TaskStatusList) => {
                    const { value } = TaskStatusList;
                    console.log("value:", value);
                    const updateTaskStatusList = modifyTaskStatusPercentageList(value);
                    console.log("updateTaskStatusList", updateTaskStatusList);
                    //eslint-disable-next-line
                    callback && callback(updateTaskStatusList);
                })
                .catch((err) => {
                    console.log('getTaskStatusAsyncSelectAction server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getTaskStatusAsyncSelectTimesheet = (appId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getTaskStatusPercentage(accessToken, searchValue, appId)
            .then((TaskStatusList) => {
                const { value } = TaskStatusList;
                const updateTaskStatusList = modifyTaskStatusList(value);
                console.log("updateTaskStatusList", updateTaskStatusList);
                //eslint-disable-next-line
                callback && callback(updateTaskStatusList);
            })
            .catch((err) => {
                console.log('getTaskStatusAsyncSelectAction server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getAllTaskStatusPercentage = (appId) => (dispatch) => {
    return getToken(dispatch).then((accessToken) => {
        return agent.ProjectTracker.getAllTaskStatusPercentage(accessToken, appId)
            .then((TaskStatusList) => {
                const { value } = TaskStatusList;
                const updateTaskStatusList = modifyTaskStatusList(value);
                console.log("updateTaskStatusList", updateTaskStatusList);
                //eslint-disable-next-line
                return Promise.resolve(updateTaskStatusList);
            })
            .catch((err) => {
                console.log('getAllTaskStatusPercentage server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                return Promise.reject(err);
            });
    });
};
export const getTaskStatusAsyncUserSelectAction = (appId, searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getTaskUserStatusPercentage(accessToken, searchValue, appId)
                .then((TaskStatusList) => {
                    const { value } = TaskStatusList;
                    console.log("value:", value);

                    const updateTaskStatusList = modifyTaskStatusPercentageList(value);
                    console.log("updateTaskStatusList", updateTaskStatusList);
                    //eslint-disable-next-line
                    callback && callback(updateTaskStatusList);
                })
                .catch((err) => {
                    console.log('getTaskStatusAsyncSelectAction server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getTaskStatusAsyncMilestoneSelectAction = (appId, searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getTaskMilestoneStatusPercentage(accessToken, searchValue, appId)
                .then((TaskStatusList) => {
                    const { value } = TaskStatusList;
                    console.log("value:", value);

                    const updateTaskStatusList = modifyTaskStatusPercentageList(value);
                    console.log("updateTaskStatusList", updateTaskStatusList);
                    //eslint-disable-next-line
                    callback && callback(updateTaskStatusList);
                })
                .catch((err) => {
                    console.log('getTaskStatusAsyncSelectAction server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getProjectNameAsyncSelect = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getProjectName(accessToken, searchValue)
                .then((ProjectList) => {
                    const { value } = ProjectList;
                    const updatedProjectList = modifyProjectList(value);
                    console.log("updatedProjectList", updatedProjectList);

                    dispatch(setGanttProjectList(updatedProjectList));
                    //eslint-disable-next-line
                    callback && callback(updatedProjectList);
                })
                .catch((err) => {
                    console.log('getProjectNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };


export const getDependenciesAsyncSelect = (appId, milestoneId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getTaskDependenciesUser(accessToken, appId, milestoneId, searchValue)
            .then((UserList) => {
                const { value } = UserList;
                const updatedTaskList = modifydepenciesTaskList(value);
                //eslint-disable-next-line
                callback && callback(updatedTaskList);
            })
            .catch((err) => {
                console.log('getAssignedUserAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                //eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getAssignedUserAsyncSelect = (projectId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getProjectUser(accessToken, projectId, searchValue)
            .then((UserList) => {
                const { value } = UserList;
                const ProjectUsers = value && value.filter((i) => i.IsGlobalReadOnlyUser === false)
                const updatedUserList = modifyProjectUsersList(ProjectUsers);
                //eslint-disable-next-line
                dispatch(setProjectUserList(updatedUserList))
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getAssignedUserAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                //eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getMilestoneSelectAction = (projectId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getMilestoneName(accessToken, projectId, searchValue)
            .then((UserList) => {
                const { value } = UserList;
                // console.log("userList: ", value)
                const updatedMilestoneList = modifyMilestoneList(value);
                //eslint-disable-next-line
                callback && callback(updatedMilestoneList);
            })
            .catch((err) => {
                console.log('getMilestoneSelectAction server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                //eslint-disable-next-line
                callback && callback();
            });
    });
};

export const CreateTaskAction = (appId, formFields, PROJECTID, selectedMilestoneId, filteredData, KanbanStartDate, KanbanEndDate) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createTaskError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = trimmedValues({
            ...formFields, completedDate: formFields.completedDate || null
        })
    }
    dispatch(createTakPending());
    dispatch(clearCreateTaskError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.createTask(accessToken, dataToSend)
            .then((cloneId) => {
                dispatch(createTaskSuccess());
                dispatch(clearForm());
                dispatch(CloseCreateTaskModalAction());
                dispatch(closeCloneTaskModal());
                if (appId) {
                    dispatch(getTaskList(appId, filteredData));
                    dispatch(getKanbanTaskList(appId, PROJECTID, '', KanbanStartDate, KanbanEndDate));
                }
                if (formFields.projectId) {
                    dispatch(getProjectDetails(formFields.projectId));

                    dispatch(getProjectMilestoneList(formFields.projectId));
                    dispatch(getProjectPieChartLists(formFields.projectId));
                }
                console.log("clone:", cloneId);

                if (cloneId && cloneId.id) {
                    dispatch(getTaskDetails(cloneId && cloneId.id));
                }
                console.log("appId:", appId);

                if (selectedMilestoneId && appId) {
                    dispatch(getmilestoneTaskLists(selectedMilestoneId, '', appId));
                }
                dispatch(showSnackbarStatus('Task created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createTaskError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createTaskError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Create Task server error', err);
                dispatch(clearForm());
                dispatch(CloseCreateTaskModalAction());
            });
    });
};

export const getTaskDetails = (id, isGanttChart) => (dispatch) => {
    dispatch(setTaskDetailsPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.ProjectTracker.getTaskDetails(accessToken, id)
            .then((taskDetails) => {
                dispatch(setTaskDetails(taskDetails))
                dispatch(clearTaskDetailsError());
                if (isGanttChart) {
                    return Promise.resolve(true)
                }
            })
            .catch((err) => {
                console.log('getTaskDetails server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(taskDetailsError(error))
            });
    })
}
export const UpdateTaskStatus = (formFields, filter, APIList, AppId) => (dispatch) => {
    console.log("formFields:", formFields, 'filter:', filter, "APIList:", APIList, 'AppId:', AppId);

    dispatch(clearUpdateTaskStatusError());
    dispatch(updateTaskStatusPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.editTaskStatus(accessToken, formFields)
            .then(() => {
                dispatch(updateTaskStatusSuccess());
                dispatch(closeEditTaskStatus());
                dispatch(isStatusTaskEditOpen(null));
                dispatch(getTaskDetails(formFields.taskId));
                if (formFields?.ProjectId) {
                    dispatch(getProjectPieChartLists(formFields.ProjectId));
                }
                if (formFields?.MileStoneId) {
                    dispatch(getmilestonesPieChart(formFields.MileStoneId));
                }

                if (APIList === 'List') {
                    dispatch(getTaskList(AppId, filter)); //shown in task list
                } else {
                    // else if (formFields.MileStoneId) {
                    dispatch(getmilestoneTaskLists(formFields.MileStoneId, filter, AppId)); //shown in project details task list.
                }
                dispatch(showSnackbarStatus('Task status details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err && err.response && err.response.text);
                    dispatch(updateTaskStatusError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateTaskStatusError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Update Task status server error', err);
                dispatch(closeEditTaskStatus());
            });
    });
}
export const UpdateTasks = (appId, formFields, PROJECTID, isGanttChartUpdate) => (dispatch) => {
    const dataToSend = trimmedValues({
        ...formFields,
        Id: formFields.id,
        completedDate: formFields.completedDate || null
    })
    dispatch(clearUpdateTaskError());
    dispatch(updateTaskPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.editTask(accessToken, dataToSend)
            .then(() => {
                dispatch(updateTaskSuccess());
                dispatch(CloseEditTaskModalAction());
                // dispatch(closeEditTaskStatus());
                dispatch(getTaskDetails(formFields.id));
                dispatch(getKanbanTaskList(appId, PROJECTID));
                console.log('isGanttChartUpdate: ', isGanttChartUpdate);
                if (isGanttChartUpdate) {
                    dispatch(updateGanttChartListSuccess());
                }
                dispatch(showSnackbarStatus('Task details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateTaskError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateTaskError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log(' Update Task server error', err);
                // dispatch(updateTaskError(null));
                dispatch(CloseEditTaskModalAction());
            });
    });
};
export const UpdateKanbanTasks = (appId, formFields, projectId, UserId, startDate, EndDate) => (dispatch) => {
    console.log("startDate:", startDate, "EndDate:", EndDate);
    console.log('formFields:', formFields);

    const dataToSend = {
        ...formFields,
        Dependencies: formFields.Dependencies.length > 0 ? formFields.Dependencies[0] : null
    }
    console.log("projectId:", projectId);
    console.log('dataToSend:', dataToSend);

    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.editTask(accessToken, dataToSend)
            .then(() => {
                dispatch(getKanbanTaskList(appId, projectId, UserId, startDate, EndDate));
                dispatch(CloseEditKanbanTaskModalAction());
                dispatch(showSnackbarStatus('Task Status updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    dispatch(getKanbanTaskList(appId, projectId, UserId, startDate, EndDate));
                    // dispatch(showSnackbarStatus(err && err.response && err.response.text));
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateKanbanTaskError(err && err.response && err.response.text));
                    return;
                }
                console.log(' Update Task server error', err);
                // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

export const getKanbanTaskList = (appId, filterProjects, filterUsers, startDate, EndDate) => (dispatch) => {
    console.log("filterProjects:", filterProjects);
    console.log("filterUsers:", filterUsers);
    dispatch(setKanbanListPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getKanbanTaskList(accessToken, appId, filterProjects, filterUsers, startDate, EndDate)
            .then((taskList) => {
                dispatch(setKanbanTaskList(taskList));
                dispatch(clearTaskListError());
            })
            .catch((err) => {
                console.log('getTaskList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(taskListError(error));
                dispatch(CloseEditKanbanTaskModalAction(error));
            });
    });
}

//----------------------------------------Rating Update----------------------------------------------------------------------

export const updateRatingPending = () => ({
    type: UPDATE_RATING_PENDING
})
export const updateRatingSuccess = () => ({
    type: UPDATE_RATING_SUCCESS
})
export const updateRatingError = (errorMessage) => ({
    type: UPDATE_RATING_ERROR,
    payload: { errorMessage }
})
export const updateRatingErrorClear = () => ({
    type: UPDATE_RATING_ERROR_CLEAR
})

export const updateRating = (id, rating) => (dispatch) => {
    const dataToSend = {
        taskId: id,
        ...rating,
    }
    dispatch(updateRatingPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.updateTaskRating(accessToken, dataToSend)
            .then(() => {
                dispatch(getTaskDetails(id));
                dispatch(updateRatingSuccess());
                dispatch(updateRatingErrorClear());
                dispatch(showSnackbarStatus('Task rating updated successfully'));
            })
            .catch((err) => {
                console.log('getTaskList server error', err);
                let error;
                if (err.status) {
                    error = err && err.response && err.response.text;
                    console.log(error);
                    dispatch(updateRatingError(error))
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                    dispatch(updateRatingError(error))
                }
            });
    });
}