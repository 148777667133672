
import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { clearForm } from "../../../form/actions";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR, CLEAR_MY_TEAM_TIMESHEET_LIST_ERROR, CLEAR_TIMESHEET_ACTION_ERROR, CLEAR_TIMESHEET_ERROR, CLEAR_TIMESHEET_PENDING, CLOSE_ACTION_TIMESHEET_MODAL, CREATE_MY_TEAM_TIMESHEET_SUCCESS, CREATE_TIMESHEET_PENDING, MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR, MY_TEAM_TIMESHEET_EXPORT_LIST_PENDING, MY_TEAM_TIMESHEET_LIST, MY_TEAM_TIMESHEET_LIST_ERROR, MY_TEAM_TIMESHEET_LIST_PENDING, OPEN_ACTION_TIMESHEET_MODAL, SET_MY_TEAM_TIMESHEET_EXPORT_LIST, SET_MY_TEAM_TIMESHEET_LIST, UPDATE_MY_TEAM_TIMESHEET_ERROR } from "./constants";
import { CLEAR_TIMESHEET_APPROVAL_ACTION_ERROR, CLOSE_ACTION_TIMESHEET_APPROVED_MODAL, OPEN_ACTION_TIMESHEET_APPROVED_MODAL } from "../TimesheetApproval/constants";
import { closeActionTimeSheetApprovalModal, getTimesheetApproval } from "../TimesheetApproval/actions";

export const setMyTeamTimeSheetList = (MyTeamTimeSheet) => ({
  type: SET_MY_TEAM_TIMESHEET_LIST,
  payload: { MyTeamTimeSheet },
})
export const setMyTeamTimeSheetExportList = (MyTeamTimeSheet) => ({
  type: SET_MY_TEAM_TIMESHEET_EXPORT_LIST,
  payload: { MyTeamTimeSheet },
})

export const myTeamTimeSheetList = () => ({
  type: MY_TEAM_TIMESHEET_LIST,
});
export const openActionTimeSheetModal = () => ({
  type: OPEN_ACTION_TIMESHEET_MODAL,
});

export const closeActionMTimeSheetModal = () => ({
  type: CLOSE_ACTION_TIMESHEET_MODAL,
});

export const clearTimeSheetActionError = () => ({
  type: CLEAR_TIMESHEET_ACTION_ERROR,
});

export const createTimeSheetPending = () => ({
  type: CREATE_TIMESHEET_PENDING,
});

export const clearCreateTimeSheetError = () => ({
  type: CLEAR_TIMESHEET_ERROR,
});
export const clearCreateTimeSheetPending = () => ({
  type: CLEAR_TIMESHEET_PENDING,
});
export const createTimeSheetSuccess = () => ({
  type: CREATE_MY_TEAM_TIMESHEET_SUCCESS,
});

export const MyTeamTimesheetListError = (myTeamTimesheetErrorMessage) => ({
  type: MY_TEAM_TIMESHEET_LIST_ERROR,
  payload: { myTeamTimesheetErrorMessage },
});
export const MyTeamTimesheetExportListError = (myTeamTimesheetErrorMessage) => ({
  type: MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR,
  payload: { myTeamTimesheetErrorMessage },
});

export const MyTeamTimesheetListPending = () => ({
  type: MY_TEAM_TIMESHEET_LIST_PENDING,
});

export const MyTeamTimesheetExportListPending = () => ({
  type: MY_TEAM_TIMESHEET_EXPORT_LIST_PENDING,
});

export const ClearMyTeamTimesheetListError = () => ({
  type: CLEAR_MY_TEAM_TIMESHEET_LIST_ERROR,
});
export const ClearMyTeamTimesheetExportListError = () => ({
  type: CLEAR_MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR,
});


export const updateTimeSheetError = (errorMessage) => ({
  type: UPDATE_MY_TEAM_TIMESHEET_ERROR,
  payload: { errorMessage },
});

export const getMyTeamTimeSheetExportListAction = (page, PageSize, searchuserName, searchprojectName, searchtaskName, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id) => (dispatch) => {
  dispatch(MyTeamTimesheetExportListPending());
  return getToken(dispatch).then((accessToken) => {
    return agent.MyTeamTimeSheet.getMyTeamTimeSheetExportList(accessToken, page, PageSize, searchuserName, searchprojectName, searchtaskName, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id)
      .then((MyTeamTimeSheetLists) => {
        // console.log("MyTeamTimeSheetLists:", MyTeamTimeSheetLists)
        dispatch(setMyTeamTimeSheetExportList(MyTeamTimeSheetLists));
        dispatch(ClearMyTeamTimesheetExportListError());
        return Promise.resolve(MyTeamTimeSheetLists);
      })
      .catch((err) => {
        console.log('getMyTeamTimeSheetExportListAction server error', err);
        let error;
        if (err.status) {
          error = err.response && err.response.body && err.response.body.error.message;
          console.log(error)
        }
        else {
          error = "Network Error"
          dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
        }
        dispatch(MyTeamTimesheetExportListError(error))
      });
  });
}
export const getMyTeamTimeSheetListActionModal = (page, PageSize, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id) => (dispatch) => {
  dispatch(MyTeamTimesheetListPending());
  getToken(dispatch).then((accessToken) => {
    agent.MyTeamTimeSheet.getMyTeamTimeSheetList(accessToken, page, PageSize, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id)
      .then((MyTeamTimeSheetLists) => {
        // console.log("MyTeamTimeSheetLists:", MyTeamTimeSheetLists)
        dispatch(setMyTeamTimeSheetList(MyTeamTimeSheetLists));
        dispatch(ClearMyTeamTimesheetListError());
      })
      .catch((err) => {
        console.log('getMyTeamTimesheetsList server error', err);
        let error;
        if (err.status) {
          error = err.response && err.response.body && err.response.body.error.message;
          console.log(error)
        }
        else {
          error = "Network Error"
          dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
        }
        dispatch(MyTeamTimesheetListError(error))
      });
  });
}

export const updateTimeSheetActionList = (timeSheetAction, statusUpdate) => (dispatch) => {
  // console.log("timeSheetAction:", timeSheetAction);
  dispatch(createTimeSheetPending());
  dispatch(clearCreateTimeSheetError());
  return getToken(dispatch).then((accessToken) => {
    return agent.MyTeamTimeSheet.createTimeSheetAction(accessToken, timeSheetAction)
      .then((response) => {
        // console.log("response:", response);
        // console.log("TimeSheet Action Created Successfully")
        dispatch(createTimeSheetSuccess());
        dispatch(clearCreateTimeSheetPending());
        dispatch(clearForm());
        dispatch(closeActionMTimeSheetModal());
        dispatch(getMyTeamTimeSheetListActionModal());
        dispatch(closeActionTimeSheetApprovalModal());
        dispatch(getTimesheetApproval(statusUpdate));
        // window.location.reload()
        // dispatch(showSnackbarStatus('TimeSheet Action created successfully'));
        return Promise.resolve(response)

      })
      .catch((err) => {
        if (err && err.response) {
          console.log("error message:", err.response)
          console.log("error message:", err.response.text)
          dispatch(updateTimeSheetError(err.response && err.response.text));
          return;
        } else {
          dispatch(updateTimeSheetError('Network Error'));
          dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
        }
        dispatch(clearForm());
        dispatch(closeActionMTimeSheetModal());
        dispatch(closeActionTimeSheetApprovalModal())
      });
  });

}