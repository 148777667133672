import {
    CLOSE_DELETE_OPPORTUNITY_DETAILS_MODAL,
    OPEN_DELETE_OPPORTUNITY_DETAILS_MODAL,
    OPPORTUNITY_CREATE_CLOSE,
    OPPORTUNITY_CREATE_ERROR,
    OPPORTUNITY_CREATE_ERROR_CLEAR,
    OPPORTUNITY_CREATE_OPEN,
    OPPORTUNITY_CREATE_PENDING,
    OPPORTUNITY_CREATE_SUCCESS,
    OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR,
    OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR_CLEAR,
    OPPORTUNITY_DETAILS_DOCUMENT_DELETE_PENDING,
    OPPORTUNITY_DETAILS_DOWNLOAD_ERROR,
    OPPORTUNITY_DETAILS_DOWNLOAD_ERROR_CLEAR,
    OPPORTUNITY_DETAILS_DOWNLOAD_PENDING,
    OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR,
    OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR_CLEAR,
    OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_PENDING,
    OPPORTUNITY_DETAILS_ERROR,
    OPPORTUNITY_DETAILS_ERROR_CLEAR,
    OPPORTUNITY_DETAILS_PENDING,
    OPPORTUNITY_EXPORT_LIST_ERROR,
    OPPORTUNITY_EXPORT_LIST_ERROR_CLEAR,
    OPPORTUNITY_EXPORT_LIST_PENDING,
    OPPORTUNITY_LIST_ERROR,
    OPPORTUNITY_LIST_ERROR_CLEAR,
    OPPORTUNITY_LIST_PENDING,
    OPPORTUNITY_UPDATE_CLOSE,
    OPPORTUNITY_UPDATE_ERROR,
    OPPORTUNITY_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_UPDATE_OPEN,
    OPPORTUNITY_UPDATE_PENDING,
    OPPORTUNITY_UPDATE_SUCCESS,
    SET_OPPORTUNITY_DETAILS,
    SET_OPPORTUNITY_DETAILS_DOWNLOAD,
    SET_OPPORTUNITY_DETAILS_DOWNLOAD_ZIP,
    SET_OPPORTUNITY_EXPORT_LIST,
    SET_OPPORTUNITY_LIST
} from "./constants";

const initialState = {
    //--------------------CONTACT-CREATE-----------------------------------
    isCreateOpportunityOpen: false,
    opportunityCreateErrorMessage: null,
    isOpportunityCreateSuccess: false,
    isOpportunityCreatePending: false,
    //--------------------CONTACT-UPDATE-----------------------------------
    isUpdateOpportunityOpen: false,
    opportunityUpdateErrorMessage: null,
    isOpportunityUpdateSuccess: false,
    isOpportunityUpdatePending: false,
    //--------------------CONTACT-LIST-------------------------------------
    opportunityList: [],
    opportunityListCount: 0,
    isOpportunityListPending: false,
    opportunityListErrorMessage: null,
    //--------------------CONTACT-DETAILS----------------------------------
    opportunityDetails: [],
    isOpportunityDetailPending: false,
    opportunityDetailErrorMessage: null,
    isOpportunityDetailDownloadPending: false,
    opportunityDetailDownloadErrorMessage: null,
    opportunityDetailsDownload:[],
    isOpportunityDetailDownloadZipPending:false,
    opportunityDetailDownloadZipErrorMessage: null,
    opportunityDetailsDownloadZip:{},
    isOpportunityDetailDocumentDeletePending:false,
    opportunityDetailDocumentDeleteErrorMessage:null,
    isDeleteOpportunityOpen:false,
    isOpportunityExportListPending:false,
    opportunityExportListErrorMessage: null,
    opportunityExportList:[]
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------OPPORTUNITY-CREATE-----------------------------------
        case OPPORTUNITY_CREATE_OPEN: {
            return {
                ...localState,
                isCreateOpportunityOpen: true
            }
        }
        case OPEN_DELETE_OPPORTUNITY_DETAILS_MODAL: {
            return {
                ...localState,
                isDeleteOpportunityOpen: true
            }
        }
        case CLOSE_DELETE_OPPORTUNITY_DETAILS_MODAL: {
            return {
                ...localState,
                isDeleteOpportunityOpen: false
            }
        }
        case OPPORTUNITY_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateOpportunityOpen: false
            }
        }
        case OPPORTUNITY_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityCreateErrorMessage: null,
            }
        }
        case OPPORTUNITY_CREATE_PENDING: {
            return {
                ...localState,
                isOpportunityCreatePending: true,
            }
        }
        case OPPORTUNITY_CREATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityCreateSuccess: true,
                isOpportunityCreatePending: false
            }
        }
        case OPPORTUNITY_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityCreateErrorMessage: errorMessage,
                isOpportunityCreatePending: false,
            }
        }

        //--------------------OPPORTUNITY-UPDATE-----------------------------------
        case OPPORTUNITY_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateOpportunityOpen: true
            }
        }
        case OPPORTUNITY_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateOpportunityOpen: false
            }
        }
        case OPPORTUNITY_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityUpdateErrorMessage: null,
            }
        }
        case OPPORTUNITY_UPDATE_PENDING: {
            return {
                ...localState,
                isOpportunityUpdatePending: true,
            }
        }
        case OPPORTUNITY_UPDATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityCreateSuccess: true,
                isOpportunityUpdatePending: false
            }
        }
        case OPPORTUNITY_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityUpdateErrorMessage: errorMessage,
                isOpportunityUpdatePending: false
            }
        }
        //--------------------OPPORTUNITY-LIST-------------------------------------
        case SET_OPPORTUNITY_LIST: {
            const { OpportunityList } = action.payload;
            return {
                ...localState,
                opportunityList: OpportunityList && OpportunityList['value'],
                opportunityListCount: OpportunityList && OpportunityList['@odata.count'],
                isOpportunityListPending: false,
                opportunityListErrorMessage: null,
            }
        }
        case SET_OPPORTUNITY_EXPORT_LIST: {
            const { OpportunityExportList } = action.payload;
            return {
                ...localState,
                opportunityExportList: OpportunityExportList,
                isOpportunityExportListPending: false,
                opportunityExportListErrorMessage: null,
            }
        }
        case OPPORTUNITY_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityListErrorMessage: errorMessage,
                isOpportunityListPending: false,
                opportunityList: []
            }
        }
        case OPPORTUNITY_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityExportListErrorMessage: errorMessage,
                isOpportunityExportListPending: false,
                opportunityExportList: []
            }
        }
        case OPPORTUNITY_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityListErrorMessage: null,
            }
        }
        case OPPORTUNITY_EXPORT_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityExportListErrorMessage: null,
                isOpportunityExportListPending: false,
            }
        }
        case OPPORTUNITY_LIST_PENDING: {
            return {
                ...localState,
                isOpportunityListPending: true,
            }
        }
        case OPPORTUNITY_EXPORT_LIST_PENDING: {
            return {
                ...localState,
                isOpportunityExportListPending: true,
            }
        }
        //--------------------OPPORTUNITY-DETAILS----------------------------------
        case SET_OPPORTUNITY_DETAILS: {
            const { OpportunityDetails } = action.payload;
            return {
                ...localState,
                opportunityDetails: OpportunityDetails,
                opportunityDetailErrorMessage: null,
                isOpportunityDetailPending: false
            }
        }
        case SET_OPPORTUNITY_DETAILS_DOWNLOAD: {
            const { OpportunityDetailsDownload } = action.payload;
            return {
                ...localState,
                opportunityDetailsDownload: OpportunityDetailsDownload,
                opportunityDetailDownloadErrorMessage: null,
                isOpportunityDetailDownloadPending: false
            }
        }
        case SET_OPPORTUNITY_DETAILS_DOWNLOAD_ZIP: {
            const { OpportunityDetailsDownloadZip } = action.payload;
            return {
                ...localState,
                opportunityDetailsDownloadZip: OpportunityDetailsDownloadZip,
                opportunityDetailDownloadZipErrorMessage: null,
                isOpportunityDetailDownloadZipPending: false
            }
        }
        case OPPORTUNITY_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityDetailErrorMessage: errorMessage,
                isOpportunityDetailPending: false,
                opportunityDetails: []
            }
        }
        case OPPORTUNITY_DETAILS_DOWNLOAD_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityDetailDownloadErrorMessage: errorMessage,
                isOpportunityDetailDownloadPending: false,
                opportunityDetailsDownload: []
            }
        }
        case OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityDetailDownloadZipErrorMessage: errorMessage,
                isOpportunityDetailDownloadZipPending: false,
                opportunityDetailsDownloadZip: {}
            }
        }
        case OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityDetailDocumentDeleteErrorMessage: errorMessage,
                isOpportunityDetailDocumentDeletePending: false,
            }
        }
        case OPPORTUNITY_DETAILS_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityDetailErrorMessage: null,
                opportunityDetails:{}
            }
        }
        case OPPORTUNITY_DETAILS_DOWNLOAD_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityDetailDownloadErrorMessage: null,
                opportunityDetailsDownload: []
            }
        }
        case OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityDetailDownloadZipErrorMessage: null,
                opportunityDetailsDownloadZip: {}
            }
        }
       
        case OPPORTUNITY_DETAILS_PENDING: {
            return {
                ...localState,
                isOpportunityDetailPending: true,
            }
        }
        case OPPORTUNITY_DETAILS_DOWNLOAD_PENDING: {
            return {
                ...localState,
                isOpportunityDetailDownloadPending: true,
            }
        }
        case OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_PENDING: {
            return {
                ...localState,
                isOpportunityDetailDownloadZipPending: true,
            }
        }
        case OPPORTUNITY_DETAILS_DOCUMENT_DELETE_PENDING: {
            return {
                ...localState,
                isOpportunityDetailDocumentDeletePending: true,
            }
        }

        default: {
            return localState;
        }
    }
};