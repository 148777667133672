import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_UPDATE_WEEKLY_OFF_ERROR, CLEAR_WEEKLY_OFF_LIST_ERROR, SET_WEEKLY_OFF_LIST, SET_WEEKLY_OFF_LIST_ERROR, SET_WEEKLY_OFF_PENDING, UPDATE_WEEKLY_OFF_ERROR, UPDATE_WEEKLY_OFF_PENDING, UPDATE_WEEKLY_OFF_SUCCESS } from "./constants";

export const setWeeklyOffPending = () => ({
    type: SET_WEEKLY_OFF_PENDING,
});
export const setWeeklyOffList = (weeklyOffList) => ({
    type: SET_WEEKLY_OFF_LIST,
    payload: { weeklyOffList },
})
export const clearWeeklyOffListError = () => ({
    type: CLEAR_WEEKLY_OFF_LIST_ERROR,
})
export const weeklyOffListError = (errorMessage) => ({
    type: SET_WEEKLY_OFF_LIST_ERROR,
    payload: { errorMessage },
})

export const clearUpdateWeeklyOffError = () => ({
    type: CLEAR_UPDATE_WEEKLY_OFF_ERROR,
});
export const updateWeeklyOffPending = () => ({
    type: UPDATE_WEEKLY_OFF_PENDING,
});
export const updateWeeklyOffSuccess = () => ({
    type: UPDATE_WEEKLY_OFF_SUCCESS,
});
export const updateWeeklyOffError = (errorMessage) => ({
    type: UPDATE_WEEKLY_OFF_ERROR,
    payload: { errorMessage },
});

export const getWeeklyOffDetailsAction = () => (dispatch) => {
    dispatch(setWeeklyOffPending());
    getToken(dispatch).then((accessToken) => {
        agent.WeeklyOff.getWeeklyOffDetails(accessToken)
            .then((weeklyOffLists) => {
                dispatch(setWeeklyOffList(weeklyOffLists));
                dispatch(clearWeeklyOffListError());
            })
            .catch((err) => {
                console.log('getWeeklyOffDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(weeklyOffListError(error))
            });
    });
}

export const updateWeeklyOffDays = (formFields) => (dispatch) => {

    dispatch(clearUpdateWeeklyOffError());
    dispatch(updateWeeklyOffPending());
    getToken(dispatch).then((accessToken) => {
        agent.WeeklyOff.editweeklyOff(accessToken, formFields)
            .then(() => {
                dispatch(updateWeeklyOffSuccess());
                dispatch(getWeeklyOffDetailsAction());
                dispatch(showSnackbarStatus('Weekly Off updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateWeeklyOffError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateWeeklyOffError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Update Weekly Off server error', err);
                // dispatch(updateWeeklyOffError(null));
                // dispatch(closeEditLeaveTypeModal());
            });
    });
};