import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Input, Button, Row, Col, Container, Card, CardBody, Label, FormFeedback, FormGroup, InputGroup } from "reactstrap";
import { clearEnableUserError, logoutUser, resetPassword, resetPasswordForm } from "../../store/auth/login/actions";
import { useNavigate } from "react-router-dom";
import vizo_logo from "../../assets/images/Vizo logo (9).png"
import VWMessage from "../../components/vwMessage/vwMessage";
import success from "../../assets/images/success-msg.png"


const mapStateToProps = (state) => ({
  formReset: state.login.formReset,
  isAuth: state.login.isAuth,
  ErrorMessage: state.login.ErrorMessage,
  isPendingSuccess: state.login.isPendingSuccess,
  isDisableForgotPassword: state.login.isDisableForgotPassword,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetPasswordForm: (formReset) => dispatch(resetPasswordForm(formReset)),
  resetPassword: (code, formFields) => dispatch(resetPassword(code, formFields)),
  clearEnableUserError: () => dispatch(clearEnableUserError()),
  logoutUser: () => dispatch(logoutUser())

});

const ResetPassword = (props) => {

  const naviagate = useNavigate();
  const [formFields, setFormFields] = useState({ EmailId: '', ConfirmPassword: '', Password: '' });
  const [codes, setCode] = useState("");
  const [EmailValid, setEmailValid] = useState(true);
  const [errors, setErrors] = useState({});
  const [showPasword1, setshowPasword1] = useState(true);
  const [showPasword2, setshowPasword2] = useState(true);

  const pathname = window.location.pathname;
  console.log("pathname:", pathname);
  const URL = pathname.split('/ResetPassword/')[1];
  console.log("URL:", URL);

  // const params = useParams();
  // console.log("params:", params);
  // const { code } = params;
  // const ecode = encodeURIComponent(code)
  // console.log("ecode:", ecode);


  // useEffect(() => {
  //   props.logoutUser();
  //   naviagate(`/ResetPassword/${ecode}`);
  //   // setCode(params.code);
  //   setCode(ecode);
  //   props.clearEnableUserError();
  // }, [ecode]);

  useEffect(() => {
    props.logoutUser();
    setCode(URL);
    props.clearEnableUserError();
  }, [URL]);

  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    let result = {};
    if (input.name === 'EmailId') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      result = {
        isValid: emailRegex.test(value),
        message: 'Enter valid email',
      }
    }
    setFormFields({
      ...formFields,
      [input.name]: value,
      errors: {
        ...formFields && formFields.errors,
        [input.name]: result.isValid ? null : result.message,
      },
    });

    if (input.name === 'Password') {
      setErrors({ ...errors, Password: 'Password must be eight character' });
      if (input.value && input.value.length >= 8) {
        setErrors({ ...errors, Password: '' });
      }
    }
    if (input.name === 'ConfirmPassword') {
      setErrors({ ...errors, ConfirmPassword: 'Confirm password does not match' });
      if (input.value === (formFields && formFields.Password)) {
        setErrors({ ...errors, ConfirmPassword: '' });
      }
    }
  };

  const onSubmit = (e) => {
    props.clearEnableUserError();
    const newErrors = {};
    if (!(formFields && formFields.EmailId) || !(formFields && formFields.EmailId && formFields.EmailId.trim())) {
      newErrors.EmailId = 'Email is required';
    }
    if (!(formFields && formFields.Password)) {
      newErrors.Password = 'Password is required';
    }
    if ((formFields && formFields.Password && formFields.Password.length < 8)) {
      newErrors.Password = 'Password must be eight character';
    }
    if (((formFields && formFields.ConfirmPassword) !== (formFields && formFields.Password))) {
      newErrors.ConfirmPassword = 'Confirm password does not match';
    }
    if (!(formFields && formFields.ConfirmPassword)) {
      newErrors.ConfirmPassword = 'Confirm password is required';
    }
    setFormFields({
      ...formFields,
      errors: newErrors
    })
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0 && EmailValid === true) {
      e.preventDefault();
      props.resetPassword(codes, formFields);
    }
  };

  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const handleLoginPage = () => {
    props.logoutUser();
    naviagate("/")
  }
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); 
      onSubmit(e);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <div className="bg-overlay"></div>
        <div className="account-pages pt-5">
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card className='mt-5'>
                <CardBody className="p-4">
                  <Col xs="auto" className="logo-lg text-center">
                    <img src={vizo_logo} height="auto" width="50%" />
                  </Col>
                  <br />
                  {!props.isPendingSuccess ?
                    <>
                      <Label style={{ display: 'flex', justifyContent: 'center' }}>Enter New Password</Label>
                      {props.ErrorMessage ? <div className='w-100'>
                        {props.ErrorMessage && (
                          <VWMessage type="danger" defaultMessage={props.ErrorMessage} baseClassName="alert" ></VWMessage>
                        )}
                      </div> : ""}
                      <form className="form-horizontal" action="#" onKeyDown={handleKeyDown}>
                        <Row>
                          <Col md={12}>
                            <div className="mt-4">
                              <label className="col-form-label">Email <span className="text-danger">*</span></label>
                              <Input
                                type="email"
                                name="EmailId"
                                autoFocus
                                className={formFields.errors && formFields.errors.EmailId ? "border-danger" : "border-secondary"}
                                placeholder="Enter email address"
                                onChange={validateOnChange}
                                invalid={formFields.errors && formFields.errors.EmailId}
                              />
                              <FormFeedback>
                                {formFields.errors && formFields.errors.EmailId}
                              </FormFeedback>
                            </div>
                            <div className="mt-1">
                              <FormGroup>
                                <label className="col-form-label">Password <span className="text-danger">*</span></label>
                                <InputGroup>
                                  <Input
                                    // type="password"
                                    className={errors && errors.Password ? "border-danger border-end-0" : 'border-end-0 border-secondary'}
                                    type={showPasword1 ? "password" : "text"}
                                    id="id-password"
                                    name="Password"
                                    placeholder="Enter password"
                                    onChange={validateOnChange}
                                  />
                                  <Button
                                    className={errors && errors.Password ? "border-danger border-start-0 rounded-end" : 'border-secondary border-start-0 rounded-end'}
                                    color='white'
                                    onClick={() => setshowPasword1(!showPasword1)}
                                  >
                                    {showPasword1 ?
                                      <i className="fas fa-eye text-secondary"></i>
                                      :
                                      <i class="fas fa-eye-slash text-secondary"></i>
                                    }
                                  </Button>
                                </InputGroup>
                                {
                                  <span className='text-danger'><small>{errors && errors.Password}</small></span>
                                }

                              </FormGroup>

                            </div>
                            <div className="mt-1">
                              <FormGroup>
                                <label className="col-form-label">Confirm Password <span className="text-danger">*</span></label>
                                <InputGroup>
                                  <Input
                                    id="id-confirmpassword"
                                    name="ConfirmPassword"
                                    className={errors && errors.ConfirmPassword ? "border-danger border-end-0" : 'border-end-0 border-secondary'}
                                    type={showPasword2 ? "password" : "text"}
                                    placeholder="Enter confirm password"
                                    onChange={validateOnChange}
                                  />
                                  <Button
                                    className={errors && errors.ConfirmPassword ? "border-danger border-start-0 rounded-end" : 'border-secondary border-start-0 rounded-end'}
                                    color='white'
                                    onClick={() => setshowPasword2(!showPasword2)}
                                  >
                                    {showPasword2 ?
                                      <i className="fas fa-eye text-secondary"></i>
                                      :
                                      <i class="fas fa-eye-slash text-secondary"></i>
                                    }
                                  </Button>
                                </InputGroup>
                                {
                                  <span className='text-danger'><small>{errors && errors.ConfirmPassword}</small></span>
                                }
                              </FormGroup>
                            </div>
                            <div className="d-grid mt-4">
                              {props.isDisableForgotPassword ?
                                (<Button color="success" block disabled> <div className="spinner-border spinner-border-sm"></div> Loading ...</Button>)
                                :
                                <Button color="success" onClick={onSubmit}>Confirm</Button>}
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </>
                    :
                    <div className='text-center pt-2'>
                      <img
                        src={success}
                        alt=''
                        height='60'
                      />
                      <p className='text-success font-size-16 pt-2'>password was successfully updated...</p>
                      <Button
                        color="link"
                        className='text-decoration-underline text-info mt-4'
                        onClick={handleLoginPage}
                      >Go to login page
                      </Button>
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment >


  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
