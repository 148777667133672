import React from 'react'
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import './gantt.css'
import { Button } from 'reactstrap';

const ViewSwitcher = ({ onViewModeChange, onViewListChange, isChecked, dateViewFormat }) => {
    console.log('DateViewFormat: ', dateViewFormat);
    return (
        <div className="ViewContainer gap-2">
            <Button
                color='primary'
                outline
                active={dateViewFormat === 'Quarter Day'}
                onClick={() => onViewModeChange(ViewMode.QuarterDay)}
            >
                Quarter of Day
            </Button>
            <Button
                color='primary'
                outline
                active={dateViewFormat === 'Half Day'}
                onClick={() => onViewModeChange(ViewMode.HalfDay)}
            >
                Half of Day
            </Button>
            <Button
                color='primary'
                outline
                active={dateViewFormat === 'Day'}
                onClick={() => onViewModeChange(ViewMode.Day)}
            >
                Day
            </Button>
            <Button
                color='primary'
                outline
                active={dateViewFormat === 'Week'}
                onClick={() => onViewModeChange(ViewMode.Week)}
            >
                Week
            </Button>
            <Button
                color='primary'
                outline
                active={dateViewFormat === 'Month'}
                onClick={() => onViewModeChange(ViewMode.Month)}
            >
                Month
            </Button>
            <Button
                color='primary'
                outline
                active={dateViewFormat === 'Year'}
                onClick={() => onViewModeChange(ViewMode.Year)}
            >
                Year
            </Button>

            <div className="Switch">
                <label className="Switch_Toggle mt-2">
                    <input
                        type="checkbox"
                        defaultChecked={isChecked}
                        onClick={() => onViewListChange(!isChecked)}
                    />
                    <span className="Slider round" />
                </label>
                <div className='fw-bold'> Show Table</div>
            </div>
        </div>
    )
}

export default ViewSwitcher