import React, { useEffect, useState } from 'react'
import { Badge, Card, CardBody, CardFooter, CardTitle, Col, Input, Label, Row, Table } from 'reactstrap'
import VWPagination from '../../../../components/vwPagination/vwPagination'
import { getPager } from '../../../../helper/common';
import { connect } from 'react-redux';
import { getRoleListAction } from '../../../../store/Modules/Admin/Role/actions';
import NoRecord from '../../../../components/NoRecords/noRecords';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSkeleton from '../../../../components/vwSkeleton/vwSkeleton';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetPath } from '../../../../store/actions';
import { ElementToolTip } from '../../Projects/vwToolTip';

const Role = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();

    const buildQueryRoleName = () => {
        const { RoleName } = filter || {};
        let searchRoleName = '';
        if (RoleName) {
            searchRoleName += `${RoleName}`;
        }
        return searchRoleName
    }
    const setPage = (page = 1) => {
        props.getRoleList(page, PageSize, buildQueryRoleName());

        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.ClearPath();
    }, [])

    //------------------------------------------Filter-----------------------------------------------------------
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    useEffect(() => {
        if (filter) {
            handleSearchClick();
        }
    }, [filter])
    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedRolesList = [...props.RolesList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'Days') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <>
                    <Card > <CardTitle
                        tag="h4"
                        className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong className='d-flex align-items-center'> ROLES -&nbsp;<Badge color="danger" id={'RoleCount'} style={{ cursor: 'pointer' }}>{props && props.totalCount}</Badge>
                            <ElementToolTip id={'RoleCount'} name={'Roles Count'} /></strong>
                        <div className="content-heading">
                            <div className="ml-auto">
                            </div>
                        </div>
                    </CardTitle></Card>
                    <Card>
                        {props.errorMessage ? <div className='w-100'>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        <CardBody>
                            <div style={{ height: "60vh", overflowX: "auto", paddingTop: "20px" }}>
                                <Table className="table table-striped table-hover w-100 " size="sm">
                                    <thead className='overflow-hidden sticky-top' style={{ zIndex: ' 1' }} >
                                        <tr>
                                            <th style={{ width: "80vh" }}>S.No</th>
                                            <th className='hand-cursor' onClick={() => handleSort('RoleName')}>Role {sortOrder === 'RoleName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}</th>
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {props.RolesList && props.RolesList.length > 0 ?
                                            sortedRolesList && sortedRolesList.map((data, index) => (
                                                <tr className='m-4' key={index}>
                                                    <td >{index + 1}</td>
                                                    <td >{
                                                        data.RoleName
                                                        || "NA"}</td>
                                                </tr>
                                            ))
                                            : props.isRoleListPending ?
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <VWSpinner />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <NoRecord />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter>
                            {props.RolesList && props.RolesList.length > 0 ?
                                <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                            }
                        </CardFooter>
                    </Card>
                </>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    RolesList: state.RoleList.RolesList,
    totalCount: state.RoleList.totalRecords,
    errorMessage: state.RoleList.roleListError,
    isRoleListPending: state.RoleList.isRoleListPending
});
const mapDispatchToProps = (dispatch) => ({
    getRoleList: (page, PageSize, searchRoleName) => dispatch(getRoleListAction(page, PageSize, searchRoleName)),
    ClearPath: () => dispatch(clearSetPath())
});
export default connect(mapStateToProps, mapDispatchToProps)(Role)