import React from "react";

import {
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Row,

} from "reactstrap";


import Async from 'react-select/async';

import { connect } from "react-redux";



//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

const mapStateToProps = (state) => ({
    Adddeviceform: state.device.Adddeviceform,
    lastSerialNo: state.device.lastSerialNo,
    currentProfile: state.login.currentProfile,
    errorMessage: state.device.errorMessage
});

const mapDispatchToProps = (dispatch: any) => ({

});

const FormWizard = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <Col lg="6">

                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">New Todo</h4>
                                <div id="progrss-wizard" className="twitter-bs-wizard">

                                    <Row>
                                        <Col md={6}>

                                            <Label>Project Name</Label>
                                            <Async
                                                key="selected_state"
                                                id="selected_state"
                                                name="selected_state"
                                                placeholder="Select Project Name"
                                                //cacheOptions
                                                defaultOptions
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Label>Todo Name</Label>
                                            <Input type="text" placeholder="Enter Todo Name" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Label>Description</Label>
                                            <Input type="textarea" placeholder="Enter Description" />
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <CardBody>

<h4>Details</h4>
                                <Label>Status</Label>
                                <Async
                                    key="selected_state"
                                    id="selected_state"
                                    name="selected_state"
                                    placeholder="Select Status"
                                    //cacheOptions
                                    defaultOptions
                                />
                                <Label>Type</Label>
                                <Async
                                    key="selected_state"
                                    id="selected_state"
                                    name="selected_state"
                                    placeholder="Select type"
                                    //cacheOptions
                                    defaultOptions
                                />
                                <Label>Priority</Label>
                                <Async
                                    key="selected_state"
                                    id="selected_state"
                                    name="selected_state"
                                    placeholder="Select Priority"
                                    //cacheOptions
                                    defaultOptions
                                />
                                <Label>Effort</Label>
                                <Async
                                    key="selected_state"
                                    id="selected_state"
                                    name="selected_state"
                                    placeholder="Select Efforts"
                                    //cacheOptions
                                    defaultOptions
                                />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                            <h4>Organisation</h4>
                                <Label>Milestones</Label>
                                <Async
                                    key="selected_state"
                                    id="selected_state"
                                    name="selected_state"
                                    placeholder="Select Milestones"
                                    //cacheOptions
                                    defaultOptions
                                />
                                <Label>Sprint</Label>
                                <Async
                                    key="selected_state"
                                    id="selected_state"
                                    name="selected_state"
                                    placeholder="Select Sprint"
                                    //cacheOptions
                                    defaultOptions
                                />
                                <Label>Tags</Label>
                                <Async
                                    key="selected_state"
                                    id="selected_state"
                                    name="selected_state"
                                    placeholder="Select Tags"
                                    //cacheOptions
                                    defaultOptions
                                />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                            <h4>People</h4>
                            <Label>Author</Label>
                                <Async
                                    key="selected_state"
                                    id="selected_state"
                                    name="selected_state"
                                    placeholder="Select Author"
                                    //cacheOptions
                                    defaultOptions
                                />
                                <Label>Assigned To</Label>
                                <Async
                                    key="selected_state"
                                    id="selected_state"
                                    name="selected_state"
                                    placeholder="Select Assigned To"
                                    //cacheOptions
                                    defaultOptions
                                /> 
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                            <h4>Dates</h4>
                            <Label>Last Update</Label>
                               <Input type="date"/>
                               <Label>Date</Label>
                               <Input type="date"/>
                               <Label>Working on it from</Label>
                               <Input type="date"/>
                               <Label>untill</Label>
                               <Input type="date"/>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                            <h4>Time Tracking</h4>
                            <Label>Planned Hours</Label>
                               <Input type="date"/>
                               <Label>Left Hours</Label>
                               <Input type="date"/>
                               <Label>Remaining Hours</Label>
                               <Input type="date"/>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                {/* </Container> */}
            </div>
        </React.Fragment>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormWizard);



