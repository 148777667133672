import { getToken, modifyCrmUserList } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    OPPORTUNITY_ACTION_TIME_CREATE_CLOSE,
    OPPORTUNITY_ACTION_TIME_CREATE_ERROR,
    OPPORTUNITY_ACTION_TIME_CREATE_ERROR_CLEAR,
    OPPORTUNITY_ACTION_TIME_CREATE_OPEN,
    OPPORTUNITY_ACTION_TIME_CREATE_PENDING,
    OPPORTUNITY_ACTION_TIME_CREATE_SUCCESS,
    OPPORTUNITY_ACTION_TIME_LIST_ERROR,
    OPPORTUNITY_ACTION_TIME_LIST_ERROR_CLEAR,
    OPPORTUNITY_ACTION_TIME_LIST_PENDING,
    OPPORTUNITY_ACTION_TIME_UPDATE_CLOSE,
    OPPORTUNITY_ACTION_TIME_UPDATE_ERROR,
    OPPORTUNITY_ACTION_TIME_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_ACTION_TIME_UPDATE_OPEN,
    OPPORTUNITY_ACTION_TIME_UPDATE_PENDING,
    OPPORTUNITY_ACTION_TIME_UPDATE_SUCCESS,
    SET_OPPORTUNITY_ACTION_TIME_DETAILS,
    SET_OPPORTUNITY_ACTION_TIME_LIST,
} from "./constants";

//--------------------Opportunity-ActionTime-CREATE-----------------------------------
export const openAddOpportunityActionTimeModal = () => ({
    type: OPPORTUNITY_ACTION_TIME_CREATE_OPEN
})
export const closeAddOpportunityActionTimeModal = () => ({
    type: OPPORTUNITY_ACTION_TIME_CREATE_CLOSE
})
export const createOpportunityActionTimePending = () => ({
    type: OPPORTUNITY_ACTION_TIME_CREATE_PENDING
})
export const createOpportunityActionTimeError = (errorMessage) => ({
    type: OPPORTUNITY_ACTION_TIME_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateOpportunityActionTimeError = () => ({
    type: OPPORTUNITY_ACTION_TIME_CREATE_ERROR_CLEAR,
})
export const createOpportunityActionTimeSuccess = () => ({
    type: OPPORTUNITY_ACTION_TIME_CREATE_SUCCESS,
})

export const createNewOpportunityActionTime = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createOpportunityActionTimeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createOpportunityActionTimePending());
    dispatch(clearCreateOpportunityActionTimeError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createOpportunityActionTime(accessToken, dataToSend)
            .then(() => {
                dispatch(getOpportunityActionTimeList());
                dispatch(createOpportunityActionTimeSuccess());
                dispatch(closeAddOpportunityActionTimeModal());
                dispatch(showSnackbarStatus('Opportunity action created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createOpportunityActionTimeError(err.response && err.response.text));
                    return;
                }
                console.log('Create Opportunity Action server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Opportunity-ActionTime-UPDATE-----------------------------------
export const openEditOpportunityActionTimeModal = () => ({
    type: OPPORTUNITY_ACTION_TIME_UPDATE_OPEN
})
export const closeEditOpportunityActionTimeModal = () => ({
    type: OPPORTUNITY_ACTION_TIME_UPDATE_CLOSE
})
export const updateOpportunityActionTimePending = () => ({
    type: OPPORTUNITY_ACTION_TIME_UPDATE_PENDING
})
export const updateOpportunityActionTimeError = (errorMessage) => ({
    type: OPPORTUNITY_ACTION_TIME_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateOpportunityActionTimeError = () => ({
    type: OPPORTUNITY_ACTION_TIME_UPDATE_ERROR_CLEAR,
})
export const updateOpportunityActionTimeSuccess = () => ({
    type: OPPORTUNITY_ACTION_TIME_UPDATE_SUCCESS,
})
export const setOpportunityActionTimeDetails = (opportunityActionTimeDetails) => ({
    type: SET_OPPORTUNITY_ACTION_TIME_DETAILS,
    payload: { opportunityActionTimeDetails }
})

export const updateOpportunityActionTime = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateOpportunityActionTimeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateOpportunityActionTimePending());
    dispatch(clearUpdateOpportunityActionTimeError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateOpportunityActionTime(accessToken, dataToSend)
            .then(() => {
                dispatch(getOpportunityActionTimeList());
                dispatch(updateOpportunityActionTimeSuccess());
                dispatch(closeEditOpportunityActionTimeModal());
                dispatch(showSnackbarStatus('Opportunity action updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateOpportunityActionTimeError(err.response && err.response.text));
                    return;
                }
                console.log('Create Opportunity Action server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Opportunity-ActionTime-LIST-----------------------------------
export const opportunityActionTimeListPending = () => ({
    type: OPPORTUNITY_ACTION_TIME_LIST_PENDING
})
export const opportunityActionTimeListError = (errorMessage) => ({
    type: OPPORTUNITY_ACTION_TIME_LIST_ERROR,
    payload: { errorMessage }
})
export const clearOpportunityActionTimeListError = () => ({
    type: OPPORTUNITY_ACTION_TIME_LIST_ERROR_CLEAR,
})
export const setOpportunityActionTimeList = (opportunityActionTimeList) => ({
    type: SET_OPPORTUNITY_ACTION_TIME_LIST,
    payload: { opportunityActionTimeList }
})

export const getOpportunityActionTimeList = (page, PageSize) => (dispatch) => {
    dispatch(opportunityActionTimeListPending());
    dispatch(clearOpportunityActionTimeListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getOpportunityActionTimeList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setOpportunityActionTimeList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                } 
                dispatch(opportunityActionTimeListError(error));
                console.log('Opportunity Action server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

