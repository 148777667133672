import { getToken, modifyCrmUserList } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { IsLeadCategoryInineUpdate } from "../LeadCategory/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    FOLLOW_UP_ACTION_CREATE_CLOSE,
    FOLLOW_UP_ACTION_CREATE_ERROR,
    FOLLOW_UP_ACTION_CREATE_ERROR_CLEAR,
    FOLLOW_UP_ACTION_CREATE_OPEN,
    FOLLOW_UP_ACTION_CREATE_PENDING,
    FOLLOW_UP_ACTION_CREATE_SUCCESS,
    FOLLOW_UP_ACTION_LIST_ERROR,
    FOLLOW_UP_ACTION_LIST_ERROR_CLEAR,
    FOLLOW_UP_ACTION_LIST_PENDING,
    FOLLOW_UP_ACTION_UPDATE_CLOSE,
    FOLLOW_UP_ACTION_UPDATE_ERROR,
    FOLLOW_UP_ACTION_UPDATE_ERROR_CLEAR,
    FOLLOW_UP_ACTION_UPDATE_OPEN,
    FOLLOW_UP_ACTION_UPDATE_PENDING,
    FOLLOW_UP_ACTION_UPDATE_SUCCESS,
    SET_FOLLOW_UP_ACTION_DETAILS,
    SET_FOLLOW_UP_ACTION_LIST,
} from "./constants";

//--------------------ACCOUNT-CATEGORY-CREATE-----------------------------------
export const openAddFollowUpActionModal = () => ({
    type: FOLLOW_UP_ACTION_CREATE_OPEN
})
export const closeAddFollowUpActionModal = () => ({
    type: FOLLOW_UP_ACTION_CREATE_CLOSE
})
export const createFollowUpActionPending = () => ({
    type: FOLLOW_UP_ACTION_CREATE_PENDING
})
export const createFollowUpActionError = (errorMessage) => ({
    type: FOLLOW_UP_ACTION_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateFollowUpActionError = () => ({
    type: FOLLOW_UP_ACTION_CREATE_ERROR_CLEAR,
})
export const createFollowUpActionSuccess = () => ({
    type: FOLLOW_UP_ACTION_CREATE_SUCCESS,
})

export const createNewFollowUpAction = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createFollowUpActionError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createFollowUpActionPending());
    dispatch(clearCreateFollowUpActionError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createFollowUpAction(accessToken, dataToSend)
            .then(() => {
                dispatch(getFollowUpActionList());
                dispatch(createFollowUpActionSuccess());
                dispatch(closeAddFollowUpActionModal());
                dispatch(showSnackbarStatus('Follow up action created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createFollowUpActionError(err.response && err.response.text));
                    return;
                }
                console.log('Create account category server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------CONTACTS-UPDATE-----------------------------------
export const openEditFollowUpActionModal = () => ({
    type: FOLLOW_UP_ACTION_UPDATE_OPEN
})
export const closeEditFollowUpActionModal = () => ({
    type: FOLLOW_UP_ACTION_UPDATE_CLOSE
})
export const updateFollowUpActionPending = () => ({
    type: FOLLOW_UP_ACTION_UPDATE_PENDING
})
export const updateFollowUpActionError = (errorMessage) => ({
    type: FOLLOW_UP_ACTION_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateFollowUpActionError = () => ({
    type: FOLLOW_UP_ACTION_UPDATE_ERROR_CLEAR,
})
export const updateFollowUpActionSuccess = () => ({
    type: FOLLOW_UP_ACTION_UPDATE_SUCCESS,
})
export const setFollowUpActionDetails = (followUpActionDetails) => ({
    type: SET_FOLLOW_UP_ACTION_DETAILS,
    payload: { followUpActionDetails }
})

export const updateFollowUpAction = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateFollowUpActionError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateFollowUpActionPending());
    dispatch(clearUpdateFollowUpActionError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateFollowUpAction(accessToken, dataToSend)
            .then(() => {
                dispatch(updateFollowUpActionSuccess());
                dispatch(closeEditFollowUpActionModal());
                dispatch(getFollowUpActionList());
                dispatch(showSnackbarStatus('Follow up action updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateFollowUpActionError(err.response && err.response.text));
                    return;
                }
                console.log('Create Account server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------ACCOUNT-CATEGORY-LIST-----------------------------------
export const FollowUpActionListPending = () => ({
    type: FOLLOW_UP_ACTION_LIST_PENDING
})
export const FollowUpActionListError = (errorMessage) => ({
    type: FOLLOW_UP_ACTION_LIST_ERROR,
    payload: { errorMessage }
})
export const clearFollowUpActionListError = () => ({
    type: FOLLOW_UP_ACTION_LIST_ERROR_CLEAR,
})
export const setFollowUpActionList = (followUpActionList) => ({
    type: SET_FOLLOW_UP_ACTION_LIST,
    payload: { followUpActionList }
})

export const getFollowUpActionList = (page, PageSize) => (dispatch) => {
    dispatch(FollowUpActionListPending());
    dispatch(clearFollowUpActionListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getFollowUpActionList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setFollowUpActionList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }  
                dispatch(FollowUpActionListError(error));
                console.log('Create Account server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
