import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Card,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
import { getDashboardCounts } from '../../../../store/Modules/Timesheet/Dashboard/actions'
import {
  useNavigate,
} from 'react-router-dom'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import VWSpinner from '../../../../components/vwSpinner/vwSpinner'
import './style.css';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendDown,
  faArrowTrendUp,
  faBoxesStacked,
  faCalendarDays,
  faCalendarWeek,
  faClipboardCheck,
  faFilePen,
  faListCheck,
  faScrewdriverWrench,
  faThumbsDown,
  faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import { timeDifference } from '../../../../helper/dateAndTime'

const Dashboard = (props) => {
  const { allCountings, isDashboardTimesheetsPending, currentProfile } = props;
  const { isProjectManager } = currentProfile || {};
  const { approvedTimesheet, myApprovalTeamTimesheet, myPendingApprovalTeamTimesheet, rejectedTimesheet, submittedTimesheet, totalTimesheet } = allCountings || {};
  console.log('allCountings:', allCountings);

  //---------------Need To Approve----------------------------
  const needToApproveCurrentWeek = myPendingApprovalTeamTimesheet && myPendingApprovalTeamTimesheet.week && myPendingApprovalTeamTimesheet.week.currentCount && myPendingApprovalTeamTimesheet.week.currentCount.loggedHr;
  const needToApproveLastWeek = myPendingApprovalTeamTimesheet && myPendingApprovalTeamTimesheet.week && myPendingApprovalTeamTimesheet.week.previousCount && myPendingApprovalTeamTimesheet.week.previousCount.loggedHr;
  const needToApproveWeekDiffType = myPendingApprovalTeamTimesheet && myPendingApprovalTeamTimesheet.week && myPendingApprovalTeamTimesheet.week.diffType;
  const needToApproveWeekDiff = myPendingApprovalTeamTimesheet && myPendingApprovalTeamTimesheet.week && myPendingApprovalTeamTimesheet.week.diffValue;
  const needToApproveCurrentMonth = myPendingApprovalTeamTimesheet && myPendingApprovalTeamTimesheet.month && myPendingApprovalTeamTimesheet.month.currentCount && myPendingApprovalTeamTimesheet.month.currentCount.loggedHr;
  const needToApproveLastMonth = myPendingApprovalTeamTimesheet && myPendingApprovalTeamTimesheet.month && myPendingApprovalTeamTimesheet.month.previousCount && myPendingApprovalTeamTimesheet.month.previousCount.loggedHr;
  const needToApprovemonthDiffType = myPendingApprovalTeamTimesheet && myPendingApprovalTeamTimesheet.month && myPendingApprovalTeamTimesheet.month.diffType;
  const needToApprovemonthDiff = myPendingApprovalTeamTimesheet && myPendingApprovalTeamTimesheet.month && myPendingApprovalTeamTimesheet.month.diffValue;
  const needToApproveCustomDate = myPendingApprovalTeamTimesheet && myPendingApprovalTeamTimesheet.customCounts && myPendingApprovalTeamTimesheet.customCounts.loggedHr;

  //---------------My Approvals----------------------------
  const myApprovalCurrentWeek = myApprovalTeamTimesheet && myApprovalTeamTimesheet.week && myApprovalTeamTimesheet.week.currentCount && myApprovalTeamTimesheet.week.currentCount.loggedHr;
  const myApprovalLastWeek = myApprovalTeamTimesheet && myApprovalTeamTimesheet.week && myApprovalTeamTimesheet.week.previousCount && myApprovalTeamTimesheet.week.previousCount.loggedHr;
  const myApprovalWeekDiffType = myApprovalTeamTimesheet && myApprovalTeamTimesheet.week && myApprovalTeamTimesheet.week.diffType;
  const myApprovalWeekDiff = myApprovalTeamTimesheet && myApprovalTeamTimesheet.week && myApprovalTeamTimesheet.week.diffValue;
  const myApprovalCurrentMonth = myApprovalTeamTimesheet && myApprovalTeamTimesheet.month && myApprovalTeamTimesheet.month.currentCount && myApprovalTeamTimesheet.month.currentCount.loggedHr;
  const myApprovalLastMonth = myApprovalTeamTimesheet && myApprovalTeamTimesheet.month && myApprovalTeamTimesheet.month.previousCount && myApprovalTeamTimesheet.month.previousCount.loggedHr;
  const myApprovalmonthDiffType = myApprovalTeamTimesheet && myApprovalTeamTimesheet.month && myApprovalTeamTimesheet.month.diffType;
  const myApprovalmonthDiff = myApprovalTeamTimesheet && myApprovalTeamTimesheet.month && myApprovalTeamTimesheet.month.diffValue;
  const myApprovalCustomDate = myApprovalTeamTimesheet && myApprovalTeamTimesheet.customCounts && myApprovalTeamTimesheet.customCounts.loggedHr;

  //---------------Total----------------------------
  const totalCurrentWeek = totalTimesheet && totalTimesheet.week && totalTimesheet.week.currentCount && totalTimesheet.week.currentCount.loggedHr;
  const totalLastWeek = totalTimesheet && totalTimesheet.week && totalTimesheet.week.previousCount && totalTimesheet.week.previousCount.loggedHr;
  const totalWeekDiffType = totalTimesheet && totalTimesheet.week && totalTimesheet.week.diffType;
  const totalWeekDiff = totalTimesheet && totalTimesheet.week && totalTimesheet.week.diffValue;
  const totalCurrentMonth = totalTimesheet && totalTimesheet.month && totalTimesheet.month.currentCount && totalTimesheet.month.currentCount.loggedHr;
  const totalLastMonth = totalTimesheet && totalTimesheet.month && totalTimesheet.month.previousCount && totalTimesheet.month.previousCount.loggedHr;
  const totalmonthDiffType = totalTimesheet && totalTimesheet.month && totalTimesheet.month.diffType;
  const totalmonthDiff = totalTimesheet && totalTimesheet.month && totalTimesheet.month.diffValue;
  const totalCustomDate = totalTimesheet && totalTimesheet.customCounts && totalTimesheet.customCounts.loggedHr;

  //---------------Submitted----------------------------
  const submittedCurrentWeek = submittedTimesheet && submittedTimesheet.week && submittedTimesheet.week.currentCount && submittedTimesheet.week.currentCount.loggedHr;
  const submittedLastWeek = submittedTimesheet && submittedTimesheet.week && submittedTimesheet.week.previousCount && submittedTimesheet.week.previousCount.loggedHr;
  const submittedWeekDiffType = submittedTimesheet && submittedTimesheet.week && submittedTimesheet.week.diffType;
  const submittedWeekDiff = submittedTimesheet && submittedTimesheet.week && submittedTimesheet.week.diffValue;
  const submittedCurrentMonth = submittedTimesheet && submittedTimesheet.month && submittedTimesheet.month.currentCount && submittedTimesheet.month.currentCount.loggedHr;
  const submittedLastMonth = submittedTimesheet && submittedTimesheet.month && submittedTimesheet.month.previousCount && submittedTimesheet.month.previousCount.loggedHr;
  const submittedmonthDiffType = submittedTimesheet && submittedTimesheet.month && submittedTimesheet.month.diffType;
  const submittedmonthDiff = submittedTimesheet && submittedTimesheet.month && submittedTimesheet.month.diffValue;
  const submittedCustomDate = submittedTimesheet && submittedTimesheet.customCounts && submittedTimesheet.customCounts.loggedHr;

  //-----------------Approved----------------------------
  const approvedCurrentWeek = approvedTimesheet && approvedTimesheet.week && approvedTimesheet.week.currentCount && approvedTimesheet.week.currentCount.loggedHr;
  const approvedLastWeek = approvedTimesheet && approvedTimesheet.week && approvedTimesheet.week.previousCount && approvedTimesheet.week.previousCount.loggedHr;
  const approvedWeekDiffType = approvedTimesheet && approvedTimesheet.week && approvedTimesheet.week.diffType;
  const approvedWeekDiff = approvedTimesheet && approvedTimesheet.week && approvedTimesheet.week.diffValue;
  const approvedCurrentMonth = approvedTimesheet && approvedTimesheet.month && approvedTimesheet.month.currentCount && approvedTimesheet.month.currentCount.loggedHr;
  const approvedLastMonth = approvedTimesheet && approvedTimesheet.month && approvedTimesheet.month.previousCount && approvedTimesheet.month.previousCount.loggedHr;
  const approvedmonthDiffType = approvedTimesheet && approvedTimesheet.month && approvedTimesheet.month.diffType;
  const approvedmonthDiff = approvedTimesheet && approvedTimesheet.month && approvedTimesheet.month.diffValue;
  const approvedCustomDate = approvedTimesheet && approvedTimesheet.customCounts && approvedTimesheet.customCounts.loggedHr;

  //---------------Rejected----------------------------
  const rejectedCurrentWeek = rejectedTimesheet && rejectedTimesheet.week && rejectedTimesheet.week.currentCount && rejectedTimesheet.week.currentCount.loggedHr;
  const rejectedLastWeek = rejectedTimesheet && rejectedTimesheet.week && rejectedTimesheet.week.previousCount && rejectedTimesheet.week.previousCount.loggedHr;
  const rejectedWeekDiffType = rejectedTimesheet && rejectedTimesheet.week && rejectedTimesheet.week.diffType;
  const rejectedWeekDiff = rejectedTimesheet && rejectedTimesheet.week && rejectedTimesheet.week.diffValue;
  const rejectedCurrentMonth = rejectedTimesheet && rejectedTimesheet.month && rejectedTimesheet.month.currentCount && rejectedTimesheet.month.currentCount.loggedHr;
  const rejectedLastMonth = rejectedTimesheet && rejectedTimesheet.month && rejectedTimesheet.month.previousCount && rejectedTimesheet.month.previousCount.loggedHr;
  const rejectedmonthDiffType = rejectedTimesheet && rejectedTimesheet.month && rejectedTimesheet.month.diffType;
  const rejectedmonthDiff = rejectedTimesheet && rejectedTimesheet.month && rejectedTimesheet.month.diffValue;
  const rejectedCustomDate = rejectedTimesheet && rejectedTimesheet.customCounts && rejectedTimesheet.customCounts.loggedHr;

  const [toggle, setToggle] = useState('week')
  const [fromDate, setFromDate] = useState(moment().startOf('week').format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState(moment().endOf('week').format('YYYY-MM-DD'))

  const navigate = useNavigate();

  useEffect(() => {
    if (fromDate && toDate && toggle === 'custom') {
      props.getDashboardCounts(moment(fromDate).format('MM/DD/YYYY'), moment(toDate).format('MM/DD/YYYY'));
      setToggle('custom');
    }
    else if (toggle !== 'custom') {
      props.getDashboardCounts();
    }

  }, [fromDate, toDate, toggle])

  const handleDateFilter = (e) => {
    const { name, value } = e.target || {};
    if (name === 'fromDate') {
      setFromDate(value);
    }
    if (name === 'toDate') {
      setToDate(value);
    }
  }

  const handleClick = (e, status) => {
    navigate(`/timesheets/mytimesheet?filter=${status}&startDate=${fromDate}&endDate=${toDate}`)
  }

  const handleClickTeam = (e, status) => {
    navigate(`/timesheets/timesheetapproval?filter=${status}&startDate=${fromDate}&endDate=${toDate}`)
  }

  return (
    <>
      <div className="page-content">
        <Card>
          <CardTitle tag='h4' className='px-4 mb-0  py-3 fw-bold d-flex flex-row justify-content-between align-items-center'>
            DASHBOARD
          </CardTitle>
        </Card>
        <Row>
          <Col xl="6" lg="6" md="6">
            <Card className="p-4">
              <div className='fs-5 fw-bold mb-3'>
                Filters
              </div>
              <div>
                <Row>
                  <Col lg="5" className="d-flex align-items-center justify-content-center">
                    <div className="button-container">
                      <button
                        className={toggle === 'week' ? 'button-design-active' : 'button-design'}
                        onClick={() => {
                          setToggle('week');
                          setFromDate(moment().startOf('week').format('YYYY-MM-DD'));
                          setToDate(moment().endOf('week').format('YYYY-MM-DD'));
                        }}
                      >
                        <FontAwesomeIcon icon={faCalendarWeek} style={{ paddingRight: "10px" }} />
                        Week
                      </button>
                      <button
                        className={toggle === 'month' ? 'button-design-active' : 'button-design'}
                        onClick={() => {
                          setToggle('month');
                          setFromDate(moment().startOf('month').format('YYYY-MM-DD'));
                          setToDate(moment().endOf('month').format('YYYY-MM-DD'));
                        }}
                      >
                        <FontAwesomeIcon icon={faCalendarDays} style={{ paddingRight: "10px" }} />
                        Month
                      </button>
                      <button
                        className={toggle === 'custom' ? 'button-design-active' : 'button-design'}
                        onClick={() => setToggle('custom')}
                      >
                        <FontAwesomeIcon icon={faScrewdriverWrench} style={{ paddingRight: "10px" }} />
                        Custom
                      </button>
                    </div>
                  </Col>
                  {/* </Row>
                <Row className="mt-3"> */}
                  <Col lg="5">
                    <div className='d-grid gap-2'>
                      <FormGroup>
                        <Label>Start Date</Label>
                        <Input
                          type='date'
                          name='fromDate'
                          placeHolder='search'
                          onChange={handleDateFilter}
                          value={fromDate ? moment(fromDate).format('YYYY-MM-DD') : 'N/A'}
                          disabled={toggle !== 'custom'}
                          max={toDate}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>End Date</Label>
                        <Input
                          type='date'
                          name='toDate'
                          placeHolder='search'
                          onChange={handleDateFilter}
                          value={toDate ? moment(toDate).format('YYYY-MM-DD') : 'N/A'}
                          disabled={toggle !== 'custom'}
                          min={fromDate}
                          max="9999-12-31"
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
            {isDashboardTimesheetsPending ?
              <div className="d-flex justify-content-center mt-2">
                <VWSpinner />
              </div>
              :
              isProjectManager &&
              <Card className="p-4">
                <Row className="mb-3">
                  <div className='fs-5 fw-bold'>
                    Your Team Timesheet Process
                  </div>
                </Row>
                <Row >
                  <Col xl="6" lg="12" md="12" className="mb-3">
                    <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={(e) => handleClickTeam(e, "Submitted")}>
                      <div className='section-1'>
                        <div className='d-flex'>
                          <div className='fs-1 fw-bold mt-2 card-1-color'>
                            {toggle === "week" ? needToApproveCurrentWeek || '00:00' : toggle === "month" ? needToApproveCurrentMonth || '00:00' : needToApproveCustomDate || '00:00'}
                          </div>
                          <div className=' d-flex align-items-end mb-2 pb-1 fs-6 fw-bold'>
                            Hrs.
                          </div>
                        </div>
                        <div className='badge-icon card-1-bg'>
                          <FontAwesomeIcon icon={faFilePen} style={{ fontSize: "18px", color: "#2B4560" }} />
                        </div>
                      </div>
                      {toggle !== "custom" &&
                        <>
                          <div className='d-flex align-items-center ps-3'>
                            <FontAwesomeIcon
                              icon={(toggle === "week" ? (needToApproveWeekDiffType === 'Decreased') : (needToApprovemonthDiffType === 'Decreased')) ? faArrowTrendDown : faArrowTrendUp}
                              style={{ color: (toggle === "week" ? (needToApproveWeekDiffType === 'Decreased') : (needToApprovemonthDiffType === 'Decreased')) ? "red" : "green", fontSize: "18px" }}
                            />
                            <div className='ps-2'>
                              {(toggle === "week" ? (needToApproveWeekDiffType === 'Decreased') : (needToApprovemonthDiffType === 'Decreased')) ?
                                <div className='fw-bold text-danger'> - </div>
                                : <div className='fw-bold text-success'> + </div>
                              }
                            </div>
                            <div className={(toggle === "week" ? (needToApproveWeekDiffType === 'Decreased') : (needToApprovemonthDiffType === 'Decreased')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                              {toggle === "week" ? needToApproveWeekDiff : needToApprovemonthDiff} Hrs
                            </div>
                          </div>
                          <div className='d-flex gap-2 ps-3'>
                            {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                            <div className='fs-6 fw-bold'>
                              {toggle === "week" ? needToApproveLastWeek || '00:00' : needToApproveLastMonth || '00:00'} Hrs.
                            </div>
                          </div>
                        </>
                      }
                      <div className='p-2 fw-bold rounded-bottom card-1-color card-1-bg'>
                        Need To Approve
                      </div>
                    </Card>
                  </Col>
                  <Col xl="6" lg="12" md="12" className="mb-3">
                    <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={(e) => handleClickTeam(e, "Approved")}>
                      <div className='section-1'>
                        <div className='d-flex'>
                          <div className='fs-1 fw-bold mt-2 card-2-color'>
                            {toggle === "week" ? myApprovalCurrentWeek || '00:00' : toggle === "month" ? myApprovalCurrentMonth || '00:00' : myApprovalCustomDate || '00:00'}
                          </div>
                          <div className=' d-flex align-items-end mb-2 pb-1 fs-6 fw-bold'>
                            Hrs.
                          </div>
                        </div>
                        <div className='badge-icon card-2-bg'>
                          <FontAwesomeIcon icon={faListCheck} style={{ fontSize: "18px", color: "#01413A" }} />
                        </div>
                      </div>
                      {toggle !== "custom" &&
                        <>
                          <div className='d-flex align-items-center ps-3'>
                            <FontAwesomeIcon
                              icon={(toggle === "week" ? (myApprovalWeekDiffType === 'Decreased') : (myApprovalmonthDiffType === 'Decreased')) ? faArrowTrendDown : faArrowTrendUp}
                              style={{ color: (toggle === "week" ? (myApprovalWeekDiffType === 'Decreased') : (myApprovalmonthDiffType === 'Decreased')) ? "red" : "green", fontSize: "18px" }}
                            />
                            <div className='ps-2'>
                              {(toggle === "week" ? (myApprovalWeekDiffType === 'Decreased') : (myApprovalmonthDiffType === 'Decreased')) ?
                                <div className='fw-bold text-danger'> - </div>
                                : <div className='fw-bold text-success'> + </div>
                              }
                            </div>
                            <div className={(toggle === "week" ? (myApprovalWeekDiffType === 'Decreased') : (myApprovalmonthDiffType === 'Decreased')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                              {(toggle === "week" ? myApprovalWeekDiff : myApprovalmonthDiff)} Hrs
                            </div>
                          </div>
                          <div className='d-flex gap-2 ps-3'>
                            {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                            <div className='fs-6 fw-bold'>
                              {toggle === "week" ? myApprovalLastWeek || '00:00' : myApprovalLastMonth || '00:00'} Hrs.
                            </div>
                          </div>
                        </>
                      }
                      <div className='p-2 fw-bold rounded-bottom card-2-color card-2-bg'>
                        My Approvals
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Card>
            }
          </Col>
          <Col xl="6" lg="6" md="6">
            <Card className="p-4">
              {isDashboardTimesheetsPending ?
                <div className="d-flex justify-content-center mt-2">
                  <VWSpinner />
                </div>
                :
                <>
                  <Row className="mb-3">
                    <div className='fs-5 fw-bold'>Your Timesheet Info</div>
                  </Row>

                  <Row>
                    <Col xl="6" lg="6" md="12" className="mb-3">
                      <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={(e) => handleClick(e, "All")}>
                        <div className='section-1'>
                          <div className='d-flex'>
                            <div className='fs-1 fw-bold mt-2 text-secondary'>
                              {toggle === "week" ? totalCurrentWeek || '00:00' : toggle === "month" ? totalCurrentMonth || '00:00' : totalCustomDate || '00:00'}
                            </div>
                            <div className=' d-flex align-items-end mb-2 pb-1 fs-6 fw-bold text-secondary'>
                              Hrs.
                            </div>
                          </div>
                          <div className='badge-icon bg-secondary'>
                            <FontAwesomeIcon icon={faBoxesStacked} style={{ fontSize: "18px", color: "white" }} />
                          </div>
                        </div>
                        {toggle !== "custom" &&
                          <>
                            <div className='d-flex align-items-center ps-3'>
                              <FontAwesomeIcon
                                icon={(toggle === "week" ? (totalWeekDiffType === 'Decreased') : (totalmonthDiffType === 'Decreased')) ? faArrowTrendDown : faArrowTrendUp}
                                style={{ color: (toggle === "week" ? (totalWeekDiffType === 'Decreased') : (totalmonthDiffType === 'Decreased')) ? "red" : "green", fontSize: "18px" }}
                              />
                              <div className='ps-2'>
                                {(toggle === "week" ? (totalWeekDiffType === 'Decreased') : (totalmonthDiffType === 'Decreased')) ?
                                  <div className='fw-bold text-danger'> - </div>
                                  : <div className='fw-bold text-success'> + </div>
                                }
                              </div>
                              <div className={(toggle === "week" ? (totalWeekDiffType === 'Decreased') : (totalmonthDiffType === 'Decreased')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                                {(toggle === "week" ? totalWeekDiff : totalmonthDiff)} Hrs
                              </div>
                            </div>
                            <div className='d-flex gap-2 ps-3'>
                              {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                              <div className='fs-6 fw-bold'>
                                {toggle === "week" ? totalLastWeek || '00:00' : totalLastMonth || '00:00'} Hrs.
                              </div>
                            </div>
                          </>
                        }
                        <div className='p-2 fw-bold rounded-bottom text-white bg-secondary'>
                          Total Timesheet
                        </div>
                      </Card>
                    </Col>
                    <Col xl="6" lg="6" md="12" className="mb-3">
                      <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={(e) => handleClick(e, "Submitted")}>
                        <div className='section-1'>
                          <div className='d-flex'>
                            <div className='fs-1 fw-bold mt-2 text-primary'>
                              {toggle === "week" ? submittedCurrentWeek || '00:00' : toggle === "month" ? submittedCurrentMonth || '00:00' : submittedCustomDate || '00:00'}
                            </div>
                            <div className=' d-flex align-items-end mb-2 pb-1 fs-6 fw-bold text-primary'>
                              Hrs.
                            </div>
                          </div>
                          <div className='badge-icon bg-primary'>
                            <FontAwesomeIcon icon={faClipboardCheck} style={{ fontSize: "18px", color: "white", paddingRight: "3px", paddingLeft: "3px" }} />
                          </div>
                        </div>
                        {toggle !== "custom" &&
                          <>
                            <div className='d-flex align-items-center ps-3'>
                              <FontAwesomeIcon
                                icon={(toggle === "week" ? (submittedWeekDiffType === 'Decreased') : (submittedmonthDiffType === 'Decreased')) ? faArrowTrendDown : faArrowTrendUp}
                                style={{ color: (toggle === "week" ? (submittedWeekDiffType === 'Decreased') : (submittedmonthDiffType === 'Decreased')) ? "red" : "green", fontSize: "18px" }}
                              />
                              <div className='ps-2'>
                                {(toggle === "week" ? (submittedWeekDiffType === 'Decreased') : (submittedmonthDiffType === 'Decreased')) ?
                                  <div className='fw-bold text-danger'> - </div>
                                  : <div className='fw-bold text-success'> + </div>
                                }
                              </div>
                              <div className={(toggle === "week" ? (submittedWeekDiffType === 'Decreased') : (submittedmonthDiffType === 'Decreased')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                                {(toggle === "week" ? submittedWeekDiff : submittedmonthDiff)} Hrs
                              </div>
                            </div>
                            <div className='d-flex gap-2 ps-3'>
                              {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                              <div className='fs-6 fw-bold'>
                                {toggle === "week" ? submittedLastWeek || '00:00' : submittedLastMonth || '00:00'} Hrs.
                              </div>
                            </div>
                          </>
                        }
                        <div className='p-2 fw-bold rounded-bottom text-white bg-primary'>
                          Submitted Timesheet
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6" lg="6" md="12" className="mb-3">
                      <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={(e) => handleClick(e, "Approved")}>
                        <div className='section-1'>
                          <div className='d-flex'>
                            <div className='fs-1 fw-bold mt-2 text-success'>
                              {toggle === "week" ? approvedCurrentWeek || '00:00' : toggle === "month" ? approvedCurrentMonth || '00:00' : approvedCustomDate || '00:00'}
                            </div>
                            <div className=' d-flex align-items-end mb-2 pb-1 fs-6 fw-bold text-success'>
                              Hrs.
                            </div>
                          </div>
                          <div className='badge-icon bg-success'>
                            <FontAwesomeIcon icon={faThumbsUp} style={{ fontSize: "18px", color: "white" }} />
                          </div>
                        </div>
                        {toggle !== "custom" &&
                          <>
                            <div className='d-flex align-items-center ps-3'>
                              <FontAwesomeIcon
                                icon={(toggle === "week" ? (approvedWeekDiffType === 'Decreased') : (approvedmonthDiffType === 'Decreased')) ? faArrowTrendDown : faArrowTrendUp}
                                style={{ color: (toggle === "week" ? (approvedWeekDiffType === 'Decreased') : (approvedmonthDiffType === 'Decreased')) ? "red" : "green", fontSize: "18px" }}
                              />
                              <div className='ps-2'>
                                {(toggle === "week" ? (approvedWeekDiffType === 'Decreased') : (approvedmonthDiffType === 'Decreased')) ?
                                  <div className='fw-bold text-danger'> - </div>
                                  : <div className='fw-bold text-success'> + </div>
                                }
                              </div>
                              <div className={(toggle === "week" ? (approvedWeekDiffType === 'Decreased') : (approvedmonthDiffType === 'Decreased')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                                {(toggle === "week" ? approvedWeekDiff : approvedmonthDiff)} Hrs
                              </div>
                            </div>
                            <div className='d-flex gap-2 ps-3'>
                              {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                              <div className='fs-6 fw-bold'>
                                {toggle === "week" ? approvedLastWeek || '00:00' : approvedLastMonth || '00:00'} Hrs.
                              </div>
                            </div>
                          </>
                        }
                        <div className='p-2 fw-bold rounded-bottom text-white bg-success'>
                          Approved Timesheet
                        </div>
                      </Card>
                    </Col>
                    <Col xl="6" lg="6" md="12" className="mb-3">
                      <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={(e) => handleClick(e, "Rejected")}>
                        <div className='section-1'>
                          <div className='d-flex'>
                            <div className='fs-1 fw-bold mt-2 card-2-color text-danger'>
                              {toggle === "week" ? rejectedCurrentWeek || '00:00' : toggle === "month" ? rejectedCurrentMonth || '00:00' : rejectedCustomDate || '00:00'}
                            </div>
                            <div className=' d-flex align-items-end mb-2 pb-1 fs-6 fw-bold text-danger'>
                              Hrs.
                            </div>
                          </div>
                          <div className='badge-icon bg-danger'>
                            <FontAwesomeIcon icon={faThumbsDown} style={{ fontSize: "18px", color: "white" }} />
                          </div>
                        </div>
                        {toggle !== "custom" &&
                          <>
                            <div className='d-flex align-items-center ps-3'>
                              <FontAwesomeIcon
                                icon={(toggle === "week" ? (rejectedWeekDiffType === 'Decreased') : (rejectedmonthDiffType === 'Decreased')) ? faArrowTrendDown : faArrowTrendUp}
                                style={{ color: (toggle === "week" ? (rejectedWeekDiffType === 'Decreased') : (rejectedmonthDiffType === 'Decreased')) ? "red" : "green", fontSize: "18px" }}
                              />
                              <div className='ps-2'>
                                {(toggle === "week" ? (rejectedWeekDiffType === 'Decreased') : (rejectedmonthDiffType === 'Decreased')) ?
                                  <div className='fw-bold text-danger'> - </div>
                                  : <div className='fw-bold text-success'> + </div>
                                }
                              </div>
                              <div className={(toggle === "week" ? (rejectedWeekDiffType === 'Decreased') : (rejectedmonthDiffType === 'Decreased')) ? "text-danger fw-bold" : "text-success fw-bold"}>
                                {(toggle === "week" ? rejectedWeekDiff : rejectedmonthDiff)} Hrs
                              </div>
                            </div>
                            <div className='d-flex gap-2 ps-3'>
                              {toggle === "week" ? <>This week vs. Last week</> : <>This month vs. Last month</>}
                              <div className='fs-6 fw-bold'>
                                {toggle === "week" ? rejectedLastWeek || '00:00' : rejectedLastMonth || '00:00'} Hrs.
                              </div>
                            </div>
                          </>
                        }
                        <div className='p-2 fw-bold rounded-bottom text-white bg-danger'>
                          Rejected Timesheet
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </>
              }
            </Card>
          </Col>
        </Row>
      </div >
    </>
  )
}
const mapStateToProps = (state) => ({
  allCountings: state.TimesheetDashboard.DashboardCounts,
  isDashboardTimesheetsPending: state.TimesheetDashboard.isDashboardTimesheetsPending,
  dashboardTimesheetsErrorMessage: state.TimesheetDashboard.dashboardTimesheetsErrorMessage,
  currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
  getDashboardCounts: (fromDate, toDate) => dispatch(getDashboardCounts(fromDate, toDate))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)