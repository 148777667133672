import React, { useEffect, useState } from 'react'
import EditLeadmandatory from './EditLeadmandatory'
import VWPagination from '../../../../../../components/vwPagination/vwPagination'
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Table } from 'reactstrap'
import VWMessage from '../../../../../../components/vwMessage/vwMessage'
import VWSpinner from '../../../../../../components/vwSpinner/vwSpinner'
import NoRecord from '../../../../../../components/NoRecords/noRecords'
import VWModel from '../../../../../../components/Modal/VWModal'
import { connect } from 'react-redux'
import Select from "react-select";
import { clearUpdateLeadMandatoryError, closeEditLeadMandatoryModal, getLeadMandatoryFieldsList, openEditLeadMandatoryModal, setLeadMandatoryDetails, updateLeadMandatory } from '../../../../../../store/Modules/crm/Settings/LeadmandatoryFields/actions'
import { getPager } from '../../../../../../helper/common'
import moment from 'moment-timezone'


const IsMandatoryStatus = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];
const LeadMandatory = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [ISMandatory, setISMandatory] = useState();
    const [formFields, setFormFields] = useState([]);
    const setPage = (page = 1) => {
        props.getLeadMandatoryFieldsList(page, PageSize);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        if (props.leadMandatoryList) {
            setISMandatory(props.leadMandatoryList && props.leadMandatoryList.IsMandatory ? { label: props.leadMandatoryList && props.leadMandatoryList.IsMandatory ? 'Yes' : 'No', value: props.leadMandatoryList && props.leadMandatoryList.IsMandatory } : 'No');
        }
    }, [props.leadMandatoryList]);

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        props.clearUpdateLeadMandatoryError();
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
    }, [])
    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedLeadMandatoryList = [...props.leadMandatoryList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsMandatory') {
            return sortDirection === 'asc' ? b.IsMandatory - a.IsMandatory : a.IsMandatory - b.IsMandatory;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleLeadMandatoryEdit = (id) => {
        const { openEditLeadMandatoryModal, setLeadMandatoryDetails } = props;
        const selected_Mandatory = sortedLeadMandatoryList.find((mandatory) => {
            return mandatory.Id === id;
        });
        setLeadMandatoryDetails(selected_Mandatory);
        openEditLeadMandatoryModal();
    }
    const handleEditClose = () => {
        props.closeEditLeadMandatoryModal();
    };

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    const handleSelectChange = (selectedOption, id) => {
        setFormFields((prevState) => {
            // Check if the field with the given ID already exists
            const existingFieldIndex = prevState.findIndex(item => item.id === id);
            if (existingFieldIndex !== -1) {
                // If the field already exists, update its isMandatory value
                const updatedFormFields = [...prevState];
                updatedFormFields[existingFieldIndex] = { id, isMandatory: selectedOption.value };
                return updatedFormFields;
            } else {
                return [...prevState, { id, isMandatory: selectedOption.value }];
            }
        });
    };

    const getSelectedValue = (id) => {
        const field = formFields.find(item => item.id === id);
        return field ? field.isMandatory : null;
    };
    console.log("formfields:", formFields);

    const handleSave = (e) => {
        e.preventDefault();
        props.updateLeadMandatory(formFields);

    }
    return (
        <React.Fragment>
            <div>
                <Card >
                    <CardTitle className=" fs-5 border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center fw-bold">
                        MANDATORY FIELDS
                        <Button
                            className='fw-bold'
                            size='sm'
                            color='info'
                            onClick={handleSave}
                            disabled={props.isLeadMandatoryUpdatePending}
                        >{props.isLeadMandatoryUpdatePending ? <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Saving...
                        </div>
                            : "Save"}

                        </Button>
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody>
                        <div style={{ height: "380px", overflowX: "auto" }}>
                            {props.errorMessage || props.leadMandatoryUpdateErrorMessage ? <div className='w-100'>
                                {(props.errorMessage || props.leadMandatoryUpdateErrorMessage) && (
                                    <VWMessage type="danger" defaultMessage={props.errorMessage || props.leadMandatoryUpdateErrorMessage} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <tr>
                                        <th onClick={() => handleSort('JsonPropertyName')} className='hand-cursor'>
                                            Field Name &nbsp;
                                            {sortOrder === 'JsonPropertyName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('IsMandatory')} className='hand-cursor'>
                                            IsMandatory &nbsp;
                                            {sortOrder === 'IsMandatory' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('CreatedBy')} className='hand-cursor'>
                                            Created By &nbsp;
                                            {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.leadMandatoryList && props.leadMandatoryList.length > 0 ?
                                        sortedLeadMandatoryList && sortedLeadMandatoryList.map((data) => (
                                            <tr class="middle-align">

                                                <td>{data.JsonPropertyName || "N/A"}</td>
                                                <td style={{ paddingRight: '30px' }}>
                                                    <Select
                                                        name="Status"
                                                        options={IsMandatoryStatus}
                                                        value={getSelectedValue(data.Id) === null ?
                                                            (data.IsMandatory ? { label: 'Yes', value: true } : { label: 'No', value: false }) :
                                                            { label: getSelectedValue(data.Id) ? 'Yes' : 'No', value: getSelectedValue(data.Id) }
                                                        }
                                                        onChange={(e) => handleSelectChange(e, data.Id)}
                                                    />
                                                </td>
                                                <td >
                                                    {data.CreatedBy || "N/A"}<br />
                                                    <small>{data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                                </td>
                                            </tr>
                                        ))
                                        : props.isLeadMandatoryListPending ?
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <VWSpinner />
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {props.leadMandatoryList && props.leadMandatoryList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="EDIT MANDATORY FIELDS"
                    modalSize="medium"
                    isOpen={props.isUpdateLeadMandatoryOpen}
                    handleClose={handleEditClose}
                >
                    <EditLeadmandatory
                        handleClose={handleEditClose}
                    />
                </VWModel>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    leadMandatoryList: state.LeadMandatory.leadMandatoryList,
    totalCount: state.LeadMandatory.leadMandatoryListCount,
    isLeadMandatoryListPending: state.LeadMandatory.isLeadMandatoryListPending,
    errorMessage: state.crmLeadCategory.leadMandatoryListErrorMessage,
    // isUpdateLeadMandatoryOpen: state.LeadMandatory.isUpdateLeadMandatoryOpen,
    isLeadMandatoryUpdatePending: state.LeadMandatory.isLeadMandatoryUpdatePending,
    leadMandatoryUpdateErrorMessage: state.LeadMandatory.leadMandatoryUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    getLeadMandatoryFieldsList: (page, PageSize) => dispatch(getLeadMandatoryFieldsList(page, PageSize)),
    // openEditLeadMandatoryModal: () => dispatch(openEditLeadMandatoryModal()),
    // closeEditLeadMandatoryModal: () => dispatch(closeEditLeadMandatoryModal()),
    setLeadMandatoryDetails: (selected_Mandatory) => dispatch(setLeadMandatoryDetails(selected_Mandatory)),
    updateLeadMandatory: (formFields) => dispatch(updateLeadMandatory(formFields)),
    clearUpdateLeadMandatoryError: () => dispatch(clearUpdateLeadMandatoryError()),
})
export default connect(mapStateToProps, mapDispatchToProps)(LeadMandatory)