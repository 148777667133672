import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardTitle, Input, Table, Badge, Col, Row } from 'reactstrap'
import { getPager } from '../../../../helper/common';
import Select from "react-select";
import moment from "moment";
import Badges from '@mui/material/Badge';
import VWPagination from '../../../../components/vwPagination/vwPagination';
import { connect } from 'react-redux';
import { clearForm } from '../../../../store/form/actions';
import { Link, useSearchParams } from 'react-router-dom';
import NoRecord from '../../../../components/NoRecords/noRecords';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { getMyTeamDetailsListActionModal, getMyteamLeaveExport, getPendingSubmittedCount } from '../../../../store/Modules/LeaveTracker/MyTeamLD/actions';
import VWModel from '../../../../components/Modal/VWModal';
import { clearCreateLeaveActionError, clearLeaveTrackerActionError, closeCreateApplyLeaveModalAction, closeLeaveTrackerModalAction, createLeaveTrackerActionSuccess, getEmployeeUserAsyncSelect, getLeaveTypeListAsyncSelect, openActionLeaveTrackerModal, openCreateApplyLeaveModalAction, updateLeavetrackerActionList } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions';
import LeaveTrackerStatusAction from "../LeaveTracker/leavetrackerStatus"
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';
import Async from "react-select/async"
import { MYTEAM_LEAVE_EXPORT_HEADERS } from '../../../../store/Modules/LeaveTracker/MyTeamLD/constants';
import { excelPrint, excelSheetExport } from '../../../../helper/export';
import { ElementToolTip } from '../../Projects/vwToolTip';

const Actions = [
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 'normal',
    minWidth: '150px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'normal'
  }),
};

const LeaveMonitoringList = (props) => {

  const path = window.location.search;
  const params = new URLSearchParams(path);
  const id = params.get('filter')

  const { currentProfile, isMyTeamLeaveExportListPending } = props || {};
  const { isManager } = currentProfile || false;

  const [PageSize] = useState(20);
  const [Pager, setPager] = useState({ currentPage: 1 });
  // const [filter, setFilter] = useState({ LeaveStatus: id || "All" });
  const [filter, setFilter] = useState();
  const [actionList, setActionsList] = useState({});
  const [badgeCount, setBadgeCount] = useState(0);
  const [currentMode, setCurrentMode] = useState("All");
  const [filterparams, setFilterParams] = useSearchParams();
  const [actionSelectedOption, setActionSelectedOption] = useState([]);

  console.log('currentMode', currentMode);
  console.log("filter:", filter);


  //  set defaultly to add filter in url
  useEffect(() => {
    const path = window.location.search;
    const params = new URLSearchParams(path);

    let filterFromUrl = params.get('filter') || "All";

    if (!params.has('filter')) {
      params.set('filter', "All");
      window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
    }

    setCurrentMode(filterFromUrl);
    setFilter({ LeaveStatus: filterFromUrl });

  }, []);

  const buildQueryuserName = () => {
    const { UserName } = filter || {};
    let searchuserName = '';
    if (UserName) {
      searchuserName += `${UserName}`;
    }
    return encodeURIComponent(searchuserName)
  }

  const buildQueryFromDateTime = () => {
    const { FromDateTime } = filter || {};
    let searchFromDateTime = '';
    if (FromDateTime) {
      searchFromDateTime += `${FromDateTime}`;
    }
    return searchFromDateTime
  }
  const buildQueryToDateTime = () => {
    const { ToDateTime } = filter || {};
    let searchToDateTime = '';
    if (ToDateTime) {
      searchToDateTime += `${ToDateTime}`;
    }
    return searchToDateTime
  }
  const buildQueryDays = () => {
    const { TotalLeaveCount } = filter || {};
    let searchDays = '';
    if (TotalLeaveCount) {
      searchDays += `${TotalLeaveCount}`;
    }
    return (searchDays)
  }
  const buildQueryReason = () => {
    const { LeaveReason } = filter || {};
    let searchReason = '';
    if (LeaveReason) {
      searchReason += `${LeaveReason}`;
    }
    return (searchReason)
  }
  const buildQueryLeaveType = () => {
    const { LeaveType } = filter || {};
    let searchLeaveType = '';
    if (LeaveType) {
      searchLeaveType += `${LeaveType}`;
    }
    return (searchLeaveType)
  }
  const buildQueryCategory = () => {
    const { Category } = filter || {};
    let searchCategory = '';
    if (Category) {
      searchCategory += `${Category}`;
    }
    return (searchCategory)
  }
  const buildQueryLeaveStatus = () => {
    const { LeaveStatus } = filter || {};
    let searchLeaveStatus = '';
    if (LeaveStatus) {
      searchLeaveStatus += `${LeaveStatus}`;
    }
    return (searchLeaveStatus)
  }
  const buildQueryStatusDescription = () => {
    const { StatusDescription } = filter || {};
    let searchStatusDescription = '';
    if (StatusDescription) {
      searchStatusDescription += `${StatusDescription}`;
    }
    return (searchStatusDescription)
  }
  const setPage = (page = 1) => {
    props.getMyTeamDetailsListActionModal(buildQueryLeaveStatus(), page, PageSize, buildQueryuserName(), /*buildQuerysearchUserEmail(),*/ buildQueryFromDateTime(), buildQueryToDateTime(), buildQueryDays(), buildQueryReason(), buildQueryLeaveType(), buildQueryCategory(), buildQueryLeaveStatus(), buildQueryStatusDescription(), id);

    setPager(prevPager => {
      const totalPages = Math.ceil(props.totalCount / PageSize);
      const visiblePages = 4;
      const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
      const newPages = [];
      for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
        newPages.push(i);
      }
      return {
        ...prevPager,
        currentPage: page,
        totalPages,
        pages: newPages,
      }
    })
  }

  useEffect(() => {
    // const currentPageNumber = Pager && Pager.currentPage;
    // setPage(currentPageNumber);
    props.getPendingSubmittedCount();// for sidebar count
    props.clearCreateLeaveActionError();
    props.ClearPath();
    props.createLeaveTrackerActionSuccess();

    return async () => {
      await props.clearSetLoginPath();
      props.ClearPath();
    }
  }, []);

  useEffect(() => {
    if (props.totalCount) {
      setBadgeCount(props.totalCount);
      const currentPager = getPager(props.totalCount, 1, PageSize)
      setPager(currentPager)
    }

  }, [props.totalCount])

  //------------------------------------------Filter-----------------------------------------------------------
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const searchInputValidation = (event) => {
    const { value, name } = event.target;
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const handleSearchClick = () => {
    setPage();
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }
  const debounceTime = window.__ENV && window.__ENV.debounceTime;
  useEffect(() => {
    if (filter) {
      const timeout = setTimeout(() => {
        handleSearchClick();
      }, debounceTime);
      setDebounceTimeout(timeout);
    }
  }, [filter])

  const handleLoadLeaveType = (inputValue, callback) => {
    const { getLeaveTypeAsyncSelectAction } = props;
    return getLeaveTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleSearchLeaveType = (value) => {
    const { label } = value || {};
    setFilter({
      ...filter,
      LeaveType: label,
    })
  }
  // ----------------------------------------SORTING-----------------------------------------------------------//
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = (column) => {
    if (sortOrder === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder(column);
      setSortDirection('asc');
    }
  };

  const sortedLeaveTrackerList = [...props.MyTeamLeaveDetailsList].sort((a, b) => {
    const aValue = a[sortOrder] || 'NA';
    const bValue = b[sortOrder] || 'NA';
    if (sortOrder === 'TotalLeaveCount') {
      const aVal = parseFloat(a[sortOrder] || 0);
      const bVal = parseFloat(b[sortOrder] || 0);

      return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
    }
    return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  const selectOnChange = (value, Id, index) => {

    const arr = actionSelectedOption;
    console.log("arr:", arr);

    arr[index] = value;
    setActionSelectedOption(arr);

    if (value.value === 'Rejected') {
      setActionsList({
        ...actionList,
        leaveStatus: value.value,
        id: Id
      });
      props.openLeaveTrackerModal();
    }
    else if (value.value === 'Approved') {
      const updatedAction = {
        leaveStatus: value.value,
        id: Id,
      };

      console.log("updatedAction:", updatedAction);
      props.updateLeavetrackerAction(updatedAction, filter).then(() => {
        setActionSelectedOption([]);
      })
    }
  }

  const handleAllAction = () => {

    const buttonUsed = "All"
    setBadgeCount(0);
    setCurrentMode(buttonUsed)
    setFilterParams({
      filter: buttonUsed
    })
    setFilter({
      ...filter,
      LeaveStatus: buttonUsed,
    })
  };
  const handleSubmittedAction = () => {
    setBadgeCount(0);
    const buttonUsed = "Submitted"
    setCurrentMode(buttonUsed)
    setFilterParams({
      filter: buttonUsed
    })
    setFilter({
      ...filter,
      LeaveStatus: buttonUsed,
    })
  };
  const handleRejectedAction = () => {
    setBadgeCount(0);
    const buttonUsed = "Rejected"
    setCurrentMode(buttonUsed)
    setFilterParams({
      filter: buttonUsed
    })
    setFilter({
      ...filter,
      LeaveStatus: buttonUsed,
    })

  };
  const handleApprovedAction = () => {
    setBadgeCount(0);
    const buttonUsed = "Approved"
    setCurrentMode(buttonUsed)
    setFilterParams({
      filter: buttonUsed
    })
    setFilter({
      ...filter,
      LeaveStatus: buttonUsed,
    })
  };

  useEffect(() => {
    if (id) {
      setCurrentMode(id)
    }
  }, [id])

  const handleCloseAction = () => {
    // window.location.reload()
    setPage();
    setActionSelectedOption([]);
    props.clearFormAction();
    props.clearLeaveTrackerActionError();
    props.closeLeaveTrackerModal();
    props.clearCreateLeaveActionError();
  };

  const handleLoadEmployeeUserList = (inputValue, callback) => {
    const { getEmployeeUserAsyncSelectAction } = props;
    return getEmployeeUserAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleSearchEmployeeUser = (Value) => {
    console.log("value:", Value);
    const { label, UserEmail } = Value || {};
    setFilter({
      ...filter,
      UserName: UserEmail,
    })
  }
  //-----------------------------------------Modal open & close-----------------------------------------------------

  const handleOpenLeaveApply = () => {
    props.openCreateApplyLeaveModal();
  };
  const handleClose = () => {
    props.clearFormAction()
    props.closeCreateApplyLeaveModal();
  };


  const { userAppDetails } = props && props.currentProfile;
  const App = userAppDetails && userAppDetails.filter((i) => {
    return (i.appName === "LeaveTracker") ? i.appId : ""
  })
  console.log(props.totalSubmittedRecords)

  const style = {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };


  //-------------------------------------------------Export---------------------------------------------------------------------------
  const handleExports = () => {
    props.getMyteamLeaveExport(buildQueryuserName(), buildQueryFromDateTime(), buildQueryToDateTime(), buildQueryDays(), buildQueryReason(), buildQueryLeaveType(), buildQueryCategory(), buildQueryLeaveStatus(), buildQueryStatusDescription(), id)
      .then((response) => {
        if (response) {
          const exportMyTeamLeavetrackerExportList = [];
          const headerExcel = [
            { headerName: "EMPLOYEE", key: "userName", headerWidhth: 30 },
            { headerName: "EMPLOYEE EMAIL", key: "userEmail", headerWidhth: 50 },
            { headerName: "FROM DATE", key: "fromDateTime", headerWidhth: 30 },
            { headerName: "TO DATE", key: "toDateTime", headerWidhth: 20 },
            { headerName: "LEAVE DAYS", key: "totalLeaveCount", headerWidhth: 20 },
            { headerName: "LEAVE REASON", key: "leaveReason", headerWidhth: 40 },
            { headerName: "LEAVE TYPE", key: "leaveType", headerWidhth: 20 },
            { headerName: "LEAVE STATUS", key: "leaveStatus", headerWidhth: 20 },
          ]
          response && response.forEach((element) => {
            exportMyTeamLeavetrackerExportList.push({
              userName: element.userName || "N/A",
              userEmail: element.userEmail || "N/A",
              fromDateTime: element.fromDateTime ? moment(element.fromDateTime).format('DD MMM YYYY') : 'N/A',
              toDateTime: element.toDateTime ? moment(element.toDateTime).format('DD MMM YYYY') : 'N/A',
              totalLeaveCount: element.totalLeaveCount || 0,
              leaveReason: element.leaveReason || "N/A",
              leaveType: element.leaveType || "N/A",
              leaveStatus: element.leaveStatus || "N/A",
            });
          });
          excelSheetExport(headerExcel, exportMyTeamLeavetrackerExportList, "My Team Leaves -List");
          // excelPrint(exportMyTeamLeavetrackerExportList, "My Team Leaves -List", headers, headerSizes);
        }
      })
  }


  console.log("actionSelectedOption:", actionSelectedOption);

  return (
    <div className="page-content">
      <Card >
        <CardTitle
          tag="h4"
          className="border-bottom px-4 py-3 mb-0"
        >
          <Row>
            <Col lg='3' md='4' sm='4' className='d-flex align-items-center ps-0'>
              <strong className='d-flex align-items-center gap-2'>
                <div> MY TEAM LEAVES</div>
                <div>-</div>
                <Badge color="danger">{props && props.totalCount || 0}</Badge>
              </strong>
            </Col>
            <Col lg='7' md='7' sm='6' className='d-flex align-items-center justify-content-around ps-0 '>
              <Row>
                <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                  <Badges
                    badgeContent={props.isMyTeamLeaveDetailsListPending ? null : badgeCount}
                    color="primary"
                    invisible={(currentMode !== "All")}
                  >
                    <Button
                      style={{ width: '100px' }}
                      onClick={handleAllAction}
                      className={(currentMode === "All") ? 'btn btn-outline-secondary  active' : "btn btn-secondary-danger "}
                      outline color="secondary"
                    ><b>All</b>
                    </Button>
                  </Badges>
                </Col>
                <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                  <Badges
                    badgeContent={props.isMyTeamLeaveDetailsListPending ? null : badgeCount}
                    color="primary"
                    invisible={currentMode !== "Submitted"}>
                    <Button
                      style={{ width: '100px' }}
                      onClick={handleSubmittedAction}
                      className={(currentMode === "Submitted") ? 'btn btn-outline-primary  active' : "btn btn-outline-primary "}
                      outline color="primary"
                    ><b>Submitted</b>
                    </Button>
                  </Badges>
                </Col>
                <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                  <Badges
                    badgeContent={props.isLeaveTrackerPending ? null : badgeCount}
                    color="primary"
                    invisible={currentMode !== "Approved"}>
                    <Button
                      style={{ width: '100px' }}
                      onClick={handleApprovedAction}
                      className={(currentMode === "Approved") ? 'btn btn-outline-success  active' : "btn btn-outline-success "}
                      outline color="success"
                    ><b>Approved</b>
                    </Button>
                  </Badges>
                </Col>
                <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                  <Badges
                    badgeContent={props.isMyTeamLeaveDetailsListPending ? null : badgeCount}
                    color="primary"
                    invisible={currentMode !== "Rejected"}>
                    <Button
                      style={{ width: '100px' }}
                      onClick={handleRejectedAction}
                      className={(currentMode === "Rejected") ? 'btn btn-outline-danger  active' : "btn btn-outline-danger "}
                      outline color="danger"
                    ><b>Rejected</b>
                    </Button>
                  </Badges>
                </Col>
              </Row>
            </Col>
            <Col lg='2' md='3' sm='2' xs='6' className='d-flex align-items-center ps-0 pe-0 justify-content-md-end justify-content-lg-end' >
              <Button
                className="me-4"
                color="success"
                onClick={handleExports}
                disabled={props.MyTeamLeaveDetailsList && props.MyTeamLeaveDetailsList.length <= 0 || isMyTeamLeaveExportListPending}
              >
                {isMyTeamLeaveExportListPending ? 'EXPORTING...' : 'EXPORT'}
              </Button>
            </Col>
          </Row>
        </CardTitle>
      </Card >
      <Card>
        <>
          <CardBody className="p-4">
            {props.errorMessage || props.createLeaveTrackerErrorMessage ? <div className='w-100'>
              {(props.errorMessage || props.createLeaveTrackerErrorMessage) && (
                <VWMessage type="danger" defaultMessage={props.errorMessage || props.createLeaveTrackerErrorMessage} baseClassName="alert" ></VWMessage>
              )}
            </div> : ""}
            <div style={{ height: "60vh", overflowX: "auto" }}>
              <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                  <tr>
                    <th
                      onClick={() => handleSort('UserName')} className='hand-cursor'
                    >
                      Employee &nbsp;
                      {sortOrder === 'UserName' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('FromDateTime')} className='hand-cursor'
                    >
                      From Date &nbsp;
                      {sortOrder === 'FromDateTime' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('ToDateTime')} className='hand-cursor'
                    >To Date &nbsp;
                      {sortOrder === 'ToDateTime' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                    </th>
                    <th
                      onClick={() => handleSort('TotalLeaveCount')} className='hand-cursor'
                    >
                      Leave Days &nbsp;
                      {sortOrder === 'TotalLeaveCount' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('LeaveReason')} className='hand-cursor'
                    >
                      Leave Reason &nbsp;
                      {sortOrder === 'LeaveReason' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('LeaveType')} className='hand-cursor'
                    >
                      Leave Type &nbsp;
                      {sortOrder === 'LeaveType' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th style={{ minWidth: '150px' }}
                      onClick={() => handleSort('LeaveStatus')} className='hand-cursor'
                    >
                      Leave Status &nbsp;
                      {sortOrder === 'LeaveStatus' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {/* <Input
                        type='text'
                        placeholder='Search'
                        name='UserName'
                        onChange={searchInputValidation}
                        value={filter && filter.UserName}
                        onKeyPress={handleKeyPress}
                      /> */}
                      <Async
                        styles={customStyles}
                        name="UserName"
                        isClearable
                        defaultOptions
                        placeholder="Search"
                        loadOptions={handleLoadEmployeeUserList}
                        onChange={handleSearchEmployeeUser}
                      />
                    </th>
                    <th>
                      <Input
                        type='date'
                        placeholder='Search'
                        name='FromDateTime'
                        onChange={searchInputValidation}
                        value={filter && filter.FromDateTime}
                        onKeyPress={handleKeyPress}
                        max="9999-12-31"
                      /></th>
                    <th >
                      <Input
                        type='date'
                        placeholder='Search'
                        name='ToDateTime'
                        onChange={searchInputValidation}
                        value={filter && filter.ToDateTime}
                        onKeyPress={handleKeyPress}
                        max="9999-12-31"
                      /></th>
                    <th >
                      <Input
                        onKeyDown={
                          (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}
                        style={{ 'min-width': '100px' }}
                        type='number'
                        min='0'
                        placeholder='Search'
                        name='TotalLeaveCount'
                        onChange={searchInputValidation}
                        value={filter && filter.TotalLeaveCount}
                        onKeyPress={handleKeyPress}
                      /></th>
                    <th >
                      <Input
                        type='text'
                        placeholder='Search'
                        name='LeaveReason'
                        onChange={searchInputValidation}
                        value={filter && filter.LeaveReason}
                        onKeyPress={handleKeyPress}
                      /></th>
                    <th >
                      {/* <Input
                        type='text'
                        placeholder='Search'
                        name='LeaveType'
                        onChange={searchInputValidation}
                        value={filter && filter.LeaveType}
                        onKeyPress={handleKeyPress}
                      /> */}
                      <Async
                        styles={customStyles}
                        name="LeaveType"
                        isClearable
                        defaultOptions
                        placeholder="Search"
                        loadOptions={handleLoadLeaveType}
                        onChange={handleSearchLeaveType}
                      />
                    </th>
                    <th >
                      <Input
                        type='text'
                        placeholder='Search'
                        name="LeaveStatus"
                        value={filter && filter.LeaveStatus}
                        disabled
                      />
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!props.isMyTeamLeaveDetailsListPending && (props.MyTeamLeaveDetailsList && props.MyTeamLeaveDetailsList.length > 0) ?
                    sortedLeaveTrackerList && sortedLeaveTrackerList.map((data, index) => (
                      <tr key={index}>
                        <td>{data.UserName || ""}<br />
                          <small>{data.UserEmail || ""}</small>
                        </td>
                        <td>{data.FromDateTime ? moment(data.FromDateTime).format('DD MMM YYYY') : 'N/A'}</td>
                        <td>{data.ToDateTime ? moment(data.ToDateTime).format('DD MMM YYYY') : 'N/A'}</td>
                        <td className='text-center'>{data.TotalLeaveCount || "N/A"}</td>
                        {/* <td>{data.LeaveReason ? (data.LeaveReason && data.LeaveReason.length > 20) ? data.LeaveReason && data.LeaveReason.substring(0, 20) + '...' : data.LeaveReason : "N/A"}</td> */}
                        <td className='ps-3'>{data.LeaveReason ? (data.LeaveReason && data.LeaveReason.length > 20) ? <><span id={`description-${data.Id}`}>{data.LeaveReason && data.LeaveReason.substring(0, 20) + '...'}</span>
                          <ElementToolTip
                            id={`description-${data.Id}`}
                            name={data.LeaveReason && data.LeaveReason.length > 20 ? data.LeaveReason : ''}
                          >
                            {data.LeaveReason && data.LeaveReason.length > 20
                              ? data.LeaveReason && data.LeaveReason.substring(0, 20) + '...'
                              : data.LeaveReason}
                          </ElementToolTip>
                        </>
                          : data.LeaveReason : "N/A"}
                        </td>
                        <td>{data.LeaveType || 'N/A'}</td>
                        {userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "leavetracker").length > 0 &&
                          userAppDetails
                            .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "leavetracker")
                            .map(userApp => {
                              const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                                const roleName = role.roleName.toLowerCase();
                                return roleName;
                              });
                              const isAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
                              return (
                                <React.Fragment key={data.Id}>
                                  {/* {console.log("data:", data)} */}
                                  {(isAdmin || isManager) ? (
                                    <td style={{ width: '175px' }}>
                                      <Select
                                        className='text-capitalize'
                                        styles={{
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                            overflow: 'auto'
                                          }),
                                        }}
                                        name="Status"
                                        // placeholder={data.LeaveStatus.toLowerCase() || "Select"}
                                        options={Actions}
                                        // value={Actions && Actions.find(option => option.value === data.LeaveStatus)}
                                        value={(actionSelectedOption && actionSelectedOption[index]) || { label: data.LeaveStatus.toLowerCase(), value: data.LeaveStatus }}
                                        isDisabled={data.LeaveStatus === "Approved" || data.LeaveStatus === "Rejected"}
                                        onChange={(e) => { selectOnChange(e, data.Id, index) }}
                                      />
                                    </td>
                                  ) : (
                                    <td className='text-capitalize'>
                                      {data.LeaveStatus.toLowerCase() || "N/A"}
                                    </td>)}
                                </React.Fragment>
                              );
                            })
                        }
                      </tr>
                    ))
                    : props.isMyTeamLeaveDetailsListPending ?
                      <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <VWSpinner />
                          </div>
                        </div>
                      </div>
                      :
                      <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <NoRecord />
                          </div>
                        </div>
                      </div>
                  }</tbody>
              </Table>
            </div>
          </CardBody>
          <CardFooter>
            {props.MyTeamLeaveDetailsList && props.MyTeamLeaveDetailsList.length > 0 ?
              <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
            }
          </CardFooter>
        </>
      </Card>
      <VWModel
        header="LEAVE TRACKER STATUS"
        isOpen={props.isActionLeaveTrackerModalOpen}
        handleClose={handleCloseAction}
      >
        <LeaveTrackerStatusAction data={actionList} status={filter}
          handleClose={handleCloseAction}
          handleStatusSelect={setActionSelectedOption}
        />
      </VWModel>
    </div >
  );
}
const mapStateToProps = (state) => ({
  MyTeamLeaveDetailsList: state.myTeamLeaveDetails.MyTeamLeaveDetailsList,
  errorMessage: state.myTeamLeaveDetails.myTeamLeaveDetailsListError,
  totalCount: state.myTeamLeaveDetails.totalRecords,
  currentProfile: state.login.currentProfile,
  isMyTeamLeaveDetailsListPending: state.myTeamLeaveDetails.isMyTeamLeaveDetailsListPending,
  isActionLeaveTrackerModalOpen: state.leaveTracker.isLeaveTrackerModalOpen,
  submittedCount: state.myTeamLeaveDetails.submittedCount,
  totalSubmittedRecords: state.myTeamLeaveDetails.totalSubmittedRecords,
  MyTeamLeaveExportList: state.myTeamLeaveDetails.MyTeamLeaveExportList,
  createLeaveTrackerErrorMessage: state.leaveTracker.createLeaveTrackerErrorMessage,
  isMyTeamLeaveExportListPending: state.myTeamLeaveDetails.isMyTeamLeaveExportListPending,
});
const mapDispatchToProps = (dispatch) => ({
  getMyTeamDetailsListActionModal: (filter, page, PageSize, searchuserName, /*searchUserEmail,*/ searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id) => dispatch(getMyTeamDetailsListActionModal(filter, page, PageSize, searchuserName, /*searchUserEmail,*/ searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id)),
  getMyteamLeaveExport: (searchuserName, /*searchUserEmail,*/ searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id) => dispatch(getMyteamLeaveExport(searchuserName, /*searchUserEmail,*/ searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id)),
  getPendingSubmittedCount: () => dispatch(getPendingSubmittedCount()),
  clearFormAction: () => dispatch(clearForm()),
  closeLeaveTrackerModal: () => dispatch(closeLeaveTrackerModalAction()),
  openLeaveTrackerModal: () => dispatch(openActionLeaveTrackerModal()),
  clearLeaveTrackerActionError: () => dispatch(clearLeaveTrackerActionError()),
  closeCreateApplyLeaveModal: () => dispatch(closeCreateApplyLeaveModalAction()),
  openCreateApplyLeaveModal: () => dispatch(openCreateApplyLeaveModalAction()),
  updateLeavetrackerAction: (updatedAction, filter) => dispatch(updateLeavetrackerActionList(updatedAction, filter)),
  clearCreateLeaveActionError: () => dispatch(clearCreateLeaveActionError()),
  ClearPath: () => dispatch(clearSetPath()),
  createLeaveTrackerActionSuccess: () => dispatch(createLeaveTrackerActionSuccess()),
  clearSetLoginPath: () => dispatch(clearSetLoginPath()),
  getEmployeeUserAsyncSelectAction: (appId, searchValue, callback) =>
    dispatch(getEmployeeUserAsyncSelect(appId, searchValue, callback)),
  getLeaveTypeAsyncSelectAction: (searchValue, callback) =>
    dispatch(getLeaveTypeListAsyncSelect(searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveMonitoringList)

