import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { clearForgotPasswordConfirmationStatus, clearForgotPasswordSuccess, forgotPassword, forgotPasswordForm, logoutUser, setForgotPasswordConfirmationStatus } from '../../store/actions';
import VWMessage from '../../components/vwMessage/vwMessage';
import visionware_logo from "../../assets/images/Visionware-Logo-R.png"
import vizo_logo from "../../assets/images/Vizo logo (8).png"
import success from "../../assets/images/success-msg.png"
import { useNavigate } from 'react-router-dom';
import FormValidator from '../../helper/formValidator';

const Recover = (props) => {
  const [formFields, setFormFields] = useState('');
  const [EmailValid, setEmailValid] = useState(true);
  const [errors, setErrors] = useState({});
  const { confirmationStatus, successStatus } = props;
  const naviagate = useNavigate();

  useEffect(() => {
    props.clearForgotPasswordConfirmationStatus();
    props.clearForgotPasswordSuccess();
  }, []);

  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    let result = FormValidator.validate(input);
    let emailValid = true;
    if (input.name === 'Email') {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

      if (!emailRegex.test(value)) {
        result = 'Invalid Email Address';
        emailValid = false;
      } else if (prohibitedChars.test(value)) {
        result = 'Invalid Email Address';
        emailValid = false;
      } else if (value.length <= 15) {
        result = 'Enter Email Address.';
        emailValid = false;
      }
      setEmailValid(emailValid);
    }

    setFormFields({
      ...formFields, [input.name]: value, errors: {
        ...(formFields && formFields.errors),
        [input.name]: result,
      },
    });
    // const { name, value } = event.target;
    // console.log(name, value)
    // setFormFields({ ...formFields, [name]: value })
  };

  const onSubmit = (e) => {
    const newErrors = {};
    if (!(formFields && formFields.Email) || !(formFields && formFields.Email && formFields.Email.trim())) {
      newErrors.Email = 'Email is required';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0 && EmailValid === true) {
      e.preventDefault();
      props.forgotPassword(formFields);
    }
    console.log("newErrors:", newErrors);

  };


  const handleLoginPage = () => {
    props.logoutUser();
    naviagate("/")
  }
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(e);
    }
  };

  console.log("formFields:", formFields);
  console.log("EmailValid:", EmailValid);
  console.log("isDisableResetLink:", props.isDisableResetLink);
  return (

    <React.Fragment>
      <Container>
        <div className="bg-overlay"></div>
        <div className="account-pages my-5 pt-5">
          <Row className="justify-content-center" onKeyDown={handleKeyDown}>
            <Col lg={6} md={8} xl={4}>
              <Card className='mt-5'>
                <CardBody className="p-4">
                  <Col xs="auto" className="logo-lg text-center">
                    <img src={vizo_logo} height="auto" width="50%" />
                  </Col>
                  <br />
                  <div>
                    <Label style={{ display: 'flex', justifyContent: 'center' }}>Password Reset</Label>
                    {successStatus ? (
                      <VWMessage type="success" defaultMessage={successStatus} baseClassName="alert"></VWMessage>
                    ) : ''}
                    {confirmationStatus ? (
                      <VWMessage type="danger" defaultMessage={confirmationStatus} baseClassName="alert"></VWMessage>
                    ) : ''}
                    <Form>
                      <Row>
                        <Col md={12}>
                          <br />
                          <label className="col-form-label">Email <span className="text-danger">*</span></label>
                          <Input autoFocus type="email" name="Email" placeholder='Enter Email Address' onChange={validateOnChange} value={formFields.Email} invalid={!EmailValid}
                            data-validate='["required"]' className='mb-1' />

                          {formFields && formFields.errors && formFields.errors.Email ? (
                            <FormFeedback>
                              {formFields.errors && formFields.errors.Email}
                            </FormFeedback>
                          ) : (
                            <span className='text-danger'><small>{errors && errors.Email}</small></span>
                          )}
                          <br />
                          {props.isDisableResetLink ?
                            <Button color="success" block disabled> <div className="spinner-border spinner-border-sm"></div> Loading ...</Button>
                            :
                            <Button color="success" block onClick={onSubmit} className='mt-2'>Request Reset Link</Button>}
                          <div className='d-flex justify-content-center font-size-14px'>
                            <Button
                              color="link"
                              className='text-decoration-underline text-info mt-4'
                              onClick={handleLoginPage}
                            >Go to login page
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col><br />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment >

  );
};

const mapStateToProps = (state) => ({
  formforgot: state.login.formforgot,
  confirmationStatus: state.login.confirmationStatus,
  isRecoverSuccess: state.login.isRecoverSuccess,
  isDisableResetLink: state.login.isDisableResetLink,
  successStatus: state.login.successStatus
});

const mapDispatchToProps = (dispatch: any) => ({
  forgotPasswordForm: (formforgot) => dispatch(forgotPasswordForm(formforgot)),
  forgotPassword: (email: string) => dispatch(forgotPassword(email)),
  setForgotPasswordConfirmationStatus: (status: string) => dispatch(setForgotPasswordConfirmationStatus(status)),
  clearForgotPasswordConfirmationStatus: () => dispatch(clearForgotPasswordConfirmationStatus()),
  clearForgotPasswordSuccess: () => dispatch(clearForgotPasswordSuccess()),
  logoutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Recover);