export const SET_EMPLOYEE_LIST = "SET_EMPLOYEE_LIST"
export const CLEAR_EMPLOYEE_LIST_ERROR = "CLEAR_EMPLOYEE_LIST_ERROR"
export const SET_ORG_EMPLOYEE_LIST_ERROR = "SET_ORG_EMPLOYEE_LIST_ERROR"
export const CLOSE_CREATE_EMPLOYEE_MODAL = "CLOSE_CREATE_EMPLOYEE_MODAL"
export const OPEN_CREATE_EMPLOYEE_MODAL = "OPEN_CREATE_EMPLOYEE_MODAL"
export const SET_EMPLOYEE_DETAILS = "SET_EMPLOYEE_DETAILS"
export const SET_ORG_EMPLOYEE_DETAILS_ERROR = "SET_ORG_EMPLOYEE_DETAILS_ERROR"
export const CLEAR_EMPLOYEE_DETAILS_ERROR = "CLEAR_EMPLOYEE_DETAILS_ERROR"
export const CREATE_EMPLOYEE_ERROR = "CREATE_EMPLOYEE_ERROR"
export const CREATE_EMPLOYEE_PENDING = "CREATE_EMPLOYEE_PENDING"
export const CLEAR_EMPLOYEE_ERROR = "CLEAR_EMPLOYEE_ERROR"
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS"
export const OPEN_EDIT_EMPLOYEE_MODAL = "OPEN_EDIT_EMPLOYEE_MODAL"
export const CLOSE_EDIT_EMPLOYEE_MODAL = "CLOSE_EDIT_EMPLOYEE_MODAL"
export const CLEAR_UPDATE_EMPLOYEE_ERROR = "CLEAR_UPDATE_EMPLOYEE_ERROR"
export const UPDATE_EMPLOYEE_PENDING = "UPDATE_EMPLOYEE_PENDING"
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS"
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR"
export const SET_EMPLOYEE_PENDING = "SET_EMPLOYEE_PENDING"
export const SET_EMPLOYEE_DETAILS_PENDING = "SET_EMPLOYEE_DETAILS_PENDING"
export const SET_EMPLOYEE_EXPORT_PENDING = "SET_EMPLOYEE_EXPORT_PENDING"
export const SET_EMPLOYEE_EXPORT_LIST = "SET_EMPLOYEE_EXPORT_LIST"
export const CLEAR_EMPLOYEE_EXPORT_LIST_ERROR = "CLEAR_EMPLOYEE_EXPORT_LIST_ERROR"
export const SET_ORG_EMPLOYEE_EXPORT_LIST_ERROR = "SET_ORG_EMPLOYEE_EXPORT_LIST_ERROR"
export const SET_EMPLOYEE_USERID_PENDING = "SET_EMPLOYEE_USERID_PENDING"
export const SET_EMPLOYEE_USERID_LIST = "SET_EMPLOYEE_USERID_LIST"
export const CLEAR_EMPLOYEE_USER_ID_LIST_ERROR = "CLEAR_EMPLOYEE_USER_ID_LIST_ERROR"
export const SET_ORG_EMPLOYEE_USER_ID_LIST_ERROR = "SET_ORG_EMPLOYEE_USER_ID_LIST_ERROR"


export const EMPLOYEE_EXPORT_HEADERS = [
    "EMPLOYEE",
    "EMPLOYEE EMAIL",
    "TYPE",
    "DESIGNATION",
    "MANAGER EMAIL",
    "DATE OF BIRTH",
    "DATE OF JOIN",
    "EMPLOYEE STATUS",
    "USER STATUS",
    "IS REHIRED",
    "IS PEOPLE MANAGER",
    "ACCOUNT STATUS",
    "EMAIL NOTIFICATION STATUS",
];