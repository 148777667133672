//--------------------OPPORTUNITY-CREATE-----------------------------------
export const OPPORTUNITY_CREATE_OPEN = "OPPORTUNITY_CREATE_OPEN";
export const OPPORTUNITY_CREATE_CLOSE = "OPPORTUNITY_CREATE_CLOSE";
export const OPPORTUNITY_CREATE_ERROR = "OPPORTUNITY_CREATE_ERROR";
export const OPPORTUNITY_CREATE_PENDING = "OPPORTUNITY_CREATE_PENDING";
export const OPPORTUNITY_CREATE_ERROR_CLEAR = "OPPORTUNITY_CREATE_ERROR_CLEAR";
export const OPPORTUNITY_CREATE_SUCCESS = "OPPORTUNITY_CREATE_SUCCESS";

//--------------------OPPORTUNITY-UPDATE-----------------------------------
export const OPPORTUNITY_UPDATE_OPEN = "OPPORTUNITY_UPDATE_OPEN";
export const OPPORTUNITY_UPDATE_CLOSE = "OPPORTUNITY_UPDATE_CLOSE";
export const OPPORTUNITY_UPDATE_PENDING = "OPPORTUNITY_UPDATE_PENDING";
export const OPPORTUNITY_UPDATE_ERROR = "OPPORTUNITY_UPDATE_ERROR";
export const OPPORTUNITY_UPDATE_SUCCESS = "OPPORTUNITY_UPDATE_SUCCESS";
export const OPPORTUNITY_UPDATE_ERROR_CLEAR = "OPPORTUNITY_UPDATE_ERROR_CLEAR";

//--------------------OPPORTUNITY-LIST-----------------------------------
export const SET_OPPORTUNITY_LIST = "SET_OPPORTUNITY_LIST";
export const OPPORTUNITY_LIST_PENDING = "OPPORTUNITY_LIST_PENDING";
export const OPPORTUNITY_LIST_ERROR = "OPPORTUNITY_LIST_ERROR";
export const OPPORTUNITY_LIST_ERROR_CLEAR = "OPPORTUNITY_LIST_ERROR_CLEAR";

//--------------------OPPORTUNITY-DETAILS-----------------------------------
export const SET_OPPORTUNITY_DETAILS = "SET_OPPORTUNITY_DETAILS";
export const OPPORTUNITY_DETAILS_PENDING = "OPPORTUNITY_DETAILS_PENDING";
export const OPPORTUNITY_DETAILS_ERROR = "OPPORTUNITY_DETAILS_ERROR";
export const OPPORTUNITY_DETAILS_ERROR_CLEAR = "OPPORTUNITY_DETAILS_ERROR_CLEAR";
export const OPPORTUNITY_DETAILS_DOWNLOAD_PENDING = "OPPORTUNITY_DETAILS_DOWNLOAD_PENDING";
export const OPPORTUNITY_DETAILS_DOWNLOAD_ERROR_CLEAR = "OPPORTUNITY_DETAILS_DOWNLOAD_ERROR_CLEAR";
export const SET_OPPORTUNITY_DETAILS_DOWNLOAD = "SET_OPPORTUNITY_DETAILS_DOWNLOAD";
export const OPPORTUNITY_DETAILS_DOWNLOAD_ERROR = "OPPORTUNITY_DETAILS_DOWNLOAD_ERROR";
export const OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_PENDING = "OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_PENDING";
export const OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR_CLEAR = "OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR_CLEAR";
export const SET_OPPORTUNITY_DETAILS_DOWNLOAD_ZIP = "SET_OPPORTUNITY_DETAILS_DOWNLOAD_ZIP";
export const OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR = "OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR";
export const OPPORTUNITY_DETAILS_DOCUMENT_DELETE_PENDING = "OPPORTUNITY_DETAILS_DOCUMENT_DELETE_PENDING";
export const OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR_CLEAR = "OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR_CLEAR";
export const OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR = "OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR";
export const OPEN_DELETE_OPPORTUNITY_DETAILS_MODAL = "OPEN_DELETE_OPPORTUNITY_DETAILS_MODAL";
export const CLOSE_DELETE_OPPORTUNITY_DETAILS_MODAL = "CLOSE_DELETE_OPPORTUNITY_DETAILS_MODAL";
export const OPPORTUNITY_EXPORT_LIST_PENDING = "OPPORTUNITY_EXPORT_LIST_PENDING";
export const OPPORTUNITY_EXPORT_LIST_ERROR_CLEAR = "OPPORTUNITY_EXPORT_LIST_ERROR_CLEAR";
export const SET_OPPORTUNITY_EXPORT_LIST = "SET_OPPORTUNITY_EXPORT_LIST";
export const OPPORTUNITY_EXPORT_LIST_ERROR = "OPPORTUNITY_EXPORT_LIST_ERROR";


export const OPPORTUNITY_EXPORT_HEADERS = [
    "ACCOUNT NAME",
    "OPPORTUNITY NAME",
    "CONTACT NAME",
    "CONTACT EMAIL",
    "OPPORTUNITY VALUE",
    "WEIGHTED VALUE",
    "CATEGORY",
    "SUB CATEGORY",
    "TOTAL DAYS",
    "PENDING DAYS",
    "TYPE NAME",
    "ACTION NAME",
    "STATUS",
    "CURRENT PHASE",
    "START DATE",
    "END DATE",
    "CREATED DATE",
    "CREATED BY"
];