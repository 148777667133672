import { IS_INLINE_EDIT } from "./constants";

const initialState = {
    isInlineEditID: null
};


export default (localState = initialState, action) => {
    switch (action.type) {
        case IS_INLINE_EDIT: {
            const { id } = action.payload;
            return {
                ...localState,
                isInlineEditID: id,
            };

        }
        default: {
            return localState;
        }
    }
};