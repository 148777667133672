import {
    OPPORTUNITY_CURRENT_PHASE_CREATE_CLOSE,
    OPPORTUNITY_CURRENT_PHASE_CREATE_ERROR,
    OPPORTUNITY_CURRENT_PHASE_CREATE_ERROR_CLEAR,
    OPPORTUNITY_CURRENT_PHASE_CREATE_OPEN,
    OPPORTUNITY_CURRENT_PHASE_CREATE_PENDING,
    OPPORTUNITY_CURRENT_PHASE_CREATE_SUCCESS,
    OPPORTUNITY_CURRENT_PHASE_LIST_ERROR,
    OPPORTUNITY_CURRENT_PHASE_LIST_ERROR_CLEAR,
    OPPORTUNITY_CURRENT_PHASE_LIST_PENDING,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_CLOSE,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_ERROR,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_OPEN,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_PENDING,
    OPPORTUNITY_CURRENT_PHASE_UPDATE_SUCCESS,
    SET_OPPORTUNITY_CURRENT_PHASE_DETAILS,
    SET_OPPORTUNITY_CURRENT_PHASE_LIST,
} from "./constants";

const initialState = {
    //--------------------Industry-CREATE-----------------------------------
    isCreateOpportunityCurrentPhaseOpen: false,
    opportunityCurrentPhaseCreateErrorMessage: null,
    isOpportunityCurrentPhaseCreateSuccess: false,
    isOpportunityCurrentPhaseCreatePending: false,
    //--------------------Industry-UPDATE-----------------------------------
    opportunityCurrentPhaseDetails: null,
    isUpdateOpportunityCurrentPhaseOpen: false,
    opportunityCurrentPhaseUpdateErrorMessage: null,
    isOpportunityCurrentPhaseUpdateSuccess: false,
    isOpportunityCurrentPhaseUpdatePending: false,
    //--------------------Industry-LIST-------------------------------------
    opportunityCurrentPhaseList: [],
    opportunityCurrentPhaseListCount: 0,
    isOpportunityCurrentPhaseListPending: false,
    opportunityCurrentPhaseListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------LEADS-CREATE-----------------------------------
        case OPPORTUNITY_CURRENT_PHASE_CREATE_OPEN: {
            return {
                ...localState,
                isCreateOpportunityCurrentPhaseOpen: true
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateOpportunityCurrentPhaseOpen: false
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityCurrentPhaseCreateErrorMessage: null,
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_CREATE_PENDING: {
            return {
                ...localState,
                isOpportunityCurrentPhaseCreatePending: true,
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_CREATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityCurrentPhaseCreateSuccess: true,
                isOpportunityCurrentPhaseCreatePending: false
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityCurrentPhaseCreateErrorMessage: errorMessage,
                isOpportunityCurrentPhaseCreatePending: false,
            }
        }

        //--------------------LEADS-UPDATE-----------------------------------
        case SET_OPPORTUNITY_CURRENT_PHASE_DETAILS: {
            const { opportunityCurrentPhaseDetails } = action.payload;
            return {
                ...localState,
                opportunityCurrentPhaseDetails: opportunityCurrentPhaseDetails
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateOpportunityCurrentPhaseOpen: true
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateOpportunityCurrentPhaseOpen: false
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityCurrentPhaseUpdateErrorMessage: null,
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_UPDATE_PENDING: {
            return {
                ...localState,
                isOpportunityCurrentPhaseUpdatePending: true,
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_UPDATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityCurrentPhaseUpdateSuccess: true,
                isOpportunityCurrentPhaseUpdatePending: false
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityCurrentPhaseUpdateErrorMessage: errorMessage,
                isOpportunityCurrentPhaseUpdatePending: false
            }
        }
        //--------------------LEADS-CurrentPhase-LIST-------------------------------------
        case SET_OPPORTUNITY_CURRENT_PHASE_LIST: {
            const { opportunityCurrentPhaseList } = action.payload;
            return {
                ...localState,
                opportunityCurrentPhaseList: opportunityCurrentPhaseList && opportunityCurrentPhaseList['value'],
                opportunityCurrentPhaseListCount: opportunityCurrentPhaseList && opportunityCurrentPhaseList['@odata.count'],
                isOpportunityCurrentPhaseListPending: false,
                opportunityCurrentPhaseListErrorMessage: null,
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityCurrentPhaseListErrorMessage: errorMessage,
                isOpportunityCurrentPhaseListPending: false,
                opportunityCurrentPhaseList: []
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityCurrentPhaseListErrorMessage: null,
            }
        }
        case OPPORTUNITY_CURRENT_PHASE_LIST_PENDING: {
            return {
                ...localState,
                isOpportunityCurrentPhaseListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};