import { SET_TASK_PERCENTAGE_DETAILS, SET_TASK_PERCENTAGE_STATUS_LIST, TASK_PERCENTAGE_STATUS_LIST_ERROR, TASK_PERCENTAGE_STATUS_LIST_ERROR_CLEAR, TASK_PERCENTAGE_STATUS_LIST_PENDING, TASK_PERCENTAGE_UPDATE_CLOSE, TASK_PERCENTAGE_UPDATE_ERROR, TASK_PERCENTAGE_UPDATE_ERROR_CLEAR, TASK_PERCENTAGE_UPDATE_OPEN, TASK_PERCENTAGE_UPDATE_PENDING, TASK_PERCENTAGE_UPDATE_SUCCESS } from "./constants";

const initialState = {
  
    //--------------------TASK PERCENTAGE STATUS-LIST-------------------------------------
    taskPercentageStatusList: [],
    taskPercentageStatusListCount: 0,
    isTaskpercentageStatusListPending: false,
    taskPercentageStatusListErrorMessage: null,
    //--------------------SCOPE-UPDATE-----------------------------------
    taskPercentageDetails: null,
    isUpdateTaskPercentageOpen: false,
    taskPercentageUpdateErrorMessage: null,
    isTaskPercentageUpdateSuccess: false,
    isTaskPercentageUpdatePending: false,
};

export default (localState = initialState, action) => {
    switch (action.type) {

        //--------------------TASK SCOPE-UPDATE-----------------------------------
        case SET_TASK_PERCENTAGE_DETAILS: {
            const { percentageDetails } = action.payload;
            console.log("percentageDetails:", percentageDetails);

            return {
                ...localState,
                taskPercentageDetails: percentageDetails
            }
        }
        case TASK_PERCENTAGE_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateTaskPercentageOpen: true
            }
        }
        case TASK_PERCENTAGE_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateTaskPercentageOpen: false
            }
        }
        case TASK_PERCENTAGE_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                taskPercentageUpdateErrorMessage: null,
            }
        }
        case TASK_PERCENTAGE_UPDATE_PENDING: {
            return {
                ...localState,
                isTaskPercentageUpdatePending: true,
            }
        }
        case TASK_PERCENTAGE_UPDATE_SUCCESS: {
            return {
                ...localState,
                isTaskPercentageUpdateSuccess: true,
                isTaskPercentageUpdatePending: false
            }
        }
        case TASK_PERCENTAGE_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                taskPercentageUpdateErrorMessage: errorMessage,
                isTaskPercentageUpdatePending: false
            }
        }

        //--------------------TASK PERCENTAGE STATUS-LIST-------------------------------------
        case SET_TASK_PERCENTAGE_STATUS_LIST: {
            const { taskPercentageList } = action.payload;
            return {
                ...localState,
                taskPercentageStatusList: taskPercentageList && taskPercentageList['value'], 
                taskPercentageStatusListCount: taskPercentageList && taskPercentageList['@odata.count'],
                isTaskpercentageStatusListPending: false,
                taskPercentageStatusListErrorMessage: null,
            }
        }
        case TASK_PERCENTAGE_STATUS_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                taskPercentageStatusListErrorMessage: errorMessage,
                isTaskpercentageStatusListPending: false,
                taskPercentageStatusList: []
            }
        }
        case TASK_PERCENTAGE_STATUS_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                taskPercentageStatusListErrorMessage: null,
            }
        }
        case TASK_PERCENTAGE_STATUS_LIST_PENDING: {
            return {
                ...localState,
                isTaskpercentageStatusListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};