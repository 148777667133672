import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWButton from '../../../../components/vwButton/VWButton';
import { clearCreateTimeSheetError, clearCreateTimeSheetPending, updateTimeSheetActionList } from '../../../../store/Modules/Timesheet/myTeamTimesheet/actions';
import { clearForm } from '../../../../store/form/actions';
import { clearSetPath } from '../../../../store/actions';
import { createTimeSheetSuccess } from '../../../../store/Modules/Timesheet/myTimesheet/actions';

const MyTeamTimeSheetAction = (props) => {
  const [timeSheetAction, setTimeSheet] = useState({});

  const { statusUpdate } = props;

  useEffect(() => {
    const { data } = props;

    setTimeSheet(data);
    props.clearCreateTimeSheetError();
    props.ClearPath();
    props.createTimeSheetSuccess();
    props.clearCreateTimeSheetPending();
  }, [])

  const validateOnChange = (event) => {
    const { name, value } = event.target;

    setTimeSheet({
      ...timeSheetAction,
      [name]: value
    })
  }

  const handleCreateActionTimeSheet = () => {
    props.updateTimeSheetAction(timeSheetAction, statusUpdate)
      .then(() => {
        props.handleStatusSelect([])
      })
  };

  console.log("errorMessage:", props.errorMessage);
  console.log('statusUpdate:', statusUpdate);
  
  return (
    <>
      {props.errorMessage && (
        <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
      )}
      <form className="mb-3" name="formAddVechicle">
        <div className="form-group">
          <Label className="col-form-label">Reason
          </Label>
          <Input
            style={{ height: '100px' }}
            type="textarea"
            name="reason"
            placeholder="Enter Reason"
            onChange={validateOnChange}
            value={timeSheetAction.reason}
          />
        </div>
      </form>
      <div className="form-group">
        <FormGroup>
          <Button
            className="me-2"
            color="success"
            onClick={handleCreateActionTimeSheet}
            disabled={props.isPending}
          >
            {props.isPending ? <>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              &nbsp; Submitting...
            </>
              : <>Submit</>}
          </Button>
          <Button
            color="danger"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
        </FormGroup>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  form: state.form,
  isPending: state.MyTeamTimeSheet.isPending,
  errorMessage: state.MyTeamTimeSheet.updateMyTeamTimeSheetErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  clearFormAction: () => dispatch(clearForm()),
  clearCreateTimeSheetError: () => dispatch(clearCreateTimeSheetError()),
  clearCreateTimeSheetPending: () => dispatch(clearCreateTimeSheetPending()),
  updateTimeSheetAction: (timeSheetAction, statusUpdate) => dispatch(updateTimeSheetActionList(timeSheetAction, statusUpdate)),
  ClearPath: () => dispatch(clearSetPath()),
  createTimeSheetSuccess: () => dispatch(createTimeSheetSuccess()),
});
export default connect(mapStateToProps, mapDispatchToProps)(MyTeamTimeSheetAction);
