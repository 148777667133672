import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import Async from 'react-select/async';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import {
    UpdateMilestone,
    getMilestoneOwnerAsyncSelect,
    getProjectDate,
    getProjectNameAsyncSelect,
    updateMilestoneSuccess
} from '../../../../store/Modules/ProjectPortal/Milestones/actions';
import { STATUS_OPTIONS } from '../../../../store/Modules/ProjectPortal/Milestones/constants';
import Select from 'react-select';

const UpdateMilestones = (props) => {
    const [formFields, setFormFields] = useState({});
    const [existingProjectUser, setExistingProjectUser] = useState([]);
    const [prevMilestoneOwnerUser, setPrevMilestoneOwnerUser] = useState([]);
    const [prevProjectName, setPrevProjectName] = useState([]);
    const [errors, setErrors] = useState({});

    const [projectStartDate, setProjectStartDate] = useState(null);
    const [projectEndDate, setProjectEndDate] = useState(null);

    const [keyChange, setKeyChange] = useState(1);
    const [projectId, setProjectId] = useState();


    useEffect(() => {
        props.updateMilestoneSuccess();
        if (props.milestonesDetails) {
            props.getProjectDate(props.milestonesDetails && props.milestonesDetails.projectId);
            setFormFields(props.milestonesDetails);
            setPrevMilestoneOwnerUser({ label: `${props.milestonesDetails && props.milestonesDetails.mileStoneOwnerUserName || 'N/A'} | ${props.milestonesDetails && props.milestonesDetails.mileStoneOwnerUserEmail || 'N/A'}`, value: props.milestonesDetails && props.milestonesDetails.mileStoneOwnerUserId });
            setPrevProjectName({ label: props.milestonesDetails && props.milestonesDetails.projectName, value: props.milestonesDetails && props.milestonesDetails.projectId });
            setProjectId(props.milestonesDetails && props.milestonesDetails.projectId);
            setKeyChange(keyChange + 1)
        }
    }, [])

    //------------------------------------------Update Input field------------------------------------------------------
    const maxLength = 50;
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'milestoneName') {
            if (value && value.length >= maxLength) {
                setErrors({ ...errors, milestoneName: `MileStone name cannot exceed ${maxLength} characters.` });
            } else {
                setErrors({ ...errors, milestoneName: '' });
            }
        }
    };
    //------------------------------------------Update Date Field-----------------------------------------------------
    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;
        // const formattedDate = value ? moment(value).format() : null;
        const formattedDate = value;
        setFormFields({ ...formFields, [name]: formattedDate });
        setErrors({ ...errors, [name]: '' });
    };
    const handleMilestone = (inputValue, callback) => {
        const { getMilestoneOwnerAsyncSelectAction } = props;
        if (projectId) {
            return getMilestoneOwnerAsyncSelectAction(projectId, inputValue.length > 0 ? inputValue : '', callback);
        }
        else {
            callback([]);
        }
    };
    //----------------------------------------Update Status Field --------------------------------------------------------
    const handleMilestoneStatus = (Value) => {
        const { value } = Value || {};
        setFormFields({ ...formFields, status: value });
        setErrors({ ...errors, status: '' });
    }
    //-------------------------------------------Default Options------------------------------------------------------
    const handleProject = (inputValue, callback) => {
        const { getProjectNameAsyncSelectAction } = props;
        return getProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    //-----------------------------------------Update Project name------------------------------------------------------
    const handleAddProjectName = (value) => {
        setKeyChange(keyChange + 1);
        setFormFields({ ...formFields, projectId: value.value, mileStoneOwnerUserId: '', allowFlexibleDueDates: value.AllowFlexibleDueDates });
        setPrevMilestoneOwnerUser({ label: 'Select milestone owner', value: '' })
        setPrevProjectName(value)
        if (value.value) {
            props.getMilestoneOwnerAsyncSelectAction(value.value, '', (existingProjectUsers) => {
                setExistingProjectUser(existingProjectUsers);
            });
            props.getProjectDate(value.value);
        }
        setProjectId(value.value);
        setErrors({ ...errors, projectId: '' });
    }
    //------------------------------------------Update Milestone Owner---------------------------------------------------
    const handleAddMilestoneOwner = (value) => {
        setFormFields({ ...formFields, mileStoneOwnerUserId: value.value });
        setPrevMilestoneOwnerUser(value);
        setErrors({ ...errors, mileStoneOwnerUserId: '' });
    }
    //---------------------------------------Update Milestone-------------------------------------------------------------
    const handleUpdateMilestones = () => {
        const startDate = moment(formFields.startDate);
        const endDate = moment(formFields.endDate);
        const projectStart = moment(projectStartDate);
        const projectEnd = moment(projectEndDate);

        const newErrors = {};
        if (!formFields.projectId || !formFields.projectId.trim()) {
            newErrors.projectId = 'Project name is required';
        }
        if (!formFields.milestoneName || !formFields.milestoneName.trim()) {
            newErrors.milestoneName = 'Milestone name is required';
        }
        if (formFields.milestoneName && formFields.milestoneName.length >= maxLength) {
            newErrors.milestoneName = `MileStone name cannot exceed ${maxLength} characters.`;
        }
        if (!formFields.mileStoneOwnerUserId || !formFields.mileStoneOwnerUserId.trim()) {
            newErrors.mileStoneOwnerUserId = 'Milestone owner name is required';
        }
        if (!formFields.startDate || !formFields.startDate.trim()) {
            newErrors.startDate = 'Start date is required';
        }
        if (!formFields.endDate || !formFields.endDate.trim()) {
            newErrors.endDate = 'End date is required';
        }
        if (!formFields.status || !formFields.status.trim()) {
            newErrors.status = 'Milestone status is required';
        }
        if (formFields && !formFields.allowFlexibleDueDates) {
        if (startDate.isBefore(projectStart) || startDate.isAfter(projectEnd)) {
            newErrors.startDate = `Start date must be between ${projectStart.format('DD/MM/YYYY')} and ${projectEnd.format('DD/MM/YYYY')}`;
        }
        if (endDate.isBefore(startDate) || endDate.isAfter(projectEnd)) {
            newErrors.endDate = `End date must be after start date and before ${projectEnd.format('DD/MM/YYYY')}`;
        }
    }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.UpdateMilestonesAction(formFields);
        }
    }
    useEffect(() => {
        if (props.MilestoneProjectDates) {
            setProjectStartDate(props.MilestoneProjectDates && props.MilestoneProjectDates.startDate);
            setProjectEndDate(props.MilestoneProjectDates && props.MilestoneProjectDates.endDate);
        }
    }, [props.MilestoneProjectDates]);

    const startDateMax = formFields.endDate ? moment(formFields.endDate).format('YYYY-MM-DD') : projectEndDate ? moment(projectEndDate).format('YYYY-MM-DD') : '';
    const endDateMin = formFields.startDate ? moment(formFields.startDate).format('YYYY-MM-DD') : projectStartDate ? moment(projectStartDate).format('YYYY-MM-DD') : '';

    console.log("MilestoneProjectDates:", props.MilestoneProjectDates);
    console.log("formFields:", formFields);
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <FormGroup>
                <Label>
                    Project Name<span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.projectId) ? 'red' : "silver"
                        })
                    }}
                    name="projectId"
                    placeholder="Select project name"
                    isSearchable
                    defaultOptions
                    loadOptions={handleProject}
                    value={prevProjectName}
                    onChange={handleAddProjectName}
                    isDisabled
                />
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.projectId}
                    </div>
                </FormText>
            </FormGroup>
            <FormGroup>
                <Label>
                    Milestone Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="milestoneName"
                    placeholder="Enter milestone name"
                    onChange={validateOnChange}
                    value={formFields && formFields.milestoneName}
                    invalid={errors && errors.milestoneName}
                    maxLength={maxLength}
                />
                <FormFeedback>{errors && errors.milestoneName}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label>
                    Milestone Owner <span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.mileStoneOwnerUserId) ? 'red' : "silver"
                        })
                    }}
                    key={keyChange}
                    name="mileStoneOwnerUserId"
                    placeholder="Select milestone owner"
                    isSearchable
                    defaultOptions
                    loadOptions={handleMilestone}
                    onChange={handleAddMilestoneOwner}
                    value={prevMilestoneOwnerUser}
                    isDisabled={formFields && !formFields.isUpdateMilestoneOwner}
                />
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.mileStoneOwnerUserId}
                    </div>
                </FormText>
            </FormGroup>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>Start Date <span className="text-danger"> *</span></Label>
                        {formFields && formFields.allowFlexibleDueDates ?
                            <Input
                                type="date"
                                name="startDate"
                                placeholder="Select start date"
                                onChange={validateOnChangeDate}
                                value={formFields.startDate ? moment(formFields.startDate).format("YYYY-MM-DD") : ""}
                                max={moment(formFields.endDate).format("YYYY-MM-DD")}
                                invalid={errors && errors.startDate}
                            />
                            :
                            <Input
                                type="date"
                                name="startDate"
                                placeholder="Select start date"
                                onChange={validateOnChangeDate}
                                value={formFields.startDate ? moment(formFields.startDate).format("YYYY-MM-DD") : ""}
                                min={projectStartDate ? moment(projectStartDate).format('YYYY-MM-DD') : ''}
                                max={startDateMax}
                                invalid={errors && errors.startDate}
                            />
                        }
                        <FormFeedback>{errors && errors.startDate}</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>End Date <span className="text-danger"> *</span></Label>
                        {formFields && formFields.allowFlexibleDueDates ?
                            <Input
                                type="date"
                                name="endDate"
                                placeholder="Select end date"
                                onChange={validateOnChangeDate}
                                value={formFields.endDate ? moment(formFields.endDate).format("YYYY-MM-DD") : ""}
                                min={moment(formFields.startDate).format("YYYY-MM-DD")}
                                invalid={errors && errors.endDate}
                            />
                            :
                            <Input
                                type="date"
                                name="endDate"
                                placeholder="Select end date"
                                onChange={validateOnChangeDate}
                                value={formFields.endDate ? moment(formFields.endDate).format("YYYY-MM-DD") : ""}
                                min={endDateMin}
                                max={projectEndDate ? moment(projectEndDate).format('YYYY-MM-DD') : ''}
                                invalid={errors && errors.endDate}
                            />
                        }
                        <FormFeedback>{errors && errors.endDate}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label>Milestone Status <span className="text-danger"> *</span></Label>
                <Select
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.status) ? 'red' : "silver"
                        })
                    }}
                    name="status"
                    placeholder="Select milestone status"
                    isSearchable
                    defaultOptions
                    options={STATUS_OPTIONS}
                    onChange={handleMilestoneStatus}
                    value={formFields && formFields.status ? { label: formFields && formFields.status, value: formFields && formFields.status } : 'Select milestone status'}
                />
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.status}
                    </div>
                </FormText>
            </FormGroup>
            <FormGroup>
                <Label>
                    Milestone Description
                </Label>
                <Input
                    type="textarea"
                    name="description"
                    placeholder="Enter mileStone description"
                    onChange={validateOnChange}
                    value={formFields && formFields.description}
                />
            </FormGroup>
            <br></br>
            <FormGroup>
                <Button
                    className="me-2"
                    color="success"
                    onClick={handleUpdateMilestones}
                    disabled={props.isPending}
                >
                    {props.isPending ? <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        &nbsp; Submitting...
                    </>
                        : <>Submit</>}
                </Button>
                <Button
                    color="danger"
                    onClick={props.handleClose}
                >
                    Cancel
                </Button>
            </FormGroup>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.Milestones.isUpdateMilestonePending,
    errorMessage: state.Milestones.updateMilestoneErrorMessage,
    milestonesDetails: state.Milestones.MilestoneDetails,
    MilestoneProjectDates: state.Milestones.MilestoneProjectDates,
    milestoneProjectDatesError: state.Milestones.milestoneProjectDatesError,
    isMilestoneProjectDatesPending: state.Milestones.isMilestoneProjectDatesPending,
});
const mapDispatchToProps = (dispatch) => ({
    getProjectNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getProjectNameAsyncSelect(searchValue, callback)),
    getMilestoneOwnerAsyncSelectAction: (projectId, searchValue, callback) =>
        dispatch(getMilestoneOwnerAsyncSelect(projectId, searchValue, callback)),
    UpdateMilestonesAction: (formFields) => dispatch(UpdateMilestone(formFields)),
    updateMilestoneSuccess: () => dispatch(updateMilestoneSuccess()),
    getProjectDate: (id) => dispatch(getProjectDate(id)),

});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateMilestones)