//--------------------ACCOUNTS-CREATE-----------------------------------
export const FOLLOW_UP_ACTION_CREATE_OPEN = "FOLLOW_UP_ACTION_CREATE_OPEN";
export const FOLLOW_UP_ACTION_CREATE_CLOSE = "FOLLOW_UP_ACTION_CREATE_CLOSE";
export const FOLLOW_UP_ACTION_CREATE_PENDING = "FOLLOW_UP_ACTION_CREATE_PENDING";
export const FOLLOW_UP_ACTION_CREATE_ERROR = "FOLLOW_UP_ACTION_CREATE_ERROR";
export const FOLLOW_UP_ACTION_CREATE_ERROR_CLEAR = "FOLLOW_UP_ACTION_CREATE_ERROR_CLEAR";
export const FOLLOW_UP_ACTION_CREATE_SUCCESS = "FOLLOW_UP_ACTION_CREATE_SUCCESS";

//--------------------ACCOUNTS-UPDATE-----------------------------------
export const FOLLOW_UP_ACTION_UPDATE_OPEN = "FOLLOW_UP_ACTION_UPDATE_OPEN";
export const FOLLOW_UP_ACTION_UPDATE_CLOSE = "FOLLOW_UP_ACTION_UPDATE_CLOSE";
export const FOLLOW_UP_ACTION_UPDATE_PENDING = "FOLLOW_UP_ACTION_UPDATE_PENDING";
export const FOLLOW_UP_ACTION_UPDATE_ERROR = "FOLLOW_UP_ACTION_UPDATE_ERROR";
export const FOLLOW_UP_ACTION_UPDATE_SUCCESS = "FOLLOW_UP_ACTION_UPDATE_SUCCESS";
export const FOLLOW_UP_ACTION_UPDATE_ERROR_CLEAR = "FOLLOW_UP_ACTION_UPDATE_ERROR_CLEAR";
export const SET_FOLLOW_UP_ACTION_DETAILS = "SET_FOLLOW_UP_ACTION_DETAILS";

//--------------------ACCOUNTS-LIST-----------------------------------
export const SET_FOLLOW_UP_ACTION_LIST = "SET_FOLLOW_UP_ACTION_LIST";
export const FOLLOW_UP_ACTION_LIST_PENDING = "ACCOUNTS_CATEGORY_LIST_PENDING";
export const FOLLOW_UP_ACTION_LIST_ERROR = "ACCOUNTS_CATEGORY_LIST_ERROR";
export const FOLLOW_UP_ACTION_LIST_ERROR_CLEAR = "ACCOUNTS_CATEGORY_LIST_ERROR_CLEAR";

//--------------------ACCOUNTS-DETAILS-----------------------------------
export const SET_ACCOUNTS_DETAILS = "SET_ACCOUNTS_DETAILS";
export const ACCOUNTS_DETAILS_PENDING = "ACCOUNTS_DETAILS_PENDING";
export const ACCOUNTS_DETAILS_ERROR = "ACCOUNTS_DETAILS_ERROR";
export const ACCOUNTS_DETAILS_ERROR_CLEAR = "ACCOUNTS_DETAILS_ERROR_CLEAR";
