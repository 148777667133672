import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import VWButton from '../../../../components/vwButton/VWButton'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import { deleteHoliday } from '../../../../store/Modules/LeaveTracker/HolidayMaster/actions'
import { Button, FormGroup } from 'reactstrap'

const HolidayDelete = (props) => {
    const [holidayDeleteAction, setHolidayDelete] = useState({});
    const { filteredData }=props;
    useEffect(() => {
        const { data } = props;

        setHolidayDelete(data)
    }, [])

    const handleDeleteActionHoliday = () => {
        props.deleteHoliday(holidayDeleteAction, filteredData);
    };

    console.log("holidayDeleteAction:", holidayDeleteAction);
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <h5>Are you sure you want to delete ?</h5>
            <br></br>
            {/* <VWButton
                messageKey="deleteHolidayActionButton"
                defaultMessage="  Delete  "
                buttonType="success"
                baseClassName="btn"
                className="me-2"
                onClick={handleDeleteActionHoliday}
            // isLoading={props.isPending}
            isDisabled={props.isPending}
            ></VWButton>
            <VWButton
                messageKey="cancelMyHolidayButton"
                defaultMessage="Cancel"
                buttonType="danger"
                baseClassName="btn"
                onClick={props.handleClose}
            // isDisabled={props.isPending}
            ></VWButton> */}
            <div className="form-group">
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleDeleteActionHoliday}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    errorMessage: state.holidayMaster.errorMessage,
    isPending: state.holidayMaster.isDeleteHolidayMasterPending,

});
const mapDispatchToProps = (dispatch) => ({
    deleteHoliday: (holidayDeleteAction, filteredData) => dispatch(deleteHoliday(holidayDeleteAction, filteredData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HolidayDelete)