import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const UITooltip = (props) => {
  const [_id, setId] = useState(`id4tooltip_${props.id}`);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return [
    <Tooltip
      {...props}
      isOpen={tooltipOpen}
      toggle={toggle}
      target={_id}
      key={props.id}
    >
      {props.content}
    </Tooltip>,
    React.cloneElement(React.Children.only(props.children), {
      id: _id,
      key: `${props.id_2}`,
    }),
  ];
};

export default UITooltip;
