const EmployeeSidebarData = [
    {
        label: "Employee Management",
        isMainMenu: true,
    },
    {
        label: "Employee",
        icon: "mdi mdi-account-hard-hat",
        isHasArrow: true,
        url: "/employees/employee",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/employees/documents",
    },

]
export default EmployeeSidebarData;