import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import moment from "moment";
import { Link, useParams } from 'react-router-dom';
import { closeEditLeaveTypeModal, getleavetypeDetailsAction, openEditLeaveTypeModal } from '../../../../store/Modules/LeaveTracker/LeaveType/actions';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import VWModel from '../../../../components/Modal/VWModal';
import EditLeaveType from './leaveTypeUpdate';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';

const LeaveTypeDetails = (props) => {
    const params = useParams();
    const { id } = params;

    useEffect(() => {
        props.getleavetypeDetails(id);
        props.ClearPath();
    }, [props.getleavetypeDetails, id]);

    useEffect(() => {
        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();
        }
    }, []);

    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditleaveTypeModel = () => {
        const { openEditLeaveTypeModalAction } = props;
        openEditLeaveTypeModalAction();
    };

    const handleCloseEditLeaveTypeModal = () => {
        const { closeEditLeaveTypeModalAction } = props;
        closeEditLeaveTypeModalAction();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        LEAVE TYPE DETAILS
                        {props.errorMessage ? "" :
                            <Link type="button"
                                className="btn btn-outline-secondary"
                                onClick={handleOpenEditleaveTypeModel}
                            >
                                EDIT
                            </Link>}
                    </CardTitle>
                    <CardBody className="p-4">
                        {props.errorMessage ? <div className='w-100'>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        {props.isLeaveTypeDetailsPending ? <div className="d-flex justify-content-center">
                            <span
                                className="spinner-border spinner-border me-3"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <div className="mt-2">Loading...</div>
                        </div>
                            :
                            <Row className="justify-content-center">
                                <Col xl="6">
                                    <div className="card b">
                                        <div className="card-header">
                                            <h4 className="my-2">
                                                <span className='text-capitalize'>{props.LeaveTypeDetails && props.LeaveTypeDetails.orgName || "NA"}</span>
                                            </h4>
                                        </div>
                                        <div className="card-body bt">
                                            <div className="row pl-4">
                                                <div className="col-lg-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-striped table-hover">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <strong>Org Name</strong>
                                                                    </td>
                                                                    <td>{props.LeaveTypeDetails && props.LeaveTypeDetails.orgName || "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <strong>Leave Type</strong>
                                                                    </td>
                                                                    <td>{props.LeaveTypeDetails && props.LeaveTypeDetails.leaveType || "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <strong>Description</strong>
                                                                    </td>
                                                                    <td>{props.LeaveTypeDetails && props.LeaveTypeDetails.description || "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <strong>Created By</strong>
                                                                    </td>
                                                                    <td>{props.LeaveTypeDetails && props.LeaveTypeDetails.createdBy || "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <strong>Created Date</strong>
                                                                    </td>
                                                                    <td>{props.LeaveTypeDetails && props.LeaveTypeDetails.createdDateTime ? moment(props.LeaveTypeDetails && props.LeaveTypeDetails.createdDateTime).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                    <VWModel
                        header="EDIT LEAVE TYPE"
                        isOpen={props.isEditLeaveModalOpen}
                        handleClose={handleCloseEditLeaveTypeModal}
                    >
                        <EditLeaveType handleClose={handleCloseEditLeaveTypeModal} />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment>

    )
}
const mapStateToProps = (state) => ({
    LeaveTypeDetails: state.leaveType.LeaveTypeDetails,
    errorMessage: state.leaveType.LeaveTypeDetailsError,
    isEditLeaveModalOpen: state.leaveType.isLeavetypeEditModalOpen,
    isLeaveTypeDetailsPending: state.leaveType.isLeaveTypeDetailsPending
});

const mapDispatchToProps = (dispatch) => ({
    // getleavetypeDetails: (Id) => dispatch(getleavetypeDetailsAction(Id)),
    openEditLeaveTypeModalAction: () => dispatch(openEditLeaveTypeModal()),
    closeEditLeaveTypeModalAction: () => dispatch(closeEditLeaveTypeModal()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeaveTypeDetails)