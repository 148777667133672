import { ALL_TIMESHEET_EXPORT_LIST_ERROR, ALL_TIMESHEET_EXPORT_LIST_PENDING, ALL_TIMESHEET_LIST_ERROR, ALL_TIMESHEET_LIST_PENDING, CLEAR_ALL_TIMESHEET_EXPORT_LIST_ERROR, CLEAR_ALL_TIMESHEET_LIST_ERROR, SET_ALL_TIMESHEET_EXPORT_LIST, SET_ALL_TIMESHEET_LIST } from "./constants";

const initialState = {
    AllTimeSheetList: [],
    totalRecords: 0,
    isAllTimesheetPending: false,
    allTimesheetErrorMessage: null,
    allTimesheetExportErrorMessage: null,
    isAllTimesheetExportPending: false,
    AllTimeSheetExportList:[],
};
export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_ALL_TIMESHEET_LIST: {
            const { AllTimeSheet } = action.payload;
            return {
                ...localState,
                isAllTimesheetPending: false,
                AllTimeSheetList: AllTimeSheet && AllTimeSheet.value,
                totalRecords: AllTimeSheet["@odata.count"],
            }
        }
        case SET_ALL_TIMESHEET_EXPORT_LIST: {
            const { AllTimeSheet } = action.payload;
            return {
                ...localState,
                isAllTimesheetExportPending: false,
                AllTimeSheetExportList: AllTimeSheet,
            }
        }
        case ALL_TIMESHEET_LIST_ERROR: {
            const { allTimesheetErrorMessage } = action.payload;
            return {
                ...localState,
                isAllTimesheetPending: false,
                AllTimeSheetList: [],
                allTimesheetErrorMessage: allTimesheetErrorMessage,
            };
        }
        case ALL_TIMESHEET_EXPORT_LIST_ERROR: {
            const { allTimesheetErrorMessage } = action.payload;
            return {
                ...localState,
                isAllTimesheetExportPending: false,
                AllTimeSheetExportList: [],
                allTimesheetExportErrorMessage: allTimesheetErrorMessage,
            };
        }
        case CLEAR_ALL_TIMESHEET_LIST_ERROR: {
            return {
                ...localState,
                allTimesheetErrorMessage: null,
            };
        }
        case CLEAR_ALL_TIMESHEET_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                allTimesheetExportErrorMessage: null,
            };
        }

        case ALL_TIMESHEET_LIST_PENDING: {
            return {
                ...localState,
                allTimesheetErrorMessage: null,
                isAllTimesheetPending: true
            }
        }
        case ALL_TIMESHEET_EXPORT_LIST_PENDING: {
            return {
                ...localState,
                allTimesheetExportErrorMessage: null,
                isAllTimesheetExportPending: true
            }
        }
        default: {
            return localState;
        }
    }
};