import {
    LEAD_SOURCE_CREATE_CLOSE,
    LEAD_SOURCE_CREATE_ERROR,
    LEAD_SOURCE_CREATE_ERROR_CLEAR,
    LEAD_SOURCE_CREATE_OPEN,
    LEAD_SOURCE_CREATE_PENDING,
    LEAD_SOURCE_CREATE_SUCCESS,
    LEAD_SOURCE_LIST_ERROR,
    LEAD_SOURCE_LIST_ERROR_CLEAR,
    LEAD_SOURCE_LIST_PENDING,
    LEAD_SOURCE_UPDATE_CLOSE,
    LEAD_SOURCE_UPDATE_ERROR,
    LEAD_SOURCE_UPDATE_ERROR_CLEAR,
    LEAD_SOURCE_UPDATE_OPEN,
    LEAD_SOURCE_UPDATE_PENDING,
    LEAD_SOURCE_UPDATE_SUCCESS,
    SET_LEAD_SOURCE_DETAILS,
    SET_LEAD_SOURCE_LIST,
} from "./constants";

const initialState = {
    //--------------------ACCOUNT-CATEGORY-CREATE-----------------------------------
    isCreateLeadSourceOpen: false,
    leadSourceCreateErrorMessage: null,
    isLeadSourceCreateSuccess: false,
    isLeadSourceCreatePending: false,
    //--------------------ACCOUNT-CATEGORY-UPDATE-----------------------------------
    leadSourceDetails: null,
    isUpdateLeadSourceOpen: false,
    leadSourceUpdateErrorMessage: null,
    isLeadSourceUpdateSuccess: false,
    isLeadSourceUpdatePending: false,
    //--------------------ACCOUNT-CATEGORY-LIST-------------------------------------
    leadSourceList: [],
    leadSourceListCount: 0,
    isLeadSourceListPending: false,
    leadSourceListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------ACCOUNT-CATEGORY-CREATE-----------------------------------
        case LEAD_SOURCE_CREATE_OPEN: {
            return {
                ...localState,
                isCreateLeadSourceOpen: true
            }
        }
        case LEAD_SOURCE_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateLeadSourceOpen: false
            }
        }
        case LEAD_SOURCE_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                leadSourceCreateErrorMessage: null,
            }
        }
        case LEAD_SOURCE_CREATE_PENDING: {
            return {
                ...localState,
                isLeadSourceCreatePending: true,
            }
        }
        case LEAD_SOURCE_CREATE_SUCCESS: {
            return {
                ...localState,
                isLeadSourceCreateSuccess: true,
                isLeadSourceCreatePending: false
            }
        }
        case LEAD_SOURCE_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadSourceCreateErrorMessage: errorMessage,
                isLeadSourceCreatePending: false,
            }
        }

        //--------------------ACCOUNT-CATEGORY-UPDATE-----------------------------------
        case SET_LEAD_SOURCE_DETAILS: {
            const { leadSourceDetails } = action.payload;
            return {
                ...localState,
                leadSourceDetails: leadSourceDetails
            }
        }
        case LEAD_SOURCE_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateLeadSourceOpen: true
            }
        }
        case LEAD_SOURCE_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateLeadSourceOpen: false
            }
        }
        case LEAD_SOURCE_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                leadSourceUpdateErrorMessage: null,
            }
        }
        case LEAD_SOURCE_UPDATE_PENDING: {
            return {
                ...localState,
                isLeadSourceUpdatePending: true,
            }
        }
        case LEAD_SOURCE_UPDATE_SUCCESS: {
            return {
                ...localState,
                isLeadSourceUpdateSuccess: true,
                isLeadSourceUpdatePending: false
            }
        }
        case LEAD_SOURCE_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadSourceUpdateErrorMessage: errorMessage,
                isLeadSourceUpdatePending: false
            }
        }
        //--------------------ACCOUNT-CATEGORY-CATEGORY-LIST-------------------------------------
        case SET_LEAD_SOURCE_LIST: {
            const { leadSourceList } = action.payload;
            return {
                ...localState,
                leadSourceList: leadSourceList && leadSourceList['value'],
                leadSourceListCount: leadSourceList && leadSourceList['@odata.count'],
                isLeadSourceListPending: false,
                leadSourceListErrorMessage: null,
            }
        }
        case LEAD_SOURCE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadSourceListErrorMessage: errorMessage,
                isLeadSourceListPending: false,
                leadSourceList: []
            }
        }
        case LEAD_SOURCE_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                leadSourceListErrorMessage: null,
            }
        }
        case LEAD_SOURCE_LIST_PENDING: {
            return {
                ...localState,
                isLeadSourceListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};