import moment from "moment-timezone";
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, Table } from "reactstrap";
import { closeActionDBSyncDeleteModalAction, closeCreateDBModalAction, getDBSyncAction, openActionDBSyncDeleteModalAction, openCreateDBModalAction } from "../../../store/Modules/DB/actions";
import { connect } from "react-redux";
import VWPagination from "../../../components/vwPagination/vwPagination";
import { getPager } from "../../../helper/common";
import React, { useEffect, useState } from "react";
import { clearForm } from "../../../store/form/actions";
import { Link } from "react-router-dom";
import NoRecord from "../../../components/NoRecords/noRecords";
import VWModel from "../../../components/Modal/VWModal";
import CreateDB from "./CreateDB";
import VWMessage from "../../../components/vwMessage/vwMessage";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteDB from "./DeleteDB";

const DBSync = (props) => {

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState({});

    const buildQueryDbName = () => {
        const { DbName } = filter;
        let searchDbName = '';
        if (DbName) {
            searchDbName += `${DbName}`;
        }
        return searchDbName
    }
    const buildQueryServerName = () => {
        const { ServerName } = filter;
        let searchServerName = '';
        if (ServerName) {
            searchServerName += `${ServerName}`;
        }
        return searchServerName
    }
    const buildQueryNoOfTables = () => {
        const { NoOfTables } = filter;
        let searchNoOfTables = '';
        if (NoOfTables) {
            searchNoOfTables += `${NoOfTables}`;
        }
        return searchNoOfTables
    }
    const buildQueryNoOfRows = () => {
        const { NoOfRows } = filter;
        let searchNoOfRows = '';
        if (NoOfRows) {
            searchNoOfRows += `${NoOfRows}`;
        }
        return searchNoOfRows
    }
    const buildQueryMissingSync = () => {
        const { MissingSync } = filter;
        let searchMissingSync = '';
        if (MissingSync) {
            searchMissingSync += `${MissingSync}`;
        }
        return searchMissingSync
    }
    const buildQueryNoOfSync = () => {
        const { NoOfSync } = filter;
        let searchNoOfSync = '';
        if (NoOfSync) {
            searchNoOfSync += `${NoOfSync}`;
        }
        return searchNoOfSync
    }
    const buildQueryLastSyncedTime = () => {
        const { LastSyncedTime } = filter;
        let searchLastSyncedTime = '';
        if (LastSyncedTime) {
            searchLastSyncedTime += `${LastSyncedTime}`;
        }
        return searchLastSyncedTime
    }

    const setPage = (page = 1) => {
        props.getDBSyncAction(page, PageSize, buildQueryDbName(), buildQueryServerName(), buildQueryNoOfTables(), buildQueryNoOfRows(), buildQueryMissingSync(), buildQueryNoOfSync(), buildQueryLastSyncedTime());

        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
    }, [])
    //------------------------------------------Filter-----------------------------------------------------------
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }

    useEffect(() => {
        if (filter.DbName || filter.ServerName || filter.NoOfTables || filter.NoOfRows || filter.MissingSync || filter.NoOfSync || filter.LastSyncedTime || filter) {
            handleSearchClick();
        }

    }, [filter.DbName, filter.ServerName, filter.NoOfTables, filter.NoOfRows, filter.MissingSync, filter.NoOfSync, filter.LastSyncedTime, filter])

    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const DBSyncList = [...props.dbSyncList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'TotalLeaveDays') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //-----------------------------------------Modal open & close-----------------------------------------------------


    const handleOpenDB = () => {
        props.openCreateDBModal();
    };
    const handleClose = () => {
        props.clearFormAction()
        props.closeActionDBSyncDeleteModalAction();
        props.closeCreateDBModal();
    };

    const [deleteList, setDeleteList] = useState({});

    const selectOnChange = (value, Id) => {
        setDeleteList({
            ...deleteList,
            dbSyncId: Id
        })
        props.openActionDBSyncDeleteModalAction();
    }

    const { userAppDetails } = props && props.currentProfile;

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    return (
        <>
            <div className="page-content">
                <Card >
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong className='d-flex align-items-center'> DB SYNC -&nbsp;<Badge color="danger">{props && props.totalCount}</Badge></strong>
                        {userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "admin").length > 0 &&
                            userAppDetails
                                .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "admin")
                                .map(userApp => {
                                    const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                                        const roleName = role.roleName.toLowerCase();
                                        return roleName;
                                    });
                                    const isAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
                                    return (
                                        <React.Fragment key={userApp.id}>
                                            <div className="content-heading">
                                                <div className="ml-auto">
                                                    {isAdmin ? (
                                                        <Link
                                                            type="button"
                                                            className="btn btn-info"
                                                            onClick={handleOpenDB}
                                                        >
                                                            Create
                                                        </Link>
                                                    ) : null}

                                                </div>
                                            </div>

                                        </React.Fragment>
                                    );
                                })
                        }
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody className="p-4">

                        {props.errorMessage ? <div className='w-50'>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        <div style={{ maxHeight: "500px", overflowX: "auto" }}>
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <tr>
                                        <th
                                            onClick={() => handleSort('DbName')}
                                        >
                                            Database Name &nbsp;
                                            {sortOrder === 'DbName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('ServerName')}
                                        >
                                            Server Name &nbsp;
                                            {sortOrder === 'ServerName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('NoOfTables')}
                                        >
                                            No Of Tables &nbsp;
                                            {sortOrder === 'NoOfTables' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('NoOfRows')}
                                        >
                                            No Of Rows &nbsp;
                                            {sortOrder === 'NoOfRows' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('MissingSync')}
                                        >Missing Sync &nbsp;
                                            {sortOrder === 'MissingSync' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th
                                            onClick={() => handleSort('NoOfSync')}
                                        >
                                            No Of Sync &nbsp;
                                            {sortOrder === 'NoOfSync' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('LastSyncedTime')}
                                        >
                                            Last Synced Time &nbsp;
                                            {sortOrder === 'Last Synced Time' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th>
                                            Delete
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='DbName'
                                                onChange={searchInputValidation}
                                                value={filter && filter.DbName}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th>
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='ServerName'
                                                onChange={searchInputValidation}
                                                value={filter && filter.ServerName}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th>
                                            <Input
                                                type='number'
                                                placeholder='Search'
                                                name='NoOfTables'
                                                onChange={searchInputValidation}
                                                value={filter && filter.NoOfTables}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th>
                                            <Input
                                                type='number'
                                                placeholder='Search'
                                                name='NoOfRows'
                                                onChange={searchInputValidation}
                                                value={filter && filter.NoOfRows}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th>
                                            <Input
                                                type='number'
                                                placeholder='Search'
                                                name='MissingSync'
                                                onChange={searchInputValidation}
                                                value={filter && filter.MissingSync}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th>
                                            <Input
                                                type='number'
                                                placeholder='Search'
                                                name='NoOfSync'
                                                onChange={searchInputValidation}
                                                value={filter && filter.NoOfSync}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th>
                                            <Input
                                                type='date'
                                                placeholder='Search'
                                                name='LastSyncedTime'
                                                onChange={searchInputValidation}
                                                value={filter && filter.LastSyncedTime}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.dbSyncList && props.dbSyncList.length > 0 ?
                                        DBSyncList && DBSyncList.map((data) => (
                                            <tr >
                                                <td>{data.DbName || "N/A"}</td>
                                                <td>{data.ServerName || "N/A"}</td>
                                                <td>{data.NoOfTables || "N/A"}</td>
                                                <td>{data.NoOfRows || "N/A"}</td>
                                                <td>{data.MissingSync || "N/A"}</td>
                                                <td>{data.NoOfSync || 'N/A'}</td>
                                                <td>{data.LastSyncedTime ? moment(data.LastSyncedTime).format('DD MMM YYYY') : 'N/A'}</td>
                                                <td>
                                                    <Button className="btn btn-sm  btn-light"
                                                        onClick={(e) => { selectOnChange(e, data.Id) }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} style={{ color: "#f51436", }} />
                                                    </Button>
                                                </td>
                                            </tr>

                                        ))
                                        : props.isDBSyncListPending ?
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <span
                                                            className="spinner-border spinner-border me-3"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                        <div className="mt-2">Loading...</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                                {/* <tbody>
                                    {DBSync && DBSync.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.dbName || "NA"}</td>
                                            <td>{data.table_count || "NA"}</td>
                                            <td>{data.rows || "NA"}</td>
                                            <td>{data.missing_sync}</td>
                                            <td>{data.sync_count || 'NA'}</td>
                                            <td>{data.last_synced_time ? moment(data.last_synced_time).format('DD MMM YYYY') : 'NA'}</td>
                                        </tr>
                                    ))}
                                </tbody> */}
                            </Table>
                        </div>
                    </CardBody>

                    <VWModel
                        header="CREATE DB"
                        isOpen={props.isCreateDBModalOpen}
                        handleClose={handleClose}
                    >
                        <CreateDB
                            handleClose={handleClose}
                        />
                    </VWModel>
                    <VWModel
                        //eslint-disable-next-line
                        centered={true}
                        header="DELETE DB"
                        isOpen={props.isDeleteDBSyncModalOpen}
                        handleClose={handleClose}
                    >
                        <DeleteDB data={deleteList} handleClose={handleClose} />
                    </VWModel>
                </Card>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    dbSyncList: state.dbSync.dbSyncList,
    errorMessage: state.dbSync.dbSyncListError,
    isCreateDBModalOpen: state.dbSync.isCreateDBModalOpen,
    totalCount: state.dbSync.totalRecords,
    isDBSyncListPending: state.dbSync.isDBSyncListPending,
    currentProfile: state.login.currentProfile,
    isDeleteDBSyncModalOpen: state.dbSync.isDeleteDBSyncModalOpen,
});
const mapDispatchToProps = (dispatch) => ({
    getDBSyncAction: (page, PageSize, searchDbName, searchServerName, searchNoOfTables, searchNoOfRows, searchMissingSync, searchNoOfSync, searchLastSyncedTime) => dispatch(getDBSyncAction(page, PageSize, searchDbName, searchServerName, searchNoOfTables, searchNoOfRows, searchMissingSync, searchNoOfSync, searchLastSyncedTime)),
    clearFormAction: () => dispatch(clearForm()),
    closeCreateDBModal: () => dispatch(closeCreateDBModalAction()),
    openCreateDBModal: () => dispatch(openCreateDBModalAction()),
    openActionDBSyncDeleteModalAction: () => dispatch(openActionDBSyncDeleteModalAction()),
    closeActionDBSyncDeleteModalAction: () => dispatch(closeActionDBSyncDeleteModalAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(DBSync);