import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import VWMessage from '../../components/vwMessage/vwMessage'
import { Button, Card, CardBody, Col, Container, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap'
import success from "../../assets/images/success-msg.png"
import { connect } from 'react-redux'
import { clearEnableUserError, enableUserAccount, logoutUser } from '../../store/actions'
import vizo_logo from "../../assets/images/Vizo logo (9).png"

const mapStateToProps = (state) => ({
    isAuth: state.login.isAuth,
    ErrorMessage: state.login.ErrorMessage,
    isPendingSuccess: state.login.isenableUserPendingSuccess,
    isDisableAccount: state.login.isDisableAccount
});

const mapDispatchToProps = (dispatch: any) => ({
    clearEnableUserError: () => dispatch(clearEnableUserError()),
    logoutUser: () => dispatch(logoutUser()),
    enableUserAccount: (formFields) => dispatch(enableUserAccount(formFields)),
});

const EnableUser = (props) => {
    const [formFields, setFormFields] = useState({ confirmPassword: '', Password: '' });
    const [errors, setErrors] = useState({});
    const [showPasword1, setshowPasword1] = useState(true);
    const [showPasword2, setshowPasword2] = useState(true);
    const naviagate = useNavigate();

    const params = useParams();
    const { code } = params;

    useEffect(() => {
        props.logoutUser();
        naviagate(`/enableuser/${code}`);
        setFormFields({ code: code });
        props.clearEnableUserError();
    }, [code]);

    const validateOnChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value })
        if (name === 'Password') {
            setErrors({ ...errors, Password: 'Password must be eight character' });
            if (value && value.length >= 8) {
                setErrors({ ...errors, Password: '' });
            }
        }
        if (name === 'confirmPassword') {
            setErrors({ ...errors, confirmPassword: 'Confirm password does not match' });
            if (value === (formFields && formFields.Password)) {
                setErrors({ ...errors, confirmPassword: '' });
            }
        }
    };


    const handleLoginPage = () => {
        props.logoutUser();
        naviagate("/")
    }


    const onSubmit = (e) => {
        props.clearEnableUserError();

        const newErrors = {};
        if (!(formFields && formFields.Password)) {
            newErrors.Password = 'Password is required';
        }
        if ((formFields && formFields.Password && formFields.Password.length < 8)) {
            newErrors.Password = 'Password must be eight character';
        }
        if (((formFields && formFields.confirmPassword) !== (formFields && formFields.Password))) {
            newErrors.confirmPassword = 'Confirm password does not match';
        }
        if (!(formFields && formFields.confirmPassword)) {
            newErrors.confirmPassword = 'Confirm password is required';
        }
        
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            e.preventDefault();
            props.enableUserAccount(formFields);
        }

    };

    return props.isAuth ? (
        <Navigate to="/" />
    ) : (
        <React.Fragment>
            <Container>
                <div className="bg-overlay"></div>
                <div className="account-pages pt-5">
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card className='mt-5'>
                                <CardBody className="p-4">
                                    <Col xs="auto" className="logo-lg text-center">
                                        <img src={vizo_logo} height="auto" width="50%" />
                                    </Col>
                                    <br />
                                    {!props.isPendingSuccess ?
                                        <>
                                            <Label style={{ display: 'flex', justifyContent: 'center' }}>Enable User Account</Label>
                                            {props.ErrorMessage ? <div className='w-100'>
                                                {props.ErrorMessage && (
                                                    <VWMessage type="danger" defaultMessage={props.ErrorMessage} baseClassName="alert" ></VWMessage>
                                                )}
                                            </div> : ""}
                                            <form className="form-horizontal" action="#">
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mt-4">
                                                            <FormGroup>
                                                                <label className="col-form-label">Password <span className="text-danger">*</span></label>
                                                                <InputGroup>
                                                                    <Input
                                                                        type={showPasword1 ? "password" : "text"}
                                                                        id="id-password"
                                                                        name="Password"
                                                                        className={errors && errors.Password ? "border-danger border-end-0" : 'border-end-0 border-secondary'}
                                                                        placeholder="Enter password"
                                                                        onChange={validateOnChange}
                                                                    />
                                                                    <Button
                                                                        className={errors && errors.Password ? "border-danger border-start-0 rounded-end" : 'border-secondary border-start-0 rounded-end'}
                                                                        color='white'
                                                                        onClick={() => setshowPasword1(!showPasword1)}
                                                                    >
                                                                        {showPasword1 ?
                                                                            <i className="fas fa-eye text-secondary"></i>
                                                                            :
                                                                            <i class="fas fa-eye-slash text-secondary"></i>
                                                                        }
                                                                    </Button>
                                                                </InputGroup>
                                                                {
                                                                    <span className='text-danger'><small>{errors && errors.Password}</small></span>
                                                                }
                                                            </FormGroup>
                                                        </div>
                                                        <div className="mt-2">
                                                            <FormGroup>
                                                                <label className="col-form-label">Confirm Password <span className="text-danger">*</span></label>
                                                                <InputGroup>
                                                                    <Input
                                                                        type={showPasword2 ? "password" : "text"}
                                                                        id="id-confirmpassword"
                                                                        name="confirmPassword"
                                                                        className={errors && errors.confirmPassword ? "border-danger border-end-0" : 'border-end-0 border-secondary'}
                                                                        placeholder="Enter confirm password"
                                                                        onChange={validateOnChange}
                                                                    />
                                                                    <Button
                                                                        className={errors && errors.confirmPassword ? "border-danger border-start-0 rounded-end" : 'border-secondary border-start-0 rounded-end'}
                                                                        color='white'
                                                                        onClick={() => setshowPasword2(!showPasword2)}
                                                                    >
                                                                        {showPasword2 ?
                                                                            <i className="fas fa-eye text-secondary"></i>
                                                                            :
                                                                            <i class="fas fa-eye-slash text-secondary"></i>
                                                                        }
                                                                    </Button>
                                                                </InputGroup>
                                                                {
                                                                    <span className='text-danger'><small>{errors && errors.confirmPassword}</small></span>
                                                                }
                                                            </FormGroup>
                                                        </div>
                                                        <div className="d-grid mt-4"
                                                        >     {props.isDisableAccount ?
                                                                    (<Button color="success" block disabled> <div className="spinner-border spinner-border-sm"></div> Loading ...</Button>) :
                                                            <Button color="success" onClick={onSubmit}>Enable</Button>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </>
                                        :
                                        <div className='text-center pt-2'>
                                            <img
                                                src={success}
                                                alt=''
                                                height='60'
                                            />
                                            <p className='text-success font-size-16 pt-2'>Your account was successfully enabled...</p>
                                            <Button
                                                color="link"
                                                className='text-decoration-underline text-info mt-4'
                                                onClick={handleLoginPage}
                                            >Go to login page
                                            </Button>
                                        </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment >
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EnableUser)