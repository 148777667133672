import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import {
    clearUpdateLeadCategoryError,
    updateLeadCategory,
    updateLeadCategorySuccess
} from '../../../../../../store/Modules/crm/Settings/LeadCategory/actions';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';

const EditLeadCategory = (props) => {
    const { leadCategoryDetails, isLeadCategoryUpdatePending, errorMessage } = props;
    const { Id, LeadCategoryName, IsActive } = leadCategoryDetails || {};

    const [categoryDetails, setCategoryDetails] = useState();
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();

    useEffect(() => {
        setCategoryDetails({
            ...categoryDetails,
            id: Id,
            leadCategoryName: LeadCategoryName,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateLeadCategoryError();
        props.updateLeadCategorySuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setCategoryDetails({
            ...categoryDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        });
    }
    const handleActiveStatusChange = () => {
        setCategoryDetails({
            ...categoryDetails,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { leadCategoryName } = categoryDetails || {};

        if (!leadCategoryName) {
            currentErrors.leadCategoryName = 'Category name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateLeadCategory(categoryDetails)
        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label> Category Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="leadCategoryName"
                        placeHolder="Enter category name"
                        onChange={validateOnchange}
                        value={categoryDetails && categoryDetails.leadCategoryName}
                        invalid={errors && errors.leadCategoryName}
                    />
                    <FormFeedback>{errors && errors.leadCategoryName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isLeadCategoryUpdatePending}
                    >
                        {isLeadCategoryUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    leadCategoryDetails: state.crmLeadCategory.leadCategoryDetails,
    isLeadCategoryUpdatePending: state.crmLeadCategory.isLeadCategoryUpdatePending,
    errorMessage: state.crmLeadCategory.leadCategoryUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateLeadCategory: (categoryDetails) => dispatch(updateLeadCategory(categoryDetails)),
    clearUpdateLeadCategoryError: () => dispatch(clearUpdateLeadCategoryError()),
    updateLeadCategorySuccess: () => dispatch(updateLeadCategorySuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditLeadCategory);