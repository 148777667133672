import { getToken, modifyContactsUserList, modifyCrmAccountList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { getAccountDetails, getContactsLists } from "../Accounts/actions";
import { getContactAsyncSelectAction } from "../Opportunity/actions";
import {
    CONTACTS_CREATE_CLOSE,
    CONTACTS_CREATE_ERROR,
    CONTACTS_CREATE_ERROR_CLEAR,
    CONTACTS_CREATE_OPEN,
    CONTACTS_CREATE_PENDING,
    CONTACTS_CREATE_SUCCESS,
    CONTACTS_DETAILS_ERROR,
    CONTACTS_DETAILS_ERROR_CLEAR,
    CONTACTS_DETAILS_PENDING,
    CONTACTS_EXPORT_LIST_ERROR,
    CONTACTS_EXPORT_LIST_ERROR_CLEAR,
    CONTACTS_EXPORT_LIST_PENDING,
    CONTACTS_LIST_ERROR,
    CONTACTS_LIST_ERROR_CLEAR,
    CONTACTS_LIST_PENDING,
    CONTACTS_UPDATE_CLOSE,
    CONTACTS_UPDATE_ERROR,
    CONTACTS_UPDATE_ERROR_CLEAR,
    CONTACTS_UPDATE_OPEN,
    CONTACTS_UPDATE_PENDING,
    CONTACTS_UPDATE_SUCCESS,
    SET_CONTACTS_DETAILS,
    SET_CONTACTS_EXPORT_LIST,
    SET_CONTACTS_LIST
} from "./constants";

//--------------------CONTACTS-CREATE-----------------------------------
export const openContactsCreate = () => ({
    type: CONTACTS_CREATE_OPEN
})
export const closeContactsCreate = () => ({
    type: CONTACTS_CREATE_CLOSE
})
export const createContactsPending = () => ({
    type: CONTACTS_CREATE_PENDING
})
export const createContactsError = (errorMessage) => ({
    type: CONTACTS_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateContactsError = () => ({
    type: CONTACTS_CREATE_ERROR_CLEAR,
})
export const createContactsSuccess = () => ({
    type: CONTACTS_CREATE_SUCCESS,
})

export const getAccountsAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getAccountsAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmAccountList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const createNewContacts = (formFields, filteredData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createContactsError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(createContactsPending());
    dispatch(clearCreateContactsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.createContacts(accessToken, dataToSend)
            .then(() => {
                dispatch(createContactsSuccess());
                dispatch(closeContactsCreate());
                dispatch(getContactsList(filteredData));
                // dispatch(getContactExportList());
                dispatch(getAccountDetails(formFields && formFields.companyId));
                dispatch(getContactsLists(formFields && formFields.companyId));
                dispatch(getContactAsyncSelectAction(formFields && formFields.companyId));
                dispatch(showSnackbarStatus('Contact created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createContactsError(err.response && err.response.text));
                    return;
                }
                else {
                    dispatch(createContactsError('Network Error'));
                }
                console.log('Create Account server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------CONTACTS-UPDATE-----------------------------------
export const openContactsUpdate = () => ({
    type: CONTACTS_UPDATE_OPEN
})
export const closeContactsUpdate = () => ({
    type: CONTACTS_UPDATE_CLOSE
})
export const updateContactsPending = () => ({
    type: CONTACTS_UPDATE_PENDING
})
export const updateContactsError = (errorMessage) => ({
    type: CONTACTS_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateContactsError = () => ({
    type: CONTACTS_UPDATE_ERROR_CLEAR,
})
export const updateContactsSuccess = () => ({
    type: CONTACTS_UPDATE_SUCCESS,
})

export const updateContacts = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateContactsError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(updateContactsPending());
    dispatch(clearUpdateContactsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.updateContacts(accessToken, dataToSend)
            .then(() => {
                dispatch(updateContactsSuccess());
                dispatch(closeContactsUpdate());
                dispatch(getContactsDetails(formFields && formFields.id));
                dispatch(showSnackbarStatus('Contact updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateContactsError(err.response && err.response.text));
                    return;
                }
                else {
                    dispatch(updateContactsError('Network Error'));
                }
                console.log('Create Account server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------CONTACTS-LIST-----------------------------------
export const contactsListPending = () => ({
    type: CONTACTS_LIST_PENDING
})
export const contactsExportListPending = () => ({
    type: CONTACTS_EXPORT_LIST_PENDING
})
export const contactsListError = (errorMessage) => ({
    type: CONTACTS_LIST_ERROR,
    payload: { errorMessage }
})
export const contactsExportListError = (errorMessage) => ({
    type: CONTACTS_EXPORT_LIST_ERROR,
    payload: { errorMessage }
})
export const clearContactsListError = () => ({
    type: CONTACTS_LIST_ERROR_CLEAR,
})
export const clearContactsExportListError = () => ({
    type: CONTACTS_EXPORT_LIST_ERROR_CLEAR,
})
export const setContactsList = (contactsList) => ({
    type: SET_CONTACTS_LIST,
    payload: { contactsList }
})
export const setContactsExportList = (contactsExportList) => ({
    type: SET_CONTACTS_EXPORT_LIST,
    payload: { contactsExportList }
})

export const getContactExportList = (filter,searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy) => (dispatch) => {
    dispatch(contactsExportListPending());

    return   getToken(dispatch).then((accessToken) => {
        return agent.Crm.getContactsExportList(accessToken, filter, searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy)
            .then((result) => {
                console.log("export:", result);
                dispatch(clearContactsExportListError());
                // dispatch(setContactsExportList(result));
                return Promise.resolve(result);
            })
            .catch((err) => {
                console.log('getContactExportList server error', err);
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(contactsExportListError(error));
                console.log('Contact Export server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
export const getContactsList = (filteredData,page, PageSize, searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy) => (dispatch) => {
    dispatch(contactsListPending());
    dispatch(clearContactsListError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getContactsList(accessToken, filteredData, page, PageSize, searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy)
            .then((result) => {
                dispatch(setContactsList(result))
            })
            .catch((err) => {
                console.log('getContactsList server error', err);
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(contactsListError(error));
                console.log('Create contact server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------CONTACTS-DETAILS-----------------------------------
export const contactsDetailsPending = () => ({
    type: CONTACTS_DETAILS_PENDING
})
export const contactsDetailError = (errorMessage) => ({
    type: CONTACTS_DETAILS_ERROR,
    payload: { errorMessage }
})
export const clearContactsDetailsError = () => ({
    type: CONTACTS_DETAILS_ERROR_CLEAR,
})
export const setContactsDetails = (contactsDetails) => ({
    type: SET_CONTACTS_DETAILS,
    payload: { contactsDetails }
})

export const getContactsDetails = (id) => (dispatch) => {
    dispatch(contactsDetailsPending());
    dispatch(clearContactsDetailsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getContactsDetails(accessToken, id)
            .then((result) => {
                dispatch(setContactsDetails(result));
                // dispatch(showSnackbarStatus('Account created successfully'));
            })
            .catch((err) => {
                let error;
                if (err.response) {
                    error = err.response.text;
                }
                else {
                    error = "Network Error"
                }
                dispatch(contactsDetailError(error));
                console.log('contact details server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

