import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import VWButton from '../../../../components/vwButton/VWButton'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import { deleteTimeSheet } from '../../../../store/Modules/Timesheet/myTimesheet/actions';

const TimeSheetDelete = (props) => {
  const [timeSheetDeleteAction, setTimeSheetDelete] = useState({});

  const { filteredData } = props;
  useEffect(() => {
    const { data } = props;

    setTimeSheetDelete(data)
  }, [])

  const handleDeleteActionTimeSheet = () => {
    props.deleteTimeSheet(timeSheetDeleteAction, filteredData);
  };


  return (
    <React.Fragment>
      {props.errorMessage && (
        <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
      )}
      <h5>Are you sure you want to delete ?</h5>
      <br></br>
      <VWButton
        messageKey="deleteTimeSheetActionButton"
        defaultMessage="  Delete  "
        buttonType="success"
        baseClassName="btn"
        className="me-2"
        onClick={handleDeleteActionTimeSheet}
      // isLoading={props.isPending}
      // isDisabled={props.isPending}
      ></VWButton>
      <VWButton
        messageKey="cancelMyTimeSheetButton"
        defaultMessage="Cancel"
        buttonType="secondary"
        baseClassName="btn"
        onClick={props.handleClose}
      // isDisabled={props.isPending}
      ></VWButton>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => ({
  errorMessage: state.TimeSheets.errorMessage,
  isPending: state.TimeSheets.isPending,

});
const mapDispatchToProps = (dispatch) => ({
  deleteTimeSheet: (timeSheetDeleteAction, filter) => dispatch(deleteTimeSheet(timeSheetDeleteAction, filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheetDelete);