export const OPEN_CREATE_ADMIN_USER_MODAL = "OPEN_CREATE_ADMIN_USER_MODAL"
export const CLOSE_CREATE_ADMIN_USER_MODAL = "CLOSE_CREATE_ADMIN_USER_MODAL"
export const SET_ADMIN_USER_LIST = "SET_ADMIN_USER_LIST"
export const CREATE_ADMIN_USER_ERROR = "SET_ADMIN_USER_LIST"
export const CREATE_ADMIN_USER_PENDING = "CREATE_ADMIN_USER_PENDING"
export const CLEAR_CREATE_ADMIN_USER_ERROR = "CLEAR_CREATE_ADMIN_USER_ERROR"
export const CREATE_ADMIN_USER_SUCCESS = "CREATE_ADMIN_USER_SUCCESS"
export const OPEN_UPDATE_ADMIN_USER_MODAL = "OPEN_UPDATE_ADMIN_USER_MODAL"
export const CLOSE_UPDATE_ADMIN_USER_MODAL = "CLOSE_UPDATE_ADMIN_USER_MODAL"
export const CLEAR_UPDATE_ADMIN_USER_ERROR = "CLEAR_UPDATE_ADMIN_USER_ERROR"
export const UPDATE_ADMIN_USER_PENDING = "UPDATE_ADMIN_USER_PENDING"
export const UPDATE_ADMIN_USER_SUCCESS = "UPDATE_ADMIN_USER_SUCCESS"
export const UPDATE_ADMIN_USER_ERROR = "UPDATE_ADMIN_USER_ERROR"