export const CLOSE_CREATE_ORGANIZATION_MODAL = "CLOSE_CREATE_APP_MASTER_MODAL";
export const OPEN_CREATE_ORGANIZATION_MODAL = "OPEN_CREATE_ORGANIZATION_MODAL";
export const SET_ORGANIZATION_LIST = "SET_ORGANIZATION_LIST";
export const SET_ORGANIZATION_LIST_ERROR = "SET_ORGANIZATION_LIST_ERROR";
export const CLEAR_ORGANIZATION_LIST_ERROR = "CLEAR_ORGANIZATION_LIST_ERROR";
export const SET_ORGANIZATION_DETAILS = "SET_ORGANIZATION_DETAILS";
export const SET_ORGANIZATION_DETAILS_ERROR = "SET_ORGANIZATION_DETAILS_ERROR";
export const CLEAR_ORGANIZATION_DETAILS_ERROR = "CLEAR_ORGANIZATION_DETAILS_ERROR";
export const OPEN_EDIT_ORGANIZATION_MODAL = "OPEN_EDIT_ORGANIZATION_MODAL";
export const CLOSE_EDIT_ORGANIZATION_MODAL = "CLOSE_EDIT_ORGANIZATION_MODAL";
export const CREATE_ORGANIZATION_ERROR = "CREATE_ORGANIZATION_ERROR";
export const CREATE_ORGANIZATION_PENDING = "CREATE_ORGANIZATION_PENDING";
export const CLEAR_CREATE_ORGANIZATION_ERROR = "CLEAR_CREATE_ORGANIZATION_ERROR";
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";
export const CLEAR_UPDATE_ORGANIZATION_ERROR = "CLEAR_UPDATE_ORGANIZATION_ERROR";
export const UPDATE_ORGANIZATION_PENDING = "UPDATE_ORGANIZATION_PENDING";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_ERROR = "UPDATE_ORGANIZATION_ERROR";
export const SET_ORGANIZATION_PENDING = "SET_ORGANIZATION_PENDING";
export const SET_ORGANIZATION_DETAILS_PENDING = "SET_ORGANIZATION_DETAILS_PENDING";
export const SET_ORGANIZATION_EXPORT_PENDING = "SET_ORGANIZATION_EXPORT_PENDING";
export const SET_ORGANIZATION_EXPORT_LIST = "SET_ORGANIZATION_EXPORT_LIST";
export const CLEAR_ORGANIZATION_EXPORT_LIST_ERROR = "CLEAR_ORGANIZATION_EXPORT_LIST_ERROR";
export const SET_ORGANIZATION_EXPORT_LIST_ERROR = "SET_ORGANIZATION_EXPORT_LIST_ERROR";
export const SET_ORGANIZATION_USER_BASED_ORG_LIST_PENDING = "SET_ORGANIZATION_USER_BASED_ORG_LIST_PENDING";
export const CLEAR_ORG_USER_BASED_ORG_LIST_ERROR = "CLEAR_ORG_USER_BASED_ORG_LIST_ERROR";
export const SET_ORG_USER_BASED_ORG_LIST = "SET_ORG_USER_BASED_ORG_LIST";
export const SET_ORG_USER_BASED_ORG_ERROR = "SET_ORG_USER_BASED_ORG_ERROR";


export const ORG_EXPORT_HEADERS_SUPERADMIN = [
    "ORGANIZATION NAME",
    "CONTACT PERSON",
    "CONTACT EMAIL",
    "ORGANIZATION USER COUNT",
    "CONTACT #",
    "ADDRESS",
    "CREATED BY",
    "STATUS",
];
export const ORG_EXPORT_HEADERS = [
    "CONTACT PERSON",
    "CONTACT EMAIL",
    "ORGANIZATION USER COUNT",
    "CONTACT #",
    "ADDRESS",
    "CREATED BY",
    "STATUS",
];