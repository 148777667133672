import { getToken } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    LEAD_SOURCE_CREATE_CLOSE,
    LEAD_SOURCE_CREATE_ERROR,
    LEAD_SOURCE_CREATE_ERROR_CLEAR,
    LEAD_SOURCE_CREATE_OPEN,
    LEAD_SOURCE_CREATE_PENDING,
    LEAD_SOURCE_CREATE_SUCCESS,
    LEAD_SOURCE_LIST_ERROR,
    LEAD_SOURCE_LIST_ERROR_CLEAR,
    LEAD_SOURCE_LIST_PENDING,
    LEAD_SOURCE_UPDATE_CLOSE,
    LEAD_SOURCE_UPDATE_ERROR,
    LEAD_SOURCE_UPDATE_ERROR_CLEAR,
    LEAD_SOURCE_UPDATE_OPEN,
    LEAD_SOURCE_UPDATE_PENDING,
    LEAD_SOURCE_UPDATE_SUCCESS,
    SET_LEAD_SOURCE_DETAILS,
    SET_LEAD_SOURCE_LIST,
} from "./constants";

//--------------------ACCOUNT-CATEGORY-CREATE-----------------------------------
export const openAddLeadSourceModal = () => ({
    type: LEAD_SOURCE_CREATE_OPEN
})
export const closeAddLeadSourceModal = () => ({
    type: LEAD_SOURCE_CREATE_CLOSE
})
export const createLeadSourcePending = () => ({
    type: LEAD_SOURCE_CREATE_PENDING
})
export const createLeadSourceError = (errorMessage) => ({
    type: LEAD_SOURCE_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateLeadSourceError = () => ({
    type: LEAD_SOURCE_CREATE_ERROR_CLEAR,
})
export const createLeadSourceSuccess = () => ({
    type: LEAD_SOURCE_CREATE_SUCCESS,
})

export const createNewLeadSource = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createLeadSourceError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createLeadSourcePending());
    dispatch(clearCreateLeadSourceError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createLeadSource(accessToken, dataToSend)
            .then(() => {
                dispatch(getLeadSourceList());
                dispatch(createLeadSourceSuccess());
                dispatch(closeAddLeadSourceModal());
                dispatch(showSnackbarStatus('Lead source created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createLeadSourceError(err.response && err.response.text));
                    return;
                }
                console.log('Create account category server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------CONTACTS-UPDATE-----------------------------------
export const openEditLeadSourceModal = () => ({
    type: LEAD_SOURCE_UPDATE_OPEN
})
export const closeEditLeadSourceModal = () => ({
    type: LEAD_SOURCE_UPDATE_CLOSE
})
export const updateLeadSourcePending = () => ({
    type: LEAD_SOURCE_UPDATE_PENDING
})
export const updateLeadSourceError = (errorMessage) => ({
    type: LEAD_SOURCE_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateLeadSourceError = () => ({
    type: LEAD_SOURCE_UPDATE_ERROR_CLEAR,
})
export const updateLeadSourceSuccess = () => ({
    type: LEAD_SOURCE_UPDATE_SUCCESS,
})
export const setLeadSourceDetails = (leadSourceDetails) => ({
    type: SET_LEAD_SOURCE_DETAILS,
    payload: { leadSourceDetails }
})

export const updateLeadSource = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateLeadSourceError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateLeadSourcePending());
    dispatch(clearUpdateLeadSourceError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateLeadSource(accessToken, dataToSend)
            .then(() => {
                dispatch(updateLeadSourceSuccess());
                dispatch(closeEditLeadSourceModal());
                dispatch(getLeadSourceList());
                dispatch(showSnackbarStatus('Lead source updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateLeadSourceError(err.response && err.response.text));
                    return;
                }
                console.log('Create lead source server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------ACCOUNT-CATEGORY-LIST-----------------------------------
export const leadSourceListPending = () => ({
    type: LEAD_SOURCE_LIST_PENDING
})
export const leadSourceListError = (errorMessage) => ({
    type: LEAD_SOURCE_LIST_ERROR,
    payload: { errorMessage }
})
export const clearLeadSourceListError = () => ({
    type: LEAD_SOURCE_LIST_ERROR_CLEAR,
})
export const setLeadSourceList = (leadSourceList) => ({
    type: SET_LEAD_SOURCE_LIST,
    payload: { leadSourceList }
})

export const getLeadSourceList = (page, PageSize) => (dispatch) => {
    dispatch(leadSourceListPending());
    dispatch(clearLeadSourceListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getLeadSourceList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setLeadSourceList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leadSourceListError(error));
                console.log('Create lead source server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
