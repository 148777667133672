const CrmAdminSidebarData = [

    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-analytics",
        isHasArrow: true,
        url: "/crm/dashboard",
    },
    {
        label: "Leads",
        icon: "mdi mdi-briefcase-outline",
        isHasArrow: true,
        url: "/crm/leads",
    },
    {
        label: "Accounts",
        icon: "mdi mdi-smart-card-outline",
        isHasArrow: true,
        url: "/crm/accounts",
    },
    {
        label: "Contacts",
        icon: "mdi mdi-card-account-details-outline",
        isHasArrow: true,
        url: "/crm/contacts",
    },
    {
        label: "Opportunity",
        icon: "mdi mdi-tag-outline",
        isHasArrow: true,
        url: "/crm/opportunity",
    },
    {
        label: "Visits",
        icon: "mdi mdi-eye-outline",
        isHasArrow: true,
        url: "/crm/visits",
    },
    {
        label: "Settings",
        icon: "mdi mdi-cog-outline",
        isHasArrow: true,
        url: "/crm/settings/leads/lead-category",
    },
]
const CrmUserSidebarData = [

    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-analytics",
        isHasArrow: true,
        url: "/crm/dashboard",
    },
    {
        label: "Leads",
        icon: "mdi mdi-briefcase-outline",
        isHasArrow: true,
        url: "/crm/leads",
    },
    {
        label: "Accounts",
        icon: "mdi mdi-smart-card-outline",
        isHasArrow: true,
        url: "/crm/accounts",
    },
    {
        label: "Contacts",
        icon: "mdi mdi-card-account-details-outline",
        isHasArrow: true,
        url: "/crm/contacts",
    },
    {
        label: "Opportunity",
        icon: "mdi mdi-tag-outline",
        isHasArrow: true,
        url: "/crm/opportunity",
    },
    {
        label: "Visits",
        icon: "mdi mdi-eye-outline",
        isHasArrow: true,
        url: "/crm/visits",
    },
]
export { CrmAdminSidebarData, CrmUserSidebarData };