import React, { useEffect, useState } from 'react'
import VWMessage from '../../../components/vwMessage/vwMessage'
import { clearDeleteVisionDocsError, deleteDocsList } from '../../../store/Modules/VisionDocs/actions';
import VWButton from '../../../components/vwButton/VWButton';
import { connect } from 'react-redux';
import { Button, FormGroup } from 'reactstrap';

const DocsDelete = (props) => {
    const [DocsDeleteAction, setDocsDelete] = useState({});

    useEffect(() => {
        const { data } = props;
        console.log("deleteID:", data);
        props.clearDeleteVisionDocsError();
        setDocsDelete(data)
    }, [])

    const handleDeleteActionDocs = () => {
        props.deleteDocs(DocsDeleteAction && DocsDeleteAction.id);
    };
    console.log("DocsDeleteAction:", DocsDeleteAction);
    console.log("errorMessage:", props.errorMessage);
    
  return (
      <React.Fragment>
          {props.errorMessage && (
              <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
          )}
          <h5>Are you sure you want to delete ?</h5>
          <br></br>
          <div className="form-group">
              <FormGroup>
                  <Button
                      className="me-2"
                      color="success"
                      onClick={handleDeleteActionDocs}
                      disabled={props.isPending}
                  >
                      {props.isPending ? <>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                          &nbsp; Submitting...
                      </>
                          : <>Submit</>}
                  </Button>
                  <Button
                      color="danger"
                      onClick={props.handleClose}
                  >
                      Cancel
                  </Button>
              </FormGroup>
          </div>
      </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
    errorMessage: state.visionDocs.DocsDeleteError,
    isPending: state.visionDocs.isvisionDocsDeletePending,

});
const mapDispatchToProps = (dispatch) => ({
    deleteDocs: (id) => dispatch(deleteDocsList(id)),
    clearDeleteVisionDocsError: () => dispatch(clearDeleteVisionDocsError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocsDelete)