export const CREATE_ZONE_SUCCESS = "CREATE_ZONE_SUCCESS";
export const CREATE_ZONE_ERROR = "CREATE_ZONE_ERROR";
export const CREATE_ZONE_PENDING = "CREATE_ZONE_PENDING";
export const CLEAR_ZONE_ERROR = "CLEAR_ZONE_ERROR";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const OPEN_CREATE_ZONE_MODAL = "OPEN_CREATE_ZONE_MODAL";
export const CLOSE_CREATE_ZONE_MODAL = "CLOSE_CREATE_ZONE_MODAL";
export const OPEN_UPDATE_ZONE_MODAL = "OPEN_UPDATE_ZONE_MODAL";
export const CLOSE_UPDATE_ZONE_MODAL = "CLOSE_UPDATE_ZONE_MODAL";
export const GET_ZONES_PENDING = "GET_ZONES_PENDING";
export const SET_ZONES_LIST = "SET_ZONES_LIST";
export const UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS";
export const UPDATE_ZONE_ERROR = "UPDATE_ZONE_ERROR";
export const UPDATE_ZONE_PENDING = "UPDATE_ZONE_PENDING";
export const SET_ZONE_DETAILS = "SET_ZONE_DETAILS";
export const SET_ASSIGNABLE_ZONES_LIST_ASYNC = "SET_ASSIGNABLE_ZONES_LIST_ASYNC";
export const ADD_ZONE = "ADD_ZONE"
