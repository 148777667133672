import React, { useEffect } from 'react'
import VWModel from '../../../../../components/Modal/VWModal'
import { Link, useParams } from 'react-router-dom'
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { closeEditGroupModal, getGroupDetailsAction, openEditGroupModal } from '../../../../../store/Modules/Admin/Group/actions'
import EditGroup from './EditGroup'
import VWMessage from '../../../../../components/vwMessage/vwMessage'
import VWSpinner from '../../../../../components/vwSpinner/vwSpinner'
import { clearSetLoginPath, clearSetPath } from '../../../../../store/actions'

const GroupDetails = (props) => {

    const params = useParams();
    const { id } = params;
    const { errorMessage, GroupDetails, isEditGroupModalOpen } = props || {};
    const { groupName, groupOwnerEmail, groupEmail, orgName, appGroupDetails, orgGroupRoleDetails,
        orgGroupUserDetails, } = GroupDetails || {};

    useEffect(() => {
        props.getGroupDetails(id);
        props.ClearPath();
    }, [props.getGroupDetails, id]);

    useEffect(() => {
        return async () => {
            await
                props.clearSetLoginPath();
            props.ClearPath();
        }
    }, []);
    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditGroupModel = () => {
        const { openEditGroupModalAction } = props;
        openEditGroupModalAction();
    };

    const handleCloseEditGroupModal = () => {
        const { closeEditGroupModalAction } = props;
        closeEditGroupModalAction();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>GROUP DETAILS</strong>
                        {errorMessage ? "" :
                            <Link type="button"
                                className="btn btn-info"
                                onClick={handleOpenEditGroupModel}
                            >
                                EDIT
                            </Link>}
                    </CardTitle>
                    <CardBody className="p-4">
                        {errorMessage ? <div className='w-100'>
                            {errorMessage && (
                                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        {props.isGroupDetailsPending ?
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                            :
                            <Row>
                                <div>
                                    <div className="p-3 bg-light fw-bold fs-4">
                                        <span className='text-capitalize'>{groupName || "N/A"}</span>
                                    </div>
                                </div>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Group Name</strong>
                                                            </td>
                                                            <td>{groupName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Group Owner Email</strong>
                                                            </td>
                                                            <td>{groupOwnerEmail || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Group Email</strong>
                                                            </td>
                                                            <td>{groupEmail || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Organization Name</strong>
                                                            </td>
                                                            <td>{orgName || "N/A"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Apps</strong>
                                                            </td>
                                                            <td>{appGroupDetails && appGroupDetails.length > 0 ?
                                                                appGroupDetails && appGroupDetails.map((items, index) => (
                                                                    //eslint-disable-next-line
                                                                    <React.Fragment key={index}>
                                                                        <Badge color='success'>{items.appName}</Badge>&nbsp;
                                                                    </React.Fragment>
                                                                ))
                                                                : "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Role</strong>
                                                            </td>
                                                            <td>{orgGroupRoleDetails && orgGroupRoleDetails.length > 0 ?
                                                                orgGroupRoleDetails && orgGroupRoleDetails.map((items, index) => (
                                                                    //eslint-disable-next-line
                                                                    <React.Fragment key={index}>
                                                                        <Badge color='success'>{items.roleName}</Badge>&nbsp;
                                                                    </React.Fragment>
                                                                ))
                                                                : "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Users</strong>
                                                            </td>
                                                            <td>{orgGroupUserDetails && orgGroupUserDetails.length > 0 ?
                                                                orgGroupUserDetails && orgGroupUserDetails.map((items, index) => (
                                                                    //eslint-disable-next-line
                                                                    <React.Fragment key={index}>
                                                                        <Badge color='success' >{`${items.userName} | ${items.userEmail}`}</Badge>&nbsp;
                                                                    </React.Fragment>
                                                                ))
                                                                : "N/A"}</td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                    <VWModel
                        header="EDIT GROUP"
                        isOpen={isEditGroupModalOpen}
                        handleClose={handleCloseEditGroupModal}
                    >
                        <EditGroup handleClose={handleCloseEditGroupModal} />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    GroupDetails: state.AdminGroup.groupDetails,
    errorMessage: state.AdminGroup.groupDetailsError,
    isEditGroupModalOpen: state.AdminGroup.isEditGroupModalOpen,
    isGroupDetailsPending: state.AdminGroup.isGroupDetailsPending
});

const mapDispatchToProps = (dispatch) => ({
    getGroupDetails: (Id) => dispatch(getGroupDetailsAction(Id)),
    openEditGroupModalAction: () => dispatch(openEditGroupModal()),
    closeEditGroupModalAction: () => dispatch(closeEditGroupModal()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails)