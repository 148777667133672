export const CLOSE_CREATE_GROUP_MODAL = "CLOSE_CREATE_GROUP_MODAL"
export const OPEN_CREATE_GROUP_MODAL = "OPEN_CREATE_GROUP_MODAL"
export const SET_GROUP_LIST = "SET_GROUP_LIST"
export const SET_GROUP_LIST_ERROR = "SET_GROUP_LIST_ERROR"
export const CLEAR_GROUP_LIST_ERROR = "CLEAR_GROUP_LIST_ERROR"
export const SET_GROUP_DETAILS = "SET_GROUP_DETAILS"
export const SET_GROUP_DETAILS_ERROR = "SET_GROUP_DETAILS_ERROR"
export const CLEAR_GROUP_DETAILS_ERROR = "CLEAR_GROUP_DETAILS_ERROR"
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR"
export const CREATE_GROUP_PENDING = "CREATE_GROUP_PENDING"
export const CLEAR_CREATE_GROUP_ERROR = "CLEAR_CREATE_GROUP_ERROR"
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS"
export const OPEN_EDIT_GROUP_MODAL = "OPEN_EDIT_GROUP_MODAL"
export const CLOSE_EDIT_GROUP_MODAL = "CLOSE_EDIT_GROUP_MODAL"
export const CLEAR_UPDATE_GROUP_ERROR = "CLEAR_UPDATE_GROUP_ERROR"
export const UPDATE_GROUP_PENDING = "UPDATE_GROUP_PENDING"
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS"
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR"
export const SET_GROUP_LIST_PENDING = "SET_GROUP_LIST_PENDING"
export const SET_GROUP_DETAILS_PENDING = "SET_GROUP_DETAILS_PENDING"
export const SET_GROUP_EXPORT_LIST_PENDING = "SET_GROUP_EXPORT_LIST_PENDING"
export const SET_GROUP_EXPORT_LIST = "SET_GROUP_EXPORT_LIST"
export const CLEAR_GROUP_EXPORT_LIST_ERROR = "CLEAR_GROUP_EXPORT_LIST_ERROR"
export const SET_GROUP_EXPORT_LIST_ERROR = "SET_GROUP_EXPORT_LIST_ERROR"

export const ORG_GROUP_EXPORT_HEADERS = [
    "GROUP NAME",
    "GROUP EMAIL",
    "GROUP OWNER EMAIL",
];