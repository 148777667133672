import { getToken, modifyCrmUserList } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    REGION_CREATE_CLOSE,
    REGION_CREATE_ERROR,
    REGION_CREATE_ERROR_CLEAR,
    REGION_CREATE_OPEN,
    REGION_CREATE_PENDING,
    REGION_CREATE_SUCCESS,
    REGION_LIST_ERROR,
    REGION_LIST_ERROR_CLEAR,
    REGION_LIST_PENDING,
    REGION_UPDATE_CLOSE,
    REGION_UPDATE_ERROR,
    REGION_UPDATE_ERROR_CLEAR,
    REGION_UPDATE_OPEN,
    REGION_UPDATE_PENDING,
    REGION_UPDATE_SUCCESS,
    SET_REGION_DETAILS,
    SET_REGION_LIST,
} from "./constants";

//--------------------REGION-CREATE-----------------------------------
export const openAddRegionModal = () => ({
    type: REGION_CREATE_OPEN
})
export const closeAddRegionModal = () => ({
    type: REGION_CREATE_CLOSE
})
export const createRegionPending = () => ({
    type: REGION_CREATE_PENDING
})
export const createRegionError = (errorMessage) => ({
    type: REGION_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateRegionError = () => ({
    type: REGION_CREATE_ERROR_CLEAR,
})
export const createRegionSuccess = () => ({
    type: REGION_CREATE_SUCCESS,
})

export const getCrmUserAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCrmUserAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmUserList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const createNewRegion = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createRegionError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createRegionPending());
    dispatch(clearCreateRegionError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createRegion(accessToken, dataToSend)
            .then(() => {
                dispatch(getRegionList());
                dispatch(createRegionSuccess());
                dispatch(closeAddRegionModal());
                dispatch(showSnackbarStatus('Region created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createRegionError(err.response && err.response.text));
                    return;
                }
                console.log('Create Region server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------REGION-UPDATE-----------------------------------
export const openEditRegionModal = () => ({
    type: REGION_UPDATE_OPEN
})
export const closeEditRegionModal = () => ({
    type: REGION_UPDATE_CLOSE
})
export const updateRegionPending = () => ({
    type: REGION_UPDATE_PENDING
})
export const updateRegionError = (errorMessage) => ({
    type: REGION_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateRegionError = () => ({
    type: REGION_UPDATE_ERROR_CLEAR,
})
export const updateRegionSuccess = () => ({
    type: REGION_UPDATE_SUCCESS,
})
export const setRegionDetails = (regionDetails) => ({
    type: SET_REGION_DETAILS,
    payload: { regionDetails }
})

export const updateRegion = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateRegionError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateRegionPending());
    dispatch(clearUpdateRegionError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateRegion(accessToken, dataToSend)
            .then(() => {
                dispatch(getRegionList());
                dispatch(updateRegionSuccess());
                dispatch(closeEditRegionModal());
                dispatch(showSnackbarStatus('Region updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateRegionError(err.response && err.response.text));
                    return;
                }
                console.log('Create Region server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------REGION-LIST-----------------------------------
export const regionListPending = () => ({
    type: REGION_LIST_PENDING
})
export const regionListError = (errorMessage) => ({
    type: REGION_LIST_ERROR,
    payload: { errorMessage }
})
export const clearRegionListError = () => ({
    type: REGION_LIST_ERROR_CLEAR,
})
export const setRegionList = (regionList) => ({
    type: SET_REGION_LIST,
    payload: { regionList }
})

export const getRegionList = (page, PageSize) => (dispatch) => {
    dispatch(regionListPending());
    dispatch(clearRegionListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getRegionList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setRegionList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }  
                dispatch(regionListError(error));
                console.log('Create Region server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

