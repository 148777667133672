import {
    OPPORTUNITY_CATEGORY_CREATE_CLOSE,
    OPPORTUNITY_CATEGORY_CREATE_ERROR,
    OPPORTUNITY_CATEGORY_CREATE_ERROR_CLEAR,
    OPPORTUNITY_CATEGORY_CREATE_OPEN,
    OPPORTUNITY_CATEGORY_CREATE_PENDING,
    OPPORTUNITY_CATEGORY_CREATE_SUCCESS,
    OPPORTUNITY_CATEGORY_LIST_ERROR,
    OPPORTUNITY_CATEGORY_LIST_ERROR_CLEAR,
    OPPORTUNITY_CATEGORY_LIST_PENDING,
    OPPORTUNITY_CATEGORY_UPDATE_CLOSE,
    OPPORTUNITY_CATEGORY_UPDATE_ERROR,
    OPPORTUNITY_CATEGORY_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_CATEGORY_UPDATE_OPEN,
    OPPORTUNITY_CATEGORY_UPDATE_PENDING,
    OPPORTUNITY_CATEGORY_UPDATE_SUCCESS,
    SET_OPPORTUNITY_CATEGORY_DETAILS,
    SET_OPPORTUNITY_CATEGORY_LIST,
} from "./constants";

const initialState = {
    //--------------------Industry-CREATE-----------------------------------
    isCreateOpportunityCategoryOpen: false,
    opportunityCategoryCreateErrorMessage: null,
    isOpportunityCategoryCreateSuccess: false,
    isOpportunityCategoryCreatePending: false,
    //--------------------Industry-UPDATE-----------------------------------
    opportunityCategoryDetails: null,
    isUpdateOpportunityCategoryOpen: false,
    opportunityCategoryUpdateErrorMessage: null,
    isOpportunityCategoryUpdateSuccess: false,
    isOpportunityCategoryUpdatePending: false,
    //--------------------Industry-LIST-------------------------------------
    opportunityCategoryList: [],
    opportunityCategoryListCount: 0,
    isOpportunityCategoryListPending: false,
    opportunityCategoryListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------LEADS-CREATE-----------------------------------
        case OPPORTUNITY_CATEGORY_CREATE_OPEN: {
            return {
                ...localState,
                isCreateOpportunityCategoryOpen: true
            }
        }
        case OPPORTUNITY_CATEGORY_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateOpportunityCategoryOpen: false
            }
        }
        case OPPORTUNITY_CATEGORY_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityCategoryCreateErrorMessage: null,
            }
        }
        case OPPORTUNITY_CATEGORY_CREATE_PENDING: {
            return {
                ...localState,
                isOpportunityCategoryCreatePending: true,
            }
        }
        case OPPORTUNITY_CATEGORY_CREATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityCategoryCreateSuccess: true,
                isOpportunityCategoryCreatePending: false
            }
        }
        case OPPORTUNITY_CATEGORY_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityCategoryCreateErrorMessage: errorMessage,
                isOpportunityCategoryCreatePending: false,
            }
        }

        //--------------------LEADS-UPDATE-----------------------------------
        case SET_OPPORTUNITY_CATEGORY_DETAILS: {
            const { opportunityCategoryDetails } = action.payload;
            return {
                ...localState,
                opportunityCategoryDetails: opportunityCategoryDetails
            }
        }
        case OPPORTUNITY_CATEGORY_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateOpportunityCategoryOpen: true
            }
        }
        case OPPORTUNITY_CATEGORY_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateOpportunityCategoryOpen: false
            }
        }
        case OPPORTUNITY_CATEGORY_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityCategoryUpdateErrorMessage: null,
            }
        }
        case OPPORTUNITY_CATEGORY_UPDATE_PENDING: {
            return {
                ...localState,
                isOpportunityCategoryUpdatePending: true,
            }
        }
        case OPPORTUNITY_CATEGORY_UPDATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityCategoryUpdateSuccess: true,
                isOpportunityCategoryUpdatePending: false
            }
        }
        case OPPORTUNITY_CATEGORY_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityCategoryUpdateErrorMessage: errorMessage,
                isOpportunityCategoryUpdatePending: false
            }
        }
        //--------------------LEADS-CATEGORY-LIST-------------------------------------
        case SET_OPPORTUNITY_CATEGORY_LIST: {
            const { opportunityCategoryList } = action.payload;
            return {
                ...localState,
                opportunityCategoryList: opportunityCategoryList && opportunityCategoryList['value'],
                opportunityCategoryListCount: opportunityCategoryList && opportunityCategoryList['@odata.count'],
                isOpportunityCategoryListPending: false,
                opportunityCategoryListErrorMessage: null,
            }
        }
        case OPPORTUNITY_CATEGORY_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityCategoryListErrorMessage: errorMessage,
                isOpportunityCategoryListPending: false,
                opportunityCategoryList: []
            }
        }
        case OPPORTUNITY_CATEGORY_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityCategoryListErrorMessage: null,
            }
        }
        case OPPORTUNITY_CATEGORY_LIST_PENDING: {
            return {
                ...localState,
                isOpportunityCategoryListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};