import React from "react";
import { Card, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const EmployeeList = () => {
    return (
        <React.Fragment>
            <div className="page-content">

                <>
                    <Card>

                        <CardTitle
                            tag="h4"
                            className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                        >
                            TO DO
                            <div className="content-heading">
                                <div className="ml-auto">
                                    <Link
                                        type="button"
                                        className="btn btn-outline-secondary"
                                    // onClick={handleOpenCreateLocker}
                                    // to="add"
                                    >
                                        CREATE
                                    </Link>&nbsp;

                                </div>
                            </div>
                        </CardTitle>

                    </Card>
                    <Card>

                        <table className="table table-striped table-hover w-100">
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Title</strong>
                                    </th>
                                    <th>
                                        <strong>Status</strong>
                                    </th>
                                    <th>
                                        <strong>Milestone</strong>
                                    </th>
                                    <th>
                                        <strong>Efforts</strong>
                                    </th>
                                    <th>
                                        <strong>Priority</strong>
                                    </th>
                                    <th>
                                        <strong>Assigned To</strong>
                                    </th>
                                    <th>
                                        <strong>Sprint</strong>
                                    </th>
                                    <th>
                                        <strong>Due Date</strong>
                                    </th>
                                    <th>
                                        <strong>Planned Hours</strong>
                                    </th>
                                    <th>
                                        <strong>Left Hours</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>

                    </Card>
                </>
                {/* )} */}

            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isCreateUserModalOpen: state.users.isCreateUserModalOpen,
    userList: state.users.users,
    isGetUserPending: state.users.isGetUserPending,
    totalRecordsCount: state.users.totalRecords,
    currentProfile: state.login.currentProfile,
    loading: state.users.loading
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);

