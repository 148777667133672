import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, Navigate, useParams } from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    Table
} from "reactstrap";
import VWModel from "../../../../components/Modal/VWModal";
import UpdateLeads from "./edit";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import {
    clearRedirectToAccountDetails,
    closeLeadsUpdate,
    convertAccount,
    getLeadsDetails,
    openLeadsUpdate
} from "../../../../store/Modules/crm/Leads/actions";
import UITooltip from '../../../../CommonData/Data/UITooltip'
import moment from "moment-timezone";

const CrmLeadsDetail = (props) => {
    const params = useParams();
    const { id } = params || {};

    const { leadsDetails, leadsDetailErrorMessage, isLeadsDetailPending, isUpdateLeadsOpen, isLeadToAccountPending, isRedirectToAccountDetails, AccountId } = props || {};
    const { leadName, leadOwnerUserName, leadWebsite, leadContact, leadDesignation, email, leadOwnerUserEmail, leadSourceName,
        leadStatus, qualificationLevel, categoryName, followUpActionName, description, phoneNo, leadResponsibility, accountId, createdBy, createdTimeStamp, isAccountAccessible } = leadsDetails || {};

    useEffect(() => {
        props.getLeadsDetails(id);
        props.clearRedirectToAccountDetails();
        return () => {
            props.clearRedirectToAccountDetails();
        }
    }, [])

    const updateContactsOpen = () => {
        props.openLeadsUpdate();
    }

    const handleClose = () => {
        props.closeLeadsUpdate();
    }

    const handleConvertAccount = () => {
        props.convertAccount(id);
    }

    console.log("AccountId: ", AccountId);
    return (isRedirectToAccountDetails ? <Navigate to={`/crm/accounts/details/${AccountId && AccountId.id}`} /> :
        <div className="page-content">
            <Card>
                <CardTitle className="p-2 mb-0 fw-bold fs-4 text-dark d-flex justify-content-between"> LEAD DETAILS
                    {!leadsDetailErrorMessage &&
                        (leadStatus !== 'Converted To Account' ?
                            <Button color="primary" onClick={updateContactsOpen} >
                                EDIT
                            </Button>
                            :
                            <UITooltip id='editLead' key='editLead' content="Can not edit the lead if it was converted to account">
                                <div className="btn btn-light-primary"
                                    style={{ cursor: "no-drop" }}>
                                    EDIT
                                </div>
                            </UITooltip>)
                    }
                </CardTitle>
            </Card>
            <Card>
                {leadsDetailErrorMessage &&
                    <VWMessage type="danger" defaultMessage={leadsDetailErrorMessage} baseClassName="alert" ></VWMessage>
                }
                {isLeadsDetailPending ?
                    <div className="d-flex justify-content-center mt-2">
                        <VWSpinner />
                    </div>
                    :
                    <CardBody>
                        {((leadStatus !== 'Converted To Account') && !leadsDetailErrorMessage) && <div className="d-flex justify-content-end">
                            {leadStatus !== 'Inactive' &&
                                <Button
                                    size="sm"
                                    color="primary"
                                    onClick={handleConvertAccount}
                                    disabled={isLeadToAccountPending || (leadStatus === 'Inactive')}
                                >{isLeadToAccountPending ?
                                    <div>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                        Converting To Account...
                                    </div>
                                    : "Convert To Account"}
                                </Button>
                            }
                            {leadStatus === 'Inactive' &&
                                <UITooltip id='editLeads' key='editLeads' content="You can't convert to account if the lead is inactive">
                                    <div className="btn btn-sm btn-light-primary"
                                        style={{ cursor: "no-drop" }}>
                                        Convert To Account
                                    </div>
                                </UITooltip>}
                        </div>
                        }
                        {((leadStatus === 'Converted To Account') && !leadsDetailErrorMessage) &&
                            <div className="d-flex justify-content-end">
                                {isAccountAccessible ?
                                    <Link className="btn btn-sm btn-primary" to={`/crm/accounts/details/${accountId}`} disabled={isAccountAccessible}>
                                        Go To Account
                                    </Link> :
                                    <UITooltip id='editLeads' key='editLeads' content="You don't have the access to see this account">
                                        <div className="btn btn-sm btn-light-primary"
                                            style={{ cursor: "no-drop" }}>
                                            Go To Account
                                        </div>
                                    </UITooltip>
                                }
                            </div>
                        }
                        <Row>
                            <Col lg="6" md="12" sm="12">
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1">
                                    <span class="mdi mdi-bullseye-arrow fs-3 text-danger" /> Lead Information
                                </div>
                                <Table responsive>
                                    <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <td>{leadName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Website</th>
                                            <td>{leadWebsite || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Contact Name</th>
                                            <td>{leadContact || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Designation</th>
                                            <td>{leadDesignation || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Responsibility</th>
                                            <td>{leadResponsibility || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{email || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Mobile Number</th>
                                            <td>{phoneNo || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Source</th>
                                            <td>{leadSourceName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Qualification Level</th>
                                            <td>{qualificationLevel || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Category</th>
                                            <td>{categoryName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Follow Up Action</th>
                                            <td>{followUpActionName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>
                                                {leadStatus === "Active" ? (
                                                    <Badge color="success">Active</Badge>
                                                ) : leadStatus === "Inactive" ? (
                                                    <Badge color="danger">Inactive</Badge>
                                                ) : (<Badge color="info">Converted To Account</Badge>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg="6" md="12" sm="12">
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1">
                                    <span class="mdi mdi-bullseye-arrow fs-3 text-danger" /> Lead Owner Information
                                </div>
                                <Table className="mb-4">
                                    <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <td>{leadOwnerUserName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{leadOwnerUserEmail || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Created By</th>
                                            <td>{createdBy || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Created Date</th>
                                            <td>{createdTimeStamp ? moment(createdTimeStamp).format('DD MMM YYYY') : 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1">
                                    <span class="mdi mdi-bullseye-arrow fs-3 text-danger" /> About Lead
                                </div>
                                <Table className="mb-2">
                                    <tbody>
                                        <tr>
                                            <th>Description</th>
                                            <td>{description || "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                }
            </Card>
            <VWModel
                header="EDIT LEAD"
                modalSize="extra-large"
                isOpen={isUpdateLeadsOpen}
                handleClose={handleClose}
            >
                <UpdateLeads handleClose={handleClose} leadsDetails={leadsDetails} />
            </VWModel>
        </div >
    )
}

const mapStateToProps = (state) => ({
    leadsDetails: state.crmLeads.leadsDetails,
    isUpdateLeadsOpen: state.crmLeads.isUpdateLeadsOpen,
    leadsDetailErrorMessage: state.crmLeads.leadsDetailErrorMessage,
    isLeadsDetailPending: state.crmLeads.isLeadsDetailPending,
    isLeadToAccountPending: state.crmLeads.isLeadToAccountPending,
    isRedirectToAccountDetails: state.crmLeads.isRedirectToAccountDetails,
    AccountId: state.crmLeads.accountId,
})

const mapDispatchToProps = (dispatch) => ({
    getLeadsDetails: (id) => dispatch(getLeadsDetails(id)),
    openLeadsUpdate: () => dispatch(openLeadsUpdate()),
    closeLeadsUpdate: () => dispatch(closeLeadsUpdate()),
    convertAccount: (id) => dispatch(convertAccount(id)),
    clearRedirectToAccountDetails: () => dispatch(clearRedirectToAccountDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CrmLeadsDetail);