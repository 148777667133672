//--------------------OPPORTUNITY_CATEGORY-CREATE-----------------------------------
export const OPPORTUNITY_TYPE_CREATE_OPEN = "OPPORTUNITY_TYPE_CREATE_OPEN";
export const OPPORTUNITY_TYPE_CREATE_CLOSE = "OPPORTUNITY_TYPE_CREATE_CLOSE";
export const OPPORTUNITY_TYPE_CREATE_PENDING = "OPPORTUNITY_TYPE_CREATE_PENDING";
export const OPPORTUNITY_TYPE_CREATE_ERROR = "OPPORTUNITY_TYPE_CREATE_ERROR";
export const OPPORTUNITY_TYPE_CREATE_ERROR_CLEAR = "OPPORTUNITY_TYPE_CREATE_ERROR_CLEAR";
export const OPPORTUNITY_TYPE_CREATE_SUCCESS = "OPPORTUNITY_TYPE_CREATE_SUCCESS";

//--------------------OPPORTUNITY_CATEGORY-UPDATE-----------------------------------
export const OPPORTUNITY_TYPE_UPDATE_OPEN = "OPPORTUNITY_TYPE_UPDATE_OPEN";
export const OPPORTUNITY_TYPE_UPDATE_CLOSE = "OPPORTUNITY_TYPE_UPDATE_CLOSE";
export const OPPORTUNITY_TYPE_UPDATE_PENDING = "OPPORTUNITY_TYPE_UPDATE_PENDING";
export const OPPORTUNITY_TYPE_UPDATE_ERROR = "OPPORTUNITY_TYPE_UPDATE_ERROR";
export const OPPORTUNITY_TYPE_UPDATE_SUCCESS = "OPPORTUNITY_TYPE_UPDATE_SUCCESS";
export const OPPORTUNITY_TYPE_UPDATE_ERROR_CLEAR = "OPPORTUNITY_TYPE_UPDATE_ERROR_CLEAR";
export const SET_OPPORTUNITY_TYPE_DETAILS = "SET_OPPORTUNITY_TYPE_DETAILS";

//--------------------OPPORTUNITY_CATEGORY-LIST-----------------------------------
export const SET_OPPORTUNITY_TYPE_LIST = "SET_OPPORTUNITY_TYPE_LIST";
export const OPPORTUNITY_TYPE_LIST_PENDING = "OPPORTUNITY_TYPE_LIST_PENDING";
export const OPPORTUNITY_TYPE_LIST_ERROR = "OPPORTUNITY_TYPE_LIST_ERROR";
export const OPPORTUNITY_TYPE_LIST_ERROR_CLEAR = "OPPORTUNITY_TYPE_LIST_ERROR_CLEAR";

