import { SET_VISIT_DETAILS, SET_VISIT_EXPORT_LIST, SET_VISIT_LIST, VISIT_CREATE_CLOSE, VISIT_CREATE_ERROR, VISIT_CREATE_ERROR_CLEAR, VISIT_CREATE_OPEN, VISIT_CREATE_PENDING, VISIT_CREATE_SUCCESS, VISIT_DETAILS_ERROR, VISIT_DETAILS_ERROR_CLEAR, VISIT_DETAILS_PENDING, VISIT_EXPORT_LIST_ERROR, VISIT_EXPORT_LIST_ERROR_CLEAR, VISIT_EXPORT_LIST_PENDING, VISIT_LIST_ERROR, VISIT_LIST_ERROR_CLEAR, VISIT_LIST_PENDING, VISIT_UPDATE_CLOSE, VISIT_UPDATE_ERROR, VISIT_UPDATE_ERROR_CLEAR, VISIT_UPDATE_OPEN, VISIT_UPDATE_PENDING, VISIT_UPDATE_SUCCESS } from "./constants";

const initialState = {
   
    //--------------------VISIT-LIST-------------------------------------
    crmVisitList: [],
    visitListCount: 0,
    isVisitListPending: false,
    visitListErrorMessage: null,
    visitExportList:[],
    isVisitExportListPending: false,
    visitExportListErrorMessage: null,

    //---------------------VISIT CREATE----------------------------------------
    isCreateVisitOpen: false,
    visitCreateErrorMessage: null,
    isVisitCreateSuccess: false,
    isVisitCreatePending: false,
    //--------------------VISIT EDIT-----------------------------------------
    isUpdateVisitOpen: false,
    visitUpdateErrorMessage: null,
    isVisitUpdateSuccess: false,
    isVisitUpdatePending: false,
    //---------------------VISIT DETAILS-------------------------------------
      visitDetails: [],
    isVisitDetailPending: false,
    visitDetailErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------VISIT -UPDATE---------------------------------
        case VISIT_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateVisitOpen: true
            }
        }
        case VISIT_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateVisitOpen: false
            }
        }
        case VISIT_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                visitUpdateErrorMessage: null,
            }
        }
        case VISIT_UPDATE_PENDING: {
            return {
                ...localState,
                isVisitUpdatePending: true,
            }
        }
        case VISIT_UPDATE_SUCCESS: {
            return {
                ...localState,
                isVisitUpdateSuccess: true,
                isVisitUpdatePending: false
            }
        }
        case VISIT_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                visitUpdateErrorMessage: errorMessage,
                isVisitUpdatePending: false
            }
        }
        //--------------------VISIT-DETAILS----------------------------------
        case SET_VISIT_DETAILS: {
            const { visitDetails } = action.payload;
            return {
                ...localState,
                visitDetails: visitDetails,
                visitDetailErrorMessage: null,
                isVisitDetailPending: false,
            }
        }
        case VISIT_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                visitDetailErrorMessage: errorMessage,
                isVisitDetailPending: false,
                visitDetails: []
            }
        }
        case VISIT_DETAILS_ERROR_CLEAR: {
            return {
                ...localState,
                visitDetailErrorMessage: null,
            }
        }
        case VISIT_DETAILS_PENDING: {
            return {
                ...localState,
                isVisitDetailPending: true,
            }
        }
        //--------------------VISIT-CREATE-----------------------------------
        case VISIT_CREATE_OPEN: {
            return {
                ...localState,
                isCreateVisitOpen: true
            }
        }
        case VISIT_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateVisitOpen: false
            }
        }
       
        case VISIT_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                visitCreateErrorMessage: null,
            }
        }
        case VISIT_CREATE_PENDING: {
            return {
                ...localState,
                isVisitCreatePending: true,
            }
        }
        case VISIT_CREATE_SUCCESS: {
            return {
                ...localState,
                isVisitCreateSuccess: true,
                isVisitCreatePending: false
            }
        }
        case VISIT_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                visitCreateErrorMessage: errorMessage,
                isVisitCreatePending: false,
            }
        }
        //--------------------VISIT-LIST-------------------------------------
        case SET_VISIT_LIST: {
            const { visitList } = action.payload;
            return {
                ...localState,
                crmVisitList: visitList && visitList['value'],
                visitListCount: visitList && visitList['@odata.count'],
                isVisitListPending: false,
                visitListErrorMessage: null,
            }
        }
        case SET_VISIT_EXPORT_LIST: {
            const { visitExportList } = action.payload;
            return {
                ...localState,
                visitExportList: visitExportList,
                isVisitExportListPending: false,
                visitExportListErrorMessage: null,
            }
        }
        case VISIT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                visitListErrorMessage: errorMessage,
                isVisitListPending: false,
                crmVisitList: []
            }
        }
        case VISIT_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                visitExportListErrorMessage: errorMessage,
                isVisitExportListPending: false,
                visitExportList: []
            }
        }
        case VISIT_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                visitListErrorMessage: null,
            }
        }
        case VISIT_EXPORT_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                visitExportListErrorMessage: null,
            }
        }
        case VISIT_LIST_PENDING: {
            return {
                ...localState,
                isVisitListPending: true,
            }
        }
        case VISIT_EXPORT_LIST_PENDING: {
            return {
                ...localState,
                isVisitExportListPending: true,
            }
        }



        default: {
            return localState;
        }
    }
};