import {
    OPPORTUNITY_ACTION_TIME_CREATE_CLOSE,
    OPPORTUNITY_ACTION_TIME_CREATE_ERROR,
    OPPORTUNITY_ACTION_TIME_CREATE_ERROR_CLEAR,
    OPPORTUNITY_ACTION_TIME_CREATE_OPEN,
    OPPORTUNITY_ACTION_TIME_CREATE_PENDING,
    OPPORTUNITY_ACTION_TIME_CREATE_SUCCESS,
    OPPORTUNITY_ACTION_TIME_LIST_ERROR,
    OPPORTUNITY_ACTION_TIME_LIST_ERROR_CLEAR,
    OPPORTUNITY_ACTION_TIME_LIST_PENDING,
    OPPORTUNITY_ACTION_TIME_UPDATE_CLOSE,
    OPPORTUNITY_ACTION_TIME_UPDATE_ERROR,
    OPPORTUNITY_ACTION_TIME_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_ACTION_TIME_UPDATE_OPEN,
    OPPORTUNITY_ACTION_TIME_UPDATE_PENDING,
    OPPORTUNITY_ACTION_TIME_UPDATE_SUCCESS,
    SET_OPPORTUNITY_ACTION_TIME_DETAILS,
    SET_OPPORTUNITY_ACTION_TIME_LIST,
} from "./constants";

const initialState = {
    //--------------------Industry-CREATE-----------------------------------
    isCreateOpportunityActionTimeOpen: false,
    opportunityActionTimeCreateErrorMessage: null,
    isOpportunityActionTimeCreateSuccess: false,
    isOpportunityActionTimeCreatePending: false,
    //--------------------Industry-UPDATE-----------------------------------
    opportunityActionTimeDetails: null,
    isUpdateOpportunityActionTimeOpen: false,
    opportunityActionTimeUpdateErrorMessage: null,
    isOpportunityActionTimeUpdateSuccess: false,
    isOpportunityActionTimeUpdatePending: false,
    //--------------------Industry-LIST-------------------------------------
    opportunityActionTimeList: [],
    opportunityActionTimeListCount: 0,
    isOpportunityActionTimeListPending: false,
    opportunityActionTimeListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------LEADS-CREATE-----------------------------------
        case OPPORTUNITY_ACTION_TIME_CREATE_OPEN: {
            return {
                ...localState,
                isCreateOpportunityActionTimeOpen: true
            }
        }
        case OPPORTUNITY_ACTION_TIME_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateOpportunityActionTimeOpen: false
            }
        }
        case OPPORTUNITY_ACTION_TIME_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityActionTimeCreateErrorMessage: null,
            }
        }
        case OPPORTUNITY_ACTION_TIME_CREATE_PENDING: {
            return {
                ...localState,
                isOpportunityActionTimeCreatePending: true,
            }
        }
        case OPPORTUNITY_ACTION_TIME_CREATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityActionTimeCreateSuccess: true,
                isOpportunityActionTimeCreatePending: false
            }
        }
        case OPPORTUNITY_ACTION_TIME_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityActionTimeCreateErrorMessage: errorMessage,
                isOpportunityActionTimeCreatePending: false,
            }
        }

        //--------------------LEADS-UPDATE-----------------------------------
        case SET_OPPORTUNITY_ACTION_TIME_DETAILS: {
            const { opportunityActionTimeDetails } = action.payload;
            return {
                ...localState,
                opportunityActionTimeDetails: opportunityActionTimeDetails
            }
        }
        case OPPORTUNITY_ACTION_TIME_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateOpportunityActionTimeOpen: true
            }
        }
        case OPPORTUNITY_ACTION_TIME_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateOpportunityActionTimeOpen: false
            }
        }
        case OPPORTUNITY_ACTION_TIME_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityActionTimeUpdateErrorMessage: null,
            }
        }
        case OPPORTUNITY_ACTION_TIME_UPDATE_PENDING: {
            return {
                ...localState,
                isOpportunityActionTimeUpdatePending: true,
            }
        }
        case OPPORTUNITY_ACTION_TIME_UPDATE_SUCCESS: {
            return {
                ...localState,
                isOpportunityActionTimeUpdateSuccess: true,
                isOpportunityActionTimeUpdatePending: false
            }
        }
        case OPPORTUNITY_ACTION_TIME_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityActionTimeUpdateErrorMessage: errorMessage,
                isOpportunityActionTimeUpdatePending: false
            }
        }
        //--------------------LEADS-ActionTime-LIST-------------------------------------
        case SET_OPPORTUNITY_ACTION_TIME_LIST: {
            const { opportunityActionTimeList } = action.payload;
            return {
                ...localState,
                opportunityActionTimeList: opportunityActionTimeList && opportunityActionTimeList['value'],
                opportunityActionTimeListCount: opportunityActionTimeList && opportunityActionTimeList['@odata.count'],
                isOpportunityActionTimeListPending: false,
                opportunityActionTimeListErrorMessage: null,
            }
        }
        case OPPORTUNITY_ACTION_TIME_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                opportunityActionTimeListErrorMessage: errorMessage,
                isOpportunityActionTimeListPending: false,
                opportunityActionTimeList: []
            }
        }
        case OPPORTUNITY_ACTION_TIME_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                opportunityActionTimeListErrorMessage: null,
            }
        }
        case OPPORTUNITY_ACTION_TIME_LIST_PENDING: {
            return {
                ...localState,
                isOpportunityActionTimeListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};