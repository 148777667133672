export const SET_DB_SYNC_LIST = "SET_DB_SYNC_LIST"
export const CLEAR_DB_SYNC_LIST_ERROR = "CLEAR_DB_SYNC_LIST_ERROR"
export const DB_SYNC_LIST_ERROR = "DB_SYNC_LIST_ERROR"
export const SET_DB_SYNC_PENDING = "SET_DB_SYNC_PENDING"
export const CREATE_DB_ERROR = 'CREATE_DB_ERROR'
export const OPEN_CREATE_DB_MODAL = "OPEN_CREATE_DB_MODAL"
export const CLOSE_CREATE_DB_MODAL = "CLOSE_CREATE_DB_MODAL"
export const CREATE_DB_PENDING = "CREATE_DB_PENDING"
export const CLEAR_CREATE_DB_ERROR = "CLEAR_CREATE_DB_ERROR"
export const CREATE_DB_SUCCESS = "CREATE_DB_SUCCESS"
export const OPEN_DELETE_DB_SYNC_MODAL = "OPEN_DELETE_DB_SYNC_MODAL"
export const CLOSE_DELETE_DB_SYNC_MODAL = "CLOSE_DELETE_DB_SYNC_MODAL"
export const DELETE_DB_SYNC_ERROR = 'DELETE_DB_SYNC_ERROR'