import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import VWMessage from '../../../../../../components/vwMessage/vwMessage'
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label
} from 'reactstrap'
import {
    clearUpdateLeadMandatoryError,
    updateLeadMandatory,
    updateLeadMandatorySuccess
} from '../../../../../../store/Modules/crm/Settings/LeadmandatoryFields/actions'

const EditLeadmandatory = (props) => {

    const { leadMandatoryDetails, isLeadMandatoryUpdatePending, errorMessage } = props;
    const { Id, JsonPropertyName, IsMandatory } = leadMandatoryDetails || {};
    const [mandatoryDetails, setLeadMandatoryDetails] = useState();
    const [errors, setErrors] = useState();
    const [ISMandatory, setISMandatory] = useState();

    useEffect(() => {
        setLeadMandatoryDetails({
            ...mandatoryDetails,
            id: Id,
            JsonPropertyName: JsonPropertyName,
            IsMandatory: IsMandatory
        });
        setISMandatory(IsMandatory);
        props.clearUpdateLeadMandatoryError();
        props.updateLeadMandatorySuccess();
    }, [])

    const handleActiveStatusChange = () => {
        setLeadMandatoryDetails({
            ...mandatoryDetails,
            IsMandatory: !ISMandatory
        })
        setISMandatory(!ISMandatory)
        setErrors({
            ...errors,
            IsMandatory: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { JsonPropertyName } = mandatoryDetails || {};

        if (!JsonPropertyName) {
            currentErrors.JsonPropertyName = 'Field name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateLeadMandatory(mandatoryDetails)
        }
    }

    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label> Field Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="JsonPropertyName"
                        placeholder="Enter field name"
                        value={mandatoryDetails && mandatoryDetails.JsonPropertyName}
                        disabled
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">IsMandatory</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!ISMandatory ? 'active' : ''}`}
                        >
                            No
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${ISMandatory ? 'active' : ''}`}
                        >
                            Yes
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isLeadMandatoryUpdatePending}
                    >
                        {isLeadMandatoryUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}
const mapStateToProps = (state) => ({
    leadMandatoryDetails: state.LeadMandatory.leadMandatoryDetails,
    isLeadMandatoryUpdatePending: state.LeadMandatory.isLeadMandatoryUpdatePending,
    errorMessage: state.LeadMandatory.leadMandatoryUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateLeadMandatory: (categoryDetails) => dispatch(updateLeadMandatory(categoryDetails)),
    clearUpdateLeadMandatoryError: () => dispatch(clearUpdateLeadMandatoryError()),
    updateLeadMandatorySuccess: () => dispatch(updateLeadMandatorySuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(EditLeadmandatory)