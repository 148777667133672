import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, Table, Tooltip } from 'reactstrap'
import VWModel from '../../../../components/Modal/VWModal'
import { connect } from 'react-redux'
import { clearForm } from '../../../../store/form/actions'
import { clearOrgExportListError, closeCreateOrganizationModalAction, getExportOrgList, getOrganizationListAction, openCreateOrganizationModalAction } from '../../../../store/Modules/Admin/organization/actions'
import CreateOrganization from './OrganizationCreate'
import { getPager } from '../../../../helper/common'
import VWPagination from '../../../../components/vwPagination/vwPagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import NoRecord from '../../../../components/NoRecords/noRecords'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import VWSkeleton from '../../../../components/vwSkeleton/vwSkeleton'
import VWSpinner from '../../../../components/vwSpinner/vwSpinner'
import { clearSetPath } from '../../../../store/actions'
import Select from 'react-select';
import '../../../Modules/viewList.css'
import { excelPrint, excelSheetExport } from '../../../../helper/export'
import { ORG_EXPORT_HEADERS, ORG_EXPORT_HEADERS_SUPERADMIN } from '../../../../store/Modules/Admin/organization/constants'
import { ElementToolTip } from '../../Projects/vwToolTip'

const statusOptions = [
    { label: 'Active', value: "true" },
    { label: 'Inactive', value: "false" },
]
const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const Organization = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const { OrganizationLists, errorMessage, orgExportListError, isOrganizationListPending, isOrganizationCreateModalOpen, OrganizationExportList, isOrganizationExportListPending } = props || {};

    const buildQueryOrgName = () => {
        const { OrgName } = filter || {};
        let searchOrgName = '';
        if (OrgName) {
            searchOrgName += `${OrgName}`;
        }
        return (searchOrgName)
    }
    const buildQueryOrgContactPerson = () => {
        const { OrgContactPerson } = filter || {};
        let searchOrgContactPerson = '';
        if (OrgContactPerson) {
            searchOrgContactPerson += `${OrgContactPerson}`;
        }
        return searchOrgContactPerson
    }
    const buildQueryOrgContactEmail = () => {
        const { OrgContactEmail } = filter || {};
        let searchOrgContactEmail = '';
        if (OrgContactEmail) {
            searchOrgContactEmail += `${OrgContactEmail}`;
        }
        return searchOrgContactEmail
    }
    const buildQueryOrgContactNumber = () => {
        const { OrgContactNumber } = filter || {};
        let searchOrgContactNumber = '';
        if (OrgContactNumber) {
            searchOrgContactNumber += `${OrgContactNumber}`;
        }
        return searchOrgContactNumber
    }
    const buildQueryOrgAddress = () => {
        const { OrgAddress } = filter || {};
        let searchOrgAddress = '';
        if (OrgAddress) {
            searchOrgAddress += `${OrgAddress}`;
        }
        return (searchOrgAddress)
    }
    const buildQueryOrgCreatedBy = () => {
        const { OrgCreatedBy } = filter || {};
        let searchOrgCreatedBy = '';
        if (OrgCreatedBy) {
            searchOrgCreatedBy += `${OrgCreatedBy}`;
        }
        return (searchOrgCreatedBy)
    }
    const buildQueryOrgUserCount = () => {
        const { UserCount } = filter || {};
        let searchOrgUserCount = '';
        if (UserCount) {
            searchOrgUserCount += `${UserCount}`;
        }
        return (searchOrgUserCount)
    }
    const buildQueryIsActive = () => {
        const { IsActive } = filter || {};
        let searchIsActive = '';
        if (IsActive) {
            searchIsActive += `${IsActive}`;
        }
        return (searchIsActive)
    }

    const searchStatus = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            IsActive: value,
        })
    }
    const setPage = (page = 1) => {
        props.getOrganizationList(filter, page, PageSize, buildQueryOrgName(), buildQueryOrgContactPerson(), buildQueryOrgContactEmail(), buildQueryOrgContactNumber(), buildQueryOrgAddress(), buildQueryOrgCreatedBy(), buildQueryOrgUserCount(), buildQueryIsActive());
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.ClearPath();
        props.clearOrgExportListError();
        //   return () => {

        //         }
    }, [])
    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }
    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])
    //-----------------------------------------Modal open & close-----------------------------------------------------

    const handleOpenOrganization = () => {
        props.openCreateOrganizationModal();
    };
    const handleClose = () => {
        props.clearFormAction()
        props.closeCreateOrganizationModal();
    };

    // ----------------------------------------SORTING---------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedOrganizationList = [...props.OrganizationLists].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        if (sortOrder === 'UserCount') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //-------------------------------------------Roles ------------------------------------------------------------
    const { roles, userAppDetails } = props.currentProfile || {};
    const Roles = roles && roles.map((data) => (
        data.roleName
    ))

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/admin/organization/details/${Id}`);
    };

    console.log("OrganizationExportList:", OrganizationExportList);


    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {
        props.getExportOrgList(buildQueryOrgName(), buildQueryOrgContactPerson(), buildQueryOrgContactEmail(), buildQueryOrgContactNumber(), buildQueryOrgAddress(), buildQueryOrgCreatedBy(), buildQueryOrgUserCount(), buildQueryIsActive())
            .then((response) => {
                if (response) {
                    if (IsSuperAdmin) {
                        const exporOrgExportList = [];
                        const headerExcel = [
                            { headerName: "ORGANIZATION NAME", key: "orgName", headerWidhth: 40 },
                            { headerName: "CONTACT PERSON", key: "orgContactPerson", headerWidhth: 20 },
                            { headerName: "CONTACT EMAIL", key: "orgContactEmail", headerWidhth: 50 },
                            { headerName: "ORGANIZATION USER COUNT", key: "userCount", headerWidhth: 30 },
                            { headerName: "CONTACT #", key: "orgContactNumber", headerWidhth: 20 },
                            { headerName: "ADDRESS", key: "orgAddress", headerWidhth: 20 },
                            { headerName: "CREATED BY", key: "orgCreatedBy", headerWidhth: 20 },
                            { headerName: "STATUS", key: "IsActive", headerWidhth: 20 },
                        ]
                        response && response.forEach((element) => {
                            exporOrgExportList.push({
                                orgName: element.orgName || "N/A",
                                orgContactPerson: element.orgContactPerson || "N/A",
                                orgContactEmail: element.orgContactEmail || "N/A",
                                userCount: element.userCount || 0,
                                orgContactNumber: element.orgContactNumber || "N/A",
                                orgAddress: element.orgAddress || "N/A",
                                orgCreatedBy: element.orgCreatedBy || "N/A",
                                IsActive: element.isActive ? "Active" : "Inactive",

                            });
                        });
                        excelSheetExport(headerExcel, exporOrgExportList, "Organization-List");
                    }
                    else {
                        const exporOrgExportList = [];
                        const headerExcel = [
                            { headerName: "CONTACT PERSON", key: "orgContactPerson", headerWidhth: 20 },
                            { headerName: "CONTACT EMAIL", key: "orgContactEmail", headerWidhth: 50 },
                            { headerName: "ORGANIZATION USER COUNT", key: "userCount", headerWidhth: 30 },
                            { headerName: "CONTACT #", key: "orgContactNumber", headerWidhth: 20 },
                            { headerName: "ADDRESS", key: "orgAddress", headerWidhth: 20 },
                            { headerName: "CREATED BY", key: "orgCreatedBy", headerWidhth: 20 },
                            { headerName: "STATUS", key: "IsActive", headerWidhth: 20 },
                        ]
                        response && response.forEach((element) => {
                            exporOrgExportList.push({
                                orgContactPerson: element.orgContactPerson || "N/A",
                                orgContactEmail: element.orgContactEmail || "N/A",
                                userCount: element.userCount || 0,
                                orgContactNumber: element.orgContactNumber || "N/A",
                                orgAddress: element.orgAddress || "N/A",
                                orgCreatedBy: element.orgCreatedBy || "N/A",
                                IsActive: element.isActive ? "Active" : "Inactive",

                            });
                        });
                        excelSheetExport(headerExcel, exporOrgExportList, "Organization-List");
                    }

                }
            })
    }

    // -------------------------------------------CHECK IS SUPER ADMIN OR NOT------------------------------------------------
    let IsSuperAdmin;

    userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "admin").length > 0 &&
        userAppDetails
            .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "admin")
            .map(userApp => {
                const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                    const roleName = role.roleName.toLowerCase();
                    return roleName;
                });
                IsSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;
            })

    console.log("IsSuperAdmin:", IsSuperAdmin);

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        {userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "admin").length > 0 &&
                            userAppDetails
                                .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "admin")
                                .map(userApp => {
                                    const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                                        const roleName = role.roleName.toLowerCase();
                                        return roleName;
                                    });
                                    const isSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;

                                    return (<>
                                        {isSuperAdmin ? (<strong className='d-flex align-items-center'>ALL ORGANIZATIONS -&nbsp;<Badge color="danger" id={'AllOrgCount'} style={{ cursor: 'pointer' }}>{props && props.totalCount}</Badge>
                                            <ElementToolTip id={'AllOrgCount'} name={
                                                'All Organizations Count'
                                            } /></strong>) :
                                            (<strong>MY ORGANIZATION</strong>)}
                                        <div>
                                            <React.Fragment key={userApp.id}>
                                                {isSuperAdmin ? (
                                                    <>
                                                        <Link
                                                            type="button"
                                                            className="btn btn-info"
                                                            onClick={handleOpenOrganization}
                                                        >
                                                            CREATE
                                                        </Link>&nbsp;
                                                        {/* {((OrganizationLists) && (OrganizationLists.length > 0)) ? */}
                                                        <Button
                                                            className="me-4"
                                                            color="success"
                                                            onClick={handleExports}
                                                            disabled={OrganizationLists && OrganizationLists.length <= 0 || isOrganizationExportListPending}
                                                        >
                                                            {isOrganizationExportListPending ? 'EXPORTING...' : 'EXPORT'}
                                                        </Button>
                                                    </>
                                                ) : null}
                                            </React.Fragment>
                                        </div>
                                    </>
                                    );
                                })
                        }

                    </CardTitle>
                </Card>
                <Card>
                    <CardBody className="p-4">
                        {(errorMessage || orgExportListError) ? <div className='w-100'>
                            {(errorMessage || orgExportListError) && (
                                <VWMessage type="danger" defaultMessage={errorMessage || orgExportListError} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        <div
                            style={{
                                // height: '380px',
                                height: "60vh",
                                overflowX: 'auto',
                            }}
                        >
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                    <tr>
                                        {userAppDetails && userAppDetails
                                            .filter(item => item.appName.toLowerCase() === "admin")
                                            .map(userApp => {
                                                const adminRoles = userApp.appRoleDetails.filter(role => {
                                                    const roleName = role.roleName.toLowerCase();
                                                    return roleName;
                                                });
                                                const isSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;
                                                if (isSuperAdmin) {
                                                    return (
                                                        <th onClick={() => handleSort('OrgName')} className='hand-cursor'>
                                                            Organization Name &nbsp;
                                                            {sortOrder === 'OrgName' && (
                                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                            )}
                                                        </th>
                                                    );
                                                }
                                            })
                                        }
                                        <th style={{ minWidth: '150px' }} className='hand-cursor'
                                            onClick={() => handleSort('OrgContactEmail')}
                                        >Contact &nbsp;
                                            {sortOrder === 'OrgContactEmail' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th style={{ minWidth: '150px' }} className='hand-cursor'
                                            onClick={() => handleSort('UserCount')}
                                        >
                                            Organization User Count &nbsp;
                                            {sortOrder === 'UserCount' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>


                                        <th style={{ minWidth: '150px' }} className='hand-cursor'
                                            onClick={() => handleSort('OrgContactNumber')}
                                        >
                                            Contact # &nbsp;
                                            {sortOrder === 'OrgContactNumber' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '150px' }} className='hand-cursor'
                                            onClick={() => handleSort('OrgAddress')}
                                        >
                                            Address &nbsp;
                                            {sortOrder === 'OrgAddress' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '150px' }} className='hand-cursor'
                                            onClick={() => handleSort('OrgCreatedBy')}
                                        >
                                            Created By &nbsp;
                                            {sortOrder === 'OrgCreatedBy' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th style={{ minWidth: '150px' }} className='hand-cursor'
                                            onClick={() => handleSort('IsActive')}
                                        >
                                            Status  &nbsp;
                                            {sortOrder === 'IsActive' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                            View
                                        </th>
                                    </tr>
                                    <tr>
                                        {userAppDetails && userAppDetails
                                            .filter(item => item.appName.toLowerCase() === "admin")
                                            .map(userApp => {
                                                const adminRoles = userApp.appRoleDetails.filter(role => {
                                                    const roleName = role.roleName.toLowerCase();
                                                    return roleName;
                                                });
                                                const isSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;
                                                if (isSuperAdmin) {
                                                    return (
                                                        <th >
                                                            <Input
                                                                type='text'
                                                                placeholder='Search'
                                                                name='OrgName'
                                                                onChange={searchInputValidation}
                                                                value={filter && filter.OrgName}
                                                                onKeyPress={handleKeyPress}
                                                            /></th>
                                                    );
                                                }
                                            })
                                        }
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='OrgContactEmail'
                                                onChange={searchInputValidation}
                                                value={filter && filter.OrgContactEmail}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th >
                                            <Input
                                                onKeyDown={
                                                    (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
                                                }
                                                onWheel={(e) => e.target.blur()}
                                                type='number'
                                                placeholder='Search'
                                                name='UserCount'
                                                onChange={searchInputValidation}
                                                value={filter && filter.UserCount}
                                                onKeyPress={handleKeyPress}
                                                min='0'
                                            /></th>

                                        <th >
                                            <Input
                                                // style={{ width: '160px' }}
                                                onKeyDown={
                                                    (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
                                                }
                                                onWheel={(e) => e.target.blur()}
                                                type='text'
                                                placeholder='Search'
                                                name='OrgContactNumber'
                                                onChange={searchInputValidation}
                                                value={filter && filter.OrgContactNumber}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='OrgAddress'
                                                onChange={searchInputValidation}
                                                value={filter && filter.OrgAddress}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='OrgCreatedBy'
                                                onChange={searchInputValidation}
                                                value={filter && filter.OrgCreatedBy}
                                                onKeyPress={handleKeyPress}
                                            /></th>
                                        <th>
                                            <Select
                                                styles={customStyles}
                                                name="IsActive"
                                                isClearable
                                                defaultOptions
                                                placeholder="Search"
                                                options={statusOptions}
                                                onChange={searchStatus}
                                            />
                                        </th>
                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isOrganizationListPending && ((OrganizationLists) && (OrganizationLists.length > 0)) ?
                                        sortedOrganizationList && sortedOrganizationList.map((data, index) => (
                                            <tr key={index} onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                                {userAppDetails && userAppDetails
                                                    .filter(item => item.appName.toLowerCase() === "admin")
                                                    .map(userApp => {
                                                        const adminRoles = userApp.appRoleDetails.filter(role => {
                                                            const roleName = role.roleName.toLowerCase();
                                                            return roleName;
                                                        });
                                                        const isSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;
                                                        if (isSuperAdmin) {
                                                            return (
                                                                <td className='ps-2'>{data.OrgName || "N/A"}</td>
                                                            );
                                                        }
                                                    })
                                                }

                                                <td className='ps-3'>{data.OrgContactPerson || "N/A"}<br />
                                                    <small>{data.OrgContactEmail || "N/A"}</small>
                                                </td>
                                                <td className='ps-5'>{data.UserCount || 0}</td>
                                                {/* <td className='ps-3'>{data.OrgContactEmail || "N/A"}</td> */}
                                                <td className='ps-3'>{data.OrgContactNumber || 'N/A'}</td>
                                                {/* <td className='ps-3'>{data.OrgAddress || "N/A"}</td> */}
                                                <td className='ps-3'>{data.OrgAddress ? (data.OrgAddress && data.OrgAddress.length > 20) ? data.OrgAddress && data.OrgAddress.substring(0, 20) + '...' : data.OrgAddress : "N/A"}</td>
                                                <td className='ps-3'>{data.OrgCreatedBy || 'N/A'}</td>
                                                <td className='ps-4'>
                                                    {data.IsActive ? (
                                                        <Badge color="success" className="text-dark-white">
                                                            Active
                                                        </Badge>
                                                    ) : (
                                                        <Badge color="danger" className="text-dark-white">
                                                            Inactive
                                                        </Badge>
                                                    )}
                                                </td>
                                                <td className='sticky-column' style={{ backgroundColor: "white" }}> <Link
                                                    type="button"
                                                    className="btn btn-sm  btn-light"
                                                    to={`/admin/organization/details/${data.Id}`}
                                                >
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link></td>
                                            </tr>

                                        ))
                                        : isOrganizationListPending ? <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <VWSpinner />
                                                </div>
                                            </div>
                                        </div>
                                            :
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <NoRecord />
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {OrganizationLists && OrganizationLists.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="CREATE ORGANIZATION"
                    isOpen={isOrganizationCreateModalOpen}
                    handleClose={handleClose}
                    modalSize="large"
                >
                    <CreateOrganization
                        handleClose={handleClose}
                        filteredData={filter}
                    />
                </VWModel>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = (state) => ({
    isOrganizationCreateModalOpen: state.Organization.isCreateOrganizationModalOpen,
    OrganizationLists: state.Organization.OrganizationList,
    errorMessage: state.Organization.orgListError,
    totalCount: state.Organization.totalRecords,
    currentProfile: state.login.currentProfile,
    isOrganizationListPending: state.Organization.isOrganizationListPending,
    isOrganizationExportListPending: state.Organization.isOrganizationExportListPending,
    OrganizationExportList: state.Organization.OrganizationExportList,
    orgExportListError: state.Organization.orgExportListError,
});
const mapDispatchToProps = (dispatch) => ({
    getOrganizationList: (filter, page, PageSize, searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive) => dispatch(getOrganizationListAction(filter, page, PageSize, searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive)),
    getExportOrgList: (searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive) => dispatch(getExportOrgList(searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive)),
    closeCreateOrganizationModal: () => dispatch(closeCreateOrganizationModalAction()),
    openCreateOrganizationModal: () => dispatch(openCreateOrganizationModalAction()),
    clearFormAction: () => dispatch(clearForm()),
    ClearPath: () => dispatch(clearSetPath()),
    clearOrgExportListError: () => dispatch(clearOrgExportListError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization)