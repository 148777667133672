//--------------------ACCOUNTS-CREATE-----------------------------------
export const ACCOUNT_CATEGORY_CREATE_OPEN = "ACCOUNT_CATEGORY_CREATE_OPEN";
export const ACCOUNT_CATEGORY_CREATE_CLOSE = "ACCOUNT_CATEGORY_CREATE_CLOSE";
export const ACCOUNT_CATEGORY_CREATE_PENDING = "ACCOUNT_CATEGORY_CREATE_PENDING";
export const ACCOUNT_CATEGORY_CREATE_ERROR = "ACCOUNT_CATEGORY_CREATE_ERROR";
export const ACCOUNT_CATEGORY_CREATE_ERROR_CLEAR = "ACCOUNT_CATEGORY_CREATE_ERROR_CLEAR";
export const ACCOUNT_CATEGORY_CREATE_SUCCESS = "ACCOUNT_CATEGORY_CREATE_SUCCESS";

//--------------------ACCOUNTS-UPDATE-----------------------------------
export const ACCOUNT_CATEGORY_UPDATE_OPEN = "ACCOUNT_CATEGORY_UPDATE_OPEN";
export const ACCOUNT_CATEGORY_UPDATE_CLOSE = "ACCOUNT_CATEGORY_UPDATE_CLOSE";
export const ACCOUNT_CATEGORY_UPDATE_PENDING = "ACCOUNT_CATEGORY_UPDATE_PENDING";
export const ACCOUNT_CATEGORY_UPDATE_ERROR = "ACCOUNT_CATEGORY_UPDATE_ERROR";
export const ACCOUNT_CATEGORY_UPDATE_SUCCESS = "ACCOUNT_CATEGORY_UPDATE_SUCCESS";
export const ACCOUNT_CATEGORY_UPDATE_ERROR_CLEAR = "ACCOUNT_CATEGORY_UPDATE_ERROR_CLEAR";
export const SET_ACCOUNT_CATEGORY_DETAILS = "SET_ACCOUNT_CATEGORY_DETAILS";

//--------------------ACCOUNTS-LIST-----------------------------------
export const SET_ACCOUNT_CATEGORY_LIST = "SET_ACCOUNT_CATEGORY_LIST";
export const ACCOUNT_CATEGORY_LIST_PENDING = "ACCOUNTS_CATEGORY_LIST_PENDING";
export const ACCOUNT_CATEGORY_LIST_ERROR = "ACCOUNTS_CATEGORY_LIST_ERROR";
export const ACCOUNT_CATEGORY_LIST_ERROR_CLEAR = "ACCOUNTS_CATEGORY_LIST_ERROR_CLEAR";

//--------------------ACCOUNTS-DETAILS-----------------------------------
export const SET_ACCOUNTS_DETAILS = "SET_ACCOUNTS_DETAILS";
export const ACCOUNTS_DETAILS_PENDING = "ACCOUNTS_DETAILS_PENDING";
export const ACCOUNTS_DETAILS_ERROR = "ACCOUNTS_DETAILS_ERROR";
export const ACCOUNTS_DETAILS_ERROR_CLEAR = "ACCOUNTS_DETAILS_ERROR_CLEAR";
