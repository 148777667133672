import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Async from 'react-select/async';
import { EditGroups, getAppNameAsyncSelect, getRoleNameAsyncSelect, getUserNameAsyncSelect, updateGroupSuccess } from '../../../../../store/Modules/Admin/Group/actions';
import { getOrgNameAsyncSelect } from '../../../../../store/Modules/Admin/OrgUser/actions';
import VWMessage from '../../../../../components/vwMessage/vwMessage';
import { Button, FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';
import VWButton from '../../../../../components/vwButton/VWButton';
import FormValidator from '../../../../../helper/formValidator';

const EditGroup = (props) => {

    const [formFields, setFormFields] = useState({});
    const [prevOrgName, setprevOrgName] = useState([{ label: '', value: '' }]);
    const [prevRoleId, setprevRoleId] = useState([{ label: '', value: '' }]);
    const [prevApps, setprevApps] = useState([{ label: '', value: '' }]);
    const [prevUser, setprevUser] = useState([{ label: '', value: '' }]);
    const [errors, setErrors] = useState({});
    const [validation1, setValidation1] = useState({ emailValid1: true });
    const [validation2, setValidation2] = useState({ emailValid2: true });
    const [existingAppDetails, setExistingAppDetails] = useState([]);
    const [groupEmailValid, setgroupEmailValid] = useState(true);
    const [groupOwnerEmailValid, setgroupOwnerEmailValid] = useState(true);
    const [selectedRoles, setSelectedRoles] = useState();
    const [keyChange, setKeyChange] = useState(1);
    const [keyUserChange, setKeyUserChange] = useState(1);

    const roles = props.currentProfile && props.currentProfile.roles && props.currentProfile.roles.map((data) => data.roleName);

    const { userAppDetails } = props.currentProfile;
    const App = userAppDetails.filter((i) => {
        return (i.appName === "Admin") ? i.appId : ""
    })
    console.log("App:", App[0] && App[0].appId);

    useEffect(() => {
        props.updateGroupSuccess();
        if (props.GroupDetails) {
            setprevOrgName({ label: props.GroupDetails && props.GroupDetails.orgName, value: props.GroupDetails && props.GroupDetails.orgId })
        }
        if (props.GroupDetails && props.GroupDetails.orgGroupRoleDetails) {
            const { orgGroupRoleDetails } = props.GroupDetails || {};
            const groupRoleArray = orgGroupRoleDetails && orgGroupRoleDetails.map((i) => {
                return {
                    label: i.roleName,
                    value: i.roleId
                }
            })
            const groupRole = groupRoleArray && groupRoleArray.length > 0 ? groupRoleArray[0] : null
            setSelectedRoles(groupRole && groupRole.label);
            if (groupRole && groupRole.label) {
                setKeyChange(keyChange + 1);
                setKeyUserChange(keyUserChange + 1);
            }
            setprevRoleId(groupRole);

        }
        if (props.GroupDetails && props.GroupDetails.appGroupDetails) {
            const AppTypeList = props.GroupDetails && props.GroupDetails.appGroupDetails && props.GroupDetails.appGroupDetails.map((item) => {
                return {
                    label: item.appName,
                    value: item.appId,
                };
            });
            setprevApps(AppTypeList);
        }
        if (props.GroupDetails && props.GroupDetails.orgGroupUserDetails) {
            const UserTypeList = props.GroupDetails && props.GroupDetails.orgGroupUserDetails && props.GroupDetails.orgGroupUserDetails.map((item) => {
                return {
                    label: item.isGlobalReadOnlyUser ? item.userName : `${item.userName} | ${item.userEmail}`,
                    value: item.userId,
                };
            });
            setprevUser(UserTypeList);
        }
    }, [])

    useEffect(() => {
        let eValue = props.GroupDetails;
        const role = eValue.orgGroupRoleDetails && eValue.orgGroupRoleDetails.map((item) => item.roleId)
        const app = eValue.appGroupDetails && eValue.appGroupDetails.map((item) => item.appId)
        const user = eValue.orgGroupUserDetails && eValue.orgGroupUserDetails.map((item) => item.userId)
        const FormFields = {
            Id: eValue.id,
            orgName: eValue.orgName,
            OrgId: eValue.orgId,
            groupName: eValue.groupName,
            groupEmail: eValue.groupEmail,
            groupOwnerEmail: eValue.groupOwnerEmail,
            RoleId: role[0],
            AppId: app,
            UserId: user,
        }
        setFormFields(FormFields);
    }, [])

    const handleOrgName = (inputValue, callback) => {
        const { getOrgNameAsyncSelectAction } = props;
        return getOrgNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleRole = (inputValue, callback) => {
        const { getRoleNameAsyncSelectAction } = props;
        return getRoleNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback, roles);
    };
    const handleapps = (inputValue, callback) => {
        const { getAppNameAsyncSelectAction } = props;
        if (selectedRoles) {
            return getAppNameAsyncSelectAction(selectedRoles, (inputValue && inputValue.length) > 0 ? inputValue : '', callback);
        } else {
            callback([]);
        }
    };
    const handleUser = (inputValue, callback) => {
        const { getUserNameAsyncSelectAction } = props;
        if (selectedRoles) {
            return getUserNameAsyncSelectAction(App[0] && App[0].appId,selectedRoles, inputValue.length > 0 ? inputValue : '', callback);
        } else {
            callback([]);
        }
    };

    const validateOnChange = (event) => {
        const input = event.target;
        let emailValid = true;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        let result = FormValidator.validate(input);
        if (input.name === 'groupName') {
            setErrors({ ...errors, groupName: '' });
        }
        if (input.name === 'groupEmail') {
            setErrors({ ...errors, groupEmail: '' });
        }
        if (input.name === 'groupOwnerEmail') {
            setErrors({ ...errors, groupOwnerEmail: '' });
        }
        if (input.name === 'groupEmail') {
            let emailValid = true;

            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(value)) {
                result = 'Invalid group email';
                emailValid = false;
            }
            else if (prohibitedChars.test(value)) {
                result = 'Invalid group email';
                emailValid = false;
            }
            else {
                emailValid = true;
            }
            setErrors({
                ...errors,
                groupEmail: emailValid ? null : result,  // Update errors directly
            });
        } if (input.name === 'groupOwnerEmail') {
            let emailValid = true;

            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(value)) {
                result = 'Invalid group owner email';
                emailValid = false;
            }
            else if (prohibitedChars.test(value)) {
                result = 'Invalid group owner email';
                emailValid = false;
            }
            else {
                emailValid = true;
            }
            setErrors({
                ...errors,
                groupOwnerEmail: emailValid ? null : result,  // Update errors directly
            });
        }
        setFormFields({
            ...formFields, [input.name]: value, errors: {
                ...(formFields && formFields.errors),
                [input.name]: result,
            },
        });
    };

    const handleAddOrgName = (value) => {
        setprevOrgName(value);
        setFormFields({ ...formFields, orgId: value.value, orgName: value.label });
        setErrors({ ...errors, UserId: '' });
    }
    const handleAddRole = (value) => {
        setKeyChange(keyChange + 1);
        setKeyUserChange(keyUserChange + 1);
        setSelectedRoles(value.label)
        setprevRoleId(value);
        setFormFields({
            ...formFields,
            RoleId: value.value,
            AppId: [],
            UserId: []
        });
        setErrors({ ...errors, RoleId: '' });
        setprevApps([]);
        setprevUser([]);
        // if (value.label) {
        //     props.getAppNameAsyncSelectAction(value.label, '', (existingAppDetails) => {
        //         setExistingAppDetails(existingAppDetails);
        //     });
        // }
    }
    const handleEditAppName = (value) => {
        const AppID = value.map((item) => item.value)
        setFormFields({ ...formFields, AppId: AppID });
        setprevApps(value);
        setErrors({ ...errors, AppId: '' });
    }
    const handleEditUser = (value) => {
        const UserID = value.map((item) => item.value)
        setFormFields({ ...formFields, UserId: UserID });
        setprevUser(value);
        setErrors({ ...errors, UserId: '' });
    }
    // useEffect(() => {
    //     if (prevRoleId && prevRoleId.label.toLowerCase() === 'super admin') {
    //         const filteredOptions = existingAppDetails.map((app) => ({
    //             ...app,
    //             isDisabled: app.appName.toLowerCase() !== 'admin',
    //         }));
    //         setExistingAppDetails(filteredOptions);
    //     } else {
    //         setExistingAppDetails([]);
    //     }
    // }, [prevRoleId, existingAppDetails]);
    //--------------------------------------------Update ---------------------------------------------------------
    const handleEditGroupUser = () => {
        const newErrors = {};
        if (!(formFields && formFields.OrgId) || !(formFields && formFields.OrgId && formFields.OrgId.trim())) {
            newErrors.OrgId = 'Org name is required';
        }
        if (!(formFields && formFields.RoleId)) {
            newErrors.OrgId = 'Role is required';
        }
        if (!(formFields && formFields.groupName)) {
            newErrors.groupName = 'Group name is required';
        }
        if (!(formFields && formFields.groupEmail)) {
            newErrors.groupEmail = 'Group email is required';
        } else {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(formFields.groupEmail)) {
                newErrors.groupEmail = 'Invalid group email';
            } else if (prohibitedChars.test(formFields.groupEmail)) {
                newErrors.groupEmail = 'Invalid group email';
            }
        }
        if (!(formFields && formFields.groupOwnerEmail)) {
            newErrors.groupOwnerEmail = 'Group owner email is required';
        } else {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(formFields.groupOwnerEmail)) {
                newErrors.groupOwnerEmail = 'Invalid group owner email';
            } else if (prohibitedChars.test(formFields.groupOwnerEmail)) {
                newErrors.groupOwnerEmail = 'Invalid group owner email';
            }
        }
        if (!(formFields && formFields.AppId && formFields.AppId.length > 0)) {
            newErrors.AppId = 'App(s) is required';
        }
        if (!(formFields && formFields.UserId && formFields.UserId.length > 0)) {
            newErrors.UserId = 'User(s) is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0 && (validation1.emailValid1 && validation1.emailValid1 === true) && (validation2.emailValid2 && validation2.emailValid2 === true)) {
            props.EditGroupAction(formFields);
        }
    };

    console.log("formfields:", formFields);

    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <FormGroup>
                <Label>
                    Group Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="groupName"
                    placeholder="Enter group name"
                    onChange={validateOnChange}
                    value={formFields && formFields.groupName}
                    invalid={errors && errors.groupName}
                />
                {<FormFeedback>{errors && errors.groupName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>
                    Group Email <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="groupEmail"
                    placeholder="Enter group email"
                    onChange={validateOnChange}
                    invalid={errors && errors.groupEmail}
                    value={formFields && formFields.groupEmail}
                />
                <FormFeedback>{errors && errors.groupEmail}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label>
                    Group Owner Email <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="groupOwnerEmail"
                    placeholder="Enter group owner email"
                    onChange={validateOnChange}
                    invalid={errors && errors.groupOwnerEmail}
                    value={formFields && formFields.groupOwnerEmail}
                />
                <FormFeedback>{errors && errors.groupOwnerEmail}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label>
                    Role <span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.RoleId) ? '#FF3D60' : "#CED4DA"
                        })
                    }}
                    name="RoleId"
                    placeholder="Select Role"
                    isSearchable
                    defaultOptions
                    loadOptions={handleRole}
                    value={prevRoleId}
                    onChange={handleAddRole}
                />
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.RoleId}
                    </div>
                </FormText>
            </FormGroup>
            <FormGroup>
                <Label>
                    App(s) <span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.AppId) ? '#FF3D60' : "#CED4DA"
                        })
                    }}
                    key={keyChange}
                    name="AppId"
                    placeholder="Select app(s)"
                    isSearchable
                    isMulti
                    defaultOptions
                    loadOptions={handleapps}
                    value={prevApps}
                    onChange={handleEditAppName}
                /> <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.AppId}
                    </div>
                </FormText>
            </FormGroup>
            <FormGroup>
                <Label>
                    User(s) <span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.UserId) ? '#FF3D60' : "#CED4DA"
                        })
                    }}
                    key={keyUserChange}
                    name="UserId"
                    placeholder="Select user(s)"
                    isSearchable
                    isMulti
                    defaultOptions
                    loadOptions={handleUser}
                    value={prevUser}
                    onChange={handleEditUser}
                /> <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.UserId}
                    </div>
                </FormText>
            </FormGroup>
            <br></br>
            <FormGroup>
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleEditGroupUser}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </FormGroup>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => ({
    GroupDetails: state.AdminGroup.groupDetails,
    isPending: state.AdminGroup.isUpdateGroupPending,
    errorMessage: state.AdminGroup.updateGroupErrorMessage,
    currentProfile: state.login.currentProfile
});
const mapDispatchToProps = (dispatch) => ({
    getOrgNameAsyncSelectAction: (searchValue, callback) => dispatch(getOrgNameAsyncSelect(searchValue, callback)),
    getRoleNameAsyncSelectAction: (searchValue, callback, roles) => dispatch(getRoleNameAsyncSelect(searchValue, callback, roles)),
    getAppNameAsyncSelectAction: (Role, searchValue, callback) => dispatch(getAppNameAsyncSelect(Role, searchValue, callback)),
    getUserNameAsyncSelectAction: (appId, Role, searchValue, callback) => dispatch(getUserNameAsyncSelect(appId,Role, searchValue, callback)),
    EditGroupAction: (formFields) => dispatch(EditGroups(formFields)),
    updateGroupSuccess: () => dispatch(updateGroupSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup)