import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Badge, Card, CardBody, CardTitle, Col, Table, Row, Button } from "reactstrap";
import { clearChangePasswordError, clearChangePasswordErrors, clearSetPath, closeChangePasswordModal, closeDisplayPictureModal, closeModalChangePassword, getCurrentProfile, openChangePasswordModal, openDisplayPictureModal, openModalChangePassword } from "../../../store/actions";
import VWModel from "../../../components/Modal/VWModal";
import ChangePassword from "./ChangePassword";
import { Link } from "react-router-dom";
import DisplayPicture from "./DisplayPicture";

const UserProfile = (props) => {

    useEffect(() => {
        // props.getCurrentProfile();
        props.ClearPath();
    }, [])

    const { displayName, userEmail, orgName, orgEmail, roles, userAppDetails, firstName, lastName, groupDetails, isManager } = props.profile;

    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleOpenChangePassword = () => {
        props.openChangePasswordModal();
    }
    const handleClose = () => {
        props.closeChangePasswordModal();
        props.clearChangePasswordErrors();
        props.closeDisplayPictureModal();
    };
    const handleDisplayPicture = () => {
        props.openDisplayPictureModal();
    }
    return (
        <>
            <div className="page-content">
                <Card >
                    <CardBody>
                        <CardTitle className="d-flex justify-content-between align-items-center">
                            <strong className='d-flex align-items-center'>{displayName && displayName.toUpperCase()} - PROFILE</strong>
                            <Link type="button"
                                className="btn btn-info mt-2"
                                onClick={handleDisplayPicture}
                            >
                                EDIT
                            </Link>
                        </CardTitle>
                    </CardBody>
                </Card>
                <Card>
                    <Col >
                        <CardTitle >
                            <Table striped size="sm" style={{ padding: "75px" }}>
                            </Table>
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <div className="table-responsive">
                                        <Table className="table table-striped table-hover table-nowrap">
                                            <tbody>
                                                <tr>
                                                    <th>Username</th>
                                                    <td>{displayName || 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th>First Name</th>
                                                    <td>{firstName || 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Last Name</th>
                                                    <td>{lastName || 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th>User Email</th>
                                                    <td>{userEmail || 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Organization Name</th>
                                                    <td className="text-capitalize">{orgName || 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Organization Email</th>
                                                    <td>{orgEmail || 'NA'}</td>
                                                </tr>
                                                <tr>
                                                    <th> Group(s)</th>
                                                    <td>
                                                        {(groupDetails && groupDetails.length > 0) ?
                                                            (groupDetails && groupDetails.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    <React.Fragment key={index}>
                                                                        <Row>
                                                                            <Col md={6} className='p-1'>
                                                                                <Badge
                                                                                    className="rounded-1 p-2 text-light text-capitalize"
                                                                                    color="success"
                                                                                >
                                                                                    {item.groupName || 'NA'}
                                                                                </Badge>
                                                                            </Col>
                                                                        </Row>
                                                                    </React.Fragment>
                                                                </React.Fragment>
                                                            ))) : 'Unassigned'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Manager Status</th>

                                                    <td className="text-capitalize">
                                                        {isManager ? (
                                                            <Badge color="success" className="text-dark-white">
                                                                Yes
                                                            </Badge>
                                                        ) : (
                                                            <Badge color="danger" className="text-dark-white">
                                                                No
                                                            </Badge>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <Table className="table table-striped table-hover table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>App Name</th>
                                                            <th>Role Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(userAppDetails && userAppDetails.length > 0) ? (userAppDetails && userAppDetails.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.appName || 'NA'}</td>
                                                                <td>
                                                                    {item && item.appRoleDetails.map((role, roleIndex) => (
                                                                        <React.Fragment key={roleIndex}>
                                                                            {role.roleName || "NA"}
                                                                            {roleIndex < item.appRoleDetails.length - 1 && ','}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </td>
                                                            </tr>
                                                        ))) : <td className="d-flex justify-content-end mt-2">There are no apps assigned to you</td>}
                                                    </tbody>
                                                </Table>
                                                <hr />
                                                <br />
                                                <Button color='success' onClick={handleOpenChangePassword}>Change Password</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Card>
                <VWModel
                    header="CHANGE PASSWORD"
                    // modalSize="extra-large"
                    isOpen={props.isOpenChangePassword}
                    handleClose={handleClose}
                >
                    <ChangePassword
                        handleClose={handleClose}
                    />
                </VWModel>
                <VWModel
                    header="EDIT PROFILE"
                    // modalSize="extra-large"
                    isOpen={props.isOpenDisplayPicture}
                    handleClose={handleClose}
                >
                    <DisplayPicture
                        handleClose={handleClose}
                    />
                </VWModel>

            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    profile: state.login.currentProfile,
    isOpenChangePassword: state.login.isOpenChangePassword,
    isOpenDisplayPicture: state.login.isOpenDisplayPicture,
})

const mapDispatchToProps = (dispatch) => ({
    getCurrentProfile: () => dispatch(getCurrentProfile()),
    openChangePasswordModal: () => dispatch(openModalChangePassword()),
    openDisplayPictureModal: () => dispatch(openDisplayPictureModal()),
    closeChangePasswordModal: () => dispatch(closeModalChangePassword()),
    closeDisplayPictureModal: () => dispatch(closeDisplayPictureModal()),
    clearChangePasswordErrors: () => dispatch(clearChangePasswordErrors()),
    ClearPath: () => dispatch(clearSetPath())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);