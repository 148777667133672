
import {
  CREATE_USER_ERROR,
  CREATE_USER_PENDING,
  CREATE_USER_SUCCESS,
  CLEAR_USER_ERROR,
  SET_INITIAL_STATE,
  OPEN_CREATE_USER_MODAL,
  CLOSE_CREATE_USER_MODAL,
  SET_USERS_PENDING,
  SET_USERS_LIST,
  SET_USERS_LIST_ASYNC,
  SET_RETAILERS_LIST_ASYNC,
  SET_FILTERED_STATES_LIST_ASYNC,
  ADD_USER_FORM,
  SET_LOADING,
  CHANGE_PASSWORD_FORM,
  ENABLE_USER_ACCOUNT_FORM,
  SET_TEST_VALUE,
  SUBMIT_PENDING,
  SUBMIT_SUCCESS,
  SUBMIT_ERROR,
  CREATE_USERS_PENDING,
  CREATE_USERS_SUCCESS,
  CREATE_USERS_ERROR,
  CLEAR_CREATE_USERS_ERROR,
} from "../users/actionTypes";

const initialState = {
  errorMessage: null,
  isPending: false,
  isCreateUserModalOpen: false,
  isGetUserPending: false,
  users: [],
  usersListAsync: [],
  searchValueAsync: "",
  retailersListAsync: [],
  totalRecords: 0,
  Addform: {},
  loading: true,
  passwordForm: {},
  enableAccount: {},
  loading: "",
  isfar: true,
  data: false,
  status: "",
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...initialState,
        isCreateUserModalOpen: true,
      };
    }

    case SUBMIT_PENDING:
      return { status: "pending" };
    case SUBMIT_SUCCESS:
      return { status: "success" };
    case SUBMIT_ERROR:
      return { status: "error" };

    case CREATE_USERS_PENDING:
      return {
        ...state,
        status: "pending",
      };
    case CREATE_USERS_SUCCESS:
      return {
        ...state,
        status: "success",
      };
    case CREATE_USERS_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload,
      };
    case CLEAR_CREATE_USERS_ERROR:
      return {
        ...state,
        error: null,
      };


    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        isPending: false,
      };
    }
    case CREATE_USER_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        errorMessage: errorMessage,
        isPending: false,
      };
    }

    case CREATE_USER_PENDING: {
      return {
        ...state,
        isPending: true,
      };
    }
    case CLEAR_USER_ERROR: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    case OPEN_CREATE_USER_MODAL: {
      return {
        ...state,
        isCreateUserModalOpen: true,
      };
    }
    case CLOSE_CREATE_USER_MODAL: {
      return {
        ...state,
        errorMessage: null,
        isCreateUserModalOpen: false,
      };
    }
    case SET_USERS_PENDING: {
      return {
        ...state,
        isGetUserPending: true,
      };
    }
    case ADD_USER_FORM: {
      const { Addform } = action.payload;
      // console.log("Addform", Addform);
      return {
        ...state,
        Addform: Addform,

      }
    }
    case ENABLE_USER_ACCOUNT_FORM: {
      const { enableAccount } = action.payload;
      // console.log("enable", enableAccount);
      return {
        ...state,
        enableAccount: enableAccount
      }
    }
    case CHANGE_PASSWORD_FORM: {
      const { passwordForm } = action.payload;

      return {
        ...state,
        passwordForm: passwordForm
      }
    }

    case SET_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading

      };
    }

    case SET_TEST_VALUE: {
      const { data } = action.payload;
      // console.log("Reducer Data", data)
      return {
        ...state,
        data,

      };
    }

    case SET_USERS_LIST: {
      const { usersOdata } = action.payload;
      // console.log("users",usersOdata)
      return {
        ...state,
        isGetUserPending: false,
        users: usersOdata && usersOdata.value,
        totalRecords: usersOdata["@odata.count"],
      };
    }
    case SET_USERS_LIST_ASYNC: {
      const { usersListAsync, searchValueAsync } = action.payload;
      return {
        ...state,
        usersListAsync,
        searchValueAsync
      };
    }
    case SET_RETAILERS_LIST_ASYNC: {
      const { retailersListAsync, searchValueAsync } = action.payload;
      return {
        ...state,
        retailersListAsync,
        searchValueAsync
      };
    }
    case SET_FILTERED_STATES_LIST_ASYNC: {
      const { statesListAsync, searchValueAsync } = action.payload;
      return {
        ...state,
        statesListAsync: statesListAsync,
        searchValueAsync
      }
    }
    default: {
      return state;
    }
  }
};
