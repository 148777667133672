const VisionDocsSidebarData = [
    {
        label: "Vision Docs",
        isMainMenu: true,
    },
    {
        label: "Documents",
        // icon: "mdi mdi-calendar-outline",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/visiondocs/documents",
    },
]

export default VisionDocsSidebarData;