import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import moment from "moment";
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { ClearLeaveCount, clearLeaveCountError, closeEditLeaveModal, getleaveDetailsAction, openEditLeaveModal } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions';
import { connect } from 'react-redux';
import VWModel from '../../../../components/Modal/VWModal';
import EditLeave from './leaveUpdate';
import { clearForm } from '../../../../store/form/actions';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';

const Details = (props) => {
    const params = useParams();
    const { id } = params;

    useEffect(() => {
        props.clearSetLoginPath();
        props.getleaveDetails(id);
        props.ClearPath();
    }, [props.getleaveDetails, id]);

    useEffect(() => {
        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();            
        }
    }, []);

    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditleaveModel = () => {
        const { openEditLeaveModalAction } = props;
        openEditLeaveModalAction();
    };

    const handleCloseEditLeaveModal = () => {
        const { closeEditLeaveModalAction, clearLeaveCountError } = props;
        closeEditLeaveModalAction();
        clearLeaveCountError();
        props.ClearLeaveCount();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>APPLY LEAVE DETAILS</strong>
                        {!props.errorMessage && (props.LeaveDetails && props.LeaveDetails.leaveStatus && props.LeaveDetails.leaveStatus.toLowerCase() === 'submitted') ?
                            <Link type="button"
                                className="btn btn-info"
                                onClick={handleOpenEditleaveModel}
                            >
                                EDIT
                            </Link> : ""
                        }
                    </CardTitle>
                    <CardBody className="p-4">
                        {props.errorMessage ? <div className='w-100'>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        {props.isLeaveTrackerDetailsPending ?
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                            :
                            <Row>
                                <div>
                                    <div className="p-3 bg-light fw-bold fs-4">
                                        <span>{props.LeaveDetails && props.LeaveDetails.userName || "N/A"}</span>
                                    </div>
                                </div>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Username</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.userName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>User Email</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.userEmail || "N/A"}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td>
                                                                <strong>Org Name</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.orgName || "N/A"}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <td>
                                                                <strong>From Date</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.fromDateTime ? moment(props.LeaveDetails && props.LeaveDetails.fromDateTime).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>To Date</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.toDateTime ? moment(props.LeaveDetails && props.LeaveDetails.toDateTime).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Total Leave</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.totalLeaveCount || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Reason</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.leaveReason || "N/A"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Total Leave Days</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.totalLeaveDays && props.LeaveDetails.totalLeaveDays.map((items, index) => (
                                                                //eslint-disable-next-line
                                                                <React.Fragment key={index}>
                                                                    <Badge color='success'>{moment(items).format('DD MMM YYYY')}</Badge>&nbsp;
                                                                </React.Fragment>
                                                            )) || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Type</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.leaveType || "N/A"}</td>
                                                        </tr>
                                                        {/* <tr>
                                                            <td>
                                                                <strong>Category</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.category || "N/A"}</td>
                                                        </tr> */}
                                                        <tr>
                                                            <td>
                                                                <strong>Status</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.leaveStatus || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Status Description</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.statusDescription || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Created By</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.createdBy || 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Updated By</strong>
                                                            </td>
                                                            <td>{props.LeaveDetails && props.LeaveDetails.updatedBy || 'N/A'}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                    <VWModel
                        header="EDIT LEAVE"
                        isOpen={props.isEditLeaveModalOpen}
                        handleClose={handleCloseEditLeaveModal}
                    >
                        <EditLeave handleClose={handleCloseEditLeaveModal} />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    LeaveDetails: state.leaveTracker.LeaveDetails,
    errorMessage: state.leaveTracker.leaveTrackerDetailsError,
    isEditLeaveModalOpen: state.leaveTracker.isEditLeaveModalOpen,
    isLeaveTrackerDetailsPending: state.leaveTracker.isLeaveTrackerDetailsPending,
});

const mapDispatchToProps = (dispatch) => ({
    getleaveDetails: (Id) => dispatch(getleaveDetailsAction(Id)),
    openEditLeaveModalAction: () => dispatch(openEditLeaveModal()),
    closeEditLeaveModalAction: () => dispatch(closeEditLeaveModal()),
    clearFormAction: () => dispatch(clearForm()),
    clearLeaveCountError: () => dispatch(clearLeaveCountError()),
    ClearLeaveCount: () => dispatch(ClearLeaveCount()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Details);