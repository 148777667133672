//--------------------LEADS-CREATE-----------------------------------
export const LEAD_CATEGORY_CREATE_OPEN = "LEAD_CATEGORY_CREATE_OPEN";
export const LEAD_CATEGORY_CREATE_CLOSE = "LEAD_CATEGORY_CREATE_CLOSE";
export const LEAD_CATEGORY_CREATE_PENDING = "LEAD_CATEGORY_CREATE_PENDING";
export const LEAD_CATEGORY_CREATE_ERROR = "LEAD_CATEGORY_CREATE_ERROR";
export const LEAD_CATEGORY_CREATE_ERROR_CLEAR = "LEAD_CATEGORY_CREATE_ERROR_CLEAR";
export const LEAD_CATEGORY_CREATE_SUCCESS = "LEAD_CATEGORY_CREATE_SUCCESS";

//--------------------LEADS-UPDATE-----------------------------------
export const LEAD_CATEGORY_UPDATE_OPEN = "LEAD_CATEGORY_UPDATE_OPEN";
export const LEAD_CATEGORY_UPDATE_CLOSE = "LEAD_CATEGORY_UPDATE_CLOSE";
export const LEAD_CATEGORY_UPDATE_PENDING = "LEAD_CATEGORY_UPDATE_PENDING";
export const LEAD_CATEGORY_UPDATE_ERROR = "LEAD_CATEGORY_UPDATE_ERROR";
export const LEAD_CATEGORY_UPDATE_SUCCESS = "LEAD_CATEGORY_UPDATE_SUCCESS";
export const LEAD_CATEGORY_UPDATE_ERROR_CLEAR = "LEAD_CATEGORY_UPDATE_ERROR_CLEAR";
export const SET_LEAD_CATEGORY_DETAILS = "SET_LEAD_CATEGORY_DETAILS";

//--------------------LEADS-LIST-----------------------------------
export const SET_LEAD_CATEGORY_LIST = "SET_LEAD_CATEGORY_LIST";
export const LEAD_CATEGORY_LIST_PENDING = "LEAD_CATEGORY_LIST_PENDING";
export const LEAD_CATEGORY_LIST_ERROR = "LEAD_CATEGORY_LIST_ERROR";
export const LEAD_CATEGORY_LIST_ERROR_CLEAR = "LEAD_CATEGORY_LIST_ERROR_CLEAR";
export const IS_LEAD_CATEGORY_INLINE_EDIT = "IS_LEAD_CATEGORY_INLINE_EDIT";

