import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Input, Button } from "reactstrap";
import { Fragment } from "react";
import { resetPassword, resetPasswordForm } from "../../store/actions.js";



const mapStateToProps = (state) => ({
  formReset: state.login.formReset,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetPasswordForm: (formReset) => dispatch(resetPasswordForm(formReset)),
  resetPassword: (code: string) => dispatch(resetPassword(code)),

});

const ResetPassword = (props) => {

  const [isPasswordsNotEqual, setIsPasswordsNotEqual] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    const {
      match: { params },
    } = props;
    setCode(params.code);
  }, []);

  const validateOnChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    // console.log(props.formReset)
    props.resetPasswordForm({ ...props.formReset, [name]: value })
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.resetPassword(code);
  };
  // console.log(code);
  return (
    <div className="page-content">
      <Fragment>
        <div className="block-center mt-4 wd-xxl">
          <div className="card card-flat">
            <div className="card-header text-center bg-primary">
              <a href="">
                <img
                  className="block-center rounded"
                  src="img/logo.png"
                  alt="Logo"
                  width="150px"
                />
              </a>
            </div>
            <div className="card-body">
              <p className="text-center py-2">ENTER NEW PASSWORD.</p>

              <Form className="mb-3" name="formResetPassword">
                <div className="form-group">
                  <div className="input-group with-focus">
                    <Input
                      type="email"
                      name="email"
                      className="border-right-0"
                      placeholder="Enter email address"

                      onChange={validateOnChange}

                    />
                    <div className="input-group-append">
                      <span className="input-group-text text-muted bg-transparent border-left-0">
                        <em className="fa fa-envelope"></em>
                      </span>
                    </div>

                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group with-focus">
                    <Input
                      type="password"
                      id="id-password"
                      name="password"
                      className="border-right-0"
                      placeholder="Password"
                      onChange={validateOnChange}
                    />
                    {/* <div className="input-group-append">
                      <span className="input-group-text text-muted bg-transparent border-left-0">
                        <em className="fa fa-lock"></em>
                      </span>
                    </div>
                    <span className="invalid-feedback">
                      {isPasswordsNotEqual ? "" : "Password is required"}
                    </span>
                  </div> */}
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group with-focus">
                    <Input
                      type="password"
                      id="id-confirmpassword"
                      name="confirmPassword"
                      className="border-right-0"
                      placeholder="Confirm password"
                      onChange={validateOnChange}
                    />
                    {/* <div className="input-group-append">
                      <span className="input-group-text text-muted bg-transparent border-left-0">
                        <em className="fa fa-lock"></em>
                      </span>
                    </div>
                    <span className="invalid-feedback">
                      {isPasswordsNotEqual
                        ? "The passwords you have entered do not match."
                        : "Confirm password is required"}
                    </span>
                  </div> */}
                  </div>
                </div>
                <Button
                  color="primary"
                  onClick={onSubmit}
                ></Button>
              </Form>
            </div>
          </div>
          <div className="p-3 text-center">
            <span className="mr-2">&copy;</span>
            <span>2023</span>
            <span className="mx-2">-</span>
            <span>Visionware Technologies</span>
            <br />
          </div>
        </div>
      </Fragment>
    </div>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
