import { CLEAR_LEAVE_MASTER_DETAILS_ERROR, CLEAR_LEAVE_MASTER_ERROR, CLEAR_LEAVE_MASTER_EXPORT_LIST_ERROR, CLEAR_LEAVE_MASTER_LIST_ERROR, CLEAR_UPDATE_LEAVE_MASTER_ERROR, CLOSE_CREATE_LEAVE_MASTER_MODAL, CLOSE_EDIT_LEAVE_MASTER_MODAL, CREATE_LEAVE_MASTER_ACTION_SUCCESS, CREATE_LEAVE_MASTER_ERROR, CREATE_LEAVE_MASTER_PENDING, OPEN_CREATE_LEAVE_MASTER_MODAL, OPEN_EDIT_LEAVE_MASTER_MODAL, SET_LEAVE_MASTER_DETAILS, SET_LEAVE_MASTER_DETAILS_ERROR, SET_LEAVE_MASTER_DETAILS_PENDING, SET_LEAVE_MASTER_EXPORT_LIST, SET_LEAVE_MASTER_EXPORT_LIST_ERROR, SET_LEAVE_MASTER_EXPORT_PENDING, SET_LEAVE_MASTER_LIST, SET_LEAVE_MASTER_LIST_ERROR, SET_LEAVE_MASTER_PENDING, UPDATE_LEAVE_MASTER_ERROR, UPDATE_LEAVE_MASTER_PENDING, UPDATE_LEAVE_MASTER_SUCCESS } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    LeaveMasterList: [],
    leaveMasterListError: null,
    leaveMasterDetails: null,
    leaveMasterDetailsError: null,
    totalRecords: 0,
    isCreateLeaveMasterModalOpen: false,
    isEditLeaveMasterModalOpen: false,
    updateleaveMasterErrorMessage: '',
    isUpdateLeaveMasterPending: false,
    isLeaveMasterPending: false,
    isLeaveMasterDetailsPending: false,
    leaveMasterExportError: null,
    isLeaveMasterExportPending: false,
    LeaveMasterExportList:[],
    leaveMasterExportListError: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_LEAVE_MASTER_EXPORT_LIST: {
            const { leaveMasterExportList } = action.payload;
            return {
                ...localState,
                isLeaveMasterExportPending: false,
                LeaveMasterExportList: leaveMasterExportList
            }
        }
        case SET_LEAVE_MASTER_LIST: {
            const { leaveMasterList } = action.payload;
            return {
                ...localState,
                isLeaveMasterPending: false,
                LeaveMasterList: leaveMasterList && leaveMasterList.value,
                totalRecords: leaveMasterList["@odata.count"],
            }
        }
        case SET_LEAVE_MASTER_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isLeaveMasterPending: false,
                leaveMasterListError: errorMessage,
            };
        }
        case SET_LEAVE_MASTER_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isLeaveMasterExportPending: false,
                leaveMasterExportListError: errorMessage,
            };
        }
        case CLEAR_LEAVE_MASTER_LIST_ERROR: {
            return {
                ...localState,
                leaveMasterListError: null,
            };
        }
        case CLEAR_LEAVE_MASTER_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                isLeaveMasterExportPending: false,
                leaveMasterExportListError: null,
            };
        }
        case SET_LEAVE_MASTER_DETAILS: {
            const { leaveMasterDetails } = action.payload;
            return {
                ...localState,
                isLeaveMasterDetailsPending: false,
                leaveMasterDetails: leaveMasterDetails,
            }
        }
        case SET_LEAVE_MASTER_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leaveMasterDetails: {},
                isLeaveMasterDetailsPending: false,
                leaveMasterDetailsError: errorMessage,
            };
        }
        case CLEAR_LEAVE_MASTER_DETAILS_ERROR: {
            return {
                ...localState,
                leaveMasterDetailsError: null,
            };
        }
        case OPEN_CREATE_LEAVE_MASTER_MODAL: {
            return {
                ...localState,
                isCreateLeaveMasterModalOpen: true,
            };
        }
        case OPEN_EDIT_LEAVE_MASTER_MODAL: {
            return {
                ...localState,
                isEditLeaveMasterModalOpen: true,
            };
        }
        case CLOSE_CREATE_LEAVE_MASTER_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateLeaveMasterModalOpen: false,
            };
        }
        case CLOSE_EDIT_LEAVE_MASTER_MODAL: {
            return {
                ...localState,
                updateleaveMasterErrorMessage: null,
                isEditLeaveMasterModalOpen: false,
            };
        }
        case CREATE_LEAVE_MASTER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_LEAVE_MASTER_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CLEAR_LEAVE_MASTER_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CREATE_LEAVE_MASTER_ACTION_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_UPDATE_LEAVE_MASTER_ERROR: {
            return {
                ...localState,
                updateleaveMasterErrorMessage: null,
                isUpdateLeaveMasterPending: false,
            };
        }
        case UPDATE_LEAVE_MASTER_PENDING: {
            return {
                ...localState,
                isUpdateLeaveMasterPending: true,
            };
        }
        case UPDATE_LEAVE_MASTER_SUCCESS: {
            return {
                ...localState,
                isUpdateLeaveMasterPending: false,
            };
        }
        case UPDATE_LEAVE_MASTER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateleaveMasterErrorMessage: errorMessage,
                isUpdateLeaveMasterPending: false,
            };
        }
        case SET_LEAVE_MASTER_PENDING: {
            return {
                ...localState,
                leaveMasterListError: null,
                isLeaveMasterPending: true
            }
        }
        case SET_LEAVE_MASTER_DETAILS_PENDING: {
            return {
                ...localState,
                leaveMasterDetailsError: null,
                isLeaveMasterDetailsPending: true
            }
        }
        case SET_LEAVE_MASTER_EXPORT_PENDING: {
            return {
                ...localState,
                leaveMasterExportError: null,
                isLeaveMasterExportPending: true
            }
        }
        default: {
            return localState;
        }
    }
};