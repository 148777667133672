import { CLEAR_MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR, CLEAR_MY_TEAM_TIMESHEET_LIST_ERROR, CLEAR_TIMESHEET_ACTION_ERROR, CLEAR_TIMESHEET_ERROR, CLEAR_TIMESHEET_PENDING, CLOSE_ACTION_TIMESHEET_MODAL, CREATE_MY_TEAM_TIMESHEET_SUCCESS, CREATE_TIMESHEET_PENDING, MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR, MY_TEAM_TIMESHEET_EXPORT_LIST_PENDING, MY_TEAM_TIMESHEET_LIST, MY_TEAM_TIMESHEET_LIST_ERROR, MY_TEAM_TIMESHEET_LIST_PENDING, OPEN_ACTION_TIMESHEET_MODAL, SET_MY_TEAM_TIMESHEET_EXPORT_LIST, SET_MY_TEAM_TIMESHEET_LIST, UPDATE_MY_TEAM_TIMESHEET_ERROR } from "./constants";

const initialState = {
  errorMessage: null,
  isActionTimeSheetModalOpen: false,
  MyTeamTimeSheetList: [],
  totalRecords: 0,
  isPending: false,
  updateMyTeamTimeSheetErrorMessage: '',
  isMyTeamTimesheetPending: false,
  myTeamTimesheetErrorMessage: null,
  isMyTeamTimesheetExportPending: false,
  MyTeamTimeSheetExportList: [],
  myTeamTimesheetExportErrorMessage: null,

};

export default (localState = initialState, action) => {
  switch (action.type) {
    case OPEN_ACTION_TIMESHEET_MODAL: {
      return {
        ...localState,
        isActionTimeSheetModalOpen: true,
      };
    }

    case CLOSE_ACTION_TIMESHEET_MODAL: {
      return {
        ...localState,
        errorMessage: null,
        isActionTimeSheetModalOpen: false,
      };
    }

    case SET_MY_TEAM_TIMESHEET_LIST: {
      const { MyTeamTimeSheet } = action.payload;
      return {
        ...localState,
        isMyTeamTimesheetPending: false,
        MyTeamTimeSheetList: MyTeamTimeSheet && MyTeamTimeSheet.value,
        totalRecords: MyTeamTimeSheet["@odata.count"],
      }
    }
    case MY_TEAM_TIMESHEET_LIST: {
      return {
        ...localState,
        isMyTeamTimesheetPending: false,
        MyTeamTimeSheetList:[],
        totalRecords: 0,
      }
    }
    case SET_MY_TEAM_TIMESHEET_EXPORT_LIST: {
      const { MyTeamTimeSheet } = action.payload;
      return {
        ...localState,
        isMyTeamTimesheetExportPending: false,
        MyTeamTimeSheetExportList: MyTeamTimeSheet,
      }
    }
    case MY_TEAM_TIMESHEET_LIST_ERROR: {
      const { myTeamTimesheetErrorMessage } = action.payload;
      return {
        ...localState,
        isMyTeamTimesheetPending: false,
        MyTeamTimeSheetList: [],
        myTeamTimesheetErrorMessage: myTeamTimesheetErrorMessage,
      };
    }
    case MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR: {
      const { myTeamTimesheetErrorMessage } = action.payload;
      return {
        ...localState,
        isMyTeamTimesheetExportPending: false,
        MyTeamTimeSheetExportList: [],
        myTeamTimesheetExportErrorMessage: myTeamTimesheetErrorMessage,
      };
    }
    case CLEAR_MY_TEAM_TIMESHEET_LIST_ERROR: {
      return {
        ...localState,
        myTeamTimesheetErrorMessage: null,
      };
    }
    case CLEAR_MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR: {
      return {
        ...localState,
        myTeamTimesheetExportErrorMessage: null,
      };
    }

    case MY_TEAM_TIMESHEET_LIST_PENDING: {
      return {
        ...localState,
        myTeamTimesheetErrorMessage: null,
        isMyTeamTimesheetPending: true
      }
    }
    case MY_TEAM_TIMESHEET_EXPORT_LIST_PENDING: {
      return {
        ...localState,
        myTeamTimesheetExportErrorMessage: null,
        isMyTeamTimesheetExportPending: true
      }
    }
    case CLEAR_TIMESHEET_ACTION_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case CREATE_TIMESHEET_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_TIMESHEET_PENDING: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case CLEAR_TIMESHEET_ERROR: {
      return {
        ...localState,
        errorMessage: null,
        updateMyTeamTimeSheetErrorMessage: null,
      };
    }
    case CREATE_MY_TEAM_TIMESHEET_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case UPDATE_MY_TEAM_TIMESHEET_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateMyTeamTimeSheetErrorMessage: errorMessage,
        isPending: false,
      };
    }
    default: {
      return localState;
    }
  }
};