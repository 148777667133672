import { getToken, modifyCrmUserList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import {
    MAIL_SETTINGS_CREATE_CLOSE,
    MAIL_SETTINGS_CREATE_ERROR,
    MAIL_SETTINGS_CREATE_ERROR_CLEAR,
    MAIL_SETTINGS_CREATE_OPEN,
    MAIL_SETTINGS_CREATE_PENDING,
    MAIL_SETTINGS_CREATE_SUCCESS,
    MAIL_SETTINGS_LIST_ERROR,
    MAIL_SETTINGS_LIST_ERROR_CLEAR,
    MAIL_SETTINGS_LIST_PENDING,
    MAIL_SETTINGS_UPDATE_CLOSE,
    MAIL_SETTINGS_UPDATE_ERROR,
    MAIL_SETTINGS_UPDATE_ERROR_CLEAR,
    MAIL_SETTINGS_UPDATE_OPEN,
    MAIL_SETTINGS_UPDATE_PENDING,
    MAIL_SETTINGS_UPDATE_SUCCESS,
    SET_MAIL_SETTINGS_DETAILS,
    SET_MAIL_SETTINGS_LIST,
} from "./constants";

//--------------------MAIL-SETTING-CREATE-----------------------------------
export const openAddMailSettingsModal = () => ({
    type: MAIL_SETTINGS_CREATE_OPEN
})
export const closeAddMailSettingsModal = () => ({
    type: MAIL_SETTINGS_CREATE_CLOSE
})
export const createMailSettingsPending = () => ({
    type: MAIL_SETTINGS_CREATE_PENDING
})
export const createMailSettingsError = (errorMessage) => ({
    type: MAIL_SETTINGS_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateMailSettingsError = () => ({
    type: MAIL_SETTINGS_CREATE_ERROR_CLEAR,
})
export const createMailSettingsSuccess = () => ({
    type: MAIL_SETTINGS_CREATE_SUCCESS,
})

export const getCrmUserAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCrmUserAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmUserList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const createNewMailSettings = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createMailSettingsError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createMailSettingsPending());
    dispatch(clearCreateMailSettingsError());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.createMailSetting(accessToken, dataToSend)
            .then(() => {
                dispatch(getMailSettingsList());
                dispatch(createMailSettingsSuccess());
                dispatch(closeAddMailSettingsModal());
                dispatch(showSnackbarStatus('Mail setting created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createMailSettingsError(err.response && err.response.text));
                    return;
                }
                console.log('Create account category server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------MAIL-SETTING-UPDATE-----------------------------------
export const openEditMailSettingsModal = () => ({
    type: MAIL_SETTINGS_UPDATE_OPEN
})
export const closeEditMailSettingsModal = () => ({
    type: MAIL_SETTINGS_UPDATE_CLOSE
})
export const updateMailSettingsPending = () => ({
    type: MAIL_SETTINGS_UPDATE_PENDING
})
export const updateMailSettingsError = (errorMessage) => ({
    type: MAIL_SETTINGS_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateMailSettingsError = () => ({
    type: MAIL_SETTINGS_UPDATE_ERROR_CLEAR,
})
export const updateMailSettingsSuccess = () => ({
    type: MAIL_SETTINGS_UPDATE_SUCCESS,
})
export const setMailSettingsDetails = (settingDetails) => ({
    type: SET_MAIL_SETTINGS_DETAILS,
    payload: { settingDetails }
})

export const updateMailSettings = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateMailSettingsError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateMailSettingsPending());
    dispatch(clearUpdateMailSettingsError());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.updateMailSetting(accessToken, dataToSend)
            .then(() => {
                dispatch(updateMailSettingsSuccess());
                dispatch(closeEditMailSettingsModal());
                dispatch(getMailSettingsList());
                dispatch(showSnackbarStatus('Mail setting updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateMailSettingsError(err.response && err.response.text));
                    return;
                }
                console.log('Create Account server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------MAIL-SETTING-LIST-----------------------------------
export const mailSettingsListPending = () => ({
    type: MAIL_SETTINGS_LIST_PENDING
})
export const mailSettingsListError = (errorMessage) => ({
    type: MAIL_SETTINGS_LIST_ERROR,
    payload: { errorMessage }
})
export const clearMailSettingsListError = () => ({
    type: MAIL_SETTINGS_LIST_ERROR_CLEAR,
})
export const setMailSettingsList = (mailSettingsList) => ({
    type: SET_MAIL_SETTINGS_LIST,
    payload: { mailSettingsList }
})

export const getMailSettingsList = (page, pageSize, searchEmailAddress, searchFromDisplayName, searchSMTPPort, searchUserName, searchPassword, searchIsActive, searchCreatedDate, searchCreatedTimeStamp, searchCreatedBy) => (dispatch) => {
    dispatch(mailSettingsListPending());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getMailSettingList(accessToken, page, pageSize, searchEmailAddress, searchFromDisplayName, searchSMTPPort, searchUserName, searchPassword, searchIsActive, searchCreatedDate, searchCreatedTimeStamp, searchCreatedBy)
            .then((result) => {
                dispatch(setMailSettingsList(result))
                dispatch(clearMailSettingsListError());
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(mailSettingsListError(err.response && err.response.text));
                    return;
                }
                else {
                    dispatch(mailSettingsListError('Network Error'));
                }
                console.log('Mail setting server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
