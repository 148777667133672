import React, { useEffect } from 'react'
import { Badge, Button, Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import VWSpinner from '../../../../components/vwSpinner/vwSpinner'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { closeVisitUpdate, getVisitDetails, openVisitUpdate } from '../../../../store/Modules/crm/Visit/actions'
import moment from 'moment-timezone'
import Edit from './edit'
import VWModel from '../../../../components/Modal/VWModal'

const Details = (props) => {

  const { isVisitDetailPending, visitDetails, visitDetailErrorMessage } = props;
  const { accountName, createdBy, createdTimeStamp, distance, endDate, endLocation, endTime, isAllDayEvent,
    leadName, orgName, ownerUserEmail, ownerUserName, primaryContactName, startDate, startLocation, startTime,
    subject, updatedBy, updatedTimeStamp, visitAddress, visitNotes, visitSource, visitTypeName, visitName, primaryContactEmail, visitStatus, transportMode, visitSourceCategory, visitSourceName, visitSourceId } = visitDetails;

  const params = useParams();
  const { id } = params || {};

  useEffect(() => {
    props.getVisitDetails(id);
  }, [])

  const updateVisitOpen = () => {
    props.openVisitUpdate();
  }

  const handleClose = () => {
    props.closeVisitUpdate();
  }

  console.log("visitDetails:", visitDetails);

  return (
    <div className="page-content">
      <Card>
        <CardTitle className="p-2 mb-0 fw-bold fs-4 text-dark d-flex justify-content-between"> VISIT DETAILS
          <Button color="primary"
            onClick={updateVisitOpen}
          >
            EDIT
          </Button>
        </CardTitle>
      </Card>
      <Card>
        {visitDetailErrorMessage &&
          <VWMessage type="danger" defaultMessage={visitDetailErrorMessage} baseClassName="alert" ></VWMessage>
        }
        {isVisitDetailPending ?
          <div className="d-flex justify-content-center mt-2">
            <VWSpinner />
          </div>
          :
          <CardBody>
            <Row>
              <Col lg="6" md="12" sm="12">
                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span> Visit Information</div>
                <Table responsive>
                  <tbody>
                    <tr>
                      <th>Visit Name</th>
                      <td>
                        {visitSourceCategory && visitSourceCategory.length > 0 ? (
                          (visitSourceCategory === 'Lead') ? (
                            <Link to={`/crm/leads/details/${visitSourceId}`} target='_blank'>
                              {`${visitSourceCategory} - ${visitSourceName || "N/A"}`}
                            </Link>
                          ) : (visitSourceCategory === 'Account') ? (
                            <Link to={`/crm/accounts/details/${visitSourceId}`} target='_blank'>
                              {`${visitSourceCategory} - ${visitSourceName || "N/A"}`}
                            </Link>
                          ) : (
                            "N/A"
                          )
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Visit Type</th>
                      <td>{visitTypeName || "N/A"}</td>
                    </tr>
                    <tr>
                      <th>Subject</th>
                      <td>{subject || "N/A"}</td>
                    </tr>
                    <tr>
                      <th>Primary Contact</th>
                      <td>{`${primaryContactName || "N/A"} - ${primaryContactEmail || "N/A"}`}</td>
                    </tr>
                    <tr>
                      <th>Visit User</th>
                      <td>{`${ownerUserName || "N/A"} - ${ownerUserEmail || "N/A"}`}</td>
                    </tr>
                    <tr>
                      <th>Visit Status</th>
                      <td>{visitStatus || "N/A"}</td>
                    </tr>
                    <tr>
                      <th>Is All Day Event</th>
                      <td> {isAllDayEvent ? (
                        <Badge color="success" className="text-dark-white">
                          Yes
                        </Badge>
                      ) : (
                        <Badge color="danger" className="text-dark-white">
                          No
                        </Badge>
                      )}</td>
                    </tr>
                    <tr>
                      <th> {isAllDayEvent ? 'Start Date' : 'Start Date and Time'}</th>
                      <td>{startDate ? moment(startDate).format('DD MMM YYYY') : 'N/A'}&nbsp; {startTime || ''}</td>
                    </tr>
                    <tr>
                      <th> {isAllDayEvent ? 'End Date' : 'End Date and Time'}</th>
                      <td>{endDate ? moment(endDate).format('DD MMM YYYY') : 'N/A'}&nbsp; {endTime || ''}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col lg="6" md="12" sm="12">
                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span> Address Information</div>
                <Table responsive>
                  <tbody>
                    <tr>
                      <th>Address</th>
                      <td>{visitAddress || "N/A"}</td>
                    </tr>
                    <tr>
                      <th>Distance</th>
                      <td>{distance || 0} KM</td>
                    </tr>
                    <tr>
                      <th>Start Location</th>
                      <td>{startLocation || 'N/A'}</td>
                    </tr>
                    <tr>
                      <th>End Location</th>
                      <td>{endLocation || 'N/A'}</td>
                    </tr>
                    <tr>
                      <th>Transport Mode</th>
                      <td>{transportMode || 'N/A'}</td>
                    </tr>
                  </tbody>
                </Table>
                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span> Description</div>
                <Table responsive>
                  <tbody>
                    <tr>
                      <th className='text-nowrap'>Visit Notes</th>
                      <td>{visitNotes || "N/A"}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        }
      </Card>
      <VWModel
        header="EDIT VISIT"
        modalSize="extra-large"
        isOpen={props.isUpdateVisitOpen}
        handleClose={handleClose}
      >
        <Edit handleClose={handleClose} visitDetails={visitDetails} />
      </VWModel>
    </div>
  )
}


const mapStateToProps = (state) => ({
  visitDetails: state.CrmVisit.visitDetails,
  isUpdateVisitOpen: state.CrmVisit.isUpdateVisitOpen,
  visitDetailErrorMessage: state.CrmVisit.visitDetailErrorMessage,
  isVisitDetailPending: state.CrmVisit.isVisitDetailPending,
})

const mapDispatchToProps = (dispatch) => ({
  getVisitDetails: (id) => dispatch(getVisitDetails(id)),
  openVisitUpdate: () => dispatch(openVisitUpdate()),
  closeVisitUpdate: () => dispatch(closeVisitUpdate()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)