import { getToken, modifyEnableOrgLeaveTypeList, modifyLeaveTypeList, modifyLeaveTypeListLeaveMaster, modifyOrgLeaveTypeList, modifyUserList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { getTimesheetApprovalCount } from "../../Timesheet/TimesheetApproval/actions";
import { getMyTeamDetailsListActionModal, getPendingSubmittedCount } from "../MyTeamLD/actions";
import { CLEAR_AVAILABLE_LEAVE_COUNT, CLEAR_AVAILABLE_LEAVE_COUNT_ERROR, CLEAR_LEAVE_COUNT, CLEAR_LEAVE_COUNT_ERROR, CLEAR_LEAVE_ERROR, CLEAR_LEAVE_TRACKER_ACTION_ERROR, CLEAR_LEAVE_TRACKER_DETAILS_ERROR, CLEAR_LEAVE_TRACKER_ERROR, CLEAR_LEAVE_TRACKER_EXPORT_LIST_ERROR, CLEAR_LEAVE_TRACKER_LIST_ERROR, CLEAR_UPDATE_LEAVE_ERROR, CLOSE_CREATE_APPLY_LEAVE_MODAL, CLOSE_LEAVE_TRACKER_MODAL, CLOSE_UPDATE_APPLY_LEAVE_MODAL, CREATE_AVAILABLE_LEAVE_COUNT_ERROR, CREATE_LEAVE_COUNT_ERROR, CREATE_LEAVE_ERROR, CREATE_LEAVE_PENDING, CREATE_LEAVE_SUCCESS, CREATE_LEAVE_TRACKER_ACTION_ERROR, CREATE_LEAVE_TRACKER_ACTION_SUCCESS, CREATE_LEAVE_TRACKER_STATUS_PENDING, LEAVE_TRACKER_EXPORT_SUCCESS, OPEN_CREATE_APPLY_LEAVE_MODAL, OPEN_LEAVE_TRACKER_MODAL, OPEN_UPDATE_APPLY_LEAVE_MODAL, SET_AVAILABLE_LEAVE_COUNT, SET_AVAILABLE_LEAVE_COUNT_PENDING, SET_CALENDER_DATES, SET_ENABLE_ORG_LEAVE_COUNT, SET_FILTER_DATA, SET_LEAVE_COUNT, SET_LEAVE_COUNT_PENDING, SET_LEAVE_DETAILS, SET_LEAVE_TRACKER_COUNT_LIST, SET_LEAVE_TRACKER_DETAILS_ERROR, SET_LEAVE_TRACKER_DETAILS_PENDING, SET_LEAVE_TRACKER_EXPORT_LIST, SET_LEAVE_TRACKER_EXPORT_LIST_ERROR, SET_LEAVE_TRACKER_EXPORT_PENDING, SET_LEAVE_TRACKER_LIST, SET_LEAVE_TRACKER_LIST_ERROR, SET_LEAVE_TRACKER_PENDING, UPDATE_LEAVE_ERROR, UPDATE_LEAVE_PENDING, UPDATE_LEAVE_SUCCESS } from "./constants";


export const setLeaveTrackerList = (leaveTrackerList) => ({
    type: SET_LEAVE_TRACKER_LIST,
    payload: { leaveTrackerList },
})
export const setLeaveTrackerExportList = (leaveTrackerExportList) => ({
    type: SET_LEAVE_TRACKER_EXPORT_LIST,
    payload: { leaveTrackerExportList },
})
export const setLeaveTypeCountList = (leaveTrackerCountList) => ({
    type: SET_LEAVE_TRACKER_COUNT_LIST,
    payload: { leaveTrackerCountList },
})
export const setCalenderDates = (Dates) => ({
    type: SET_CALENDER_DATES,
    payload: { Dates },
})
export const clearLeaveTrackerListError = () => ({
    type: CLEAR_LEAVE_TRACKER_LIST_ERROR,
})
export const clearLeaveTrackerExportListError = () => ({
    type: CLEAR_LEAVE_TRACKER_EXPORT_LIST_ERROR,
})
export const leaveTrackerListError = (errorMessage) => ({
    type: SET_LEAVE_TRACKER_LIST_ERROR,
    payload: { errorMessage },
})
export const leaveTrackerExportListError = (errorMessage) => ({
    type: SET_LEAVE_TRACKER_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const openActionLeaveTrackerModal = () => ({
    type: OPEN_LEAVE_TRACKER_MODAL,
});
export const clearLeaveTrackerActionError = () => ({
    type: CLEAR_LEAVE_TRACKER_ACTION_ERROR,
});
export const closeLeaveTrackerModalAction = () => ({
    type: CLOSE_LEAVE_TRACKER_MODAL,
});
export const createLeaveTrackerActionPending = () => ({
    type: CREATE_LEAVE_TRACKER_STATUS_PENDING,
});
export const clearCreateLeaveActionError = () => ({
    type: CLEAR_LEAVE_TRACKER_ERROR,
});
export const createLeaveTrackerActionSuccess = () => ({
    type: CREATE_LEAVE_TRACKER_ACTION_SUCCESS,
});
export const CreateLeaveTrackerStatusError = (errorMessage) => ({
    type: CREATE_LEAVE_TRACKER_ACTION_ERROR,
    payload: { errorMessage },
});
export const setLeaveDetails = (LeaveDetails) => ({
    type: SET_LEAVE_DETAILS,
    payload: { LeaveDetails },
})
export const clearLeaveTrackerDetailsError = () => ({
    type: CLEAR_LEAVE_TRACKER_DETAILS_ERROR,
})
export const leaveTrackerDetailsError = (errorMessage) => ({
    type: SET_LEAVE_TRACKER_DETAILS_ERROR,
    payload: { errorMessage },
})
export const openCreateApplyLeaveModalAction = () => ({
    type: OPEN_CREATE_APPLY_LEAVE_MODAL,
});
export const openEditLeaveModal = () => ({
    type: OPEN_UPDATE_APPLY_LEAVE_MODAL,
});
export const closeEditLeaveModal = () => ({
    type: CLOSE_UPDATE_APPLY_LEAVE_MODAL,
});
export const closeCreateApplyLeaveModalAction = () => ({
    type: CLOSE_CREATE_APPLY_LEAVE_MODAL,
});

export const createLeaveError = (errorMessage) => ({
    type: CREATE_LEAVE_ERROR,
    payload: { errorMessage },
});
export const createLeavePending = () => ({
    type: CREATE_LEAVE_PENDING,
});
export const clearCreateLeaveError = () => ({
    type: CLEAR_LEAVE_ERROR,
});
export const createLeaveSuccess = () => ({
    type: CREATE_LEAVE_SUCCESS,
});
export const clearUpdateLeaveError = () => ({
    type: CLEAR_UPDATE_LEAVE_ERROR,
});
export const updateLeavePending = () => ({
    type: UPDATE_LEAVE_PENDING,
});
export const updateLeaveSuccess = () => ({
    type: UPDATE_LEAVE_SUCCESS,
});
export const updateLeaveError = (errorMessage) => ({
    type: UPDATE_LEAVE_ERROR,
    payload: { errorMessage },
});
export const setLeaveTrackerPending = () => ({
    type: SET_LEAVE_TRACKER_PENDING
})
export const setLeaveTrackerExportPending = () => ({
    type: SET_LEAVE_TRACKER_EXPORT_PENDING
})
export const setLeaveTrackerExportSuccess = () => ({
    type: LEAVE_TRACKER_EXPORT_SUCCESS,
})
export const setLeaveTrackerDetailsPending = () => ({
    type: SET_LEAVE_TRACKER_DETAILS_PENDING
})
export const setLeaveCountPending = () => ({
    type: SET_LEAVE_COUNT_PENDING
})
export const ClearLeaveCount = () => ({
    type: CLEAR_LEAVE_COUNT
})
export const setLeaveCount = (LeaveCount) => ({
    type: SET_LEAVE_COUNT,
    payload: { LeaveCount },
})
export const clearLeaveCountError = () => ({
    type: CLEAR_LEAVE_COUNT_ERROR,
})
export const createLeaveCountError = (errorMessage) => ({
    type: CREATE_LEAVE_COUNT_ERROR,
    payload: { errorMessage },
});
export const setAvailableLeaveCountPending = () => ({
    type: SET_AVAILABLE_LEAVE_COUNT_PENDING
})
export const ClearAvailableLeaveCount = () => ({
    type: CLEAR_AVAILABLE_LEAVE_COUNT
})
export const setAvailableLeaveCount = (AvailableLeaveCount) => ({
    type: SET_AVAILABLE_LEAVE_COUNT,
    payload: { AvailableLeaveCount },
})
export const clearAvailableLeaveCountError = () => ({
    type: CLEAR_AVAILABLE_LEAVE_COUNT_ERROR,
})
export const createAvailableLeaveCountError = (errorMessage) => ({
    type: CREATE_AVAILABLE_LEAVE_COUNT_ERROR,
    payload: { errorMessage },
});
export const setEnableOrgLeaveTypeCount = (orgLeaveType) => ({
    type: SET_ENABLE_ORG_LEAVE_COUNT,
    payload: { orgLeaveType },
});

export const getMyLeavetrackerExportList = (searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id) => (dispatch) => {
    dispatch(setLeaveTrackerExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.LeaveTracker.getLeaveExportList(accessToken, searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id)
            .then((Lists) => {
                console.log("Lists:", Lists);
                dispatch(setLeaveTrackerExportSuccess());
                return Promise.resolve(Lists);
                // dispatch(clearLeaveTrackerExportListError());
            })
            .catch((err) => {
                console.log('getMyLeavetrackerExportList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(leaveTrackerExportListError(error));
            });
    });
}
export const getLeaveTrackerListActionModal = (filteredData, page, PageSize, searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id) => (dispatch) => {
    dispatch(setLeaveTrackerPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.getLeaveList(accessToken, filteredData, page, PageSize, searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id)
            .then((Lists) => {
                dispatch(setLeaveTrackerList(Lists));
                dispatch(clearLeaveTrackerListError());
            })
            .catch((err) => {
                console.log('getLeaveTrackerListActionModal server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leaveTrackerListError(error))
                // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
}

export const checkEmployeeUserAccess = (userId) => (dispatch) => {
    return getToken(dispatch).then((accessToken) => {
        return agent.LeaveTracker.checkEmployeeUserAccessaction(accessToken, userId)
            .then((response) => {
                console.log("response:", response);
                return Promise.resolve(response)
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("error message:", err.response)
                    console.log("error message:", err.response.text)
                    // dispatch(CreateLeaveTrackerStatusError(err.response && err.response.text));
                    return;
                } else {
                    // dispatch(CreateLeaveTrackerStatusError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
            });
    }
    )
}


export const getDisableCalenderDates = () => (dispatch) => {
    // dispatch(setLeaveTrackerPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.getDisableDates(accessToken)
            .then((Dates) => {
                console.log(Dates)
                dispatch(setCalenderDates(Dates));
                // dispatch(clearLeaveTrackerListError());
            })
            .catch((err) => {
                console.log('getLeaveTrackerListActionModal server error', err);
                // let error;
                // if (err.status) {
                //     error = err.response && err.response.body && err.response.body.error.message;
                // }
                // else {
                //     error = "Network Error"
                // }
                // dispatch(leaveTrackerListError(error))
                // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
}
//------------------------- Leave tracker Status update--------------------------------------------------------

export const updateLeavetrackerActionList = (leavetrackerAction, filter) => (dispatch) => {
    dispatch(createLeaveTrackerActionPending());
    // dispatch(createLeaveError());
    return getToken(dispatch).then((accessToken) => {
        return agent.LeaveTracker.createLeaveTrackerStatusAction(accessToken, leavetrackerAction)
            .then((response) => {
                dispatch(createLeaveTrackerActionSuccess());
                dispatch(clearForm());
                dispatch(closeLeaveTrackerModalAction());
                // dispatch(getLeaveTrackerListActionModal());
                dispatch(getPendingSubmittedCount());
                dispatch(clearCreateLeaveActionError());
                // window.location.reload();
                dispatch(showSnackbarStatus('Leave Tracker Action created successfully'));
                dispatch(getMyTeamDetailsListActionModal(filter));
                return Promise.resolve(response)
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("error message:", err.response)
                    console.log("error message:", err.response.text)
                    dispatch(CreateLeaveTrackerStatusError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(CreateLeaveTrackerStatusError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(clearForm());
                dispatch(closeLeaveTrackerModalAction());
                // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });

}
//---------------------------------------------------Details----------------------------------------------------------

export const getleaveDetailsAction = (id) => (dispatch) => {
    dispatch(setLeaveTrackerDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.getLeaveDetails(accessToken, id)
            .then(
                (leaveDetails) => {
                    dispatch(setLeaveDetails(leaveDetails))
                    dispatch(clearLeaveTrackerDetailsError());
                }
            )
            .catch((err) => {
                console.log('getleaveDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(leaveTrackerDetailsError(error))
            });
    })
}

//------------------------------------------Leave Type Options List----------------------------------------------------
export const getLeaveTypeAsyncSelect =
    (searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.LeaveTracker.getUserLeaveTypelist(accessToken, searchValue)
                    .then((LeaveTypeList) => {
                        const { value } = LeaveTypeList;
                        console.log("LeaveTypeList:", LeaveTypeList);
                        console.log("value:", value);
                        const updatedLeaveTypeList = modifyLeaveTypeList(value);
                        //eslint-disable-next-line
                        callback && callback(updatedLeaveTypeList);
                    })
                    .catch((err) => {
                        console.log('getLeaveTypeAsyncSelect server error', err && err.response && err.response.body && err.response.body.error && err.response.body.error.message);
                        dispatch(createLeaveError(err && err.response && err.response.body && err.response.body.error && err.response.body.error.message));
                        //eslint-disable-next-line
                        callback && callback();
                    });
            });
        };
//------------------------------------------Leave Type Options List----------------------------------------------------
export const getLeaveTypeListAsyncSelect =
    (searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.LeaveTracker.getAllUserLeaveTypelist(accessToken, searchValue)
                    .then((LeaveTypeList) => {
                        const { value } = LeaveTypeList;
                        console.log("LeaveTypeList:", LeaveTypeList);
                        console.log("value:", value);
                        const updatedLeaveTypeList = modifyLeaveTypeListLeaveMaster(value);
                        //eslint-disable-next-line
                        callback && callback(updatedLeaveTypeList);
                    })
                    .catch((err) => {
                        console.log('getLeaveTypeAsyncSelect server error', err && err.response && err.response.body && err.response.body.error && err.response.body.error.message);
                        dispatch(createLeaveError(err && err.response && err.response.body && err.response.body.error && err.response.body.error.message));
                        //eslint-disable-next-line
                        callback && callback();
                    });
            });
        };
export const getEnableOrgLeaveTypeListAsyncSelect =
    (searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.LeaveTracker.getEnableOrgUserLeaveTypelist(accessToken, searchValue)
                    .then((LeaveTypeList) => {
                        const { value } = LeaveTypeList;
                        console.log("LeaveTypeList:", LeaveTypeList);
                        console.log("value:", value);
                        const updatedLeaveTypeList = modifyEnableOrgLeaveTypeList(value);
                        //eslint-disable-next-line
                        callback && callback(updatedLeaveTypeList);
                    })
                    .catch((err) => {
                        console.log('getEnableOrgLeaveTypeListAsyncSelect server error', err && err.response && err.response.body && err.response.body.error && err.response.body.error.message);
                        dispatch(createLeaveError(err && err.response && err.response.body && err.response.body.error && err.response.body.error.message));
                        //eslint-disable-next-line
                        callback && callback();
                    });
            });
        };
export const getCountforEnableOrgLeaveType = () => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.getEnableOrgUserLeaveTypelist(accessToken)
            .then((LeaveTypeList) => {
                const { value } = LeaveTypeList;
                const updatedLeaveTypeList = modifyEnableOrgLeaveTypeList(value);
                dispatch(setEnableOrgLeaveTypeCount(updatedLeaveTypeList))
            })
            .catch((err) => {
                console.log('getEnableOrgLeaveTypeListAsyncSelect server error', err && err.response && err.response.body && err.response.body.error && err.response.body.error.message);
                dispatch(createLeaveError(err && err.response && err.response.body && err.response.body.error && err.response.body.error.message));
            });
    });
}

export const getEmployeeUserAsyncSelect =
    (appId, searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.LeaveTracker.getOnlyEmployeeUserNameList(accessToken, appId, searchValue)
                    .then((EmployeeUserList) => {
                        const { value } = EmployeeUserList;
                        console.log("EmployeeUserList:", EmployeeUserList);
                        console.log("value:", value);
                        const updatedEmployeeUserList = modifyUserList(value);
                        //eslint-disable-next-line
                        callback && callback(updatedEmployeeUserList);
                    })
                    .catch((err) => {
                        console.log('getLeaveTypeAsyncSelect server error', err && err.response && err.response.body && err.response.body.error && err.response.body.error.message);
                        dispatch(createLeaveError(err && err.response && err.response.body && err.response.body.error && err.response.body.error.message));
                        //eslint-disable-next-line
                        callback && callback();
                    });
            });
        };
export const getLeaveTypeCount =
    () =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.LeaveTracker.getUserLeaveTypelist(accessToken)
                    .then((LeaveTypeList) => {
                        const { value } = LeaveTypeList;
                        console.log("LeaveTypeList:", LeaveTypeList);
                        console.log("value:", value);
                        dispatch(setLeaveTypeCountList(LeaveTypeList));
                        // const updatedLeaveTypeList = modifyLeaveTypeList(value);
                        //eslint-disable-next-line
                        // callback && callback(updatedLeaveTypeList);
                    })
                    .catch((err) => {
                        console.log('getLeaveTypeAsyncSelect server error', err && err.response && err.response.body && err.response.body.error && err.response.body.error.message);
                        // console.log(err.response);
                        // console.log(err && err.response && err.response.body && err.response.body.error && err.response.body.error.message);
                        dispatch(createLeaveError(err && err.response && err.response.body && err.response.body.error && err.response.body.error.message));
                        // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        // callback && callback();
                    });
            });
        };




export const getOrgLeaveTypeAsyncSelect =
    (searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.LeaveTracker.getLeaveTypelist(accessToken, searchValue)
                    .then((LeaveTypeList) => {
                        const { value } = LeaveTypeList;
                        console.log("value:", value);
                        const updatedLeaveTypeList = modifyOrgLeaveTypeList(value);
                        //eslint-disable-next-line
                        callback && callback(updatedLeaveTypeList);
                    })
                    .catch((err) => {
                        console.log('getLeaveTypeAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                        callback && callback();
                    });
            });
        };
//-------------------------------------------- Create Leave -------------------------------------------------------------
export const createLeave = (formFields, reasons, filteredData) => (dispatch) => {

    let dataToSend = {}
    if (!formFields) {
        dispatch(createLeaveError('Please fill required details.'));
        return;
    }
    else {
        const { leaveReason } = reasons;
        dataToSend = trimmedValues({
            ...formFields, leaveReason
        })
    }
    console.log("dataToSend:", dataToSend);
    dispatch(createLeavePending());
    dispatch(clearCreateLeaveError());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.createLeave(accessToken, dataToSend)
            .then(() => {
                dispatch(createLeaveSuccess());
                dispatch(clearForm());
                dispatch(closeCreateApplyLeaveModalAction());
                dispatch(getLeaveTrackerListActionModal(filteredData));
                // dispatch(getMyLeavetrackerExportList());
                dispatch(showSnackbarStatus('Leave Applied Successfully'));
                dispatch(getPendingSubmittedCount()); // trigger myTeam Leaves sidebar count
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createLeaveError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createLeaveError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Create Leave server error', err);
                dispatch(clearForm());
                dispatch(closeCreateApplyLeaveModalAction());
                // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//----------------------------------------- Update Leave -----------------------------------------------------------------
export const updateLeave = (formFields) => (dispatch) => {
    const dataToSend = trimmedValues({
        ...formFields,
        fromDate: formFields && formFields.fromDateTime,
        toDate: formFields && formFields.toDateTime,
        leaveReason: (formFields && formFields.leaveReason && formFields.leaveReason.length) > 0 ? formFields && formFields.leaveReason : null
    })
    dispatch(clearUpdateLeaveError());
    dispatch(updateLeavePending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.editLeave(accessToken, dataToSend)
            .then(() => {
                dispatch(updateLeaveSuccess());
                dispatch(closeEditLeaveModal());
                dispatch(getleaveDetailsAction(formFields.id));
                dispatch(showSnackbarStatus('Leave Application Updated Successfully'));
                dispatch(ClearLeaveCount());
                dispatch(clearLeaveCountError());
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateLeaveError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateLeaveError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Update Leave server error', err);
                dispatch(updateLeaveError(null));
                dispatch(closeEditLeaveModal());
                // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
//--------------------------------------Get Leave Count--------------------------------------------------------------------
export const getLeaveCountAction = (formfields) => (dispatch) => {
    dispatch(ClearLeaveCount());
    dispatch(setLeaveCountPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.getLeaveCount(accessToken, formfields)
            .then(
                (leaveCountDetails) => {
                    console.log("leaveCountDetails:", leaveCountDetails);
                    dispatch(setLeaveCount(leaveCountDetails));
                    dispatch(clearLeaveCountError());
                }
            )
            .catch((err) => {
                console.log('getleaveDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                    console.log(error)
                    dispatch(createLeaveCountError(err.response && err.response.text));
                }
                else {
                    error = "Network Error"
                    // dispatch(leaveCountError(error))
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
            });
    })
}
//--------------------------------------Get Leave Available Leave Count--------------------------------------------------------------------
export const getAvailableLeaveCount = (formfields) => (dispatch) => {
    dispatch(ClearAvailableLeaveCount());
    dispatch(setAvailableLeaveCountPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.getAvailableLeaveCount(accessToken, formfields)
            .then(
                (AvailableleaveCountDetails) => {
                    console.log("AvailableleaveCountDetails:", AvailableleaveCountDetails);
                    dispatch(setAvailableLeaveCount(AvailableleaveCountDetails));
                    dispatch(clearAvailableLeaveCountError());
                    dispatch(clearCreateLeaveError());
                }
            )
            .catch((err) => {
                console.log('getAvailableLeaveCount server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                    console.log(error)
                    dispatch(createAvailableLeaveCountError(err.response && err.response.text));
                }
                else {
                    error = "Network Error"
                    // dispatch(leaveCountError(error))
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
            });
    })
}