import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Table,
  Badge,
  Row,
  Col,
  Tooltip
} from 'reactstrap'
import moment from "moment";
import Badges from '@mui/material/Badge';
import VWPagination from '../../../../components/vwPagination/vwPagination'
import { connect } from 'react-redux'
import { getPager } from '../../../../helper/common';
import { clearForm } from '../../../../store/form/actions';
import {
  clearTimeSheetActionError,
  closeActionMTimeSheetModal,
  getMyTeamTimeSheetExportListAction,
  getMyTeamTimeSheetListActionModal,
  myTeamTimeSheetList,
  openActionTimeSheetModal
} from '../../../../store/Modules/Timesheet/myTeamTimesheet/actions';
import VWModel from '../../../../components/Modal/VWModal';
import MyTeamTimeSheetListAction from './MyTeamTimeSheetAction'
import { useSearchParams } from 'react-router-dom';
import NoRecord from '../../../../components/NoRecords/noRecords';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import {
  clearSetLoginPath,
  clearSetPath
} from '../../../../store/actions';
import { MY_TIMESHEET_EXPORT_HEADERS } from '../../../../store/Modules/Timesheet/myTeamTimesheet/constants';
import { excelPrint, excelSheetExport } from '../../../../helper/export';
import { getTaskScopeListAsyncSelectAction, getTaskStatusAsyncSelectAction, getTaskStatusAsyncSelectTimesheet } from '../../../../store/Modules/ProjectPortal/Task/actions';
import Async from "react-select/async";
import { ElementToolTip } from '../../Projects/vwToolTip';
import { getProjectDropDownUserListAsyncSelectAction, getUserNameAsyncSelect } from '../../../../store/Modules/ProjectPortal/Projects/actions';

const customStyles = {
  container: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'normal'
  }),
};
const ScopeOptions = [
  { label: 'Development', value: 'Development' },
  { label: 'Testing', value: 'Testing' },
  { label: 'Discussion', value: 'Discussion' },
  { label: 'Review', value: 'Review' },
  { label: 'Deployment', value: 'Deployment' },
  { label: 'Documentation', value: 'Documentation' },
]
const Actions = [
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
];
const MyTeamTimeSheet = (props) => {

  const [actionList, setActionsList] = useState({})
  const [PageSize] = useState(20);
  const [Pager, setPager] = useState({ currentPage: 1 })
  const [filter, setFilter] = useState();
  const [all, setAll] = useState(true);
  const [Submitted, setSubmitted] = useState(false);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);

  const { userAppDetails } = props && props.currentProfile;
  const App = userAppDetails.filter((i) => {
    return (i.appName === "Timesheets") ? i.appId : ""
  })

  const [filterparams, setFilterParams] = useSearchParams();

  const path = window.location.search;
  const params = new URLSearchParams(path);
  const id = params.get('filter')

  const { MyTeamTimeSheetExportList, isMyTeamTimesheetExportPending } = props;

  useEffect(() => {

    if (id === "Submitted") {
      setAll(false)
      setSubmitted(true)
      setFilter({
        ...filter,
        Status: "Submitted",
      })
    }
    else if (id === "Approved") {
      setAll(false);
      setSubmitted(false);
      setRejected(false);
      setApproved(true);
      setFilter({
        ...filter,
        Status: "Approved",
      })
    }
    else if (id === "Rejected") {
      setAll(false);
      setSubmitted(false);
      setRejected(true);
      setApproved(false);
      setFilter({
        ...filter,
        Status: "Rejected",
      })
    }
    else {
      setAll(true);
      setSubmitted(false);
      setRejected(false);
      setApproved(false);
      setFilter({
        ...filter,
        Status: "All",
      })
      setFilterParams('filter=All')
    }
  }, [])

  useEffect(() => {
    return async () => {
      await props.clearSetLoginPath();
      props.ClearPath();
    }
  }, []);

  const buildQueryuserName = () => {
    const { UserName } = filter || {};
    let searchuserName = '';
    if (UserName) {
      searchuserName += `${UserName}`;
    }
    return searchuserName
  }
  const buildQueryprojectName = () => {
    const { ProjectName } = filter || {};
    let searchprojectName = '';
    if (ProjectName) {
      searchprojectName += `${ProjectName}`;
    }
    return searchprojectName
  }
  const buildQuerytaskName = () => {
    const { TaskName } = filter || {};
    let searchtaskName = '';
    if (TaskName) {
      searchtaskName += `${TaskName}`;
    }
    return searchtaskName
  }
  const buildQueryCurrentTaskStatus = () => {
    const { CurrentTaskStatus } = filter || {};
    let searchCurrentTaskStatus = '';
    if (CurrentTaskStatus) {
      searchCurrentTaskStatus += `${CurrentTaskStatus}`;
    }
    return (searchCurrentTaskStatus)
  }
  const buildQueryType = () => {
    const { ScopeName } = filter || {};
    let searchType = '';
    if (ScopeName) {
      searchType += `${ScopeName}`;
    }
    return searchType
  }
  const buildworkHours = () => {
    const { WorkHours } = filter || {};
    let searchworkHours = '';
    if (WorkHours) {
      searchworkHours += `${WorkHours}`;
    }
    return searchworkHours
  }
  const buildworkDate = () => {
    const { WorkDate } = filter || {};
    let searchworkDate = '';
    if (WorkDate) {
      searchworkDate += `${WorkDate}`;
    }
    return searchworkDate
  }
  const buildcreatedDateTime = () => {
    const { CreatedDateTime } = filter || {};
    let searchcreatedDateTime = '';
    if (CreatedDateTime) {
      searchcreatedDateTime += `${CreatedDateTime}`;
    }
    return searchcreatedDateTime
  }
  const buildUpdatedDateTime = () => {
    const { UpdatedTimeStamp } = filter || {};
    let searchUpdatedDateTime = '';
    if (UpdatedTimeStamp) {
      searchUpdatedDateTime += `${UpdatedTimeStamp}`;
    }
    return searchUpdatedDateTime
  }

  const buildReason = () => {
    const { Reason } = filter || {};
    let searchReason = '';
    if (Reason) {
      searchReason += `${Reason}`;
    }
    return searchReason
  }
  const buildStatus = () => {
    const { Status } = filter || {};
    let searchStatus = '';
    if (Status) {
      searchStatus += `${Status}`;
    }
    return searchStatus
  }
  const searchStatus = (option) => {
    const { value } = option || {};
    setFilter({
      ...filter,
      CurrentTaskStatus: value,
    })
  }
  const searchType = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      ScopeName: label,
    })
  }
  const setPage = (page = 1) => {
    props.getMyTeamTimeSheetListAction(page, PageSize, buildQueryuserName(), buildQueryprojectName(), buildQuerytaskName(), buildQueryCurrentTaskStatus(),
      buildQueryType(), buildworkHours(), buildworkDate(), buildcreatedDateTime(), buildUpdatedDateTime(), buildStatus(), buildReason(), id);

    setPager(prevPager => {
      const totalPages = Math.ceil(props.totalCount / PageSize);
      const visiblePages = 4;
      const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
      const newPages = [];
      for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
        newPages.push(i);
      }

      return {
        ...prevPager,
        currentPage: page,
        totalPages,
        pages: newPages,
      }
    })
  }

  useEffect(() => {
    const currentPager = getPager(props.totalCount, 1, PageSize)
    setPager(currentPager);
    setBadgeCount(props.totalCount);
  }, [props.totalCount])

  useEffect(() => {
    const currentPageNumber = Pager && Pager.currentPage;
    setPage(currentPageNumber);
    props.ClearPath();

    return (() => {
      props.myTeamTimeSheetList();
    })

  }, [])

  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const searchInputValidation = (event) => {
    const { value, name } = event.target;
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setFilter({
      ...filter,
      [name]: value,
    })
  }


  const handleSearchClick = () => {
    setPage();
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }
  const searchInputValidationDate = (event) => {
    const { value, name } = event.target;
    setFilter({
      ...filter,
      [name]: value,
    })
  }
  const debounceTime = window.__ENV && window.__ENV.debounceTime;
  useEffect(() => {
    if (filter) {
      const timeout = setTimeout(() => {
        handleSearchClick();
      }, debounceTime);
      setDebounceTimeout(timeout);
    }

  }, [filter])


  const selectOnChange = (value, Id) => {

    setActionsList({
      ...actionList,
      status: value.value,
      timesheetId: Id
    })

    props.openActionTimeSheetModalAction();
  }

  const handleCloseAction = () => {
    window.location.reload()
    props.clearFormAction();
    props.clearTimeSheetActionError();

    props.closeActionMyTeamTimeSheetModalAction();
  };

  const handleProjectUser = (inputValue, callback) => {
    const { getUserNameAsyncSelectAction } = props;
    return getUserNameAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleSearchprojectUser = (Value) => {
    console.log("value:", Value);
    const { label, assignedUserEmail, assignedUserName } = Value || {};
    setFilter({
      ...filter,
      UserName: assignedUserEmail || assignedUserName,
    })
  }
  //------------------------------------Status Filter with Count ---------------------------------------------------
  const handleAllAction = () => {
    setAll(true);
    setRejected(false);
    setApproved(false);
    setSubmitted(false);
    setBadgeCount(0);
    setFilterParams({
      filter: "All"
    })
    setFilter({
      ...filter,
      Status: "All",
    })

  };
  const handleSubmittedAction = () => {
    setAll(false);
    setRejected(false);
    setApproved(false);
    setSubmitted(true);
    setBadgeCount(0);
    setFilterParams({
      filter: "Submitted"
    })
    setFilter({
      ...filter,
      Status: "Submitted",
    })
  };
  const handleRejectedAction = () => {
    setAll(false);
    setRejected(true);
    setApproved(false);
    setSubmitted(false);
    setBadgeCount(0);
    setFilterParams({
      filter: "Rejected"
    })
    setFilter({
      ...filter,
      Status: "Rejected",
    })
  };
  const handleApprovedAction = () => {
    setAll(false);
    setRejected(false);
    setApproved(true);
    setSubmitted(false);
    setBadgeCount(0);
    setFilterParams({
      filter: "Approved"
    })
    setFilter({
      ...filter,
      Status: "Approved",
    })
  };
  // ----------------------------------------SORTING-----------------------------------------------------------//
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = (column) => {
    if (sortOrder === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder(column);
      setSortDirection('asc');
    }
  };

  const sortedMyTeamTimesheetList = [...props.MyTeamTimeSheetList].sort((a, b) => {

    const aValue = a[sortOrder] || 'NA';
    const bValue = b[sortOrder] || 'NA';
    return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  const style = {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };

  const handleTaskScope = (inputValue, callback) => {
    const { getTaskScopeAsyncSelectAction } = props;
    return getTaskScopeAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleTaskStatus = (inputValue, callback) => {
    const { getTaskStatusAsyncSelectAction } = props;
    return getTaskStatusAsyncSelectAction(App[0] && App[0].appId, inputValue.length > 0 ? inputValue : '', callback);
  };
  //-------------------------------------------------Export---------------------------------------------------------------------------
  const handleExports = () => {
    props.getMyTeamTimeSheetExportListAction(buildQueryuserName(), buildQueryprojectName(), buildQuerytaskName(), buildQueryCurrentTaskStatus(),
      buildQueryType(), buildworkHours(), buildworkDate(), buildcreatedDateTime(), buildUpdatedDateTime(), buildStatus(), buildReason(), id)
      .then((MyTeamTimeSheetLists) => {
        const exportMyteamTimesheetExportList = [];
        const headerExcel = [
          { headerName: "USER NAME", key: "userName", headerWidhth: 30 },
          { headerName: "USER EMAIL", key: "userEmail", headerWidhth: 50 },
          { headerName: "PROJECT NAME", key: "projectName", headerWidhth: 30 },
          { headerName: "TASK NAME", key: "taskName", headerWidhth: 30 },
          { headerName: "TASK CURRENT STATUS", key: "currentTaskStatus", headerWidhth: 30 },
          { headerName: "SCOPE", key: "scopeName", headerWidhth: 20 },
          { headerName: "WORK DATE", key: "workDate", headerWidhth: 20 },
          { headerName: "WORK HOURS", key: "workHours", headerWidhth: 20 },
          { headerName: "CREATED DATE", key: "createdDateTime", headerWidhth: 20 },
          { headerName: "UPDATED DATE", key: "updatedTimeStamp", headerWidhth: 20 },
          { headerName: "REASON", key: "reason", headerWidhth: 20 },
          { headerName: "STATUS", key: "status", headerWidhth: 20 },
        ]
        MyTeamTimeSheetLists && MyTeamTimeSheetLists.forEach((element) => {
          exportMyteamTimesheetExportList.push({
            userName: element.userName || "N/A",
            userEmail: element.userEmail || "N/A",
            projectName: element.projectName || "N/A",
            taskName: element.taskName || "N/A",
            currentTaskStatus: element.currentTaskStatus || "N/A",
            scopeName: element.scopeName || "N/A",
            workDate: element.workDate ? moment(element.workDate).format('DD MMM YYYY') : 'N/A',
            workHours: element.workHours || "N/A",
            createdDateTime: element.createdDateTime ? moment(element.createdDateTime).format('DD MMM YYYY') : 'N/A',
            updatedTimeStamp: element.updatedTimeStamp ? moment(element.updatedTimeStamp).format('DD MMM YYYY') : 'N/A',
            reason: element.reason || "N/A",
            status: element.status || "N/A",

          });
        });
        excelSheetExport(headerExcel, exportMyteamTimesheetExportList, "My Team Timesheet-List");
      })
  }

  console.log("MyTeamTimeSheetExportList:", MyTeamTimeSheetExportList);

  const [myTeamTimesheetCountTooltipOpen, setMyTeamTimesheetCountTooltipOpen] = useState(false);
  const toggleMyTeamTimesheetCountTooltipOpen = () => setMyTeamTimesheetCountTooltipOpen(!myTeamTimesheetCountTooltipOpen);

  return (
    <React.Fragment>
      <div className="page-content">
        <Card >
          <CardTitle
            tag="h4"
            className="border-bottom px-4 py-3 mb-0"
          >
            <Row>
              <Col lg='3' md='3' sm='4' className='d-flex align-items-center ps-0'>
                <strong className='d-flex align-items-center gap-2'>
                  <div>MY TEAM TIMESHEET</div>
                  <div> -</div>
                  <Badge color="danger" id={'myTeamTimesheetCount'} style={{ cursor: 'pointer' }}>{props && props.totalCount || 0}</Badge>
                  <ElementToolTip id={'myTeamTimesheetCount'} name={
                    'My Team Timesheet Count'
                  } /></strong>
              </Col>
              <Col lg='7' md='7' sm='6' className='d-flex align-items-center justify-content-around gap-4 ps-0'>
                <Row>
                  <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                    <Badges badgeContent={props.isMyTeamTimesheetPending ? null : badgeCount} color='primary' invisible={all !== true}>
                      <Button
                        style={{ width: '100px' }}
                        onClick={handleAllAction}
                        className={all !== true ? "btn btn-outline-secondary" : 'btn btn-outline-secondary active'}
                        outline color="secondary"
                      ><b>All</b>
                      </Button>
                    </Badges>
                  </Col>
                  <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                    <Badges badgeContent={props.isMyTeamTimesheetPending ? null : badgeCount} color="primary" invisible={Submitted !== true}>
                      <Button
                        style={{ width: '100px' }}
                        onClick={handleSubmittedAction}
                        className={Submitted !== true ? "btn btn-outline-primary " : 'btn btn-outline-primary  active'}
                        outline color="primary"
                      ><b>Submitted</b>
                      </Button>
                    </Badges>
                  </Col>
                  <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                    <Badges badgeContent={props.isMyTeamTimesheetPending ? null : badgeCount} color="primary" invisible={approved !== true}>
                      <Button
                        style={{ width: '100px' }}
                        onClick={handleApprovedAction}
                        className={approved !== true ? "btn btn-outline-success" : 'btn btn-outline-success active'}
                        outline color="success"
                      ><b>Approved</b>
                      </Button>
                    </Badges>
                  </Col>
                  <Col xl='3' lg='3' md='3' sm='6' xs='6' className='d-flex align-items-center pe-2 pt-1'>
                    <Badges badgeContent={props.isMyTeamTimesheetPending ? null : badgeCount} color="primary" invisible={rejected !== true}>
                      <Button
                        style={{ width: '100px' }}
                        onClick={handleRejectedAction}
                        className={rejected !== true ? "btn btn-outline-danger " : 'btn btn-outline-danger  active'}
                        outline color="danger"
                      ><b>Rejected</b>
                      </Button>
                    </Badges>
                  </Col>
                </Row>
              </Col>
              <Col lg='1' md='1' sm='1'>
              </Col>
              <Col lg='1' md='1' sm='1' className='d-flex align-items-center justify-content-end pe-0'>
                <div className="content-heading">
                  <div className="ml-auto d-flex align-items-center pt-1">
                    <Button
                      className="me-2 mb-2"
                      color="success"
                      onClick={handleExports}
                      disabled={isMyTeamTimesheetExportPending || !props.totalCount}
                    >
                      {isMyTeamTimesheetExportPending ? 'EXPORTING' : 'EXPORT'}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </CardTitle>
        </Card>
        <Card>

          <>
            <CardBody className="p-4">
              {props.myTeamTimesheetErrorMessage ? <div className='w-100'>
                {props.myTeamTimesheetErrorMessage && (
                  <VWMessage type="danger" defaultMessage={props.myTeamTimesheetErrorMessage} baseClassName="alert" ></VWMessage>
                )}
              </div> : ""}
              <div style={{ height: "60vh", overflowX: "auto" }}>
                <Table className="table table-striped table-hover table-nowrap w-100" style={style.table}>
                  <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                    <tr>
                      <th onClick={() => handleSort('UserName')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        User &nbsp;
                        {sortOrder === 'UserName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('ProjectName')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        Project Name &nbsp;
                        {sortOrder === 'ProjectName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('TaskName')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        Task Name &nbsp;
                        {sortOrder === 'TaskName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('CurrentTaskStatus')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        Task Current Status &nbsp;
                        {sortOrder === 'CurrentTaskStatus' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('ScopeName')} style={{ minWidth: '200px' }} className='hand-cursor'>Scope
                        &nbsp;
                        {sortOrder === 'ScopeName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('WorkDate')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        Work Date &nbsp;
                        {sortOrder === 'WorkDate' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('WorkHours')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        Work Hours &nbsp;
                        {sortOrder === 'WorkHours' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('CreatedDateTime')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        Created Date &nbsp;
                        {sortOrder === 'CreatedDateTime' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('UpdatedTimeStamp')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        Updated Date &nbsp;
                        {sortOrder === 'UpdatedTimeStamp' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('Reason')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        Reason &nbsp;
                        {sortOrder === 'Reason' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                      <th onClick={() => handleSort('Status')} style={{ minWidth: '200px' }} className='hand-cursor'>
                        Action &nbsp;
                        {sortOrder === 'Status' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                      </th>
                    </tr>
                    <tr>
                      <th >
                        {/* <Input
                          // style={{ 'min-width': '100px' }}
                          type='text'
                          placeholder='Search'
                          name='UserName'
                          onChange={searchInputValidation}
                          value={filter && filter.UserName}
                          onKeyPress={handleKeyPress}
                        /> */}
                        <Async
                          styles={customStyles}
                          name="UserName"
                          isClearable
                          defaultOptions
                          placeholder="Search"
                          loadOptions={handleProjectUser}
                          onChange={handleSearchprojectUser}
                        />
                      </th>
                      <th >
                        <Input
                          // style={{ 'min-width': '100px' }}
                          type='text'
                          placeholder='Search'
                          name='ProjectName'
                          onChange={searchInputValidation}
                          value={filter && filter.ProjectName}
                          onKeyPress={handleKeyPress}
                        />
                      </th>
                      <th >
                        <Input
                          // style={{ 'min-width': '100px' }}
                          type='text'
                          placeholder='Search'
                          name='TaskName'
                          onChange={searchInputValidation}
                          value={filter && filter.TaskName}
                          onKeyPress={handleKeyPress}
                        />
                      </th>
                      <th>
                        <Async
                          styles={customStyles}
                          name="CurrentTaskStatus"
                          isClearable
                          defaultOptions
                          placeholder="Search"
                          loadOptions={handleTaskStatus}
                          onChange={searchStatus}
                        />
                      </th>
                      <th >
                        <Async
                          styles={customStyles}
                          name="ScopeName"
                          isClearable
                          defaultOptions
                          placeholder="Search"
                          loadOptions={handleTaskScope}
                          onChange={searchType}
                        />
                      </th>
                      <th >
                        <Input
                          type='date'
                          placeholder='Search'
                          name='WorkDate'
                          onChange={searchInputValidation}
                          value={filter && filter.WorkDate}
                          onKeyPress={handleKeyPress}
                          max="9999-12-31"
                        />
                      </th>
                      <th >
                        <Input
                          // style={{ 'min-width': '100px' }}
                          type='text'
                          placeholder='Search'
                          name='WorkHours'
                          onChange={searchInputValidation}
                          value={filter && filter.WorkHours}
                          onKeyPress={handleKeyPress}
                        />
                      </th>
                      <th >
                        <Input
                          type='date'
                          placeholder='Search'
                          name='CreatedDateTime'
                          onChange={searchInputValidationDate}
                          value={filter && filter.CreatedDateTime}
                          onKeyPress={handleKeyPress}
                          max="9999-12-31"
                        />
                      </th>
                      <th >
                        <Input
                          type='date'
                          placeholder='Search'
                          name='UpdatedTimeStamp'
                          onChange={searchInputValidationDate}
                          value={filter && filter.UpdatedTimeStamp}
                          onKeyPress={handleKeyPress}
                          max="9999-12-31"
                        />
                      </th>
                      <th >
                        <Input
                          // style={{ 'min-width': '100px' }}
                          type='text'
                          placeholder='Search'
                          name='Reason'
                          onChange={searchInputValidation}
                          value={filter && filter.Reason}
                          onKeyPress={handleKeyPress}
                        />
                      </th>
                      <th>
                        <Input
                          // style={{ width: '120px' }}
                          type='text'
                          placeholder='Search'
                          name='Status'
                          onChange={searchInputValidation}
                          value={filter && filter.Status}
                          onKeyPress={handleKeyPress}
                          disabled
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!props.isMyTeamTimesheetPending && (props.MyTeamTimeSheetList && props.MyTeamTimeSheetList.length > 0) ?
                      // props.MyTeamTimeSheetList && props.MyTeamTimeSheetList.map((data) => (
                      sortedMyTeamTimesheetList && sortedMyTeamTimesheetList.map((data) => (

                        <tr>
                          <td>{data.UserName || "N/A"}<br />
                            <small>{data.UserEmail || ""}</small></td>
                          <td>{data.ProjectName || "N/A"}</td>
                          {/* <td>{data.TaskName || "N/A"}</td> */}
                          <td className='ps-3'>{data.TaskName ? (data.TaskName && data.TaskName.length > 20) ? <><span id={`taskName-${data.Id}`}>{data.TaskName && data.TaskName.substring(0, 20) + '...'}</span>
                            <ElementToolTip
                              id={`taskName-${data.Id}`}
                              name={data.TaskName && data.TaskName.length > 20 ? data.TaskName : ''}
                            >
                              {data.TaskName && data.TaskName.length > 20
                                ? data.TaskName && data.TaskName.substring(0, 20) + '...'
                                : data.TaskName}
                            </ElementToolTip>
                          </>
                            : data.TaskName : "N/A"}
                          </td>
                          <td>{data.CurrentTaskStatus || "N/A"}</td>
                          <td>{data.ScopeName || "N/A"}</td>
                          <td>{data.WorkDate ? moment(data.WorkDate).format('DD MMM YYYY') : 'N/A'}</td>
                          <td style={{ width: '120px' }}>{`${data.WorkHours} hrs` || "N/A"}</td>
                          <td>{data.CreatedDateTime ? moment(data.CreatedDateTime).format('DD MMM YYYY') : 'N/A'}</td>
                          <td>{data.UpdatedTimeStamp ? moment(data.UpdatedTimeStamp).format('DD MMM YYYY') : 'N/A'}</td>
                          <td>{data.Reason ? (data.Reason && data.Reason.length > 20) ? data.Reason && data.Reason.substring(0, 20) + '...' : data.Reason : "N/A"}</td>
                          <td style={{ width: '175px' }}>
                            {data.Status || 'N/A'}
                          </td>
                        </tr>

                      ))
                      : props.isMyTeamTimesheetPending ? <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <VWSpinner />
                          </div>
                        </div>
                      </div>
                        :
                        <div className="sticky-spinner-row">
                          <div className="position-relative">
                            <div className="d-flex justify-content-center align-items-center"
                              style={{
                                position: 'fixed',
                                top: '50%',
                                left: '60%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1,
                                width: '100vw',
                                height: '100vh',
                                pointerEvents: 'none',
                              }}>
                              <NoRecord />
                            </div>
                          </div>
                        </div>}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            <CardFooter>
              {props.MyTeamTimeSheetList && props.MyTeamTimeSheetList.length > 0 ?
                <VWPagination
                  pager={Pager}
                  setPage={setPage}
                  totalRecords={props.totalCount}
                  pageSize={PageSize}
                />
                : null
              }
            </CardFooter>
          </>
          {/* )} */}
        </Card>

        <VWModel
          header="MY TEAM TIMESHEET ACTION"
          isOpen={props.isActionTimeSheetModalOpen}
          handleClose={handleCloseAction}
        >
          <MyTeamTimeSheetListAction data={actionList} handleClose={handleCloseAction} />
        </VWModel>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => ({
  isActionTimeSheetModalOpen: state.MyTeamTimeSheet.isActionTimeSheetModalOpen,
  MyTeamTimeSheetList: state.MyTeamTimeSheet.MyTeamTimeSheetList,
  totalCount: state.MyTeamTimeSheet.totalRecords,
  isMyTeamTimesheetPending: state.MyTeamTimeSheet.isMyTeamTimesheetPending,
  myTeamTimesheetErrorMessage: state.MyTeamTimeSheet.myTeamTimesheetErrorMessage,
  MyTeamTimeSheetExportList: state.MyTeamTimeSheet.MyTeamTimeSheetExportList,
  isMyTeamTimesheetExportPending: state.MyTeamTimeSheet.isMyTeamTimesheetExportPending,
  currentProfile: state.login.currentProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getMyTeamTimeSheetListAction: (page, PageSize, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType,
    searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id) =>
    dispatch(getMyTeamTimeSheetListActionModal(page, PageSize, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType,
      searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id)),
  getMyTeamTimeSheetExportListAction: (searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours,
    searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id) =>
    dispatch(getMyTeamTimeSheetExportListAction(searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType,
      searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id)),
  closeActionMyTeamTimeSheetModalAction: () => dispatch(closeActionMTimeSheetModal()),
  openActionTimeSheetModalAction: () => dispatch(openActionTimeSheetModal()),
  clearFormAction: () => dispatch(clearForm()),
  clearTimeSheetActionError: () => dispatch(clearTimeSheetActionError()),
  ClearPath: () => dispatch(clearSetPath()),
  clearSetLoginPath: () => dispatch(clearSetLoginPath()),
  myTeamTimeSheetList: () => dispatch(myTeamTimeSheetList()),
  getTaskScopeAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskScopeListAsyncSelectAction(appId, searchValue, callback)),
  getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectTimesheet(appId, searchValue, callback)),
  getUserNameAsyncSelectAction: (appId, searchValue, callback) => dispatch(getProjectDropDownUserListAsyncSelectAction(appId, searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyTeamTimeSheet);