//---------------------------------------------MM-CONTACT-LIST----------------------------------------------------------------------------------
export const SET_CONTACT_LIST = "SET_CONTACT_LIST";
export const SET_EXPORT_CONTACT_LIST = "SET_EXPORT_CONTACT_LIST";
export const SET_CONTACT_PENDING = "SET_CONTACT_PENDING";
export const CONTACT_LIST_ERROR = "CONTACT_LIST_ERROR";
export const CLEAR_CONTACT_LIST_ERROR = "CLEAR_CONTACT_LIST_ERROR";

export const CONTACT_EXPORT_HEADERS = [
    "EMAIL",
    "MAIL CATEGORY",
    "STATUS",
    "SUBSCRIPTION STATUS",
    "CREATED BY",
    "CREATED ON",
];

export const CONTACT_STATUS_FILTER_OPTIONS = [
    { label: "Select...", value: null },
    { label: "Active", value: 'true' },
    { label: "Inactive", value: 'false' },
];
export const MAIL_CATEGORY_FILTER_OPTIONS = [
    { label: "Select...", value: null },
    { label: "Test", value: "Test" },
    { label: "Production", value: "Production" },
];
export const SUBSCRIPTION_STATUS_FILTER_OPTIONS = [
    { label: "Select...", value: null },
    { label: "OPTIN", value: "OPTIN" },
    { label: "OPTOUT", value: "OPTOUT" },
];
//---------------------------------------------MM-CONTACT-DETAILS-------------------------------------------------------------------------------
export const SET_CONTACT_DETAILS = "SET_CONTACT_DETAILS";
export const CLEAR_CONTACT_DETAILS_ERROR = "CLEAR_CONTACT_DETAILS_ERROR";
export const CONTACT_DETAILS_ERROR = "CONTACT_DETAILS_ERROR";
export const SET_CONTACT_DETAILS_PENDING = "SET_CONTACT_DETAILS_PENDING";
