import { getToken } from "../../../../helper/common"
import agent from "../../../services/agent"
import { showSnackbarStatus } from "../../../snackbar/actions"
import { CLEAR_MY_TEAM_LEAVE_DETAILS_LIST_ERROR, CLEAR_MY_TEAM_LEAVE_EXPORT_LIST_ERROR, MY_TEAM_LEAVE_DETAILS_LIST_ERROR, MY_TEAM_LEAVE_EXPORT_LIST_ERROR, PENDING_SUBMITTED_COUNT, SET_MY_TEAM_LEAVE_DETAILS_LIST, SET_MY_TEAM_LEAVE_DETAILS_PENDING, SET_MY_TEAM_LEAVE_EXPORT_LIST, SET_MY_TEAM_LEAVE_EXPORT_PENDING, SET_MY_TEAM_LEAVE_EXPORT_SUCCESS } from "./constants"



export const setMyTeamLeaveDetailsList = (myTeamLeaveDetails) => ({
    type: SET_MY_TEAM_LEAVE_DETAILS_LIST,
    payload: { myTeamLeaveDetails },
})
export const setMyTeamLeaveExportList = (myTeamLeaveExport) => ({
    type: SET_MY_TEAM_LEAVE_EXPORT_LIST,
    payload: { myTeamLeaveExport },
})
export const clearMyTeamLeaveDetailsListError = () => ({
    type: CLEAR_MY_TEAM_LEAVE_DETAILS_LIST_ERROR,
})
export const clearMyTeamLeaveExportListError = () => ({
    type: CLEAR_MY_TEAM_LEAVE_EXPORT_LIST_ERROR,
})
export const MyTeamLeaveDetailsListError = (errorMessage) => ({
    type: MY_TEAM_LEAVE_DETAILS_LIST_ERROR,
    payload: { errorMessage },
})
export const MyTeamLeaveExportListError = (errorMessage) => ({
    type: MY_TEAM_LEAVE_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const setMyTeamLeaveDetailsPending = () => ({
    type: SET_MY_TEAM_LEAVE_DETAILS_PENDING
})
export const setMyTeamLeaveExportPending = () => ({
    type: SET_MY_TEAM_LEAVE_EXPORT_PENDING
})
export const setMyTeamLeaveExportSuccess = () => ({
    type: SET_MY_TEAM_LEAVE_EXPORT_SUCCESS
})
export const pendingSubmittedCount = (submittedCount) => ({
    type: PENDING_SUBMITTED_COUNT,
    payload: { submittedCount }
})

export const getPendingSubmittedCount = () => (dispatch) => {
    dispatch(setMyTeamLeaveDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.getPendingCount(accessToken)
            .then((PendingSubmittedCount) => {
                dispatch(pendingSubmittedCount(PendingSubmittedCount));
                // dispatch(clearMyTeamLeaveDetailsListError());
            })
            .catch((err) => {
                console.log('get Pending Submitted Count Action Modal server error', err);
                // let error;
                // if (err.status) {
                //     error = err.response && err.response.body && err.response.body.error.message;
                // }
                // else {
                //     error = "Network Error"
                // }
                // dispatch(MyTeamLeaveDetailsListError(error))
                // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
}



export const getMyTeamDetailsListActionModal = (filter,page, PageSize, searchuserName, /*searchUserEmail,*/ searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id) => (dispatch) => {
    dispatch(setMyTeamLeaveDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveTracker.getMyTeamLeaveDetailsList(accessToken, filter,page, PageSize, searchuserName, /*searchUserEmail,*/ searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id)
            .then((MyTeamLeaveDetailsList) => {
                dispatch(setMyTeamLeaveDetailsList(MyTeamLeaveDetailsList));
                dispatch(clearMyTeamLeaveDetailsListError());
            })
            .catch((err) => {
                console.log('getMyTeamLeaveDetailsListActionModal server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(MyTeamLeaveDetailsListError(error))
            });
    });
}
export const getMyteamLeaveExport = ( searchuserName, /*searchUserEmail,*/ searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id) => (dispatch) => {
    dispatch(setMyTeamLeaveExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.LeaveTracker.getMyTeamLeaveExportList(accessToken, searchuserName, /*searchUserEmail,*/ searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id)
            .then((MyTeamLeaveDetailsList) => {
                dispatch(setMyTeamLeaveExportSuccess());
                return Promise.resolve(MyTeamLeaveDetailsList);
            })
            .catch((err) => {
                console.log('getMyteamLeaveExport server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(MyTeamLeaveExportListError(error));
            });
    });
}
