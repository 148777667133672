/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const VWPagination = ({ pager, setPage, totalRecords = 0, pageSize = 1 }) => {
  console.log("pager: ", pager);

  let currentPageCount;
  if (totalRecords !== 0) {
    if (pager.totalPages === pager.currentPage) {

      const remainder = totalRecords % pageSize;
      if (remainder === 0) {
        currentPageCount = pageSize;
      }
      else {
        currentPageCount = remainder;
      }
    }
    else {
      currentPageCount = pageSize;
    }
  }
  else {
    currentPageCount = 0;
  }

  let startIndex = (((pager.currentPage * pageSize) + 1) - pageSize);
  if (totalRecords === 0) {
    startIndex = 0;
  }
  let endIndex = (pager.currentPage * pageSize);
  if (endIndex > totalRecords) {
    endIndex = totalRecords;
  }
  return (

    <div className="d-flex flex-row-reverse">
      <Pagination className="pagination-sm">
        <PaginationItem disabled={pager.currentPage === 1}>
          <PaginationLink onClick={() => setPage(1)} previous />
        </PaginationItem>
        <PaginationItem disabled={pager.currentPage === 1}>
          <PaginationLink onClick={() => setPage(pager.currentPage - 1)}>‹</PaginationLink>
        </PaginationItem>
        {pager.pages &&
          pager.pages.map((page, i) => (
            <PaginationItem active={pager.currentPage === page} key={i}>
              <PaginationLink onClick={() => setPage(page)}>{page}</PaginationLink>
            </PaginationItem>
          ))}
        <PaginationItem disabled={pager.currentPage === pager.totalPages}>
          <PaginationLink onClick={() => setPage(pager.currentPage + 1)}>›</PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={pager.currentPage === pager.totalPages}>
          <PaginationLink onClick={() => setPage(pager.totalPages)} next />
        </PaginationItem>
      </Pagination>
      <div className='m-3'> {startIndex} - {endIndex} of {totalRecords}</div>
      {/* <div>Total Rec: {totalRecords}, PAge size: {pageSize}, Current PAge: {pager.currentPage}, Current PAge Count: {currentPageCount}, Total Pages: {pager.totalPages}</div> */}
    </div>


  );
};

export default VWPagination;
