import React, { useState } from 'react'
import Async from 'react-select/async';
import VWButton from '../../../../../components/vwButton/VWButton'
import { Input } from 'reactstrap'
import { connect } from 'react-redux';
import { getOrgNameAsyncSelect } from '../../../../../store/Modules/Admin/OrgUser/actions';
import VWMessage from '../../../../../components/vwMessage/vwMessage';
import { createAdminUser, getOrgNameAsyncSelectActions } from '../../../../../store/Modules/Admin/AdminUser/actions';

const CreateAdminUser = (props) => {

    const [formFields, setFormFields] = useState({ IsPortalEnable: true });
    const [IsPortalEnable, setIsPortalEnable] = useState(true);

    const handleOrgName = (inputValue, callback) => {
        const { getOrgNameAsyncSelectAction } = props;
        return getOrgNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };

    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setFormFields({ ...formFields, [input.name]: value });
    };

    const handleAddOrgName = (value) => {
        setFormFields({ ...formFields, OrgId: value.value });
    }

    const handleActiveStatusChange = () => {
        setIsPortalEnable(!IsPortalEnable);
        setFormFields({ ...formFields, IsPortalEnable: !IsPortalEnable })
    };
    //--------------------------------------------Create ---------------------------------------------------------
    const handleCreateAdminUser = () => {
        props.createAdminUserAction(formFields);
    };
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <>
                <div className="form-group">
                    <label className="col-form-label">
                        Org Name<span className="text-danger"> *</span>
                    </label>
                    <Async
                        name="OrgId"
                        placeholder="Select Org Name"
                        className="vm-react-select"
                        isSearchable
                        defaultOptions
                        loadOptions={handleOrgName}
                        onChange={handleAddOrgName}
                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">
                        Email <span className="text-danger"> *</span>
                    </label>
                    <Input
                        type="text"
                        name="UserEmail"
                        placeholder="Enter Email"
                        onChange={validateOnChange}
                        value={formFields && formFields.UserEmail}
                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">
                        First Name <span className="text-danger"> *</span>
                    </label>
                    <Input
                        type="text"
                        name="FirstName"
                        placeholder="Enter FirstName"
                        onChange={validateOnChange}
                        value={formFields && formFields.FirstName}
                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">
                        Last Name
                    </label>
                    <Input
                        type="text"
                        name="LastName"
                        placeholder="Enter LastName"
                        onChange={validateOnChange}
                        value={formFields && formFields.LastName}
                    />
                </div>

                <div className="form-group">
                    <label className="col-form-label">
                        Password <span className="text-danger"> *</span>
                    </label>
                    <Input
                        type="Password"
                        name="Password"
                        placeholder="Enter Password"
                        onChange={validateOnChange}
                        value={formFields && formFields.Password}
                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">
                        Confirm Password <span className="text-danger"> *</span>
                    </label>
                    <Input
                        type="Password"
                        name="ConfirmPassword"
                        placeholder="Enter Password"
                        onChange={validateOnChange}
                        value={formFields && formFields.ConfirmPassword}
                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">IsPortalEnable</label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!IsPortalEnable ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${IsPortalEnable ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </div>
                <br></br>
                <div className="form-group">
                    <VWButton
                        messageKey="createAdminUserButton"
                        defaultMessage="Create Admin User"
                        buttonType="success"
                        baseClassName="btn"
                        className="me-2"
                        onClick={handleCreateAdminUser}
                    ></VWButton>
                    <VWButton
                        messageKey="cancel Admin User"
                        defaultMessage="Cancel"
                        buttonType="secondary"
                        baseClassName="btn"
                        onClick={props.handleClose}
                    ></VWButton>
                </div>
            </>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isPending: state.AdminUser.isPending,
    errorMessage: state.AdminUser.errorMessage,
});
const mapDispatchToProps = (dispatch) => ({
    getOrgNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getOrgNameAsyncSelectActions(searchValue, callback)),
    createAdminUserAction: (formFields) => dispatch(createAdminUser(formFields)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateAdminUser)