import { getToken, modifyProjectDropDownList, modifyProjectDropDownUserList, modifyProjectNonUserList, modifyProjectUserList, modifyUserList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import {
    CLEAR_CREATE_PROJECT_ERROR,
    CLEAR_PROJECT_DETAILS_ERROR,
    CLEAR_PROJECT_EXPORT_LIST_ERROR,
    CLEAR_PROJECT_LIST,
    CLEAR_PROJECT_LIST_ERROR,
    CLEAR_PROJECT_PIE_CHART_DETAILS_ERROR,
    CLEAR_UPDATE_PROJECT_ERROR,
    CLOSE_CREATE_PROJECT_MODAL,
    CLOSE_EDIT_PROJECT_MODAL,
    CLOSE_SORT_MODAL,
    CREATE_PROJECT_ERROR,
    CREATE_PROJECT_PENDING,
    CREATE_PROJECT_SUCCESS,
    OPEN_CREATE_PROJECT_MODAL,
    OPEN_EDIT_PROJECT_MODAL,
    OPEN_SORT_MODAL,
    PROJECT_EXPORT_LIST_ERROR,
    PROJECT_EXPORT_SUCCESS,
    PROJECT_LIST_ERROR,
    PROJECT_PIE_CHART_DETAILS_ERROR,
    SET_PROJECT_DETAILS,
    SET_PROJECT_DETAILS_ERROR,
    SET_PROJECT_DETAILS_PENDING,
    SET_PROJECT_EXPORT_PENDING,
    SET_PROJECT_LIST,
    SET_PROJECT_PENDING,
    SET_PROJECT_PIE_CHART_DETAILS,
    SET_PROJECT_PIE_CHART_DETAILS_PENDING,
    UPDATE_PROJECT_ERROR,
    UPDATE_PROJECT_PENDING,
    UPDATE_PROJECT_SUCCESS
} from "./constants";

//-------------------------------------------------PROJECT-CREATE---------------------------------------------------------------------------------------
export const openCreateProjectModalAction = () => ({
    type: OPEN_CREATE_PROJECT_MODAL
})
export const closeCreateProjectModalAction = () => ({
    type: CLOSE_CREATE_PROJECT_MODAL
})
export const createProjectError = (errorMessage) => ({
    type: CREATE_PROJECT_ERROR,
    payload: { errorMessage },
});
export const createProjectPending = () => ({
    type: CREATE_PROJECT_PENDING,
});
export const clearCreateProjectError = () => ({
    type: CLEAR_CREATE_PROJECT_ERROR,
});
export const createProjectSuccess = () => ({
    type: CREATE_PROJECT_SUCCESS,
});

export const CreateProject = (formFields, filterData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createProjectError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(createProjectPending());
    dispatch(clearCreateProjectError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.createProject(accessToken, dataToSend)
            .then((resp) => {
                dispatch(closeCreateProjectModalAction());
                console.log('resp:', resp);
                dispatch(createProjectSuccess());
                dispatch(clearForm());
                // dispatch(getProjectList(filterData));
                // dispatch(getProjectDetails(resp.id));
                dispatch(showSnackbarStatus('Project created successfully'));
                // setTimeout(() => {
                window.location.href = `/projectportal/projects/details/${resp.id}`;
                // }, 100)
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createProjectError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createProjectError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Create Project server error', err);
                dispatch(clearForm());
                dispatch(closeCreateProjectModalAction());
               
            });
    });
};

export const getAdminUserNameAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getAdminUserName(accessToken, searchValue)
            .then((UserList) => {
                const updatedUserList = modifyProjectUserList(UserList.value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getAdminUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const getUserNameAsyncSelect = (appId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getUserName(accessToken, searchValue, appId)
            .then((UserList) => {
                const updatedUserList = modifyProjectUserList(UserList.value);
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                callback && callback();
            });
    });
};
export const getNonUserNameAsyncSelect = (appId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.ProjectDropDownUserList(accessToken, searchValue, appId)
            .then((UserList) => {
                const updatedUserList = modifyProjectDropDownList(UserList.value);
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                callback && callback();
            });
    });
};
export const getManagerUserNameAsyncSelectAction = (appId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getManagerUserName(accessToken, appId, searchValue)
            .then((ManagerList) => {
                const updatedUserList = modifyProjectUserList(ManagerList.value);
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                callback && callback();
            });
    });
};

export const getProjectDropDownUserListAsyncSelectAction = (appID, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.ProjectDropDownUserList(accessToken, searchValue, appID)
            .then((ManagerList) => {
                const updatedUserList = modifyProjectDropDownUserList(ManagerList.value);
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getProjectDropDownUserListAsyncSelectAction server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                callback && callback();
            });
    });
};

//-------------------------------------------------PROJECT-UPDATE---------------------------------------------------------------------------------------
export const openEditProjectModalAction = () => ({
    type: OPEN_EDIT_PROJECT_MODAL
})
export const closeEditProjectModalAction = () => ({
    type: CLOSE_EDIT_PROJECT_MODAL
})
export const clearUpdateProjectError = () => ({
    type: CLEAR_UPDATE_PROJECT_ERROR,
});
export const updateProjectPending = () => ({
    type: UPDATE_PROJECT_PENDING,
});
export const updateProjectSuccess = () => ({
    type: UPDATE_PROJECT_SUCCESS,
});
export const updateProjectError = (errorMessage) => ({
    type: UPDATE_PROJECT_ERROR,
    payload: { errorMessage },
});

export const UpdateProject = (formFields) => (dispatch) => {
    const dataToSend = trimmedValues({
        ...formFields,
        Id: formFields.id,
    })
    dispatch(clearUpdateProjectError());
    dispatch(updateProjectPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.editProject(accessToken, dataToSend)
            .then(() => {
                dispatch(updateProjectSuccess());
                dispatch(closeEditProjectModalAction());
                dispatch(getProjectDetails(formFields.id));
                dispatch(showSnackbarStatus('Project details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateProjectError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateProjectError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Edit Project server error', err);
                // dispatch(updateProjectError(null));
                dispatch(closeEditProjectModalAction());
                
            });
    });
};

//-------------------------------------------------PROJECT-LIST---------------------------------------------------------------------------------------
export const setProjectList = (projectList) => ({
    type: SET_PROJECT_LIST,
    payload: { projectList }
})
export const clearProjectList = () => ({
    type: CLEAR_PROJECT_LIST
})
export const clearProjectListError = () => ({
    type: CLEAR_PROJECT_LIST_ERROR
})
export const projectListError = (errorMessage) => ({
    type: PROJECT_LIST_ERROR,
    payload: { errorMessage },
})
export const setProjectPending = () => ({
    type: SET_PROJECT_PENDING
})

export const getProjectList = (filterData, page, PageSize, searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName) => (dispatch) => {
    dispatch(setProjectPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getProjectList(accessToken, filterData, page, PageSize, searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName)
            .then((ProjectLists) => {
                console.log("ProjectLists:", ProjectLists);

                dispatch(setProjectList(ProjectLists));
                dispatch(clearProjectListError());
            })
            .catch((err) => {
                console.log('getProjectList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(projectListError(error))
               
            });
    });
}

//-------------------------------------------------PROJECT-EXPORT---------------------------------------------------------------------------------------
export const setProjectExportSuccess = () => ({
    type: PROJECT_EXPORT_SUCCESS,
})
export const clearProjectExportListError = () => ({
    type: CLEAR_PROJECT_EXPORT_LIST_ERROR
})
export const projectExportListError = (errorMessage) => ({
    type: PROJECT_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const setProjectExportPending = () => ({
    type: SET_PROJECT_EXPORT_PENDING
})

export const getProjectExportListAction = (searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName) => (dispatch) => {
    dispatch(setProjectExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.ProjectTracker.getProjectExportList(accessToken, searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName)
            .then((ProjectLists) => {
                dispatch(setProjectExportSuccess());
                return Promise.resolve(ProjectLists);
                // dispatch(clearProjectExportListError());
            })
            .catch((err) => {
                console.log('getProjectExportListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(projectExportListError(error));

            });
    });
}

//-------------------------------------------------PROJECT-DETAILS---------------------------------------------------------------------------------------
export const setProjectDetails = (projectDetails) => ({
    type: SET_PROJECT_DETAILS,
    payload: { projectDetails },
})
export const setProjectDetailsPending = () => ({
    type: SET_PROJECT_DETAILS_PENDING
})
export const setProjectPieChartDetailsPending = () => ({
    type: SET_PROJECT_PIE_CHART_DETAILS_PENDING
})
export const setProjectPieChartDetails = (projectPieChartDetails) => ({
    type: SET_PROJECT_PIE_CHART_DETAILS,
    payload: { projectPieChartDetails },
})
export const clearProjectDetailsError = () => ({
    type: CLEAR_PROJECT_DETAILS_ERROR,
})
export const clearProjectPieChartDetailsError = () => ({
    type: CLEAR_PROJECT_PIE_CHART_DETAILS_ERROR,
})
export const projectDetailsError = (errorMessage) => ({
    type: SET_PROJECT_DETAILS_ERROR,
    payload: { errorMessage },
})
export const projectPieChartDetailsError = (errorMessage) => ({
    type: PROJECT_PIE_CHART_DETAILS_ERROR,
    payload: { errorMessage },
})

export const getProjectDetails = (id) => (dispatch) => {
    dispatch(setProjectDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getProjectDetails(accessToken, id)
            .then((projectDetails) => {
                dispatch(setProjectDetails(projectDetails))
                dispatch(clearProjectDetailsError());
            })
            .catch((err) => {
                console.log('getProjectDetails server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(projectDetailsError(error))
            });
    })
}

export const getProjectPieChartLists = (id) => (dispatch) => {
    dispatch(setProjectPieChartDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getProjectPieChart(accessToken, id)
            .then((projectPieCharts) => {
                dispatch(setProjectPieChartDetails(projectPieCharts))
                dispatch(clearProjectPieChartDetailsError());
            })
            .catch((err) => {
                console.log('getProjectPieChartLists server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(projectDetailsError(error))
            });
    })
}
//---------------------------------------------------------------MILESTONE-SORTING---------------------------------------------------------------------
export const openSortModal = () => ({
    type: OPEN_SORT_MODAL
})
export const closeSortModal = () => ({
    type: CLOSE_SORT_MODAL
})