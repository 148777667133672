import React, { useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Col, Input, InputGroup, Row, Spinner, Table, Tooltip } from "reactstrap";
import moment from 'moment';
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getPager } from "../../../../helper/common";
import { clearEmployeesExportListError, closeCreateEmployeeModalAction, getEmployeeExportList, getEmployeeListAction, getManagerNameAsyncSelect, getManagerNameListAsyncSelect, openCreateEmployeeModalAction } from "../../../../store/Modules/Employees/actions";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBorderAll, faSearch } from "@fortawesome/free-solid-svg-icons";
import CreateEmployee from "./CreateEmployee";
import VWModel from "../../../../components/Modal/VWModal";
import { clearForm } from "../../../../store/form/actions";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWSkeleton from "../../../../components/vwSkeleton/vwSkeleton";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import { clearSetPath } from "../../../../store/actions";
import Select from 'react-select';
import '../../../Modules/viewList.css'
import { excelPrint, excelSheetExport } from "../../../../helper/export";
import { EMPLOYEE_EXPORT_HEADERS } from "../../../../store/Modules/Employees/constants";
import { ElementToolTip } from "../../Projects/vwToolTip";
import Async from "react-select/async";
import './Employee.css'
import EmployeeCard from './EmployeeCard'
import { sendToggleViewDocs } from "../../../../store/Modules/OverAllDocs/actions";
import UITooltip from "../../../../CommonData/Data/UITooltip";

const statusOptions = [
  { label: 'Active', value: "false" },
  { label: 'Terminated', value: "true" },
]
const UserStatusOptions = [
  { label: 'Active', value: "true" },
  { label: 'Inactive', value: "false" },
]
const statusBoolean = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]
const accountStatusOptions = [
  { label: 'Enabled', value: 'true' },
  { label: 'Disabled', value: 'false' },
]
const statusType = [
  { label: 'Full Time', value: "Full Time" },
  { label: 'Part Time', value: "Part Time" },
]
const TitleOptions = [
  { label: 'Miss', value: 'Miss' },
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'None of the above', value: 'N/A' },
]
const customStyles = {
  container: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 'normal'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'normal'
  }),
};
const EmployeeList = (props) => {

  const [PageSize] = useState(20);
  const [Pager, setPager] = useState({ currentPage: 1 });
  const [filter, setFilter] = useState();
  const { EmployeeList, EmployeeExportList } = props;
  const [toggleView, setToggleView] = useState(true);

  const buildQueryEmployeeDisplayName = () => {
    const { EmployeeDisplayName } = filter || {};
    let searchEmployeeDisplayName = '';
    if (EmployeeDisplayName) {
      searchEmployeeDisplayName += `${EmployeeDisplayName}`;
    }
    return searchEmployeeDisplayName
  }
  const buildQueryEmployeeType = () => {
    const { EmployeeType } = filter || {};
    let searchEmployeeType = '';
    if (EmployeeType) {
      searchEmployeeType += `${EmployeeType}`;
    }
    return (searchEmployeeType)
  }
  const buildQueryEmployeeTitle = () => {
    const { EmployeeTitle } = filter || {};
    let searchEmployeeTitle = '';
    if (EmployeeTitle) {
      searchEmployeeTitle += `${EmployeeTitle}`;
    }
    return (searchEmployeeTitle)
  }
  const buildQueryDateOfBirth = () => {
    const { DateOfBirth } = filter || {};
    let searchDateOfBirth = '';
    if (DateOfBirth) {
      searchDateOfBirth += `${DateOfBirth}`;
    }
    return (searchDateOfBirth)
  }
  const buildQuerysearchDateOfJoin = () => {
    const { DateOfJoin } = filter || {};
    let searchDateOfJoin = '';
    if (DateOfJoin) {
      searchDateOfJoin += `${DateOfJoin}`;
    }
    return (searchDateOfJoin)
  }
  const buildQuerysearchIsTerminated = () => {
    const { IsTerminated } = filter || {};
    let searchIsTerminated = '';
    if (IsTerminated) {
      searchIsTerminated += `${IsTerminated}`;
    }
    return (searchIsTerminated)
  }
  const buildQuerysearchIsUserActive = () => {
    const { IsUserActive } = filter || {};
    let searchIsUserActive = '';
    if (IsUserActive) {
      searchIsUserActive += `${IsUserActive}`;
    }
    return (searchIsUserActive)
  }
  const buildQuerysearchIsRehired = () => {
    const { IsRehired } = filter || {};
    let searchIsRehired = '';
    if (IsRehired) {
      searchIsRehired += `${IsRehired}`;
    }
    return (searchIsRehired)
  }
  const buildQueryIsPortalEnable = () => {
    const { IsPortalEnable } = filter || {};
    let searchIsPortalEnable = '';
    if (IsPortalEnable) {
      searchIsPortalEnable += `${IsPortalEnable}`;
    }
    return (searchIsPortalEnable)
  }
  const buildQuerysearchIsPeopleManager = () => {
    const { IsPeopleManager } = filter || {};
    let searchIsPeopleManager = '';
    if (IsPeopleManager) {
      searchIsPeopleManager += `${IsPeopleManager}`;
    }
    return (searchIsPeopleManager)
  }
  const buildQuerysearchManagerUserEmail = () => {
    const { ManagerUserEmail } = filter || {};
    let searchManagerUserEmail = '';
    if (ManagerUserEmail) {
      searchManagerUserEmail += `${ManagerUserEmail}`;
    }
    return searchManagerUserEmail
  }
  const buildQuerysearchUserEmail = () => {
    const { UserEmail } = filter || {};
    let searchUserEmail = '';
    if (UserEmail) {
      searchUserEmail += `${UserEmail}`;
    }
    return searchUserEmail
  }
  const buildQuerySearchEnableMailNotification = () => {
    const { EnableMailNotification } = filter || {};
    let searchEnableMailNotification = '';
    if (EnableMailNotification) {
      searchEnableMailNotification += `${EnableMailNotification}`;
    }
    return searchEnableMailNotification
  }
  const setPage = (page = 1) => {
    props.getEmployeeList(filter, page, PageSize, buildQueryEmployeeDisplayName(), buildQueryEmployeeType(), buildQueryEmployeeTitle(), buildQueryDateOfBirth(), buildQuerysearchDateOfJoin(), buildQuerysearchIsTerminated(), buildQuerysearchManagerUserEmail(), buildQuerysearchUserEmail(), buildQuerysearchIsRehired(), buildQuerysearchIsPeopleManager(), buildQueryIsPortalEnable(), buildQuerysearchIsUserActive());

    setPager(prevPager => {
      const totalPages = Math.ceil(props.totalCount / PageSize);
      const visiblePages = 4;
      const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
      const newPages = [];
      for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
        newPages.push(i);
      }
      return {
        ...prevPager,
        currentPage: page,
        totalPages,
        pages: newPages,
      }
    })
  }

  useEffect(() => {
    if (props.toggleViewBool) {
      setToggleView(props.toggleViewBool)
    }
  }, [props.toggleViewBool])

  useEffect(() => {
    const currentPager = getPager(props.totalCount, 1, PageSize)
    setPager(currentPager)
  }, [props.totalCount])

  useEffect(() => {
    const currentPageNumber = Pager && Pager.currentPage;
    setPage(currentPageNumber);
    props.ClearPath();
    props.clearEmployeesExportListError();
  }, [])
  // //------------------------------------------Filter-----------------------------------------------------------
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const searchInputValidation = (event) => {
    const { value, name } = event.target;
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setFilter({
      ...filter,
      [name]: value,
    })
  }

  const handleSearchClick = () => {
    setPage();
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }
  const debounceTime = window.__ENV && window.__ENV.debounceTime;
  useEffect(() => {
    if (filter) {
      const timeout = setTimeout(() => {
        handleSearchClick();
      }, debounceTime);
      setDebounceTimeout(timeout);
    }
  }, [filter])

  const handleEmployeeName = (inputValue, callback) => {
    const { getManagerNameAsyncSelect } = props;
    return getManagerNameAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleAddEmployeeName = (option) => {
    console.log("option:", option);

    let managerEmail;
    const { label, value } = option || {};
    const managerData = label && label.split(' | ');
    if (managerData && managerData.length > 0) {
      managerEmail = managerData[1]
    }
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    if (value === 'N/A') {
      setFilter({
        ...filter,
        ManagerUserEmail: value,
      })
    } else {
      setFilter({
        ...filter,
        ManagerUserEmail: managerEmail,
      })
    }
  }
  // ----------------------------------------SORTING-----------------------------------------------------------//
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = (column) => {
    if (sortOrder === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder(column);
      setSortDirection('asc');
    }
  };

  const sortedEmployeeList = [...EmployeeList].sort((a, b) => {
    const aValue = a[sortOrder] || 'NA';
    const bValue = b[sortOrder] || 'NA';

    if (sortOrder === 'IsTerminated') {
      return sortDirection === 'asc' ? b.IsTerminated - a.IsTerminated : a.IsTerminated - b.IsTerminated;
    }
    if (sortOrder === 'IsRehired') {
      return sortDirection === 'asc' ? b.IsRehired - a.IsRehired : a.IsRehired - b.IsRehired;
    }
    if (sortOrder === 'IsUserActive') {
      return sortDirection === 'asc' ? b.IsUserActive - a.IsUserActive : a.IsUserActive - b.IsUserActive;
    }
    if (sortOrder === 'IsPeopleManager') {
      return sortDirection === 'asc' ? b.IsPeopleManager - a.IsPeopleManager : a.IsPeopleManager - b.IsPeopleManager;
    }
    if (sortOrder === 'IsPortalEnable') {
      return sortDirection === 'asc' ? b.IsPortalEnable - a.IsPortalEnable : a.IsPortalEnable - b.IsPortalEnable;
    }
    if (sortOrder === 'EnableMailNotification') {
      return sortDirection === 'asc' ? b.EnableMailNotification - a.EnableMailNotification : a.EnableMailNotification - b.EnableMailNotification;
    }
    return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });
  //-----------------------------------------Modal open & close-----------------------------------------------------

  const handleOpenCreateEmployee = () => {
    props.openCreateEmployeeModal();
  };
  const handleClose = () => {
    props.clearFormAction();
    props.closeCreateEmployeeModal();
  };

  const { userAppDetails, displayName } = props.currentProfile || {};
  const App = userAppDetails && userAppDetails.filter((i) => {
    return (i.appName === "Employee") ? i.appId : ""
  })
  const style = {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };

  const searchStatus = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      IsTerminated: value,
    })
  }
  const searchTitle = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      EmployeeTitle: value,
    })
  }
  const searchIsRehired = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      IsRehired: value,
    })
  }
  const searchIsPeopleManager = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      IsPeopleManager: value,
    })
  }
  const searchIsPortalEnable = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      IsPortalEnable: value,
    })
  }
  const searchIsuserEnable = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      IsUserActive: value,
    })
  }
  const searchEnableMailNotification = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      EnableMailNotification: value,
    })
  }
  const searchType = (Value) => {
    const { label, value } = Value || {};
    setFilter({
      ...filter,
      EmployeeType: value,
    })
  }

  const navigate = useNavigate();
  const OnDoubleClickAction = (Id) => {
    navigate(`/employees/employee/details/${Id}`);
  };

  //-------------------------------------------------Export---------------------------------------------------------------------------
  const handleExports = () => {
    props.getEmployeeExportList(buildQueryEmployeeDisplayName(), buildQueryEmployeeType(), buildQueryEmployeeTitle(), buildQueryDateOfBirth(), buildQuerysearchDateOfJoin(), buildQuerysearchIsTerminated(), buildQuerysearchManagerUserEmail(), buildQuerysearchUserEmail(), buildQuerysearchIsRehired(), buildQuerysearchIsPeopleManager(), buildQueryIsPortalEnable(), buildQuerySearchEnableMailNotification(), buildQuerysearchIsUserActive())
      .then((response) => {
        if (response) {
          const exportEmployeeExportList = [];
          const headerExcel = [
            { headerName: "EMPLOYEE", key: "employeeDisplayName", headerWidhth: 30 },
            { headerName: "EMPLOYEE EMAIL", key: "userEmail", headerWidhth: 50 },
            { headerName: "TYPE", key: "employeeType", headerWidhth: 30 },
            { headerName: "DESIGNATION", key: "employeeTitle", headerWidhth: 20 },
            { headerName: "MANAGER EMAIL", key: "managerUserEmail", headerWidhth: 50 },
            { headerName: "DATE OF BIRTH", key: "dateOfBirth", headerWidhth: 20 },
            { headerName: "DATE OF JOIN", key: "dateOfJoin", headerWidhth: 20 },
            { headerName: "EMPLOYEE STATUS", key: "isTerminated", headerWidhth: 20 },
            { headerName: "USER STATUS", key: "isUserActive", headerWidhth: 20 },
            { headerName: "IS REHIRED", key: "isRehired", headerWidhth: 20 },
            { headerName: "IS PEOPLE MANAGER", key: "IsPeopleManager", headerWidhth: 20 },
            { headerName: "ACCOUNT STATUS", key: "IsPortalEnable", headerWidhth: 30 },
            { headerName: "EMAIL NOTIFICATION STATUS", key: "enableMailNotification", headerWidhth: 30 },
          ]
          response && response.forEach((element) => {
            exportEmployeeExportList.push({
              employeeDisplayName: element.employeeDisplayName || "N/A",
              userEmail: element.userEmail || "N/A",
              employeeType: element.employeeType || "N/A",
              employeeTitle: element.employeeTitle || "N/A",
              managerUserEmail: element.managerUserEmail || "N/A",
              dateOfBirth: element.dateOfBirth ? moment(element.dateOfBirth).format('DD MMM YYYY') : 'N/A',
              dateOfJoin: element.dateOfJoin ? moment(element.dateOfJoin).format('DD MMM YYYY') : 'N/A',
              isTerminated: element.isTerminated ? "Terminated" : "Active",
              isUserActive: element.isUserActive ? "Active" : "Inactive",
              isRehired: element.isRehired ? "Yes" : 'No',
              IsPeopleManager: element.isPeopleManager ? "Yes" : 'No',
              IsPortalEnable: element.isPortalEnable ? "Enabled" : 'Disabled',
              enableMailNotification: element.enableMailNotification ? "Enabled" : 'Disabled',
            });
          });
          excelSheetExport(headerExcel, exportEmployeeExportList, "Employee-List");
        }
      })
  }


  const IsAdmin = userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "employee").length > 0 &&
    userAppDetails
      .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "employee")
      .map(userApp => {
        const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
          const roleName = role.roleName.toLowerCase();
          return roleName;
        });
        return adminRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
      })



  console.log('toggleView:', toggleView);

  return (
    <React.Fragment>
      <div className="page-content">
        <Card >
          <CardTitle
            tag="h4"
            className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
          >
            <div className="d-flex align-items-center">
              <strong>EMPLOYEE -&nbsp;</strong>
              <Badge color="danger" id={'EmployeeCount'}>
                {props && props.totalCount}
              </Badge>
              <ElementToolTip id={'EmployeeCount'} name={'Employee Count'} />
            </div>
            <Row className='border border-info rounded-1' noGutters>
              <Col
                className={toggleView ? "bg-info text-white p-2" : "bg-white text-info p-2 rounded-1"}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setToggleView(true)
                  props.sendToggleView(true)
                }}
              >
                <FontAwesomeIcon icon={faBars} className='fs-4' />
              </Col>
              <Col
                className={!toggleView ? "bg-info p-2 text-white" : "bg-white text-info p-2 rounded-1"}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setToggleView(false)
                  props.sendToggleView(false)
                }}
              >
                <FontAwesomeIcon icon={faBorderAll} className='fs-4' />
              </Col>
            </Row>
            <div className="d-flex align-items-center">
              <Input
                type="text"
                placeholder="Search by name or email"
                name="EmployeeDisplayName"
                onChange={searchInputValidation}
                value={filter && filter.EmployeeDisplayName}
                onKeyPress={handleKeyPress}
                className="me-2"
                style={{ width: '200px' }}
              />
              {userAppDetails &&
                userAppDetails.filter((item) => item && item.appName && item.appName.toLowerCase() === "employee").length > 0 &&
                userAppDetails
                  .filter((userApp) => userApp && userApp.appName && userApp.appName.toLowerCase() === "employee")
                  .map((userApp) => {
                    const adminRoles =
                      userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter((role) => {
                        const roleName = role.roleName.toLowerCase();
                        return roleName;
                      });
                    const isAdmin = adminRoles.filter(
                      (role) => role.roleName.toLowerCase() === "administrator"
                    ).length > 0;

                    return (
                      <>
                        {isAdmin && (
                          <Link
                            type="button"
                            className="btn btn-info ms-2"
                            onClick={handleOpenCreateEmployee}
                          >
                            CREATE
                          </Link>
                        )}
                      </>
                    );
                  })}
              <Button
                className="ms-2"
                color="success"
                onClick={handleExports}
                disabled={EmployeeList && EmployeeList.length <= 0 || props.isGetEmployeeExportPending}
              >
                {props.isGetEmployeeExportPending ? 'EXPORTING...' : 'EXPORT'}
              </Button>
            </div>
          </CardTitle>

        </Card>
        <Card>
          <CardBody className="p-4">
            {(props.errorMessage || props.employeeExportListError) ? <div className='w-100'>
              {(props.errorMessage || props.employeeExportListError) && (
                <VWMessage type="danger" defaultMessage={props.errorMessage || props.employeeExportListError} baseClassName="alert" ></VWMessage>
              )}
            </div> : ""}
            {toggleView ?
              <div style={{ height: "60vh", overflowX: "auto" }}>
                <Table className="table table-striped table-hover table-nowrap w-100 " style={style.table}>
                  <thead className='overflow-hidden sticky-top ' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                    <tr>
                      <th onClick={() => handleSort('EmployeeDisplayName')} className='hand-cursor'
                      >
                        &nbsp;  Employee &nbsp;
                        {sortOrder === 'EmployeeDisplayName' && (
                          <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                        )}
                      </th>
                      <th onClick={() => handleSort('ManagerUserName')} className='hand-cursor'
                      >
                        &nbsp;  Manager  &nbsp;
                        {sortOrder === 'ManagerUserName' && (
                          <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                        )}
                      </th>
                      <th onClick={() => handleSort('EmployeeType')} className='hand-cursor'
                      >
                        &nbsp;  Employee Type  &nbsp;
                        {sortOrder === 'EmployeeType' && (
                          <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                        )}
                      </th>

                      <th onClick={() => handleSort('DateOfBirth')} className='hand-cursor'
                      >
                        &nbsp;  Date Of Birth  &nbsp;
                        {sortOrder === 'DateOfBirth' && (
                          <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                        )}
                      </th>
                      <th onClick={() => handleSort('DateOfJoin')} className='hand-cursor'
                      >
                        &nbsp;  Date Of Join  &nbsp;
                        {sortOrder === 'DateOfJoin' && (
                          <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                        )}
                      </th>
                      <th onClick={() => handleSort('EnableMailNotification')} className='hand-cursor'
                      >
                        &nbsp;  Notification  &nbsp;
                        {sortOrder === 'EnableMailNotification' && (
                          <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                        )}
                      </th>
                      <th onClick={() => handleSort('IsPortalEnable')} className='hand-cursor'
                      >
                        &nbsp;  IsPortalEnable  &nbsp;
                        {sortOrder === 'IsPortalEnable' && (
                          <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                        )}
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {!props.isGetEmployeePending && (sortedEmployeeList && sortedEmployeeList.length > 0) ? (
                      sortedEmployeeList && sortedEmployeeList.map((data, index) => (
                        <tr key={index} onDoubleClick={() => OnDoubleClickAction(data.Id, data.AppId)}>
                          <td className='ps-4'>{data.EmployeeDisplayName || 'N/A'}
                            <br />
                            <small>{data.UserEmail || 'N/A'}</small>
                          </td>
                          <td className='ps-4'>{data.ManagerUserName || 'N/A'}<br />
                            <small>{data.ManagerUserEmail || 'N/A'}</small></td>
                          <td className='ps-4'>{data.EmployeeType || 'N/A'}</td>
                          <td className='ps-4'>{data.DateOfBirth ? moment(data.DateOfBirth).format("DD MMM YYYY") : "N/A"}</td>
                          <td className='ps-4'>{data.DateOfJoin ? moment(data.DateOfJoin).format("DD MMM YYYY") : "N/A"}</td>
                          <td className='ps-4'>{data.EnableMailNotification ? 'On' : 'Off'}</td>
                          <td className='ps-4'>{data.IsPortalEnable ? "Active" : "Inactive"}</td>
                        </tr>
                      ))
                    ) : (
                      props.isGetEmployeePending ? <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1000,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <VWSpinner />
                          </div>
                        </div>
                      </div>
                        :
                        <div className="sticky-spinner-row">
                          <div className="position-relative">
                            <div className="d-flex justify-content-center align-items-center"
                              style={{
                                position: 'fixed',
                                top: '50%',
                                left: '60%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1000,
                                width: '100vw',
                                height: '100vh',
                                pointerEvents: 'none',
                              }}>
                              <NoRecord />
                            </div>
                          </div>
                        </div>
                    )}

                  </tbody>
                </Table>
              </div>
              :
              <>
                <div style={{ height: "60vh", overflowX: "auto" }} className="card-container">
                  {!props.isGetEmployeePending && sortedEmployeeList && sortedEmployeeList.length > 0 ? (
                    sortedEmployeeList.map((data, index) => <EmployeeCard key={index} data={data} />)
                  ) : props.isGetEmployeePending ?
                    <div className="sticky-spinner-row">
                      <div className="position-relative">
                        <div className="d-flex justify-content-center align-items-center"
                          style={{
                            position: 'fixed',
                            top: '50%',
                            left: '60%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1,
                            width: '100vw',
                            height: '100vh',
                            pointerEvents: 'none',
                          }}>
                          <VWSpinner />
                        </div>
                      </div>
                    </div>
                    :
                    <div className="sticky-spinner-row">
                      <div className="position-relative">
                        <div className="d-flex justify-content-center align-items-center"
                          style={{
                            position: 'fixed',
                            top: '50%',
                            left: '60%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1,
                            width: '100vw',
                            height: '100vh',
                            pointerEvents: 'none',
                          }}>
                          <NoRecord />
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </>
            }
          </CardBody>
          <CardFooter>
            {EmployeeList && EmployeeList.length > 0 ?
              <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
            }
          </CardFooter>
        </Card>
        <VWModel
          header="CREATE EMPLOYEE"
          modalSize="large"
          isOpen={props.isEmployeeCreateModalOpen}
          handleClose={handleClose}
        >
          <CreateEmployee
            handleClose={handleClose}
            filteredData={filter}
            appId={App ? App[0] && App[0].appId : ''}
          />
        </VWModel>
      </div>
    </React.Fragment >
  )
}

const mapStateToProps = (state) => ({
  EmployeeList: state.Employee.EmployeeList,
  errorMessage: state.Employee.employeeListError,
  totalCount: state.Employee.totalRecords,
  isEmployeeCreateModalOpen: state.Employee.isCreateEmployeeModalOpen,
  isGetEmployeePending: state.Employee.isGetEmployeePending,
  EmployeeExportList: state.Employee.EmployeeExportList,
  employeeExportListError: state.Employee.employeeExportListError,
  isGetEmployeeExportPending: state.Employee.isGetEmployeeExportPending,
  currentProfile: state.login.currentProfile,
  toggleViewBool: state.OverAllDocsList.toggleViewBool,

});

const mapDispatchToProps = (dispatch) => ({
  sendToggleView: (toggleView) => dispatch(sendToggleViewDocs(toggleView)),
  getEmployeeList: (filter, page, PageSize, searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchIsUserActive) => dispatch(getEmployeeListAction(filter, page, PageSize, searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchIsUserActive)),
  getEmployeeExportList: (searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchEnableMailNotification, searchIsUserActive) => dispatch(getEmployeeExportList(searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchEnableMailNotification, searchIsUserActive)),
  closeCreateEmployeeModal: () => dispatch(closeCreateEmployeeModalAction()),
  clearFormAction: () => dispatch(clearForm()),
  openCreateEmployeeModal: () => dispatch(openCreateEmployeeModalAction()),
  clearEmployeesExportListError: () => dispatch(clearEmployeesExportListError()),
  ClearPath: () => dispatch(clearSetPath()),
  getManagerNameAsyncSelect: (searchValue, callback) => dispatch(getManagerNameListAsyncSelect(searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);

