//--------------------TASK SCOPE -CREATE-----------------------------------
export const TASK_SCOPE_CREATE_OPEN = "TASK_SCOPE_CREATE_OPEN";
export const TASK_SCOPE_CREATE_CLOSE = "TASK_SCOPE_CREATE_CLOSE";
export const TASK_SCOPE_CREATE_PENDING = "TASK_SCOPE_CREATE_PENDING";
export const TASK_SCOPE_CREATE_ERROR_CLEAR = "TASK_SCOPE_CREATE_ERROR_CLEAR";
export const TASK_SCOPE_CREATE_ERROR = "TASK_SCOPE_CREATE_ERROR";
export const TASK_SCOPE_CREATE_SUCCESS = "TASK_SCOPE_CREATE_SUCCESS";

//--------------------SCOPE-UPDATE-----------------------------------
export const TASK_SCOPE_UPDATE_OPEN = "TASK_SCOPE_UPDATE_OPEN";
export const TASK_SCOPE_UPDATE_CLOSE = "TASK_SCOPE_UPDATE_CLOSE";
export const TASK_SCOPE_UPDATE_PENDING = "TASK_SCOPE_UPDATE_PENDING";
export const TASK_SCOPE_UPDATE_ERROR = "TASK_SCOPE_UPDATE_ERROR";
export const TASK_SCOPE_UPDATE_SUCCESS = "TASK_SCOPE_UPDATE_SUCCESS";
export const TASK_SCOPE_UPDATE_ERROR_CLEAR = "TASK_SCOPE_UPDATE_ERROR_CLEAR";
export const SET_TASK_SCOPE_DETAILS = "SET_TASK_SCOPE_DETAILS";

//--------------------TASK SCOPE-LIST-----------------------------------
export const SET_TASK_SCOPE_LIST = "SET_TASK_SCOPE_LIST";
export const TASK_SCOPE_LIST_PENDING = "TASK_SCOPE_LIST_PENDING";
export const TASK_SCOPE_LIST_ERROR = "TASK_SCOPE_LIST_ERROR";
export const TASK_SCOPE_LIST_ERROR_CLEAR = "TASK_SCOPE_LIST_ERROR_CLEAR";

