import agent from "../../services/agent"
import moment from 'moment'
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  FORM_LOGIN,
  SET_CURRENT_PROFILE,
  LOGIN_USER_SUCCESS,
  USER_LOGOUT,
  SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
  LOGIN_PENDING,
  LOGIN_PENDING_ERROR,
  LOGIN_PENDING_SUCCESS,
  LOGIN_ERROR,
  CLEAR_LOGIN_ERROR,
  ENABLE_USER_ERROR,
  ENABLE_USER_PENDING,
  ENABLE_USER_PENDING_SUCCESS,
  CLEAR_ENABLE_USER_ERROR,
  ENABLE_USER_PENDING_ERROR,
  FORGOT_PASSWORD_FORM,
  RESET_PASSWORD_FORM,
  FORM_REGISTER,
  SET_IS_LOADING,
  SET_LOGIN_ERROR,
  SET_CURRENT_PROFILE_PENDING,
  CLEAR_CURRENT_PROFILE_LIST_ERROR,
  SET_CURRENT_PROFILE_ERROR,
  CLOSE_CHANGE_PASSWORD_MODAL,
  OPEN_CHANGE_PASSWORD_MODAL,
  SET_CHANGE_PASSWORD_ERROR,
  CLEAR_CHANGE_PASSWORD_ERRORS,
  SET_PATH,
  CLEAR_SET_PATH,
  ENABLE_USER_ACCOUNT_PENDING_SUCCESS,
  ENABLE_USER_ACCOUNT_PENDING,
  DISABLE_USER_ACCOUNT_PENDING,
  DISABLE_USER_ACCOUNT_SUCCESS,
  RECOVER_PENDING,
  RECOVER_SUCCESS,
  DISABLE_RESET_LINK_PENDING,
  DISABLE_RESET_LINK_SUCCESS,
  DISABLE_FORGOT_PASSWORD_PENDING,
  DISABLE_FORGOT_PASSWORD_SUCCESS,
  SET_FORGOT_PASSWORD_SUCCESS,
  CLEAR_FORGOT_PASSWORD_CONFIRMATION_STATUS,
  CLEAR_FORGOT_PASSWORD_SUCCESS,
  CLEAR_PATH,
  SET_LOGIN_PATH,
  CLEAR_SET_LOGIN_PATH,
  OPEN_DISPLAY_PICTURE_MODAL,
  CLOSE_DISPLAY_PICTURE_MODAL,
  CLEAR_UPDATE_PROFILE_DETAILS_ERROR,
  UPDATE_PROFILE_DETAILS_PENDING,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_ERROR,
  IS_CHANGE_PASSWORD_PENDING,
  IS_CHANGE_PASSWORD_SUCCESS,
} from "./actionTypes"
import { getToken, setTokenDetails } from "../../../helper/common"
import { clearChangePasswordError } from "../../actions"
import { clearForm } from "../../form/actions"
import { showSnackbarStatus } from "../../snackbar/actions"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUsers = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

// export const setTokenDetails = (authData) => {
//   const { access_token, expires_in, token_type, id_token, refresh_token } = authData;
//   // console.log(access_token, expires_in, token_type);
//   const expires = moment().unix() + expires_in;
//   // console.log("expires: ", expires);
//   // console.log("refresh_token: ", refresh_token);
//   localStorage.setItem("id_token", id_token);
//   localStorage.setItem("accessToken", access_token);
//   localStorage.setItem("expires_in", expires);
//   localStorage.setItem("token_type", token_type);
//   localStorage.setItem("refresh_token", refresh_token);
// };

// export const setProfileLocalStorage = (profile) => {
//   // console.log("profile:", profile);
//   const Roles = profile && profile.roles && profile.roles.map((data) => (
//     data.roleName
//   ))
//   const RoleName = Roles && Roles.toString();
//   // console.log("Roles:", Roles && Roles.toString());
//   // console.log("RoleName:", RoleName);
//   localStorage.setItem("RoleName", RoleName);
// }

export const formLogin = (form): Action => ({
  type: FORM_LOGIN,
  payload: { form }
})

export const registerForm = (formRegister): Action => ({
  type: FORM_REGISTER,
  payload: { formRegister }
})


export const forgotPasswordForm = (formforgot): Action => ({
  type: FORGOT_PASSWORD_FORM,
  payload: { formforgot }
})

export const resetPasswordForm = (formReset): Action => ({
  type: RESET_PASSWORD_FORM,
  payload: { formReset }
})

export const loginUserSuccess = (
  email: string,
  token: string,
  expires: number
): Action => ({
  type: LOGIN_USER_SUCCESS,
  payload: { email, token, expires },
})

export const loginUserPending = (): Action => ({
  type: LOGIN_PENDING,
});

export const loginUserPendingError = (): Action => ({
  type: LOGIN_PENDING_ERROR,
});

export const loginUserPendingSuccess = (): Action => ({
  type: LOGIN_PENDING_SUCCESS,
});

export const loginUserError = (errorMessage: string): Action => ({
  type: LOGIN_ERROR,
  payload: { errorMessage },
});

export const clearLoginUserError = (): Action => ({
  type: CLEAR_LOGIN_ERROR,
});

export const enableUserError = (ErrorMessage) => ({
  type: ENABLE_USER_ERROR,
  payload: { ErrorMessage },
});

export const enableUserPending = (): Action => ({
  type: ENABLE_USER_PENDING,
});
export const recoverPending = (): Action => ({
  type: RECOVER_PENDING,
});
export const recoverSuccess = (): Action => ({
  type: RECOVER_SUCCESS,
});
export const enableUserAccountPending = (): Action => ({
  type: ENABLE_USER_ACCOUNT_PENDING,
});

export const enableUserPendingSuccess = (): Action => ({
  type: ENABLE_USER_PENDING_SUCCESS,
});
export const enableUserAccountPendingSuccess = (): Action => ({
  type: ENABLE_USER_ACCOUNT_PENDING_SUCCESS,
});

export const clearEnableUserError = (): Action => ({
  type: CLEAR_ENABLE_USER_ERROR,
});

export const enableUserPendingError = (): Action => ({
  type: ENABLE_USER_PENDING_ERROR,
});

export const logoutUser = () => (dispatch: Function) => {
  dispatch({ type: USER_LOGOUT });
};

const setCurrentProfile = (currentProfile): Action => ({
  type: SET_CURRENT_PROFILE,
  payload: { currentProfile },
});

export const setIsLoading = (isloading) => ({
  type: SET_IS_LOADING,
  payload: { isloading },
});

export const setCurrentProfilePending = () => ({
  type: SET_CURRENT_PROFILE_PENDING,
})

export const setCurrentProfileError = (errorMessage,) => ({
  type: SET_CURRENT_PROFILE_ERROR,
  payload: { errorMessage }
});
export const setChangePasswordError = (changePasswordErrorMessage) => ({
  type: SET_CHANGE_PASSWORD_ERROR,
  payload: { changePasswordErrorMessage }
});

export const clearCurrentProfileListError = () => ({
  type: CLEAR_CURRENT_PROFILE_LIST_ERROR,
})

export const setLoginError = (loginErrorMessage,) => ({
  type: SET_LOGIN_ERROR,
  payload: { loginErrorMessage }
});
export const clearChangePasswordErrors = () => ({
  type: CLEAR_CHANGE_PASSWORD_ERRORS,
});
export const isChangePasswordPending = () => ({
  type: IS_CHANGE_PASSWORD_PENDING,
});
export const isChangePasswordSuccess = () => ({
  type: IS_CHANGE_PASSWORD_SUCCESS,
});
export const openModalChangePassword = () => ({
  type: OPEN_CHANGE_PASSWORD_MODAL,
});
export const openDisplayPictureModal = () => ({
  type: OPEN_DISPLAY_PICTURE_MODAL,
});
export const closeDisplayPictureModal = () => ({
  type: CLOSE_DISPLAY_PICTURE_MODAL,
});
export const closeModalChangePassword = () => ({
  type: CLOSE_CHANGE_PASSWORD_MODAL,
});
export const isDisableAccountPending = () => ({
  type: DISABLE_USER_ACCOUNT_PENDING,
});
export const isDisableAccountSuccess = () => ({
  type: DISABLE_USER_ACCOUNT_SUCCESS,
});
export const isDisableResetLinkPending = () => ({
  type: DISABLE_RESET_LINK_PENDING,
});
export const isDisableResetLinkSuccess = () => ({
  type: DISABLE_RESET_LINK_SUCCESS,
});
export const isDisableForgotPasswordsuccess = () => ({
  type: DISABLE_FORGOT_PASSWORD_SUCCESS,
});
export const isDisableForgotPasswordPending = () => ({
  type: DISABLE_FORGOT_PASSWORD_PENDING,
});
export const clearSetPath = () => ({
  type: CLEAR_SET_PATH,
});
export const clearSetLoginPath = () => ({
  type: CLEAR_SET_LOGIN_PATH,
});

export const setPath = (path) => ({
  type: SET_PATH,
  payload: { path }
});
export const setLoginPath = (path) => ({
  type: SET_LOGIN_PATH,
  payload: { path }
});


export const userLogin = (code) => (dispatch) => {
  console.log("code:", code);
  dispatch(loginUserPending());
  agent.Auth.login(code)
    .then((OauthData) => {
      console.log("OauthData:", OauthData);
      setTokenDetails(OauthData);
      const { expires_in, id_token } = OauthData;
      agent.Auth.getCurrentUser(id_token)
        .then((currentUser) => {
          dispatch(loginUserPendingSuccess());
          dispatch(setCurrentProfile(currentUser));
          dispatch(loginUserSuccess(currentUser.email, id_token, expires_in));
          dispatch(clearLoginUserError());
        })
        .catch((err) => {
          console.log("err:", err.response);
          console.log("err:", err.response && err.response.text);

          dispatch(setCurrentProfileError(err.response && err.response.text));
          dispatch(loginUserPendingError());
          // dispatch(logoutUser());
        })
    })
    .catch((err) => {
      // dispatch(setCurrentProfileError(err.message));
      dispatch(setCurrentProfileError(err.response && err.response.text));
      dispatch(loginUserPendingError());
    });
}

export const getCurrentProfile = () => (dispatch) => {
  getToken(dispatch).then((Token) => {
    agent.Auth.getCurrentUser(Token)
      .then((currentUser) => {
        dispatch(setCurrentProfile(currentUser));
        dispatch(clearCurrentProfileListError());
      })
      .catch((err) => {
        console.log("err", err.response);
        console.log("err", err.response && err.response.text);
        dispatch(setCurrentProfileError(err.response && err.response.text));
        // dispatch(logoutUser());
      })
  })
}


export const setForgotPasswordConfirmationStatus = (confirmationStatus): Action => ({
  type: SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
  payload: { confirmationStatus },
});
export const clearForgotPasswordConfirmationStatus = (): Action => ({
  type: CLEAR_FORGOT_PASSWORD_CONFIRMATION_STATUS,
});

export const setForgotPasswordSuccess = (successStatus): Action => ({
  type: SET_FORGOT_PASSWORD_SUCCESS,
  payload: { successStatus },
});

export const clearForgotPasswordSuccess = (): Action => ({
  type: CLEAR_FORGOT_PASSWORD_SUCCESS,
});



export const enableUserAccount =
  (formFields) => (dispatch) => {
    // const currentState = getState();
    // const { formCompleteRegistration } = currentState && currentState.form;

    if (!formFields) {
      dispatch(enableUserError("Please fill required details."));
      return;
    }
    dispatch(isDisableAccountPending())
    dispatch(enableUserAccountPending());
    dispatch(clearEnableUserError());
    agent.Auth.enableAccount(formFields)
      .then((result) => {
        dispatch(showSnackbarStatus("User Account successfully enabled"));
        console.log("User Account successfully enabled", result);
        dispatch(enableUserAccountPendingSuccess());
        dispatch(isDisableAccountSuccess());
        // dispatch(clearForm());
      })
      .catch((err) => {
        dispatch(enableUserPendingError());
        if (err && err.response) {
          const message = err.response.text;
          console.log(message)
          console.log(err.response)
          dispatch(enableUserError(message));
          return;
        }
        else {
          console.log("enable user server error", err);
          const message = "Network error";
          dispatch(enableUserError(message));
          dispatch(showSnackbarStatus("Something went wrong. Please try again later"));
        }
        // console.log("enable user server error", err);
        // dispatch(enableUserError(null));
        // dispatch(clearForm());
        // dispatch(
        //   showSnackbarStatus("Something went wrong. Please try again later")
        // );
      });
  };


export const forgotPassword = (email) => (dispatch) => {
  if (!email || email.Email == "") {
    dispatch(setForgotPasswordConfirmationStatus('Please fill required details.'));
    dispatch(clearForgotPasswordSuccess());
    return;
  }
  else {
    dispatch(isDisableResetLinkPending());
    dispatch(clearForgotPasswordConfirmationStatus());
    agent.Auth.forgotPassword(email)
      .then((res) => {
        dispatch(setForgotPasswordSuccess(res));
        dispatch(showSnackbarStatus(res));
        dispatch(recoverSuccess());
        dispatch(isDisableResetLinkSuccess());
      })
      .catch((err) => {
        console.log(err.response && err.response.text);
        if (err && err.response) {
          dispatch(clearForgotPasswordSuccess());
          dispatch(setForgotPasswordConfirmationStatus(err.response && err.response.text));
        } else {
          console.log("enable user server error", err);
          const message = "Network error";
          dispatch(setForgotPasswordConfirmationStatus(message));
          dispatch(showSnackbarStatus("Something went wrong. Please try again later"));
        }
      });
  }
};
export const getChangePasswordAction = (formFields) => (dispatch) => {
  getToken(dispatch).then((Token) => {
    dispatch(clearChangePasswordErrors());
    dispatch(isChangePasswordPending());
    agent.Auth.changePassword(Token, formFields)
      .then((result) => {
        console.log(result)
        dispatch(clearForm());
        dispatch(closeModalChangePassword());
        dispatch(isChangePasswordSuccess());
        dispatch(showSnackbarStatus('Password Changed Successfully'));
        dispatch(clearChangePasswordError());
      })
      .catch((err) => {
        console.log(err.response && err.response.text)
        dispatch(setChangePasswordError(err.response && err.response.text));
        dispatch(isChangePasswordSuccess());
      });
  })
};

export const resetPassword =
  (code, formFields) => (dispatch) => {
    // const currentState = getState();
    // const { login } = currentState;
    // const { formReset } = login;

    if (!formFields) {
      dispatch(enableUserError("Please fill required details."));
      return;
    }
    dispatch(isDisableForgotPasswordPending());
    dispatch(clearEnableUserError());
    dispatch(enableUserPending());
    agent.Auth.resetPassword(formFields, code)

      .then((result) => {
        dispatch(
          formLogin({
            username: result,
            password: formFields.password,
          })
        );
        // dispatch(userLogin(code));
        // dispatch(clearForm());
        dispatch(enableUserPendingSuccess());
        dispatch(isDisableForgotPasswordsuccess());

      })
      .catch((err) => {
        dispatch(enableUserPendingError());
        if (err && err.response) {
          console.log("err && err.response:", err && err.response.text);
          const message = err.response && err.response.text;
          dispatch(enableUserError(message));
          // return;
        } else {
          console.log("reset password error", err);
          const message = "Network error";
          dispatch(enableUserError(message));
          dispatch(showSnackbarStatus("Something went wrong. Please try again later"));
        }
        // dispatch(clearForm());
        // dispatch(
        //   showSnackbarStatus("Something went wrong. Please try again later")
        // );
      });
  };

export const clearUpdateProfileDetailsError = () => ({
  type: CLEAR_UPDATE_PROFILE_DETAILS_ERROR,
});
export const updateProfileDetailsPending = () => ({
  type: UPDATE_PROFILE_DETAILS_PENDING,
});
export const updateProfileDetailsSuccess = () => ({
  type: UPDATE_PROFILE_DETAILS_SUCCESS,
});
export const updateProfileDetailsError = (errorMessage) => ({
  type: UPDATE_PROFILE_DETAILS_ERROR,
  payload: { errorMessage },
});

export const editProfileDetails = (formFields) => (dispatch) => {
  const dataToSend = {
    ...formFields
  }
  dispatch(clearUpdateProfileDetailsError());
  dispatch(updateProfileDetailsPending());
  getToken(dispatch).then((accessToken) => {
    agent.Auth.editProfileDetails(accessToken, dataToSend)
      .then(() => {
        dispatch(updateProfileDetailsSuccess());
        dispatch(closeDisplayPictureModal());
        dispatch(getCurrentProfile());
        dispatch(showSnackbarStatus('Profile updated successfully'));
      })
      .catch((err) => {
        if (err && err.response) {
          // const { message } = err.response.body;
          console.log("err:", err && err.response && err.response.text)
          dispatch(updateProfileDetailsError(err && err.response && err.response.text));
          return;
        } else {
          dispatch(updateProfileDetailsError('Network Error'));
          dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
        }
        console.log('Edit profile Details server error', err);
        dispatch(updateProfileDetailsError(null));
        dispatch(closeDisplayPictureModal());
      });
  });
};