import { CLEAR_UPDATE_WEEKLY_OFF_ERROR, SET_WEEKLY_OFF_LIST, SET_WEEKLY_OFF_LIST_ERROR, SET_WEEKLY_OFF_PENDING, UPDATE_WEEKLY_OFF_ERROR, UPDATE_WEEKLY_OFF_PENDING, UPDATE_WEEKLY_OFF_SUCCESS } from "./constants";

const initialState = {
    weeklyOffListError: null,
    isWeeklyOffPending: false,
    weeklyOffList: {},
    updateWeeklyOffErrorMessage: null,
    isUpdateweeklyOffPending: false,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_WEEKLY_OFF_PENDING: {
            return {
                ...localState,
                weeklyOffListError: null,
                isWeeklyOffPending: true
            }
        }
        case SET_WEEKLY_OFF_LIST: {
            const { weeklyOffList } = action.payload;
            return {
                ...localState,
                isWeeklyOffPending: false,
                weeklyOffList: weeklyOffList,
            }
        }
        case SET_WEEKLY_OFF_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isWeeklyOffPending: false,
                weeklyOffListError: errorMessage,
            };
        }
        case CLEAR_UPDATE_WEEKLY_OFF_ERROR: {
            return {
                ...localState,
                updateWeeklyOffErrorMessage: null,
                isUpdateweeklyOffPending: false,
            };
        }
        case UPDATE_WEEKLY_OFF_PENDING: {
            return {
                ...localState,
                isUpdateweeklyOffPending: true,
            };
        }
        case UPDATE_WEEKLY_OFF_SUCCESS: {
            return {
                ...localState,
                isUpdateweeklyOffPending: false,
            };
        }
        case UPDATE_WEEKLY_OFF_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateWeeklyOffErrorMessage: errorMessage,
                isUpdateweeklyOffPending: false,
            };
        }
        default: {
            return localState;
        }
    }
};