import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearUpdateFollowUpActionError,
    updateFollowUpAction,
    updateFollowUpActionSuccess
} from '../../../../../../store/Modules/crm/Settings/FollowUpAction/actions';

const EditFollowUpAction = (props) => {
    const { followUpActionDetails, isFollowUpActionUpdatePending, errorMessage } = props;
    const { Id, FollowUpActionName, IsActive } = followUpActionDetails || {};

    const [followUpActionDetail, setFollowUpActionDetail] = useState();
    const [Active, setActive] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        setFollowUpActionDetail({
            ...followUpActionDetail,
            id: Id,
            followUpActionName: FollowUpActionName,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateFollowUpActionError();
        props.updateFollowUpActionSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setFollowUpActionDetail({
            ...followUpActionDetail,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        })
    }
    const handleActiveStatusChange = () => {
        setFollowUpActionDetail({
            ...followUpActionDetail,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { followUpActionName } = followUpActionDetail || {};

        if (!followUpActionName) {
            currentErrors.followUpActionName = 'Follow up action name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateFollowUpAction(followUpActionDetail)

        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label>Follow Up Action Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="followUpActionName"
                        placeHolder="Enter follow up action name"
                        onChange={validateOnchange}
                        value={followUpActionDetail && followUpActionDetail.followUpActionName}
                        invalid={errors && errors.followUpActionName}
                    />
                    <FormFeedback>{errors && errors.followUpActionName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isFollowUpActionUpdatePending}
                    >
                        {isFollowUpActionUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    followUpActionDetails: state.crmFollowUpAction.followUpActionDetails,
    isFollowUpActionUpdatePending: state.crmFollowUpAction.isFollowUpActionUpdatePending,
    errorMessage: state.crmFollowUpAction.followUpActionUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateFollowUpAction: (followUpActionDetail) => dispatch(updateFollowUpAction(followUpActionDetail)),
    clearUpdateFollowUpActionError: () => dispatch(clearUpdateFollowUpActionError()),
    updateFollowUpActionSuccess: () => dispatch(updateFollowUpActionSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditFollowUpAction);