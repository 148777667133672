//-----------------------------------------------------------LIST-----------------------------------------------------------------------------------
export const SET_REPORT_LIST_PENDING = "SET_REPORT_LIST_PENDING";
export const SET_REPORT_LIST = 'SET_REPORT_LIST';
export const REPORT_LIST_ERROR = 'REPORT_LIST_ERROR';
export const CLEAR_REPORT_LIST_ERROR = 'CLEAR_REPORT_LIST_ERROR';
export const SET_REPORT_EXPORT_PENDING = 'SET_REPORT_EXPORT_PENDING';
export const REPORT_EXPORT_SUCCESS = 'REPORT_EXPORT_SUCCESS';
export const REPORT_EXPORT_ERROR = 'REPORT_EXPORT_ERROR';
export const SET_REPORT_USER_LIST_PENDING = 'SET_REPORT_USER_LIST_PENDING';
export const SET_REPORT_USER_LIST = 'SET_REPORT_USER_LIST';
export const CLEAR_REPORT_USER_LIST_ERROR = 'CLEAR_REPORT_USER_LIST_ERROR';
export const REPORT_USER_LIST_ERROR = 'REPORT_USER_LIST_ERROR';

export const REPORT_EXPORT_HEADERS = [
    "PROJECT NAME",
    "PROJECT MANAGER",
    "START DATE",
    "END DATE",
    "PENDING TASK",
    "TODAY TASK",
    "RATING",
    "PROJECT STATUS",
    "TOTAL TASK",
    "TOTAL MILESTONE"
];
export const USER_REPORT_EXPORT_HEADERS =[
    "USERNAME",
    "USEREMAIL",
    "RUNNING PROJECT",
    "MANAGED PROJECT",
    "PENDING TASKS",
    "TODAY TASKS",
    "TOTAL PROJECT",
    "COMPLETED PROJECTS"
]