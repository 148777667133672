

//--------------------TASK PERCENTAGE STATUS-LIST-----------------------------------
export const SET_TASK_PERCENTAGE_STATUS_LIST = "SET_TASK_PERCENTAGE_STATUS_LIST";
export const TASK_PERCENTAGE_STATUS_LIST_PENDING = "TASK_PERCENTAGE_STATUS_LIST_PENDING";
export const TASK_PERCENTAGE_STATUS_LIST_ERROR = "TASK_PERCENTAGE_STATUS_LIST_ERROR";
export const TASK_PERCENTAGE_STATUS_LIST_ERROR_CLEAR = "TASK_PERCENTAGE_STATUS_LIST_ERROR_CLEAR";


//--------------------SCOPE-UPDATE-----------------------------------
export const TASK_PERCENTAGE_UPDATE_OPEN = "TASK_PERCENTAGE_UPDATE_OPEN";
export const TASK_PERCENTAGE_UPDATE_CLOSE = "TASK_PERCENTAGE_UPDATE_CLOSE";
export const TASK_PERCENTAGE_UPDATE_PENDING = "TASK_PERCENTAGE_UPDATE_PENDING";
export const TASK_PERCENTAGE_UPDATE_ERROR = "TASK_PERCENTAGE_UPDATE_ERROR";
export const TASK_PERCENTAGE_UPDATE_SUCCESS = "TASK_PERCENTAGE_UPDATE_SUCCESS";
export const TASK_PERCENTAGE_UPDATE_ERROR_CLEAR = "TASK_PERCENTAGE_UPDATE_ERROR_CLEAR";
export const SET_TASK_PERCENTAGE_DETAILS = "SET_TASK_PERCENTAGE_DETAILS";