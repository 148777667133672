import { getToken, modifyActionList, modifyContactsUserList, modifyCrmAccountList, modifyCrmUserList, modifyCurrentPhaseList, modifyMainCategoryList, modifySubCategoryList, modifyTypeList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import {
    CLOSE_DELETE_OPPORTUNITY_DETAILS_MODAL,
    OPEN_DELETE_OPPORTUNITY_DETAILS_MODAL,
    OPPORTUNITY_CREATE_CLOSE,
    OPPORTUNITY_CREATE_ERROR,
    OPPORTUNITY_CREATE_ERROR_CLEAR,
    OPPORTUNITY_CREATE_OPEN,
    OPPORTUNITY_CREATE_PENDING,
    OPPORTUNITY_CREATE_SUCCESS,
    OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR,
    OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR_CLEAR,
    OPPORTUNITY_DETAILS_DOCUMENT_DELETE_PENDING,
    OPPORTUNITY_DETAILS_DOWNLOAD_ERROR,
    OPPORTUNITY_DETAILS_DOWNLOAD_ERROR_CLEAR,
    OPPORTUNITY_DETAILS_DOWNLOAD_PENDING,
    OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR,
    OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR_CLEAR,
    OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_PENDING,
    OPPORTUNITY_DETAILS_ERROR,
    OPPORTUNITY_DETAILS_ERROR_CLEAR,
    OPPORTUNITY_DETAILS_PENDING,
    OPPORTUNITY_EXPORT_LIST_ERROR,
    OPPORTUNITY_EXPORT_LIST_ERROR_CLEAR,
    OPPORTUNITY_EXPORT_LIST_PENDING,
    OPPORTUNITY_LIST_ERROR,
    OPPORTUNITY_LIST_ERROR_CLEAR,
    OPPORTUNITY_LIST_PENDING,
    OPPORTUNITY_UPDATE_CLOSE,
    OPPORTUNITY_UPDATE_ERROR,
    OPPORTUNITY_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_UPDATE_OPEN,
    OPPORTUNITY_UPDATE_PENDING,
    OPPORTUNITY_UPDATE_SUCCESS,
    SET_OPPORTUNITY_DETAILS,
    SET_OPPORTUNITY_DETAILS_DOWNLOAD,
    SET_OPPORTUNITY_DETAILS_DOWNLOAD_ZIP,
    SET_OPPORTUNITY_EXPORT_LIST,
    SET_OPPORTUNITY_LIST
} from "./constants";

//--------------------OPPORTUNITY-CREATE-----------------------------------
export const openOpportunityCreate = () => ({
    type: OPPORTUNITY_CREATE_OPEN
})
export const closeOpportunityCreate = () => ({
    type: OPPORTUNITY_CREATE_CLOSE
})
export const createOpportunityPending = () => ({
    type: OPPORTUNITY_CREATE_PENDING
})
export const createOpportunityError = (errorMessage) => ({
    type: OPPORTUNITY_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateOpportunityError = () => ({
    type: OPPORTUNITY_CREATE_ERROR_CLEAR,
})
export const createOpportunitySuccess = () => ({
    type: OPPORTUNITY_CREATE_SUCCESS,
})

export const getSubCategoryAsyncSelectAction = (oppCategory, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getSubCategoryAsyncList(accessToken, oppCategory, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                let totalActiveData = [];
                for (const list of value) {
                    const { IsActive } = list
                    if (IsActive) {
                        totalActiveData.push(list)
                    }
                }
                const updatedSubCategoryList = modifySubCategoryList(totalActiveData);
                //eslint-disable-next-line
                callback && callback(updatedSubCategoryList);
            })
            .catch((err) => {
                console.log('getSubCategoryAsyncSelectAction server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getContactAsyncSelectAction = (id, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getContactAsyncUserlist(accessToken, id, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyContactsUserList(value);
                console.log("value:", value);

                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getContactAsyncSelectAction server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getTypeAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getTypeAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                let totalActiveData = [];
                for (const list of value) {
                    const { IsActive } = list
                    if (IsActive) {
                        totalActiveData.push(list)
                    }
                }
                const updatedtypeList = modifyTypeList(totalActiveData);
                //eslint-disable-next-line
                callback && callback(updatedtypeList);
            })
            .catch((err) => {
                console.log('getTypeAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getTypeListAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getTypeAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedtypeList = modifyTypeList(value);
                //eslint-disable-next-line
                callback && callback(updatedtypeList);
            })
            .catch((err) => {
                console.log('getTypeAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getActionAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getActionAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                let totalActiveData = [];
                for (const list of value) {
                    const { IsActive } = list
                    if (IsActive) {
                        totalActiveData.push(list)
                    }
                }
                const updatedActionList = modifyActionList(totalActiveData);
                //eslint-disable-next-line
                callback && callback(updatedActionList);
            })
            .catch((err) => {
                console.log('getActionAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getActionListAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getActionAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedActionList = modifyActionList(value);
                //eslint-disable-next-line
                callback && callback(updatedActionList);
            })
            .catch((err) => {
                console.log('getActionAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getCurrentPhaseAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCurrentPhaseAsync(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                let totalActiveData = [];
                for (const list of value) {
                    const { IsActive } = list
                    if (IsActive) {
                        totalActiveData.push(list)
                    }
                }
                const updatedCurrentPhaseList = modifyCurrentPhaseList(totalActiveData);
                //eslint-disable-next-line
                callback && callback(updatedCurrentPhaseList);
            })
            .catch((err) => {
                console.log('getCurrentPhaseAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getCurrentPhaseListAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCurrentPhaseAsync(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedCurrentPhaseList = modifyCurrentPhaseList(value);
                //eslint-disable-next-line
                callback && callback(updatedCurrentPhaseList);
            })
            .catch((err) => {
                console.log('getCurrentPhaseAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getCategoryAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getMainCategoryAsync(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                let totalActiveData = [];
                for (const list of value) {
                    const { IsActive } = list
                    if (IsActive) {
                        totalActiveData.push(list)
                    }
                }
                const updatedCategoryList = modifyMainCategoryList(totalActiveData);
                //eslint-disable-next-line
                callback && callback(updatedCategoryList);
            })
            .catch((err) => {
                console.log('getCategoryAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getContantsAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getContactsAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyContactsUserList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getContantsAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const getCrmUserAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCrmUserAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmUserList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const createNewOpportunity = (formFields, filteredData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createOpportunityError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(createOpportunityPending());
    dispatch(clearCreateOpportunityError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.createOpportunity(accessToken, dataToSend)
            .then(() => {
                dispatch(createOpportunitySuccess());
                dispatch(closeOpportunityCreate());
                dispatch(getOpportunityList(filteredData));
                // dispatch(getOpportunityExportList());
                dispatch(showSnackbarStatus('Opportunity created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createOpportunityError(err.response && err.response.text));
                    return;
                }
                else {
                    dispatch(createOpportunityError('Network Error'));
                }
                console.log('Create Account server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------CONTACTS-UPDATE-----------------------------------
export const openOpportunityUpdate = () => ({
    type: OPPORTUNITY_UPDATE_OPEN
})
export const closeOpportunityUpdate = () => ({
    type: OPPORTUNITY_UPDATE_CLOSE
})
export const updateOpportunityPending = () => ({
    type: OPPORTUNITY_UPDATE_PENDING
})
export const updateOpportunityError = (errorMessage) => ({
    type: OPPORTUNITY_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateOpportunityError = () => ({
    type: OPPORTUNITY_UPDATE_ERROR_CLEAR,
})
export const updateOpportunitySuccess = () => ({
    type: OPPORTUNITY_UPDATE_SUCCESS,
})

export const updateOpportunity = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateOpportunityError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
            oppourtunityName: formFields && formFields.opportunityName
        })
    }
    dispatch(updateOpportunityPending());
    dispatch(clearUpdateOpportunityError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.updateOpportunity(accessToken, dataToSend)
            .then(() => {
                dispatch(updateOpportunitySuccess());
                dispatch(closeOpportunityUpdate());
                dispatch(getOpportunityDetails(formFields && formFields.id));
                dispatch(showSnackbarStatus('Opportunity updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateOpportunityError(err.response && err.response.text));
                    return;
                }
                else {
                    dispatch(updateOpportunityError('Network Error'));
                }
                console.log('Create Account server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------OPPORTUNITY-LIST-----------------------------------
export const opportunityListPending = () => ({
    type: OPPORTUNITY_LIST_PENDING
})
export const opportunityExportListPending = () => ({
    type: OPPORTUNITY_EXPORT_LIST_PENDING
})
export const opportunityListError = (errorMessage) => ({
    type: OPPORTUNITY_LIST_ERROR,
    payload: { errorMessage }
})
export const opportunityExportListError = (errorMessage) => ({
    type: OPPORTUNITY_EXPORT_LIST_ERROR,
    payload: { errorMessage }
})
export const clearOpportunityListError = () => ({
    type: OPPORTUNITY_LIST_ERROR_CLEAR,
})
export const clearOpportunityExportListError = () => ({
    type: OPPORTUNITY_EXPORT_LIST_ERROR_CLEAR,
})
export const setOpportunityList = (OpportunityList) => ({
    type: SET_OPPORTUNITY_LIST,
    payload: { OpportunityList }
})
export const setOpportunityExportList = (OpportunityExportList) => ({
    type: SET_OPPORTUNITY_EXPORT_LIST,
    payload: { OpportunityExportList }
})

export const getOpportunityExportList = (filter,searchContactName, searchCompanyName, searchExpectedValue, searchStage, searchTotalDays, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy) => (dispatch) => {
    dispatch(opportunityExportListPending());
   return getToken(dispatch).then((accessToken) => {
       return agent.Crm.getOpportunityExportList(accessToken,filter,searchContactName, searchCompanyName, searchExpectedValue, searchStage, searchTotalDays, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy)
            .then((result) => {
                console.log("result:", result);
                dispatch(clearOpportunityExportListError());
                // dispatch(setOpportunityExportList(result));
                return Promise.resolve(result);
            })
            .catch((err) => {
                let error;
                if (err.response) {
                    error = err.response && err.response.body && err.response.body.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(opportunityExportListError(error));
                console.log('Opportunity Export server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
export const getOpportunityList = (filteredData,page, PageSize, searchContactName, searchCompanyName, searchExpectedValue, searchStage, searchstartDate, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy) => (dispatch) => {
    dispatch(opportunityListPending());
    dispatch(clearOpportunityListError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getOpportunityList(accessToken, filteredData, page, PageSize, searchContactName, searchCompanyName, searchExpectedValue, searchStage, searchstartDate, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy)
            .then((result) => {
                console.log("result:", result);

                dispatch(setOpportunityList(result))
            })
            .catch((err) => {
                let error;
                if (err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(opportunityListError(error));
                console.log('Create Account server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------OPPORTUNITY-DETAILS-----------------------------------
export const opportunityDetailsPending = () => ({
    type: OPPORTUNITY_DETAILS_PENDING
})
export const opportunityDetailsDownloadPending = () => ({
    type: OPPORTUNITY_DETAILS_DOWNLOAD_PENDING
})
export const opportunityDetailsDownloadZipPending = () => ({
    type: OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_PENDING
})
export const opportunityDetailsDocumentDeletePending = () => ({
    type: OPPORTUNITY_DETAILS_DOCUMENT_DELETE_PENDING
})
export const opportunityDetailError = (errorMessage) => ({
    type: OPPORTUNITY_DETAILS_ERROR,
    payload: { errorMessage }
})
export const opportunityDetailDownloadError = (errorMessage) => ({
    type: OPPORTUNITY_DETAILS_DOWNLOAD_ERROR,
    payload: { errorMessage }
})
export const opportunityDetailDownloadZipError = (errorMessage) => ({
    type: OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR,
    payload: { errorMessage }
})
export const opportunityDetailDocumentDeleteError = (errorMessage) => ({
    type: OPPORTUNITY_DETAILS_DOCUMENT_DELETE_ERROR,
    payload: { errorMessage }
})
export const clearOpportunityDetailsError = () => ({
    type: OPPORTUNITY_DETAILS_ERROR_CLEAR,
})
export const openDeleteDocsModalAction = () => ({
    type: OPEN_DELETE_OPPORTUNITY_DETAILS_MODAL,
})
export const closeDeleteOpportunityModal = () => ({
    type: CLOSE_DELETE_OPPORTUNITY_DETAILS_MODAL,
})
export const clearOpportunityDetailsDownloadError = () => ({
    type: OPPORTUNITY_DETAILS_DOWNLOAD_ERROR_CLEAR,
})
export const clearOpportunityDetailsDownloadZipError = () => ({
    type: OPPORTUNITY_DETAILS_DOWNLOAD_ZIP_ERROR_CLEAR,
})
export const setOpportunityDetails = (OpportunityDetails) => ({
    type: SET_OPPORTUNITY_DETAILS,
    payload: { OpportunityDetails }
})
export const setOpportunityDetailsDownload = (OpportunityDetailsDownload) => ({
    type: SET_OPPORTUNITY_DETAILS_DOWNLOAD,
    payload: { OpportunityDetailsDownload }
})
export const setOpportunityDetailsDownloadZip = (OpportunityDetailsDownloadZip) => ({
    type: SET_OPPORTUNITY_DETAILS_DOWNLOAD_ZIP,
    payload: { OpportunityDetailsDownloadZip }
})

export const getOpportunityDetails = (id) => (dispatch) => {
    dispatch(opportunityDetailsPending());
    dispatch(clearOpportunityDetailsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getOpportunityDetails(accessToken, id)
            .then((result) => {
                console.log("result:", result);

                dispatch(setOpportunityDetails(result))
                // dispatch(showSnackbarStatus('Account created successfully'));
            })
            .catch((err) => {
                console.log("err:", err.response);

                let error;
                if (err.response) {
                    error = err.response.text;
                }
                else {
                    error = "Network Error"
                }
                dispatch(opportunityDetailError(error));
                console.log('Account details server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
export const getOpportunityDetailsDownload = (opportunityDocuments) => (dispatch) => {
    console.log("opportunityDocuments:", opportunityDocuments);

    dispatch(opportunityDetailsDownloadPending());
    dispatch(clearOpportunityDetailsDownloadError());
    getToken(dispatch).then(async (accessToken) => {
        const downloadPromises = opportunityDocuments && opportunityDocuments.map(doc => {
            return agent.Crm.getOpportunityDetailsDownload(accessToken, doc.opportunityDocumentId);
        });
        console.log("downloadPromises:", downloadPromises);

        try {
            // Wait for all promises to resolve
            const blobs = await Promise.all(downloadPromises);
            dispatch(setOpportunityDetailsDownload(blobs))
            // blobs will now be an array of Blob objects
            console.log(blobs); // Logs the array of Blob objects
            // return blobs; // You can return this array or process it further
        } catch (error) {
            dispatch(opportunityDetailDownloadError(error));
            console.error('Error downloading documents:', error);
        }

        // agent.Crm.getOpportunityDetailsDownload(accessToken, id)
        //     .then((result) => {
        //         console.log("result:", result);
        //         dispatch(setOpportunityDetailsDownload(result))
        //         // dispatch(showSnackbarStatus('Account created successfully'));
        //     })
        //     .catch((err) => {
        //         console.log("err:", err.response);

        //         let error;
        //         if (err.response) {
        //             error = err.response.text;
        //         }
        //         else {
        //             error = "Network Error"
        //         } 
        //         dispatch(opportunityDetailDownloadError(error));
        //         console.log('Opportunity details download server error', error);
        //         // dispatch(closeAccountCreate());
        //         dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
        //     });
    });
};
export const getOpportunityDetailsDownloadZip = (id) => (dispatch) => {
    console.log("zip id:", id);

    dispatch(opportunityDetailsDownloadZipPending());
    dispatch(clearOpportunityDetailsDownloadZipError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getOpportunityDetailsDownloadZip(accessToken, id)
            .then((result) => {
                console.log("result:", result);

                dispatch(setOpportunityDetailsDownloadZip(result))
            })
            .catch((err) => {
                console.log("err:", err.response);

                let error;
                if (err.response) {
                    error = err.response.text;
                }
                else {
                    error = "Network Error"
                }
                dispatch(opportunityDetailDownloadZipError(error));
                console.log('Opportunity details download server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
export const DeleteDocument = (id, detailId) => (dispatch) => {
    console.log("Delete Document id:", id);
    console.log("Detail Id:", detailId);
    dispatch(opportunityDetailsDocumentDeletePending());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getOpportunityDetailsDocumentDelete(accessToken, id)
            .then((result) => {
                console.log("result:", result);
                dispatch(showSnackbarStatus('Document Deleted successfully'));
                dispatch(getOpportunityDetails(detailId));
                if (detailId && detailId.opportunityDocuments) {
                    dispatch(getOpportunityDetailsDownloadZip(detailId));
                }
                dispatch(closeDeleteOpportunityModal());
            })
            .catch((err) => {
                console.log("err:", err.response);

                let error;
                if (err.response) {
                    error = err.response.text;
                }
                else {
                    error = "Network Error"
                }
                dispatch(opportunityDetailDocumentDeleteError(error));
                console.log('Opportunity document delete server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

