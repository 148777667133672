import React, { useEffect } from 'react'
import VWModel from '../../../../components/Modal/VWModal'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom';
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import moment from 'moment';
import { closeEditAppMasterModal, getAppMasterDetailsAction, openEditAppMasterModal } from '../../../../store/Modules/Admin/AppMaster/actions';
import AppMasterEdit from './AppMasterEdit';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';

const AppMasterDetails = (props) => {
    const params = useParams();
    const { id } = params;
    const { errorMessage, AppMasterDetails, isEditAppMasterModalOpen } = props || {};
    const { appName, latestVersion, createdDateTime, isActive, organizationIds, assignedTimeStamp } = AppMasterDetails || {};

    useEffect(() => {
        props.getAppMasterDetails(id);
        props.ClearPath();
    }, [props.getAppMasterDetails, id]);

    useEffect(() => {
        return async () => {
            await props.clearSetLoginPath(); 
            props.ClearPath();
        }
    }, []);
    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditAppMasterModel = () => {
        const { openEditAppMasterModalAction } = props;
        openEditAppMasterModalAction();
    };

    const handleCloseEditAppMasterModal = () => {
        const { closeEditAppMasterModalAction } = props;
        closeEditAppMasterModalAction();
    };
    //---------------------------------------------Role-----------------------------------------------------------------
    const { roles, userAppDetails } = props.currentProfile || {};
    const Roles = roles && roles.map((data) => (
        data.roleName
    ))

    let isSuperAdmin;
    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>APP MASTER DETAILS</strong>
                        {
                            (!errorMessage && userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "admin").length > 0 &&
                                userAppDetails
                                    .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "admin")
                                    .map(userApp => {
                                        const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                                            const roleName = role.roleName.toLowerCase();
                                            return roleName;
                                        });
                                        isSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;
                                        return (
                                            isSuperAdmin ? (
                                                <Link type="button"
                                                    className="btn btn-info"
                                                    onClick={handleOpenEditAppMasterModel}
                                                >
                                                    Edit
                                                </Link>
                                            ) : null
                                        );
                                    })
                            )
                        }

                    </CardTitle>
                    <CardBody className="p-4">
                        {errorMessage ? <div className='w-100'>
                            {errorMessage && (
                                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        {props.isAppMasterDetailsPending ?
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                            :
                            <Row className="justify-content-center">
                                <Col xl="6">
                                    <div className="card b">
                                        <div className="card-header">
                                            <h4 className="my-2">
                                                <span className='text-capitalize'>{appName || "N/A"}</span>
                                            </h4>
                                        </div>
                                        <div className="card-body bt">
                                            <div className="row pl-4">
                                                <div className="col-lg-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-striped table-hover">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <strong>App Name</strong>
                                                                    </td>
                                                                    <td>{appName || "N/A"}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <strong>Latest Version</strong>
                                                                    </td>
                                                                    <td>{latestVersion || "N/A"}</td>
                                                                </tr>
                                                                {isSuperAdmin ?
                                                                <tr>
                                                                    <td>
                                                                        <strong> Created Date</strong>
                                                                    </td>
                                                                    <td>
                                                                        {createdDateTime ? moment(createdDateTime).format('DD MMM YYYY',) : 'N/A'}
                                                                    </td>
                                                                </tr>:
                                                                    <tr>
                                                                        <td>
                                                                            <strong> Assigned Date</strong>
                                                                        </td>
                                                                        <td>
                                                                            {assignedTimeStamp ? moment(assignedTimeStamp).format('DD MMM YYYY',) : 'N/A'}
                                                                        </td>
                                                                    </tr>
                                                                        }
                                                                <tr>
                                                                    <td>
                                                                        <strong> Status</strong>
                                                                    </td>
                                                                    <td>
                                                                        {isActive ? (
                                                                            <Badge color="success" className="text-dark-white">
                                                                                Active
                                                                            </Badge>
                                                                        ) : (
                                                                            <Badge color="danger" className="text-dark-white">
                                                                                Inactive
                                                                            </Badge>
                                                                        )}
                                                                    </td>

                                                                </tr>
                                                                {isSuperAdmin ?
                                                                    <tr>
                                                                        <td><strong>Organization(s)</strong></td>
                                                                        <td>
                                                                            {(organizationIds &&organizationIds.length>0)?organizationIds && organizationIds.map((data, index) => (
                                                                                //eslint-disable-next-line
                                                                                <React.Fragment key={index}>
                                                                                    <Badge color="success" className="text-dark-white">
                                                                                        {data.orgName}
                                                                                    </Badge>&nbsp;
                                                                                </React.Fragment>
                                                                            )) :'N/A'}
                                                                        </td>
                                                                    </tr> : ''}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                    <VWModel
                        header="EDIT APP MASTER"
                        isOpen={isEditAppMasterModalOpen}
                        handleClose={handleCloseEditAppMasterModal}
                    >
                        <AppMasterEdit handleClose={handleCloseEditAppMasterModal} />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    AppMasterDetails: state.appMaster.appMasterDetails,
    errorMessage: state.appMaster.appMasterDetailsError,
    isEditAppMasterModalOpen: state.appMaster.isEditAppMasterModalOpen,
    currentProfile: state.login.currentProfile,
    isAppMasterDetailsPending: state.appMaster.isAppMasterDetailsPending
});

const mapDispatchToProps = (dispatch) => ({
    getAppMasterDetails: (Id) => dispatch(getAppMasterDetailsAction(Id)),
    openEditAppMasterModalAction: () => dispatch(openEditAppMasterModal()),
    closeEditAppMasterModalAction: () => dispatch(closeEditAppMasterModal()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMasterDetails)