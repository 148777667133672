import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { clearForm } from '../../../../store/form/actions';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import VWButton from '../../../../components/vwButton/VWButton';
import { clearCreateLeaveActionError, updateLeavetrackerActionList } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions';

const LeavemonitoringStatus = (props) => {
    const [leavetrackerAction, setLeaveTrackerAction] = useState({});
    const { status, data } = props;
    useEffect(() => {
        setLeaveTrackerAction(data);
        props.clearCreateLeaveActionError();
    }, [])
    const validateOnChange = (event) => {
        const { name, value } = event.target;
        setLeaveTrackerAction({
            ...leavetrackerAction,
            [name]: value
        })
    }
    const handleCreateActionLeavetracker = () => {
        props.updateLeavetrackerAction(leavetrackerAction, status).then(() => {
            props.handleStatusSelect([])
        })

    };
    console.log(leavetrackerAction)
    return (
        <>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <form className="mb-3" name="formAddVechicle">
                <div className="form-group">
                    <Label className="col-form-label">Reason
                    </Label>
                    <Input
                        style={{ height: '100px' }}
                        type="textarea"
                        name="statusDescription"
                        placeholder="Enter Reason"
                        onChange={validateOnChange}
                        value={leavetrackerAction.statusDescription}
                    />
                </div>
            </form>
            <div className="form-group">
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleCreateActionLeavetracker}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Saving...
                        </>
                            : <>Save</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </div>
            {/* <div className="form-group">
                <VWButton
                    messageKey="createTimeSheetActionButton"
                    defaultMessage="  Save  "
                    buttonType="success"
                    baseClassName="btn"
                    className="me-2"
                    onClick={handleCreateActionLeavetracker}
                ></VWButton>
                <VWButton
                    messageKey="cancelMyTeamTimeSheetButton"
                    defaultMessage="Cancel"
                    buttonType="danger"
                    baseClassName="btn"
                    onClick={props.handleClose}
                ></VWButton>
            </div> */}
        </>
    )
}
const mapStateToProps = (state) => ({
    form: state.form,
    isPending: state.leaveTracker.isPending,
    errorMessage: state.leaveTracker.createLeaveTrackerErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    clearFormAction: () => dispatch(clearForm()),
    updateLeavetrackerAction: (leavetrackerAction, status) => dispatch(updateLeavetrackerActionList(leavetrackerAction, status)),
    clearCreateLeaveActionError: () => dispatch(clearCreateLeaveActionError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeavemonitoringStatus);