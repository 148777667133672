import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearUpdateOpportunityCategoryError,
    updateOpportunityCategory
} from '../../../../../../store/Modules/crm/Settings/OpportunityCategory/actions';

const EditOpportunityCategory = (props) => {
    const { opportunityCategoryDetails, isOpportunityCategoryUpdatePending, errorMessage } = props;
    const { Id, OppCategory, OppSubCategory, IsActive } = opportunityCategoryDetails || {};

    const [opportunityCategoryDetail, setOpportunityCategoryDetail] = useState();
    const [Active, setActive] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        setOpportunityCategoryDetail({
            ...opportunityCategoryDetail,
            id: Id,
            oppCategory: OppCategory,
            oppSubCategory: OppSubCategory,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateOpportunityCategoryError();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setOpportunityCategoryDetail({
            ...opportunityCategoryDetail,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        })
    }
    const handleActiveStatusChange = () => {
        setOpportunityCategoryDetail({
            ...opportunityCategoryDetail,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { oppCategory, oppSubCategory } = opportunityCategoryDetail || {};

        if (!oppCategory) {
            currentErrors.oppCategory = 'Opportunity category name is required';
        }
        if (!oppSubCategory) {
            currentErrors.oppSubCategory = 'Opportunity sub-category name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateOpportunityCategory(opportunityCategoryDetail)

        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label>Opportunity Category Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="oppCategory"
                        placeHolder="Enter opportunity category name"
                        onChange={validateOnchange}
                        value={opportunityCategoryDetail && opportunityCategoryDetail.oppCategory}
                        invalid={errors && errors.oppCategory}
                    />
                    <FormFeedback>{errors && errors.oppCategory}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Opportunity Sub-Category Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="oppSubCategory"
                        placeHolder="Enter opportunity sub-category name"
                        onChange={validateOnchange}
                        value={opportunityCategoryDetail && opportunityCategoryDetail.oppSubCategory}
                        invalid={errors && errors.oppSubCategory}
                    />
                    <FormFeedback>{errors && errors.oppSubCategory}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isOpportunityCategoryUpdatePending}
                    >
                        {isOpportunityCategoryUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    opportunityCategoryDetails: state.crmOpportunityCategory.opportunityCategoryDetails,
    isOpportunityCategoryUpdatePending: state.crmOpportunityCategory.isOpportunityCategoryUpdatePending,
    errorMessage: state.crmOpportunityCategory.opportunityCategoryUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateOpportunityCategory: (opportunityCategoryDetail) => dispatch(updateOpportunityCategory(opportunityCategoryDetail)),
    clearUpdateOpportunityCategoryError: () => dispatch(clearUpdateOpportunityCategoryError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditOpportunityCategory);