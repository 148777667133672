//---------------------------------------------MM-CAMPAIGN-CREATE--------------------------------------------------------------------------------
export const OPEN_CREATE_CAMPAIGN_MODAL = "OPEN_CREATE_CAMPAIGN_MODAL";
export const CLOSE_CREATE_CAMPAIGN_MODAL = "CLOSE_CREATE_CAMPAIGN_MODAL";
export const CAMPAIGN_CREATE_PENDING = "CAMPAIGN_CREATE_PENDING";
export const CREATE_CAMPAIGN_SUCCESSS = "CREATE_CAMPAIGN_SUCCESSS";
export const CAMPAIGN_CREATE_ERROR = "CAMPAIGN_CREATE_ERROR";
export const CAMPAIGN_CREATE_ERROR_CLEAR = "CAMPAIGN_CREATE_ERROR_CLEAR";
export const CAMPAIGN_FROM_ADDRESS_ERROR = "CAMPAIGN_FROM_ADDRESS_ERROR";
export const CAMPAIGN_FROM_ADDRESS_ERROR_CLEAR = "CAMPAIGN_FROM_ADDRESS_ERROR_CLEAR";

//---------------------------------------------MM-CAMPAIGN-UPDATE--------------------------------------------------------------------------------
export const OPEN_EDIT_CAMPAIGN_MODAL = "OPEN_EDIT_CAMPAIGN_MODAL";
export const CLOSE_EDIT_CAMPAIGN_MODAL = "CLOSE_EDIT_CAMPAIGN_MODAL";
export const CAMPAIGN_EDIT_PENDING = "CAMPAIGN_EDIT_PENDING";
export const EDIT_CAMPAIGN_SUCCESSS = "EDIT_CAMPAIGN_SUCCESSS";
export const CAMPAIGN_EDIT_ERROR = "CAMPAIGN_EDIT_ERROR";
export const CLEAR_CAMPAIGN_EDIT_ERROR = "CLEAR_CAMPAIGN_EDIT_ERROR";

//---------------------------------------------MM-CAMPAIGN-LIST----------------------------------------------------------------------------------
export const SET_CAMPAIGN_LIST = "SET_CAMPAIGN_LIST";
export const SET_EXPORTED_CAMPAIGN_LIST = "SET_EXPORTED_CAMPAIGN_LIST";
export const SET_CAMPAIGN_LIST_PENDING = "SET_CAMPAIGN_LIST_PENDING";
export const SET_EXPORT_CAMPAIGN_LIST = "SET_EXPORT_CAMPAIGN_LIST";
export const CAMPAIGN_LIST_ERROR = "CAMPAIGN_LIST_ERROR";
export const CLEAR_CAMPAIGN_LIST_ERROR = "CLEAR_CAMPAIGN_LIST_ERROR";
export const CAMPAIGN_LIST_EXPORT_ERROR = "CAMPAIGN_LIST_EXPORT_ERROR";
export const CLEAR_CAMPAIGN_LIST_EXPORT_ERROR = "CLEAR_CAMPAIGN_LIST_EXPORT_ERROR";

export const CAMPAIGN_EXPORT_HEADERS = [
    "CAMPAIGN NAME",
    "FROM DISPLAY NAME",
    "FROM EMAIL ADDRESS",
    "MAIL CATEGORY",
    "SUBJECT",
    "CREATED ON",
    "CREATED BY",
    "STATUS"
];
export const CAMPAIGN_CONTACT_EXPORT_HEADERS = [
    "EMAIL",
];

export const MAIL_CATEGORY_OPTIONS = [
    { label: "Test", value: "Test" },
    { label: "Production", value: "Production" },
];

export const CAMPAIGN_STATUS_FILTER_OPTIONS = [
    { label: "Select...", value: null },
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
];

//---------------------------------------------MM-CAMPAIGN-DETAILS-------------------------------------------------------------------------------
export const SET_CAMPAIGN_DETAILS = "SET_CAMPAIGN_DETAILS";
export const SET_CAMPAIGN_DETAILS_PENDING = "SET_CAMPAIGN_DETAILS_PENDING";
export const CAMPAIGN_DETAILS_ERROR = "CAMPAIGN_DETAILS_ERROR";
export const CLEAR_CAMPAIGN_DETAILS_ERROR = "CLEAR_CAMPAIGN_DETAILS_ERROR";
export const SET_CONTACT_COUNT = "SET_CONTACT_COUNT";
export const SET_HTML_FILE = "SET_HTML_FILE";
export const HTML_FILE_ERROR = "HTML_FILE_ERROR";
export const CLEAR_HTML_FILE_ERROR = "CLEAR_HTML_FILE_ERROR";

//---------------------------------------------MM-CAMPAIGN-CONTACT-LIST----------------------------------------------------------------------------------
export const SET_CAMPAIGN_CONTACT_LIST = "SET_CAMPAIGN_CONTACT_LIST";
export const SET_EXPORTED_CAMPAIGN_CONTACT_LIST = "SET_EXPORTED_CAMPAIGN_CONTACT_LIST";
export const SET_CAMPAIGN_CONTACT_LIST_PENDING = "SET_CAMPAIGN_CONTACT_LIST_PENDING";
export const CAMPAIGN_CONTACT_LIST_ERROR = "CAMPAIGN_CONTACT_LIST_ERROR";
export const CLEAR_CAMPAIGN_CONTACT_LIST_ERROR = "CLEAR_CAMPAIGN_CONTACT_LIST_ERROR";
export const SET_EXPORT_CAMPAIGN_CONTACT_LIST = "SET_EXPORT_CAMPAIGN_CONTACT_LIST";
