import React from 'react'
import OverAllDocsList from '../../OverAllDocs/OverAllDocsList'
import { connect } from 'react-redux'

const TimesheetDocs = (props) => {
    // const AppID = '3001241806275313408'
  const { userAppDetails } = props.profile;
  console.log("userAppDetails:", userAppDetails);
  const App = userAppDetails.filter((i) => {
    return (i.appName === "Timesheets") ? i.appId : ""
  })

  return (
    <OverAllDocsList data={App[0] && App[0].appId} />
  )
}
const mapStateToProps = (state) => ({
  profile: state.login.currentProfile,
})
export default connect(mapStateToProps, null)(TimesheetDocs)