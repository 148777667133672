import { Gantt, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { useEffect, useState } from 'react';
import {
    Card,
    CardTitle,
    Col,
    Input,
    Row
} from 'reactstrap';
import ViewSwitcher from './ViewSwitcher';
import VWModel from '../../../../components/Modal/VWModal';
import { connect } from 'react-redux';
import UpdateTask from '../Task/UpdateTask';
import {
    CloseEditTaskModalAction,
    getProjectNameAsyncSelect,
    getTaskDetails,
    openEditTaskModalAction
} from '../../../../store/Modules/ProjectPortal/Task/actions';
import { CustomTooltip } from './toolTipContentDesign';
import Async from 'react-select/async';
import {
    clearGanttList,
    GanttSelectedProject,
    getProjectGanttListAction,
    updateGanttMilestoneDate
} from '../../../../store/Modules/ProjectPortal/GanttChart/actions';
import moment from 'moment';
import Swal from 'sweetalert2';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import NoRecord from '../../../../components/NoRecords/noRecords';

const TestGanttChart = (props) => {
    const [view, setView] = useState(ViewMode.Day);
    const [tasks, setTasks] = useState([]);
    const [isChecked, setIsChecked] = useState(true);
    const [existingProject, setExistingProject] = useState();
    const [taskId, setTaskId] = useState();
    const [taskList, setTaskList] = useState();

    let columnWidth = 65;
    if (view === ViewMode.Year) {
        columnWidth = 350;
    } else if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    }

    const getGanttChartTaskList = (id) => {
        props.getProjectGanttListAction(id)
            .then((GanttList) => {
                if (GanttList) {
                    const list = GanttList['value'];
                    setTaskList(list[0])
                }
            })
    }

    useEffect(() => {
        return (() => {
            props.clearGanttList();
        })
    }, [])

    useEffect(() => {
        if (props.GanttProjects) {
            if (!existingProject) {
                setExistingProject(props.GanttProjects[0]);
                if (props.GanttProjects[0] && props.GanttProjects[0].value) {
                    getGanttChartTaskList(props.GanttProjects[0] && props.GanttProjects[0].value);
                }
            }
            props.GanttSelectedProject(props.GanttProjects[0]);
        }
        if (props.updateGanttListsuccess) {
            if (existingProject) {
                if (existingProject.value) {
                    getGanttChartTaskList(existingProject.value);
                }
            }
        }

    }, [props.GanttProjects, props.updateGanttListsuccess])

    useEffect(() => {
        const projectList = taskList;
        const { milestoneslist } = projectList || {};
        const mtArray = [];

        milestoneslist && projectList.milestoneslist.map((i) => {
            mtArray.push(i);
            i.taskslist.map((j) => mtArray.push(j));
        });

        const combinedTaskLists = [{ ...projectList }];
        mtArray && mtArray.map((i) => combinedTaskLists.push(i));

        const formatedTaskLists = combinedTaskLists && combinedTaskLists.map((task) => ({
            ...task,
            id: task.id ? task.id : task.taskId ? task.taskId : task.milestoneId,
            name: task.type === 'project' ? `${task.name}-[Project]` : task.IsMilestone ? `${task.name}-[Milestone]` : task.name,
            start: task.start ? new Date(task.start) : new Date(),
            end: task.end ? new Date(task.end) : new Date(),
            isDisabled: task.IsMilestone || !task.isEditable,
            styles: {
                backgroundColor: task.type === 'task' ? !task.IsMilestone ? '#125B9A' : '#0D7C66' : '#FF4E88',
                progressColor: task.type === 'task' ? !task.IsMilestone ? '#5B99C2' : '#41B3A2' : '#FF8C9E',
                backgroundSelectedColor: task.type === 'task' ? !task.IsMilestone ? '#125B9A' : '#0D7C66' : '#FF4E88',
                progressSelectedColor: task.type === 'task' ? !task.IsMilestone ? '#5B99C2' : '#41B3A2' : '#FF8C9E',
            }
        }))

        formatedTaskLists && formatedTaskLists.forEach(item => {
            delete item.milestoneslist;
            delete item.taskslist;
        });

        setTasks(formatedTaskLists)
    }, [taskList])

    const handleDateChange = (task) => {
        const { type, id, start, end, project } = task || {};
        const formatedTask = {
            id: id,
            type: type,
            startDate: moment(start).format('YYYY-MM-DD'),
            endDate: moment(end).format('YYYY-MM-DD'),
        }

        props.updateGanttMilestoneDate(formatedTask, project)
            .catch((error) => {
                console.log('error: ', error);
                Swal.fire({
                    title: "Due Date Changes",
                    text: error,
                    icon: "error"
                });
            })
        getGanttChartTaskList(project);
    };
    const handleExpanderClick = (task) => {
        const updatedTasks = tasks.map((t) => t.id === task.id ? { ...t, hideChildren: !t.hideChildren } : t);
        setTasks(updatedTasks);
    };

    const handleOpenEditTaskModel = (task) => {
        const { type, id, isEditable } = task || {};
        console.log("task: ", task)
        if (!(task && task.IsMilestone) && type === 'task' && isEditable) {
            setTaskId(id)
            props.getTaskDetails(id, true)
                .then((result) => {
                    if (result) {
                        props.openEditTaskModal()
                    }
                });
        }
    }

    const handleCloseEditTaskModal = () => {
        props.closeEditTaskModal();
    };

    //--------------------------------LOAD-OPTIONS--------------------------------------------------------------
    const handleProject = (inputValue, callback) => {
        const { getProjectNameAsyncSelectAction } = props;
        return getProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    //-------------------------------------SELECT-PROJECT----------------------------------------------------------
    const handleAddProjectName = (option) => {
        setExistingProject(option);
        getGanttChartTaskList(option.value);
        props.GanttSelectedProject(option);
    }

    console.log("tasks: ", tasks);
    console.log("taskId: ", taskId);
    return (
        <div className='page-content'>
            {/* <Card className='p-1 d-flex flex-row justify-content-between align-items-center'> */}
            <Card>
                <CardTitle
                    tag="h4"
                    className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                >
                    {/* <div className='fs-4 text-uppercase fw-bold ms-2'> */}
                    <strong className='d-flex align-items-center fs-7 text-uppercase'> Gantt Chart</strong>
                    {/* </div> */}
                    <div>
                        <ViewSwitcher
                            onViewModeChange={(viewMode) => setView(viewMode)}
                            onViewListChange={setIsChecked}
                            isChecked={isChecked}
                            dateViewFormat={view}
                        />
                    </div>
                </CardTitle>
            </Card>
            <Card className='p-2'>
                <Row className='py-2 d-flex justify-content-between align-items-center'>
                    <Col md={8} className='d-flex gap-3'>
                        <div className='d-flex gap-2 fw-bold'>
                            <div class="project-legend"></div>
                            Project
                        </div>
                        <div className='d-flex gap-2 fw-bold'>
                            <div class="milestone-legend"></div>
                            Milestone
                        </div>
                        <div className='d-flex gap-2 fw-bold'>
                            <div class="task-legend"></div>
                            Task
                        </div>
                    </Col>
                    <Col md={4}>
                        <Async
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                })
                            }}
                            name="ProjectId"
                            placeholder="Select Project"
                            isSearchable
                            defaultOptions
                            loadOptions={handleProject}
                            onChange={handleAddProjectName}
                            value={existingProject}
                        />
                    </Col>
                </Row>
                {/* {props.isGanttListPending ?
                    <div className='d-flex justify-content-center'>
                        <VWSpinner />
                    </div>
                    :  */}
                {((tasks && tasks.length) > 0) ?
                    <Gantt
                        tasks={tasks}
                        viewMode={view}
                        onExpanderClick={handleExpanderClick}
                        onDateChange={handleDateChange}
                        onDoubleClick={handleOpenEditTaskModel}
                        todayColor="#77E4C8"
                        TooltipContent={CustomTooltip}
                        columnWidth={columnWidth}
                        listCellWidth={isChecked ? "200px" : ""}
                        ganttHeight={440}
                        viewDate={new Date()}
                    />
                    : <NoRecord />
                }
            </Card>
            <VWModel
                header="EDIT TASK"
                modalSize="large"
                isOpen={props.isEditTaskModalOpen}
                handleClose={handleCloseEditTaskModal}
            >
                <UpdateTask
                    handleClose={handleCloseEditTaskModal}
                    taskId={taskId}
                    isGanttChartUpdate={true}
                />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    GanttProjectList: state.GanttChartList.GanttProjectList,
    isGanttListPending: state.GanttChartList.isGanttListPending,
    GanttProjects: state.task.GanttProjectsList,
    errorMessage: state.GanttChartList.isGanttMilestoneUpdateError,
    updateGanttListsuccess: state.GanttChartList.updateGanttListsuccess,
    isEditTaskModalOpen: state.task.isEditTaskModalOpen,
});
const mapDispatchToProps = (dispatch) => ({
    clearGanttList: () => dispatch(clearGanttList()),
    closeEditTaskModal: () => dispatch(CloseEditTaskModalAction()),
    openEditTaskModal: () => dispatch(openEditTaskModalAction()),
    getProjectNameAsyncSelectAction: (searchValue, callback) => dispatch(getProjectNameAsyncSelect(searchValue, callback)),
    GanttSelectedProject: (project) => dispatch(GanttSelectedProject(project)),
    getProjectGanttListAction: (projId) => dispatch(getProjectGanttListAction(projId)),
    updateGanttMilestoneDate: (taskData, projectId) => dispatch(updateGanttMilestoneDate(taskData, projectId)),
    getTaskDetails: (Id, isGanttChart) => dispatch(getTaskDetails(Id, isGanttChart)),

});

export default connect(mapStateToProps, mapDispatchToProps)(TestGanttChart);