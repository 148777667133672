import { getToken, modifyUsersList } from "../../helper/common";
import { setIsLoading } from "../actions";
import agent from "../services/agent";
import { showSnackbarStatus } from "../snackbar/actions";
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_CHANGE_PASSWORD_ERROR,
  CLEAR_TRANSFER_DEVICE_ERROR,
  CLEAR_TRANSFER_USER_ERROR,
  CLEAR_UPDATE_USER_ERROR,
  CLOSE_CHANGE_PASSWORD_MODAL,
  CLOSE_EDIT_USER_MODAL,
  CLOSE_TRANSFER_DEVICE_MODAL,
  GET_USER_DETAILS_PENDING,
  GET_USER_DETAILS_SUCCESS,
  OPEN_CHANGE_PASSWORD_MODAL,
  OPEN_EDIT_USER_MODAL,
  OPEN_TRANSFER_DEVICE_MODAL,
  SEND_RESET_PASSWORD_ERROR,
  SEND_RESET_PASSWORD_PENDING,
  SEND_RESET_PASSWORD_SUCCESS,
  SET_ASSIGNABLE_TRANSFER_USERS_LIST_ASYNC,
  SET_SUBORDINATES_USERS_LIST_ASYNC,
  SET_USER_DETAILS,
  TRANSFER_DEVICE_ERROR,
  TRANSFER_DEVICE_PENDING,
  TRANSFER_DEVICE_SUCCESS,
  TRANSFER_USER_ERROR,
  TRANSFER_USER_PENDING,
  TRANSFER_USER_SUCCESS,
  TRASFER_USERS_FORM,
  UPDATE_USER_ERROR,
  UPDATE_USER_PENDING,
  UPDATE_USER_SUCCESS,
} from "../users/actionTypes";
import { SET_LOADING } from "./actionTypes";

export const getUserDetailsPending = (): Action => ({
  type: GET_USER_DETAILS_PENDING,
});

export const setUserDetails = (user): Action => ({
  type: SET_USER_DETAILS,
  payload: { user },
});

export const transferUsersForm = (transferForm): Action => ({
  type: TRASFER_USERS_FORM,
  payload: { transferForm },
});

export const getUserDetailsSuccess = (): Action => ({
  type: GET_USER_DETAILS_SUCCESS,
});

export const sendResetPasswordPending = (): Action => ({
  type: SEND_RESET_PASSWORD_PENDING,
});

export const sendResetPasswordSuccess = (): Action => ({
  type: SEND_RESET_PASSWORD_SUCCESS,
});

export const sendResetPasswordError = (): Action => ({
  type: SEND_RESET_PASSWORD_ERROR,
});

export const closeChangePasswordModal = (): Action => ({
  type: CLOSE_CHANGE_PASSWORD_MODAL,
});

export const openChangePasswordModal = (): Action => ({
  type: OPEN_CHANGE_PASSWORD_MODAL,
});

export const changePasswordPending = (): Action => ({
  type: CHANGE_PASSWORD_PENDING,
});

export const changePasswordSuccess = (): Action => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordError = (errorMessage): Action => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: { errorMessage },
});


export const clearChangePasswordError = (): Action => ({
  type: CLEAR_CHANGE_PASSWORD_ERROR,
});

export const closeEditUserModal = (): Action => ({
  type: CLOSE_EDIT_USER_MODAL,
});

export const openEditUserModal = (): Action => ({
  type: OPEN_EDIT_USER_MODAL,
});

export const updateUserPending = (): Action => ({
  type: UPDATE_USER_PENDING,
});

export const updateUserSuccess = (): Action => ({
  type: UPDATE_USER_SUCCESS,
});

export const updateUserError = (errorMessage): Action => ({
  type: UPDATE_USER_ERROR,
  payload: { errorMessage },
});

export const clearUpdateUserError = (): Action => ({
  type: CLEAR_UPDATE_USER_ERROR,
});

export const transferDevicePending = (): Action => ({
  type: TRANSFER_DEVICE_PENDING,
});

export const transferDeviceSuccess = (): Action => ({
  type: TRANSFER_DEVICE_SUCCESS,
});

export const transferDeviceError = (errorMessage): Action => ({
  type: TRANSFER_DEVICE_ERROR,
  payload: { errorMessage },
});

export const clearTransferDeviceError = (): Action => ({
  type: CLEAR_TRANSFER_DEVICE_ERROR,
});

export const transferUserPending = (): Action => ({
  type: TRANSFER_USER_PENDING,
});

export const transferUserSuccess = (): Action => ({
  type: TRANSFER_USER_SUCCESS,
});

export const transferUserError = (errorMessage): Action => ({
  type: TRANSFER_USER_ERROR,
  payload: { errorMessage },
});

export const clearTransferUserError = (): Action => ({
  type: CLEAR_TRANSFER_USER_ERROR,
});

export const closeTransferDeviceModal = (): Action => ({
  type: CLOSE_TRANSFER_DEVICE_MODAL,
});

export const openTransferDeviceModal = (): Action => ({
  type: OPEN_TRANSFER_DEVICE_MODAL,
});

const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: { loading },
});

const setAssignableTranferUsersForAsyncSelect = (
  usersList: any[],
  searchValue?: string
): Action => ({
  type: SET_ASSIGNABLE_TRANSFER_USERS_LIST_ASYNC,
  payload: { usersListAsync: usersList, searchValueAsync: searchValue },
});

const setSubordinatesUsersAsyncSelect = (
  usersList: any[],
  searchValue?: string
): Action => ({
  type: SET_SUBORDINATES_USERS_LIST_ASYNC,
  payload: { usersListAsync: usersList, searchValueAsync: searchValue },
});

export const getUserDetails = (userId: string) => (dispatch: Function) => {
  dispatch(getUserDetailsPending());
  dispatch(setLoading(true))
  getToken(dispatch).then((accessToken) => {
    agent.User.getUserDetails(accessToken, userId)
      .then((userDetails) => {
        dispatch(setUserDetails(userDetails));
        dispatch(getUserDetailsSuccess());
        dispatch(setLoading(false))
      })
      .catch((err) => {
        console.log("setUserDetails server error", err);
      });
  });
};

export const sendResetPasswordEmail =
  (email: string) => (dispatch: Function) => {
    dispatch(sendResetPasswordPending());
    agent.User.sendResetPasswordEmail(email)

      .then(() => {
        dispatch(sendResetPasswordSuccess());
        dispatch(showSnackbarStatus("Password reset link sent successfully."));
      })
      .catch((err) => {
        dispatch(sendResetPasswordError());
        console.log("setUserDetails server error", err);
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  };

export const changePassword =
  () => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { users } = currentState;
    const { passwordForm } = users;

    if (!passwordForm) {
      dispatch(changePasswordError("Please fill required details."));
      return;
    }

    const changePasswordData = {
      oldPassword: passwordForm.currentPassword,
      newPassword: passwordForm.newPassword,
      confirmNewPassword: passwordForm.confirmNewPassword,
    };

    dispatch(clearChangePasswordError());
    dispatch(changePasswordPending());
    getToken(dispatch).then((accessToken) => {
      agent.User.changePassword(accessToken, changePasswordData)
        .then(() => {
          dispatch(changePasswordSuccess());
          //   dispatch(clearForm());
          dispatch(closeChangePasswordModal());
          dispatch(showSnackbarStatus("password changed succussfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            const { message } = err.response.body;
            dispatch(changePasswordError(message));
            return;
          }
          console.log("changePassword server error", err);
          //   dispatch(clearForm());
          dispatch(changePasswordError(null));
          dispatch(closeChangePasswordModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

export const updateUser = (user: any) => (dispatch: Function) => {
  user = {
    ...user,
    zone: user.selected_zone && user.selected_zone.value,
    state: user.selected_state && user.selected_state.value,
  };

  dispatch(clearUpdateUserError());
  dispatch(updateUserPending());
  dispatch(setLoading(true))
  getToken(dispatch).then((accessToken) => {
    agent.User.updateUser(accessToken, user)
      .then((userId) => {
        dispatch(updateUserSuccess());
        dispatch(closeEditUserModal());
        dispatch(getUserDetails(userId));
        dispatch(showSnackbarStatus("User details updated succussfully"));
        dispatch(setLoading(false))
      })
      .catch((err) => {
        if (err && err.response) {
          const { message } = err.response.body;
          dispatch(updateUserError(message));
          return;
        }
        console.log("Update user server error", err);
        dispatch(updateUserError(null));
        dispatch(closeEditUserModal());
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  });
};

export const transferDevices =
  (userId: string) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formTransferDevices } = currentState && currentState.form;

    if (!formTransferDevices) {
      dispatch(transferDeviceError("Please fill required details."));
      return;
    }

    const transferDeviceData = {
      currentUserId: userId,
      targetUserId:
        formTransferDevices.selected_user &&
        formTransferDevices.selected_user.value,
      type: formTransferDevices.selection,
      deviceIds:
        formTransferDevices.selected_devices &&
        formTransferDevices.selected_devices.map(function (item) {
          return item["value"];
        }),
    };

    // console.log(transferDeviceData);

    dispatch(clearTransferDeviceError());
    dispatch(transferDevicePending());
    getToken(dispatch).then((accessToken) => {
      agent.Device.transferDevices(accessToken, transferDeviceData)
        .then(() => {
          dispatch(transferDeviceSuccess());
          //   dispatch(clearForm());
          dispatch(closeTransferDeviceModal());
          dispatch(showSnackbarStatus("Devices Transferred succussfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            const { message } = err.response.body;
            dispatch(transferDeviceError(message));
            return;
          }
          console.log("changePassword server error", err);
          //   dispatch(clearForm());
          dispatch(transferDeviceError(null));
          dispatch(closeTransferDeviceModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

export const transferUsers =
  (userId: string) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { userdetails } = currentState;
    const { transferForm } = userdetails;

    if (!transferForm) {
      dispatch(transferUserError("Please fill required details."));
      return;
    }

    const transferUserData = {
      currentUserId: userId,
      targetUserId:
        transferForm.selected_user &&
        transferForm.selected_user.value,
      type: transferForm.selection,
      userIds:
        transferForm.selected_users &&
        transferForm.selected_users.map(function (item) {
          return item["value"];
        }),
    };

    // console.log(transferUserData);

    dispatch(clearTransferUserError());
    dispatch(transferUserPending());
    getToken(dispatch).then((accessToken) => {
      agent.User.transferUsers(accessToken, transferUserData)
        .then(() => {
          dispatch(transferUserSuccess());
          //   dispatch(clearForm());
          dispatch(closeTransferDeviceModal());
          dispatch(showSnackbarStatus("Users Transferred succussfully"));
        })
        .catch((err) => {
          if (err && err.response) {
            const { message } = err.response.body;
            dispatch(transferUserError(message));
            return;
          }
          console.log("changePassword server error", err);
          //   dispatch(clearForm());
          dispatch(transferUserError(null));
          dispatch(closeTransferDeviceModal());
          dispatch(
            showSnackbarStatus("Something went wrong. Please try again later")
          );
        });
    });
  };

export const getAssignableTranferUsersAsyncSelect =
  (
    userId: string,
    organisationId?: string,
    searchValue?: string,
    callback?: Function
  ) =>
    (dispatch: Function) => {
      getToken(dispatch).then((accessToken) => {
        agent.User.getAssignableTransferUser(accessToken, userId, organisationId)
          .then((userList) => {
            let userListOptions = modifyUsersList(userList);
            dispatch(setAssignableTranferUsersForAsyncSelect(userListOptions));
            callback && callback(userListOptions);
          })
          .catch((err) => {
            console.log(
              "getAssignableTranferUsers for dropdown server error",
              err
            );
            dispatch(
              showSnackbarStatus("Something went wrong. Please try again later")
            );
            callback && callback();
          });
      });
    };

export const getSubordinatesUsersAsyncSelect =
  (
    userId: string,
    organisationId?: string,
    searchValue?: string,
    callback?: Function
  ) =>
    (dispatch: Function) => {
      getToken(dispatch).then((accessToken) => {
        agent.User.getSubordinates(accessToken, userId, organisationId)
          .then((userList) => {
            let userListOptions = modifyUsersList(userList);
            dispatch(setSubordinatesUsersAsyncSelect(userListOptions));
            callback && callback(userListOptions);
          })
          .catch((err) => {
            console.log(
              "getSubordinatesUsersAsyncSelect for dropdown server error",
              err
            );
            dispatch(
              showSnackbarStatus("Something went wrong. Please try again later")
            );
            callback && callback();
          });
      });
    };
