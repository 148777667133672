import { Table } from "reactstrap";
import moment from 'moment';

export const CustomTooltip = ({ task }) => {

    return (
        <div style={{ padding: '0px', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
            <h4
                className="text-white py-1 px-2 rounded-2"
                style={{
                    backgroundColor: task.type === 'task' ? !task.IsMilestone ? '#125B9A' : '#0D7C66' : '#FF4E88',
                }}
            >
                {task.name}
            </h4>
            <Table className='m-2' borderless>
                {((task.type === 'task') && !(task.IsMilestone)) &&
                    <tbody>
                        <tr>
                            <th className='p-0'>Start Date:</th>
                            <td className='p-0'> {task.start ? moment(task.start).format('DD MMM YYYY') : "N/A"}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>End Date:</th>
                            <td className='p-0'> {task.end ? moment(task.end).format('DD MMM YYYY') : "N/A"}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>Milestone: </th>
                            <td className='p-0'> {task.milestoneName}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>Assigned To: </th>
                            <td className='p-0 text-capitalize'>{task.assignedUserName}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>Project:</th>
                            <td className='p-0'>{task.projectName}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>Status:</th>
                            <td className='p-0'>{task.status}</td>
                        </tr>
                    </tbody>
                }
                {((task.type === 'task') && (task.IsMilestone)) &&
                    <tbody>
                        <tr>
                            <th className='p-0'>Start Date:</th>
                            <td className='p-0'> {task.start ? moment(task.start).format('DD MMM YYYY') : "N/A"}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>End Date:</th>
                            <td className='p-0'> {task.end ? moment(task.end).format('DD MMM YYYY') : "N/A"}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>Project:</th>
                            <td className='p-0'>{task.projectName}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>Status: </th>
                            <td className='p-0'> {task.status}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>Milestone <br />Owner: </th>
                            <td className='p-0 pt-2 text-capitalize'>{task.milestoneOwnerName}</td>
                        </tr>
                    </tbody>
                }
                {(task.type === 'project') &&
                    <tbody>
                        <tr>
                            <th className='p-0'>Start Date:</th>
                            <td className='p-0'> {task.start ? moment(task.start).format('DD MMM YYYY') : "N/A"}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>End Date:</th>
                            <td className='p-0'> {task.end ? moment(task.end).format('DD MMM YYYY') : "N/A"}</td>
                        </tr>
                        <tr>
                            <th className='p-0'>Project<br /> Manager: </th>
                            <td className='p-0 pt-2 text-capitalize'>{task.projectManagerName}</td>
                        </tr>
                    </tbody>
                }

            </Table>
        </div >
    );
};
