import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    Table
} from "reactstrap";
import {
    closeAccountUpdate,
    getAccountDetails,
    openAccountUpdate
} from "../../../../store/Modules/crm/Accounts/actions";
import VWModel from "../../../../components/Modal/VWModal";
import UpdateContacts from "./edit";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import { closeContactsUpdate, getContactsDetails, openContactsUpdate } from "../../../../store/Modules/crm/Contacts/actions";
import moment from "moment-timezone";

const CrmContacttsDetail = (props) => {
    const params = useParams();
    const { id } = params || {};

    const { contactsDetails, contactsDetailErrorMessage, isContactsDetailPending } = props || {};
    const { firstName, orgName, lastName, email, displayName, companyName, street, city, contactEmail, companyEmail,
        state, code, country, phoneNo, title, landLineNo, designation, department, createdBy, createdTimeStamp } = contactsDetails || {};

    useEffect(() => {
        props.getContactsDetails(id);
    }, [])

    const updateContactsOpen = () => {
        props.openContactsUpdate();
    }

    const handleClose = () => {
        props.closeContactsUpdate();
    }

    return (
        <div className="page-content">
            <Card>
                <CardTitle className="p-2 mb-0 fw-bold fs-4 text-dark d-flex justify-content-between"> CONTACT DETAILS
                    <Button color="primary" onClick={updateContactsOpen}>
                        EDIT
                    </Button>
                </CardTitle>
            </Card>
            <Card>
                {contactsDetailErrorMessage &&
                    <VWMessage type="danger" defaultMessage={contactsDetailErrorMessage} baseClassName="alert" ></VWMessage>
                }
                {isContactsDetailPending ?
                    <div className="d-flex justify-content-center mt-2">
                        <VWSpinner />
                    </div>
                    :
                    <CardBody>
                        <Row>
                            <Col lg="6" md="12" sm="12">
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span> Contact Information</div>
                                <Table responsive>
                                    <tbody>
                                        <tr>
                                            <th>Title</th>
                                            <td>{title || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>First Name</th>
                                            <td>{firstName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Last Name</th>
                                            <td>{lastName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Display Name</th>
                                            <td>{displayName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Email Address</th>
                                            <td>{contactEmail || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Account Name</th>
                                            <td>{companyName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Account Email</th>
                                            <td>{companyEmail || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Organization Name</th>
                                            <td>{orgName || "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg="6" md="12" sm="12">
                                {/* <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"> <span class="mdi mdi-map-marker-radius fs-3 text-danger"></span> Address Information</div> */}
                                <br></br>
                                <br></br>
                                <Table responsive>
                                    <tbody>

                                        <tr>
                                            <th>Designation</th>
                                            <td>{designation || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Department</th>
                                            <td>{department || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>City</th>
                                            <td>{city || "N/A"}</td>
                                        </tr>

                                        <tr>
                                            <th>Mobile Number</th>
                                            <td>{phoneNo || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Landline Number</th>
                                            <td>{landLineNo || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Created By</th>
                                            <td>{createdBy || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Created Date</th>
                                            <td>{createdTimeStamp ? moment(createdTimeStamp).format('DD MMM YYYY') : 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                }
            </Card>
            <VWModel
                header="EDIT CONTACT"
                modalSize="extra-large"
                isOpen={props.isUpdateContactsOpen}
                handleClose={handleClose}
            >
                <UpdateContacts handleClose={handleClose} contactsDetails={contactsDetails} />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    contactsDetails: state.crmContacts.contactsDetails,
    isUpdateContactsOpen: state.crmContacts.isUpdateContactsOpen,
    contactsDetailErrorMessage: state.crmContacts.contactsDetailErrorMessage,
    isContactsDetailPending: state.crmContacts.isContactsDetailPending,
})

const mapDispatchToProps = (dispatch) => ({
    getContactsDetails: (id) => dispatch(getContactsDetails(id)),
    openContactsUpdate: () => dispatch(openContactsUpdate()),
    closeContactsUpdate: () => dispatch(closeContactsUpdate()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CrmContacttsDetail);