import { faCircleInfo, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Doughnut } from "react-chartjs-2";
import { Card } from 'reactstrap';

const TakenDonutChart = ({ datas, filter }) => {
    console.log('datas:', datas);
    const { totalLOPLeaveCount, totalPaidLeaveCount } = datas || {};
    const isBothZero = totalPaidLeaveCount === 0 && totalLOPLeaveCount === 0;

    const data = isBothZero
        ? {
            labels: ["Pending"],
            datasets: [
                {
                    data: [100],
                    backgroundColor: ['#c4c4c4'], // grey color
                    borderColor: ['#c4c4c4'], // grey border
                },
            ],
        }
        : {
            labels: ["LOP", "Paid"],
            datasets: [
                {
                    data: [totalLOPLeaveCount, totalPaidLeaveCount],
                    backgroundColor: ['#ff0000', '#11c46e'],
                    borderColor: ['#ff0000', '#11c46e'],
                },
            ],
        };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        cutout: '70%',
        animation: {
            animateScale: true,
            animateRotate: true,
        },
    };
    return (
        <React.Fragment>
            {isBothZero ?
                <Card className='d-flex align-item-center p-4'>
                    <div
                    >
                        {
                            filter ?
                                <>
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ color: 'red' }} /> <span style={{ color: 'red' }}>You didn't take any leave for this particular category.</span>
                                </>
                                :
                                <>
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ color: 'red' }} /> <span style={{ color: 'red' }}>You didn't take any leave across any category.</span>
                                </>
                        }
                    </div>
                </Card>
                :
                <Doughnut width={537} height={268} data={data} options={options} className="chartjs-chart" />}
        </React.Fragment>
    )
}

export default TakenDonutChart