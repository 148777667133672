import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import {
    DASHBOARD_CLEAR_ERROR,
    DASHBOARD_ERROR_MESSAGE,
    SET_DASHBOARD_MASS_MAILER,
    SET_DASHBOARD_PENDING
} from "./constants";

export const setDashboard = (dashboard) => ({
    type: SET_DASHBOARD_MASS_MAILER,
    payload: { dashboard },
});

export const setDashboardPending = () => ({
    type: SET_DASHBOARD_PENDING,
})

export const dashboardErrorMessage = (dashboardErrorMessage) => ({
    type: DASHBOARD_ERROR_MESSAGE,
    payload: { dashboardErrorMessage },
});

export const dashboardClearError = () => ({
    type: DASHBOARD_CLEAR_ERROR,
})

export const dashboardCounts = () => (dispatch) => {
    dispatch(setDashboardPending());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getMMDashboardCounts(accessToken)
            .then((dashboardCounts) => {
                dispatch(setDashboard(dashboardCounts));
                dispatch(dashboardClearError());
            })
            .catch((err) => {
                console.log('get Dashboard server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(dashboardErrorMessage(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    })
}