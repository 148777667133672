//eslint-disable-next-line
export const SET_MY_TEAM_TIMESHEET_LIST = "SET_MY_TEAM_TIMESHEET_LIST";
export const OPEN_ACTION_TIMESHEET_MODAL = "OPEN_ACTION_TIMESHEET_MODAL";
export const CLOSE_ACTION_TIMESHEET_MODAL = "CLOSE_ACTION_TIMESHEET_MODAL";
export const CLEAR_TIMESHEET_ACTION_ERROR = "CLEAR_TIMESHEET_ACTION_ERROR";
export const CREATE_TIMESHEET_PENDING = "CREATE_TIMESHEET_PENDING";
export const CLEAR_TIMESHEET_ERROR = "CLEAR_TIMESHEET_ERROR";
export const CREATE_MY_TEAM_TIMESHEET_SUCCESS = "CREATE_MY_TEAM_TIMESHEET_SUCCESS";
export const UPDATE_MY_TEAM_TIMESHEET_ERROR = "UPDATE_MY_TEAM_TIMESHEET_ERROR";
export const MY_TEAM_TIMESHEET_LIST_ERROR = "MY_TEAM_TIMESHEET_LIST_ERROR"
export const MY_TEAM_TIMESHEET_LIST_PENDING = "MY_TEAM_TIMESHEET_LIST_PENDING"
export const CLEAR_MY_TEAM_TIMESHEET_LIST_ERROR = "CLEAR_MY_TEAM_TIMESHEET_LIST_ERROR"
export const MY_TEAM_TIMESHEET_EXPORT_LIST_PENDING = "MY_TEAM_TIMESHEET_EXPORT_LIST_PENDING"
export const SET_MY_TEAM_TIMESHEET_EXPORT_LIST = "SET_MY_TEAM_TIMESHEET_EXPORT_LIST"
export const CLEAR_MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR = "CLEAR_MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR"
export const MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR = "MY_TEAM_TIMESHEET_EXPORT_LIST_ERROR"
export const CLEAR_TIMESHEET_PENDING = "CLEAR_TIMESHEET_PENDING"
export const MY_TEAM_TIMESHEET_LIST = "MY_TEAM_TIMESHEET_LIST"




export const MY_TIMESHEET_EXPORT_HEADERS = [
    "USER NAME",
    "USER EMAIL",
    "PROJECT NAME",
    "TASK NAME",
    "TASK CURRENT STATUS",
    "SCOPE",
    "WORK DATE",
    "WORK HOURS",
    "CREATED DATE",
    "UPDATED DATE",
    "REASON",
    "STATUS",
];