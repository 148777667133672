import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearUpdateRegionError,
    updateRegion,
    updateRegionSuccess
} from '../../../../../../store/Modules/crm/Settings/Region/actions';

const EditRegion = (props) => {
    const { regionDetails, isRegionUpdatePending, errorMessage } = props;
    const { Id, RegionName, IsActive } = regionDetails || {};

    const [regionDetail, setRegionDetail] = useState();
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();

    useEffect(() => {
        setRegionDetail({
            ...regionDetail,
            id: Id,
            regionName: RegionName,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateRegionError();
        props.updateRegionSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setRegionDetail({
            ...regionDetail,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        })
    }
    const handleActiveStatusChange = () => {
        setRegionDetail({
            ...regionDetail,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { regionName } = regionDetail || {};

        if (!regionName) {
            currentErrors.regionName = 'Region name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateRegion(regionDetail)

        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label>Region Name  <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="regionName"
                        placeHolder="Enter region name"
                        onChange={validateOnchange}
                        value={regionDetail && regionDetail.regionName}
                        invalid={errors && errors.regionName}
                    />
                    <FormFeedback>{errors && errors.regionName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isRegionUpdatePending}
                    >
                        {isRegionUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    regionDetails: state.crmRegion.regionDetails,
    isRegionUpdatePending: state.crmRegion.isRegionUpdatePending,
    errorMessage: state.crmRegion.regionUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateRegion: (regionDetail) => dispatch(updateRegion(regionDetail)),
    clearUpdateRegionError: () => dispatch(clearUpdateRegionError()),
    updateRegionSuccess: () => dispatch(updateRegionSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditRegion);