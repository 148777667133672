import {
    CLEAR_LEADS_TO_ACCOUNT_PENDING,
    CLEAR_REDIRECT_TO_ACCOUNT_DETAILS,
    LEADS_CREATE_CLOSE,
    LEADS_CREATE_ERROR,
    LEADS_CREATE_ERROR_CLEAR,
    LEADS_CREATE_OPEN,
    LEADS_CREATE_PENDING,
    LEADS_CREATE_SUCCESS,
    LEADS_DETAILS_ERROR,
    LEADS_DETAILS_ERROR_CLEAR,
    LEADS_DETAILS_PENDING,
    LEADS_EXPORT_LIST_ERROR,
    LEADS_EXPORT_LIST_PENDING,
    LEADS_EXPORT_LIST_SUCCESS,
    LEADS_IMPORT_PENDING,
    LEADS_IMPORT_SUCCESS,
    LEADS_LIST_ERROR,
    LEADS_LIST_ERROR_CLEAR,
    LEADS_LIST_EXPORT_ERROR_CLEAR,
    LEADS_LIST_PENDING,
    LEADS_TO_ACCOUNT_PENDING,
    LEADS_UPDATE_CLOSE,
    LEADS_UPDATE_ERROR,
    LEADS_UPDATE_ERROR_CLEAR,
    LEADS_UPDATE_OPEN,
    LEADS_UPDATE_PENDING,
    LEADS_UPDATE_SUCCESS,
    REDIRECT_TO_ACCOUNT_DETAILS,
    SET_ACCOUNT_ID,
    SET_LEADS_DETAILS,
    SET_LEADS_EXPORT_LIST,
    SET_LEADS_LIST
} from "./constants";

const initialState = {
    //--------------------CONTACT-CREATE-----------------------------------
    isCreateLeadsOpen: false,
    leadsCreateErrorMessage: null,
    isLeadsCreateSuccess: false,
    isLeadsCreatePending: false,
    //--------------------CONTACT-UPDATE-----------------------------------
    isUpdateLeadsOpen: false,
    leadsUpdateErrorMessage: null,
    isLeadsUpdateSuccess: false,
    isLeadsUpdatePending: false,
    //--------------------CONTACT-LIST-------------------------------------
    leadsList: [],
    leadsListCount: 0,
    isLeadsListPending: false,
    LeadsListErrorMessage: null,
    //--------------------CONTACT-DETAILS----------------------------------
    leadsDetails: [],
    isLeadsDetailPending: false,
    leadsDetailErrorMessage: null,
    //--------------------LEADS-TO-ACCOUNT-CONVERSION--------------------
    isLeadToAccountPending: false,
    isRedirectToAccountDetails: false,
    accountId: null,
    isLeadsExportListPending:false,
    leadsListExportErrorMessage: null,
    leadsListExport:[],
    leadsListExportCount:0,
    isLeadsImportPending:false
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------LEADS-CREATE-----------------------------------
        case LEADS_CREATE_OPEN: {
            return {
                ...localState,
                isCreateLeadsOpen: true
            }
        }
        case LEADS_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateLeadsOpen: false
            }
        }
        case LEADS_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                leadsCreateErrorMessage: null,
            }
        }
        case LEADS_CREATE_PENDING: {
            return {
                ...localState,
                isLeadsCreatePending: true,
            }
        }
        case LEADS_CREATE_SUCCESS: {
            return {
                ...localState,
                isLeadsCreateSuccess: true,
                isLeadsCreatePending: false
            }
        }
        case LEADS_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadsCreateErrorMessage: errorMessage,
                isLeadsCreatePending: false,
            }
        }

        //--------------------LEADS-UPDATE-----------------------------------
        case LEADS_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateLeadsOpen: true
            }
        }
        case LEADS_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateLeadsOpen: false
            }
        }
        case LEADS_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                leadsUpdateErrorMessage: null,
            }
        }
        case LEADS_UPDATE_PENDING: {
            return {
                ...localState,
                isLeadsUpdatePending: true,
            }
        }
        case LEADS_IMPORT_PENDING: {
            return {
                ...localState,
                isLeadsImportPending: true,
            }
        }
        case LEADS_IMPORT_SUCCESS: {
            return {
                ...localState,
                isLeadsImportPending: false,
            }
        }
        case LEADS_UPDATE_SUCCESS: {
            return {
                ...localState,
                isLeadsCreateSuccess: true,
                isLeadsUpdatePending: false
            }
        }
        case LEADS_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadsUpdateErrorMessage: errorMessage,
                isLeadsUpdatePending: false
            }
        }
        //--------------------LEADS-LSIT-------------------------------------
        case SET_LEADS_LIST: {
            const { leadsList } = action.payload;
            console.log("leadsList: ", leadsList);
            return {
                ...localState,
                leadsList: leadsList && leadsList['value'],
                leadsListCount: leadsList && leadsList['@odata.count'],
                isLeadsListPending: false,
                leadsListErrorMessage: null,
            }
        }
        case SET_LEADS_EXPORT_LIST: {
            const { leadsExportList } = action.payload;
            console.log("leadsExportList: ", leadsExportList);
            return {
                ...localState,
                leadsListExport: leadsExportList,
                // leadsListExportCount: leadsExportList && leadsExportList['@odata.count'],
                isLeadsExportListPending: false,
                leadsListExportErrorMessage: null,
            }
        }
        case LEADS_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadsListExportErrorMessage: errorMessage,
                isLeadsExportListPending: false,
                leadsListExport: []
            }
        }
        case LEADS_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadsListErrorMessage: errorMessage,
                isLeadsListPending: false,
                leadsList: []
            }
        }
        case LEADS_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                leadsListErrorMessage: null,
            }
        }
        case LEADS_LIST_EXPORT_ERROR_CLEAR: {
            return {
                ...localState,
                leadsListExportErrorMessage: null,
                isLeadsExportListPending: false,
            }
        }
        case LEADS_LIST_PENDING: {
            return {
                ...localState,
                isLeadsListPending: true,
            }
        }
        case LEADS_EXPORT_LIST_PENDING: {
            return {
                ...localState,
                isLeadsExportListPending: true,
            }
        }
        case LEADS_EXPORT_LIST_SUCCESS: {
            return {
                ...localState,
                isLeadsExportListPending: false,
            }
        }
        //--------------------LEADS-DETAILS----------------------------------
        case SET_LEADS_DETAILS: {
            const { leadsDetails } = action.payload;
            return {
                ...localState,
                leadsDetails: leadsDetails,
                leadsDetailErrorMessage: null,
                isLeadsDetailPending: false,
                isLeadToAccountPending: false,
            }
        }
        case LEADS_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadsDetailErrorMessage: errorMessage,
                isLeadsDetailPending: false,
                isLeadToAccountPending: false,
                leadsDetails: []
            }
        }
        case LEADS_DETAILS_ERROR_CLEAR: {
            return {
                ...localState,
                leadsDetailErrorMessage: null,
            }
        }
        case LEADS_DETAILS_PENDING: {
            return {
                ...localState,
                isLeadsDetailPending: true,
            }
        }
        //--------------------LEADS-TO-ACCOUNT-CONVERSION--------------------
        case LEADS_TO_ACCOUNT_PENDING: {
            return {
                ...localState,
                isLeadToAccountPending: true,
            }
        }
        case CLEAR_LEADS_TO_ACCOUNT_PENDING: {
            return {
                ...localState,
                isLeadToAccountPending: false,
            }
        }
        case REDIRECT_TO_ACCOUNT_DETAILS: {
            return {
                ...localState,
                isRedirectToAccountDetails: true,
            }
        }
        case CLEAR_REDIRECT_TO_ACCOUNT_DETAILS: {
            return {
                ...localState,
                isRedirectToAccountDetails: false,
            }
        }
        case SET_ACCOUNT_ID: {
            const { id } = action.payload;
            return {
                ...localState,
                accountId: id,
            }
        }
        default: {
            return localState;
        }
    }
};