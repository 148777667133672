import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
    Badge,
    Button,
    Card,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    Tooltip
} from 'reactstrap'
import moment from 'moment'
import Async from 'react-select/async';
import VWModel from '../../../../components/Modal/VWModal'
import UpdateProjects from './UpdateProjects'
import { connect } from 'react-redux'
import {
    clearProjectList,
    closeEditProjectModalAction,
    closeSortModal,
    getProjectDetails,
    getProjectPieChartLists,
    openEditProjectModalAction,
    openSortModal,
    projectDetailsError
} from '../../../../store/Modules/ProjectPortal/Projects/actions'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import {
    clearMilestoneList,
    clearStatusUpdateMilestoneError,
    closeCreateMilestoneModalAction,
    getProjectMilestoneList,
    isStatusMilestoneEditOpen,
    openCreateMilestoneModalAction,
    UpdateStatusMilestone
} from '../../../../store/Modules/ProjectPortal/Milestones/actions'
import {
    clearMilestoneTaskList,
    clearMilestoneTaskListError,
    CloseCreateTaskModalAction,
    getmilestoneTaskLists,
    getTaskMilestoneOwnerStatusAsyncSelectAction,
    getTaskStatusAsyncSelectAction,
    getTaskStatusAsyncUserSelectAction,
    getTaskUserStatusAsyncSelectAction,
    isStatusTaskEditOpen,
    openCreateTaskModalAction,
    UpdateTaskStatus
} from '../../../../store/Modules/ProjectPortal/Task/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSearch,
    faInfoCircle,
    faArrowUp,
    faArrowDown,
    faPlus,
    faChartPie,
    faTasks,
    faStream,
    faEdit,
    faClose,
    faXmarkCircle,
    faXmarkSquare
} from '@fortawesome/free-solid-svg-icons'
import PieChart from './PieChart'
import VWSpinner from '../../../../components/vwSpinner/vwSpinner'
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions'
import CreateMilestones from '../Milestones/CreateMilestones'
import CreateTask from '../Task/CreateTask'
import Select from 'react-select';
import { MILESTONE_SORTING_OPTIONS, STATUS_FULL_OPTIONS, STATUS_MILESTONE_OWNER_OPTIONS, STATUS_USER_OPTIONS } from '../../../../store/Modules/ProjectPortal/Projects/constants'
import { ElementToolTip } from '../vwToolTip'
import { STATUS_OPTIONS } from '../../../../store/Modules/ProjectPortal/Milestones/constants'



const ProjectDetails = (props) => {


    const { displayName, userEmail } = props.currentProfile;
    const { ProjectPieChartDetails } = props;
    const [task, setTasks] = useState([]);
    const [milestone, setMilestones] = useState([]);
    const [taskTotalRecord, setTasksTotalrecord] = useState(0);
    const [assignedMilestone, setAssignedMilestoneName] = useState();
    const [assignedMilestoneId, setAssignedMilestoneId] = useState();
    const [assignedMilestoneTaskCreateOption, setAssignedMilestoneTaskCreateOption] = useState();
    const [MilestoneOwnerUpdateStatus, setMilestoneOwnerUpdateStatus] = useState();
    const [UserUpdateStatus, setUserUpdateStatus] = useState();
    const [labels, setLabels] = useState([]);
    const [series, setSeries] = useState([]);
    const { projectDetails } = props;
    const [sortBy, setSortBy] = useState();
    const [mileStoneOrder, setMileStoneOrder] = useState();
    const [filterTask, setFilterTask] = useState();

    const params = useLocation();
    const { pathname } = params || {};
    const path = pathname && pathname.split('/');
    const id = path[4];

    const { userAppDetails } = props && props.currentProfile;
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })

    const customStyles = {
        container: (provided) => ({
            ...provided,
            fontWeight: 'normal'
        }),
        control: (provided) => ({
            ...provided,
            fontWeight: 'normal',
            minWidth: '250px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontWeight: 'normal'
        }),
    };

    useEffect(() => {
        props.getProjectDetails(id);
        // props.getMilestoneList(id);
        props.getProjectPieChart(id);
        props.clearMilestoneTaskListError();
        props.clearMilestoneTaskList();
        props.clearStatusUpdateMilestoneError();

        setFilterTask(null);
        setTasks([]);
    }, [id]);

    useEffect(() => {
        return () => {
            props.clearProjectDetails();
            props.clearSetLoginPath();
            props.ClearPath();
            props.clearMilestoneList();
            props.clearMilestoneTaskList();
            props.clearStatusUpdateMilestoneError();
            setFilterTask(null);
            setTasks([]);
        }
    }, [])
    useEffect(() => {
        if (props.MilestoneTaskList) {
            setTasks(props.MilestoneTaskList);
            setTasksTotalrecord(props.MilestoneTasktotalRecords);
        }
    }, [props.MilestoneTaskList])

    useEffect(() => {

        if (ProjectPieChartDetails) {
            let ProjectPiechartList = [];
            let ProjectPieChartCountList = [];

            ProjectPiechartList.push(ProjectPieChartDetails.newTask && ProjectPieChartDetails.newTask.status)
            ProjectPiechartList.push(ProjectPieChartDetails.inProgressTask && ProjectPieChartDetails.inProgressTask.status)
            ProjectPiechartList.push(ProjectPieChartDetails.onHoldTask && ProjectPieChartDetails.onHoldTask.status)
            ProjectPiechartList.push(ProjectPieChartDetails.internalTestingTask && ProjectPieChartDetails.internalTestingTask.status)
            ProjectPiechartList.push(ProjectPieChartDetails.internalTestingDoneTask && ProjectPieChartDetails.internalTestingDoneTask.status)
            ProjectPiechartList.push(ProjectPieChartDetails.waitingForApprovalTask && ProjectPieChartDetails.waitingForApprovalTask.status)
            ProjectPiechartList.push(ProjectPieChartDetails.doneTask && ProjectPieChartDetails.doneTask.status)
            ProjectPieChartCountList.push(ProjectPieChartDetails.newTask && ProjectPieChartDetails.newTask.count)
            ProjectPieChartCountList.push(ProjectPieChartDetails.inProgressTask && ProjectPieChartDetails.inProgressTask.count)
            ProjectPieChartCountList.push(ProjectPieChartDetails.onHoldTask && ProjectPieChartDetails.onHoldTask.count)
            ProjectPieChartCountList.push(ProjectPieChartDetails.internalTestingTask && ProjectPieChartDetails.internalTestingTask.count)
            ProjectPieChartCountList.push(ProjectPieChartDetails.internalTestingDoneTask && ProjectPieChartDetails.internalTestingDoneTask.count)
            ProjectPieChartCountList.push(ProjectPieChartDetails.waitingForApprovalTask && ProjectPieChartDetails.waitingForApprovalTask.count)
            ProjectPieChartCountList.push(ProjectPieChartDetails.doneTask && ProjectPieChartDetails.doneTask.count)
            const labelsData = ProjectPiechartList.filter((i) => i !== null)
            const seriesData = ProjectPieChartCountList.filter((i) => i !== null)
            setLabels(labelsData);
            setSeries(seriesData);
        }
    }, [ProjectPieChartDetails])

    useEffect(() => {
        // clear task filter when trigger milestone list
        setFilterTask({
            ...filterTask, TaskStartDate: '', TaskName: '', AssignedUserName: '', TaskStatus: ''
        })
        setStatusFilter('Select');

        setMilestones(props.ProjectMilestoneLists);
        if (props.ProjectMilestoneLists && props.ProjectMilestoneLists.length > 0) {
            // Set first mileStone Id for default to get tasks list.
            const firstMilestoneId = props.ProjectMilestoneLists[0].Id;
            setAssignedMilestoneId(firstMilestoneId);
            // handleOpenTaskList(null, firstMilestoneId, props.ProjectMilestoneLists[0].MilestoneName, props.ProjectMilestoneLists[0].isCurrentUserManagerOrMilestoneOwner, props.ProjectMilestoneLists[0].AllowFlexibleDueDates, props.ProjectMilestoneLists[0].IsUserUpdateStatus, props.ProjectMilestoneLists[0].IsMilestoneOwnerUpdateStatus);
        } else {
            setTasks([]);
            setTasksTotalrecord(0);
            setAssignedMilestoneName();
        }

    }, [props.ProjectMilestoneLists]);

    useEffect(() => {
        if (filterTask) {
            // handleOpenTaskList(null, assignedMilestoneId, props.ProjectMilestoneLists[0].MilestoneName, props.ProjectMilestoneLists[0].isCurrentUserManagerOrMilestoneOwner, props.ProjectMilestoneLists[0].AllowFlexibleDueDates, props.ProjectMilestoneLists[0].IsUserUpdateStatus, props.ProjectMilestoneLists[0].IsMilestoneOwnerUpdateStatus, filterTask);
            if (props.ProjectMilestoneLists && props.ProjectMilestoneLists.length > 0) {
                const milestoneData = props.ProjectMilestoneLists[0];
                if (milestoneData && milestoneData.MilestoneName && milestoneData.isCurrentUserManagerOrMilestoneOwner !== undefined) {
                    handleOpenTaskList(
                        null,
                        assignedMilestoneId,
                        milestoneData.MilestoneName,
                        milestoneData.isCurrentUserManagerOrMilestoneOwner,
                        milestoneData.AllowFlexibleDueDates,
                        milestoneData.IsUserUpdateStatus,
                        milestoneData.IsMilestoneOwnerUpdateStatus,
                        filterTask
                    );
                }
            }
        }
    }, [filterTask])
    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleOpenEditProjectTypeModel = () => {
        props.openEditProjectModal();
    }
    const handleCloseEditProjectModal = () => {
        props.closeEditProjectModal();
    };

    const handleOpenTaskList = (e, Id, milestoneName, isCurrentUserManagerOrMilestoneOwner, AllowFlexibleDueDates, IsUserUpdateStatus, IsMilestoneOwnerUpdateStatus, filterTask) => {
        const timeout = setTimeout(() => {
            props.getmilestoneTaskLists(Id, filterTask, App[0] && App[0].appId);
        }, debounceTime);
        setDebounceTimeout(timeout);
        // props.getmilestoneTaskLists(Id, filterTask);
        setAssignedMilestoneName(milestoneName);
        setAssignedMilestoneId(Id);
        setAssignedMilestoneTaskCreateOption(isCurrentUserManagerOrMilestoneOwner);
        setUserUpdateStatus(IsUserUpdateStatus);
        setMilestoneOwnerUpdateStatus(IsMilestoneOwnerUpdateStatus);
    }
    const handleOpenCreateMilestones = () => {
        props.openCreateMilestoneModal();
    }
    const handleClose = () => {
        props.closeCreateMilestoneModal();
    };
    const handleOpenCreateTask = () => {
        props.openCreateTaskModal();
    };
    const handleCloseTaskModal = () => {
        props.closeCreateTaskModal();
    };

    // --------------------------------------------SORTING------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        console.log("column:", column);
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedTaskList = [...task].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'CurrentStatusPercentage') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    const sortedMilestoneList = [...milestone].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    const handleMileStoneSorting = (option) => {
        setSortBy(option);
    }
    const handleMileStoneSortOrder = (order) => {
        setMileStoneOrder(order);
    }
    useEffect(() => {
        setSortBy({ label: 'Start Date', value: 'startDate' });
        setMileStoneOrder('asc');
        return (() => {
            props.clearProjectList();
        })
    }, [])

    console.log('MilestonetaskListError:', props.MilestonetaskListError);
    const { isGlobalReadOnlyUser } = props.currentProfile || {};

    console.log("displayName:", displayName);
    console.log("MilestoneTaskList:", props.MilestoneTaskList);

    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        console.log("Id:", Id);
        navigate(`/projectportal/tasks/details/${Id}`);
    };

    //---------------------------------------------------Status Update for Milestone ------------------------------------------------
    const [statusValues, setStatusValues] = useState({});
    const handleEditClick = (itemId) => {
        props.isStatusMilestoneEditOpen(itemId);
    };
    const handleSelectChange = (selectedOption, Id, projectId) => {
        const updatedStatus = selectedOption ? selectedOption.value : '';
        setStatusValues((prevValues) => ({
            ...prevValues,
            [Id]: updatedStatus
        }));
        console.log("sortBy.value:", sortBy.value);
        console.log("sortOrder:", sortOrder);
        console.log("filter:", filter);

        // props.UpdateMilestonesAction({ Id, Status: updatedStatus, ProjectId: projectId }, sortBy.value, sortOrder, filter);
        props.UpdateMilestonesAction({ Id, Status: updatedStatus, ProjectId: projectId }, sortBy.value, mileStoneOrder, filter);
    };
    //-------------------------------------------------Task status Update -------------------------------------------------

    const [taskStatusValues, setTaskStatusValues] = useState([]);

    const handleTaskSelectChange = (selectedOption, Id, milestoneId, projectId) => {
        const updatedStatus = selectedOption ? selectedOption.value : '';
        setTaskStatusValues((prevValues) => ({
            ...prevValues,
            [Id]: updatedStatus
        }));
        props.UpdateTaskAction({ TaskStatus: updatedStatus, taskId: Id, MileStoneId: milestoneId, ProjectId: projectId, CurrentStatusPercentage: selectedOption.Percentage }, filterTask, null, App[0] && App[0].appId);
    };
    const handleTaskMilestoneOwnerStatus = (inputValue, callback) => {
        const { getTaskMilestoneOwnerStatusAsyncSelectAction } = props;
        return getTaskMilestoneOwnerStatusAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleTaskUserStatus = (inputValue, callback) => {
        const { getTaskUserStatusAsyncSelectAction } = props;
        return getTaskUserStatusAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const { userId } = props.currentProfile;
    console.log("userId:", userId);

    const handleTaskEditClick = (itemId) => {
        props.isStatusTaskEditOpen(itemId);
    };

    //---------------------------------dropdown close action for outside click------------------------------------------
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                props.isStatusMilestoneEditOpen(null);
                props.isStatusTaskEditOpen(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    //----------------------------------------------------------------------------------------------------------------------
    console.log("isStatusMilestoneEditID:", props.isStatusMilestoneEditID);
    console.log("MilestoneOwnerUpdateStatus:", MilestoneOwnerUpdateStatus);
    console.log("ProjectMilestoneLists:", props.ProjectMilestoneLists);
    //------------------------------------------Filter-----------------------------------------------------------
    const [filter, setFilter] = useState();
    console.log("filter:", filter);
    console.log("filterTask:", filterTask);


    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
        // remove task filter when filter applied to milestone list(to avoid mismatch filter).
        // setFilterTask({
        //     ...filterTask, TaskStartDate: '', TaskName: '', AssignedUserName: '', TaskStatus: ''
        // })
        // setStatusFilter('Select');
    }
    const searchTaskInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilterTask({
            ...filterTask,
            [name]: value,
        })
    }
    const [TaskStatusFilter, setStatusFilter] = useState();
    const searchStatus = (Value) => {
        const { label, value } = Value || {};
        setFilterTask({
            ...filterTask,
            TaskStatus: value,
        })
        setStatusFilter({ label: label || 'Search', value: value || '' })
    }

    const handleMilestoneStatus = (Value) => {
        const { value } = Value || {};
        setFilter({
            ...filter,
            Status: value
        })
        // setFilterTask({
        //     ...filterTask, TaskStartDate: '', TaskName: '', AssignedUserName: '', TaskStatus: ''
        // })
        // setStatusFilter('Select');
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;

    useEffect(() => {
        if (mileStoneOrder || sortBy || filter) {
            const { value } = sortBy || {};
            const timeout = setTimeout(() => {
                props.getMilestoneList(id, value, mileStoneOrder, filter);
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [sortBy, mileStoneOrder, filter])

    const handleTaskStatus = (inputValue, callback) => {
        const { getTaskStatusAsyncSelectAction } = props;
        return getTaskStatusAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    console.log("sortBy:", sortBy);
    console.log("sortOrder:", sortOrder);

    return (
        <React.Fragment>
            <div className="page-content">
                <div style={{ height: "85vh", overflowY: "auto" }}>
                    <Card>
                        <CardTitle
                            tag="h4"
                            className=" px-3 py-2 mb-0 d-flex justify-content-between align-items-center overflow-hidden sticky-top"
                            style={{ backgroundColor: "white", overflow: "hidden", zIndex: "30" }}
                        >
                            <span className='text-capitalize'>
                                <strong > PROJECT DETAILS&nbsp;&gt;&nbsp;<span id={'ProjName'}>{props.isProjectDetailsPending ? <>
                                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" />
                                    &nbsp; loading...
                                </>
                                    : <span >{projectDetails && projectDetails.projectName && projectDetails.projectName.trim() || "NA"}</span>}<ElementToolTip id={'ProjName'} name={'Project Name'} />    </span ></strong>

                            </span>
                            {((!props.errorMessage) && (projectDetails && projectDetails.isEditable)) && (!props.isProjectDetailsPending) && (!isGlobalReadOnlyUser) &&
                                <Link type="button"
                                    className="btn btn-info"
                                    onClick={handleOpenEditProjectTypeModel}
                                >
                                    EDIT
                                </Link>
                            }
                        </CardTitle>
                        {/* </Card> */}
                        <div className=" sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />
                            )}
                            {props.isProjectDetailsPending ?
                                <div className="d-flex justify-content-center mt-2">
                                    <VWSpinner />
                                </div>
                                :
                                <Row className="g-0">
                                    <Col xl="9">
                                        <Card className='p-2 pb-1 me-1' style={{ minHeight: '220px' }}>
                                            {/* ---------------------------------------------PROJECT-DETAILS------------------------------------------------ */}
                                            <div className="table-responsive mt-2">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td className='py-2'>
                                                                <strong>Project Manager</strong>
                                                            </td>
                                                            <td className='py-2 d-flex align-items-center gap-2 text-capitalize'>
                                                                <span> {projectDetails && projectDetails.projectManagerUserName || "N/A"}<br />
                                                                    <small>{projectDetails && projectDetails.projectManagerUserEmail}</small>
                                                                </span>
                                                                <span
                                                                    id={`avatar`}
                                                                >
                                                                    <FontAwesomeIcon icon={faInfoCircle} color='#1CAC78' style={{ cursor: 'pointer' }} />
                                                                </span>
                                                                <ElementToolTip id={`avatar`} name={
                                                                    <div>
                                                                        {(projectDetails && projectDetails.projectUserDetails && projectDetails.projectUserDetails.length) > 0 ? projectDetails && projectDetails.projectUserDetails && projectDetails.projectUserDetails.map((user, index) => (
                                                                            <div key={index}>
                                                                                <small className='text-capitalize'>{user && user.assignedUserName} - {user && user.assignedUserPermission} </small>
                                                                            </div>
                                                                        )) : 'No user assigned'}
                                                                    </div>
                                                                } />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='py-2'>
                                                                <strong>Description</strong>
                                                            </td>
                                                            <td className='py-2'>{projectDetails && projectDetails.description || "N/A"}</td>
                                                        </tr>
                                                        {(projectDetails && projectDetails.client) &&
                                                            <tr>
                                                                <td className='py-2'>
                                                                    <strong>Client</strong>
                                                                </td>
                                                                <td className='py-2'>{projectDetails && projectDetails.client || "N/A"}</td>
                                                            </tr>}
                                                        <tr>
                                                            <th className='py-2'>Duration</th>
                                                            <td className='py-2'>
                                                                <span
                                                                    id="startDate"

                                                                >
                                                                    {projectDetails && projectDetails.startDate ? moment(projectDetails && projectDetails.startDate).format('DD MMM YYYY') : 'NA' || "NA"}
                                                                </span>
                                                                <ElementToolTip id={`startDate`} name={
                                                                    'Start Date'
                                                                } />
                                                                <span>&nbsp; → &nbsp;</span>
                                                                <span
                                                                    id='endDate'
                                                                >
                                                                    {projectDetails && projectDetails.endDate ? moment(projectDetails && projectDetails.endDate).format('DD MMM YYYY') : 'NA' || "NA"}
                                                                </span>
                                                                <ElementToolTip id={`endDate`} name={
                                                                    'End Date'
                                                                } />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col xl="3">
                                        <Card style={{ minHeight: '220px' }}>
                                            {/* ---------------------------------------------PIE-CHART------------------------------------------------ */}
                                            <div className='fs-5 fw-bold pt-2 ps-2 text-info'>
                                                <FontAwesomeIcon icon={faChartPie} /> Pie-Chart
                                            </div>
                                            {props.projectPieChartDetailsError && (
                                                <VWMessage type="danger" defaultMessage={props.projectPieChartDetailsError} baseClassName="alert" />
                                            )}
                                            {((labels && labels.length > 0) && (series && series.length > 0)) ?
                                                <PieChart labels={labels} value={series} />
                                                : <div className="d-flex justify-content-center">
                                                    <div className={props.projectPieChartDetailsError ? "text-center mb-4" : "text-center my-4"}>
                                                        <div className="text-md mb-2 text-danger">Pie-Chart data not found</div>
                                                        <p className="lead m-0">Oh! It is empty :{`(`} </p>
                                                        <p className='m-0'>
                                                            You don`t have any data for this pie-chart.
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        </Card>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Card>
                    <VWModel
                        header="EDIT PROJECT"
                        modalSize="extra-large"
                        isOpen={props.isEditProjectModalOpen}
                        handleClose={handleCloseEditProjectModal}
                    >
                        <UpdateProjects handleClose={handleCloseEditProjectModal} />
                    </VWModel>
                    <Row className='g-0'>
                        <Col xl="6">
                            <Card className='p-2 me-1'>
                                {/* ---------------------------------------------MILESTONE-LIST------------------------------------------------ */}
                                {/* <div style={{ height: "300px", overflowX: "auto" }}> */}
                                {(props.ProjectMilestoneListError || props.updateStatusMilestoneErrorMessage) && (
                                    <VWMessage type="danger" defaultMessage={(props.ProjectMilestoneListError || props.updateStatusMilestoneErrorMessage)} baseClassName="alert" />
                                )}
                                <div className='d-flex justify-content-between border-secondary border-bottom pb-1'>
                                    <div className="p-2 fw-bold fs-5" >
                                        <span className='text-capitalize text-info'>
                                            <FontAwesomeIcon icon={faStream} />  Milestones -&nbsp;
                                            <Badge
                                                id='mileStoneCount'
                                                color="secondary"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {props && props.MilestonetotalRecords}
                                            </Badge>
                                            <ElementToolTip id={`mileStoneCount`} name={
                                                'Milestones Count'
                                            } />
                                        </span>
                                        {projectDetails && projectDetails.isEditable &&
                                            <>
                                                <Button
                                                    id='createMileStone'
                                                    onClick={handleOpenCreateMilestones}
                                                    size='sm'
                                                    className='ms-2 border rounded-circle align-items-center'
                                                    color='primary'
                                                >
                                                    <FontAwesomeIcon icon={faPlus} style={{ fontWeight: 'bolder', color: "white", marginTop: '5px' }} />
                                                </Button>
                                                <ElementToolTip id={`createMileStone`} name={
                                                    'Create Milestone'
                                                } />
                                            </>
                                        }
                                    </div>
                                    <div className='d-flex gap-2 align-items-center me-2'>
                                        <div className='fw-bold text-nowrap'>
                                            Sort By :
                                        </div>
                                        <Select
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    minWidth: '150px',
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    padding: '0',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                    backgroundColor: '#f1f1f1',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected
                                                        ? '#007bff'
                                                        : state.isFocused
                                                            ? '#e3f3f7'
                                                            : '#ffffff',
                                                    color: state.isSelected ? '#ffffff' : '#000000',
                                                    padding: '10px',
                                                }),
                                                menuPortal: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            options={MILESTONE_SORTING_OPTIONS}
                                            onChange={handleMileStoneSorting}
                                            value={sortBy}
                                        />
                                        <FontAwesomeIcon
                                            id='asc'
                                            icon={faArrowUp}
                                            style={{
                                                fontSize: '20px',
                                                color: mileStoneOrder === 'asc' ? 'black' : 'gray',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleMileStoneSortOrder('asc')}
                                        />
                                        <ElementToolTip id={`asc`} name={
                                            'Ascending Order'
                                        } />
                                        <FontAwesomeIcon
                                            id='desc'
                                            icon={faArrowDown}
                                            style={{
                                                fontSize: '20px',
                                                color: mileStoneOrder === 'desc' ? 'black' : 'gray',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleMileStoneSortOrder('desc')}
                                        />
                                        <ElementToolTip id={`desc`} name={
                                            'Descending Order'
                                        } />
                                    </div>
                                </div>
                                {(props.isProjectMilestonePending || props.isUpdateStatusMilestonePending) ?
                                    <div className="d-flex justify-content-center mt-2">
                                        <VWSpinner />
                                    </div>
                                    :
                                    <div className="table-responsive mt-2" style={{ height: "40vh", overflowY: 'auto' }}>
                                        <Table className="table table-striped table-hover table-nowrap w-100" size="sm" style={style.table}>
                                            <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                                <tr >
                                                    <th className='hand-cursor' onClick={() => handleSort('MilestoneName')}>Milestone Name &nbsp;
                                                        {sortOrder === 'MilestoneName' && (
                                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                        )}</th>
                                                    <th className='hand-cursor' onClick={() => handleSort('Status')}>Status  &nbsp;
                                                        {sortOrder === 'Status' && (
                                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                                        }</th>
                                                    <th className='hand-cursor' onClick={() => handleSort('StartDate')}>Duration &nbsp;
                                                        {sortOrder === 'StartDate' && (
                                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                                        }</th>
                                                    <th className='hand-cursor' onClick={() => handleSort('MileStoneOwnerUserName')}>Milestone Owner &nbsp;
                                                        {sortOrder === 'MileStoneOwnerUserName' && (
                                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                                        }</th>
                                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>View</th>
                                                </tr>
                                                <tr >
                                                    <th >
                                                        <Input
                                                            size="sm"
                                                            type='text'
                                                            placeholder='Search'
                                                            name='MilestoneName'
                                                            onChange={searchInputValidation}
                                                            value={filter && filter.MilestoneName}
                                                        /></th>
                                                    <th >
                                                        <Select
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    fontWeight: 'normal',
                                                                    height: '30px', // Adjust the height to make it smaller
                                                                    minHeight: '30px',
                                                                    fontSize: '0.700rem', // Set font size for small size
                                                                    padding: '0 8px', // Add some padding to the select control
                                                                    minWidth: '150px', // Set a minimum width (adjust as needed)
                                                                }),
                                                                dropdownIndicator: (provided) => ({
                                                                    ...provided,
                                                                    padding: '0px 1px 0px 0px', // Make dropdown indicator smaller
                                                                }),
                                                                indicatorSeparator: (provided) => ({
                                                                    ...provided,
                                                                    display: 'none', // Optionally remove the indicator separator
                                                                }),
                                                                placeholder: (provided) => ({
                                                                    ...provided,
                                                                    fontWeight: 'normal', // Reduce the font weight of the placeholder
                                                                    fontSize: '0.700rem', // Set the font size for placeholder text
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    fontWeight: 'normal',
                                                                    fontSize: '0.700rem', // Set font size in the dropdown menu
                                                                }),
                                                                option: (provided) => ({
                                                                    ...provided,
                                                                    fontWeight: 'normal',
                                                                    fontSize: '0.700rem', // Set font size for options
                                                                }),
                                                            }}
                                                            size="sm"
                                                            name="Status"
                                                            placeholder="Search"
                                                            isSearchable
                                                            isClearable
                                                            defaultOptions
                                                            options={STATUS_OPTIONS}
                                                            onChange={handleMilestoneStatus}
                                                            value={filter && filter.Status ? { label: filter && filter.Status, value: filter && filter.Status } : 'Select milestone status'}
                                                        /></th>
                                                    <th >
                                                        <Input
                                                            size="sm"
                                                            type='date'
                                                            placeholder='Search'
                                                            name='StartDate'
                                                            onChange={searchInputValidation}
                                                            value={filter && filter.StartDate}
                                                            max="9999-12-31"
                                                        />
                                                    </th>
                                                    <th >
                                                        <Input
                                                            size="sm"
                                                            type='text'
                                                            placeholder='Search'
                                                            name='MileStoneOwnerUserName'
                                                            onChange={searchInputValidation}
                                                            value={filter && filter.MileStoneOwnerUserName}
                                                        // onKeyPress={handleKeyPress}
                                                        /></th>
                                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedMilestoneList && sortedMilestoneList.length > 0 ?
                                                    sortedMilestoneList && sortedMilestoneList.map((item) =>
                                                        <tr style={{ cursor: 'pointer' }} onClick={(e) => handleOpenTaskList(e, item.Id, item.MilestoneName, item.isCurrentUserManagerOrMilestoneOwner, item.AllowFlexibleDueDates, item.IsUserUpdateStatus, item.IsMilestoneOwnerUpdateStatus)}>
                                                            <td>
                                                                <span
                                                                    id={`milestoneName-${item.Id}`}
                                                                >
                                                                    {item.MilestoneName || 'N/A'}&nbsp;
                                                                </span>
                                                                <ElementToolTip id={`milestoneName-${item.Id}`} name={'Milestone Name'} />
                                                                <sup
                                                                    id={`milestoneTaskCount-${item.Id}`}
                                                                >
                                                                    <Badge color='danger'>  {item.TaskCount || '0'}</Badge>
                                                                </sup>
                                                                <ElementToolTip id={`milestoneTaskCount-${item.Id}`} name={'Tasks Count'} />
                                                            </td>
                                                            <td>
                                                                {item.IsEditable ? (
                                                                    props.isStatusMilestoneEditID === item.Id ? (
                                                                        <div className='d-flex' ref={dropdownRef}>
                                                                            <Select
                                                                                styles={customStyles}
                                                                                name="Status"
                                                                                options={STATUS_OPTIONS}
                                                                                value={STATUS_OPTIONS.find(option => option.value === (statusValues[item.Id] || item.Status)) || null}
                                                                                onChange={(e) => { handleSelectChange(e, item.Id, item.ProjectId) }}
                                                                                isDisabled={props.isUpdateStatusMilestonePending}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className='d-flex justify-content-between'>
                                                                            <span id={`mileStoneStatus-${item.Id}`}>
                                                                                {item.Status || 'N/A'}
                                                                            </span>
                                                                            <ElementToolTip id={`mileStoneStatus-${item.Id}`} name={'Status'} />
                                                                            <FontAwesomeIcon
                                                                                icon={faEdit}
                                                                                style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                onClick={() => handleEditClick(item.Id)}
                                                                            />
                                                                        </div>
                                                                    )
                                                                ) : (
                                                                    <>
                                                                        <span id={`mileStoneStatus-${item.Id}`}>
                                                                            {item.Status || 'N/A'}
                                                                        </span>
                                                                        <ElementToolTip id={`mileStoneStatus-${item.Id}`} name={'Status'} />
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    className='badge badge-soft-success'
                                                                    id={`mileStoneStartDate-${item.Id}`}
                                                                >
                                                                    {item.StartDate ? moment(item.StartDate).format('DD MMM YYYY') : 'NA' || "NA"}
                                                                </span>
                                                                <ElementToolTip id={`mileStoneStartDate-${item.Id}`} name={'Start Date'} />
                                                                <span>&nbsp;→&nbsp;</span>
                                                                <span
                                                                    className='badge badge-soft-danger'
                                                                    id={`mileStoneEndDate-${item.Id}`}
                                                                >
                                                                    {item.EndDate ? moment(item.EndDate).format('DD MMM YYYY') : 'NA' || "NA"}
                                                                </span>
                                                                <ElementToolTip id={`mileStoneEndDate-${item.Id}`} name={'End Date'} />
                                                            </td>
                                                            <td>
                                                                <span
                                                                    id={`mileStoneOwnerTooltipOpen-${item.Id}`}
                                                                >
                                                                    {item.MileStoneOwnerUserName || 'N/A'}
                                                                    <br />
                                                                    <small>{item.MileStoneOwnerUserEmail || "N/A"}</small>
                                                                </span>
                                                                <ElementToolTip id={`mileStoneOwnerTooltipOpen-${item.Id}`} name={'Milestone Owner Name'} />
                                                            </td>
                                                            <td className='sticky-column text-end' style={{ backgroundColor: "white" }}>
                                                                <Link
                                                                    type="button"
                                                                    className="btn btn-sm  btn-light"
                                                                    to={`/projectportal/milestones/details/${item.Id}`}
                                                                >
                                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black" }} />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    <td colSpan={7}>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="text-center my-4">
                                                                <div className="mb-3">
                                                                    <em className="bi bi-database error-title"></em>
                                                                </div>
                                                                <div className="text-md mb-3 text-danger">Milestone not found</div>
                                                                <p className="lead m-0">Oh! It is empty :{`(`} </p>
                                                                <p>
                                                                    You don`t have any milestone for this project.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                                {/* </div> */}
                            </Card>
                        </Col>
                        <Col xl="6">
                            <Card className='p-2'>
                                {/* ---------------------------------------------TASK-LIST------------------------------------------------ */}
                                {/* <div style={{ height: "300px", overflowX: "auto" }}> */}
                                {props.MilestonetaskListError && (
                                    <div className='mt-1'>
                                        <VWMessage type="danger" defaultMessage={props.MilestonetaskListError} baseClassName="alert" />
                                    </div>
                                )}
                                <div className="d-flex justify-content-between align-items-center border-secondary border-bottom pb-1 overflow-hidden sticky-top" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <div className="p-2 fw-bold fs-5 d-flex align-items-center">
                                        <span className="text-info">
                                            Tasks for {assignedMilestone ? (
                                                <div className="d-inline" id={`selectedMilestoneName`}>
                                                    {assignedMilestone}
                                                    <ElementToolTip id={`selectedMilestoneName`} name={'Selected Milestone'} />
                                                </div>
                                            ) : (
                                                <span>Milestone</span>
                                            )}
                                            &nbsp;-&nbsp;
                                            <Badge
                                                id={`taskCount`}
                                                color="secondary"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {taskTotalRecord}
                                            </Badge>
                                            <ElementToolTip id={`taskCount`} name={'Tasks Count'} />
                                        </span>
                                        {assignedMilestone && assignedMilestoneTaskCreateOption && (
                                            <div className="ms-2">
                                                <Button
                                                    id={`createTask`}
                                                    className="border rounded-circle"
                                                    size="sm"
                                                    color="primary"
                                                    onClick={handleOpenCreateTask}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} style={{ fontWeight: 'bolder', color: "white", marginTop: '5px' }} />
                                                </Button>
                                                <ElementToolTip id={`createTask`} name={'Create Task'} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-1" >
                                    <div style={{ height: "40vh", overflowY: 'auto' }}>
                                        <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                            <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                                <tr>
                                                    <th className='hand-cursor' onClick={() => handleSort('TaskName')}>Task Name &nbsp;
                                                        {sortOrder === 'TaskName' && (
                                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                        )}</th>
                                                    <th className='hand-cursor' onClick={() => handleSort('CurrentStatusPercentage')}>Status  &nbsp;
                                                        {sortOrder === 'CurrentStatusPercentage' && (
                                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                                        }</th>
                                                    <th className='hand-cursor' onClick={() => handleSort('AssignedUserName')}>Assigned To &nbsp;
                                                        {sortOrder === 'AssignedUserName' && (
                                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                                        }</th>
                                                    <th className='hand-cursor' onClick={() => handleSort('StartDate')}>Duration &nbsp;
                                                        {sortOrder === 'StartDate' && (
                                                            <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)
                                                        }</th>
                                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>View</th>
                                                </tr>
                                                <tr>
                                                    <th >
                                                        <Input
                                                            // size="sm"
                                                            type='text'
                                                            placeholder='Search'
                                                            name='TaskName'
                                                            onChange={searchTaskInputValidation}
                                                            value={filterTask && filterTask.TaskName}
                                                        /></th>
                                                    <th >
                                                        <Async
                                                            styles={customStyles}
                                                            name="TaskStatus"
                                                            placeholder="Search"
                                                            isSearchable
                                                            isClearable
                                                            defaultOptions
                                                            loadOptions={handleTaskStatus}
                                                            onChange={searchStatus}
                                                            value={TaskStatusFilter}
                                                        /></th>
                                                    <th >
                                                        <Input
                                                            // size="sm"
                                                            type='text'
                                                            placeholder='Search'
                                                            name='AssignedUserName'
                                                            onChange={searchTaskInputValidation}
                                                            value={filterTask && filterTask.AssignedUserName}
                                                        // onKeyPress={handleKeyPress}
                                                        /></th>
                                                    <th >
                                                        <Input
                                                            // size="sm"
                                                            type='date'
                                                            placeholder='Search'
                                                            name='TaskStartDate'
                                                            onChange={searchTaskInputValidation}
                                                            value={filterTask && filterTask.TaskStartDate}
                                                            max="9999-12-31"
                                                        />
                                                    </th>
                                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(props.isMilestoneTaskPending || props.isUpdateTaskStatusPending || props.updateTaskStatusErrorMessage) ? (
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className="d-flex justify-content-center mt-2">
                                                                <VWSpinner />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    sortedTaskList && sortedTaskList.length > 0 ?
                                                        sortedTaskList && sortedTaskList.map(item =>
                                                            <tr onDoubleClick={() => OnDoubleClickAction(item.Id)}>
                                                                <td>{item.TaskName || 'N/A'}</td>
                                                                <td>
                                                                    {item.IsEditable ? (
                                                                        <>
                                                                            {item.IsMilestoneOwnerUpdateStatus ? (
                                                                                props.isStatusTaskEditID === item.Id ? (
                                                                                    <div className='d-flex' ref={dropdownRef}>
                                                                                        <Async
                                                                                            styles={customStyles}
                                                                                            name="TaskStatus"
                                                                                            placeholder="Search"
                                                                                            isSearchable
                                                                                            isClearable
                                                                                            defaultOptions
                                                                                            loadOptions={handleTaskMilestoneOwnerStatus}
                                                                                            onChange={(e) => { handleTaskSelectChange(e, item.Id, item.MileStoneId, item.ProjectId) }}
                                                                                            value={taskStatusValues[item.Id] ?
                                                                                                { value: taskStatusValues[item.Id], label: taskStatusValues[item.Id] } :
                                                                                                item.TaskStatus ?
                                                                                                    { value: item.TaskStatus, label: item.TaskStatus } :
                                                                                                    null
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <span className="d-flex justify-content-between">{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                        <FontAwesomeIcon
                                                                                            icon={faEdit}
                                                                                            style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                            onClick={() => handleTaskEditClick(item.Id)}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            ) : (

                                                                                item.IsUserUpdateStatus && userId === item.AssignedUserId ? (
                                                                                    props.isStatusTaskEditID === item.Id ? (
                                                                                        <div className='d-flex' ref={dropdownRef}>
                                                                                            <Async
                                                                                                styles={customStyles}
                                                                                                name="TaskStatus"
                                                                                                placeholder="Search"
                                                                                                isSearchable
                                                                                                isClearable
                                                                                                defaultOptions
                                                                                                loadOptions={handleTaskUserStatus}
                                                                                                onChange={(e) => { handleTaskSelectChange(e, item.Id, item.MileStoneId, item.ProjectId) }}
                                                                                                value={taskStatusValues[item.Id] ?
                                                                                                    { value: taskStatusValues[item.Id], label: taskStatusValues[item.Id] } :
                                                                                                    item.TaskStatus ?
                                                                                                        { value: item.TaskStatus, label: item.TaskStatus } :
                                                                                                        null
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <span className="d-flex justify-content-between">{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faEdit}
                                                                                                style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                                onClick={() => handleTaskEditClick(item.Id)}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                ) : (

                                                                                    props.isStatusTaskEditID === item.Id ? (
                                                                                        <div className='d-flex' ref={dropdownRef}>
                                                                                            <Async
                                                                                                styles={customStyles}
                                                                                                name="TaskStatus"
                                                                                                placeholder="Search"
                                                                                                isSearchable
                                                                                                isClearable
                                                                                                defaultOptions
                                                                                                loadOptions={handleTaskStatus}
                                                                                                onChange={(e) => { handleTaskSelectChange(e, item.Id, item.MileStoneId, item.ProjectId) }}
                                                                                                value={taskStatusValues[item.Id] ?
                                                                                                    { value: taskStatusValues[item.Id], label: taskStatusValues[item.Id] } :
                                                                                                    item.TaskStatus ?
                                                                                                        { value: item.TaskStatus, label: item.TaskStatus } :
                                                                                                        null
                                                                                                }

                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <span className="d-flex justify-content-between">{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faEdit}
                                                                                                style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                                onClick={() => handleTaskEditClick(item.Id)}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : item.IsUserEdit ? (

                                                                        props.isStatusTaskEditID === item.Id ? (
                                                                            <div className='d-flex' ref={dropdownRef}>
                                                                                <Async
                                                                                    styles={customStyles}
                                                                                    name="TaskStatus"
                                                                                    placeholder="Search"
                                                                                    isSearchable
                                                                                    isClearable
                                                                                    defaultOptions
                                                                                    loadOptions={handleTaskUserStatus}
                                                                                    onChange={(e) => { handleTaskSelectChange(e, item.Id, item.MileStoneId, item.ProjectId) }}
                                                                                    value={taskStatusValues[item.Id] ?
                                                                                        { value: taskStatusValues[item.Id], label: taskStatusValues[item.Id] } :
                                                                                        item.TaskStatus ?
                                                                                            { value: item.TaskStatus, label: item.TaskStatus } :
                                                                                            null
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div className='d-flex justify-content-between'>
                                                                                <span className="d-flex justify-content-between">{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                                <FontAwesomeIcon
                                                                                    icon={faEdit}
                                                                                    style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                                                    onClick={() => handleTaskEditClick(item.Id)}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    ) : (
                                                                        <span className="d-flex justify-content-between">{`${item.TaskStatus} - ${item.CurrentStatusPercentage || 0}%` || 'N/A'}</span>
                                                                    )}
                                                                </td>
                                                                <td>{item.AssignedUserName || "N/A"} <br />
                                                                    <small>{item.AssignedUserEmail || "N/A"}</small></td>
                                                                <td>
                                                                    <span
                                                                        id={`taskStartDate`}
                                                                        className='badge badge-soft-success'
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        {item.StartDate ? moment(item.StartDate).format('DD MMM YYYY') : 'NA' || "NA"}
                                                                    </span>
                                                                    <ElementToolTip id={`taskStartDate`} name={'Start Date'} />
                                                                    <span>&nbsp;→&nbsp;</span>
                                                                    <span
                                                                        id={`taskEndDate`}
                                                                        className='badge badge-soft-danger'
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        {item.EndDate ? moment(item.EndDate).format('DD MMM YYYY') : 'NA' || "NA"}
                                                                    </span>
                                                                    <ElementToolTip id={`taskEndDate`} name={'End Date'} />
                                                                </td>
                                                                <td className='sticky-column' style={{ backgroundColor: "white" }}>
                                                                    <Link
                                                                        type="button"
                                                                        className="btn btn-sm  btn-light"
                                                                        to={`/projectportal/tasks/details/${item.Id}`}
                                                                    >
                                                                        <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ) :
                                                        (<tr>
                                                            <td colSpan={5}>
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="text-center my-4">
                                                                        <div className="mb-3">
                                                                            <em className="bi bi-database error-title"></em>
                                                                        </div>
                                                                        <div className="text-md mb-3 text-danger">Task not found</div>
                                                                        <p className="lead m-0">Oh! It is empty :{`(`} </p>
                                                                        <p>
                                                                            You don`t have any task for the selected milestone <br />or else  you do not select any milestone.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <VWModel
                    header="CREATE MILESTONE"
                    isOpen={props.isCreateMilestoneModalOpen}
                    handleClose={handleClose}
                >
                    <CreateMilestones
                        ProjName={projectDetails && projectDetails.projectName}
                        projId={projectDetails && projectDetails.id}
                        selectedFlexibleDueDates={projectDetails && projectDetails.allowFlexibleDueDates}
                        handleClose={handleClose}
                    />
                </VWModel>
                <VWModel
                    header="CREATE TASK"
                    isOpen={props.isCreateTaskModalOpen}
                    handleClose={handleCloseTaskModal}
                    modalSize="large"
                >
                    <CreateTask
                        selectedProjectName={projectDetails && projectDetails.projectName}
                        selectedProjectId={projectDetails && projectDetails.id}
                        selectedMilestoneId={assignedMilestoneId}
                        selectedMilestoneName={assignedMilestone}
                        selectedMilestoneEditOption={assignedMilestoneTaskCreateOption}
                        selectedFlexibleDueDates={projectDetails && projectDetails.allowFlexibleDueDates}
                        selectedLoginUser={displayName}
                        selectedLoginEmail={userEmail}
                        selectedUserStatus={UserUpdateStatus}
                        selectedMilestoneOwnerStatus={MilestoneOwnerUpdateStatus}
                        handleClose={handleCloseTaskModal}
                    />
                </VWModel>
            </div >
        </React.Fragment >
    )
}
const mapStateToProps = (state) => ({
    isEditProjectModalOpen: state.ProjectPortal.isEditProjectModalOpen,
    isCreateMilestoneModalOpen: state.Milestones.isCreateMilestoneModalOpen,
    isCreateTaskModalOpen: state.task.isCreateTaskModalOpen,
    projectDetails: state.ProjectPortal.ProjectDetails,
    errorMessage: state.ProjectPortal.projectDetailsError,
    projectPieChartDetailsError: state.ProjectPortal.projectPieChartDetailsError,
    ProjectPieChartDetails: state.ProjectPortal.ProjectPieChartDetails,
    isProjectDetailsPending: state.ProjectPortal.isProjectDetailsPending,
    ProjectMilestoneLists: state.Milestones.ProjectMilestoneLists,
    isProjectMilestonePending: state.Milestones.isProjectMilestonePending,
    isStatusMilestoneEdit: state.Milestones.isStatusMilestoneEdit,
    ProjectMilestoneListError: state.Milestones.ProjectMilestoneListError,
    MilestonetotalRecords: state.Milestones.MilestonetotalRecords,
    isUpdateStatusMilestonePending: state.Milestones.isUpdateStatusMilestonePending,
    isStatusMilestoneEditID: state.Milestones.isStatusMilestoneEditID,
    updateStatusMilestoneErrorMessage: state.Milestones.updateStatusMilestoneErrorMessage,
    isStatusTaskEditID: state.task.isStatusTaskEditID,
    MilestoneTaskList: state.task.MilestoneTaskList,
    MilestoneTasktotalRecords: state.task.MilestoneTasktotalRecords,
    MilestonetaskListError: state.task.MilestonetaskListError,
    isMilestoneTaskPending: state.task.isMilestoneTaskPending,
    isUpdateTaskStatusPending: state.task.isUpdateTaskStatusPending,
    updateTaskStatusErrorMessage: state.task.updateTaskStatusErrorMessage,
    isMileStoneSortModalOpen: state.ProjectPortal.isMileStoneSortModalOpen,
    currentProfile: state.login.currentProfile,
});

const mapDispatchToProps = (dispatch) => ({
    clearProjectList: () => dispatch(clearProjectList()),
    clearProjectDetails: () => dispatch(projectDetailsError()),
    closeEditProjectModal: () => dispatch(closeEditProjectModalAction()),
    openEditProjectModal: () => dispatch(openEditProjectModalAction()),
    closeCreateMilestoneModal: () => dispatch(closeCreateMilestoneModalAction()),
    openCreateMilestoneModal: () => dispatch(openCreateMilestoneModalAction()),
    openCreateTaskModal: () => dispatch(openCreateTaskModalAction()),
    closeCreateTaskModal: () => dispatch(CloseCreateTaskModalAction()),
    openSortModal: () => dispatch(openSortModal()),
    closeSortModal: () => dispatch(closeSortModal()),
    getProjectDetails: (Id) => dispatch(getProjectDetails(Id)),
    getMilestoneList: (Id, sortName, sortOrder, filter) => dispatch(getProjectMilestoneList(Id, sortName, sortOrder, filter)),
    getmilestoneTaskLists: (Id, filterTask, appId) => dispatch(getmilestoneTaskLists(Id, filterTask, appId)),
    getProjectPieChart: (Id) => dispatch(getProjectPieChartLists(Id)),
    clearMilestoneTaskList: () => dispatch(clearMilestoneTaskList()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
    ClearPath: () => dispatch(clearSetPath()),
    clearMilestoneTaskListError: () => dispatch(clearMilestoneTaskListError()),
    clearMilestoneList: () => dispatch(clearMilestoneList()),
    clearStatusUpdateMilestoneError: () => dispatch(clearStatusUpdateMilestoneError()),
    isStatusMilestoneEditOpen: (id) => dispatch(isStatusMilestoneEditOpen(id)),
    isStatusTaskEditOpen: (id) => dispatch(isStatusTaskEditOpen(id)),
    UpdateMilestonesAction: (formFields, sortName, sortOrder, filter) => dispatch(UpdateStatusMilestone(formFields, sortName, sortOrder, filter)),
    UpdateTaskAction: (formFields, filterTask, APIList, AppId) => dispatch(UpdateTaskStatus(formFields, filterTask, APIList, AppId)),
    getTaskStatusAsyncUserSelectAction: (searchValue, callback) => dispatch(getTaskStatusAsyncUserSelectAction(searchValue, callback)),
    getTaskStatusAsyncSelectAction: (appId, searchValue, callback) => dispatch(getTaskStatusAsyncSelectAction(appId, searchValue, callback)),
    getTaskMilestoneOwnerStatusAsyncSelectAction: (searchValue, callback) => dispatch(getTaskMilestoneOwnerStatusAsyncSelectAction(searchValue, callback)),
    getTaskUserStatusAsyncSelectAction: (searchValue, callback) => dispatch(getTaskUserStatusAsyncSelectAction(searchValue, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails)



