import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
    Table
} from 'reactstrap';
import moment from 'moment-timezone';
import Async from 'react-select/async';
import { connect } from 'react-redux';
import {
    UpdateProject,
    getAdminUserNameAsyncSelect,
    getManagerUserNameAsyncSelectAction,
    getUserNameAsyncSelect,
    updateProjectSuccess
} from '../../../../store/Modules/ProjectPortal/Projects/actions';
import VWMessage from '../../../../components/vwMessage/vwMessage';

const UpdateProjects = (props) => {
    const [formFields, setFormFields] = useState({});
    const [prevProjectUser, setprevProjectUser] = useState([{ label: '', value: '' }]);
    const [prevProjectManagerUser, setprevProjectManagerUser] = useState([{ label: '', value: '' }]);
    const [UserPermission, setUserPermission] = useState();
    const [isReadWrite, setisReadWrite] = useState([]);
    const [errors, setErrors] = useState({});

    const { userAppDetails } = props.currentProfile || {};
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })
    
    const { projectDetails } = props;
    const maxLength = 50;

    useEffect(() => {
        props.updateProjectSuccess();
        if (projectDetails) {
            setFormFields(projectDetails);
            setprevProjectManagerUser({ label: `${projectDetails && projectDetails.projectManagerUserName || 'N/A'} | ${projectDetails && projectDetails.projectManagerUserEmail || 'N/A'}`, value: projectDetails && projectDetails.projectManagerUserId });
        }
        if (projectDetails && projectDetails.projectUserDetails) {
            console.log('projectDetails.projectUserDetails: ', projectDetails.projectUserDetails);
            const UserTypeList = projectDetails && projectDetails.projectUserDetails && projectDetails.projectUserDetails.map((item) => {
                return {
                    label: !item.isGlobalReadOnlyUser ? item.assignedUserName : `${item.assignedUserName} | ${item.assignedUserEmail}`,
                    value: item.assignedUserId,
                    permission: item.assignedUserPermission,
                    assignedUserEmail: item.assignedUserEmail,
                    assignedUserName: item.assignedUserName,
                };
            });
            setprevProjectUser(UserTypeList);
            setUserPermission(UserTypeList);
            const UserID = UserTypeList.map((item) => item.value)
            setFormFields({ ...projectDetails, ProjectUsers: UserID });

            const prevReadWrite = projectDetails && projectDetails.projectUserDetails && projectDetails.projectUserDetails.map((item) => {
                return {
                    userId: item.assignedUserId,
                    permission: item.assignedUserPermission
                };
            });
            setisReadWrite(prevReadWrite);
            setFormFields({ ...projectDetails, ProjectUsers: prevReadWrite });
        }
    }, [])
    //------------------------------------ Default Options-------------------------------------------------------------
    const handleAdminUser = (inputValue, callback) => {
        const { getAdminUserNameAsyncSelectAction } = props;
        return getAdminUserNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    const handleUser = (inputValue, callback) => {
        const { getUserNameAsyncSelectAction } = props;
        return getUserNameAsyncSelectAction(App[0] && App[0].appId || '',inputValue.length > 0 ? inputValue : '', callback);
    };
    const handlemanager = (inputValue, callback) => {
        const { getManagerUserNameAsyncSelectAction } = props;
        return getManagerUserNameAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
    };
    //----------------------------------------Input Field Update--------------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'projectName') {
            if (value && value.length >= maxLength) {
                setErrors({ ...errors, projectName: `Project name cannot exceed ${maxLength} characters.` });
            } else {
                setErrors({ ...errors, projectName: '' });
            }
        }
    };
    //-------------------------------------------Project Manager Update-------------------------------------------------
    const handleEditProjectManagerUser = (value) => {
        setprevProjectManagerUser(value);
        setFormFields({ ...formFields, projectManagerUserId: value.value })
        setErrors({ ...errors, projectManagerUserId: '' });
    }
    //-----------------------------------------Date Field Update -------------------------------------------------------
    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;
        // const formattedDate = value ? moment(value).format() : null;
        const formattedDate = value;
        setFormFields({ ...formFields, [name]: formattedDate });
        setErrors({ ...errors, [name]: '' });
    };
    // ----------------------------------- select Project users---------------------------------------------------------
    const handleEditProjectUser = (value) => {
        const userID = value.map((item) => ({
            userId: item.value,
            permission: isReadWrite && isReadWrite.find((i) => (i.userId === item.value)) ?
                isReadWrite && isReadWrite.filter((i) => i.userId === item.value)[0].permission
                : item.isUserAdmin ? "Write" : "Read"
        }))
        setisReadWrite(userID);
        setFormFields({ ...formFields, ProjectUsers: userID });
        setUserPermission(value)
        setprevProjectUser(value);
    }
    //-------------------------------Read / write Status Change----------------------------------------------------------
    const handleActiveStatusChange = (userId, Permission) => {
        const updatedUserList = [...isReadWrite];
        const existingIndex = isReadWrite.findIndex((item) => item.userId === userId);
        if (existingIndex !== -1) {
            updatedUserList[existingIndex].permission = Permission
        }
        setisReadWrite(updatedUserList);
        setFormFields({ ...formFields, ProjectUsers: updatedUserList })
    };
    //------------------------------------------------Update Project-----------------------------------------------------
    const handleUpdateProject = () => {
        const newErrors = {};

        if (!formFields.projectName || !formFields.projectName.trim()) {
            newErrors.projectName = 'Project name is required';
        }
        if (formFields.projectName && formFields.projectName.length >= maxLength) {
            newErrors.projectName = `Project name cannot exceed ${maxLength} characters.`;
        }
        if (!formFields.projectManagerUserId || !formFields.projectManagerUserId.trim()) {
            newErrors.projectManagerUserId = 'Project manager is required';
        }
        if (!formFields.startDate || !formFields.startDate.trim()) {
            newErrors.startDate = 'Start date is required';
        }
        if (!formFields.endDate || !formFields.endDate.trim()) {
            newErrors.endDate = 'End date is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.UpdateProjectAction(formFields)
        }
    }
    //-------------------------------------------------------------------------------------------------------------------------
    const [isFlexibleDueDates, setIsFlexibleDueDates] = useState(false);
    const handleFlexibleDueDatesChange = () => {
        const newIsFlexibleDueDates = !isFlexibleDueDates;
        setIsFlexibleDueDates(newIsFlexibleDueDates);
        setFormFields({ ...formFields, allowFlexibleDueDates: newIsFlexibleDueDates });
    };


    const [isTimesheetCreate, setIsTimesheetCrete] = useState(false);
    const handleAllowTimesheetCreate = () => {
        const newTimesheetCreate = !isTimesheetCreate;
        setIsTimesheetCrete(newTimesheetCreate);
        setFormFields({ ...formFields, allowTimesheetCreate: newTimesheetCreate });
    };

    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>
                                Project Name <span className="text-danger"> *</span>
                            </Label>
                            <Input
                                type="text"
                                name="projectName"
                                placeholder="Enter project name"
                                onChange={validateOnChange}
                                value={formFields && formFields.projectName}
                                invalid={errors && errors.projectName}
                                maxLength={maxLength}
                            />
                            <FormFeedback>{errors && errors.projectName}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>
                                Project Manager<span className="text-danger"> *</span>
                            </Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.projectManagerUserId) ? 'red' : "silver"
                                    })
                                }}
                                name="UserId"
                                placeholder="Select project manager"
                                isSearchable
                                defaultOptions
                                loadOptions={handlemanager}
                                value={prevProjectManagerUser}
                                onChange={handleEditProjectManagerUser}
                            // isOptionDisabled={(option) =>
                            //     formFields?.ProjectUsers?.some(user => user.userId === option.value)
                            // }
                            />
                            <FormText sx={{ marginLeft: "10px" }}>
                                <div className="text-danger">
                                    {errors && errors.projectManagerUserId}
                                </div>
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Start Date <span className="text-danger"> *</span></Label>
                            <Input
                                type="date"
                                name="startDate"
                                placeholder="Select start date"
                                onChange={validateOnChangeDate}
                                value={moment(formFields.startDate).format("YYYY-MM-DD")}
                                max={moment(formFields.endDate).format("YYYY-MM-DD")}
                                invalid={errors && errors.startDate}
                            /> <FormFeedback>{errors && errors.startDate}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>End Date <span className="text-danger"> *</span></Label>
                            <Input
                                type="date"
                                name="endDate"
                                placeholder="Select end date"
                                onChange={validateOnChangeDate}
                                value={moment(formFields.endDate).format("YYYY-MM-DD")}
                                min={moment(formFields.startDate).format("YYYY-MM-DD")}
                                invalid={errors && errors.endDate}
                            /> <FormFeedback>{errors && errors.endDate}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>
                                Project Client
                            </Label>
                            <Input
                                type="text"
                                name="client"
                                placeholder="Enter project client"
                                onChange={validateOnChange}
                                value={formFields && formFields.client}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>
                                Project Description
                            </Label>
                            <Input
                                type="textarea"
                                name="description"
                                placeholder="Enter project description"
                                onChange={validateOnChange}
                                value={formFields && formFields.description}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className='align-items-center'>
                    <Col md={6}>
                        <FormGroup>
                            <Label>
                                Project Users
                            </Label>
                            <Async
                                name="ProjectUsers"
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 30,
                                    }),
                                }}
                                placeholder="Select project users"
                                //   className="vm-react-select"
                                isSearchable
                                defaultOptions
                                loadOptions={handleUser}
                                isMulti
                                onChange={handleEditProjectUser}
                                value={prevProjectUser}
                                isOptionDisabled={(option) => option.value === formFields.projectManagerUserId}
                            />
                            <FormText>
                                <strong>NOTE</strong>: <strong>Project Admins</strong> are always have <strong>write</strong> access. You can't remove or modify their permissions.
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label>
                                <Input
                                    className='me-2'
                                    type="checkbox"
                                    name="allowTimesheetCreate"
                                    // placeholder="Enter Comments"
                                    onChange={validateOnChange}
                                    onClick={handleAllowTimesheetCreate}
                                    checked={formFields && formFields.allowTimesheetCreate}
                                >
                                </Input>
                                Allow Timesheets Autofill
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label>
                                <Input
                                    className='me-2'
                                    type="checkbox"
                                    name="allowFlexibleDueDates"
                                    onClick={handleFlexibleDueDatesChange}
                                    checked={formFields && formFields.allowFlexibleDueDates}
                                >
                                </Input>
                                Allow Flexible Due Date
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                {UserPermission && UserPermission.length > 0 ?
                    <Row>
                        <Col md={6}>
                            <Table className='table-width' responsive borderless >
                                <thead>
                                    <tr>
                                        <th>Project User</th>
                                        <th style={{ minWidth: "150px" }}>Permission</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {UserPermission && UserPermission.map((item, index) => (
                                        <tr key={index}>
                                            {(index % 2 == 0) ? (
                                                <>
                                                    <td>
                                                        {item.assignedUserName}<br />
                                                        {item.isGlobalReadOnlyUser ?
                                                            "" :
                                                            <small>{item.assignedUserEmail}</small>
                                                        }
                                                    </td>
                                                    <td style={{ minWidth: "150px" }}>
                                                        <FormGroup>
                                                            <ul className="forms-unstyled-list form-status-list">
                                                                <li
                                                                    onClick={() => { !item.isUserAdmin && handleActiveStatusChange(item.value, "Read") }}
                                                                    className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Read")).length > 0 ? 'active' : '') : ""}`}
                                                                >
                                                                    Read
                                                                </li>
                                                                <li
                                                                    onClick={() => handleActiveStatusChange(item.value, "Write")}
                                                                    className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Write")).length > 0 ? 'active' : '') : ""}`}
                                                                >
                                                                    Write
                                                                </li>
                                                            </ul>
                                                        </FormGroup>
                                                    </td>
                                                </>
                                            ) : ''}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        {formFields.ProjectUsers && formFields.ProjectUsers.length > 1 ?
                            <Col md={6}>
                                <Table className='table-width' responsive borderless>
                                    <thead>
                                        <tr>
                                            <th>Project User</th>
                                            <th style={{ minWidth: "150px" }}>Permission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {UserPermission && UserPermission.map((item, index) => (
                                            <tr key={index}>
                                                {(index % 2 == 1) ? (
                                                    <>
                                                        <td>
                                                            {item.assignedUserName}<br />
                                                            {item.isGlobalReadOnlyUser ?
                                                                "" :
                                                                <small>{item.assignedUserEmail}</small>
                                                            }
                                                        </td>
                                                        <td style={{ minWidth: "150px" }}>
                                                            <FormGroup>
                                                                <ul className="forms-unstyled-list form-status-list">
                                                                    <li
                                                                        onClick={() => { !item.isUserAdmin && handleActiveStatusChange(item.value, "Read") }}
                                                                        className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Read")).length > 0 ? 'active' : '') : ""}`}
                                                                    >
                                                                        Read
                                                                    </li>
                                                                    <li
                                                                        onClick={() => handleActiveStatusChange(item.value, "Write")}
                                                                        className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Write")).length > 0 ? 'active' : '') : ""}`}
                                                                    >
                                                                        Write
                                                                    </li>
                                                                </ul>
                                                            </FormGroup>
                                                        </td>
                                                    </>
                                                ) : ''}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col> : ""}
                    </Row>
                    : ""}
                <br></br>
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleUpdateProject}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isPending: state.ProjectPortal.isUpdateProjectPending,
    errorMessage: state.ProjectPortal.updateProjectErrorMessage,
    projectDetails: state.ProjectPortal.ProjectDetails,
    currentProfile: state.login.currentProfile
});
const mapDispatchToProps = (dispatch) => ({
    getAdminUserNameAsyncSelectAction: (searchValue, callback) => dispatch(getAdminUserNameAsyncSelect(searchValue, callback)),
    getUserNameAsyncSelectAction: (appId,searchValue, callback) => dispatch(getUserNameAsyncSelect(appId,searchValue, callback)),
    UpdateProjectAction: (formFields) => dispatch(UpdateProject(formFields)),
    updateProjectSuccess: () => dispatch(updateProjectSuccess()),
    getManagerUserNameAsyncSelectAction: (appId,searchValue, callback) => dispatch(getManagerUserNameAsyncSelectAction(appId,searchValue, callback)),

});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateProjects)