
import {
  CLEAR_UPDATE_ORGANISATION_ERROR,
  CLOSE_EDIT_ORGANISATION_MODAL,
  GET_ORGANISATION_DETAILS_PENDING,
  GET_ORGANISATION_DETAILS_SUCCESS,
  OPEN_EDIT_ORGANISATION_MODAL,
  SET_ORGANISATION_DETAILS,
  SET_ORGANISATION_DETAILS_INITIAL_STATE,
  UPDATE_ORGANISATION_ERROR,
  UPDATE_ORGANISATION_PENDING,
  UPDATE_ORGANISATION_SUCCESS,
} from "../organisationDetails/actionTypes";

const initialState = {
  isGetOrganisationDetailsPending: false,
  organisation: null,
  updateOrganisationErrorMessage: null,
  isUpdateOrganisationPending: false,
  isEditOrganisationModalOpen: false
};

export default (
  localState = initialState,
  action: Action
)=> {
  switch (action.type) {
    case SET_ORGANISATION_DETAILS_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case SET_ORGANISATION_DETAILS: {
      return {
        ...localState,
        organisation: action.payload,
      };
    }
    case GET_ORGANISATION_DETAILS_PENDING: {
      return {
        ...localState,
        isGetOrganisationDetailsPending: true,
      };
    }
    case GET_ORGANISATION_DETAILS_SUCCESS: {
      return {
        ...localState,
        isGetOrganisationDetailsPending: false,
      };
    }
    case OPEN_EDIT_ORGANISATION_MODAL: {
      return {
        ...localState,
        isEditOrganisationModalOpen: true,
      };
    }
    case CLOSE_EDIT_ORGANISATION_MODAL: {
      return {
        ...localState,
        isEditOrganisationModalOpen: false,
        updateOrganisationErrorMessage: null
      };
    }
    case UPDATE_ORGANISATION_PENDING: {
      return {
        ...localState,
        isUpdateOrganisationPending: true,
      };
    }
    case UPDATE_ORGANISATION_SUCCESS: {
      return {
        ...localState,
        isUpdateOrganisationPending: false,
      };
    }
    case UPDATE_ORGANISATION_ERROR: {
      const { errorMessage  } = action.payload;
      return {
        ...localState,
        updateOrganisationErrorMessage: errorMessage, 
        isUpdateOrganisationPending: false,
      };
    }
    case CLEAR_UPDATE_ORGANISATION_ERROR: {
      return {
        ...localState,
        updateOrganisationErrorMessage: null,
        isUpdateOrganisationPending: false,
      };
    }
    default: {
      return localState;
    }
  }
};
