import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  FORM_LOGIN,
  SET_CURRENT_PROFILE,
  LOGIN_USER_SUCCESS,
  USER_LOGOUT,
  SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
  FORGOT_PASSWORD_FORM,
  RESET_PASSWORD_FORM,
  FORM_REGISTER,
  LOGIN_ERROR,
  SET_IS_LOADING,
  LOGIN_PENDING,
  SET_LOGIN_ERROR,
  SET_CURRENT_PROFILE_PENDING,
  CLEAR_CURRENT_PROFILE_LIST_ERROR,
  SET_CURRENT_PROFILE_ERROR,
  LOGIN_PENDING_ERROR,
  CLEAR_LOGIN_ERROR,
  ENABLE_USER_ERROR,
  CLEAR_ENABLE_USER_ERROR,
  ENABLE_USER_PENDING,
  ENABLE_USER_PENDING_SUCCESS,
  CLOSE_CHANGE_PASSWORD_MODAL,
  OPEN_CHANGE_PASSWORD_MODAL,
  SET_CHANGE_PASSWORD_ERROR,
  CLEAR_CHANGE_PASSWORD_ERRORS,
  SET_PATH,
  CLEAR_SET_PATH,
  ENABLE_USER_ACCOUNT_PENDING_SUCCESS,
  ENABLE_USER_ACCOUNT_PENDING,
  DISABLE_USER_ACCOUNT_PENDING,
  DISABLE_USER_ACCOUNT_SUCCESS,
  RECOVER_PENDING,
  RECOVER_SUCCESS,
  DISABLE_RESET_LINK_SUCCESS,
  DISABLE_RESET_LINK_PENDING,
  DISABLE_FORGOT_PASSWORD_SUCCESS,
  DISABLE_FORGOT_PASSWORD_PENDING,
  SET_FORGOT_PASSWORD_SUCCESS,
  CLEAR_FORGOT_PASSWORD_CONFIRMATION_STATUS,
  CLEAR_FORGOT_PASSWORD_SUCCESS,
  SET_LOGIN_PATH,
  CLEAR_SET_LOGIN_PATH,
  OPEN_DISPLAY_PICTURE_MODAL,
  CLOSE_DISPLAY_PICTURE_MODAL,
  CLEAR_UPDATE_PROFILE_DETAILS_ERROR,
  UPDATE_PROFILE_DETAILS_PENDING,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_ERROR,
  IS_CHANGE_PASSWORD_PENDING,
  IS_CHANGE_PASSWORD_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  form: {},
  currentProfile: {},
  isAuth: false,
  token: null,
  expires: null,
  email: null,
  isPending: false,
  errorMessage: null,
  confirmationStatus: '',
  formforgot: {},
  formRegister: {},
  isloading: true,
  isCurrentProfilePending: false,
  currentProfileErrorMessage: null,
  ErrorMessage: null,
  isPendingSuccess: false,
  isOpenChangePassword: false,
  changePasswordErrorMessage: false,
  path: null,
  isenableUserPendingSuccess: false,
  isDisableAccount: false,
  isRecoverSuccess: false,
  isDisableResetLink: false,
  isDisableForgotPassword: false,
  successStatus: null,
  loginPath: null,
  isOpenDisplayPicture: false,
  updateProfileDetailsErrorMessage: null,
  isUpdateProfileDetailsPending: false,
  changePasswordPending: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    // case USER_LOGOUT:
    //   state = { ...state, isUserLogout: false };
    //   break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      };
      break;
    case FORM_LOGIN: {
      const { form } = action.payload;
      // console.log(form);
      return {
        ...state,
        /* eslint-disable object-shorthand */
        form: form,
      }
    }
    case FORGOT_PASSWORD_FORM: {
      const { formforgot } = action.payload;
      // console.log(formforgot);
      return {
        ...state,
        /* eslint-disable object-shorthand */
        formforgot: formforgot,
      }
    }
    case RESET_PASSWORD_FORM: {
      const { formReset } = action.payload;
      // console.log(formReset);
      return {
        ...state,
        /* eslint-disable object-shorthand */
        formReset: formReset,
      }
    }
    case FORM_REGISTER: {
      const { formRegister } = action.payload;
      // console.log(formRegister);
      return {
        ...state,
        /* eslint-disable object-shorthand */
        formRegister: formRegister,
      }
    }
    case SET_CURRENT_PROFILE: {
      const { currentProfile } = action.payload;
      // console.log("currentProfile", currentProfile)
      return {
        ...state,
        /* eslint-disable object-shorthand */
        currentProfile: currentProfile,
        isPending: false,

      };
    }
    case SET_FORGOT_PASSWORD_CONFIRMATION_STATUS: {
      const { confirmationStatus } = action.payload;
      return {
        ...state,
        confirmationStatus: confirmationStatus,
        isDisableResetLink: false
      }
    }
    case CLEAR_FORGOT_PASSWORD_CONFIRMATION_STATUS: {
      return {
        ...state,
        confirmationStatus: null,
      }
    }

    case CLEAR_FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        successStatus: null,
      }
    }

    case SET_FORGOT_PASSWORD_SUCCESS: {
      const { successStatus } = action.payload;
      return {
        ...state,
        successStatus: successStatus,
      }
    }

    case LOGIN_USER_SUCCESS: {
      const { email, token, expires } = action.payload;
      return {
        ...state,
        email,
        token,
        expires,
        isAuth: true,
        errorMessage: null,
        currentProfileErrorMessage: null,
        isPending: false,
      };
    }

    case LOGIN_ERROR: {
      const { errorMessage } = action.payload;
      console.log(errorMessage)
      return {
        ...state,
        currentProfileErrorMessage: errorMessage,
        isPending: false
      };
    }
    case CLEAR_CHANGE_PASSWORD_ERRORS: {
      return {
        ...state,
        changePasswordErrorMessage: null,
      };
    }
    case IS_CHANGE_PASSWORD_PENDING: {
      return {
        ...state,
        changePasswordPending: true,
      };
    }
    case IS_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordPending: false,
      };
    }
    case SET_PATH: {
      const { path } = action.payload;
      return {
        ...state,
        path: path,
      };
    }
    case SET_LOGIN_PATH: {
      const { path } = action.payload;
      return {
        ...state,
        loginPath: path,
      };
    }
    case CLEAR_SET_LOGIN_PATH: {
      return {
        ...state,
        loginPath: null,
      };
    }
    case CLEAR_SET_PATH: {
      return {
        ...state,
        path: null,
      };
    }

    // Step 1
    case LOGIN_PENDING: {
      return {
        ...state,
        isPending: true,

      };
    }
    // Step 3 
    case LOGIN_PENDING_ERROR: {
      return {
        ...state,
        isPending: false,
        // currentProfileErrorMessage: null
      };
    }
    // Step 2
    case SET_CURRENT_PROFILE_ERROR:
      const { errorMessage } = action.payload;
      console.log(errorMessage)
      return {
        ...state,
        currentProfileErrorMessage: errorMessage,
      };
    case ENABLE_USER_ERROR:
      const { ErrorMessage } = action.payload;
      console.log(ErrorMessage)
      return {
        ...state,
        ErrorMessage: ErrorMessage,
        isDisableAccount: false,
        isDisableForgotPassword: false,
      };
    case SET_CHANGE_PASSWORD_ERROR:
      const { changePasswordErrorMessage } = action.payload;
      console.log(changePasswordErrorMessage)
      return {
        ...state,
        changePasswordErrorMessage: changePasswordErrorMessage,
      };
    case CLEAR_ENABLE_USER_ERROR:
      return {
        ...state,
        ErrorMessage: null,
      };
    case ENABLE_USER_PENDING:
      return {
        ...state,
        isPendingSuccess: false,
      };
    case RECOVER_PENDING:
      return {
        ...state,
        isRecoverSuccess: false,
      };
    case RECOVER_SUCCESS:
      return {
        ...state,
        isRecoverSuccess: true,
      };
    case ENABLE_USER_ACCOUNT_PENDING:
      return {
        ...state,
        isenableUserPendingSuccess: false,
      };
    case ENABLE_USER_PENDING_SUCCESS:
      return {
        ...state,
        isPendingSuccess: true,
      };
    case ENABLE_USER_ACCOUNT_PENDING_SUCCESS:
      return {
        ...state,
        isenableUserPendingSuccess: true,
      };

    // case SET_CURRENT_PROFILE_PENDING:
    //   return {
    //     ...state,
    //     isPending: true,
    //     currentProfileErrorMessage: null
    //   };

    case CLEAR_CURRENT_PROFILE_LIST_ERROR: {
      return {
        ...state,
        currentProfileErrorMessage: null,
      }
    }
    case CLEAR_LOGIN_ERROR: {
      return {
        ...state,
        currentProfileErrorMessage: null,
      }
    }
    case OPEN_CHANGE_PASSWORD_MODAL: {
      return {
        ...state,
        isOpenChangePassword: true,
      }
    }
    case OPEN_DISPLAY_PICTURE_MODAL: {
      return {
        ...state,
        isOpenDisplayPicture: true,
      }
    }
    case CLOSE_DISPLAY_PICTURE_MODAL: {
      return {
        ...state,
        isOpenDisplayPicture: false,
      }
    }
    case CLOSE_CHANGE_PASSWORD_MODAL: {
      return {
        ...state,
        isOpenChangePassword: false,
      }
    }
    case DISABLE_USER_ACCOUNT_PENDING: {
      return {
        ...state,
        isDisableAccount: true,
      }
    }
    case DISABLE_USER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isDisableAccount: false,
      }
    }
    case DISABLE_RESET_LINK_SUCCESS: {
      return {
        ...state,
        isDisableResetLink: false,
      }
    }
    case DISABLE_RESET_LINK_PENDING: {
      return {
        ...state,
        isDisableResetLink: true,
      }
    }
    case DISABLE_FORGOT_PASSWORD_PENDING: {
      return {
        ...state,
        isDisableForgotPassword: true,
      }
    }
    case DISABLE_FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        isDisableForgotPassword: false,
      }
    }
    case CLEAR_UPDATE_PROFILE_DETAILS_ERROR: {
      return {
        ...state,
        updateProfileDetailsErrorMessage: null,
        isUpdateProfileDetailsPending: false,
      };
    }
    case UPDATE_PROFILE_DETAILS_PENDING: {
      return {
        ...state,
        isUpdateProfileDetailsPending: true,
      };
    }
    case UPDATE_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        isUpdateProfileDetailsPending: false,
      };
    }
    case UPDATE_PROFILE_DETAILS_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        updateProfileDetailsErrorMessage: errorMessage,
        isUpdateProfileDetailsPending: false,
      };
    }
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
