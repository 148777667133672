import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { closeEditEmployeeModal, getEmployeeDetailsAction, openEditEmployeeModal } from '../../../../store/Modules/Employees/actions';
import moment from "moment";
import EditEmployee from './EditEmployee';
import VWModel from '../../../../components/Modal/VWModal';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSkeleton from '../../../../components/vwSkeleton/vwSkeleton';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';

const EmployeeDetails = (props) => {
    const params = useParams();
    const { id } = params;
    // console.log("props.EmployeeDetails: ", props.EmployeeDetails)
    const { employeeDisplayName, employeeFirstName, employeeLastName,
        employeeId, employeeTitle, employeeType, dateOfBirth,
        dateOfJoin, dateOfTermination, isActive, gender,
        isRehired, terminationReason, roleDetails,
        isTerminated, enableMailNotification, isUserActive,
        createdDateTime, updatedBy, updatedDateTime, userEmail, orgName, isPeopleManager, managerUserEmail, isPortalEnable } = props && props.EmployeeDetails || {};

    useEffect(() => {
        props.getEmployeeDetails(id);
        props.ClearPath();
    }, [props.getEmployeeDetails, id]);

    useEffect(() => {
        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();
        }
    }, []);
    console.log("id:", id);

    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditEmployeeModel = () => {
        const { openEditEmployeeModalAction } = props;
        openEditEmployeeModalAction();
    };

    const handleCloseEditEmployeeModal = () => {
        const { closeEditEmployeeModalAction } = props;
        closeEditEmployeeModalAction();
    };
    //--------------------------------------------------------------------------------------------------------------
    const Roles = props.currentProfile && props.currentProfile.roles && props.currentProfile.roles.map((data) => (
        data.roleName
    ))
    const { userAppDetails } = props && props.currentProfile || {};

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>EMPLOYEE DETAILS</strong>
                        {
                            !props.errorMessage && userAppDetails && userAppDetails.filter(item => item.appName.toLowerCase() === "employee").length > 0 && (
                                userAppDetails
                                    .filter(userApp => userApp.appName.toLowerCase() === "employee")
                                    .map(userApp => {
                                        const adminRoles = userApp.appRoleDetails.filter(role => {
                                            const roleName = role.roleName.toLowerCase();
                                            return roleName;
                                        });
                                        const isAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
                                        return (
                                            isAdmin ? (
                                                <Link
                                                    type="button"
                                                    className="btn btn-info"
                                                    onClick={handleOpenEditEmployeeModel}
                                                >
                                                    EDIT
                                                </Link>
                                            ) : null
                                        );
                                    })
                            )
                        }
                    </CardTitle>
                    <CardBody className="p-4">
                        {props.errorMessage ? <div className='w-100'>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        {props.isGetEmployeeDetailsPending ?
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                            :
                            <Row className="justify-content-center">
                                <div>
                                    <div className="p-3 bg-light fw-bold fs-4">
                                        <span className='text-capitalize'>{employeeDisplayName || "N/A"}</span>
                                    </div>
                                </div>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover" >
                                                    <tbody>
                                                        <tr style={{ width: '200px' }}>
                                                            <td >
                                                                <strong>First Name</strong>
                                                            </td>
                                                            <td>{employeeFirstName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Last Name</strong>
                                                            </td>
                                                            <td>{employeeLastName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Display Name</strong>
                                                            </td>
                                                            <td>{employeeDisplayName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>User Email</strong>
                                                            </td>
                                                            <td>{userEmail || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Manager Email</strong>
                                                            </td>
                                                            <td>{managerUserEmail || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Organization Name</strong>
                                                            </td>
                                                            <td>{orgName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Employee ID</strong>
                                                            </td>
                                                            <td>{employeeId || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Designation</strong>
                                                            </td>
                                                            <td>{employeeTitle || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Type</strong>
                                                            </td>
                                                            <td>{employeeType || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Gender</strong>
                                                            </td>
                                                            <td>{gender || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>User Status</strong>
                                                            </td>
                                                            <td>
                                                                {isUserActive ? (
                                                                    <Badge color="success" className="text-dark-white">
                                                                        Active
                                                                    </Badge>
                                                                ) : (
                                                                    <Badge color="danger" className="text-dark-white">
                                                                        Inactive
                                                                    </Badge>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Is Rehired</strong>
                                                            </td>
                                                            <td>
                                                                {isRehired ? (
                                                                    <Badge color="success" className="text-dark-white">
                                                                        Yes
                                                                    </Badge>
                                                                ) : (
                                                                    <Badge color="danger" className="text-dark-white">
                                                                        No
                                                                    </Badge>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Date Of Birth</strong>
                                                            </td>
                                                            <td>
                                                                {dateOfBirth ? moment(dateOfBirth).format('DD MMM YYYY',) : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Date Of Joining</strong>
                                                            </td>
                                                            <td>
                                                                {dateOfJoin ? moment(dateOfJoin).format('DD MMM YYYY',) : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        {dateOfTermination ?
                                                            <tr>
                                                                <td>
                                                                    <strong>Date Of Termination</strong>
                                                                </td>
                                                                <td>
                                                                    {dateOfTermination ? moment(dateOfTermination).format('DD MMM YYYY',) : 'N/A'}
                                                                </td>
                                                            </tr>
                                                            : ''}
                                                        {terminationReason ?
                                                            <tr>
                                                                <td>
                                                                    <strong>Termination Reason</strong>
                                                                </td>
                                                                <td>
                                                                    {terminationReason || "N/A"}
                                                                </td>
                                                            </tr>
                                                            : ''}
                                                        <tr>
                                                            <td>
                                                                <strong>Created Date</strong>
                                                            </td>
                                                            <td>
                                                                {createdDateTime ? moment(createdDateTime).format('DD MMM YYYY',) : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Updated By</strong>
                                                            </td>
                                                            <td>
                                                                {updatedBy || "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Updated Date</strong>
                                                            </td>
                                                            <td>
                                                                {updatedDateTime ? moment(updatedDateTime).format('DD MMM YYYY',) : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Is People Manager</strong>
                                                            </td>
                                                            <td>
                                                                {isPeopleManager ? (
                                                                    <Badge color="success" className="text-dark-white">
                                                                        Yes
                                                                    </Badge>
                                                                ) : (
                                                                    <Badge color="danger" className="text-dark-white">
                                                                        No
                                                                    </Badge>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Account Status</strong>
                                                            </td>
                                                            <td>
                                                                {isPortalEnable ? (
                                                                    <Badge color="success" className="text-dark-white">
                                                                        Active
                                                                    </Badge>
                                                                ) : (
                                                                    <Badge color="danger" className="text-dark-white">
                                                                        Inactive
                                                                    </Badge>

                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Employee Status</strong>
                                                            </td>
                                                            <td>
                                                                {isTerminated ? (
                                                                    <Badge color="danger" className="text-dark-white">
                                                                        Terminated
                                                                    </Badge>
                                                                ) : (
                                                                    <Badge color="success" className="text-dark-white">
                                                                        Active
                                                                    </Badge>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-nowrap">
                                                                <strong>Email Notification Status</strong>
                                                            </td>
                                                            <td>
                                                                {enableMailNotification ? (
                                                                    <Badge color="success" className="text-dark-white">
                                                                        Enabled
                                                                    </Badge>
                                                                ) : (
                                                                    <Badge color="danger" className="text-dark-white">
                                                                        Disabled
                                                                    </Badge>)}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                    <VWModel
                        header="EDIT EMPLOYEE"
                        modalSize="large"
                        isOpen={props.isEditEmployeeModalOpen}
                        handleClose={handleCloseEditEmployeeModal}
                    >
                        <EditEmployee handleClose={handleCloseEditEmployeeModal} />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    EmployeeDetails: state.Employee.EmployeeDetails,
    errorMessage: state.Employee.employeeDetailsError,
    isEditEmployeeModalOpen: state.Employee.isEditEmployeeModalOpen,
    currentProfile: state.login.currentProfile,
    isGetEmployeeDetailsPending: state.Employee.isGetEmployeeDetailsPending
});

const mapDispatchToProps = (dispatch) => ({
    getEmployeeDetails: (Id) => dispatch(getEmployeeDetailsAction(Id)),
    openEditEmployeeModalAction: () => dispatch(openEditEmployeeModal()),
    closeEditEmployeeModalAction: () => dispatch(closeEditEmployeeModal()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails)