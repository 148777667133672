import { CLEAR_CREATE_ADMIN_USER_ERROR, CLEAR_UPDATE_ADMIN_USER_ERROR, CLOSE_CREATE_ADMIN_USER_MODAL, CLOSE_UPDATE_ADMIN_USER_MODAL, CREATE_ADMIN_USER_ERROR, CREATE_ADMIN_USER_PENDING, CREATE_ADMIN_USER_SUCCESS, OPEN_CREATE_ADMIN_USER_MODAL, OPEN_UPDATE_ADMIN_USER_MODAL, SET_ADMIN_USER_LIST, UPDATE_ADMIN_USER_ERROR, UPDATE_ADMIN_USER_PENDING, UPDATE_ADMIN_USER_SUCCESS } from "./constants";


const initialState = {
    errorMessage: null,
    isPending: false,
    isCreateAdminUserModalOpen: false,
    isUpdateAdminUserModalOpen: false,
    AdminUserList: [],
    totalRecords: 0,
    updateAdminUserErrorMessage: null,
    isUpdateAdminUserPending: false,
};
export default (localState = initialState, action) => {
    switch (action.type) {

        case OPEN_CREATE_ADMIN_USER_MODAL: {
            return {
                ...localState,
                isCreateAdminUserModalOpen: true,
            };
        }
        case OPEN_UPDATE_ADMIN_USER_MODAL: {
            return {
                ...localState,
                isUpdateAdminUserModalOpen: true,
            };
        }

        case CLOSE_CREATE_ADMIN_USER_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateAdminUserModalOpen: false,
            };
        }
        case CLOSE_UPDATE_ADMIN_USER_MODAL: {
            return {
                ...localState,
                updateAdminUserErrorMessage: null,
                isUpdateAdminUserModalOpen: false,
            };
        }
        case SET_ADMIN_USER_LIST: {
            const { adminUserList } = action.payload;
            return {
                ...localState,
                AdminUserList: adminUserList && adminUserList.value,
                totalRecords: adminUserList["@odata.count"],
            }
        }
        case CREATE_ADMIN_USER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_ADMIN_USER_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CREATE_ADMIN_USER_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_CREATE_ADMIN_USER_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CLEAR_UPDATE_ADMIN_USER_ERROR: {
            return {
                ...localState,
                updateAdminUserErrorMessage: null,
                isUpdateAdminUserPending: false,
            };
        }
        case UPDATE_ADMIN_USER_PENDING: {
            return {
                ...localState,
                isUpdateAdminUserPending: true,
            };
        }
        case UPDATE_ADMIN_USER_SUCCESS: {
            return {
                ...localState,
                isUpdateAdminUserPending: false,
            };
        }
        case UPDATE_ADMIN_USER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateAdminUserErrorMessage: errorMessage,
                isUpdateAdminUserPending: false,
            };
        }
        default: {
            return localState;
        }
    }
};