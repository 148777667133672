import React from "react";
import { Navigate } from "react-router-dom";

// Import Calender
import Calender from "../Pages/Calender";


// Import E-mail
import Inbox from "../Pages/E-mail/Inbox";
import ReadEmail from "../Pages/E-mail/ReadEmail";
import EmailCompose from "../Pages/E-mail/EmailCompose";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
import LockScreen from "../Pages/AuthenticationPages/LockScreen";

// Import Utility Pages
import StarterPage from "../Pages/Utility/Starter-Page";
import Maintenance from "../Pages/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Utility/ComingSoon-Page";
import TimeLine from "../Pages/Utility/TimeLine-Page";
import FAQs from "../Pages/Utility/FAQs-Page";
import Pricing from "../Pages/Utility/Pricing-Page";
import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";

// Import UIElement Pages
import UiAlerts from "../Pages/UiElements/UiAlerts";
import UiBadge from "../Pages/UiElements/UiBadge";
import UiBreadcrumb from "../Pages/UiElements/UiBreadcrumb";
import UiButtons from "../Pages/UiElements/UiButtons";
import UiCards from "../Pages/UiElements/UiCards";
import UiCarousel from "../Pages/UiElements/UiCarousel";
import UiDropdown from "../Pages/UiElements/UiDropdowns";
import UiGrid from "../Pages/UiElements/UiGrid";
import UiImages from "../Pages/UiElements/UiImages";
import UiLightbox from "../Pages/UiElements/UiLightbox";
import UiModals from "../Pages/UiElements/UiModals";
import UiOffcanvas from "../Pages/UiElements/UiOffcanvas";
import UiRangeSlider from "../Pages/UiElements/UiRangeSlider";
import UiSessionTimeout from "../Pages/UiElements/UiSessionTimeout";
import UiPagination from "../Pages/UiElements/UiPagination";
import UiProgressBars from "../Pages/UiElements/UiProgressBars";
import UiPlaceholders from "../Pages/UiElements/UiPlaceholders";
import UiTabs from "../Pages/UiElements/UiTabs&Accordions";
import UiTypography from "../Pages/UiElements/UiTypography";
import UiToasts from "../Pages/UiElements/UiToasts";
import UiVideo from "../Pages/UiElements/UiVideo";
import UiPopovers from "../Pages/UiElements/UiPopovers&Tooltips";
import UiRating from "../Pages/UiElements/UiRating";

// Import Forms
import FormEditors from "../Pages/Forms/FormEditors";
import FormUpload from "../Pages/Forms/FormUpload";
import FormXeditable from "../Pages/Forms/FormXeditable";
import FormMask from "../Pages/Forms/FormMask";
import FormElements from "../Pages/Forms/FormElements";
import FormAdvanced from "../Pages/Forms/FormAdvanced";
import FormValidations from "../Pages/Forms/FormValidations";
import FormWizard from "../Pages/Forms/FormWizard";

// Import Tables
import BasicTable from "../Pages/Tables/BasicTable.js";
import ListJs from "../Pages/Tables/ListTables/ListTables";
import DataTable from "../Pages/Tables/DataTables/DataTables";


// Import Charts
import ApexCharts from "../Pages/Charts/ApexCharts";
import ChartJs from "../Pages/Charts/ChartjsCharts";
import Sparklinechart from "../Pages/Charts/SparklineCharts";
import FloatChart from "../Pages/Charts/FloatCharts";
import JknobCharts from "../Pages/Charts/JqueryKnobCharts";

// Import Icon Pages
import IconMaterialdesign from "../Pages/Icons/IconMaterialdesign";
import IconFontawesome from "../Pages/Icons/IconFontAwesome";
import IconDripicons from "../Pages/Icons/IconDrip";
import IconBoxicons from "../Pages/Icons/IconBoxicons"

// Import Map Pages
import VectorMaps from "../Pages/Maps/VectorMap";
import GoogleMap from "../Pages/Maps/GoogleMap";
import CreateEmployee from "../Pages/Modules/Employees/Employee/CreateEmployee.js"
import EditEmployee from "../Pages/Modules/Employees/Employee/EditEmployee.js"
import Recover from "../Pages/Authentication/Recover";
import ResetPassword from "../Pages/AuthenticationPages/ResetPassword";
import EmployeeList from "../Pages/Modules/Employees/Employee/EmployeeList.js";
import LeaveTrackerDashboard from "../Pages/Modules/LeaveTracker/Dashboard/Dashboard.js"
import ApplyLeaveDetails from "../Pages/Modules/LeaveTracker/LeaveTracker/leaveDetails.js"
import ProjectDetails from "../Pages/Modules/Projects/Projects/ProjectDetails.js";
import GanttChart from "../Pages/Modules/Projects/GanttChart/GanttChart.js";
import LeaveTracker from "../Pages/Modules/LeaveTracker/LeaveTracker/leaveTracker.js"
import LeaveReport from "../Pages/Modules/LeaveTracker/LeaveTrackerReports/LeaveReports.js"
import LeaveType from "../Pages/Modules/LeaveTracker/LeaveType/leaveType.js"
import LeaveMaster from "../Pages/Modules/LeaveTracker/LeaveMaster/leaveMaster.js";
import LeaveMasterDetails from "../Pages/Modules/LeaveTracker/LeaveMaster/leaveMasterDetails.js"
import LeaveTypeDetails from "../Pages/Modules/LeaveTracker/LeaveType/leaveTypeDetails.js"
import HolidayMasterList from "../Pages/Modules/LeaveTracker/HolidayMaster/holidayMasterList.js";
import HolidayDetails from "../Pages/Modules/LeaveTracker/HolidayMaster/holidayMasterDetails.js";
// import Leaveapplication from "../Pages/Modules/LeaveTracker/leaveApplication";
import SalaryList from "../Pages/Modules/PayRoll/salaryList"
import SalaryCreate from "../Pages/Modules/PayRoll/salaryCreate";
import ProjectsList from "../Pages/Modules/Projects/Projects/ProjectsList.js";
// import CreateProjects from "../Pages/Modules/Projects/Projects/CreateProjects.js";
import TimeSheets from "../Pages/Modules/Timesheets/MyTimesheet/TimeSheets.js";
import TimesheetDashboard from "../Pages/Modules/Timesheets/Dashboard/Dashboard.js"
import MyTeamTimeSheet from "../Pages/Modules/Timesheets/MyTeamTimesheet/MyTeamTimeSheet.js";
import AllTimesheet from "../Pages/Modules/Timesheets/AllTimeSheet/AllTimesheet.js";
// import CreateTimeSheets from "../Pages/Modules/Timesheets/CreateTimeSheets";
import CalenderView from '../Pages/Modules/LeaveTracker/Calender'
import Dashboard from "../Pages/Dashboard/Dashboard";
import TodoList from "../Pages/Modules/Projects/Todo/TodoList";
import CreateTodo from "../Pages/Modules/Projects/Todo/CreateTodo";
import Milestones from "../Pages/Modules/Projects/Milestones/Milestones";
import MilestonesDetails from "../Pages/Modules/Projects/Milestones/MilestonesDetails.js";
import TimeSheetDetails from "../Pages/Modules/Timesheets/MyTimesheet/TimeSheetDetails.js";
import Organization from "../Pages/Modules/Admin/Organization/Organization.js";
import AppMaster from "../Pages/Modules/Admin/AppMaster/AppMaster.js";
import Roles from "../Pages/Modules/Admin/Roles/Role.js";
import Groups from "../Pages/Modules/Admin/UserManagement/Groups/Groups.js";
import GroupDetails from "../Pages/Modules/Admin/UserManagement/Groups/GroupDetails.js";
import AdminUser from "../Pages/Modules/Admin/UserManagement/AdminUser/AdminUser.js";
import AdminUserDetails from "../Pages/Modules/Admin/UserManagement/AdminUser/AdminUserDetails.js";
import OrgUser from "../Pages/Modules/Admin/UserManagement/OrgUser/OrgUser.js";
import OrganizationDetails from "../Pages/Modules/Admin/Organization/OrganizationDetails.js"
import AppMasterDetails from "../Pages/Modules/Admin/AppMaster/AppMasterDetails.js";
import OrgUserDetails from "../Pages/Modules/Admin/UserManagement/OrgUser/OrgUserDetails.js";
import EmployeeDetails from "../Pages/Modules/Employees/Employee/EmployeeDetails.js";
import UserProfile from "../Pages/Modules/UserProfile/UserProfile.js";
import TaskList from "../Pages/Modules/Projects/Task/TaskList.js";
import TaskDetails from "../Pages/Modules/Projects/Task/TaskDetails.js";
import DocsList from "../Pages/Modules/VisionDocs/DocsList.js";
import AddDocs from "../Pages/Modules/VisionDocs/AddDocs.js";
import EditDocs from "../Pages/Modules/VisionDocs/EditDocs.js";
import Home from "../Pages/Modules/MassMailer/Unsubscribe/home.js";
import ContactList from "../Pages/Modules/MassMailer/ContactManagement/contactList.js";
import MMDashboard from "../Pages/Modules/MassMailer/Dashboard/dashboard.js";
import CreateCampaign from "../Pages/Modules/MassMailer/Campaign/CreateCampaign.js";
import CampaignList from "../Pages/Modules/MassMailer/Campaign/CampaignList.js";
import CampaignDetails from "../Pages/Modules/MassMailer/Campaign/CampaignDetails.js";
import ContactDetails from "../Pages/Modules/MassMailer/ContactManagement/contactDetails.js";
import DBSync from "../Pages/Modules/DBSync/DBSync.js";
import MyTeamLeaveDetails from "../Pages/Modules/LeaveTracker/MyTeamLD/MyTeamLeaveDetails.js";
import KanbanView from "../Pages/Modules/Projects/KanbanView.js"
import WeeklyoffDays from "../Pages/Modules/LeaveTracker/weeklyOffDays/weeklyoffDays.js";
import ProjectDashboard from "../Pages/Modules/Projects/Dashboard.js";
import AdminBlankPage from "../Pages/Modules/Admin/AdminBlankPage.js";
import TimesheetApproval from "../Pages/Modules/Timesheets/TimesheetApproval/TimesheetApproval.js";
import CrmEnquiriesList from "../Pages/Modules/Crm/EnquriesList.js";
import CrmEnquiriesDetails from "../Pages/Modules/Crm/EnquiriesDetails.js"
import EnableUser from "../Pages/Authentication/enableUser.js";
import AdminDocs from "../Pages/Modules/Admin/AdminDocs/AdminDocs.js";
import EmployeeDocs from "../Pages/Modules/Employees/EmployeeDocs/EmployeeDocs.js";
import LeaveDocs from "../Pages/Modules/LeaveTracker/LeaveDocs/LeaveDocs.js";
import TimesheetDocs from "../Pages/Modules/Timesheets/TimesheetDocs/TimesheetDocs.js";
import ProjectDocs from "../Pages/Modules/Projects/ProjectDocs/ProjectDocs.js";
import OverAllDocsCreate from "../Pages/Modules/OverAllDocs/OverAllDocsCreate.js";
import OverAllDocsEdit from "../Pages/Modules/OverAllDocs/OverAllDocsEdit.js";
import GeneralTasks from "../Pages/Modules/Projects/GeneralTasks/GeneralTasks.js";
import ProjectReport from "../Pages/Modules/Projects/Report/Report.js";

//CRM-MODULE----------------------
import CrmDashboard from "../Pages/Modules/Crm/Dashboard/dashboard.js";
import CrmAccountList from "../Pages/Modules/Crm/Accoutants/list.js"
import CrmAccountDetail from "../Pages/Modules/Crm/Accoutants/details.js";
import CrmContactsList from "../Pages/Modules/Crm/Contacts/list.js";
import CrmContactsDetail from "../Pages/Modules/Crm/Contacts/details.js";
import CrmLeadsList from "../Pages/Modules/Crm/Leads/list.js";
import CrmLeadsDetail from "../Pages/Modules/Crm/Leads/details.js";
import CrmOpportunityList from "../Pages/Modules/Crm/Opportunity/list.js";
import CrmOpportunityDetail from "../Pages/Modules/Crm/Opportunity/details.js"
import CRMSettings from "../Pages/Modules/Crm/Settings/layout.js";
import LeadCategory from "../Pages/Modules/Crm/Settings/Pages/LeadCategory/leadCategory.js";
import LeadSource from "../Pages/Modules/Crm/Settings/Pages/LeadSource/leadSource.js";
import FollowUpAction from "../Pages/Modules/Crm/Settings/Pages/FollowUpAction/followUpAction.js";
import AccountCategory from "../Pages/Modules/Crm/Settings/Pages/AccountCategory/accountCategory.js";
import Region from "../Pages/Modules/Crm/Settings/Pages/Region/region.js";
import Industry from "../Pages/Modules/Crm/Settings/Pages/Industry/industry.js";
import OpportunityCategory from "../Pages/Modules/Crm/Settings/Pages/OpportunityCategory/opportunityCategory.js";
import OpportunityType from "../Pages/Modules/Crm/Settings/Pages/OpportunityType/opportunityType.js";
import OpportunityActionTime from "../Pages/Modules/Crm/Settings/Pages/OpportunityActionTime/opportunityActionTime.js";
import OpportunityCurrentPhase from "../Pages/Modules/Crm/Settings/Pages/OpportunityCurrentPhase/OpportunityCurrentPhase.js";
import MailSettings from "../Pages/Modules/MassMailer/Settings/MailSetting.js";
import UnsubscribeHome from "../Pages/Modules/MassMailer/Unsubscribe/home.js";
import VisitType from "../Pages/Modules/Crm/Settings/Pages/Visit/visit.js"
import Visit from "../Pages/Modules/Crm/Visit/list.js"
import VisitDetail from "../Pages/Modules/Crm/Visit/details.js"

//------Project Settings --------------------------------------------
import TaskScope from "../Pages/Modules/Projects/Settings/Pages/TaskScope/TaskScope.js"
import TaskType from "../Pages/Modules/Projects/Settings/Pages/TaskType/TaskType.js"
import TaskPercentageStatus from "../Pages/Modules/Projects/Settings/Pages/TaskPercentageStatus/TaskPercentageStatus.js";
import LeadMandatory from "../Pages/Modules/Crm/Settings/Pages/LeadMandatoryFields/LeadMandatory.js";

//-------------------------------------Portal logo---------------------------------------
import PortalLogo from "../Pages/Modules/Admin/PortalManagement/PortalLogo.js";

const authProtectedRoutes = [

  //---------CRM-MODULE-------------------------
  {
    path: '/admin/portal-logo',
    component: <PortalLogo />,
  },
  {
    path: '/crm/dashboard',
    component: <CrmDashboard />,
  },
  {
    path: '/crm/visits',
    component: <Visit />,
  },
  {
    path: '/crm/visits/details/:id',
    component: <VisitDetail />,
  },
  {
    path: '/crm/settings',
    component: <CRMSettings />,
  },
  {
    path: '/crm/accounts/details/:id',
    component: <CrmAccountDetail />,
  },
  {
    path: '/crm/contacts/details/:id',
    component: <CrmContactsDetail />,
  },
  {
    path: '/crm/leads/details/:id',
    component: <CrmLeadsDetail />,
  },
  {
    path: '/crm/enquiries',
    component: <CrmEnquiriesList />,
  },
  {
    path: '/crm/accounts',
    component: <CrmAccountList />,
  },
  {
    path: '/crm/contacts',
    component: <CrmContactsList />,
  },
  {
    path: '/crm/leads',
    component: <CrmLeadsList />,
  },
  {
    path: '/crm/opportunity',
    component: <CrmOpportunityList />,
  },
  {
    path: '/crm/opportunity/details/:Id',
    component: <CrmOpportunityDetail />,
  },
  // {
  //   path: '/crm/Accounts/:id/details',
  //   component: <CrmAccountDetails />,
  // },
  //--------------------------------------------
  {
    path: '/visiondocs/documents',
    component: <DocsList />,
  },
  {
    path: '/dbsync/list',
    component: <DBSync />,
  },
  {
    path: '/visiondocs/documents/edit/:id',
    component: <EditDocs />,
  },
  {
    path: '/visiondocs/documents/add',
    component: <AddDocs />,
  },
  {
    path: '/admin/adminuser/details/:id',
    component: <AdminUserDetails />,
  },
  {
    path: '/admin/orguser/details/:id',
    component: <OrgUserDetails />,
  },
  {
    path: '/admin/orguser',
    component: <OrgUser />,
  },
  {
    path: '/admin/adminuser',
    component: <AdminUser />,
  },
  {
    path: '/admin',
    component: <AdminBlankPage />,
  },
  {
    path: '/admin/organization',
    component: <Organization />,
  },
  { path: '/admin/organization/details/:id', component: <OrganizationDetails /> },
  { path: '/admin/appmaster/details/:id', component: <AppMasterDetails /> },
  { path: '/admin/groups/details/:id', component: <GroupDetails /> },
  {
    path: '/admin/groups',
    component: <Groups />,
  },
  {
    path: '/admin/roles',
    component: <Roles />,
  },
  {
    path: '/admin/documents',
    component: <AdminDocs />,
  },
  {
    path: '/:appName/documents/add',
    component: <OverAllDocsCreate />,
  },
  {
    path: '/:appName/documents/edit/:id/:appid',
    component: <OverAllDocsEdit />,
  },
  {
    path: '/admin/appmaster',
    component: <AppMaster />,
  },
  {
    path: '/employees/add',
    component: <CreateEmployee />,
  },
  {
    path: '/employees/edit',
    component: <EditEmployee />,
  },
  {
    path: '/projectportal/projects', component: <ProjectsList />
  },
  // {
  // path: "/timesheets",
  // children: [
  {
    path: '/timesheets/dashboard', component: <TimesheetDashboard />
  },
  // {
  //   path: "/timesheets/mytimesheet/:id", component: <TimeSheets />
  // },
  {
    path: "/timesheets/mytimesheet", component: <TimeSheets />
  },
  {
    path: "/timesheets/documents", component: <TimesheetDocs />
  },
  {
    path: "/timesheets/timesheetapproval", component: <TimesheetApproval />
  },
  { path: '/timesheets/mytimesheet/details/:id', component: <TimeSheetDetails /> },
  { path: '/employees/employee/details/:id', component: <EmployeeDetails /> },
  {
    path: '/timesheets/myteamtimesheet', component: <MyTeamTimeSheet />
  },
  {
    path: '/timesheets/myteamtimesheet/:id', component: <MyTeamTimeSheet />
  },
  {
    path: '/timesheets/alltimesheet', component: <AllTimesheet />
  },
  // ]
  // },

  { path: '/leavetracker/calender', component: <CalenderView /> },

  { path: '/projectportal/todo', component: <TodoList /> },
  { path: '/projectportal/todo/add', component: <CreateTodo /> },
  {
    path: '/employees/employee', component: <EmployeeList />
  },
  {
    path: '/employees/documents', component: <EmployeeDocs />
  },
  {
    path: '/leavetracker/applyleave', component: <LeaveTracker />
  },
  {
    path: '/leavetracker/report', component: <LeaveReport />
  },
  {
    path: '/leavetracker/documents', component: <LeaveDocs />
  },
  {
    path: '/leavetracker/leavetype', component: <LeaveType />
  },
  {
    path: '/leavetracker/leavetype/details/:id', component: <LeaveTypeDetails />
  },
  {
    path: '/leavetracker/myteamleaves', component: <MyTeamLeaveDetails />
  },
  {
    path: '/leavetracker/weeklyoffdays', component: <WeeklyoffDays />
  },

  // {
  //   path: "/projects/add", component: <CreateProjects />
  // },
  // {
  //   path : "/timesheets/add", component: <CreateTimeSheets/>
  // },
  {
    path: '/leavetracker/leavemaster', component: <LeaveMaster />
  },
  { path: '/leavetracker/leavemaster/details/:id', component: <LeaveMasterDetails /> },
  {
    path: '/leavetracker/dashboard', component: <LeaveTrackerDashboard />
  },

  { path: '/leavetracker/applyleave/details/:id', component: <ApplyLeaveDetails /> },
  { path: '/leavetracker/holidaymaster/details/:id', component: <HolidayDetails /> },

  { path: '/leavetracker/holidaymaster', component: <HolidayMasterList /> },

  //---------------PROJECT MODULE-----------------------------------------------------
  { path: '/projectportal/projects/details/:id', component: <ProjectDetails /> },

  {
    path: '/projectportal/milestones', component: <Milestones />
  },
  {
    path: '/projectportal/ganttchart', component: <GanttChart />
  },
  {
    path: '/projectportal/documents', component: <ProjectDocs />
  },
  { path: '/projectportal/milestones/details/:id', component: <MilestonesDetails /> },
  {
    path: '/projectportal/tasks', component: <TaskList />
  },
  { path: '/projectportal/tasks/details/:id', component: <TaskDetails /> },

  { path: '/projectportal/kanbanboard', component: <KanbanView /> },
  { path: '/projectportal/dashboard', component: <ProjectDashboard /> },
  { path: '/projectportal/generaltasks', component: <GeneralTasks /> },
  { path: '/projectportal/reports', component: <ProjectReport /> },
  //-------------------------------------------------------------------------------------
  {
    // path : "/leaveapplication", component: <Leaveapplication/>
  },
  {
    path: "/salary", component: <SalaryList />
  },
  {
    path: "/salary/salarycreate", component: <SalaryCreate />
  },
  // Calender
  { path: "/calendar", component: <Calender /> },

  //------------------------------------------------------Mass Mailer Portal----------------------------------------------------------------------------
  // { path: "/massmailer", component: <Home /> },
  { path: "/massmailer/contacts", component: <ContactList /> },
  { path: "/massmailer/contacts/campaign/:id", component: <ContactList /> },
  { path: "/massmailer/contacts/details/:id", component: <ContactDetails /> },
  { path: "/massmailer/dashboard", component: <MMDashboard /> },
  { path: "/massmailer/campaigns", component: <CampaignList /> },
  { path: "/massmailer/campaigns/details/:id", component: <CampaignDetails /> },
  { path: "/massmailer/mail-settings", component: <MailSettings /> },

  // E-mail
  { path: "/inbox", component: <Inbox /> },
  { path: "/read-email", component: <ReadEmail /> },
  { path: "/compose-email", component: <EmailCompose /> },

  // Utility Pages
  { path: "/pages-starter", component: <StarterPage /> },
  { path: "/pages-timeline", component: <TimeLine /> },
  { path: "/pages-faqs", component: <FAQs /> },
  { path: "/pages-pricing", component: <Pricing /> },

  // UiElements Pages
  { path: "/ui-alerts", component: <UiAlerts /> },
  { path: "/ui-badge", component: <UiBadge /> },
  { path: "/ui-breadcrumb", component: <UiBreadcrumb /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModals /> },
  { path: "/ui-offcanvas", component: <UiOffcanvas /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-sessiontimeout", component: <UiSessionTimeout /> },
  { path: "/ui-pagination", component: <UiPagination /> },
  { path: "/ui-progressbars", component: <UiProgressBars /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-tabs-accordions", component: <UiTabs /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-toasts", component: <UiToasts /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-popovers", component: <UiPopovers /> },
  { path: "/ui-rating", component: <UiRating /> },

  // Forms pages
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-validation", component: <FormValidations /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editor", component: <FormEditors /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-editors", component: <FormXeditable /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-mask", component: <FormMask /> },

  // Tables pages
  { path: "/tables-basic", component: <BasicTable /> },
  { path: "/tables-listjs", component: <ListJs /> },
  { path: "/table-datatables", component: <DataTable /> },

  // Charts Pages
  { path: "/chart-apexcharts", component: <ApexCharts /> },
  { path: "/chart-chartjscharts", component: <ChartJs /> },
  { path: "/chart-floatcharts", component: <FloatChart /> },
  { path: "/chart-jknobcharts", component: <JknobCharts /> },
  { path: "/chart-sparklinecharts", component: <Sparklinechart /> },

  // Icons Pages
  { path: "/icon-boxicon", component: <IconBoxicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },
  { path: "/icon-dripicons", component: <IconDripicons /> },

  // Maps Pages
  { path: "/maps-vector", component: <VectorMaps /> },
  { path: "/maps-google", component: <GoogleMap /> },

  //UserProfile
  { path: '/profile', component: <UserProfile /> },
  { path: ":params/userprofile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/login" />,
  // },
];


const publicRoutes = [

  // Authentication Page
  // { path: "/logout", component: <Logout /> },
  { path: '/', component: <Login /> },
  { path: '/projectportal/kanbanboard', component: <Login /> },
  { path: '/projectportal/tasks/details/:id', component: <Login /> },
  { path: '/projectportal/milestones/details/:id', component: <Login /> },
  { path: '/projectportal/projects/details/:id', component: <Login /> },
  { path: '/timesheets/mytimesheet/details/:id', component: <Login /> },
  { path: '/timesheets/timesheetapproval', component: <Login /> },
  { path: '/timesheets/myteamtimesheet', component: <Login /> },
  { path: '/timesheets/alltimesheet', component: <Login /> },
  { path: '/leavetracker/applyleave/details/:id', component: <Login /> },
  { path: '/leavetracker/myteamleaves', component: <Login /> },
  { path: '/leavetracker/leavetype', component: <Login /> },
  { path: '/leavetracker/leavemaster/details/:id', component: <Login /> },
  { path: '/leavetracker/holidaymaster/details/:id', component: <Login /> },
  { path: '/leavetracker/leavemaster/details/:id', component: <Login /> },
  { path: '/leavetracker/weeklyoffdays', component: <Login /> },
  { path: '/employees/employee/details/:id', component: <Login /> },
  { path: '/admin/organization/details/:id', component: <Login /> },
  { path: '/admin/appmaster/details/:id', component: <Login /> },
  { path: '/admin/orguser/details/:id', component: <Login /> },
  { path: '/admin/groups/details/:id', component: <Login /> },
  { path: '/admin/roles', component: <Login /> },
  { path: '/ResetPassword', component: <Login /> },
  { path: '/visiondocs/documents/edit/:id', component: <Login /> },
  { path: '/enableuser', component: <Login /> },
  { path: '/:appName/documents/edit/:id/:appid', component: <Login /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/ResetPassword', component: <Dashboard /> },
  { path: '/enableuser', component: <Dashboard /> },
  { path: '/admin', component: <Dashboard /> },
  { path: '/employees', component: <Dashboard /> },
  { path: '/leavetracker', component: <Dashboard /> },
  { path: '/timesheets', component: <Dashboard /> },
  { path: '/projectportal', component: <Dashboard /> },
  { path: '/massmailer', component: <Dashboard /> },
  { path: '/dbsync', component: <Dashboard /> },
  { path: '/visiondocs', component: <Dashboard /> },
  { path: '/visiondocs/documents/edit', component: <Dashboard /> },
  { path: '/crm', component: <Dashboard /> },
  { path: '/crm/leads/details', component: <Dashboard /> },
  { path: '/crm/accounts/details', component: <Dashboard /> },
  { path: '/crm/contacts/details', component: <Dashboard /> },
  { path: '/crm/opportunity/details', component: <Dashboard /> },
  { path: '/ResetPassword/:code', component: <ForgetPasswordPage /> },
  { path: '/register', component: <Register /> },
  { path: '/recover', component: <Recover /> },
  { path: '/enableuser/:code', component: <EnableUser /> },

  //Mass-Mailer
  { path: "/massmailer/unsubscribe", component: <UnsubscribeHome /> },

  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: 'auth/resetpassword/:code', element: <ResetPassword /> },

  // Utility Pages
  { path: '*', component: <Error404 /> },
  // { path: '*', component: <Dashboard /> },
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-comingsoon", component: <ComingSoon /> },
  { path: "/massmailer", component: <Home /> },

];
const CRMSettingsData = [
  { path: "/crm/settings/leads/lead-category", component: < LeadCategory /> },
  { path: "/crm/settings/leads/lead-source", component: < LeadSource /> },
  { path: "/crm/settings/leads/mandatory-fields", component: < LeadMandatory /> },
  { path: "/crm/settings/leads/follow-up-action", component: < FollowUpAction /> },
  { path: "/crm/settings/accounts/account-category", component: < AccountCategory /> },
  { path: "/crm/settings/accounts/region", component: <Region /> },
  { path: "/crm/settings/accounts/industry-type", component: <Industry /> },
  { path: "/crm/settings/opportunity/opportunity-category", component: <OpportunityCategory /> },
  { path: "/crm/settings/opportunity/opportunity-type", component: <OpportunityType /> },
  { path: "/crm/settings/opportunity/opportunity-action", component: <OpportunityActionTime /> },
  { path: "/crm/settings/opportunity/opportunity-current-phase", component: <OpportunityCurrentPhase /> },
  { path: "/crm/settings/visits/visit-type", component: <VisitType /> },
]

const PROJECTPORTALSettingsData = [
  { path: "/projectportal/settings/tasks/task-scope", component: < TaskScope /> },
  { path: "/projectportal/settings/tasks/task-type", component: < TaskType /> },
  { path: "/projectportal/settings/tasks/task-status-percentage", component: < TaskPercentageStatus /> },
]
export { authProtectedRoutes, publicRoutes, CRMSettingsData, PROJECTPORTALSettingsData };
