import React, { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";

export const ElementReportToolTip = ({ name, id }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [targetExists, setTargetExists] = useState(false);

    const toggleTooltip = () => {
        const targetElement = document.getElementById(id);
        if (targetElement) {
            setTooltipOpen(!tooltipOpen);
        } else {
            setTooltipOpen(false);
        }
    };

    useEffect(() => {
        const targetElement = document.getElementById(id);
        if (targetElement) {
            setTargetExists(true);
        } else {
            setTargetExists(false);
        }

        return () => {
            setTargetExists(false); // Cleanup when component unmounts or id changes
        };
    }, [id]);

    useEffect(() => {
        if (tooltipOpen) {
            window.addEventListener('wheel', toggleTooltip);
        }
        return () => {
            window.removeEventListener('wheel', toggleTooltip);
        };
    }, [tooltipOpen]);

    return (
        <>
            {targetExists && (
                <Tooltip
                    isOpen={tooltipOpen}
                    target={id}
                    toggle={toggleTooltip}
                >
                    {name}
                </Tooltip>
            )}
        </>
    )
}