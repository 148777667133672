import React, { useEffect, useState } from 'react'
import VWButton from '../../../components/vwButton/VWButton';
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import VWMessage from '../../../components/vwMessage/vwMessage';
import { connect } from 'react-redux';
import { clearCreateOverAllDocsError } from '../../../store/Modules/OverAllDocs/actions';

const OverAllDocsCreateHeader = (props) => {
  const [formFields, setFormFields] = useState();
  const [fileName, setFileName] = useState('');
  const [includeUserInfo, setIsIncludeUserInfo] = useState();

  useEffect(() => {
    const { data } = props;
    const { contentText, title, assignUsers, headerTitle, headerLogo, id, appId, logoFileName, includeUserInfo, ownerUserName, updatedDateTime, createdTimeStamp } = data;
    console.log("data:", data);
    setFormFields({
      ...formFields, contentText, title, assignUsers, id, 'headerTitle': headerTitle, 'headerLogo': headerLogo, appId, logoFileName, includeUserInfo, ownerUserName
      , updatedDateTime, createdTimeStamp
    });
    setFileName(data && data.logoFileName || '');
    setIsIncludeUserInfo(includeUserInfo);

    props.clearCreateOverAllDocsError();
  }, [props.data])

  const handleCreateVisionDocsHeader = (e) => {
    e.preventDefault();
    props.handleDone(formFields);
    props.handleClose();
  }
  const maxLength = 30;
  const [errors, setErrors] = useState({});
  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    setFormFields({ ...formFields, [input.name]: value });
    if (value && value.length >= maxLength) {
      setErrors({ ...errors, headerTitle: `Header title cannot exceed ${maxLength} characters.` });
    } else {
      setErrors({ ...errors, headerTitle: '' });
    }
  };
  const [error, setError] = useState('');
  const validateOnChangeLogo = (e) => {
    const logoFile = e.target && e.target.files[0];
    console.log("logoFile:", logoFile);
    setError('');
    if (logoFile && logoFile.size > 2 * 1024 * 1024) {
      setError('Uploaded image was exceed the maximum size of 2 MB.');
      return Promise.reject('File size too large');
    }

    const reader = new FileReader();
    reader.readAsDataURL(logoFile)
    reader.onerror = (error) => {
      console.error("Error while reading the file:", error);
    };
    reader.onload = () => {
      const imagedata = reader.result
      console.log("imagedata:", imagedata)
      setFormFields({ ...formFields, headerLogo: imagedata, logoFileName: logoFile.name });
    }
    setFileName(logoFile.name);
  };

  const handleRemoveFile = () => {
    setFileName('');
    setFormFields({ ...formFields, headerLogo: '', logoFileName: '' });
  };

  const handleIsUserInfoStatusChange = () => {
    const newIsIncludeUserInfo = !includeUserInfo;
    setIsIncludeUserInfo(newIsIncludeUserInfo);
    setFormFields({ ...formFields, includeUserInfo: newIsIncludeUserInfo });
  };
  console.log("formFields:", formFields);
  console.log("isIncludeUserInfo:", includeUserInfo);


  return (
    <>
      {props.errorMessage && (
        <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
      )}

      <FormGroup>
        <label className="col-form-label">
          Header Title
        </label>
        <Input
          className='m-0'
          type="text"
          name="headerTitle"
          placeholder="Enter Header Title"
          value={formFields && formFields.headerTitle}
          onChange={validateOnChange}
          aria-label="Header Title"
          invalid={errors && errors.headerTitle}
          maxLength={maxLength}
        />
        <FormFeedback>{errors && errors.headerTitle}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <label className="col-form-label">
          Upload Logo
        </label>
        {error && <div className="text-danger">{error}</div>}
        {fileName ? (
          <div className="d-flex gap-3">
            <h6 className="me-2 mt-2">Selected file: {fileName}</h6>
            <button type="button" className="btn btn-danger btn-sm mt-0" onClick={handleRemoveFile}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ) : (
          <>
            <Input
              type="file"
              name="headerLogo"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                // const file = e.target.files[0];
                // if (file) {
                //   validateOnChangeLogo(file);
                // }
                validateOnChangeLogo(e).catch(err => {
                  console.error(err); // Handle the rejection if needed
                });
              }}
            />
          </>
        )}
      </FormGroup>
      <div className="form-group mt-4">
        <div>
          <label>
            <Input
              type="checkbox"
              checked={includeUserInfo}
              onClick={handleIsUserInfoStatusChange}
            />
            &nbsp;&nbsp;Include User Info
          </label>
        </div>
      </div>


      <div className="form-group pt-2">
        <VWButton
          messageKey="createVisionDocsButton"
          defaultMessage="Done"
          buttonType="success"
          baseClassName="btn"
          className="me-2"
          onClick={handleCreateVisionDocsHeader}
        ></VWButton>
        <VWButton
          messageKey="cancelVisionDocsButton"
          defaultMessage="Cancel"
          buttonType="danger"
          baseClassName="btn"
          onClick={props.handleClose}
        ></VWButton>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  errorMessage: state.OverAllDocsList.ErrorMessageCreateDocs,
  isPending: state.OverAllDocsList.isPendingDocsCreate,
  currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
  clearCreateOverAllDocsError: () => dispatch(clearCreateOverAllDocsError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(OverAllDocsCreateHeader)