import {
    REGION_CREATE_CLOSE,
    REGION_CREATE_ERROR,
    REGION_CREATE_ERROR_CLEAR,
    REGION_CREATE_OPEN,
    REGION_CREATE_PENDING,
    REGION_CREATE_SUCCESS,
    REGION_LIST_ERROR,
    REGION_LIST_ERROR_CLEAR,
    REGION_LIST_PENDING,
    REGION_UPDATE_CLOSE,
    REGION_UPDATE_ERROR,
    REGION_UPDATE_ERROR_CLEAR,
    REGION_UPDATE_OPEN,
    REGION_UPDATE_PENDING,
    REGION_UPDATE_SUCCESS,
    SET_REGION_DETAILS,
    SET_REGION_LIST,
} from "./constants";

const initialState = {
    //--------------------REGION-CREATE-----------------------------------
    isCreateRegionOpen: false,
    regionCreateErrorMessage: null,
    isRegionCreateSuccess: false,
    isRegionCreatePending: false,
    //--------------------REGION-UPDATE-----------------------------------
    regionDetails: null,
    isUpdateRegionOpen: false,
    regionUpdateErrorMessage: null,
    isRegionUpdateSuccess: false,
    isRegionUpdatePending: false,
    //--------------------REGION-LIST-------------------------------------
    regionList: [],
    regionListCount: 0,
    isRegionListPending: false,
    regionListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------LEADS-CREATE-----------------------------------
        case REGION_CREATE_OPEN: {
            return {
                ...localState,
                isCreateRegionOpen: true
            }
        }
        case REGION_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateRegionOpen: false
            }
        }
        case REGION_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                regionCreateErrorMessage: null,
            }
        }
        case REGION_CREATE_PENDING: {
            return {
                ...localState,
                isRegionCreatePending: true,
            }
        }
        case REGION_CREATE_SUCCESS: {
            return {
                ...localState,
                isRegionCreateSuccess: true,
                isRegionCreatePending: false
            }
        }
        case REGION_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                regionCreateErrorMessage: errorMessage,
                isRegionCreatePending: false,
            }
        }

        //--------------------LEADS-UPDATE-----------------------------------
        case SET_REGION_DETAILS: {
            const { regionDetails } = action.payload;
            return {
                ...localState,
                regionDetails: regionDetails
            }
        }
        case REGION_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateRegionOpen: true
            }
        }
        case REGION_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateRegionOpen: false
            }
        }
        case REGION_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                regionUpdateErrorMessage: null,
            }
        }
        case REGION_UPDATE_PENDING: {
            return {
                ...localState,
                isRegionUpdatePending: true,
            }
        }
        case REGION_UPDATE_SUCCESS: {
            return {
                ...localState,
                isRegionUpdateSuccess: true,
                isRegionUpdatePending: false
            }
        }
        case REGION_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                regionUpdateErrorMessage: errorMessage,
                isRegionUpdatePending: false
            }
        }
        //--------------------LEADS-CATEGORY-LIST-------------------------------------
        case SET_REGION_LIST: {
            const { regionList } = action.payload;
            return {
                ...localState,
                regionList: regionList && regionList['value'],
                regionListCount: regionList && regionList['@odata.count'],
                isRegionListPending: false,
                regionListErrorMessage: null,
            }
        }
        case REGION_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                regionListErrorMessage: errorMessage,
                isRegionListPending: false,
                regionList: []
            }
        }
        case REGION_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                regionListErrorMessage: null,
            }
        }
        case REGION_LIST_PENDING: {
            return {
                ...localState,
                isRegionListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};