//--------------------LEADS-CREATE-----------------------------------
export const LEADS_CREATE_OPEN = "LEADS_CREATE_OPEN";
export const LEADS_CREATE_CLOSE = "LEADS_CREATE_CLOSE";
export const LEADS_CREATE_ERROR = "LEADS_CREATE_ERROR";
export const LEADS_CREATE_PENDING = "LEADS_CREATE_PENDING";
export const LEADS_CREATE_ERROR_CLEAR = "LEADS_CREATE_ERROR_CLEAR";
export const LEADS_CREATE_SUCCESS = "LEADS_CREATE_SUCCESS";

//--------------------LEADS-UPDATE-----------------------------------
export const LEADS_UPDATE_OPEN = "LEADS_UPDATE_OPEN";
export const LEADS_UPDATE_CLOSE = "LEADS_UPDATE_CLOSE";
export const LEADS_UPDATE_PENDING = "LEADS_UPDATE_PENDING";
export const LEADS_UPDATE_ERROR = "LEADS_UPDATE_ERROR";
export const LEADS_UPDATE_SUCCESS = "LEADS_UPDATE_SUCCESS";
export const LEADS_UPDATE_ERROR_CLEAR = "LEADS_UPDATE_ERROR_CLEAR";

//--------------------LEADS-LSIT-----------------------------------
export const LEADS_IMPORT_SUCCESS = "LEADS_IMPORT_SUCCESS";
export const SET_LEADS_LIST = "SET_LEADS_LIST";
export const LEADS_IMPORT_PENDING = "LEADS_IMPORT_PENDING";
export const LEADS_LIST_PENDING = "LEADS_LIST_PENDING";
export const LEADS_LIST_ERROR = "LEADS_LIST_ERROR";
export const LEADS_LIST_ERROR_CLEAR = "LEADS_LIST_ERROR_CLEAR";
export const LEADS_EXPORT_LIST_SUCCESS = "LEADS_EXPORT_LIST_SUCCESS";

//--------------------LEADS-DETAILS-----------------------------------
export const SET_LEADS_DETAILS = "SET_LEADS_DETAILS";
export const LEADS_DETAILS_PENDING = "LEADS_DETAILS_PENDING";
export const LEADS_DETAILS_ERROR = "LEADS_DETAILS_ERROR";
export const LEADS_DETAILS_ERROR_CLEAR = "LEADS_DETAILS_ERROR_CLEAR";

//--------------------LEADS-TO-ACCOUNT-CONVERSION---------------------
export const LEADS_TO_ACCOUNT_PENDING = "LEADS_TO_ACCOUNT_PENDING";
export const CLEAR_LEADS_TO_ACCOUNT_PENDING = "CLEAR_LEADS_TO_ACCOUNT_PENDING";
export const REDIRECT_TO_ACCOUNT_DETAILS = "REDIRECT_TO_ACCOUNT_DETAILS";
export const CLEAR_REDIRECT_TO_ACCOUNT_DETAILS = "CLEAR_REDIRECT_TO_ACCOUNT_DETAILS";
export const SET_ACCOUNT_ID = "SET_ACCOUNT_ID";
export const LEADS_EXPORT_LIST_PENDING = "LEADS_EXPORT_LIST_PENDING";
export const LEADS_LIST_EXPORT_ERROR_CLEAR = "LEADS_LIST_EXPORT_ERROR_CLEAR";
export const SET_LEADS_EXPORT_LIST = "SET_LEADS_EXPORT_LIST";
export const LEADS_EXPORT_LIST_ERROR = "LEADS_EXPORT_LIST_ERROR";

//--------------------CONSTANTS---------------------------------------
export const QUALIFICATIONLEVELOPTIONS = [
    { label: 'Hot', value: 'Hot' },
    { label: 'Warm', value: 'Warm' },
    { label: 'Cold', value: 'Cold' },
]

export const LEADSTATUSOPTIONS = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Converted To Account', value: 'Converted To Account' },
]

export const LEADSOURCEOPTIONS = [
    { label: 'Website', value: 'Website' },
    { label: 'Cold Call', value: 'Cold Call' },
    { label: 'Sales Visit', value: 'Sales Visit' },
    { label: 'Phone Call Inbound', value: 'Phone Call Inbound' },
    { label: 'Social Media', value: 'Social Media' },
    { label: 'Trade Show / Exhibition', value: 'Trade Show / Exhibition' },
    { label: 'Email Campaign', value: 'Email Campaign' },
    { label: 'Print Media', value: 'Print Media' },
    { label: 'Distributor / External Partner', value: 'Distributor / External Partner' },
    { label: 'Seminar', value: 'Seminar' },
    { label: 'Search Engine', value: 'Search Engine' },
]

export const LEAD_EXPORT_HEADERS = [
    "LEAD NAME",
    "EMAIL",
    "LEAD CONTACT",
    "MOBILE NUMBER",    
    "LEAD SOURCE",
    "FOLLOW UP ACTION",
    "LEAD STATUS",
    "QUALIFICATION LEVEL",
    "CATEGORY NAME",
    "LEAD WEBSITE",
    "LEAD DESIGNATION",
    "LEAD RESPONSIBILITY",
    "CREATED DATE",
    "CREATED BY"
];