import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Input,
    Table
} from "reactstrap";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import { getPager } from "../../../../helper/common";
import {
    CONTACT_EXPORT_HEADERS,
    CONTACT_STATUS_FILTER_OPTIONS,
    MAIL_CATEGORY_FILTER_OPTIONS,
    SUBSCRIPTION_STATUS_FILTER_OPTIONS
} from "../../../../store/Modules/MassMailer/ContactList/constants";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import { excelPrint } from "../../../../helper/export";
import moment from "moment-timezone";
import Select from 'react-select';
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import { getCampaignContactList } from "../../../../store/Modules/MassMailer/Campaign/actions";

const style = {
    table: {
        width: '100%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'separate',
    },
    th: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#fff',
    },
};

const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const CampaignContactList = (props) => {
    const { campaignId } = props;
    const [pageSize] = useState(10);
    const [pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState({});

    //----------------------------------------------------------------------------FILTER-----------------------------------------------------------------
    const buildQueryEmail = () => {
        const { Email } = filter;
        let searchEmail = '';
        if (Email) {
            searchEmail += `${Email}`;
        }
        return searchEmail
    }

    const buildQueryType = () => {
        const { Type } = filter;
        let searchType = '';
        if (Type) {
            searchType += `${Type}`;
        }
        return searchType
    }
    const buildQueryIsActive = () => {
        const { IsActive } = filter;
        let searchIsActive = '';
        if (IsActive) {
            searchIsActive += `${IsActive}`;
        }
        return searchIsActive
    }
    const buildQuerySubscriptionStatus = () => {
        const { SubscriptionStatus } = filter;
        let searchSubscriptionStatus = '';
        if (SubscriptionStatus) {
            searchSubscriptionStatus += `${SubscriptionStatus}`;
        }
        return searchSubscriptionStatus
    }
    const buildQueryCreatedBy = () => {
        const { CreatedBy } = filter;
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }

    useEffect(() => {
        const currentPager = getPager(props.campaignContactListCount, 1, pageSize);
        setPager(currentPager)
    }, [props.campaignContactListCount])

    const setPage = (page = 1) => {
        props.getContactList(campaignId, page, pageSize, buildQueryEmail(), buildQueryType(), buildQueryIsActive(), buildQuerySubscriptionStatus(),
            buildQueryCreatedBy(), sortDirection, sortOrder);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.campaignContactListCount / pageSize);
            const visiblePages = 10;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
                pageSize
            };
        });
    }

    useEffect(() => {
        const currentPageNumber = pager && pager.currentPage
        if ((Object.keys(filter).length === 0)) {
            setPage(currentPageNumber)
        }
    }, [])

    const searchInputValidation = (e) => {
        const input = e.target;
        const value = input.value;
        setFilter({
            ...filter,
            [input.name]: value
        })
    }

    const searchMailCategoryDD = (Value) => {
        const { value, label } = Value;
        setFilter({
            ...filter,
            Type: value
        })
    }
    const searchContactStatusDD = (Value) => {
        const { value, label } = Value;
        setFilter({
            ...filter,
            IsActive: value
        })
    }

    const searchSubscriptionStatusDD = (Value) => {
        const { value, label } = Value;
        setFilter({
            ...filter,
            SubscriptionStatus: value
        })
    }

    useEffect(() => {
        if (filter && (Object.keys(filter).length > 0)) {
            handleSearchClick();
        }
    }, [filter])

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }

    // -----------------------------------------------------------SORTING--------------------------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedCampaignContactList = [...props.campaignContactList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //--------------------------------------------------------------EXPORTS---------------------------------------------------------------------------
    const handleExports = () => {
        const contactListsExported = [];
        const headers = CONTACT_EXPORT_HEADERS;
        const headerSizes = [40, 30, 30, 10, 30, 30, 30];
        props.campaignContactExportList.forEach((element) => {
            contactListsExported.push({
                Email: element.Email || "NA",
                CampaignName: element.CampaignName || "NA",
                MailCategory: element.Type || "NA",
                Status: (element.IsActive ? "Active" : "Inactive") || "NA",
                SubscriptionStatus: element.SubscriptionStatus || "NA",
                CreatedBy: element.CreatedBy || "NA",
                CreatedTimeStamp: element.CreatedTimeStamp ? moment(element.CreatedTimeStamp).format('DD/MM/YYYY') : "NA",
            });
        });
        excelPrint(contactListsExported, "Campaign-Contact-List", headers, headerSizes);
    }
    return (
        <div className="pt-3">
            <Card >
                <CardTitle className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center">
                    <strong className='d-flex align-items-center'>
                        CONTACTS -&nbsp;<Badge color="danger">{props && props.campaignContactListCount}</Badge>
                    </strong>
                    {(props.campaignContactListCount > 0) &&
                        <div className="content-heading">
                            <div className="ml-auto">
                                <Button
                                    className="me-4"
                                    color="success"
                                    onClick={handleExports}>
                                    Export
                                </Button>
                            </div>
                        </div>}
                </CardTitle>
            </Card>
            <Card>
                <CardBody>
                    <div style={{ maxHeight: "400px", overflowX: "auto" }}>
                        {props.errorMessage && (
                            <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />
                        )}
                        <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                            <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                <tr className="sticky-top">
                                    <th onClick={() => handleSort('Email')}>
                                        Email Address &nbsp;
                                        {sortOrder === 'Email' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th onClick={() => handleSort('Type')}>
                                        Mail Category &nbsp;
                                        {sortOrder === 'Type' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th onClick={() => handleSort('IsActive')}>
                                        Status &nbsp;
                                        {sortOrder === 'IsActive' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th onClick={() => handleSort('SubscriptionStatus')}>
                                        Subscription Status &nbsp;
                                        {sortOrder === 'SubscriptionStatus' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                    <th onClick={() => handleSort('CreatedBy')}>
                                        Created By &nbsp;
                                        {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                    </th>
                                </tr>
                                <tr style={{ backgroundColor: "white" }}>
                                    <th>
                                        <Input
                                            type='text'
                                            placeholder='Search'
                                            name='Email'
                                            onChange={searchInputValidation}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            placeholder='Search'
                                            name="Type"
                                            onChange={searchMailCategoryDD}
                                            options={MAIL_CATEGORY_FILTER_OPTIONS}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            placeholder='Search'
                                            name="IsActive"
                                            onChange={searchContactStatusDD}
                                            options={CONTACT_STATUS_FILTER_OPTIONS}
                                        />
                                    </th>
                                    <th>
                                        <Select
                                            styles={customStyles}
                                            placeholder='Search'
                                            name="SubscriptionStatus"
                                            onChange={searchSubscriptionStatusDD}
                                            options={SUBSCRIPTION_STATUS_FILTER_OPTIONS}
                                            value={filter && filter.SubscriptionStatus ?
                                                { label: filter.SubscriptionStatus, value: filter.SubscriptionStatus }
                                                : null}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type='text'
                                            placeholder='Search'
                                            name='CreatedBy'
                                            onChange={searchInputValidation}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </th>
                                </tr>
                            </thead> {console.log("campaignContactList: ", props.campaignContactList)}
                            <tbody>
                                {props.campaignContactList && props.campaignContactList.length > 0 ?
                                    sortedCampaignContactList && sortedCampaignContactList.map((i, index) =>
                                        <tr key={index}>
                                            <td>{i.Email || "N/A"}</td>
                                            <td>{i.Type || "N/A"}</td>
                                            <td>
                                                {i.IsActive ?
                                                    (<Badge color="success">Active</Badge>)
                                                    : (<Badge color="danger">Inactive</Badge>)
                                                }
                                            </td>
                                            <td>{i.SubscriptionStatus || "N/A"}</td>
                                            <td>
                                                {i.CreatedBy || "N/A"}<br />
                                                <small>{i.CreatedTimeStamp ? moment(i.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                            </td>
                                        </tr>
                                    )
                                    : props.isCampaignContactPending ?
                                        <tr>
                                            <td colSpan={7}>
                                                <div className="d-flex justify-content-center mt-2">
                                                    <VWSpinner />
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        <tr>
                                            <td colSpan={50}>
                                                <NoRecord />
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                {props.campaignContactListCount > 0 ?
                    <VWPagination
                        pager={pager}
                        setPage={setPage}
                        totalRecords={props.campaignContactListCount}
                        pageSize={pageSize}
                    />
                    : ""}
            </Card>
        </div >
    )
}
const mapStateToProps = (state) => ({
    campaignContactList: state.campaign.campaignContactList,
    campaignContactListCount: state.campaign.campaignContactListCount,
    campaignContactExportList: state.campaign.campaignContactExportList,
    isCampaignContactPending: state.campaign.isCampaignContactPending,
    errorMessage: state.campaign.campaignContactListErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    getContactList: (campaignId, page, pageSize, searchEmail, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, sortDirection, sortOrder) =>
        dispatch(getCampaignContactList(campaignId, page, pageSize, searchEmail, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, sortDirection, sortOrder)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CampaignContactList);