export const OPEN_CREATE_TIMESHEET_MODAL = "OPEN_CREATE_TIMESHEET_MODAL";
export const CLOSE_CREATE_TIMESHEET_MODAL = "CLOSE_CREATE_TIMESHEET_MODAL";
export const SET_TIMESHEET_LIST = "SET_TIMESHEET_LIST";
export const SET_TIMESHEET_DETAILS = "SET_TIMESHEET_DETAILS";
export const CREATE_TIMESHEET_ERROR = "CREATE_TIMESHEET_ERROR";
export const CREATE_TIMESHEET_PENDING = "CREATE_TIMESHEET_PENDING";
export const CLEAR_TIMESHEET_ERROR = "CLEAR_TIMESHEET_ERROR";
export const CREATE_TIMESHEET_SUCCESS = "CREATE_TIMESHEET_SUCCESS";
export const OPEN_EDIT_TIMESHEET_MODAL = "OPEN_EDIT_TIMESHEET_MODAL";
export const CLOSE_EDIT_TIMESHEET_MODAL = "CLOSE_EDIT_TIMESHEET_MODAL";
export const CLEAR_UPDATE_TIMESHEET_ERROR = "CLEAR_UPDATE_TIMESHEET_ERROR";
export const UPDATE_TIMESHEET_PENDING = "UPDATE_TIMESHEET_PENDING";
export const UPDATE_TIMESHEET_SUCCESS = "UPDATE_TIMESHEET_SUCCESS";
export const UPDATE_TIMESHEET_ERROR = "UPDATE_TIMESHEET_ERROR";
export const OPEN_DELETE_TIMESHEET_MODAL = "OPEN_DELETE_TIMESHEET_MODAL";
export const CLOSE_DELETE_TIMESHEET_MODAL = "CLOSE_DELETE_TIMESHEET_MODAL";
export const SET_LOGGED_HOURS = "SET_LOGGED_HOURS";
export const DELETE_TIMESHEET_ERROR = "DELETE_TIMESHEET_ERROR";
export const TIMESHEET_LIST_ERROR = "TIMESHEET_LIST_ERROR"
export const TIMESHEET_LIST_PENDING = "TIMESHEET_LIST_PENDING"
export const CLEAR_TIMESHEET_LIST_ERROR = "CLEAR_TIMESHEET_LIST_ERROR"
export const TIMESHEET_DETAILS_ERROR = "TIMESHEET_DETAILS_ERROR"
export const TIMESHEET_DETAILS_PENDING = "TIMESHEET_DETAILS_PENDING"
export const CLEAR_TIMESHEET_DETAILS_ERROR = "CLEAR_TIMESHEET_DETAILS_ERROR"
export const TIMESHEET_EXPORT_LIST_PENDING = "TIMESHEET_EXPORT_LIST_PENDING"
export const SET_TIMESHEET_EXPORT_LIST = "SET_TIMESHEET_EXPORT_LIST"
export const CLEAR_TIMESHEET_EXPORT_ERROR = "CLEAR_TIMESHEET_EXPORT_ERROR"
export const TIMESHEET_EXPORT_LIST_ERROR = "TIMESHEET_EXPORT_LIST_ERROR"
export const CLEAR_TIMESHEET_LIST = "CLEAR_TIMESHEET_LIST"


export const TIMESHEET_EXPORT_HEADERS = [
    "USER NAME",
    "USER EMAIL",
    "PROJECT NAME",
    "TASK NAME",
    "TASK CURRENT STATUS",
    "SCOPE",
    "WORK DATE",
    "CREATED DATE",
    "STATUS",
    "WORK HOURS",
];