const DBSyncSidebar = [
    {
        label: "DB Sync Management",
        isMainMenu: true,
    },
    {
        label: "DB Sync",
        icon: "mdi mdi-content-copy",
        isHasArrow: true,
        url: "/dbsync/list",
    },

]


export default DBSyncSidebar;



