import React, { useEffect, useState } from 'react'
import VWButton from '../../../components/vwButton/VWButton'
import VWMessage from '../../../components/vwMessage/vwMessage'
import { Col, FormGroup, Input, Row, Table } from 'reactstrap'
import Async from 'react-select/async';
import { connect } from 'react-redux';
import { clearCreateOverAllDocsError, createOverAllDocs, getOrgUserCreateAsyncSelect, getOrgUserNameAsyncSelect } from '../../../store/Modules/OverAllDocs/actions';

const OverAllDocsCreateModal = (props) => {

    const [formFields, setFormFields] = useState({ "assignUsers": [] });
    const [UserPermission, setUserPermission] = useState([]);
    const [isReadWrite, setisReadWrite] = useState([]);
    const [existingUsers, setExistingUsers] = useState([]);
    const [prevVisionDocsUser, setprevVisionDocsUser] = useState([{ label: '', value: '' }]);

    const { userId } = props.currentProfile;
    useEffect(() => {
        const { data } = props;
        const { contentText, appId, isPublic, title, assignUsers, includeUserInfo, ownerUserName, updatedDateTime, createdTimeStamp } = data;

        console.log("data:", data);
        setFormFields({ ...formFields, contentText, appId, isPublic, title, assignUsers, includeUserInfo, ownerUserName, updatedDateTime, createdTimeStamp });
        props.clearCreateOverAllDocsError();
        if (data) {
            props.getUserNameAsyncSelectAction(userId, props.data && props.data.appId, '', (UserTypeList) => {
                setExistingUsers(UserTypeList);
            })
        }
        //------------
        if (assignUsers) {
            const UserTypeList = assignUsers.map((item) => {
                return {
                    label: item.userName,
                    value: item.userId,
                    permission: item.permission
                };
            });
            console.log("UserTypeList:", UserTypeList);

            setUserPermission(UserTypeList);
            setprevVisionDocsUser(UserTypeList);
            const UserID = UserTypeList.map((item) => item.value)
            console.log("UserID:", UserID);

            setFormFields({ ...data, assignUsers: UserID });

            const prevReadWrite = data.assignUsers && data.assignUsers.map((item) => {
                return {
                    userId: item.userId,
                    userName: item.userName,
                    permission: item.permission
                };
            });
            console.log("prevReadWrite:", prevReadWrite);

            setisReadWrite(prevReadWrite);
            setFormFields({ ...data, assignUsers: prevReadWrite });
        }
    }, [props.data])

    const handleUserName = (inputValue, callback) => {
        const { userId } = props.currentProfile;
        console.log("form", formFields);
        const { getUserNameAsyncSelectAction } = props;
        return getUserNameAsyncSelectAction(userId, props.data && props.data.appId, inputValue.length > 0 ? inputValue : '', callback);
    };

    const handleAddUserName = (value) => {
        const userID = value.map((item) => ({
            userId: item.value,
            userName: item.label,
            permission: isReadWrite && isReadWrite.find((i) => (i.userId === item.value)) ? isReadWrite && isReadWrite.filter((i) => i.userId === item.value)[0].permission : "Read"
        }))
        setisReadWrite(userID);
        setFormFields({ ...formFields, assignUsers: userID });
        setUserPermission(value);
        setprevVisionDocsUser(value);
    }

    //-------------------------------------Status Change-----------------------------------------------------------
    const handleActiveStatusChange = (userId, Permission) => {
        const updatedUserList = [...isReadWrite];
        const existingIndex = isReadWrite.findIndex((item) => item.userId === userId);
        if (existingIndex !== -1) {
            updatedUserList[existingIndex].permission = Permission
        }
        setisReadWrite(updatedUserList);
        setFormFields({ ...formFields, assignUsers: updatedUserList })
    };

    const handleCreateOverAllDocs = (e) => {
        e.preventDefault();
        props.handleDone(formFields);

    };

    const handleLoadUsers = (inputValue, callback) => {
        const { userId } = props.currentProfile;
        const { getUserNameAsyncSelectAction } = props;
        getUserNameAsyncSelectAction(userId, props.data && props.data.appId, inputValue || '', (UserTypeList) => {
            if (!inputValue) {
                setExistingUsers(UserTypeList);
            }
            callback(UserTypeList);
        });
    };

    console.log("formFields:", formFields);
    console.log("errorMessage:", props.errorMessage);
    console.log("UserPermission:", UserPermission);
    console.log("existingUsers:", existingUsers);
    console.log("isReadWrite:", isReadWrite);

    return (
        <>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <div className="form-group">
                <label className="col-form-label">
                    Select Users
                </label>
                <Async
                    name="UserId"
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 30,
                        }),
                    }}
                    placeholder="Select users"
                    isSearchable
                    isMulti
                    loadOptions={handleLoadUsers}
                    defaultOptions={existingUsers}
                    onChange={handleAddUserName}
                    value={prevVisionDocsUser}
                />
                {<p className='text-danger mt-2' style={{ lineHeight: '.9em' }}><small><strong>Note</strong>: By default, administrators have read access, even if you don't select administrators.</small></p>}
            </div>

            {UserPermission && UserPermission.length > 0 ?
                <Row>
                    <Col md={12}>
                        <Table className='table-width' responsive borderless>
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th style={{ width: "200px" }}>Permission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {UserPermission && UserPermission.map((item, index) => (
                                    <tr key={item.value}>
                                        <>
                                            <td>{item.label}</td>
                                            <td style={{ minWidth: "150px" }}>
                                                <FormGroup>
                                                    <ul className="forms-unstyled-list form-status-list">
                                                        <li
                                                            onClick={() => { handleActiveStatusChange(item.value, "Read") }}
                                                            className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Read")).length > 0 ? 'active' : '') : ""}`}
                                                        >
                                                            Read
                                                        </li>
                                                        <li
                                                            onClick={() => handleActiveStatusChange(item.value, "Write")}
                                                            className={`forms-status-item ${isReadWrite ? ((isReadWrite.filter((i) => i.userId === item.value && i.permission === "Write")).length > 0 ? 'active' : '') : ""}`}
                                                        >
                                                            Write
                                                        </li>
                                                    </ul>
                                                </FormGroup>
                                            </td>
                                        </>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                : ""}
            <div className="form-group pt-2">
                <VWButton
                    messageKey="createOverAllDocsButton"
                    defaultMessage="Done"
                    buttonType="success"
                    baseClassName="btn"
                    className="me-2"
                    onClick={handleCreateOverAllDocs}
                    // isLoading={props.isPending}
                    isDisabled={props.isPending}
                ></VWButton>
                <VWButton
                    messageKey="cancelOverAllDocsButton"
                    defaultMessage="Cancel"
                    buttonType="danger"
                    baseClassName="btn"
                    onClick={props.handleClose}
                // isDisabled={props.isPending}
                ></VWButton>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    errorMessage: state.OverAllDocsList.ErrorMessageCreateDocs,
    isPending: state.OverAllDocsList.isPendingDocsCreate,
    currentProfile: state.login.currentProfile,

});
const mapDispatchToProps = (dispatch) => ({

    getUserNameAsyncSelectAction: (userId, appId, searchValue, callback) =>
        dispatch(getOrgUserCreateAsyncSelect(userId, appId, searchValue, callback)),
    clearCreateOverAllDocsError: () => dispatch(clearCreateOverAllDocsError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(OverAllDocsCreateModal)