import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap'
import { clearCreateLeaveTypeError, createLeaveType, getLeaveTypeMasterListAction, getOrgLeaveTypeListAction } from '../../../../store/Modules/LeaveTracker/LeaveType/actions';
import Select from "react-select";
import VWButton from '../../../../components/vwButton/VWButton';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import NoRecord from '../../../../components/NoRecords/noRecords';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';


const GenderOptions = [
    { label: 'All', value: 'All' },
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },

]
const FrequencyOptions = [
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Yearly', value: 'Yearly' },
]

const LeaveType = (props) => {

    const [prevGender, setprevGender] = useState([]);
    const [prevFrequency, setprevFrequency] = useState([]);
    const [formFields, setFormFields] = useState({});

    useEffect(() => {
        props.clearcreateLeavetypeError();
        props.getOrgLeaveTypeList();
        props.ClearPath();
    }, [])

    useEffect(() => {
        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();
        }
    }, []);

    useEffect(() => {
        if (props.OrgLeaveTypeList) {
            const finalArr = props.OrgLeaveTypeList;
            setFormFields(finalArr);
            let genderArray = [];
            for (const items of finalArr) {
                genderArray.push({ label: items.Gender, value: items.Gender })
            }
            setprevGender(genderArray);
            let frequencyArray = [];
            for (const items of finalArr) {
                frequencyArray.push({ label: items.Frequency, value: items.Frequency })
            }
            setprevFrequency(frequencyArray);
        }
    }, [props.OrgLeaveTypeList])


    const handleEnableCheckbox = (e, i) => {
        const isChecked = e.target.checked;
        console.log("isChecked:", isChecked);
        console.log("index:", i);

        setFormFields((data => {
            const prevdata = [...data]
            prevdata[i].IsEnable = isChecked
            console.log("updateprevdata:", prevdata);
            return prevdata
        }))
    }
    const handleCarryForwardCheckbox = (e, i) => {
        const isChecked = e.target.checked;
        console.log("isChecked:", isChecked);
        console.log("index:", i);

        setFormFields((data => {
            const prevdata = [...data]
            prevdata[i].CarryForward = isChecked
            console.log("updateprevdata:", prevdata);
            return prevdata
        }))
    }
    const handleAllowConsecutiveDaysCheckbox = (e, i) => {
        const isChecked = e.target.checked;
        console.log("isChecked:", isChecked);
        console.log("index:", i);

        setFormFields((data => {
            const prevdata = [...data]
            prevdata[i].AllowConsecutiveDays = isChecked
            console.log("updateprevdata:", prevdata);
            return prevdata
        }))
    }

    const validateOnChange = (event, i) => {
        const Name = event.target.name;
        const Value = event.target.value;
        console.log("value:", Value);
        console.log("type:", typeof (Value));
        setFormFields((data => {
            const prevdata = [...data]
            prevdata[i][Name] = Value
            console.log("updateprevdata:", prevdata);
            return prevdata
        }))
    };

    const handleChangeGenderSelect = (e, i) => {
        console.log("e:", e.value)
        setprevGender((data => {
            const prevdata = [...data]
            prevdata[i].label = e.label
            prevdata[i].value = e.value
            console.log("updateprevdata:", prevdata);
            return prevdata
        }))
        setFormFields((data => {
            const prevdata = [...data]
            prevdata[i].Gender = e.value;
            return prevdata
        }))
    }
    const handleChangeFrequencySelect = (e, i) => {
        console.log("e:", e.value)
        setprevFrequency((data => {
            const prevdata = [...data]
            prevdata[i].label = e.label
            prevdata[i].value = e.value
            console.log("updateprevdata:", prevdata);
            return prevdata
        }))
        setFormFields((data => {
            const prevdata = [...data]
            prevdata[i].Frequency = e.value;
            return prevdata
        }))
    }


    const handleUpdateLeaveType = () => {
        props.createLeaveTypeAction(formFields);
    }

    console.log("OrgLeaveTypeList:", props.OrgLeaveTypeList);
    console.log("formFields:", formFields);
    console.log("prevGender:", prevGender);
    console.log("prevFrequency:", prevFrequency);
    console.log("errorMessage:", props.errorMessage);
    return (
        <React.Fragment>
            <div className="page-content">
                <>
                    <Card >
                        <CardTitle
                            tag="h4"
                            className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                        >
                            <strong> LEAVE TYPE </strong>
                            <div className="form-group">
                                <FormGroup>
                                    <Button
                                        className="me-2"
                                        color="success"
                                        onClick={handleUpdateLeaveType}
                                        disabled={props.isPending}
                                    >
                                        {props.isPending ? <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                            &nbsp; Saving...
                                        </>
                                            : <>Save</>}
                                    </Button>
                                </FormGroup>
                            </div>
                        </CardTitle>
                    </Card>
                    <Card>
                        <CardBody className="p-4">
                            {(props.errorMessage || props.OrgLeaveTypeListError) && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage || props.OrgLeaveTypeListError} baseClassName="alert"></VWMessage>
                            )}
                            <div style={{ height: "60vh", overflowX: "auto" }}>
                                <Table striped>
                                    <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                        <tr className='text-center'>
                                            <th>
                                                Enabled
                                            </th>
                                            <th className='text-nowrap'>
                                                Leave Type<span className="text-danger"> *</span>
                                            </th>
                                            <th style={{ minWidth: '150px' }}>
                                                Gender<span className="text-danger"> *</span>
                                            </th>
                                            <th style={{ minWidth: '150px' }}>
                                                Frequency<span className="text-danger"> *</span>
                                            </th>
                                            <th style={{ minWidth: '100px' }}>
                                                Qty<span className="text-danger"> *</span>
                                            </th>
                                            <th>
                                                Carry Forward
                                            </th>

                                            <th>
                                                Allow Consecutive Days
                                            </th>
                                            <th>
                                                No Of Consecutive Days<span className="text-danger"> *</span>
                                            </th>
                                            <th style={{ minWidth: '200px' }}>
                                                Description
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {formFields && formFields.length > 0 ? formFields && formFields.map((data, i) => (
                                            <tr className='text-center'>
                                                <td> <Input
                                                    type='checkbox'
                                                    name='IsEnable'
                                                    onChange={(e) => handleEnableCheckbox(e, i)}
                                                    checked={data && data.IsEnable}
                                                /></td>
                                                <td>

                                                    {(data && data.LeaveType ? data && data.LeaveType : "") || (data && data.LeavetypeName ? data && data.LeavetypeName : "")}
                                                </td>
                                                <td>
                                                    <Select
                                                        name="gender"
                                                        placeholder="Select Gender"
                                                        options={GenderOptions}
                                                        onChange={e => handleChangeGenderSelect(e, i)}
                                                        value={prevGender[i]}
                                                        menuPlacement='auto'
                                                        styles={{
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                                overflow: 'auto'
                                                            }),
                                                        }}
                                                    />

                                                </td>
                                                <td>
                                                    <Select
                                                        name="Frequency"
                                                        placeholder="Select Frequency"
                                                        options={FrequencyOptions}
                                                        onChange={e => handleChangeFrequencySelect(e, i)}
                                                        value={prevFrequency[i]}
                                                        menuPlacement='auto'
                                                        styles={{
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                                overflow: 'auto'
                                                            }),
                                                        }}
                                                    />
                                                </td>
                                                <td >
                                                    <Input
                                                        onKeyDown={
                                                            (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        type="number"
                                                        name="Qty"
                                                        min='0'
                                                        placeholder="Enter Qty"
                                                        onChange={(e) => validateOnChange(e, i)}
                                                        value={data && data.Qty}
                                                    />
                                                </td>
                                                <td> <Input className="m-2"
                                                    type='checkbox'
                                                    name='CarryForward'
                                                    onChange={(e) => handleCarryForwardCheckbox(e, i)}
                                                    checked={data && data.CarryForward}
                                                /></td>

                                                <td> <Input className="m-2"
                                                    type='checkbox'
                                                    name='AllowConsecutiveDays'
                                                    onChange={(e) => handleAllowConsecutiveDaysCheckbox(e, i)}
                                                    checked={data && data.AllowConsecutiveDays}
                                                /></td>
                                                <td>
                                                    <Input
                                                        onKeyDown={
                                                            (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                                        }
                                                        onWheel={(e) => e.target.blur()}
                                                        min='0'
                                                        type="number"
                                                        name="ConsecutiveDaysQty"
                                                        placeholder="Enter Consecutive Qty"
                                                        onChange={(e) => validateOnChange(e, i)}
                                                        value={data && data.ConsecutiveDaysQty}
                                                        disabled={!data.AllowConsecutiveDays}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="textarea"
                                                        name="Description"
                                                        placeholder="Enter Description"
                                                        onChange={(e) => validateOnChange(e, i)}
                                                        value={data && data.Description}
                                                    />
                                                </td>
                                            </tr>
                                        )) : props.isOrgLeaveTypeListPending ? <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <VWSpinner />
                                                </div>
                                            </div>
                                        </div>
                                            :
                                            <div className="sticky-spinner-row">
                                                <div className="position-relative">
                                                    <div className="d-flex justify-content-center align-items-center"
                                                        style={{
                                                            position: 'fixed',
                                                            top: '50%',
                                                            left: '60%',
                                                            transform: 'translate(-50%, -50%)',
                                                            zIndex: 1,
                                                            width: '100vw',
                                                            height: '100vh',
                                                            pointerEvents: 'none',
                                                        }}>
                                                        <NoRecord />
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = (state) => ({
    isLeaveTypeMasterListPending: state.leaveType.isLeaveTypeMasterListPending,
    isOrgLeaveTypeListPending: state.leaveType.isOrgLeaveTypeListPending,
    LeaveTypeMasterListError: state.leaveType.LeaveTypeMasterListError,
    OrgLeaveTypeListError: state.leaveType.OrgLeaveTypeListError,
    OrgLeaveTypeList: state.leaveType.OrgLeaveTypeList,
    isPending: state.leaveType.isPending,
    errorMessage: state.leaveType.errorMessage,
});
const mapDispatchToProps = (dispatch) => ({
    getOrgLeaveTypeList: () => dispatch(getOrgLeaveTypeListAction()),
    clearcreateLeavetypeError: () => dispatch(clearCreateLeaveTypeError()),
    createLeaveTypeAction: (formFields) => dispatch(createLeaveType(formFields)),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveType)