import { getToken } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../../../crm/Settings/SettingsInlineUpdate/actions";
import { SET_TASK_PERCENTAGE_DETAILS, SET_TASK_PERCENTAGE_STATUS_LIST, TASK_PERCENTAGE_STATUS_LIST_ERROR, TASK_PERCENTAGE_STATUS_LIST_ERROR_CLEAR, TASK_PERCENTAGE_STATUS_LIST_PENDING, TASK_PERCENTAGE_UPDATE_CLOSE, TASK_PERCENTAGE_UPDATE_ERROR, TASK_PERCENTAGE_UPDATE_ERROR_CLEAR, TASK_PERCENTAGE_UPDATE_OPEN, TASK_PERCENTAGE_UPDATE_PENDING, TASK_PERCENTAGE_UPDATE_SUCCESS } from "./constants";



// //--------------------SCOPE-UPDATE-----------------------------------
export const openEditTaskPercetageStatusModal = () => ({
    type: TASK_PERCENTAGE_UPDATE_OPEN
})
export const closeEditTaskPercentageModal = () => ({
    type: TASK_PERCENTAGE_UPDATE_CLOSE
})
export const updateTaskPercentagePending = () => ({
    type: TASK_PERCENTAGE_UPDATE_PENDING
})
export const updateTaskPercentageError = (errorMessage) => ({
    type: TASK_PERCENTAGE_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateTaskPercentageError = () => ({
    type: TASK_PERCENTAGE_UPDATE_ERROR_CLEAR,
})
export const updateTaskPercentageSuccess = () => ({
    type: TASK_PERCENTAGE_UPDATE_SUCCESS,
})
export const setTaskPercentageDetails = (percentageDetails) => ({
    type: SET_TASK_PERCENTAGE_DETAILS,
    payload: { percentageDetails }
})

export const updateTaskPercentage = (formFields, appId) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateTaskPercentageError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateTaskPercentagePending());
    dispatch(clearUpdateTaskPercentageError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.updateTaskPercentageStatus(accessToken, dataToSend)
            .then(() => {
                dispatch(getTaskPercentageStatusList(appId));
                dispatch(updateTaskPercentageSuccess());
                dispatch(closeEditTaskPercentageModal());
                dispatch(showSnackbarStatus('Task percentage status updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateTaskPercentageError(err.response && err.response.text));
                    return;
                }
                console.log('Create task percentage status server error', err)
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};


//--------------------TASK PERCENTAGE STATUS-LIST-----------------------------------
export const taskPercentageStatusListPending = () => ({
    type: TASK_PERCENTAGE_STATUS_LIST_PENDING
})
export const taskPercentageStatusListError = (errorMessage) => ({
    type: TASK_PERCENTAGE_STATUS_LIST_ERROR,
    payload: { errorMessage }
})
export const clearTaskpercentageStatusListError = () => ({
    type: TASK_PERCENTAGE_STATUS_LIST_ERROR_CLEAR,
})
export const setTaskPercentageStatusList = (taskPercentageList) => ({
    type: SET_TASK_PERCENTAGE_STATUS_LIST,
    payload: { taskPercentageList }
})

export const getTaskPercentageStatusList = (appId, page, PageSize) => (dispatch) => {
    dispatch(taskPercentageStatusListPending());
    dispatch(clearTaskpercentageStatusListError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getTaskPercentageStatusList(accessToken, appId, page, PageSize)
            .then((result) => {
                dispatch(setTaskPercentageStatusList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(taskPercentageStatusListError(error));
                console.log('Task percentage status list server error', error);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
