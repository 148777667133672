import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
    Badge,
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    Table
} from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import moment from "moment";
import VWMessage from '../../../../components/vwMessage/vwMessage'
import { getContactDetails } from '../../../../store/Modules/MassMailer/ContactList/actions';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import NoRecord from '../../../../components/NoRecords/noRecords';

const ContactDetails = (props) => {
    const params = useParams();
    const { id } = params;
    const { contactDetails } = props || {};
    const navigate = useNavigate();

    useEffect(() => {
        props.getContactDetails(id);
    }, [id]);

    const handleRouteCampaignDetails = (e, id) => {
        e.preventDefault();
        navigate(`/massmailer/campaigns/details/${id}`);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>CONTACT DETAILS</strong>
                    </CardTitle>
                    <CardBody className="p-4">
                        {props.errorMessage && (
                            <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />
                        )}
                        {props.isContactDetailsPending ?
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                            :
                            <>
                                <Row>
                                    <div>
                                        <div className="p-3 bg-light fw-bold fs-4">
                                            <span className='text-capitalize'>{contactDetails && contactDetails.email || "N/A"}</span>
                                        </div>
                                    </div>
                                    <Col xl="6">
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <strong>Email Address</strong>
                                                                </td>
                                                                <td>
                                                                    {(contactDetails && contactDetails.email) || "N/A"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Mail Category</strong>
                                                                </td>
                                                                <td>
                                                                    {(contactDetails && contactDetails.type) || "N/A"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Campaign Name</strong>
                                                                </td>
                                                                <td>
                                                                    {(contactDetails && contactDetails.campaignName) || "N/A"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Contact Source</strong>
                                                                </td>
                                                                <td>
                                                                    {(contactDetails && contactDetails.contactSource) || "N/A"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: "200px" }}>
                                                                    <strong>Status</strong>
                                                                </td>
                                                                <td>
                                                                    {contactDetails === null ? "N/A"
                                                                        : contactDetails && contactDetails.isActive ? (
                                                                            <Badge color="success" className="text-dark-white">
                                                                                Active
                                                                            </Badge>
                                                                        ) : (
                                                                            <Badge color="danger" className="text-dark-white">
                                                                                InActive
                                                                            </Badge>
                                                                        )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="6">
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <strong>Subscription Status</strong>
                                                                </td>
                                                                <td>
                                                                    {(contactDetails && contactDetails.subscriptionStatus) || "N/A"}

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Unsubscribed On</strong>
                                                                </td>
                                                                <td>
                                                                    {(contactDetails && contactDetails.optOutDateTime) ? moment(contactDetails.optOutDateTime).format('DD MMM YYYY') : "N/A"}

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Unsubscription Reason</strong>
                                                                </td>
                                                                <td>
                                                                    {(contactDetails && contactDetails.optOutReason) || "N/A"}

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Created By</strong>
                                                                </td>
                                                                <td>
                                                                    {(contactDetails && contactDetails.createdBy) || "N/A"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Created Time</strong>
                                                                </td>
                                                                <td>
                                                                    {contactDetails && contactDetails.createdTimeStamp ? moment(contactDetails && contactDetails.createdTimeStamp).format('DD MMM YYYY',) : 'NA'}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className='py-2 fw-bold fs-5 text-upercase'>
                                        Campaign List
                                    </div>
                                    <Col xl='12'>
                                        <Table className='table table-striped table-hover table-nowrap w-100'>
                                            <thead>
                                                <tr>
                                                    <th>Campaign Name</th>
                                                    <th>Status</th>
                                                    <th>Created On</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {contactDetails && contactDetails.campaignlst ?
                                                    contactDetails.campaignlst.map((data, index) => (
                                                        <tr key={index} style={{ cursor: 'pointer' }} onDoubleClick={(e) => handleRouteCampaignDetails(e, data.campaignId)}>
                                                            <td>{data.campaignName}</td>
                                                            <td>{data.status === "Open" ?
                                                                <Badge color='success'>Open</Badge>
                                                                : data.status === "Closed" ? <Badge color='danger'>Closed</Badge>
                                                                    : "N/A"
                                                            }</td>
                                                            <td>
                                                                {data.createdTimeStamp ? moment(data.createdTimeStamp).format('DD MMM YYYY') : "N/A"}
                                                            </td>
                                                        </tr>))
                                                    : <tr>
                                                        <td colSpan={50}>
                                                            <NoRecord />
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </>
                        }
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    contactDetails: state.massmailerContactList.contactDetails,
    isContactDetailsPending: state.massmailerContactList.isContactDetailsPending,
    errorMessage: state.massmailerContactList.contactDetailsError,
});

const mapDispatchToProps = (dispatch) => ({
    getContactDetails: (id) => dispatch(getContactDetails(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails)