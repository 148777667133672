import { getToken, modifyAppList, modifyRoleList, modifyUserList } from "../../../../helper/common";
import { SET_CURRENT_PROFILE } from "../../../auth/login/actionTypes";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_CREATE_GROUP_ERROR, CLEAR_GROUP_DETAILS_ERROR, CLEAR_GROUP_EXPORT_LIST_ERROR, CLEAR_GROUP_LIST_ERROR, CLEAR_UPDATE_GROUP_ERROR, CLOSE_CREATE_GROUP_MODAL, CLOSE_EDIT_GROUP_MODAL, CREATE_GROUP_ERROR, CREATE_GROUP_PENDING, CREATE_GROUP_SUCCESS, OPEN_CREATE_GROUP_MODAL, OPEN_EDIT_GROUP_MODAL, SET_GROUP_DETAILS, SET_GROUP_DETAILS_ERROR, SET_GROUP_DETAILS_PENDING, SET_GROUP_EXPORT_LIST, SET_GROUP_EXPORT_LIST_ERROR, SET_GROUP_EXPORT_LIST_PENDING, SET_GROUP_LIST, SET_GROUP_LIST_ERROR, SET_GROUP_LIST_PENDING, UPDATE_GROUP_ERROR, UPDATE_GROUP_PENDING, UPDATE_GROUP_SUCCESS } from "./constants";

export const openCreateGroupModalAction = () => ({
    type: OPEN_CREATE_GROUP_MODAL,
});
export const openEditGroupModal = () => ({
    type: OPEN_EDIT_GROUP_MODAL,
});
export const closeCreateGroupModalAction = () => ({
    type: CLOSE_CREATE_GROUP_MODAL,
});
export const closeEditGroupModal = () => ({
    type: CLOSE_EDIT_GROUP_MODAL,
});
export const setGroupList = (groupList) => ({
    type: SET_GROUP_LIST,
    payload: { groupList },
})
export const setGroupExportList = (groupExportList) => ({
    type: SET_GROUP_EXPORT_LIST,
    payload: { groupExportList },
})
export const groupListError = (errorMessage) => ({
    type: SET_GROUP_LIST_ERROR,
    payload: { errorMessage },
})
export const groupExportListError = (errorMessage) => ({
    type: SET_GROUP_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const clearGroupListError = () => ({
    type: CLEAR_GROUP_LIST_ERROR,
})
export const clearGroupExportListError = () => ({
    type: CLEAR_GROUP_EXPORT_LIST_ERROR,
})
export const setGroupDetails = (groupDetails) => ({
    type: SET_GROUP_DETAILS,
    payload: { groupDetails },
})
export const groupDetailsError = (errorMessage) => ({
    type: SET_GROUP_DETAILS_ERROR,
    payload: { errorMessage },
})
export const clearGroupDetailsError = () => ({
    type: CLEAR_GROUP_DETAILS_ERROR,
})
export const createGroupError = (errorMessage) => ({
    type: CREATE_GROUP_ERROR,
    payload: { errorMessage },
});
export const createGroupPending = () => ({
    type: CREATE_GROUP_PENDING,
});
export const clearCreateGroupError = () => ({
    type: CLEAR_CREATE_GROUP_ERROR,
});
export const createGroupSuccess = () => ({
    type: CREATE_GROUP_SUCCESS,
});

export const clearUpdateGroupError = () => ({
    type: CLEAR_UPDATE_GROUP_ERROR,
});
export const updateGroupPending = () => ({
    type: UPDATE_GROUP_PENDING,
});
export const updateGroupSuccess = () => ({
    type: UPDATE_GROUP_SUCCESS,
});
export const updateGroupError = (errorMessage) => ({
    type: UPDATE_GROUP_ERROR,
    payload: { errorMessage },
});
export const setGroupListPending = () => ({
    type: SET_GROUP_LIST_PENDING,
})
export const setGroupExportListPending = () => ({
    type: SET_GROUP_EXPORT_LIST_PENDING,
})
export const setGroupDetailsPending = () => ({
    type: SET_GROUP_DETAILS_PENDING,
})
const setCurrentProfile = (currentProfile): Action => ({
    type: SET_CURRENT_PROFILE,
    payload: { currentProfile },
});



export const getGroupExportList = (searchGroupName, searchGroupEmail, searchGroupOwnerEmail) => (dispatch) => {
    dispatch(setGroupExportListPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.Groups.getGroupExportlist(accessToken, searchGroupName, searchGroupEmail, searchGroupOwnerEmail).then((groupList) => {
            // dispatch(setGroupExportList(groupList));
            dispatch(clearGroupExportListError());
            return Promise.resolve(groupList);
        })
            .catch((err) => {
                console.log('getGroupExportList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(groupExportListError(error));
            });
    });
};
export const getGroupListAction = (appId, filterData, page, PageSize, searchGroupName, searchGroupEmail, searchGroupOwnerEmail) => (dispatch) => {
    dispatch(setGroupListPending());
    getToken(dispatch).then((accessToken) => {
        agent.Groups.getGrouplist(accessToken, appId, filterData, page, PageSize, searchGroupName, searchGroupEmail, searchGroupOwnerEmail).then((groupList) => {
            console.log("groupList:", groupList);

            dispatch(setGroupList(groupList));
            dispatch(clearGroupListError());
        })
            .catch((err) => {
                console.log('getGroupListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(groupListError(error));
            });
    });
};

export const getGroupDetailsAction = (id) => (dispatch) => {
    dispatch(setGroupDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.Groups.getGroupDetails(accessToken, id).then(
            (groupDetails) => {
                dispatch(setGroupDetails(groupDetails));
                dispatch(clearGroupDetailsError());
            })
            .catch((err) => {
                console.log('getGroupDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(groupDetailsError(error));
            });
    });
}

export const getRoleNameAsyncSelect = (searchValue, callback, roles) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Groups.getRoleName(accessToken, searchValue)
                .then((RoleList) => {
                    // console.log("RoleList:", RoleList);
                    // console.log("roles:", roles);
                    const { value } = RoleList;
                    let listOfRoles;
                    if (roles && roles.filter((item) => item && item.toLowerCase() !== "super admin").length > 0) {
                        listOfRoles = value;
                    }
                    else {
                        listOfRoles = value && value.filter((item) => item.RoleName && item.RoleName.toLowerCase() !== "super admin");
                    }
                    // console.log("listOfRoles: ", listOfRoles)
                    const updatedRoleList = modifyRoleList(listOfRoles);
                    //eslint-disable-next-line
                    callback && callback(updatedRoleList);
                })
                .catch((err) => {
                    console.log('getRoleNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getAppNameAsyncSelect = (Role, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Groups.getAppName(accessToken, Role, searchValue)
            .then((appList) => {
                const { value } = appList;
                console.log(Role, value)
                let totalApp = [];
                if (Role === "User") {
                    for (const list of value) {
                        const { AppName } = list
                        if (AppName !== "Admin") {
                            totalApp.push(list)
                        }
                    }
                }
                else {
                    totalApp = value;
                }
                // console.log("totalApp:", totalApp);
                const updatedAppList = modifyAppList(totalApp);
                // console.log("updatedAppList:", updatedAppList);
                //eslint-disable-next-line
                callback && callback(updatedAppList);
            })
            .catch((err) => {
                console.log('getAppNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                //eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getUserNameAsyncSelect = (appId, Role, searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Groups.getOrgUserName(accessToken, appId, searchValue)
                .then((UserList) => {
                    const { value } = UserList;
                    let totalUsers = [];
                    if (Role === "Administrator") {
                        for (const list of value) {
                            const { IsGlobalReadOnlyUser } = list
                            if (IsGlobalReadOnlyUser !== true) {
                                totalUsers.push(list)
                            }
                        }
                    }
                    else {
                        totalUsers = value;
                    }
                    console.log("totalUsers:", totalUsers);

                    const updatedUserList = modifyUserList(totalUsers);
                    //eslint-disable-next-line
                    callback && callback(updatedUserList);
                })
                .catch((err) => {
                    console.log('getUserNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };

export const CreateGroups = (formFields, appId, filterData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createGroupError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = {
            ...formFields,
        }
    }
    dispatch(createGroupPending());
    dispatch(clearCreateGroupError());
    getToken(dispatch).then((accessToken) => {
        agent.Groups.createGroup(accessToken, dataToSend)
            .then(() => {
                dispatch(createGroupSuccess());
                dispatch(clearForm());
                dispatch(closeCreateGroupModalAction());
                dispatch(getGroupListAction(appId, filterData));
                // dispatch(getGroupExportList());
                dispatch(showSnackbarStatus('Group created successfully'));

                agent.Auth.getCurrentUser(accessToken).then((currentUser) => {
                    // console.log("currentUser:", currentUser);
                    dispatch(setCurrentProfile(currentUser));
                }).catch((err) => {
                    console.log("err:", err.response);
                    console.log('Get Current User server error', err);
                    dispatch(showSnackbarStatus('Current Profile server error'));
                });
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createGroupError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createGroupError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Create Group server error', err);
                dispatch(clearForm());
                dispatch(closeCreateGroupModalAction());
            });
    });
};

export const EditGroups = (formFields) => (dispatch) => {
    const dataToSend = {
        ...formFields
    }
    dispatch(clearUpdateGroupError());
    dispatch(updateGroupPending());
    getToken(dispatch).then((accessToken) => {
        agent.Groups.editGroup(accessToken, dataToSend)
            .then(() => {
                dispatch(updateGroupSuccess());
                dispatch(closeEditGroupModal());
                dispatch(getGroupDetailsAction(formFields.Id));
                agent.Auth.getCurrentUser(accessToken).then((currentUser) => {
                    // console.log("currentUser:", currentUser);
                    dispatch(setCurrentProfile(currentUser));
                }).catch((err) => {
                    console.log("err:", err.response);
                    console.log('Get Current User server error', err);
                    dispatch(showSnackbarStatus('Current Profile server error'));
                });
                dispatch(showSnackbarStatus('Group details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateGroupError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateGroupError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Edit Groups server error', err);
                dispatch(updateGroupError(null));
                dispatch(closeEditGroupModal());
            });
    });
};