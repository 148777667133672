import React, { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";

export const ElementToolTip = ({ name, id }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const handleScroll = () => {
        if (tooltipOpen) {
            setTooltipOpen(false);
        }
    };

    useEffect(() => {
        if (tooltipOpen) {
            window.addEventListener('wheel', handleScroll);
        }
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [tooltipOpen])

    return (
        <>
            <Tooltip
                isOpen={tooltipOpen}
                target={id}
                toggle={toggleTooltip}
            >
                {name}
            </Tooltip>
        </>
    )
}