export const OPEN_CREATE_MILESTONE_MODAL = "OPEN_CREATE_MILESTONE_MODAL"
export const CLOSE_CREATE_MILESTONE_MODAL = "CLOSE_CREATE_MILESTONE_MODAL"
export const OPEN_EDIT_MILESTONE_MODAL = "OPEN_EDIT_MILESTONE_MODAL"
export const CLOSE_EDIT_MILESTONE_MODAL = "CLOSE_EDIT_MILESTONE_MODAL"
export const CREATE_MILESTONE_ERROR = "CREATE_MILESTONE_ERROR"
export const CLEAR_MILESTONE_ERROR = "CLEAR_MILESTONE_ERROR"
export const CREATE_MILESTONE_PENDING = "CREATE_MILESTONE_PENDING"
export const CREATE_MILESTONE_SUCCESS = "CREATE_MILESTONE_SUCCESS"
export const SET_MILESTONE_PENDING = "SET_MILESTONE_PENDING"
export const SET_MILESTONE_LIST = "SET_MILESTONE_LIST"
export const CLEAR_MILESTONE_LIST_ERROR = "CLEAR_MILESTONE_LIST_ERROR"
export const CLEAR_MILESTONE_LIST = "CLEAR_MILESTONE_LIST"
export const MILESTONE_LIST_ERROR = "MILESTONE_LIST_ERROR"
export const SET_MILESTONE_DETAILS = "SET_MILESTONE_DETAILS"
export const CLEAR_MILESTONE_DETAILS_ERROR = "CLEAR_MILESTONE_DETAILS_ERROR"
export const SET_MILESTONE_DETAILS_ERROR = "SET_MILESTONE_DETAILS_ERROR"
export const CLEAR_UPDATE_MILESTONE_ERROR = "CLEAR_UPDATE_MILESTONE_ERROR"
export const UPDATE_MILESTONE_PENDING = "UPDATE_MILESTONE_PENDING"
export const UPDATE_MILESTONE_SUCCESS = "UPDATE_MILESTONE_SUCCESS"
export const UPDATE_MILESTONE_ERROR = "UPDATE_MILESTONE_ERROR"
export const SET_MILESTONE_DETAILS_PENDING = "SET_MILESTONE_DETAILS_PENDING"
export const SET_PROJECT_MILESTONE_LIST = "SET_PROJECT_MILESTONE_LIST"
export const SET_PROJECT_MILESTONE_PENDING = "SET_PROJECT_MILESTONE_PENDING"
export const PROJECT_MILESTONE_LIST_ERROR = "PROJECT_MILESTONE_LIST_ERROR"
export const CLEAR_PROJECT_MILESTONE_LIST_ERROR = "CLEAR_PROJECT_MILESTONE_LIST_ERROR"
export const SET_MILESTONE_PROJECT_DATES = "SET_MILESTONE_PROJECT_DATES"
export const CLEAR_MILESTONE_PROJECT_DATES_ERROR = "CLEAR_MILESTONE_PROJECT_DATES_ERROR"
export const SET_MILESTONE_PROJECT_DATE_ERROR = "SET_MILESTONE_PROJECT_DATE_ERROR"
export const SET_MILESTONE_PROJECT_DATES_PENDING = "SET_MILESTONE_PROJECT_DATES_PENDING"
export const CLEAR_MILESTONE_PROJECT_DATE = "CLEAR_MILESTONE_PROJECT_DATE"
export const SET_MILESTONE_EXPORT_PENDING = "SET_MILESTONE_EXPORT_PENDING"
export const SET_MILESTONE_EXPORT_LIST = "SET_MILESTONE_EXPORT_LIST"
export const CLEAR_MILESTONE_EXPORT_LIST_ERROR = "CLEAR_MILESTONE_EXPORT_LIST_ERROR"
export const MILESTONE_EXPORT_LIST_ERROR = "MILESTONE_EXPORT_LIST_ERROR"
export const MILESTONE_EXPORT_SUCCESS = "MILESTONE_EXPORT_SUCCESS"
export const OPEN_CLONE_MILESTONE_MODAL = "OPEN_CLONE_MILESTONE_MODAL"
export const CLOSE_CLONE_MILESTONE_MODAL = "CLOSE_CLONE_MILESTONE_MODAL"
export const CLEAR_MILESTONE_DETAILS = "CLEAR_MILESTONE_DETAILS"
export const IS_STATUS_MILESTONE_EDIT_OPEN = "IS_STATUS_MILESTONE_EDIT_OPEN"
export const CLEAR_STATUS_UPDATE_MILESTONE_ERROR = "CLEAR_STATUS_UPDATE_MILESTONE_ERROR"
export const UPDATE_STATUS_MILESTONE_PENDING = "UPDATE_STATUS_MILESTONE_PENDING"
export const UPDATE_STATUS_MILESTONE_SUCCESS = "UPDATE_STATUS_MILESTONE_SUCCESS"
export const UPDATE_STATUS_MILESTONE_ERROR = "UPDATE_STATUS_MILESTONE_ERROR"
export const CLEAR_MILESTONE_LISTS = "CLEAR_MILESTONE_LISTS"
export const CREATED_MILESTONE_NAME = "CREATED_MILESTONE_NAME"
export const CLEAR_CREATED_MIESTONE_NAME = "CLEAR_CREATED_MIESTONE_NAME"


export const MILESTONE_EXPORT_HEADERS = [
    "MILESTONE NAME",
    "PROJECT NAME",
    "DESCRIPTION",
    "MILESTONE OWNER",
    "START DATE",
    "END DATE",
];

export const STATUS_OPTIONS = [
    { label: "New", value: "New" },
    { label: "InProgress", value: "InProgress" },
    { label: "OnHold", value: "OnHold" },
    { label: "Done", value: "Done" },
]
