import { getToken, modifyCrmAccountList, modifyCrmFollowUpActionList, modifyCrmLeadCategoryList, modifyCrmLeadSourceList, modifyCrmUserList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import {
    CLEAR_LEADS_TO_ACCOUNT_PENDING,
    CLEAR_REDIRECT_TO_ACCOUNT_DETAILS,
    LEADS_CREATE_CLOSE,
    LEADS_CREATE_ERROR,
    LEADS_CREATE_ERROR_CLEAR,
    LEADS_CREATE_OPEN,
    LEADS_CREATE_PENDING,
    LEADS_CREATE_SUCCESS,
    LEADS_DETAILS_ERROR,
    LEADS_DETAILS_ERROR_CLEAR,
    LEADS_DETAILS_PENDING,
    LEADS_EXPORT_LIST_ERROR,
    LEADS_EXPORT_LIST_PENDING,
    LEADS_EXPORT_LIST_SUCCESS,
    LEADS_IMPORT_PENDING,
    LEADS_IMPORT_SUCCESS,
    LEADS_LIST_ERROR,
    LEADS_LIST_ERROR_CLEAR,
    LEADS_LIST_EXPORT_ERROR_CLEAR,
    LEADS_LIST_PENDING,
    LEADS_TO_ACCOUNT_PENDING,
    LEADS_UPDATE_CLOSE,
    LEADS_UPDATE_ERROR,
    LEADS_UPDATE_ERROR_CLEAR,
    LEADS_UPDATE_OPEN,
    LEADS_UPDATE_PENDING,
    LEADS_UPDATE_SUCCESS,
    REDIRECT_TO_ACCOUNT_DETAILS,
    SET_ACCOUNT_ID,
    SET_LEADS_DETAILS,
    SET_LEADS_EXPORT_LIST,
    SET_LEADS_LIST
} from "./constants";

//--------------------LEADS-CREATE-----------------------------------
export const openLeadsCreate = () => ({
    type: LEADS_CREATE_OPEN
})
export const closeLeadsCreate = () => ({
    type: LEADS_CREATE_CLOSE
})
export const createLeadsPending = () => ({
    type: LEADS_CREATE_PENDING
})
export const createLeadsError = (errorMessage) => ({
    type: LEADS_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateLeadsError = () => ({
    type: LEADS_CREATE_ERROR_CLEAR,
})
export const createLeadsSuccess = () => ({
    type: LEADS_CREATE_SUCCESS,
})

export const getCrmUserAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCrmUserAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmUserList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getLeadCategoryAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getLeadCategoryAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmLeadCategoryList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getLeadCategoryAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getLeadSourceAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getLeadSourceAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                console.log("value:", value);
                const updatedLeadSourceList = modifyCrmLeadSourceList(value);
                console.log("updatedLeadSourceList:", updatedLeadSourceList);
                //eslint-disable-next-line
                callback && callback(updatedLeadSourceList);
            })
            .catch((err) => {
                console.log('getLeadSourceAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getFollowActionAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getLeadFollowActionAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                console.log("value:", value);
                const updatedLeadFollowActionList = modifyCrmFollowUpActionList(value);
                console.log("updatedLeadFollowActionList:", updatedLeadFollowActionList);
                //eslint-disable-next-line
                callback && callback(updatedLeadFollowActionList);
            })
            .catch((err) => {
                console.log('getLeadSourceAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const createNewLeads = (formFields, filteredData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createLeadsError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createLeadsPending());
    dispatch(clearCreateLeadsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.createLeads(accessToken, dataToSend)
            .then(() => {
                dispatch(createLeadsSuccess());
                dispatch(closeLeadsCreate());
                dispatch(getLeadsList(filteredData));
                // dispatch(getExportLeadList());
                dispatch(showSnackbarStatus('Lead created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createLeadsError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createLeadsError('Network Error'));
                }
                console.log('Create lead server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------CONTACTS-UPDATE-----------------------------------
export const openLeadsUpdate = () => ({
    type: LEADS_UPDATE_OPEN
})
export const closeLeadsUpdate = () => ({
    type: LEADS_UPDATE_CLOSE
})
export const updateLeadsPending = () => ({
    type: LEADS_UPDATE_PENDING
})
export const updateLeadsError = (errorMessage) => ({
    type: LEADS_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateLeadsError = () => ({
    type: LEADS_UPDATE_ERROR_CLEAR,
})
export const updateLeadsSuccess = () => ({
    type: LEADS_UPDATE_SUCCESS,
})

export const updateLeads = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateLeadsError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields })
    }
    dispatch(updateLeadsPending());
    dispatch(clearUpdateLeadsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.updateLeads(accessToken, dataToSend)
            .then(() => {
                dispatch(updateLeadsSuccess());
                dispatch(closeLeadsUpdate());
                dispatch(getLeadsDetails(formFields && formFields.id));
                dispatch(showSnackbarStatus('Lead updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateLeadsError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateLeadsError('Network Error'));
                }
                console.log('Create Lead server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------LEADS-LIST-----------------------------------
export const leadsListPending = () => ({
    type: LEADS_LIST_PENDING
})
export const isImportLeadsPending = () => ({
    type: LEADS_IMPORT_PENDING
})
export const isImportLeadsSuccess = () => ({
    type: LEADS_IMPORT_SUCCESS
})
export const leadsExportListPending = () => ({
    type: LEADS_EXPORT_LIST_PENDING
})
export const leadsExportListSuccess = () => ({
    type: LEADS_EXPORT_LIST_SUCCESS
})
export const leadsListError = (errorMessage) => ({
    type: LEADS_LIST_ERROR,
    payload: { errorMessage }
})
export const leadsExportListError = (errorMessage) => ({
    type: LEADS_EXPORT_LIST_ERROR,
    payload: { errorMessage }
})
export const clearLeadsListError = () => ({
    type: LEADS_LIST_ERROR_CLEAR,
})
export const clearLeadsExportListError = () => ({
    type: LEADS_LIST_EXPORT_ERROR_CLEAR,
})
export const setLeadsList = (leadsList) => ({
    type: SET_LEADS_LIST,
    payload: { leadsList }
})
export const setLeadsExportList = (leadsExportList) => ({
    type: SET_LEADS_EXPORT_LIST,
    payload: { leadsExportList }
})

export const getLeadsList = (filteredData, page, PageSize, searchLeadName, searchLeadContact, searchLeadSource, searchEmail, searchPhoneNo, searchLeadOwnerUserName, searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName, searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy) => (dispatch) => {
    dispatch(leadsListPending());
    dispatch(clearLeadsListError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getLeadsList(accessToken, filteredData, page, PageSize, searchLeadName, searchLeadContact, searchLeadSource, searchEmail, searchPhoneNo, searchLeadOwnerUserName, searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName, searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy)
            .then((result) => {
                dispatch(setLeadsList(result))
            })
            .catch((err) => {
                console.log("err:", err.response);

                let error;
                if (err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leadsListError(error));
                console.log('Create Lead server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
export const getExportLeadList = (filter,searchLeadName, searchLeadContact, searchLeadSource, searchEmail, searchPhoneNo, searchLeadOwnerUserName,
    searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName, searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy) => (dispatch) => {
        dispatch(leadsExportListPending());

        return getToken(dispatch).then((accessToken) => {
            return agent.Crm.getLeadsExportList(accessToken, filter, searchLeadName, searchLeadContact, searchLeadSource, searchEmail, searchPhoneNo, searchLeadOwnerUserName,
                searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName, searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy)
                .then((result) => {
                    // dispatch(setLeadsExportList(result))
                    dispatch(clearLeadsExportListError());
                    dispatch(leadsExportListSuccess());
                    return Promise.resolve(result);
                })
                .catch((err) => {
                    let error;
                    if (err.response) {
                        error = err.response && err.response.body && err.response.body.message;
                    }
                    else {
                        error = "Network Error"
                    }
                    dispatch(leadsExportListError(error));
                    console.log('Create Lead Export server error', error);
                    // dispatch(closeAccountCreate());
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                });
        });
    };
export const filterLeadCategoryAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.filterLeadCategoryAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmLeadCategoryList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const filterLeadSourceAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.filterLeadSourceAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                console.log("value:", value);
                const updatedLeadSourceList = modifyCrmLeadSourceList(value);
                console.log("updatedLeadSourceList:", updatedLeadSourceList);
                //eslint-disable-next-line
                callback && callback(updatedLeadSourceList);
            })
            .catch((err) => {
                console.log('getLeadSourceAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const filterFollowActionAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.filterLeadFollowActionAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                console.log("value:", value);
                const updatedLeadFollowActionList = modifyCrmFollowUpActionList(value);
                console.log("updatedLeadFollowActionList:", updatedLeadFollowActionList);
                //eslint-disable-next-line
                callback && callback(updatedLeadFollowActionList);
            })
            .catch((err) => {
                console.log('getLeadSourceAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

//--------------------LEADS-IMPORT-----------------------------------
export const importLeads = (excelSheet,filter) => (dispatch) => {
    dispatch(isImportLeadsPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.Crm.importLeads(accessToken, excelSheet)
            .then((result) => {
                dispatch(isImportLeadsSuccess());
                dispatch(getLeadsList(filter));
                dispatch(showSnackbarStatus('Leads imported successfully!'));
                return Promise.resolve(result)
            })
            .catch((err) => {
                dispatch(isImportLeadsSuccess());
                let message;
                if (err && err.response) {
                    message = err.response && err.response.text;
                    dispatch(showSnackbarStatus(message));
                }
                else {
                    message = 'Network Error'
                    dispatch(showSnackbarStatus(message));
                }
                return Promise.reject(message)
            })
    })
}

//--------------------LEADS-DETAILS-----------------------------------
export const leadsDetailsPending = () => ({
    type: LEADS_DETAILS_PENDING
})
export const leadsDetailError = (errorMessage) => ({
    type: LEADS_DETAILS_ERROR,
    payload: { errorMessage }
})
export const clearLeadsDetailsError = () => ({
    type: LEADS_DETAILS_ERROR_CLEAR,
})
export const setLeadsDetails = (leadsDetails) => ({
    type: SET_LEADS_DETAILS,
    payload: { leadsDetails }
})

export const getLeadsDetails = (id) => (dispatch) => {
    dispatch(leadsDetailsPending());
    dispatch(clearLeadsDetailsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getLeadsDetails(accessToken, id)
            .then((result) => {
                dispatch(setLeadsDetails(result))
                // dispatch(showSnackbarStatus('Account created successfully'));
            })
            .catch((err) => {
                let error;
                if (err.response) {
                    error = err.response.text;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leadsDetailError(error));
                console.log('lead details server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------LEADS-TO-ACCOUNT-CONVERSION-----------------------------------
export const setAccountId = (id) => ({
    type: SET_ACCOUNT_ID,
    payload: { id }
})
export const convertAccountPending = () => ({
    type: LEADS_TO_ACCOUNT_PENDING
})
export const redirectToAccountDetails = () => ({
    type: REDIRECT_TO_ACCOUNT_DETAILS
})
export const clearRedirectToAccountDetails = () => ({
    type: CLEAR_REDIRECT_TO_ACCOUNT_DETAILS
})
export const clearConvertAccountPending = () => ({
    type: CLEAR_LEADS_TO_ACCOUNT_PENDING
})
export const convertAccount = (id) => (dispatch) => {
    dispatch(convertAccountPending());
    dispatch(clearLeadsDetailsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.convertAccount(accessToken, id)
            .then((result) => {
                dispatch(setAccountId(result));
                dispatch(clearConvertAccountPending());
                dispatch(redirectToAccountDetails())
                dispatch(showSnackbarStatus('Lead converted as Account successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(leadsDetailError(err.response && err.response.text));
                    return;
                }
                console.log('lead details server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};