import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearUpdateOpportunityTypeError,
    updateOpportunityType,
    updateOpportunityTypeSuccess
} from '../../../../../../store/Modules/crm/Settings/OpportunityType/actions';

const EditOpportunityType = (props) => {
    const { opportunityTypeDetails, isOpportunityTypeUpdatePending, errorMessage } = props;
    const { Id, OppTypeName, IsActive } = opportunityTypeDetails || {};

    const [oppTypeDetails, setOppTypeDetails] = useState();
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();

    useEffect(() => {
        setOppTypeDetails({
            ...oppTypeDetails,
            id: Id,
            name: OppTypeName,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateOpportunityTypeError();
        props.updateOpportunityTypeSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setOppTypeDetails({
            ...oppTypeDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        });
    }
    const handleActiveStatusChange = () => {
        setOppTypeDetails({
            ...oppTypeDetails,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { name } = oppTypeDetails || {};

        if (!name) {
            currentErrors.name = 'Opportunity type name is required';
        }

        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateOpportunityType(oppTypeDetails)
        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label>Opportunity Type Name  <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="name"
                        placeHolder="Enter opportunity type name"
                        onChange={validateOnchange}
                        value={oppTypeDetails && oppTypeDetails.name}
                        invalid={errors && errors.name}
                    />
                    <FormFeedback>{errors && errors.name}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isOpportunityTypeUpdatePending}
                    >
                        {isOpportunityTypeUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    opportunityTypeDetails: state.crmOpportunityType.opportunityTypeDetails,
    isOpportunityTypeUpdatePending: state.crmOpportunityType.isOpportunityTypeUpdatePending,
    errorMessage: state.crmOpportunityType.opportunityTypeUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateOpportunityType: (oppTypeDetails) => dispatch(updateOpportunityType(oppTypeDetails)),
    clearUpdateOpportunityTypeError: () => dispatch(clearUpdateOpportunityTypeError()),
    updateOpportunityTypeSuccess: () => dispatch(updateOpportunityTypeSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditOpportunityType);