import React, { useState } from 'react'
import VWButton from '../../../components/vwButton/VWButton'
import VWMessage from '../../../components/vwMessage/vwMessage';
import { connect } from 'react-redux';
import FormValidator from '../../../helper/formValidator';
import { createDB } from '../../../store/Modules/DB/actions';
import { Col, Input, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './DB.css';

const CreateOrguser = (props) => {

    const [formFields, setFormFields] = useState({});

    const [errors, setErrors] = useState({});

    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        if (input.name === 'dbName') {
            setErrors({ ...errors, dbName: '' });
        }
        if (input.name === 'login') {
            setErrors({ ...errors, login: '' });
        }
        if (input.name === 'pwd') {
            setErrors({ ...errors, pwd: '' });
        }
        if (input.name === 'serverName') {
            setErrors({ ...errors, serverName: '' });
        }
        let result = FormValidator.validate(input);
        setFormFields({
            ...formFields, [input.name]: value, errors: {
                ...(formFields && formFields.errors),
                [input.name]: result,
            },
        });
    };


    const handleCreateDatabaseName = () => {

        const newErrors = {};
        if (!(formFields && formFields.dbName) || !(formFields.dbName && formFields.dbName.trim())) {
            newErrors.dbName = 'Database Name is required';
        }
        if (!(formFields && formFields.serverName) || !(formFields.serverName && formFields.serverName.trim())) {
            newErrors.serverName = 'Server Name is required';
        }
        if (!(formFields && formFields.login) || !(formFields.pwd && formFields.login.trim())) {
            newErrors.login = 'Login is required';
        }
        if (!(formFields && formFields.pwd) || !(formFields.pwd && formFields.pwd.trim())) {
            newErrors.pwd = 'Password is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.createDB(formFields);
        }
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <Row>
                <Col md={12}>
                    <div className="form-group">
                        <label className="col-form-label">
                            Database Name <span className="text-danger"> *</span>
                        </label>
                        <Input
                            type="text"
                            name="dbName"
                            placeholder="Enter Database Name"
                            onChange={validateOnChange}
                            value={formFields && formFields.dbName}
                        />
                        {<span className='text-danger'><small>{errors && errors.dbName}</small></span>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="form-group">
                        <label className="col-form-label">
                            Server Name <span className="text-danger"> *</span>
                        </label>
                        <Input
                            type="text"
                            name="serverName"
                            placeholder="Enter Server Name"
                            onChange={validateOnChange}
                            value={formFields && formFields.serverName}
                        />
                        {<span className='text-danger'><small>{errors && errors.serverName}</small></span>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="form-group">
                        <label className="col-form-label">
                            Login <span className="text-danger"> *</span>
                        </label>
                        <Input
                            type="text"
                            name="login"
                            placeholder="Enter Login"
                            onChange={validateOnChange}
                            value={formFields && formFields.login}
                        />
                        {<span className='text-danger'><small>{errors && errors.login}</small></span>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <label className="col-form-label">
                        Password <span className="text-danger"> *</span>
                    </label>
                    <div className="password-input-container">
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            name="pwd"
                            placeholder="Enter Password"
                            onChange={validateOnChange}
                            value={formFields && formFields.pwd}
                        />
                        <div className="password-toggle-icon-container" onClick={togglePasswordVisibility}>
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="password-toggle-icon"
                            />
                        </div>
                        {<span className='text-danger'><small>{errors && errors.pwd}</small></span>}
                    </div>
                </Col>
            </Row>
            <br></br>
            <div className="form-group">
                <VWButton
                    messageKey="createDBButton"
                    defaultMessage="Create"
                    buttonType="success"
                    baseClassName="btn"
                    className="me-2"
                    onClick={handleCreateDatabaseName}
                ></VWButton>
                <VWButton
                    messageKey="cancel Org User"
                    defaultMessage="Cancel"
                    buttonType="danger"
                    baseClassName="btn"
                    onClick={props.handleClose}

                ></VWButton>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isPending: state.dbSync.isPending,
    errorMessage: state.dbSync.errorMessage,
    currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    createDB: (formFields) => dispatch(createDB(formFields)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrguser)