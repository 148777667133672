const trimmedValues = (data) => {
    const result = {};
    for (let key in data) {
        if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
            result[key] = data[key].trim();
        } else {
            result[key] = data[key];
        }
    }
    return result;
}
export default trimmedValues;