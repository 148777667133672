import React, { useEffect, useState } from 'react'
import { Button, Form, FormGroup, Input, InputGroup, Label } from 'reactstrap'
import VWButton from '../../../components/vwButton/VWButton'
import { connect } from 'react-redux'
import { clearChangePasswordError, clearChangePasswordErrors, closeModalChangePassword, getChangePasswordAction, isChangePasswordSuccess } from '../../../store/actions'
import VWMessage from '../../../components/vwMessage/vwMessage'

const ChangePassword = (props) => {
    const { userEmail } = props.profile;

    const [formFields, setFormFields] = useState({ emailId: userEmail });
    const [errors, setErrors] = useState({});
    const [showPasword1, setshowPasword1] = useState(true);
    const [showPasword2, setshowPasword2] = useState(true);
    const [showPasword3, setshowPasword3] = useState(true);

    useEffect(() => {
        props.isChangePasswordSuccess();
        props.clearChangePasswordError();
    }, [])

    //---------------------------------------Input Field Update--------------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'emailId') {
            setErrors({ ...errors, emailId: '' });
        }
        if (input.name === 'oldPassword') {
            setErrors({ ...errors, oldPassword: '' });
        }
        if (input.name === 'newPassword') {
            setErrors({ ...errors, newPassword: 'Password must be eight character' });
            if (value && value.length >= 8) {
                setErrors({ ...errors, newPassword: '' });
            }
            // if (value !== (formFields && formFields.confirmPassword)){
            //     setErrors({ ...errors, confirmPassword: 'Confirm password does not match' });
            // }
            // if (value === (formFields && formFields.confirmPassword)){
            //     setErrors({ ...errors, confirmPassword: '' });
            // }
        }
        if (input.name === 'confirmPassword') {
            setErrors({ ...errors, confirmPassword: 'Confirm password does not match' });
            if (value === (formFields && formFields.newPassword)) {
                setErrors({ ...errors, confirmPassword: '' });
            }
        }
    };

    //------------------------------------------Create Project----------------------------------------------------
    const handleChangePassword = (e) => {
        e.preventDefault();
        props.clearChangePasswordError();
        const newErrors = {};

        if (!formFields.emailId || !formFields.emailId.trim()) {
            newErrors.emailId = 'Email is required';
        }
        if (!formFields.oldPassword || !formFields.oldPassword.trim()) {
            newErrors.oldPassword = 'Old password is required';
        }
        if (!formFields.newPassword || !formFields.newPassword.trim()) {
            newErrors.newPassword = 'New password is required';
        }
        if ((formFields && formFields.newPassword && formFields.newPassword.length < 8)) {
            newErrors.newPassword = 'New password must be eight character';
        }
        if (((formFields && formFields.confirmPassword) !== (formFields && formFields.newPassword))) {
            newErrors.confirmPassword = 'Confirm password does not match';
        }
        if (!formFields.confirmPassword || !formFields.confirmPassword.trim()) {
            newErrors.confirmPassword = 'Confirm password is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.ChangePasswordAction(formFields);
        }
    }
    console.log("formFields:", formFields)
    return (
        <>
            {props.changePasswordErrorMessage && (
                <VWMessage type="danger" defaultMessage={props.changePasswordErrorMessage} baseClassName="alert"></VWMessage>
            )}
            <Form onSubmit={handleChangePassword}>
                <div className="mt-2">
                    <Label>Email <span className="text-danger">*</span></Label>
                    <Input
                        type="email"
                        name="emailId"
                        className="border-right-0"
                        placeholder="Enter email address"
                        onChange={validateOnChange}
                        value={userEmail}
                        disabled
                    />
                    {<span className='text-danger'><small>{errors && errors.emailId}</small></span>}
                </div>
                <div className="mt-2">
                    <FormGroup>
                        <Label>Old Password <span className="text-danger">*</span></Label>
                        <InputGroup>
                            <Input
                                type={showPasword1 ? "password" : "text"}
                                name="oldPassword"
                                className={errors && errors.oldPassword ? "border-danger border-end-0" : 'border-end-0 border-secondary'}
                                placeholder="Enter old password"
                                onChange={validateOnChange}
                            />
                            <Button
                                className={errors && errors.oldPassword ? "border-danger border-start-0 rounded-end" : 'border-secondary border-start-0 rounded-end'}
                                color='white'
                                onClick={() => setshowPasword1(!showPasword1)}
                            >
                                {showPasword1 ?
                                    <i className="fas fa-eye text-secondary"></i>
                                    :
                                    <i class="fas fa-eye-slash text-secondary"></i>
                                }
                            </Button>
                        </InputGroup>
                        {<span className='text-danger'><small>{errors && errors.oldPassword}</small></span>}
                    </FormGroup>
                </div>
                <div className="mt-2">
                    <FormGroup>
                        <Label>New Password <span className="text-danger">*</span></Label>
                        <InputGroup>
                            <Input
                                type={showPasword2 ? "password" : "text"}
                                name="newPassword"
                                className={errors && errors.newPassword ? "border-danger border-end-0" : 'border-end-0 border-secondary'}
                                placeholder="Enter new password"
                                onChange={validateOnChange}
                            />
                            <Button
                                className={errors && errors.newPassword ? "border-danger border-start-0 rounded-end" : 'border-secondary border-start-0 rounded-end'}
                                color='white'
                                onClick={() => setshowPasword2(!showPasword2)}
                            >
                                {showPasword2 ?
                                    <i className="fas fa-eye text-secondary"></i>
                                    :
                                    <i class="fas fa-eye-slash text-secondary"></i>
                                }
                            </Button>
                        </InputGroup>
                        {<span className='text-danger'><small>{errors && errors.newPassword}</small></span>}
                    </FormGroup>
                </div>
                <div className="mt-2">
                    <FormGroup>
                        <Label>Confirm New Password <span className="text-danger">*</span></Label>
                        <InputGroup>
                            <Input
                                type={showPasword3 ? "password" : "text"}
                                name="confirmPassword"
                                className={errors && errors.confirmPassword ? "border-danger border-end-0" : 'border-end-0 border-secondary'}
                                placeholder="Enter confirm new password"
                                onChange={validateOnChange}
                            />
                            <Button
                                className={errors && errors.confirmPassword ? "border-danger border-start-0 rounded-end" : 'border-secondary border-start-0 rounded-end'}
                                color='white'
                                onClick={() => setshowPasword3(!showPasword3)}
                            >
                                {showPasword3 ?
                                    <i className="fas fa-eye text-secondary"></i>
                                    :
                                    <i class="fas fa-eye-slash text-secondary"></i>
                                }
                            </Button>
                        </InputGroup>
                        {<span className='text-danger'><small>{errors && errors.confirmPassword}</small></span>}
                    </FormGroup>
                </div>
                <br />
                {/* <div className="form-group">
                    <VWButton
                        messageKey="createTimesheetButton"
                        defaultMessage="Save"
                        buttonType="success"
                        baseClassName="btn"
                        className="me-2"
                        onClick={handleChangePassword}
                    // isLoading={props.isPending}
                    // isDisabled={props.isPending}
                    ></VWButton>
                    <VWButton
                        messageKey="cancelTimesheetButton"
                        defaultMessage="Cancel"
                        buttonType="danger"
                        baseClassName="btn"
                        onClick={props.handleClose}
                    // isDisabled={props.isPending}
                    ></VWButton>
                </div> */}
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleChangePassword}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}
const mapStateToProps = (state) => ({
    profile: state.login.currentProfile,
    changePasswordErrorMessage: state.login.changePasswordErrorMessage,
    isPending: state.login.changePasswordPending,
});

const mapDispatchToProps = (dispatch) => ({
    ChangePasswordAction: (formFields) => dispatch(getChangePasswordAction(formFields)),
    clearChangePasswordError: () => dispatch(clearChangePasswordErrors()),
    isChangePasswordSuccess: () => dispatch(isChangePasswordSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)