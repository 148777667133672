import moment from "moment-timezone";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ElementToolTip } from "../../Projects/vwToolTip";

const EmployeeCard = ({ data }) => {
    console.log("data:", data);

    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/employees/employee/details/${Id}`);
    };
    return (
        <div className="employee-card" onDoubleClick={() => OnDoubleClickAction(data.Id)} style={{ cursor: 'pointer' }}>
            <>
                <div className="employee-card-header">
                    <Avatar src={data.ProfilePicture || null} name={data.ProfilePicture ? null : data.EmployeeDisplayName} round size="40" />
                    <div className="employee-info ms-2">
                        <h5>
                            {data.IsUserActive ? '' :
                                <>
                                    <span className="online-status me-1" id={`empstatus-${data.Id}`}></span><ElementToolTip id={`empstatus-${data.Id}`} name={`Inactive`} />
                                </>}
                            <span id={`empusername-${data.Id}`}>{data.EmployeeDisplayName || "N/A"}</span> <ElementToolTip id={`empusername-${data.Id}`} name={`Name: ${data.EmployeeDisplayName}`} />
                        </h5>
                        <p><span id={`empuseremail-${data.Id}`}>{data.UserEmail || "N/A"}</span><ElementToolTip id={`empuseremail-${data.Id}`} name={`Email: ${data.UserEmail}`} /></p>
                        {data.EmployeeTitle ? <>
                            <p><span id={`empdesg-${data.Id}`}>{data.EmployeeTitle || "N/A"}</span><ElementToolTip id={`empdesg-${data.Id}`} name={`Designation: ${data.EmployeeTitle || 'N/A'}`} /></p>
                        </>
                            :
                            ''}
                    </div>
                </div>
            </>

            <div className="card-details m-1">
                <Row>
                    <Col md={6}>
                        <div>
                            <p><strong>Employment Type</strong></p>
                            <p>{data.EmployeeType || "N/A"}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div>
                            <p><strong>Manager</strong></p>
                            <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><span id={`empmanager-${data.Id}`}>{data.ManagerUserName || "N/A"}</span><ElementToolTip id={`empmanager-${data.Id}`} name={`${data.ManagerUserName || 'N/A'}`} /></p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div>
                            <p><strong>DOB: {data.DateOfBirth ? moment(data.DateOfBirth).format("DD MMM YYYY") : "N/A"}</strong></p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div>
                            <p><strong>DOJ: {data.DateOfJoin ? moment(data.DateOfJoin).format("DD MMM YYYY") : "N/A"}</strong></p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div>
                            <span className={`toggle ${data.EnableMailNotification ? "on" : "off"}`}>
                                Notification {data.EnableMailNotification ? "On" : "Off"}
                            </span>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div>
                            <span className={`toggle ${data.IsPortalEnable ? "on" : "off"}`}>
                                Account {data.IsPortalEnable ? "Active" : "Inactive"}
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default EmployeeCard