import { CLEAR_EMPLOYEE_DETAILS_ERROR, CLEAR_EMPLOYEE_ERROR, CLEAR_EMPLOYEE_EXPORT_LIST_ERROR, CLEAR_EMPLOYEE_LIST_ERROR, CLEAR_EMPLOYEE_USER_ID_LIST_ERROR, CLEAR_UPDATE_EMPLOYEE_ERROR, CLOSE_CREATE_EMPLOYEE_MODAL, CLOSE_EDIT_EMPLOYEE_MODAL, CREATE_EMPLOYEE_ERROR, CREATE_EMPLOYEE_PENDING, CREATE_EMPLOYEE_SUCCESS, OPEN_CREATE_EMPLOYEE_MODAL, OPEN_EDIT_EMPLOYEE_MODAL, SET_EMPLOYEE_DETAILS, SET_EMPLOYEE_DETAILS_PENDING, SET_EMPLOYEE_EXPORT_LIST, SET_EMPLOYEE_EXPORT_PENDING, SET_EMPLOYEE_LIST, SET_EMPLOYEE_PENDING, SET_EMPLOYEE_USERID_LIST, SET_EMPLOYEE_USERID_PENDING, SET_ORG_EMPLOYEE_DETAILS_ERROR, SET_ORG_EMPLOYEE_EXPORT_LIST_ERROR, SET_ORG_EMPLOYEE_LIST_ERROR, SET_ORG_EMPLOYEE_USER_ID_LIST_ERROR, UPDATE_EMPLOYEE_ERROR, UPDATE_EMPLOYEE_PENDING, UPDATE_EMPLOYEE_SUCCESS } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    EmployeeList: [],
    employeeListError: null,
    totalRecords: 0,
    isCreateEmployeeModalOpen: false,
    EmployeeDetails: null,
    employeeDetailsError: null,
    isEditEmployeeModalOpen: false,
    updateEmployeeErrorMessage: null,
    isUpdateEmployeePending: false,
    isGetEmployeePending: false,
    isGetEmployeeDetailsPending: false,
    employeeExportListError: null,
    isGetEmployeeExportPending: false,
    EmployeeExportList: [],
    employeeUserIdListError: null,
    isGetEmployeeUserIdPending: false,
    employeeUserIDList: [],
};

export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_EMPLOYEE_EXPORT_LIST: {
            const { employeeList } = action.payload;
            return {
                ...localState,
                isGetEmployeeExportPending: false,
                EmployeeExportList: employeeList,
            }
        }
        case SET_EMPLOYEE_USERID_LIST: {
            const { employeeUserIDList } = action.payload;
            return {
                ...localState,
                isGetEmployeeUserIdPending: false,
                employeeUserIDList: employeeUserIDList && employeeUserIDList.value,
                // totalRecords: employeeUserIDList["@odata.count"],
            }
        }
        case SET_EMPLOYEE_LIST: {
            const { employeeList } = action.payload;
            return {
                ...localState,
                isGetEmployeePending: false,
                EmployeeList: employeeList && employeeList.value,
                totalRecords: employeeList["@odata.count"],
            }
        }
        case SET_ORG_EMPLOYEE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                EmployeeList: [],
                isGetEmployeePending: false,
                employeeListError: errorMessage,
            };
        }
        case SET_ORG_EMPLOYEE_USER_ID_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                employeeUserIDList: [],
                isGetEmployeeUserIdPending: false,
                employeeUserIdListError: errorMessage,
            };
        }
        case SET_ORG_EMPLOYEE_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                EmployeeExportList: [],
                isGetEmployeeExportPending: false,
                employeeExportListError: errorMessage,
            };
        }
        case CLEAR_EMPLOYEE_LIST_ERROR: {
            return {
                ...localState,
                employeeListError: null,
            };
        }
        case CLEAR_EMPLOYEE_USER_ID_LIST_ERROR: {
            return {
                ...localState,
                employeeUserIdListError: null,
            };
        }
        case CLEAR_EMPLOYEE_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                employeeExportListError: null,
                isGetEmployeeExportPending: false,
            };
        }
        case OPEN_CREATE_EMPLOYEE_MODAL: {
            return {
                ...localState,
                isCreateEmployeeModalOpen: true,
            };
        }
        case OPEN_EDIT_EMPLOYEE_MODAL: {
            return {
                ...localState,
                isEditEmployeeModalOpen: true,
            };
        }
        case CLOSE_EDIT_EMPLOYEE_MODAL: {
            return {
                ...localState,
                updateEmployeeErrorMessage: null,
                isEditEmployeeModalOpen: false,
            };
        }
        case CLOSE_CREATE_EMPLOYEE_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateEmployeeModalOpen: false,
            };
        }
        case SET_EMPLOYEE_DETAILS: {
            const { EmployeeDetails } = action.payload;
            return {
                ...localState,
                isGetEmployeeDetailsPending: false,
                EmployeeDetails: EmployeeDetails,
            }
        }
        case SET_ORG_EMPLOYEE_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                EmployeeDetails: {},
                isGetEmployeeDetailsPending: false,
                employeeDetailsError: errorMessage,
            };
        }
        case CLEAR_EMPLOYEE_DETAILS_ERROR: {
            return {
                ...localState,
                employeeDetailsError: null,
            };
        }
        case CREATE_EMPLOYEE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_EMPLOYEE_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CREATE_EMPLOYEE_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_EMPLOYEE_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CLEAR_UPDATE_EMPLOYEE_ERROR: {
            return {
                ...localState,
                updateEmployeeErrorMessage: null,
                isUpdateEmployeePending: false,
            };
        }
        case UPDATE_EMPLOYEE_PENDING: {
            return {
                ...localState,
                isUpdateEmployeePending: true,
            };
        }
        case UPDATE_EMPLOYEE_SUCCESS: {
            return {
                ...localState,
                isUpdateEmployeePending: false,
            };
        }
        case UPDATE_EMPLOYEE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateEmployeeErrorMessage: errorMessage,
                isUpdateEmployeePending: false,
            };
        }
        case SET_EMPLOYEE_PENDING: {
            return {
                ...localState,
                employeeListError: null,
                isGetEmployeePending: true,
            };
        }
        case SET_EMPLOYEE_USERID_PENDING: {
            return {
                ...localState,
                employeeUserIdListError: null,
                isGetEmployeeUserIdPending: true,
            };
        }
        case SET_EMPLOYEE_EXPORT_PENDING: {
            return {
                ...localState,
                employeeExportListError: null,
                isGetEmployeeExportPending: true,
            };
        }
        case SET_EMPLOYEE_DETAILS_PENDING: {
            return {
                ...localState,
                employeeDetailsError: null,
                isGetEmployeeDetailsPending: true,
            };
        }
        default: {
            return localState;
        }
    }
};