import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Badge, Card, CardBody, CardFooter, CardTitle, Container, Input, Table } from 'reactstrap'
import moment from 'moment';
import { closeCreateAdminUserModalAction, getAdminUserListAction, openCreateAdminUserModalAction } from '../../../../../store/Modules/Admin/AdminUser/actions'
import { clearForm } from '../../../../../store/form/actions'
import { getPager } from '../../../../../helper/common'
import VWPagination from '../../../../../components/vwPagination/vwPagination';
import { Link } from 'react-router-dom';
import VWModel from '../../../../../components/Modal/VWModal';
import CreateAdminUser from './CreateAdminUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const AdminUser = (props) => {

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState({});

    const buildQueryOrgName = () => {
        const { OrgName } = filter;
        let searchOrgName = '';
        if (OrgName) {
            searchOrgName += `${OrgName}`;
        }
        return searchOrgName
    }
    const buildQueryUserEmail = () => {
        const { UserEmail } = filter;
        let searchUserEmail = '';
        if (UserEmail) {
            searchUserEmail += `${UserEmail}`;
        }
        return searchUserEmail
    }
    const buildQueryCreatedBy = () => {
        const { CreatedBy } = filter;
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }
    const buildQueryCreatedDateTime = () => {
        const { CreatedDateTime } = filter;
        let searchCreatedDateTime = '';
        if (CreatedDateTime) {
            searchCreatedDateTime += `${CreatedDateTime}`;
        }
        return searchCreatedDateTime
    }
    const buildQueryIsPortalEnable = () => {
        const { IsPortalEnable } = filter;
        let searchIsPortalEnable = '';
        if (IsPortalEnable) {
            searchIsPortalEnable += `${IsPortalEnable}`;
        }
        return searchIsPortalEnable
    }
    const buildQueryRoleDetails = () => {
        const { RoleDetails } = filter;
        let searchRoleDetails = '';
        if (RoleDetails) {
            searchRoleDetails += `${RoleDetails}`;
        }
        return searchRoleDetails
    }

    const setPage = (page = 1) => {
        props.getAdminUserList(page, PageSize, buildQueryOrgName(), buildQueryUserEmail(), buildQueryCreatedBy(), buildQueryCreatedDateTime(), buildQueryIsPortalEnable(), buildQueryRoleDetails());

        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
    }, [])
    //------------------------------------------Filter-----------------------------------------------------------
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    useEffect(() => {
        if (filter.CreatedDateTime || filter.IsPortalEnable || filter.RoleDetails || filter.OrgName || filter.UserEmail || filter.CreatedBy || filter) {
            handleSearchClick();
        }
    }, [filter.CreatedDateTime, filter.IsPortalEnable, filter.RoleDetails, filter.OrgName, filter.UserEmail, filter.CreatedBy, filter])

    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedAdminUserList = [...props.AdminUserList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsPortalEnable') {
            return sortDirection === 'asc' ? b.IsPortalEnable - a.IsPortalEnable : a.IsPortalEnable - b.IsPortalEnable;
        }
        if (sortOrder === 'RoleDetails') {
            const aRole = a.RoleDetails.map((items) => items.RoleName).join(" , ");
            const bRole = b.RoleDetails.map((items) => items.RoleName).join(" , ");
            return sortDirection === 'asc' ? aRole.localeCompare(bRole) : bRole.localeCompare(aRole);
        }
        if (sortOrder === 'Days') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //-----------------------------------------Modal open & close-----------------------------------------------------

    const handleOpenCreateAdminUser = () => {
        props.openCreateAdminUserModal();
    };
    const handleClose = () => {
        props.clearFormAction()
        props.closeCreateAdminUserModal();
    };

    return (
        <Container fluid>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        Admin User
                        <div className="content-heading">
                            <div className="ml-auto">
                                <Link
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    onClick={handleOpenCreateAdminUser}
                                >
                                    CREATE
                                </Link>&nbsp;
                            </div>
                        </div>
                    </CardTitle>
                </Card>
                <Card>
                    <div style={{ height: "500px", overflowX: "auto" }}>
                        <CardBody className="p-4">
                            <Table className="table table-striped table-hover w-100 " size="sm">
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <tr>
                                        <th
                                            onClick={() => handleSort('OrgName')}
                                        >
                                            Org Name &nbsp;
                                            {sortOrder === 'OrgName' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('UserEmail')}
                                        >
                                            Email &nbsp;
                                            {sortOrder === 'UserEmail' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('CreatedBy')}
                                        >
                                            Created By &nbsp;
                                            {sortOrder === 'CreatedBy' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('CreatedDateTime')}
                                        >
                                            Created Date &nbsp;
                                            {sortOrder === 'CreatedDateTime' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}
                                        </th>
                                        <th
                                            onClick={() => handleSort('IsPortalEnable')}
                                        >IsPortal Enable &nbsp;
                                            {sortOrder === 'IsPortalEnable' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th>
                                            Role
                                        </th>
                                        <th>View</th>
                                    </tr>
                                    <tr>
                                        <th><Input type='text' placeholder='Search' name='OrgName'
                                            onChange={searchInputValidation} value={filter && filter.OrgName} onKeyPress={handleKeyPress}
                                        /></th>
                                        <th><Input type='text' placeholder='Search' name='UserEmail'
                                            onChange={searchInputValidation} value={filter && filter.UserEmail} onKeyPress={handleKeyPress}
                                        /></th>
                                        <th><Input type='text' placeholder='Search' name='CreatedBy'
                                            onChange={searchInputValidation} value={filter && filter.CreatedBy} onKeyPress={handleKeyPress}
                                        /></th>
                                        <th><Input type='date' placeholder='Search' name='CreatedDateTime'
                                            onChange={searchInputValidation} value={filter && filter.CreatedDateTime} onKeyPress={handleKeyPress}
                                        /></th>
                                        <th>
                                            <select
                                                style={{ width: '130px' }}
                                                className="form-control"
                                                name="IsPortalEnable"
                                                onChange={searchInputValidation}
                                                value={filter && filter.IsPortalEnable}
                                            >
                                                <option value="">Select</option>
                                                <option value="true">Active</option>
                                                <option value="false">InActive</option>
                                            </select>
                                        </th>
                                        <th>
                                            <select
                                                style={{ width: '130px' }}
                                                className="form-control"
                                                name="RoleDetails"
                                                onChange={searchInputValidation}
                                                value={filter && filter.RoleDetails}
                                            >
                                                <option value="">Select</option>
                                                <option value="Super admin">Super admin</option>
                                                <option value="Administrator">Administrator</option>
                                                <option value="Manager">Manager</option>
                                                <option value="Employee">Employee</option>
                                                <option value="Client">Client</option>
                                                <option value="User">User</option>
                                            </select>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.AdminUserList && props.AdminUserList.length > 0 ?
                                        sortedAdminUserList && sortedAdminUserList.map((data) => (
                                            <tr >
                                                <td>{data.OrgName || "NA"}</td>
                                                <td>{data.UserEmail || "NA"}</td>

                                                <td>{data.CreatedBy || 'NA'}</td>
                                                <td>{data.CreatedDateTime ? moment(data.CreatedDateTime).format('DD MMM YYYY') : 'NA'}</td>
                                                <td> {data.IsPortalEnable ? (
                                                    <Badge color="success" className="text-dark-white">
                                                        Active
                                                    </Badge>
                                                ) : (
                                                    <Badge color="danger" className="text-dark-white">
                                                        Inactive
                                                    </Badge>
                                                )}</td>
                                                <td>{data.RoleDetails && data.RoleDetails.length > 0 ?
                                                    data.RoleDetails.map((items, index) => (
                                                        //eslint-disable-next-line
                                                        <React.Fragment key={index}>
                                                            <Badge >{items.RoleName}</Badge>&nbsp;
                                                        </React.Fragment>
                                                    ))
                                                    : "NA"}</td>
                                                <td> <Link
                                                    type="button"
                                                    className="btn btn-sm  btn-light"
                                                    to={`/admin/adminuser/details/${data.Id}`}
                                                >
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link></td>
                                            </tr>
                                        ))
                                        : ""}
                                </tbody>
                            </Table>
                        </CardBody>
                    </div>
                    <CardFooter>
                        <VWPagination pager={Pager} setPage={setPage} />
                    </CardFooter>
                </Card>
                <VWModel
                    header="CREATE ADMIN USER"
                    isOpen={props.isAdminUserCreateModalOpen}
                    handleClose={handleClose}
                >
                    <CreateAdminUser
                        handleClose={handleClose}
                    />
                </VWModel>
            </div>
        </Container>
    )
}
const mapStateToProps = (state) => ({
    isAdminUserCreateModalOpen: state.AdminUser.isCreateAdminUserModalOpen,
    AdminUserList: state.AdminUser.AdminUserList,
    totalCount: state.AdminUser.totalRecords,
});
const mapDispatchToProps = (dispatch) => ({
    closeCreateAdminUserModal: () => dispatch(closeCreateAdminUserModalAction()),
    openCreateAdminUserModal: () => dispatch(openCreateAdminUserModalAction()),
    clearFormAction: () => dispatch(clearForm()),
    getAdminUserList: (page, PageSize, searchOrgName, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, searchRoleDetails) => dispatch(getAdminUserListAction(page, PageSize, searchOrgName, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, searchRoleDetails)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminUser)