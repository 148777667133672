import { CLEAR_GENERAL_TASK_LIST, CLEAR_GENERAL_TASK_LIST_ERROR, CLEAR_UPDATE_GENERAL_TASK_ERROR, GENERAL_TASK_IMAGE_MODAL_CLOSE, GENERAL_TASK_IMAGE_MODAL_OPEN, GENERAL_TASK_LIST_ERROR, SET_GENERAL_TASK_LIST, SET_GENERAL_TASK_LIST_PENDING, UPDATE_GENERAL_TASK_ERROR, UPDATE_GENERAL_TASK_PENDING, UPDATE_GENERAL_TASK_SUCCESS } from "./constants";

const initialState = {

    GeneralTaskLists: [],
    totalRecords: 0,
    generalTaskListError: null,
    isGeneralTaskListPending: false,
    updateGeneralTaskErrorMessage: null,
    isUpdateGeneralTaskPending: false,
    isGeneralTaskImgModalOpen: false,
    imgIndex: null

}

export default (localState = initialState, action) => {
    switch (action.type) {


        case SET_GENERAL_TASK_LIST_PENDING: {
            return {
                ...localState,
                generalTaskListError: null,
                isGeneralTaskListPending: true
            }
        }
        case GENERAL_TASK_IMAGE_MODAL_OPEN: {
            return {
                ...localState,
                isGeneralTaskImgModalOpen: true,
                imgIndex: action.payload
            }
        }
        case GENERAL_TASK_IMAGE_MODAL_CLOSE: {
            return {
                ...localState,
                isGeneralTaskImgModalOpen: false
            }
        }

        case SET_GENERAL_TASK_LIST: {
            const { generalTaskList } = action.payload;
            return {
                ...localState,
                isGeneralTaskListPending: false,
                GeneralTaskLists: generalTaskList && generalTaskList.value,
                totalRecords: generalTaskList["@odata.count"],
            }
        }
        case CLEAR_GENERAL_TASK_LIST: {
            return {
                ...localState,
                isGeneralTaskListPending: false,
                GeneralTaskLists: 0,
                totalRecords: [],
            }
        }

        case GENERAL_TASK_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isGeneralTaskListPending: false,
                generalTaskListError: errorMessage,
            };
        }

        case CLEAR_GENERAL_TASK_LIST_ERROR: {
            return {
                ...localState,
                generalTaskListError: null,
            };
        }
        case CLEAR_UPDATE_GENERAL_TASK_ERROR: {
            return {
                ...localState,
                updateGeneralTaskErrorMessage: null,
                isUpdateGeneralTaskPending: false,
            };
        }
        case UPDATE_GENERAL_TASK_PENDING: {
            return {
                ...localState,
                isUpdateGeneralTaskPending: true,
            };
        }
        case UPDATE_GENERAL_TASK_SUCCESS: {
            return {
                ...localState,
                isUpdateGeneralTaskPending: false,
            };
        }
        case UPDATE_GENERAL_TASK_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateGeneralTaskErrorMessage: errorMessage,
                isUpdateGeneralTaskPending: false,
            };
        }

        default: {
            return localState;
        }
    }
}