//--------------------ACCOUNT-CREATE-----------------------------------
export const ACCOUNT_CREATE_OPEN = "ACCOUNT_CREATE_OPEN";
export const ACCOUNT_CREATE_CLOSE = "ACCOUNT_CREATE_CLOSE";
export const ACCOUNT_CREATE_ERROR = "ACCOUNT_CREATE_ERROR";
export const ACCOUNT_CREATE_PENDING = "ACCOUNT_CREATE_PENDING";
export const ACCOUNT_CREATE_ERROR_CLEAR = "ACCOUNT_CREATE_ERROR_CLEAR";
export const ACCOUNT_CREATE_SUCCESS = "ACCOUNT_CREATE_SUCCESS";

//--------------------ACCOUNT-UPDATE-----------------------------------
export const ACCOUNT_UPDATE_OPEN = "ACCOUNT_UPDATE_OPEN";
export const ACCOUNT_UPDATE_CLOSE = "ACCOUNT_UPDATE_CLOSE";
export const ACCOUNT_UPDATE_PENDING = "ACCOUNT_UPDATE_PENDING";
export const ACCOUNT_UPDATE_ERROR = "ACCOUNT_UPDATE_ERROR";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_UPDATE_ERROR_CLEAR = "ACCOUNT_UPDATE_ERROR_CLEAR";

//--------------------ACCOUNT-LSIT-----------------------------------
export const SET_ACCOUNT_LIST = "SET_ACCOUNT_LIST";
export const IS_IMPORT_ACCOUNT_PENDING = "IS_IMPORT_ACCOUNT_PENDING";
export const ACCOUNT_LIST_PENDING = "ACCOUNT_LIST_PENDING";
export const IS_IMPORT_ACCOUNT_SUCCESS = "IS_IMPORT_ACCOUNT_SUCCESS";
export const ACCOUNT_LIST_ERROR = "ACCOUNT_LIST_ERROR";
export const ACCOUNT_LIST_ERROR_CLEAR = "ACCOUNT_LIST_ERROR_CLEAR";
export const ACCOUNT_EXPORT_LIST_SUCCESS = "ACCOUNT_EXPORT_LIST_SUCCESS";

//--------------------ACCOUNT-DETAILS-----------------------------------
export const SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS";
export const ACCOUNT_DETAILS_PENDING = "ACCOUNT_DETAILS_PENDING";
export const ACCOUNT_DETAILS_ERROR = "ACCOUNT_DETAILS_ERROR";
export const ACCOUNT_DETAILS_ERROR_CLEAR = "ACCOUNT_DETAILS_ERROR_CLEAR";

//---------------Contact-List------------------------------------

export const SET_CONTACT_LIST = "SET_CONTACT_LIST";
export const ACCOUNT_EXPORT_LIST_PENDING = "ACCOUNT_EXPORT_LIST_PENDING";
export const ACCOUNT_EXPORT_LIST_ERROR_CLEAR = "ACCOUNT_EXPORT_LIST_ERROR_CLEAR";
export const SET_ACCOUNT_EXPORT_LIST = "SET_ACCOUNT_EXPORT_LIST";
export const ACCOUNT_EXPORT_LIST_ERROR = "ACCOUNT_EXPORT_LIST_ERROR";




export const ACCOUNT_EXPORT_HEADERS = [
    "ACCOUNT NAME",
    "ACCOUNT EMAIL",
    "INDUSTRY TYPE",
    "REGION NAME",
    "WEBSITE",
    "MOBILE #",
    "DESIGNATION",
    "RESPONSIBILITY",
    "GST #",
    "ACCOUNT CATEGORY NAME",
    "ACCOUNT CATEGORY",
    "CITY",
    "STATE",
    "COUNTRY",
    "PIN CODE",
    "STATUS",
    "CREATED DATE",
    "CREATED BY"
];