import { CLEAR_ROLE_LIST_ERROR, SET_ROLE_LIST, SET_ROLE_LIST_ERROR, SET_ROLE_LIST_PENDING } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    totalRecords: 0,
    RolesList: [],
    roleListError: null,
    isRoleListPending: false
};
export default (localState = initialState, action) => {
    switch (action.type) {

        case SET_ROLE_LIST: {
            const { roleList } = action.payload;
            return {
                ...localState,
                isRoleListPending: false,
                RolesList: roleList && roleList.value,
                totalRecords: roleList["@odata.count"],
            }
        }
        case SET_ROLE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isRoleListPending: false,
                roleListError: errorMessage,
            }
        }
        case CLEAR_ROLE_LIST_ERROR: {
            return {
                ...localState,
                roleListError: null,
            }
        }
        case SET_ROLE_LIST_PENDING: {
            return {
                ...localState,
                roleListError: null,
                isRoleListPending: true
            }
        }

        default: {
            return localState;
        }
    }
};