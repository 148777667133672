export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const FORM_LOGIN = "FORM_LOGIN"
export const SET_CURRENT_PROFILE = "SET_CURRENT_PROFILE"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const USER_LOGOUT = "USER_LOGOUT"
export const SET_FORGOT_PASSWORD_CONFIRMATION_STATUS = "SET_FORGOT_PASSWORD_CONFIRMATION_STATUS"
export const SET_PATH = "SET_PATH"
export const CLEAR_PATH = "CLEAR_PATH"
export const SET_LOGIN_PATH = "SET_LOGIN_PATH"
export const CLEAR_SET_LOGIN_PATH = "CLEAR_SET_LOGIN_PATH"


// Login
export const LOGIN_PENDING = 'LOGIN_PENDING'
export const OPEN_DISPLAY_PICTURE_MODAL = 'OPEN_DISPLAY_PICTURE_MODAL'
export const LOGIN_PENDING_ERROR = "LOGIN_PENDING_ERROR"
export const LOGIN_PENDING_SUCCESS = "LOGIN_PENDING_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const CLEAR_LOGIN_ERROR = " CLEAR_LOGIN_ERROR"
export const ENABLE_USER_ERROR = "ENABLE_USER_ERROR"
export const ENABLE_USER_PENDING = "ENABLE_USER_PENDING"
export const ENABLE_USER_PENDING_SUCCESS = "ENABLE_USER_PENDING_SUCCESS"
export const CLEAR_ENABLE_USER_ERROR = "CLEAR_ENABLE_USER_ERROR"
export const ENABLE_USER_PENDING_ERROR = " ENABLE_USER_PENDING_ERROR"
export const FORGOT_PASSWORD_FORM = "FORGOT_PASSWORD_FORM"
export const RESET_PASSWORD_FORM = "RESET_PASSWORD_FORM"
export const FORM_REGISTER = "FORM_REGISTER"
export const SET_IS_LOADING = "SET_IS_LOADING"
export const SET_CURRENT_PROFILE_ERROR = 'SET_CURRENT_PROFILE_ERROR'
export const SET_CURRENT_PROFILE_PENDING = "SET_CURRENT_PROFILE_PENDING"
export const CLEAR_CURRENT_PROFILE_LIST_ERROR = "CLEAR_CURRENT_PROFILE_LIST_ERROR"
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR"
export const CLOSE_CHANGE_PASSWORD_MODAL = "CLOSE_CHANGE_PASSWORD_MODAL"
export const OPEN_CHANGE_PASSWORD_MODAL = "OPEN_CHANGE_PASSWORD_MODAL"
export const CLOSE_DISPLAY_PICTURE_MODAL = "CLOSE_DISPLAY_PICTURE_MODAL"
export const SET_CHANGE_PASSWORD_ERROR = "SET_CHANGE_PASSWORD_ERROR"
export const CLEAR_CHANGE_PASSWORD_ERRORS = "CLEAR_CHANGE_PASSWORD_ERRORS"
export const CLEAR_SET_PATH = "CLEAR_SET_PATH"
export const ENABLE_USER_ACCOUNT_PENDING_SUCCESS = "ENABLE_USER_ACCOUNT_PENDING_SUCCESS"
export const ENABLE_USER_ACCOUNT_PENDING = "ENABLE_USER_ACCOUNT_PENDING"
export const DISABLE_USER_ACCOUNT_PENDING = "DISABLE_USER_ACCOUNT_PENDING"
export const DISABLE_USER_ACCOUNT_SUCCESS = "DISABLE_USER_ACCOUNT_SUCCESS"
export const RECOVER_PENDING = "RECOVER_PENDING"
export const RECOVER_SUCCESS = "RECOVER_SUCCESS"
export const DISABLE_RESET_LINK_PENDING = "DISABLE_RESET_LINK_PENDING"
export const DISABLE_RESET_LINK_SUCCESS = "DISABLE_RESET_LINK_SUCCESS"
export const DISABLE_FORGOT_PASSWORD_PENDING = "DISABLE_FORGOT_PASSWORD_PENDING"
export const DISABLE_FORGOT_PASSWORD_SUCCESS = "DISABLE_FORGOT_PASSWORD_SUCCESS"
export const SET_FORGOT_PASSWORD_SUCCESS = "SET_FORGOT_PASSWORD_SUCCESS"
export const CLEAR_FORGOT_PASSWORD_CONFIRMATION_STATUS = "CLEAR_FORGOT_PASSWORD_CONFIRMATION_STATUS"
export const CLEAR_FORGOT_PASSWORD_SUCCESS = "CLEAR_FORGOT_PASSWORD_SUCCESS"
export const CLEAR_UPDATE_PROFILE_DETAILS_ERROR = "CLEAR_UPDATE_PROFILE_DETAILS_ERROR"
export const UPDATE_PROFILE_DETAILS_PENDING = "UPDATE_PROFILE_DETAILS_PENDING"
export const UPDATE_PROFILE_DETAILS_SUCCESS = "UPDATE_PROFILE_DETAILS_SUCCESS"
export const UPDATE_PROFILE_DETAILS_ERROR = "UPDATE_PROFILE_DETAILS_ERROR"
export const IS_CHANGE_PASSWORD_PENDING = "IS_CHANGE_PASSWORD_PENDING"
export const IS_CHANGE_PASSWORD_SUCCESS = "IS_CHANGE_PASSWORD_SUCCESS"

