import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, InputGroup, Table } from 'reactstrap'
import VWModel from '../../../../../components/Modal/VWModal';
import CreateOrguser from './CreateOrguser';
import moment from 'moment';
import { connect } from 'react-redux';
import { clearForm } from '../../../../../store/form/actions';
import { clearOrgUserExportListError, closeCreateOrgUserModalAction, getOrganizationUserListAction, getOrgUserExportList, openCreateOrgUserModalAction } from '../../../../../store/Modules/Admin/OrgUser/actions';
import { getPager } from '../../../../../helper/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faFont, faSearch } from '@fortawesome/free-solid-svg-icons';
import VWPagination from '../../../../../components/vwPagination/vwPagination';
import NoRecord from '../../../../../components/NoRecords/noRecords';
import VWMessage from '../../../../../components/vwMessage/vwMessage';
import VWSkeleton from '../../../../../components/vwSkeleton/vwSkeleton';
import VWSpinner from '../../../../../components/vwSpinner/vwSpinner';
import { clearSetPath } from '../../../../../store/actions';
import Select from 'react-select';
import '../../../../Modules/viewList.css'
import { ORG_USER_EXPORT_HEADERS } from '../../../../../store/Modules/Admin/OrgUser/constants';
import { excelPrint, excelSheetExport } from '../../../../../helper/export';
import UITooltip from '../../../../../CommonData/Data/UITooltip';
import { ElementToolTip } from '../../../Projects/vwToolTip';

const userStatusOptions = [
    { label: 'Active', value: "true" },
    { label: 'Inactive', value: "false" },
]
const accountStatusOptions = [
    { label: 'Enabled', value: "true" },
    { label: 'Disabled', value: "false" },
]
const globalReadOnlyOptions = [
    { label: 'Yes', value: "true" },
    { label: 'No', value: "false" },
]
const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const OrgUser = (props) => {
    const { userAppDetails } = props.profile;
    const App = userAppDetails.filter((i) => {
        return (i.appName === "Admin") ? i.appId : ""
    })
    console.log("App:", App[0] && App[0].appId);

    const [filterSelectionOption, setFilterSelectionOption] = useState('name');
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const { errorMessage, OrgUserList, isOrgUserListPending, isOrgUserCreateModalOpen, OrganizationUserExportList, orgListExportError, isOrgUserExportListPending } = props || {};

    const buildQueryUserEmail = () => {
        const { UserEmail } = filter || {};
        let searchUserEmail = '';
        if (UserEmail) {
            searchUserEmail += `${UserEmail}`;
        }
        return searchUserEmail
    }
    const buildQueryCreatedBy = () => {
        const { CreatedBy } = filter || {};
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return (searchCreatedBy)
    }
    const buildQueryCreatedDateTime = () => {
        const { CreatedDateTime } = filter || {};
        let searchCreatedDateTime = '';
        if (CreatedDateTime) {
            searchCreatedDateTime += `${CreatedDateTime}`;
        }
        return (searchCreatedDateTime)
    }
    const buildQueryIsPortalEnable = () => {
        const { IsActive } = filter || {};
        let searchIsPortalEnable = '';
        if (IsActive) {
            searchIsPortalEnable += `${IsActive}`;
        }
        return (searchIsPortalEnable)
    }

    const searchStatus = (name, option) => {
        console.log("name: ", name);
        const { value } = option || {};
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const setPage = (page = 1) => {
        props.getOrganizationUserList(App[0] && App[0].appId, filter, page, PageSize, buildQueryUserEmail(), buildQueryCreatedBy(), buildQueryCreatedDateTime(), buildQueryIsPortalEnable());

        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.ClearPath();
        props.clearOrgUserExportListError();
    }, [])
    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])
    //-----------------------------------------Modal open & close-----------------------------------------------------

    const handleOpenCreateOrgUser = () => {
        props.openCreateOrgUserModal();
    };
    const handleClose = () => {
        props.clearFormAction()
        props.closeCreateOrgUserModal();
    };
    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedOrgUserList = [...props.OrgUserList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        if (sortOrder === 'IsPortalEnable') {
            return sortDirection === 'asc' ? b.IsPortalEnable - a.IsPortalEnable : a.IsPortalEnable - b.IsPortalEnable;
        }
        if (sortOrder === 'IsGlobalReadOnlyUser') {
            return sortDirection === 'asc' ? b.IsGlobalReadOnlyUser - a.IsGlobalReadOnlyUser : a.IsGlobalReadOnlyUser - b.IsGlobalReadOnlyUser;
        }
        if (sortOrder === 'EnableMailNotification') {
            return sortDirection === 'asc' ? b.EnableMailNotification - a.EnableMailNotification : a.EnableMailNotification - b.EnableMailNotification;
        }
        if (sortOrder === 'Days') {
            const aVal = parseFloat(a[sortOrder] || 0);
            const bVal = parseFloat(b[sortOrder] || 0);

            return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };


    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/admin/orguser/details/${Id}`);
    };

    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedDateTime: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedDateTime: null,
        });
    }


    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {
        props.getOrgUserExportList(App[0] && App[0].appId, buildQueryUserEmail(), buildQueryCreatedBy(), buildQueryCreatedDateTime(), buildQueryIsPortalEnable(), filter)
            .then((response) => {
                if (response) {
                    const exporOrgUserExportList = [];
                    const headers = ORG_USER_EXPORT_HEADERS;
                    const headerSizes = [30, 50, 30, 20, 20, 20, 30, 30];
                    const headerExcel = [
                        { headerName: "ORG USER", key: "displayName", headerWidhth: 30 },
                        { headerName: "ORG USER EMAIL", key: "userEmail", headerWidhth: 50 },
                        { headerName: "CREATED BY", key: "createdBy", headerWidhth: 30 },
                        { headerName: "CREATED DATE", key: "createdDateTime", headerWidhth: 20 },
                        { headerName: "USER STATUS", key: "isActive", headerWidhth: 20 },
                        { headerName: "ACCOUNT STATUS", key: "isPortalEnable", headerWidhth: 20 },
                        { headerName: "IS EMPLOYEE", key: "isEmployee", headerWidhth: 20 },
                        { headerName: "IS GLOBAL READ ONLY USER", key: "isGlobalReadOnlyUser", headerWidhth: 30 },
                        { headerName: "EMAIL NOTIFICATION STATUS", key: "enableMailNotification", headerWidhth: 30 },
                    ]
                    response && response.forEach((element) => {
                        exporOrgUserExportList.push({
                            displayName: element.displayName || "N/A",
                            userEmail: element.isGlobalReadOnlyUser ? 'N/A' : element.userEmail || "N/A",
                            createdBy: element.createdBy || "N/A",
                            createdDateTime: element.createdDateTime ? moment(element.createdDateTime).format('DD MMM YYYY') : 'N/A',
                            isActive: element.isActive ? "Active" : "Inactive",
                            isPortalEnable: element.isPortalEnable ? "Enabled" : "Disabled",
                            isEmployee: element.isEmployee ? "Yes" : "No",
                            isGlobalReadOnlyUser: element.isGlobalReadOnlyUser ? "Yes" : "No",
                            enableMailNotification: element.enableMailNotification ? "Enabled" : "Disabled",

                        });
                    });
                    excelSheetExport(headerExcel, exporOrgUserExportList, "Organization User-List");
                    // excelPrint(exporOrgUserExportList, "Organization User-List", headers, headerSizes);
                }
            })
    }
    console.log('OrganizationUserExportList:', OrganizationUserExportList);
    console.log("filter:", filter);

    return (
        <React.Fragment>
            <div className="page-content">
                <>
                    <Card >
                        <CardTitle
                            tag="h4"
                            className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                        >
                            <strong className='d-flex align-items-center'> ORGANIZATION USER -&nbsp;<Badge color="danger" id={'OrgUserCount'} style={{ cursor: 'pointer' }}>{props && props.totalCount}</Badge>
                                <ElementToolTip id={'OrgUserCount'} name={'Organization User Count'} /></strong>
                            <div className="content-heading">
                                <div className="ml-auto">
                                    <Link
                                        type="button"
                                        className="btn btn-info"
                                        onClick={handleOpenCreateOrgUser}
                                    >
                                        CREATE
                                    </Link>&nbsp;
                                    <Button
                                        className="me-4"
                                        color="success"
                                        onClick={handleExports}
                                        disabled={OrgUserList && OrgUserList.length <= 0 || isOrgUserExportListPending}
                                    >
                                        {isOrgUserExportListPending ? 'EXPORTING...' : 'EXPORT'}
                                    </Button>
                                </div>
                            </div>
                        </CardTitle>
                    </Card>
                    <Card>
                        <CardBody className="p-4">
                            {(errorMessage || orgListExportError) ? <div className='w-100'>
                                {(errorMessage || orgListExportError) && (
                                    <VWMessage type="danger" defaultMessage={errorMessage || orgListExportError} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            <div style={{ height: "60vh", overflowX: "auto" }}>
                                <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                    <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                        <tr>
                                            <th onClick={() => handleSort('DisplayName')} className='hand-cursor' style={{ minWidth: '200px' }}>
                                                Organization User &nbsp;
                                                {sortOrder === 'DisplayName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                            </th>
                                            <th onClick={() => handleSort('CreatedBy')} className='hand-cursor' style={{ minWidth: '200px' }}>
                                                Created By &nbsp;
                                                {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                            </th>
                                            <th onClick={() => handleSort('IsActive')} className='hand-cursor' style={{ minWidth: '200px' }}>
                                                User Status &nbsp;
                                                {sortOrder === 'IsActive' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                            </th>
                                            <th onClick={() => handleSort('IsPortalEnable')} className='hand-cursor' style={{ minWidth: '200px' }}>
                                                Account Status &nbsp;
                                                {sortOrder === 'IsPortalEnable' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                            </th>
                                            <th onClick={() => handleSort('IsEmployee')} className='hand-cursor' style={{ minWidth: '200px' }}>
                                                Is Employee &nbsp;
                                                {sortOrder === 'IsEmployee' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                            </th>
                                            <th onClick={() => handleSort('IsGlobalReadOnlyUser')} className='hand-cursor' style={{ minWidth: '200px' }}>
                                                Is Global Read Only User &nbsp;
                                                {sortOrder === 'IsGlobalReadOnlyUser' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                            </th>
                                            <th onClick={() => handleSort('EnableMailNotification')} className='hand-cursor' style={{ minWidth: '200px' }}>
                                                Email Notification Status &nbsp;
                                                {sortOrder === 'EnableMailNotification' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                            </th>
                                            <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                                View
                                            </th>
                                        </tr>
                                        <tr>
                                            <th >
                                                <Input
                                                    autocomplete="off"
                                                    type='text'
                                                    placeholder='Search'
                                                    name='UserEmail'
                                                    onChange={searchInputValidation}
                                                    value={filter && filter.UserEmail}
                                                    onKeyPress={handleKeyPress}
                                                /></th>

                                            <th >
                                                <InputGroup >
                                                    {filterSelectionOption === 'name' ?
                                                        <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                            <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                        </UITooltip>
                                                        : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                            <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                        </UITooltip>
                                                    }
                                                    <Input
                                                        style={{ width: "120px" }}
                                                        type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                        placeholder='Search'
                                                        name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedDateTime'}
                                                        onChange={searchInputValidation}
                                                        onKeyPress={handleKeyPress}
                                                        value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedDateTime || '' : ""}
                                                        max={filterSelectionOption !== "name" && "9999-12-31"}
                                                    />
                                                </InputGroup>
                                            </th>
                                            <th>
                                                <Select
                                                    styles={customStyles}
                                                    name="IsActive"
                                                    isClearable
                                                    defaultOptions
                                                    placeholder="Search"
                                                    options={userStatusOptions}
                                                    onChange={(option) => searchStatus("IsActive", option)}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    styles={customStyles}
                                                    name="IsPortalEnable"
                                                    isClearable
                                                    defaultOptions
                                                    placeholder="Search"
                                                    options={accountStatusOptions}
                                                    onChange={(option) => searchStatus("IsPortalEnable", option)}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    styles={customStyles}
                                                    name="IsEmployee"
                                                    isClearable
                                                    defaultOptions
                                                    placeholder="Search"
                                                    options={globalReadOnlyOptions}
                                                    onChange={(option) => searchStatus("IsEmployee", option)}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    styles={customStyles}
                                                    name="IsGlobalReadOnlyUser"
                                                    isClearable
                                                    defaultOptions
                                                    placeholder="Search"
                                                    options={globalReadOnlyOptions}
                                                    onChange={(option) => searchStatus("IsGlobalReadOnlyUser", option)}
                                                />
                                            </th>

                                            <th>
                                                <Select
                                                    styles={customStyles}
                                                    name="EnableMailNotification"
                                                    isClearable
                                                    defaultOptions
                                                    placeholder="Search"
                                                    options={accountStatusOptions}
                                                    onChange={(option) => searchStatus("EnableMailNotification", option)}
                                                />
                                            </th>
                                            <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isOrgUserListPending && (OrgUserList && OrgUserList.length > 0) ?
                                            sortedOrgUserList && sortedOrgUserList.map((data) => (
                                                <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                                    <td className='ps-3'>{data.DisplayName || "N/A"}
                                                        <br />
                                                        {data.IsGlobalReadOnlyUser ? '' :
                                                            <small>{data.UserEmail || ""}</small>}</td>
                                                    <td className='ps-3'>{data.CreatedBy || 'N/A'}
                                                        <br />
                                                        <small>{data.CreatedDateTime ? moment(data.CreatedDateTime).format('DD MMM YYYY') : 'N/A'}</small>
                                                    </td>
                                                    <td className='ps-3'>
                                                        {data.IsActive ? (
                                                            <Badge color="success" className="text-dark-white">
                                                                Active
                                                            </Badge>
                                                        ) : (
                                                            <Badge color="danger" className="text-dark-white">
                                                                Inactive
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td className='ps-3'>
                                                        {data.IsPortalEnable ? (
                                                            <Badge color="success" className="text-dark-white">
                                                                Enabled
                                                            </Badge>
                                                        ) : (
                                                            <Badge color="danger" className="text-dark-white">
                                                                Disabled
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td className='ps-3'>
                                                        {data.IsEmployee ? (
                                                            <Badge color="success" className="text-dark-white">
                                                                Yes
                                                            </Badge>
                                                        ) : (
                                                            <Badge color="danger" className="text-dark-white">
                                                                No
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td className='ps-3'>
                                                        {data.IsGlobalReadOnlyUser ? (
                                                            <Badge color="success" className="text-dark-white">
                                                                Yes
                                                            </Badge>
                                                        ) : (
                                                            <Badge color="danger" className="text-dark-white">
                                                                No
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td className='ps-3'>
                                                        {data.EnableMailNotification ? (
                                                            <Badge color="success" className="text-dark-white">
                                                                Enabled
                                                            </Badge>
                                                        ) : (
                                                            <Badge color="danger" className="text-dark-white">
                                                                Disabled
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td className='sticky-column' style={{ backgroundColor: "white" }}> <Link
                                                        type="button"
                                                        className="btn btn-sm  btn-light"
                                                        to={`/admin/orguser/details/${data.Id}`}
                                                    >
                                                        <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                    </Link></td>
                                                </tr>
                                            ))
                                            : isOrgUserListPending ?
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <VWSpinner />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <NoRecord />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter className='pb-0'>
                            {OrgUserList && OrgUserList.length > 0 ?
                                <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                            }
                        </CardFooter>
                    </Card>
                    <VWModel
                        header="CREATE ORGANIZATION USER"
                        isOpen={isOrgUserCreateModalOpen}
                        handleClose={handleClose}
                    >
                        <CreateOrguser
                            appId={App[0] && App[0].appId}
                            filterData={filter}
                            handleClose={handleClose}
                        />
                    </VWModel>
                </>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isOrgUserCreateModalOpen: state.OrgUser.isCreateOrgUserModalOpen,
    OrgUserList: state.OrgUser.OrganizationUserList,
    errorMessage: state.OrgUser.orgListError,
    totalCount: state.OrgUser.totalRecords,
    isOrgUserListPending: state.OrgUser.isOrgUserListPending,
    OrganizationUserExportList: state.OrgUser.OrganizationUserExportList,
    isOrgUserExportListPending: state.OrgUser.isOrgUserExportListPending,
    orgListExportError: state.OrgUser.orgListExportError,
    profile: state.login.currentProfile,

});
const mapDispatchToProps = (dispatch) => ({
    closeCreateOrgUserModal: () => dispatch(closeCreateOrgUserModalAction()),
    openCreateOrgUserModal: () => dispatch(openCreateOrgUserModalAction()),
    clearFormAction: () => dispatch(clearForm()),
    getOrganizationUserList: (AppId, filter, page, PageSize, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable) => dispatch(getOrganizationUserListAction(AppId, filter, page, PageSize, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable)),
    getOrgUserExportList: (AppId, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, filter) => dispatch(getOrgUserExportList(AppId, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, filter)),
    ClearPath: () => dispatch(clearSetPath()),
    clearOrgUserExportListError: () => dispatch(clearOrgUserExportListError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgUser)