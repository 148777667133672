import React, { useEffect, useState } from 'react';
import { Card, CardTitle, Col, Row } from 'reactstrap';
import { getDonutDashboardCounts, getLeaveMasterDashboardCounts } from '../../../../store/Modules/LeaveTracker/Dashboard/actions';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxesStacked,
  faThumbsUp,
  faClockRotateLeft,
  faThumbsDown,
  faListCheck,
  faFilePen,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import "./Dashboard.css";
import TotalDonutChart from './TotalDonutChart';
import Async from "react-select/async"
import { getCountforEnableOrgLeaveType, getEnableOrgLeaveTypeListAsyncSelect } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions';
import TakenDonutChart from './TakenDonutChart';

const Dashboard = (props) => {
  const [prevLeaveType, setprevLeaveType] = useState({ label: '', value: '' });
  const customStyles = {
    container: (provided) => ({
      ...provided,
      fontWeight: 'normal',
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      minWidth: "250px",
      maxWidth: "250px",
      minHeight: "40px",
      fontWeight: 'normal',
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: 'normal'
    }),
  };

  const { userAppDetails, isManager } = props.currentProfile || {};
  let isAdmin;

  const [filterparams, setFilterParams] = useSearchParams();
  const navigate = useNavigate();

  userAppDetails?.some(item => item.appName.toLowerCase() === "leavetracker") &&
    userAppDetails
      .filter(userApp => userApp.appName.toLowerCase() === "leavetracker")
      .map(userApp => {
        isAdmin = userApp.appRoleDetails?.some(role => role.roleName.toLowerCase() === "administrator");
      })

  const xlSize = (isAdmin || isManager) ? "3" : "2";
  const lgSize = (isAdmin || isManager) ? "6" : "3";
  const mdSize = (isAdmin || isManager) ? "6" : "3";

  const [formfields, setFormFields] = useState();
  useEffect(() => {
    props.getDashboardCounts();
    props.getDonutDashboardCounts();
  }, [])

  useEffect(() => {
    props.getCountforEnableOrgLeaveType();
    if (props.donutDashboardCounts) {
      setFormFields(props.donutDashboardCounts);
    }
  }, [props.donutDashboardCounts])

  const handleClickAdmin = (name) => {
    setFilterParams({
      filter: name
    })
    const path = window.location.search;
    const params = new URLSearchParams(path);
    const filter = params.get('filter')
    navigate(`/leavetracker/myteamleaves?filter=${filter}`)
  }

  const handleClick = (name) => {
    setFilterParams({
      filter: name
    })
    const path = window.location.search;
    const params = new URLSearchParams(path);
    const filter = params.get('filter')
    navigate(`/leavetracker/applyleave?filter=${filter}`)
  }
  const [leaveTypeCount, setLeaveTypeCount] = useState(null);
  const handleLoadLeaveType = (inputValue, callback) => {
    const { getLeaveTypeAsyncSelectAction } = props;
    return getLeaveTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', (result) => {
      setLeaveTypeCount(result); // Update the state with the result
      callback(result); // Call the original callback
    });
  };

  const handleSearchLeaveType = (options) => {
    if (options) {
      const { label, value } = options;
      props.getDonutDashboardCounts(value);
      setprevLeaveType({ label, value });
    } else {
      setprevLeaveType({ label: '', value: '' });
      props.getDonutDashboardCounts('');
    }
  };

  console.log("donutDashboardCounts:", props.donutDashboardCounts);
  console.log('formfields:', formfields);
  console.log('leaveTypeCount:', leaveTypeCount);
  console.log('props.OrgLeaveTypeCountList:', props.OrgLeaveTypeCountList);
  console.log('prevLeaveType:', prevLeaveType.label && prevLeaveType.label);
  console.log('prevLeaveType:', prevLeaveType.label ? 'yes' : 'No');

  return (
    <>
      <div className="page-content">
        <Card>
          <CardTitle
            tag="h4"
            className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
          >
            <strong>DASHBOARD</strong>
          </CardTitle>
        </Card>
        {props.dashboardErrorMessage && (
          <VWMessage type="danger" defaultMessage={props.dashboardErrorMessage} baseClassName="alert" />
        )}
        {props.isDashboardPending ? (
          <tr className="d-flex justify-content-center align-items-center mt-2">
            <td colSpan={8}>
              <div className="d-flex justify-content-center mt-2">
                <VWSpinner />
              </div>
            </td>
          </tr>
        ) : (
          <>
            <Row>
              {(isAdmin || isManager) &&
                <Col lg="5" md="5">
                  <Card className="p-4">
                    <Row className="mb-3">
                      <div className='fs-5 fw-bold'>
                        Your Team Leave Process
                      </div>
                    </Row>
                    <Row >
                      <Col xl="6" lg="12" md="12" className="mb-3">
                        <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={() => handleClickAdmin("Submitted")}>
                          <div className='section-1'>
                            <div className='fs-1 fw-bold mt-2 card-1-color'>
                              {props.allCountings && props.allCountings.myPendingApprovalCount || '0'}
                            </div>
                            <div className='badge-icon card-1-bg'>
                              <FontAwesomeIcon icon={faFilePen} style={{ fontSize: "18px", color: "#2B4560" }} />
                            </div>
                          </div>
                          <div className='p-2 fw-bold rounded-bottom card-1-color card-1-bg'>
                            Need To Approve
                          </div>
                        </Card>
                      </Col>
                      <Col xl="6" lg="12" md="12" className="mb-3">
                        <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={() => handleClickAdmin("Approved")}>
                          <div className='section-1'>
                            <div className='fs-1 fw-bold mt-2 card-2-color'>
                              {props.allCountings && props.allCountings.myApprovalApprovedCount || '0'}
                            </div>
                            <div className='badge-icon card-2-bg'>
                              <FontAwesomeIcon icon={faListCheck} style={{ fontSize: "18px", color: "#2B4560" }} />
                            </div>
                          </div>
                          <div className='p-2 fw-bold rounded-bottom card-2-color card-2-bg'>
                            My Approvals
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              }
              <Col lg={(isAdmin || isManager) ? "7" : "12"} md={(isAdmin || isManager) ? "7" : "12"}>
                <Card className="p-4" >
                  <Row className="mb-3">
                    <div className='fs-5 fw-bold'>Your Leave Info</div>
                  </Row>
                  <Row className={(isAdmin || isManager) ? "" : "d-flex justify-content-between"}>
                    <Col xl={xlSize} lg={lgSize} md={mdSize} sm="6" xs="6" className="mb-3">
                      <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={() => handleClick("All")}>
                        <div className='section-1'>
                          <div className='fs-1 fw-bold mt-2 text-secondary'>
                            {props.allCountings && props.allCountings.totalLeavecount || '0'}
                          </div>
                          <div className='badge-icon bg-secondary'>
                            <FontAwesomeIcon icon={faBoxesStacked} style={{ fontSize: "18px", color: "white" }} />
                          </div>
                        </div>
                        <div className='p-2 fw-bold rounded-bottom text-white bg-secondary'>
                          Total Applied Leaves
                        </div>
                      </Card>
                    </Col>
                    <Col xl={xlSize} lg={lgSize} md={mdSize} sm="6" xs="6" className="mb-3">
                      <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={() => handleClick("Submitted")}>
                        <div className='section-1'>
                          <div className='fs-1 fw-bold mt-2 text-primary'>
                            {props.allCountings && props.allCountings.pendingforApproval || '0'}
                          </div>
                          <div className='badge-icon bg-primary'>
                            <FontAwesomeIcon icon={faClockRotateLeft} style={{ fontSize: "18px", color: "white" }} />
                          </div>
                        </div>
                        <div className='p-2 fw-bold rounded-bottom text-white bg-primary'>
                          Pending Leaves
                        </div>
                      </Card>
                    </Col>
                    <Col xl={xlSize} lg={lgSize} md={mdSize} sm="6" xs="6" className="mb-3">
                      <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={() => handleClick("Approved")}>
                        <div className='section-1'>
                          <div className='fs-1 fw-bold mt-2 text-success'>
                            {props.allCountings && props.allCountings.approvedLeaveCount || '0'}
                          </div>
                          <div className='badge-icon bg-success'>
                            <FontAwesomeIcon icon={faThumbsUp} style={{ fontSize: "18px", color: "white" }} />
                          </div>
                        </div>
                        <div className='p-2 fw-bold rounded-bottom bg-success text-white' >
                          Approved Leaves
                        </div>
                      </Card>
                    </Col>
                    <Col xl={xlSize} lg={lgSize} md={mdSize} sm="6" xs="6" className="mb-3">
                      <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} onClick={() => handleClick("Rejected")}>
                        <div className='section-1'>
                          <div className='fs-1 fw-bold mt-2 text-danger'>
                            {props.allCountings && props.allCountings.rejectedLeaveCount || '0'}
                          </div>
                          <div className='badge-icon bg-danger'>
                            <FontAwesomeIcon icon={faThumbsDown} style={{ fontSize: "18px", color: "white" }} />
                          </div>
                        </div>
                        <div className='p-2 fw-bold rounded-bottom bg-danger text-white'>
                          Rejected Leaves
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Card className="p-4">
                <Row>
                  <div className='d-flex justify-content-between'>
                    <div className='fs-5 fw-bold'>
                      Allocated Leaves for Users
                    </div>
                    <div>
                      <Async
                        styles={customStyles}
                        name="LeaveType"
                        isClearable
                        defaultOptions
                        placeholder="Search"
                        loadOptions={handleLoadLeaveType}
                        onChange={handleSearchLeaveType}
                        value={prevLeaveType.label ? prevLeaveType : null}
                      />

                    </div>
                  </div>
                </Row>
                {(props.OrgLeaveTypeCountList && props.OrgLeaveTypeCountList.length > 0) ?
                  <Row >
                    <Col lg="6" md="6" className="d-flex flex-column justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center flex-grow-1">
                        <TotalDonutChart datas={formfields} filter={prevLeaveType && prevLeaveType.label} />
                      </div>
                      <span className='mt-2'><strong>Total Leaves - {formfields && formfields.totalAllocateLeaveCount || 0}</strong></span>
                    </Col>
                    <Col lg="6" md="6" className="d-flex flex-column justify-content-between align-items-center">
                      <div className="d-flex justify-content-center align-items-center flex-grow-1">
                        <TakenDonutChart datas={formfields} filter={prevLeaveType && prevLeaveType.label} />
                      </div>
                      <span className="mt-2"><strong>Taken Leaves - {formfields && formfields.totalTakenLeaveCount || 0}</strong></span>
                    </Col>
                  </Row>
                  : <Card className="d-flex align-items-center justify-content-center p-4 text-center">
                    <div
                    >
                      <FontAwesomeIcon icon={faCircleInfo} style={{ color: 'red' }} /> <span style={{ color: 'red' }}>You didn't have any leave type for your organization.</span>
                    </div>
                  </Card>
                }
              </Card>


            </Row>
          </>
        )}
      </div >
    </>
  )
}
const mapStateToProps = (state) => ({
  OrgLeaveTypeCountList: state.leaveTracker.OrgLeaveTypeCountList,
  donutDashboardCounts: state.LeaveTrackerDashboard.donutDashboardCounts,
  allCountings: state.LeaveTrackerDashboard.DashboardCounts,
  isDashboardPending: state.LeaveTrackerDashboard.isDashboardPending,
  dashboardErrorMessage: state.LeaveTrackerDashboard.dashboardErrorMessage,
  currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
  getCountforEnableOrgLeaveType: () => dispatch(getCountforEnableOrgLeaveType()),
  getDashboardCounts: () => dispatch(getLeaveMasterDashboardCounts()),
  getDonutDashboardCounts: (leaveType) => dispatch(getDonutDashboardCounts(leaveType)),
  getLeaveTypeAsyncSelectAction: (searchValue, callback) =>
    dispatch(getEnableOrgLeaveTypeListAsyncSelect(searchValue, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
