//--------------------CONTACTS-CREATE-----------------------------------
export const CONTACTS_CREATE_OPEN = "CONTACTS_CREATE_OPEN";
export const CONTACTS_CREATE_CLOSE = "CONTACTS_CREATE_CLOSE";
export const CONTACTS_CREATE_ERROR = "CONTACTS_CREATE_ERROR";
export const CONTACTS_CREATE_PENDING = "CONTACTS_CREATE_PENDING";
export const CONTACTS_CREATE_ERROR_CLEAR = "CONTACTS_CREATE_ERROR_CLEAR";
export const CONTACTS_CREATE_SUCCESS = "CONTACTS_CREATE_SUCCESS";

//--------------------CONTACTS-UPDATE-----------------------------------
export const CONTACTS_UPDATE_OPEN = "CONTACTS_UPDATE_OPEN";
export const CONTACTS_UPDATE_CLOSE = "CONTACTS_UPDATE_CLOSE";
export const CONTACTS_UPDATE_PENDING = "CONTACTS_UPDATE_PENDING";
export const CONTACTS_UPDATE_ERROR = "CONTACTS_UPDATE_ERROR";
export const CONTACTS_UPDATE_SUCCESS = "CONTACTS_UPDATE_SUCCESS";
export const CONTACTS_UPDATE_ERROR_CLEAR = "CONTACTS_UPDATE_ERROR_CLEAR";

//--------------------CONTACTS-LSIT-----------------------------------
export const SET_CONTACTS_LIST = "SET_CONTACTS_LIST";
export const CONTACTS_LIST_PENDING = "CONTACTS_LIST_PENDING";
export const CONTACTS_LIST_ERROR = "CONTACTS_LIST_ERROR";
export const CONTACTS_LIST_ERROR_CLEAR = "CONTACTS_LIST_ERROR_CLEAR";

//--------------------CONTACTS-DETAILS-----------------------------------
export const SET_CONTACTS_DETAILS = "SET_CONTACTS_DETAILS";
export const CONTACTS_DETAILS_PENDING = "CONTACTS_DETAILS_PENDING";
export const CONTACTS_DETAILS_ERROR = "CONTACTS_DETAILS_ERROR";
export const CONTACTS_DETAILS_ERROR_CLEAR = "CONTACTS_DETAILS_ERROR_CLEAR";


//------------------------Export---------------------------------------

export const CONTACTS_EXPORT_LIST_PENDING = "CONTACTS_EXPORT_LIST_PENDING";
export const CONTACTS_EXPORT_LIST_ERROR_CLEAR = "CONTACTS_EXPORT_LIST_ERROR_CLEAR";
export const SET_CONTACTS_EXPORT_LIST = "SET_CONTACTS_EXPORT_LIST";
export const CONTACTS_EXPORT_LIST_ERROR = "CONTACTS_EXPORT_LIST_ERROR";


export const CONTACT_EXPORT_HEADERS = [
    "TITLE",
    "DISPLAY NAME",
    "CONTACT EMAIL",
    "ACCOUNT NAME",
    "ACCOUNT EMAIL",
    "DESIGNATION",
    "DEPARTMENT",
    "MOBILE #",
    "LAND LINE #",
    "CITY",
    "CREATED DATE",
    "CREATED BY"
];