import React from 'react'
import { Link } from 'react-router-dom'

const EnquriesList = () => {
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <div>
                        <Link to='/crm/enquiries/details'>EnquriesList</Link></div>
                </div>
            </React.Fragment>
        </>
    )
}

export default EnquriesList