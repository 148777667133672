import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_LEAVE_REPORT_LIST_ERROR, CLEAR_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR, SET_LEAVE_RECORD_LIST, SET_LEAVE_REPORT_PENDING, SET_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR, SET_LEAVE_TRACKER_REPORT_EXPORT_PENDING, SET_LEAVE_TRACKER_REPORT_LIST_ERROR } from "./constants";

export const setLeaveReportPending = () => ({
    type: SET_LEAVE_REPORT_PENDING,
});

export const setLeaveRecordList = (leaveRecordList) => ({
    type: SET_LEAVE_RECORD_LIST,
    payload: { leaveRecordList },
})
export const clearLeaveTrackerReportListError = () => ({
    type: CLEAR_LEAVE_REPORT_LIST_ERROR,
})
export const clearLeaveTrackerReportExportListError = () => ({
    type: CLEAR_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR,
})
export const leaveTrackerReportListError = (errorMessage) => ({
    type: SET_LEAVE_TRACKER_REPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const leaveTrackerReportExportListError = (errorMessage) => ({
    type: SET_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const setLeaveTrackerReportExportPending = () => ({
    type: SET_LEAVE_TRACKER_REPORT_EXPORT_PENDING,
});

export const getLeaveReportListAction = (page, PageSize,filter) => (dispatch) => {
    dispatch(setLeaveReportPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeavetackerReport.getLeaveTrackerReportList(accessToken, page, PageSize,filter)
            .then((LeaveRecordLists) => {
                dispatch(setLeaveRecordList(LeaveRecordLists));
                dispatch(clearLeaveTrackerReportListError());
                console.log("LeaveRecordLists:", LeaveRecordLists);
            })
            .catch((err) => {
                console.log('getLeaveReportListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(leaveTrackerReportListError(error));

            });
    });
}


export const getLeaveTrackerReportExport = (filter) => (dispatch) => {
    dispatch(setLeaveTrackerReportExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.LeavetackerReport.getLeaveTrackerReportExportList(accessToken, filter)
            .then((LeaveReportLists) => {
                dispatch(clearLeaveTrackerReportExportListError());
                return Promise.resolve(LeaveReportLists);
            })
            .catch((err) => {
                console.log('getLeaveTrackerReportExport server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                else {
                    error = "Network Error"
                }
                dispatch(leaveTrackerReportExportListError(error))
            });
    });
}