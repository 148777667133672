
import { getToken } from "../../../../helper/common";
import { getCurrentProfile } from "../../../actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_ORG_LOGO_ERROR, CLEAR_UPDATE_ORG_LOGO_ERROR, SET_ORG_LOGO, SET_ORG_LOGO_ERROR, SET_ORG_LOGO_PENDING, UPDATE_ORG_LOGO_ERROR, UPDATE_ORG_LOGO_PENDING, UPDATE_ORG_LOGO_SUCCESS } from "./constants";

export const setOrgLogoPending = () => ({
    type: SET_ORG_LOGO_PENDING,
})
export const setOrgLogo = (Orglogo) => ({
    type: SET_ORG_LOGO,
    payload: { Orglogo },
})

export const clearOrgLogoError = () => ({
    type: CLEAR_ORG_LOGO_ERROR,
})
export const orgLogoError = (errorMessage) => ({
    type: SET_ORG_LOGO_ERROR,
    payload: { errorMessage },
})
export const clearUpdateOrgLogoError = () => ({
    type: CLEAR_UPDATE_ORG_LOGO_ERROR,
});

export const updateOrgLogoPending = () => ({
    type: UPDATE_ORG_LOGO_PENDING,
});
export const updateOrgLogoSuccess = () => ({
    type: UPDATE_ORG_LOGO_SUCCESS,
});
export const updateOrgLogoError = (errorMessage) => ({
    type: UPDATE_ORG_LOGO_ERROR,
    payload: { errorMessage },
});
export const getOrganizationLogo = (id) => (dispatch) => {
    dispatch(setOrgLogoPending());
    getToken(dispatch).then((accessToken) => {
        agent.OrgLogo.getOrgLogo(accessToken, id).then(
            (OrgLogo) => {
                console.log("OrgLogo:", OrgLogo);
                dispatch(setOrgLogo(OrgLogo));
                dispatch(clearOrgLogoError());
            })
            .catch((err) => {
                console.log('getOrganizationLogo server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgLogoError(error));
            });
    });
}
export const EditOrgPortalLogo = (formFields) => (dispatch) => {
    const dataToSend = {
        ...formFields,
    }
    dispatch(clearUpdateOrgLogoError());
    dispatch(updateOrgLogoPending());
    getToken(dispatch).then((accessToken) => {
        agent.OrgLogo.editOrgLogo(accessToken, dataToSend)
            .then(() => {
                dispatch(updateOrgLogoSuccess());
                dispatch(getOrganizationLogo(formFields.orgId));
                dispatch(getCurrentProfile())
                dispatch(showSnackbarStatus('Organization logo updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateOrgLogoError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateOrgLogoError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Edit Org Portal Logo server error', err);
                dispatch(updateOrgLogoError(null));
            });
    });
};