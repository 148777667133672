import React, { useEffect, useRef, useState } from "react";
import {
    Form,
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
    Container,
    Button,
    Input,
    FormFeedback,
    FormGroup
} from "reactstrap";
import { Link, Navigate, useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { clearUpdateVisionDocsError, ClearVisionDocsDetails, clearVisionDocsDetailsError, closeUpdateVisionDocsHeaderModal, closeUpdateVisionDocsModal, getVisionDocsDetailsAction, isUpdateEditDocspending, openUpdateVisionDocsHeaderModalAction, openUpdateVisionDocsModal, updateVisionDocs } from "../../../store/Modules/VisionDocs/actions";
import "./Editor.css";
import "./Docs.css"
import VWModel from "../../../components/Modal/VWModal";
import EditDocsModal from "./EditDocsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faDownload, faSave, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import VWMessage from "../../../components/vwMessage/vwMessage";
import EditDocsHeader from "./EditDocsHeader";
import jsPDF from "jspdf";
import moment from "moment-timezone";
import _ from "lodash";


const FormEditors = (props) => {

    const [formFields, setFormFields] = useState({});
    const [errors, setErrors] = useState({});
    const params = useParams();
    const { id } = params;
    const [headerTitle, setHeaderTitle] = useState('');
    const maxLength = 50;
    const initialForm = useRef(props.DocsDetails);
    initialForm.current = props.DocsDetails;
    const formFieldsRef = useRef(formFields);
    formFieldsRef.current = formFields;

    console.log("initialForm:", initialForm);

    useEffect(() => {
        if (id) {
            props.getVisionDocsDetailsAction(id);
        }
    }, [id]);

    useEffect(() => {
        if (props.DocsDetails) {
            console.log("First props.DocsDetails:", props.DocsDetails);
            const updatedFormFields = {
                ...props.DocsDetails,
                contentText: formFields.contentText || props.DocsDetails && props.DocsDetails.contentText
            };
            setFormFields(updatedFormFields);
            setHeaderTitle(updatedFormFields.headerTitle || '');
        }
    }, [props.DocsDetails]);

    const AutoSave = () => {
        const isFormChanged = _.isEqual(initialForm.current, formFieldsRef.current);
        if ((formFieldsRef.current && formFieldsRef.current.isEditable) && !isFormChanged) {
            EditVisionDocs(formFieldsRef.current);
        }
    }

    useEffect(() => {
        props.clearUpdateVisionDocsError();
        props.isUpdateEditDocspending();
        return () => {
            props.clearVisionDocsDetailsError();
            props.ClearVisionDocsDetails();
            AutoSave();
        }
    }, []);



    const handleEditorChange = (content) => {
        setFormFields(prevFields => ({ ...prevFields, contentText: content }));
    };

    const EditVisionDocs = (currentFormFields) => {
        console.log("currentFormFields:", currentFormFields);
        console.log("CreateDocsID:", props.CreateDocsID);
        console.log("props.documentContent:", props.documentContent);

        const updatedFormFields = currentFormFields;
        setFormFields(updatedFormFields);
        console.log('updatedFormFields:', updatedFormFields);

        const newErrors = {};
        if (!updatedFormFields.title || !updatedFormFields.title.trim()) {
            newErrors.title = 'Document Title is required';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            props.updateVisionDocsAction(updatedFormFields);
        }
    }
    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        EditVisionDocs(formFieldsRef.current);
    };


    const handleClose = () => {
        props.closeUpdateVisionDocsModalAction();
        props.closeUpdateVisionDocsHeaderModal();
    };

    const handleDone = (updatedFormFields) => {
        setFormFields(updatedFormFields);
        console.log("updatedFormFields:", updatedFormFields);

        setHeaderTitle(updatedFormFields && updatedFormFields.headerTitle);
        handleClose();
    };

    const handleOpenModal = (e) => {
        e.preventDefault();
        props.openUpdateVisionDocsModalAction();
    };

    const handleTitleChange = (e) => {
        const { value } = e.target;
        if (value && value.length >= maxLength) {
            setErrors({ ...errors, title: `Document name cannot exceed ${maxLength} characters.` });
        } else {
            setErrors({ ...errors, title: '' });
        }
        setFormFields(prevFields => ({ ...prevFields, title: e.target.value }));
    };

    const handleOpenHeaderModal = (e) => {
        e.preventDefault();
        props.openUpdateVisionDocsHeaderModalAction();
    };


    const pdfPrint = async (headerTitle, content) => {
        console.log("headerTitle:", headerTitle);
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });

        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;
        const margin = 10;
        const headerHeight = 20;
        const footerHeight = 10;
        let pageNumber = 1;
        let currentY = headerHeight; // Start content position after header
        if ((headerTitle && headerTitle.length > 0) || (formFields && formFields.headerLogo)) {
            currentY = headerHeight + 17;
        }

        // Function to add header
        const addHeader = async () => {
            if ((headerTitle && headerTitle.length > 0) || (formFields && formFields.headerLogo)) {
                pdf.setFontSize(20);
                pdf.text(headerTitle, pageWidth / 2, 20, { align: 'center' });
                pdf.setFontSize(12);
                pdf.setDrawColor(0, 0, 0);
                pdf.line(margin, 25, pageWidth - margin, 25);
                console.log("headerLogo:", formFields && formFields.headerLogo);
                if (formFields && formFields.headerLogo) {
                    pdf.addImage(formFields && formFields.headerLogo, 'PNG', 10, 10, 30, 15);
                }
                if (formFields && formFields.includeUserInfo) {
                    pdf.setFontSize(10);
                    pdf.text(`Document Owner: ${formFields.ownerUserName}`, pageWidth - margin - 50, 10);
                }
            }
            else if (formFields && formFields.includeUserInfo) {
                pdf.setFontSize(10);
                pdf.text(`Document Owner: ${formFields.ownerUserName}`, pageWidth - margin - 50, 10);
            }
        };

        // Function to add footer
        const addFooter = () => {
            pdf.setFontSize(10);
            pdf.text(`Page ${pageNumber}`, pageWidth / 2, pageHeight - footerHeight / 2, { align: 'center' });
            const timestampText = `Last Updated Date: ${formFields.updatedTimeStamp ? moment(formFields.updatedTimeStamp).format('DD MMM YYYY') : moment(formFields.createdTimeStamp).format('DD MMM YYYY')}`;
            const timestampX = pageWidth - margin - pdf.getTextWidth(timestampText);
            const timestampY = pageHeight - footerHeight / 2;

            pdf.text(timestampText, timestampX, timestampY);
        };

        // Function to add content to the page
        const addContentToPage = async (startY, content) => {
            let currentY = startY;

            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, 'text/html');
            const elements = htmlDoc.body.childNodes;

            for (const element of elements) {
                pdf.setFont("YourCustomFontName", "normal");
                pdf.setFontSize(12);

                if (element.nodeName === 'P' || element.nodeName === '#text') {
                    const textNodes = element.childNodes;
                    for (const textNode of textNodes) {
                        let text = textNode.textContent || '';

                        if (textNode.nodeName === 'STRONG' || textNode.nodeName === 'B') {
                            pdf.setFont("YourCustomFontName", "bold");
                        } else if (textNode.nodeName === 'EM' || textNode.nodeName === 'I') {
                            pdf.setFont("YourCustomFontName", "italic");
                        }
                        else if (textNode.nodeName === 'U') {
                            pdf.setFont("YourCustomFontName", "underline");
                        }
                        else {
                            pdf.setFont("YourCustomFontName", "normal");
                        }

                        const lines = pdf.splitTextToSize(text, pageWidth - 2 * margin);
                        for (const line of lines) {
                            const lineHeight = pdf.getTextDimensions(line).h;
                            if (currentY + lineHeight > pageHeight - margin - footerHeight) {
                                pdf.addPage();
                                pageNumber++;
                                currentY = headerHeight; // Reset Y position after new page
                                if ((headerTitle && headerTitle.length > 0) || (formFields && formFields.headerLogo)) {
                                    currentY = headerHeight + 17;
                                }
                                await addHeader(); // Add header on new page
                                addFooter(); // Add footer on new page
                            }
                            pdf.text(line, margin, currentY);
                            currentY += lineHeight;
                        }
                        currentY += 5;

                        if (textNode.nodeName === 'IMG') {
                            const img = textNode;
                            const imgUrl = img.getAttribute('src');
                            const imgFormat = imgUrl.split(';')[0].split('/')[1].toUpperCase();

                            const image = new Image();
                            image.src = imgUrl;

                            await new Promise((resolve) => {
                                image.onload = async () => {
                                    const imgWidth = Math.min(image.width, pageWidth - 2 * margin);
                                    const imgHeight = (imgWidth * image.height) / image.width;

                                    if (currentY + imgHeight > pageHeight - margin - footerHeight) {
                                        pdf.addPage();
                                        pageNumber++;
                                        currentY = headerHeight + 7; // Reset Y position after new page
                                        await addHeader(); // Add header on new page
                                        addFooter(); // Add footer on new page
                                    }

                                    pdf.addImage(imgUrl, imgFormat, margin, currentY, imgWidth, imgHeight);
                                    currentY += imgHeight + 5;

                                    resolve();
                                };
                                image.onerror = () => {
                                    console.error('Failed to load image:', imgUrl);
                                    resolve();
                                };
                            });
                        }
                    }
                } else if (element.nodeName.startsWith('H')) {
                    const text = element.textContent || '';
                    const headerLevel = parseInt(element.nodeName.charAt(1));
                    pdf.setFontSize(24 - headerLevel * 2);
                    pdf.setFont("YourCustomFontName", "bold");

                    const lines = pdf.splitTextToSize(text, pageWidth - 2 * margin);
                    for (const line of lines) {
                        const lineHeight = pdf.getTextDimensions(line).h;
                        if (currentY + lineHeight > pageHeight - margin - footerHeight) {
                            pdf.addPage();
                            pageNumber++;
                            currentY = headerHeight + 7; // Reset Y position after new page
                            await addHeader(); // Add header on new page
                            addFooter(); // Add footer on new page
                        }
                        pdf.text(line, margin, currentY);
                        currentY += lineHeight;
                    }
                    currentY += 5;
                } else if (element.nodeName === 'UL' || element.nodeName === 'OL') {
                    const isOrdered = element.nodeName === 'OL';
                    const items = element.getElementsByTagName('LI');
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        let itemText = '';
                        const itemChildren = item.childNodes;

                        for (const child of itemChildren) {
                            if (child.nodeName === 'IMG') {
                                const img = child;
                                const imgUrl = img.getAttribute('src');
                                const imgFormat = imgUrl.split(';')[0].split('/')[1].toUpperCase();

                                const image = new Image();
                                image.src = imgUrl;

                                await new Promise((resolve) => {
                                    image.onload = async () => {
                                        const imgWidth = Math.min(image.width, pageWidth - 2 * margin);
                                        const imgHeight = (imgWidth * image.height) / image.width;

                                        if (currentY + imgHeight > pageHeight - margin - footerHeight) {
                                            pdf.addPage();
                                            pageNumber++;
                                            currentY = headerHeight + 7; // Reset Y position after new page
                                            await addHeader(); // Add header on new page
                                            addFooter(); // Add footer on new page
                                        }

                                        const bullet = isOrdered ? `${i + 1}. ` : '• ';
                                        pdf.text(bullet, margin, currentY);
                                        pdf.addImage(imgUrl, imgFormat, margin + 10, currentY - 3, imgWidth, imgHeight);
                                        currentY += imgHeight + 5;

                                        resolve();
                                    };
                                    image.onerror = () => {
                                        console.error('Failed to load image:', imgUrl);
                                        resolve();
                                    };
                                });
                            } else if (child.nodeType === Node.TEXT_NODE) {
                                itemText += child.textContent || '';
                            }
                        }

                        if (itemText) {
                            const bullet = isOrdered ? `${i + 1}. ` : '• ';
                            const lines = pdf.splitTextToSize(bullet + itemText, pageWidth - 2 * margin);
                            for (const line of lines) {
                                const lineHeight = pdf.getTextDimensions(line).h;
                                if (currentY + lineHeight > pageHeight - margin - footerHeight) {
                                    pdf.addPage();
                                    pageNumber++;
                                    currentY = headerHeight + 7; // Reset Y position after new page
                                    await addHeader(); // Add header on new page
                                    addFooter(); // Add footer on new page
                                }
                                pdf.text(line, margin, currentY);
                                currentY += lineHeight;
                            }
                            currentY += 5;
                        }
                    }
                } else if (element.nodeName === 'IMG') {
                    const img = element;
                    const imgUrl = img.getAttribute('src');
                    const imgFormat = imgUrl.split(';')[0].split('/')[1].toUpperCase();

                    const image = new Image();
                    image.src = imgUrl;

                    await new Promise((resolve) => {
                        image.onload = async () => {
                            const imgWidth = Math.min(image.width, pageWidth - 2 * margin);
                            const imgHeight = (imgWidth * image.height) / image.width;

                            if (currentY + imgHeight > pageHeight - margin - footerHeight) {
                                pdf.addPage();
                                pageNumber++;
                                currentY = headerHeight + 7; // Reset Y position after new page
                                await addHeader(); // Add header on new page
                                addFooter(); // Add footer on new page
                            }

                            pdf.addImage(imgUrl, imgFormat, margin, currentY, imgWidth, imgHeight);
                            currentY += imgHeight + 5;

                            resolve();
                        };
                        image.onerror = () => {
                            console.error('Failed to load image:', imgUrl);
                            resolve();
                        };
                    });
                }
            }
            // addFooter();
        };
        // Add header and footer to the first page
        await addHeader();
        addFooter();
        // Add content to the first page
        await addContentToPage(currentY, content);
        // Save the PDF
        pdf.save(`${formFields && formFields.title}.pdf`);
    };
    const handlePdfDownload = async () => {
        console.log("Editor Content:", formFields && formFields.contentText);

        // Normalize HTML content
        const normalizedContent = normalizeHtmlContent(formFields && formFields.contentText);
        console.log("Normalized Content:", normalizedContent);

        // Generate PDF with normalized content
        await pdfPrint(headerTitle, normalizedContent);
    };

    const normalizeHtmlContent = (html) => {
        // Create a temporary DOM element to parse and normalize the HTML content
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const body = doc.body;

        // Remove extra spaces and line breaks inside <p> tags
        const paragraphs = body.getElementsByTagName('p');
        for (let p of paragraphs) {
            p.innerHTML = p.innerHTML
                .replace(/[\r\n]+/g, ' ')  // Replace newlines with a space
                .replace(/\s{2,}/g, ' ')   // Replace multiple spaces with a single space
                .trim();
        }

        // Remove unnecessary line breaks and spaces in other tags if needed
        body.innerHTML = body.innerHTML
            .replace(/[\r\n]+/g, ' ')  // Replace newlines with a space
            .replace(/\s{2,}/g, ' ')   // Replace multiple spaces with a single space
            .trim();

        return body.innerHTML;
    };

    console.log("formFields:", formFields);

    const isEditable = formFields && formFields.isEditable;
    // Check if CSS or container styles are affecting visibility
    console.log("Editor Container Styles:", document.querySelector('.quill-container') && document.querySelector('.quill-container').style);

    return (
        <>
            {props.isUpdateEditDocsSuccess ? (
                <Navigate to='/visiondocs/document' />
            ) : (
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid={true}>
                            <Row>
                                <Col>
                                    <Card>
                                        <Form>
                                            <CardBody>
                                                {(props.errorMessage || props.detailsErrorMessage) && (
                                                    <VWMessage type="danger" defaultMessage={props.errorMessage || props.detailsErrorMessage} baseClassName="alert"></VWMessage>
                                                )}
                                                <CardTitle className="h4 card-title-container">
                                                    <FormGroup>
                                                        <Input
                                                            className='title-input'
                                                            type="text"
                                                            name="title"
                                                            placeholder="Enter Document Title"
                                                            value={formFields && formFields.title || ''}
                                                            onChange={handleTitleChange}
                                                            disabled={formFields && !formFields.isEditable}
                                                            style={{ width: '230px', marginRight: '16px' }}
                                                            invalid={errors && errors.title}
                                                            maxLength={maxLength}
                                                        /><FormFeedback>{errors && errors.title}</FormFeedback>
                                                    </FormGroup>
                                                    <div className="button-group">
                                                        {formFields && formFields.isEditable && (
                                                            <>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={handleOpenHeaderModal}
                                                                ><FontAwesomeIcon icon={faAdd} style={{ marginRight: '8px' }} />
                                                                    INSERT HEADER
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={handlePdfDownload}
                                                                ><FontAwesomeIcon icon={faDownload} style={{ marginRight: '8px' }} />
                                                                    DOWNLOAD
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    className="share-button"
                                                                    onClick={handleOpenModal}
                                                                >
                                                                    <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: '8px' }} />
                                                                    SHARE
                                                                </Button>
                                                                <Button
                                                                    type="submit"
                                                                    color="primary"
                                                                    onClick={handleSubmit}
                                                                    disabled={props.isPendingUpdate}
                                                                > {props.isPendingUpdate ? <>
                                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                                    &nbsp; Saving...
                                                                </>
                                                                    : <> <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
                                                                        SAVE</>}
                                                                </Button>
                                                            </>
                                                        )}
                                                    </div>
                                                </CardTitle>
                                                {(formFields && formFields.isEditable) ?
                                                    <div className="quill-container">
                                                        {''}
                                                        <ReactQuill
                                                            value={formFields && formFields.contentText}
                                                            onChange={handleEditorChange}
                                                            theme="snow"
                                                            placeholder="Start From Here..."
                                                            readOnly={!(formFields && formFields.isEditable)}
                                                            modules={{ toolbar: true }}
                                                        />
                                                    </div>
                                                    :

                                                    <div className="quill-container">
                                                        <ReactQuill
                                                            value={formFields && formFields.contentText}
                                                            onChange={handleEditorChange}
                                                            theme="snow"
                                                            placeholder="Start From Here..."
                                                            readOnly={!(formFields && formFields.isEditable)}
                                                            modules={{ toolbar: false }}
                                                        />
                                                    </div>
                                                }
                                            </CardBody>
                                        </Form>
                                    </Card>
                                    <VWModel
                                        header="UPDATE VISION DOCS"
                                        isOpen={props.isOpenVisionDocsModal}
                                        handleClose={handleClose}
                                    >
                                        <EditDocsModal data={formFields}
                                            handleClose={handleClose}
                                            handleDone={handleDone}
                                        />
                                    </VWModel>
                                    <VWModel
                                        header="INSERT HEADER"
                                        isOpen={props.openUpdateVisionDocsHeaderModal}
                                        handleClose={handleClose}
                                    >
                                        <EditDocsHeader data={formFields} handleClose={handleClose} handleDone={handleDone} />
                                    </VWModel>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment >
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    detailsErrorMessage: state.visionDocs.detailsErrorMessage,
    errorMessage: state.visionDocs.updateErrorMessage,
    isPendingDetails: state.visionDocs.isPendingDetails,
    isPendingUpdate: state.visionDocs.isPendingUpdate,
    DocsDetails: state.visionDocs.DocsDetails,
    isUpdateEditDocsSuccess: state.visionDocs.isUpdateEditDocsSuccess,
    isOpenVisionDocsModal: state.visionDocs.isOpenUpdateVisionDocsModal,
    openUpdateVisionDocsHeaderModal: state.visionDocs.openUpdateVisionDocsHeaderModal,
});

const mapDispatchToProps = (dispatch) => ({
    getVisionDocsDetailsAction: (id) => dispatch(getVisionDocsDetailsAction(id)),
    updateVisionDocsAction: (formFields) => dispatch(updateVisionDocs(formFields)),
    closeUpdateVisionDocsModalAction: () => dispatch(closeUpdateVisionDocsModal()),
    openUpdateVisionDocsModalAction: () => dispatch(openUpdateVisionDocsModal()),
    isUpdateEditDocspending: () => dispatch(isUpdateEditDocspending()),
    openUpdateVisionDocsHeaderModalAction: () => dispatch(openUpdateVisionDocsHeaderModalAction()),
    closeUpdateVisionDocsHeaderModal: () => dispatch(closeUpdateVisionDocsHeaderModal()),
    clearUpdateVisionDocsError: () => dispatch(clearUpdateVisionDocsError()),
    ClearVisionDocsDetails: () => dispatch(ClearVisionDocsDetails()),
    clearVisionDocsDetailsError: () => dispatch(clearVisionDocsDetailsError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormEditors);

