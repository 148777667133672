import { getToken } from "../../../../../helper/common"
import trimmedValues from "../../../../../helper/trim"
import agent from "../../../../services/agent"
import { showSnackbarStatus } from "../../../../snackbar/actions"
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions"
import { SET_VISIT_TYPE_DETAILS, VISIT_TYPE_CREATE_CLOSE, VISIT_TYPE_CREATE_ERROR, VISIT_TYPE_CREATE_ERROR_CLEAR, VISIT_TYPE_CREATE_OPEN, VISIT_TYPE_CREATE_PENDING, VISIT_TYPE_CREATE_SUCCESS, VISIT_TYPE_LIST, VISIT_TYPE_LIST_ERROR, VISIT_TYPE_LIST_ERROR_CLEAR, VISIT_TYPE_LIST_PENDING, VISIT_TYPE_UPDATE_CLOSE, VISIT_TYPE_UPDATE_ERROR, VISIT_TYPE_UPDATE_ERROR_CLEAR, VISIT_TYPE_UPDATE_OPEN, VISIT_TYPE_UPDATE_PENDING, VISIT_TYPE_UPDATE_SUCCESS } from "./constants"



//--------------------REGION-UPDATE-----------------------------------
export const openEditVisitTypeModal = () => ({
    type: VISIT_TYPE_UPDATE_OPEN
})
export const closeEditVisitTypeModal = () => ({
    type: VISIT_TYPE_UPDATE_CLOSE
})
export const updateVisitTypePending = () => ({
    type: VISIT_TYPE_UPDATE_PENDING
})
export const updateVisitTypeError = (errorMessage) => ({
    type: VISIT_TYPE_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateVisitTypeError = () => ({
    type: VISIT_TYPE_UPDATE_ERROR_CLEAR,
})
export const updateVisitTypeSuccess = () => ({
    type: VISIT_TYPE_UPDATE_SUCCESS,
})
export const setVisitTypeDetails = (visitTypeDetails) => ({
    type: SET_VISIT_TYPE_DETAILS,
    payload: { visitTypeDetails }
})

export const updateVisitType = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateVisitTypeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateVisitTypePending());
    dispatch(clearUpdateVisitTypeError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateVisitType(accessToken, dataToSend)
            .then(() => {
                dispatch(getVisitTypeList());
                dispatch(updateVisitTypeSuccess());
                dispatch(closeEditVisitTypeModal());
                dispatch(showSnackbarStatus('Visit type updated successfully'));
                dispatch(setIsInlineUpdate(null))
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateVisitTypeError(err.response && err.response.text));
                    return;
                }
                console.log('Update Visit Type server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
//--------------------Visit-Type-CREATE-----------------------------------
export const openAddVisitTypeModal = () => ({
    type: VISIT_TYPE_CREATE_OPEN
})
export const closeAddVisitTypeModal = () => ({
    type: VISIT_TYPE_CREATE_CLOSE
})

export const createVisitTypePending = () => ({
    type: VISIT_TYPE_CREATE_PENDING
})
export const createVisitTypeError = (errorMessage) => ({
    type: VISIT_TYPE_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateVisitTypeError = () => ({
    type: VISIT_TYPE_CREATE_ERROR_CLEAR,
})
export const createVisitTypeSuccess = () => ({
    type: VISIT_TYPE_CREATE_SUCCESS,
})

export const createNewVisitType = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createVisitTypeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createVisitTypePending());
    dispatch(clearCreateVisitTypeError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createVisitType(accessToken, dataToSend)
            .then(() => {
                dispatch(getVisitTypeList());
                dispatch(createVisitTypeSuccess());
                dispatch(closeAddVisitTypeModal());
                dispatch(showSnackbarStatus('Visit type created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createVisitTypeError(err.response && err.response.text));
                    return;
                }
                console.log('Create VisitType server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};


//-----------------Visit Type List ----------------------------------------

export const visitTypeListPending = () => ({
    type: VISIT_TYPE_LIST_PENDING
})
export const visitTypeListError = (errorMessage) => ({
    type: VISIT_TYPE_LIST_ERROR,
    payload: { errorMessage }
})
export const clearVisitTypeListError = () => ({
    type: VISIT_TYPE_LIST_ERROR_CLEAR,
})
export const setVisitTypeList = (visitTypeList) => ({
    type: VISIT_TYPE_LIST,
    payload: { visitTypeList }
})

export const getVisitTypeList = (page, PageSize) => (dispatch) => {
    dispatch(visitTypeListPending());
    dispatch(clearVisitTypeListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getVisitTypeList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setVisitTypeList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(visitTypeListError(error));
                console.log('Create VisitType server error', error);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};