import {
    TASK_TYPE_CREATE_CLOSE,
    TASK_TYPE_CREATE_ERROR,
    TASK_TYPE_CREATE_ERROR_CLEAR,
    TASK_TYPE_CREATE_OPEN,
    TASK_TYPE_CREATE_PENDING,
    TASK_TYPE_CREATE_SUCCESS,
    TASK_TYPE_LIST_ERROR,
    TASK_TYPE_LIST_ERROR_CLEAR,
    TASK_TYPE_UPDATE_CLOSE,
    TASK_TYPE_UPDATE_ERROR,
    TASK_TYPE_UPDATE_ERROR_CLEAR,
    TASK_TYPE_UPDATE_OPEN,
    TASK_TYPE_UPDATE_PENDING,
    TASK_TYPE_UPDATE_SUCCESS,
    SET_TASK_TYPE_DETAILS,
    SET_TASK_TYPE_LIST,
    TASK_TYPE_LIST_PENDING,
} from "./constants";

const initialState = {
    //--------------------TASK TYPE-CREATE-----------------------------------
    isCreateTaskTypeOpen: false,
    TaskTypeCreateErrorMessage: null,
    isTaskTypeCreateSuccess: false,
    isTaskTypeCreatePending: false,
    //--------------------TASK TYPE-UPDATE-----------------------------------
    taskTypeDetails: null,
    isUpdateTaskTypeOpen: false,
    taskTypeUpdateErrorMessage: null,
    isTaskTypeUpdateSuccess: false,
    isTaskTypeUpdatePending: false,
    //--------------------TASK TYPE-LIST-------------------------------------
    taskTypeList: [],
    taskTypeListCount: 0,
    isTaskTypeListPending: false,
    taskTypeListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------TASK TYPE-CREATE-----------------------------------
        case TASK_TYPE_CREATE_OPEN: {
            return {
                ...localState,
                isCreateTaskTypeOpen: true
            }
        }
        case TASK_TYPE_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateTaskTypeOpen: false
            }
        }
        case TASK_TYPE_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                TaskTypeCreateErrorMessage: null,
            }
        }
        case TASK_TYPE_CREATE_PENDING: {
            return {
                ...localState,
                isTaskTypeCreatePending: true,
            }
        }
        case TASK_TYPE_CREATE_SUCCESS: {
            return {
                ...localState,
                isTaskTypeCreateSuccess: true,
                isTaskTypeCreatePending: false
            }
        }
        case TASK_TYPE_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                TaskTypeCreateErrorMessage: errorMessage,
                isTaskTypeCreatePending: false,
            }
        }

        // //--------------------TASK TYPE-UPDATE-----------------------------------
        case SET_TASK_TYPE_DETAILS: {
            const { typeDetails } = action.payload;
            return {
                ...localState,
                taskTypeDetails: typeDetails
            }
        }
        case TASK_TYPE_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateTaskTypeOpen: true
            }
        }
        case TASK_TYPE_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateTaskTypeOpen: false
            }
        }
        case TASK_TYPE_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                taskTypeUpdateErrorMessage: null,
            }
        }
        case TASK_TYPE_UPDATE_PENDING: {
            return {
                ...localState,
                isTaskTypeUpdatePending: true,
            }
        }
        case TASK_TYPE_UPDATE_SUCCESS: {
            return {
                ...localState,
                isTaskTypeUpdateSuccess: true,
                isTaskTypeUpdatePending: false
            }
        }
        case TASK_TYPE_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                taskTypeUpdateErrorMessage: errorMessage,
                isTaskTypeUpdatePending: false
            }
        }
        //--------------------TASK TYPE-LIST-------------------------------------
        case SET_TASK_TYPE_LIST: {
            const { taskTypeList } = action.payload;
            return {
                ...localState,
                taskTypeList: taskTypeList && taskTypeList['value'],
                taskTypeListCount: taskTypeList && taskTypeList['@odata.count'],
                isTaskTypeListPending: false,
                taskTypeListErrorMessage: null,
            }
        }
        case TASK_TYPE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                taskTypeListErrorMessage: errorMessage,
                isTaskTypeListPending: false,
                taskTypeList: []
            }
        }
        case TASK_TYPE_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                taskTypeListErrorMessage: null,
            }
        }
        case TASK_TYPE_LIST_PENDING: {
            return {
                ...localState,
                isTaskTypeListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};