import { CLEAR_AVAILABLE_LEAVE_COUNT, CLEAR_AVAILABLE_LEAVE_COUNT_ERROR, CLEAR_LEAVE_COUNT, CLEAR_LEAVE_COUNT_ERROR, CLEAR_LEAVE_ERROR, CLEAR_LEAVE_TRACKER_ACTION_ERROR, CLEAR_LEAVE_TRACKER_DETAILS_ERROR, CLEAR_LEAVE_TRACKER_ERROR, CLEAR_LEAVE_TRACKER_EXPORT_LIST_ERROR, CLEAR_LEAVE_TRACKER_LIST_ERROR, CLEAR_UPDATE_LEAVE_ERROR, CLOSE_CREATE_APPLY_LEAVE_MODAL, CLOSE_LEAVE_TRACKER_MODAL, CLOSE_UPDATE_APPLY_LEAVE_MODAL, CREATE_AVAILABLE_LEAVE_COUNT_ERROR, CREATE_LEAVE_COUNT_ERROR, CREATE_LEAVE_ERROR, CREATE_LEAVE_PENDING, CREATE_LEAVE_SUCCESS, CREATE_LEAVE_TRACKER_ACTION_ERROR, CREATE_LEAVE_TRACKER_ACTION_SUCCESS, CREATE_LEAVE_TRACKER_STATUS_PENDING, LEAVE_TRACKER_EXPORT_SUCCESS, OPEN_CREATE_APPLY_LEAVE_MODAL, OPEN_LEAVE_TRACKER_MODAL, OPEN_UPDATE_APPLY_LEAVE_MODAL, SET_AVAILABLE_LEAVE_COUNT, SET_AVAILABLE_LEAVE_COUNT_PENDING, SET_CALENDER_DATES, SET_ENABLE_ORG_LEAVE_COUNT, SET_FILTER_DATA, SET_LEAVE_COUNT, SET_LEAVE_COUNT_PENDING, SET_LEAVE_DETAILS, SET_LEAVE_TRACKER_COUNT_LIST, SET_LEAVE_TRACKER_DETAILS_ERROR, SET_LEAVE_TRACKER_DETAILS_PENDING, SET_LEAVE_TRACKER_EXPORT_LIST, SET_LEAVE_TRACKER_EXPORT_LIST_ERROR, SET_LEAVE_TRACKER_EXPORT_PENDING, SET_LEAVE_TRACKER_LIST, SET_LEAVE_TRACKER_LIST_ERROR, SET_LEAVE_TRACKER_PENDING, UPDATE_LEAVE_ERROR, UPDATE_LEAVE_PENDING, UPDATE_LEAVE_SUCCESS } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    LeaveTrackerList: [],
    leaveTrackerListError: null,
    totalRecords: 0,
    isLeaveTrackerModalOpen: false,
    createLeaveTrackerErrorMessage: '',
    LeaveDetails: null,
    isCreateleaveModalOpen: false,
    isEditLeaveModalOpen: false,
    isUpdateLeavePending: false,
    updateLeaveErrorMessage: '',
    isLeaveTrackerPending: false,
    isLeaveTrackerDetailsPending: false,
    leaveTrackerDetailsError: null,
    Dates: [],
    totalDatesRecords: 0,
    createLeaveCountErrorMessage: null,
    isLeaveCountPending: false,
    LeaveCount: null,
    isAvailableLeaveCountPending: false,
    availableLeaveCount: null,
    createAvailableLeaveCountErrorMessage: null,
    LeaveTypeCount:0,
    leaveTrackerExportListError: null,
    isLeaveTrackerExportPending: false,
    LeaveTrackerExportList:[],
    OrgLeaveTypeCountList:[]

};
export default (localState = initialState, action) => {
    switch (action.type) {
       
        case SET_ENABLE_ORG_LEAVE_COUNT: {
            const { orgLeaveType } = action.payload;
            return {
                ...localState,
                OrgLeaveTypeCountList: orgLeaveType,
            }
        }
        case SET_LEAVE_TRACKER_LIST: {
            const { leaveTrackerList } = action.payload;
            return {
                ...localState,
                isLeaveTrackerPending: false,
                LeaveTrackerList: leaveTrackerList && leaveTrackerList.value,
                totalRecords: leaveTrackerList["@odata.count"],
            }
        }
        case SET_LEAVE_TRACKER_EXPORT_LIST: {
            const { leaveTrackerExportList } = action.payload;
            return {
                ...localState,
                isLeaveTrackerExportPending: false,
                LeaveTrackerExportList: leaveTrackerExportList,
            }
        }
        case SET_LEAVE_TRACKER_COUNT_LIST: {
            const { leaveTrackerCountList } = action.payload;
            return {
                ...localState,
                LeaveTypeList: leaveTrackerCountList && leaveTrackerCountList.value,
                LeaveTypeCount: leaveTrackerCountList["@odata.count"],
            }
        }
        case SET_CALENDER_DATES: {
            const { Dates } = action.payload;
            console.log(Dates)
            return {
                ...localState,
                Dates: Dates && Dates.value,
                totalDatesRecords: Dates["@odata.count"],
            }
        }
        case SET_LEAVE_TRACKER_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isLeaveTrackerPending: false,
                leaveTrackerListError: errorMessage,
            };
        }
        case SET_LEAVE_TRACKER_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isLeaveTrackerExportPending: false,
                leaveTrackerExportListError: errorMessage,
            };
        }
        case CLEAR_LEAVE_TRACKER_LIST_ERROR: {
            return {
                ...localState,
                leaveTrackerListError: null,
            };
        }
        case CLEAR_LEAVE_TRACKER_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                leaveTrackerExportListError: null,
            };
        }
        case OPEN_LEAVE_TRACKER_MODAL: {
            return {
                ...localState,
                isLeaveTrackerModalOpen: true,
            };
        }
        case CLEAR_LEAVE_TRACKER_ACTION_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CLOSE_LEAVE_TRACKER_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                createLeaveTrackerErrorMessage: null,
                isLeaveTrackerModalOpen: false,
            };
        }
        case CREATE_LEAVE_TRACKER_STATUS_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CLEAR_LEAVE_TRACKER_ERROR: {
            return {
                ...localState,
                errorMessage: null,
                createLeaveTrackerErrorMessage: null
            };
        }
        case CREATE_LEAVE_TRACKER_ACTION_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CREATE_LEAVE_TRACKER_ACTION_ERROR: {
            const { errorMessage } = action.payload;
            // console.log("errorMessage: ", errorMessage)
            return {
                ...localState,
                createLeaveTrackerErrorMessage: errorMessage,
                isPending: false,
            };
        }
        case SET_LEAVE_DETAILS: {
            const { LeaveDetails } = action.payload;
            return {
                ...localState,
                isLeaveTrackerDetailsPending: false,
                LeaveDetails: LeaveDetails,
            }
        }

        case SET_LEAVE_TRACKER_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                LeaveDetails: {},
                isLeaveTrackerDetailsPending: false,
                leaveTrackerDetailsError: errorMessage,
            };
        }
        case CLEAR_LEAVE_TRACKER_DETAILS_ERROR: {
            return {
                ...localState,
                leaveTrackerDetailsError: null,
            };
        }
        case OPEN_CREATE_APPLY_LEAVE_MODAL: {
            return {
                ...localState,
                isCreateleaveModalOpen: true,
            };
        }
        case OPEN_UPDATE_APPLY_LEAVE_MODAL: {
            return {
                ...localState,
                isEditLeaveModalOpen: true,
            };
        }
        case CLOSE_CREATE_APPLY_LEAVE_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateleaveModalOpen: false,
                LeaveCount: null
            };
        }
        case CLOSE_UPDATE_APPLY_LEAVE_MODAL: {
            return {
                ...localState,
                updateLeaveErrorMessage: null,
                isEditLeaveModalOpen: false,
            };
        }
        case CREATE_LEAVE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_LEAVE_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CLEAR_LEAVE_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CREATE_LEAVE_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_UPDATE_LEAVE_ERROR: {
            return {
                ...localState,
                updateLeaveErrorMessage: null,
                isUpdateLeavePending: false,
            };
        }
        case UPDATE_LEAVE_PENDING: {
            return {
                ...localState,
                isUpdateLeavePending: true,
            };
        }
        case UPDATE_LEAVE_SUCCESS: {
            return {
                ...localState,
                isUpdateLeavePending: false,
            };
        }
        case UPDATE_LEAVE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateLeaveErrorMessage: errorMessage,
                isUpdateLeavePending: false,
            };
        }
        case SET_LEAVE_TRACKER_PENDING: {
            return {
                ...localState,
                leaveTrackerListError: null,
                isLeaveTrackerPending: true
            }
        }
        case SET_LEAVE_TRACKER_EXPORT_PENDING: {
            return {
                ...localState,
                leaveTrackerExportListError: null,
                isLeaveTrackerExportPending: true
            }
        }
        case LEAVE_TRACKER_EXPORT_SUCCESS: {
            return {
                ...localState,
                leaveTrackerExportListError: null,
                isLeaveTrackerExportPending: false
            }
        }

        case SET_LEAVE_TRACKER_DETAILS_PENDING: {
            return {
                ...localState,
                leaveTrackerDetailsError: null,
                isLeaveTrackerDetailsPending: true
            }
        }
        case SET_LEAVE_COUNT_PENDING: {
            return {
                ...localState,
                leaveCountListError: null,
                isLeaveCountPending: true,
            }
        }
        case SET_LEAVE_COUNT: {
            const { LeaveCount } = action.payload;
            return {
                ...localState,
                isLeaveCountPending: false,
                LeaveCount: LeaveCount,
            }
        }
        case CLEAR_LEAVE_COUNT: {
            return {
                ...localState,
                isLeaveCountPending: false,
                LeaveCount: null,
            }
        }
        case CLEAR_LEAVE_COUNT_ERROR: {
            return {
                ...localState,
                createLeaveCountErrorMessage: null,
            };
        }
        case CREATE_LEAVE_COUNT_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                createLeaveCountErrorMessage: errorMessage,
                isLeaveCountPending: false,
            };
        }
        case SET_AVAILABLE_LEAVE_COUNT_PENDING: {
            return {
                ...localState,
                availableLeaveCountListError: null,
                isAvailableLeaveCountPending: true
            }
        }
        case CLEAR_AVAILABLE_LEAVE_COUNT: {
            return {
                ...localState,
                isAvailableLeaveCountPending: false,
                availableLeaveCount: null,
            }
        }
        case SET_AVAILABLE_LEAVE_COUNT: {
            const { AvailableLeaveCount } = action.payload;
            return {
                ...localState,
                isAvailableLeaveCountPending: false,
                availableLeaveCount: AvailableLeaveCount,
            }
        }
        case CLEAR_AVAILABLE_LEAVE_COUNT_ERROR: {
            return {
                ...localState,
                createAvailableLeaveCountErrorMessage: null,
            };
        }
        case CREATE_AVAILABLE_LEAVE_COUNT_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                createAvailableLeaveCountErrorMessage: errorMessage,
                isAvailableLeaveCountPending: false,
            };
        }
        default: {
            return localState;
        }
    }
};