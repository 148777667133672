import React, { useEffect, useState } from 'react'
import VWModel from '../../../../components/Modal/VWModal'
import moment from 'moment'
import { Badge, Card, CardBody, CardFooter, CardTitle, Col, Input, Row, Table } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { clearOrgUserBasedOrgError, closeEditOrganizationModal, getOrganizationDetailsAction, getOrgUserBasedOnOrgList, openEditOrganizationModal } from '../../../../store/Modules/Admin/organization/actions'
import OrganizationEdit from './OrganizationEdit'
import Select from 'react-select';
import VWMessage from '../../../../components/vwMessage/vwMessage'
import VWSpinner from '../../../../components/vwSpinner/vwSpinner'
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions'
import { getPager } from '../../../../helper/common'
import VWPagination from '../../../../components/vwPagination/vwPagination'
import NoRecord from '../../../../components/NoRecords/noRecords'

const statusOptions = [
    { label: 'Active', value: "true" },
    { label: 'Inactive', value: "false" },
]
const accountStatusOptions = [
    { label: 'Enabled', value: "true" },
    { label: 'Disabled', value: "false" },
]
const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const OrganizationDetails = (props) => {
    const params = useParams();
    const { id } = params;
    const { errorMessage, OrganizationDetails, isEditOrganizationModalOpen, orgUserBasedOrgListError, isOrgUserBasedOrgListPending } = props || {};
    const { OrgUserBasedOrgList } = props || [];
    const { orgName, orgContactPerson, orgContactEmail, orgContactNumber, orgAddress,
        appDetails, orgCreatedBy, orgCreatedTimeStamp, userBasedAppRoleList, isActive } = OrganizationDetails || {};
    const [filter, setFilter] = useState();
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });

    useEffect(() => {
        props.getOrganizationDetails(id);
        props.clearOrgUserBasedOrgError();
        // props.getOrgUserBasedOnOrgList(id);
        props.ClearPath();
    }, [props.getOrganizationDetails, id]);

    useEffect(() => {
        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();
        }
    }, []);
    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditOrganizationModel = () => {
        const { openEditOrganizationModalAction } = props;
        openEditOrganizationModalAction();
    };

    const handleCloseEditOrganizationModal = () => {
        const { closeEditOrganizationModalAction } = props;
        closeEditOrganizationModalAction();
    };
    //---------------------------------------------Role-----------------------------------------------------------------
    const { roles, userAppDetails } = props.currentProfile || {};
    const Roles = roles && roles.map((data) => (
        data.roleName
    ))

    // ----------------------------------------SORTING---------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    //--------------------------------------------------------------------pagination--------------------------------------------------------------------------------------------------------------------------------------------------------------
    const setPage = (page = 1) => {
        props.getOrgUserBasedOnOrgList(id, page, PageSize, filter);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.ClearPath();
    }, [])
    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }
    const handleSearchClick = () => {
        setPage();
    }

    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])

    const searchStatus = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            IsActive: value,
        })
    }
    const searchPortalEnable = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            IsPortalEnable: value,
        })
    }
    console.log("totalCount", props.totalCount);

    const sortedOrgUserBasedOrgList = [...OrgUserBasedOrgList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        if (sortOrder === 'IsPortalEnable') {
            return sortDirection === 'asc' ? b.IsPortalEnable - a.IsPortalEnable : a.IsPortalEnable - b.IsPortalEnable;
        }
        if (sortOrder === 'UserAppDetails') {
            const aAppName = a.UserAppDetails && a.UserAppDetails.length > 0 ? a.UserAppDetails[0].AppName : '';
            const bAppName = b.UserAppDetails && b.UserAppDetails.length > 0 ? b.UserAppDetails[0].AppName : '';
            return sortDirection === 'asc' ? aAppName.localeCompare(bAppName) : bAppName.localeCompare(aAppName);
        }

        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>ORGANIZATION DETAILS</strong>

                        {
                            (!errorMessage && userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "admin").length > 0 &&
                                userAppDetails
                                    .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "admin")
                                    .map(userApp => {
                                        const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                                            const roleName = role.roleName.toLowerCase();
                                            return roleName;
                                        });
                                        const isSuperAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "super admin").length > 0;
                                        return (
                                            isSuperAdmin ? (
                                                <Link type="button"
                                                    // className="btn btn-outline-secondary"
                                                    className="btn btn-info"
                                                    onClick={handleOpenEditOrganizationModel}
                                                >
                                                    EDIT
                                                </Link>
                                            ) : null
                                        );
                                    })
                            )
                        }
                    </CardTitle>
                    <CardBody className="p-4">
                        {errorMessage ? <div className='w-100'>
                            {errorMessage && (
                                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}

                        {props.isOrganizationDetailsPending ?

                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>

                            :
                            <Row >
                                <div>
                                    <div className="p-3 bg-light fw-bold fs-4">
                                        <span className='text-capitalize'>{orgName || "N/A"}</span>
                                    </div>
                                </div>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Organization Name</strong>
                                                            </td>
                                                            <td>{orgName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Contact Person</strong>
                                                            </td>
                                                            <td>{orgContactPerson || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Contact Email</strong>
                                                            </td>
                                                            <td>{orgContactEmail || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Contact #</strong>
                                                            </td>
                                                            <td>{orgContactNumber || "N/A"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Address</strong>
                                                            </td>
                                                            <td>{orgAddress || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Assigned Apps</strong>
                                                            </td>
                                                            <td>{appDetails && appDetails.length > 0 ?
                                                                appDetails && appDetails.map((items, index) => (                                                                        //eslint-disable-next-line
                                                                    <React.Fragment key={index}>
                                                                        <Badge color='success'>{items.appName}</Badge>&nbsp;
                                                                        {index !== appDetails.length - 1 && '  '}
                                                                    </React.Fragment>
                                                                ))
                                                                : "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Created By</strong>
                                                            </td>
                                                            <td>{orgCreatedBy || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Created Date</strong>
                                                            </td>
                                                            <td>
                                                                {orgCreatedTimeStamp ? moment(orgCreatedTimeStamp).format('DD MMM YYYY',) : 'NA'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong> Status</strong>
                                                            </td>
                                                            <td>
                                                                {isActive ? (
                                                                    <Badge color="success" className="text-dark-white">
                                                                        Active
                                                                    </Badge>
                                                                ) : (
                                                                    <Badge color="danger" className="text-dark-white">
                                                                        Inactive
                                                                    </Badge>
                                                                )}
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>

                        }
                    </CardBody>
                    <VWModel
                        header="EDIT ORGANIZATION"
                        isOpen={isEditOrganizationModalOpen}
                        handleClose={handleCloseEditOrganizationModal}
                    >
                        <OrganizationEdit handleClose={handleCloseEditOrganizationModal} />
                    </VWModel>
                </Card>
                <Card>
                    <CardBody className="p-4">
                        {(orgUserBasedOrgListError) ? <div className='w-100'>
                            {(orgUserBasedOrgListError) && (
                                <VWMessage type="danger" defaultMessage={orgUserBasedOrgListError} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        <div style={{ height: "350px", overflowX: "auto", backgroundColor: "white" }}>
                            <Table className="table table-striped table-hover w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <tr>
                                        <th style={{ width: "80vh" }} className='hand-cursor'
                                            onClick={() => handleSort('UserEmail')}>User&nbsp;
                                            {sortOrder === 'UserEmail' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}</th>
                                        <th style={{ width: "80vh" }} className='hand-cursor'
                                            onClick={() => handleSort('IsActive')}>User Status&nbsp;
                                            {sortOrder === 'IsActive' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}</th>
                                        <th style={{ width: "80vh" }} className='hand-cursor'
                                            onClick={() => handleSort('IsPortalEnable')}>Account Status&nbsp;
                                            {sortOrder === 'IsPortalEnable' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}</th>
                                        <th style={{ width: "80vh" }} className='hand-cursor'
                                            onClick={() => handleSort('UserAppDetails')}>Assigned Apps and Roles&nbsp;
                                            {sortOrder === 'UserAppDetails' && (
                                                <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                            )}</th>
                                    </tr>
                                    <tr>
                                        <th >
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='UserEmail'
                                                onChange={searchInputValidation}
                                                value={filter && filter.UserEmail}
                                            // onKeyPress={handleKeyPress}
                                            /></th>
                                        <th>
                                            <Select
                                                styles={customStyles}
                                                name="IsActive"
                                                isClearable
                                                defaultOptions
                                                placeholder="Search"
                                                options={statusOptions}
                                                onChange={searchStatus}
                                            />
                                        </th>
                                        <th>
                                            <Select
                                                styles={customStyles}
                                                name="IsPortalEnable"
                                                isClearable
                                                defaultOptions
                                                placeholder="Search"
                                                options={accountStatusOptions}
                                                onChange={searchPortalEnable}
                                            />
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!isOrgUserBasedOrgListPending && (OrgUserBasedOrgList && OrgUserBasedOrgList.length > 0)) ?

                                        sortedOrgUserBasedOrgList && sortedOrgUserBasedOrgList.map((data, index) => (
                                            <tr>
                                                <td className='ps-3'>{data.UserName || "N/A"}<br />
                                                    <small>{data.UserEmail || "N/A"}</small></td>
                                                <td className='ps-3'>{data.IsActive ? (
                                                    <Badge color="success" className="text-dark-white">
                                                        Active
                                                    </Badge>
                                                ) : (
                                                    <Badge color="danger" className="text-dark-white">
                                                        Inactive
                                                    </Badge>
                                                )}</td>
                                                <td className='ps-3'>{data.IsPortalEnable ? (
                                                    <Badge color="success" className="text-dark-white">
                                                        Enabled
                                                    </Badge>
                                                ) : (
                                                    <Badge color="danger" className="text-dark-white">
                                                        Disabled
                                                    </Badge>
                                                )}</td>
                                                <td className='ps-3'>
                                                    {data.UserAppDetails && data.UserAppDetails.length > 0 ? (
                                                        data.UserAppDetails && data.UserAppDetails.map((app, appIndex) => (
                                                            <div key={appIndex}>
                                                                {app.AppRoleDetails && app.AppRoleDetails.map((role, roleIndex) => (
                                                                    <Badge key={roleIndex} color="success" className="text-dark-white mx-1">
                                                                        {app.AppName} / {role.RoleName}
                                                                    </Badge>
                                                                ))}
                                                            </div>
                                                        ))
                                                    ) :
                                                        'N/A'
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        isOrgUserBasedOrgListPending ?
                                            <tr>
                                                <td colSpan={11}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <VWSpinner />
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {OrgUserBasedOrgList && OrgUserBasedOrgList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    OrganizationDetails: state.Organization.OrganizationDetails,
    errorMessage: state.Organization.orgDetailsError,
    isEditOrganizationModalOpen: state.Organization.isEditOrganizationModalOpen,
    currentProfile: state.login.currentProfile,
    isOrganizationDetailsPending: state.Organization.isOrganizationDetailsPending,
    OrgUserBasedOrgList: state.Organization.OrgUserBasedOrgList,
    totalCount: state.Organization.OrgUserBasedOrgtotalRecords,
    orgUserBasedOrgListError: state.Organization.orgUserBasedOrgListError,
    isOrgUserBasedOrgListPending: state.Organization.isOrgUserBasedOrgListPending,
});

const mapDispatchToProps = (dispatch) => ({
    getOrgUserBasedOnOrgList: (Id, page, PageSize, filter) => dispatch(getOrgUserBasedOnOrgList(Id, page, PageSize, filter)),
    getOrganizationDetails: (Id) => dispatch(getOrganizationDetailsAction(Id)),
    openEditOrganizationModalAction: () => dispatch(openEditOrganizationModal()),
    closeEditOrganizationModalAction: () => dispatch(closeEditOrganizationModal()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
    clearOrgUserBasedOrgError: () => dispatch(clearOrgUserBasedOrgError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails)