import React, { useEffect, useState } from 'react'
import { Gantt, ViewMode, Task } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { getStartEndDateForProject, initTasks } from "./Helper";
import ViewSwitcher from './ViewSwitcher';
import './gantt.css'
import Async from 'react-select/async';
import { connect } from 'react-redux';
import { getProjectNameAsyncSelect } from '../../../../store/Modules/ProjectPortal/Task/actions';
import { clearGanttListError, clearGanttMilestoneUpdateError, GanttSelectedProject, getProjectGanttListAction, updateGanttMilestoneDate } from '../../../../store/Modules/ProjectPortal/GanttChart/actions';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import NoRecord from '../../../../components/NoRecords/noRecords';
import TestGanttChart from './testGanttChart';

const GanttChart = (props) => {
    // //----------------------------------------------------------------------------------------------------------

    // const [view, setView] = useState(ViewMode.Day);
    // const [tasks, setTasks] = useState([]);
    // const [isChecked, setIsChecked] = useState(true);
    // const [existingProject, setExistingProject] = useState([]);

    // let columnWidth = 65;
    // if (view === ViewMode.Year) {
    //     columnWidth = 350;
    // } else if (view === ViewMode.Month) {
    //     columnWidth = 300;
    // } else if (view === ViewMode.Week) {
    //     columnWidth = 250;
    // }

    // useEffect(() => {
    //     props.clearGanttMilestoneUpdateError();
    //     props.clearGanttListError();
    //     if (props.GanttProjectList && props.GanttProjectList.milestones && props.GanttProjectList.milestones.length > 0) {
    //         if (props.SelectedGanttProject) {
    //             setExistingProject(props.SelectedGanttProject);
    //         }
    //         const project = props.GanttProjectList && props.GanttProjectList.project;
    //         const newProject = { ...project, start: new Date(project && project.start), end: new Date(project && project.end) }
    //         // console.log("GanttProjectList:", props.GanttProjectList);
    //         // console.log("project:", project);
    //         // console.log("newProject:", newProject);

    //         const formattedMilestones = props.GanttProjectList && props.GanttProjectList.milestones && props.GanttProjectList.milestones.map((task, index) => ({
    //             ...task,
    //             start: task.start ? new Date(task && task.start) : new Date(),
    //             end: task.end ? new Date(task && task.end) : new Date(),
    //         }));

    //         const finalTasks = [newProject, ...formattedMilestones];
    //         // console.log("finalTasks:", finalTasks);

    //         setTasks(finalTasks);
    //     } else {
    //         setTasks([]);
    //     }
    // }, [props.GanttProjectList]);

    // useEffect(() => {
    //     if (props.GanttProjects) {
    //         setExistingProject(props.GanttProjects[0]);
    //         // console.table('selectedIds:', props.GanttProjects[0] && props.GanttProjects[0].value);
    //         if (props.GanttProjects[0] && props.GanttProjects[0].value) {
    //             props.getProjectGanttListAction(props.GanttProjects[0] && props.GanttProjects[0].value);
    //         }
    //         props.GanttSelectedProject(props.GanttProjects[0]);
    //     }
    // }, [props.GanttProjects])
    // const handleTaskChange = async (task) => {
    //     // console.log("On date change for task:", task);

    //     if (!task.start || !task.end) {
    //         // console.error("Task dates are not defined:", task);
    //         return;
    //     }

    //     // Find the original task in the current state
    //     const originalTask = tasks && tasks.find(t => t.id === task.id);
    //     // console.log("originalTask:", originalTask);

    //     //  project start and end dates
    //     const projectStart = new Date(props.GanttProjectList && props.GanttProjectList.project && props.GanttProjectList.project.start);
    //     const projectEnd = new Date(props.GanttProjectList && props.GanttProjectList.project && props.GanttProjectList.project.end);

    //     // Adjust task start and end dates if they are outside the project dates
    //     let adjustedStart = task.start;
    //     let adjustedEnd = task.end;

    //     if (adjustedStart < projectStart) {
    //         adjustedStart = projectStart; // Set to project start
    //     }

    //     // Check and adjust the end date
    //     if (adjustedEnd > projectEnd) {
    //         adjustedEnd = projectEnd;
    //     }

    //     if (adjustedEnd < adjustedStart) {
    //         adjustedEnd = adjustedStart;
    //     }

    //     if ((adjustedStart < projectStart) && (adjustedEnd > projectEnd)) {
    //         console.log("Both start and end date outside the project date");
    //     }
    //     // Update the local tasks state immediately
    //     const newTasks = tasks.map(t => (t.id === task.id ? { ...task, start: adjustedStart, end: adjustedEnd } : t));
    //     setTasks(newTasks);

    //     const startDate = new Date(adjustedStart.getTime() - (adjustedStart.getTimezoneOffset() * 60000));
    //     const endDate = new Date(adjustedEnd.getTime() - (adjustedEnd.getTimezoneOffset() * 60000));

    //     const updateMilestoneDate = {
    //         id: task.id,
    //         startDate: startDate.toISOString(),
    //         endDate: endDate.toISOString(),
    //     };

    //     // console.log("updateMilestoneDate:", updateMilestoneDate);
    //     await props.updateGanttMilestoneDate(updateMilestoneDate, task.project, task.name);
    // };

    // const handleExpanderClick = (task) => {
    //     setTasks(tasks && tasks.map((t) => (t.id === task.id ? task : t)));
    //     // console.log("On expander click Id:" + task.id);
    // };
    // //-----------------------------------------------------------------------------------------------------------------------------------
    // const handleTaskDelete = (task) => {
    //     const conf = window.confirm("Are you sure about " + task.name + " ?");
    //     if (conf) {
    //         setTasks(tasks.filter((t) => t.id !== task.id));
    //     }
    //     return conf;
    // };
    // const handleProgressChange = async (task) => {
    //     setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    //     // console.log("On progress change Id:" + task.id);
    // };
    // const handleDblClick = (task) => {
    //     // console.log("task:", task);
    //     alert("On Double Click event Id:" + task.id);
    // };
    // const handleSelect = (task, isSelected) => {
    //     // console.log("task:", task);

    //     // console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
    // };

    // const handleClick = (task) => {
    //     // console.log("task:" + task);
    //     // console.log("On Click event Id:" + task.id);
    // };

    // //--------------------------------------- Default Options ---------------------------------------------------------
    // const handleProject = (inputValue, callback) => {
    //     const { getProjectNameAsyncSelectAction } = props;
    //     return getProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    // };
    // //----------------------------------------Add Project Name-------------------------------------------------------
    // const handleAddProjectName = (value) => {
    //     setExistingProject(value);
    //     // console.table('selectedIds:', value);
    //     // console.table('selectedIds:', value.value);
    //     props.getProjectGanttListAction(value.value);
    //     props.GanttSelectedProject(value);
    // }

    // const CustomTooltipContent = ({ task }) => {
    //     // console.log("task:", task);

    //     const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

    //     const formattedStart = task.start.toLocaleDateString('en-GB', options);
    //     const formattedEnd = task.end.toLocaleDateString('en-GB', options);
    //     return (
    //         <div style={{ padding: '10px', backgroundColor: '#fff', border: '1px solid #ccc' }}>
    //             <h4>{task.name}</h4>
    //             <p>Start Date: {formattedStart}</p>
    //             <p>End Date: {formattedEnd}</p>
    //             <p>Progress: {task.progress}%</p>
    //         </div>
    //     );
    // };

    // console.log("tasks:", tasks);
    // console.log("GanttProjectList:", props.GanttProjectList);
    // console.log("SelectedGanttProject:", props.SelectedGanttProject);
    // console.log("existingProject:", existingProject);
    // console.log("GanttProjects:", props.GanttProjects[0]);

    return (
        <React.Fragment>
            {/* <div className="page-content">
                <div className="control-container">
                    <Async
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                minWidth: "250px",
                                maxWidth: "550px",
                                minHeight: "10px"
                            })
                        }}
                        // isClearable
                        name="ProjectId"
                        placeholder="Select Project"
                        className="vm-react-select"
                        isSearchable
                        defaultOptions
                        loadOptions={handleProject}
                        onChange={handleAddProjectName}
                        value={existingProject}
                    />
                    <ViewSwitcher
                        onViewModeChange={(viewMode) => setView(viewMode)}
                        onViewListChange={setIsChecked}
                        isChecked={isChecked}
                    /></div>
                {props.isGanttListError || props.isGanttMilestoneUpdateError ? <div className='w-100'>
                    {(props.isGanttListError || props.isGanttMilestoneUpdateError) && (
                        <VWMessage type="danger" defaultMessage={props.isGanttListError || props.isGanttMilestoneUpdateError} baseClassName="alert" ></VWMessage>
                    )}
                </div> : ""}
                <h3>Gantt Chart</h3>

                {tasks.length > 0 ? (
                    <Gantt
                        tasks={tasks}   // api options
                        viewMode={view}  // column width
                        TooltipContent={CustomTooltipContent}
                        onDateChange={handleTaskChange}
                        // onDelete={handleTaskDelete}
                        // onProgressChange={handleProgressChange}
                        // onDoubleClick={handleDblClick}
                        // onClick={handleClick}
                        // onSelect={handleSelect}
                        onExpanderClick={handleExpanderClick} // for minimize the project
                        listCellWidth={isChecked ? "155px" : ""}
                        columnWidth={columnWidth}
                        ganttHeight={440}
                    />
                ) :
                    <div className='d-flex justify-content-center'>
                        <tr>
                            <td colSpan={50} >
                                <NoRecord />
                            </td>
                        </tr>
                    </div>}
            </div> */}
            <TestGanttChart />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    GanttProjectList: state.GanttChartList.GanttProjectList,
    isGanttListError: state.GanttChartList.isGanttListError,
    isGanttListPending: state.GanttChartList.isGanttListPending,
    SelectedGanttProject: state.GanttChartList.SelectedGanttProject,
    isGanttMilestoneUpdateError: state.GanttChartList.isGanttMilestoneUpdateError,
    GanttProjects: state.task.GanttProjectsList,
});

const mapDispatchToProps = (dispatch) => ({
    getProjectNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getProjectNameAsyncSelect(searchValue, callback)),
    getProjectGanttListAction: (projId) => dispatch(getProjectGanttListAction(projId)),
    GanttSelectedProject: (project) => dispatch(GanttSelectedProject(project)),
    clearGanttMilestoneUpdateError: () => dispatch(clearGanttMilestoneUpdateError()),
    clearGanttListError: () => dispatch(clearGanttListError()),
    updateGanttMilestoneDate: (updateMilestoneDate, projectID, milestoneName) => dispatch(updateGanttMilestoneDate(updateMilestoneDate, projectID, milestoneName)),
})
export default connect(mapStateToProps, mapDispatchToProps)(GanttChart)



