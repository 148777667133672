import { getToken, modifyCrmUserList } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    INDUSTRY_CREATE_CLOSE,
    INDUSTRY_CREATE_ERROR,
    INDUSTRY_CREATE_ERROR_CLEAR,
    INDUSTRY_CREATE_OPEN,
    INDUSTRY_CREATE_PENDING,
    INDUSTRY_CREATE_SUCCESS,
    INDUSTRY_LIST_ERROR,
    INDUSTRY_LIST_ERROR_CLEAR,
    INDUSTRY_LIST_PENDING,
    INDUSTRY_UPDATE_CLOSE,
    INDUSTRY_UPDATE_ERROR,
    INDUSTRY_UPDATE_ERROR_CLEAR,
    INDUSTRY_UPDATE_OPEN,
    INDUSTRY_UPDATE_PENDING,
    INDUSTRY_UPDATE_SUCCESS,
    SET_INDUSTRY_DETAILS,
    SET_INDUSTRY_LIST,
} from "./constants";

//--------------------INDUSTRY-CREATE-----------------------------------
export const openAddIndustryModal = () => ({
    type: INDUSTRY_CREATE_OPEN
})
export const closeAddIndustryModal = () => ({
    type: INDUSTRY_CREATE_CLOSE
})
export const createIndustryPending = () => ({
    type: INDUSTRY_CREATE_PENDING
})
export const createIndustryError = (errorMessage) => ({
    type: INDUSTRY_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateIndustryError = () => ({
    type: INDUSTRY_CREATE_ERROR_CLEAR,
})
export const createIndustrySuccess = () => ({
    type: INDUSTRY_CREATE_SUCCESS,
})

export const getCrmUserAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCrmUserAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmUserList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const createNewIndustry = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createIndustryError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createIndustryPending());
    dispatch(clearCreateIndustryError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createIndustry(accessToken, dataToSend)
            .then(() => {
                dispatch(getIndustryList());
                dispatch(createIndustrySuccess());
                dispatch(closeAddIndustryModal());
                dispatch(showSnackbarStatus('Industry type created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createIndustryError(err.response && err.response.text));
                    return;
                }
                console.log('Create Industry server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Industry-UPDATE-----------------------------------
export const openEditIndustryModal = () => ({
    type: INDUSTRY_UPDATE_OPEN
})
export const closeEditIndustryModal = () => ({
    type: INDUSTRY_UPDATE_CLOSE
})
export const updateIndustryPending = () => ({
    type: INDUSTRY_UPDATE_PENDING
})
export const updateIndustryError = (errorMessage) => ({
    type: INDUSTRY_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateIndustryError = () => ({
    type: INDUSTRY_UPDATE_ERROR_CLEAR,
})
export const updateIndustrySuccess = () => ({
    type: INDUSTRY_UPDATE_SUCCESS,
})
export const setIndustryDetails = (industryDetails) => ({
    type: SET_INDUSTRY_DETAILS,
    payload: { industryDetails }
})

export const updateIndustry = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateIndustryError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateIndustryPending());
    dispatch(clearUpdateIndustryError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateIndustry(accessToken, dataToSend)
            .then(() => {
                dispatch(getIndustryList());
                dispatch(updateIndustrySuccess());
                dispatch(closeEditIndustryModal());
                dispatch(showSnackbarStatus('Industry type updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateIndustryError(err.response && err.response.text));
                    return;
                }
                console.log('Create Industry server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Industry-LIST-----------------------------------
export const industryListPending = () => ({
    type: INDUSTRY_LIST_PENDING
})
export const industryListError = (errorMessage) => ({
    type: INDUSTRY_LIST_ERROR,
    payload: { errorMessage }
})
export const clearIndustryListError = () => ({
    type: INDUSTRY_LIST_ERROR_CLEAR,
})
export const setIndustryList = (industryList) => ({
    type: SET_INDUSTRY_LIST,
    payload: { industryList }
})

export const getIndustryList = (page, PageSize) => (dispatch) => {
    dispatch(industryListPending());
    dispatch(clearIndustryListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getIndustryList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setIndustryList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }  
                dispatch(industryListError(error));
                console.log('Create Industry server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

