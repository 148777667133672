import {
    ACCOUNT_CREATE_CLOSE,
    ACCOUNT_CREATE_ERROR,
    ACCOUNT_CREATE_ERROR_CLEAR,
    ACCOUNT_CREATE_OPEN,
    ACCOUNT_CREATE_PENDING,
    ACCOUNT_CREATE_SUCCESS,
    ACCOUNT_DETAILS_ERROR,
    ACCOUNT_DETAILS_ERROR_CLEAR,
    ACCOUNT_DETAILS_PENDING,
    ACCOUNT_EXPORT_LIST_ERROR,
    ACCOUNT_EXPORT_LIST_ERROR_CLEAR,
    ACCOUNT_EXPORT_LIST_PENDING,
    ACCOUNT_EXPORT_LIST_SUCCESS,
    ACCOUNT_LIST_ERROR,
    ACCOUNT_LIST_ERROR_CLEAR,
    ACCOUNT_LIST_PENDING,
    ACCOUNT_UPDATE_CLOSE,
    ACCOUNT_UPDATE_ERROR,
    ACCOUNT_UPDATE_ERROR_CLEAR,
    ACCOUNT_UPDATE_OPEN,
    ACCOUNT_UPDATE_PENDING,
    ACCOUNT_UPDATE_SUCCESS,
    IS_IMPORT_ACCOUNT_PENDING,
    IS_IMPORT_ACCOUNT_SUCCESS,
    SET_ACCOUNT_DETAILS,
    SET_ACCOUNT_EXPORT_LIST,
    SET_ACCOUNT_LIST,
    SET_CONTACT_DETAILS,
    SET_CONTACT_LIST,
    SET_LEAD_DETAILS
} from "./constants";

const initialState = {
    //--------------------ACCOUNT-CREATE-----------------------------------
    isCreateAccountOpen: false,
    accountCreateErrorMessage: null,
    isAccountCreateSuccess: false,
    isAccountCreatePending: false,
    //--------------------ACCOUNT-UPDATE-----------------------------------
    isUpdateAccountOpen: false,
    accountUpdateErrorMessage: null,
    isAccountUpdateSuccess: false,
    isAccountUpdatePending: false,
    //--------------------ACCOUNT-LSIT-------------------------------------
    accountsList: [],
    accountsListCount: 0,
    isAccountListPending: false,
    accountListErrorMessage: null,
    //--------------------ACCOUNT-DETAILS----------------------------------
    accountDetails: [],
    isAccountDetailPending: false,
    accounDetailErrorMessage: null,
    //-------------------contact-list--------------------------------------
    contactList:[],
    isAccountExportListPending:false,
    accountExportListErrorMessage: null,
    accountsExportList:[],
    isImportAccountPending:false
};

export default (localState = initialState, action) => {
    switch (action.type) {

        //--------------------ACCOUNT-CREATE-----------------------------------
        case ACCOUNT_CREATE_OPEN: {
            return {
                ...localState,
                isCreateAccountOpen: true
            }
        }
        case ACCOUNT_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateAccountOpen: false
            }
        }
        case ACCOUNT_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                accountCreateErrorMessage: null,
            }
        }
        case ACCOUNT_CREATE_PENDING: {
            return {
                ...localState,
                isAccountCreatePending: true,
            }
        }
        case ACCOUNT_CREATE_SUCCESS: {
            return {
                ...localState,
                isAccountCreateSuccess: true,
                isAccountCreatePending: false
            }
        }
        case ACCOUNT_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                accountCreateErrorMessage: errorMessage,
                isAccountCreatePending: false,
            }
        }

        //--------------------ACCOUNT-UPDATE-----------------------------------
        case ACCOUNT_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateAccountOpen: true
            }
        }
        case ACCOUNT_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateAccountOpen: false
            }
        }
        case ACCOUNT_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                accountUpdateErrorMessage: null,
            }
        }
        case ACCOUNT_UPDATE_PENDING: {
            return {
                ...localState,
                isAccountUpdatePending: true,
            }
        }
        case ACCOUNT_UPDATE_SUCCESS: {
            return {
                ...localState,
                isAccountUpdateSuccess: true,
                isAccountUpdatePending: false
            }
        }
        case ACCOUNT_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                accountUpdateErrorMessage: errorMessage,
                isAccountUpdatePending: false
            }
        }
        //--------------------ACCOUNT-LSIT-------------------------------------
        case SET_ACCOUNT_LIST: {
            const { accountsList } = action.payload;
            return {
                ...localState,
                accountsList: accountsList && accountsList['value'],
                accountsListCount: accountsList && accountsList['@odata.count'],
                isAccountListPending: false,
                accountListErrorMessage: null,
            }
        }
        case SET_ACCOUNT_EXPORT_LIST: {
            const { accountsExportList } = action.payload;
            return {
                ...localState,
                accountsExportList: accountsExportList,
                // accountsListCount: accountsList && accountsList['@odata.count'],
                isAccountExportListPending: false,
                accountExportListErrorMessage: null,
            }
        }
        case ACCOUNT_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                accountExportListErrorMessage: errorMessage,
                isAccountExportListPending: false,
                accountsExportList: []
            }
        }
        case ACCOUNT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                accountListErrorMessage: errorMessage,
                isAccountListPending: false,
                accountsList: []
            }
        }
        case ACCOUNT_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                accountListErrorMessage: null,
            }
        }
        case ACCOUNT_EXPORT_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                accountExportListErrorMessage: null,
                isAccountExportListPending: false,
            }
        }
        case ACCOUNT_LIST_PENDING: {
            return {
                ...localState,
                isAccountListPending: true,
            }
        }
        case IS_IMPORT_ACCOUNT_PENDING: {
            return {
                ...localState,
                isImportAccountPending: true,
            }
        }
        case IS_IMPORT_ACCOUNT_SUCCESS: {
            return {
                ...localState,
                isImportAccountPending: false,
            }
        }
        case ACCOUNT_EXPORT_LIST_PENDING: {
            return {
                ...localState,
                isAccountExportListPending: true,
            }
        }
        case ACCOUNT_EXPORT_LIST_SUCCESS: {
            return {
                ...localState,
                isAccountExportListPending: false,
            }
        }
        //--------------------ACCOUNT-DETAILS----------------------------------
        case SET_ACCOUNT_DETAILS: {
            const { accountDetails } = action.payload;
            return {
                ...localState,
                accountDetails: accountDetails,
                accounDetailErrorMessage: null,
                isAccountDetailPending: false
            }
        }
        case ACCOUNT_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                accounDetailErrorMessage: errorMessage,
                isAccountDetailPending: false,
                accountDetails: []
            }
        }
        case ACCOUNT_DETAILS_ERROR_CLEAR: {
            return {
                ...localState,
                accounDetailErrorMessage: null,
            }
        }
        case ACCOUNT_DETAILS_PENDING: {
            return {
                ...localState,
                isAccountDetailPending: true,
            }
        }
        //-----------------------Contact-list--------------------------------
        case SET_CONTACT_LIST: {
            const { contactList } = action.payload;
            return {
                ...localState,
                contactList: contactList && contactList['value'],
            }
        }
        default: {
            return localState;
        }
    }
};