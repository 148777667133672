import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, Table, Tooltip } from 'reactstrap'
import VWModel from '../../../../components/Modal/VWModal';
import { clearMilestoneLists, clearStatusUpdateMilestoneError, closeCreateMilestoneModalAction, getMilestoneExportList, getMilestoneList, isStatusMilestoneEditOpen, openCreateMilestoneModalAction, UpdateStatusMilestone } from '../../../../store/Modules/ProjectPortal/Milestones/actions';
import CreateMilestones from './CreateMilestones'
import VWMessage from '../../../../components/vwMessage/vwMessage';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import VWSkeleton from '../../../../components/vwSkeleton/vwSkeleton';
import NoRecord from '../../../../components/NoRecords/noRecords';
import { getPager } from '../../../../helper/common';
import VWPagination from '../../../../components/vwPagination/vwPagination';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import '../../../Modules/viewList.css'
import Async from 'react-select/async';
import { excelPrint, excelSheetExport } from '../../../../helper/export';
import { MILESTONE_EXPORT_HEADERS, STATUS_OPTIONS } from '../../../../store/Modules/ProjectPortal/Milestones/constants';
import Select from 'react-select';
import { ElementToolTip } from '../vwToolTip';
import { getProjectDropDownUserListAsyncSelectAction, getUserNameAsyncSelect } from '../../../../store/Modules/ProjectPortal/Projects/actions';

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: '250px',
    fontWeight: 'normal'
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    // padding: '0',
  }),
  control: (provided) => ({
    ...provided,
    fontWeight: 'normal',
    minWidth: '250px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'normal'
  }),
};

const Milestones = (props) => {
  const [PageSize] = useState(20);
  const [Pager, setPager] = useState({ currentPage: 1 });
  const [filter, setFilter] = useState();
  const { isMilestoneExportPending } = props;

  const buildProjectName = () => {
    const { ProjectName } = filter || {};
    let searchProjectName = '';
    if (ProjectName) {
      searchProjectName += `${ProjectName}`;
    }
    return (searchProjectName) || ''
  }
  const buildMilestoneName = () => {
    const { MilestoneName } = filter || {};
    let searchMilestoneName = '';
    if (MilestoneName) {
      searchMilestoneName += `${MilestoneName}`;
    }
    return (searchMilestoneName) || ''
  }
  const buildStatus = () => {
    const { Status } = filter || {};
    let searchStatus = '';
    if (Status) {
      searchStatus += `${Status}`;
    }
    return (searchStatus) || ''
  }
  const buildDescription = () => {
    const { Description } = filter || {};
    let searchDescription = '';
    if (Description) {
      searchDescription += `${Description}`;
    }
    return (searchDescription) || ''
  }
  const buildComments = () => {
    const { Comments } = filter || {};
    let searchComments = '';
    if (Comments) {
      searchComments += `${Comments}`;
    }
    return (searchComments) || ''
  }
  const buildMileStoneOwnerUserName = () => {
    const { MileStoneOwnerUserName } = filter || {};
    let searchMileStoneOwnerUserName = '';
    if (MileStoneOwnerUserName) {
      searchMileStoneOwnerUserName += `${MileStoneOwnerUserName}`;
    }
    return (searchMileStoneOwnerUserName) || ''
  }
  const buildStartTimeStamp = () => {
    const { StartDate } = filter || {};
    let searchStartTimeStamp = '';
    if (StartDate) {
      searchStartTimeStamp += `${StartDate}`;
    }
    return searchStartTimeStamp || ''
  }
  const buildEndTimeStamp = () => {
    const { EndDate } = filter || {};
    let searchEndTimeStamp = '';
    if (EndDate) {
      searchEndTimeStamp += `${EndDate}`;
    }
    return searchEndTimeStamp || ''
  }
  const buildIsActive = () => {
    const { IsActive } = filter || {};
    let searchIsActive = '';
    if (IsActive) {
      searchIsActive += `${IsActive}`;
    }
    return searchIsActive || ''
  }

  const setPage = (page = 1) => {
    props.getMilestoneList(filter, page, PageSize, buildProjectName(), buildMilestoneName(), buildStatus(), buildDescription(), buildComments(), buildMileStoneOwnerUserName(), buildStartTimeStamp(), buildEndTimeStamp(), buildIsActive());
    setPager(prevPager => {
      const totalPages = Math.ceil(props.totalCount / PageSize);
      const visiblePages = 4;
      const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
      const newPages = [];
      for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
        newPages.push(i);
      }
      return {
        ...prevPager,
        currentPage: page,
        totalPages,
        pages: newPages,
      }
    })
  }

  useEffect(() => {
    const currentPager = getPager(props.totalCount, 1, PageSize)
    setPager(currentPager)
  }, [props.totalCount])

  useEffect(() => {
    props.clearStatusUpdateMilestoneError();
    const currentPageNumber = Pager && Pager.currentPage;
    setPage(currentPageNumber);

    return (() => [
      props.clearMilestoneLists()
    ])
  }, [])
  //------------------------------------------Filter-----------------------------------------------------------
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const searchInputValidation = (event) => {
    const { value, name } = event.target;
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setFilter({
      ...filter,
      [name]: value,
    })
  }
  const handleMilestoneStatus = (Value) => {
    const { value } = Value || {};
    setFilter({
      ...filter,
      Status: value
    })
  }
  const handleSearchClick = () => {
    setPage();
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }
  const debounceTime = window.__ENV && window.__ENV.debounceTime;
  useEffect(() => {
    if (filter) {
      const timeout = setTimeout(() => {
        handleSearchClick();
      }, debounceTime);
      setDebounceTimeout(timeout);
    }
  }, [filter])

  // ----------------------------------------SORTING------------------------------------------------------------------
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = (column) => {
    if (sortOrder === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder(column);
      setSortDirection('asc');
    }
  };

  const sortedMilestonesList = [...props.MilestoneList].sort((a, b) => {
    const aValue = a[sortOrder] || 'NA';
    const bValue = b[sortOrder] || 'NA';

    if (sortOrder === 'IsActive') {
      return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
    }
    return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });

  //---------------------------------------- Modal Open & Close----------------------------------------------------
  const handleOpenCreateMilestones = () => {
    props.openCreateMilestoneModal();
  }
  const handleClose = () => {
    props.closeCreateMilestoneModal();
  };
  //------------------------------------------Assigned Roles----------------------------------------------------------
  const { isProjectManager } = props.currentProfile;
  const Roles = props.currentProfile && props.currentProfile.roles && props.currentProfile.roles.map((data) => (
    data.roleName
  ))
  const style = {
    table: {
      width: '100%',
      display: 'table',
      borderSpacing: 0,
      borderCollapse: 'separate',
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'sticky',
      backgroundColor: '#fff',
    },
  };
  const { userAppDetails } = props.currentProfile || [];

  const navigate = useNavigate();
  const OnDoubleClickAction = (Id) => {
    navigate(`/projectportal/milestones/details/${Id}`);
  };
  //---------------------------------dropdown close action for outside click------------------------------------------
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        props.isStatusMilestoneEditOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //----------

  //-------------------------------------------------Export---------------------------------------------------------------------------
  const handleExports = () => {
    props.getMilestoneExportList(buildProjectName(), buildMilestoneName(), buildStatus(), buildDescription(), buildComments(), buildMileStoneOwnerUserName(), buildStartTimeStamp(), buildEndTimeStamp(), buildIsActive())
      .then((response) => {
        if (response) {
          const exportMilestoneListExportList = [];
          const headerExcel = [
            { headerName: "MILESTONE NAME", key: "milestoneName", headerWidhth: 30 },
            { headerName: "PROJECT NAME", key: "projectName", headerWidhth: 50 },
            { headerName: "DESCRIPTION", key: "description", headerWidhth: 30 },
            { headerName: "MILESTONE OWNER", key: "mileStoneOwnerUserName", headerWidhth: 30 },
            { headerName: "START DATE", key: "StartDate", headerWidhth: 30 },
            { headerName: "END DATE", key: "EndDate", headerWidhth: 30 },
          ]
          response && response.forEach((element) => {
            exportMilestoneListExportList.push({
              milestoneName: element.milestoneName || "N/A",
              projectName: element.projectName || "N/A",
              description: element.description || "N/A",
              mileStoneOwnerUserName: element.mileStoneOwnerUserName || "N/A",
              StartDate: element.startDate ? moment(element.startDate).format('DD MMM YYYY') : 'N/A',
              EndDate: element.endDate ? moment(element.endDate).format('DD MMM YYYY') : 'N/A',
            });
          });
          excelSheetExport(headerExcel, exportMilestoneListExportList, "Milestone-List");
        }
      })
  }

  const App = userAppDetails.filter((i) => {
    return (i.appName === "ProjectPortal") ? i.appId : ""
  })
  const handleUser = (inputValue, callback) => {
    const { getUserNameAsyncSelectAction } = props;
    return getUserNameAsyncSelectAction(App[0] && App[0].appId || '', inputValue.length > 0 ? inputValue : '', callback);
  };
  const handleAddProjectUser = (Value) => {
    console.log("value:", Value);
    const { label, assignedUserEmail, assignedUserName } = Value || {};
    setFilter({
      ...filter,
      MileStoneOwnerUserName: assignedUserEmail || assignedUserName,
    })
  }
  //---------------------------------------------------Status Update for Milestone ------------------------------------------------
  const APIList = 'List';
  const [statusValues, setStatusValues] = useState({});
  const handleEditClick = (itemId) => {
    props.isStatusMilestoneEditOpen(itemId);
  };
  const handleSelectChange = (selectedOption, Id, projectId) => {
    const updatedStatus = selectedOption ? selectedOption.value : '';
    setStatusValues((prevValues) => ({
      ...prevValues,
      [Id]: updatedStatus
    }));
    props.UpdateMilestonesAction({ Id, Status: updatedStatus, ProjectId: projectId }, '', '', filter, APIList);
  };

  const { isGlobalReadOnlyUser } = props.currentProfile || {};

  return (
    <React.Fragment>
      <div className="page-content">
        <Card >
          <CardTitle
            tag="h4"
            className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
          >
            <strong className='d-flex align-items-center' style={{ cursor: 'pointer' }}>MILESTONES -&nbsp;<Badge id='milestoneCount' color="danger">{props && props.totalCount || 0}</Badge>
              <ElementToolTip id={'milestoneCount'} name={
                'Milestones Count'
              } /></strong>
            {userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "projectportal").length > 0 &&
              userAppDetails
                .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "projectportal")
                .map(userApp => {
                  const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                    const roleName = role.roleName.toLowerCase();
                    return roleName;
                  });
                  const isAdmin = adminRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
                  return (
                    <div>
                      <React.Fragment key={userApp.id}>
                        {((isAdmin || isProjectManager) && (!isGlobalReadOnlyUser)) ? (
                          <Link
                            type="button"
                            className="btn btn-info"
                            onClick={handleOpenCreateMilestones}
                          >
                            CREATE
                          </Link>
                        ) : null}&nbsp;
                        <Button
                          className="me-4"
                          color="success"
                          onClick={handleExports}
                          disabled={props.MilestoneList && props.MilestoneList.length <= 0 || isMilestoneExportPending}
                        >
                          {isMilestoneExportPending ? 'EXPORTING...' : 'EXPORT'}
                        </Button>
                      </React.Fragment>
                    </div>
                  );
                })
            }
          </CardTitle>
        </Card>
        <Card>
          <CardBody className="p-4">
            {(props.errorMessage || props.updateStatusMilestoneErrorMessage) ? <div className='w-100'>
              {(props.errorMessage || props.updateStatusMilestoneErrorMessage) && (
                <VWMessage type="danger" defaultMessage={props.errorMessage || props.updateStatusMilestoneErrorMessage} baseClassName="alert" ></VWMessage>
              )}
            </div> : ""}
            <div style={{ height: "60vh", overflowX: "auto" }}>
              <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                  <tr>
                    <th
                      onClick={() => handleSort('MilestoneName')} style={{ minWidth: '200px' }} className='hand-cursor'
                    >Milestone Name &nbsp;
                      {sortOrder === 'MilestoneName' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                    </th>
                    <th
                      onClick={() => handleSort('ProjectName')} style={{ minWidth: '200px' }} className='hand-cursor'
                    >
                      Project Name &nbsp;
                      {sortOrder === 'ProjectName' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th style={{ minWidth: '250px' }} className='hand-cursor'
                      onClick={() => handleSort('Status')}
                    >
                      Milestone Status &nbsp;
                      {sortOrder === 'Status' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('Description')} style={{ minWidth: '200px' }} className='hand-cursor'
                    >
                      Description &nbsp;
                      {sortOrder === 'Description' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('MileStoneOwnerUserName')} style={{ minWidth: '250px' }} className='hand-cursor'
                    >
                      Milestone Owner &nbsp;
                      {sortOrder === 'MileStoneOwnerUserName' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('StartDate')} style={{ minWidth: '200px' }} className='hand-cursor'
                    >
                      Start Date &nbsp;
                      {sortOrder === 'StartDate' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort('EndDate')} style={{ minWidth: '200px' }} className='hand-cursor'
                    >
                      End Date &nbsp;
                      {sortOrder === 'EndDate' && (
                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                      )}
                    </th>
                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                      View
                    </th>
                  </tr>
                  <tr>
                    <th >
                      <Input
                        type='text'
                        placeholder='Search'
                        name='MilestoneName'
                        onChange={searchInputValidation}
                        value={filter && filter.MilestoneName}
                        onKeyPress={handleKeyPress}
                      /></th>
                    <th >
                      <Input
                        type='text'
                        placeholder='Search'
                        name='ProjectName'
                        onChange={searchInputValidation}
                        value={filter && filter.ProjectName}
                        onKeyPress={handleKeyPress}
                      /></th>
                    <Select
                      name="status"
                      placeholder="Search"
                      isSearchable
                      isClearable
                      defaultOptions
                      options={STATUS_OPTIONS}
                      onChange={handleMilestoneStatus}
                    // value={filter && filter.Status ? { label: filter && filter.Status, value: filter && filter.Status } : { label: 'Search', value: null }}
                    />
                    <th >
                      <Input
                        type='text'
                        placeholder='Search'
                        name='Description'
                        onChange={searchInputValidation}
                        value={filter && filter.Description}
                        onKeyPress={handleKeyPress}
                      /></th>
                    <th >
                      {/* <Input
                        type='text'
                        placeholder='Search'
                        name='MileStoneOwnerUserName'
                        onChange={searchInputValidation}
                        value={filter && filter.MileStoneOwnerUserName}
                        onKeyPress={handleKeyPress}
                      /> */}
                      <Async
                        styles={customStyles}
                        name="MileStoneOwnerUserName"
                        placeholder="Select"
                        isSearchable
                        defaultOptions
                        loadOptions={handleUser}
                        onChange={handleAddProjectUser}
                        isClearable
                      />
                    </th>
                    <th >
                      <Input
                        type='date'
                        placeholder='Search'
                        name='StartDate'
                        onChange={searchInputValidation}
                        value={filter && filter.StartDate}
                        onKeyPress={handleKeyPress}
                        max="9999-12-31"
                      /></th>
                    <th >
                      <Input
                        type='date'
                        placeholder='Search'
                        name='EndDate'
                        onChange={searchInputValidation}
                        value={filter && filter.EndDate}
                        onKeyPress={handleKeyPress}
                        max="9999-12-31"
                      /></th>
                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {!props.isMilestonePending && (props.MilestoneList && props.MilestoneList.length > 0) ?
                    sortedMilestonesList && sortedMilestonesList.map((data) => (
                      <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                        <td className='ps-3 '>
                          {data.MilestoneName || "N/A"}
                          <sup id={`mileStoneTaskCount-${data.Id}`} style={{ fontSize: "13px" }}>
                            <Badge className='ms-2' style={{ fontSize: "11px" }} color='danger'>{data.TaskCount}</Badge>
                          </sup>
                          <ElementToolTip id={`mileStoneTaskCount-${data.Id}`} name={
                            'Tasks Count'
                          } />
                        </td>
                        <td className='ps-3 text-wrap'>{data.ProjectName || "N/A"}</td>
                        <td>
                          {data.IsEditable ? (
                            props.isStatusMilestoneEditID === data.Id ? (
                              <div className='d-flex' ref={dropdownRef}>
                                <Select
                                  styles={customStyles}
                                  name="Status"
                                  options={STATUS_OPTIONS}
                                  value={STATUS_OPTIONS.find(option => option.value === (statusValues[data.Id] || data.Status)) || null}
                                  onChange={(e) => { handleSelectChange(e, data.Id, data.ProjectId) }}
                                  isDisabled={props.isUpdateStatusMilestonePending}
                                />
                              </div>
                            ) : (
                              <div className='d-flex justify-content-between'>
                                <span >
                                  {data.Status || 'N/A'}
                                </span>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                  onClick={() => handleEditClick(data.Id)}
                                />
                              </div>
                            )
                          ) : (
                            <>
                              <span>
                                {data.Status || 'N/A'}
                              </span>
                            </>
                          )}
                        </td>
                        <td className='ps-3'>{data.Description ? (data.Description && data.Description.length > 20) ? <><span id={`description-${data.Id}`}>{data.Description && data.Description.substring(0, 20) + '...'}</span>
                          <ElementToolTip
                            id={`description-${data.Id}`}
                            name={data.Description && data.Description.length > 20 ? data.Description : ''}
                          >
                            {data.Description && data.Description.length > 20
                              ? data.Description && data.Description.substring(0, 20) + '...'
                              : data.Description}
                          </ElementToolTip>
                        </>
                          : data.Description : "N/A"}
                        </td>
                        <td className='ps-3'>{data.MileStoneOwnerUserName || 'N/A'}
                          <br />
                          <small>{data.MileStoneOwnerUserEmail || "N/A"}</small>
                        </td>
                        <td className='ps-3'>{data.StartDate ? moment(data.StartDate).format('DD MMM YYYY') : 'N/A'}</td>
                        <td className='ps-3'>{data.EndDate ? moment(data.EndDate).format('DD MMM YYYY') : 'N/A'}</td>
                        <td className='sticky-column' style={{ backgroundColor: "white" }}> <Link
                          type="button"
                          className="btn btn-sm  btn-light"
                          to={`/projectportal/milestones/details/${data.Id}`}
                        >
                          <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                        </Link></td>
                      </tr>
                    ))
                    : props.isMilestonePending ?
                      <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <VWSpinner />
                          </div>
                        </div>
                      </div>
                      :
                      <div className="sticky-spinner-row">
                        <div className="position-relative">
                          <div className="d-flex justify-content-center align-items-center"
                            style={{
                              position: 'fixed',
                              top: '50%',
                              left: '60%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 1,
                              width: '100vw',
                              height: '100vh',
                              pointerEvents: 'none',
                            }}>
                            <NoRecord />
                          </div>
                        </div>
                      </div>
                  }
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardFooter>
            {props.MilestoneList && props.MilestoneList.length > 0 ?
              <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
            }
          </CardFooter>
        </Card>
        <VWModel
          header="CREATE MILESTONE"
          isOpen={props.isCreateMilestoneModalOpen}
          handleClose={handleClose}
        >
          <CreateMilestones
            handleClose={handleClose}
            filteredData={filter}
          />
        </VWModel>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  isCreateMilestoneModalOpen: state.Milestones.isCreateMilestoneModalOpen,
  MilestoneList: state.Milestones.MilestoneLists,
  isMilestonePending: state.Milestones.isMilestonePending,
  errorMessage: state.Milestones.milestoneListError,
  totalCount: state.Milestones.totalRecords,
  isMilestoneExportPending: state.Milestones.isMilestoneExportPending,
  currentProfile: state.login.currentProfile,
  isStatusMilestoneEditID: state.Milestones.isStatusMilestoneEditID,
  isUpdateStatusMilestonePending: state.Milestones.isUpdateStatusMilestonePending,
  updateStatusMilestoneErrorMessage: state.Milestones.updateStatusMilestoneErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  getUserNameAsyncSelectAction: (appId, searchValue, callback) => dispatch(getProjectDropDownUserListAsyncSelectAction(appId, searchValue, callback)),
  UpdateMilestonesAction: (formFields, sortName, sortOrder, filter, APIList) => dispatch(UpdateStatusMilestone(formFields, sortName, sortOrder, filter, APIList)),
  isStatusMilestoneEditOpen: (id) => dispatch(isStatusMilestoneEditOpen(id)),
  closeCreateMilestoneModal: () => dispatch(closeCreateMilestoneModalAction()),
  openCreateMilestoneModal: () => dispatch(openCreateMilestoneModalAction()),
  clearStatusUpdateMilestoneError: () => dispatch(clearStatusUpdateMilestoneError()),
  clearMilestoneLists: () => dispatch(clearMilestoneLists()),
  getMilestoneList: (filter, page, PageSize, searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive) => dispatch(getMilestoneList(filter, page, PageSize, searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive)),
  getMilestoneExportList: (searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive) => dispatch(getMilestoneExportList(searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive))
});

export default connect(mapStateToProps, mapDispatchToProps)(Milestones)