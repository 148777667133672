const ProjectsAdminSidebarData = [
    {
        label: "Project Management",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/projectportal/dashboard",
    },
    {
        label: "Kanban Board",
        icon: "mdi mdi-table-large",
        isHasArrow: true,
        url: "/projectportal/kanbanboard",
    },
    {
        label: "Projects",
        icon: "bx bx-code-block",
        isHasArrow: true,
        url: "/projectportal/projects",
    },
    {
        label: "Milestones",
        // icon: "mdi mdi-calendar-outline",
        icon: 'bx bx-tachometer',
        isHasArrow: true,
        url: "/projectportal/milestones",
    },
    // {
    //     label: "Todos",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/projectportal/todo",
    // },
    {
        label: "Tasks",
        icon: "mdi mdi-calendar-text-outline",
        isHasArrow: true,
        url: "/projectportal/tasks",
    },
    {
        label: "Gantt Chart",
        icon: "mdi mdi-chart-gantt",
        isHasArrow: true,
        url: "/projectportal/ganttchart",
    },
    {
        label: "General Tasks",
        icon: "mdi mdi-calendar-text-outline",
        isHasArrow: true,
        url: "/projectportal/generaltasks",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/projectportal/documents",
    },
    {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/projectportal/reports",
    },
    {
        label: "Settings",
        icon: "mdi mdi-cog-outline",
        isHasArrow: true,
        url: "/projectportal/settings/tasks/task-scope",
    },
]
const ProjectsGlobalReadOnlyuserSidebarData = [
    {
        label: "Project Management",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/projectportal/dashboard",
    },
    {
        label: "Kanban Board",
        icon: "mdi mdi-table-large",
        isHasArrow: true,
        url: "/projectportal/kanbanboard",
    },
    {
        label: "Projects",
        icon: "bx bx-code-block",
        isHasArrow: true,
        url: "/projectportal/projects",
    },
    {
        label: "Milestones",
        icon: 'bx bx-tachometer',
        isHasArrow: true,
        url: "/projectportal/milestones",
    },
    {
        label: "Tasks",
        icon: "mdi mdi-calendar-text-outline",
        isHasArrow: true,
        url: "/projectportal/tasks",
    },
    {
        label: "Gantt Chart",
        icon: "mdi mdi-chart-gantt",
        isHasArrow: true,
        url: "/projectportal/ganttchart",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/projectportal/documents",
    },
]

const ProjectsUserSidebarData = [
    {
        label: "Project Management",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/projectportal/dashboard",
    },
    {
        label: "Kanban Board",
        icon: "mdi mdi-table-large",
        isHasArrow: true,
        url: "/projectportal/kanbanboard",
    },
    {
        label: "Projects",
        icon: "bx bx-code-block",
        isHasArrow: true,
        url: "/projectportal/projects",
    },
    {
        label: "Milestones",
        // icon: "mdi mdi-calendar-outline",
        icon: 'bx bx-tachometer',
        isHasArrow: true,
        url: "/projectportal/milestones",
    },
    // {
    //     label: "Todos",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/projectportal/todo",
    // },
    {
        label: "Tasks",
        icon: "mdi mdi-calendar-text-outline",
        isHasArrow: true,
        url: "/projectportal/tasks",
    },
    {
        label: "Gantt Chart",
        icon: "mdi mdi-chart-gantt",
        isHasArrow: true,
        url: "/projectportal/ganttchart",
    },
    {
        label: "General Tasks",
        icon: "mdi mdi-calendar-text-outline",
        isHasArrow: true,
        url: "/projectportal/generaltasks",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/projectportal/documents",
    }, {
        label: "Reports",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/projectportal/reports",
    },
]
export { ProjectsUserSidebarData, ProjectsAdminSidebarData,ProjectsGlobalReadOnlyuserSidebarData };