import { getToken, modifyLeavetypeMasterList } from "../../../../helper/common";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_LEAVE_TYPE_DETAILS_ERROR, CLEAR_LEAVE_TYPE_ERROR, CLEAR_LEAVE_TYPE_LIST_ERROR, CLEAR_LEAVE_TYPE_MASTER_LIST_ERROR, CLEAR_ORG_LEAVE_TYPE_LIST_ERROR, CLEAR_UPDATE_LEAVE_TYPE_ERROR, CLOSE_CREATE_LEAVE_TYPE_MODAL, CLOSE_EDIT_LEAVE_TYPE_MODAL, CLOSE_LEAVE_TYPE_DELETE_MODAL, CREATE_LEAVE_TYPE_ERROR, CREATE_LEAVE_TYPE_PENDING, CREATE_LEAVE_TYPE_SUCCESS, DELETE_LEAVETYPE_ERROR, OPEN_CREATE_LEAVE_TYPE_MODAL, OPEN_EDIT_LEAVE_TYPE_MODAL, OPEN_LEAVE_TYPE_DELETE_MODAL, SET_LEAVE_TYPE_DETAILS, SET_LEAVE_TYPE_DETAILS_ERROR, SET_LEAVE_TYPE_DETAILS_PENDING, SET_LEAVE_TYPE_LIST, SET_LEAVE_TYPE_LIST_ERROR, SET_LEAVE_TYPE_MASTER_LIST, SET_LEAVE_TYPE_MASTER_LIST_ERROR, SET_LEAVE_TYPE_MASTER_LIST_PENDING, SET_LEAVE_TYPE_PENDING, SET_ORG_LEAVE_TYPE_LIST, SET_ORG_LEAVE_TYPE_LIST_ERROR, SET_ORG_LEAVE_TYPE_LIST_PENDING, UPDATE_LEAVE_TYPE_ERROR, UPDATE_LEAVE_TYPE_PENDING, UPDATE_LEAVE_TYPE_SUCCESS } from "./constants";

export const setLeaveTypeList = (leaveTypeList) => ({
    type: SET_LEAVE_TYPE_LIST,
    payload: { leaveTypeList },
})
export const setLeaveTypemasterList = (leaveTypeMasterList) => ({
    type: SET_LEAVE_TYPE_MASTER_LIST,
    payload: { leaveTypeMasterList },
})
export const setOrgLeaveTypeList = (OrgleaveTypeList) => ({
    type: SET_ORG_LEAVE_TYPE_LIST,
    payload: { OrgleaveTypeList },
})
export const clearLeaveTypeListError = () => ({
    type: CLEAR_LEAVE_TYPE_LIST_ERROR,
})
export const clearLeaveTypeMasterListError = () => ({
    type: CLEAR_LEAVE_TYPE_MASTER_LIST_ERROR,
})
export const clearOrgLeaveTypeListError = () => ({
    type: CLEAR_ORG_LEAVE_TYPE_LIST_ERROR,
})
export const leaveTypeListError = (errorMessage) => ({
    type: SET_LEAVE_TYPE_LIST_ERROR,
    payload: { errorMessage },
})
export const leaveTypeMasterListError = (errorMessage) => ({
    type: SET_LEAVE_TYPE_MASTER_LIST_ERROR,
    payload: { errorMessage },
})
export const OrgLeaveTypeListError = (errorMessage) => ({
    type: SET_ORG_LEAVE_TYPE_LIST_ERROR,
    payload: { errorMessage },
})
export const openActionleavetypeDeleteModalaction = () => ({
    type: OPEN_LEAVE_TYPE_DELETE_MODAL,
});

export const closeActionLeavetypeDeleteModalAction = () => ({
    type: CLOSE_LEAVE_TYPE_DELETE_MODAL,
});
export const openCreateLeaveTypeModalAction = () => ({
    type: OPEN_CREATE_LEAVE_TYPE_MODAL,
});
export const openEditLeaveTypeModal = () => ({
    type: OPEN_EDIT_LEAVE_TYPE_MODAL,
});
export const closeEditLeaveTypeModal = () => ({
    type: CLOSE_EDIT_LEAVE_TYPE_MODAL,
});
export const closeCreateLeaveTypeModalAction = () => ({
    type: CLOSE_CREATE_LEAVE_TYPE_MODAL,
});

export const deleteLeaveTypeError = (errorMessage) => ({
    type: DELETE_LEAVETYPE_ERROR,
    payload: { errorMessage },
});
export const setLeaveTypeDetails = (LeaveTypeDetails) => ({
    type: SET_LEAVE_TYPE_DETAILS,
    payload: { LeaveTypeDetails },
})
export const clearLeaveTypeDetailsError = () => ({
    type: CLEAR_LEAVE_TYPE_DETAILS_ERROR,
})
export const leaveTypeDeatilsError = (errorMessage) => ({
    type: SET_LEAVE_TYPE_DETAILS_ERROR,
    payload: { errorMessage },
})
export const createLeaveTypeError = (errorMessage) => ({
    type: CREATE_LEAVE_TYPE_ERROR,
    payload: { errorMessage },
});
export const createLeaveTypePending = () => ({
    type: CREATE_LEAVE_TYPE_PENDING,
});
export const clearCreateLeaveTypeError = () => ({
    type: CLEAR_LEAVE_TYPE_ERROR,
});
export const createLeaveTypeSuccess = () => ({
    type: CREATE_LEAVE_TYPE_SUCCESS,
});
export const clearUpdateLeaveTypeError = () => ({
    type: CLEAR_UPDATE_LEAVE_TYPE_ERROR,
});
export const updateLeaveTypePending = () => ({
    type: UPDATE_LEAVE_TYPE_PENDING,
});
export const updateLeaveTypeSuccess = () => ({
    type: UPDATE_LEAVE_TYPE_SUCCESS,
});
export const updateLeaveTypeError = (errorMessage) => ({
    type: UPDATE_LEAVE_TYPE_ERROR,
    payload: { errorMessage },
});
export const setLeaveTypePending = () => ({
    type: SET_LEAVE_TYPE_PENDING
})
export const setLeaveTypeMasterListPending = () => ({
    type: SET_LEAVE_TYPE_MASTER_LIST_PENDING
})
export const setOrgLeaveTypeListPending = () => ({
    type: SET_ORG_LEAVE_TYPE_LIST_PENDING
})
export const setLeaveTypeDetailsPending = () => ({
    type: SET_LEAVE_TYPE_DETAILS_PENDING
})

export const getLeaveTypeListActionModal = (page, PageSize, searchLeaveType, searchDescription, searchCreatedBy, searchCreatedDateTime) => (dispatch) => {
    dispatch(setLeaveTypePending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveType.getLeaveTypeLists(accessToken, page, PageSize, searchLeaveType, searchDescription, searchCreatedBy, searchCreatedDateTime)
            .then((LeaveType) => {
                dispatch(setLeaveTypeList(LeaveType));
                dispatch(clearLeaveTypeListError());
            })
            .catch((err) => {
                console.log('getLeaveTypeListActionModal server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leaveTypeListError(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
}


//------------------------------------ Delete Leave type -------------------------------------------------------------
export const deleteLeaveTypeAction = (id) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.LeaveType.getDeleteLeaveTypeList(accessToken, id).then(
            (LeaveTypeListDeleted) => {
                console.log("LeaveTypeListDeleted:", LeaveTypeListDeleted);
                dispatch(closeActionLeavetypeDeleteModalAction());
                dispatch(getLeaveTypeListActionModal());
                window.location.reload()

            }
        ).catch((err) => {
            console.log("err:", err.response && err.response.text);
            console.log('deleteLeaveTypeAction server error', err);
            dispatch(showSnackbarStatus(err.response && err.response.text));
            dispatch(deleteLeaveTypeError(err.response && err.response.text));
        })
    })
}


//---------------------------------- Create Leave Type ----------------------------------------------------------------
export const createLeaveType = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createLeaveTypeError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = formFields.map((data) => ({
            ...data,
            ConsecutiveDaysQty: data.ConsecutiveDaysQty || 0,
            Qty: data.Qty || 0
        }))
    }
    console.log("dataToSend:", dataToSend);
    dispatch(createLeaveTypePending());
    dispatch(clearCreateLeaveTypeError());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveType.createLeaveType(accessToken, dataToSend)
            .then(() => {
                dispatch(createLeaveTypeSuccess());
                dispatch(clearForm());
                // dispatch(closeCreateLeaveTypeModalAction());
                // dispatch(getLeaveTypeListActionModal());
                dispatch(getOrgLeaveTypeListAction());
                dispatch(getLeaveTypeMasterListAction());
                dispatch(showSnackbarStatus('Leave Type updated successfully'));
            })
            .catch((err) => {
                console.log("err:", err);

                let error;
                if (err && err.response) {
                    error = err.response.text;
                }
                else {
                    error = "Network Error"
                }
                dispatch(createLeaveTypeError(error));
                console.log('Create Leave server error', error);
                dispatch(clearForm());
                // dispatch(closeCreateLeaveTypeModalAction());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};



//-------------------------------------Leave type Options ----------------------------------------------------------------
export const getLeaveTypeAsyncSelect = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.LeaveType.getLeavetypeMasterList(accessToken, searchValue)
                .then((LeaveTypeMasterList) => {
                    const { value } = LeaveTypeMasterList;
                    // console.log("value:", value);
                    const updatedLeaveTypeList = modifyLeavetypeMasterList(value);
                    //eslint-disable-next-line
                    callback && callback(updatedLeaveTypeList);
                })
                .catch((err) => {
                    console.log('getLeaveTypeAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };



export const getLeaveTypeMasterListAction = () => (dispatch) => {
    dispatch(setLeaveTypeMasterListPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveType.getLeavetypeMasterList(accessToken)
            .then((LeaveTypeMasterList) => {
                dispatch(setLeaveTypemasterList(LeaveTypeMasterList));
                dispatch(clearLeaveTypeMasterListError());
            })
            .catch((err) => {
                console.log('getLeaveTypeMasterListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leaveTypeMasterListError(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
}
export const getOrgLeaveTypeListAction = () => (dispatch) => {
    dispatch(setOrgLeaveTypeListPending());
    getToken(dispatch).then((accessToken) => {
        agent.LeaveType.getOrgLeaveTypeList(accessToken)
            .then((OrgLeaveTypeList) => {
                dispatch(setOrgLeaveTypeList(OrgLeaveTypeList));
                dispatch(clearOrgLeaveTypeListError());
            })
            .catch((err) => {
                console.log('getOrgLeaveTypeListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(OrgLeaveTypeListError(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
}