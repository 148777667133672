import {
    CLEAR_CREATE_PROJECT_ERROR,
    CLEAR_PROJECT_DETAILS_ERROR,
    CLEAR_PROJECT_EXPORT_LIST_ERROR,
    CLEAR_PROJECT_LIST,
    CLEAR_PROJECT_LIST_ERROR,
    CLEAR_PROJECT_PIE_CHART_DETAILS_ERROR,
    CLEAR_UPDATE_PROJECT_ERROR,
    CLOSE_CREATE_PROJECT_MODAL,
    CLOSE_EDIT_PROJECT_MODAL,
    CLOSE_SORT_MODAL,
    CREATE_PROJECT_ERROR,
    CREATE_PROJECT_PENDING,
    CREATE_PROJECT_SUCCESS,
    OPEN_CREATE_PROJECT_MODAL,
    OPEN_EDIT_PROJECT_MODAL,
    OPEN_SORT_MODAL,
    PROJECT_EXPORT_LIST_ERROR,
    PROJECT_EXPORT_SUCCESS,
    PROJECT_LIST_ERROR,
    PROJECT_PIE_CHART_DETAILS_ERROR,
    SET_PROJECT_DETAILS,
    SET_PROJECT_DETAILS_ERROR,
    SET_PROJECT_DETAILS_PENDING,
    SET_PROJECT_EXPORT_LIST,
    SET_PROJECT_EXPORT_PENDING,
    SET_PROJECT_LIST,
    SET_PROJECT_PENDING,
    SET_PROJECT_PIE_CHART_DETAILS,
    SET_PROJECT_PIE_CHART_DETAILS_PENDING,
    UPDATE_PROJECT_ERROR,
    UPDATE_PROJECT_PENDING,
    UPDATE_PROJECT_SUCCESS
} from "./constants";

const initialState = {
    //-------------------------------------------------PROJECT-CREATE---------------------------------------------------------------------------------------
    isCreateProjectModalOpen: false,
    errorMessage: null,
    isPending: false,
    //-------------------------------------------------PROJECT-UPDATE---------------------------------------------------------------------------------------
    isEditProjectModalOpen: false,
    isUpdateProjectPending: false,
    updateProjectErrorMessage: null,
    //-------------------------------------------------PROJECT-LIST---------------------------------------------------------------------------------------
    isProjectPending: false,
    ProjectLists: [],
    totalRecords: 0,
    ProjectListError: null,
    //-------------------------------------------------PROJECT-EXPORT---------------------------------------------------------------------------------------
    isProjectExportPending: false,
    ProjectExportListError: null,
    //-------------------------------------------------PROJECT-DETAILS---------------------------------------------------------------------------------------
    ProjectDetails: null,
    projectDetailsError: null,
    isProjectDetailsPending: false,
    ProjectPieChartDetails: null,
    isProjectPieChartDetailsPending: false,
    //-------------------------------------------------MILESTONE-SORTING---------------------------------------------------------------------------------------
    isMileStoneSortModalOpen: false,
}

export default (localState = initialState, action) => {
    switch (action.type) {
        //-------------------------------------------------PROJECT-CREATE---------------------------------------------------------------------------------------
        case OPEN_CREATE_PROJECT_MODAL: {
            return {
                ...localState,
                isCreateProjectModalOpen: true,
            };
        }
        case CLOSE_CREATE_PROJECT_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateProjectModalOpen: false,
            };
        }
        case CREATE_PROJECT_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_PROJECT_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CREATE_PROJECT_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_CREATE_PROJECT_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }

        //-------------------------------------------------PROJECT-UPDATE---------------------------------------------------------------------------------------
        case OPEN_EDIT_PROJECT_MODAL: {
            return {
                ...localState,
                isEditProjectModalOpen: true,
            };
        }
        case CLOSE_EDIT_PROJECT_MODAL: {
            return {
                ...localState,
                updateProjectErrorMessage: null,
                isEditProjectModalOpen: false,
            };
        }
        case CLEAR_UPDATE_PROJECT_ERROR: {
            return {
                ...localState,
                updateProjectErrorMessage: null,
                isUpdateProjectPending: false,
            };
        }
        case UPDATE_PROJECT_PENDING: {
            return {
                ...localState,
                isUpdateProjectPending: true,
            };
        }
        case UPDATE_PROJECT_SUCCESS: {
            return {
                ...localState,
                isUpdateProjectPending: false,
            };
        }
        case UPDATE_PROJECT_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateProjectErrorMessage: errorMessage,
                isUpdateProjectPending: false,
            };
        }

        //-------------------------------------------------PROJECT-LIST---------------------------------------------------------------------------------------
        case SET_PROJECT_PENDING: {
            return {
                ...localState,
                ProjectListError: null,
                isProjectPending: true
            }
        }
        case CLEAR_PROJECT_LIST: {
            return {
                ...localState,
                ProjectListError: null,
                totalRecords:0,
                isProjectPending: false,
                ProjectLists:[]
            }
        }
        case SET_PROJECT_LIST: {
            const { projectList } = action.payload;
            return {
                ...localState,
                isProjectPending: false,
                ProjectLists: projectList && projectList.value,
                totalRecords: projectList["@odata.count"],
            }
        }
        case PROJECT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isProjectPending: false,
                ProjectListError: errorMessage,
            };
        }
        case CLEAR_PROJECT_LIST_ERROR: {
            return {
                ...localState,
                ProjectListError: null,
            };
        }

        //-------------------------------------------------PROJECT-EXPORT---------------------------------------------------------------------------------------
        case SET_PROJECT_EXPORT_PENDING: {
            return {
                ...localState,
                ProjectExportListError: null,
                isProjectExportPending: true
            }
        }
        case PROJECT_EXPORT_SUCCESS: {
            return {
                ...localState,
                isProjectExportPending: false,
            }
        }
        case PROJECT_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isProjectExportPending: false,
                ProjectExportListError: errorMessage,
            };
        }
        case CLEAR_PROJECT_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                ProjectExportListError: null,
            };
        }

        //-------------------------------------------------PROJECT-DETAILS---------------------------------------------------------------------------------------
        case SET_PROJECT_DETAILS_PENDING: {
            return {
                ...localState,
                projectDetailsError: null,
                isProjectDetailsPending: true
            }
        }
        case SET_PROJECT_DETAILS: {
            const { projectDetails } = action.payload;
            return {
                ...localState,
                isProjectDetailsPending: false,
                ProjectDetails: projectDetails,
            }
        }
        case SET_PROJECT_PIE_CHART_DETAILS: {
            const { projectPieChartDetails } = action.payload;
            return {
                ...localState,
                isProjectPieChartDetailsPending: false,
                ProjectPieChartDetails: projectPieChartDetails,
            }
        }
        case SET_PROJECT_PIE_CHART_DETAILS_PENDING: {
            return {
                ...localState,
                projectPieChartDetailsError: null,
                isProjectPieChartDetailsPending: true
            }
        }
        case CLEAR_PROJECT_PIE_CHART_DETAILS_ERROR: {
            return {
                ...localState,
                projectPieChartDetailsError: null,
            };
        }
        case PROJECT_PIE_CHART_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                projectPieChartDetailsError: errorMessage,
                isProjectPieChartDetailsPending: false,
                ProjectPieChartDetails: null,
            };
        }
        case SET_PROJECT_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                ProjectDetails: {},
                isProjectDetailsPending: false,
                projectDetailsError: errorMessage,
            };
        }
        case CLEAR_PROJECT_DETAILS_ERROR: {
            return {
                ...localState,
                projectDetailsError: null,
            };
        }
        //-------------------------------------------------MILESTONE-SORTING---------------------------------------------------------------------------------------
        case OPEN_SORT_MODAL: {
            return {
                ...localState,
                isMileStoneSortModalOpen: true,
            };
        }
        case CLOSE_SORT_MODAL: {
            return {
                ...localState,
                // updateProjectErrorMessage: null,
                isMileStoneSortModalOpen: false,
            };
        }
        default: {
            return localState;
        }
    }
}