import { FORM_CLEAR } from "./actionTypes";

export const updateForm = (data: any): Action => ({
  type: "FORM_UPDATE",
  payload: data,
});

export const clearForm = (): Action => ({
  type: FORM_CLEAR,
});


export const updateUsersData = (data: object) => (dispatch: Function) => {
  dispatch({ type: "UPDATE_USERS_DATA", payload: data });
};