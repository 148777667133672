import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label
} from 'reactstrap';
import {
    clearCreateAccountCategoryError,
    createNewAccountCategory
} from '../../../../../../store/Modules/crm/Settings/AccountCategory/actions';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import Select from 'react-select';

const typeOptions = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
]

const AddAccountCategory = (props) => {
    const { isAccountCategoryCreatePending, errorMessage } = props;
    const [categoryDetails, setCategoryDetails] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        props.clearCreateAccountCategoryError();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setCategoryDetails({
            ...categoryDetails,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: null
        })
    }

    const handleTypeSelect = (Value) => {
        const { label, value } = Value;
        setCategoryDetails({
            ...categoryDetails,
            typeName: value
        })
        setErrors({
            ...errors,
            typeName: null
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { typeName, range } = categoryDetails || {};

        if (!typeName) {
            currentErrors.typeName = 'Type name is required';
        }
        if (!range) {
            currentErrors.range = 'Range is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createAccountCategory(categoryDetails)
        }
    }
    return (
        <>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label>Type Name <span className='text-danger'>*</span></Label>
                <Select
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.typeName) ? 'red' : "",
                        })
                    }}
                    type="text"
                    name="typeName"
                    placeholder="Select type name"
                    onChange={handleTypeSelect}
                    options={typeOptions}
                // value={categoryDetails && categoryDetails.typeName ? { label: categoryDetails.typeName, value: categoryDetails.typeName } : { label: 'Select type name', value: null }}
                />
                <FormText><div className='text-danger'>{errors && errors.typeName}</div></FormText>
            </FormGroup>
            <FormGroup>
                <Label>Range <span className='text-danger'>*</span></Label>
                <Input
                    type="number"
                    name="range"
                    placeHolder="Enter range"
                    onChange={validateOnchange}
                    value={categoryDetails && categoryDetails.range}
                    invalid={errors && errors.range}
                    onKeyDown={
                        (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                    }
                    onWheel={(e) => e.target.blur()}
                />
                <FormFeedback>{errors && errors.range}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isAccountCategoryCreatePending}
                >
                    {isAccountCategoryCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    isAccountCategoryCreatePending: state.crmAccountCategory.isAccountCategoryCreatePending,
    errorMessage: state.crmAccountCategory.accountCategoryCreateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    createAccountCategory: (categoryDetails) => dispatch(createNewAccountCategory(categoryDetails)),
    clearCreateAccountCategoryError: () => dispatch(clearCreateAccountCategoryError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddAccountCategory);