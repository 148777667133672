import React, { useEffect, useState } from 'react'
import VWButton from '../../../../components/vwButton/VWButton'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import { Button, Col, FormFeedback, FormGroup, FormText, Input, Label, Row } from 'reactstrap'
import Async from 'react-select/async';
import { connect } from 'react-redux'
import { clearForm, updateForm } from '../../../../store/form/actions'
import { createOrganization, createOrganizationSuccess, getAppTypeAsyncSelect } from '../../../../store/Modules/Admin/organization/actions'
import FormValidator from '../../../../helper/formValidator';


const OrganizationCreate = (props) => {
    const [formFields, setFormFields] = useState({ OrgName: '', OrgContactPerson: '', OrgContactNumber: '', OrgAddress: '', AppId: [], UserEmail: '', FirstName: '', LastName: '' });
    const [validation, setValidation] = useState({ emailValid: true });
    const [errors, setErrors] = useState({});
    const [userEmailValid, setUserEmailValid] = useState(true);
    const [orgEmailValid, setOrgEmailValid] = useState(true);
    const { filteredData }=props;

    useEffect(()=>{
        props.createOrganizationSuccess();
    },[])
    //------------------------------------Default Options---------------------------------------------------------------
    const handleLoadAppType = (inputValue, callback) => {
        const { getAppTypeAsyncSelectAction } = props;
        return getAppTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    //------------------------------------Add  Input Field -------------------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;

        let result = FormValidator.validate(input);
        let emailValid = true;

        if (input.name === 'UserEmail') {
            let emailValid = true;

            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(value)) {
                result = 'Invalid user email';
                emailValid = false;
            }
            else if (prohibitedChars.test(value)) {
                result = 'Invalid user email';
                emailValid = false;
            }
            else {
                emailValid = true;
            }
            setErrors({
                ...errors,
                UserEmail: emailValid ? null : result,  // Update errors directly
            });
        }

        setFormFields({
            ...formFields,
            [input.name]: value,
            errors: {
                ...(formFields && formFields.errors),
                [input.name]: result,
            },
        });

        // Reset errors for specific fields
        if (input.name === 'OrgName') {
            setErrors({ ...errors, OrgName: '' });
        }
        if (input.name === 'FirstName') {
            setErrors({ ...errors, FirstName: '' });
        }
        if (input.name === 'LastName') {
            setErrors({ ...errors, LastName: '' });
        }
    };

    //------------------------------------------Add App Name-----------------------------------------------------
    const handleAddAppName = (value) => {
        const AppID = value.map((item) => {
            return (
                item.value
            )
        })
        setFormFields({ ...formFields, AppId: AppID });
        setErrors({ ...errors, AppId: null });
    }

    //--------------------------------------------Create ---------------------------------------------------------
    const handleCreateOrganization = () => {
        const newErrors = {};
        if (!(formFields && formFields.OrgName) || !(formFields.OrgName && formFields.OrgName.trim())) {
            newErrors.OrgName = 'Organization name is required';
        }
        if (!(formFields && formFields.FirstName) || !(formFields.FirstName && formFields.FirstName.trim())) {
            newErrors.FirstName = 'First name is required';
        }
        if (!(formFields && formFields.AppId && formFields.AppId.length > 0)) {
            newErrors.AppId = 'Apps is required';
        }
        if (!(formFields && formFields.UserEmail) || !(formFields.UserEmail && formFields.UserEmail.trim())) {
            newErrors.UserEmail = 'User email is required';
        } else {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(formFields.UserEmail)) {
                newErrors.UserEmail = 'Invalid user email';
            } else if (prohibitedChars.test(formFields.UserEmail)) {
                newErrors.UserEmail = 'Invalid user email';
            }
        }
        if (!(formFields && formFields.LastName)) {
            newErrors.LastName = 'Last name is required';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0 && validation.emailValid === true) {
            props.createOrganizationAction(formFields, filteredData);
        }

    };

    console.log("formFields",formFields);
    

    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Organization Name <span className="text-danger"> *</span>
                        </Label>
                        <Input
                            type="text"
                            name="OrgName"
                            placeholder="Enter organization name"
                            onChange={validateOnChange}
                            value={formFields && formFields.OrgName}
                            invalid={errors && errors.OrgName}
                        />
                        {<FormFeedback>{errors && errors.OrgName}</FormFeedback>}
                    </FormGroup>
                </Col>
                <Col md={6}>

                    <FormGroup>
                        <Label>
                            Organization Contact Person
                        </Label>
                        <Input
                            type="text"
                            name="OrgContactPerson"
                            placeholder="Enter organization contact person"
                            onChange={validateOnChange}
                            value={formFields && formFields.OrgContactPerson}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Organization Contact #
                        </Label>
                        <Input
                            type="text"
                            name="OrgContactNumber"
                            placeholder="Enter organization contact #"
                            onChange={validateOnChange}
                            value={formFields && formFields.OrgContactNumber}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                    Organization Address
                        </Label>
                <Input
                    type="textarea"
                    name="OrgAddress"
                            placeholder="Enter organization address"
                    onChange={validateOnChange}
                    value={formFields && formFields.OrgAddress}
                />
                    </FormGroup>
            </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Apps <span className="text-danger"> *</span>
                        </Label>
                        <Async
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: (errors && errors.AppId) ? '#FF3D60' : "#CED4DA"
                                })
                            }}
                            name="AppId"
                            placeholder="Select Apps"
                            isSearchable
                            isMulti
                            defaultOptions
                            loadOptions={handleLoadAppType}
                            onChange={handleAddAppName}
                        />
                        <FormText sx={{ marginLeft: "10px" }}>
                            <div className="text-danger">
                                {errors && errors.AppId}
                            </div>
                        </FormText>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>User Email <span className="text-danger"> *</span></Label>
                        <Input
                            type='text'
                            name='UserEmail'
                            placeholder='Enter user email'
                            invalid={errors && errors.UserEmail}
                            data-validate='["required"]'
                            onChange={validateOnChange}
                            value={formFields && formFields.UserEmail}
                        />
                           <FormFeedback>{errors && errors.UserEmail}</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row><Col md={6}>
                <FormGroup>
                    <Label>First Name <span className="text-danger"> *</span></Label>
                    <Input
                        type='text'
                        name='FirstName'
                        placeholder='Enter first name'
                        onChange={validateOnChange}
                        value={formFields && formFields.FirstName}
                        invalid={errors && errors.FirstName}
                    />
                    {<FormFeedback>{errors && errors.FirstName}</FormFeedback>}
                </FormGroup>
            </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Last Name <span className="text-danger"> *</span> </Label>
                        <Input
                            type='text'
                            name='LastName'
                            placeholder='Enter last name'
                            onChange={validateOnChange}
                            value={formFields && formFields.LastName}
                            invalid={errors && errors.LastName}
                        />
                        {<FormFeedback>{errors && errors.LastName}</FormFeedback>}
                    </FormGroup>
                </Col></Row>
            <br></br>
            <div className="form-group">
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleCreateOrganization}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    form: state.form,
    isPending: state.Organization.isPending,
    errorMessage: state.Organization.errorMessage,
});
const mapDispatchToProps = (dispatch) => ({
    updateFormAction: (data) => dispatch(updateForm(data)),
    clearFormAction: () => dispatch(clearForm()),
    getAppTypeAsyncSelectAction: (searchValue, callback) =>
        dispatch(getAppTypeAsyncSelect(searchValue, callback)),
    createOrganizationAction: (formFields, filteredData) => dispatch(createOrganization(formFields, filteredData)),
    createOrganizationSuccess: () => dispatch(createOrganizationSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCreate)