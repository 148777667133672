import { getToken, modifyCrmUserList } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    ACCOUNT_CATEGORY_CREATE_CLOSE,
    ACCOUNT_CATEGORY_CREATE_ERROR,
    ACCOUNT_CATEGORY_CREATE_ERROR_CLEAR,
    ACCOUNT_CATEGORY_CREATE_OPEN,
    ACCOUNT_CATEGORY_CREATE_PENDING,
    ACCOUNT_CATEGORY_CREATE_SUCCESS,
    ACCOUNT_CATEGORY_LIST_ERROR,
    ACCOUNT_CATEGORY_LIST_ERROR_CLEAR,
    ACCOUNT_CATEGORY_LIST_PENDING,
    ACCOUNT_CATEGORY_UPDATE_CLOSE,
    ACCOUNT_CATEGORY_UPDATE_ERROR,
    ACCOUNT_CATEGORY_UPDATE_ERROR_CLEAR,
    ACCOUNT_CATEGORY_UPDATE_OPEN,
    ACCOUNT_CATEGORY_UPDATE_PENDING,
    ACCOUNT_CATEGORY_UPDATE_SUCCESS,
    SET_ACCOUNT_CATEGORY_DETAILS,
    SET_ACCOUNT_CATEGORY_LIST,
} from "./constants";

//--------------------ACCOUNT-CATEGORY-CREATE-----------------------------------
export const openAddAccountCategoryModal = () => ({
    type: ACCOUNT_CATEGORY_CREATE_OPEN
})
export const closeAddAccountCategoryModal = () => ({
    type: ACCOUNT_CATEGORY_CREATE_CLOSE
})
export const createAccountCategoryPending = () => ({
    type: ACCOUNT_CATEGORY_CREATE_PENDING
})
export const createAccountCategoryError = (errorMessage) => ({
    type: ACCOUNT_CATEGORY_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateAccountCategoryError = () => ({
    type: ACCOUNT_CATEGORY_CREATE_ERROR_CLEAR,
})
export const createAccountCategorySuccess = () => ({
    type: ACCOUNT_CATEGORY_CREATE_SUCCESS,
})

export const getCrmUserAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCrmUserAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmUserList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const createNewAccountCategory = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createAccountCategoryError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createAccountCategoryPending());
    dispatch(clearCreateAccountCategoryError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createAccountCategory(accessToken, dataToSend)
            .then(() => {
                dispatch(getAccountCategoryList());
                dispatch(createAccountCategorySuccess());
                dispatch(closeAddAccountCategoryModal());
                dispatch(showSnackbarStatus('Account category created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createAccountCategoryError(err.response && err.response.text));
                    return;
                }
                console.log('Create account category server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------CONTACTS-UPDATE-----------------------------------
export const openEditAccountCategoryModal = () => ({
    type: ACCOUNT_CATEGORY_UPDATE_OPEN
})
export const closeEditAccountCategoryModal = () => ({
    type: ACCOUNT_CATEGORY_UPDATE_CLOSE
})
export const updateAccountCategoryPending = () => ({
    type: ACCOUNT_CATEGORY_UPDATE_PENDING
})
export const updateAccountCategoryError = (errorMessage) => ({
    type: ACCOUNT_CATEGORY_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateAccountCategoryError = () => ({
    type: ACCOUNT_CATEGORY_UPDATE_ERROR_CLEAR,
})
export const updateAccountCategorySuccess = () => ({
    type: ACCOUNT_CATEGORY_UPDATE_SUCCESS,
})
export const setAccountCategoryDetails = (categoryDetails) => ({
    type: SET_ACCOUNT_CATEGORY_DETAILS,
    payload: { categoryDetails }
})

export const updateAccountCategory = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateAccountCategoryError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateAccountCategoryPending());
    dispatch(clearUpdateAccountCategoryError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateAccountCategory(accessToken, dataToSend)
            .then(() => {
                dispatch(updateAccountCategorySuccess());
                dispatch(closeEditAccountCategoryModal());
                dispatch(getAccountCategoryList());
                dispatch(showSnackbarStatus('Account category updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateAccountCategoryError(err.response && err.response.text));
                    return;
                }
                console.log('Create Account server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------ACCOUNT-CATEGORY-LIST-----------------------------------
export const AccountCategoryListPending = () => ({
    type: ACCOUNT_CATEGORY_LIST_PENDING
})
export const AccountCategoryListError = (errorMessage) => ({
    type: ACCOUNT_CATEGORY_LIST_ERROR,
    payload: { errorMessage }
})
export const clearAccountCategoryListError = () => ({
    type: ACCOUNT_CATEGORY_LIST_ERROR_CLEAR,
})
export const setAccountCategoryList = (accountCategoryList) => ({
    type: SET_ACCOUNT_CATEGORY_LIST,
    payload: { accountCategoryList }
})

export const getAccountCategoryList = (page, PageSize) => (dispatch) => {
    dispatch(AccountCategoryListPending());
    dispatch(clearAccountCategoryListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getAccountCategoryList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setAccountCategoryList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }  
                dispatch(AccountCategoryListError(error));
                console.log('Create Account server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
