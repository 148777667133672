
import {
  CREATE_ORGANISATION_ERROR,
  CREATE_ORGANISATION_PENDING,
  CREATE_ORGANISATION_SUCCESS,
  CLEAR_ORGANISATION_ERROR,
  SET_INITIAL_STATE,
  OPEN_CREATE_ORGANISATION_MODAL,
  CLOSE_CREATE_ORGANISATION_MODAL,
  SET_ORGANISATIONS_PENDING,
  SET_ORGANISATIONS_LIST,
  SET_ORGANISATIONS_LIST_ASYNC,
  ADD_ORG,
} from "../organisation/actionTypes";

const initialState = {
  errorMessage: null,
  isPending: false,
  isCreateOrganisationModalOpen: false,
  isGetOrganisationPending: false,
  organisations: [],
  organisationsListAsync: [],
  searchValueAsync: "",
  AddOrganisation: {},
};

export default (
  localState = initialState,
  action: Action
) => {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case CREATE_ORGANISATION_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case CREATE_ORGANISATION_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_ORGANISATION_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_ORGANISATION_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case OPEN_CREATE_ORGANISATION_MODAL: {
      return {
        ...localState,
        isCreateOrganisationModalOpen: true,
      };
    }
    case CLOSE_CREATE_ORGANISATION_MODAL: {
      return {
        ...localState,
        isCreateOrganisationModalOpen: false,
        errorMessage: null,
      };
    }
    case SET_ORGANISATIONS_PENDING: {
      return {
        ...localState,
        isGetOrganisationPending: true,
      };
    }
    case SET_ORGANISATIONS_LIST: {
      const { organisationsOdata } = action.payload;
      return {
        ...localState,
        isGetOrganisationPending: false,
        organisations: organisationsOdata && organisationsOdata.value,
      };
    }
    case ADD_ORG: {
      const { AddOrganisation } = action.payload;
      return {
        ...localState,
        AddOrganisation: AddOrganisation
      }
    }
    case SET_ORGANISATIONS_LIST_ASYNC: {
      const { organisationsListAsync, searchValueAsync } = action.payload;
      return {
        ...localState,
        organisationsListAsync,
        searchValueAsync,
      };
    }
    default: {
      return localState;
    }
  }
};
