import { getToken } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../../../crm/Settings/SettingsInlineUpdate/actions";
import {
    TASK_SCOPE_CREATE_CLOSE,
    TASK_SCOPE_CREATE_ERROR,
    TASK_SCOPE_CREATE_ERROR_CLEAR,
    TASK_SCOPE_CREATE_OPEN,
    TASK_SCOPE_CREATE_PENDING,
    TASK_SCOPE_CREATE_SUCCESS,
    TASK_SCOPE_LIST_ERROR,
    TASK_SCOPE_LIST_ERROR_CLEAR,
    TASK_SCOPE_LIST_PENDING,
    TASK_SCOPE_UPDATE_CLOSE,
    TASK_SCOPE_UPDATE_ERROR,
    TASK_SCOPE_UPDATE_ERROR_CLEAR,
    TASK_SCOPE_UPDATE_OPEN,
    TASK_SCOPE_UPDATE_PENDING,
    TASK_SCOPE_UPDATE_SUCCESS,
    SET_TASK_SCOPE_DETAILS,
    SET_TASK_SCOPE_LIST,
} from "./constants";

//--------------------TASK_SCOPE-CREATE-----------------------------------
export const openAddTaskScopeModal = () => ({
    type: TASK_SCOPE_CREATE_OPEN
})
export const closeAddTaskScopeModal = () => ({
    type: TASK_SCOPE_CREATE_CLOSE
})
export const createTaskScopePending = () => ({
    type: TASK_SCOPE_CREATE_PENDING
})
export const createTaskScopeError = (errorMessage) => ({
    type: TASK_SCOPE_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateTaskScopeError = () => ({
    type: TASK_SCOPE_CREATE_ERROR_CLEAR,
})
export const createTaskScopeSuccess = () => ({
    type: TASK_SCOPE_CREATE_SUCCESS,
})


export const createNewTaskScope = (formFields, appId) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createTaskScopeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createTaskScopePending());
    dispatch(clearCreateTaskScopeError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.createTaskScope(accessToken, dataToSend)
            .then(() => {
                dispatch(getTaskScopeList(appId));
                dispatch(createTaskScopeSuccess());
                dispatch(closeAddTaskScopeModal());
                dispatch(showSnackbarStatus('Task scope created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createTaskScopeError(err.response && err.response.text));
                    return;
                }
                console.log('Create Task scope server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

// //--------------------SCOPE-UPDATE-----------------------------------
export const openEditTaskScopeModal = () => ({
    type: TASK_SCOPE_UPDATE_OPEN
})
export const closeEditTaskScopeModal = () => ({
    type: TASK_SCOPE_UPDATE_CLOSE
})
export const updateTaskScopePending = () => ({
    type: TASK_SCOPE_UPDATE_PENDING
})
export const updateTaskScopeError = (errorMessage) => ({
    type: TASK_SCOPE_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateTaskScopeError = () => ({
    type: TASK_SCOPE_UPDATE_ERROR_CLEAR,
})
export const updateTaskScopeSuccess = () => ({
    type: TASK_SCOPE_UPDATE_SUCCESS,
})
export const setTaskScopeDetails = (scopeDetails) => ({
    type: SET_TASK_SCOPE_DETAILS,
    payload: { scopeDetails }
})

export const updateTaskScope = (formFields, appId) => (dispatch) => {
    console.log('appId:', appId);
    
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateTaskScopeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateTaskScopePending());
    dispatch(clearUpdateTaskScopeError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.updateTaskScope(accessToken, dataToSend)
            .then(() => {
                dispatch(getTaskScopeList(appId));
                dispatch(updateTaskScopeSuccess());
                dispatch(closeEditTaskScopeModal());
                dispatch(showSnackbarStatus('Task scope updated successfully'));
                dispatch(setIsInlineUpdate());
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateTaskScopeError(err.response && err.response.text));
                    return;
                }
                console.log('Create task scope server error', err)
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------TASK SCOPE-LIST-----------------------------------
export const taskScopeListPending = () => ({
    type: TASK_SCOPE_LIST_PENDING
})
export const taskScopeListError = (errorMessage) => ({
    type: TASK_SCOPE_LIST_ERROR,
    payload: { errorMessage }
})
export const clearTaskScopeListError = () => ({
    type: TASK_SCOPE_LIST_ERROR_CLEAR,
})
export const setTaskScopeList = (taskScopeList) => ({
    type: SET_TASK_SCOPE_LIST,
    payload: { taskScopeList }
})

export const getTaskScopeList = (appId, page, PageSize) => (dispatch) => {
    dispatch(taskScopeListPending());
    dispatch(clearTaskScopeListError());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getTaskScopeList(accessToken, appId, page, PageSize)
            .then((result) => {
                dispatch(setTaskScopeList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(taskScopeListError(error));
                console.log('Task scope list server error', error);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

