import {
    IS_LEAD_CATEGORY_INLINE_EDIT,
    LEAD_CATEGORY_CREATE_CLOSE,
    LEAD_CATEGORY_CREATE_ERROR,
    LEAD_CATEGORY_CREATE_ERROR_CLEAR,
    LEAD_CATEGORY_CREATE_OPEN,
    LEAD_CATEGORY_CREATE_PENDING,
    LEAD_CATEGORY_CREATE_SUCCESS,
    LEAD_CATEGORY_LIST_ERROR,
    LEAD_CATEGORY_LIST_ERROR_CLEAR,
    LEAD_CATEGORY_LIST_PENDING,
    LEAD_CATEGORY_UPDATE_CLOSE,
    LEAD_CATEGORY_UPDATE_ERROR,
    LEAD_CATEGORY_UPDATE_ERROR_CLEAR,
    LEAD_CATEGORY_UPDATE_OPEN,
    LEAD_CATEGORY_UPDATE_PENDING,
    LEAD_CATEGORY_UPDATE_SUCCESS,
    SET_LEAD_CATEGORY_DETAILS,
    SET_LEAD_CATEGORY_LIST,
} from "./constants";

const initialState = {
    //--------------------CONTACT-CREATE-----------------------------------
    isCreateLeadCategoryOpen: false,
    leadCategoryCreateErrorMessage: null,
    isLeadCategoryCreateSuccess: false,
    isLeadCategoryCreatePending: false,
    //--------------------CONTACT-UPDATE-----------------------------------
    leadCategoryDetails: null,
    isUpdateLeadCategoryOpen: false,
    leadCategoryUpdateErrorMessage: null,
    isLeadCategoryUpdateSuccess: false,
    isLeadCategoryUpdatePending: false,
    //--------------------CONTACT-LIST-------------------------------------
    leadCategoryList: [],
    leadCategoryListCount: 0,
    isLeadCategoryListPending: false,
    leadCategoryListErrorMessage: null,
    isLeadCategoryEditID: null
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------LEADS-CREATE-----------------------------------
        case LEAD_CATEGORY_CREATE_OPEN: {
            return {
                ...localState,
                isCreateLeadCategoryOpen: true
            }
        }
        case LEAD_CATEGORY_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateLeadCategoryOpen: false
            }
        }
        case LEAD_CATEGORY_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                leadCategoryCreateErrorMessage: null,
            }
        }
        case LEAD_CATEGORY_CREATE_PENDING: {
            return {
                ...localState,
                isLeadCategoryCreatePending: true,
            }
        }
        case LEAD_CATEGORY_CREATE_SUCCESS: {
            return {
                ...localState,
                isLeadCategoryCreateSuccess: true,
                isLeadCategoryCreatePending: false
            }
        }
        case LEAD_CATEGORY_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadCategoryCreateErrorMessage: errorMessage,
                isLeadCategoryCreatePending: false,
            }
        }

        //--------------------LEADS-UPDATE-----------------------------------
        case SET_LEAD_CATEGORY_DETAILS: {
            const { categoryDetails } = action.payload;
            return {
                ...localState,
                leadCategoryDetails: categoryDetails
            }
        }
        case LEAD_CATEGORY_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateLeadCategoryOpen: true
            }
        }
        case LEAD_CATEGORY_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateLeadCategoryOpen: false
            }
        }
        case LEAD_CATEGORY_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                leadCategoryUpdateErrorMessage: null,
            }
        }
        case LEAD_CATEGORY_UPDATE_PENDING: {
            return {
                ...localState,
                isLeadCategoryUpdatePending: true,
            }
        }
        case LEAD_CATEGORY_UPDATE_SUCCESS: {
            return {
                ...localState,
                isLeadCategoryUpdateSuccess: true,
                isLeadCategoryUpdatePending: false
            }
        }
        case LEAD_CATEGORY_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadCategoryUpdateErrorMessage: errorMessage,
                isLeadCategoryUpdatePending: false
            }
        }
        //--------------------LEADS-CATEGORY-LIST-------------------------------------
        case SET_LEAD_CATEGORY_LIST: {
            const { leadCategoryList } = action.payload;
            return {
                ...localState,
                leadCategoryList: leadCategoryList && leadCategoryList['value'],
                leadCategoryListCount: leadCategoryList && leadCategoryList['@odata.count'],
                isLeadCategoryListPending: false,
                leadCategoryListErrorMessage: null,
            }
        }
        case LEAD_CATEGORY_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadCategoryListErrorMessage: errorMessage,
                isLeadCategoryListPending: false,
                leadCategoryList: []
            }
        }
        case LEAD_CATEGORY_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                leadCategoryListErrorMessage: null,
            }
        }
        case LEAD_CATEGORY_LIST_PENDING: {
            return {
                ...localState,
                isLeadCategoryListPending: true,
            }
        }
        case IS_LEAD_CATEGORY_INLINE_EDIT: {
            const { id } = action.payload;
            return {
                ...localState,
                isLeadCategoryEditID: id,
            };

        }
        default: {
            return localState;
        }
    }
};