import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { DASHBOARD_TIMESHEETS_CLEAR_ERROR, DASHBOARD_TIMESHEETS_ERROR_MESSAGE, SET_DASHBOARD, SET_TIMESHEETS_DASHBOARD_PENDING } from "./constants";

export const setDashboard = (dashboard) => ({
    type: SET_DASHBOARD,
    payload: { dashboard },
});

export const setTimesheetsDashboardPending = () => ({
    type: SET_TIMESHEETS_DASHBOARD_PENDING,
})

export const dashboardTimesheetsErrorMessage = (dashboardTimesheetsErrorMessage) => ({
    type: DASHBOARD_TIMESHEETS_ERROR_MESSAGE,
    payload: { dashboardTimesheetsErrorMessage },
});

export const dashboardTimesheetsClearError = () => ({
    type: DASHBOARD_TIMESHEETS_CLEAR_ERROR,
})

export const getDashboardCounts = (fromDate, toDate) => (dispatch) => {
    dispatch(setTimesheetsDashboardPending());
    getToken(dispatch).then((accessToken) => {
        agent.TimesheetDashboard.getDashboardCounts(accessToken, fromDate, toDate)
            .then((dashboardCounts) => {
                // console.log("Dashboard :", dashboardCounts);
                dispatch(setDashboard(dashboardCounts));
                dispatch(dashboardTimesheetsClearError());
            })
            .catch((err) => {
                console.log('getMyTeamTimesheetsList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                }
                dispatch(dashboardTimesheetsErrorMessage(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    })
}