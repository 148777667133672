import React, { useEffect, useRef, useState } from "react";
import {
    Form,
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
    Container,
    Button,
    Input,
    FormGroup,
    FormFeedback,
} from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import "./Editor.css";
import VWModel from "../../../components/Modal/VWModal";
import { Navigate, useLocation } from "react-router-dom";
import { clearCreateOverAllDocsError, ClearCreateOverAllDocsReducer, closeCreateOverAllDocsModalAction, closeCreateVisionDocsHeaderModalAction, createOverAllDocs, docsContent, docsTitle, getOverAllDocsDetailsAction, openCreateOverAllDocsHeaderModalAction, openCreateOverAllDocsModalAction } from "../../../store/Modules/OverAllDocs/actions";
import OverAllDocsCreateModal from "./OverAllDocsCreateModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faDownload, faSave, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import OverAllDocsCreateHeader from "./OverAllDocsCreateHeader";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import VWMessage from "../../../components/vwMessage/vwMessage";
import moment from "moment-timezone";

const OverAllDocsCreate = (props) => {
    const [errors, setErrors] = useState({});
    const [headerTitle, setHeaderTitle] = useState('');
    const maxLength = 50;
    const location = useLocation();
    const AppId = location.state || {};

    let path = location.pathname;
    let newPath = path.replace("/add", "");

    //-------------------------------------------Document title default date----------------------------------------------
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    };

    const getRandomNumber = () => {
        return Math.floor(Math.random() * 1000);
    };

    //--------------------------------------------------------------------------------------------------------------------
    // Initialize editor state
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const [formFields, setFormFields] = useState({
        "appId": AppId,
        "isPublic": true,
        "title": `newDocument_${getTodayDate()}${getRandomNumber()}`,
        "assignUsers": [],
        "contentText": '',
        "id": '',
        "headerTitle": '',
        "headerLogo": '',
        "includeUserInfo": false,
        "ownerUserName": '',
        "updatedDateTime": null,
        "createdTimeStamp": null
    });

    const pdfRef = useRef();

    const handleEditorChange = (content) => {
        setFormFields({ ...formFields, "contentText": content });
        props.docContent(content);
    };
    const formFieldsRef = useRef(formFields);
    formFieldsRef.current = formFields;



    const CreateOVerAllDocs = (currentFormFields) => {
        console.log("currentFormFields:", currentFormFields);
        console.log("CreateDocsID:", props.CreateDocsID);
        console.log("props.documentContent:", props.documentContent);

        let updatedFormFields;
        updatedFormFields = { ...currentFormFields };
        console.log('updatedFormFields:', updatedFormFields);

        const newErrors = {};
        if (!updatedFormFields.title || !updatedFormFields.title.trim()) {
            newErrors.title = 'Document Title is required';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            props.createOverAllDocsAction(updatedFormFields);
        }
    }
    console.log("formFieldLength:", formFields.contentText);


    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        CreateOVerAllDocs(formFieldsRef.current);
    };

    useEffect(() => {
        setFormFields({ ...formFields, 'id': props.CreateDocsID || '' });
        if (props.CreateDocsID) {
            props.getOverAllDocsDetailsAction(props.CreateDocsID, AppId);
        }
    }, [props.CreateDocsID])

    useEffect(() => {
        if (props.DocsDetails) {
            console.log("props.DocsDetails:", props.DocsDetails);
            setFormFields({ ...props.DocsDetails, 'id': props.CreateDocsID || '' });
        }
    }, [props.DocsDetails]);



    useEffect(() => {
        // props.isCreateEditDocspending();
        props.clearCreateOverAllDocsError();
        return async () => {
            console.log("CreateDocsID:", props.CreateDocsID);
            console.log("props.documentContent:", props.documentContent);

            CreateOVerAllDocs(formFieldsRef.current);
            await props.ClearCreateOverAllDocsReducer();
            console.log("formFields:", formFields);
            console.log("documentContent:", props.documentContent);
            console.log("CreateDocsID:", props.CreateDocsID);
        }
    }, []);

    const handleOpenModal = (e) => {
        e.preventDefault();
        props.openCreateOverAllDocsModal();
    };

    const handleClose = () => {
        props.closeCreateOverAllDocsModal();
        props.closeCreateVisionDocsHeaderModal();
    };

    const handleTitleChange = (e) => {
        const { value } = e.target;
        if (value && value.length >= maxLength) {
            setErrors({ ...errors, title: `Document name cannot exceed ${maxLength} characters.` });
        } else {
            setErrors({ ...errors, title: '' });
        }
        setFormFields({ ...formFields, title: e.target.value });
        props.docTitle(e.target.value);
    };

    const handleDone = (updatedFormFields) => {
        console.log("Return from modal:", updatedFormFields);
        setFormFields({ ...updatedFormFields });
        // setFormFields(updatedFormFields);
        setHeaderTitle(updatedFormFields && updatedFormFields.headerTitle);
        handleClose();
    };

    const handleOpenHeaderModal = (e) => {
        e.preventDefault();
        props.openCreateOverAllDocsHeaderModalAction();
    };


    const pdfPrint = async (headerTitle, content) => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });

        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;
        const margin = 10;
        const headerHeight = 20;
        const footerHeight = 10;
        let pageNumber = 1;
        let currentY = headerHeight; // Start content position after header
        if ((formFields.headerTitle && formFields.headerTitle.length > 0) || (formFields && formFields.headerLogo)) {
            currentY = headerHeight + 17;
        }

        // Function to add header
        const addHeader = async () => {
            if ((formFields.headerTitle && formFields.headerTitle.length > 0) || (formFields && formFields.headerLogo)) {
                pdf.setFontSize(20);
                pdf.text(formFields && formFields.headerTitle, pageWidth / 2, 20, { align: 'center' });
                pdf.setFontSize(12);
                pdf.setDrawColor(0, 0, 0);
                pdf.line(margin, 25, pageWidth - margin, 25);
                console.log("headerLogo:", formFields && formFields.headerLogo);
                if (formFields && formFields.headerLogo) {
                    pdf.addImage(formFields && formFields.headerLogo, 'PNG', 10, 10, 30, 15);
                }
                if (formFields && formFields.includeUserInfo) {
                    pdf.setFontSize(10);
                    pdf.text(`Document Owner: ${formFields.ownerUserName || 'N/A'}`, pageWidth - margin - 50, 10);
                }
            }
            else if (formFields && formFields.includeUserInfo) {
                pdf.setFontSize(10);
                pdf.text(`Document Owner: ${formFields.ownerUserName || 'N/A'}`, pageWidth - margin - 50, 10);
            }
        };

        // Function to add footer
        const addFooter = () => {
            pdf.setFontSize(10);
            pdf.text(`Page ${pageNumber}`, pageWidth / 2, pageHeight - footerHeight / 2, { align: 'center' });
            const timestampText = `Last Updated Date: ${formFields.updatedDateTime ? moment(formFields.updatedDateTime).format('DD MMM YYYY') : moment(formFields.createdTimeStamp).format('DD MMM YYYY') || 'N/A'}`;
            const timestampX = pageWidth - margin - pdf.getTextWidth(timestampText);
            const timestampY = pageHeight - footerHeight / 2;

            pdf.text(timestampText, timestampX, timestampY);
        };

        // Function to add content to the page
        const addContentToPage = async (startY, content) => {
            let currentY = startY;

            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(content, 'text/html');
            const elements = htmlDoc.body.childNodes;

            for (const element of elements) {
                pdf.setFont("YourCustomFontName", "normal");
                pdf.setFontSize(12);

                if (element.nodeName === 'P' || element.nodeName === '#text') {
                    const textNodes = element.childNodes;
                    for (const textNode of textNodes) {
                        let text = textNode.textContent || '';

                        if (textNode.nodeName === 'STRONG' || textNode.nodeName === 'B') {
                            pdf.setFont("YourCustomFontName", "bold");
                        } else if (textNode.nodeName === 'EM' || textNode.nodeName === 'I') {
                            pdf.setFont("YourCustomFontName", "italic");
                        }
                        else if (textNode.nodeName === 'U') {
                            pdf.setFont("YourCustomFontName", "underline");
                        }
                        else {
                            pdf.setFont("YourCustomFontName", "normal");
                        }

                        const lines = pdf.splitTextToSize(text, pageWidth - 2 * margin);
                        for (const line of lines) {
                            const lineHeight = pdf.getTextDimensions(line).h;
                            if (currentY + lineHeight > pageHeight - margin - footerHeight) {
                                pdf.addPage();
                                pageNumber++;
                                currentY = headerHeight; // Reset Y position after new page
                                if ((headerTitle && headerTitle.length > 0) || (formFields && formFields.headerLogo)) {
                                    currentY = headerHeight + 17;
                                }
                                await addHeader(); // Add header on new page
                                addFooter(); // Add footer on new page
                            }
                            pdf.text(line, margin, currentY);
                            currentY += lineHeight;
                        }
                        currentY += 5;

                        if (textNode.nodeName === 'IMG') {
                            const img = textNode;
                            const imgUrl = img.getAttribute('src');
                            const imgFormat = imgUrl.split(';')[0].split('/')[1].toUpperCase();

                            const image = new Image();
                            image.src = imgUrl;

                            await new Promise((resolve) => {
                                image.onload = async () => {
                                    const imgWidth = Math.min(image.width, pageWidth - 2 * margin);
                                    const imgHeight = (imgWidth * image.height) / image.width;

                                    if (currentY + imgHeight > pageHeight - margin - footerHeight) {
                                        pdf.addPage();
                                        pageNumber++;
                                        currentY = headerHeight + 7; // Reset Y position after new page
                                        await addHeader(); // Add header on new page
                                        addFooter(); // Add footer on new page
                                    }

                                    pdf.addImage(imgUrl, imgFormat, margin, currentY, imgWidth, imgHeight);
                                    currentY += imgHeight + 5;

                                    resolve();
                                };
                                image.onerror = () => {
                                    console.error('Failed to load image:', imgUrl);
                                    resolve();
                                };
                            });
                        }
                    }
                } else if (element.nodeName.startsWith('H')) {
                    const text = element.textContent || '';
                    const headerLevel = parseInt(element.nodeName.charAt(1));
                    pdf.setFontSize(24 - headerLevel * 2);
                    pdf.setFont("YourCustomFontName", "bold");

                    const lines = pdf.splitTextToSize(text, pageWidth - 2 * margin);
                    for (const line of lines) {
                        const lineHeight = pdf.getTextDimensions(line).h;
                        if (currentY + lineHeight > pageHeight - margin - footerHeight) {
                            pdf.addPage();
                            pageNumber++;
                            currentY = headerHeight + 7; // Reset Y position after new page
                            await addHeader(); // Add header on new page
                            addFooter(); // Add footer on new page
                        }
                        pdf.text(line, margin, currentY);
                        currentY += lineHeight;
                    }
                    currentY += 5;
                } else if (element.nodeName === 'UL' || element.nodeName === 'OL') {
                    const isOrdered = element.nodeName === 'OL';
                    const items = element.getElementsByTagName('LI');
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        let itemText = '';
                        const itemChildren = item.childNodes;

                        for (const child of itemChildren) {
                            if (child.nodeName === 'IMG') {
                                const img = child;
                                const imgUrl = img.getAttribute('src');
                                const imgFormat = imgUrl.split(';')[0].split('/')[1].toUpperCase();

                                const image = new Image();
                                image.src = imgUrl;

                                await new Promise((resolve) => {
                                    image.onload = async () => {
                                        const imgWidth = Math.min(image.width, pageWidth - 2 * margin);
                                        const imgHeight = (imgWidth * image.height) / image.width;

                                        if (currentY + imgHeight > pageHeight - margin - footerHeight) {
                                            pdf.addPage();
                                            pageNumber++;
                                            currentY = headerHeight + 7; // Reset Y position after new page
                                            await addHeader(); // Add header on new page
                                            addFooter(); // Add footer on new page
                                        }

                                        const bullet = isOrdered ? `${i + 1}. ` : '• ';
                                        pdf.text(bullet, margin, currentY);
                                        pdf.addImage(imgUrl, imgFormat, margin + 10, currentY - 3, imgWidth, imgHeight);
                                        currentY += imgHeight + 5;

                                        resolve();
                                    };
                                    image.onerror = () => {
                                        console.error('Failed to load image:', imgUrl);
                                        resolve();
                                    };
                                });
                            } else if (child.nodeType === Node.TEXT_NODE) {
                                itemText += child.textContent || '';
                            }
                        }

                        if (itemText) {
                            const bullet = isOrdered ? `${i + 1}. ` : '• ';
                            const lines = pdf.splitTextToSize(bullet + itemText, pageWidth - 2 * margin);
                            for (const line of lines) {
                                const lineHeight = pdf.getTextDimensions(line).h;
                                if (currentY + lineHeight > pageHeight - margin - footerHeight) {
                                    pdf.addPage();
                                    pageNumber++;
                                    currentY = headerHeight + 7; // Reset Y position after new page
                                    await addHeader(); // Add header on new page
                                    addFooter(); // Add footer on new page
                                }
                                pdf.text(line, margin, currentY);
                                currentY += lineHeight;
                            }
                            currentY += 5;
                        }
                    }
                } else if (element.nodeName === 'IMG') {
                    const img = element;
                    const imgUrl = img.getAttribute('src');
                    const imgFormat = imgUrl.split(';')[0].split('/')[1].toUpperCase();

                    const image = new Image();
                    image.src = imgUrl;

                    await new Promise((resolve) => {
                        image.onload = async () => {
                            const imgWidth = Math.min(image.width, pageWidth - 2 * margin);
                            const imgHeight = (imgWidth * image.height) / image.width;

                            if (currentY + imgHeight > pageHeight - margin - footerHeight) {
                                pdf.addPage();
                                pageNumber++;
                                currentY = headerHeight + 7; // Reset Y position after new page
                                await addHeader(); // Add header on new page
                                addFooter(); // Add footer on new page
                            }

                            pdf.addImage(imgUrl, imgFormat, margin, currentY, imgWidth, imgHeight);
                            currentY += imgHeight + 5;

                            resolve();
                        };
                        image.onerror = () => {
                            console.error('Failed to load image:', imgUrl);
                            resolve();
                        };
                    });
                }
            }
            // addFooter();
        };
        // Add header and footer to the first page
        await addHeader();
        addFooter();
        // Add content to the first page
        await addContentToPage(currentY, content);
        // Save the PDF
        pdf.save(`${formFields && formFields.title}.pdf`);
    };
    const handlePdfDownload = async () => {
        console.log("Editor Content:", formFields && formFields.contentText);

        // Normalize HTML content
        const normalizedContent = normalizeHtmlContent(formFields && formFields.contentText);
        console.log("Normalized Content:", normalizedContent);

        // Generate PDF with normalized content
        await pdfPrint(headerTitle, normalizedContent);
    };

    const normalizeHtmlContent = (html) => {
        // Create a temporary DOM element to parse and normalize the HTML content
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const body = doc.body;

        // Remove extra spaces and line breaks inside <p> tags
        const paragraphs = body.getElementsByTagName('p');
        for (let p of paragraphs) {
            p.innerHTML = p.innerHTML
                .replace(/[\r\n]+/g, ' ')  // Replace newlines with a space
                .replace(/\s{2,}/g, ' ')   // Replace multiple spaces with a single space
                .trim();
        }

        // Remove unnecessary line breaks and spaces in other tags if needed
        body.innerHTML = body.innerHTML
            .replace(/[\r\n]+/g, ' ')  // Replace newlines with a space
            .replace(/\s{2,}/g, ' ')   // Replace multiple spaces with a single space
            .trim();

        return body.innerHTML;
    };

    console.log("formFields:", formFields);
    console.log("documentContent:", props.documentContent)
    console.log("documentTitle:", props.documentTitle)

    return (
        <>
            {props.isCreateEditDocsSuccess ?
                (<Navigate to={newPath} />) :
                (<React.Fragment>
                    <div className="page-content">
                        <Container fluid={true}>
                            <Row>
                                <Col xs={12}>
                                    <Card>
                                        <Form >
                                            <CardBody>
                                                {props.errorMessage && (
                                                    <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
                                                )}
                                                <CardTitle className="h4 card-title-container">
                                                    <FormGroup>
                                                        <Input
                                                            style={{ width: '230px', marginRight: '16px' }}
                                                            className='title-input'
                                                            type="text"
                                                            name="title"
                                                            placeholder="Enter Document Title"
                                                            value={formFields.title}
                                                            onChange={handleTitleChange}
                                                            invalid={errors && errors.title}
                                                            maxLength={maxLength}
                                                        /><FormFeedback>{errors && errors.title}</FormFeedback>
                                                    </FormGroup>
                                                    <div className="button-group">
                                                        <Button
                                                            color="primary"
                                                            onClick={handleOpenHeaderModal}
                                                        ><FontAwesomeIcon icon={faAdd} style={{ marginRight: '8px' }} />
                                                            INSERT HEADER
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            onClick={handlePdfDownload}
                                                        >
                                                            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '8px' }} />
                                                            DOWNLOAD
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            className="share-button"
                                                            onClick={handleOpenModal}
                                                        >
                                                            <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: '8px' }} />
                                                            SHARE
                                                        </Button>

                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            onClick={handleSubmit}
                                                            disabled={props.isPending}
                                                        >  {props.isPending ? <>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                                            &nbsp; Saving...
                                                        </>
                                                            : <> <FontAwesomeIcon icon={faSave} style={{ marginRight: '8px' }} />
                                                                SAVE</>}
                                                        </Button>
                                                    </div>
                                                </CardTitle>
                                                <div className="quill-container">
                                                    <ReactQuill
                                                        ref={pdfRef}
                                                        id="pdf-content"
                                                        value={formFields && formFields.contentText}
                                                        onChange={handleEditorChange}
                                                        theme="snow"
                                                        placeholder="Start From Here..."
                                                    />
                                                </div>


                                            </CardBody>
                                        </Form>
                                    </Card>
                                    <VWModel
                                        header="CREATE DOCUMENT"
                                        isOpen={props.isCreateOverAllModalOpen}
                                        handleClose={handleClose}
                                    >
                                        <OverAllDocsCreateModal
                                            data={formFields}
                                            handleClose={handleClose}
                                            handleDone={handleDone}
                                        />
                                    </VWModel>
                                    <VWModel
                                        header="INSERT HEADER"
                                        isOpen={props.isOpenCreateOverAllDocsModal}
                                        handleClose={handleClose}
                                    >
                                        <OverAllDocsCreateHeader data={formFields} handleClose={handleClose} handleDone={handleDone} />
                                    </VWModel>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>)
            }
        </>
    );
}

OverAllDocsCreate.modules = {
    toolbar: [
        [{ 'font': [] }],
        [{ 'header': '1' }, { 'header': '2' }],
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link', 'image']
    ]
};

const mapStateToProps = (state) => ({
    errorMessage: state.OverAllDocsList.ErrorMessageCreateDocs,
    isPending: state.OverAllDocsList.isPendingDocsCreate,
    isCreateEditDocsSuccess: state.OverAllDocsList.isCreateEditDocsSuccess,
    isCreateOverAllModalOpen: state.OverAllDocsList.isCreateOverAllModalOpen,
    isOpenCreateOverAllDocsModal: state.OverAllDocsList.isOpenCreateOverAllDocsModal,
    documentContent: state.OverAllDocsList.documentContent,
    CreateDocsID: state.OverAllDocsList.CreateDocsID,
    documentTitle: state.OverAllDocsList.documentTitle,
    DocsDetails: state.OverAllDocsList.DocsDetails,
});

const mapDispatchToProps = (dispatch) => ({
    closeCreateOverAllDocsModal: () => dispatch(closeCreateOverAllDocsModalAction()),
    openCreateOverAllDocsModal: () => dispatch(openCreateOverAllDocsModalAction()),
    createOverAllDocsAction: (formFields) => dispatch(createOverAllDocs(formFields)),
    openCreateOverAllDocsHeaderModalAction: () => dispatch(openCreateOverAllDocsHeaderModalAction()),
    closeCreateVisionDocsHeaderModal: () => dispatch(closeCreateVisionDocsHeaderModalAction()),
    docContent: (content) => dispatch(docsContent(content)),
    ClearCreateOverAllDocsReducer: () => dispatch(ClearCreateOverAllDocsReducer()),
    getOverAllDocsDetailsAction: (id, appId) => dispatch(getOverAllDocsDetailsAction(id, appId)),
    docTitle: (title) => dispatch(docsTitle(title)),
    clearCreateOverAllDocsError: () => dispatch(clearCreateOverAllDocsError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverAllDocsCreate);
