import React, { useEffect, useState } from 'react'
import VWModel from '../../../../../components/Modal/VWModal'
import { Badge, Button, ButtonDropdown, Card, CardBody, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment';
import { connect } from 'react-redux'
import { closeEditOrgUserModal, getOrgUserDetailsAction, openEditOrgUserModal, closeChangePasswordByAdminModal, openChangePasswordByAdminModal, sendResetPasswordEmail, clearSendResetPasswordError } from '../../../../../store/Modules/Admin/OrgUser/actions'
import OrgUserEdit from './OrgUserEdit';
import VWMessage from '../../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../../components/vwSpinner/vwSpinner';
import { clearSetLoginPath, clearSetPath } from '../../../../../store/actions';
import ChangePasswordByAdmin from './ChangePasswordByAdmin';

const OrgDetails = (props) => {

    const params = useParams();
    const { id } = params;
    const { errorMessage, OrgUserDetails, isEditOrgUserModalOpen, isChangePasswordByAdminModalOpen, resetPasswordErrorMessage } = props || {};
    const { orgName, userEmail, firstName, lastName, isPortalEnable, createdBy, createdDateTime, userAppDetails, isActive,
        roleDetails, isGlobalReadOnlyUser, enableMailNotification } = OrgUserDetails || {};

    const [dropdownOpen, setOpen] = useState(false);

    useEffect(() => {
        props.getOrgUserDetails(id);
        props.ClearPath();
        props.clearSendResetPasswordError();
    }, [props.getOrgUserDetails, id]);

    useEffect(() => {
        return async () => {
            await props.ClearPath();
            props.clearSetLoginPath();
        }
    }, []);
    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditOrgUserModel = () => {
        const { openEditOrgUserModalAction } = props;
        openEditOrgUserModalAction();
    };

    const handleCloseEditOrgUserModal = () => {
        const { closeEditOrgUserModalAction } = props;
        closeEditOrgUserModalAction();
    };
    //----------------------------------------RESET-PASSWORD----------------------------------------------------
    const handleResetPassword = () => {
        const { sendResetPasswordEmail } = props;
        if (userEmail) {
            const dataToSend = { email: userEmail };
            sendResetPasswordEmail(dataToSend);
        }
    };

    const handleDirectPasswordReset = () => {
        const { openChangePasswordByAdminModal } = props;
        openChangePasswordByAdminModal();
    };

    const handleDirectPasswordResetClose = () => {
        const { closeChangePasswordByAdminModal } = props;
        closeChangePasswordByAdminModal();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>ORGANIZATION USER DETAILS</strong>
                        {errorMessage ? "" :
                            <Link type="button"
                                className="btn btn-info"
                                onClick={handleOpenEditOrgUserModel}
                            >
                                EDIT
                            </Link>}
                    </CardTitle>
                    <CardBody className="p-4">
                        {errorMessage ? <div className='w-100'>
                            {errorMessage && (
                                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : resetPasswordErrorMessage &&
                        <VWMessage type="danger" defaultMessage={resetPasswordErrorMessage} baseClassName="alert" ></VWMessage>
                        }
                        {props.isOrgUserDetailsPending ?
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                            :
                            <>
                                <Row className="justify-content-center">
                                    <div>
                                        <div className="p-3 bg-light fw-bold fs-4">
                                            <span className='text-capitalize'>{orgName || "N/A"}</span>
                                        </div>
                                    </div>
                                    <Col xl="6">
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>Organization Name</strong>
                                                                </td>
                                                                <td>{orgName || "N/A"}</td>
                                                            </tr>
                                                            {isGlobalReadOnlyUser ? '' :
                                                                <tr>
                                                                    <td class="text-nowrap">
                                                                        <strong>User Email</strong>
                                                                    </td>
                                                                    <td>{userEmail || "N/A"}</td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>First Name</strong>
                                                                </td>
                                                                <td>{firstName || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>Last Name</strong>
                                                                </td>
                                                                <td>{lastName || "N/A"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>Created By</strong>
                                                                </td>
                                                                <td>{createdBy || "N/A"}</td>
                                                            </tr>

                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>Created Date</strong>
                                                                </td>
                                                                <td>
                                                                    {createdDateTime ? moment(createdDateTime).format('DD MMM YYYY',) : 'N/A'}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="6">
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <strong>Roles</strong>
                                                                </td>
                                                                <td>{roleDetails && roleDetails.length > 0 ?
                                                                    roleDetails && roleDetails.map((items, index) => (
                                                                        //eslint-disable-next-line
                                                                        <React.Fragment key={index}>
                                                                            <Badge color='success' >{items.roleName}</Badge>&nbsp;
                                                                        </React.Fragment>
                                                                    ))
                                                                    : "N/A"}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>Assigned Apps and Roles</strong>
                                                                </td>
                                                                <td> {
                                                                    userAppDetails && userAppDetails.length > 0 ?
                                                                        userAppDetails.map(item => (
                                                                            item.appRoleDetails.map(role => (
                                                                                <Badge key={`${item.appName}-${role.roleName}`} className="me-1" color="success" size="sm">
                                                                                    {`${item.appName} / ${role.roleName || "NA"}`}
                                                                                </Badge>
                                                                            ))
                                                                        ))
                                                                        : 'N/A'
                                                                }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>User Status</strong>
                                                                </td>
                                                                <td>
                                                                    {isActive ? (
                                                                        <Badge color="success" className="text-dark-white">
                                                                            Active
                                                                        </Badge>
                                                                    ) : (
                                                                        <Badge color="danger" className="text-dark-white">
                                                                            Inactive
                                                                        </Badge>)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>Account Status</strong>
                                                                </td>
                                                                <td>
                                                                    {isPortalEnable ? (
                                                                        <Badge color="success" className="text-dark-white">
                                                                            Enabled
                                                                        </Badge>
                                                                    ) : (
                                                                        <Badge color="danger" className="text-dark-white">
                                                                            Disabled
                                                                        </Badge>)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>Is Global Read Only User</strong>
                                                                </td>
                                                                <td>
                                                                    {isGlobalReadOnlyUser ? (
                                                                        <Badge color="success" className="text-dark-white">
                                                                            Yes
                                                                        </Badge>
                                                                    ) : (
                                                                        <Badge color="danger" className="text-dark-white">
                                                                            No
                                                                        </Badge>)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-nowrap">
                                                                    <strong>Email Notification Status</strong>
                                                                </td>
                                                                <td>
                                                                    {enableMailNotification ? (
                                                                        <Badge color="success" className="text-dark-white">
                                                                            Enabled
                                                                        </Badge>
                                                                    ) : (
                                                                        <Badge color="danger" className="text-dark-white">
                                                                            Disabled
                                                                        </Badge>)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {!errorMessage &&
                                    <div>
                                        <h5 className='fw-bold'>RESET PASSWORD</h5>
                                        <div className="mt-4 mb-4">
                                            <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }}
                                                isOpen={dropdownOpen}>
                                                <Button
                                                    className="btn-oval"
                                                    color='danger'
                                                    size='sm'
                                                    onClick={() => { setOpen(!dropdownOpen) }}
                                                >Reset Password
                                                </Button>
                                                <DropdownToggle
                                                    color='danger'
                                                    split
                                                    className='px-2'
                                                >
                                                    {dropdownOpen ? <i class="fas fa-caret-down" />
                                                        : <i class="fas fa-caret-up" />
                                                    }
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={handleResetPassword}>
                                                        Reset via Email
                                                    </DropdownItem>
                                                    <DropdownItem onClick={handleDirectPasswordReset}>
                                                        Reset Directly
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </div>
                                    </div>}
                            </>
                        }
                    </CardBody>
                    <VWModel
                        header="EDIT ORGANIZATION USER"
                        isOpen={isEditOrgUserModalOpen}
                        handleClose={handleCloseEditOrgUserModal}
                    >
                        <OrgUserEdit handleClose={handleCloseEditOrgUserModal} />
                    </VWModel>
                    <VWModel
                        header="CHANGE PASSWORD"
                        isOpen={isChangePasswordByAdminModalOpen}
                        handleClose={handleDirectPasswordResetClose}
                    >
                        <ChangePasswordByAdmin
                            userId={id}
                            handleClose={handleDirectPasswordResetClose}
                        />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = (state) => ({
    OrgUserDetails: state.OrgUser.OrgUserDetails,
    errorMessage: state.OrgUser.orgDetailsError,
    isEditOrgUserModalOpen: state.OrgUser.isEditOrgUserModalOpen,
    isOrgUserDetailsPending: state.OrgUser.isOrgUserDetailsPending,
    isChangePasswordByAdminModalOpen: state.OrgUser.isChangePasswordByAdminModalOpen,
    resetPasswordErrorMessage: state.OrgUser.resetPasswordError,
});

const mapDispatchToProps = (dispatch) => ({
    getOrgUserDetails: (Id) => dispatch(getOrgUserDetailsAction(Id)),
    openEditOrgUserModalAction: () => dispatch(openEditOrgUserModal()),
    closeEditOrgUserModalAction: () => dispatch(closeEditOrgUserModal()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
    sendResetPasswordEmail: (email) => dispatch(sendResetPasswordEmail(email)),
    openChangePasswordByAdminModal: () => dispatch(openChangePasswordByAdminModal()),
    closeChangePasswordByAdminModal: () => dispatch(closeChangePasswordByAdminModal()),
    clearSendResetPasswordError: () => dispatch(clearSendResetPasswordError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgDetails)