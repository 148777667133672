import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import {
    MAIL_ERROR,
    MAIL_ERROR_CLEAR,
    MAIL_PENDING,
    SET_MAIL,
    SUBMIT_UNSUBSCRIBE_ERROR,
    SUBMIT_UNSUBSCRIBE_ERROR_CLEAR,
    SUBMIT_UNSUBSCRIBE_PENDING,
    SUBMIT_UNSUBSCRIBE_SUCCESS
} from "./constants";

export const mailPending = () => ({
    type: MAIL_PENDING
})
export const mailError = (errorMessage) => ({
    type: MAIL_ERROR,
    payload: { errorMessage }
})
export const clearMailError = () => ({
    type: MAIL_ERROR_CLEAR,
})
export const setMail = (mail) => ({
    type: SET_MAIL,
    payload: { mail }
})

export const getEmail = (id) => (dispatch) => {
    dispatch(mailPending());
    agent.MassMailer.getEmail(id)
        .then((result) => {
            dispatch(setMail(result))
            dispatch(clearMailError());
        })
        .catch((err) => {
            if (err && err.response) {
                console.log("err:", err.response.text);
                dispatch(mailError(err.response && err.response.text));
                return;
            }
            console.log('Create Account server error', err);
            // dispatch(closeAccountCreate());
            dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
        });
};

//-------------------------------------------------------------MAIL-UNSUBSCRIBE-----------------------------------------------------------------------
export const submitUnsubscribePending = () => ({
    type: SUBMIT_UNSUBSCRIBE_PENDING
})
export const submitUnsubscribeError = (errorMessage) => ({
    type: SUBMIT_UNSUBSCRIBE_ERROR,
    payload: { errorMessage }
})
export const clearSubmitUnsubscribeError = () => ({
    type: SUBMIT_UNSUBSCRIBE_ERROR_CLEAR,
})
export const submitUnsubscribeSuccess = () => ({
    type: SUBMIT_UNSUBSCRIBE_SUCCESS,
})

export const submitUnsubscribe = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(submitUnsubscribeError('Please fill required details.'));
    }
    else {
        dataToSend = { ...formFields, };
    }
    dispatch(submitUnsubscribePending());
    dispatch(clearSubmitUnsubscribeError());
    agent.MassMailer.submitUnsubscribe(dataToSend)
        .then(() => {
            dispatch(submitUnsubscribeSuccess());
            dispatch(showSnackbarStatus('Unsubscribed successfully'));
        })
        .catch((err) => {
            if (err && err.response) {
                console.log("err:", err.response.text);
                dispatch(submitUnsubscribeError(err.response && err.response.text));
                return;
            }
            console.log('Create Account server error', err);
            // dispatch(closeAccountCreate());
            dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
        });
};
