import { CLEAR_CREATE_DB_ERROR, CLEAR_DB_SYNC_LIST_ERROR, CLOSE_CREATE_DB_MODAL, CLOSE_DELETE_DB_SYNC_MODAL, CREATE_DB_ERROR, CREATE_DB_PENDING, CREATE_DB_SUCCESS, DB_SYNC_LIST_ERROR, DELETE_DB_SYNC_ERROR, OPEN_CREATE_DB_MODAL, OPEN_DELETE_DB_SYNC_MODAL, SET_DB_SYNC_LIST, SET_DB_SYNC_PENDING } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    isCreateDBModalOpen: false,
    dbSyncList: [],
    dbSyncListError: null,
    totalRecords: 0,
    isDBSyncListPending: false,
    isDeleteDBSyncModalOpen: false

};
export default (localState = initialState, action) => {
    switch (action.type) {


        case SET_DB_SYNC_LIST: {
            const { dbSyncList } = action.payload;
            return {
                ...localState,
                isDBSyncListPending: false,
                dbSyncList: dbSyncList && dbSyncList.value,
                totalRecords: dbSyncList["@odata.count"],
            }
        }
        case DB_SYNC_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                dbSyncList: [],
                isDBSyncListPending: false,
                dbSyncListError: errorMessage,
            }
        }
        case CLEAR_DB_SYNC_LIST_ERROR: {
            return {
                ...localState,
                dbSyncListError: null,
            }
        }

        case SET_DB_SYNC_PENDING: {
            return {
                ...localState,
                dbSyncListError: null,
                isDBSyncListPending: true
            }
        }
        case OPEN_CREATE_DB_MODAL: {
            return {
                ...localState,
                isCreateDBModalOpen: true,
            };
        }
        case CLOSE_CREATE_DB_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateDBModalOpen: false,
            };
        }
        case CLEAR_CREATE_DB_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CREATE_DB_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CREATE_DB_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }

        case CREATE_DB_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case OPEN_DELETE_DB_SYNC_MODAL: {
            return {
                ...localState,
                isDeleteDBSyncModalOpen: true,
            };
        }
        case CLOSE_DELETE_DB_SYNC_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isDeleteDBSyncModalOpen: false,
            };
        }
        case DELETE_DB_SYNC_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        default: {
            return localState;
        }
    }
};