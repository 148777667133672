import React, { useEffect, useState } from 'react'
import './Report.css'
import Avatar from 'react-avatar'
import moment from 'moment-timezone'
import { Progress } from 'reactstrap'
import Rating from 'react-rating'
import { Link } from 'react-router-dom'
import { ElementToolTip } from '../vwToolTip'
import { ElementReportToolTip } from '../vwToolTipReport'


const ProjectCard = ({ data, dates }) => {

    const getUserColor = (userName) => {
        const hash = Array.from(userName).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const hue = hash % 360; // Hue value for HSL color
        return `hsl(${hue}, 70%, 60%)`; // Return a color based on the hue
    };

    useEffect(() => {
        // Reinitialize tooltips after each render
        document.querySelectorAll('.tooltip').forEach((tooltip) => {
            // Assuming a method to reinitialize tooltips
            ElementToolTip.reinitialize(tooltip);
        });
    });
    return (
        <div className="card-container">
            <div className="project-card">
                <div className="projectcard-header mt-2">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <Avatar name={data.ProjectName || null} round size="40" className="avatar" />
                        <div className="projectcard-header-info">
                            <h5><span style={{ cursor: 'pointer' }} id={`projectname-${data.Id}`}>{data.ProjectName || "N/A"}</span><ElementToolTip id={`projectname-${data.Id}`} name={`Project Name: ${data.ProjectName}`} /></h5>
                            <p><span style={{ cursor: 'pointer' }} id={`projectmanagername-${data.Id}`}>{data.ProjectManagerUserName || "N/A"}</span><ElementToolTip id={`projectmanagername-${data.Id}`} name={`Project Manager: ${data.ProjectManagerUserName}`} /></p>
                        </div>
                    </div>
                </div>
                <div className="date-range">
                    <span role="img" aria-label="calendar">📅</span> {data.StartDate ? moment(data.StartDate).format('DD MMM YYYY') : 'N/A'} - {data.EndDate ? moment(data.EndDate).format('DD MMM YYYY') : 'N/A'}
                </div>
                <div className="card-details">
                    <div className='d-flex justify-content-between'>
                        <span style={{ cursor: 'pointer' }} id={`pendingTask-${data.Id}`}><strong>Pending Tasks:</strong> {data.PendingTask || 0}</span><ElementToolTip id={`pendingTask-${data.Id}`} name={`Task count between ${moment(dates.StartDate).format('DD-MM-YYYY')} to ${moment(dates.EndDate).format('DD-MM-YYYY')}`} />
                        <span className='me-1'><strong>Today Tasks:</strong> {data.TodayTask || 0}</span>
                    </div>
                    <div className='d-flex align-items-center ms-0'>
                        <strong >Rating </strong>
                        <div style={{ flex: 0.75, display: "flex", textAlign: 'center' }} className='p-1'>
                            <Rating
                                className="user-rating"
                                emptySymbol="mdi mdi-star-outline text-warning fa-2x"
                                fullSymbol="mdi mdi-star text-warning fa-2x"
                                initialRating={(data && data.OverAllRating) || 0}
                                readonly
                            />  </div>
                        <div style={{ flex: 0.30, textAlign: 'center' }}>
                            {(data && data.OverAllRating) || 0}/5
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div style={{ flex: 0.75, display: "flex", alignItems: "center" }}>
                            <strong>Status</strong>&nbsp;
                            <Progress
                                value={data?.ProjectStatus || 0}
                                color="success"
                                style={{ width: "100%" }}
                                className='mt-0'
                                // animated
                                striped
                            />
                        </div>
                        <div style={{ flex: 0.25, textAlign: "center" }} className='mb-1'>
                            {data?.ProjectStatus || 0}%
                        </div>
                    </div>

                </div>
                <div className="buttons">
                    <button className="badge badge-soft-primary me-1 bg-soft-primary" style={{ cursor: 'default' }}>Tasks: {data && data.TotalTask || 0}</button>
                    <button className="badge badge-soft-success me-1 bg-soft-success" style={{ cursor: 'default' }}>Milestones: {data && data.TotalMilestone || 0}</button>
                </div>
                <div className="assigned-users mt-2">
                    <div className="avatar-group">
                        {data.UserDetails &&
                            data.UserDetails.slice(0, 3).map((user, index) => (
                                <div key={index} className="avatar-group-item" id={`contributions-tooltip-${data.Id}`} style={{ cursor: 'pointer' }}>
                                    {user.ProfilePicture ? (
                                        <img
                                            src={user.ProfilePicture}
                                            alt={user.UserName}
                                            className="rounded-circle avatar-xs"
                                        />
                                    ) : (
                                        <div className="fallback-avatar" style={{ backgroundColor: getUserColor(user.UserName) }}>
                                            {user.UserName.charAt(0).toUpperCase()}
                                        </div>
                                    )}
                                </div>
                            ))}
                        {data.UserDetails && data.UserDetails.length > 3 && (
                            <div className="avatar-group-item more-users" id={`contributions-tooltip-${data.Id}`} style={{ cursor: 'pointer' }}>
                                <div className="rounded-circle avatar" style={{ backgroundColor: getUserColor('more-users'), display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', fontWeight: 'bold' }}>
                                    +{data.UserDetails.length - 3}
                                </div>
                            </div>
                        )}
                        <ElementReportToolTip id={`contributions-tooltip-${data.Id}`} name={
                            <div>
                                {data.UserDetails && data.UserDetails.map((user, index) => (
                                    <div key={index}>
                                        <small>{user.UserName} - {`${user.UserStatus || "0"}%`}</small>
                                    </div>
                                ))}
                            </div>
                        } />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProjectCard