import React, { useEffect, useState } from 'react'
import VWButton from '../../../../components/vwButton/VWButton'
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { updateLeaveType } from '../../../../store/Modules/LeaveTracker/LeaveType/actions';

const LeaveTypeUpdate = (props) => {
    const [formFields, setFormFields] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props.LeaveTypeDetails) {
            setFormFields(props.LeaveTypeDetails);
        }
    }, [])

    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setFormFields({ ...formFields, [input.name]: value });

        if (input.name === 'LeaveType') {
            setErrors({ ...errors, leaveType: '' });
        }
    };
    //--------------------------------------------Update ---------------------------------------------------------
    const handleUpdateLeaveType = () => {
        const newErrors = {};
        if (!formFields.leaveType) {
            newErrors.leaveType = 'Leave type is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.EditLeaveTypeAction(formFields);
        }
    };
    //-----------------------------------------------------------------------------------------------------------
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <>
                <div className="form-group">
                    <label className="col-form-label">
                        Leave Type <span className="text-danger">*</span>
                    </label>
                    <Input
                        type="text"
                        name="leaveType"
                        placeholder="Enter LeaveType"
                        onChange={validateOnChange}
                        value={formFields && formFields.leaveType}
                    />
                    {<span className='text-danger'><small>{errors && errors.leaveType}</small></span>}
                </div>
                <div className="form-group">
                    <label className="col-form-label">
                        Description
                    </label>
                    <Input
                        type="textarea"
                        name="description"
                        placeholder="Enter description"
                        onChange={validateOnChange}
                        value={formFields && formFields.description}
                    />
                </div>
                <br></br>
                <div className="form-group">
                    <VWButton
                        messageKey="UpdateLeaveTypeButton"
                        defaultMessage="Update"
                        buttonType="success"
                        baseClassName="btn"
                        className="me-2"
                        onClick={handleUpdateLeaveType}
                    ></VWButton>
                    <VWButton
                        messageKey="cancel Leave Type"
                        defaultMessage="Cancel"
                        buttonType="danger"
                        baseClassName="btn"
                        onClick={props.handleClose}
                    ></VWButton>
                </div>
            </>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.leaveType.isPending,
    errorMessage: state.leaveType.updateleaveTypeErrorMessage,
    LeaveTypeDetails: state.leaveType.LeaveTypeDetails,
});

const mapDispatchToProps = (dispatch) => ({
    // EditLeaveTypeAction: (formfield) => dispatch(updateLeaveType(formfield)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveTypeUpdate);