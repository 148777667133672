import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container, Form, Button, Alert } from "reactstrap";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { clearSetPath, formLogin, loginUserPending, logoutUser, setIsLoading, setLoginPath, setPath, userLogin } from "../../store/actions";
import Message from "../../CommonData/Data/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import vizo_logo from "../../assets/images/Vizo logo (9).png"
import { faLock } from "@fortawesome/free-solid-svg-icons";
import VWSpinner from "../../components/vwSpinner/vwSpinner";


const Login = props => {
  const [isTrigger, setIsTrigger] = useState(false);
  const { path } = props;
  const fullPath = window.location.pathname;

  console.log("fullPath:", fullPath);


  useEffect(() => {
    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const code = urlParams.get('code');

    if (code) {
      props.userLogin(code);
      loginUserPending();
    }
  }, []);

  useEffect(() => {
    if (fullPath && fullPath.length > 1) {
      if (fullPath) {
        props.setPath(fullPath);
      }
    }
  }, [fullPath && fullPath.length > 1])

  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const OAuth_apiUrl = window.__ENV && window.__ENV.OAuth_apiUrl;
  const client_id = window.__ENV && window.__ENV.client_id;
  const redirect_uri = window.__ENV && window.__ENV.redirect_uri;

  const OAuthCodeURI = `${OAuth_apiUrl}/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=openid%20email%20offline%5Faccess`
  const loginTrigger = () => {
    window.location.href = OAuthCodeURI;
    setIsTrigger(true);
  }

  const { isAuth, errorMessage, isPending } = props;
  console.log("currentProfileErrorMessage:", props.currentProfileErrorMessage);
  console.log("path:", path);

  useEffect(() => {
    console.log("isAuth", isAuth);
    console.log("path", path);
    console.log("fullPath", fullPath);
    console.log("props.loginPath", props.loginPath);

    if ((path === fullPath) && !isAuth) {
      console.log("check path  and fullpath equal");
      console.log("isAuth", isAuth);

      props.setLoginPath(path);
    }
    else if ((path === null) && fullPath) {
      props.setLoginPath(fullPath);
    }
    // else if ((path === props.loginPath) && fullPath==='/' && !isAuth){
    //   console.log("check path and loginpath equal");
    //   props.setLoginPath(path);
    // }
    // else if (fullPath && !isAuth && (path !== fullPath)) {
    //   console.log("isAuth false and full path");

    //   props.setLoginPath(fullPath);
    // }
    else {
      console.log("else");

      // props.setLoginPath(fullPath);
    }
  }, [fullPath && fullPath.length > 1])

  console.log("LoginPath:", props.loginPath);

  useEffect(() => {
    if (props.currentProfileErrorMessage) {
      const timeoutId = setTimeout(() => {
        props.logoutUser();
        const OAuthLogout = `${OAuth_apiUrl}/v2/logout?client_id=${client_id}&returnTo=${encodeURIComponent(window.location.origin)}`
        window.location.replace(OAuthLogout)
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [props.currentProfileErrorMessage]);

  // return (isAuth) && (!props.currentProfileErrorMessage) ? (path && path.length > 1) ? (<Navigate to={path} />) : (
  return (isAuth) && (!props.currentProfileErrorMessage) ? (props.loginPath && props.loginPath.length > 1) ? (<Navigate to={props.loginPath} />) : (
    <Navigate to='/dashboard' />) :
    (
      <>
        {isPending ? (
          <>
            <div className="bg-overlay"></div>
            {props.currentProfileErrorMessage && (
              <Alert color="danger">{props.currentProfileErrorMessage}</Alert>
            )}
            <div className="d-flex justify-content-center" style={{ color: "grey", paddingTop: "20%" }}>
              <div className="d-flex justify-content-center mt-2">
                <VWSpinner />
              </div>
            </div>
          </>
        )
          :
          <React.Fragment>
            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={6} md={8} xl={4}>
                    <Card>
                      <CardBody className="p-4 ">
                        <div>
                          {errorMessage || props.currentProfileErrorMessage && (
                            <Message
                              type="danger"
                              defaultMessage={errorMessage || props.currentProfileErrorMessage}
                              baseClassName="alert"
                            ></Message>
                          )}
                          <Card className='shadow-none'>
                            <Col xs="auto" className="logo-lg text-center px-2 mx-2">
                              <img src={vizo_logo} height="auto" width="50%" />
                            </Col>
                          </Card>

                          <Form
                            className="form-horizontal"
                          >
                            <Row>
                              <Col md={12}>
                              </Col>
                            </Row>
                            <Row>
                              <Col><br />
                                {!isTrigger ?
                                  <div className="text-center">
                                    <>
                                      <Button style={{ color: 'white', width: "50%" }} className="ms-auto text-decoration-none btn btn-success"
                                        onClick={loginTrigger}
                                        disabled={props.currentProfileErrorMessage}
                                      >
                                        <FontAwesomeIcon icon={faLock} />&nbsp; Login
                                      </Button>
                                    </>
                                  </div>
                                  :
                                  <div className="text-center">
                                    <>
                                      <Link style={{ color: 'white', width: "50%" }} className="ms-auto text-decoration-none btn btn-success"
                                      >
                                        <div className="spinner-border spinner-border-sm"></div> Loading ...
                                      </Link>
                                    </>
                                  </div>
                                }
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="mt-5 text-center">
                      <p className="text-white-50">
                        {new Date().getFullYear()} © {window.__ENV && window.__ENV.credits}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        }
      </>
    );
};

const mapStateToProps = (state) => ({
  form: state.login.form,
  currentProfile: state.login.currentProfile,
  isAuth: state.login.isAuth,
  errorMessage: state.login.errorMessage,
  isloading: state.login.isloading,
  isPending: state.login.isPending,
  currentProfileErrorMessage: state.login.currentProfileErrorMessage,
  path: state.login.path,
  loginPath: state.login.loginPath
});

const mapDispatchToProps = (dispatch) => ({
  formLogin: (form) => dispatch(formLogin(form)),
  userLogin: () => dispatch(userLogin()),
  setIsLoading: (isloading) => dispatch(setIsLoading(isloading)),
  userLogin: (code) => dispatch(userLogin(code)),
  setPath: (path) => dispatch(setPath(path)),
  setLoginPath: (path) => dispatch(setLoginPath(path)),
  logoutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);