import { LEAD_MANDATORY_LIST_ERROR, LEAD_MANDATORY_LIST_ERROR_CLEAR, LEAD_MANDATORY_LIST_PENDING, LEAD_MANDATORY_UPDATE_CLOSE, LEAD_MANDATORY_UPDATE_ERROR, LEAD_MANDATORY_UPDATE_ERROR_CLEAR, LEAD_MANDATORY_UPDATE_OPEN, LEAD_MANDATORY_UPDATE_PENDING, LEAD_MANDATORY_UPDATE_SUCCESS, SET_LEAD_MANDATORY_DETAILS, SET_LEAD_MANDATORY_LIST } from "./constants";



const initialState = {
    //--------------------LEAD-MANDATORY-LIST-------------------------------------
    leadMandatoryList: [],
    leadMandatoryListCount: 0,
    isLeadMandatoryListPending: false,
    leadMandatoryListErrorMessage: null,
    //--------------------CONTACT-UPDATE-----------------------------------
    leadMandatoryDetails: null,
    isUpdateLeadMandatoryOpen: false,
    leadMandatoryUpdateErrorMessage: null,
    isLeadMandatoryUpdateSuccess: false,
    isLeadMandatoryUpdatePending: false,
};



export default (localState = initialState, action) => {
    switch (action.type) {

        //--------------------LEADS-CATEGORY-LIST-------------------------------------
        case SET_LEAD_MANDATORY_LIST: {
            const { leadMandatoryList } = action.payload;
            return {
                ...localState,
                leadMandatoryList: leadMandatoryList && leadMandatoryList['value'],
                leadMandatoryListCount: leadMandatoryList && leadMandatoryList['@odata.count'],
                isLeadMandatoryListPending: false,
                leadMandatoryListErrorMessage: null,
            }
        }
        case LEAD_MANDATORY_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadMandatoryListErrorMessage: errorMessage,
                isLeadMandatoryListPending: false,
                leadMandatoryList: []
            }
        }
        case LEAD_MANDATORY_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                leadMandatoryListErrorMessage: null,
            }
        }
        case LEAD_MANDATORY_LIST_PENDING: {
            return {
                ...localState,
                isLeadMandatoryListPending: true,
            }
        }
        //--------------------LEADS-UPDATE-----------------------------------
        case SET_LEAD_MANDATORY_DETAILS: {
            const { leadMandatoryDetails } = action.payload;
            return {
                ...localState,
                leadMandatoryDetails: leadMandatoryDetails
            }
        }
        case LEAD_MANDATORY_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateLeadMandatoryOpen: true
            }
        }
        case LEAD_MANDATORY_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateLeadMandatoryOpen: false
            }
        }
        case LEAD_MANDATORY_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                leadMandatoryUpdateErrorMessage: null,
            }
        }
        case LEAD_MANDATORY_UPDATE_PENDING: {
            return {
                ...localState,
                isLeadMandatoryUpdatePending: true,
            }
        }
        case LEAD_MANDATORY_UPDATE_SUCCESS: {
            return {
                ...localState,
                isLeadMandatoryUpdateSuccess: true,
                isLeadMandatoryUpdatePending: false
            }
        }
        case LEAD_MANDATORY_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                leadMandatoryUpdateErrorMessage: errorMessage,
                isLeadMandatoryUpdatePending: false
            }
        }

        default: {
            return localState;
        }
    }
};