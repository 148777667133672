import React, { useEffect } from 'react'
import VWModel from '../../../../components/Modal/VWModal'
import moment from "moment";
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { closeEditTimeSheetModal, getTimeSheetDetails, openEditTimeSheetModal } from '../../../../store/Modules/Timesheet/myTimesheet/actions';
import EditTimeSheetMaster from './TimeSheetUpdate';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';

const TimeSheetDetails = (props) => {
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    props.getTimeSheetDetailsAction(id);
    props.ClearPath();
  }, [props.getTimeSheetDetailsAction, id]);

  useEffect(() => {
    return async () => {
      await props.clearSetLoginPath();
      props.ClearPath();
    }
  }, []);

  const handleOpenEditTimesheetModel = () => {
    const { openEditTimeSheetModalAction } = props;
    openEditTimeSheetModalAction();
  };

  const handleCloseEditTimeSheetModel = () => {
    const { closeEditTimeSheetModalAction } = props;
    closeEditTimeSheetModalAction();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Card>
          <CardTitle
            tag="h4"
            className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
          >
            <strong>TIMESHEET DETAILS</strong>
            {props.TimeSheetDetails && props.TimeSheetDetails.isEditable?
            <Link type="button"
              className="mr-1 btn btn-primary"
              onClick={handleOpenEditTimesheetModel}
            >
              EDIT
            </Link>:''}
          </CardTitle>
          <CardBody className="p-4">
            {props.timesheetDetailsErrorMessage ? <div className='w-100'>
              {props.timesheetDetailsErrorMessage && (
                <VWMessage type="danger" defaultMessage={props.timesheetDetailsErrorMessage} baseClassName="alert" ></VWMessage>
              )}
            </div> : ""}
            {props.isTimesheetDetailsPending ?
              <div className="d-flex justify-content-center mt-2">
                <VWSpinner />
              </div>
              :
              <Row className="justify-content-center">
                <div>
                  <div className="p-3 bg-light fw-bold fs-4">
                    <span>{props.TimeSheetDetails && props.TimeSheetDetails.userName || "N/A"}</span>
                  </div>
                </div>
                <Col xl="6">
                  <div className="row pl-4">
                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped table-hover">
                          <tbody>
                            <tr>
                              <td>
                                <strong>User Name</strong>
                              </td>
                              <td>{props.TimeSheetDetails && props.TimeSheetDetails.userName || "N/A"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Project Name</strong>
                              </td>
                              <td>
                                <Link
                                  to={`/projectportal/projects/details/${props.TimeSheetDetails && props.TimeSheetDetails.projectId}`}
                                >
                                  {props.TimeSheetDetails && props.TimeSheetDetails.projectName || "N/A"}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Task Name</strong>
                              </td>
                              <td>
                                <Link
                                  to={`/projectportal/tasks/details/${props.TimeSheetDetails && props.TimeSheetDetails.taskId}`}
                                >
                                  {props.TimeSheetDetails && props.TimeSheetDetails.taskName || "N/A"}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Scope</strong>
                              </td>
                              <td>{props.TimeSheetDetails && props.TimeSheetDetails.scopeName || "N/A"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Status</strong>
                              </td>
                              <td>{props.TimeSheetDetails && props.TimeSheetDetails.status || 'N/A'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="row pl-4">
                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped table-hover">
                          <tbody>
                            <tr>
                              <td>
                                <strong>Work Hours</strong>
                              </td>
                              <td>{`${props.TimeSheetDetails && props.TimeSheetDetails.workHours} hrs` || "N/A"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Work Date</strong>
                              </td>
                              <td>{props.TimeSheetDetails && props.TimeSheetDetails.workDate ? moment(props.TimeSheetDetails && props.TimeSheetDetails.workDate).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Created Date</strong>
                              </td>
                              <td>{props.TimeSheetDetails && props.TimeSheetDetails.createdDateTime ? moment(props.TimeSheetDetails && props.TimeSheetDetails.createdDateTime).format('DD MMM YYYY') : 'N/A'}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Created By</strong>
                              </td>
                              <td>{props.TimeSheetDetails && props.TimeSheetDetails.createdBy || 'N/A'}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Reason</strong>
                              </td>
                              <td>{props.TimeSheetDetails && props.TimeSheetDetails.reason || 'N/A'}</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </Col>
              </Row>

            }
          </CardBody>
          <VWModel
            header="UPDATE TIMESHEET"
            isOpen={props.isEditTimeSheetModalOpen}
            handleClose={handleCloseEditTimeSheetModel}
          >
            <EditTimeSheetMaster handleClose={handleCloseEditTimeSheetModel} />
          </VWModel>
        </Card>
      </div >
    </React.Fragment >
  )
}
const mapStateToProps = (state) => ({
  TimeSheetDetails: state.TimeSheets.timeSheetDetails,
  isEditTimeSheetModalOpen: state.TimeSheets.isEditTimeSheetModalOpen,
  isTimesheetDetailsPending: state.TimeSheets.isTimesheetDetailsPending,
  timesheetDetailsErrorMessage: state.TimeSheets.timesheetDetailsErrorMessage
});

const mapDispatchToProps = (dispatch) => ({
  getTimeSheetDetailsAction: (Id) => dispatch(getTimeSheetDetails(Id)),
  openEditTimeSheetModalAction: () => dispatch(openEditTimeSheetModal()),
  closeEditTimeSheetModalAction: () => dispatch(closeEditTimeSheetModal()),
  ClearPath: () => dispatch(clearSetPath()),
  clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});
export default connect(mapStateToProps, mapDispatchToProps)(TimeSheetDetails)