import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Row
} from 'reactstrap'
import Async from "react-select/async"
import {
  clearUpdateVisitError,
  getVisitNameAsyncSelect,
  getVisitPrimaryContactAsyncSelect,
  getVisitTypeAsyncSelect,
  UpdateVisit,
  updateVisitSuccess
} from '../../../../store/Modules/crm/Visit/actions';
import Select from 'react-select';
import moment from 'moment-timezone';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import {
  HOURS,
  MINUTES,
  TIME_POSTFIX
} from '../../../../store/Modules/crm/Visit/constants';

const visitStatusOptions = [
  { label: 'Completed', value: 'Completed' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'In Progress', value: 'InProgress' },
]
const transportModeOptions = [
  { label: 'Bike', value: 'Bike' },
  { label: 'Bus', value: 'Bus' },
  { label: 'Car', value: 'Car' },
  { label: 'Flight', value: 'Flight' },
  { label: 'Train', value: 'Train' },
  { label: 'Others', value: 'Others' },
]

const removeSymbols = {
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const Edit = (props) => {

  const { currentProfile, visitDetails, isVisitUpdatePending, errorMessage } = props;
  const { visitSourceCategory, visitSourceName, visitSourceId } = visitDetails;
  const [formFields, setFormFields] = useState({});
  const [errors, setErrors] = useState();
  const [isAllDayEvent, setIsAllDayEvent] = useState(true);
  const [keyChange, setKeyChange] = useState(1);
  const [visitNameId, setVisitNameId] = useState();
  const [existingVisitType, setExistingVisitType] = useState();
  const [existingVisitName, setExistingVisitName] = useState();
  const [existingVisitContact, setExistingVisitContact] = useState();
  const [existingVisitStatus, setExistingVisitStatus] = useState();
  const [existingtransport, setExistingtransport] = useState();

  useEffect(() => {
    props.clearUpdateVisitError();
    props.updateVisitSuccess();

    if (visitDetails) {
      if (visitDetails.startTime && visitDetails.endTime) {
        const startTimeFormat = visitDetails.startTime.split(':');
        const startTimeMinutesFormat = startTimeFormat && startTimeFormat[1].split(' ');
        const endTimeFormat = visitDetails.endTime.split(':');
        const endTimeMinutesFormat = endTimeFormat && endTimeFormat[1].split(' ');

        setFormFields({
          ...visitDetails,
          startHour: startTimeFormat[0],
          startMinute: startTimeMinutesFormat[0],
          startPrefix: startTimeMinutesFormat[1],
          endHour: endTimeFormat[0],
          endMinute: endTimeMinutesFormat[0],
          endPrefix: endTimeMinutesFormat[1]
        });
      }
      else {
        setFormFields({ ...visitDetails, })
      }

      setIsAllDayEvent(visitDetails && visitDetails.isAllDayEvent)
      setExistingVisitName({ label: `${visitSourceCategory} - ${visitSourceName}` || 'Select your visit name', value: visitSourceId });
      if (visitSourceId) {
        setKeyChange(keyChange + 1);
        setVisitNameId(visitSourceId);
      }
      setExistingVisitType({ label: visitDetails && visitDetails.visitTypeName || 'Select your visit type', value: visitDetails && visitDetails.visitTypeId });
      setExistingVisitStatus({ label: visitDetails && visitDetails.visitStatus || 'Select your visit status', value: visitDetails && visitDetails.visitStatus });
      setExistingtransport({ label: visitDetails && visitDetails.transportMode || 'Select your visit status', value: visitDetails && visitDetails.transportMode });
      setExistingVisitContact({ label: `${visitDetails && visitDetails.primaryContactName} - ${visitDetails && visitDetails && visitDetails.primaryContactEmail}` || 'Select your primary contact', value: visitDetails && visitDetails.primaryContactId || '' });
    }
  }, [])

  const handleVisitType = (inputValue, callback) => {
    const { getVisitTypeAsyncSelect } = props;
    return getVisitTypeAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
  }
  const handleVisitName = (inputValue, callback) => {
    const { getVisitNameAsyncSelect } = props;
    return getVisitNameAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
  }
  const handleVisitPrimaryContact = (inputValue, callback) => {
    const { getVisitPrimaryContactAsyncSelect } = props;
    console.log("visitNameId:", visitNameId);

    if (visitNameId) {
      return getVisitPrimaryContactAsyncSelect(visitNameId, inputValue.length > 0 ? inputValue : '', callback);
    } else {
      return getVisitPrimaryContactAsyncSelect('', inputValue.length > 0 ? inputValue : '', callback);
    }
  }

  //-------------------------------------Status Change----------------------------------------------------------
  const handleActiveStatusChange = () => {
    console.log("isAllDayEvent:", isAllDayEvent);

    setIsAllDayEvent(!isAllDayEvent);
    if (!isAllDayEvent) {
      setFormFields({ ...formFields, isAllDayEvent: !isAllDayEvent, startTime: '', endTime: '' });
    } else {
      setFormFields({ ...formFields, isAllDayEvent: !isAllDayEvent });
    }
    setErrors({
      ...errors,
      endTime: null,
      startTime: null
    })
  };

  //---------------------------------------- Validate OnChange---------------------------------------------------------
  const validateOnchange = (e) => {
    const { name, value } = e.target;
    setFormFields({
      ...formFields,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: null
    })
  }

  //--------------------------------------------------Visit Type-------------------------------------------------------------
  const handleVisitTypeSelect = (value) => {
    console.log("value", value);

    setExistingVisitType({ label: value.label, value: value.value });
    setFormFields({
      ...formFields,
      visitTypeId: value.value,
    })
    setErrors({
      ...errors,
      visitTypeId: null
    })
  }

  //---------------------------------------------------------Visit Status ----------------------------------------------------
  const handleChangeVisitStatusSelect = (value) => {
    if (value ===null){
      setExistingVisitStatus(value);
      setFormFields({
        ...formFields,
        visitStatus:null
      })
    }else{
      setExistingVisitStatus({ label: value.label, value: value.value });
      setFormFields({
        ...formFields,
        visitStatus: value && value.value
      })
      setErrors({
        ...errors,
        visitStatus: null
      })
    }
  }

  //----------------------------------------------------------------------------------------------------------------------
  const handleVisitNameSelect = (value) => {
    console.log("value", value);
    setExistingVisitName({ label: `${value && value.sourceCategory} - ${value && value.sourceName}`, value: value && value.value });
    setFormFields({
      ...formFields,
      visitSourceId: value.value || '',
      visitSourceCategory: value.sourceCategory || '',
      visitSourceName: value.sourceName || '',
      visitAddress: (value && value.Address) || '',
      primaryContactId: ''
    })

    setExistingVisitContact({ label: 'Select your primary contact', value: '' })
    setKeyChange(keyChange + 1);
    setVisitNameId(value.value);
    setErrors({
      ...errors,
      visitSourceId: '',
      visitAddress: ''
    })
  }
  const handlePrimaryContactSelect = (value) => {
    console.log("value", value);
    setExistingVisitContact({ label: value.label, value: value.value });
    setFormFields({
      ...formFields,
      primaryContactId: value.value || ''

    })
    setErrors({
      ...errors,
      primaryContactId: ''
    })
  }

  //----------------------------------------Update Date Field--------------------------------------------------------
  const validateOnChangeDate = (e) => {
    const { name, value } = e.target;
    // const formattedDate = value ? moment(value).format() : null;
    const formattedDate = value;
    setFormFields({ ...formFields, [name]: formattedDate });
    if (name === 'startDate') {
      setErrors({ ...errors, startDate: '' });
    }
    if (name === 'endDate') {
      setErrors({ ...errors, endDate: '' });
    }
  };
  const validateOnChangeTime = (options, name) => {
    const { value } = options || {};
    setFormFields({ ...formFields, [name]: value });
    setErrors({ ...errors, [name]: false });
  }
  useEffect(() => {
    const { startHour, startMinute, startPrefix, endHour, endMinute, endPrefix } = formFields || {};
    if (startHour && startMinute && startPrefix) {
      setErrors({ ...errors, startTime: null });
    }
    if (endHour && endMinute && endPrefix) {
      setErrors({ ...errors, endTime: null });
    }
  }, [formFields])
  //-----------------------------------------------------Transport Mode------------------------------------------------------
  const handleChangeTransportModeSelect = (value) => {
    setExistingtransport({ label: value.label, value: value.value });
    setFormFields({
      ...formFields,
      transportMode: value && value.value
    })
    setErrors({
      ...errors,
      transportMode: null
    })
  }

  //----------------------------------------------Update visit ------------------------------------------------------
  const handleVisitUpdate = (e) => {
    const { startHour, startMinute, startPrefix, endPrefix, endMinute, endHour, subject, visitTypeId, visitSourceId,
      primaryContactId, startDate, endDate, distance, visitAddress, transportMode } = formFields || {};
    let currentErrors = {};

    if (!subject) {
      currentErrors.subject = "Subject is required";
    }
    if (!visitTypeId) {
      currentErrors.visitTypeId = "Visit type is required";
    }
    if (!visitSourceId) {
      currentErrors.visitSourceId = "Visit name is required";
    }
    if (!primaryContactId) {
      currentErrors.primaryContactId = "Primary contact is required";
    }
    if (!startDate) {
      currentErrors.startDate = "Start date is required";
    }
    if (!endDate) {
      currentErrors.endDate = "End date is required";
    }
    if (!visitAddress) {
      currentErrors.visitAddress = "Address is required";
    }
    if (!distance) {
      currentErrors.distance = "Distance is required";
    }
    if (!transportMode) {
      currentErrors.transportMode = "Transport mode is required";
    }
    if (!isAllDayEvent) {
      if (!startPrefix || !startMinute || !startHour) {
        currentErrors.startTime = "Start time is required";
      }
      if (!startPrefix) {
        currentErrors.startPrefix = true;
      }
      if (!startMinute) {
        currentErrors.startMinute = true;
      }
      if (!startHour) {
        currentErrors.startHour = true;
      }
      if (!endPrefix || !endMinute || !endHour) {
        currentErrors.endTime = "End time is required";
      }
      if (!endPrefix) {
        currentErrors.endPrefix = true;
      }
      if (!endMinute) {
        currentErrors.endMinute = true;
      }
      if (!endHour) {
        currentErrors.endHour = true;
      }
    }

    setErrors(currentErrors);

    if (Object.keys(currentErrors).length === 0) {
      console.log("update:", formFields);
      props.UpdateVisit(formFields);
    }
    e.preventDefault();
  }
  //-----------------------------------------------------------------------------------------------------------------------------
  console.log("currentProfile", currentProfile);
  console.log("formFields", formFields);

  return (
    <div>
      <Form>
        {errorMessage &&
          (<VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />)
        }
        <div className="fw-bold text-uppercase fs-5 text-dark border-start border-primary border-5 mb-3"> &nbsp;Visit Information</div>
        <Row>
          <Col lg="4">
            <FormGroup>
              <Label>Visit Name <span className="text-danger">*</span></Label>
              <Async
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 30,
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: (errors && errors.visitSourceId) ? 'red' : "silver"
                  })
                }}
                name="visitSourceId"
                defaultOptions
                placeholder="Select your visit name"
                loadOptions={handleVisitName}
                onChange={handleVisitNameSelect}
                value={existingVisitName}
              />
              {(errors && errors.visitSourceId) &&
                <FormText sx={{ marginLeft: "10px" }}>
                  <div className="text-danger">
                    {errors && errors.visitSourceId}
                  </div>
                </FormText>
              }
            </FormGroup>
          </Col >
          <Col lg="4">
            <FormGroup>
              <Label>Primary Contact <span className="text-danger">*</span></Label>
              <Async
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 30,
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: (errors && errors.primaryContactId) ? 'red' : "silver"
                  })
                }}
                name="primaryContactId"
                key={keyChange}
                defaultOptions
                placeholder="Select your primary contact"
                loadOptions={handleVisitPrimaryContact}
                onChange={handlePrimaryContactSelect}
                value={existingVisitContact}
              />
              {(errors && errors.primaryContactId) &&
                <FormText sx={{ marginLeft: "10px" }}>
                  <div className="text-danger">
                    {errors && errors.primaryContactId}
                  </div>
                </FormText>
              }
            </FormGroup>
          </Col >
          <Col lg="4">
            <FormGroup>
              <Label>Subject <span className="text-danger">*</span></Label>
              <Input
                type="text"
                name="subject"
                placeHolder="Enter your subject"
                onChange={validateOnchange}
                value={formFields && formFields.subject}
                invalid={errors && errors.subject}
              />
              <FormFeedback>{errors && errors.subject}</FormFeedback>
            </FormGroup>
          </Col >
        </Row>
        <Row >
          <Col lg="4">
            <FormGroup>
              <Label>Visit Type <span className="text-danger">*</span></Label>
              <Async
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: (errors && errors.visitTypeId) ? 'red' : "silver"
                  })
                }}
                name="visitTypeId"
                defaultOptions
                placeholder="Select your visit type"
                loadOptions={handleVisitType}
                onChange={handleVisitTypeSelect}
                value={existingVisitType}
              />
              {(errors && errors.visitTypeId) &&
                <FormText sx={{ marginLeft: "10px" }}>
                  <div className="text-danger">
                    {errors && errors.visitTypeId}
                  </div>
                </FormText>
              }
            </FormGroup>
          </Col >
          <Col lg="4">
            <FormGroup>
              <Label>Visit User</Label>
              <Input
                type="text"
                name="visitUser"
                placeHolder="Enter your visit user"
                onChange={validateOnchange}
                value={formFields && formFields.ownerUserEmail}
                disabled
              />
            </FormGroup>
          </Col >
          <Col lg='4'>
            <FormGroup>
              <Label>Visit Status</Label>
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: (errors && errors.visitStatus) ? 'red' : "silver"
                  })
                }}
                name='visitStatus'
                placeholder='Select your visit status'
                options={visitStatusOptions}
                onChange={handleChangeVisitStatusSelect}
                value={existingVisitStatus}
                isClearable
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg='4'>
            <FormGroup>
              <Label>Is All Day Event</Label>
              <ul className="form-unstyled-list form-status-list">
                <li
                  onClick={handleActiveStatusChange}
                  className={`form-status-item ${!isAllDayEvent ? 'active' : ''}`}
                >
                  No
                </li>
                <li
                  onClick={handleActiveStatusChange}
                  className={`form-status-item ${isAllDayEvent ? 'active' : ''}`}
                >
                  Yes
                </li>
              </ul>
            </FormGroup>
          </Col>
          <Col lg='4' className={!isAllDayEvent && 'd-flex flex-wrap'}>
            <FormGroup className={!isAllDayEvent && 'me-4'}>
              <Label>Start Date <span className="text-danger">*</span></Label>
              <Input
                type="date"
                name="startDate"
                placeholder="Select Start Date"
                onChange={validateOnChangeDate}
                invalid={errors && errors.startDate}
                value={moment(formFields.startDate).format("YYYY-MM-DD")}
                max={moment(formFields.endDate).format("YYYY-MM-DD")}
              />
              {(errors && errors.startDate) &&
                <FormText sx={{ marginLeft: "10px" }}>
                  <div className="text-danger">
                    {errors && errors.startDate}
                  </div>
                </FormText>
              }
            </FormGroup>
            {!isAllDayEvent &&
              <FormGroup>
                <Label>Start Time <span className="text-danger">*</span></Label>
                <div className='d-flex align-items-center flex-wrap'>
                  <Select
                    styles={{
                      ...removeSymbols,
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: (errors && errors.startHour) ? 'red' : "silver"
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 20,
                        minWidth: '50px'
                      }),
                    }}
                    name="startHour"
                    options={HOURS}
                    placeholder="HH"
                    menuPlacement='bottom'
                    onChange={(options) => validateOnChangeTime(options, 'startHour')}
                    value={formFields.startHour ? { label: formFields.startHour, value: formFields.startHour } : null}
                  />
                  <div className='fs-4 fw-bold'>&nbsp;:&nbsp;</div>
                  <Select
                    name="startMinute"
                    styles={{
                      ...removeSymbols,
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: (errors && errors.startMinute) ? 'red' : "silver",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 20,
                        minWidth: '50px'
                      }),
                    }}
                    placeholder="MM"
                    options={MINUTES}
                    menuPlacement='bottom'
                    onChange={(options) => validateOnChangeTime(options, 'startMinute')}
                    value={formFields.startMinute ? { label: formFields.startMinute, value: formFields.startMinute } : null}
                  />
                  &nbsp;&nbsp;
                  <Select
                    styles={{
                      ...removeSymbols,
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: (errors && errors.startPrefix) ? 'red' : "silver"
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 20,
                        minWidth: '50px'
                      }),
                    }}
                    name="startPrefix"
                    options={TIME_POSTFIX}
                    placeholder="--"
                    menuPlacement='bottom'
                    onChange={(options) => validateOnChangeTime(options, 'startPrefix')}
                    value={formFields.startPrefix ? { label: formFields.startPrefix, value: formFields.startPrefix } : null}
                  />
                </div>
                <FormText sx={{ marginLeft: "10px" }}>
                  <div className="text-danger">{errors && errors.startTime}</div>
                </FormText>
              </FormGroup>
            }
          </Col>
          <Col lg='4' className={!isAllDayEvent && 'd-flex flex-wrap'}>
            <FormGroup className={!isAllDayEvent && 'me-4'}>
              <Label>End Date <span className="text-danger">*</span></Label>
              <Input
                type="date"
                name="endDate"
                placeholder="Select End Date"
                onChange={validateOnChangeDate}
                invalid={errors && errors.endDate}
                min={moment(formFields.startDate).format("YYYY-MM-DD")}
                value={moment(formFields.endDate).format("YYYY-MM-DD")}
              />
              {(errors && errors.endDate) &&
                <FormText sx={{ marginLeft: "10px" }}>
                  <div className="text-danger">
                    {errors && errors.endDate}
                  </div>
                </FormText>
              }
            </FormGroup>
            {!isAllDayEvent &&
              <FormGroup>
                <Label>End Time <span className="text-danger">*</span></Label>
                <div className='d-flex align-items-center flex-wrap'>
                  <Select
                    styles={{
                      ...removeSymbols,
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: (errors && errors.endHour) ? 'red' : "silver"
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 20,
                        minWidth: '50px'
                      }),
                    }}
                    name="endHour"
                    options={HOURS}
                    placeholder="HH"
                    menuPlacement='bottom'
                    onChange={(options) => validateOnChangeTime(options, 'endHour')}
                    value={formFields.endHour ? { label: formFields.endHour, value: formFields.endHour } : null}
                  />
                  <div className='fs-4 fw-bold'>&nbsp;:&nbsp;</div>
                  <Select
                    name="endMinute"
                    styles={{
                      ...removeSymbols,
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: (errors && errors.endMinute) ? 'red' : "silver",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 20,
                        minWidth: '50px'
                      }),
                    }}
                    placeholder="MM"
                    options={MINUTES}
                    menuPlacement='bottom'
                    onChange={(options) => validateOnChangeTime(options, 'endMinute')}
                    value={formFields.endMinute ? { label: formFields.endMinute, value: formFields.endMinute } : null}
                  />
                  &nbsp;&nbsp;
                  <Select
                    styles={{
                      ...removeSymbols,
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: (errors && errors.endPrefix) ? 'red' : "silver"
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 20,
                        minWidth: '50px'
                      }),
                    }}
                    name="endPrefix"
                    options={TIME_POSTFIX}
                    placeholder="--"
                    menuPlacement='bottom'
                    onChange={(options) => validateOnChangeTime(options, 'endPrefix')}
                    value={formFields.endPrefix ? { label: formFields.endPrefix, value: formFields.endPrefix } : null}
                  />
                </div>
                {/* {(errors && errors.startDate) && */}
                <FormText sx={{ marginLeft: "10px" }}>
                  <div className="text-danger">
                    {errors && errors.endTime}
                  </div>
                </FormText>
                {/* } */}
              </FormGroup>
            }
          </Col>
        </Row>

        <div className="fw-bold text-uppercase fs-5 text-dark border-start border-success border-5 my-3"> &nbsp;Address Information</div>
        <Row>
          <Col lg="8">
            <FormGroup>
              <Label>Address <span className="text-danger">*</span></Label>
              <Input
                type="textarea"
                name="visitAddress"
                placeHolder="Enter your address"
                onChange={validateOnchange}
                value={formFields && formFields.visitAddress}
                invalid={errors && errors.visitAddress}
              />
              <FormFeedback>{errors && errors.visitAddress}</FormFeedback>
            </FormGroup>
          </Col >
          <Col lg='3'>
            <FormGroup>
              <Label>Distance <span className='text-danger'>*</span></Label>
              <InputGroup>
                <Input
                  onKeyDown={
                    (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                  }
                  onWheel={(e) => e.target.blur()}
                  min='0'
                  type="number"
                  className='border-end-0'
                  name="distance"
                  placeholder="Enter your distance"
                  onChange={validateOnchange}
                  invalid={errors && errors.distance}
                  value={formFields.distance}
                />
                <Button
                  disabled
                  style={errors && errors.distance ?
                    {
                      borderColor: 'red',
                      borderRadius: "0px 5px 5px 0px",
                      borderLeft: 'none'

                    }
                    : {
                      borderRadius: "0px 5px 5px 0px",
                      borderColor: 'silver',
                    }
                  }
                  className='bg-white border-start-0'
                >
                  <div className='text-dark fs-5 fw-bold '> KM </div>
                </Button>
                <FormFeedback>{errors && errors.distance}</FormFeedback>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg='4'>
            <FormGroup>
              <Label>Start Location </Label>
              <Input
                type="text"
                name="startLocation"
                placeholder="Select your start location"
                onChange={validateOnchange}
                value={formFields.startLocation}
              />
            </FormGroup>
          </Col>
          <Col lg='4'>
            <FormGroup>
              <Label>End Location </Label>
              <Input
                type="text"
                name="endLocation"
                placeholder="Select your end location"
                onChange={validateOnchange}
                value={formFields.endLocation}
              />
            </FormGroup>
          </Col>
          <Col lg='4'>
            <FormGroup>
              <Label>Transport Mode <span className='text-danger'>*</span></Label>
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: (errors && errors.transportMode) ? 'red' : "silver"
                  })
                }}
                name='transportMode'
                placeholder='Select your transport mode'
                options={transportModeOptions}
                onChange={handleChangeTransportModeSelect}
                value={existingtransport}
              />
              {(errors && errors.transportMode) &&
                <FormText sx={{ marginLeft: "10px" }}>
                  <div className="text-danger">
                    {errors && errors.transportMode}
                  </div>
                </FormText>
              }
            </FormGroup>
          </Col>
        </Row>
        <div className="fw-bold text-uppercase fs-5 text-dark border-start border-danger border-5 mb-3"> &nbsp;Description Information</div>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Visit Notes</Label>
              <Input
                type="textarea"
                name="visitNotes"
                placeHolder="Enter your visit notes"
                onChange={validateOnchange}
                value={formFields && formFields.visitNotes}
              />
            </FormGroup>
          </Col >
        </Row>
        <hr className="mt-0" />
        <FormGroup>
          <Button
            className="me-2"
            color="success"
            onClick={handleVisitUpdate}
            disabled={isVisitUpdatePending}
          >
            {isVisitUpdatePending ? <>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              &nbsp; Submitting...
            </>
              : <>Submit</>}
          </Button>
          <Button
            color="danger"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
        </FormGroup>
      </Form >
    </div >
  )
}

const mapStateToProps = (state) => ({

  errorMessage: state.CrmVisit.visitUpdateErrorMessage,
  isVisitUpdatePending: state.CrmVisit.isVisitUpdatePending,
  currentProfile: state.login.currentProfile,
})
const mapDispatchToProps = (dispatch) => ({
  getVisitTypeAsyncSelect: (searchValue, callback) => dispatch(getVisitTypeAsyncSelect(searchValue, callback)),
  getVisitNameAsyncSelect: (searchValue, callback) => dispatch(getVisitNameAsyncSelect(searchValue, callback)),
  getVisitPrimaryContactAsyncSelect: (visitNameId, searchValue, callback) => dispatch(getVisitPrimaryContactAsyncSelect(visitNameId, searchValue, callback)),
  UpdateVisit: (data) => dispatch(UpdateVisit(data)),
  clearUpdateVisitError: () => dispatch(clearUpdateVisitError()),
  updateVisitSuccess: () => dispatch(updateVisitSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)