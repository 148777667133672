import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import { clearUpdateTaskTypeError, updateTaskType, updateTaskTypeSuccess } from '../../../../../../store/Modules/ProjectPortal/Settings/TaskType/actions';


const EditTaskType = (props) => {

    const { taskTypeDetails, isTaskTypeUpdatePending, errorMessage } = props;
    const { Id, TypeName, IsActive } = taskTypeDetails || {};

    const [typeDetails, setTypeDetails] = useState();
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();

    useEffect(() => {
        setTypeDetails({
            ...typeDetails,
            id: Id,
            TypeName: TypeName,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateTaskTypeError();
        props.updateTaskTypeSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setTypeDetails({
            ...typeDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        });
    }
    const handleActiveStatusChange = () => {
        setTypeDetails({
            ...typeDetails,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { TypeName } = typeDetails || {};

        if (!TypeName) {
            currentErrors.TypeName = 'Type name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateTaskType(typeDetails)
        }
    }

  return (
      <>
          {errorMessage && (
              <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
          )}
          <Form>
              <FormGroup>
                  <Label> Type Name <span className='text-danger'>*</span></Label>
                  <Input
                      type="text"
                      name="TypeName"
                      placeHolder="Enter type name"
                      onChange={validateOnchange}
                      value={typeDetails && typeDetails.TypeName}
                      invalid={errors && errors.TypeName}
                  />
                  <FormFeedback>{errors && errors.TypeName}</FormFeedback>
              </FormGroup>
              <FormGroup>
                  <Label className="col-form-label">Status</Label>
                  <ul className="form-unstyled-list form-status-list">
                      <li
                          onClick={handleActiveStatusChange}
                          className={`form-status-item ${!Active ? 'active' : ''}`}
                      >
                          Inactive
                      </li>
                      <li
                          onClick={handleActiveStatusChange}
                          className={`form-status-item ${Active ? 'active' : ''}`}
                      >
                          Active
                      </li>
                  </ul>
              </FormGroup>
              <FormGroup>
                  <Button
                      color='success'
                      className='me-2'
                      onClick={handleSubmit}
                      disabled={isTaskTypeUpdatePending}
                  >
                      {isTaskTypeUpdatePending ?
                          <div>
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                              Submitting...
                          </div>
                          : "Submit"}
                  </Button>
                  <Button
                      color='danger'
                      onClick={props.handleClose}
                  >Cancel
                  </Button>
              </FormGroup>
          </Form>
      </>
  )
}

const mapStateToProps = (state) => ({
    taskTypeDetails: state.TaskType.taskTypeDetails,
    isTaskTypeUpdatePending: state.TaskType.isTaskTypeUpdatePending,
    errorMessage: state.TaskType.taskTypeUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateTaskType: (typeDetails) => dispatch(updateTaskType(typeDetails)),
    clearUpdateTaskTypeError: () => dispatch(clearUpdateTaskTypeError()),
    updateTaskTypeSuccess: () => dispatch(updateTaskTypeSuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(EditTaskType)