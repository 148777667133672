import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearUpdateOpportunityCurrentPhaseError,
    updateOpportunityCurrentPhase,
    updateOpportunityCurrentPhaseSuccess
} from '../../../../../../store/Modules/crm/Settings/OpportunityCurrentPhase/actions';

const EditOpportunityType = (props) => {
    const { opportunityCurrentPhaseDetails, isOpportunityCurrentPhaseListPending, errorMessage } = props;
    const { Id, PhaseName, IsActive, Value } = opportunityCurrentPhaseDetails || {};

    const [oppCurrentPhaseDetails, setOppCurrentPhaseDetails] = useState();
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();

    useEffect(() => {
        setOppCurrentPhaseDetails({
            ...oppCurrentPhaseDetails,
            id: Id,
            phaseName: PhaseName,
            isActive: IsActive,
            value: Value
        });
        setActive(IsActive)
        props.clearUpdateOpportunityCurrentPhaseError();
        props.updateOpportunityCurrentPhaseSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setOppCurrentPhaseDetails({
            ...oppCurrentPhaseDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        });
    }
    const handleActiveStatusChange = () => {
        setOppCurrentPhaseDetails({
            ...oppCurrentPhaseDetails,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { phaseName, value } = oppCurrentPhaseDetails || {};

        if (!phaseName) {
            currentErrors.phaseName = 'Opportunity current phase name is required';
        }
        if (!value) {
            currentErrors.value = 'Opportunity current phase percentage is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateOpportunityCurrentPhase(oppCurrentPhaseDetails)
        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label>Opportunity Current Phase Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="phaseName"
                        placeHolder="Enter opportunity current phase name"
                        onChange={validateOnchange}
                        value={oppCurrentPhaseDetails && oppCurrentPhaseDetails.phaseName}
                        invalid={errors && errors.phaseName}
                    />
                    <FormFeedback>{errors && errors.phaseName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Opportunity Current Phase Percentage <span className='text-danger'>*</span></Label>
                    <InputGroup>
                        <Input
                            type="number"
                            className='border-end-0'
                            name="value"
                            placeHolder="Enter opportunity current phase percentage"
                            onChange={(e) => {
                                const value = e.target.value;
                                console.log("value:", value);

                                if (value === "") {
                                    validateOnchange(e);
                                } else if (/^(0|0[0-9]|0[1-9][0-9]|[1-9][0-9]?|100)$/.test(value)) {
                                    // Allow "0", "01" to "09", "099", and valid numbers from 1 to 100
                                    validateOnchange(e);
                                } else if (/^00[0-9]*$/.test(value)) {
                                    // Block inputs like "001", "00010", etc.
                                    e.preventDefault();
                                } else {
                                    // Block any other invalid inputs
                                    e.preventDefault();
                                }
                            }}
                            value={oppCurrentPhaseDetails && oppCurrentPhaseDetails.value}
                            invalid={errors && errors.value}
                            onKeyDown={
                                (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                            }
                            onWheel={(e) => e.target.blur()}
                            onBlur={(e) => {
                                let value = parseFloat(e.target.value);
                                if (value < 0) {
                                    e.target.value = 0;
                                } else if (value > 100) {
                                    e.target.value = 100;
                                }
                            }}

                        />
                        <Button
                            disabled
                            style={errors && errors.value ?
                                {
                                    borderColor: 'red',
                                    borderRadius: "0px 5px 5px 0px",
                                    borderLeft: 'none'

                                }
                                : {
                                    borderRadius: "0px 5px 5px 0px",
                                    borderColor: 'silver',
                                }
                            }
                            className='bg-white border-start-0'
                        >
                            <div className='text-dark fs-5 fw-bold'> % </div>
                        </Button>
                        <FormFeedback>{errors && errors.value}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isOpportunityCurrentPhaseListPending}
                    >
                        {isOpportunityCurrentPhaseListPending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form >
        </>
    )
}

const mapStateToProps = (state) => ({
    opportunityCurrentPhaseDetails: state.crmOpportunityCurrentPhase.opportunityCurrentPhaseDetails,
    isOpportunityCurrentPhaseListPending: state.crmOpportunityCurrentPhase.isOpportunityCurrentPhaseListPending,
    errorMessage: state.crmOpportunityCurrentPhase.opportunityCurrentPhaseUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateOpportunityCurrentPhase: (oppCurrentPhaseDetails) => dispatch(updateOpportunityCurrentPhase(oppCurrentPhaseDetails)),
    clearUpdateOpportunityCurrentPhaseError: () => dispatch(clearUpdateOpportunityCurrentPhaseError()),
    updateOpportunityCurrentPhaseSuccess: () => dispatch(updateOpportunityCurrentPhaseSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditOpportunityType);