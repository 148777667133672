import { getToken, modifyOrgListList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CHANGE_PASSWORD_BY_ADMIN_ERROR, CHANGE_PASSWORD_BY_ADMIN_PENDING, CLEAR_CHANGE_PASSWORD_BY_ADMIN_ERROR, CLEAR_CREATE_ORG_USER_ERROR, CLEAR_ORG_USER_DETAILS_ERROR, CLEAR_ORG_USER_EXPORT_LIST_ERROR, CLEAR_ORG_USER_LIST_ERROR, CLEAR_SEND_RESET_PASSWORD_ERROR, CLEAR_UPDATE_ORG_USER_ERROR, CLOSE_CHANGE_PASSWORD_BY_ADMIN_MODAL, CLOSE_CREATE_ORG_USER_MODAL, CLOSE_EDIT_ORG_USER_MODAL, CREATE_ORG_USER_ERROR, CREATE_ORG_USER_PENDING, CREATE_ORG_USER_SUCCESS, OPEN_CHANGE_PASSWORD_BY_ADMIN_MODAL, OPEN_CREATE_ORG_USER_MODAL, OPEN_EDIT_ORG_USER_MODAL, SEND_RESET_PASSWORD_ERROR, SEND_RESET_PASSWORD_PENDING, SEND_RESET_PASSWORD_SUCCESS, SET_ORG_USER_DETAILS, SET_ORG_USER_DETAILS_ERROR, SET_ORG_USER_DETAILS_PENDING, SET_ORG_USER_EXPORT_LIST, SET_ORG_USER_EXPORT_LIST_ERROR, SET_ORG_USER_EXPORT_PENDING, SET_ORG_USER_LIST, SET_ORG_USER_LIST_ERROR, SET_ORG_USER_PENDING, UPDATE_ORG_USER_ERROR, UPDATE_ORG_USER_PENDING, UPDATE_ORG_USER_SUCCESS } from "./constants";


export const openCreateOrgUserModalAction = () => ({
    type: OPEN_CREATE_ORG_USER_MODAL,
});
export const openEditOrgUserModal = () => ({
    type: OPEN_EDIT_ORG_USER_MODAL,
});
export const closeCreateOrgUserModalAction = () => ({
    type: CLOSE_CREATE_ORG_USER_MODAL,
});
export const closeEditOrgUserModal = () => ({
    type: CLOSE_EDIT_ORG_USER_MODAL,
});

export const setOrgUserList = (orgUserList) => ({
    type: SET_ORG_USER_LIST,
    payload: { orgUserList },
})
export const setOrgUserExportList = (orgUserExportList) => ({
    type: SET_ORG_USER_EXPORT_LIST,
    payload: { orgUserExportList },
})
export const clearOrgUserListError = () => ({
    type: CLEAR_ORG_USER_LIST_ERROR,
})
export const clearOrgUserExportListError = () => ({
    type: CLEAR_ORG_USER_EXPORT_LIST_ERROR,
})
export const orgUserListError = (errorMessage) => ({
    type: SET_ORG_USER_LIST_ERROR,
    payload: { errorMessage },
})
export const orgUserExportListError = (errorMessage) => ({
    type: SET_ORG_USER_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const setOrgUserDetails = (OrgUserDetails) => ({
    type: SET_ORG_USER_DETAILS,
    payload: { OrgUserDetails },
})
export const clearOrgDetailsListError = () => ({
    type: CLEAR_ORG_USER_DETAILS_ERROR,
})
export const orgUserDetailsError = (errorMessage) => ({
    type: SET_ORG_USER_DETAILS_ERROR,
    payload: { errorMessage },
})
export const createOrgUserError = (errorMessage) => ({
    type: CREATE_ORG_USER_ERROR,
    payload: { errorMessage },
});
export const createOrgUserPending = () => ({
    type: CREATE_ORG_USER_PENDING,
});
export const clearCreateOrgUserError = () => ({
    type: CLEAR_CREATE_ORG_USER_ERROR,
});
export const createOrgUserSuccess = () => ({
    type: CREATE_ORG_USER_SUCCESS,
});
export const clearUpdateOrgUserError = () => ({
    type: CLEAR_UPDATE_ORG_USER_ERROR,
});
export const updateOrgUserPending = () => ({
    type: UPDATE_ORG_USER_PENDING,
});
export const updateOrgUserSuccess = () => ({
    type: UPDATE_ORG_USER_SUCCESS,
});
export const updateOrgUserError = (errorMessage) => ({
    type: UPDATE_ORG_USER_ERROR,
    payload: { errorMessage },
});
export const setOrganizationUserPending = () => ({
    type: SET_ORG_USER_PENDING,
})
export const setOrganizationUserExportPending = () => ({
    type: SET_ORG_USER_EXPORT_PENDING,
})
export const setOrganizationUserDetailsPending = () => ({
    type: SET_ORG_USER_DETAILS_PENDING,
})

export const getOrgUserExportList = (AppId, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, filter) => (dispatch) => {
    dispatch(setOrganizationUserExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.OrgUser.getOrgUserExportlist(accessToken, AppId, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, filter)
            .then((orgUserList) => {
                // dispatch(setOrgUserExportList(orgUserList));
                dispatch(clearOrgUserExportListError());
                return Promise.resolve(orgUserList);
            })
            .catch((err) => {
                console.log('getOrgUserExportList server error', err);
                let error;
                if (err.status) {
                    // error = err.response && err.response.text;
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgUserExportListError(error))
            });
    });
};
export const getOrganizationUserListAction = (AppId, filterData, page, PageSize, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable) => (dispatch) => {
    dispatch(setOrganizationUserPending());
    getToken(dispatch).then((accessToken) => {
        agent.OrgUser.getOrgUserlist(accessToken, AppId, filterData, page, PageSize, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable)
            .then((orgUserList) => {
                dispatch(setOrgUserList(orgUserList));
                dispatch(clearOrgUserListError());
            })
            .catch((err) => {
                console.log('getOrganizationUserListAction server error', err);
                let error;
                if (err.status) {
                    // error = err.response && err.response.text;
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgUserListError(error))
            });
    });
};

export const getOrgNameAsyncSelect =
    (searchValue, callback, orgName) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.OrgUser.getOrgNameList(accessToken, searchValue)
                    .then((OrganisationList) => {
                        const { value } = OrganisationList;
                        let orgList;
                        orgList = value && value.filter((item) => orgName === item.OrgName)
                        const updatedOrgList = modifyOrgListList(orgList);
                        //eslint-disable-next-line
                        callback && callback(updatedOrgList);
                    })
                    .catch((err) => {
                        console.log('getOrgNameAsyncSelect server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                        //eslint-disable-next-line
                        callback && callback();
                    });
            });
        };



export const getOrgUserDetailsAction = (id) => (dispatch) => {
    dispatch(setOrganizationUserDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.OrgUser.getOrgUserDetails(accessToken, id).then(
            (OrgUserDetails) => {
                dispatch(setOrgUserDetails(OrgUserDetails));
                dispatch(clearOrgDetailsListError());
            })
            .catch((err) => {
                console.log('getOrgUserDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgUserDetailsError(error));
            });
    });
}

export const createOrgUser = (formFields, appId, filterData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createOrgUserError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = {
            ...formFields,
        }
    }
    dispatch(createOrgUserPending());
    dispatch(clearCreateOrgUserError());
    getToken(dispatch).then((accessToken) => {
        agent.OrgUser.createOrgUser(accessToken, dataToSend, appId)
            .then(() => {
                dispatch(createOrgUserSuccess());
                dispatch(clearForm());
                dispatch(closeCreateOrgUserModalAction());
                dispatch(getOrganizationUserListAction(appId, filterData));
                // dispatch(getOrgUserExportList());
                dispatch(showSnackbarStatus('Org User created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createOrgUserError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createOrgUserError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('create OrgUser server error', err);
                dispatch(clearForm());
                dispatch(closeCreateOrgUserModalAction());
            });
    });
};

export const EditOrgUser = (formFields) => (dispatch) => {
    const dataToSend = {
        ...formFields,
        Id: formFields.id
    }
    dispatch(clearUpdateOrgUserError());
    dispatch(updateOrgUserPending());
    getToken(dispatch).then((accessToken) => {
        agent.OrgUser.editOrgUser(accessToken, dataToSend)
            .then(() => {
                dispatch(updateOrgUserSuccess());
                dispatch(closeEditOrgUserModal());
                dispatch(getOrgUserDetailsAction(formFields.id));
                dispatch(showSnackbarStatus('Organization User details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateOrgUserError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateOrgUserError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Edit Organization User server error', err);
                dispatch(updateOrgUserError(null));
                dispatch(closeEditOrgUserModal());
            });
    });
};

//----------------------------------------RESET-PASSWORD--------------------------------------------------------------------------------------------
export const sendResetPasswordPending = () => ({
    type: SEND_RESET_PASSWORD_PENDING,
});

export const sendResetPasswordSuccess = () => ({
    type: SEND_RESET_PASSWORD_SUCCESS,
});

export const sendResetPasswordError = (errorMessage) => ({
    type: SEND_RESET_PASSWORD_ERROR,
    payload: { errorMessage }
});
export const clearSendResetPasswordError = () => ({
    type: CLEAR_SEND_RESET_PASSWORD_ERROR,
});

export const sendResetPasswordEmail = (email) => (dispatch) => {
    dispatch(sendResetPasswordPending());
    agent.OrgUser.sendResetPasswordEmail(email)
        .then(() => {
            dispatch(sendResetPasswordSuccess());
            dispatch(clearSendResetPasswordError());
            dispatch(showSnackbarStatus("Password reset link sent successfully."));
        })
        .catch((err) => {
            if (err && err.response) {
                const message = err.response && err.response.text;
                console.log("message: ", message)
                dispatch(sendResetPasswordError(message));
            }
            else {
                const message = "Network error";
                dispatch(sendResetPasswordError(message));
                dispatch(showSnackbarStatus("Something went wrong. Please try again later"));
            }
            console.log("setUserDetails server error", err);
        });
};

//----------------------------------------------------CHANGE-PASSWORD---------------------------------------------------------------------------
export const openChangePasswordByAdminModal = () => ({
    type: OPEN_CHANGE_PASSWORD_BY_ADMIN_MODAL,
})
export const closeChangePasswordByAdminModal = () => ({
    type: CLOSE_CHANGE_PASSWORD_BY_ADMIN_MODAL,
})
export const changePasswordPending = () => ({
    type: CHANGE_PASSWORD_BY_ADMIN_PENDING,
})
export const changePasswordError = (errorMessage) => ({
    type: CHANGE_PASSWORD_BY_ADMIN_ERROR,
    payload: { errorMessage }
})
export const clearChangePasswordByAdminError = () => ({
    type: CLEAR_CHANGE_PASSWORD_BY_ADMIN_ERROR,
})

export const changePasswordByAdmin = (formfields) => (dispatch) => {
    if (!formfields) {
        dispatch(changePasswordError("Please fill required details."));
        return;
    }
    const values = trimmedValues(formfields)
    dispatch(changePasswordPending());
    getToken(dispatch).then((accessToken) => {
        agent.OrgUser.changePasswordByAdmin(accessToken, values)
            .then(() => {
                dispatch(clearChangePasswordByAdminError());
                dispatch(clearSendResetPasswordError());
                dispatch(closeChangePasswordByAdminModal());
                dispatch(showSnackbarStatus("Password changed successfully"));
            })
            .catch((err) => {
                if (err && err.response) {
                    const message = err && err.response && err.response.text;
                    console.log("message: ", message)
                    dispatch(changePasswordError(message));
                    return;
                }
                else {
                    const message = "Network error";
                    dispatch(changePasswordError(message));
                    dispatch(showSnackbarStatus("Something went wrong. Please try again later"));
                }
                console.log("changePassword server error", err);
            });
    });
};