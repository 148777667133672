import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Input,
    InputGroup,
    Table
} from "reactstrap";
import {
    clearCampaignListError,
    closeCreateCampaignModal,
    exportCampaignList,
    getCampaignList,
    openCreateCampaignModal,
} from "../../../../store/Modules/MassMailer/Campaign/actions";
import { Link } from "react-router-dom";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWModel from "../../../../components/Modal/VWModal";
import CreateCampaign from "./CreateCampaign";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendarAlt, faFont } from "@fortawesome/free-solid-svg-icons";
import {
    CAMPAIGN_EXPORT_HEADERS,
    CAMPAIGN_STATUS_FILTER_OPTIONS
} from "../../../../store/Modules/MassMailer/Campaign/constants";
import { getPager } from "../../../../helper/common";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import moment from "moment-timezone";
import { excelPrint } from "../../../../helper/export";
import { MAIL_CATEGORY_FILTER_OPTIONS } from "../../../../store/Modules/MassMailer/ContactList/constants";
import Select from 'react-select';
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import '../../viewList.css';
import UITooltip from "../../../../CommonData/Data/UITooltip";

const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const style = {
    table: {
        width: '100%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'separate',
    },
    th: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#fff',
    },
};

const CampaignList = (props) => {
    const { campaignList, exportedCampaignList, currentProfile, isOpenCreateCampaign } = props || {};
    const { userAppDetails } = currentProfile || {};
    let isAdmin;

    const [pageSize] = useState(10);
    const [filter, setFilter] = useState();
    const [pager, setPager] = useState({ currentPage: 1 });
    const [filterSelectionOption, setFilterSelectionOption] = useState('name');


    const crmApps = userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "crm");
    if (crmApps.length > 0) {
        const Roles = crmApps[0];
        const { appRoleDetails } = Roles || {};
        isAdmin = appRoleDetails.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
    }
    //--------------------------------------------------PARAMS-FILTER----------------------------------------------------------------------------------
    const path = window.location.search;
    const params = new URLSearchParams(path);
    const id = params.get('filter')

    useEffect(() => {
        if (id) {
            setFilter({
                ...filter,
                Status: id,
            })
        }
    }, [])

    //-------------------------------------------------FILTER----------------------------------------------------------------------------------------------
    const buildQueryCampaignName = () => {
        const { CampaignName } = filter || {};
        let searchCampaignName = '';
        if (CampaignName) {
            searchCampaignName += `${CampaignName}`;
        }
        return searchCampaignName
    }
    const buildQuerymailCategory = () => {
        const { Type } = filter || {};
        let searchMailCategory = '';
        if (Type) {
            searchMailCategory += `${Type}`;
        }
        return searchMailCategory
    }
    const buildQuerySubject = () => {
        const { Subject } = filter || {};
        let searchSubject = '';
        if (Subject) {
            searchSubject += `${Subject}`;
        }
        return searchSubject
    }
    const buildQueryStatus = () => {
        const { Status } = filter || {};
        let searchStatus = '';
        if (Status) {
            searchStatus += `${Status}`;
        }
        return searchStatus
    }
    const buildQueryEmailAddress = () => {
        const { EmailAddress } = filter || {};
        let searchEmailAddress = '';
        if (EmailAddress) {
            searchEmailAddress += `${EmailAddress}`;
        }
        return searchEmailAddress
    }
    const buildQueryCreatedBy = () => {
        const { CreatedBy } = filter || {};
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }
    const buildQueryCreatedTimeStamp = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedTimeStamp = '';
        if (CreatedTimeStamp) {
            searchCreatedTimeStamp += `${CreatedTimeStamp}`;
        }
        return searchCreatedTimeStamp
    }

    useEffect(() => {
        const currentPager = getPager(props.campaignListCount, 1, pageSize);
        setPager(currentPager)
    }, [props.campaignListCount])

    const setPage = (page = 1) => {
        props.getCampaignList(page, pageSize, buildQueryCampaignName(), buildQueryEmailAddress(), buildQuerymailCategory(), buildQuerySubject(),
            buildQueryStatus(), buildQueryCreatedBy(), buildQueryCreatedTimeStamp(), sortDirection, sortOrder);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.campaignListCount / pageSize);
            const visiblePages = 10;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
                pageSize
            };
        });
    }

    useEffect(() => {
        const currentPageNumber = pager && pager.currentPage
        setPage(currentPageNumber)
    }, [])

    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (e) => {
        const input = e.target;
        const value = input.value;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [input.name]: value
        })
    }
    const searchMailCategoryDD = (Value) => {
        const { value, label } = Value;
        setFilter({
            ...filter,
            Type: value
        })
    }

    const searchCampaignStatusDD = (Value) => {
        const { value, label } = Value;
        setFilter({
            ...filter,
            Status: value
        })
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }

    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }

    // --------------------------------------------------SORTING-----------------------------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedCampaignList = [...campaignList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //-------------------------------------------EXPORTING-DATA--------------------------------------------------------------------------------------
    useEffect(() => {
        exportCampaignDetails();
    }, [props.campaignListCount])

    const exportCampaignDetails = () => {
        props.exportCampaignList(buildQueryCampaignName(), buildQueryEmailAddress(), buildQuerymailCategory(), buildQuerySubject(),
            buildQueryStatus(), buildQueryCreatedBy(), buildQueryCreatedTimeStamp(), sortDirection, sortOrder);
    }

    const handleExports = () => {
        const exportCampaignList = [];
        const headers = CAMPAIGN_EXPORT_HEADERS;
        const headerSizes = [20, 30, 40, 20, 40, 40, 20, 20];
        exportedCampaignList.forEach((element) => {
            exportCampaignList.push({
                campaignName: element.campaignName || "N/A",
                FromDisplayName: element.fromDisplayName || "N/A",
                EmailAddress: element.emailAddress || "N/A",
                Type: element.type || "N/A",
                subject: element.subject || "N/A",
                createdTimeStamp: element.createdTimeStamp ? moment(element.createdTimeStamp).format('DD MMM YYYY') : "NA",
                createdBy: element.createdBy || "NA",
                status: element.status || "N/A",
            });
        });
        excelPrint(exportCampaignList, "Campaign-List", headers, headerSizes);
    }
    //------------------------------------------------------------MODAL OPEN & CLOSE---------------------------------------------------------------------
    const handleOpenCreateCampaign = () => {
        props.openCreateCampaignModal();
    }

    const handleClose = () => {
        props.closeCreateCampaignModal();
        props.clearCampaignError();
    };

    return (
        <>
            <div className="page-content">
                <Card >
                    <CardTitle tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center">
                        <strong className='d-flex align-items-center'>CAMPAIGN LIST -&nbsp;<Badge color="danger">{props.campaignListCount}</Badge></strong>
                        <div className="content-heading d-flex flex-row-reverse">
                            {isAdmin && <div className="ml-auto">
                                <Link
                                    type="button"
                                    className="btn btn-info"
                                    onClick={handleOpenCreateCampaign}
                                >
                                    Create
                                </Link>&nbsp;
                            </div>}
                            {(props.campaignListCount > 0) &&
                                <Button
                                    className="me-4"
                                    color="success"
                                    onClick={handleExports}>
                                    Export
                                </Button>
                            }
                        </div>
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody className="p-4">
                        <div style={{ height: "60vh", overflowX: "auto" }}>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />
                            )}
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                    <tr>
                                        <th onClick={() => handleSort('CampaignName')}>
                                            Campaign Name &nbsp;
                                            {sortOrder === 'CampaignName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('EmailAddress')}>
                                            From Address &nbsp;
                                            {sortOrder === 'EmailAddress' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('Type')}>
                                            Mail Category &nbsp;
                                            {sortOrder === 'Type' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('Subject')}>
                                            Subject &nbsp;
                                            {sortOrder === 'Subject' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        {/* <th onClick={() => handleSort('UnSubscriptionLink')}>
                                            Unsubscribe Link &nbsp;
                                            {sortOrder === 'UnSubscriptionLink' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th> */}
                                        <th onClick={() => handleSort('Status')}>
                                            Status &nbsp;
                                            {sortOrder === 'Status' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('CreatedBy')}>
                                            Created By &nbsp;
                                            {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                            View
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='CampaignName'
                                                onChange={searchInputValidation}
                                                value={filter && filter.CampaignName}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th>
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='EmailAddress'
                                                onChange={searchInputValidation}
                                                value={filter && filter.EmailAddress}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th>
                                            <Select
                                                styles={customStyles}
                                                placeholder='Search'
                                                name="Type"
                                                onChange={searchMailCategoryDD}
                                                options={MAIL_CATEGORY_FILTER_OPTIONS}
                                            />
                                        </th>
                                        <th>
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='Subject'
                                                onChange={searchInputValidation}
                                                value={filter && filter.Subject}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th>
                                            <Select
                                                styles={customStyles}
                                                placeholder='Search'
                                                name="Status"
                                                onChange={searchCampaignStatusDD}
                                                options={CAMPAIGN_STATUS_FILTER_OPTIONS}
                                                value={filter && filter.Status ?
                                                    { label: filter.Status, value: filter.Status }
                                                    : null
                                                }
                                            />
                                        </th>
                                        <th>
                                            <InputGroup style={{ width: "200px" }}>
                                                {filterSelectionOption === 'name' ?
                                                    <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                        <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                    </UITooltip>
                                                    : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                        <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                    </UITooltip>
                                                }
                                                <Input
                                                    style={{ width: "120px" }}
                                                    type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                    placeholder='Search'
                                                    name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                    onChange={searchInputValidation}
                                                    onKeyPress={handleKeyPress}
                                                    value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                                />
                                            </InputGroup>
                                        </th>
                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!props.isCampaignListPending && (sortedCampaignList && sortedCampaignList.length > 0) ?
                                        sortedCampaignList && sortedCampaignList.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data.CampaignName || "N/A"}</td>
                                                <td>
                                                    {data.FromDisplayName || "N/A"}
                                                    <br />
                                                    <small>{data.EmailAddress || "N/A"}</small>
                                                </td>
                                                <td>{data.Type || 'N/A'}</td>
                                                <td>{data.Subject || 'N/A'}</td>
                                                {/* <td>{data.UnSubscriptionLink || 'N/A'}</td> */}
                                                <td className="text-center">
                                                    {data.Status === "Open" ? (
                                                        <Badge color="success">Open</Badge>
                                                    ) : (data.Status === "Closed" ?
                                                        <Badge color="danger">Closed</Badge>
                                                        : 'N/A'
                                                    )}
                                                </td>
                                                <td>
                                                    {data.CreatedBy || "N/A"}<br />
                                                    <small>{data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                                </td>
                                                <td className="sticky-column" style={{ backgroundColor: "white" }}>
                                                    <Link
                                                        type="button"
                                                        className="btn btn-sm  btn-light"
                                                        to={`/massmailer/campaigns/details/${data.Id}`}
                                                    >
                                                        <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                        : props.isCampaignListPending ? (
                                            <tr>
                                                <td colSpan={9}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <VWSpinner />
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>)
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>

                    <CardFooter>
                        {campaignList && campaignList.length > 0 ?
                            <VWPagination
                                pager={pager}
                                setPage={setPage}
                                totalRecords={props.campaignListCount}
                                pageSize={pageSize}
                            />
                            : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="CREATE CAMPAIGN"
                    isOpen={isOpenCreateCampaign}
                    handleClose={handleClose}
                >
                    <CreateCampaign
                        handleClose={handleClose}
                    />
                </VWModel>
            </div >
        </>
    )
}

const mapStateToProps = (state) => ({
    campaignList: state.campaign.campaignList,
    campaignListCount: state.campaign.campaignListCount,
    exportedCampaignList: state.campaign.exportedCampaignList,
    isCampaignListPending: state.campaign.isCampaignListPending,
    isOpenCreateCampaign: state.campaign.isOpenCreateCampaign,
    errorMessage: state.campaign.campaignListErrorMessage,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    getCampaignList: (page, pageSize, searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) =>
        dispatch(getCampaignList(page, pageSize, searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder)),
    exportCampaignList: (searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) =>
        dispatch(exportCampaignList(searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder)),
    openCreateCampaignModal: () => dispatch(openCreateCampaignModal()),
    closeCreateCampaignModal: () => dispatch(closeCreateCampaignModal()),
    clearCampaignError: () => dispatch(clearCampaignListError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);