const ProfileSidebarData = [
    {
        label: "Profile Management",
        isMainMenu: true,
    },
    {
        label: "User Profile",
        icon: "mdi mdi-account-outline",
        isHasArrow: true,
        url: "/profile",
    },

]
export default ProfileSidebarData;