import React, { useEffect, useState } from 'react'
import { Button, FormGroup, Input, Label, Form, FormFeedback } from 'reactstrap'
import VWMessage from '../../../../../../components/vwMessage/vwMessage'
import { connect } from 'react-redux';
import { clearCreateTaskScopeError, createNewTaskScope, createTaskScopeSuccess } from '../../../../../../store/Modules/ProjectPortal/Settings/TaskScope/actions';

const AddTaskScope = (props) => {
    const { isTaskScopeCreatePending, errorMessage } = props;

    const [scopeDetails, setScopeDetails] = useState();
    const [errors, setErrors] = useState();

    const { userAppDetails } = props.currentProfile || {};
    const App = userAppDetails.filter((i) => {
        return (i.appName === "ProjectPortal") ? i.appId : ""
    })


    useEffect(() => {
        props.clearCreateTaskScopeError();
        props.createTaskScopeSuccess();
        setScopeDetails({ ...scopeDetails, isActive: true });
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setScopeDetails({
            ...scopeDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { scopeName } = scopeDetails || {};

        if (!scopeName) {
            currentErrors.scopeName = 'scope name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createNewTaskScope(scopeDetails, App[0] && App[0].appId || '')
        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label> Scope Name <span className='text-danger'>*</span></Label>
                <Input
                    type="text"
                    name="scopeName"
                    placeHolder="Enter scope name"
                    onChange={validateOnchange}
                    value={scopeDetails && scopeDetails.scopeName}
                    invalid={errors && errors.scopeName}
                />
                <FormFeedback>{errors && errors.scopeName}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isTaskScopeCreatePending}
                >
                    {isTaskScopeCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    isTaskScopeCreatePending: state.TaskScope.isTaskScopeCreatePending,
    errorMessage: state.TaskScope.TaskScopeCreateErrorMessage,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    createNewTaskScope: (scopeDetails, appId) => dispatch(createNewTaskScope(scopeDetails, appId)),
    clearCreateTaskScopeError: () => dispatch(clearCreateTaskScopeError()),
    createTaskScopeSuccess: () => dispatch(createTaskScopeSuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(AddTaskScope)