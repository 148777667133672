import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Card,
    Form,
    FormGroup,
    Input,
    Label
} from "reactstrap";
import { submitUnsubscribe } from "../../../../store/Modules/MassMailer/Unsubscribe/actions";
import unsubscribeImag from '../../../../assets/images/Mass-Mailer/unsubscribe-image.gif'
import SuccessfullyUnsubscribeImag from '../../../../assets/images/Mass-Mailer/successfully-unsubscribed.gif'
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";

const Unsubscribe = (props) => {
    const { mail, unsubscribedSuccesss, isSubmitUnsubscribePending } = props;
    const { email, id } = mail || {};
    const [selectedOption, setSelectedOption] = useState();
    const [mailData, setMailData] = useState();

    useEffect(() => {
        setMailData({
            id: id,
        })
    }, [id])

    const validateOnchange = (e, data) => {
        const { name, checked } = e.target;
        console.log(name + ' : ' + checked);
        setSelectedOption(name);
        setMailData({
            ...mailData,
            reason: data
        })
    }

    const handleUnsubscribe = () => {
        props.submitUnsubscribe(mailData);
    }

    return (
        <div>

            {isSubmitUnsubscribePending ?
                <VWSpinner />
                :
                <Card className='p-4 shadow-lg'>
                    {unsubscribedSuccesss ?
                        <div className=" d-flex align-items-center">
                            <img src={SuccessfullyUnsubscribeImag} alt="no-user-found" style={{ height: "300px", width: "auto" }} />
                            <div className="fs-4  fw-bold text-success">
                                Successfully Unsubscribed.<br />
                                You won't get any mail from us now.
                            </div>
                        </div>
                        :
                        <div className=" d-flex">
                            <img src={unsubscribeImag} alt="no-user-found" style={{ height: "300px", width: "auto" }} />
                            <div>
                                <Form>
                                    <h4 style={{ color: '#f72d7e' }}>Please confirm to unsubscribe from these email notifications.</h4><br />
                                    <FormGroup className='w-50'>
                                        <Label className='fs-5'>Email Address</Label>
                                        <Input value={email} disabled />
                                    </FormGroup>
                                    <FormGroup tag="fieldset">
                                        <Label className='fs-5'>Reason</Label>
                                        <FormGroup className='ps-2'>
                                            <Label style={{ cursor: "pointer" }}>
                                                <Input
                                                    className='me-2'
                                                    type='radio'
                                                    name='radio1'
                                                    onChange={(e) => validateOnchange(e, `This content isn't relevant to my interests.`)}
                                                    checked={selectedOption === 'radio1'}
                                                />
                                                This content isn't relevant to my interests.
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='ps-2'>
                                            <Label style={{ cursor: "pointer" }}>
                                                <Input
                                                    className='me-2'
                                                    type='radio'
                                                    name='radio2'
                                                    onChange={(e) => validateOnchange(e, `I receive too many emails.`)}
                                                    checked={selectedOption === 'radio2'}
                                                />
                                                I receive too many emails.
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='ps-2'>
                                            <Label style={{ cursor: "pointer" }}>
                                                <Input
                                                    className='me-2'
                                                    type='radio'
                                                    name='radio3'
                                                    onChange={(e) => validateOnchange(e, `I need to reduce my email subscriptions.`)}
                                                    checked={selectedOption === 'radio3'}
                                                />
                                                I need to reduce my email subscriptions.
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='ps-2'>
                                            <Label style={{ cursor: "pointer" }}>
                                                <Input
                                                    className='me-2'
                                                    type='radio'
                                                    name='radio4'
                                                    onChange={(e) => validateOnchange(e, `I experience technical issues with the newsletter.`)}
                                                    checked={selectedOption === 'radio4'}
                                                />
                                                I experience technical issues with the newsletter.
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className='ps-2'>
                                            <Label style={{ cursor: "pointer" }}>
                                                <Input
                                                    className='me-2'
                                                    type='radio'
                                                    name='radio5'
                                                    onChange={(e) => validateOnchange(e, `Others`)}
                                                    checked={selectedOption === 'radio5'}
                                                />
                                                Others
                                            </Label>
                                            {selectedOption === 'radio5' &&
                                                <Input
                                                    className='me-2'
                                                    type='text'
                                                    name='radio5'
                                                    onChange={(e) => validateOnchange(e, e.target.value)}
                                                />
                                            }
                                        </FormGroup>
                                    </FormGroup>
                                    <Button
                                        cla4sName='mt-4'
                                        color='danger'
                                        onClick={handleUnsubscribe}
                                    >Unsubscribe
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    }
                </Card >
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    mail: state.unsubscribe.mail,
    unsubscribedSuccesss: state.unsubscribe.unsubscribedSuccesss,
    isSubmitUnsubscribePending: state.unsubscribe.isSubmitUnsubscribePending,
})
const mapDispatchToProps = (dispatch) => ({
    submitUnsubscribe: (mailData) => (dispatch(submitUnsubscribe(mailData))),
})

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);