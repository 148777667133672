import { CLEAR_LEAVE_TYPE_DETAILS_ERROR, CLEAR_LEAVE_TYPE_ERROR, CLEAR_LEAVE_TYPE_LIST_ERROR, CLEAR_LEAVE_TYPE_MASTER_LIST_ERROR, CLEAR_ORG_LEAVE_TYPE_LIST_ERROR, CLEAR_UPDATE_LEAVE_TYPE_ERROR, CLOSE_CREATE_LEAVE_TYPE_MODAL, CLOSE_EDIT_LEAVE_TYPE_MODAL, CLOSE_LEAVE_TYPE_DELETE_MODAL, CREATE_LEAVE_TYPE_ERROR, CREATE_LEAVE_TYPE_PENDING, CREATE_LEAVE_TYPE_SUCCESS, OPEN_CREATE_LEAVE_TYPE_MODAL, OPEN_EDIT_LEAVE_TYPE_MODAL, OPEN_LEAVE_TYPE_DELETE_MODAL, SET_LEAVE_TYPE_DETAILS, SET_LEAVE_TYPE_DETAILS_ERROR, SET_LEAVE_TYPE_DETAILS_PENDING, SET_LEAVE_TYPE_LIST, SET_LEAVE_TYPE_LIST_ERROR, SET_LEAVE_TYPE_MASTER_LIST, SET_LEAVE_TYPE_MASTER_LIST_ERROR, SET_LEAVE_TYPE_MASTER_LIST_PENDING, SET_LEAVE_TYPE_PENDING, SET_ORG_LEAVE_TYPE_LIST, SET_ORG_LEAVE_TYPE_LIST_ERROR, SET_ORG_LEAVE_TYPE_LIST_PENDING, UPDATE_LEAVE_TYPE_ERROR, UPDATE_LEAVE_TYPE_PENDING, UPDATE_LEAVE_TYPE_SUCCESS } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    LeaveTypeList: [],
    LeaveTypeListError: null,
    totalRecords: 0,
    isLeavetypeDeleteModalOpen: false,
    isLeavetypeCreateModalOpen: false,
    isLeavetypeEditModalOpen: false,
    LeaveTypeDetails: null,
    LeaveTypeDetailsError: null,
    updateleaveTypeErrorMessage: '',
    isUpdateLeaveTypePending: false,
    isLeaveTypePending: false,
    isLeaveTypeDetailsPending: false,
    LeaveTypeMasterList: [],
    LeaveTypeMasterListError: null,
    isLeaveTypeMasterListPending: false,
    OrgLeaveTypeListError: null,
    isOrgLeaveTypeListPending: false,
    OrgLeaveTypeList: [],
};
export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_LEAVE_TYPE_LIST: {
            const { leaveTypeList } = action.payload;
            return {
                ...localState,
                isLeaveTypePending: false,
                LeaveTypeList: leaveTypeList && leaveTypeList.value,
                totalRecords: leaveTypeList["@odata.count"],
            }
        }
        case SET_LEAVE_TYPE_MASTER_LIST: {
            const { leaveTypeMasterList } = action.payload;
            return {
                ...localState,
                isLeaveTypeMasterListPending: false,
                LeaveTypeMasterList: leaveTypeMasterList && leaveTypeMasterList.value,
                // totalRecords: leaveTypeMasterList["@odata.count"],
            }
        }
        case SET_ORG_LEAVE_TYPE_LIST: {
            const { OrgleaveTypeList } = action.payload;
            return {
                ...localState,
                isOrgLeaveTypeListPending: false,
                OrgLeaveTypeList: OrgleaveTypeList && OrgleaveTypeList.value,
                // totalRecords: OrgleaveTypeList["@odata.count"],
            }
        }
        case SET_LEAVE_TYPE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isLeaveTypePending: false,
                LeaveTypeListError: errorMessage,
            };
        }
        case SET_LEAVE_TYPE_MASTER_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isLeaveTypeMasterListPending: false,
                leaveTypeMasterListError: errorMessage,
            };
        }
        case SET_ORG_LEAVE_TYPE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isOrgLeaveTypeListPending: false,
                OrgLeaveTypeListError: errorMessage,
            };
        }
        case CLEAR_LEAVE_TYPE_LIST_ERROR: {
            return {
                ...localState,
                LeaveTypeListError: null,
            };
        }
        case CLEAR_LEAVE_TYPE_MASTER_LIST_ERROR: {
            return {
                ...localState,
                LeaveTypeMasterListError: null,
            };
        }
        case CLEAR_ORG_LEAVE_TYPE_LIST_ERROR: {
            return {
                ...localState,
                OrgLeaveTypeListError: null,
            };
        }
        case OPEN_LEAVE_TYPE_DELETE_MODAL: {
            return {
                ...localState,
                isLeavetypeDeleteModalOpen: true,
            };
        }
        case OPEN_EDIT_LEAVE_TYPE_MODAL: {
            return {
                ...localState,
                isLeavetypeEditModalOpen: true,
            };
        }
        case OPEN_CREATE_LEAVE_TYPE_MODAL: {
            return {
                ...localState,
                isLeavetypeCreateModalOpen: true,
            };
        }
        case CLOSE_LEAVE_TYPE_DELETE_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isLeavetypeDeleteModalOpen: false,
            };
        }
        case CLOSE_EDIT_LEAVE_TYPE_MODAL: {
            return {
                ...localState,
                updateleaveTypeErrorMessage: null,
                isLeavetypeEditModalOpen: false,
            };
        }
        case CLOSE_CREATE_LEAVE_TYPE_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isLeavetypeCreateModalOpen: false,
            };
        }
        case SET_LEAVE_TYPE_DETAILS: {
            const { LeaveTypeDetails } = action.payload;
            return {
                ...localState,
                isLeaveTypeDetailsPending: false,
                LeaveTypeDetails: LeaveTypeDetails,
            }
        }
        case SET_LEAVE_TYPE_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                LeaveTypeDetails: {},
                isLeaveTypeDetailsPending: false,
                LeaveTypeDetailsError: errorMessage,
            };
        }
        case CLEAR_LEAVE_TYPE_DETAILS_ERROR: {
            return {
                ...localState,
                LeaveTypeDetailsError: null,
            };
        }
        case CREATE_LEAVE_TYPE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_LEAVE_TYPE_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CLEAR_LEAVE_TYPE_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CREATE_LEAVE_TYPE_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_UPDATE_LEAVE_TYPE_ERROR: {
            return {
                ...localState,
                updateleaveTypeErrorMessage: null,
                isUpdateLeaveTypePending: false,
            };
        }
        case UPDATE_LEAVE_TYPE_PENDING: {
            return {
                ...localState,
                isUpdateLeaveTypePending: true,
            };
        }
        case UPDATE_LEAVE_TYPE_SUCCESS: {
            return {
                ...localState,
                isUpdateLeaveTypePending: false,
            };
        }
        case UPDATE_LEAVE_TYPE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateleaveTypeErrorMessage: errorMessage,
                isUpdateLeaveTypePending: false,
            };
        }
        case SET_LEAVE_TYPE_PENDING: {
            return {
                ...localState,
                LeaveTypeListError: null,
                isLeaveTypePending: true
            }
        }
        case SET_LEAVE_TYPE_MASTER_LIST_PENDING: {
            return {
                ...localState,
                LeaveTypeMasterListError: null,
                isLeaveTypeMasterListPending: true
            }
        }
        case SET_ORG_LEAVE_TYPE_LIST_PENDING: {
            return {
                ...localState,
                OrgLeaveTypeListError: null,
                isOrgLeaveTypeListPending: true
            }
        }
        case SET_LEAVE_TYPE_DETAILS_PENDING: {
            return {
                ...localState,
                LeaveTypeDetailsError: null,
                isLeaveTypeDetailsPending: true
            }
        }
        default: {
            return localState;
        }
    }
};