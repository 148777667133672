import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, InputGroup, Table, Tooltip } from "reactstrap";
import VWModel from "../../../../components/Modal/VWModal";
import CreateContact from "./create";
import { getPager } from "../../../../helper/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendarAlt, faFont } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import { clearContactsExportListError, closeContactsCreate, getContactExportList, getContactsList, openContactsCreate } from "../../../../store/Modules/crm/Contacts/actions";
import '../../../Modules/viewList.css'
import moment from "moment-timezone";
import { excelPrint } from "../../../../helper/export";
import { CONTACT_EXPORT_HEADERS } from "../../../../store/Modules/crm/Contacts/constants";
import UITooltip from "../../../../CommonData/Data/UITooltip";
import { ElementToolTip } from "../../Projects/vwToolTip";

const CrmContactsList = (props) => {

    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const { contactsListErrorMessage, isContactsListPending, contactsListCount, contactsList, isCreateContactsOpen, contactsExportList, contactsExportListErrorMessage, isContactsExportListPending, currentProfile } = props;
    const [filterSelectionOption, setFilterSelectionOption] = useState('name');


    const path = window.location.search;
    const params = new URLSearchParams(path);
    console.log("path:", path);
    console.log("params:", params);

    const StartDate = params.get('startDate');
    const EndDate = params.get('endDate');

    //----------------------------ROLE-------------------------------------------
    const { userAppDetails } = currentProfile || [];
    const appData = userAppDetails && userAppDetails.filter((app) => app.appName === "CRM");
    const { appRoleDetails } = appData.length > 0 ? appData[0] : null;
    const adminRoleData = appRoleDetails && appRoleDetails.filter((role) => role.roleName === "Administrator");
    const isAdmin = adminRoleData && adminRoleData.length > 0;


    const buildQueryDisplayName = () => {
        const { DisplayName } = filter || {};
        let searchDisplayName = '';
        if (DisplayName) {
            searchDisplayName += `${DisplayName}`;
        }
        return searchDisplayName
    }
    const buildQueryCompanyName = () => {
        const { CompanyEmail } = filter || {};
        let searchCompanyName = '';
        if (CompanyEmail) {
            searchCompanyName += `${CompanyEmail.replace(/\+/g, "%2B")}`;
        }
        return searchCompanyName
    }
    const buildQueryEmail = () => {
        const { ContactEmail } = filter || {};
        let searchEmail = '';
        if (ContactEmail) {
            searchEmail += `${ContactEmail.replace(/\+/g, "%2B")}`;
        }
        return searchEmail
    }
    const buildQueryPhoneNo = () => {
        const { PhoneNo } = filter || {};
        let searchPhoneNo = '';
        if (PhoneNo) {
            searchPhoneNo += `${PhoneNo}`;
        }
        return searchPhoneNo
    }
    const buildQueryTitle = () => {
        const { Title } = filter || {};
        let searchTitle = '';
        if (Title) {
            searchTitle += `${Title}`;
        }
        return searchTitle
    }
    const buildQuerysearchDesignation = () => {
        const { Designation } = filter || {};
        let searchDesignation = '';
        if (Designation) {
            searchDesignation += `${Designation}`;
        }
        return searchDesignation
    }
    const buildQuerysearchDepartment = () => {
        const { Department } = filter || {};
        let searchDepartment = '';
        if (Department) {
            searchDepartment += `${Department}`;
        }
        return searchDepartment
    }
    const buildQuerysearchLandLineNo = () => {
        const { LandLineNo } = filter || {};
        let searchLandLineNo = '';
        if (LandLineNo) {
            searchLandLineNo += `${LandLineNo}`;
        }
        return searchLandLineNo
    }
    const buildQuerysearchCity = () => {
        const { City } = filter || {};
        let searchCity = '';
        if (City) {
            searchCity += `${City}`;
        }
        return searchCity
    }
    const buildQueryLeadCreatedDate = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedDate = '';
        if (CreatedTimeStamp) {
            searchCreatedDate += `${CreatedTimeStamp}`;
        }
        return searchCreatedDate
    }
    const buildQueryLeadCreatedBy = () => {
        const { CreatedBy } = filter || {};
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }
    const setPage = (page = 1) => {
        props.getContactsList(filter, page, PageSize, buildQueryDisplayName(), buildQueryCompanyName(), buildQueryEmail(), buildQueryPhoneNo(), buildQueryTitle(), buildQuerysearchDesignation(), buildQuerysearchDepartment(), buildQuerysearchLandLineNo(), buildQuerysearchCity(), buildQueryLeadCreatedDate(), buildQueryLeadCreatedBy());
        setPager(prevPager => {
            const totalPages = Math.ceil(contactsListCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(contactsListCount, 1, PageSize)
        setPager(currentPager)
    }, [contactsListCount])

    useEffect(() => {
        if (StartDate || EndDate) {
            setFilter({
                ...filter,
                CreatedTimeStamp: StartDate,
                UpdatedTimeStamp: EndDate,
            })
        } else {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        }
        props.clearContactsExportListError();
    }, [])

    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }
    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])

    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }

    // ----------------------------------------SORTING-----------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedContactsList = [...contactsList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';

        if (sortOrder === 'IsTerminated') {
            return sortDirection === 'asc' ? b.IsTerminated - a.IsTerminated : a.IsTerminated - b.IsTerminated;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //------------------------------------------ACCOUNT-CREATE-MODAL------------------------------------------------
    const createAccountOpen = () => {
        props.openContactsCreate();
    }
    const handleClose = () => {
        props.closeContactsCreate();
    }


    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/crm/contacts/details/${Id}`);
    };

    //----------------------------------------Export----------------------------------------------------------------
    const handleExports = () => {
        props.getContactExport(filter,buildQueryDisplayName(), buildQueryCompanyName(), buildQueryEmail(), buildQueryPhoneNo(), buildQueryTitle(), buildQuerysearchDesignation(), buildQuerysearchDepartment(), buildQuerysearchLandLineNo(), buildQuerysearchCity(), buildQueryLeadCreatedDate(), buildQueryLeadCreatedBy())
            .then((response) => {
                if (response) {
                    const exportContactList = [];
                    const headers = CONTACT_EXPORT_HEADERS;
                    const headerSizes = [20, 30, 50, 30, 50, 20, 20, 30, 20, 20, 20, 20];
                    response && response.forEach((element) => {
                        exportContactList.push({
                            Title: element.title || "N/A",
                            DisplayName: element.displayName || "N/A",
                            ContactEmail: element.contactEmail || "N/A",
                            companyName: element.companyName || "N/A",
                            companyEmail: element.companyEmail || "N/A",
                            Designation: element.designation || "N/A",
                            Department: element.department || "N/A",
                            phoneNo: element.phoneNo || "N/A",
                            LandLineNo: element.landLineNo || "N/A",
                            City: element.city || "N/A",
                            createdTimeStamp: element.createdTimeStamp ? moment(element.createdTimeStamp).format('DD MMM YYYY') : "NA",
                            CreatedBy: element.createdBy || "NA",
                        });
                    });
                    const sheetName = "Contact-List";
                    excelPrint(exportContactList, sheetName, headers, headerSizes);
                }
            })
    }
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    console.log("filter:", filter);

    return (
        <div className="page-content">
            <Card>
                <CardTitle tag="h4"
                    className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center">
                    <strong className='d-flex align-items-center'>CONTACTS  -&nbsp;<Badge color="danger" id={'contactCount'}>{props && props.contactsListCount || 0}</Badge>
                        <ElementToolTip id={'contactCount'} name={
                            'Contacts Count'
                        } />
                    </strong>
                    <div>
                        <Button color="primary" onClick={createAccountOpen}>
                            CREATE
                        </Button>&nbsp;
                        {isAdmin &&
                            <Button
                                className="me-4"
                                color="success"
                                onClick={handleExports}
                                disabled={contactsList && contactsList.length <= 0 || isContactsExportListPending}
                            >
                                {isContactsExportListPending ? 'EXPORTING...' : 'EXPORT'}
                            </Button>
                        }
                    </div>
                </CardTitle>
            </Card>
            <Card >
                <CardBody className="p-4">
                    {(contactsListErrorMessage || contactsExportListErrorMessage) ? <div className='w-100'>
                        {(contactsListErrorMessage || contactsExportListErrorMessage) && (
                            <VWMessage type="danger" defaultMessage={contactsListErrorMessage || contactsExportListErrorMessage} baseClassName="alert" ></VWMessage>
                        )}
                    </div> : ""}
                    <div style={{ height: "60vh", overflowX: "auto" }}>
                        <Table size="sm" className="table table-striped table-hover table-nowrap w-100 " style={style.table}>
                            <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                <tr>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('ContactEmail')} className='hand-cursor'>Contact Name {sortOrder === 'ContactEmail' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('CompanyEmail')} className='hand-cursor'>Account Name {sortOrder === 'CompanyEmail' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Designation')} className='hand-cursor'>Designation {sortOrder === 'Designation' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('Department')} className='hand-cursor'>Department {sortOrder === 'Department' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('PhoneNo')} className='hand-cursor'>Mobile # {sortOrder === 'PhoneNo' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('LandLineNo')} className='hand-cursor'>LandLine # {sortOrder === 'LandLineNo' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('City')} className='hand-cursor'>City {sortOrder === 'City' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th style={{ minWidth: '200px' }} onClick={() => handleSort('CreatedBy')} className='hand-cursor'>Created By {sortOrder === 'CreatedBy' && (
                                        <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                    )}</th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>View</th>
                                </tr>
                                <tr>
                                    <th>
                                        <Input
                                            type="text"
                                            name="ContactEmail"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="CompanyEmail"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="Designation"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="Department"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="PhoneNo"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="LandLineNo"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <Input
                                            type="text"
                                            name="City"
                                            placeholder="Search"
                                            onChange={searchInputValidation}
                                        />
                                    </th>
                                    <th>
                                        <InputGroup style={{ width: "200px" }}>
                                            {filterSelectionOption === 'name' ?
                                                <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                    <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                </UITooltip>
                                                : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                    <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                </UITooltip>
                                            }
                                            <Input
                                                style={{ width: "120px" }}
                                                type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                placeholder='Search'
                                                name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                onChange={searchInputValidation}
                                                onKeyPress={handleKeyPress}
                                                value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                                max={filterSelectionOption !== "name" && "9999-12-31"}
                                            />
                                        </InputGroup>
                                    </th>
                                    <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isContactsListPending && (contactsList && contactsList.length > 0) ?
                                    sortedContactsList && sortedContactsList.map((contact, index) => (
                                        <tr key={index} onDoubleClick={() => OnDoubleClickAction(contact.Id)}>
                                            <td className='ps-3'>{contact.Title} {contact.DisplayName || "N/A"}
                                                <br />
                                                <small>{contact.ContactEmail || ""}</small>
                                            </td>
                                            <td className='ps-3'>{contact.CompanyName || "N/A"}
                                                <br />
                                                <small>{contact.CompanyEmail || ""}</small>
                                            </td>
                                            <td className='ps-3'>{contact.Designation || "N/A"}</td>
                                            <td className='ps-3'>{contact.Department || "N/A"}</td>
                                            <td className='ps-3'>{contact.PhoneNo || "N/A"}</td>
                                            <td className='ps-3'>{contact.LandLineNo || "N/A"}</td>
                                            <td className='ps-3'>{contact.City || "N/A"}</td>
                                            <td>
                                                {contact.CreatedBy || "N/A"}<br />
                                                <small>{contact.CreatedTimeStamp ? moment(contact.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                            </td>
                                            <td className='sticky-column' style={{ backgroundColor: "white" }}>
                                                <Link className="btn btn-sm btn-light" to={`/crm/contacts/details/${contact.Id}`}>
                                                    <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                </Link></td>
                                        </tr>)) :
                                    isContactsListPending ?
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <VWSpinner />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="sticky-spinner-row">
                                            <div className="position-relative">
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        position: 'fixed',
                                                        top: '50%',
                                                        left: '60%',
                                                        transform: 'translate(-50%, -50%)',
                                                        zIndex: 1,
                                                        width: '100vw',
                                                        height: '100vh',
                                                        pointerEvents: 'none',
                                                    }}>
                                                    <NoRecord />
                                                </div>
                                            </div>
                                        </div>}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <CardFooter>
                    {contactsList && contactsList.length > 0 ?
                        <VWPagination pager={Pager} setPage={setPage} totalRecords={contactsListCount} pageSize={PageSize} /> : null
                    }
                </CardFooter>
            </Card>
            <VWModel
                header="CREATE CONTACT"
                modalSize="extra-large"
                isOpen={props.isCreateContactsOpen}
                handleClose={handleClose}
            >
                <CreateContact handleClose={handleClose} filteredData={filter} />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isCreateContactsOpen: state.crmContacts.isCreateContactsOpen,
    contactsList: state.crmContacts.contactsList,
    contactsListCount: state.crmContacts.contactsListCount,
    isContactsListPending: state.crmContacts.isContactsListPending,
    contactsListErrorMessage: state.crmContacts.contactsListErrorMessage,
    contactsExportList: state.crmContacts.contactsExportList,
    isContactsExportListPending: state.crmContacts.isContactsExportListPending,
    contactsExportListErrorMessage: state.crmContacts.contactsExportListErrorMessage,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    clearContactsExportListError: () => dispatch(clearContactsExportListError()),
    openContactsCreate: () => dispatch(openContactsCreate()),
    closeContactsCreate: () => dispatch(closeContactsCreate()),
    getContactExport: (filter,searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy) => dispatch(getContactExportList(filter,searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy)),
    getContactsList: (filter, page, PageSize, searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy) => dispatch(getContactsList(filter, page, PageSize, searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy))
})

export default connect(mapStateToProps, mapDispatchToProps)(CrmContactsList)