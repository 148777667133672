import { getToken, modifyCrmUserList } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    OPPORTUNITY_CATEGORY_CREATE_CLOSE,
    OPPORTUNITY_CATEGORY_CREATE_ERROR,
    OPPORTUNITY_CATEGORY_CREATE_ERROR_CLEAR,
    OPPORTUNITY_CATEGORY_CREATE_OPEN,
    OPPORTUNITY_CATEGORY_CREATE_PENDING,
    OPPORTUNITY_CATEGORY_CREATE_SUCCESS,
    OPPORTUNITY_CATEGORY_LIST_ERROR,
    OPPORTUNITY_CATEGORY_LIST_ERROR_CLEAR,
    OPPORTUNITY_CATEGORY_LIST_PENDING,
    OPPORTUNITY_CATEGORY_UPDATE_CLOSE,
    OPPORTUNITY_CATEGORY_UPDATE_ERROR,
    OPPORTUNITY_CATEGORY_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_CATEGORY_UPDATE_OPEN,
    OPPORTUNITY_CATEGORY_UPDATE_PENDING,
    OPPORTUNITY_CATEGORY_UPDATE_SUCCESS,
    SET_OPPORTUNITY_CATEGORY_DETAILS,
    SET_OPPORTUNITY_CATEGORY_LIST,
} from "./constants";

//--------------------Opportunity-Category-CREATE-----------------------------------
export const openAddOpportunityCategoryModal = () => ({
    type: OPPORTUNITY_CATEGORY_CREATE_OPEN
})
export const closeAddOpportunityCategoryModal = () => ({
    type: OPPORTUNITY_CATEGORY_CREATE_CLOSE
})
export const createOpportunityCategoryPending = () => ({
    type: OPPORTUNITY_CATEGORY_CREATE_PENDING
})
export const createOpportunityCategoryError = (errorMessage) => ({
    type: OPPORTUNITY_CATEGORY_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateOpportunityCategoryError = () => ({
    type: OPPORTUNITY_CATEGORY_CREATE_ERROR_CLEAR,
})
export const createOpportunityCategorySuccess = () => ({
    type: OPPORTUNITY_CATEGORY_CREATE_SUCCESS,
})

export const getCrmUserAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCrmUserAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmUserList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const createNewOpportunityCategory = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createOpportunityCategoryError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields })
    }
    dispatch(createOpportunityCategoryPending());
    dispatch(clearCreateOpportunityCategoryError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createOpportunityCategory(accessToken, dataToSend)
            .then(() => {
                dispatch(getOpportunityCategoryList());
                dispatch(createOpportunityCategorySuccess());
                dispatch(closeAddOpportunityCategoryModal());
                dispatch(showSnackbarStatus('Opportunity category created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createOpportunityCategoryError(err.response && err.response.text));
                    return;
                }
                console.log('Create Opportunity Category server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Opportunity-Category-UPDATE-----------------------------------
export const openEditOpportunityCategoryModal = () => ({
    type: OPPORTUNITY_CATEGORY_UPDATE_OPEN
})
export const closeEditOpportunityCategoryModal = () => ({
    type: OPPORTUNITY_CATEGORY_UPDATE_CLOSE
})
export const updateOpportunityCategoryPending = () => ({
    type: OPPORTUNITY_CATEGORY_UPDATE_PENDING
})
export const updateOpportunityCategoryError = (errorMessage) => ({
    type: OPPORTUNITY_CATEGORY_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateOpportunityCategoryError = () => ({
    type: OPPORTUNITY_CATEGORY_UPDATE_ERROR_CLEAR,
})
export const updateOpportunityCategorySuccess = () => ({
    type: OPPORTUNITY_CATEGORY_UPDATE_SUCCESS,
})
export const setOpportunityCategoryDetails = (opportunityCategoryDetails) => ({
    type: SET_OPPORTUNITY_CATEGORY_DETAILS,
    payload: { opportunityCategoryDetails }
})

export const updateOpportunityCategory = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateOpportunityCategoryError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateOpportunityCategoryPending());
    dispatch(clearUpdateOpportunityCategoryError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateOpportunityCategory(accessToken, dataToSend)
            .then(() => {
                dispatch(getOpportunityCategoryList());
                dispatch(updateOpportunityCategorySuccess());
                dispatch(closeEditOpportunityCategoryModal());
                dispatch(showSnackbarStatus('Opportunity category updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateOpportunityCategoryError(err.response && err.response.text));
                    return;
                }
                console.log('Create OpportunityCategory server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Opportunity-Category-LIST-----------------------------------
export const opportunityCategoryListPending = () => ({
    type: OPPORTUNITY_CATEGORY_LIST_PENDING
})
export const opportunityCategoryListError = (errorMessage) => ({
    type: OPPORTUNITY_CATEGORY_LIST_ERROR,
    payload: { errorMessage }
})
export const clearOpportunityCategoryListError = () => ({
    type: OPPORTUNITY_CATEGORY_LIST_ERROR_CLEAR,
})
export const setOpportunityCategoryList = (opportunityCategoryList) => ({
    type: SET_OPPORTUNITY_CATEGORY_LIST,
    payload: { opportunityCategoryList }
})

export const getOpportunityCategoryList = (page, PageSize) => (dispatch) => {
    dispatch(opportunityCategoryListPending());
    dispatch(clearOpportunityCategoryListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getOpportunityCategoryList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setOpportunityCategoryList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(opportunityCategoryListError(error));
                console.log('Create OpportunityCategory server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

