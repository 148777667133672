import {
    CLEAR_REPORT_LIST_ERROR,
    CLEAR_REPORT_USER_LIST_ERROR,
    REPORT_EXPORT_ERROR,
    REPORT_EXPORT_SUCCESS,
    REPORT_LIST_ERROR,
    REPORT_USER_LIST_ERROR,
    SET_REPORT_EXPORT_PENDING,
    SET_REPORT_LIST,
    SET_REPORT_LIST_PENDING,
    SET_REPORT_USER_LIST,
    SET_REPORT_USER_LIST_PENDING
} from "./constants";

const initialState = {
    //--------------------LIST-------------------------------------
    reportList: [],
    reportListCount: 0,
    isReportListPending: false,
    reportListErrorMessage: null,
    isReportUserListPending: false,
    reportUserList: [],
    reportUserListCount: 0,
    reportUserListErrorMessage: null,
    //--------------------EXPORT-------------------------------------
    isReportExportPending: false,
    reportExportErrorMessage: null,
}

export default (localState = initialState, action) => {
    switch (action.type) {

        //---------------------------------------------------------LIST----------------------------------------------------------------------------
        case SET_REPORT_USER_LIST_PENDING: {
            return {
                ...localState,
                isReportUserListPending: true,
            };
        }
        case SET_REPORT_LIST_PENDING: {
            return {
                ...localState,
                isReportListPending: true,
            };
        }
        case SET_REPORT_USER_LIST: {
            const { reportUserList } = action.payload;
            return {
                ...localState,
                reportUserList: reportUserList && reportUserList['value'],
                reportUserListCount: reportUserList && reportUserList['@odata.count'],
                isReportUserListPending: false,
                reportUserListErrorMessage: null,
            };
        }
        case SET_REPORT_LIST: {
            const { reportList } = action.payload;
            return {
                ...localState,
                reportList: reportList && reportList['value'],
                reportListCount: reportList && reportList['@odata.count'],
                isReportListPending: false,
                reportListErrorMessage: null,
            };
        }
        case REPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                reportListErrorMessage: errorMessage,
                reportList: [],
                reportListCount: 0,
                isReportListPending: false,
            };
        }
        case REPORT_USER_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                reportUserListErrorMessage: errorMessage,
                reportUserList: [],
                reportUserListCount: 0,
                isReportUserListPending: false,
            };
        }
        case CLEAR_REPORT_LIST_ERROR: {
            return {
                ...localState,
                reportListErrorMessage: null,
            };
        }
        case CLEAR_REPORT_USER_LIST_ERROR: {
            return {
                ...localState,
                reportUserListErrorMessage: null,
            };
        }
        //---------------------------------------------------------EXPORT----------------------------------------------------------------------------
        case SET_REPORT_EXPORT_PENDING: {
            return {
                ...localState,
                isReportExportPending: true,
            };
        }
        case REPORT_EXPORT_SUCCESS: {
            return {
                ...localState,
                isReportExportPending: false,
            };
        }
        case REPORT_EXPORT_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                reportExportErrorMessage: errorMessage,
                isReportExportPending: false,
            };
        }

        default: {
            return localState;
        }
    }
}