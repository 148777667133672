import { getToken } from "../../../../helper/common"
import agent from "../../../services/agent"
import { showSnackbarStatus } from "../../../snackbar/actions"
import {
    CLEAR_CONTACT_DETAILS_ERROR,
    CLEAR_CONTACT_LIST_ERROR,
    CONTACT_DETAILS_ERROR,
    CONTACT_LIST_ERROR,
    SET_CONTACT_DETAILS,
    SET_CONTACT_DETAILS_PENDING,
    SET_CONTACT_LIST, SET_CONTACT_PENDING,
    SET_EXPORT_CONTACT_LIST
} from "./constants"

//---------------------------------------------MM-CONTACT-LIST---------------------------------------------------------------------------------
export const setContactList = (contactList) => ({
    type: SET_CONTACT_LIST,
    payload: { contactList },
})
export const setExportContactList = (contactList) => ({
    type: SET_EXPORT_CONTACT_LIST,
    payload: contactList,
})
export const setContactPending = () => ({
    type: SET_CONTACT_PENDING
})
export const contactListError = (errorMessage) => ({
    type: CONTACT_LIST_ERROR,
    payload: { errorMessage },
})
export const clearContactListError = () => ({
    type: CLEAR_CONTACT_LIST_ERROR
})

export const getContactList = (page, pageSize, searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) => (dispatch) => {
    dispatch(setContactPending());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getContactList(accessToken, page, pageSize, searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder)
            .then((contactList) => {
                dispatch(setContactList(contactList));
                dispatch(clearContactListError());
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                console.log("Get Contact Error: ", error);
                dispatch(contactListError(error));
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            })
    })
}
export const exportContactList = (searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.exportContactList(accessToken, searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder)
            .then((contactList) => {
                dispatch(setExportContactList(contactList))
            })
            .catch(() => {
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            })

    })
}
//---------------------------------------------MM-CONTACT-DETAILS-------------------------------------------------------------------------------
export const setContactDetails = (contactDetails) => ({
    type: SET_CONTACT_DETAILS,
    payload: { contactDetails }
})
export const setContactDetailsPending = () => ({
    type: SET_CONTACT_DETAILS_PENDING
})
export const contactDetailsError = (errorMessage) => ({
    type: CONTACT_DETAILS_ERROR,
    payload: { errorMessage },
})
export const clearContactDetailsError = () => ({
    type: CLEAR_CONTACT_DETAILS_ERROR,
})

export const getContactDetails = (id) => (dispatch) => {
    dispatch(setContactDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.MassMailer.getContactDetails(accessToken, id)
            .then((contactDetails) => {
                dispatch(setContactDetails(contactDetails));
                dispatch(clearContactDetailsError());
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                }
                console.log("Get Contact Error: ", err);
                dispatch(contactDetailsError(error));
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            })

    })
}
