//eslint-disable-next-line
export const TIMESHEET_APPROVAL_LIST_PENDING = "TIMESHEET_APPROVAL_LIST_PENDING";
export const SET_TIMESHEET_APPROVAL_LIST = "SET_TIMESHEET_APPROVAL_LIST";
export const CLEAR_TIMESHEET_APPROVAL_LIST_ERROR = "CLEAR_TIMESHEET_APPROVAL_LIST_ERROR";
export const TIMESHEET_APPROVAL_LIST_ERROR = "TIMESHEET_APPROVAL_LIST_ERROR";
export const OPEN_ACTION_TIMESHEET_APPROVED_MODAL = "OPEN_ACTION_TIMESHEET_APPROVED_MODAL";
export const CLOSE_ACTION_TIMESHEET_APPROVED_MODAL = "CLOSE_ACTION_TIMESHEET_APPROVED_MODAL";
export const CLEAR_TIMESHEET_APPROVAL_ACTION_ERROR = "CLEAR_TIMESHEET_APPROVAL_ACTION_ERROR";
export const SET_TIMESHEET_APPROVAL_COUNT = "SET_TIMESHEET_APPROVAL_COUNT";
export const TIMESHEET_APPROVAL_EXPORT_LIST_PENDING = "TIMESHEET_APPROVAL_EXPORT_LIST_PENDING";
export const SET_TIMESHEET_APPROVAL_EXPORT_LIST = "SET_TIMESHEET_APPROVAL_EXPORT_LIST";
export const CLEAR_TIMESHEET_APPROVAL_EXPORT_LIST_ERROR = "CLEAR_TIMESHEET_APPROVAL_EXPORT_LIST_ERROR";
export const TIMESHEET_APPROVAL_EXPORT_LIST_ERROR = "TIMESHEET_APPROVAL_EXPORT_LIST_ERROR";


export const TIMESHEET_APPROVAL_EXPORT_HEADERS = [
    "USER NAME",
    "USER EMAIL",
    "PROJECT NAME",
    "TASK NAME",
    "TASK CURRENT STATUS",
    "SCOPE",
    "WORK DATE",
    "WORK HOURS",
    "CREATED DATE",
    "UPDATED DATE",
    "REASON",
    "STATUS",
];