import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_TIMESHEET_APPROVAL_ACTION_ERROR, CLEAR_TIMESHEET_APPROVAL_EXPORT_LIST_ERROR, CLEAR_TIMESHEET_APPROVAL_LIST_ERROR, CLOSE_ACTION_TIMESHEET_APPROVED_MODAL, OPEN_ACTION_TIMESHEET_APPROVED_MODAL, SET_TIMESHEET_APPROVAL_COUNT, SET_TIMESHEET_APPROVAL_EXPORT_LIST, SET_TIMESHEET_APPROVAL_LIST, TIMESHEET_APPROVAL_EXPORT_LIST_ERROR, TIMESHEET_APPROVAL_EXPORT_LIST_PENDING, TIMESHEET_APPROVAL_LIST_ERROR, TIMESHEET_APPROVAL_LIST_PENDING } from "./constants";

export const setTimeSheetApprovalList = (TimeSheetApprovalLists) => ({
    type: SET_TIMESHEET_APPROVAL_LIST,
    payload: { TimeSheetApprovalLists },
})
export const setTimeSheetApprovalExportList = (TimeSheetApprovalLists) => ({
    type: SET_TIMESHEET_APPROVAL_EXPORT_LIST,
    payload: { TimeSheetApprovalLists },
})

export const TimesheetApprovalListPending = () => ({
    type: TIMESHEET_APPROVAL_LIST_PENDING,
});
export const TimesheetApprovalExportListPending = () => ({
    type: TIMESHEET_APPROVAL_EXPORT_LIST_PENDING,
});
export const ClearTimesheetApprovalListError = () => ({
    type: CLEAR_TIMESHEET_APPROVAL_LIST_ERROR,
});
export const ClearTimesheetApprovalExportListError = () => ({
    type: CLEAR_TIMESHEET_APPROVAL_EXPORT_LIST_ERROR,
});

export const TimesheetApprovalListError = (TimesheetApprovalErrorMessage) => ({
    type: TIMESHEET_APPROVAL_LIST_ERROR,
    payload: { TimesheetApprovalErrorMessage },
});
export const TimesheetApprovalExportListError = (TimesheetApprovalErrorMessage) => ({
    type: TIMESHEET_APPROVAL_EXPORT_LIST_ERROR,
    payload: { TimesheetApprovalErrorMessage },
});

export const openActionTimeSheetApprovalModal = () => ({
    type: OPEN_ACTION_TIMESHEET_APPROVED_MODAL,
});

export const closeActionTimeSheetApprovalModal = () => ({
    type: CLOSE_ACTION_TIMESHEET_APPROVED_MODAL,
});
export const clearTimeSheetApprovalActionError = () => ({
    type: CLEAR_TIMESHEET_APPROVAL_ACTION_ERROR,
});
export const setTimeSheetApprovalCount = (TimeSheetApprovalCount) => ({
    type: SET_TIMESHEET_APPROVAL_COUNT,
    payload: { TimeSheetApprovalCount },
})

export const getTimesheetApprovalCount = () => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.TimeSheetApproval.getTimesheetApprovalPendingCount(accessToken).then((ApprovalCount) => {
            console.log("ApprovalCount:", ApprovalCount);
            dispatch(setTimeSheetApprovalCount(ApprovalCount));
        })
            .catch((err) => {
                console.log("getTimesheetApprovalCount", err)
            })
    })
}

export const getTimesheetApproval = (filter,searchStatus, page, PageSize, searchuserName, searchprojectName, searchtaskName,
     searchCurrentTaskStatus, searchType, searchworkHours,
    searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchReason, id, startDate, endDate) => (dispatch) => {
    dispatch(TimesheetApprovalListPending());
    getToken(dispatch).then((accessToken) => {
        console.log("searchCurrentTaskStatus:", searchCurrentTaskStatus);
        agent.TimeSheetApproval.getTimeSheetApprovalList(accessToken, filter, searchStatus, page, PageSize, searchuserName, searchprojectName, 
            searchtaskName, searchCurrentTaskStatus, searchType,searchworkHours, searchworkDate,
             searchcreatedDateTime, searchUpdatedDateTime, searchReason, id, startDate, endDate)
            .then((TimeSheetApprovalLists) => {
                console.log("TimeSheetApprovalLists:", TimeSheetApprovalLists);
                dispatch(setTimeSheetApprovalList(TimeSheetApprovalLists));
                dispatch(ClearTimesheetApprovalListError());
                dispatch(getTimesheetApprovalCount());
            })
            .catch((err) => {
                console.log('getTimesheetApprovalList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(TimesheetApprovalListError(error))
            });
    });
}
export const getTimesheetApprovalExport = (searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id) => (dispatch) => {
    dispatch(TimesheetApprovalExportListPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.TimeSheetApproval.getTimeSheetApprovalExportList(accessToken, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id)
            .then((TimeSheetApprovalLists) => {
                dispatch(setTimeSheetApprovalExportList(TimeSheetApprovalLists));
                dispatch(ClearTimesheetApprovalExportListError());
                return Promise.resolve(TimeSheetApprovalLists);
            })
            .catch((err) => {
                console.log('getTimesheetApprovalExport server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(TimesheetApprovalExportListError(error))
            });
    });
}
