import { getToken, modifyCrmUserList } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    IS_LEAD_CATEGORY_INLINE_EDIT,
    LEAD_CATEGORY_CREATE_CLOSE,
    LEAD_CATEGORY_CREATE_ERROR,
    LEAD_CATEGORY_CREATE_ERROR_CLEAR,
    LEAD_CATEGORY_CREATE_OPEN,
    LEAD_CATEGORY_CREATE_PENDING,
    LEAD_CATEGORY_CREATE_SUCCESS,
    LEAD_CATEGORY_LIST_ERROR,
    LEAD_CATEGORY_LIST_ERROR_CLEAR,
    LEAD_CATEGORY_LIST_PENDING,
    LEAD_CATEGORY_UPDATE_CLOSE,
    LEAD_CATEGORY_UPDATE_ERROR,
    LEAD_CATEGORY_UPDATE_ERROR_CLEAR,
    LEAD_CATEGORY_UPDATE_OPEN,
    LEAD_CATEGORY_UPDATE_PENDING,
    LEAD_CATEGORY_UPDATE_SUCCESS,
    SET_LEAD_CATEGORY_DETAILS,
    SET_LEAD_CATEGORY_LIST,
} from "./constants";

//--------------------LEAD-CATEGORY-CREATE-----------------------------------
export const openAddLeadCategoryModal = () => ({
    type: LEAD_CATEGORY_CREATE_OPEN
})
export const closeAddLeadCategoryModal = () => ({
    type: LEAD_CATEGORY_CREATE_CLOSE
})
export const createLeadCategoryPending = () => ({
    type: LEAD_CATEGORY_CREATE_PENDING
})
export const createLeadCategoryError = (errorMessage) => ({
    type: LEAD_CATEGORY_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateLeadCategoryError = () => ({
    type: LEAD_CATEGORY_CREATE_ERROR_CLEAR,
})
export const createLeadCategorySuccess = () => ({
    type: LEAD_CATEGORY_CREATE_SUCCESS,
})

export const getCrmUserAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getCrmUserAsyncSelect(accessToken, searchValue)
            .then((UserLists) => {
                const { value } = UserLists;
                const updatedUserList = modifyCrmUserList(value);
                //eslint-disable-next-line
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getUserNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};

export const createNewLeadCategory = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createLeadCategoryError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createLeadCategoryPending());
    dispatch(clearCreateLeadCategoryError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createLeadCategory(accessToken, dataToSend)
            .then(() => {
                dispatch(getLeadCategoryList());
                dispatch(createLeadCategorySuccess());
                dispatch(closeAddLeadCategoryModal());
                dispatch(showSnackbarStatus('Lead category created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createLeadCategoryError(err.response && err.response.text));
                    return;
                }
                console.log('Create lead category server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------CONTACTS-UPDATE-----------------------------------
export const openEditLeadCategoryModal = () => ({
    type: LEAD_CATEGORY_UPDATE_OPEN
})
export const closeEditLeadCategoryModal = () => ({
    type: LEAD_CATEGORY_UPDATE_CLOSE
})
export const updateLeadCategoryPending = () => ({
    type: LEAD_CATEGORY_UPDATE_PENDING
})
export const updateLeadCategoryError = (errorMessage) => ({
    type: LEAD_CATEGORY_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateLeadCategoryError = () => ({
    type: LEAD_CATEGORY_UPDATE_ERROR_CLEAR,
})
export const updateLeadCategorySuccess = () => ({
    type: LEAD_CATEGORY_UPDATE_SUCCESS,
})
export const setLeadCategoryDetails = (categoryDetails) => ({
    type: SET_LEAD_CATEGORY_DETAILS,
    payload: { categoryDetails }
})

export const IsLeadCategoryInineUpdate = (id) => ({
    type: IS_LEAD_CATEGORY_INLINE_EDIT,
    payload: { id }
})
export const updateLeadCategory = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateLeadCategoryError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateLeadCategoryPending());
    dispatch(clearUpdateLeadCategoryError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateLeadCategory(accessToken, dataToSend)
            .then(() => {
                dispatch(getLeadCategoryList());
                dispatch(updateLeadCategorySuccess());
                dispatch(closeEditLeadCategoryModal());
                dispatch(showSnackbarStatus('Lead category updated successfully'));
                dispatch(setIsInlineUpdate(null));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateLeadCategoryError(err.response && err.response.text));
                    return;
                }
                console.log('Create lead category server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------LEADS-CATEGORY-LIST-----------------------------------
export const leadCategoryListPending = () => ({
    type: LEAD_CATEGORY_LIST_PENDING
})
export const leadCategoryListError = (errorMessage) => ({
    type: LEAD_CATEGORY_LIST_ERROR,
    payload: { errorMessage }
})
export const clearLeadCategoryListError = () => ({
    type: LEAD_CATEGORY_LIST_ERROR_CLEAR,
})
export const setLeadCategoryList = (leadCategoryList) => ({
    type: SET_LEAD_CATEGORY_LIST,
    payload: { leadCategoryList }
})

export const getLeadCategoryList = (page, PageSize) => (dispatch) => {
    dispatch(leadCategoryListPending());
    dispatch(clearLeadCategoryListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getLeadCategoryList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setLeadCategoryList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leadCategoryListError(error));
                console.log('lead category server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

