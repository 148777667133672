import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    Card,
    CardTitle,
    Col,
    Container,
    Row
} from "reactstrap";
import { dashboardCounts } from "../../../../store/Modules/MassMailer/Dashboard/actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUsers,
    faUserTimes,
    faUserCheck,
    faMailBulk,
    faEnvelopeOpenText,
    faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";

const MMDashboard = (props) => {
    const { mmDashboardCounts } = props;
    const { closedCampaign, openCampaign, optInCount, optOutCount, totalCampaign, totalContact } = mmDashboardCounts || {};

    const navigate = useNavigate();
    const [filterParams, setFilterParams] = useSearchParams()

    useEffect(() => {
        props.dashboardCounts()
    }, [])

    //---------------------------------------------------------CONTACTS-PARAMS--------------------------------------------------------------------------
    const handleClickAll = () => {
        navigate('/massmailer/contacts')
    }

    const handleClickSubscribe = () => {
        setFilterParams({
            filter: "optin"
        })
        const path = window.location.search;
        const params = new URLSearchParams(path);
        const filter = params.get('filter')
        navigate(`/massmailer/contacts?filter=${filter}`)
    }

    const handleClickUnsubscribe = () => {
        setFilterParams({ filter: "optout" })
        const path = window.location.search;
        const params = new URLSearchParams(path);
        const filter = params.get('filter')
        navigate(`/massmailer/contacts?filter=${filter}`)
    }

    //---------------------------------------------------------CAMPAIGN-PARAMS--------------------------------------------------------------------------
    const handleClickTotalCampaign = () => {
        navigate('/massmailer/campaigns')
    }

    const handleClickOpenCampaign = () => {
        setFilterParams({ filter: "Open" })
        const path = window.location.search;
        const params = new URLSearchParams(path);
        const filter = params.get('filter')
        navigate(`/massmailer/campaigns?filter=${filter}`)
    }

    const handleClickClosedCampaign = () => {
        setFilterParams({ filter: "Closed" })
        const path = window.location.search;
        const params = new URLSearchParams(path);
        const filter = params.get('filter')
        navigate(`/massmailer/campaigns?filter=${filter}`)
    }

    return (
        <div className="page-content">
            <Card>
                <CardTitle
                    tag="h4"
                    className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                >
                    <strong>DASHBOARD</strong>
                </CardTitle>
                {props.errorMessage && (
                    <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />
                )}
                {props.isDashboardPending ? (
                    <tr className="d-flex justify-content-center align-items-center mt-2">
                        <td colSpan={8}>
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                        </td>
                    </tr>
                ) : (
                    <Container className='py-4'>
                        <Row className='justify-content-xl-around'>
                            <Col xl='3' lg='4' md='4' sm='6' xs='10' className='pt-4'>
                                <Card
                                    onClick={handleClickAll}
                                    style={{ cursor: "pointer" }}
                                    className="text-center shadow-none border border-primary  border-3"
                                >
                                    <Row className='g-0'>
                                        <Col md="4" sm='4' xs='4' className="bg-primary text-white d-flex align-items-center justify-content-around">
                                            <FontAwesomeIcon icon={faUsers} style={{ fontSize: "40px" }} />
                                        </Col>
                                        <Col md="8" sm='8' xs='8'>
                                            <h1 className='text-primary fw-bold'> {totalContact || 0}</h1>
                                            <h5 className='text-primary fw-bold text-uppercase'>Total <br />Contacts</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl='3' lg='4' md='4' sm='6' xs='10' className='pt-4'>
                                <Card
                                    style={{ cursor: "pointer" }}
                                    className="text-center shadow-none border border-success  border-3"
                                    onClick={handleClickSubscribe}
                                >
                                    <Row className='g-0'>
                                        <Col md="4" sm='4' xs='4' className="bg-success text-white d-flex align-items-center justify-content-around">
                                            <FontAwesomeIcon icon={faUserCheck} style={{ fontSize: "40px" }} />
                                        </Col>
                                        <Col md="8" sm='8' xs='8'>
                                            <h1 className='text-success fw-bold'> {optInCount || 0}</h1>
                                            <h5 className='text-success fw-bold text-uppercase'>Subscribed <br />Contacts</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl='3' lg='4' md='4' sm='6' xs='10' className='pt-4'>
                                <Card
                                    style={{ cursor: "pointer" }}
                                    className="text-center shadow-none border border-danger  border-3"
                                    onClick={handleClickUnsubscribe}
                                >
                                    <Row className='g-0'>
                                        <Col md="4" sm='4' xs='4' className="bg-danger text-white d-flex align-items-center justify-content-around">
                                            <FontAwesomeIcon icon={faUserTimes} style={{ fontSize: "40px" }} />
                                        </Col>
                                        <Col md="8" sm='8' xs='8'>
                                            <h1 className='text-danger fw-bold'> {optOutCount || 0}</h1>
                                            <h5 className='text-danger fw-bold text-uppercase'>Unsubscribed <br />Contacts</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='justify-content-xl-around'>
                            <Col xl='3' lg='4' md='4' sm='6' xs='10' className='pt-4'>
                                <Card
                                    style={{ cursor: "pointer" }}
                                    className="text-center shadow-none border border-dark  border-3"
                                    onClick={handleClickTotalCampaign}
                                >
                                    <Row className='g-0'>
                                        <Col md="4" sm='4' xs='4' className="bg-dark text-white d-flex align-items-center justify-content-around">
                                            <FontAwesomeIcon icon={faMailBulk} style={{ fontSize: "40px" }} />
                                        </Col>
                                        <Col md="8" sm='8' xs='8'>
                                            <h1 className='text-dark fw-bold'> {totalCampaign || 0}</h1>
                                            <h5 className='text-dark fw-bold text-uppercase'>Total <br />Campaigns</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl='3' lg='4' md='4' sm='6' xs='10' className='pt-4'>
                                <Card
                                    style={{ cursor: "pointer" }}
                                    className="text-center shadow-none border border-warning  border-3"
                                    onClick={handleClickOpenCampaign}
                                >
                                    <Row className='g-0'>
                                        <Col md="4" sm='4' xs='4' className="bg-warning text-white d-flex align-items-center justify-content-around">
                                            <FontAwesomeIcon icon={faEnvelopeOpenText} style={{ fontSize: "40px" }} />
                                        </Col>
                                        <Col md="8" sm='8' xs='8'>
                                            <h1 className='text-warning fw-bold'>  {openCampaign || 0}</h1>
                                            <h5 className='text-warning fw-bold text-uppercase'>Open <br />Campaigns</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xl='3' lg='4' md='4' sm='6' xs='10' className='pt-4'>
                                <Card
                                    style={{ cursor: "pointer" }}
                                    className="text-center shadow-none border border-secondary  border-3"
                                    onClick={handleClickClosedCampaign}
                                >
                                    <Row className='g-0'>
                                        <Col md="4" sm='4' xs='4' className="bg-secondary text-white d-flex align-items-center justify-content-around">
                                            <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: "40px" }} />
                                        </Col>
                                        <Col md="8" sm='8' xs='8'>
                                            <h1 className='text-secondary fw-bold'> {closedCampaign || 0}</h1>
                                            <h5 className='text-secondary fw-bold text-uppercase'>Closed <br />Campaigns</h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            </Card>
        </div>
    )
}

const mapStateToProps = (state) => ({
    mmDashboardCounts: state.massmailerDashboard.DashboardCounts,
    errorMessage: state.massmailerDashboard.dashboardErrorMessage,
    isDashboardPending: state.massmailerDashboard.isDashboardPending,
})

const mapDispatchToProps = (dispatch) => ({
    dashboardCounts: () => dispatch(dashboardCounts())
})

export default connect(mapStateToProps, mapDispatchToProps)(MMDashboard);