import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Row
} from 'reactstrap'
import VWModel from '../../../../components/Modal/VWModal'
import { Link, useParams } from 'react-router-dom'
import EditCampaign from './EditCampaign'
import {
    closeEditCampaignModal,
    getCampaignContactsCount,
    getCampaignDetails,
    getHTMLFile,
    openEditCampaignModal
} from '../../../../store/Modules/MassMailer/Campaign/actions'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import moment from 'moment-timezone'
import * as FileSaver from "file-saver";
import VWSpinner from '../../../../components/vwSpinner/vwSpinner'
import { CAMPAIGN_CONTACT_EXPORT_HEADERS } from '../../../../store/Modules/MassMailer/Campaign/constants'
import { excelPrint } from '../../../../helper/export'
import UITooltip from '../../../../CommonData/Data/UITooltip'
import CampaignContactList from './CampaignContactList'


const CampaignDetails = (props) => {
    const params = useParams();
    const { id } = params;
    const { campaignDetails, HTMLFile, isOpenEditCampaign, currentProfile, errorMessage, HTMLFileError } = props || {};
    const { userAppDetails } = currentProfile || {};
    let isAdmin;

    const crmApps = userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "crm");
    if (crmApps.length > 0) {
        const Roles = crmApps[0];
        const { appRoleDetails } = Roles || {};
        isAdmin = appRoleDetails.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
    }

    useEffect(() => {
        const { indexFileName } = campaignDetails || {};
        props.getCampaignContactsCount(id);
        props.getCampaignDetails(id);
        if (indexFileName) {
            props.getHTMLFile(id);
        }
    }, [id]);

    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleOpenEditCampaign = () => {
        props.openEditCampaignModal();
    }

    const handleClose = () => {
        props.closeEditCampaignModal();
    };

    //--------------------------------------------Download-------------------------------------------------------------
    const handleHTMLView = () => {
        if (HTMLFile) {
            const html = new Blob([HTMLFile], { type: 'text/html' });;
            const url = URL.createObjectURL(html);
            window.open(url, '_blank');
        }
    }
    const handleDownload = () => {
        const fileName = campaignDetails && campaignDetails.indexFileName;
        if (HTMLFile) {
            const html = new Blob([HTMLFile]);
            FileSaver.saveAs(html, fileName);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>CAMPAIGN DETAILS</strong>
                        {isAdmin &&
                            <Link
                                type="button"
                                className="btn btn-info"
                                onClick={handleOpenEditCampaign}
                            >
                                Edit
                            </Link>}
                    </CardTitle>
                    <CardBody className="p-4">
                        {errorMessage && (
                            <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
                        )}
                        {props.isCampaignDetailsPending ?
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                            :
                            <Row>
                                <div>
                                    <div className="p-3 bg-light fw-bold fs-4">
                                        <span className='text-capitalize'>{campaignDetails && campaignDetails.campaignName || "NA"}</span>
                                    </div>
                                </div>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Campaign Name</strong>
                                                            </td>
                                                            <td>
                                                                {(campaignDetails && campaignDetails.campaignName) || "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>From Display Name</strong>
                                                            </td>
                                                            <td>
                                                                {(campaignDetails && campaignDetails.fromDisplayName) || "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>From Email Address</strong>
                                                            </td>
                                                            <td>
                                                                {(campaignDetails && campaignDetails.emailAddress) || "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: "200px" }}>
                                                                <strong>Subject</strong>
                                                            </td>
                                                            <td>
                                                                {(campaignDetails && campaignDetails.subject) || "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Mail Category</strong>
                                                            </td>
                                                            <td>
                                                                {(campaignDetails && campaignDetails.type) || "N/A"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>HTML File</strong>
                                                            </td>
                                                            <td className={campaignDetails && campaignDetails.indexFileName ? 'py-0' : ""}>
                                                                {(campaignDetails && campaignDetails.indexFileName && !HTMLFileError) ?
                                                                    <div className='d-flex align-items-center my-0'>
                                                                        < Link
                                                                            style={{ fontSize: "14px" }}
                                                                            onClick={handleHTMLView}                                                                        >
                                                                            {campaignDetails && campaignDetails.indexFileName}
                                                                        </Link>
                                                                        <UITooltip id='downloadCampignHTML' key='downloadCampignHTML' content="Download as .html">
                                                                            <Link
                                                                                style={{ fontSize: "30px", marginLeft: "5px" }}
                                                                                onClick={handleDownload}
                                                                            >
                                                                                <i className='mdi mdi-download-box text-success' />
                                                                            </Link>
                                                                        </UITooltip>
                                                                    </div>
                                                                    : "NA"
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Organization Name</strong>
                                                            </td>
                                                            <td className='text-capitalize'>
                                                                {(campaignDetails && campaignDetails.orgName) || "N/A"}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <strong>Status</strong>
                                                            </td>
                                                            <td>
                                                                {(campaignDetails && campaignDetails.status) || "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Created By</strong>
                                                            </td>
                                                            <td>
                                                                {(campaignDetails && campaignDetails.createdBy) || "N/A"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Created Time</strong>
                                                            </td>
                                                            <td>
                                                                {(campaignDetails && campaignDetails.createdTimeStamp) ? moment(campaignDetails && campaignDetails.createdTimeStamp).format('DD MMM YYYY') : "N/A"}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                </Card>
                <CampaignContactList campaignId={id} />
                <VWModel
                    header="EDIT CAMPAIGN"
                    isOpen={isOpenEditCampaign}
                    handleClose={handleClose}
                >
                    <EditCampaign
                        handleClose={handleClose}
                    />
                </VWModel>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = (state) => ({
    campaignDetails: state.campaign.campaignDetails,
    isCampaignDetailsPending: state.campaign.isCampaignDetailsPending,
    errorMessage: state.campaign.campaignDetailsError,
    HTMLFile: state.campaign.HTMLFile,
    HTMLFileError: state.campaign.HTMLFileError,
    currentProfile: state.login.currentProfile,
    isOpenEditCampaign: state.campaign.isOpenEditCampaign,
});

const mapDispatchToProps = (dispatch) => ({
    openEditCampaignModal: () => dispatch(openEditCampaignModal()),
    closeEditCampaignModal: () => dispatch(closeEditCampaignModal()),
    getCampaignDetails: (id) => dispatch(getCampaignDetails(id)),
    getCampaignContactsCount: (id) => dispatch(getCampaignContactsCount(id)),
    getHTMLFile: (id) => dispatch(getHTMLFile(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetails)