export const SET_HOLIDAY_MASTER_PENDING = "SET_HOLIDAY_MASTER_PENDING";
export const SET_HOLIDAY_MASTER_LIST = "SET_HOLIDAY_MASTER_LIST";
export const CLEAR_HOLIDAY_MASTER_LIST_ERROR = "CLEAR_HOLIDAY_MASTER_LIST_ERROR";
export const SET_HOLIDAY_MASTER_LIST_ERROR = "SET_HOLIDAY_MASTER_LIST_ERROR";
export const SET_HOLIDAY_MASTER_DETAILS_PENDING = "SET_HOLIDAY_MASTER_DETAILS_PENDING";
export const SET_HOLIDAY_MASTER_DETAILS = "SET_HOLIDAY_MASTER_DETAILS";
export const CLEAR_HOLIDAY_MASTER_DETAILS_ERROR = "CLEAR_HOLIDAY_MASTER_DETAILS_ERROR";
export const SET_HOLIDAY_MASTER_DETAILS_ERROR = "SET_HOLIDAY_MASTER_DETAILS_ERROR";
export const OPEN_CREATE_HOLIDAY_MODAL = "OPEN_CREATE_HOLIDAY_MODAL";
export const CLOSE_CREATE_HOLIDAY_MODAL = "CLOSE_CREATE_HOLIDAY_MODAL";
export const CREATE_HOLIDAY_MASTER_ERROR = "CREATE_HOLIDAY_MASTER_ERROR";
export const CREATE_HOLIDAY_MASTER_PENDING = "CREATE_HOLIDAY_MASTER_PENDING";
export const CLEAR_HOLIDAY_MASTER_ERROR = "CLEAR_HOLIDAY_MASTER_ERROR";
export const CREATE_HOLIDAY_MASTER_ACTION_SUCCESS = "CREATE_HOLIDAY_MASTER_ACTION_SUCCESS";
export const OPEN_EDIT_HOLIDAY_MODAL = "OPEN_EDIT_HOLIDAY_MODAL";
export const CLOSE_EDIT_HOLIDAY_MODAL = "CLOSE_EDIT_HOLIDAY_MODAL";
export const CLEAR_UPDATE_HOLIDAY_MASTER_ERROR = "CLEAR_UPDATE_HOLIDAY_MASTER_ERROR";
export const UPDATE_HOLIDAY_MASTER_PENDING = "UPDATE_HOLIDAY_MASTER_PENDING";
export const UPDATE_HOLIDAY_MASTER_SUCCESS = "UPDATE_HOLIDAY_MASTER_SUCCESS";
export const UPDATE_HOLIDAY_MASTER_ERROR = "UPDATE_HOLIDAY_MASTER_ERROR";
export const CLOSE_DELETE_HOLIDAY_MODAL = "CLOSE_DELETE_HOLIDAY_MODAL";
export const OPEN_DELETE_HOLIDAY_MODAL = "OPEN_DELETE_HOLIDAY_MODAL";
export const DELETE_HOLIDAY_ERROR = "DELETE_HOLIDAY_ERROR";
export const DELETE_HOLIDAY_PENDING = "DELETE_HOLIDAY_PENDING";
export const SET_HOLIDAY_MASTER_EXPORT_PENDING = "SET_HOLIDAY_MASTER_EXPORT_PENDING";
export const SET_HOLIDAY_MASTER_EXPORT_LIST = "SET_HOLIDAY_MASTER_EXPORT_LIST";
export const CLEAR_HOLIDAY_MASTER_EXPORT_LIST_ERROR = "CLEAR_HOLIDAY_MASTER_EXPORT_LIST_ERROR";
export const SET_HOLIDAY_MASTER_EXPORT_LIST_ERROR = "SET_HOLIDAY_MASTER_EXPORT_LIST_ERROR";




export const HOLIDAY_MASTER_EXPORT_HEADERS = [
    "HOLIDAY NAME",
    "DATE",
];