//--------------------VISIT-TYPE-CREATE-----------------------------------
export const VISIT_TYPE_CREATE_OPEN = "VISIT_TYPE_CREATE_OPEN";
export const VISIT_TYPE_CREATE_CLOSE = "VISIT_TYPE_CREATE_CLOSE";
export const VISIT_TYPE_CREATE_PENDING = "VISIT_TYPE_CREATE_PENDING";
export const VISIT_TYPE_CREATE_ERROR = "VISIT_TYPE_CREATE_ERROR";
export const VISIT_TYPE_CREATE_ERROR_CLEAR = "VISIT_TYPE_CREATE_ERROR_CLEAR";
export const VISIT_TYPE_CREATE_SUCCESS = "VISIT_TYPE_CREATE_SUCCESS";


//--------------------VISIT-TYPE-LIST-----------------------------------
export const VISIT_TYPE_LIST_PENDING = "VISIT_TYPE_LIST_PENDING";
export const VISIT_TYPE_LIST_ERROR = "VISIT_TYPE_LIST_ERROR";
export const VISIT_TYPE_LIST_ERROR_CLEAR = "VISIT_TYPE_LIST_ERROR_CLEAR";
export const VISIT_TYPE_LIST = "VISIT_TYPE_LIST";

//---------------------VIST TYPE UPDATE-----------------------------------

export const VISIT_TYPE_UPDATE_OPEN = "VISIT_TYPE_UPDATE_OPEN";
export const VISIT_TYPE_UPDATE_CLOSE = "VISIT_TYPE_UPDATE_CLOSE";
export const VISIT_TYPE_UPDATE_PENDING = "VISIT_TYPE_UPDATE_PENDING";
export const VISIT_TYPE_UPDATE_ERROR = "VISIT_TYPE_UPDATE_ERROR";
export const VISIT_TYPE_UPDATE_ERROR_CLEAR = "VISIT_TYPE_UPDATE_ERROR_CLEAR";
export const VISIT_TYPE_UPDATE_SUCCESS = "VISIT_TYPE_UPDATE_SUCCESS";
export const SET_VISIT_TYPE_DETAILS = "SET_VISIT_TYPE_DETAILS";
