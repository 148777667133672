import React from 'react'
import OverAllDocsList from '../../OverAllDocs/OverAllDocsList'
import { connect } from 'react-redux'

const EmployeeDocs = (props) => {
  const { userAppDetails} = props.profile;

    // const AppID = '3001241807142921982'

  const App = userAppDetails.filter((i)=>{
    
    return (i.appName === "Employee") ? i.appId:""
  })

  // console.log("currentProfile:", props.profile);
  // console.log("AppID:", App[0].appId);

  return (
    <OverAllDocsList data={App[0]  && App[0].appId}/>
  )
}   
const mapStateToProps = (state) => ({
  profile: state.login.currentProfile,
})

export default connect(mapStateToProps,null)(EmployeeDocs)