import { CHANGE_PASSWORD_BY_ADMIN_ERROR, CHANGE_PASSWORD_BY_ADMIN_PENDING, CLEAR_CHANGE_PASSWORD_BY_ADMIN_ERROR, CLEAR_CREATE_ORG_USER_ERROR, CLEAR_ORG_USER_DETAILS_ERROR, CLEAR_ORG_USER_EXPORT_LIST_ERROR, CLEAR_ORG_USER_LIST_ERROR, CLEAR_SEND_RESET_PASSWORD_ERROR, CLEAR_UPDATE_ORG_USER_ERROR, CLOSE_CHANGE_PASSWORD_BY_ADMIN_MODAL, CLOSE_CREATE_ORG_USER_MODAL, CLOSE_EDIT_ORG_USER_MODAL, CREATE_ORG_USER_ERROR, CREATE_ORG_USER_PENDING, CREATE_ORG_USER_SUCCESS, OPEN_CHANGE_PASSWORD_BY_ADMIN_MODAL, OPEN_CREATE_ORG_USER_MODAL, OPEN_EDIT_ORG_USER_MODAL, SEND_RESET_PASSWORD_ERROR, SEND_RESET_PASSWORD_PENDING, SEND_RESET_PASSWORD_SUCCESS, SET_ORG_USER_DETAILS, SET_ORG_USER_DETAILS_ERROR, SET_ORG_USER_DETAILS_PENDING, SET_ORG_USER_EXPORT_LIST, SET_ORG_USER_EXPORT_LIST_ERROR, SET_ORG_USER_EXPORT_PENDING, SET_ORG_USER_LIST, SET_ORG_USER_LIST_ERROR, SET_ORG_USER_PENDING, UPDATE_ORG_USER_ERROR, UPDATE_ORG_USER_PENDING, UPDATE_ORG_USER_SUCCESS } from "./constants";

const initialState = {
    errorMessage: null,
    isPending: false,
    isCreateOrgUserModalOpen: false,
    isEditOrgUserModalOpen: false,
    OrganizationUserList: [],
    orgListError: null,
    totalRecords: 0,
    OrgUserDetails: null,
    orgDetailsError: null,
    updateOrgUserErrorMessage: null,
    isUpdateOrgUserPending: false,
    isOrgUserListPending: false,
    isOrgUserDetailsPending: false,
    isOrgUserExportListPending: false,
    orgListExportError: null,
    OrganizationUserExportList: [],
    //----------------------------------------RESET-PASSWORD----------------------------------------------------------------------------------------------
    isResetPasswordPending: false,
    resetPasswordError: null,
    //----------------------------------------CHANGE-PASSWORD---------------------------------------------------------------------------------------------
    isChangePasswordByAdminModalOpen: false,
    isChangePasswordByAdminPending: false,
    isChangePasswordByAdminErrorMessage: null,
};
export default (localState = initialState, action) => {
    switch (action.type) {

        case OPEN_CREATE_ORG_USER_MODAL: {
            return {
                ...localState,
                isCreateOrgUserModalOpen: true,
            };
        }
        case OPEN_EDIT_ORG_USER_MODAL: {
            return {
                ...localState,
                isEditOrgUserModalOpen: true,
            };
        }

        case CLOSE_CREATE_ORG_USER_MODAL: {
            return {
                ...localState,
                errorMessage: null,
                isCreateOrgUserModalOpen: false,
            };
        }
        case CLOSE_EDIT_ORG_USER_MODAL: {
            return {
                ...localState,
                updateOrgUserErrorMessage: null,
                isEditOrgUserModalOpen: false,
            };
        }
        case SET_ORG_USER_LIST: {
            const { orgUserList } = action.payload;
            return {
                ...localState,
                isOrgUserListPending: false,
                OrganizationUserList: orgUserList && orgUserList.value,
                totalRecords: orgUserList["@odata.count"],
            }
        }
        case SET_ORG_USER_EXPORT_LIST: {
            const { orgUserExportList } = action.payload;
            return {
                ...localState,
                isOrgUserExportListPending: false,
                OrganizationUserExportList: orgUserExportList,
                // totalRecords: orgUserList["@odata.count"],
            }
        }
        case SET_ORG_USER_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isOrgUserListPending: false,
                orgListError: errorMessage,
            }
        }
        case SET_ORG_USER_EXPORT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isOrgUserExportListPending: false,
                orgListExportError: errorMessage,
            }
        }
        case CLEAR_ORG_USER_LIST_ERROR: {
            return {
                ...localState,
                orgListError: null,
            }
        }
        case CLEAR_ORG_USER_EXPORT_LIST_ERROR: {
            return {
                ...localState,
                orgListExportError: null,
                isOrgUserExportListPending: false,
            }
        }
        case SET_ORG_USER_DETAILS: {
            const { OrgUserDetails } = action.payload;
            return {
                ...localState,
                isOrgUserDetailsPending: false,
                OrgUserDetails: OrgUserDetails,
            }
        }
        case SET_ORG_USER_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                OrgUserDetails: {},
                isOrgUserDetailsPending: false,
                orgDetailsError: errorMessage,
            }
        }
        case CLEAR_ORG_USER_DETAILS_ERROR: {
            return {
                ...localState,
                orgDetailsError: null,
            }
        }
        case CREATE_ORG_USER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case CREATE_ORG_USER_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case CREATE_ORG_USER_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case CLEAR_CREATE_ORG_USER_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CLEAR_UPDATE_ORG_USER_ERROR: {
            return {
                ...localState,
                updateOrgUserErrorMessage: null,
                isUpdateOrgUserPending: false,
            };
        }
        case UPDATE_ORG_USER_PENDING: {
            return {
                ...localState,
                isUpdateOrgUserPending: true,
            };
        }
        case UPDATE_ORG_USER_SUCCESS: {
            return {
                ...localState,
                isUpdateOrgUserPending: false,
            };
        }
        case UPDATE_ORG_USER_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateOrgUserErrorMessage: errorMessage,
                isUpdateOrgUserPending: false,
            };
        }
        case SET_ORG_USER_PENDING: {
            return {
                ...localState,
                orgListError: null,
                isOrgUserListPending: true
            }
        }
        case SET_ORG_USER_EXPORT_PENDING: {
            return {
                ...localState,
                orgListExportError: null,
                isOrgUserExportListPending: true
            }
        }
        case SET_ORG_USER_DETAILS_PENDING: {
            return {
                ...localState,
                orgDetailsError: null,
                isOrgUserDetailsPending: true
            }
        }
        //----------------------------------------RESET-PASSWORD----------------------------------------------------------------------------------------------
        case SEND_RESET_PASSWORD_PENDING: {
            return {
                ...localState,
                isResetPasswordPending: true,
            };
        }
        case SEND_RESET_PASSWORD_SUCCESS: {
            return {
                ...localState,
                isResetPasswordPending: false,
            };
        }
        case SEND_RESET_PASSWORD_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                resetPasswordError: errorMessage,
                isResetPasswordPending: false,
            };
        }
        case CLEAR_SEND_RESET_PASSWORD_ERROR: {
            return {
                ...localState,
                resetPasswordError: null,
                isResetPasswordPending: false,
            };
        }

        //-----------------------------------------------------DIRECT-PASSWORD-FORM---------------------------------------------------------------------------------
        case OPEN_CHANGE_PASSWORD_BY_ADMIN_MODAL: {
            return {
                ...localState,
                isChangePasswordByAdminModalOpen: true,
            };
        }

        case CLOSE_CHANGE_PASSWORD_BY_ADMIN_MODAL: {
            return {
                ...localState,
                isChangePasswordByAdminModalOpen: false,
            };
        }
        case CHANGE_PASSWORD_BY_ADMIN_PENDING: {
            return {
                ...localState,
                isChangePasswordByAdminPending: true,
            };
        }
        case CHANGE_PASSWORD_BY_ADMIN_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                isChangePasswordByAdminErrorMessage: errorMessage,
                isChangePasswordByAdminPending: false,
            };
        }
        case CLEAR_CHANGE_PASSWORD_BY_ADMIN_ERROR: {
            return {
                ...localState,
                isChangePasswordByAdminErrorMessage: null,
                isChangePasswordByAdminPending: false,
            };
        }

        default: {
            return localState;
        }
    }
};