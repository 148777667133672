const MassMailerSidebarData = [
    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/massmailer/dashboard",
    },
    {
        label: "Managements",
        isMainMenu: true,
    },
    {
        label: "Contacts",
        icon: "mdi mdi-phone",
        isHasArrow: true,
        url: "/massmailer/contacts",
    },
    {
        label: "Actions",
        isMainMenu: true,
    },
    {
        label: "Campaigns",
        icon: "mdi mdi-clipboard-list-outline",
        isHasArrow: true,
        url: "/massmailer/campaigns",
    },
    {
        label: "Settings",
        isMainMenu: true,
    },
    {
        label: "Mail Settings",
        icon: "mdi mdi-email-plus-outline",
        isHasArrow: true,
        url: "/massmailer/mail-settings",
    },
]
const MassMailerUserSidebarData = [
    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-view-dashboard-outline",
        isHasArrow: true,
        url: "/massmailer/dashboard",
    },
    {
        label: "Managements",
        isMainMenu: true,
    },
    {
        label: "Contacts",
        icon: "mdi mdi-phone",
        isHasArrow: true,
        url: "/massmailer/contacts",
    },
    {
        label: "Actions",
        isMainMenu: true,
    },
    {
        label: "Campaigns",
        icon: "mdi mdi-clipboard-list-outline",
        isHasArrow: true,
        url: "/massmailer/campaigns",
    },
]
export { MassMailerSidebarData, MassMailerUserSidebarData };