import { getToken } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { LEAD_MANDATORY_LIST_ERROR, LEAD_MANDATORY_LIST_ERROR_CLEAR, LEAD_MANDATORY_LIST_PENDING, LEAD_MANDATORY_UPDATE_CLOSE, LEAD_MANDATORY_UPDATE_ERROR, LEAD_MANDATORY_UPDATE_ERROR_CLEAR, LEAD_MANDATORY_UPDATE_OPEN, LEAD_MANDATORY_UPDATE_PENDING, LEAD_MANDATORY_UPDATE_SUCCESS, SET_LEAD_MANDATORY_DETAILS, SET_LEAD_MANDATORY_LIST } from "./constants";



//--------------------LEADS-CATEGORY-LIST-----------------------------------
export const leadMandfatoryfieldsListPending = () => ({
    type: LEAD_MANDATORY_LIST_PENDING
})
export const leadMandatoryListError = (errorMessage) => ({
    type: LEAD_MANDATORY_LIST_ERROR,
    payload: { errorMessage }
})
export const clearLeadMandatoryListError = () => ({
    type: LEAD_MANDATORY_LIST_ERROR_CLEAR,
})
export const setLeadMandatoryList = (leadMandatoryList) => ({
    type: SET_LEAD_MANDATORY_LIST,
    payload: { leadMandatoryList }
})

export const getLeadMandatoryFieldsList = () => (dispatch) => {
    dispatch(leadMandfatoryfieldsListPending());
    dispatch(clearLeadMandatoryListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getLeadMandatoryList(accessToken)
            .then((result) => {
                dispatch(setLeadMandatoryList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(leadMandatoryListError(error));
                console.log('lead mandatory server error', error);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------LEAD MANDATORY - UPDATE -----------------------------------
export const openEditLeadMandatoryModal = () => ({
    type: LEAD_MANDATORY_UPDATE_OPEN
})
export const closeEditLeadMandatoryModal = () => ({
    type: LEAD_MANDATORY_UPDATE_CLOSE
})
export const updateLeadMandatoryPending = () => ({
    type: LEAD_MANDATORY_UPDATE_PENDING
})
export const updateLeadMandatoryError = (errorMessage) => ({
    type: LEAD_MANDATORY_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateLeadMandatoryError = () => ({
    type: LEAD_MANDATORY_UPDATE_ERROR_CLEAR,
})
export const updateLeadMandatorySuccess = () => ({
    type: LEAD_MANDATORY_UPDATE_SUCCESS,
})
export const setLeadMandatoryDetails = (leadMandatoryDetails) => ({
    type: SET_LEAD_MANDATORY_DETAILS,
    payload: { leadMandatoryDetails }
})

export const updateLeadMandatory = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateLeadMandatoryError('Please fill required details.'));
    }
    // else {
    //     dataToSend = trimmedValues({ ...formFields, })
    // }
    dispatch(updateLeadMandatoryPending());
    dispatch(clearUpdateLeadMandatoryError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateLeadMandatory(accessToken, formFields)
            .then(() => {
                dispatch(getLeadMandatoryFieldsList());
                dispatch(updateLeadMandatorySuccess());
                dispatch(closeEditLeadMandatoryModal());
                dispatch(showSnackbarStatus('Lead mandatory updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateLeadMandatoryError(err.response && err.response.text));
                    return;
                }
                console.log('Update lead category server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
