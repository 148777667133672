import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearCreateLeadSourceError,
    createLeadSourceSuccess,
    createNewLeadSource
} from '../../../../../../store/Modules/crm/Settings/LeadSource/actions';

const AddLeadSource = (props) => {
    const { isLeadSourceCreatePending, errorMessage } = props;
    const [leadSourceDetails, setLeadSourceDetails] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        props.clearCreateLeadSourceError();
        props.createLeadSourceSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setLeadSourceDetails({
            ...leadSourceDetails,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: null
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { leadSourceName } = leadSourceDetails || {};

        if (!leadSourceName) {
            currentErrors.leadSourceName = 'Lead source name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createLeadSource(leadSourceDetails)
        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label>Lead Source Name <span className='text-danger'>*</span></Label>
                <Input
                    type="text"
                    name="leadSourceName"
                    placeHolder="Enter lead source name"
                    onChange={validateOnchange}
                    value={leadSourceDetails && leadSourceDetails.leadSourceName}
                    invalid={errors && errors.leadSourceName}
                />
                <FormFeedback>{errors && errors.leadSourceName}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isLeadSourceCreatePending}
                >
                    {isLeadSourceCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    isLeadSourceCreatePending: state.crmLeadSource.isLeadSourceCreatePending,
    errorMessage: state.crmLeadSource.leadSourceCreateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    createLeadSource: (industryDetails) => dispatch(createNewLeadSource(industryDetails)),
    clearCreateLeadSourceError: () => dispatch(clearCreateLeadSourceError()),
    createLeadSourceSuccess: () => dispatch(createLeadSourceSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddLeadSource);