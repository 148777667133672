import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";


const PieChart = ({ labels, value }) => {
  const colors = [
    { labels: "New", value: "#64B5F6" },
    { labels: "InProgress", value: "#FFB300" },
    { labels: "OnHold", value: "#FF5252" },
    { labels: "Internal Testing", value: "#FF4081" },
    { labels: "Internal Testing Done", value: "#7C4DFF" },
    { labels: "Waiting For Approval", value: "#FF7043" },
    { labels: "Done", value: "#00796B" },
  ]
  const filterColor = colors.filter((color) =>
    color.labels.includes(color.labels)
  )

  const PieChartData = {
    series: value,
    options: {
      labels: filterColor.map((items) => items.labels),
      colors: filterColor && filterColor.map((items) => items.value),
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  }


  return (
    <React.Fragment>
      <ReactApexChart
        options={PieChartData && PieChartData.options}
        series={value}
        type="pie"
        width={260}
        // height="250"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

export default PieChart;
