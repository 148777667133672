import { getToken, modifyAccountCategory, modifyAccountCategoryList, modifyAccountCategoryValue, modifyCompanyNameList, modifyIndustryNameList, modifyRegionList, modifyUserList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import {
    ACCOUNT_CREATE_CLOSE,
    ACCOUNT_CREATE_ERROR,
    ACCOUNT_CREATE_ERROR_CLEAR,
    ACCOUNT_CREATE_OPEN,
    ACCOUNT_CREATE_PENDING,
    ACCOUNT_CREATE_SUCCESS,
    ACCOUNT_DETAILS_ERROR,
    ACCOUNT_DETAILS_ERROR_CLEAR,
    ACCOUNT_DETAILS_PENDING,
    ACCOUNT_EXPORT_LIST_ERROR,
    ACCOUNT_EXPORT_LIST_ERROR_CLEAR,
    ACCOUNT_EXPORT_LIST_PENDING,
    ACCOUNT_EXPORT_LIST_SUCCESS,
    ACCOUNT_LIST_ERROR,
    ACCOUNT_LIST_ERROR_CLEAR,
    ACCOUNT_LIST_PENDING,
    ACCOUNT_UPDATE_CLOSE,
    ACCOUNT_UPDATE_ERROR,
    ACCOUNT_UPDATE_ERROR_CLEAR,
    ACCOUNT_UPDATE_OPEN,
    ACCOUNT_UPDATE_PENDING,
    ACCOUNT_UPDATE_SUCCESS,
    IS_IMPORT_ACCOUNT_PENDING,
    IS_IMPORT_ACCOUNT_SUCCESS,
    SET_ACCOUNT_DETAILS,
    SET_ACCOUNT_EXPORT_LIST,
    SET_ACCOUNT_LIST,
    SET_CONTACT_DETAILS,
    SET_CONTACT_LIST,
    SET_LEAD_DETAILS
} from "./constants";

//--------------------ACCOUNT-CREATE-----------------------------------
export const openAccountCreate = () => ({
    type: ACCOUNT_CREATE_OPEN
})
export const closeAccountCreate = () => ({
    type: ACCOUNT_CREATE_CLOSE
})
export const createAccountPending = () => ({
    type: ACCOUNT_CREATE_PENDING
})
export const createAccountError = (errorMessage) => ({
    type: ACCOUNT_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateAccountError = () => ({
    type: ACCOUNT_CREATE_ERROR_CLEAR,
})
export const createAccountSuccess = () => ({
    type: ACCOUNT_CREATE_SUCCESS,
})


export const getCompanyNameAsyncSelect = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getCompanyName(accessToken, searchValue)
                .then((UserList) => {
                    const { value } = UserList;
                    const updatedUserList = modifyCompanyNameList(value);
                    //eslint-disable-next-line
                    callback && callback(updatedUserList);
                })
                .catch((err) => {
                    console.log('getCompanyNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getIndustryNameAsyncSelect = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getIndustryName(accessToken, searchValue)
                .then((List) => {
                    const { value } = List;
                    let totalActiveData = [];
                    for (const list of value) {
                        const { IsActive } = list
                        if (IsActive) {
                            totalActiveData.push(list)
                        }
                    }
                    const updatedUserList = modifyIndustryNameList(totalActiveData);
                    //eslint-disable-next-line
                    callback && callback(updatedUserList);
                })
                .catch((err) => {
                    console.log('getIndustryNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getIndustryNameListAsyncSelect = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getIndustryName(accessToken, searchValue)
                .then((List) => {
                    const { value } = List;
                    const updatedUserList = modifyIndustryNameList(value);
                    //eslint-disable-next-line
                    callback && callback(updatedUserList);
                })
                .catch((err) => {
                    console.log('getIndustryNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getAccountCategoryAsyncSelect = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getAccountCategoryName(accessToken, searchValue)
                .then((List) => {
                    const { value } = List;
                    const updatedAccountList = modifyAccountCategory(value);
                    //eslint-disable-next-line
                    callback && callback(updatedAccountList);
                })
                .catch((err) => {
                    console.log('getAccountCategoryAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getAccountCategoryAsyncSelectList = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getAccountCategoryName(accessToken, searchValue)
                .then((List) => {
                    const { value } = List;
                    const updatedAccountList = modifyAccountCategoryList(value);
                    //eslint-disable-next-line
                    callback && callback(updatedAccountList);
                })
                .catch((err) => {
                    console.log('getAccountCategoryAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getAccountCategoryListAsyncSelectList = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getAccountCategoryName(accessToken, searchValue)
                .then((List) => {
                    const { value } = List;
                    const updatedAccountList = modifyAccountCategoryList(value);
                    //eslint-disable-next-line
                    callback && callback(updatedAccountList);
                })
                .catch((err) => {
                    console.log('getAccountCategoryAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getAccountCategoryAsync = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getAccountCategoryValue(accessToken, searchValue)
                .then((List) => {
                    const { value } = List;
                    let totalActiveData = [];
                    for (const list of value) {
                        const { IsActive } = list
                        if (IsActive) {
                            totalActiveData.push(list)
                        }
                    }
                    const updatedAccountList = modifyAccountCategoryValue(totalActiveData);
                    //eslint-disable-next-line
                    callback && callback(updatedAccountList);
                })
                .catch((err) => {
                    console.log('getAccountCategoryAsync server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getAccountCategoryListAsync = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getAccountCategoryValue(accessToken, searchValue)
                .then((List) => {
                    const { value } = List;
                    const updatedAccountList = modifyAccountCategoryValue(value);
                    //eslint-disable-next-line
                    callback && callback(updatedAccountList);
                })
                .catch((err) => {
                    console.log('getAccountCategoryAsync server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getRegionNameAsyncSelect = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getRegionName(accessToken, searchValue)
                .then((List) => {
                    const { value } = List;
                    let totalActiveData = [];
                    for (const list of value) {
                        const { IsActive } = list
                        if (IsActive) {
                            totalActiveData.push(list)
                        }
                    }
                    const updatedRegionList = modifyRegionList(totalActiveData);
                    //eslint-disable-next-line
                    callback && callback(updatedRegionList);
                })
                .catch((err) => {
                    console.log('getRegionNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getRegionNameListAsyncSelect = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getRegionName(accessToken, searchValue)
                .then((List) => {
                    const { value } = List;
                    const updatedRegionList = modifyRegionList(value);
                    //eslint-disable-next-line
                    callback && callback(updatedRegionList);
                })
                .catch((err) => {
                    console.log('getRegionNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };


export const getLeadDetailsAsyncSelect = (id, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getLeadDetails(accessToken, id)
                .then((value) => {
                    console.log("value:", value);
                    //eslint-disable-next-line
                    callback && callback(value);
                })
                .catch((err) => {
                    console.log('getLeadDetailsAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };

export const createNewAccount = (formFields, filteredData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createAccountError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(createAccountPending());
    dispatch(clearCreateAccountError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.createAccount(accessToken, dataToSend)
            .then((resp) => {
                console.log("resp:", resp.id);
                dispatch(createAccountSuccess());
                dispatch(closeAccountCreate());
                // dispatch(getAccountsList());
                dispatch(getAccountDetails(resp.id));
                dispatch(showSnackbarStatus('Account created successfully'));
                window.location.href = `/crm/accounts/details/${resp.id}`;
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createAccountError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createAccountError('Network Error'));
                }
                console.log('Create Account server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------ACCOUNT-UPDATE-----------------------------------
export const openAccountUpdate = () => ({
    type: ACCOUNT_UPDATE_OPEN
})
export const closeAccountUpdate = () => ({
    type: ACCOUNT_UPDATE_CLOSE
})
export const updateAccountPending = () => ({
    type: ACCOUNT_UPDATE_PENDING
})
export const updateAccountError = (errorMessage) => ({
    type: ACCOUNT_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateAccountError = () => ({
    type: ACCOUNT_UPDATE_ERROR_CLEAR,
})
export const updateAccountSuccess = () => ({
    type: ACCOUNT_UPDATE_SUCCESS,
})

export const updateAccount = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateAccountError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({
            ...formFields,
        })
    }
    dispatch(updateAccountPending());
    dispatch(clearUpdateAccountError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.updateAccount(accessToken, dataToSend)
            .then((returnId) => {
                dispatch(updateAccountSuccess());
                if (returnId) {
                    dispatch(getContactsLists(returnId.id));
                }
                dispatch(closeAccountUpdate());
                dispatch(getAccountDetails(formFields && formFields.id));
                dispatch(showSnackbarStatus('Account updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateAccountError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateAccountError('Network Error'));
                }
                console.log('Create Account server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------ACCOUNT-LSIT-----------------------------------
export const accountListPending = () => ({
    type: ACCOUNT_LIST_PENDING
})
export const isImportAccountPending = () => ({
    type: IS_IMPORT_ACCOUNT_PENDING
})
export const isImportAccountSuccess = () => ({
    type: IS_IMPORT_ACCOUNT_SUCCESS
})
export const accountExportListPending = () => ({
    type: ACCOUNT_EXPORT_LIST_PENDING
})
export const accountExportListSuccess = () => ({
    type: ACCOUNT_EXPORT_LIST_SUCCESS
})
export const accountListError = (errorMessage) => ({
    type: ACCOUNT_LIST_ERROR,
    payload: { errorMessage }
})
export const accountExportListError = (errorMessage) => ({
    type: ACCOUNT_EXPORT_LIST_ERROR,
    payload: { errorMessage }
})
export const clearAccountListError = () => ({
    type: ACCOUNT_LIST_ERROR_CLEAR,
})
export const clearAccountExportListError = () => ({
    type: ACCOUNT_EXPORT_LIST_ERROR_CLEAR,
})
export const setAccountsList = (accountsList) => ({
    type: SET_ACCOUNT_LIST,
    payload: { accountsList }
})
export const setAccountExportList = (accountsExportList) => ({
    type: SET_ACCOUNT_EXPORT_LIST,
    payload: { accountsExportList }
})


export const getAccountExportList = (filter, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo, searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue, searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo) => (dispatch) => {
    dispatch(accountExportListPending());

    return getToken(dispatch).then((accessToken) => {
        return agent.Crm.getAccountExportList(accessToken, filter, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo, searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue, searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo)
            .then((result) => {
                console.log("result:", result);
                // dispatch(setAccountExportList(result));
                dispatch(clearAccountExportListError());
                dispatch(accountExportListSuccess());
                return Promise.resolve(result);
            })
            .catch((err) => {
                console.log('getAccountsExportList server error', err.response);
                let error;
                if (err.response) {
                    error = err.response && err.response.body && err.response.body.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(accountExportListError(error));
                console.log('Create Account server error', error);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    })
}

export const getAccountsList = (filter, page, PageSize, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo,
    searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue,
    searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo) => (dispatch) => {
        dispatch(accountListPending());
        dispatch(clearAccountListError());
        getToken(dispatch).then((accessToken) => {
            agent.Crm.getAccountsList(accessToken, page, PageSize, filter, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo, searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue, searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo)
                .then((result) => {
                    dispatch(setAccountsList(result))
                })
                .catch((err) => {
                    console.log('getAccountsList server error', err);
                    console.log('getAccountsList server error', err.response && err.response.body && err.response.body.error && err.response.body.error.message);
                    let error;
                    if (err.response) {
                        error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                    }
                    else {
                        error = "Network Error"
                    }
                    dispatch(accountListError(error));
                    console.log('Create Account server error', error);
                    dispatch(closeAccountCreate());
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                });
        });
    };

//--------------------LEADS-IMPORT-----------------------------------
export const importAccounts = (excelSheet, filter) => (dispatch) => {
    dispatch(isImportAccountPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.Crm.importAccounts(accessToken, excelSheet)
            .then((result) => {
                dispatch(isImportAccountSuccess());
                dispatch(getAccountsList(filter));
                dispatch(showSnackbarStatus('Accounts imported successfully!'));
                return Promise.resolve(result)
            })
            .catch((err) => {
                dispatch(isImportAccountSuccess());
                let message;
                if (err && err.response) {
                    message = err.response && err.response.text;
                    dispatch(showSnackbarStatus(message));
                }
                else {
                    message = 'Network Error'
                    dispatch(showSnackbarStatus(message));
                }
                return Promise.reject(message)
            })
    })
}


//--------------------ACCOUNT-DETAILS-----------------------------------
export const accountDetailsPending = () => ({
    type: ACCOUNT_DETAILS_PENDING
})
export const accountDetailError = (errorMessage) => ({
    type: ACCOUNT_DETAILS_ERROR,
    payload: { errorMessage }
})
export const clearAccountDetailsError = () => ({
    type: ACCOUNT_DETAILS_ERROR_CLEAR,
})
export const setAccountsDetails = (accountDetails) => ({
    type: SET_ACCOUNT_DETAILS,
    payload: { accountDetails }
})
export const setContactList = (contactList) => ({
    type: SET_CONTACT_LIST,
    payload: { contactList }
})

export const getAccountDetails = (id) => (dispatch) => {
    dispatch(accountDetailsPending());
    dispatch(clearAccountDetailsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getAccountDetails(accessToken, id)
            .then((result) => {
                dispatch(setAccountsDetails(result))
                // dispatch(showSnackbarStatus('Account created successfully'));
            })
            .catch((err) => {
                let error;
                if (err.response) {
                    error = err.response.text;
                }
                else {
                    error = "Network Error"
                }
                dispatch(accountDetailError(error));
                console.log('Account details server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

export const getContactsLists = (id) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getAccountContactLists(accessToken, id)
            .then((result) => {
                dispatch(setContactList(result));
                console.log("result:", result);
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(accountDetailError(err.response && err.response.text));
                    return;
                }
                console.log('get account list server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

