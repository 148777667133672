import React, { useEffect, useState } from 'react'
import Async from 'react-select/async';
import VWButton from '../../../../../components/vwButton/VWButton'
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import VWMessage from '../../../../../components/vwMessage/vwMessage';
import { connect } from 'react-redux';
import { createOrgUser, createOrgUserSuccess, getOrgNameAsyncSelect } from '../../../../../store/Modules/Admin/OrgUser/actions';
import FormValidator from '../../../../../helper/formValidator';

const CreateOrguser = (props) => {

    const { currentProfile, filterData, appId } = props;
    const { orgName, orgId } = currentProfile;
    const [validation, setValidation] = useState({ emailValid: true, });
    const [formFields, setFormFields] = useState({ OrgId: orgId, IsGlobalReadOnlyUser: false });
    const [errors, setErrors] = useState({});
    const [isMailNotificationEnable, setIsMailNotificationEnable] = useState(true);

    useEffect(() => {
        props.createOrgUserSuccess();
        setFormFields({ ...formFields, enableMailNotification: true })
    }, [])

    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;

        if (input.name === 'FirstName') {
            setErrors({ ...errors, FirstName: null });
        }
        if (input.name === 'LastName') {
            setErrors({ ...errors, LastName: null });
        }

        let result = FormValidator.validate(input);
        if (input.name === 'UserEmail') {
            let emailValid = true;

            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(value)) {
                result = 'Invalid email';
                emailValid = false;
            }
            else if (prohibitedChars.test(value)) {
                result = 'Invalid email';
                emailValid = false;
            }
            else {
                emailValid = true;
            }
            setErrors({
                ...errors,
                UserEmail: emailValid ? null : result,  // Update errors directly
            });
        }
        setFormFields({
            ...formFields, [input.name]: value, errors: {
                ...(formFields && formFields.errors),
                [input.name]: result,
            },
        });
    };

    const handleAddOrgName = (value) => {
        setFormFields({ ...formFields, OrgId: value.value });
        setErrors({ ...errors, OrgId: null });
    }

    //--------------------------------------------Status---------------------------------------------------------
    const handleEmailNotificationStatusChange = () => {
        setIsMailNotificationEnable(!isMailNotificationEnable);
        setFormFields({ ...formFields, enableMailNotification: !isMailNotificationEnable })
    };
    //--------------------------------------------Create ---------------------------------------------------------
    const handleCreateOrganizationUser = () => {
        const newErrors = {};

        if (!(formFields && formFields.FirstName) || !(formFields.OrgId && formFields.FirstName.trim())) {
            newErrors.FirstName = 'First name is required';
        }
        if (!(formFields && formFields.LastName) || !(formFields.OrgId && formFields.LastName.trim())) {
            newErrors.LastName = 'Last name is required';
        }

        if (!(formFields && formFields.UserEmail) || !(formFields.UserEmail && formFields.UserEmail.trim())) {
            newErrors.UserEmail = 'Email is required';
        } else {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const prohibitedChars = /[;,!#$%^&*()[\]{}]/;

            if (!emailRegex.test(formFields.UserEmail)) {
                newErrors.UserEmail = 'Invalid email';
            } else if (prohibitedChars.test(formFields.UserEmail)) {
                newErrors.UserEmail = 'Invalid email';
            }
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0 && validation.emailValid === true) {
            const { currentProfile, filterData, appId } = props;
            props.createOrgUserAction(formFields, appId, filterData);
        }
    };
    const [isGlobalReadOnlyUser, setIsGlobalReadOnlyUser] = useState(false)
    const handleIsGlobalReadOnlyUserStatusChange = () => {
        const newIsGlobalReadOnlyUser = !isGlobalReadOnlyUser;
        setIsGlobalReadOnlyUser(newIsGlobalReadOnlyUser);
        setFormFields({ ...formFields, IsGlobalReadOnlyUser: newIsGlobalReadOnlyUser });
    };

    console.log("formFields:", formFields);
    console.log("filterData:", filterData);
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <FormGroup>
                <Label>
                    Email <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="UserEmail"
                    placeholder="Enter email"
                    invalid={errors && errors.UserEmail}
                    onChange={validateOnChange}
                    value={formFields && formFields.UserEmail}
                />
                <FormFeedback>{errors && errors.UserEmail}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label>
                    First Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="FirstName"
                    placeholder="Enter first name"
                    onChange={validateOnChange}
                    value={formFields && formFields.FirstName}
                    invalid={errors && errors.FirstName}
                />
                {<FormFeedback>{errors && errors.FirstName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>
                    Last Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="LastName"
                    placeholder="Enter last name"
                    onChange={validateOnChange}
                    invalid={errors && errors.LastName}
                    value={formFields && formFields.LastName}
                />
                {<FormFeedback>{errors && errors.LastName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>
                    <Input
                        type="checkbox"
                        onClick={handleIsGlobalReadOnlyUserStatusChange}
                    />
                    &nbsp;Is Global ReadOnly User
                </Label>
            </FormGroup>
            <FormGroup>
                <Label>Email Notification Status</Label>
                <ul className="form-unstyled-list form-status-list">
                    <li
                        onClick={handleEmailNotificationStatusChange}
                        className={`form-status-item ${!isMailNotificationEnable ? 'active' : ''}`}
                    >
                        Disable
                    </li>
                    <li
                        onClick={handleEmailNotificationStatusChange}
                        className={`form-status-item ${isMailNotificationEnable ? 'active' : ''}`}
                    >
                        Enable
                    </li>
                </ul>
            </FormGroup>
            <br></br>
            <FormGroup>
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleCreateOrganizationUser}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </FormGroup>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isPending: state.OrgUser.isPending,
    errorMessage: state.OrgUser.errorMessage,
    currentProfile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    // getOrgNameAsyncSelectAction: (searchValue, callback, orgName) =>
    //     dispatch(getOrgNameAsyncSelect(searchValue, callback, orgName)),
    createOrgUserAction: (formFields, appId, filterData) => dispatch(createOrgUser(formFields, appId, filterData)),
    createOrgUserSuccess: () => dispatch(createOrgUserSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrguser)