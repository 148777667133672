import React, { useEffect, useState } from 'react'
import VWMessage from '../../../components/vwMessage/vwMessage'
import VWButton from '../../../components/vwButton/VWButton'
import { connect } from 'react-redux';
import { clearDeleteDocsError, deleteDocsOverAllList } from '../../../store/Modules/OverAllDocs/actions';
import { Button, FormGroup } from 'reactstrap';

const OverAllDocsDelete = (props) => {
    const [DocsDeleteAction, setDocsDelete] = useState({});
    const { filteredData } =props;
    useEffect(() => {
        const { data, appID } = props;
        console.log("deleteID:", data);
        props.clearDeleteDocsError();
        setDocsDelete(data)
    }, [])

    const handleDeleteActionDocs = () => {
        props.deleteDocsList(DocsDeleteAction && DocsDeleteAction.id, props.appID, filteredData);
    };
  return (
      <React.Fragment>
          {props.errorMessage && (
              <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
          )}
          <h5>Are you sure you want to delete ?</h5>
          <br></br>
          {/* <VWButton
              messageKey="deleteVisionDocsActionButton"
              defaultMessage="  Delete  "
              buttonType="success"
              baseClassName="btn"
              className="me-2"
              onClick={handleDeleteActionDocs}
          ></VWButton>
          <VWButton
              messageKey="cancelVisionDocsButton"
              defaultMessage="Cancel"
              buttonType="danger"
              baseClassName="btn"
              onClick={props.handleClose}
          ></VWButton> */}
          <div className="form-group">
              <FormGroup>
                  <Button
                      className="me-2"
                      color="success"
                      onClick={handleDeleteActionDocs}
                      disabled={props.isPending}
                  >
                      {props.isPending ? <>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                          &nbsp; Submitting...
                      </>
                          : <>Submit</>}
                  </Button>
                  <Button
                      color="danger"
                      onClick={props.handleClose}
                  >
                      Cancel
                  </Button>
              </FormGroup>
          </div>
      </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
    errorMessage: state.OverAllDocsList.DeleteErrorMessage,
    isPending: state.OverAllDocsList.isPendingOverAllDocsPending,

});
const mapDispatchToProps = (dispatch) => ({
    deleteDocsList: (id, AppID, filteredData) => dispatch(deleteDocsOverAllList(id, AppID, filteredData)),
    clearDeleteDocsError: () => dispatch(clearDeleteDocsError()),
});
export default connect(mapStateToProps, mapDispatchToProps)(OverAllDocsDelete)