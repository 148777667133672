import React, { useEffect, useState } from 'react'
import VWButton from '../../../../components/vwButton/VWButton'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import Async from 'react-select/async';
import { Button, Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table } from 'reactstrap'
import { connect } from "react-redux";
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { ClearLeaveCount, clearLeaveCountError, clearUpdateLeaveError, getLeaveCountAction, getLeaveTypeAsyncSelect, updateLeave, updateLeaveSuccess } from '../../../../store/Modules/LeaveTracker/LeaveTracking/actions';

const Update = (props) => {
    const [prevLeaveType, setprevLeaveType] = useState({ label: '', value: '' });
    const [formFields, setFormFields] = useState({});
    // const [formFieldsstatus, formFieldsstatus] = useState(false);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        props.updateLeaveSuccess();
        if (props.LeaveDetails) {
            setFormFields({ ...props.LeaveDetails, fromDateTime: moment(props.LeaveDetails && props.LeaveDetails.fromDateTime).format("YYYY-MM-DD"), toDateTime: moment(props.LeaveDetails && props.LeaveDetails.toDateTime).format("YYYY-MM-DD") });
            setprevLeaveType({ label: props.LeaveDetails && props.LeaveDetails.leaveType, value: props.LeaveDetails && props.LeaveDetails.leaveTypeId });
        }
        props.ClearLeaveCount();
    }, [])

    useEffect(() => {
        if (props.LeaveCount) {
            setFormFields({
                ...formFields, applyingLeaveDays: props.LeaveCount && props.LeaveCount.applyingLeaveDays, totalLeaveDays: props.LeaveCount && props.LeaveCount.totalLeaveDays
                , privilegeLeaveCount: props.LeaveCount && props.LeaveCount.privilegeLeaveCount, lopLeaveCount: props.LeaveCount && props.LeaveCount.lopLeaveCount, totalLeaveCount: props.LeaveCount && props.LeaveCount.totalLeaveCount
                , holidayLeaveDays: props.LeaveCount && props.LeaveCount.holidayLeaveDays, weekOffLeaveDays: props.LeaveCount && props.LeaveCount.weekOffLeaveDays
            });
        }
    }, [props.LeaveCount])

    //----------------------------  Leave Type Default Options--------------------------------------------------
    const handleLoadLeaveType = (inputValue, callback) => {
        const { getLeaveTypeAsyncSelectAction } = props;
        return getLeaveTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    //----------------------------- Input Field Changes---------------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        setErrors({ ...errors, leaveReason: '' });
    };
    //------------------------------Updating  Date Field -------------------------------------------------------
    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;
        // const formattedDate = value ? moment(value).format() : null;
        const formattedDate = value;
        console.log("formattedDate:", formattedDate)
        setFormFields({ ...formFields, [name]: formattedDate });

        if (name === 'fromDateTime') {
            setErrors({ ...errors, fromDateTime: '' });
        }
        if (name === 'toDateTime') {
            setErrors({ ...errors, toDateTime: '' });
        }
        props.getLeaveCount({ ...formFields, [name]: formattedDate });
        props.clearUpdateLeaveError();
        props.clearLeaveCountError();
    };
    //------------------------------ Leave Type Field Changes---------------------------------------------------
    const handleAddLeaveTypeChange = (value) => {
        setFormFields({ ...formFields, leaveTypeId: value.value, leaveType: value.label });
        setprevLeaveType({ label: value.label, value: value.value });
        setErrors({ ...errors, leaveTypeId: '' });
        props.getLeaveCount(formFields);
    }
    //--------------------------------------Update Leave Form --------------------------------------------------
    const handleUpdateLeave = () => {
        const newErrors = {};
        if (!formFields.fromDateTime) {
            newErrors.fromDateTime = 'From date is required';
        }
        if (!formFields.toDateTime) {
            newErrors.toDateTime = 'To date is required';
        }
        if (!formFields.leaveTypeId) {
            newErrors.leaveTypeId = 'Leave type is required';
        }
        if (!formFields.leaveReason) {
            newErrors.leaveReason = 'Leave reason is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.EditLeaveAction(formFields);
        }
    };



    let current = moment().subtract(1, 'weeks').calendar();
    console.log("formFields:", formFields);
    console.log("createLeaveCountErrorMessage:", props.createLeaveCountErrorMessage);
    console.log("props.errorMessage:", props.errorMessage);
    return (
        <React.Fragment>
            {(props.createLeaveCountErrorMessage || props.errorMessage) && (
                <VWMessage type="danger" defaultMessage={props.createLeaveCountErrorMessage || props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <>
                <Row>
                    <div className="form-group">
                        <label className="col-form-label">
                            Leave Type <span className="text-danger"> *</span>
                        </label>
                        <Async
                            name="leaveTypeId"
                            placeholder="Select Leave Type"
                          //  className="vm-react-select"
                            isSearchable
                            defaultOptions
                            value={prevLeaveType}
                            loadOptions={handleLoadLeaveType}
                            onChange={handleAddLeaveTypeChange}
                            isDisabled
                        />
                        {<span className='text-danger'><small>{errors && errors.leaveTypeId}</small></span>}
                    </div>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">From Date <span className="text-danger">*</span></label>
                            <Input
                                type="date"
                                name="fromDateTime"
                                placeholder="Select From Date"
                                onChange={validateOnChangeDate}
                                value={moment(formFields.fromDateTime).format("YYYY-MM-DD")}
                                min={moment(current).format("YYYY-MM-DD")}
                                max={moment(formFields.toDateTime).format("YYYY-MM-DD")}
                                invalid={errors && errors.fromDateTime}
                            />
                            {<FormFeedback>{errors && errors.fromDateTime}</FormFeedback>}
                        </div>

                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <label className="col-form-label">To Date <span className="text-danger">*</span></label>
                            <Input
                                type="date"
                                name="toDateTime"
                                placeholder="Select To Date"
                                onChange={validateOnChangeDate}
                                value={moment(formFields.toDateTime).format("YYYY-MM-DD")}
                                min={moment(formFields.fromDateTime).format("YYYY-MM-DD") || moment(current).format("YYYY-MM-DD")}
                                invalid={errors && errors.toDateTime}
                            />
                            {<FormFeedback>{errors && errors.toDateTime}</FormFeedback>}
                        </div>
                    </Col>
                </Row>
                <br></br>
                {formFields.totalLeaveDays && formFields.totalLeaveDays.length > 0 || formFields.applyingLeaveDays && formFields.applyingLeaveDays.length > 0 ? (
                    <>
                        {props.createLeaveCountErrorMessage ? '' :
                            <Row>
                                <Col>
                                    <Label>LOP Count: {formFields.lopLeaveCount}</Label>
                                </Col>
                                <Col>
                                    <Label>Privilege Leave: {formFields.privilegeLeaveCount}</Label>
                                </Col>
                                <Col>
                                    <Label>Total Leave: {formFields.totalLeaveCount}</Label>
                                </Col>
                            </Row>}
                    </>
                ) : ''}
                <div className="form-group">
                    <label className="col-form-label">
                        Reason  <span className="text-danger">*</span>
                    </label>
                    <Input
                        type="text"
                        name="leaveReason"
                        placeholder="Enter Reason"
                        onChange={validateOnChange}
                        value={formFields.leaveReason}
                        invalid={errors && errors.leaveReason}
                    />
                    {<FormFeedback>{errors && errors.leaveReason}</FormFeedback>}
                </div>

                <br></br>
                <div className="form-group">
                    <FormGroup>
                        <Button
                            className="me-2"
                            color="success"
                            onClick={handleUpdateLeave}
                            disabled={props.isPending}
                        >
                            {props.isPending ? <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                &nbsp; Submitting...
                            </>
                                : <>Submit</>}
                        </Button>
                        <Button
                            color="danger"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </FormGroup>
                </div>
            </>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.leaveTracker.isUpdateLeavePending,
    errorMessage: state.leaveTracker.updateLeaveErrorMessage,
    LeaveDetails: state.leaveTracker.LeaveDetails,
    LeaveCount: state.leaveTracker.LeaveCount,
    createLeaveCountErrorMessage: state.leaveTracker.createLeaveCountErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    EditLeaveAction: (formfield) => dispatch(updateLeave(formfield)),
    getLeaveTypeAsyncSelectAction: (searchValue, callback) =>
        dispatch(getLeaveTypeAsyncSelect(searchValue, callback)),
    getLeaveCount: (formFields) => dispatch(getLeaveCountAction(formFields)),
    ClearLeaveCount: () => dispatch(ClearLeaveCount()),
    clearUpdateLeaveError: () => dispatch(clearUpdateLeaveError()),
    updateLeaveSuccess: () => dispatch(updateLeaveSuccess()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Update)