import { DASHBOARD_CLEAR_ERROR, DASHBOARD_ERROR_MESSAGE, SET_DASHBOARD_MASS_MAILER, SET_DASHBOARD_PENDING } from "./constants";

const initialState = {
    DashboardCounts: {},
    isDashboardPending: false,
    dashboardErrorMessage: null
};
export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_DASHBOARD_MASS_MAILER: {
            const { dashboard } = action.payload;
            return {
                ...localState,
                isDashboardPending: false,
                DashboardCounts: dashboard,
            }
        }
        case DASHBOARD_ERROR_MESSAGE: {
            const { dashboardErrorMessage } = action.payload;
            return {
                ...localState,
                isDashboardPending: false,
                dashboardErrorMessage: dashboardErrorMessage,
                DashboardCounts: null,
            }
        }
        case DASHBOARD_CLEAR_ERROR: {
            return {
                ...localState,
                dashboardErrorMessage: null,
            }
        }

        case SET_DASHBOARD_PENDING: {
            return {
                ...localState,
                dashboardErrorMessage: null,
                isDashboardPending: true
            }
        }
        default: {
            return localState;
        }
    }
};