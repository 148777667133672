import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CRM_DASHBOARD_CLEAR_ERROR, DASHBOARD_CRM_CLEAR_ERROR, DASHBOARD_CRM_ERROR_MESSAGE, SET_CRM_DASHBOARD_PENDING, SET_DASHBOARD_CRM } from "./constants";

export const setDashboard = (dashboard) => ({
    type: SET_DASHBOARD_CRM,
    payload: { dashboard },
});

export const setDashboardPending = () => ({
    type: SET_CRM_DASHBOARD_PENDING,
})

export const dashboardErrorMessage = (dashboardErrorMessage) => ({
    type: DASHBOARD_CRM_ERROR_MESSAGE,
    payload: { dashboardErrorMessage },
});

export const dashboardClearError = () => ({
    type: DASHBOARD_CRM_CLEAR_ERROR,
})
export const crmDashboardClearError = () => ({
    type: CRM_DASHBOARD_CLEAR_ERROR,
})

export const getCrmDashboardCount = (toggle,fromDate, toDate) => (dispatch) => {
    dispatch(setDashboardPending());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getDashboardCrm(accessToken, fromDate, toDate, toggle)
            .then((dashboardCounts) => {
                console.log("dashboardCounts:", dashboardCounts);
                dispatch(setDashboard(dashboardCounts));
                dispatch(dashboardClearError());
            })
            .catch((err) => {
                console.log('get Dashboard server error', err);
                console.log('get Dashboard server error', err.response);
                console.log('get Dashboard server error', err.response && err.response.text);
                let error;
                if (err.status) {
                    error = err.response && err.response.text 
                }
                else {
                    error = "Network Error"
                }
                dispatch(dashboardErrorMessage(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    })
}