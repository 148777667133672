import {
    CAMPAIGN_DETAILS_ERROR,
    CAMPAIGN_EDIT_ERROR,
    CAMPAIGN_LIST_ERROR,
    CLEAR_CAMPAIGN_DETAILS_ERROR,
    CLEAR_CAMPAIGN_EDIT_ERROR,
    CLEAR_CAMPAIGN_LIST_ERROR,
    CLOSE_CREATE_CAMPAIGN_MODAL,
    CLOSE_EDIT_CAMPAIGN_MODAL,
    OPEN_CREATE_CAMPAIGN_MODAL,
    OPEN_EDIT_CAMPAIGN_MODAL,
    SET_CAMPAIGN_DETAILS,
    SET_CAMPAIGN_DETAILS_PENDING,
    SET_CAMPAIGN_LIST,
    SET_CONTACT_COUNT,
    SET_EXPORTED_CAMPAIGN_LIST,
    CAMPAIGN_CREATE_PENDING,
    SET_HTML_FILE,
    SET_CAMPAIGN_CONTACT_LIST_PENDING,
    CLEAR_CAMPAIGN_CONTACT_LIST_ERROR,
    CAMPAIGN_CONTACT_LIST_ERROR,
    SET_EXPORT_CAMPAIGN_CONTACT_LIST,
    SET_CAMPAIGN_CONTACT_LIST,
    CAMPAIGN_CREATE_ERROR,
    CAMPAIGN_CREATE_ERROR_CLEAR,
    CAMPAIGN_EDIT_PENDING,
    SET_CAMPAIGN_LIST_PENDING,
    CAMPAIGN_LIST_EXPORT_ERROR,
    CLEAR_CAMPAIGN_LIST_EXPORT_ERROR,
    CAMPAIGN_FROM_ADDRESS_ERROR,
    CAMPAIGN_FROM_ADDRESS_ERROR_CLEAR,
    CLEAR_HTML_FILE_ERROR,
    HTML_FILE_ERROR,
    CREATE_CAMPAIGN_SUCCESSS,
    EDIT_CAMPAIGN_SUCCESSS
} from "./constants";

const initialState = {
    //--------------------------------------------------------MM-CAMPAIGN-CREATE------------------------------------------------------------------
    isOpenCreateCampaign: false,
    isCreateCampaignPending: false,
    createCampaignErrorMessage: null,
    CampaignFromAddressErrorMessage: null,
    //--------------------------------------------------------MM-CAMPAIGN-EDIT------------------------------------------------------------------
    isOpenEditCampaign: false,
    isEditCampaignPending: false,
    campaignEditErrorMessage: null,
    //--------------------------------------------------------MM-CAMPAIGN-LIST------------------------------------------------------------------
    campaignList: [],
    exportedCampaignList: [],
    campaignListCount: 0,
    isCampaignListPending: false,
    campaignListErrorMessage: null,
    campaignListExportErrorMessage: null,
    //--------------------------------------------------------MM-CAMPAIGN-DETAILS------------------------------------------------------------------
    campaignDetails: null,
    isCampaignDetailsPending: false,
    campaignDetailsErrorMessage: null,
    contactListCount: 0,
    HTMLFile: null,
    HTMLFileError: null,
    //-----------------------------------------------MM-CAMPAIGN-CONTACT-LIST---------------------------------------------------------------------
    campaignContactList: [],
    campaignContactExportList: [],
    campaignContactListCount: 0,
    isCampaignContactPending: null,
    campaignContactListErrorMessage: false,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //---------------------------------------------MM-CAMPAIGN-CREATE--------------------------------------------------------------------------------
        case OPEN_CREATE_CAMPAIGN_MODAL: {
            return {
                ...localState,
                isOpenCreateCampaign: true,
            }
        }
        case CLOSE_CREATE_CAMPAIGN_MODAL: {
            return {
                ...localState,
                isOpenCreateCampaign: false,
            }
        }
        case CAMPAIGN_CREATE_PENDING: {
            return {
                ...localState,
                isCreateCampaignPending: true,
            }
        }
        case CREATE_CAMPAIGN_SUCCESSS: {
            return {
                ...localState,
                isCreateCampaignPending: false,
            }
        }
        case CAMPAIGN_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                createCampaignErrorMessage: errorMessage,
                isCreateCampaignPending: false,
            }
        }
        case CAMPAIGN_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                createCampaignErrorMessage: null,
            }
        }
        case CAMPAIGN_FROM_ADDRESS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                CampaignFromAddressErrorMessage: errorMessage,

            }
        }
        case CAMPAIGN_FROM_ADDRESS_ERROR_CLEAR: {
            return {
                ...localState,
                CampaignFromAddressErrorMessage: null,
                isCreateCampaignPending: false,
            }
        }
        //---------------------------------------------MM-CAMPAIGN-UPDATE--------------------------------------------------------------------------------
        case OPEN_EDIT_CAMPAIGN_MODAL: {
            return {
                ...localState,
                isOpenEditCampaign: true,
            }
        }
        case CLOSE_EDIT_CAMPAIGN_MODAL: {
            return {
                ...localState,
                isOpenEditCampaign: false,
            }
        }
        case CAMPAIGN_EDIT_PENDING: {
            return {
                ...localState,
                isEditCampaignPending: true,
            }
        }
        case EDIT_CAMPAIGN_SUCCESSS: {
            return {
                ...localState,
                isEditCampaignPending: false,
            }
        }
        case CAMPAIGN_EDIT_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                campaignEditErrorMessage: errorMessage,
                isEditCampaignPending: false,
            };
        }
        case CLEAR_CAMPAIGN_EDIT_ERROR: {
            return {
                ...localState,
                campaignEditErrorMessage: null,
                isEditCampaignPending: false,
            };
        }
        //---------------------------------------------MM-CAMPAIGN-LIST----------------------------------------------------------------------------------
        case SET_CAMPAIGN_LIST: {
            const { campaignList } = action.payload;
            const campaign = campaignList.value;
            const campaignListCount = campaignList['@odata.count']
            return {
                ...localState,
                campaignList: campaign,
                campaignListCount: campaignListCount,
                isCampaignListPending: false
            }
        }
        case SET_EXPORTED_CAMPAIGN_LIST: {
            const { campaignList } = action.payload;
            return {
                ...localState,
                exportedCampaignList: campaignList,
            }
        }
        case SET_CAMPAIGN_LIST_PENDING: {
            return {
                ...localState,
                campaignListError: null,
                isCampaignListPending: true
            }
        }
        case CAMPAIGN_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                campaignListErrorMessage: errorMessage,
                isCampaignListPending: false,
                campaignList: [],
                campaignListCount: 0,
            };
        }
        case CLEAR_CAMPAIGN_LIST_ERROR: {
            return {
                ...localState,
                campaignListErrorMessage: null,
            };
        }
        case CAMPAIGN_LIST_EXPORT_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                campaignListExportErrorMessage: errorMessage,
                exportedCampaignList: [],
            };
        }
        case CLEAR_CAMPAIGN_LIST_EXPORT_ERROR: {
            return {
                ...localState,
                campaignListExportErrorMessage: null,
            };
        }

        //---------------------------------------------MM-CAMPAIGN-DETAILS-------------------------------------------------------------------------------

        case SET_CAMPAIGN_DETAILS: {
            const { campaignDetails } = action.payload;
            return {
                ...localState,
                campaignDetails: campaignDetails,
                isCampaignDetailsPending: false,
            }
        }
        case SET_CAMPAIGN_DETAILS_PENDING: {
            return {
                ...localState,
                isCampaignDetailsPending: true
            }
        }
        case CAMPAIGN_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                campaignDetailsErrorMessage: errorMessage,
                isCampaignDetailsPending: false,
                campaignDetails: null,
            };
        }
        case CLEAR_CAMPAIGN_DETAILS_ERROR: {
            return {
                ...localState,
                campaignDetailsErrorMessage: null,
            };
        }
        case SET_CONTACT_COUNT: {
            const { contactList } = action.payload;
            const contactListCount = contactList['@odata.count'];
            return {
                ...localState,
                contactListCount: contactListCount,
            }
        }
        case SET_HTML_FILE: {
            const { HTMLFile } = action.payload;
            return {
                ...localState,
                HTMLFile: HTMLFile,
            }
        }
        case HTML_FILE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                HTMLFileError: errorMessage,
                HTMLFile: null,
            }
        }
        case CLEAR_HTML_FILE_ERROR: {
            return {
                ...localState,
                HTMLFileError: null,
            }
        }
        //----------------------------------------------------CAMPAIGN-CONTACT-LIST-----------------------------------------------------------------------
        case SET_CAMPAIGN_CONTACT_LIST: {
            const { CampaignContactList } = action.payload;
            const contactsList = CampaignContactList['value'];
            const campaignContactListCount = CampaignContactList['@odata.count'];
            return {
                ...localState,
                campaignContactList: contactsList,
                campaignContactListCount: campaignContactListCount,
                campaignContactExportList: contactsList,
                isCampaignContactPending: false,
            }
        }
        case SET_EXPORT_CAMPAIGN_CONTACT_LIST: {
            const contactList = action.payload;
            return {
                ...localState,
                campaignContactExportList: contactList,

            }
        }
        case SET_CAMPAIGN_CONTACT_LIST_PENDING: {
            return {
                ...localState,
                isCampaignContactPending: true
            }
        }
        case CAMPAIGN_CONTACT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                campaignContactListErrorMessage: errorMessage,
                isCampaignContactPending: false,
                campaignContactList: [],
                campaignContactListCount: 0
            };
        }
        case CLEAR_CAMPAIGN_CONTACT_LIST_ERROR: {
            return {
                ...localState,
                campaignContactListErrorMessage: null,
            };
        }

        default: {
            return localState;
        }
    }
};
