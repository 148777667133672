export const OPEN_CREATE_APP_MASTER_MODAL = "OPEN_CREATE_APP_MASTER_MODAL";
export const CLOSE_CREATE_APP_MASTER_MODAL = "CLOSE_CREATE_APP_MASTER_MODAL";
export const SET_APP_MASTER_LIST = "SET_APP_MASTER_LIST";
export const CLEAR_APP_MASTER_LIST_ERROR = "CLEAR_APP_MASTER_LIST_ERROR";
export const SET_APP_LIST_ERROR = "SET_APP_LIST_ERROR";
export const SET_APP_MASTER_DETAILS = "SET_APP_MASTER_DETAILS";
export const SET_APP_DETAILS_ERROR = "SET_APP_DETAILS_ERROR";
export const CLEAR_APP_MASTER_DETAILS_ERROR = "CLEAR_APP_MASTER_DETAILS_ERROR";
export const CREATE_APP_MASTER_PENDING = "CREATE_APP_MASTER_PENDING";
export const CLEAR_CREATE_APP_MASTER_ERROR = "CLEAR_CREATE_APP_MASTER_ERROR";
export const CREATE_APP_MASTER_SUCCESS = "CREATE_APP_MASTER_SUCCESS";
export const CREATE_APP_MASTER_ERROR = "CREATE_APP_MASTER_ERROR";
export const CLOSE_EDIT_APP_MASTER_MODAL = "CLOSE_EDIT_APP_MASTER_MODAL";
export const OPEN_EDIT_APP_MASTER_MODAL = "OPEN_EDIT_APP_MASTER_MODAL";
export const CLEAR_UPDATE_APP_MASTER_ERROR = "CLEAR_UPDATE_APP_MASTER_ERROR";
export const UPDATE_APP_MASTER_PENDING = "UPDATE_APP_MASTER_PENDING";
export const UPDATE_APP_MASTER_SUCCESS = "UPDATE_APP_MASTER_SUCCESS";
export const UPDATE_APP_MASTER_ERROR = "UPDATE_APP_MASTER_ERROR";
export const SET_APP_MASTER_PENDING = "SET_APP_MASTER_PENDING";
export const SET_APP_MASTER_DETAILS_PENDING = "SET_APP_MASTER_DETAILS_PENDING";
export const SET_APP_MASTER_EXPORT_PENDING = "SET_APP_MASTER_EXPORT_PENDING";
export const SET_APP_MASTER_EXPORT_LIST = "SET_APP_MASTER_EXPORT_LIST";
export const CLEAR_APP_MASTER_EXPORT_LIST_ERROR = "CLEAR_APP_MASTER_EXPORT_LIST_ERROR";
export const SET_APP_EXPORT_LIST_ERROR = "SET_APP_EXPORT_LIST_ERROR";
export const SET_APP_MASTER_EXPORT_SUCCESS = "SET_APP_MASTER_EXPORT_SUCCESS";


export const APPMASTER_EXPORT_HEADERS_SUPERADMIN = [
    "APP NAME",
    "ORGANIZATION COUNT",
    "LATEST VERSION",
    "CREATED DATE",
    "STATUS",
];
export const APPMASTER_EXPORT_HEADERS = [
    "APP NAME",
    "LATEST VERSION",
    "ASSIGNED DATE",
    "STATUS",
];