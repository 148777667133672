export const SET_GENERAL_TASK_LIST_PENDING = "SET_GENERAL_TASK_LIST_PENDING"
export const SET_GENERAL_TASK_LIST = "SET_GENERAL_TASK_LIST"
export const CLEAR_GENERAL_TASK_LIST_ERROR = "CLEAR_GENERAL_TASK_LIST_ERROR"
export const GENERAL_TASK_LIST_ERROR = "GENERAL_TASK_LIST_ERROR"
export const CLEAR_UPDATE_GENERAL_TASK_ERROR = "CLEAR_UPDATE_GENERAL_TASK_ERROR"
export const UPDATE_GENERAL_TASK_PENDING = "UPDATE_GENERAL_TASK_PENDING"
export const UPDATE_GENERAL_TASK_SUCCESS = "UPDATE_GENERAL_TASK_SUCCESS"
export const UPDATE_GENERAL_TASK_ERROR = "UPDATE_GENERAL_TASK_ERROR"
export const GENERAL_TASK_IMAGE_MODAL_OPEN = "GENERAL_TASK_IMAGE_MODAL_OPEN"
export const GENERAL_TASK_IMAGE_MODAL_CLOSE = "GENERAL_TASK_IMAGE_MODAL_CLOSE"
export const CLEAR_GENERAL_TASK_LIST = "CLEAR_GENERAL_TASK_LIST"