import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    Table
} from "reactstrap";
import {
    closeAccountUpdate,
    getAccountDetails,
    getContactsLists,
    openAccountUpdate
} from "../../../../store/Modules/crm/Accounts/actions";
import VWModel from "../../../../components/Modal/VWModal";
import UpdateAccount from "./edit";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import CreateContact from "../Contacts/create";
import { closeContactsCreate, openContactsCreate } from "../../../../store/Modules/crm/Contacts/actions";
import moment from "moment-timezone";

const CrmAccountDetail = (props) => {
    const params = useParams();
    const { id } = params || {};

    const { accountDetails, accounDetailErrorMessage, isAccountDetailPending, isCreateContactsOpen } = props || {};
    const { companyName, orgName, industryName, website, street, city, address2, address1, ContactEmail,
        state, code, country, description, phoneNo, isActive, regionName, companyEmail, gst, responsibility, designation, accountOwnerUserName, accountOwnerUserEmail, accountTeamUsers, accountCategoryName, accountCategoryValue, accountContact, createdTimeStamp, createdBy } = accountDetails || {};

    useEffect(() => {
        props.getAccountDetails(id);
        props.getcontactList(id);
    }, [])

    const updateAccountOpen = () => {
        props.openAccountUpdate();
    }
    const handleClose = () => {
        props.closeAccountupdate();
        props.closeContactsCreate();
    }
    //------------------------------------------ACCOUNT-CREATE-MODAL------------------------------------------------
    const createContactOpen = () => {
        props.openContactsCreate();
    }

    console.log("accountDetails: ", props.accountDetails);
    console.log("contactList: ", props.contactList);
    return (
        <div className="page-content">
            <Card>
                <CardTitle className="p-2 mb-0 fw-bold fs-4 text-dark d-flex justify-content-between"> ACCOUNT DETAILS
                    <div>
                        <Button color="primary mx-2" onClick={updateAccountOpen}>
                            EDIT
                        </Button>
                    </div>
                </CardTitle>
            </Card>
            <Card>
                {accounDetailErrorMessage &&
                    <VWMessage type="danger" defaultMessage={accounDetailErrorMessage} baseClassName="alert" ></VWMessage>
                }
                {isAccountDetailPending ?
                    <div className="d-flex justify-content-center mt-2">
                        <VWSpinner />
                    </div>
                    :
                    <CardBody>
                        <Row>
                            <Col lg="6">
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span> Account Information</div>
                                <Table responsive>
                                    <tbody>
                                        <tr>
                                            <th>Account Name</th>
                                            <td>{companyName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Organization Name</th>
                                            <td>{orgName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{companyEmail || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>GST Number</th>
                                            <td>{gst || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Website</th>
                                            <td>{website || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Industry Type</th>
                                            <td>{industryName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Responsibility</th>
                                            <td>{responsibility || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Designation</th>
                                            <td>{designation || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Category Name</th>
                                            <td>{accountCategoryName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Category Value</th>
                                            <td>{accountCategoryValue || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Account Owner</th>
                                            <td>{accountOwnerUserName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Account Owner Email</th>
                                            <td>{accountOwnerUserEmail || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Account Team Users</th>
                                            <td>{(accountTeamUsers && accountTeamUsers.length > 0) ? accountTeamUsers && accountTeamUsers.map((item) =>
                                                <Badge className="me-1" color="success" size="sm">{`${item.userName}  /  ${item.userEmail}`}</Badge>) : 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td> {isActive === true ? (
                                                <Badge color="success">Active</Badge>
                                            ) : (
                                                <Badge color="danger">Inactive</Badge>
                                            )}</td>
                                        </tr>
                                        <tr>
                                            <th>Created By</th>
                                            <td>{createdBy || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Created Date</th>
                                            <td>{createdTimeStamp ? moment(createdTimeStamp).format('DD MMM YYYY') : 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg="6">
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1"> <span class="mdi mdi-map-marker-radius fs-3 text-danger"></span> Address Information</div>
                                <Table responsive>
                                    <tbody>
                                        <tr>
                                            <th>Address 1</th>
                                            <td>{address1 || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Address 2</th>
                                            <td>{address2 || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>City</th>
                                            <td>{city || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>State</th>
                                            <td>{state || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Country</th>
                                            <td>{country || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Pincode </th>
                                            <td>{code || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Region</th>
                                            <td>{regionName || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <th>Mobile Number</th>
                                            <td>{phoneNo || "N/A"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex align-items-center gap-1">
                                    <span class="mdi mdi-clipboard-account fs-3 text-danger"></span>  About this account
                                </div>
                                <div className="fs-6 ms-5">
                                    {description || "No information available"}
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col >
                                <div className="text-uppercase text-dark fs-5 fw-bold d-flex justify-content-between gap-1"><div><span class="mdi mdi-bullseye-arrow fs-3 text-danger"></span> Contact Information </div>
                                    <Button color="primary mx-2"
                                        onClick={createContactOpen}
                                    >
                                        Add Contact
                                    </Button></div>
                                <Table responsive>
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive" style={{ maxHeight: "270px", overflowX: "auto" }}>
                                                <Table className="table table-striped table-hover table-nowrap w-100 " size="sm">
                                                    <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                                        <tr>

                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Designation </th>
                                                            <th>Mobile #</th>
                                                            <th>Landline #</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            props.contactList && props.contactList.length > 0 ?
                                                                props.contactList && props.contactList.map(item =>
                                                                    <tr key={item.Id}>
                                                                        <td>{item.DisplayName || 'N/A'}</td>
                                                                        <td>{item.ContactEmail || 'N/A'}</td>
                                                                        <td>{item.Designation || 'N/A'}</td>
                                                                        <td>{item.PhoneNo || "N/A"}</td>
                                                                        <td>{item.LandLineNo || "N/A"}</td>
                                                                    </tr>
                                                                ) : <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className="text-center">There is no data</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </Table>

                            </Col>
                        </Row>
                    </CardBody>
                }
            </Card>
            <VWModel
                header="EDIT ACCOUNT"
                modalSize="extra-large"
                isOpen={props.isUpdateAccountOpen}
                handleClose={handleClose}
            >
                <UpdateAccount handleClose={handleClose} accountDetails={accountDetails} />
            </VWModel>
            <VWModel
                header="CREATE CONTACT"
                modalSize="extra-large"
                isOpen={props.isCreateContactsOpen}
                handleClose={handleClose}
            >
                <CreateContact Id={id} Name={companyName} City={city} handleClose={handleClose} />
            </VWModel>
        </div>
    )
}

const mapStateToProps = (state) => ({
    accountDetails: state.crmAccounts.accountDetails,
    isCreateContactsOpen: state.crmContacts.isCreateContactsOpen,
    contactList: state.crmAccounts.contactList,
    isUpdateAccountOpen: state.crmAccounts.isUpdateAccountOpen,
    accounDetailErrorMessage: state.crmAccounts.accounDetailErrorMessage,
    isAccountDetailPending: state.crmAccounts.isAccountDetailPending,
})

const mapDispatchToProps = (dispatch) => ({
    getAccountDetails: (id) => dispatch(getAccountDetails(id)),
    openAccountUpdate: () => dispatch(openAccountUpdate()),
    closeAccountupdate: () => dispatch(closeAccountUpdate()),
    getcontactList: (id) => dispatch(getContactsLists(id)),
    openContactsCreate: () => dispatch(openContactsCreate()),
    closeContactsCreate: () => dispatch(closeContactsCreate()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CrmAccountDetail);