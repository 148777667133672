import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import moment from "moment";
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import VWModel from '../../../../components/Modal/VWModal';
import { Link, useParams } from 'react-router-dom';
import { closeEditLeaveMasterModal, getleaveMasterDetailsAction, openEditLeaveMasterModal } from '../../../../store/Modules/LeaveTracker/LeaveMaster/actions';
import EditLeaveMaster from './leaveMasterUpdate';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';

const LeaveMasterDetails = (props) => {

    const params = useParams();
    const { id } = params;

    useEffect(() => {
        props.getleaveMasterDetails(id);
        props.ClearPath();
    }, [props.getleaveMasterDetails, id]);

    useEffect(() => {
        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();
        }
    }, []);
    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditleaveMasterModel = () => {
        const { openEditLeaveMasterModalAction } = props;
        openEditLeaveMasterModalAction();
    };

    const handleCloseEditLeaveMasterModal = () => {
        const { closeEditLeaveMasterModalAction } = props;
        closeEditLeaveMasterModalAction();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>LEAVE MASTER DETAILS</strong>
                        {props.errorMessage ? "" :
                            <Link type="button"
                                className="btn btn-info"
                                onClick={handleOpenEditleaveMasterModel}
                            >
                                EDIT
                            </Link>}
                    </CardTitle>
                    <CardBody className="p-4">
                        {props.errorMessage ? <div className='w-100'>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        {props.isLeaveMasterDetailsPending ?
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                            :
                            <Row>
                                <div>
                                    <div className="p-3 bg-light fw-bold fs-4">
                                        <span>{props.LeaveMasterDetails && props.LeaveMasterDetails.username || "N/A"}</span>
                                    </div>
                                </div>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Username</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.username || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>User Email</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.userEmail || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Type</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.leaveTypeName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Qty</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.qty || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Consecutive Days Qty</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.consecutiveDaysQty || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Frequency</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.frequency || "N/A"}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Carry Forward</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.carryForward ? <Badge color='success'>Active</Badge> : <Badge color='danger'>InActive</Badge>}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Allow Consecutive Days</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.allowConsecutiveDays ? <Badge color='success'>Active</Badge> : <Badge color='danger'>InActive</Badge>}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>IsActive</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.isActive ? <Badge color='success'>Active</Badge> : <Badge color='danger'>InActive</Badge>}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Description</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.description || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Created By</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.createdBy || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Created Date</strong>
                                                            </td>
                                                            <td>{props.LeaveMasterDetails && props.LeaveMasterDetails.createdDateTime ? moment(props.LeaveMasterDetails && props.LeaveMasterDetails.createdDateTime).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                    <VWModel
                        header="EDIT LEAVE MASTER"
                        isOpen={props.isEditLeaveMasterModalOpen}
                        handleClose={handleCloseEditLeaveMasterModal}
                        modalSize='large'
                    >
                        <EditLeaveMaster handleClose={handleCloseEditLeaveMasterModal} />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    LeaveMasterDetails: state.leaveMaster.leaveMasterDetails,
    errorMessage: state.leaveMaster.leaveMasterDetailsError,
    isEditLeaveMasterModalOpen: state.leaveMaster.isEditLeaveMasterModalOpen,
    isLeaveMasterDetailsPending: state.leaveMaster.isLeaveMasterDetailsPending,
});

const mapDispatchToProps = (dispatch) => ({
    getleaveMasterDetails: (Id) => dispatch(getleaveMasterDetailsAction(Id)),
    openEditLeaveMasterModalAction: () => dispatch(openEditLeaveMasterModal()),
    closeEditLeaveMasterModalAction: () => dispatch(closeEditLeaveMasterModal()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveMasterDetails)