//--------------------TASK TYPE -CREATE-----------------------------------
export const TASK_TYPE_CREATE_OPEN = "TASK_TYPE_CREATE_OPEN";
export const TASK_TYPE_CREATE_CLOSE = "TASK_TYPE_CREATE_CLOSE";
export const TASK_TYPE_CREATE_PENDING = "TASK_TYPE_CREATE_PENDING";
export const TASK_TYPE_CREATE_ERROR_CLEAR = "TASK_TYPE_CREATE_ERROR_CLEAR";
export const TASK_TYPE_CREATE_ERROR = "TASK_TYPE_CREATE_ERROR";
export const TASK_TYPE_CREATE_SUCCESS = "TASK_TYPE_CREATE_SUCCESS";

//--------------------TASK TYPE-UPDATE-----------------------------------
export const TASK_TYPE_UPDATE_OPEN = "TASK_TYPE_UPDATE_OPEN";
export const TASK_TYPE_UPDATE_CLOSE = "TASK_TYPE_UPDATE_CLOSE";
export const TASK_TYPE_UPDATE_PENDING = "TASK_TYPE_UPDATE_PENDING";
export const TASK_TYPE_UPDATE_ERROR = "TASK_TYPE_UPDATE_ERROR";
export const TASK_TYPE_UPDATE_SUCCESS = "TASK_TYPE_UPDATE_SUCCESS";
export const TASK_TYPE_UPDATE_ERROR_CLEAR = "TASK_TYPE_UPDATE_ERROR_CLEAR";
export const SET_TASK_TYPE_DETAILS = "SET_TASK_TYPE_DETAILS";

//--------------------TASK SCOPE-LIST-----------------------------------
export const SET_TASK_TYPE_LIST = "SET_TASK_TYPE_LIST";
export const TASK_TYPE_LIST_PENDING = "TASK_TYPE_LIST_PENDING";
export const TASK_TYPE_LIST_ERROR = "TASK_TYPE_LIST_ERROR";
export const TASK_TYPE_LIST_ERROR_CLEAR = "TASK_TYPE_LIST_ERROR_CLEAR";

