import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { clearForm } from '../../../../store/form/actions';
import { closeEditHolidayModal, getHolidayDetailsAction, openEditHolidayModal } from '../../../../store/Modules/LeaveTracker/HolidayMaster/actions';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import moment from 'moment-timezone';
import VWModel from '../../../../components/Modal/VWModal';
import HolidayDate from './holidayEdit'
import VWSpinner from '../../../../components/vwSpinner/vwSpinner';
import { clearSetLoginPath, clearSetPath } from '../../../../store/actions';

const HolidayMasterDetails = (props) => {
    const { userAppDetails } = props.currentProfile;

    const params = useParams();
    const { id } = params;

    useEffect(() => {
        props.getHolidayDetails(id);
        props.ClearPath();
    }, [id]);

    useEffect(() => {
        return async () => {
            await props.clearSetLoginPath();
            props.ClearPath();
        }
    }, []);
    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditHolidayModel = () => {
        const { openEditHolidayModalAction } = props;
        openEditHolidayModalAction();
    };

    const handleCloseEditHolidayModal = () => {
        const { closeEditHolidayModalAction } = props;
        closeEditHolidayModalAction();
    };

    console.log("currentProfile:", props.currentProfile);

    let isAdministrator;
    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        <strong>HOLIDAY MASTER DETAILS</strong>
                        {/* {props.errorMessage ? "" : */}
                        {
                            (!props.errorMessage && userAppDetails && userAppDetails.filter(item => item && item.appName && item.appName.toLowerCase() === "leavetracker").length > 0 &&
                                userAppDetails
                                    .filter(userApp => userApp && userApp.appName && userApp.appName.toLowerCase() === "leavetracker")
                                    .map(userApp => {
                                        const adminRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => {
                                            const roleName = role.roleName.toLowerCase();
                                            return roleName;
                                        });
                                        isAdministrator = adminRoles.filter(role => role.roleName.toLowerCase() === "administrator").length > 0;
                                        return (
                                            isAdministrator ? (
                                                <Link type="button"
                                                    className="btn btn-info"
                                                    onClick={handleOpenEditHolidayModel}
                                                >
                                                    EDIT
                                                </Link>
                                            ) : null
                                        );
                                    })
                            )
                        }
                    </CardTitle>
                    <CardBody className="p-4">
                        {props.errorMessage ? <div className='w-100'>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" ></VWMessage>
                            )}
                        </div> : ""}
                        {props.isHolidayMasterDetailsPending ?
                            <div className="d-flex justify-content-center mt-2">
                                <VWSpinner />
                            </div>
                            :
                            <Row>
                                <div>
                                    <div className="p-3 bg-light fw-bold fs-4">
                                        <span>{props.holidayMasterDetails && props.holidayMasterDetails.holidayName || "N/A"}</span>
                                    </div>
                                </div>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Org Name</strong>
                                                            </td>
                                                            <td>{props.holidayMasterDetails && props.holidayMasterDetails.orgName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Name</strong>
                                                            </td>
                                                            <td>{props.holidayMasterDetails && props.holidayMasterDetails.holidayName || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Date</strong>
                                                            </td>
                                                            <td>{props.holidayMasterDetails && props.holidayMasterDetails.holidayDate ? moment(props.holidayMasterDetails && props.holidayMasterDetails.holidayDate).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="6">
                                    <div className="row pl-4">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Created By</strong>
                                                            </td>
                                                            <td>{props.holidayMasterDetails && props.holidayMasterDetails.createdBy || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Created Date</strong>
                                                            </td>
                                                            <td>{props.holidayMasterDetails && props.holidayMasterDetails.createdTimeStamp ? moment(props.holidayMasterDetails && props.holidayMasterDetails.createdTimeStamp).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ width: '200px' }}>
                                                                <strong>Updated By</strong>
                                                            </td>
                                                            <td>{props.holidayMasterDetails && props.holidayMasterDetails.updatedBy || "N/A"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Updated Date</strong>
                                                            </td>
                                                            <td>{props.holidayMasterDetails && props.holidayMasterDetails.updatedTimeStamp ? moment(props.holidayMasterDetails && props.holidayMasterDetails.updatedTimeStamp).format('DD MMM YYYY') : 'N/A' || "N/A"}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        }
                    </CardBody>
                    <VWModel
                        header="EDIT HOLIDAY"
                        isOpen={props.isEditHolidayModalOpen}
                        handleClose={handleCloseEditHolidayModal}
                    >
                        <HolidayDate handleClose={handleCloseEditHolidayModal} />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    holidayMasterDetails: state.holidayMaster.holidayMasterDetails,
    errorMessage: state.holidayMaster.holidayMasterDetailsError,
    isEditHolidayModalOpen: state.holidayMaster.isEditHolidayMasterModalOpen,
    isHolidayMasterDetailsPending: state.holidayMaster.isHolidayMasterDetailsPending,
    currentProfile: state.login.currentProfile,
});

const mapDispatchToProps = (dispatch) => ({
    getHolidayDetails: (Id) => dispatch(getHolidayDetailsAction(Id)),
    openEditHolidayModalAction: () => dispatch(openEditHolidayModal()),
    closeEditHolidayModalAction: () => dispatch(closeEditHolidayModal()),
    clearFormAction: () => dispatch(clearForm()),
    ClearPath: () => dispatch(clearSetPath()),
    clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});
export default connect(mapStateToProps, mapDispatchToProps)(HolidayMasterDetails)