export const SET_LEAVE_REPORT_PENDING = "SET_LEAVE_REPORT_PENDING";
export const SET_LEAVE_RECORD_LIST = "SET_LEAVE_RECORD_LIST";
export const CLEAR_LEAVE_REPORT_LIST_ERROR = "CLEAR_LEAVE_REPORT_LIST_ERROR";
export const SET_LEAVE_TRACKER_REPORT_LIST_ERROR = "SET_LEAVE_TRACKER_REPORT_LIST_ERROR";
export const SET_LEAVE_TRACKER_REPORT_EXPORT_PENDING = "SET_LEAVE_TRACKER_REPORT_EXPORT_PENDING";
export const CLEAR_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR = "CLEAR_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR";
export const SET_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR = "SET_LEAVE_TRACKER_REPORT_EXPORT_LIST_ERROR";

export const LEAVE_TRACKER_REPORT_EXPORT_HEADERS = [
    "USER NAME",
    "PRIVILAGE TOTAL LEAVE COUNT",
    "PRIVILAGE TAKEN LEAVE COUNT",
    "PRIVILAGE PENDING LEAVE COUNT",
    "PRIVILAGE PAID LEAVE COUNT",
    "PRIVILAGE LOP LEAVE COUNT",
    "SICK TOTAL LEAVE COUNT",
    "SICK TAKEN LEAVE COUNT",
    "SICK PENDING LEAVE COUNT",
    "SICK LOP LEAVE COUNT",
    "SICK PAID LEAVE COUNT",
    "MATERNAL TOTAL LEAVE COUNT",
    "MATERNAL TAKEN LEAVE COUNT",
    "MATERNAL PENDING LEAVE COUNT",
    "MATERNAL LOP LEAVE COUNT",
    "MATERNAL PAID LEAVE COUNT",
    "PATERNAL TOTAL LEAVE COUNT",
    "PATERNAL TAKEN LEAVE COUNT",
    "PATERNAL PENDING LEAVE COUNT",
    "PATERNAL LOP LEAVE COUNT",
    "PATERNAL PAID LEAVE COUNT",
    "OTHER TOTAL LEAVE COUNT",
    "OTHER TAKEN LEAVE COUNT",
    "OTHER PENDING LEAVE COUNT",
    "OTHER LOP LEAVE COUNT",
    "OTHER PAID LEAVE COUNT",
    "OVERALL TOTAL LEAVE COUNT",
    "OVERALL TAKEN LEAVE COUNT",
    "OVERALL PENDING LEAVE COUNT",
    "OVERALL LOP LEAVE COUNT",
    "OVERALL PAID LEAVE COUNT"
]