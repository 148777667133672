import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import VWMessage from '../../../components/vwMessage/vwMessage';
import { connect } from 'react-redux';
import { getProjectDashboard } from '../../../store/Modules/ProjectPortal/Dashboard/actions';
import { Link } from 'react-router-dom';
import moment from 'moment';
import VWSpinner from '../../../components/vwSpinner/vwSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faBan, faCircleCheck, faClipboardList, faFlask, faHistory, faLaptopCode, faPlusSquare, faSpellCheck, faSpinner, faTasks } from '@fortawesome/free-solid-svg-icons';
import Async from 'react-select/async';
import { getProjectNameAsyncSelect } from '../../../store/Modules/ProjectPortal/Task/actions';
import './Dashboard.css';

const Dashboard = (props) => {

    const [formFields, setFormFields] = useState({ EndDate: '', StartDate: '', projectId: '', projectName: '' });
    const { userId, displayName, userEmail } = props.currentProfile || '';
    const { userAppDetails } = props.currentProfile || [];
    const [isProjectManager, setIsProjectManager] = useState(false);
    const { isGlobalReadOnlyUser } = props.currentProfile || false;

    useEffect(() => {
        if (formFields.StartDate || formFields.EndDate) {
            props.getProjectDashboardCounts(formFields);
        }
    }, [formFields])

    useEffect(() => {
        const eDate = moment().format("YYYY-MM-DD");
        const sDate = moment().subtract(14, 'd').format('YYYY-MM-DD');
        setFormFields({
            EndDate: eDate,
            StartDate: sDate,
        })
    }, [])

    const handleChangeTypeSelect = (e, selectedOption) => {
        const isChecked = e.target.checked;
        console.log("isChecked:", isChecked)
        if (isChecked) {
            setFormFields({ ...formFields, FilterObject: selectedOption })
        }
    }

    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        setFormFields({
            ...formFields,
            [name]: value,
        })
    }
    //----------------------------------------Add Project Name-------------------------------------------------------
    const [existingProjectUser, setExistingProjectUser] = useState([]);
    const handleAddProjectName = (value) => {
        setIsProjectManager((value && value.ProjectManagerUserId) === userId);
        // const selectedIds = value ? value && value.map(option => option.value) : [];
        // console.table('selectedIds:', selectedIds);
        setFormFields({ ...formFields, projectId: value && value.value, projectName: value && value.label });
        setExistingProjectUser(value);
    }
    //--------------------------------------- Default Options ---------------------------------------------------------
    const handleProject = (inputValue, callback) => {
        const { getProjectNameAsyncSelectAction } = props;
        return getProjectNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    // const handleSearch = () => {
    //     props.getProjectDashboardCounts(formFields);
    // }
    console.log("allCountings:", props.allCountings);
    console.log("formfields:", formFields);
    console.log("currentProfile:", props.currentProfile);
    console.log('isProjectManager:', isProjectManager);

    console.log("userId:", userId);

    const projectPortalApps = userAppDetails?.filter(item =>
        item?.appName?.toLowerCase() === "projectportal"
    ) || [];

    const isProjectAdmin = projectPortalApps.some(userApp => {
        const adminRoles = userApp?.appRoleDetails?.filter(role => role.roleName) || [];
        return adminRoles.some(role => role.roleName.toLowerCase() === "administrator");
    });

    console.log("isProjectAdmin:", isProjectAdmin);

    const generateLink = ({ filter }) => {
        const fromDate = formFields && formFields.StartDate;
        const toDate = formFields && formFields.EndDate;
        const projectName = formFields && formFields.projectName;
        const username = displayName;
        const params = new URLSearchParams({ filter, StartDate: fromDate, EndDate: toDate });

        if (projectName) {
            params.append('ProjectName', projectName);
        }

        if (!isProjectManager && !isProjectAdmin && username && userEmail && (!isGlobalReadOnlyUser)) {
            params.append('Username', username);
            params.append('UserEmail', userEmail);
            params.append('UserId', userId);
        }

        return `../projectportal/tasks?${params.toString()}`;
    }

    const generateProjectLink = () => {
        const fromDate = formFields && formFields.StartDate;
        const toDate = formFields && formFields.EndDate;
        const projectName = formFields && formFields.projectName;
        const params = new URLSearchParams({ StartDate: fromDate, EndDate: toDate });

        if (projectName) {
            params.append('ProjectName', projectName);
        }
        return `../projectportal/projects?${params.toString()}`;
    };

    const generateTotalTaskLink = () => {
        const fromDate = formFields && formFields.StartDate;
        const toDate = formFields && formFields.EndDate;
        const projectName = formFields && formFields.projectName;
        const username = displayName;
        const params = new URLSearchParams({ StartDate: fromDate, EndDate: toDate });
        if (projectName) {
            params.append('ProjectName', projectName);
        }
        if (!isProjectManager && !isProjectAdmin && username && userEmail && (!isGlobalReadOnlyUser)) {
            params.append('Username', username);
            params.append('UserEmail', userEmail);
            params.append('UserId', userId);
        }

        return `../projectportal/tasks?${params.toString()}`;
    };

    return (
        <>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex align-items-center justify-content-between"
                    >
                        <div>
                            <strong>DASHBOARD</strong>
                        </div>
                    </CardTitle>
                </Card >
                {props.dashboardProjectsErrorMessage ? <div className='w-100'>
                    {props.dashboardProjectsErrorMessage && (
                        <VWMessage type="danger" defaultMessage={props.dashboardProjectsErrorMessage} baseClassName="alert" ></VWMessage>
                    )}
                </div> : ""}
                <Row>
                    <Col lg="5">
                        <Card className="p-4">
                            <Row className="mb-3">
                                <div className='fs-5 fw-bold'>Filter</div>
                            </Row>
                            <div>
                                <FormGroup>
                                    <Label>Project Name</Label>
                                    <Async
                                        isClearable
                                        name="ProjectId"
                                        placeholder="Select project name"
                                        // className="vm-react-select"
                                        isSearchable
                                        defaultOptions
                                        loadOptions={handleProject}
                                        onChange={handleAddProjectName}
                                        value={existingProjectUser}
                                    />
                                </FormGroup>
                            </div>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label>Start Date</Label>
                                        <Input
                                            type='date'
                                            name='StartDate'
                                            onChange={searchInputValidation}
                                            value={formFields && formFields.StartDate}
                                            max={formFields.EndDate}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label>End Date</Label>
                                        <Input
                                            type='date'
                                            name='EndDate'
                                            onChange={searchInputValidation}
                                            value={formFields && formFields.EndDate}
                                            min={formFields.StartDate}
                                            max="9999-12-31"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Card>
                        <Card className="p-4">
                            {props.isDashboardProjectsPending ? (
                                <div className="d-flex justify-content-center mt-2">
                                    <VWSpinner />
                                </div>
                            ) :
                                <>
                                    <Row className="mb-3">
                                        <div className='fs-5 fw-bold'>Project Over All Info</div>
                                    </Row>
                                    <Row>
                                        {/* Total Project Card */}
                                        <Col lg="6" md="6" className="mb-3">
                                            <Link to={generateProjectLink()}>
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                    <div className='section-1'>
                                                        <div className='fs-1 fw-bold mt-2 card-1-color'>
                                                            {props.allCountings?.projectCount || 0}
                                                        </div>
                                                        <div className='badge-icon card-1-bg'>
                                                            <FontAwesomeIcon icon={faLaptopCode} style={{ fontSize: "18px", color: "#2B4560" }} />
                                                        </div>
                                                    </div>
                                                    <div className='p-2 fw-bold rounded-bottom card-1-bg card-1-color'>
                                                        Total Projects
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>
                                        {/* Total Task Card */}
                                        <Col lg="6" md="6" className="mb-3">
                                            <Link to={generateTotalTaskLink()}>
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                    <div className='section-1'>
                                                        <div className='fs-1 fw-bold mt-2 card-2-color'>
                                                            {props.allCountings?.totalTaskCount || 0}
                                                        </div>
                                                        <div className='badge-icon card-2-bg'>
                                                            <FontAwesomeIcon icon={faTasks} style={{ fontSize: "18px", color: "#01413A" }} />
                                                        </div>
                                                    </div>
                                                    <div className='p-2 fw-bold rounded-bottom card-2-bg card-2-color'>
                                                        Total Tasks
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>
                                    </Row>
                                </>}
                        </Card>
                    </Col>
                    <Col lg="7">
                        <Card className="p-4">
                            {props.isDashboardProjectsPending ? (
                                <div className="d-flex justify-content-center mt-2">
                                    <VWSpinner />
                                </div>
                            ) :
                                <>
                                    <Row className="mb-3">
                                        <div className='fs-5 fw-bold'>Project Status Info</div>
                                    </Row>
                                    <Row>
                                        {/* New Card */}
                                        <Col xl="3" lg="4" md="4" className="mb-3">
                                            <Link to={generateLink({ filter: 'New' })}>
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                    <div className='section-1'>
                                                        <div className='fs-1 fw-bold mt-2 text-new'>
                                                            {props.allCountings?.newTaskCount || 0}
                                                        </div>
                                                        <div className='badge-icon bg-new'>
                                                            <FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    <div className='p-2 fw-bold rounded-bottom bg-new text-white'>
                                                        New
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>
                                        {/* In Progress Card */}
                                        <Col xl="3" lg="4" md="4" className="mb-3">
                                            <Link to={generateLink({ filter: 'InProgress' })}>
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                    <div className='section-1'>
                                                        <div className='fs-1 fw-bold mt-2 text-inprogress'>
                                                            {props.allCountings?.inProgressTaskCount || 0}
                                                        </div>
                                                        <div className='badge-icon bg-inprogress'>
                                                            <FontAwesomeIcon icon={faSpinner} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    <div className='p-2 fw-bold rounded-bottom bg-inprogress text-white'>
                                                        In Progress
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>
                                        {/* On Hold Card */}
                                        <Col xl="3" lg="4" md="4" className="mb-3">
                                            <Link to={generateLink({ filter: 'OnHold' })}>
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                    <div className='section-1'>
                                                        <div className='fs-1 fw-bold mt-2 text-onhold'>
                                                            {props.allCountings?.onHoldTaskCount || 0}
                                                        </div>
                                                        <div className='badge-icon bg-onhold'>
                                                            <FontAwesomeIcon icon={faBan} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    <div className='p-2 fw-bold rounded-bottom bg-onhold text-white'>
                                                        On Hold
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>
                                        {/* Internal Testing Card */}
                                        <Col xl="3" lg="4" md="4" className="mb-3">
                                            <Link to={generateLink({ filter: 'Internal Testing' })}>
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                    <div className='section-1'>
                                                        <div className='fs-1 fw-bold mt-2 text-testing'>
                                                            {props.allCountings?.internalTestingTaskCount || 0}
                                                        </div>
                                                        <div className='badge-icon bg-testing'>
                                                            <FontAwesomeIcon icon={faFlask} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    <div className='p-2 fw-bold rounded-bottom bg-testing text-white'>
                                                        Internal Testing
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>
                                        {/* Internal Testing Done Card */}
                                        <Col xl="3" lg="4" md="4" className="mb-3">
                                            <Link to={generateLink({ filter: 'Internal Testing Done' })}>
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                    <div className='section-1'>
                                                        <div className='fs-1 fw-bold mt-2 text-testing-done'>
                                                            {props.allCountings?.internalTestingDoneTaskCount || 0}
                                                        </div>
                                                        <div className='badge-icon bg-testing-done'>
                                                            <FontAwesomeIcon icon={faSpellCheck} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    <div className='p-2 fw-bold rounded-bottom bg-testing-done text-white'>
                                                        Internal Testing Done
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>
                                        {/* Waiting for Approval Card */}
                                        <Col xl="3" lg="4" md="4" className="mb-3">
                                            <Link to={generateLink({ filter: 'Waiting For Approval' })}>
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                    <div className='section-1'>
                                                        <div className='fs-1 fw-bold mt-2 text-waiting'>
                                                            {props.allCountings?.waitingForApprovalTaskCount || 0}
                                                        </div>
                                                        <div className='badge-icon bg-waiting'>
                                                            <FontAwesomeIcon icon={faHistory} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    <div className='p-2 fw-bold rounded-bottom bg-waiting text-white'>
                                                        Waiting For Approval
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>
                                        {/* Done Card */}
                                        <Col xl="3" lg="4" md="4" className="mb-3">
                                            <Link to={generateLink({ filter: 'Done' })}>
                                                <Card className='sub-card' style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                                    <div className='section-1'>
                                                        <div className='fs-1 fw-bold mt-2 text-done'>
                                                            {props.allCountings?.doneTaskCount || 0}
                                                        </div>
                                                        <div className='badge-icon bg-done'>
                                                            <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: "18px", color: "white" }} />
                                                        </div>
                                                    </div>
                                                    <div className='p-2 fw-bold rounded-bottom bg-done text-white'>
                                                        Done
                                                    </div>
                                                </Card>
                                            </Link>
                                        </Col>
                                    </Row>
                                </>}
                        </Card>
                    </Col>
                </Row >
            </div >
        </>
    )
}
const mapStateToProps = (state) => ({
    allCountings: state.ProjectDashboard.DashboardCounts,
    isDashboardProjectsPending: state.ProjectDashboard.isDashboardProjectsPending,
    dashboardProjectsErrorMessage: state.ProjectDashboard.dashboardProjectsErrorMessage,
    currentProfile: state.login.currentProfile,
})

const mapDispatchToProps = (dispatch) => ({
    getProjectDashboardCounts: (formfields) => dispatch(getProjectDashboard(formfields)),
    getProjectNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getProjectNameAsyncSelect(searchValue, callback)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)