import React, { useEffect, useState } from 'react'
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import VWButton from '../../../components/vwButton/VWButton';
import VWMessage from '../../../components/vwMessage/vwMessage';
import { connect } from 'react-redux';
import { clearCreatereateVisionDocsError, createVisionDocs } from '../../../store/Modules/VisionDocs/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const DocsHeader = (props) => {
  const [formFields, setFormFields] = useState();
  const [fileName, setFileName] = useState('');
  const [includeUserInfo, setIsIncludeUserInfo] = useState();
  const maxLength = 30;

  useEffect(() => {
    const { data } = props;
    const { contentText, title, assignUsers, headerTitle, headerLogo, id, ownerUserName, updatedTimeStamp, createdTimeStamp, includeUserInfo, fileName } = data;
    console.log("data:", data);
    setFormFields({
      ...formFields, contentText, title, assignUsers, id, 'headerTitle': headerTitle, 'headerLogo': headerLogo, ownerUserName, updatedTimeStamp, createdTimeStamp, includeUserInfo, fileName
    });
    setFileName(data && data.fileName || '');
    setIsIncludeUserInfo(includeUserInfo);

    props.clearCreatereateVisionDocsError();
  }, [props.data])

  const handleCreateVisionDocsHeader = (e) => {
    e.preventDefault();
    // props.createVisionDocsAction(formFields);
    props.handleDone(formFields);
    props.handleClose();

  }

  const [errors, setErrors] = useState({});
  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    setFormFields({ ...formFields, [input.name]: value });
    if (value && value.length >= maxLength) {
      setErrors({ ...errors, headerTitle: `Header title cannot exceed ${maxLength} characters.` });
    } else {
      setErrors({ ...errors, headerTitle: '' });
    }
  };
  const [error, setError] = useState('');
  const validateOnChangeLogo = (e) => {
    const logoFile = e.target && e.target.files[0];
    console.log("logoFile:", logoFile);
    setError('');
    if (logoFile && logoFile.size > 2 * 1024 * 1024) {
      setError('Uploaded image was exceed the maximum size of 2 MB.');
      return Promise.reject('File size too large');
    }

    const reader = new FileReader();
    reader.readAsDataURL(logoFile)
    reader.onerror = (error) => {
      console.error("Error while reading the file:", error);
      setError('Error while reading the file');
    };
    reader.onload = () => {
      const imagedata = reader.result
      console.log("imagedata:", imagedata)
      setFormFields({ ...formFields, headerLogo: imagedata, fileName: logoFile.name });
    }
    setFileName(logoFile.name);
  };

  const handleRemoveFile = () => {
    setFileName('');
    setFormFields({ ...formFields, headerLogo: '', logoFileName: '', fileName: '' });
  };

  const handleIsUserInfoStatusChange = () => {
    const newIsIncludeUserInfo = !includeUserInfo;
    setIsIncludeUserInfo(newIsIncludeUserInfo);
    setFormFields({ ...formFields, includeUserInfo: newIsIncludeUserInfo });
  };

  console.log("formFields:", formFields);

  return (
    <>
      {props.errorMessage && (
        <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
      )}

      <FormGroup>
        <label className="col-form-label">
          Header Title
        </label>
        <Input
          className='m-0'
          type="text"
          name="headerTitle"
          placeholder="Enter Header Title"
          value={formFields && formFields.headerTitle}
          onChange={validateOnChange}
          aria-label="Header Title"
          invalid={errors && errors.headerTitle}
          maxLength={maxLength}
        />
        <FormFeedback>{errors && errors.headerTitle}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <label className="col-form-label">
          Upload Logo
        </label>
        {error && <div className="text-danger">{error}</div>}
        {fileName ? (
          <div className="d-flex gap-3">
            <h6 className="me-2 mt-2">Selected file: {fileName}</h6>
            <button type="button" className="btn btn-danger btn-sm mt-0" onClick={handleRemoveFile}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ) : (
          <>
            <Input
              type="file"
              name="headerLogo"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                // const file = e.target.files[0];
                // if (file) {
                //   validateOnChangeLogo(file);
                // }
                // if (file) {
                validateOnChangeLogo(e).catch(err => {
                  console.error(err); // Handle the rejection if needed
                });
                // }
              }}
            />
          </>
        )}
      </FormGroup>
      <div className="form-group mt-4">
        <div>
          <label>
            <Input
              type="checkbox"
              checked={includeUserInfo}
              onClick={handleIsUserInfoStatusChange}
            />
            &nbsp;&nbsp;Include User Info
          </label>
        </div>
      </div>


      <div className="form-group pt-2">
        <VWButton
          messageKey="createVisionDocsButton"
          defaultMessage="Done"
          buttonType="success"
          baseClassName="btn"
          className="me-2"
          onClick={handleCreateVisionDocsHeader}
        // isDisabled={props.isPending}
        ></VWButton>
        <VWButton
          messageKey="cancelVisionDocsButton"
          defaultMessage="Cancel"
          buttonType="danger"
          baseClassName="btn"
          onClick={props.handleClose}
        // isDisabled={props.isPending}
        ></VWButton>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  errorMessage: state.visionDocs.errorMessage,
  isPending: state.visionDocs.isPending,
  currentProfile: state.login.currentProfile,

});
const mapDispatchToProps = (dispatch) => ({
  clearCreatereateVisionDocsError: () => dispatch(clearCreatereateVisionDocsError()),
  createVisionDocsAction: (formFields) => dispatch(createVisionDocs(formFields)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocsHeader)