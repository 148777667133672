// import { createStore, applyMiddleware, compose } from "redux";
// import createSagaMiddleware from "redux-saga";
// import thunkMiddleware from "redux-thunk";
// import { USER_LOGOUT } from "./auth/login/actionTypes";
// import rootReducer from "./reducers";
// import rootSaga from "./sagas";


// const sagaMiddleware = createSagaMiddleware();

// // define local storage middleware
// const localStorageMiddleware = store => next => action =>  {

//   const result = next(action);

//   localStorage.setItem("initialState", JSON.stringify(store.getState()));

//   if (action.type === USER_LOGOUT) {
//     localStorage.clear();
//     sessionStorage.clear();
//     store.getState().initialState = undefined;
//   }

//   return result;
// };


// const initialState = JSON.parse(localStorage.getItem("state")) || {};


// const middlewares = [thunkMiddleware, sagaMiddleware, localStorageMiddleware];


// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export function configureStore() {
//   const store = createStore(
//     rootReducer,
//     initialState,
//     composeEnhancers(applyMiddleware(...middlewares))
//   );

//   sagaMiddleware.run(rootSaga);
//   console.log("store:", store);
//   console.log("initialState:", initialState);
//   return store;
// }


import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import { USER_LOGOUT } from "./auth/login/actionTypes";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunkMiddleware, sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Retrieve any previous state from localStorage
const persistedState = (() => {
  try {
    const state = localStorage.getItem("reduxState");
    return state ? JSON.parse(state) : {};
  } catch (e) {
    console.warn("Error parsing localStorage:", e);
    return {};
  }
})();



const appReducer = (state, action) => {

  if (action.type === USER_LOGOUT) {
    localStorage.clear();
    state = undefined;
    localStorage.removeItem("reduxState")
  }
  return rootReducer(state, action);
};

export function configureStore() {
  const store = createStore(
    appReducer,
    persistedState, // Use previous state from localStorage, if any
    composeEnhancers(applyMiddleware(...middlewares))
  );

  // Save the state to localStorage on any change
  store.subscribe(() => {
    localStorage.setItem("reduxState", JSON.stringify(store.getState()));
  });

  sagaMiddleware.run(rootSaga);

  // if (!store.getState().token && typeof store.getState().token === "undefined") {
  //   window.location.href = "/login"; // Redirect to login page
  // }

  return store;
}
