//--------------------REGION-CREATE-----------------------------------
export const REGION_CREATE_OPEN = "REGION_CREATE_OPEN";
export const REGION_CREATE_CLOSE = "REGION_CREATE_CLOSE";
export const REGION_CREATE_PENDING = "REGION_CREATE_PENDING";
export const REGION_CREATE_ERROR = "REGION_CREATE_ERROR";
export const REGION_CREATE_ERROR_CLEAR = "REGION_CREATE_ERROR_CLEAR";
export const REGION_CREATE_SUCCESS = "REGION_CREATE_SUCCESS";

//--------------------REGION-UPDATE-----------------------------------
export const REGION_UPDATE_OPEN = "REGION_UPDATE_OPEN";
export const REGION_UPDATE_CLOSE = "REGION_UPDATE_CLOSE";
export const REGION_UPDATE_PENDING = "REGION_UPDATE_PENDING";
export const REGION_UPDATE_ERROR = "REGION_UPDATE_ERROR";
export const REGION_UPDATE_SUCCESS = "REGION_UPDATE_SUCCESS";
export const REGION_UPDATE_ERROR_CLEAR = "REGION_UPDATE_ERROR_CLEAR";
export const SET_REGION_DETAILS = "SET_REGION_DETAILS";

//--------------------REGION-LIST-----------------------------------
export const SET_REGION_LIST = "SET_REGION_LIST";
export const REGION_LIST_PENDING = "REGION_LIST_PENDING";
export const REGION_LIST_ERROR = "REGION_LIST_ERROR";
export const REGION_LIST_ERROR_CLEAR = "REGION_LIST_ERROR_CLEAR";

