import React, { useEffect, useState } from 'react'
import VWButton from '../../../../../components/vwButton/VWButton'
import VWMessage from '../../../../../components/vwMessage/vwMessage'
import Async from 'react-select/async';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { EditOrgUser, getOrgNameAsyncSelect } from '../../../../../store/Modules/Admin/OrgUser/actions';
import { EditAdminUserUpdate } from '../../../../../store/Modules/Admin/AdminUser/actions';


const EditAdminUser = (props) => {

    const [formFields, setFormFields] = useState({});
    const [isPortalEnable, setIsPortalEnable] = useState(true);
    const [prevOrgName, setprevOrgName] = useState([{ label: '', value: '' }]);

    useEffect(() => {
        if (props.orgUserDetails) {
            setFormFields(props.orgUserDetails);
            setIsPortalEnable(props.orgUserDetails && props.orgUserDetails.isPortalEnable);
            setprevOrgName({ label: props.orgUserDetails && props.orgUserDetails.orgName, value: props.orgUserDetails && props.orgUserDetails.orgId })
        }
    }, [])

    //-------------------------------- Default Option for Org name---------------------------------------------
    const handleOrgName = (inputValue, callback) => {
        const { getOrgNameAsyncSelectAction } = props;
        return getOrgNameAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    //-----------------------------------------OnChange---------------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setFormFields({ ...formFields, [input.name]: value });
    };

    //--------------------------------------------Status Change ------------------------------------------------- 
    const handleActiveStatusChange = () => {
        setIsPortalEnable(!isPortalEnable);
        setFormFields({ ...formFields, isPortalEnable: !isPortalEnable })
    };
    //------------------------------------- Change Org name -----------------------------------------------------

    const handleAddOrgName = (value) => {
        setprevOrgName(value);
        setFormFields({ ...formFields, orgId: value.value, orgName: value.label });
    }
    //--------------------------------------------Update Form ------------------------------------------------------
    const handleUpdateOrganizationUser = () => {
        props.EditAdminUserAction(formFields);
    };
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <>
                <div className="form-group">
                    <label className="col-form-label">
                        Org Name<span className="text-danger"> *</span>
                    </label>
                    <Async
                        name="orgId"
                        placeholder="Select Org Name"
                        className="vm-react-select"
                        isSearchable
                        defaultOptions
                        value={prevOrgName}
                        loadOptions={handleOrgName}
                        onChange={handleAddOrgName}
                        isDisabled

                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">
                        Email <span className="text-danger"> *</span>
                    </label>
                    <Input
                        type="text"
                        name="userEmail"
                        placeholder="Enter Email"
                        onChange={validateOnChange}
                        value={formFields && formFields.userEmail}
                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">
                        First Name <span className="text-danger"> *</span>
                    </label>
                    <Input
                        type="text"
                        name="firstName"
                        placeholder="Enter FirstName"
                        onChange={validateOnChange}
                        value={formFields && formFields.firstName}
                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">
                        Last Name
                    </label>
                    <Input
                        type="text"
                        name="lastName"
                        placeholder="Enter LastName"
                        onChange={validateOnChange}
                        value={formFields && formFields.lastName}
                    />
                </div>
                <div className="form-group">
                    <label className="col-form-label">IsPortalEnable</label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!isPortalEnable ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${isPortalEnable ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </div>
                <br></br>
                <div className="form-group">
                    <VWButton
                        messageKey="createAdminUserButton"
                        defaultMessage="Edit Admin User"
                        buttonType="success"
                        baseClassName="btn"
                        className="me-2"
                        onClick={handleUpdateOrganizationUser}
                    ></VWButton>
                    <VWButton
                        messageKey="cancel Admin User"
                        defaultMessage="Cancel"
                        buttonType="secondary"
                        baseClassName="btn"
                        onClick={props.handleClose}
                    ></VWButton>
                </div>
            </>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    orgUserDetails: state.OrgUser.OrgUserDetails,
    isPending: state.AdminUser.isUpdateOrgUserPending,
    errorMessage: state.AdminUser.updateAdminUserErrorMessage,
});
const mapDispatchToProps = (dispatch) => ({
    getOrgNameAsyncSelectAction: (searchValue, callback) =>
        dispatch(getOrgNameAsyncSelect(searchValue, callback)),
    EditAdminUserAction: (formFields) => dispatch(EditAdminUserUpdate(formFields)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditAdminUser)