import { getToken, modifyCrmUserList } from "../../../../../helper/common";
import trimmedValues from "../../../../../helper/trim";
import agent from "../../../../services/agent";
import { showSnackbarStatus } from "../../../../snackbar/actions";
import { setIsInlineUpdate } from "../SettingsInlineUpdate/actions";
import {
    OPPORTUNITY_TYPE_CREATE_CLOSE,
    OPPORTUNITY_TYPE_CREATE_ERROR,
    OPPORTUNITY_TYPE_CREATE_ERROR_CLEAR,
    OPPORTUNITY_TYPE_CREATE_OPEN,
    OPPORTUNITY_TYPE_CREATE_PENDING,
    OPPORTUNITY_TYPE_CREATE_SUCCESS,
    OPPORTUNITY_TYPE_LIST_ERROR,
    OPPORTUNITY_TYPE_LIST_ERROR_CLEAR,
    OPPORTUNITY_TYPE_LIST_PENDING,
    OPPORTUNITY_TYPE_UPDATE_CLOSE,
    OPPORTUNITY_TYPE_UPDATE_ERROR,
    OPPORTUNITY_TYPE_UPDATE_ERROR_CLEAR,
    OPPORTUNITY_TYPE_UPDATE_OPEN,
    OPPORTUNITY_TYPE_UPDATE_PENDING,
    OPPORTUNITY_TYPE_UPDATE_SUCCESS,
    SET_OPPORTUNITY_TYPE_DETAILS,
    SET_OPPORTUNITY_TYPE_LIST,
} from "./constants";

//--------------------Opportunity-Type-CREATE-----------------------------------
export const openAddOpportunityTypeModal = () => ({
    type: OPPORTUNITY_TYPE_CREATE_OPEN
})
export const closeAddOpportunityTypeModal = () => ({
    type: OPPORTUNITY_TYPE_CREATE_CLOSE
})
export const createOpportunityTypePending = () => ({
    type: OPPORTUNITY_TYPE_CREATE_PENDING
})
export const createOpportunityTypeError = (errorMessage) => ({
    type: OPPORTUNITY_TYPE_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateOpportunityTypeError = () => ({
    type: OPPORTUNITY_TYPE_CREATE_ERROR_CLEAR,
})
export const createOpportunityTypeSuccess = () => ({
    type: OPPORTUNITY_TYPE_CREATE_SUCCESS,
})

export const createNewOpportunityType = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createOpportunityTypeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(createOpportunityTypePending());
    dispatch(clearCreateOpportunityTypeError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.createOpportunityType(accessToken, dataToSend)
            .then(() => {
                dispatch(getOpportunityTypeList());
                dispatch(createOpportunityTypeSuccess());
                dispatch(closeAddOpportunityTypeModal());
                dispatch(showSnackbarStatus('Opportunity type created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createOpportunityTypeError(err.response && err.response.text));
                    return;
                }
                console.log('Create OpportunityType server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Opportunity-Type-UPDATE-----------------------------------
export const openEditOpportunityTypeModal = () => ({
    type: OPPORTUNITY_TYPE_UPDATE_OPEN
})
export const closeEditOpportunityTypeModal = () => ({
    type: OPPORTUNITY_TYPE_UPDATE_CLOSE
})
export const updateOpportunityTypePending = () => ({
    type: OPPORTUNITY_TYPE_UPDATE_PENDING
})
export const updateOpportunityTypeError = (errorMessage) => ({
    type: OPPORTUNITY_TYPE_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateOpportunityTypeError = () => ({
    type: OPPORTUNITY_TYPE_UPDATE_ERROR_CLEAR,
})
export const updateOpportunityTypeSuccess = () => ({
    type: OPPORTUNITY_TYPE_UPDATE_SUCCESS,
})
export const setOpportunityTypeDetails = (opportunityTypeDetails) => ({
    type: SET_OPPORTUNITY_TYPE_DETAILS,
    payload: { opportunityTypeDetails }
})

export const updateOpportunityType = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(updateOpportunityTypeError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({ ...formFields, })
    }
    dispatch(updateOpportunityTypePending());
    dispatch(clearUpdateOpportunityTypeError());
    dispatch(setIsInlineUpdate());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.updateOpportunityType(accessToken, dataToSend)
            .then(() => {
                dispatch(getOpportunityTypeList());
                dispatch(updateOpportunityTypeSuccess());
                dispatch(closeEditOpportunityTypeModal());
                dispatch(showSnackbarStatus('Opportunity type updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateOpportunityTypeError(err.response && err.response.text));
                    return;
                }
                console.log('Create OpportunityType server error', err);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

//--------------------Opportunity-Type-LIST-----------------------------------
export const opportunityTypeListPending = () => ({
    type: OPPORTUNITY_TYPE_LIST_PENDING
})
export const opportunityTypeListError = (errorMessage) => ({
    type: OPPORTUNITY_TYPE_LIST_ERROR,
    payload: { errorMessage }
})
export const clearOpportunityTypeListError = () => ({
    type: OPPORTUNITY_TYPE_LIST_ERROR_CLEAR,
})
export const setOpportunityTypeList = (opportunityTypeList) => ({
    type: SET_OPPORTUNITY_TYPE_LIST,
    payload: { opportunityTypeList }
})

export const getOpportunityTypeList = (page, PageSize) => (dispatch) => {
    dispatch(opportunityTypeListPending());
    dispatch(clearOpportunityTypeListError());
    getToken(dispatch).then((accessToken) => {
        agent.CrmSettings.getOpportunityTypeList(accessToken, page, PageSize)
            .then((result) => {
                dispatch(setOpportunityTypeList(result))
            })
            .catch((err) => {
                let error;
                if (err && err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                } 
                dispatch(opportunityTypeListError(error));
                console.log('Create OpportunityType server error', error);
                // dispatch(closeAccountCreate());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

