import React from 'react'
import { Card, CardTitle } from 'reactstrap'
import { Link } from "react-router-dom";

const Salary = () => {
  return (
    <>
 <React.Fragment>
 <div className="page-content">
    <Card>
    <CardTitle
          tag="h4"
          className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
        >
       Salary
          <div className="content-heading">
            <div className="ml-auto">
              <Link
                type="button"
                className="mr-1 btn btn-primary"
                // onClick={handleOpenCreateLocker}
                to="salarycreate"
              >
                Create
              </Link>&nbsp;
              <button
                type="button"
                className="mr-1 btn btn-primary"
                
              >
                Export
              </button>
            </div>
          </div>
        </CardTitle>
          </Card></div>
          </React.Fragment>
          </>
  )
}

export default Salary