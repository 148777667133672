import { SNACKBAR_MESSAGE } from "./constants";

export const setSnackbarStatus = (status?: string) => ({
  type: SNACKBAR_MESSAGE,
  payload: status,
});

export const showSnackbarStatus = (status: string) => (dispatch: any) => {
  dispatch(setSnackbarStatus(status));
  setTimeout(() => {
    dispatch(setSnackbarStatus());
  }, 2000);

};
export const snackbar = (status) => (dispatch) => {
  dispatch(setSnackbarStatus(status));
  alert(' Created Sucessfully')
}