import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearCreateFollowUpActionError,
    createFollowUpActionSuccess,
    createNewFollowUpAction
} from '../../../../../../store/Modules/crm/Settings/FollowUpAction/actions';

const AddFollowUpAction = (props) => {
    const { isFollowUpActionCreatePending, errorMessage } = props;
    const [followUpActionDetails, setFollowUpActionDetails] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        props.clearCreateFollowUpActionError();
        props.createFollowUpActionSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setFollowUpActionDetails({
            ...followUpActionDetails,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: null
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { followUpActionName } = followUpActionDetails || {};

        if (!followUpActionName) {
            currentErrors.followUpActionName = 'Follow up action name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createFollowUpAction(followUpActionDetails)
        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label>Follow Up Action Name <span className='text-danger'>*</span></Label>
                <Input
                    type="text"
                    name="followUpActionName"
                    placeHolder="Enter follow up action name"
                    onChange={validateOnchange}
                    value={followUpActionDetails && followUpActionDetails.followUpActionName}
                    invalid={errors && errors.followUpActionName}
                />
                <FormFeedback>{errors && errors.followUpActionName}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isFollowUpActionCreatePending}
                >
                    {isFollowUpActionCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    isFollowUpActionCreatePending: state.crmFollowUpAction.isFollowUpActionCreatePending,
    errorMessage: state.crmFollowUpAction.followUpActionCreateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    createFollowUpAction: (industryDetails) => dispatch(createNewFollowUpAction(industryDetails)),
    clearCreateFollowUpActionError: () => dispatch(clearCreateFollowUpActionError()),
    createFollowUpActionSuccess: () => dispatch(createFollowUpActionSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddFollowUpAction);