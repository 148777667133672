export const SET_LEAVE_TYPE_LIST = "SET_LEAVE_TYPE_LIST";
export const SET_LEAVE_TYPE_LIST_ERROR = "SET_LEAVE_TYPE_LIST_ERROR";
export const CLEAR_LEAVE_TYPE_LIST_ERROR = "CLEAR_LEAVE_TYPE_LIST_ERROR";
export const OPEN_LEAVE_TYPE_DELETE_MODAL = "OPEN_LEAVE_TYPE_DELETE_MODAL";
export const CLOSE_LEAVE_TYPE_DELETE_MODAL = "CLOSE_LEAVE_TYPE_DELETE_MODAL";
export const DELETE_LEAVETYPE_ERROR = "DELETE_LEAVETYPE_ERROR";
export const SET_LEAVE_TYPE_DETAILS = "SET_LEAVE_TYPE_DETAILS";
export const SET_LEAVE_TYPE_DETAILS_ERROR = "SET_LEAVE_TYPE_DETAILS_ERROR";
export const CLEAR_LEAVE_TYPE_DETAILS_ERROR = "CLEAR_LEAVE_TYPE_DETAILS_ERROR";
export const CLOSE_CREATE_LEAVE_TYPE_MODAL = "CLOSE_CREATE_LEAVE_TYPE_MODAL";
export const OPEN_CREATE_LEAVE_TYPE_MODAL = "OPEN_CREATE_LEAVE_TYPE_MODAL";
export const CREATE_LEAVE_TYPE_ERROR = "CREATE_LEAVE_TYPE_ERROR";
export const CREATE_LEAVE_TYPE_PENDING = "CREATE_LEAVE_TYPE_PENDING";
export const CLEAR_LEAVE_TYPE_ERROR = "CLEAR_LEAVE_TYPE_ERROR";
export const CREATE_LEAVE_TYPE_SUCCESS = "CREATE_LEAVE_TYPE_SUCCESS";
export const OPEN_EDIT_LEAVE_TYPE_MODAL = "OPEN_EDIT_LEAVE_TYPE_MODAL";
export const CLOSE_EDIT_LEAVE_TYPE_MODAL = "CLOSE_EDIT_LEAVE_TYPE_MODAL";
export const CLEAR_UPDATE_LEAVE_TYPE_ERROR = "CLEAR_UPDATE_LEAVE_TYPE_ERROR";
export const UPDATE_LEAVE_TYPE_PENDING = "UPDATE_LEAVE_TYPE_PENDING";
export const UPDATE_LEAVE_TYPE_SUCCESS = "UPDATE_LEAVE_TYPE_SUCCESS";
export const UPDATE_LEAVE_TYPE_ERROR = "UPDATE_LEAVE_TYPE_ERROR";
export const SET_LEAVE_TYPE_PENDING = "SET_LEAVE_TYPE_PENDING";
export const SET_LEAVE_TYPE_DETAILS_PENDING = "SET_LEAVE_TYPE_DETAILS_PENDING";
export const SET_LEAVE_TYPE_MASTER_LIST = "SET_LEAVE_TYPE_MASTER_LIST";
export const SET_LEAVE_TYPE_MASTER_LIST_PENDING = "SET_LEAVE_TYPE_MASTER_LIST_PENDING";
export const CLEAR_LEAVE_TYPE_MASTER_LIST_ERROR = "CLEAR_LEAVE_TYPE_MASTER_LIST_ERROR";
export const SET_LEAVE_TYPE_MASTER_LIST_ERROR = "SET_LEAVE_TYPE_MASTER_LIST_ERROR";
export const SET_ORG_LEAVE_TYPE_LIST_PENDING = "SET_ORG_LEAVE_TYPE_LIST_PENDING";
export const SET_ORG_LEAVE_TYPE_LIST = "SET_ORG_LEAVE_TYPE_LIST";
export const CLEAR_ORG_LEAVE_TYPE_LIST_ERROR = "CLEAR_ORG_LEAVE_TYPE_LIST_ERROR";
export const SET_ORG_LEAVE_TYPE_LIST_ERROR = "SET_ORG_LEAVE_TYPE_LIST_ERROR";