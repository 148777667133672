import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label,
    Row,
    Table
} from "reactstrap";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import Async from "react-select/async"
import {
    closeContactsCreate,
    getAccountsAsyncSelect,
    openContactsCreate
} from "../../../../store/Modules/crm/Contacts/actions";
import {
    clearUpdateOpportunityError,
    DeleteDocument,
    getActionAsyncSelect,
    getCategoryAsyncSelect,
    getContactAsyncSelectAction,
    getContantsAsyncSelect,
    getCrmUserAsyncSelect,
    getCurrentPhaseAsyncSelect,
    getSubCategoryAsyncSelectAction,
    getTypeAsyncSelect,
    updateOpportunity,
    updateOpportunitySuccess
} from "../../../../store/Modules/crm/Opportunity/actions";
import moment from "moment-timezone";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import VWModel from "../../../../components/Modal/VWModal";
import CreateContact from '../Contacts/create'

const currencyOptions = [
    { label: 'INR', value: 'INR' },
]
const statusOptions = [
    { label: 'open', value: 'open' },
    { label: 'closed won', value: 'closed won' },
    { label: 'closed lost', value: 'closed lost' },
]
const UpdateLeads = (props) => {
    const { errorMessage, isOpportunityCreateSuccess, isOpportunityUpdatePending, opportunityDetail } = props;
    const [formFields, setFormFields] = useState();
    const [errors, setErrors] = useState();
    const [isActive, setIsActive] = useState(true);
    const [owner, setOwner] = useState([{ label: '', value: '' }]);
    const [contactOwner, setContactOwner] = useState([{ label: '', value: '' }]);
    const [contactName, setContactName] = useState([{ label: '', value: '' }]);
    const [prevContactValue, setPrevContactValue] = useState([]);
    const [existingContact, setExistingContact] = useState([]);
    const [prevMainCategory, setPrevMainCategory] = useState([]);
    const [existingSubCategory, setExistingSubCategory] = useState([]);
    const [allOptionSubCategory, setAllOptionSubCategory] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [opType, setOpType] = useState([]);
    const [opAction, setOpAction] = useState([]);
    const [oppStatus, setOppStatus] = useState([]);
    const [currentPhase, setCurrentPhase] = useState([]);
    const [Files, setFile] = useState([]);
    const [keyChange, setKeyChange] = useState(1);
    const [subKeyChange, setSubKeyChange] = useState(1);
    const [accountId, setAccountId] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [deleteMultiDocs, setDeleteMultiDocs] = useState([]);

    useEffect(() => {
        props.clearUpdateOpportunityError();
        props.updateOpportunitySuccess();
        if (opportunityDetail) {
            setFormFields({ ...opportunityDetail, IndexFile: [] });
            setFile(opportunityDetail && opportunityDetail.opportunityDocuments || []);
            setCurrentPhase({ label: `${opportunityDetail.opCurrentPhase} - ${opportunityDetail.opCurrentPhaseValue}%`, value: opportunityDetail.opCurrentPhaseId });
            setOppStatus({ label: opportunityDetail.opStatus, value: opportunityDetail.opStatus });
            setOpAction({ label: opportunityDetail.opActionName || 'Select your action', value: opportunityDetail.opActionId });
            setOpType({ label: opportunityDetail.opTypeName, value: opportunityDetail.opTypeId });

            if (opportunityDetail.opCurrency) {
                setCurrency({ label: opportunityDetail.opCurrency || 'Select your currency', value: opportunityDetail.opCurrency });
            }

            setPrevMainCategory({ label: opportunityDetail.opCategoryName, value: opportunityDetail.opCategoryName });
            setExistingSubCategory({ label: opportunityDetail.opSubCategory, value: opportunityDetail.opCategoryId });
            setOwner({ label: opportunityDetail.ownerUserEmail, value: opportunityDetail.ownerUserId });
            setContactOwner({ label: opportunityDetail.companyName, value: opportunityDetail.companyId });
            setPrevContactValue({ label: `${opportunityDetail.contactName} | ${opportunityDetail.contactEmail}`, value: opportunityDetail.contactId });
            setContactName({ label: `${opportunityDetail.contactName} | ${opportunityDetail.contactEmail}`, value: opportunityDetail.contactId });
            setIsActive(opportunityDetail.leadStatus === "true" ? true : false);
            if (opportunityDetail && opportunityDetail.companyId) {
                setKeyChange(keyChange + 1);
                setAccountId(opportunityDetail && opportunityDetail.companyId);
            }
            if (opportunityDetail && opportunityDetail.opCategoryName) {
                setSubKeyChange(subKeyChange + 1);
                setSelectedCategory(opportunityDetail && opportunityDetail.opCategoryName);
            }
        }
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setFormFields({
            ...formFields,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]: null
        })
    }

    const handleChangeCurrencySelect = (value) => {
        if (value === null) {
            setFormFields({
                ...formFields,
                opCurrency: null
            });
            setCurrency(value);
        }
        else {
            setFormFields({
                ...formFields,
                opCurrency: value && value.value
            });
            setCurrency(value);
            setErrors({
                ...errors,
                opCurrency: null
            })
        }
    }

    const handleAccountSelect = (value) => {
        setFormFields({
            ...formFields,
            companyName: value.label,
            companyId: value.value,
            contactId: ''

        })
        setKeyChange(keyChange + 1);
        setAccountId(value.value);
        setContactOwner({ label: value.label, value: value.value });
        setPrevContactValue({ label: 'Select your contact name', value: '' });
        // if (value.value) {
        //     props.getContactAsyncSelectAction(value.value, '', (existingProjectUsers) => {
        //         setExistingContact(existingProjectUsers);
        //     });
        // }
        setErrors({
            ...errors,
            companyId: null
        })
    }
    const handleContactSelect = (value) => {
        setFormFields({
            ...formFields,
            contactId: value.value
        });
        setContactName({ label: value.label, value: value.value });
        setErrors({
            ...errors,
            contactId: null
        })
    }

    const handleUserSelect = (value) => {
        setFormFields({
            ...formFields,
            ownerUserId: value.value
        })
        setOwner({ label: value.label, value: value.value });
        setErrors({
            ...errors,
            ownerUserId: null
        })
    }
    //-----------------------------------------Add Date field---------------------------------------------------------
    const validateOnChangeDate = (e) => {
        const { name, value } = e.target;

        const formattedDate = value || null;
        setFormFields({ ...formFields, [name]: formattedDate });
        if (name === 'startDate') {
            setErrors({ ...errors, startDate: '' });
        }
        if (name === 'endDate') {
            setErrors({ ...errors, endDate: '' });
        }
    };


    const handleTypeSelect = (value) => {
        setFormFields({
            ...formFields,
            opTypeId: value.value
        });
        setOpType(value);
        setErrors({
            ...errors,
            opTypeId: null
        })
    }
    const handleActionSelect = (value) => {
        setOpAction(value);
        setFormFields({
            ...formFields,
            opActionId: value.value
        });

        setErrors({
            ...errors,
            opActionId: null
        })
    }
    const handleChangeStatusSelect = (value) => {
        if (value === null) {
            setOppStatus(value);
            setFormFields({
                ...formFields,
                opStatus: null
            })
        }
        else {
            setOppStatus(value);
            setFormFields({
                ...formFields,
                opStatus: value && value.value
            })
            setErrors({
                ...errors,
                opStatus: null
            })
        }
    }

    const handleActionCurrentPhase = (value) => {
        setFormFields({
            ...formFields,
            opCurrentPhaseId: value.value
        });
        setCurrentPhase(value);
        setErrors({
            ...errors,
            opCurrentPhaseId: null
        })
    }
    const handleCurrentPhase = (inputValue, callback) => {
        const { getCurrentPhaseAsyncSelect } = props;
        return getCurrentPhaseAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleAction = (inputValue, callback) => {
        const { getActionAsyncSelect } = props;
        return getActionAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleType = (inputValue, callback) => {
        const { getTypeAsyncSelect } = props;
        return getTypeAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleUsers = (inputValue, callback) => {
        const { getCrmUserAsyncSelect } = props;
        return getCrmUserAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleAccounts = (inputValue, callback) => {
        const { getAccountsAsyncSelect } = props;
        return getAccountsAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleContacts = (inputValue, callback) => {
        const { getContactAsyncSelectAction } = props;
        if (accountId) {
            return getContactAsyncSelectAction(accountId, inputValue.length > 0 ? inputValue : '', callback);
        }
    }
    const handleAddContact = (value) => {
        setFormFields({ ...formFields, contactId: value.value });
        setPrevContactValue(value);
        setErrors({ ...errors, contactId: '' });
    }
    const handleMainCategory = (inputValue, callback) => {
        const { getCategoryAsyncSelect } = props;
        return getCategoryAsyncSelect(inputValue.length > 0 ? inputValue : '', callback);
    }
    const handleSubCategory = (inputValue, callback) => {
        const { getSubCategoryAsyncSelectAction } = props;
        if (selectedCategory) {
            return getSubCategoryAsyncSelectAction(selectedCategory, inputValue.length > 0 ? inputValue : '', callback);
        }
    }
    const handleMainCategorySelect = (value) => {
        setFormFields({
            ...formFields,
            opCategoryName: value.label,
            opCategoryId: ''
        })
        setSubKeyChange(subKeyChange + 1);
        setSelectedCategory(value.label);
        setPrevMainCategory(value);
        setExistingSubCategory({ label: 'Select your sub category', value: '' });
        // if (value.label) {
        //     props.getSubCategoryAsyncSelectAction(value.label, '', (existingProjectUsers) => {
        //         setAllOptionSubCategory(existingProjectUsers);
        //     });
        // }
        setErrors({
            ...errors,
            opCategoryName: ''
        })
    }

    const handleAddSubCategory = (value) => {

        setFormFields({ ...formFields, opCategoryId: value.value });
        setExistingSubCategory(value);
        setErrors({ ...errors, opCategoryId: '', opCategoryName: '' });
    }
    const createContactOpen = () => {
        console.log("open modal");

        props.openContactsCreate();
    }
    const onRefreshColor = () => {
        let icon = document.getElementById("icon")
        icon.style.color = "grey"
    }

    const onRefresh = () => {
        let icon = document.getElementById("icon")
        icon.style.color = "lightseagreen"
        console.log("onRefresh");

        if (formFields && formFields.companyId) {
            // console.log("working");

            // props.getContactAsyncSelectAction(formFields && formFields.companyId, '', (existingProjectUsers) => {
            //     setExistingContact(existingProjectUsers);
            // });
            // console.log("existingContact 1: ", existingContact);
            setKeyChange(keyChange + 1);
        }
    }
    const handleAccountCreate = (e) => {
        const { expectedValue, opCurrentPhaseId, opActionId, opportunityName, endDate, stage, ownerUserId, companyId, contactId, startDate,
            opCategoryId, opCategoryName, opTypeId } = formFields || {};
        let currentErrors = {};

        if (!opportunityName) {
            currentErrors.opportunityName = "Opportunity name is required";
        }
        // if (!expectedValue) {
        //     currentErrors.expectedValue = "Opportunity value is required";
        // }
        if (!opCategoryName) {
            currentErrors.opCategoryName = "Category is required";
        }
        if (!opCategoryId) {
            currentErrors.opCategoryId = "Sub category is required";
        }
        if (!contactId) {
            currentErrors.contactId = "Contact name is required";
        }
        if (!endDate) {
            currentErrors.endDate = "End date is required";
        }
        if (!startDate) {
            currentErrors.startDate = "Start date is required";
        }
        if (!companyId) {
            currentErrors.companyId = "Account name is required";
        }
        if (!opTypeId) {
            currentErrors.opTypeId = "Type is required";
        }
        if (!opActionId) {
            currentErrors.opActionId = "Action is required";
        }
        if (!opCurrentPhaseId) {
            currentErrors.opCurrentPhaseId = "Current phase is required";
        }
        setErrors(currentErrors);


        if (Object.keys(currentErrors).length === 0) {
            props.updateOpportunity(formFields);
            props.DeleteDocument(deleteMultiDocs, formFields && formFields.id);
        }
        e.preventDefault();
    }

    const handleClose = () => {
        props.closeContactsCreate();
    }
    const handleDone = (data) => {
        console.log("Return from modal:", data);
        if (formFields && formFields.companyId) {
            // props.getContactAsyncSelectAction(data, '', (existingProjectUsers) => {
            //     setExistingContact(existingProjectUsers);
            // });
            setKeyChange(keyChange + 1);
        }
    };

    console.log("error message: ", errorMessage);
    console.log("formFields: ", formFields);
    console.log("owner: ", owner);
    console.log("errors: ", errors);
    console.log("keyChange: ", keyChange);

    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        console.log('selectedFiles:', selectedFiles);
        setError('');

        const validFiles = selectedFiles.filter(file => {
            if (file.size > 5 * 1024 * 1024) {
                setError(prev => `${prev} ${file.name} exceeds 5 MB.`);
                return false; // Exclude invalid files
            }
            return true; // Include valid files
        });
        console.log("validFiles:", validFiles);
        // Update state with valid files
        setFile(prevFiles => [...prevFiles, ...validFiles]);
        setFormFields({ ...formFields, IndexFile: [...formFields.IndexFile, ...validFiles] });
        // Reset input if no files are valid
        if (validFiles.length === 0) {
            e.target.value = ""; // Reset input
        }
    };

    const handleRemoveFile = (file, index) => {
        console.log("file:", file);
        console.log("index:", index);
        if (file.opportunityDocumentId) {
            setDeleteMultiDocs([...deleteMultiDocs, file.opportunityDocumentId]);
            const updatedFiles = Files.filter((_, i) => i !== index);
            setFile(updatedFiles);
        } else {
            const updatedFiles = Files.filter((_, i) => i !== index);
            console.log("updatedFiles:", updatedFiles);

            setFile(updatedFiles);
            const actualIndexFiles = formFields.IndexFile && formFields.IndexFile.filter((i) => i !== file);
            console.log("actualIndexFiles:", actualIndexFiles);

            setFormFields({ ...formFields, IndexFile: actualIndexFiles });
        }
        document.getElementById('file-input').value = "";
        console.log("file:", file);
    };

    console.log("Files:", Files);
    console.log("deleteMultiDocs:", deleteMultiDocs);
    console.log("isOpportunityUpdatePending:", isOpportunityUpdatePending);

    return (
        <div>
            <Form>
                {errorMessage &&
                    (<VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />)
                }
                <div className="fw-bold text-uppercase fs-5 text-dark border-start border-primary border-5 mb-3"> &nbsp;Opportunity Information</div>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Opportunity Name <span className="text-danger">*</span></Label>
                            <Input
                                type="text"
                                name="opportunityName"
                                placeHolder="Enter your opportunity name"
                                onChange={validateOnchange}
                                value={formFields && formFields.opportunityName}
                                invalid={errors && errors.opportunityName}
                            />
                            <FormFeedback>{errors && errors.opportunityName}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label>Account Name <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.companyId) ? 'red' : "silver"
                                    })
                                }}
                                name="companyId"
                                defaultOptions
                                placeholder="Select your account name"
                                loadOptions={handleAccounts}
                                onChange={handleAccountSelect}
                                value={contactOwner}
                            />
                            {(errors && errors.companyId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.companyId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label className="d-flex justify-content-between"><div>Contact Name <span className="text-danger">*</span>
                                &nbsp;
                                <FontAwesomeIcon icon={faRefresh} id="icon"
                                    onClick={onRefresh}
                                    onMouseLeave={onRefreshColor}
                                    style={{ cursor: "pointer" }}
                                    data-toggle="tooltip"
                                    title="Refresh Contact Name" /></div>
                                <Link
                                    onClick={createContactOpen}
                                >&nbsp;
                                    Create Contact
                                </Link></Label>
                            <Async
                                key={keyChange}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.contactId) ? 'red' : "silver"
                                    })
                                }}
                                // className="vm-react-select"
                                name="contactId"
                                onChange={handleAddContact}
                                placeholder="Select your contact name"
                                isSearchable
                                defaultOptions
                                loadOptions={handleContacts}
                                value={prevContactValue}
                            />
                            {(errors && errors.contactId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.contactId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Category <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opCategoryName) ? 'red' : "silver"
                                    })
                                }}
                                name="opCategoryName"
                                defaultOptions
                                placeholder="Select your category"
                                loadOptions={handleMainCategory}
                                onChange={handleMainCategorySelect}
                                value={prevMainCategory}
                            />
                            {(errors && errors.opCategoryName) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.opCategoryName}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Sub Category <span className="text-danger">*</span></Label>
                            <Async
                                key={subKeyChange}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opCategoryId) ? 'red' : "silver"
                                    })
                                }}
                                // className="vm-react-select"
                                name="opCategoryId"
                                onChange={handleAddSubCategory}
                                placeholder="Select your sub category"
                                isSearchable
                                defaultOptions
                                loadOptions={handleSubCategory}
                                value={existingSubCategory}
                            // isDisabled={!(formFields && formFields.opCategoryId)}
                            />
                            {(errors && errors.opCategoryId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.opCategoryId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Opportunity Value</Label>
                            <Input
                                onKeyDown={
                                    (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                                }
                                onWheel={(e) => e.target.blur()}
                                min='0'
                                type="number"
                                name="expectedValue"
                                placeHolder="Enter your opportunity value"
                                onChange={validateOnchange}
                                value={formFields && formFields.expectedValue}
                            // invalid={errors && errors.expectedValue}
                            />
                            {/* <FormFeedback>{errors && errors.expectedValue}</FormFeedback> */}
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Currency</Label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.title) ? 'red' : "silver"
                                    })
                                }}
                                name='title'
                                placeholder='Select your currency'
                                options={currencyOptions}
                                onChange={handleChangeCurrencySelect}
                                value={currency}
                                isClearable
                            />
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Start Date <span className="text-danger">*</span></Label>
                            <Input
                                type="date"
                                name="startDate"
                                placeholder="Select your start date"
                                onChange={validateOnChangeDate}

                                // disabled={!isEdited}
                                max={formFields && formFields.endDate}
                                invalid={errors && errors.startDate}
                                value={moment(formFields && formFields.startDate).format("YYYY-MM-DD")}
                            />{(errors && errors.startDate) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.startDate}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>End Date <span className="text-danger">*</span></Label>
                            <Input
                                type="date"
                                name="endDate"
                                placeholder="Select your end date"
                                onChange={validateOnChangeDate}
                                min={formFields && formFields.startDate}
                                invalid={errors && errors.endDate}
                                value={moment(formFields && formFields.endDate).format("YYYY-MM-DD")}
                            />
                            {(errors && errors.endDate) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.endDate}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col>

                    <Col lg="4">
                        <FormGroup>
                            <Label>Type <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opTypeId) ? 'red' : "silver"
                                    })
                                }}
                                name="opTypeId"
                                defaultOptions
                                placeholder="Select your company"
                                loadOptions={handleType}
                                onChange={handleTypeSelect}
                                value={opType}
                            />
                            {(errors && errors.opTypeId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.opTypeId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Action <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opActionId) ? 'red' : "silver"
                                    })
                                }}
                                name="opActionId"
                                defaultOptions
                                placeholder="Select your action"
                                loadOptions={handleAction}
                                onChange={handleActionSelect}
                                value={opAction}
                            />
                            {(errors && errors.opActionId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.opActionId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Status</Label>
                            <Select
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opStatus) ? 'red' : "silver"
                                    })
                                }}
                                name='opStatus'
                                placeholder='Select your status'
                                options={statusOptions}
                                onChange={handleChangeStatusSelect}
                                value={oppStatus}
                                isClearable
                            />
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Current Phase <span className="text-danger">*</span></Label>
                            <Async
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: (errors && errors.opCurrentPhaseId) ? 'red' : "silver"
                                    })
                                }}
                                name="opCurrentPhaseId"
                                defaultOptions
                                placeholder="Select your current phase"
                                loadOptions={handleCurrentPhase}
                                onChange={handleActionCurrentPhase}
                                value={currentPhase}
                            />
                            {(errors && errors.opCurrentPhaseId) &&
                                <FormText sx={{ marginLeft: "10px" }}>
                                    <div className="text-danger">
                                        {errors && errors.opCurrentPhaseId}
                                    </div>
                                </FormText>
                            }
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                placeHolder="Enter your opportunity notes"
                                onChange={validateOnchange}
                                value={formFields && formFields.description}
                            />
                        </FormGroup>
                    </Col >
                    <Col lg="4">
                        <FormGroup>
                            <Label>Attach Documents</Label>
                            <Input
                                id="file-input"
                                type="file"
                                name="files"
                                accept="*/*"
                                multiple
                                onChange={handleFileChange}
                            /> {error && <div className="text-danger">{error}</div>}
                            <div>
                                <Table className="mb-2">
                                    <tbody>
                                        {Files && Files.map((file, index) => (
                                            // <div key={file.id} >
                                            <tr key={file.id} >
                                                <td className="me-2 mt-2">Selected file: {file.fileName || file.name}</td>
                                                <td>
                                                    <Button
                                                        type="reset"
                                                        color="danger"
                                                        size="sm"
                                                        className="mt-0"
                                                        onClick={() => handleRemoveFile(file, index)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>

                        </FormGroup>
                    </Col>
                </Row>

                <hr className="mt-0" />
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleAccountCreate}
                        disabled={isOpportunityUpdatePending}
                    >
                        {isOpportunityUpdatePending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </Form >
            <VWModel
                header="CREATE CONTACTS"
                modalSize="extra-large"
                isOpen={props.isCreateContactsOpen}
                handleClose={handleClose}
            >
                <CreateContact
                    handleClose={handleClose}
                    AccId={formFields && formFields.companyId}
                    AccName={formFields && formFields.companyName}
                    handleDone={handleDone} />
            </VWModel>
        </div >
    )
}

const mapStateToProps = (state) => ({
    isCreateContactsOpen: state.crmContacts.isCreateContactsOpen,
    errorMessage: state.crmOpportunity.opportunityUpdateErrorMessage,
    isOpportunityCreateSuccess: state.crmOpportunity.isOpportunityCreateSuccess,
    isOpportunityUpdatePending: state.crmOpportunity.isOpportunityUpdatePending,
})
const mapDispatchToProps = (dispatch) => ({
    updateOpportunity: (data) => dispatch(updateOpportunity(data)),
    DeleteDocument: (data, detailID) => dispatch(DeleteDocument(data, detailID)),
    openContactsCreate: () => dispatch(openContactsCreate()),
    closeContactsCreate: () => dispatch(closeContactsCreate()),
    updateOpportunitySuccess: () => dispatch(updateOpportunitySuccess()),
    clearUpdateOpportunityError: () => dispatch(clearUpdateOpportunityError()),
    getCrmUserAsyncSelect: (searchValue, callback) => dispatch(getCrmUserAsyncSelect(searchValue, callback)),
    getAccountsAsyncSelect: (searchValue, callback) => dispatch(getAccountsAsyncSelect(searchValue, callback)),
    getContantsAsyncSelect: (searchValue, callback) => dispatch(getContantsAsyncSelect(searchValue, callback)),
    getContactAsyncSelectAction: (id, searchValue, callback) => dispatch(getContactAsyncSelectAction(id, searchValue, callback)),
    getCategoryAsyncSelect: (searchValue, callback) => dispatch(getCategoryAsyncSelect(searchValue, callback)),
    getTypeAsyncSelect: (searchValue, callback) => dispatch(getTypeAsyncSelect(searchValue, callback)),
    getActionAsyncSelect: (searchValue, callback) => dispatch(getActionAsyncSelect(searchValue, callback)),
    getCurrentPhaseAsyncSelect: (searchValue, callback) => dispatch(getCurrentPhaseAsyncSelect(searchValue, callback)),
    getSubCategoryAsyncSelectAction: (oppCategory, searchValue, callback) => dispatch(getSubCategoryAsyncSelectAction(oppCategory, searchValue, callback)),
})
export default connect(mapStateToProps, mapDispatchToProps)(UpdateLeads);