import { getToken } from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_ROLE_LIST_ERROR, SET_ROLE_LIST, SET_ROLE_LIST_ERROR, SET_ROLE_LIST_PENDING } from "./constants";



export const setRoleList = (roleList) => ({
    type: SET_ROLE_LIST,
    payload: { roleList },
})
export const roleListError = (errorMessage) => ({
    type: SET_ROLE_LIST_ERROR,
    payload: { errorMessage },
})
export const clearRoleListError = () => ({
    type: CLEAR_ROLE_LIST_ERROR,
})
export const setRoleListPending = () => ({
    type: SET_ROLE_LIST_PENDING,
})

export const getRoleListAction = (page, PageSize, searchRoleName) => (dispatch) => {
    dispatch(setRoleListPending());
    getToken(dispatch).then((accessToken) => {
        agent.Role.getRolelist(accessToken, page, PageSize, searchRoleName)
            .then((roleList) => {
                dispatch(setRoleList(roleList));
                dispatch(clearRoleListError());
            })
            .catch((err) => {
                console.log('getRoleListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(roleListError(error))
            });
    });
};