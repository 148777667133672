import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import withRouter from "../withRouter";

// users
import { clearSetLoginPath, clearSetPath, getCurrentProfile, logoutUser, } from "../../../store/actions";

const ProfileMenu = (props) => {

  const { currentProfile } = props;
  const { displayName, orgName, profilePicture } = currentProfile || '';
  const { userAppDetails, roles } = currentProfile || [];

  const pathName = props.router.location.pathname;
  const params = pathName.split('/')[1]

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const [username, setusername] = useState("Admin");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);


  const handleLogOut = () => {
    console.log("path:", props.path);

    props.clearSetPath();
    props.logoutUser();
    console.log("path:", props.path);
    //  localStorage.clear();
    //  sessionStorage.removeItem();
    //  window.location.href='login'
  };

  //----------------------OAuth Logout--------------------------------------------

  const OAuth_apiUrl = window.__ENV && window.__ENV.OAuth_apiUrl;
  const client_id = window.__ENV && window.__ENV.client_id;

  const OAuthLogout = () => {
    console.log("window.location.origin:", window.location.origin);
    console.log("path:", props.path);
    props.clearSetPath();
    props.clearSetLoginPath();
    const OAuthLogout = `${OAuth_apiUrl}/v2/logout?client_id=${client_id}&returnTo=${encodeURIComponent(window.location.origin)}`
    // await fetch(OAuthLogout)
    // navigate(OAuthLogout)
    window.location.replace(OAuthLogout);
    console.log("path:", props.path);
    console.log("OAuthLogout:", OAuthLogout);
    props.clearSetPath();
  }
  const handleMultipleFunction = () => {
    props.clearSetPath();
    props.clearSetLoginPath();
    OAuthLogout();
    handleLogOut();

  }
  // const name = currentProfile.email && currentProfile.email.split("@") ;
  // const RoleName = localStorage.getItem("RoleName");

  useEffect(() => {
    props.getCurrentProfile();
  }, [])

  const superAdminAllowedRoles = [
    "administrator",
    "super admin",
  ];

  const allowedRoles = [
    "administrator",
    "user"
  ];

  const dbSyncAllowedRoles = [
    'administrator'
  ]

  if (!props.isAuth) {
    OAuthLogout();
    return <Navigate to='/' />;
  } else {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item "
            id="page-header-user-dropdown"
            tag="button"
          >
            <Avatar
              src={profilePicture || null}
              name={profilePicture ? null : displayName}
              round size={params === "dashboard" ? 40 : 30} />
            {/*<i className="mdi mdi-chevron-down d-none d-xl-inline-block" />*/}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end" style={{ width: "300px" }}>
            {/* <DropdownItem className="px-4 py-3"> */}
            <div className="px-4 py-3">
              <span>
                <h4>{displayName}</h4>
                <h5 className="text-capitalize">{orgName}</h5>
                <div className="">
                  {roles && roles.map((item) =>
                    <Badge className="mt-2 me-1 p-2  text-capitalize" color="primary">{item.roleName || "NA"}</Badge>)}
                </div>
              </span>
            </div>
            <DropdownItem divider />
            <Link
              style={{ textDecoration: 'none', color: 'black' }}
              to={params === "dashboard" ? "/profile" : `/${params}/userprofile`}
            >
              <DropdownItem>
                <i className="fa fa-user pe-2" aria-hidden="true"></i>
                My Profile
              </DropdownItem>
            </Link>
            <DropdownItem divider />
            {/* <DropdownItem > */}
            {userAppDetails ?
              (<ListGroup>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2px"
                  }}
                  className="px-2"
                >
                  {userAppDetails && userAppDetails
                    .filter(item => item.appName.toLowerCase() === "admin")
                    .map(userApp => {
                      const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => superAdminAllowedRoles.includes(role.roleName.toLowerCase()));
                      if (allowedModulesRoles && allowedModulesRoles.length > 0) {
                        return (
                          <Link to="/admin/organization" style={{ color: 'black' }}>
                            {userAppDetails && userAppDetails.filter(item => item && item.appName.toLowerCase() === "admin").length > 0 &&
                              (params === "admin" ? "" :
                                <ListGroupItem style={{ border: '2px solid white', backgroundColor: "#54ADF5" }}>
                                  Admin
                                </ListGroupItem>
                              )}
                          </Link>
                        );
                      }
                      return null;
                    })
                  }

                  {userAppDetails && userAppDetails
                    .filter(item => item.appName.toLowerCase() === "employee")
                    .map(userApp => {
                      const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                      if (allowedModulesRoles && allowedModulesRoles.length > 0) {
                        return (
                          <Link to="/employees/employee" style={{ color: 'black' }}>
                            {userAppDetails && userAppDetails.filter(item => item && item.appName.toLowerCase() === "employee").length > 0 &&
                              (params === "employees" ? "" :
                                <ListGroupItem color="primary" style={{ border: '2px solid white' }}>
                                  Employee
                                </ListGroupItem>
                              )}
                          </Link>
                        );
                      }
                      return null;
                    })
                  }

                  {userAppDetails && userAppDetails
                    .filter(item => item.appName.toLowerCase() === "leavetracker")
                    .map(userApp => {
                      const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                      if (allowedModulesRoles && allowedModulesRoles.length > 0) {
                        return (
                          <Link to="/leavetracker/dashboard" style={{ color: 'black' }}>
                            {userAppDetails && userAppDetails.filter(item => item && item.appName.toLowerCase() === "leavetracker").length > 0 &&
                              (params === "leavetracker" ? "" :
                                <ListGroupItem color="danger" style={{ border: '2px solid white' }}>
                                  Leave Tracker
                                </ListGroupItem>
                              )}
                          </Link>
                        );
                      }
                      return null;
                    })
                  }

                  {userAppDetails && userAppDetails
                    .filter(item => item.appName.toLowerCase() === "timesheets")
                    .map(userApp => {
                      const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                      if (allowedModulesRoles && allowedModulesRoles.length > 0) {
                        return (
                          <Link to="/timesheets/dashboard" style={{ color: 'black' }}>
                            {userAppDetails && userAppDetails.filter(item => item && item.appName.toLowerCase() === "timesheets").length > 0 &&
                              (params === "timesheets" ? "" :
                                <ListGroupItem style={{ backgroundColor: "#ebd0ff", border: '2px solid white' }}>
                                  Timesheets
                                </ListGroupItem>
                              )}
                          </Link>
                        );
                      }
                      return null;
                    })
                  }

                  {userAppDetails && userAppDetails
                    .filter(item => item.appName.toLowerCase() === "projectportal")
                    .map(userApp => {
                      const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                      if (allowedModulesRoles && allowedModulesRoles.length > 0) {
                        return (
                          <Link to="/projectportal/dashboard" style={{ color: 'black' }}>
                            {userAppDetails && userAppDetails.filter(item => item && item.appName.toLowerCase() === "projectportal").length > 0 &&
                              (params === "projectportal" ? "" :
                                <ListGroupItem color="success" style={{ border: '2px solid white' }}>
                                  Project Portal
                                </ListGroupItem>
                              )}
                          </Link>
                        );
                      }
                      return null;
                    })
                  }

                  {userAppDetails && userAppDetails
                    .filter(item => item.appName.toLowerCase() === "massmailer")
                    .map(userApp => {
                      const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => allowedRoles.includes(role.roleName.toLowerCase()));
                      if (allowedModulesRoles && allowedModulesRoles.length > 0) {
                        return (
                          <Link to="/massmailer/dashboard" style={{ color: 'black' }}>
                            {userAppDetails && userAppDetails.filter(item => item && item.appName.toLowerCase() === "massmailer").length > 0 &&
                              (params === "massmailer" ? "" :
                                <ListGroupItem style={{ border: '2px solid white', backgroundColor: "#cf7e7e" }}>
                                  Mass Mailer
                                </ListGroupItem>
                              )}
                          </Link>
                        );
                      }
                      return null;
                    })
                  }

                  {userAppDetails && userAppDetails
                    .filter(item => item.appName.toLowerCase() === "db sync")
                    .map(userApp => {
                      const allowedModulesRoles = userApp && userApp.appRoleDetails && userApp.appRoleDetails.filter(role => dbSyncAllowedRoles.includes(role.roleName.toLowerCase()));
                      if (allowedModulesRoles && allowedModulesRoles.length > 0) {
                        return (
                          <Link to="/dbsync/list" style={{ color: 'black' }}>
                            {userAppDetails && userAppDetails.filter(item => item && item.appName.toLowerCase() === "Db Sync").length > 0 &&
                              (params === "dbsync" ? "" :
                                <ListGroupItem style={{ border: '2px solid white', backgroundColor: "#f23e18" }}>
                                  DB Sync
                                </ListGroupItem>
                              )
                            }
                          </Link>
                        );
                      }
                      return null;
                    })
                  }

                  {userAppDetails && userAppDetails
                    .map(item => item["appName"])
                    .find((item) => item.toLowerCase() === "vision docs") &&
                    (<Link to="/visiondocs/documents" style={{ color: 'black' }}>
                      {params === "visiondocs" ? "" :
                        <ListGroupItem style={{ border: '2px solid white', backgroundColor: "orange" }}>
                          Vision Docs
                        </ListGroupItem>}
                    </Link>)
                  }

                  {userAppDetails && userAppDetails
                    .map(item => item["appName"])
                    .find((item) => item.toLowerCase() === "crm") &&
                    (<Link to="/crm/dashboard" style={{ color: '#e85c90' }}>
                      {params === "crm" ? "" :
                        <ListGroupItem style={{ border: '2px solid white', backgroundColor: "#e85c90" }}>
                          CRM
                        </ListGroupItem>}
                    </Link>)
                  }

                  {/* <Link to="/massmailer/dashboard" style={{ color: 'black' }}>
                    {params === "massmailer" ? "" :
                      <ListGroupItem style={{ border: '2px solid white', backgroundColor: "#cf7e7e" }}>
                        Mass Mailer
                      </ListGroupItem>}
                  </Link> */}
                </div>
              </ListGroup>)
              : null
            }
            {userAppDetails && userAppDetails.length > 0 ?
              <DropdownItem divider />
              : null
            }
            <div className="px-4 py-3">
              <Button
                color="success"
                onClick={handleMultipleFunction}
              >
                Logout
              </Button>
            </div>
            {/* </DropdownItem> */}
            <DropdownItem divider />
          </DropdownMenu>
        </Dropdown>
      </React.Fragment >
    );
  }
};


ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};



const mapStatetoProps = state => {

  const { error, success } = state.profile;
  const { currentProfile } = state.login;
  const { isAuth, path } = state.login;
  return { error, success, currentProfile, isAuth, path };
};
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  getCurrentProfile: () => dispatch(getCurrentProfile()),
  clearSetPath: () => dispatch(clearSetPath()),
  clearSetLoginPath: () => dispatch(clearSetLoginPath()),
});

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(withTranslation()(ProfileMenu))
);
