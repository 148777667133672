//eslint-disable-next-line
export const SET_ALL_TIMESHEET_LIST = "SET_ALL_TIMESHEET_LIST";
export const ALL_TIMESHEET_LIST_ERROR = "ALL_TIMESHEET_LIST_ERROR"
export const ALL_TIMESHEET_LIST_PENDING = "ALL_TIMESHEET_LIST_PENDING"
export const CLEAR_ALL_TIMESHEET_LIST_ERROR = "CLEAR_ALL_TIMESHEET_LIST_ERROR"
export const ALL_TIMESHEET_EXPORT_LIST_PENDING = "ALL_TIMESHEET_EXPORT_LIST_PENDING"
export const SET_ALL_TIMESHEET_EXPORT_LIST = "SET_ALL_TIMESHEET_EXPORT_LIST"
export const CLEAR_ALL_TIMESHEET_EXPORT_LIST_ERROR = "CLEAR_ALL_TIMESHEET_EXPORT_LIST_ERROR"
export const ALL_TIMESHEET_EXPORT_LIST_ERROR = "ALL_TIMESHEET_EXPORT_LIST_ERROR"


export const ALL_TIMESHEET_EXPORT_HEADERS = [
    "USER NAME",
    "USER EMAIL",
    "PROJECT NAME",
    "TASK NAME",
    "TASK CURRENT STATUS",
    "WORK DATE",
    "WORK HOURS",
    "SCOPE",
    "CREATED DATE",
    "STATUS",
    "UPDATED DATE",
];
