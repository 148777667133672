import React, { useEffect, useState } from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormFeedback,
    FormGroup,
    Input,
    Table
} from "reactstrap";
import { connect } from "react-redux";
import moment from 'moment';
import VWModel from "../../../../../../components/Modal/VWModal";
import VWMessage from "../../../../../../components/vwMessage/vwMessage";
import NoRecord from "../../../../../../components/NoRecords/noRecords";
import VWPagination from "../../../../../../components/vwPagination/vwPagination";
import { getPager } from "../../../../../../helper/common";
import VWSpinner from "../../../../../../components/vwSpinner/vwSpinner";
import {
    clearUpdateRegionError,
    closeAddRegionModal,
    closeEditRegionModal,
    getRegionList,
    openAddRegionModal,
    openEditRegionModal,
    setRegionDetails,
    updateRegion,
    updateRegionSuccess
} from "../../../../../../store/Modules/crm/Settings/Region/actions";
import AddRegion from "./addRegion";
import EditRegion from "./editRegion";
import { setIsInlineUpdate } from "../../../../../../store/Modules/crm/Settings/SettingsInlineUpdate/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";

const Region = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });

    const setPage = (page = 1) => {
        props.getRegionList(page, PageSize);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.setIsInlineUpdate();
        props.clearUpdateRegionError();
        props.updateRegionSuccess();
    }, [])
    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedRegionList = [...props.regionList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleAddOpenRegion = () => {
        props.openAddRegionModal();
    }
    const handleClose = () => {
        props.closeAddRegionModal();
    };

    const handleRegionEdit = (id) => {
        const { setRegionDetails, openEditRegionModal } = props;
        const selected_Region = sortedRegionList.find((region) => {
            return region.Id === id;
        });
        setRegionDetails(selected_Region);
        openEditRegionModal();
    }
    const handleEditClose = () => {
        props.closeEditRegionModal();
    };

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();
    const [editInlineList, setEditInlineList] = useState({ regionName: '' });

    const handleEditClick = (itemId, IsActive, regionName) => {
        props.setIsInlineUpdate(itemId);
        setActive(IsActive);
        setEditInlineList({ ...editInlineList, regionName: regionName, isActive: IsActive, Id: itemId });
        setErrors({});
    };
    const handleClearClick = () => {
        props.setIsInlineUpdate(null);
    };

    const handleSave = (e) => {
        let currentErrors = {};
        const { regionName } = editInlineList || {};

        if (!regionName) {
            currentErrors.regionName = 'Region name is required';
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [editInlineList.Id]: currentErrors
        }));
        if (Object.keys(currentErrors).length === 0) {
            props.updateRegion(editInlineList)

        }
    }


    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setEditInlineList({
            ...editInlineList,
            [name]: value
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            [editInlineList.Id]: {
                ...prevErrors[editInlineList.Id],
                [name]: value ? null : `Region name is required`
            }
        }));
    }
    const handleActiveStatusChange = () => {
        setEditInlineList({
            ...editInlineList,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    return (
        <React.Fragment>
            <div>
                <Card >
                    <CardTitle className="fs-5 fw-bold border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center">
                        REGION
                        <Button
                            className='fw-bold'
                            size='sm'
                            color='info'
                            onClick={handleAddOpenRegion} >
                            ADD
                        </Button>
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody>
                        <div style={{ height: "380px", overflowX: "auto" }}>
                            {(props.errorMessage || props.regionUpdateErrorMessage) ? <div className='w-100'>
                                {(props.errorMessage || props.regionUpdateErrorMessage) && (
                                    <VWMessage type="danger" defaultMessage={props.errorMessage || props.regionUpdateErrorMessage} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <tr>
                                        <th onClick={() => handleSort('RegionName')} className='hand-cursor'>
                                            Region Name &nbsp;
                                            {sortOrder === 'RegionName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('IsActive')} className='hand-cursor'>
                                            Status &nbsp;
                                            {sortOrder === 'IsActive' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('CreatedBy')} className='hand-cursor'>
                                            Created By &nbsp;
                                            {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.regionList && props.regionList.length > 0 ?
                                        sortedRegionList && sortedRegionList.map((data) => (
                                            <tr class="middle-align">
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <FormGroup>
                                                            <Input
                                                                size='md'
                                                                type="text"
                                                                name="regionName"
                                                                placeHolder="Enter region name"
                                                                onChange={validateOnchange}
                                                                value={editInlineList.regionName}
                                                                invalid={errors && errors[editInlineList.Id]?.regionName}
                                                            />
                                                            <FormFeedback>{errors && errors[editInlineList.Id]?.regionName}</FormFeedback>
                                                        </FormGroup>
                                                    </td>
                                                    :
                                                    <td>{data.RegionName || "N/A"}</td>
                                                }
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <FormGroup>
                                                            <ul className="form-settings-unstyled-list form-settings-status-list">
                                                                <li
                                                                    onClick={handleActiveStatusChange}
                                                                    className={`form-settings-status-item ${!Active ? 'active' : ''}`}
                                                                >
                                                                    Inactive
                                                                </li>
                                                                <li
                                                                    onClick={handleActiveStatusChange}
                                                                    className={`form-settings-status-item ${Active ? 'active' : ''}`}
                                                                >
                                                                    Active
                                                                </li>
                                                            </ul>
                                                        </FormGroup>
                                                    </td>
                                                    :
                                                    <td>
                                                        {data.IsActive ? (
                                                            <Badge color="success">Active</Badge>
                                                        ) : (
                                                            <Badge color="danger">Inactive</Badge>
                                                        )}
                                                    </td>
                                                }
                                                <td>
                                                    {data.CreatedBy || "N/A"}<br />
                                                    <small>{data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                                </td>
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <>
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                style={{ color: "green", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleSave()}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faXmark}
                                                                style={{ color: "red", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleClearClick(data.Id)}
                                                            />
                                                        </>
                                                    </td>
                                                    :
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                            onClick={() => handleEditClick(data.Id, data.IsActive, data.RegionName)}
                                                        />
                                                    </td>
                                                }
                                            </tr>
                                        ))
                                        : props.isRegionListPending ?
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <VWSpinner />
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {props.regionList && props.regionList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="ADD REGION"
                    modalSize="medium"
                    isOpen={props.isCreateRegionOpen}
                    handleClose={handleClose}
                >
                    <AddRegion
                        handleClose={handleClose}
                    />
                </VWModel>
                <VWModel
                    header="EDIT REGION"
                    modalSize="medium"
                    isOpen={props.isUpdateRegionOpen}
                    handleClose={handleEditClose}
                >
                    <EditRegion
                        handleClose={handleEditClose}
                    />
                </VWModel>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    regionList: state.crmRegion.regionList,
    totalCount: state.crmRegion.regionListCount,
    errorMessage: state.crmRegion.regionListErrorMessage,
    isRegionListPending: state.crmRegion.isRegionListPending,
    isCreateRegionOpen: state.crmRegion.isCreateRegionOpen,
    isUpdateRegionOpen: state.crmRegion.isUpdateRegionOpen,
    isInlineEditID: state.SettingsInlineEdit.isInlineEditID,
    isRegionUpdatePending: state.crmRegion.isRegionUpdatePending,
    regionUpdateErrorMessage: state.crmRegion.regionUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    getRegionList: (page, PageSize) => dispatch(getRegionList(page, PageSize)),
    openAddRegionModal: () => dispatch(openAddRegionModal()),
    closeAddRegionModal: () => dispatch(closeAddRegionModal()),
    openEditRegionModal: () => dispatch(openEditRegionModal()),
    closeEditRegionModal: () => dispatch(closeEditRegionModal()),
    setRegionDetails: (categoryDetails) => dispatch(setRegionDetails(categoryDetails)),
    setIsInlineUpdate: (itemId) => dispatch(setIsInlineUpdate(itemId)),
    updateRegion: (regionDetail) => dispatch(updateRegion(regionDetail)),
    clearUpdateRegionError: () => dispatch(clearUpdateRegionError()),
    updateRegionSuccess: () => dispatch(updateRegionSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Region);