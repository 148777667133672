import { getToken } from "../../../helper/common";
import { clearForm } from "../../form/actions";
import agent from "../../services/agent";
import { showSnackbarStatus } from "../../snackbar/actions";
import { CLEAR_CREATE_DB_ERROR, CLEAR_DB_SYNC_LIST_ERROR, CLOSE_CREATE_DB_MODAL, CLOSE_DELETE_DB_SYNC_MODAL, CREATE_DB_ERROR, CREATE_DB_PENDING, CREATE_DB_SUCCESS, DB_SYNC_LIST_ERROR, DELETE_DB_SYNC_ERROR, OPEN_CREATE_DB_MODAL, OPEN_DELETE_DB_SYNC_MODAL, SET_DB_SYNC_LIST, SET_DB_SYNC_PENDING } from "./constants";


export const setDBSyncList = (dbSyncList) => ({
    type: SET_DB_SYNC_LIST,
    payload: { dbSyncList },
})
export const clearDBSyncListError = () => ({
    type: CLEAR_DB_SYNC_LIST_ERROR,
})
export const DBSyncListError = (errorMessage) => ({
    type: DB_SYNC_LIST_ERROR,
    payload: { errorMessage },
})
export const setDBSyncPending = () => ({
    type: SET_DB_SYNC_PENDING,
})

export const createDBError = (errorMessage) => ({
    type: CREATE_DB_ERROR,
    payload: { errorMessage },
});
export const openCreateDBModalAction = () => ({
    type: OPEN_CREATE_DB_MODAL,
});
export const closeCreateDBModalAction = () => ({
    type: CLOSE_CREATE_DB_MODAL,
});
export const createDBPending = () => ({
    type: CREATE_DB_PENDING,
});
export const clearCreateDBError = () => ({
    type: CLEAR_CREATE_DB_ERROR,
});

export const createDBSuccess = () => ({
    type: CREATE_DB_SUCCESS,
});

export const openActionDBSyncDeleteModalAction = () => ({
    type: OPEN_DELETE_DB_SYNC_MODAL,
});

export const closeActionDBSyncDeleteModalAction = () => ({
    type: CLOSE_DELETE_DB_SYNC_MODAL,
});

export const deleteDBSyncError = (errorMessage) => ({
    type: DELETE_DB_SYNC_ERROR,
    payload: { errorMessage },
});


export const getDBSyncAction = (page, PageSize, searchDatabaseName, searchServerName, searchTableCount, searchNoOfRows, searchMissingSync, searchNoOfSync, searchLastSyncedTime) => (dispatch) => {
    dispatch(setDBSyncPending());
    getToken(dispatch).then((accessToken) => {
        agent.DBSync.getDBSynclist(accessToken, page, PageSize, searchDatabaseName, searchServerName, searchTableCount, searchNoOfRows, searchMissingSync, searchNoOfSync, searchLastSyncedTime)
            .then((dbSyncList) => {
                dispatch(setDBSyncList(dbSyncList));
                dispatch(clearDBSyncListError());
            })
            .catch((err) => {
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.value;
                }
                else {
                    error = "Network Error"
                }
                dispatch(DBSyncListError(error))
                // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};


export const createDB = (formFields) => (dispatch) => {

    // if (!formFields) {
    //     dispatch(createDBError('Please fill required details.'));
    //     return;
    // }

    const DBSyncCreate = {
        dbName: formFields && formFields.dbName,
        serverName: formFields && formFields.serverName,
        login: formFields && formFields.login,
        pwd: formFields && formFields.pwd
    }

    console.log(DBSyncCreate)
    dispatch(createDBPending());
    dispatch(clearCreateDBError());
    getToken(dispatch).then((accessToken) => {
        agent.DBSync.createDBSync(accessToken, DBSyncCreate)
            .then(() => {
                dispatch(createDBSuccess());
                dispatch(clearForm());
                dispatch(closeCreateDBModalAction());
                dispatch(getDBSyncAction());
                dispatch(showSnackbarStatus('DB created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createDBError(err.response && err.response.text));
                    return;
                }
                console.log('Create App Master server error', err);
                dispatch(clearForm());
                dispatch(closeCreateDBModalAction());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

export const deleteDBSync = (id) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        // console.log("accessToken:", accessToken)
        agent.DBSync.getDeleteDBSyncList(accessToken, id).then(
            (dbsyncdeleted) => {
                dispatch(closeActionDBSyncDeleteModalAction());
                dispatch(getDBSyncAction());
            }
        ).catch((err) => {
            console.log("err:", err.response && err.response.body.value);
            dispatch(showSnackbarStatus(err.response && err.response.text));
            dispatch(deleteDBSyncError(err.response && err.response.text));
        })
    })
}