/* eslint-disable camelcase */
import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { configureStore } from "../store";
import { USER_LOGOUT } from "../auth/login/actionTypes";


const superagent = superagentPromise(_superagent, Promise);



const handleErrors = async (err) => {
  if (err && err.status === 401) {
    console.log("err:", err.status);
    const store = configureStore();
    await store.dispatch({ type: USER_LOGOUT });
    // localStorage.setItem('reduxState', undefined);
    localStorage.removeItem("reduxState")
  }
  // else if (err && typeof err.message === 'string') {
  //   try {
  //     const errorMessage = JSON.parse(err.message);
  //     if (errorMessage.error === 'invalid_grant' && errorMessage.error_description === 'Invalid authorization code') {
  //       const store = configureStore();
  //       await store.dispatch({ type: USER_LOGOUT });
  //     }
  //   } catch (parseError) {
  //     console.error('Error parsing error message:', parseError);
  //   }
  // }
};

const responseBody = (res) => res.body;

const refresh_token = 'refresh_token'
const apiUrl = window.__ENV && window.__ENV.apiUrl;
const OAuth_apiUrl = window.__ENV && window.__ENV.OAuth_apiUrl;
const grant_type = window.__ENV && window.__ENV.grant_type;
const redirect_uri = window.__ENV && window.__ENV.redirect_uri;
const client_id = window.__ENV && window.__ENV.client_id;
const client_secret = window.__ENV && window.__ENV.client_secret;

const requests = {
  post: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .then(responseBody),
  postText: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .then((responseBody) => responseBody.text),
  postData: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .on("error", handleErrors)
      .then(responseBody),
  getWithToken: (url, token) =>
    superagent
      .get(url)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  getFileWithToken: (url, token) =>
    superagent
      .get(url)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .responseType('blob')
      .then(responseBody),
  postWithToken: (url, body, token) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),

  postWithTokenOnly: (url, token) =>
    superagent
      .post(url)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),

  postToDeleteWithToken: (url, token) =>
    superagent
      .post(url, token)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),

  postMultiPartWithToken: (url, body, token) =>
    superagent
      .post(url, body)
      // .set("Content-Type", "multipart/form-data")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),
  deleteWithToken: (url, token) =>
    superagent
      .del(url, token)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),
  deletebodyWithToken: (url, body, token) =>
    superagent
      .del(url)
      .send(body)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),
  putWithToken: (url, body, token) =>
    superagent
      .put(url, body, token)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),
};

const Auth = {
  // login: (email,Password) => {
  //   const formData = {
  //     username: email,
  //     password: Password
  //   }
  //   console.log(email, Password)
  /* eslint-disable prefer-template */
  // const formData =
  //   "username=" +
  //   encodeURIComponent(email) +
  //   "&password=" +
  //   encodeURIComponent(password) +
  //   "&grant_type=password";
  // return requests.post(`${apiUrl}/api/Auth`,formData);
  // },
  login: (Code) => {

    const formData = { //eslint-disable-next-line
      "grant_type": grant_type,
      "code": Code,//eslint-disable-next-line
      "redirect_uri": redirect_uri,//eslint-disable-next-line
      "client_id": client_id,//eslint-disable-next-line
      "client_secret": client_secret,
      "audience": "https://vizoapp.us.auth0.com/api/v2/"
    };
    //eslint-disable-next-line
    return requests.postData(`${OAuth_apiUrl}/oauth/token`, formData);
  },
  getRefreshToken: (refreshToken) => {
    console.log("refreshToken:", refreshToken);
    const formData = { //eslint-disable-next-line
      "grant_type": refresh_token,
      "client_id": client_id,//eslint-disable-next-line
      "client_secret": client_secret,
      "refresh_token": refreshToken
    };
    //eslint-disable-next-line
    return requests.postData(`${OAuth_apiUrl}/oauth/token`, formData);
  },
  getCurrentUser: (id_token) => {
    return requests.getWithToken(`${apiUrl}/api/Profile`, id_token);
  },
  enableAccount: (formfields) => {
    return requests.post(`${apiUrl}/api/WebRegister/Enable`, formfields);
  },

  forgotPassword: (Email: string) => {
    // console.log(Email)
    return requests.postText(`${apiUrl}/api/Auth/ForgotPassword`, Email);
  },
  resetPassword: (data: any, Code) => {
    // console.log(data)
    const { Password, ConfirmPassword, EmailId } = data;

    return requests.post(`${apiUrl}/api/Auth/Resetpassword`, {
      Password, ConfirmPassword, EmailId,
      Code,
    });
  },
  changePassword: (Token, data) => {
    console.log(data)
    return requests.postWithToken(`${apiUrl}/api/Auth/ChangePassword`, data, Token);
  },
  editProfileDetails: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Profile/Update`, data, accessToken);
  },
};

//-------------------------------------TimeSheet--------------------------------------------------------------
const TimeSheet = {
  getProjectlist: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/OrgProjectList?$count=true&$orderby=ProjectName asc&$select=ProjectName,Id`;
    if (searchValue) {
      url = `${apiUrl}/odata/OrgProjectList?$count=true&$select=ProjectName,Id&$filter=contains(tolower(ProjectName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getTasklist: (accessToken, appId, ProjectID, userId, searchValue) => {
    let url = `${apiUrl}/odata/ProjectTaskList?AppId=${appId}&$filter=ProjectId eq '${ProjectID}' and (AssignedUserId eq '${userId}' or ReviewerUserId eq '${userId}')`;
    if (searchValue) {
      url += ` and contains(tolower(TaskName),tolower('${encodeURIComponent(searchValue)}'))`
    }
    url += '&$select=Id,TaskName'
    return requests.getWithToken(url, accessToken);
  },
  getTimeSheetExportList: (accessToken, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchStatus, id) => {

    let url = `${apiUrl}/Timesheet/Export?$count=true&$orderby=projectName asc`;
    if (searchuserName || searchprojectName || searchtaskName || searchCurrentTaskStatus || searchType || searchworkHours || searchworkDate || searchcreatedDateTime || searchStatus || id) {
      url += `&$filter=`;
      const filterArray = [];

      if (searchuserName) {
        if (searchuserName.toLowerCase() === 'n/a') {
          filterArray.push(`userName eq null`);
        } else {
          const nameFilter = `(contains(tolower(userName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchprojectName) {
        if (searchprojectName.toLowerCase() === 'n/a') {
          filterArray.push('projectName eq null');
        } else {
          filterArray.push(`contains(tolower(projectName),tolower('${encodeURIComponent(searchprojectName)}'))`);
        }
      }
      if (searchtaskName) {
        if (searchtaskName.toLowerCase() === 'n/a') {
          filterArray.push('taskName eq null');
        } else {
          filterArray.push(`contains(tolower(taskName),tolower('${encodeURIComponent(searchtaskName)}'))`);
        }
      }
      if (searchType) {
        if (searchType.toLowerCase() === 'n/a') {
          filterArray.push('ScopeName eq null');
        } else {
          filterArray.push(`contains(tolower(ScopeName),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchCurrentTaskStatus) {
        if (searchCurrentTaskStatus.toLowerCase() === 'n/a') {
          filterArray.push('CurrentTaskStatus eq null');
        } else {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(searchCurrentTaskStatus)}'`);
        }
      }
      if (searchworkHours) {
        if (searchworkHours.toLowerCase() === 'n/a') {
          filterArray.push('WorkHours eq null');
        } else {
          filterArray.push(`contains(tolower(WorkHours),tolower('${encodeURIComponent(searchworkHours)}'))`);
        }
      }
      if (searchworkDate) {
        if (searchworkDate.toLowerCase() === 'n/a') {
          filterArray.push('workDate eq null');
        } else {
          filterArray.push(`workDate eq ${encodeURIComponent(searchworkDate)}`);
        }
      }
      if (searchcreatedDateTime) {
        if (searchcreatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('createdDateTime eq null');
        } else {
          filterArray.push(`createdDateTime eq ${encodeURIComponent(searchcreatedDateTime)}`);
        }
      }

      if (searchStatus || id) {
        let status = "";
        if (searchStatus) {
          status = searchStatus.toLowerCase() === "all" ? "" : searchStatus;
        } else if (id === "All") {
          status = "";
        } else {
          status = id;
        }
        if (status.toLowerCase() === 'na') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`contains(tolower(Status), tolower('${encodeURIComponent(status)}'))`);
        }
      }


      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },

  getTimeSheetList: (accessToken, filteredData, currentPage, PageSize = 15, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchStatus, id, StartDate, EndDate) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filteredData:", filteredData);

    const { UserName, projectName, taskName, CurrentTaskStatus, ScopeName, WorkHours, workDate, createdDateTime, Status, startDate, endDate } = filteredData || {};
    let url = `${apiUrl}/odata/TimeSheetList?$count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`;
    if (searchuserName || searchprojectName || searchtaskName || searchCurrentTaskStatus || searchType || searchworkHours ||
      searchworkDate || searchcreatedDateTime || searchStatus || id || startDate || endDate || StartDate || EndDate
      || UserName || projectName || taskName || CurrentTaskStatus || ScopeName || WorkHours || workDate || createdDateTime || Status) {
      url += `&$filter=`;
      const filterArray = [];

      if (searchuserName || UserName) {
        if (searchuserName?.toLowerCase() === 'n/a' || UserName?.toLowerCase() === 'n/a') {
          filterArray.push(`userName eq null`);
        }
        else if (UserName) {
          const nameFilter = `(contains(tolower(userName), tolower('${encodeURIComponent(UserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(UserName)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(userName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchprojectName || projectName) {
        if (searchprojectName?.toLowerCase() === 'n/a' || projectName?.toLowerCase() === 'n/a') {
          filterArray.push('projectName eq null');
        }
        else if (projectName) {
          filterArray.push(`contains(tolower(projectName),tolower('${encodeURIComponent(projectName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(projectName),tolower('${encodeURIComponent(searchprojectName)}'))`);
        }
      }
      if (searchtaskName || taskName) {
        if (searchtaskName?.toLowerCase() === 'n/a' || taskName?.toLowerCase() === 'n/a') {
          filterArray.push('taskName eq null');
        }
        else if (taskName) {
          filterArray.push(`contains(tolower(taskName),tolower('${encodeURIComponent(taskName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(taskName),tolower('${encodeURIComponent(searchtaskName)}'))`);
        }
      }
      if (searchCurrentTaskStatus || CurrentTaskStatus) {
        if (searchCurrentTaskStatus?.toLowerCase() === 'n/a' || CurrentTaskStatus?.toLowerCase() === 'n/a') {
          filterArray.push('CurrentTaskStatus eq null');
        }
        else if (CurrentTaskStatus) {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(CurrentTaskStatus)}'`);
        }
        else {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(searchCurrentTaskStatus)}'`);
        }
      }
      if (searchType || ScopeName) {
        if (searchType?.toLowerCase() === 'n/a' || ScopeName?.toLowerCase() === 'n/a') {
          filterArray.push('ScopeName eq null');
        }
        else if (ScopeName) {
          filterArray.push(`contains(tolower(ScopeName),tolower('${encodeURIComponent(ScopeName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(ScopeName),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchworkHours || WorkHours) {
        if (searchworkHours?.toLowerCase() === 'n/a' || WorkHours?.toLowerCase() === 'n/a') {
          filterArray.push('WorkHours eq null');
        }
        else if (WorkHours) {
          filterArray.push(`contains(tolower(WorkHours),tolower('${encodeURIComponent(WorkHours)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(WorkHours),tolower('${encodeURIComponent(searchworkHours)}'))`);
        }
      }
      if (searchworkDate || workDate) {
        if (searchworkDate?.toLowerCase() === 'n/a' || workDate?.toLowerCase() === 'n/a') {
          filterArray.push('workDate eq null');
        }
        else if (workDate) {
          filterArray.push(`workDate eq ${encodeURIComponent(workDate)}`);
        }
        else {
          filterArray.push(`workDate eq ${encodeURIComponent(searchworkDate)}`);
        }
      }
      if (searchcreatedDateTime || createdDateTime) {
        if (searchcreatedDateTime?.toLowerCase() === 'n/a' || createdDateTime?.toLowerCase() === 'n/a') {
          filterArray.push('createdDateTime eq null');
        }
        else if (createdDateTime) {
          filterArray.push(`createdDateTime eq ${encodeURIComponent(createdDateTime)}`);
        }
        else {
          filterArray.push(`createdDateTime eq ${encodeURIComponent(searchcreatedDateTime)}`);
        }
      }

      if (searchStatus || id || Status) {
        let status = "";
        if (searchStatus) {
          status = searchStatus.toLowerCase() === "all" ? "" : searchStatus;
        }
        else if (Status) {
          status = Status.toLowerCase() === "all" ? "" : Status;
        }
        else if (id === "all") {
          status = "";
        }
        else {
          status = id;
        }
        if (status.toLowerCase() === 'na') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`contains(tolower(Status), tolower('${encodeURIComponent(status)}'))`);
        }
      }
      if (startDate && endDate) {
        filterArray.push(`workDate ge ${startDate} and workDate le ${endDate}`);
      }
      if (StartDate && EndDate) {
        filterArray.push(`workDate ge ${StartDate} and workDate le ${EndDate}`);
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getTimeSheetsDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/TimeSheet/Details?timeSheetId=${id}`, accessToken);
  },
  getTimeSheetLoggedHours: (accessToken, date) => {
    return requests.getWithToken(`${apiUrl}/api/Timesheet/LogHours?date=${date}`, accessToken);
  },
  getDeleteTimeSheetList: (accessToken, id) => {
    const { timesheetId } = id;
    return requests.deleteWithToken(`${apiUrl}/api/TimeSheet/Delete?timesheetId=${timesheetId}`, accessToken);
  },
  createTimeSheet: (accessToken, appId, data) => {
    return requests.postWithToken(`${apiUrl}/api/TimeSheet/Create?AppId=${appId}`, data, accessToken);
  },
  editTimeSheet: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/TimeSheet/Update`, data, accessToken);
  }

}

const MyTeamTimeSheet = {
  getMyTeamTimeSheetExportList: (accessToken, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id) => {
    let url = `${apiUrl}/TeamTimesheet/Export?count=true&$orderby=projectName asc`;
    if (searchuserName || searchprojectName || searchtaskName || searchCurrentTaskStatus || searchType || searchworkHours || searchworkDate || searchcreatedDateTime || searchUpdatedDateTime || searchStatus || searchReason || id) {
      url += `&$filter=`;
      const filterArray = [];

      if (searchuserName) {
        if (searchuserName.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq ''`);
        } else {
          const nameFilter = `(contains(tolower(UserName), tolower('${searchuserName}')) or contains(tolower(UserEmail), tolower('${searchuserName}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchprojectName) {
        if (searchprojectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${searchprojectName}'))`);
        }
      }
      if (searchtaskName) {
        if (searchtaskName.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(TaskName),tolower('${searchtaskName}'))`);
        }
      }
      if (searchCurrentTaskStatus) {
        if (searchCurrentTaskStatus.toLowerCase() === 'n/a') {
          filterArray.push('CurrentTaskStatus eq null');
        } else {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(searchCurrentTaskStatus)}'`);
        }
      }
      if (searchType) {
        if (searchType.toLowerCase() === 'n/a') {
          filterArray.push('scopeName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(scopeName),tolower('${searchType}'))`);
        }
      }
      if (searchworkHours) {
        if (searchworkHours.toLowerCase() === 'n/a') {
          filterArray.push('WorkHours eq null');
        } else {
          filterArray.push(`contains(tolower(WorkHours),tolower('${searchworkHours}'))`);
        }
      }
      if (searchworkDate) {
        if (searchworkDate.toLowerCase() === 'n/a') {
          filterArray.push('workDate eq \'\'');
        } else {
          filterArray.push(`workDate eq ${searchworkDate}`);
        }
      }
      if (searchcreatedDateTime) {
        if (searchcreatedDateTime.toLowerCase() === 'na') {
          filterArray.push('CreatedDateTime eq null');
        } else {
          filterArray.push(`CreatedDateTime eq ${searchcreatedDateTime}`);
        }
      }
      if (searchUpdatedDateTime) {
        if (searchUpdatedDateTime.toLowerCase() === 'na') {
          filterArray.push('UpdatedTimeStamp eq null');
        } else {
          filterArray.push(`UpdatedTimeStamp eq ${searchUpdatedDateTime}`);
        }
      }

      // if (searchStatus || id) {
      //   const status = searchStatus ? (searchStatus === "All" ? "" : searchStatus) : (id);
      //   if (status.toLowerCase() === 'na') {
      //     filterArray.push('Status eq null');
      //   } else {
      //     filterArray.push(`contains(tolower(Status),tolower('${status}'))`);
      //   }
      // }

      if (searchStatus || id) {
        let status = "";
        if (searchStatus) {
          status = searchStatus.toLowerCase() === "all" ? "" : searchStatus;
        } else if (id === "All") {
          status = "";
        } else {
          status = id;
        }
        if (status.toLowerCase() === 'n/a') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`contains(tolower(Status), tolower('${status}'))`);
        }
      }
      if (searchReason) {
        if (searchReason.toLowerCase() === 'n/a') {
          filterArray.push('Reason eq null');
        } else {
          filterArray.push(`contains(tolower(Reason),tolower('${searchReason}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getMyTeamTimeSheetList: (accessToken, currentPage, PageSize = 15, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/TeamTimesheetList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`;
    if (searchuserName || searchprojectName || searchtaskName || searchCurrentTaskStatus || searchType || searchworkHours || searchworkDate || searchcreatedDateTime || searchUpdatedDateTime || searchStatus || searchReason || id) {
      url += `&$filter=`;
      const filterArray = [];
      // if (searchuserName) {
      //   if (searchuserName.toLowerCase() === 'na' || searchuserName.toLowerCase() === 'n/a') {
      //     filterArray.push('UserName eq \'\'');
      //   } else {
      //     filterArray.push(`contains(tolower(UserName),tolower('${searchuserName}'))  or contains(tolower(UserEmail),tolower('${searchuserName}'))`);
      //   }
      // }
      if (searchuserName) {
        if (searchuserName.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq ''`);
        } else {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchprojectName) {
        if (searchprojectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchprojectName)}'))`);
        }
      }
      if (searchtaskName) {
        if (searchtaskName.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(searchtaskName)}'))`);
        }
      }
      if (searchCurrentTaskStatus) {
        if (searchCurrentTaskStatus.toLowerCase() === 'n/a') {
          filterArray.push('CurrentTaskStatus eq null');
        } else {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(searchCurrentTaskStatus)}'`);
        }
      }
      if (searchType) {
        if (searchType.toLowerCase() === 'n/a') {
          filterArray.push('ScopeName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(ScopeName),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchworkHours) {
        if (searchworkHours.toLowerCase() === 'n/a') {
          filterArray.push('WorkHours eq null');
        } else {
          filterArray.push(`contains(tolower(WorkHours),tolower('${encodeURIComponent(searchworkHours)}'))`);
        }
      }
      if (searchworkDate) {
        if (searchworkDate.toLowerCase() === 'n/a') {
          filterArray.push('workDate eq \'\'');
        } else {
          filterArray.push(`workDate eq ${encodeURIComponent(searchworkDate)}`);
        }
      }
      if (searchcreatedDateTime) {
        if (searchcreatedDateTime.toLowerCase() === 'na') {
          filterArray.push('CreatedDateTime eq null');
        } else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchcreatedDateTime)}`);
        }
      }
      if (searchUpdatedDateTime) {
        if (searchUpdatedDateTime.toLowerCase() === 'na') {
          filterArray.push('UpdatedTimeStamp eq null');
        } else {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(searchUpdatedDateTime)}`);
        }
      }

      // if (searchStatus || id) {
      //   const status = searchStatus ? (searchStatus === "All" ? "" : searchStatus) : (id);
      //   if (status.toLowerCase() === 'na') {
      //     filterArray.push('Status eq null');
      //   } else {
      //     filterArray.push(`contains(tolower(Status),tolower('${status}'))`);
      //   }
      // }

      if (searchStatus || id) {
        let status = "";
        if (searchStatus) {
          status = searchStatus.toLowerCase() === "all" ? "" : searchStatus;
        } else if (id === "All") {
          status = "";
        } else {
          status = id;
        }
        if (status.toLowerCase() === 'n/a') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`contains(tolower(Status), tolower('${encodeURIComponent(status)}'))`);
        }
      }



      if (searchReason) {
        if (searchReason.toLowerCase() === 'n/a') {
          filterArray.push('Reason eq null');
        } else {
          filterArray.push(`contains(tolower(Reason),tolower('${encodeURIComponent(searchReason)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  createTimeSheetAction: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/TimeSheet/Updatestatus`, data, accessToken);
  },
}
const AllTimeSheet = {
  getAllTimeSheetExportList: (accessToken, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchworkDate, searchworkHours, searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedDateTime, id) => {
    let url = `${apiUrl}/OverAllTimesheetList/Export?count=true&$orderby=projectName asc`;
    if (searchuserName || searchprojectName || searchtaskName || searchCurrentTaskStatus || searchType || searchworkHours || searchworkDate || searchcreatedDateTime || searchUpdatedDateTime || searchStatus || searchUpdatedBy || id) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchuserName) {
        if (searchuserName.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq null`);
        } else {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchprojectName) {
        if (searchprojectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchprojectName)}'))`);
        }
      }
      if (searchtaskName) {
        if (searchtaskName.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq null');
        } else {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(searchtaskName)}'))`);
        }
      }
      if (searchCurrentTaskStatus) {
        if (searchCurrentTaskStatus.toLowerCase() === 'n/a') {
          filterArray.push('CurrentTaskStatus eq null');
        } else {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(searchCurrentTaskStatus)}'`);
        }
      }
      if (searchType) {
        if (searchType.toLowerCase() === 'n/a') {
          filterArray.push('scopeName eq null');
        } else {
          filterArray.push(`contains(tolower(scopeName),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchworkHours) {
        if (searchworkHours.toLowerCase() === 'n/a') {
          filterArray.push('WorkHours eq null');
        } else {
          filterArray.push(`contains(tolower(WorkHours),tolower('${encodeURIComponent(searchworkHours)}'))`);
        }
      }
      if (searchworkDate) {
        if (searchworkDate.toLowerCase() === 'n/a') {
          filterArray.push('workDate eq null');
        } else {
          filterArray.push(`workDate eq ${encodeURIComponent(searchworkDate)}`);
        }
      }
      if (searchcreatedDateTime) {
        if (searchcreatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('CreatedDateTime eq null');
        } else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchcreatedDateTime)}`);
        }
      }
      if (searchUpdatedDateTime) {
        if (searchUpdatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedTimeStamp eq null');
        } else {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(searchUpdatedDateTime)}`);
        }
      }

      if (searchStatus || id) {
        let status = "";
        if (searchStatus) {
          status = searchStatus.toLowerCase() === "all" ? "" : searchStatus;
        } else if (id === "All") {
          status = "";
        } else {
          status = id;
        }
        if (status.toLowerCase() === 'na') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`contains(tolower(Status), tolower('${encodeURIComponent(status)}'))`);
        }
      }

      if (searchUpdatedBy) {
        if (searchUpdatedBy.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(UpdatedBy),tolower('${encodeURIComponent(searchUpdatedBy)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getAllTimeSheetList: (accessToken, currentPage, PageSize = 15, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchworkDate, searchworkHours, searchType, searchcreatedDateTime, searchStatus, searchUpdatedBy, searchUpdatedDateTime, id) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/OverAllTimesheetList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`;
    if (searchuserName || searchprojectName || searchtaskName || searchCurrentTaskStatus || searchType || searchworkHours || searchworkDate || searchcreatedDateTime || searchUpdatedDateTime || searchStatus || searchUpdatedBy || id) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchuserName) {
        if (searchuserName.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq null`);
        } else {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchprojectName) {
        if (searchprojectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchprojectName)}'))`);
        }
      }
      if (searchtaskName) {
        if (searchtaskName.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq null');
        } else {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(searchtaskName)}'))`);
        }
      }
      if (searchCurrentTaskStatus) {
        if (searchCurrentTaskStatus.toLowerCase() === 'n/a') {
          filterArray.push('CurrentTaskStatus eq null');
        } else {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(searchCurrentTaskStatus)}'`);
        }
      }
      if (searchType) {
        if (searchType.toLowerCase() === 'n/a') {
          filterArray.push('ScopeName eq null');
        } else {
          filterArray.push(`contains(tolower(ScopeName),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchworkHours) {
        if (searchworkHours.toLowerCase() === 'n/a') {
          filterArray.push('WorkHours eq null');
        } else {
          filterArray.push(`contains(tolower(WorkHours),tolower('${encodeURIComponent(searchworkHours)}'))`);
        }
      }
      if (searchworkDate) {
        if (searchworkDate.toLowerCase() === 'n/a') {
          filterArray.push('workDate eq null');
        } else {
          filterArray.push(`workDate eq ${encodeURIComponent(searchworkDate)}`);
        }
      }
      if (searchcreatedDateTime) {
        if (searchcreatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('CreatedDateTime eq null');
        } else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchcreatedDateTime)}`);
        }
      }
      if (searchUpdatedDateTime) {
        if (searchUpdatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedTimeStamp eq null');
        } else {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(searchUpdatedDateTime)}`);
        }
      }

      if (searchStatus || id) {
        let status = "";
        if (searchStatus) {
          status = searchStatus.toLowerCase() === "all" ? "" : searchStatus;
        } else if (id === "All") {
          status = "";
        } else {
          status = id;
        }
        if (status.toLowerCase() === 'na') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`contains(tolower(Status), tolower('${encodeURIComponent(status)}'))`);
        }
      }

      if (searchUpdatedBy) {
        if (searchUpdatedBy.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(UpdatedBy),tolower('${encodeURIComponent(searchUpdatedBy)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
}
const TimesheetDashboard = {
  getDashboardCounts: (accessToken, fromDate, toDate) => {
    if (fromDate && toDate) {
      return requests.getWithToken(`${apiUrl}/api/Timesheet/DashBoard?startDate=${fromDate}&endDate=${toDate}`, accessToken)
    }
    else {
      return requests.getWithToken(`${apiUrl}/api/Timesheet/DashBoard`, accessToken)
    }
  },
}
const TimeSheetApproval = {
  getTimeSheetApprovalExportList: (accessToken, searchuserName, searchprojectName, searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate, searchcreatedDateTime, searchUpdatedDateTime, searchStatus, searchReason, id) => {

    let url = `${apiUrl}/TimesheetApproval/Export?count=true&$orderby=projectName asc`;
    if (searchuserName || searchprojectName || searchtaskName || searchCurrentTaskStatus || searchType || searchworkHours || searchworkDate || searchcreatedDateTime || searchUpdatedDateTime || searchStatus || searchReason || id) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchuserName) {
        if (searchuserName.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq null`);
        } else {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchprojectName) {
        if (searchprojectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchprojectName)}'))`);
        }
      }
      if (searchtaskName) {
        if (searchtaskName.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq null');
        } else {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(searchtaskName)}'))`);
        }
      }
      if (searchCurrentTaskStatus) {
        if (searchCurrentTaskStatus.toLowerCase() === 'n/a') {
          filterArray.push('CurrentTaskStatus eq null');
        } else {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(searchCurrentTaskStatus)}'`);
        }
      }
      if (searchType) {
        if (searchType.toLowerCase() === 'n/a') {
          filterArray.push('scopeName eq null');
        } else {
          filterArray.push(`contains(tolower(scopeName),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchworkHours) {
        if (searchworkHours.toLowerCase() === 'n/a') {
          filterArray.push('WorkHours eq null');
        } else {
          filterArray.push(`contains(tolower(WorkHours),tolower('${encodeURIComponent(searchworkHours)}'))`);
        }
      }
      if (searchworkDate) {
        if (searchworkDate.toLowerCase() === 'n/a') {
          filterArray.push('workDate eq null');
        } else {
          filterArray.push(`workDate eq ${encodeURIComponent(searchworkDate)}`);
        }
      }
      if (searchcreatedDateTime) {
        if (searchcreatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('CreatedDateTime eq null');
        } else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchcreatedDateTime)}`);
        }
      }
      if (searchUpdatedDateTime) {
        if (searchUpdatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedTimeStamp eq null');
        } else {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(searchUpdatedDateTime)}`);
        }
      }

      if (searchStatus || id) {
        let status = "";
        if (searchStatus) {
          status = searchStatus.toLowerCase() === "all" ? "" : searchStatus;
        } else if (id === "All") {
          status = "";
        } else {
          status = id;
        }
        if (status.toLowerCase() === 'n/a') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`contains(tolower(Status), tolower('${encodeURIComponent(status)}'))`);
        }
      }


      if (searchReason) {
        if (searchReason.toLowerCase() === 'n/a') {
          filterArray.push('Reason eq null');
        } else {
          filterArray.push(`contains(tolower(Reason),tolower('${encodeURIComponent(searchReason)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getTimeSheetApprovalList: (accessToken, filter, searchStatus, currentPage, PageSize = 15, searchuserName, searchprojectName,
    searchtaskName, searchCurrentTaskStatus, searchType, searchworkHours, searchworkDate,
    searchcreatedDateTime, searchUpdatedDateTime, searchReason, id, startDate, endDate) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filter:", filter);
    const { WorkHours, Status, ProjectName, UserName, TaskName, CurrentTaskStatus, ScopeName, WorkDate, CreatedDateTime, UpdatedTimeStamp, Reason } = filter || {};
    console.log("searchStatus:", searchStatus);
    console.log("searchCurrentTaskStatus:", searchCurrentTaskStatus);
    // const { Status } = searchStatus || {};
    let url = `${apiUrl}/odata/TimesheetApproval?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`;
    if (WorkHours || UserName || Reason || UpdatedTimeStamp || CreatedDateTime || WorkDate || searchCurrentTaskStatus || Status || searchuserName || searchprojectName || searchtaskName || searchType || searchworkHours || searchworkDate || searchcreatedDateTime || searchUpdatedDateTime || searchReason || id || startDate || endDate) {
      url += `&$filter=`;
      const filterArray = [];
      if (Status || id) {
        let status = "";
        if (Status) {
          status = Status?.toLowerCase() === "all" ? "" : Status;
        } else if (id === "All") {
          status = "";
        } else {
          status = id;
        }
        if (status?.toLowerCase() === 'n/a') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`contains(tolower(Status), tolower('${encodeURIComponent(status)}'))`);
        }
      }
      if (searchuserName || UserName) {
        if (searchuserName?.toLowerCase() === 'n/a' || UserName?.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq null`);
        }
        else if (UserName) {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(UserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(UserName)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchprojectName || ProjectName) {
        if (searchprojectName?.toLowerCase() === 'n/a' || ProjectName?.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        }
        else if (ProjectName) {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(ProjectName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchprojectName)}'))`);
        }
      }
      if (searchtaskName || TaskName) {
        if (searchtaskName?.toLowerCase() === 'n/a' || TaskName?.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq null');
        }
        else if (TaskName) {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(TaskName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(searchtaskName)}'))`);
        }
      }
      if (searchCurrentTaskStatus || CurrentTaskStatus) {
        if (searchCurrentTaskStatus?.toLowerCase() === 'n/a' || CurrentTaskStatus?.toLowerCase() === 'n/a') {
          filterArray.push('CurrentTaskStatus eq null');
        }
        else if (CurrentTaskStatus) {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(CurrentTaskStatus)}'`);
        }
        else {
          filterArray.push(`CurrentTaskStatus eq '${encodeURIComponent(searchCurrentTaskStatus)}'`);
        }
      }
      if (searchType || ScopeName) {
        if (searchType?.toLowerCase() === 'n/a' || ScopeName?.toLowerCase() === 'n/a') {
          filterArray.push('ScopeName eq null');
        }
        else if (ScopeName) {
          filterArray.push(`contains(tolower(ScopeName),tolower('${encodeURIComponent(ScopeName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(ScopeName),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchworkHours || WorkHours) {
        if (searchworkHours?.toLowerCase() === 'n/a' || WorkHours?.toLowerCase() === 'n/a') {
          filterArray.push('WorkHours eq null');
        }
        else if (WorkHours) {
          filterArray.push(`contains(tolower(WorkHours),tolower('${encodeURIComponent(WorkHours)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(WorkHours),tolower('${encodeURIComponent(searchworkHours)}'))`);
        }
      }
      if (searchworkDate || WorkDate) {
        if (searchworkDate?.toLowerCase() === 'n/a' || WorkDate?.toLowerCase() === 'n/a') {
          filterArray.push('workDate eq null');
        }
        else if (WorkDate) {
          filterArray.push(`workDate eq ${encodeURIComponent(WorkDate)}`);
        }
        else {
          filterArray.push(`workDate eq ${encodeURIComponent(searchworkDate)}`);
        }
      }
      if (searchcreatedDateTime || CreatedDateTime) {
        if (searchcreatedDateTime?.toLowerCase() === 'n/a' || CreatedDateTime?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedDateTime eq null');
        }
        else if (CreatedDateTime) {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(CreatedDateTime)}`);
        }
        else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchcreatedDateTime)}`);
        }
      }
      if (searchUpdatedDateTime || UpdatedTimeStamp) {
        if (searchUpdatedDateTime?.toLowerCase() === 'n/a' || UpdatedTimeStamp?.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedTimeStamp eq null');
        }
        else if (UpdatedTimeStamp) {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(UpdatedTimeStamp)}`);
        }
        else {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(searchUpdatedDateTime)}`);
        }
      }
      // if ( id || Status) {
      //   console.log("Status:", Status);
      //   let status;
      //   if (Status) {
      //     status = Status ? (Status === 'All' ? "" : Status) : (id === "All" ? "" : id);
      //     filterArray.push(`contains(tolower(Status),tolower('${encodeURIComponent(status)}'))`);
      //   }

      //   // if (searchStatus && searchStatus.toLowerCase() === 'n/a') {
      //   //   filterArray.push('Status eq null');
      //   // } else {
      //   //   filterArray.push(`contains(tolower(Status),tolower('${status}'))`);
      //   // }
      // }

      if (startDate && endDate) {
        filterArray.push(`workDate ge ${encodeURIComponent(startDate)} and workDate le ${encodeURIComponent(endDate)}`);
      }

      if (searchReason || Reason) {
        if (searchReason?.toLowerCase() === 'n/a' || Reason?.toLowerCase() === 'n/a') {
          filterArray.push('Reason eq null');
        }
        else if (Reason) {
          filterArray.push(`contains(tolower(Reason),tolower('${encodeURIComponent(Reason)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Reason),tolower('${encodeURIComponent(searchReason)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getTimesheetApprovalPendingCount: (accessToken) => {
    return requests.getWithToken(`${apiUrl}/odata/TimesheetApproval?count=true&$filter=Status eq 'Submitted'`, accessToken);
  },
}
const ProjectDashboard = {
  getDashboardCounts: (accessToken, typeName) => {
    console.log("typeName:", typeName);
    if (typeName.projectId) {
      return requests.getWithToken(`${apiUrl}/api/Project/Dashboard?FromDate=${encodeURIComponent(typeName.StartDate)}&ToDate=${encodeURIComponent(typeName.EndDate)}&projectId=${encodeURIComponent(typeName.projectId)}`, accessToken)
    } else {
      return requests.getWithToken(`${apiUrl}/api/Project/Dashboard?FromDate=${encodeURIComponent(typeName.StartDate)}&ToDate=${encodeURIComponent(typeName.EndDate)}`, accessToken)
    }
  },
}

const LeaveTracker = {
  getLeaveExportList: (accessToken, searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id) => {
    console.log(id)

    let url = `${apiUrl}/MyLeaveTrackerList/Export?count =true&$orderby=userName asc`
    if (searchuserName || searchFromDateTime || searchToDateTime || searchDays || searchReason || searchLeaveType || searchCategory || searchLeaveStatus || searchStatusDescription /*|| searchUserEmail*/ || id) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchuserName) {
        if (searchuserName.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq null`);
        } else {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchFromDateTime) {
        if (searchFromDateTime.toLowerCase() === 'n/a') {
          filterArray.push('FromDateTime eq null');
        } else {
          filterArray.push(`FromDateTime eq ${encodeURIComponent(searchFromDateTime)}`);
        }
      }
      if (searchToDateTime) {
        if (searchToDateTime.toLowerCase() === 'n/a') {
          filterArray.push('ToDateTime eq null');
        } else {
          filterArray.push(`ToDateTime eq ${encodeURIComponent(searchToDateTime)}`);
        }
      }
      if (searchDays) {
        if (searchDays.toLowerCase() === 'n/a') {
          filterArray.push('TotalLeaveCount eq 0');
        } else {
          filterArray.push(`TotalLeaveCount eq ${encodeURIComponent(searchDays)}`);
        }
      }
      if (searchReason) {
        if (searchReason.toLowerCase() === 'n/a') {
          filterArray.push('LeaveReason eq null');
        } else {
          filterArray.push(`contains(tolower(LeaveReason),tolower('${encodeURIComponent(searchReason)}'))`);
        }
      }
      if (searchLeaveType) {
        if (searchLeaveType.toLowerCase() === 'n/a') {
          filterArray.push('LeaveType eq null');
        } else {
          filterArray.push(`contains(tolower(LeaveType),tolower('${encodeURIComponent(searchLeaveType)}'))`);
        }
      }
      if (searchCategory) {
        if (searchCategory.toLowerCase() === 'n/a') {
          filterArray.push('Category eq null');
        } else {
          filterArray.push(`contains(tolower(Category),tolower('${encodeURIComponent(searchCategory)}'))`);
        }
      }
      console.log("searchLeaveStatus:", searchLeaveStatus, "id:", id);

      if (searchLeaveStatus || id) {
        const status = searchLeaveStatus ? (searchLeaveStatus === 'All' ? "" : searchLeaveStatus) : (id === "All" ? "" : id);
        if (searchLeaveStatus.toLowerCase() === 'na') {
          filterArray.push('LeaveStatus eq null');
        } else {
          filterArray.push(`contains(tolower(LeaveStatus),tolower('${encodeURIComponent(status)}'))`);

        }
      }
      if (searchStatusDescription) {
        if (searchStatusDescription.toLowerCase() === 'n/a') {
          filterArray.push('StatusDescription eq null');
        } else {
          filterArray.push(`contains(tolower(StatusDescription),tolower('${encodeURIComponent(searchStatusDescription)}'))`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  checkEmployeeUserAccessaction: (accessToken, userId) => {
    return requests.getWithToken(`${apiUrl}/odata/EmployeeList?count=true&$filter=UserId eq '${userId}'`, accessToken);
  },
  getLeaveList: (accessToken, filteredData, currentPage, PageSize = 15, searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, /*searchUserEmail,*/ id) => {
    console.log(id)
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filteredData:", filteredData);
    const { UserName, FromDateTime, ToDateTime, TotalLeaveCount, LeaveReason, LeaveType, Category, LeaveStatus, StatusDescription } = filteredData || {};
    let url = `${apiUrl}/odata/MyLeaveTrackerList?count =true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`
    if (UserName || FromDateTime || ToDateTime || TotalLeaveCount || LeaveReason || LeaveType || Category || LeaveStatus || StatusDescription ||
      searchuserName || searchFromDateTime || searchToDateTime || searchDays || searchReason || searchLeaveType ||
      searchCategory || searchLeaveStatus || searchStatusDescription /*|| searchUserEmail*/ || id) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchuserName || UserName) {
        if (searchuserName?.toLowerCase() === 'n/a' || UserName?.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq null`);
        }
        else if (UserName) {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(UserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(UserName)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchFromDateTime || FromDateTime) {
        if (searchFromDateTime?.toLowerCase() === 'n/a' || FromDateTime?.toLowerCase() === 'n/a') {
          filterArray.push('FromDateTime eq null');
        }
        else if (FromDateTime) {
          filterArray.push(`FromDateTime eq ${encodeURIComponent(FromDateTime)}`);
        }
        else {
          filterArray.push(`FromDateTime eq ${encodeURIComponent(searchFromDateTime)}`);
        }
      }
      if (searchToDateTime || ToDateTime) {
        if (searchToDateTime?.toLowerCase() === 'n/a' || ToDateTime?.toLowerCase() === 'n/a') {
          filterArray.push('ToDateTime eq null');
        }
        else if (ToDateTime) {
          filterArray.push(`ToDateTime eq ${encodeURIComponent(ToDateTime)}`);
        }
        else {
          filterArray.push(`ToDateTime eq ${encodeURIComponent(searchToDateTime)}`);
        }
      }
      if (searchDays || TotalLeaveCount) {
        if (searchDays?.toLowerCase() === 'n/a' || TotalLeaveCount?.toLowerCase() === 'n/a') {
          filterArray.push('TotalLeaveCount eq 0');
        }
        else if (TotalLeaveCount) {
          filterArray.push(`TotalLeaveCount eq ${encodeURIComponent(TotalLeaveCount)}`);
        }
        else {
          filterArray.push(`TotalLeaveCount eq ${encodeURIComponent(searchDays)}`);
        }
      }
      if (searchReason || LeaveReason) {
        if (searchReason?.toLowerCase() === 'n/a' || LeaveReason?.toLowerCase() === 'n/a') {
          filterArray.push('LeaveReason eq null');
        }
        else if (LeaveReason) {
          filterArray.push(`contains(tolower(LeaveReason),tolower('${encodeURIComponent(LeaveReason)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeaveReason),tolower('${encodeURIComponent(searchReason)}'))`);
        }
      }
      if (searchLeaveType || LeaveType) {
        if (searchLeaveType?.toLowerCase() === 'n/a' || LeaveType?.toLowerCase() === 'n/a') {
          filterArray.push('LeaveType eq null');
        }
        else if (LeaveType) {
          filterArray.push(`contains(tolower(LeaveType),tolower('${encodeURIComponent(LeaveType)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeaveType),tolower('${encodeURIComponent(searchLeaveType)}'))`);
        }
      }
      if (searchCategory || Category) {
        if (searchCategory?.toLowerCase() === 'n/a' || Category?.toLowerCase() === 'n/a') {
          filterArray.push('Category eq null');
        }
        else if (Category) {
          filterArray.push(`contains(tolower(Category),tolower('${encodeURIComponent(Category)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Category),tolower('${encodeURIComponent(searchCategory)}'))`);
        }
      }
      console.log("searchLeaveStatus:", searchLeaveStatus, "id:", id);

      if (searchLeaveStatus || id || LeaveStatus) {
        const status = searchLeaveStatus ? (searchLeaveStatus === 'All' ? "" : searchLeaveStatus) : LeaveStatus ? (LeaveStatus === 'All' ? "" : LeaveStatus) : (id === "All" ? "" : id);
        if (searchLeaveStatus?.toLowerCase() === 'na' || LeaveStatus?.toLowerCase() === 'na') {
          filterArray.push('LeaveStatus eq null');
        }
        // else if (LeaveStatus) {
        //   filterArray.push(`contains(tolower(LeaveStatus),tolower('${encodeURIComponent(LeaveStatus)}'))`);
        // }
        else {
          filterArray.push(`contains(tolower(LeaveStatus),tolower('${encodeURIComponent(status)}'))`);
        }
      }
      if (searchStatusDescription || StatusDescription) {
        if (searchStatusDescription?.toLowerCase() === 'n/a' || StatusDescription?.toLowerCase() === 'n/a') {
          filterArray.push('StatusDescription eq null');
        }
        else if (StatusDescription) {
          filterArray.push(`contains(tolower(StatusDescription),tolower('${encodeURIComponent(StatusDescription)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(StatusDescription),tolower('${encodeURIComponent(searchStatusDescription)}'))`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getLeaveDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/LeaveTracker/Details?LeaveId=${id}`, accessToken);
  },
  getLeaveCount: (accessToken, formfields) => {
    console.log("formfileds:", formfields);
    return requests.getWithToken(`${apiUrl}/api/LeaveTracker/LeaveCount?FromDate=${encodeURIComponent(formfields.fromDateTime)}&ToDate=${encodeURIComponent(formfields.toDateTime)}&leaveTypeId=${encodeURIComponent(formfields.leaveTypeId)}`, accessToken);
  },
  getAvailableLeaveCount: (accessToken, formfields) => {
    console.log("formfileds:", formfields);
    return requests.getWithToken(`${apiUrl}/api/LeaveTracker/AvailableLeaveCount?leaveTypeId=${encodeURIComponent(formfields.leaveTypeId)}`, accessToken);
  },
  getLeaveTypelist: (accessToken, searchValue) => {
    const url = `${apiUrl}/odata/OrgLeaveTypeList?count =true&$select=Id,LeavetypeName,LeavetypeId,IsEnable&$filter=contains(tolower(LeavetypeName),tolower('${encodeURIComponent(searchValue)}'))`;
    return requests.getWithToken(url, accessToken);
  },
  getUserLeaveTypelist: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/UserLeaveTypeList?count =true&$select=Id,LeavetypeName,IsEnable`;
    if (searchValue) {
      url = `${apiUrl}/odata/UserLeaveTypeList?count =true&$filter=contains(tolower(LeavetypeName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getAllUserLeaveTypelist: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/OrgLeaveTypeList?count =true&$select=Id,LeavetypeName,IsEnable`;
    if (searchValue) {
      url = `${apiUrl}/odata/OrgLeaveTypeList?count =true&$filter=contains(tolower(LeavetypeName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getEnableOrgUserLeaveTypelist: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/OrgLeaveTypeList?count =true&$select=Id,LeavetypeName,LeavetypeId,IsEnable&filter=IsEnable eq true`;
    if (searchValue) {
      url = `${apiUrl}/odata/OrgLeaveTypeList?count =true&$select=Id,LeavetypeName,LeavetypeId,IsEnable&filter=IsEnable eq true and contains(tolower(LeavetypeName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getOnlyEmployeeUserNameList: (accessToken, appId, searchValue) => {
    let url = `${apiUrl}/odata/OrgUserList?AppId=${appId}&UserType=Employee&count=true&$select=Id,IsActive,IsGlobalReadOnlyUser,DisplayName,UserEmail,FirstName,LastName`;
    if (searchValue) {
      // url = `${apiUrl}/odata/OrgUserList?AppId=${appId}&count=true&$filter=contains(tolower(DisplayName),tolower('${encodeURIComponent(searchValue)}'))&$select=Id,IsActive,IsGlobalReadOnlyUser,DisplayName,UserEmail,FirstName,LastName`;
      url = `${apiUrl}/odata/OrgUserList?AppId=${appId}&UserType=Employee&count=true&$filter=contains(tolower(DisplayName),tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(UserEmail),tolower('${encodeURIComponent(searchValue)}'))&$select=Id,IsActive,IsGlobalReadOnlyUser,DisplayName,UserEmail,FirstName,LastName`;

    }
    return requests.getWithToken(url, accessToken);
  },
  createLeave: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/LeaveTracker/Create`, data, accessToken);
  },
  editLeave: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/LeaveTracker/Update`, data, accessToken);
  },
  getLeaveTrackerList: (accessToken, currentPage, PageSize = 15, searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id) => {
    console.log("searchLeaveStatus:", searchLeaveStatus);

    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/LeaveTrackerList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`
    if (searchuserName || searchFromDateTime || searchToDateTime || searchDays || searchReason || searchLeaveType || searchCategory || searchLeaveStatus || searchStatusDescription || id) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchuserName) {
        if (searchuserName.toLowerCase() === 'n/a') {
          filterArray.push('UserName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(UserName),tolower('${encodeURIComponent(searchuserName)}'))`);
        }
      }
      if (searchFromDateTime) {
        if (searchFromDateTime.toLowerCase() === 'n/a') {
          filterArray.push('FromDateTime eq \'\'');
        } else {
          filterArray.push(`FromDateTime eq ${encodeURIComponent(searchFromDateTime)}`);
        }
      }
      if (searchToDateTime) {
        if (searchToDateTime.toLowerCase() === 'n/a') {
          filterArray.push('ToDateTime eq \'\'');
        } else {
          filterArray.push(`ToDateTime eq ${encodeURIComponent(searchToDateTime)}`);
        }
      }
      if (searchDays) {
        if (searchDays.toLowerCase() === 'n/a') {
          filterArray.push('Days eq 0');
        } else {
          filterArray.push(`Days eq ${encodeURIComponent(searchDays)}`);
        }
      }
      if (searchReason) {
        if (searchReason.toLowerCase() === 'n/a') {
          filterArray.push('LeaveReason eq \'\'');
        } else {
          filterArray.push(`contains(tolower(LeaveReason),tolower('${encodeURIComponent(searchReason)}'))`);
        }
      }
      if (searchLeaveType) {
        if (searchLeaveType.toLowerCase() === 'n/a') {
          filterArray.push('LeaveType eq \'\'');
        } else {
          filterArray.push(`contains(tolower(LeaveType),tolower('${encodeURIComponent(searchLeaveType)}'))`);
        }
      }
      if (searchCategory) {
        if (searchCategory.toLowerCase() === 'n/a') {
          filterArray.push('Category eq null');
        } else {
          filterArray.push(`contains(tolower(Category),tolower('${encodeURIComponent(searchCategory)}'))`);
        }
      }
      if (searchLeaveStatus || id) {
        const status = searchLeaveStatus ? (searchLeaveStatus === 'All' ? "" : searchLeaveStatus) : (id === "All" ? "" : id);
        if (searchLeaveStatus.toLowerCase() === 'n/a') {
          filterArray.push('LeaveStatus eq null');
        } else {
          filterArray.push(`contains(tolower(LeaveStatus),tolower('${encodeURIComponent(status)}'))`);
        }
      }
      if (searchStatusDescription) {
        if (searchStatusDescription.toLowerCase() === 'n/a') {
          filterArray.push('StatusDescription eq null');
        } else {
          filterArray.push(`contains(tolower(StatusDescription),tolower('${encodeURIComponent(searchStatusDescription)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },
  getMyTeamLeaveExportList: (accessToken, searchuserName, searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id) => {


    let url = `${apiUrl}/MyTeamLeaveList/Export?count=true&$orderby=LeaveStatus desc`
    if (searchuserName || searchFromDateTime || searchToDateTime || searchDays || searchReason || searchLeaveType || searchCategory || searchLeaveStatus || searchStatusDescription || id) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchuserName) {
        if (searchuserName.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq ''`);
        } else {
          const nameFilter = `(contains(tolower(UserName), tolower('${encodeURIComponent(searchuserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchuserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchFromDateTime) {
        if (searchFromDateTime.toLowerCase() === 'n/a') {
          filterArray.push('FromDateTime eq \'\'');
        } else {
          filterArray.push(`FromDateTime eq ${encodeURIComponent(searchFromDateTime)}`);
        }
      }
      if (searchToDateTime) {
        if (searchToDateTime.toLowerCase() === 'n/a') {
          filterArray.push('ToDateTime eq \'\'');
        } else {
          filterArray.push(`ToDateTime eq ${encodeURIComponent(searchToDateTime)}`);
        }
      }
      if (searchDays) {
        if (searchDays.toLowerCase() === 'n/a') {
          filterArray.push('TotalLeaveCount eq 0');
        } else {
          filterArray.push(`TotalLeaveCount eq ${encodeURIComponent(searchDays)}`);
        }
      }
      if (searchReason) {
        if (searchReason.toLowerCase() === 'n/a') {
          filterArray.push('LeaveReason eq null');
        } else {
          filterArray.push(`contains(tolower(LeaveReason),tolower('${encodeURIComponent(searchReason)}'))`);
        }
      }
      if (searchLeaveType) {
        if (searchLeaveType.toLowerCase() === 'n/a') {
          filterArray.push('LeaveType eq \'\'');
        } else {
          filterArray.push(`contains(tolower(LeaveType),tolower('${encodeURIComponent(searchLeaveType)}'))`);
        }
      }
      if (searchCategory) {
        if (searchCategory.toLowerCase() === 'n/a') {
          filterArray.push('Category eq null');
        } else {
          filterArray.push(`contains(tolower(Category),tolower('${encodeURIComponent(searchCategory)}'))`);
        }
      }
      if (searchLeaveStatus || id) {
        const status = searchLeaveStatus ? (searchLeaveStatus === 'All' ? "" : searchLeaveStatus) : (id === "All" ? "" : id);
        if (searchLeaveStatus.toLowerCase() === 'n/a') {
          filterArray.push('LeaveStatus eq null');
        } else {
          filterArray.push(`contains(tolower(LeaveStatus),tolower('${encodeURIComponent(status)}'))`);
        }
      }
      if (searchStatusDescription) {
        if (searchStatusDescription.toLowerCase() === 'n/a') {
          filterArray.push('StatusDescription eq null');
        } else {
          filterArray.push(`contains(tolower(StatusDescription),tolower('${encodeURIComponent(searchStatusDescription)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },
  getMyTeamLeaveDetailsList: (accessToken, filter, currentPage, PageSize = 15, searchuserName, /*searchUserEmail,*/ searchFromDateTime, searchToDateTime, searchDays, searchReason, searchLeaveType, searchCategory, searchLeaveStatus, searchStatusDescription, id) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filter:", filter);
    const { StatusDescription, Category, LeaveReason, LeaveStatus, UserName, FromDateTime, ToDateTime, TotalLeaveCount, LeaveType } = filter || {};

    let url = `${apiUrl}/odata/MyTeamLeaveList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=LeaveStatus desc`
    if (StatusDescription || Category || LeaveType || LeaveReason || TotalLeaveCount || ToDateTime || FromDateTime || UserName || searchuserName /*|| searchUserEmail*/ || searchFromDateTime || searchToDateTime || searchDays || searchReason || searchLeaveType || searchCategory || searchLeaveStatus || searchStatusDescription || id || LeaveStatus) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchuserName || UserName) {
        if (searchuserName?.toLowerCase() === 'n/a' || UserName?.toLowerCase() === 'n/a') {
          filterArray.push(`UserName eq ''`);
        }
        else if (UserName) {
          const nameFilter = `(contains(tolower(UserName), tolower('${UserName}')) or contains(tolower(UserEmail), tolower('${UserName}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(UserName), tolower('${searchuserName}')) or contains(tolower(UserEmail), tolower('${searchuserName}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchFromDateTime || FromDateTime) {
        if (searchFromDateTime?.toLowerCase() === 'n/a' || FromDateTime?.toLowerCase() === 'n/a') {
          filterArray.push('FromDateTime eq \'\'');
        }
        else if (FromDateTime) {
          filterArray.push(`FromDateTime eq ${FromDateTime}`);
        }
        else {
          filterArray.push(`FromDateTime eq ${searchFromDateTime}`);
        }
      }
      if (searchToDateTime || ToDateTime) {
        if (searchToDateTime?.toLowerCase() === 'n/a' || ToDateTime?.toLowerCase() === 'n/a') {
          filterArray.push('ToDateTime eq \'\'');
        }
        else if (ToDateTime) {
          filterArray.push(`ToDateTime eq ${ToDateTime}`);
        }
        else {
          filterArray.push(`ToDateTime eq ${searchToDateTime}`);
        }
      }
      if (searchDays || TotalLeaveCount) {
        if (searchDays?.toLowerCase() === 'n/a' || TotalLeaveCount?.toLowerCase() === 'n/a') {
          filterArray.push('TotalLeaveCount eq 0');
        }
        else if (TotalLeaveCount) {
          filterArray.push(`TotalLeaveCount eq ${TotalLeaveCount}`);
        }
        else {
          filterArray.push(`TotalLeaveCount eq ${searchDays}`);
        }
      }
      if (searchReason || LeaveReason) {
        if (searchReason?.toLowerCase() === 'n/a' || LeaveReason?.toLowerCase() === 'n/a') {
          filterArray.push('LeaveReason eq null');
        }
        else if (LeaveReason) {
          filterArray.push(`contains(tolower(LeaveReason),tolower('${LeaveReason}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeaveReason),tolower('${searchReason}'))`);
        }
      }
      if (searchLeaveType || LeaveType) {
        if (searchLeaveType?.toLowerCase() === 'n/a' || LeaveType?.toLowerCase() === 'n/a') {
          filterArray.push('LeaveType eq \'\'');
        }
        else if (LeaveType) {
          filterArray.push(`contains(tolower(LeaveType),tolower('${LeaveType}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeaveType),tolower('${searchLeaveType}'))`);
        }
      }
      if (searchCategory || Category) {
        if (searchCategory?.toLowerCase() === 'n/a' || Category?.toLowerCase() === 'n/a') {
          filterArray.push('Category eq null');
        }
        else if (Category) {
          filterArray.push(`contains(tolower(Category),tolower('${Category}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Category),tolower('${searchCategory}'))`);
        }
      }
      if (searchLeaveStatus || id || LeaveStatus) {
        let status;
        if (LeaveStatus) {
          status = LeaveStatus ? (LeaveStatus === 'All' ? "" : LeaveStatus) : (id === "All" ? "" : id);
        } else {
          status = searchLeaveStatus ? (searchLeaveStatus === 'All' ? "" : searchLeaveStatus) : (id === "All" ? "" : id);
        }

        if (searchLeaveStatus && searchLeaveStatus.toLowerCase() === 'n/a') {
          filterArray.push('LeaveStatus eq null');
        } else {
          filterArray.push(`contains(tolower(LeaveStatus),tolower('${status}'))`);
        }
      }
      if (searchStatusDescription || StatusDescription) {
        if (searchStatusDescription?.toLowerCase() === 'n/a' || StatusDescription?.toLowerCase() === 'n/a') {
          filterArray.push('StatusDescription eq null');
        }
        else if (StatusDescription) {
          filterArray.push(`contains(tolower(StatusDescription),tolower('${StatusDescription}'))`);
        }
        else {
          filterArray.push(`contains(tolower(StatusDescription),tolower('${searchStatusDescription}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },

  getPendingCount: (accessToken) => {
    return requests.getWithToken(`${apiUrl}/odata/MyTeamLeaveList?count=true&$filter=LeaveStatus eq 'SUBMITTED'`, accessToken);
  },

  getDisableDates: (accessToken) => {
    return requests.getWithToken(`${apiUrl}/odata/MyLeaveTrackerList?count=true`, accessToken);
  },

  createLeaveTrackerStatusAction: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/LeaveTracker/UpdateStatus`, data, accessToken);
  },
}

const LeaveType = {

  // getDeleteLeaveTypeList: (accessToken, id) => {
  //   const { typeId } = id;
  //   return requests.deleteWithToken(`${apiUrl}/api/LeaveType/Delete?typeId=${typeId}`, accessToken);
  // },

  createLeaveType: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/OrgLeaveType/Create`, data, accessToken);
  },

  getLeavetypeMasterList: (accessToken) => {
    return requests.getWithToken(`${apiUrl}/odata/LeaveTypeMasterList`, accessToken);
  },
  getOrgLeaveTypeList: (accessToken) => {
    return requests.getWithToken(`${apiUrl}/odata/OrgLeaveTypeList`, accessToken);
  },

}

const LeavetackerReport = {
  getLeaveTrackerReportList: (accessToken, currentPage, PageSize = 15, filter) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { startDate, endDate, UserName, PrivilageTotalLeaveCount, PrivilageTakenLeaveCount, PrivilagePendingLeaveCount, PrivilageUnPaidLeaveCount, PrivilageLOPLeaveCount, SickTotalLeaveCount, SickTakenLeaveCount, SickPendingLeaveCount, SickLOPLeaveCount, SickUnPaidLeaveCount, MaternalTotalLeaveCount, MaternalTakenLeaveCount, MaternalPendingLeaveCount, MaternalLOPLeaveCount, MaternalUnPaidLeaveCount, PaternalTotalLeaveCount, PaternalTakenLeaveCount, PaternalPendingLeaveCount, PaternalLOPLeaveCount, PaternalUnPaidLeaveCount, OtherTotalLeaveCount, OtherTakenLeaveCount, OtherPendingLeaveCount, OtherLOPLeaveCount, OtherUnPaidLeaveCount, OverAllTotalLeaveCount, OverAllTakenLeaveCount, OverAllPendingLeaveCount, OverAllLOPLeaveCount, OverAllUnPaidLeaveCount } = filter || {};
    let url;
    if (startDate && endDate) {
      url = `${apiUrl}/odata/LeaveTrackerReport?StartDate=${startDate}&EndDate=${endDate}&count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;
    } else {
      url = `${apiUrl}/odata/LeaveTrackerReport?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;
    }
    console.log("startDate:", startDate);
    if (UserName || PrivilageTotalLeaveCount || PrivilageTakenLeaveCount || PrivilagePendingLeaveCount || PrivilageUnPaidLeaveCount || PrivilageLOPLeaveCount ||
      SickTotalLeaveCount || SickTakenLeaveCount || SickPendingLeaveCount || SickLOPLeaveCount || SickUnPaidLeaveCount ||
      MaternalTotalLeaveCount || MaternalTakenLeaveCount || MaternalPendingLeaveCount || MaternalLOPLeaveCount || MaternalUnPaidLeaveCount ||
      PaternalTotalLeaveCount || PaternalTakenLeaveCount || PaternalPendingLeaveCount || PaternalLOPLeaveCount || PaternalUnPaidLeaveCount ||
      OtherTotalLeaveCount || OtherTakenLeaveCount || OtherPendingLeaveCount || OtherLOPLeaveCount || OtherUnPaidLeaveCount ||
      OverAllTotalLeaveCount || OverAllTakenLeaveCount || OverAllPendingLeaveCount || OverAllLOPLeaveCount || OverAllUnPaidLeaveCount) {
      url += `&$filter=`;
      const filterArray = [];
      console.log("startDate:", startDate);
      if (UserName) {
        if (UserName.toLowerCase() === 'n/a') {
          filterArray.push(`Username eq null`);
        } else {
          const nameFilter = `(contains(tolower(Username), tolower('${encodeURIComponent(UserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(UserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (OverAllUnPaidLeaveCount) {
        if (OverAllUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`OverAllUnPaidLeaveCount eq ${encodeURIComponent(OverAllUnPaidLeaveCount)}`);
        }
      }
      if (OverAllLOPLeaveCount) {
        if (OverAllLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllLOPLeaveCount eq null');
        } else {
          filterArray.push(`OverAllLOPLeaveCount eq ${encodeURIComponent(OverAllLOPLeaveCount)}`);
        }
      }
      if (OverAllPendingLeaveCount) {
        if (OverAllPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllPendingLeaveCount eq null');
        } else {
          filterArray.push(`OverAllPendingLeaveCount eq ${encodeURIComponent(OverAllPendingLeaveCount)}`);
        }
      }
      if (OverAllTakenLeaveCount) {
        if (OverAllTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllTakenLeaveCount eq null');
        } else {
          filterArray.push(`OverAllTakenLeaveCount eq ${encodeURIComponent(OverAllTakenLeaveCount)}`);
        }
      }
      if (OverAllTotalLeaveCount) {
        if (OverAllTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllTotalLeaveCount eq null');
        } else {
          filterArray.push(`OverAllTotalLeaveCount eq ${encodeURIComponent(OverAllTotalLeaveCount)}`);
        }
      }
      if (OtherUnPaidLeaveCount) {
        if (OtherUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`OtherUnPaidLeaveCount eq ${encodeURIComponent(OtherUnPaidLeaveCount)}`);
        }
      }
      if (OtherLOPLeaveCount) {
        if (OtherLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherLOPLeaveCount eq null');
        } else {
          filterArray.push(`OtherLOPLeaveCount eq ${encodeURIComponent(OtherLOPLeaveCount)}`);
        }
      }
      if (OtherPendingLeaveCount) {
        if (OtherPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherPendingLeaveCount eq null');
        } else {
          filterArray.push(`OtherPendingLeaveCount eq ${encodeURIComponent(OtherPendingLeaveCount)}`);
        }
      }
      if (OtherTakenLeaveCount) {
        if (OtherTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherTakenLeaveCount eq null');
        } else {
          filterArray.push(`OtherTakenLeaveCount eq ${encodeURIComponent(OtherTakenLeaveCount)}`);
        }
      }
      if (OtherTotalLeaveCount) {
        if (OtherTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherTotalLeaveCount eq null');
        } else {
          filterArray.push(`OtherTotalLeaveCount eq ${encodeURIComponent(OtherTotalLeaveCount)}`);
        }
      }
      if (PaternalUnPaidLeaveCount) {
        if (PaternalUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`PaternalUnPaidLeaveCount eq ${encodeURIComponent(PaternalUnPaidLeaveCount)}`);
        }
      }
      if (PaternalLOPLeaveCount) {
        if (PaternalLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalLOPLeaveCount eq null');
        } else {
          filterArray.push(`PaternalLOPLeaveCount eq ${encodeURIComponent(PaternalLOPLeaveCount)}`);
        }
      }
      if (PaternalPendingLeaveCount) {
        if (PaternalPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalPendingLeaveCount eq null');
        } else {
          filterArray.push(`PaternalPendingLeaveCount eq ${encodeURIComponent(PaternalPendingLeaveCount)}`);
        }
      }
      if (PaternalTakenLeaveCount) {
        if (PaternalTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalTakenLeaveCount eq null');
        } else {
          filterArray.push(`PaternalTakenLeaveCount eq ${encodeURIComponent(PaternalTakenLeaveCount)}`);
        }
      }
      if (PaternalTotalLeaveCount) {
        if (PaternalTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalTotalLeaveCount eq null');
        } else {
          filterArray.push(`PaternalTotalLeaveCount eq ${encodeURIComponent(PaternalTotalLeaveCount)}`);
        }
      }
      if (MaternalUnPaidLeaveCount) {
        if (MaternalUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`MaternalUnPaidLeaveCount eq ${encodeURIComponent(MaternalUnPaidLeaveCount)}`);
        }
      }
      if (MaternalLOPLeaveCount) {
        if (MaternalLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalLOPLeaveCount eq null');
        } else {
          filterArray.push(`MaternalLOPLeaveCount eq ${encodeURIComponent(MaternalLOPLeaveCount)}`);
        }
      }
      if (MaternalPendingLeaveCount) {
        if (MaternalPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalPendingLeaveCount eq null');
        } else {
          filterArray.push(`MaternalPendingLeaveCount eq ${encodeURIComponent(MaternalPendingLeaveCount)}`);
        }
      }
      if (MaternalTakenLeaveCount) {
        if (MaternalTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalTakenLeaveCount eq null');
        } else {
          filterArray.push(`MaternalTakenLeaveCount eq ${encodeURIComponent(MaternalTakenLeaveCount)}`);
        }
      }
      if (MaternalTotalLeaveCount) {
        if (MaternalTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalTotalLeaveCount eq null');
        } else {
          filterArray.push(`MaternalTotalLeaveCount eq ${encodeURIComponent(MaternalTotalLeaveCount)}`);
        }
      }
      if (SickUnPaidLeaveCount) {
        if (SickUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`SickUnPaidLeaveCount eq ${encodeURIComponent(SickUnPaidLeaveCount)}`);
        }
      }
      if (SickLOPLeaveCount) {
        if (SickLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickLOPLeaveCount eq null');
        } else {
          filterArray.push(`SickLOPLeaveCount eq ${encodeURIComponent(SickLOPLeaveCount)}`);
        }
      }
      if (SickPendingLeaveCount) {
        if (SickPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickPendingLeaveCount eq null');
        } else {
          filterArray.push(`SickPendingLeaveCount eq ${encodeURIComponent(SickPendingLeaveCount)}`);
        }
      }
      if (SickTakenLeaveCount) {
        if (SickTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickTakenLeaveCount eq null');
        } else {
          filterArray.push(`SickTakenLeaveCount eq ${encodeURIComponent(SickTakenLeaveCount)}`);
        }
      }
      if (SickTotalLeaveCount) {
        if (SickTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickTotalLeaveCount eq null');
        } else {
          filterArray.push(`SickTotalLeaveCount eq ${encodeURIComponent(SickTotalLeaveCount)}`);
        }
      }
      if (PrivilageTotalLeaveCount) {
        if (PrivilageTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilageTotalLeaveCount eq null');
        } else {
          filterArray.push(`PrivilageTotalLeaveCount eq ${encodeURIComponent(PrivilageTotalLeaveCount)}`);
        }
      }
      if (PrivilageTakenLeaveCount) {
        if (PrivilageTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilageTakenLeaveCount eq null');
        } else {
          filterArray.push(`PrivilageTakenLeaveCount eq ${encodeURIComponent(PrivilageTakenLeaveCount)}`);
        }
      }
      if (PrivilagePendingLeaveCount) {
        if (PrivilagePendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilagePendingLeaveCount eq null');
        } else {
          filterArray.push(`PrivilagePendingLeaveCount eq ${encodeURIComponent(PrivilagePendingLeaveCount)}`);
        }
      }
      if (PrivilageUnPaidLeaveCount) {
        if (PrivilageUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilageUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`PrivilageUnPaidLeaveCount eq ${encodeURIComponent(PrivilageUnPaidLeaveCount)}`);
        }
      }
      if (PrivilageLOPLeaveCount) {
        if (PrivilageLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilageLOPLeaveCount eq null');
        } else {
          filterArray.push(`PrivilageLOPLeaveCount eq ${encodeURIComponent(PrivilageLOPLeaveCount)}`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getLeaveTrackerReportExportList: (accessToken, filter) => {
    const { UserName, PrivilageTotalLeaveCount, PrivilageTakenLeaveCount, PrivilagePendingLeaveCount, PrivilageUnPaidLeaveCount,
      PrivilageLOPLeaveCount, SickTotalLeaveCount, SickTakenLeaveCount, SickPendingLeaveCount, SickLOPLeaveCount,
      SickUnPaidLeaveCount, MaternalTotalLeaveCount, MaternalTakenLeaveCount, MaternalPendingLeaveCount, MaternalLOPLeaveCount,
      MaternalUnPaidLeaveCount, PaternalTotalLeaveCount, PaternalTakenLeaveCount, PaternalPendingLeaveCount, PaternalLOPLeaveCount,
      PaternalUnPaidLeaveCount, OtherTotalLeaveCount, OtherTakenLeaveCount, OtherPendingLeaveCount, OtherLOPLeaveCount,
      OtherUnPaidLeaveCount, OverAllTotalLeaveCount, OverAllTakenLeaveCount, OverAllPendingLeaveCount, OverAllLOPLeaveCount,
      OverAllUnPaidLeaveCount, startDate, endDate } = filter || {};

    let url;
    if (startDate && endDate) {
      url = `${apiUrl}/LeaveTrackerReport/Export?StartDate=${startDate}&EndDate=${endDate}&count=true`;
    } else {
      url = `${apiUrl}/LeaveTrackerReport/Export?count=true`;
    }

    if (UserName || PrivilageTotalLeaveCount || PrivilageTakenLeaveCount || PrivilagePendingLeaveCount || PrivilageUnPaidLeaveCount || PrivilageLOPLeaveCount ||
      SickTotalLeaveCount || SickTakenLeaveCount || SickPendingLeaveCount || SickLOPLeaveCount || SickUnPaidLeaveCount ||
      MaternalTotalLeaveCount || MaternalTakenLeaveCount || MaternalPendingLeaveCount || MaternalLOPLeaveCount || MaternalUnPaidLeaveCount ||
      PaternalTotalLeaveCount || PaternalTakenLeaveCount || PaternalPendingLeaveCount || PaternalLOPLeaveCount || PaternalUnPaidLeaveCount ||
      OtherTotalLeaveCount || OtherTakenLeaveCount || OtherPendingLeaveCount || OtherLOPLeaveCount || OtherUnPaidLeaveCount ||
      OverAllTotalLeaveCount || OverAllTakenLeaveCount || OverAllPendingLeaveCount || OverAllLOPLeaveCount || OverAllUnPaidLeaveCount) {
      url += `&$filter=`;
      const filterArray = [];
      if (UserName) {
        if (UserName.toLowerCase() === 'n/a') {
          filterArray.push(`Username eq null`);
        } else {
          const nameFilter = `(contains(tolower(Username), tolower('${encodeURIComponent(UserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(UserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (OverAllUnPaidLeaveCount) {
        if (OverAllUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`OverAllUnPaidLeaveCount eq ${encodeURIComponent(OverAllUnPaidLeaveCount)}`);
        }
      }
      if (OverAllLOPLeaveCount) {
        if (OverAllLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllLOPLeaveCount eq null');
        } else {
          filterArray.push(`OverAllLOPLeaveCount eq ${encodeURIComponent(OverAllLOPLeaveCount)}`);
        }
      }
      if (OverAllPendingLeaveCount) {
        if (OverAllPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllPendingLeaveCount eq null');
        } else {
          filterArray.push(`OverAllPendingLeaveCount eq ${encodeURIComponent(OverAllPendingLeaveCount)}`);
        }
      }
      if (OverAllTakenLeaveCount) {
        if (OverAllTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllTakenLeaveCount eq null');
        } else {
          filterArray.push(`OverAllTakenLeaveCount eq ${encodeURIComponent(OverAllTakenLeaveCount)}`);
        }
      }
      if (OverAllTotalLeaveCount) {
        if (OverAllTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OverAllTotalLeaveCount eq null');
        } else {
          filterArray.push(`OverAllTotalLeaveCount eq ${encodeURIComponent(OverAllTotalLeaveCount)}`);
        }
      }
      if (OtherUnPaidLeaveCount) {
        if (OtherUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`OtherUnPaidLeaveCount eq ${encodeURIComponent(OtherUnPaidLeaveCount)}`);
        }
      }
      if (OtherLOPLeaveCount) {
        if (OtherLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherLOPLeaveCount eq null');
        } else {
          filterArray.push(`OtherLOPLeaveCount eq ${encodeURIComponent(OtherLOPLeaveCount)}`);
        }
      }
      if (OtherPendingLeaveCount) {
        if (OtherPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherPendingLeaveCount eq null');
        } else {
          filterArray.push(`OtherPendingLeaveCount eq ${encodeURIComponent(OtherPendingLeaveCount)}`);
        }
      }
      if (OtherTakenLeaveCount) {
        if (OtherTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherTakenLeaveCount eq null');
        } else {
          filterArray.push(`OtherTakenLeaveCount eq ${encodeURIComponent(OtherTakenLeaveCount)}`);
        }
      }
      if (OtherTotalLeaveCount) {
        if (OtherTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('OtherTotalLeaveCount eq null');
        } else {
          filterArray.push(`OtherTotalLeaveCount eq ${encodeURIComponent(OtherTotalLeaveCount)}`);
        }
      }
      if (PaternalUnPaidLeaveCount) {
        if (PaternalUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`PaternalUnPaidLeaveCount eq ${encodeURIComponent(PaternalUnPaidLeaveCount)}`);
        }
      }
      if (PaternalLOPLeaveCount) {
        if (PaternalLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalLOPLeaveCount eq null');
        } else {
          filterArray.push(`PaternalLOPLeaveCount eq ${encodeURIComponent(PaternalLOPLeaveCount)}`);
        }
      }
      if (PaternalPendingLeaveCount) {
        if (PaternalPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalPendingLeaveCount eq null');
        } else {
          filterArray.push(`PaternalPendingLeaveCount eq ${encodeURIComponent(PaternalPendingLeaveCount)}`);
        }
      }
      if (PaternalTakenLeaveCount) {
        if (PaternalTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalTakenLeaveCount eq null');
        } else {
          filterArray.push(`PaternalTakenLeaveCount eq ${encodeURIComponent(PaternalTakenLeaveCount)}`);
        }
      }
      if (PaternalTotalLeaveCount) {
        if (PaternalTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PaternalTotalLeaveCount eq null');
        } else {
          filterArray.push(`PaternalTotalLeaveCount eq ${encodeURIComponent(PaternalTotalLeaveCount)}`);
        }
      }
      if (MaternalUnPaidLeaveCount) {
        if (MaternalUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`MaternalUnPaidLeaveCount eq ${encodeURIComponent(MaternalUnPaidLeaveCount)}`);
        }
      }
      if (MaternalLOPLeaveCount) {
        if (MaternalLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalLOPLeaveCount eq null');
        } else {
          filterArray.push(`MaternalLOPLeaveCount eq ${encodeURIComponent(MaternalLOPLeaveCount)}`);
        }
      }
      if (MaternalPendingLeaveCount) {
        if (MaternalPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalPendingLeaveCount eq null');
        } else {
          filterArray.push(`MaternalPendingLeaveCount eq ${encodeURIComponent(MaternalPendingLeaveCount)}`);
        }
      }
      if (MaternalTakenLeaveCount) {
        if (MaternalTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalTakenLeaveCount eq null');
        } else {
          filterArray.push(`MaternalTakenLeaveCount eq ${encodeURIComponent(MaternalTakenLeaveCount)}`);
        }
      }
      if (MaternalTotalLeaveCount) {
        if (MaternalTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('MaternalTotalLeaveCount eq null');
        } else {
          filterArray.push(`MaternalTotalLeaveCount eq ${encodeURIComponent(MaternalTotalLeaveCount)}`);
        }
      }
      if (SickUnPaidLeaveCount) {
        if (SickUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`SickUnPaidLeaveCount eq ${encodeURIComponent(SickUnPaidLeaveCount)}`);
        }
      }
      if (SickLOPLeaveCount) {
        if (SickLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickLOPLeaveCount eq null');
        } else {
          filterArray.push(`SickLOPLeaveCount eq ${encodeURIComponent(SickLOPLeaveCount)}`);
        }
      }
      if (SickPendingLeaveCount) {
        if (SickPendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickPendingLeaveCount eq null');
        } else {
          filterArray.push(`SickPendingLeaveCount eq ${encodeURIComponent(SickPendingLeaveCount)}`);
        }
      }
      if (SickTakenLeaveCount) {
        if (SickTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickTakenLeaveCount eq null');
        } else {
          filterArray.push(`SickTakenLeaveCount eq ${encodeURIComponent(SickTakenLeaveCount)}`);
        }
      }
      if (SickTotalLeaveCount) {
        if (SickTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('SickTotalLeaveCount eq null');
        } else {
          filterArray.push(`SickTotalLeaveCount eq ${encodeURIComponent(SickTotalLeaveCount)}`);
        }
      }
      if (PrivilageTotalLeaveCount) {
        if (PrivilageTotalLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilageTotalLeaveCount eq null');
        } else {
          filterArray.push(`PrivilageTotalLeaveCount eq ${encodeURIComponent(PrivilageTotalLeaveCount)}`);
        }
      }
      if (PrivilageTakenLeaveCount) {
        if (PrivilageTakenLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilageTakenLeaveCount eq null');
        } else {
          filterArray.push(`PrivilageTakenLeaveCount eq ${encodeURIComponent(PrivilageTakenLeaveCount)}`);
        }
      }
      if (PrivilagePendingLeaveCount) {
        if (PrivilagePendingLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilagePendingLeaveCount eq null');
        } else {
          filterArray.push(`PrivilagePendingLeaveCount eq ${encodeURIComponent(PrivilagePendingLeaveCount)}`);
        }
      }
      if (PrivilageUnPaidLeaveCount) {
        if (PrivilageUnPaidLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilageUnPaidLeaveCount eq null');
        } else {
          filterArray.push(`PrivilageUnPaidLeaveCount eq ${encodeURIComponent(PrivilageUnPaidLeaveCount)}`);
        }
      }
      if (PrivilageLOPLeaveCount) {
        if (PrivilageLOPLeaveCount.toLowerCase() === 'n/a') {
          filterArray.push('PrivilageLOPLeaveCount eq null');
        } else {
          filterArray.push(`PrivilageLOPLeaveCount eq ${encodeURIComponent(PrivilageLOPLeaveCount)}`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  }
}
const LeaveMaster = {
  getLeaveMasterExportList: (accessToken, searchUserId, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty) => {

    let url = `${apiUrl}/LeaveMasterList/Export?count=true&$orderby=username asc`;
    if (searchUserId || searchQty || searchCreatedBy || searchCreatedDateTime || searchFrequency || searchLeaveTypeName || searchConsecutiveDaysQty) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchUserId) {
        if (searchUserId.toLowerCase() === 'n/a') {
          filterArray.push(`Username eq null`);
        } else {
          const nameFilter = `(contains(tolower(Username), tolower('${encodeURIComponent(searchUserId)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchUserId)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchLeaveTypeName) {
        if (searchLeaveTypeName.toLowerCase() === 'n/a') {
          filterArray.push('LeaveTypeName eq null');
        } else {
          filterArray.push(`contains(tolower(LeaveTypeName),tolower('${encodeURIComponent(searchLeaveTypeName)}'))`);
        }
      }
      if (searchFrequency) {
        if (searchFrequency.toLowerCase() === 'n/a') {
          filterArray.push('Frequency eq null');
        } else {
          filterArray.push(`contains(tolower(Frequency),tolower('${encodeURIComponent(searchFrequency)}'))`);
        }
      }
      if (searchCreatedDateTime) {
        if (searchCreatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('CreatedDateTime eq null');
        } else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchCreatedDateTime)}`);
        }
      }
      if (searchQty) {
        if (searchQty.toLowerCase() === '0' || searchQty.toLowerCase() === 'n/a') {
          filterArray.push('Qty eq 0');
        } else {
          filterArray.push(`Qty eq ${encodeURIComponent(searchQty)}`);
        }
      }
      if (searchConsecutiveDaysQty) {
        if (searchConsecutiveDaysQty.toLowerCase() === '0' || searchConsecutiveDaysQty.toLowerCase() === 'n/a') {
          filterArray.push('ConsecutiveDaysQty eq 0');
        } else {
          filterArray.push(`ConsecutiveDaysQty eq ${encodeURIComponent(searchConsecutiveDaysQty)}`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getLeaveMasterList: (accessToken, filteredData, currentPage, PageSize = 15, searchUserId, searchQty, searchCreatedBy, searchCreatedDateTime, searchFrequency, searchLeaveTypeName, searchConsecutiveDaysQty) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filteredData:", filteredData);
    const { UserName, CreatedBy, LeaveTypeName, Frequency, CreatedDateTime, Qty, ConsecutiveDaysQty } = filteredData || {};
    let url = `${apiUrl}/odata/LeaveMasterList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`;
    if (LeaveTypeName || UserName || Frequency || CreatedBy || CreatedDateTime || Qty || ConsecutiveDaysQty || searchUserId || searchQty || searchCreatedBy || searchCreatedDateTime || searchFrequency || searchLeaveTypeName || searchConsecutiveDaysQty) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchUserId || UserName) {
        if (searchUserId?.toLowerCase() === 'n/a' || UserName?.toLowerCase() === 'n/a') {
          filterArray.push(`Username eq null`);
        }
        else if (UserName) {
          const nameFilter = `(contains(tolower(Username), tolower('${encodeURIComponent(UserName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(UserName)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(Username), tolower('${encodeURIComponent(searchUserId)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchUserId)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchCreatedBy || CreatedBy) {
        if (searchCreatedBy?.toLowerCase() === 'n/a' || CreatedBy?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        }
        else if (CreatedBy) {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(CreatedBy)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchLeaveTypeName || LeaveTypeName) {
        if (searchLeaveTypeName?.toLowerCase() === 'n/a' || LeaveTypeName?.toLowerCase() === 'n/a') {
          filterArray.push('LeaveTypeName eq null');
        }
        else if (LeaveTypeName) {
          filterArray.push(`contains(tolower(LeaveTypeName),tolower('${encodeURIComponent(LeaveTypeName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeaveTypeName),tolower('${encodeURIComponent(searchLeaveTypeName)}'))`);
        }
      }
      if (searchFrequency || Frequency) {
        if (searchFrequency?.toLowerCase() === 'n/a' || Frequency?.toLowerCase() === 'n/a') {
          filterArray.push('Frequency eq null');
        }
        else if (Frequency) {
          filterArray.push(`contains(tolower(Frequency),tolower('${encodeURIComponent(Frequency)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Frequency),tolower('${encodeURIComponent(searchFrequency)}'))`);
        }
      }
      if (searchCreatedDateTime || CreatedDateTime) {
        if (searchCreatedDateTime?.toLowerCase() === 'n/a' || CreatedDateTime?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedDateTime eq null');
        }
        else if (CreatedDateTime) {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(CreatedDateTime)}`);
        }
        else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchCreatedDateTime)}`);
        }
      }
      if (searchQty || Qty) {
        if (searchQty?.toLowerCase() === '0' || searchQty?.toLowerCase() === 'n/a' || Qty?.toLowerCase() === '0') {
          filterArray.push('Qty eq 0');
        }
        else if (Qty) {
          filterArray.push(`Qty eq ${encodeURIComponent(Qty)}`);
        }
        else {
          filterArray.push(`Qty eq ${encodeURIComponent(searchQty)}`);
        }
      }
      if (searchConsecutiveDaysQty || ConsecutiveDaysQty) {
        if (searchConsecutiveDaysQty?.toLowerCase() === '0' || searchConsecutiveDaysQty?.toLowerCase() === 'n/a' || ConsecutiveDaysQty?.toLowerCase() === '0') {
          filterArray.push('ConsecutiveDaysQty eq 0');
        }
        else if (ConsecutiveDaysQty) {
          filterArray.push(`ConsecutiveDaysQty eq ${encodeURIComponent(ConsecutiveDaysQty)}`);
        }
        else {
          filterArray.push(`ConsecutiveDaysQty eq ${encodeURIComponent(searchConsecutiveDaysQty)}`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getLeaveMasterDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/LeaveMaster/Details?LeaveMasterId=${id}`, accessToken);
  },
  createLeaveMaster: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/LeaveMaster/Create`, data, accessToken);
  },
  editLeaveMaster: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/LeaveMaster/Update`, data, accessToken);
  },
}

const HolidayMaster = {
  getHolidayMasterExportList: (accessToken, searchbuildHolidayName, searchbuildHolidayDate) => {

    let url = `${apiUrl}/HolidayMasterList/Export?count=true &$orderby=holidayDate asc`;
    if (searchbuildHolidayName || searchbuildHolidayDate) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchbuildHolidayName) {
        if (searchbuildHolidayName.toLowerCase() === 'n/a') {
          filterArray.push('HolidayName eq null');
        } else {
          filterArray.push(`contains(tolower(HolidayName),tolower('${encodeURIComponent(searchbuildHolidayName)}'))`);
        }
      }
      if (searchbuildHolidayDate) {
        if (searchbuildHolidayDate.toLowerCase() === 'n/a') {
          filterArray.push('HolidayDate eq null');
        } else {
          filterArray.push(`HolidayDate eq ${encodeURIComponent(searchbuildHolidayDate)}`);
        }
      }

      console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getHolidayMasterList: (accessToken, filteredData, currentPage, PageSize = 15, searchbuildHolidayName, searchbuildHolidayDate) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { HolidayName, HolidayDate } = filteredData || {};
    let url = `${apiUrl}/odata/HolidayMasterList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=HolidayDate asc`;
    if (searchbuildHolidayName || searchbuildHolidayDate || HolidayDate || HolidayName) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchbuildHolidayName || HolidayName) {
        if (searchbuildHolidayName?.toLowerCase() === 'n/a' || HolidayName?.toLowerCase() === 'n/a') {
          filterArray.push('HolidayName eq null');
        }
        else if (HolidayName) {
          filterArray.push(`contains(tolower(HolidayName),tolower('${encodeURIComponent(HolidayName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(HolidayName),tolower('${encodeURIComponent(searchbuildHolidayName)}'))`);
        }
      }
      if (searchbuildHolidayDate || HolidayDate) {
        if (searchbuildHolidayDate?.toLowerCase() === 'n/a' || HolidayDate?.toLowerCase() === 'n/a') {
          filterArray.push('HolidayDate eq null');
        }
        else if (HolidayDate) {
          filterArray.push(`HolidayDate eq ${encodeURIComponent(HolidayDate)}`);
        }
        else {
          filterArray.push(`HolidayDate eq ${encodeURIComponent(searchbuildHolidayDate)}`);
        }
      }

      console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getHolidayMasterDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/HolidayMaster/Details?holidayId=${id}`, accessToken);
  },
  createHolidayMaster: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/HolidayMaster/Create`, data, accessToken);
  },
  editHolidayMaster: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/HolidayMaster/Update`, data, accessToken);
  },
  getDeleteHolidayList: (accessToken, id) => {
    const { holidayId } = id;
    return requests.deleteWithToken(`${apiUrl}/api/HolidayMaster/Delete?holidayId=${holidayId}`, accessToken);
  },
}
const WeeklyOff = {
  getWeeklyOffDetails: (accessToken) => {
    return requests.getWithToken(`${apiUrl}/api/WeeklyOffDays/Details?$count=true`, accessToken)
  },
  editweeklyOff: (accessToken, data) => {
    console.log("data:", data);
    return requests.postWithToken(`${apiUrl}/api/WeeklyOffDays/Save`, data, accessToken);
  },
}
const LeaveTrackerDashboard = {
  getDashboardLeaveTrackerCounts: (accessToken) => {
    return requests.getWithToken(`${apiUrl}/api/LeaveTracker/DashBoard?$count=true`, accessToken)
  },
  getDonutDashboardLeaveCounts: (accessToken, leaveType) => {
    console.log('leaveType-id:', leaveType);

    if (leaveType) {
      return requests.getWithToken(`${apiUrl}/api/LeaveTracker/UserLeaveTypeCount?LeaveTypeId=${leaveType}`, accessToken);
    }
    else {
      return requests.getWithToken(`${apiUrl}/api/LeaveTracker/UserLeaveTypeCount`, accessToken);
    }
  },
}

const Organization = {
  getOrganizationExportlist: (accessToken, searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive) => {

    let url = `${apiUrl}/OrgMasterList/Export?$count=true&$orderby=orgName asc`;

    if (searchOrgName || searchOrgContactPerson || searchOrgContactEmail || searchOrgContactNumber || searchOrgAddress || searchOrgCreatedBy || searchOrgUserCount || searchIsActive) {
      url += `&$filter=`;
      const filterArray = [];
      console.log("searchOrgUserCount:", searchOrgUserCount);

      if (searchOrgName) {
        if (searchOrgName.toLowerCase() === 'n/a') {
          filterArray.push('OrgName eq null');
        } else {
          filterArray.push(`contains(tolower(OrgName),tolower('${encodeURIComponent(searchOrgName)}'))`);
        }
      }
      if (searchIsActive) {
        if (searchIsActive.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
        }
      }
      if (searchOrgUserCount) {
        if (searchOrgUserCount.toLowerCase() === 'n/a') {
          filterArray.push('UserCount eq null');
        } else {
          filterArray.push(`UserCount eq ${encodeURIComponent(searchOrgUserCount)}`);
        }
      }
      if (searchOrgContactPerson) {
        if (searchOrgContactPerson.toLowerCase() === 'n/a') {
          filterArray.push('OrgContactPerson eq null');
        } else {
          filterArray.push(`contains(tolower(OrgContactPerson),tolower('${encodeURIComponent(searchOrgContactPerson)}'))`);
        }
      }
      if (searchOrgContactEmail) {
        if (searchOrgContactEmail.toLowerCase() === 'n/a') {
          filterArray.push('OrgContactPerson eq null');
        } else {
          const nameFilter = `(contains(tolower(OrgContactEmail), tolower('${encodeURIComponent(searchOrgContactEmail)}')) or contains(tolower(OrgContactPerson), tolower('${encodeURIComponent(searchOrgContactEmail)}')))`;
          filterArray.push(nameFilter);
          // filterArray.push(`contains(tolower(OrgContactEmail),tolower('${searchOrgContactEmail}'))`);
        }
      }
      if (searchOrgContactNumber) {
        if (searchOrgContactNumber.toLowerCase() === 'n/a') {
          filterArray.push('OrgContactNumber eq null');
        } else {
          filterArray.push(`contains(tolower(OrgContactNumber),tolower('${encodeURIComponent(searchOrgContactNumber)}'))`);
        }
      }
      if (searchOrgAddress) {
        if (searchOrgAddress.toLowerCase() === 'n/a') {
          filterArray.push('OrgAddress eq null');
        } else {
          filterArray.push(`contains(tolower(OrgAddress),tolower('${encodeURIComponent(searchOrgAddress)}'))`);
        }
      }
      if (searchOrgCreatedBy) {
        if (searchOrgCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('OrgCreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(OrgCreatedBy),tolower('${encodeURIComponent(searchOrgCreatedBy)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getOrganizationlist: (accessToken, filteredData, currentPage, PageSize = 15, searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { OrgCreatedBy, OrgAddress, OrgContactNumber, OrgName, IsActive, UserCount, OrgContactPerson, OrgContactEmail } = filteredData || {};
    let url = `${apiUrl}/odata/OrgMasterList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=OrgCreatedTimeStamp desc`;
    if (OrgCreatedBy || OrgAddress || OrgContactNumber || OrgContactEmail || OrgContactPerson || UserCount || OrgName || IsActive || searchOrgName || searchOrgContactPerson || searchOrgContactEmail || searchOrgContactNumber || searchOrgAddress || searchOrgCreatedBy || searchOrgUserCount || searchIsActive) {
      url += `&$filter=`;
      const filterArray = [];
      console.log("searchOrgUserCount:", searchOrgUserCount);

      if (searchOrgName || OrgName) {
        if (searchOrgName?.toLowerCase() === 'n/a' || OrgName?.toLowerCase() === 'n/a') {
          filterArray.push('OrgName eq null');
        }
        else if (OrgName) {
          filterArray.push(`contains(tolower(OrgName),tolower('${encodeURIComponent(OrgName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OrgName),tolower('${encodeURIComponent(searchOrgName)}'))`);
        }
      }
      if (searchIsActive || IsActive) {
        if (searchIsActive?.toLowerCase() === 'n/a' || IsActive?.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        }
        else if (IsActive) {
          filterArray.push(`IsActive eq ${encodeURIComponent(IsActive)}`);
        }
        else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
        }
      }
      if (searchOrgUserCount || UserCount) {
        if (searchOrgUserCount?.toLowerCase() === 'n/a' || UserCount?.toLowerCase() === 'n/a') {
          filterArray.push('UserCount eq null');
        }
        else if (UserCount) {
          filterArray.push(`UserCount eq ${encodeURIComponent(UserCount)}`);
        }
        else {
          filterArray.push(`UserCount eq ${encodeURIComponent(searchOrgUserCount)}`);
        }
      }
      if (searchOrgContactPerson || OrgContactPerson) {
        if (searchOrgContactPerson?.toLowerCase() === 'n/a' || OrgContactPerson?.toLowerCase() === 'n/a') {
          filterArray.push('OrgContactPerson eq null');
        }
        else if (OrgContactPerson) {
          filterArray.push(`contains(tolower(OrgContactPerson),tolower('${encodeURIComponent(OrgContactPerson)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OrgContactPerson),tolower('${encodeURIComponent(searchOrgContactPerson)}'))`);
        }
      }
      if (searchOrgContactEmail || OrgContactEmail) {
        if (searchOrgContactEmail?.toLowerCase() === 'n/a' || OrgContactEmail?.toLowerCase() === 'n/a') {
          filterArray.push('OrgContactEmail eq null');
        }
        else if (OrgContactEmail) {
          const nameFilter = `(contains(tolower(OrgContactEmail), tolower('${encodeURIComponent(OrgContactEmail)}')) or contains(tolower(OrgContactPerson), tolower('${encodeURIComponent(OrgContactEmail)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(OrgContactEmail), tolower('${encodeURIComponent(searchOrgContactEmail)}')) or contains(tolower(OrgContactPerson), tolower('${encodeURIComponent(searchOrgContactEmail)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchOrgContactNumber || OrgContactNumber) {
        if (searchOrgContactNumber?.toLowerCase() === 'n/a' || OrgContactNumber?.toLowerCase() === 'n/a') {
          filterArray.push('OrgContactNumber eq null');
        }
        else if (OrgContactNumber) {
          filterArray.push(`contains(tolower(OrgContactNumber),tolower('${encodeURIComponent(OrgContactNumber)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OrgContactNumber),tolower('${encodeURIComponent(searchOrgContactNumber)}'))`);
        }
      }
      if (searchOrgAddress || OrgAddress) {
        if (searchOrgAddress?.toLowerCase() === 'n/a' || OrgAddress?.toLowerCase() === 'n/a') {
          filterArray.push('OrgAddress eq null');
        }
        else if (OrgAddress) {
          filterArray.push(`contains(tolower(OrgAddress),tolower('${encodeURIComponent(OrgAddress)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OrgAddress),tolower('${encodeURIComponent(searchOrgAddress)}'))`);
        }
      }
      if (searchOrgCreatedBy || OrgCreatedBy) {
        if (searchOrgCreatedBy?.toLowerCase() === 'n/a' || OrgCreatedBy?.toLowerCase() === 'n/a') {
          filterArray.push('OrgCreatedBy eq null');
        }
        else if (OrgCreatedBy) {
          filterArray.push(`contains(tolower(OrgCreatedBy),tolower('${encodeURIComponent(OrgCreatedBy)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OrgCreatedBy),tolower('${encodeURIComponent(searchOrgCreatedBy)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getOrganizationDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/OrgMaster/Details?OrgMasterId=${id}`, accessToken);
  },
  getOrgUserBasedOrgList: (accessToken, id, currentPage, PageSize, filter) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { IsActive, IsPortalEnable, UserEmail } = filter || {};
    let url = `${apiUrl}/odata/OrgUserBasedOnOrgList?OrgId=${id}&count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;
    if (IsActive || IsPortalEnable || UserEmail) {
      url += `&$filter=`;
      const filterArray = [];
      if (IsActive) {
        if (IsActive.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(IsActive)}`);
        }
      }
      if (IsPortalEnable) {
        if (IsPortalEnable.toLowerCase() === 'n/a') {
          filterArray.push('IsPortalEnable eq null');
        } else {
          filterArray.push(`IsPortalEnable eq ${encodeURIComponent(IsPortalEnable)}`);
        }
      }
      if (UserEmail) {
        if (UserEmail.toLowerCase() === 'n/a') {
          filterArray.push('UserEmail eq null');
        } else {
          // filterArray.push(`contains(tolower(UserEmail),tolower('${encodeURIComponent(UserEmail)}'))`);
          const nameFilter = `(contains(tolower(UserEmail), tolower('${encodeURIComponent(UserEmail)}')) or contains(tolower(UserName), tolower('${encodeURIComponent(UserEmail)}')))`;
          filterArray.push(nameFilter);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getApptypeList: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/AppMasterList?count=true&$select=Id,AppName&$filter=IsActive eq true`;
    if (searchValue) {
      url = `${apiUrl}/odata/AppMasterList?count=true&$select=Id,AppName&$filter=IsActive eq true and contains(tolower(AppName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  createOrganization: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/OrgMaster/Create`, data, accessToken);
  },
  editOrganization: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/OrgMaster/Update`, data, accessToken);
  },
}

const AppMaster = {
  getAppMasterExportlist: (accessToken, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, isSuperAdmin, searchorganizationCount, searchAssignedTimeStamp) => {
    console.log("isSuperAdmin:", isSuperAdmin);
    if (isSuperAdmin === false) {
      let url = `${apiUrl}/OrgAppMasterList/Export?count=true&$orderby=appName asc`;
      if (searchAppName || searchId || searchLatestVersion || searchCreatedDateTime || searchIsActive || searchorganizationCount || searchAssignedTimeStamp) {
        url += `&$filter=`;
        const filterArray = [];
        if (searchorganizationCount) {
          if (searchorganizationCount.toLowerCase() === 'n/a') {
            filterArray.push(`organizationCount eq null`);
          } else {
            // filterArray.push(`contains(tolower(organizationCount),tolower('${searchorganizationCount}'))`);
            filterArray.push(`organizationCount eq ${encodeURIComponent(searchorganizationCount)}`);
          }
        }
        if (searchAssignedTimeStamp) {
          if (searchAssignedTimeStamp.toLowerCase() === 'n/a') {
            filterArray.push('assignedTimeStamp eq null');
          } else {
            filterArray.push(`assignedTimeStamp eq ${encodeURIComponent(searchAssignedTimeStamp)}`);
          }
        }
        if (searchAppName) {
          if (searchAppName.toLowerCase() === 'n/a') {
            filterArray.push('AppName eq null');
          } else {

            filterArray.push(`contains(tolower(AppName),tolower('${encodeURIComponent(searchAppName)}'))`);
          }
        }
        if (searchId) {
          if (searchId.toLowerCase() === 'n/a') {
            filterArray.push('Id eq null');
          } else {
            filterArray.push(`contains(tolower(Id),tolower('${encodeURIComponent(searchId)}'))`);
          }
        }
        if (searchLatestVersion) {
          if (searchLatestVersion.toLowerCase() === 'n/a') {
            filterArray.push('LatestVersion eq null');
          } else {
            filterArray.push(`contains(tolower(LatestVersion),tolower('${encodeURIComponent(searchLatestVersion)}'))`);
          }
        }
        if (searchIsActive) {
          if (searchIsActive.toLowerCase() === 'n/a') {
            filterArray.push('IsActive eq null');
          } else {
            filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
          }
        }
        if (searchCreatedDateTime) {
          if (searchCreatedDateTime.toLowerCase() === 'n/a') {
            filterArray.push('CreatedDateTime eq null');
          } else {
            filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchCreatedDateTime)}`);
          }
        }
        // console.log("filterArray", filterArray);
        let searchTerm = "";
        if (filterArray.length > 0) {
          searchTerm = filterArray.join(' and ');
        }
        // console.log("searchterm:", searchTerm);
        if (searchTerm.length > 0)
          url += `${searchTerm}`;
        // console.log(url);
      }
      return requests.getWithToken(url, accessToken);
    }
    else if (isSuperAdmin === true) {
      let url = `${apiUrl}/AppMasterList/Export?count=true&$orderby=appName asc`;
      if (searchAppName || searchId || searchLatestVersion || searchCreatedDateTime || searchIsActive || searchorganizationCount || searchAssignedTimeStamp) {
        url += `&$filter=`;
        const filterArray = [];
        if (searchorganizationCount) {
          console.log("searchorganizationCount:", searchorganizationCount);
          if (searchorganizationCount.toLowerCase() === 'n/a') {
            filterArray.push('organizationCount eq null');
          } else {
            // filterArray.push(`contains(tolower(organizationCount),tolower('${searchorganizationCount}'))`);
            filterArray.push(`organizationCount eq ${encodeURIComponent(searchorganizationCount)}`);
          }
        }
        if (searchAssignedTimeStamp) {
          if (searchAssignedTimeStamp.toLowerCase() === 'n/a') {
            filterArray.push('assignedTimeStamp eq null');
          } else {
            filterArray.push(`assignedTimeStamp eq ${encodeURIComponent(searchAssignedTimeStamp)}`);
          }
        }
        if (searchAppName) {
          if (searchAppName.toLowerCase() === 'n/a') {
            filterArray.push('AppName eq null');
          } else {
            filterArray.push(`contains(tolower(AppName),tolower('${encodeURIComponent(searchAppName)}'))`);
          }
        }
        if (searchId) {
          if (searchId.toLowerCase() === 'n/a') {
            filterArray.push('Id eq null');
          } else {
            filterArray.push(`contains(tolower(Id),tolower('${encodeURIComponent(searchId)}'))`);
          }
        }
        if (searchLatestVersion) {
          if (searchLatestVersion.toLowerCase() === 'n/a') {
            filterArray.push('LatestVersion eq null');
          } else {
            filterArray.push(`contains(tolower(LatestVersion),tolower('${encodeURIComponent(searchLatestVersion)}'))`);
          }
        }
        if (searchIsActive) {
          if (searchIsActive.toLowerCase() === 'n/a') {
            filterArray.push('IsActive eq null');
          } else {
            filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
          }
        }
        if (searchCreatedDateTime) {
          if (searchCreatedDateTime.toLowerCase() === 'n/a') {
            filterArray.push('CreatedDateTime eq null');
          } else {
            filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchCreatedDateTime)}`);
          }
        }
        // console.log("filterArray", filterArray);
        let searchTerm = "";
        if (filterArray.length > 0) {
          searchTerm = filterArray.join(' and ');
        }
        // console.log("searchterm:", searchTerm);
        if (searchTerm.length > 0)
          url += `${searchTerm}`;
        // console.log(url);
      }
      return requests.getWithToken(url, accessToken);
    }

  },
  getAppMasterlist: (accessToken, filteredData, currentPage = 1, PageSize = 15, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, isSuperAdmin, searchorganizationCount, searchAssignedTimeStamp) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { organizationCount, AppName, Id, LatestVersion, IsActive, CreatedDateTime, AssignedTimeStamp } = filteredData || {};
    console.log("isSuperAdmin:", isSuperAdmin);
    console.log("filteredData:", filteredData);

    if (isSuperAdmin === false) {
      let url = `${apiUrl}/odata/OrgAppMasterList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`;
      if (searchAppName || searchId || searchLatestVersion || searchCreatedDateTime || searchIsActive || searchorganizationCount || searchAssignedTimeStamp) {
        url += `&$filter=`;
        const filterArray = [];
        if (searchorganizationCount) {
          if (searchorganizationCount.toLowerCase() === 'n/a') {
            filterArray.push(`organizationCount eq null`);
          } else {
            // filterArray.push(`contains(tolower(organizationCount),tolower('${searchorganizationCount}'))`);
            filterArray.push(`organizationCount eq ${encodeURIComponent(searchorganizationCount)}`);
          }
        }
        if (searchAppName) {
          if (searchAppName.toLowerCase() === 'n/a') {
            filterArray.push('AppName eq null');
          } else {

            filterArray.push(`contains(tolower(AppName),tolower('${encodeURIComponent(searchAppName)}'))`);
          }
        }
        if (searchId) {
          if (searchId.toLowerCase() === 'n/a') {
            filterArray.push('Id eq null');
          } else {
            filterArray.push(`contains(tolower(Id),tolower('${encodeURIComponent(searchId)}'))`);
          }
        }
        if (searchLatestVersion) {
          if (searchLatestVersion.toLowerCase() === 'n/a') {
            filterArray.push('LatestVersion eq null');
          } else {
            filterArray.push(`contains(tolower(LatestVersion),tolower('${encodeURIComponent(searchLatestVersion)}'))`);
          }
        }
        if (searchIsActive) {
          if (searchIsActive.toLowerCase() === 'n/a') {
            filterArray.push('IsActive eq null');
          } else {
            filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
          }
        }
        if (searchCreatedDateTime) {
          if (searchCreatedDateTime.toLowerCase() === 'n/a') {
            filterArray.push('CreatedDateTime eq null');
          } else {
            filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchCreatedDateTime)}`);
          }
        }
        if (searchAssignedTimeStamp) {
          if (searchAssignedTimeStamp.toLowerCase() === 'n/a') {
            filterArray.push('AssignedTimeStamp eq null');
          } else {
            filterArray.push(`AssignedTimeStamp eq ${encodeURIComponent(searchAssignedTimeStamp)}`);
          }
        }
        // console.log("filterArray", filterArray);
        let searchTerm = "";
        if (filterArray.length > 0) {
          searchTerm = filterArray.join(' and ');
        }
        // console.log("searchterm:", searchTerm);
        if (searchTerm.length > 0)
          url += `${searchTerm}`;
        // console.log(url);
      }
      return requests.getWithToken(url, accessToken);
    }
    else if (isSuperAdmin === true) {
      let url = `${apiUrl}/odata/AppMasterList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`;
      if (AssignedTimeStamp || CreatedDateTime || IsActive || LatestVersion || Id || AppName || organizationCount || searchAppName || searchId || searchLatestVersion || searchCreatedDateTime || searchIsActive || searchorganizationCount || searchAssignedTimeStamp) {
        url += `&$filter=`;
        const filterArray = [];
        if (searchorganizationCount || organizationCount) {
          console.log("searchorganizationCount:", searchorganizationCount);
          if (searchorganizationCount?.toLowerCase() === 'n/a' || organizationCount?.toLowerCase() === 'n/a') {
            filterArray.push('organizationCount eq null');
          }
          else if (organizationCount) {
            filterArray.push(`organizationCount eq ${encodeURIComponent(organizationCount)}`);
          }
          else {
            filterArray.push(`organizationCount eq ${encodeURIComponent(searchorganizationCount)}`);
          }
        }
        if (searchAssignedTimeStamp || AssignedTimeStamp) {
          if (searchAssignedTimeStamp?.toLowerCase() === 'n/a' || AssignedTimeStamp?.toLowerCase() === 'n/a') {
            filterArray.push('AssignedTimeStamp eq null');
          }
          else if (AssignedTimeStamp) {
            filterArray.push(`AssignedTimeStamp eq ${encodeURIComponent(AssignedTimeStamp)}`);
          }
          else {
            filterArray.push(`AssignedTimeStamp eq ${encodeURIComponent(searchAssignedTimeStamp)}`);
          }
        }
        if (searchAppName || AppName) {
          if (searchAppName?.toLowerCase() === 'n/a' || AppName?.toLowerCase() === 'n/a') {
            filterArray.push('AppName eq null');
          }
          else if (AppName) {
            filterArray.push(`contains(tolower(AppName),tolower('${encodeURIComponent(AppName)}'))`);
          }
          else {
            filterArray.push(`contains(tolower(AppName),tolower('${encodeURIComponent(searchAppName)}'))`);
          }
        }
        if (searchId || Id) {
          if (searchId?.toLowerCase() === 'n/a' || Id?.toLowerCase() === 'n/a') {
            filterArray.push('Id eq null');
          }
          else if (Id) {
            filterArray.push(`contains(tolower(Id),tolower('${encodeURIComponent(Id)}'))`);
          }
          else {
            filterArray.push(`contains(tolower(Id),tolower('${encodeURIComponent(searchId)}'))`);
          }
        }
        if (searchLatestVersion || LatestVersion) {
          if (searchLatestVersion?.toLowerCase() === 'n/a' || LatestVersion?.toLowerCase() === 'n/a') {
            filterArray.push('LatestVersion eq null');
          }
          else if (LatestVersion) {
            filterArray.push(`contains(tolower(LatestVersion),tolower('${encodeURIComponent(LatestVersion)}'))`);
          }
          else {
            filterArray.push(`contains(tolower(LatestVersion),tolower('${encodeURIComponent(searchLatestVersion)}'))`);
          }
        }
        if (searchIsActive || IsActive) {
          if (searchIsActive?.toLowerCase() === 'n/a' || IsActive?.toLowerCase() === 'n/a') {
            filterArray.push('IsActive eq null');
          }
          else if (IsActive) {
            filterArray.push(`IsActive eq ${encodeURIComponent(IsActive)}`);
          }
          else {
            filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
          }
        }
        if (searchCreatedDateTime || CreatedDateTime) {
          if (searchCreatedDateTime.toLowerCase() === 'n/a') {
            filterArray.push('CreatedDateTime eq null');
          }
          else if (CreatedDateTime) {
            filterArray.push(`CreatedDateTime eq ${encodeURIComponent(CreatedDateTime)}`);
          }
          else {
            filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchCreatedDateTime)}`);
          }
        }
        // console.log("filterArray", filterArray);
        let searchTerm = "";
        if (filterArray.length > 0) {
          searchTerm = filterArray.join(' and ');
        }
        // console.log("searchterm:", searchTerm);
        if (searchTerm.length > 0)
          url += `${searchTerm}`;
        // console.log(url);
      }
      return requests.getWithToken(url, accessToken);
    }

  },
  getAppMasterDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/AppMaster/Details?AppMasterId=${id}`, accessToken);
  },
  createAppMaster: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/AppMaster/Create`, data, accessToken);
  },
  editAppMaster: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/AppMaster/Update`, data, accessToken);
  },

}

const OrgUser = {
  getOrgUserExportlist: (accessToken, AppId, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, filter) => {

    let url = `${apiUrl}/OrgUserList/Export?AppId=${AppId}&count=true&$orderby=displayName asc`;
    const { IsPortalEnable, IsGlobalReadOnlyUser, EnableMailNotification, IsEmployee } = filter || {};
    if (IsEmployee || searchUserEmail || searchCreatedBy || searchCreatedDateTime || searchIsPortalEnable || IsPortalEnable || IsGlobalReadOnlyUser || EnableMailNotification) {
      url += `&$filter=`;
      const filterArray = [];

      if (searchUserEmail) {
        if (searchUserEmail.toLowerCase() === 'n/a') {
          filterArray.push(`UserEmail eq null`);
        } else {
          const nameFilter = `(contains(tolower(DisplayName), tolower('${encodeURIComponent(searchUserEmail)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchUserEmail)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedDateTime) {
        if (searchCreatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('CreatedDateTime eq null');
        } else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchCreatedDateTime)}`);
        }
      }
      if (searchIsPortalEnable) {
        if (searchIsPortalEnable.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsPortalEnable)}`);
        }
      }
      if (IsPortalEnable) {
        if (IsPortalEnable?.toLowerCase() === 'n/a') {
          filterArray.push('IsPortalEnable eq null');
        }
        else {
          filterArray.push(`IsPortalEnable eq ${IsPortalEnable}`);
        }
      }
      if (IsGlobalReadOnlyUser) {
        if (IsGlobalReadOnlyUser?.toLowerCase() === 'n/a') {
          filterArray.push('IsGlobalReadOnlyUser eq null');
        }
        else {
          filterArray.push(`IsGlobalReadOnlyUser eq ${IsGlobalReadOnlyUser}`);
        }
      }
      if (IsEmployee) {
        if (IsEmployee?.toLowerCase() === 'n/a') {
          filterArray.push('IsEmployee eq null');
        }
        else {
          filterArray.push(`IsEmployee eq ${IsEmployee}`);
        }
      }
      if (EnableMailNotification) {
        if (EnableMailNotification?.toLowerCase() === 'n/a') {
          filterArray.push('EnableMailNotification eq null');
        }
        else {
          filterArray.push(`EnableMailNotification eq ${EnableMailNotification}`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },
  getOrgUserlist: (accessToken, AppId, filterData, currentPage, PageSize = 15, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("AppId:", AppId);

    const { UserEmail, CreatedBy, CreatedDateTime, IsActive, IsPortalEnable, IsGlobalReadOnlyUser, EnableMailNotification, IsEmployee } = filterData || {};
    let url = `${apiUrl}/odata/OrgUserList?AppId=${AppId}&count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`;

    if (IsEmployee || UserEmail || CreatedBy || CreatedDateTime || IsActive || IsPortalEnable || IsGlobalReadOnlyUser || EnableMailNotification || searchUserEmail || searchCreatedBy || searchCreatedDateTime || searchIsPortalEnable) {
      url += `&$filter=`;
      const filterArray = [];

      if (searchUserEmail || UserEmail) {
        if (searchUserEmail?.toLowerCase() === 'n/a' || UserEmail?.toLowerCase() === 'n/a') {
          filterArray.push(`UserEmail eq null`);
        }
        else if (UserEmail) {
          const nameFilter = `(contains(tolower(DisplayName), tolower('${encodeURIComponent(UserEmail)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(UserEmail)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(DisplayName), tolower('${encodeURIComponent(searchUserEmail)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchUserEmail)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchCreatedBy || CreatedBy) {
        if (searchCreatedBy?.toLowerCase() === 'n/a' || CreatedBy?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        }
        else if (CreatedBy) {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(CreatedBy)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedDateTime || CreatedDateTime) {
        if (searchCreatedDateTime?.toLowerCase() === 'n/a' || CreatedDateTime?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedDateTime eq null');
        }
        else if (CreatedDateTime) {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(CreatedDateTime)}`);
        }
        else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchCreatedDateTime)}`);
        }
      }
      if (searchIsPortalEnable || IsActive) {
        if (searchIsPortalEnable?.toLowerCase() === 'n/a' || IsActive?.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        }
        else if (IsActive) {
          filterArray.push(`IsActive eq ${encodeURIComponent(IsActive)}`);
        }
        else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsPortalEnable)}`);
        }
      }
      if (IsPortalEnable) {
        if (IsPortalEnable?.toLowerCase() === 'n/a') {
          filterArray.push('IsPortalEnable eq null');
        }
        else {
          filterArray.push(`IsPortalEnable eq ${IsPortalEnable}`);
        }
      }
      if (IsGlobalReadOnlyUser) {
        if (IsGlobalReadOnlyUser?.toLowerCase() === 'n/a') {
          filterArray.push('IsGlobalReadOnlyUser eq null');
        }
        else {
          filterArray.push(`IsGlobalReadOnlyUser eq ${IsGlobalReadOnlyUser}`);
        }
      }
      if (IsEmployee) {
        if (IsEmployee?.toLowerCase() === 'n/a') {
          filterArray.push('IsEmployee eq null');
        }
        else {
          filterArray.push(`IsEmployee eq ${IsEmployee}`);
        }
      }
      if (EnableMailNotification) {
        if (EnableMailNotification?.toLowerCase() === 'n/a') {
          filterArray.push('EnableMailNotification eq null');
        }
        else {
          filterArray.push(`EnableMailNotification eq ${EnableMailNotification}`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },
  getOrgUserDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/OrgUser/Details?OrgUserId=${id}`, accessToken);
  },
  getOrgNameList: (accessToken, searchValue) => {
    return requests.getWithToken(`${apiUrl}/odata/OrgMasterList?count=true&$filter=contains(tolower(OrgName), tolower('${encodeURIComponent(searchValue)}'))`, accessToken)
  },
  createOrgUser: (accessToken, data, AppId) => {
    return requests.postWithToken(`${apiUrl}/api/OrgUser/Create?AppId=${AppId}`, data, accessToken);
  },
  editOrgUser: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/OrgUser/Update`, data, accessToken);
  },
  sendResetPasswordEmail: (email) => {
    return requests.postText(`${apiUrl}/api/Auth/ForgotPassword`, email);
  },
  changePasswordByAdmin: (token, email) => {
    return requests.postWithToken(`${apiUrl}/api/Auth/ChangePasswordByAdmin`, email, token);
  }
}

const OrgLogo = {
  getOrgLogo: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/OrgMaster/GetOrgLogo?OrgId=${id}`, accessToken);
  },
  editOrgLogo: (accessToken, data) => {
    // const formData = new FormData();
    // formData.append('orgPortalName', data.orgPortalName);
    // formData.append('orgId', data.orgId);
    // formData.append('orgLogo', data.logo);
    const Data = { ...data, orgLogo: data.logo };
    return requests.postWithToken(`${apiUrl}/api/OrgMaster/ChangeLogo`, Data, accessToken);
  },
}
const Groups = {
  getGroupExportlist: (accessToken, searchGroupName, searchGroupEmail, searchGroupOwnerEmail) => {

    let url = `${apiUrl}/OrgGroupList/Export?count=true&$orderby=GroupName asc`;

    if (searchGroupName || searchGroupEmail || searchGroupOwnerEmail) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchGroupName) {
        if (searchGroupName.toLowerCase() === 'n/a') {
          filterArray.push('GroupName eq null');
        } else {
          filterArray.push(`contains(tolower(GroupName),tolower('${encodeURIComponent(searchGroupName)}'))`);
        }
      }
      if (searchGroupEmail) {
        if (searchGroupEmail.toLowerCase() === 'n/a') {
          filterArray.push('GroupEmail eq null');
        } else {
          filterArray.push(`contains(tolower(GroupEmail),tolower('${encodeURIComponent(searchGroupEmail)}'))`);
        }
      }
      if (searchGroupOwnerEmail) {
        if (searchGroupOwnerEmail.toLowerCase() === 'n/a') {
          filterArray.push('GroupOwnerEmail eq null');
        } else {
          filterArray.push(`contains(tolower(GroupOwnerEmail),tolower('${encodeURIComponent(searchGroupOwnerEmail)}'))`);
        }
      }

      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },
  getGrouplist: (accessToken, appId, filterData, currentPage, PageSize = 15, searchGroupName, searchGroupEmail, searchGroupOwnerEmail) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/OrgGroupList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;
    const { GroupName, GroupEmail, GroupOwnerEmail } = filterData || {};
    if (GroupName || GroupEmail || GroupOwnerEmail || searchGroupName || searchGroupEmail || searchGroupOwnerEmail) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchGroupName || GroupName) {
        if (searchGroupName?.toLowerCase() === 'n/a' || GroupName?.toLowerCase() === 'n/a') {
          filterArray.push('GroupName eq null');
        }
        else if (GroupName) {
          filterArray.push(`contains(tolower(GroupName),tolower('${encodeURIComponent(GroupName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(GroupName),tolower('${encodeURIComponent(searchGroupName)}'))`);
        }
      }
      if (searchGroupEmail || GroupEmail) {
        if (searchGroupEmail?.toLowerCase() === 'n/a' || GroupEmail?.toLowerCase() === 'n/a') {
          filterArray.push('GroupEmail eq null');
        }
        else if (GroupEmail) {
          filterArray.push(`contains(tolower(GroupEmail),tolower('${encodeURIComponent(GroupEmail)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(GroupEmail),tolower('${encodeURIComponent(searchGroupEmail)}'))`);
        }
      }
      if (searchGroupOwnerEmail || GroupOwnerEmail) {
        if (searchGroupOwnerEmail?.toLowerCase() === 'n/a' || GroupOwnerEmail?.toLowerCase() === 'n/a') {
          filterArray.push('GroupOwnerEmail eq null');
        }
        else if (GroupOwnerEmail) {
          filterArray.push(`contains(tolower(GroupOwnerEmail),tolower('${encodeURIComponent(GroupOwnerEmail)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(GroupOwnerEmail),tolower('${encodeURIComponent(searchGroupOwnerEmail)}'))`);
        }
      }

      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },
  getGroupDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/OrgGroup/Details?OrgGroupId=${id}`, accessToken);
  },
  getRoleName: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/RoleList?count=true`;
    if (searchValue) {
      url = `${apiUrl}/odata/RoleList?count=true&$filter=contains(tolower(RoleName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getAppName: (accessToken, Role, searchValue) => {
    let url = `${apiUrl}/odata/OrgAppMasterList?count=true&$select=Id,IsActive,AppName`;
    if (searchValue) {
      url += `&$filter=contains(tolower(AppName),tolower('${searchValue}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getOrgUserName: (accessToken, appId, searchValue) => {
    let url = `${apiUrl}/odata/OrgUserList?AppId=${appId}&count=true&$select=Id,DisplayName,UserEmail,IsGlobalReadOnlyUser`;
    if (searchValue) {
      url = `${apiUrl}/odata/OrgUserList?AppId=${appId}&count=true&$select=Id,DisplayName,UserEmail,IsGlobalReadOnlyUser&$filter=contains(tolower(DisplayName),tolower('${searchValue}')) or contains(tolower(UserEmail),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getUserName: (accessToken, appId, searchValue) => {
    let url = `${apiUrl}/odata/OrgUserList?AppId=${appId}&UserType=Employee&count=true&$select=Id,DisplayName,UserEmail,IsGlobalReadOnlyUser`;
    if (searchValue) {
      url = `${apiUrl}/odata/OrgUserList?AppId=${appId}&UserType=Employee&count=true&$select=Id,DisplayName,UserEmail,IsGlobalReadOnlyUser&$filter=contains(tolower(DisplayName),tolower('${searchValue}')) or contains(tolower(UserEmail),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  createGroup: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/OrgGroup/Create`, data, accessToken);
  },
  editGroup: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/OrgGroup/Update`, data, accessToken);
  },

}
const AdminUser = {
  getAdminUserlist: (accessToken, currentPage, PageSize = 15, searchOrgName, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, searchRoleDetails) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/AdminUserList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;
    if (searchOrgName || searchUserEmail || searchCreatedBy || searchCreatedDateTime || searchIsPortalEnable || searchRoleDetails) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchOrgName) {
        if (searchOrgName.toLowerCase() === 'n/a') {
          filterArray.push('OrgName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(OrgName),tolower('${encodeURIComponent(searchOrgName)}'))`);
        }
      }
      if (searchUserEmail) {
        if (searchUserEmail.toLowerCase() === 'n/a') {
          filterArray.push('UserEmail eq \'\'');
        } else {
          filterArray.push(`contains(tolower(UserEmail),tolower('${encodeURIComponent(searchUserEmail)}'))`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedDateTime) {
        if (searchCreatedDateTime.toLowerCase() === 'n/a') {
          filterArray.push('CreatedDateTime eq \'\'');
        } else {
          filterArray.push(`CreatedDateTime eq ${encodeURIComponent(searchCreatedDateTime)}`);
        }
      }
      if (searchIsPortalEnable) {
        if (searchIsPortalEnable.toLowerCase() === 'n/a') {
          filterArray.push('IsPortalEnable eq null');
        } else {
          filterArray.push(`IsPortalEnable eq ${encodeURIComponent(searchIsPortalEnable)}`);
        }
      }
      if (searchRoleDetails) {
        if (searchRoleDetails.toLowerCase() === 'n/a') {
          filterArray.push('IsPortalEnable eq null');
        } else {
          filterArray.push(`RoleDetails/any(ai:ai/RoleName eq '${encodeURIComponent(searchRoleDetails)}')`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },
  createAdminUser: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/OrgUser/Create`, data, accessToken);
  },
}

const Role = {
  getRolelist: (accessToken, currentPage, PageSize = 15, searchRoleName) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/RoleList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;
    if (searchRoleName) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchRoleName) {
        if (searchRoleName.toLowerCase() === 'n/a') {
          filterArray.push('RoleName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(RoleName),tolower('${encodeURIComponent(searchRoleName)}'))`);
        }
      }

      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },
}

const Employee = {
  getUserEmployeelist: (accessToken) => {
    return requests.getWithToken(`${apiUrl}/odata/EmployeeList?count=true`, accessToken);
  },
  getUserIdEmployeelist: (accessToken, appId, User) => {
    return requests.getWithToken(`${apiUrl}/api/Employee/EmployeeValidate?AppId=${appId}&Email=${encodeURIComponent(User)}`, accessToken);
  },

  getEmployeeIDlist: (accessToken, appId, EmpId, ID) => {
    if (ID) {
      return requests.getWithToken(`${apiUrl}/api/Employee/EmployeeValidate?AppId=${appId}&EmployeeId=${EmpId}&Id=${ID}`, accessToken);
    } else {
      return requests.getWithToken(`${apiUrl}/api/Employee/EmployeeValidate?AppId=${appId}&EmployeeId=${EmpId}`, accessToken);
    }
  },
  getIsManagerEmployeelist: (accessToken, appId, Id) => {
    return requests.getWithToken(`${apiUrl}/api/Employee/EmployeeValidate?AppId=${appId}&ManagerId=${Id}`, accessToken);
  },

  getEmployeeExportlist: (accessToken, searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchEnableMailNotification, searchIsUserActive) => {

    let url = `${apiUrl}/EmployeeList/Export?count=true&$orderby=userName asc`;
    if (searchIsUserActive || searchIsPortalEnable || searchIsPeopleManager || searchEmployeeDisplayName || searchEmployeeType || searchEmployeeTitle || searchDateOfBirth || searchDateOfJoin || searchIsTerminated || searchManagerUserEmail || searchUserEmail || searchIsRehired || searchEnableMailNotification) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchEmployeeDisplayName) {
        if (searchEmployeeDisplayName.toLowerCase() === 'n/a') {
          filterArray.push(`EmployeeDisplayName eq null`);
        } else {
          const nameFilter = `(contains(tolower(EmployeeDisplayName), tolower('${encodeURIComponent(searchEmployeeDisplayName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchEmployeeDisplayName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchIsUserActive) {
        if (searchIsUserActive?.toLowerCase() === 'n/a') {
          filterArray.push('isUserActive eq null');
        }
        else {
          filterArray.push(`isUserActive eq ${encodeURIComponent(searchIsUserActive)}`);
        }
      }
      if (searchIsPortalEnable) {
        if (searchIsPortalEnable?.toLowerCase() === 'n/a') {
          filterArray.push('IsPortalEnable eq null');
        }
        else {
          filterArray.push(`IsPortalEnable eq ${encodeURIComponent(searchIsPortalEnable)}`);
        }
      }
      if (searchIsPeopleManager) {
        if (searchIsPeopleManager?.toLowerCase() === 'n/a') {
          filterArray.push('IsPeopleManager eq null');
        }
        else {
          filterArray.push(`IsPeopleManager eq ${encodeURIComponent(searchIsPeopleManager)}`);
        }
      }
      if (searchIsRehired) {
        if (searchIsRehired?.toLowerCase() === 'n/a') {
          filterArray.push('IsRehired eq null');
        }
        else {
          filterArray.push(`IsRehired eq ${encodeURIComponent(searchIsRehired)}`);
        }
      }
      if (searchEmployeeType) {
        if (searchEmployeeType.toLowerCase() === 'n/a') {
          filterArray.push('EmployeeType eq null');
        } else {
          filterArray.push(`contains(tolower(EmployeeType),tolower('${encodeURIComponent(searchEmployeeType)}'))`);
        }
      }
      if (searchEmployeeTitle) {
        if (searchEmployeeTitle.toLowerCase() === 'n/a') {
          filterArray.push('EmployeeTitle eq null');
        } else {
          filterArray.push(`contains(tolower(EmployeeTitle),tolower('${encodeURIComponent(searchEmployeeTitle)}'))`);
        }
      }
      if (searchDateOfBirth) {
        if (searchDateOfBirth.toLowerCase() === 'n/a') {
          filterArray.push('DateOfBirth eq null');
        } else {
          filterArray.push(`DateOfBirth eq ${encodeURIComponent(searchDateOfBirth)}`);
        }
      }
      if (searchDateOfJoin) {
        if (searchDateOfJoin.toLowerCase() === 'n/a') {
          filterArray.push('DateOfJoin eq null');
        } else {
          filterArray.push(`DateOfJoin eq ${encodeURIComponent(searchDateOfJoin)}`);
        }
      }
      if (searchIsTerminated) {
        if (searchIsTerminated.toLowerCase() === 'n/a') {
          filterArray.push('IsTerminated eq null');
        } else {
          filterArray.push(`IsTerminated eq ${encodeURIComponent(searchIsTerminated)}`);
        }
      }
      if (searchManagerUserEmail) {
        if (searchManagerUserEmail.toLowerCase() === 'n/a') {
          filterArray.push('ManagerUserEmail eq null');
        } else {
          filterArray.push(`contains(tolower(ManagerUserEmail),tolower('${encodeURIComponent(searchManagerUserEmail)}'))`);
        }
      }
      if (searchUserEmail) {
        if (searchUserEmail.toLowerCase() === 'n/a') {
          filterArray.push('UserEmail eq null');
        } else {
          filterArray.push(`contains(tolower(UserEmail),tolower('${encodeURIComponent(searchUserEmail)}'))`);
        }
      }
      if (searchEnableMailNotification) {
        if (searchEnableMailNotification.toLowerCase() === 'n/a') {
          filterArray.push('EnableMailNotification eq null');
        } else {
          filterArray.push(`EnableMailNotification eq ${searchEnableMailNotification}`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },
  getEmployeelist: (accessToken, filteredData, currentPage, PageSize = 15, searchEmployeeDisplayName, searchEmployeeType, searchEmployeeTitle, searchDateOfBirth, searchDateOfJoin, searchIsTerminated, searchManagerUserEmail, searchUserEmail, searchIsRehired, searchIsPeopleManager, searchIsPortalEnable, searchIsUserActive) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { IsPortalEnable, IsPeopleManager, IsRehired, EmployeeDisplayName, EmployeeType, EmployeeTitle, DateOfBirth, DateOfJoin, IsTerminated, ManagerUserEmail, UserEmail, EnableMailNotification } = filteredData || {};
    let url = `${apiUrl}/odata/EmployeeList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedDateTime desc`;
    if (searchIsUserActive || IsPortalEnable || IsPeopleManager || IsRehired || UserEmail || ManagerUserEmail || IsTerminated || DateOfJoin || DateOfBirth || EmployeeDisplayName || EmployeeType || EmployeeTitle || EnableMailNotification || searchEmployeeDisplayName || searchEmployeeType || searchEmployeeTitle || searchDateOfBirth || searchDateOfJoin || searchIsTerminated || searchManagerUserEmail || searchUserEmail || searchIsRehired || searchIsPeopleManager || searchIsPortalEnable) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchEmployeeDisplayName || EmployeeDisplayName) {
        if (searchEmployeeDisplayName?.toLowerCase() === 'n/a' || EmployeeDisplayName?.toLowerCase() === 'n/a') {
          filterArray.push(`EmployeeDisplayName eq null`);
        }
        else if (EmployeeDisplayName) {
          const nameFilter = `(contains(tolower(EmployeeDisplayName), tolower('${encodeURIComponent(EmployeeDisplayName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(EmployeeDisplayName)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(EmployeeDisplayName), tolower('${encodeURIComponent(searchEmployeeDisplayName)}')) or contains(tolower(UserEmail), tolower('${encodeURIComponent(searchEmployeeDisplayName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchEmployeeType || EmployeeType) {
        if (searchEmployeeType?.toLowerCase() === 'n/a' || EmployeeType?.toLowerCase() === 'n/a') {
          filterArray.push('EmployeeType eq null');
        }
        else if (EmployeeType) {
          filterArray.push(`contains(tolower(EmployeeType),tolower('${encodeURIComponent(EmployeeType)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(EmployeeType),tolower('${encodeURIComponent(searchEmployeeType)}'))`);
        }
      }
      if (searchEmployeeTitle || EmployeeTitle) {
        if (searchEmployeeTitle?.toLowerCase() === 'n/a' || EmployeeTitle?.toLowerCase() === 'n/a') {
          filterArray.push('EmployeeTitle eq null');
        }
        else if (EmployeeTitle) {
          filterArray.push(`contains(tolower(EmployeeTitle),tolower('${encodeURIComponent(EmployeeTitle)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(EmployeeTitle),tolower('${encodeURIComponent(searchEmployeeTitle)}'))`);
        }
      }
      if (searchDateOfBirth || DateOfBirth) {
        if (searchDateOfBirth?.toLowerCase() === 'n/a' || DateOfBirth?.toLowerCase() === 'n/a') {
          filterArray.push('DateOfBirth eq null');
        }
        else if (DateOfBirth) {
          filterArray.push(`DateOfBirth eq ${encodeURIComponent(DateOfBirth)}`);
        }
        else {
          filterArray.push(`DateOfBirth eq ${encodeURIComponent(searchDateOfBirth)}`);
        }
      }
      if (searchDateOfJoin || DateOfJoin) {
        if (searchDateOfJoin?.toLowerCase() === 'n/a' || DateOfJoin?.toLowerCase() === 'n/a') {
          filterArray.push('DateOfJoin eq null');
        }
        else if (DateOfJoin) {
          filterArray.push(`DateOfJoin eq ${encodeURIComponent(DateOfJoin)}`);
        }
        else {
          filterArray.push(`DateOfJoin eq ${encodeURIComponent(searchDateOfJoin)}`);
        }
      }
      if (searchIsTerminated || IsTerminated) {
        if (searchIsTerminated?.toLowerCase() === 'n/a' || IsTerminated?.toLowerCase() === 'n/a') {
          filterArray.push('IsTerminated eq null');
        }
        else if (IsTerminated) {
          filterArray.push(`IsTerminated eq ${encodeURIComponent(IsTerminated)}`);
        }
        else {
          filterArray.push(`IsTerminated eq ${encodeURIComponent(searchIsTerminated)}`);
        }
      }
      if (searchIsRehired || IsRehired) {
        if (searchIsRehired?.toLowerCase() === 'n/a' || IsRehired?.toLowerCase() === 'n/a') {
          filterArray.push('IsRehired eq null');
        }
        else if (IsTerminated) {
          filterArray.push(`IsRehired eq ${encodeURIComponent(IsRehired)}`);
        }
        else {
          filterArray.push(`IsRehired eq ${encodeURIComponent(searchIsRehired)}`);
        }
      }
      if (searchIsUserActive) {
        if (searchIsUserActive?.toLowerCase() === 'n/a') {
          filterArray.push('IsUserActive eq null');
        }
        else {
          filterArray.push(`IsUserActive eq ${encodeURIComponent(searchIsUserActive)}`);
        }
      }
      if (searchIsPeopleManager || IsPeopleManager) {
        if (searchIsPeopleManager?.toLowerCase() === 'n/a' || IsPeopleManager?.toLowerCase() === 'n/a') {
          filterArray.push('IsPeopleManager eq null');
        }
        else if (IsPeopleManager) {
          filterArray.push(`IsPeopleManager eq ${encodeURIComponent(IsPeopleManager)}`);
        }
        else {
          filterArray.push(`IsPeopleManager eq ${encodeURIComponent(searchIsPeopleManager)}`);
        }
      }
      if (searchIsPortalEnable || IsPortalEnable) {
        if (searchIsPortalEnable?.toLowerCase() === 'n/a' || IsPortalEnable?.toLowerCase() === 'n/a') {
          filterArray.push('IsPortalEnable eq null');
        }
        else if (IsPortalEnable) {
          filterArray.push(`IsPortalEnable eq ${encodeURIComponent(IsPortalEnable)}`);
        }
        else {
          filterArray.push(`IsPortalEnable eq ${encodeURIComponent(searchIsPortalEnable)}`);
        }
      }
      if (EnableMailNotification) {
        if (EnableMailNotification?.toLowerCase() === 'n/a') {
          filterArray.push('EnableMailNotification eq null');
        }
        else {
          filterArray.push(`EnableMailNotification eq ${EnableMailNotification}`);
        }
      }
      if (searchManagerUserEmail || ManagerUserEmail) {
        if (searchManagerUserEmail?.toLowerCase() === 'n/a' || ManagerUserEmail?.toLowerCase() === 'n/a') {
          filterArray.push('ManagerUserEmail eq null');
        }
        else if (ManagerUserEmail) {
          filterArray.push(`contains(tolower(ManagerUserEmail),tolower('${encodeURIComponent(ManagerUserEmail)}')) or contains(tolower(ManagerUserName),tolower('${encodeURIComponent(ManagerUserEmail)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(ManagerUserEmail),tolower('${encodeURIComponent(searchManagerUserEmail)}')) or contains(tolower(ManagerUserName),tolower('${encodeURIComponent(searchManagerUserEmail)}'))`);
        }
      }
      if (searchUserEmail) {
        if (searchUserEmail?.toLowerCase() === 'n/a' || UserEmail?.toLowerCase() === 'n/a') {
          filterArray.push('UserEmail eq null');
        }
        else if (UserEmail) {
          filterArray.push(`contains(tolower(UserEmail),tolower('${encodeURIComponent(UserEmail)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(UserEmail),tolower('${encodeURIComponent(searchUserEmail)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);

    }
    return requests.getWithToken(url, accessToken);
  },

  getGroupDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/Employee/Details?EmployeeId=${id}`, accessToken);
  },
  getUserNameList: (accessToken, appId, searchValue) => {
    let url = `${apiUrl}/odata/OrgUserList?AppId=${appId}&IsActiveUser=true&UserType=nonEmployee&count=true&$select=Id,IsActive,IsGlobalReadOnlyUser,DisplayName,UserEmail,FirstName,LastName,EnableMailNotification`;
    if (searchValue) {
      url = `${apiUrl}/odata/OrgUserList?AppId=${appId}&IsActiveUser=true&UserType=nonEmployee&count=true&$filter=contains(tolower(DisplayName),tolower('${encodeURIComponent(searchValue)}'))&$select=Id,IsActive,IsGlobalReadOnlyUser,DisplayName,UserEmail,FirstName,LastName`;
    }
    return requests.getWithToken(url, accessToken);
    // return requests.getWithToken(`${apiUrl}/odata/OrgUserList?count=true&$filter=contains(tolower(DisplayName),tolower('${searchValue}'))`, accessToken);
  },
  getManagerNameList: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/ManagersUserList?count=true&$select=Id,DisplayName,UserEmail`;
    if (searchValue) {
      url = `${apiUrl}/odata/ManagersUserList?count=true&$filter=contains(tolower(DisplayName),tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(UserEmail),tolower('${encodeURIComponent(searchValue)}')) `;
    }
    return requests.getWithToken(url, accessToken);
    // return requests.getWithToken(`${apiUrl}/odata/ManagersUserList?count=true&$filter=contains(tolower(DisplayName),tolower('${searchValue}'))`, accessToken);
  },

  createEmployee: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Employee/Create`, data, accessToken);
  },
  editEmployee: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Employee/Update`, data, accessToken);
  },
}

const ProjectTracker = {
  getTaskScopeList: (accessToken) => {
    let url = `${apiUrl}/odata/ProjectTaskScopeList?count=true&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  getTaskTypeList: (accessToken) => {
    let url = `${apiUrl}/odata/ProjectTaskTypeList?count=true&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },

  createTaskType: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/ProjectSettings/UpdateTaskType`, data, accessToken);
  },
  updateTaskType: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/ProjectSettings/UpdateTaskType`, data, accessToken);
  },
  createTaskScope: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/ProjectSettings/UpdateTaskScope`, data, accessToken);
  },
  updateTaskPercentageStatus: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/ProjectSettings/UpdateTaskStatus`, data, accessToken);
  },
  updateTaskScope: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/ProjectSettings/UpdateTaskScope`, data, accessToken);
  },
  getGanttProjectList: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/odata/GanttChartProjectList?$filter=id eq '${id}'`, accessToken);
  },

  getGanttMilestoneUpdate: (accessToken, taskData) => {
    return requests.postWithToken(`${apiUrl}/api/Project/GanttChartMilestoneUpdate`, taskData, accessToken);
  },

  getProjectExportList: (accessToken, searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName) => {
    let url = `${apiUrl}/OrgProjectList/Export?count=true&$orderby=projectName asc`;
    if (searchProjectName || searchDescription || searchComments || searchStartTimeStamp || searchEndTimeStamp || searchIsProjectActive || searchProjectManagerUserName) {
      url += `&$filter=`;
      const filterArray = [];

      if (searchProjectName) {
        if (searchProjectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchProjectName)}'))`);
        }
      }
      if (searchProjectManagerUserName) {
        if (searchProjectManagerUserName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectManagerUserName eq null');
        } else {
          const nameFilter = `(contains(tolower(ProjectManagerUserName), tolower('${encodeURIComponent(searchProjectManagerUserName)}')) or contains(tolower(ProjectManagerUserEmail), tolower('${encodeURIComponent(searchProjectManagerUserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchDescription) {
        if (searchDescription.toLowerCase() === 'n/a') {
          filterArray.push('Description eq null');
        } else {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(searchDescription)}'))`);
        }
      }
      if (searchComments) {
        if (searchComments.toLowerCase() === 'n/a') {
          filterArray.push('Comments eq null');
        } else {
          filterArray.push(`contains(tolower(Comments),tolower('${encodeURIComponent(searchComments)}'))`);
        }
      }
      if (searchStartTimeStamp && searchEndTimeStamp) {

        const startDateFilter = `StartDate ge ${encodeURIComponent(searchStartTimeStamp)} and StartDate le ${encodeURIComponent(searchEndTimeStamp)}`;
        const endDateFilter = `EndDate ge ${encodeURIComponent(searchStartTimeStamp)} and EndDate le ${encodeURIComponent(searchEndTimeStamp)}`;
        const spanRangeFilter = `StartDate le ${encodeURIComponent(searchStartTimeStamp)} and EndDate ge ${encodeURIComponent(searchEndTimeStamp)}`;
        const combinedDateFilter = `(${startDateFilter} or ${endDateFilter} or ${spanRangeFilter})`.trim();
        filterArray.push(combinedDateFilter);
      }
      else if (searchStartTimeStamp) {
        if (searchStartTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('startDate eq null');
        } else {
          filterArray.push(`startDate eq ${encodeURIComponent(searchStartTimeStamp)}`);
        }
      }
      else if (searchEndTimeStamp) {
        if (searchEndTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('endDate eq null');
        } else {
          filterArray.push(`endDate eq ${encodeURIComponent(searchEndTimeStamp)}`);
        }
      }
      if (searchIsProjectActive) {
        if (searchIsProjectActive.toLowerCase() === 'n/a') {
          filterArray.push('IsProjectActive eq null');
        } else {
          filterArray.push(`IsProjectActive eq ${encodeURIComponent(searchIsProjectActive)}`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getProjectList: (accessToken, filterData, currentPage, PageSize = 15, searchProjectName, searchDescription, searchComments, searchStartTimeStamp, searchEndTimeStamp, searchIsProjectActive, searchProjectManagerUserName) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filterData:", filterData);

    const { ProjectName, ProjectManagerUserName, Description, Comments, StartDate, EndDate, IsProjectActive } = filterData || {};
    let url = `${apiUrl}/odata/OrgProjectList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=StartDate desc`;
    if (ProjectName || ProjectManagerUserName || Description || Comments || StartDate || EndDate || IsProjectActive || searchProjectName || searchDescription || searchComments || searchStartTimeStamp || searchEndTimeStamp || searchIsProjectActive || searchProjectManagerUserName) {
      url += `&$filter=`;
      const filterArray = [];

      if (searchProjectName || ProjectName) {
        if (searchProjectName?.toLowerCase() === 'n/a' || ProjectName?.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        }
        else if (ProjectName) {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(ProjectName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchProjectName)}'))`);
        }
      }
      if (searchProjectManagerUserName || ProjectManagerUserName) {
        if (searchProjectManagerUserName?.toLowerCase() === 'n/a' || ProjectManagerUserName?.toLowerCase() === 'n/a') {
          filterArray.push('ProjectManagerUserName eq null');
        }
        else if (ProjectManagerUserName) {
          const nameFilter = `(contains(tolower(ProjectManagerUserName), tolower('${encodeURIComponent(ProjectManagerUserName)}')) or contains(tolower(ProjectManagerUserEmail), tolower('${encodeURIComponent(ProjectManagerUserName)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(ProjectManagerUserName), tolower('${encodeURIComponent(searchProjectManagerUserName)}')) or contains(tolower(ProjectManagerUserEmail), tolower('${encodeURIComponent(searchProjectManagerUserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchDescription || Description) {
        if (searchDescription?.toLowerCase() === 'n/a' || Description?.toLowerCase() === 'n/a') {
          filterArray.push('Description eq null');
        }
        else if (Description) {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(Description)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(searchDescription)}'))`);
        }
      }
      if (searchComments || Comments) {
        if (searchComments?.toLowerCase() === 'n/a' || Comments?.toLowerCase() === 'n/a') {
          filterArray.push('Comments eq null');
        }
        else if (Comments) {
          filterArray.push(`contains(tolower(Comments),tolower('${encodeURIComponent(Comments)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Comments),tolower('${encodeURIComponent(searchComments)}'))`);
        }
      }
      if ((searchStartTimeStamp && searchEndTimeStamp) || (StartDate && EndDate)) {

        const startDateFilter = `StartDate ge ${encodeURIComponent(searchStartTimeStamp || StartDate)} and StartDate le ${encodeURIComponent(searchEndTimeStamp || EndDate)}`;
        const endDateFilter = `EndDate ge ${encodeURIComponent(searchStartTimeStamp || StartDate)} and EndDate le ${encodeURIComponent(searchEndTimeStamp || EndDate)}`;
        const spanRangeFilter = `StartDate le ${encodeURIComponent(searchStartTimeStamp || StartDate)} and EndDate ge ${encodeURIComponent(searchEndTimeStamp || EndDate)}`;
        const combinedDateFilter = `(${startDateFilter} or ${endDateFilter} or ${spanRangeFilter})`.trim();
        filterArray.push(combinedDateFilter);
      }
      else if (searchStartTimeStamp || StartDate) {
        if (searchStartTimeStamp?.toLowerCase() === 'n/a' || StartDate?.toLowerCase() === 'n/a') {
          filterArray.push('StartDate eq null');
        }
        else if (StartDate) {
          filterArray.push(`StartDate eq ${encodeURIComponent(StartDate)}`);
        }
        else {
          filterArray.push(`StartDate eq ${encodeURIComponent(searchStartTimeStamp)}`);
        }
      }
      else if (searchEndTimeStamp || EndDate) {
        if (searchEndTimeStamp?.toLowerCase() === 'n/a' || EndDate?.toLowerCase() === 'n/a') {
          filterArray.push('EndDate eq null');
        }
        else if (EndDate) {
          filterArray.push(`EndDate eq ${encodeURIComponent(EndDate)}`);
        }
        else {
          filterArray.push(`EndDate eq ${encodeURIComponent(searchEndTimeStamp)}`);
        }
      }
      if (searchIsProjectActive || IsProjectActive) {
        if (searchIsProjectActive?.toLowerCase() === 'n/a' || IsProjectActive?.toLowerCase() === 'n/a') {
          filterArray.push('IsProjectActive eq null');
        }
        else if (IsProjectActive) {
          filterArray.push(`IsProjectActive eq ${encodeURIComponent(IsProjectActive)}`);
        }
        else {
          filterArray.push(`IsProjectActive eq ${encodeURIComponent(searchIsProjectActive)}`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    console.log("url:", url);

    return requests.getWithToken(url, accessToken);
  },
  getProjectDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/Project/Details?ProjectId=${id}`, accessToken);
  },

  getProjectPieChart: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/Project/PieChart?ProjectId=${id}`, accessToken);
  },
  getMilestonePieChartList: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/MileStone/PieChart?MilestoneId=${id}`, accessToken);
  },
  getAdminUserName: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/ProjectManagerUserList?count=true`;
    if (searchValue) {
      url = `${apiUrl}/odata/ProjectManagerUserList?count=true&$filter=contains(tolower(AssignedUserName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
    // return requests.getWithToken(`${apiUrl}/odata/ProjectManagerUserList?count=true&$filter=contains(tolower(AssignedUserName),tolower('${searchValue}'))`, accessToken);
  },
  getManagerUserName: (accessToken, appId, searchValue) => {
    let url = `${apiUrl}/odata/ProjectUserList?AppId=${appId}&count=true&filter=IsGlobalReadOnlyUser eq false`;
    if (searchValue) {
      url = `${apiUrl}/odata/ProjectUserList?AppId=${appId}&count=true&$filter=IsGlobalReadOnlyUser eq false and contains(tolower(AssignedUserName),tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(AssignedUserEmail),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  ProjectDropDownUserList: (accessToken, searchValue, appID) => {
    let url = `${apiUrl}/odata/ProjectDropDownUserList?AppId=${appID}&count=true`;
    if (searchValue) {
      url = `${apiUrl}/odata/ProjectDropDownUserList?AppId=${appID}&count=true&$filter=contains(tolower(UserName),tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(UserEmail),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getUserName: (accessToken, searchValue, appID) => {
    let url = `${apiUrl}/odata/ProjectUserList?AppId=${appID}&?count=true`;
    if (searchValue) {
      url = `${apiUrl}/odata/ProjectUserList?AppId=${appID}&count=true&$filter=contains(tolower(AssignedUserName),tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(AssignedUserEmail),tolower('${encodeURIComponent(searchValue)}')) `;
    }
    return requests.getWithToken(url, accessToken);
  },
  createProject: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Project/Create`, data, accessToken);
  },
  editProject: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Project/Update`, data, accessToken);
  },
  getTaskPercentageStatusList: (accessToken, appId, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/ProjectTaskStatusList?AppId=${appId}&count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=StatusName asc`;
    // let url = `${apiUrl}/odata/ProjectTaskStatusList?AppId=${appId}&count=true&$orderby=StatusName asc`;
    // if (searchValue) {
    //   url += `&$filter=contains(tolower(StatusName),tolower('${encodeURIComponent(searchValue)}'))`;
    // }
    return requests.getWithToken(url, accessToken);
  },
  getTaskScopeList: (accessToken, appId, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/ProjectTaskScopeList?AppId=${appId}&count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=ScopeName asc`;
    // let url = `${apiUrl}/odata/ProjectTaskScopeList?AppId=${appId}&count=true&$orderby=ScopeName asc`;
    // if (searchValue) {
    //   url += `&$filter=contains(tolower(ScopeName),tolower('${encodeURIComponent(searchValue)}'))`;
    // }
    return requests.getWithToken(url, accessToken);
  },
  getTaskScopeAsyncList: (accessToken, appId, searchValue) => {

    // let url = `${apiUrl}/odata/ProjectTaskScopeList?AppId=${appId}&count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=ScopeName asc`;
    let url = `${apiUrl}/odata/ProjectTaskScopeList?AppId=${appId}&count=true&$orderby=ScopeName asc`;
    if (searchValue) {
      url += `&$filter=contains(tolower(ScopeName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getTaskScope: (accessToken, searchValue, appId) => {
    let url = `${apiUrl}/odata/ProjectTaskScopeList?AppId=${appId}&count=true&$select=ScopeName,Id&$orderby=ScopeName asc&$filter=(IsActive eq true)`;
    if (searchValue) {
      url += `and (contains(tolower(ScopeName),tolower('${encodeURIComponent(searchValue)}')))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getTaskUserStatusPercentage: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/ProjectTaskStatusList?count=true&$orderby=StatusName asc`;

    const allowedStatuses = ["New", "InProgress", "Internal Testing", "OnHold"];
    const filterCondition = allowedStatuses.map(status => `StatusName eq '${status}'`).join(' or ');

    let filterString = `(${filterCondition})`;
    if (searchValue) {
      filterString += ` and contains(tolower(StatusName),tolower('${encodeURIComponent(searchValue)}'))`;
    }


    url += `&$filter=${filterString}`;

    return requests.getWithToken(url, accessToken);
  },
  getTaskMilestoneStatusPercentage: (accessToken, searchValue, appId) => {
    let url = `${apiUrl}/odata/ProjectTaskStatusList?AppId=${appId}&count=true&$orderby=StatusName asc`;

    const allowedStatuses = ["New", "InProgress", "Internal Testing", "Internal Testing Done", "OnHold"];
    const filterCondition = allowedStatuses.map(status => `StatusName eq '${status}'`).join(' or ');

    let filterString = `(${filterCondition})`;
    if (searchValue) {
      filterString += ` and contains(tolower(StatusName),tolower('${encodeURIComponent(searchValue)}'))`;
    }


    url += `&$filter=${filterString}`;

    return requests.getWithToken(url, accessToken);
  }
  ,
  getTaskStatusPercentage: (accessToken, searchValue, appId) => {
    let url = `${apiUrl}/odata/ProjectTaskStatusList?AppId=${appId}&count=true&$select=Id,StatusName,Percentage&$orderby=StatusName asc`;
    if (searchValue) {
      url += `&$filter=contains(tolower(StatusName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getAllTaskStatusPercentage: (accessToken, appId) => {
    let url = `${apiUrl}/odata/ProjectTaskStatusList?AppId=${appId}&count=true&$select=Id,StatusName,Percentage&$orderby=StatusName asc`;
    return requests.getWithToken(url, accessToken);
  },

  getTaskMilestoneOwnerStatusPercentage: (accessToken, searchValue) => {

    const allowedStatuses = [
      'New',
      'InProgress',
      'OnHold',
      'Internal Testing',
      'Internal Testing Done'
    ];

    let url = `${apiUrl}/odata/ProjectTaskStatusList?count=true&$select=Id,StatusName,Percentage&$orderby=StatusName asc`;

    url += `&$filter=(${allowedStatuses.map(status => `StatusName eq '${status}'`).join(' or ')})`;

    if (searchValue) {
      url += ` and contains(tolower(StatusName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getTaskUserStatusPercentage: (accessToken, searchValue, appId) => {

    const allowedStatuses = [
      'New',
      'InProgress',
      'OnHold',
      'Internal Testing',
    ];

    let url = `${apiUrl}/odata/ProjectTaskStatusList?AppId=${appId}&count=true&$select=Id,StatusName,Percentage&$orderby=StatusName asc`;

    url += `&$filter=(${allowedStatuses.map(status => `StatusName eq '${status}'`).join(' or ')})`;

    if (searchValue) {
      url += ` and contains(tolower(StatusName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },

  getTaskType: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/ProjectTaskTypeList?count=true&$select=TypeName,Id,IsActive&$orderby=TypeName asc&$filter=(IsActive eq true)`;
    if (searchValue) {
      url += `and (contains(tolower(TypeName),tolower('${encodeURIComponent(searchValue)}')))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getTaskTypeList: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/ProjectTaskTypeList?count=true&$orderby=TypeName asc&$select=TypeName,Id,IsActive`;
    if (searchValue) {
      url += `&$filter=contains(tolower(TypeName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getSettingsTaskTypeList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/ProjectTaskTypeList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=TypeName asc&$select=TypeName,Id,IsActive`;
    // let url = `${apiUrl}/odata/ProjectTaskTypeList?count=true&$orderby=TypeName asc&$select=TypeName,Id,IsActive`;
    return requests.getWithToken(url, accessToken);
  },
  getProjectName: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/OrgProjectList?count=true&$orderby=ProjectName asc&$select=Id,ProjectName,ProjectManagerUserId,AllowFlexibleDueDates`;
    if (searchValue) {
      url = `${apiUrl}/odata/OrgProjectList?count=true&$select=Id,AllowFlexibleDueDates,ProjectName,ProjectManagerUserId&$filter=contains(tolower(ProjectName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
    // return requests.getWithToken(`${apiUrl}/odata/OrgProjectList?count=true&$filter=contains(tolower(ProjectName),tolower('${searchValue}'))`, accessToken);
  },
  createMilestone: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/MileStone/Create`, data, accessToken);
  },
  editMilestone: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/MileStone/Update`, data, accessToken);
  },
  editMilestoneStatus: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/MileStone/Update/Status`, data, accessToken);
  },

  getexistingProjectUserlist: (accessToken, projectId, searchValue) => {
    let url = `${apiUrl}/odata/UserBasedOnProjectList?ProjectId=${projectId}&$filter=AssignedUserPermission eq 'Write' and IsGlobalReadOnlyUser eq false`;
    if (searchValue) {
      url += ` and contains(tolower(assignedUserName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getMilestoneExportList: (accessToken, searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive) => {

    let url = `${apiUrl}/MileStoneList/Export?count=true&$orderby=projectName asc`;
    if (searchProjectName || searchMilestoneName || searchStatus || searchDescription || searchComments || searchMileStoneOwnerUserName || searchStartTimeStamp || searchEndTimeStamp || searchIsActive) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchProjectName) {
        if (searchProjectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchProjectName)}'))`);
        }
      }
      if (searchMilestoneName) {
        if (searchMilestoneName.toLowerCase() === 'n/a') {
          filterArray.push('MilestoneName eq null');
        } else {
          filterArray.push(`contains(tolower(MilestoneName),tolower('${encodeURIComponent(searchMilestoneName)}'))`);
        }
      }
      if (searchStatus) {
        if (searchStatus.toLowerCase() === 'n/a') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`contains(tolower(Status),tolower('${encodeURIComponent(searchStatus)}'))`);
        }
      }
      if (searchDescription) {
        if (searchDescription.toLowerCase() === 'n/a') {
          filterArray.push('Description eq null');
        } else {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(searchDescription)}'))`);
        }
      }
      if (searchComments) {
        if (searchComments.toLowerCase() === 'n/a') {
          filterArray.push('Comments eq null');
        } else {
          filterArray.push(`contains(tolower(Comments),tolower('${encodeURIComponent(searchComments)}'))`);
        }
      }
      if (searchMileStoneOwnerUserName) {
        if (searchMileStoneOwnerUserName.toLowerCase() === 'n/a') {
          filterArray.push('MileStoneOwnerUserName eq null');
        } else {
          const nameFilter = `(contains(tolower(MileStoneOwnerUserName), tolower('${encodeURIComponent(searchMileStoneOwnerUserName)}')) or contains(tolower(MileStoneOwnerUserEmail), tolower('${encodeURIComponent(searchMileStoneOwnerUserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchStartTimeStamp) {
        if (searchStartTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('startDate eq null');
        } else {
          filterArray.push(`startDate eq ${encodeURIComponent(searchStartTimeStamp)}`);
        }
      }
      if (searchEndTimeStamp) {
        if (searchEndTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('endDate eq null');
        } else {
          filterArray.push(`endDate eq ${encodeURIComponent(searchEndTimeStamp)}`);
        }
      }
      if (searchIsActive) {
        if (searchIsActive.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getMilestoneList: (accessToken, filter, currentPage, PageSize = 15, searchProjectName, searchMilestoneName, searchStatus, searchDescription, searchComments, searchMileStoneOwnerUserName, searchStartTimeStamp, searchEndTimeStamp, searchIsActive) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filter:", filter);
    const { IsActive, StartDate, EndDate, Description, Comments, MileStoneOwnerUserName, MilestoneName, Status, ProjectName } = filter || {};
    let url = `${apiUrl}/odata/MileStoneList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    if (IsActive || StartDate || EndDate || Description || Comments || MileStoneOwnerUserName || MilestoneName || ProjectName || Status || searchProjectName || searchMilestoneName || searchStatus || searchDescription || searchComments || searchMileStoneOwnerUserName || searchStartTimeStamp || searchEndTimeStamp || searchIsActive) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchProjectName || ProjectName) {
        if (searchProjectName?.toLowerCase() === 'n/a' || ProjectName?.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        }
        else if (ProjectName) {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(ProjectName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchProjectName)}'))`);
        }
      }
      if (searchMilestoneName || MilestoneName) {
        if (searchMilestoneName?.toLowerCase() === 'n/a' || MilestoneName?.toLowerCase() === 'n/a') {
          filterArray.push('MilestoneName eq null');
        }
        else if (MilestoneName) {
          filterArray.push(`contains(tolower(MilestoneName),tolower('${encodeURIComponent(MilestoneName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(MilestoneName),tolower('${encodeURIComponent(searchMilestoneName)}'))`);
        }
      }
      if (searchStatus || Status) {
        if ((searchStatus && searchStatus.toLowerCase() === 'n/a') || (Status && Status.toLowerCase() === 'n/a')) {
          filterArray.push('Status eq null');
        } else if (Status) {
          filterArray.push(`contains(tolower(Status),tolower('${encodeURIComponent(Status)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Status),tolower('${encodeURIComponent(searchStatus)}'))`);
        }
      }
      if (searchDescription || Description) {
        if (searchDescription?.toLowerCase() === 'n/a' || Description?.toLowerCase() === 'n/a') {
          filterArray.push('Description eq null');
        }
        else if (Description) {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(Description)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(searchDescription)}'))`);
        }
      }
      if (searchComments || Comments) {
        if (searchComments?.toLowerCase() === 'n/a' || Comments?.toLowerCase() === 'n/a') {
          filterArray.push('Comments eq null');
        }
        else if (Comments) {
          filterArray.push(`contains(tolower(Comments),tolower('${encodeURIComponent(Comments)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Comments),tolower('${encodeURIComponent(searchComments)}'))`);
        }
      }
      if (searchMileStoneOwnerUserName || MileStoneOwnerUserName) {
        if (searchMileStoneOwnerUserName?.toLowerCase() === 'n/a' || MileStoneOwnerUserName?.toLowerCase() === 'n/a') {
          filterArray.push('MileStoneOwnerUserName eq null');
        }
        else if (MileStoneOwnerUserName) {
          const nameFilter = `(contains(tolower(MileStoneOwnerUserName), tolower('${encodeURIComponent(MileStoneOwnerUserName)}')) or contains(tolower(MileStoneOwnerUserEmail), tolower('${encodeURIComponent(MileStoneOwnerUserName)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(MileStoneOwnerUserName), tolower('${encodeURIComponent(searchMileStoneOwnerUserName)}')) or contains(tolower(MileStoneOwnerUserEmail), tolower('${encodeURIComponent(searchMileStoneOwnerUserName)}')))`;
          filterArray.push(nameFilter);
          // filterArray.push(`contains(tolower(MileStoneOwnerUserName),tolower('${encodeURIComponent(searchMileStoneOwnerUserName)}'))`);
        }
      }
      if (searchStartTimeStamp || StartDate) {
        if (searchStartTimeStamp?.toLowerCase() === 'n/a' || StartDate?.toLowerCase() === 'n/a') {
          filterArray.push('StartDate eq null');
        }
        else if (StartDate) {
          filterArray.push(`StartDate eq ${encodeURIComponent(StartDate)}`);
        }
        else {
          filterArray.push(`StartDate eq ${encodeURIComponent(searchStartTimeStamp)}`);
        }
      }
      if (searchEndTimeStamp || EndDate) {
        if (searchEndTimeStamp.toLowerCase() === 'n/a' || EndDate?.toLowerCase() === 'n/a') {
          filterArray.push('EndDate eq null');
        }
        else if (EndDate) {
          filterArray.push(`EndDate eq ${encodeURIComponent(EndDate)}`);
        }
      }
      if (searchIsActive || IsActive) {
        if (searchIsActive.toLowerCase() === 'n/a' || IsActive?.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        }
        else if (IsActive) {
          filterArray.push(`IsActive eq ${encodeURIComponent(IsActive)}`);
        }
        else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getProjectMilestoneList: (accessToken, id, sortName, sortOrder, filter) => {
    console.log("sortName:", sortName, "sortOrder:", sortOrder);

    const { Status, StartDate, MileStoneOwnerUserName, MilestoneName } = filter || {};
    let url = `${apiUrl}/odata/MileStoneList?count=true&$filter=contains(tolower(ProjectId),tolower('${id}'))`;

    if (Status || StartDate || MileStoneOwnerUserName || MilestoneName) {
      url += ' and ';
      const filterArray = [];
      if (StartDate) {
        if (StartDate?.toLowerCase() === 'n/a') {
          filterArray.push('StartDate eq null');
        }
        else {
          filterArray.push(`(StartDate eq ${encodeURIComponent(StartDate)} or EndDate eq ${encodeURIComponent(StartDate)})`);
        }
      }
      if (Status) {
        if (Status && Status.toLowerCase() === 'n/a') {
          filterArray.push('Status eq null');
        } else {
          // filterArray.push(`contains(tolower(Status),tolower('${encodeURIComponent(Status)}'))`);
          filterArray.push(`Status eq '${encodeURIComponent(Status)}'`);
        }
      }
      if (MilestoneName) {
        if (MilestoneName.toLowerCase() === 'n/a') {
          filterArray.push('MilestoneName eq null');
        } else {
          filterArray.push(`contains(tolower(MilestoneName),tolower('${encodeURIComponent(MilestoneName)}'))`);
        }
      }

      if (MileStoneOwnerUserName) {
        if (MileStoneOwnerUserName.toLowerCase() === 'n/a') {
          filterArray.push('MileStoneOwnerUserName eq null');
        } else {
          const nameFilter = `(contains(tolower(MileStoneOwnerUserName), tolower('${encodeURIComponent(MileStoneOwnerUserName)}')) or contains(tolower(MileStoneOwnerUserEmail), tolower('${encodeURIComponent(MileStoneOwnerUserName)}')))`;
          filterArray.push(nameFilter);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    if (sortName && sortOrder) {
      url += `&$orderby=${sortName} ${sortOrder}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getMilestonesDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/MileStone/Details?MilestoneId=${id}`, accessToken);
  },
  getMilestoneTaskDate: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/Project/FetchDates?MilestoneId=${id}`, accessToken);
  },
  getMilestonesProjectDate: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/Project/FetchDates?ProjectId=${id}`, accessToken);
  },

  getTaskExportList: (accessToken, appId, searchTaskName, searchMilestoneName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription) => {
    let url = `${apiUrl}/ProjectTaskList/Export?AppId=${appId}&count=true&$orderby=projectName asc`;
    if (searchTaskName || searchMilestoneName || searchTaskType || searchAssignedUserName || searchPriority || searchEffort || searchStartTimeStemp || searchEndTimeStemp || searchPlannedHours || searchProjectName || searchTaskStatus || searchUpdatedDate || searchCreatedDate || searchTaskScope || searchReviewerUserName || searchDescription) {

      url += `&$filter=`;
      const filterArray = [];

      if (searchProjectName) {
        if (searchProjectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchProjectName)}'))`);
        }
      }
      if (searchDescription) {
        if (searchDescription.toLowerCase() === 'n/a') {
          filterArray.push('Description eq null');
        } else {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(searchDescription)}'))`);
        }
      }
      if (searchReviewerUserName) {
        if (searchReviewerUserName.toLowerCase() === 'n/a') {
          filterArray.push('reviewerUserName eq null');
        } else {
          const nameFilter = `(contains(tolower(reviewerUserName), tolower('${encodeURIComponent(searchReviewerUserName)}')) or contains(tolower(reviewerUserEmail), tolower('${encodeURIComponent(searchReviewerUserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchTaskType) {
        if (searchTaskType.toLowerCase() === 'n/a') {
          filterArray.push('taskTypeName eq null');
        } else {
          filterArray.push(`contains(tolower(taskTypeName),tolower('${encodeURIComponent(searchTaskType)}'))`);
        }
      }
      if (searchTaskScope) {
        if (searchTaskScope.toLowerCase() === 'n/a') {
          filterArray.push('taskScopeName eq null');
        } else {
          filterArray.push(`contains(tolower(taskScopeName),tolower('${encodeURIComponent(searchTaskScope)}'))`);
        }
      }
      if (searchTaskName) {
        if (searchTaskName.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq null');
        } else {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(searchTaskName)}'))`);
        }
      }
      if (searchMilestoneName) {
        if (searchMilestoneName.toLowerCase() === 'n/a') {
          filterArray.push('MilestoneName eq null');
        } else {
          filterArray.push(`contains(tolower(MilestoneName),tolower('${encodeURIComponent(searchMilestoneName)}'))`);
        }
      }
      if (searchTaskType) {
        if (searchTaskType.toLowerCase() === 'n/a') {
          filterArray.push('taskTypeName eq null');
        } else {
          filterArray.push(`contains(tolower(taskTypeName),tolower('${encodeURIComponent(searchTaskType)}'))`);
        }
      }
      if (searchTaskStatus) {
        if (searchTaskStatus.toLowerCase() === 'n/a') {
          filterArray.push('TaskStatus eq null');
        } else {
          // filterArray.push(`contains(tolower(TaskStatus),tolower('${searchTaskStatus}'))`);
          filterArray.push(`TaskStatus eq '${encodeURIComponent(searchTaskStatus)}'`);
        }
      }
      if (searchAssignedUserName) {
        if (searchAssignedUserName.toLowerCase() === 'n/a') {
          filterArray.push('assignedUserName eq null');
        } else {
          const nameFilter = `(contains(tolower(assignedUserName), tolower('${encodeURIComponent(searchAssignedUserName)}')) or contains(tolower(assignedUserEmail), tolower('${encodeURIComponent(searchAssignedUserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchPriority) {
        if (searchPriority.toLowerCase() === 'n/a') {
          filterArray.push('Priority eq null');
        } else {
          filterArray.push(`contains(tolower(Priority),tolower('${encodeURIComponent(searchPriority)}'))`);
        }
      }
      if (searchEffort) {
        if (searchEffort.toLowerCase() === 'n/a') {
          filterArray.push('Effort eq null');
        } else {
          filterArray.push(`Effort eq '${searchEffort}'`);
        }
      }

      if (searchUpdatedDate) {
        if (searchUpdatedDate.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedTimeStamp eq null');
        } else {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(searchUpdatedDate)}`);
        }
      }
      if (searchCreatedDate) {
        if (searchCreatedDate.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        } else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedDate)}`);
        }
      }
      if (searchStartTimeStemp && searchEndTimeStemp) {

        const startDateFilter = `StartDate ge ${encodeURIComponent(searchStartTimeStemp)} and StartDate le ${encodeURIComponent(searchEndTimeStemp)}`;
        const endDateFilter = `EndDate ge ${encodeURIComponent(searchStartTimeStemp)} and EndDate le ${encodeURIComponent(searchEndTimeStemp)}`;
        const spanRangeFilter = `StartDate le ${encodeURIComponent(searchStartTimeStemp)} and EndDate ge ${encodeURIComponent(searchEndTimeStemp)}`;
        const combinedDateFilter = `(${startDateFilter} or ${endDateFilter} or ${spanRangeFilter})`.trim();
        filterArray.push(combinedDateFilter);
      }
      else if (searchStartTimeStemp) {
        if (searchStartTimeStemp.toLowerCase() === 'n/a') {
          filterArray.push('StartDate eq null');
        } else {
          filterArray.push(`StartDate  eq ${encodeURIComponent(searchStartTimeStemp)}`);
        }
      }
      else if (searchEndTimeStemp) {
        if (searchEndTimeStemp.toLowerCase() === 'n/a') {
          filterArray.push('EndDate eq null');
        } else {
          filterArray.push(`EndDate eq ${encodeURIComponent(searchEndTimeStemp)}`);
        }
      }
      if (searchPlannedHours) {
        if (searchPlannedHours.toLowerCase() === 'n/a') {
          filterArray.push('PlannedHours eq null');
        } else {
          filterArray.push(`contains(tolower(PlannedHours),tolower('${encodeURIComponent(searchPlannedHours)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getTaskList: (accessToken, appId, filter, currentPage, PageSize = 15, searchTaskName, searchMilestoneName, searchTaskType, searchAssignedUserName, searchPriority, searchEffort, searchStartTimeStemp, searchEndTimeStemp, searchPlannedHours, searchProjectName, searchTaskStatus, searchUpdatedDate, searchCreatedDate, searchTaskScope, searchReviewerUserName, searchDescription) => {

    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filter:", filter);
    const { taskScopeName,UpdatedTimeStamp, CreatedTimeStamp, PlannedHours, Effort, Priority, ReviewerUserName, TaskScopeName, TaskTypeName, AssignedUserName, TaskStatus, StartDate, EndDate, TaskName, ProjectName, MilestoneName, Description } = filter || {};

    let url = `${apiUrl}/odata/ProjectTaskList?AppId=${appId}&count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    if (taskScopeName||CreatedTimeStamp || UpdatedTimeStamp || PlannedHours || Effort || Priority || ReviewerUserName || TaskScopeName || TaskTypeName || AssignedUserName || ProjectName || TaskName || MilestoneName || Description || TaskStatus || StartDate || EndDate || searchTaskName || searchMilestoneName || searchTaskType || searchAssignedUserName || searchPriority || searchEffort || searchStartTimeStemp || searchEndTimeStemp || searchPlannedHours || searchProjectName || searchTaskStatus || searchUpdatedDate || searchCreatedDate || searchTaskScope || searchReviewerUserName || searchDescription) {

      url += `&$filter=`;
      const filterArray = [];

      if (searchProjectName || ProjectName) {
        if (searchProjectName?.toLowerCase() === 'n/a' || ProjectName?.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        } else if (ProjectName) {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(ProjectName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(searchProjectName)}'))`);
        }
      }
      if (searchDescription || Description) {
        if (searchDescription?.toLowerCase() === 'n/a' || Description?.toLowerCase() === 'n/a') {
          filterArray.push('Description eq null');
        } else if (Description) {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(Description)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(searchDescription)}'))`);
        }
      }
      if (searchTaskName || TaskName) {
        if (searchTaskName?.toLowerCase() === 'n/a' || TaskName?.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq null');
        }
        else if (TaskName) {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(TaskName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(searchTaskName)}'))`);
        }
      }
      if (searchMilestoneName || MilestoneName) {
        if (searchMilestoneName?.toLowerCase() === 'n/a' || MilestoneName.toLowerCase() === 'n/a') {
          filterArray.push('MilestoneName eq null');
        } else if (MilestoneName) {
          filterArray.push(`contains(tolower(MilestoneName),tolower('${encodeURIComponent(MilestoneName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(MilestoneName),tolower('${encodeURIComponent(searchMilestoneName)}'))`);
        }
      }
      if (searchTaskType || TaskTypeName) {
        if (searchTaskType?.toLowerCase() === 'n/a' || TaskTypeName?.toLowerCase() === 'n/a') {
          filterArray.push('taskTypeName eq null');
        }
        else if (TaskTypeName) {
          filterArray.push(`contains(tolower(taskTypeName),tolower('${encodeURIComponent(TaskTypeName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(taskTypeName),tolower('${encodeURIComponent(searchTaskType)}'))`);
        }
      }
      if (searchTaskScope || TaskScopeName || taskScopeName) {
        if (searchTaskScope?.toLowerCase() === 'n/a' || TaskScopeName?.toLowerCase() === 'n/a' || taskScopeName?.toLowerCase() === 'n/a') {
          filterArray.push('taskScopeName eq null');
        }
        else if (taskScopeName) {
          filterArray.push(`contains(tolower(taskScopeName),tolower('${encodeURIComponent(taskScopeName)}'))`);
        }
        else if (TaskScopeName) {
          filterArray.push(`contains(tolower(taskScopeName),tolower('${encodeURIComponent(TaskScopeName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(taskScopeName),tolower('${encodeURIComponent(searchTaskScope)}'))`);
        }
      }
      if (searchTaskStatus || TaskStatus) {

        if (searchTaskStatus?.toLowerCase() === 'n/a' || TaskStatus?.toLowerCase() === 'n/a') {
          filterArray.push('TaskStatus eq null');
        }
        else if (TaskStatus) {
          filterArray.push(`TaskStatus eq '${encodeURIComponent(TaskStatus)}'`);
        }
        else {
          filterArray.push(`TaskStatus eq '${encodeURIComponent(searchTaskStatus)}'`);
        }
      }
      if (searchAssignedUserName || AssignedUserName) {
        if (searchAssignedUserName?.toLowerCase() === 'n/a' || AssignedUserName?.toLowerCase() === 'n/a') {
          filterArray.push('AssignedUserName eq null');
        }
        else if (AssignedUserName) {
          const nameFilter = `(contains(tolower(AssignedUserName), tolower('${encodeURIComponent(AssignedUserName)}')) or contains(tolower(AssignedUserEmail), tolower('${encodeURIComponent(AssignedUserName)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(AssignedUserName), tolower('${encodeURIComponent(searchAssignedUserName)}')) or contains(tolower(AssignedUserEmail), tolower('${encodeURIComponent(searchAssignedUserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchReviewerUserName || ReviewerUserName) {
        if (searchReviewerUserName?.toLowerCase() === 'n/a' || ReviewerUserName?.toLowerCase() === 'n/a') {
          filterArray.push('ReviewerUserName eq null');
        }
        else if (ReviewerUserName) {
          const nameFilter = `(contains(tolower(ReviewerUserName), tolower('${encodeURIComponent(ReviewerUserName)}')) or contains(tolower(ReviewerUserEmail), tolower('${encodeURIComponent(ReviewerUserName)}')))`;
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(ReviewerUserName), tolower('${encodeURIComponent(searchReviewerUserName)}')) or contains(tolower(ReviewerUserEmail), tolower('${encodeURIComponent(searchReviewerUserName)}')))`;
          filterArray.push(nameFilter);
        }
      }
      if (searchPriority || Priority) {
        if (searchPriority?.toLowerCase() === 'n/a' || Priority?.toLowerCase() === 'n/a') {
          filterArray.push('Priority eq null');
        }
        else if (Priority) {
          filterArray.push(`contains(tolower(Priority),tolower('${encodeURIComponent(Priority)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Priority),tolower('${encodeURIComponent(searchPriority)}'))`);
        }
      }
      if (searchEffort || Effort) {
        if (searchEffort?.toLowerCase() === 'n/a' || Effort?.toLowerCase() === 'n/a') {
          filterArray.push('Effort eq null');
        }
        else if (Effort) {
          filterArray.push(`Effort eq '${encodeURIComponent(Effort)}'`);
        }
        else {
          filterArray.push(`Effort eq '${encodeURIComponent(searchEffort)}'`);
        }
      }

      if (searchUpdatedDate || UpdatedTimeStamp) {
        if (searchUpdatedDate?.toLowerCase() === 'n/a' || UpdatedTimeStamp?.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedTimeStamp eq null');
        }
        else if (UpdatedTimeStamp) {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(UpdatedTimeStamp)}`);
        }
        else {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(searchUpdatedDate)}`);
        }
      }
      if (searchCreatedDate || CreatedTimeStamp) {
        if (searchCreatedDate?.toLowerCase() === 'n/a' || CreatedTimeStamp?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        }
        else if (CreatedTimeStamp) {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(CreatedTimeStamp)}`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedDate)}`);
        }
      }
      if (StartDate || EndDate) {
        if (StartDate && EndDate) {
          const startDateFilter = `StartDate ge ${encodeURIComponent(StartDate)} and StartDate le ${encodeURIComponent(EndDate)}`;
          const endDateFilter = `EndDate ge ${encodeURIComponent(StartDate)} and EndDate le ${encodeURIComponent(EndDate)}`;
          const spanRangeFilter = `StartDate le ${encodeURIComponent(StartDate)} and EndDate ge ${encodeURIComponent(EndDate)}`;
          const combinedDateFilter = `(${startDateFilter} or ${endDateFilter} or ${spanRangeFilter})`.trim();
          filterArray.push(combinedDateFilter);
        }
        else if (StartDate) {
          if (StartDate?.toLowerCase() === 'n/a') {
            filterArray.push('StartDate eq null');
          } else {
            filterArray.push(`StartDate  eq ${encodeURIComponent(StartDate)}`);
          }
        }
        else if (EndDate) {
          if (EndDate?.toLowerCase() === 'n/a') {
            filterArray.push('EndDate eq null');
          } else {
            filterArray.push(`EndDate eq ${encodeURIComponent(EndDate)}`);
          }
        }
      }
      else {
        if (searchStartTimeStemp && searchEndTimeStemp) {
          const startDateFilter = `StartDate ge ${encodeURIComponent(searchStartTimeStemp)} and StartDate le ${encodeURIComponent(searchEndTimeStemp)}`;
          const endDateFilter = `EndDate ge ${encodeURIComponent(searchStartTimeStemp)} and EndDate le ${encodeURIComponent(searchEndTimeStemp)}`;
          const spanRangeFilter = `StartDate le ${encodeURIComponent(searchStartTimeStemp)} and EndDate ge ${encodeURIComponent(searchEndTimeStemp)}`;
          const combinedDateFilter = `(${startDateFilter} or ${endDateFilter} or ${spanRangeFilter})`.trim();
          filterArray.push(combinedDateFilter);
        }
        else if (searchStartTimeStemp) {
          if (searchStartTimeStemp?.toLowerCase() === 'n/a') {
            filterArray.push('StartDate eq null');
          } else {
            filterArray.push(`StartDate  eq ${encodeURIComponent(searchStartTimeStemp)}`);
          }
        }
        else if (searchEndTimeStemp) {
          if (searchEndTimeStemp?.toLowerCase() === 'n/a') {
            filterArray.push('EndDate eq null');
          } else {
            filterArray.push(`EndDate eq ${encodeURIComponent(searchEndTimeStemp)}`);
          }
        }
      }

      if (searchPlannedHours || PlannedHours) {
        if (searchPlannedHours?.toLowerCase() === 'n/a' || PlannedHours?.toLowerCase() === 'n/a') {
          filterArray.push('PlannedHours eq null');
        }
        else if (PlannedHours) {
          filterArray.push(`contains(tolower(PlannedHours),tolower('${encodeURIComponent(PlannedHours)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(PlannedHours),tolower('${encodeURIComponent(searchPlannedHours)}'))`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },

  getMilestoneTaskList: (accessToken, Id, filterTask, appId) => {
    const { TaskStartDate, AssignedUserName, TaskStatus, TaskName } = filterTask || {};
    let url = `${apiUrl}/odata/ProjectTaskList?AppId=${appId}&count=true&$filter=MileStoneId eq '${Id}'`;

    if (TaskStatus || TaskStartDate || AssignedUserName || TaskName) {
      url += ' and ';
      const filterArray = [];
      if (TaskStartDate) {
        if (TaskStartDate?.toLowerCase() === 'n/a') {
          filterArray.push('StartDate eq null');
        }
        else {
          filterArray.push(`(StartDate eq ${encodeURIComponent(TaskStartDate)} or EndDate eq ${encodeURIComponent(TaskStartDate)})`);
        }
      }
      if (TaskStatus) {
        if (TaskStatus && TaskStatus.toLowerCase() === 'n/a') {
          filterArray.push('TaskStatus eq null');
        } else {
          // filterArray.push(`contains(tolower(TaskStatus),tolower('${encodeURIComponent(TaskStatus)}'))`);
          filterArray.push(`TaskStatus eq '${encodeURIComponent(TaskStatus)}'`);
        }
      }
      if (TaskName) {
        if (TaskName.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq null');
        } else {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(TaskName)}'))`);
        }
      }

      if (AssignedUserName) {
        if (AssignedUserName.toLowerCase() === 'n/a') {
          filterArray.push('AssignedUserName eq null');
        } else {
          const nameFilter = `((contains(tolower(AssignedUserName), tolower('${encodeURIComponent(AssignedUserName)}'))) or (contains(tolower(AssignedUserEmail), tolower('${encodeURIComponent(AssignedUserName)}'))))`;
          filterArray.push(nameFilter);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getProjectUser: (accessToken, projectId, searchValue) => {
    let url = `${apiUrl}/odata/UserBasedOnProjectList?ProjectId=${projectId}&filter=IsGlobalReadOnlyUser eq false`;
    if (searchValue) {
      url += ` and contains(tolower(assignedUserName),tolower('${searchValue}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getTaskDependenciesUser: (accessToken, appId, milestoneId, searchValue) => {
    let url = `${apiUrl}/odata/ProjectTaskList?AppId=${appId}&count=true&$select=TaskName,Id&$filter=MileStoneId eq '${milestoneId}'`;
    if (searchValue) {
      url += ` and contains(tolower(TaskName),tolower('${searchValue}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getMilestoneName: (accessToken, projectId, searchValue) => {
    let url = `${apiUrl}/odata/MileStoneList?&count=true&$orderby=CreatedTimeStamp desc&$select=Id,IsMilestoneOwnerUpdateStatus,MilestoneName,isCurrentUserManagerOrMilestoneOwner,MilestoneError,AllowFlexibleDueDates,IsUserUpdateStatus&$filter=ProjectId eq '${projectId}' and isCurrentUserManagerOrMilestoneOwner eq true`;
    if (searchValue) {
      url += ` and contains(tolower(MilestoneName),tolower('${searchValue}'))`
    }
    return requests.getWithToken(url, accessToken);
  },
  createTask: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Task/Create`, data, accessToken);
  },
  editTask: (accessToken, data) => {
    console.log("data:", data);
    return requests.postWithToken(`${apiUrl}/api/Task/Update`, data, accessToken);
  },
  editTaskStatus: (accessToken, data) => {
    console.log("data:", data);
    return requests.postWithToken(`${apiUrl}/api/Task/Update/Status`, data, accessToken);
  },
  getTaskDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/Task/Details?TaskId=${id}`, accessToken);
  },
  getProjectAccessValidation: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/Task/TaskValidation?ProjectId=${id}`, accessToken);
  },

  updateTaskRating: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Task/Update/Rating`, data, accessToken);
  },
  getKanbanTaskList: (accessToken, appId, projectId, filterUsers, StartDate, EndDate) => {
    let filterString = '';
    if (projectId && projectId.length > 0) {
      filterString = projectId.map(id => `ProjectId eq '${id}'`).join(' or ');
    }
    if (filterUsers && filterUsers.length > 0) {
      const userFilter = filterUsers.map(id => `AssignedUserId eq '${id}'`).join(' or ');

      if (filterString) {
        filterString = `(${filterString}) and (${userFilter})`;
      } else {
        filterString = userFilter;
      }
    }
    // if (startDate && endDate) {
    //   const startDateFilter = `StartDate ge ${encodeURIComponent(startDate)} and StartDate le ${encodeURIComponent(endDate)}`;
    //   const endDateFilter = `EndDate ge ${encodeURIComponent(startDate)} and EndDate le ${encodeURIComponent(endDate)}`;

    //   filterString = `${filterString ? filterString + ' and ' : ''}(${startDateFilter}) and (${endDateFilter})`;
    // }

    // if (startDate && !endDate) {
    //   const startDateFilter = `StartDate ge ${encodeURIComponent(startDate)}`;
    //   filterString = `${filterString ? filterString + ' and ' : ''}${startDateFilter}`;
    // }

    // if (endDate && !startDate) {
    //   const endDateFilter = `EndDate le ${encodeURIComponent(endDate)}`;
    //   filterString = `${filterString ? filterString + ' and ' : ''}${endDateFilter}`;
    // }
    if (StartDate || EndDate) {
      let dateFilterString = '';

      if (StartDate && EndDate) {
        const startDateFilter = `StartDate ge ${encodeURIComponent(StartDate)} and StartDate le ${encodeURIComponent(EndDate)}`;
        const endDateFilter = `EndDate ge ${encodeURIComponent(StartDate)} and EndDate le ${encodeURIComponent(EndDate)}`;
        const spanRangeFilter = `StartDate le ${encodeURIComponent(StartDate)} and EndDate ge ${encodeURIComponent(EndDate)}`;
        dateFilterString = `(${startDateFilter} or ${endDateFilter} or ${spanRangeFilter})`;
      } else if (StartDate) {
        if (StartDate.toLowerCase() === 'n/a') {
          dateFilterString = 'StartDate eq null';
        } else {
          dateFilterString = `StartDate ge ${encodeURIComponent(StartDate)}`;
        }
      } else if (EndDate) {
        if (EndDate.toLowerCase() === 'n/a') {
          dateFilterString = 'EndDate eq null';
        } else {
          dateFilterString = `EndDate le ${encodeURIComponent(EndDate)}`;
        }
      }
      if (filterString) {
        filterString = `(${filterString}) and (${dateFilterString})`;
      } else {
        filterString = dateFilterString;
      }
    }

    const url = `${apiUrl}/odata/KanbanProjectTaskList?AppId=${appId}&count=true${filterString ? `&$filter=${filterString}` : ''}`;
    console.log('Final URL:', url);
    return requests.getWithToken(url, accessToken);
  },
  getGeneralTaskList: (accessToken, currentPage, PageSize = 15, searchTaskName, searchDescription) => {

    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/GeneralTaskList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    if (searchTaskName || searchDescription) {

      url += `&$filter=`;
      const filterArray = [];
      if (searchTaskName) {
        if (searchTaskName.toLowerCase() === 'n/a') {
          filterArray.push('TaskName eq null');
        } else {
          filterArray.push(`contains(tolower(TaskName),tolower('${encodeURIComponent(searchTaskName)}'))`);
        }
      }
      if (searchDescription) {
        if (searchDescription.toLowerCase() === 'n/a') {
          filterArray.push('Description eq null');
        } else {
          filterArray.push(`contains(tolower(Description),tolower('${encodeURIComponent(searchDescription)}'))`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },

  EditGeneralTask: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Task/GeneralTask/Update`, data, accessToken);
  },

  //----------------------------------------------------PROJECT-REPORT-----------------------------------------------------------------------------------
  getProjectUserReportList: (accessToken, currentPage, PageSize = 15, filter) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { StartDate, EndDate, UserName } = filter || {};
    let url = `${apiUrl}/odata/ProjectUserReportList?count=true&StartDate=${StartDate}&EndDate=${EndDate}&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;
    if (UserName) {
      url += `&$filter=`;
      const filterArray = [];
      if (UserName) {
        if (UserName.toLowerCase() === 'n/a') {
          filterArray.push('UserName eq null');
        } else {
          filterArray.push(`contains(tolower(UserName),tolower('${encodeURIComponent(UserName)}')) or contains(tolower(UserEmail),tolower('${encodeURIComponent(UserName)}')) `);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getReportList: (accessToken, currentPage, PageSize = 15, filter) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { AssignedUserName, StartDate, EndDate, ProjectName } = filter || {};
    console.log("ProjectName:", ProjectName);

    let url = `${apiUrl}/odata/ProjectReportList?count=true&StartDate=${StartDate}&EndDate=${EndDate}&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;
    if (ProjectName) {
      url += `&$filter=`;
      const filterArray = [];
      if (ProjectName) {
        if (ProjectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(ProjectName)}'))`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getReportUserListExport: (accessToken, filter) => {
    const { StartDate, EndDate, UserName } = filter || {};
    let url = `${apiUrl}/ProjectUserReportList/Export?count=true&StartDate=${StartDate}&EndDate=${EndDate}`;
    if (UserName) {
      url += `&$filter=`;
      const filterArray = [];
      if (UserName) {
        if (UserName.toLowerCase() === 'n/a') {
          filterArray.push('UserName eq null');
        } else {
          filterArray.push(`contains(tolower(UserName),tolower('${encodeURIComponent(UserName)}')) or contains(tolower(UserEmail),tolower('${encodeURIComponent(UserName)}')) `);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getReportListExport: (accessToken, filter) => {
    const { StartDate, EndDate, ProjectName } = filter || {};
    let url = `${apiUrl}/ProjectReportList/Export?count=true&StartDate=${StartDate}&EndDate=${EndDate}`;
    if (ProjectName) {
      url += `&$filter=`;
      const filterArray = [];
      if (ProjectName) {
        if (ProjectName.toLowerCase() === 'n/a') {
          filterArray.push('ProjectName eq null');
        } else {
          filterArray.push(`contains(tolower(ProjectName),tolower('${encodeURIComponent(ProjectName)}'))`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
}

const MassMailer = {
  //-------------------------------------------------------DASHBOARDS------------------------------------------------------------------------------------
  getMMDashboardCounts: (accessToken) => {
    return requests.getWithToken(`${apiUrl}/api/MassMailerDashboard/Dashboard`, accessToken)
  },
  //-------------------------------------------------------CONTACTS--------------------------------------------------------------------------------------
  getContactList: (accessToken, currentPage, pageSize, searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/MailerContactList?count=true&$skip=${(currentPage - 1) * pageSize}&$top=${pageSize}`;
    if (sortDirection && sortOrder) {
      url += `&$orderby=${sortOrder} ${sortDirection}`;
    }
    if (searchId || searchEmail || searchCampaignName || searchType || searchIsActive || searchSubscriptionStatus || searchCreatedBy || searchCreatedTimeStamp) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchId) {
        if (searchId.toLowerCase() === 'n/a') {
          filterArray.push('CampaignId eq null');
        } else {
          filterArray.push(`contains(tolower(CampaignId),tolower('${encodeURIComponent(searchId)}'))`);
        }
      }
      if (searchEmail) {
        if (searchEmail.toLowerCase() === 'n/a') {
          filterArray.push('Email eq null');
        } else {
          filterArray.push(`contains(tolower(Email),tolower('${encodeURIComponent(searchEmail)}'))`);
        }
      }
      if (searchCampaignName) {
        if (searchCampaignName.toLowerCase() === 'n/a') {
          filterArray.push('CampaignName eq null');
        } else {
          filterArray.push(`contains(tolower(CampaignName),tolower('${encodeURIComponent(searchCampaignName)}'))`);
        }
      }
      if (searchType) {
        if (searchType.toLowerCase() === 'n/a') {
          filterArray.push('Type eq null');
        } else {
          filterArray.push(`contains(tolower(Type),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchIsActive) {
        if (searchIsActive.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
        }
      }
      if (searchSubscriptionStatus) {
        if (searchSubscriptionStatus.toLowerCase() === 'n/a') {
          filterArray.push('SubscriptionStatus eq null');
        } else {
          filterArray.push(`SubscriptionStatus eq '${encodeURIComponent(searchSubscriptionStatus)}'`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedTimeStamp) {
        if (searchCreatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        } else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedTimeStamp)}`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getContactDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/MailerContact/Details?mailContactId=${id}`, accessToken);
  },
  exportContactList: (accessToken, searchId, searchEmail, searchCampaignName, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) => {

    let url = `${apiUrl}/Export?count=true&$orderby=email asc`;
    // if (sortDirection && sortOrder) {
    //   url += `&$orderby=${sortOrder} ${sortDirection}`;
    // }

    if (searchId || searchEmail || searchCampaignName || searchType || searchIsActive || searchSubscriptionStatus || searchCreatedBy || searchCreatedTimeStamp) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchId) {
        if (searchId.toLowerCase() === 'n/a') {
          filterArray.push('CampaignId eq null');
        } else {
          filterArray.push(`contains(tolower(CampaignId),tolower('${encodeURIComponent(searchId)}'))`);
        }
      }
      if (searchEmail) {
        if (searchEmail.toLowerCase() === 'n/a') {
          filterArray.push('Email eq null');
        } else {
          filterArray.push(`contains(tolower(Email),tolower('${encodeURIComponent(searchEmail)}'))`);
        }
      }
      if (searchCampaignName) {
        if (searchCampaignName.toLowerCase() === 'n/a') {
          filterArray.push('CampaignName eq null');
        } else {
          filterArray.push(`contains(tolower(CampaignName),tolower('${encodeURIComponent(searchCampaignName)}'))`);
        }
      }
      if (searchType) {
        if (searchType.toLowerCase() === 'n/a') {
          filterArray.push('Type eq null');
        } else {
          filterArray.push(`contains(tolower(Type),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchIsActive) {
        if (searchIsActive.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
        }
      }
      if (searchSubscriptionStatus) {
        if (searchSubscriptionStatus.toLowerCase() === 'n/a') {
          filterArray.push('SubscriptionStatus eq null');
        } else {
          filterArray.push(`contains(tolower(SubscriptionStatus),tolower('${encodeURIComponent(searchSubscriptionStatus)}'))`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedTimeStamp) {
        if (searchCreatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('createdTimeStamp eq null');
        } else {
          filterArray.push(`createdTimeStamp eq ${encodeURIComponent(searchCreatedTimeStamp)}`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  //-------------------------------------------------------CAMPAIGN--------------------------------------------------------------------------------------
  createCampaign: (accessToken, campaignDetails) => {
    console.log("campaignDetails: ", campaignDetails);
    const formData = new FormData();
    formData.append('CampaignName', campaignDetails.CampaignName);
    formData.append('Type', campaignDetails.Type);
    formData.append('MailSettingId', campaignDetails.FromAddress);
    formData.append('Subject', campaignDetails.Subject);
    formData.append('ContactList', campaignDetails.ContactList);
    formData.append('IndexFile', campaignDetails.IndexFile);
    formData.append('UnSubscriptionLink', campaignDetails.UnSubscriptionLink);

    return requests.postMultiPartWithToken(`${apiUrl}/api/Campaign/create`, formData, accessToken);
  },
  getFromAddressAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/MailSettingList?$count=true&$filter=(IsActive eq true)`;
    if (searchValue) {
      url += ` and (contains(tolower(EmailAddress),tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(FromDisplayName),tolower('${encodeURIComponent(searchValue)}')))`;
    }
    return requests.getWithToken(url, accessToken);
  },

  getCampaignList: (accessToken, currentPage, pageSize = 15, searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CampaignList?count=true&$skip=${(currentPage - 1) * pageSize}&$top=${pageSize}`;

    if (sortDirection && sortOrder) {
      url += `&$orderby=${sortOrder} ${sortDirection}`;
    }
    else {
      url += `&$orderby=CreatedTimeStamp desc`;
    }
    if (searchCampaignName || searchEmailAddress || searchMailCategory || searchSubject || searchStatus || searchCreatedBy || searchCreatedTimeStamp) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchCampaignName) {
        if (searchCampaignName.toLowerCase() === 'n/a') {
          filterArray.push('campaignName eq null');
        } else {
          filterArray.push(`contains(tolower(campaignName),tolower('${encodeURIComponent(searchCampaignName)}'))`);
        }
      }
      if (searchEmailAddress) {
        if (searchEmailAddress.toLowerCase() === 'n/a') {
          filterArray.push('EmailAddress eq null');
        } else {
          filterArray.push(`(contains(tolower(EmailAddress),tolower('${encodeURIComponent(searchEmailAddress)}')) or contains(tolower(FromDisplayName),tolower('${encodeURIComponent(searchEmailAddress)}')))`);
        }
      }
      if (searchMailCategory) {
        if (searchMailCategory.toLowerCase() === 'n/a') {
          filterArray.push('Type eq null');
        } else {
          filterArray.push(`contains(tolower(Type),tolower('${encodeURIComponent(searchMailCategory)}'))`);
        }
      }
      if (searchSubject) {
        if (searchSubject.toLowerCase() === 'n/a') {
          filterArray.push('Subject eq null');
        } else {
          filterArray.push(`contains(tolower(Subject),tolower('${encodeURIComponent(searchSubject)}'))`);
        }
      }
      if (searchStatus) {
        if (searchStatus.toLowerCase() === 'n/a') {
          filterArray.push('Status eq null');
        } else {
          filterArray.push(`Status eq '${encodeURIComponent(searchStatus)}'`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedTimeStamp) {
        if (searchCreatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        } else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedTimeStamp)}`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },

  exportCampaignList: (accessToken, searchCampaignName, searchEmailAddress, searchMailCategory, searchSubject, searchStatus, searchCreatedBy, searchCreatedTimeStamp, sortDirection, sortOrder) => {
    let url = `${apiUrl}/ExportList?count=true&$orderby=campaignName asc`;
    // if (sortDirection && sortOrder) {
    //   url += `&$orderby=${sortOrder} ${sortDirection}`;
    // }
    // else {
    //   url += `&$orderby=createdTimeStamp desc`;
    // }
    if (searchCampaignName || searchEmailAddress || searchMailCategory || searchSubject || searchStatus || searchCreatedBy || searchCreatedTimeStamp) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchCampaignName) {
        if (searchCampaignName.toLowerCase() === 'n/a') {
          filterArray.push('campaignName eq null');
        } else {
          filterArray.push(`contains(tolower(campaignName),tolower('${encodeURIComponent(searchCampaignName)}'))`);
        }
      }
      if (searchEmailAddress) {
        if (searchEmailAddress.toLowerCase() === 'n/a') {
          filterArray.push('EmailAddress eq null');
        } else {
          filterArray.push(`contains(tolower(EmailAddress),tolower('${encodeURIComponent(searchEmailAddress)}'))`);
        }
      }
      if (searchMailCategory) {
        if (searchMailCategory.toLowerCase() === 'n/a') {
          filterArray.push('type eq null');
        } else {
          filterArray.push(`contains(tolower(type),tolower('${encodeURIComponent(searchMailCategory)}'))`);
        }
      }
      if (searchSubject) {
        if (searchSubject.toLowerCase() === 'n/a') {
          filterArray.push('subject eq null');
        } else {
          filterArray.push(`contains(tolower(subject),tolower('${encodeURIComponent(searchSubject)}'))`);
        }
      }
      if (searchStatus) {
        if (searchStatus.toLowerCase() === 'n/a') {
          filterArray.push('status eq null');
        } else {
          filterArray.push(`status eq '${encodeURIComponent(searchStatus)}'`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('createdBy eq null');
        } else {
          filterArray.push(`contains(tolower(createdBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedTimeStamp) {
        if (searchCreatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('createdTimeStamp eq null');
        } else {
          filterArray.push(`createdTimeStamp eq ${encodeURIComponent(searchCreatedTimeStamp)}`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },

  getCampaignDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/Campaign/Details?campaignId=${id}`, accessToken);
  },

  getCampaignContactsCount: (accessToken, campaignId) => {
    let url = `${apiUrl}/odata/MailContactBasedList?CampaignId=${campaignId}&count=true`;
    return requests.getWithToken(url, accessToken);
  },
  getHTMLFile: (accessToken, id) => {
    return requests.getFileWithToken(`${apiUrl}/api/Campaign/Get/Image?CampaignId=${id}`, accessToken);
  },
  getCampaignContactList: (accessToken, currentPage, pageSize = 10, campaignId, searchEmail, searchType, searchIsActive, searchSubscriptionStatus, searchCreatedBy, sortDirection, sortOrder) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/MailContactBasedList?CampaignId=${campaignId}&count=true&$skip=${(currentPage - 1) * pageSize}&$top=${pageSize}`;
    if (sortDirection && sortOrder) {
      url += `&$orderby=${sortOrder} ${sortDirection}`;
    }
    if (searchEmail || searchType || searchIsActive || searchSubscriptionStatus || searchCreatedBy) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchEmail) {
        if (searchEmail.toLowerCase() === 'na' || searchEmail.toLowerCase() === 'n/a') {
          filterArray.push('Email eq null');
        } else {
          filterArray.push(`contains(tolower(Email),tolower('${encodeURIComponent(searchEmail)}'))`);
        }
      }
      if (searchType) {
        if (searchType.toLowerCase() === 'na' || searchType.toLowerCase() === 'n/a') {
          filterArray.push('Type eq null');
        } else {
          filterArray.push(`contains(tolower(Type),tolower('${encodeURIComponent(searchType)}'))`);
        }
      }
      if (searchIsActive) {
        if (searchIsActive.toLowerCase() === 'na' || searchIsActive.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
        }
      }
      if (searchSubscriptionStatus) {
        if (searchSubscriptionStatus.toLowerCase() === 'na' || searchSubscriptionStatus.toLowerCase() === 'n/a') {
          filterArray.push('SubscriptionStatus eq null');
        } else {
          filterArray.push(`SubscriptionStatus eq '${encodeURIComponent(searchSubscriptionStatus)}'`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'na' || searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },

  getCampaignEdit: (accessToken, campaignDetails) => {
    console.log("campaignDetails: ", campaignDetails);
    const formData = new FormData();
    formData.append('Id', campaignDetails.id);
    formData.append('CampaignName', campaignDetails.campaignName);
    formData.append('MailSettingId', campaignDetails.FromAddress);
    formData.append('Type', campaignDetails.type);
    formData.append('Subject', campaignDetails.subject);
    formData.append('ContactList', campaignDetails.ContactList);
    formData.append('IndexFile', campaignDetails.IndexFile);
    formData.append('UnSubscriptionLink', campaignDetails.unSubscriptionLink);
    return requests.postMultiPartWithToken(`${apiUrl}/api/Campaign/Update`, formData, accessToken);
  },
  //-------------------------------------------------------MAIL-SETTING--------------------------------------------------------------------------------------
  createMailSetting: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/MailSetting/Create`, data, accessToken);
  },
  getMailSettingList: (accessToken, currentPage, pageSize = 15, searchEmailAddress, searchFromDisplayName, searchSMTPPort, searchUserName, searchPassword, searchIsActive, searchCreatedTimeStamp, searchCreatedBy) => {
    currentPage = parseInt(currentPage, 10);

    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }

    let url = `${apiUrl}/odata/MailSettingList?count=true&$skip=${(currentPage - 1) * pageSize}&$top=${pageSize}`;

    if (searchEmailAddress || searchFromDisplayName || searchSMTPPort || searchUserName || searchPassword || searchIsActive || searchCreatedTimeStamp || searchCreatedBy) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchEmailAddress) {
        if (searchEmailAddress.toLowerCase() === 'na' || searchEmailAddress.toLowerCase() === 'n/a') {
          filterArray.push('(EmailAddress eq null or FromDisplayName eq null)');
        } else {
          filterArray.push(`(contains(tolower(EmailAddress),tolower('${encodeURIComponent(searchEmailAddress)}')) or contains(tolower(FromDisplayName),tolower('${encodeURIComponent(searchEmailAddress)}')))`);
        }
      }
      if (searchCreatedTimeStamp) {
        if (searchCreatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        } else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedTimeStamp)}`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('FromDisplayName eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchFromDisplayName) {
        if (searchFromDisplayName.toLowerCase() === 'na' || searchFromDisplayName.toLowerCase() === 'n/a') {
          filterArray.push('FromDisplayName eq null');
        } else {
          filterArray.push(`contains(tolower(FromDisplayName),tolower('${encodeURIComponent(searchFromDisplayName)}'))`);
        }
      }
      if (searchSMTPPort) {
        if (searchSMTPPort.toLowerCase() === 'na' || searchSMTPPort.toLowerCase() === 'n/a') {
          filterArray.push('SMTPPort eq null');
        } else {
          filterArray.push(`contains(tolower(SMTPPort),tolower('${encodeURIComponent(searchSMTPPort)}'))`);
        }
      }
      if (searchUserName) {
        if (searchUserName.toLowerCase() === 'na' || searchUserName.toLowerCase() === 'n/a') {
          filterArray.push('UserName eq null');
        } else {
          filterArray.push(`contains(tolower(UserName),tolower('${encodeURIComponent(searchUserName)}'))`);
        }
      }
      if (searchPassword) {
        if (searchPassword.toLowerCase() === 'na' || searchPassword.toLowerCase() === 'n/a') {
          filterArray.push('Password eq null');
        } else {
          filterArray.push(`contains(tolower(Password),tolower('${encodeURIComponent(searchPassword)}'))`);
        }
      }
      if (searchIsActive) {
        if (searchIsActive.toLowerCase() === 'na' || searchIsActive.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },
  updateMailSetting: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/MailSetting/Update`, data, accessToken);
  },
  //----------------------------------UNSUBSCRIBE--------------------------------------------------------------------------------
  getEmail: (id) => {
    return requests.getWithToken(`${apiUrl}/api/UnSubscribe/GetEmailDetails?Id=${id}`);
  },
  submitUnsubscribe: (data) => {
    return requests.post(`${apiUrl}/api/UnSubscribe/UpdateStatus`, data);
  },
}

const DBSync = {
  getDBSynclist: (accessToken, currentPage, PageSize = 15, searchDatabaseName, searchServerName, searchTableCount, searchNoOfRows, searchMissingSync, searchNoOfSync, searchLastSyncedTime) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/DbSyncList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;
    if (searchDatabaseName || searchServerName || searchTableCount || searchNoOfRows || searchMissingSync || searchNoOfSync || searchLastSyncedTime) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchDatabaseName) {
        if (searchDatabaseName.toLowerCase() === 'na') {
          filterArray.push('DbName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(DbName),tolower('${encodeURIComponent(searchDatabaseName)}'))`);
        }
      }
      if (searchServerName) {
        if (searchServerName.toLowerCase() === 'na') {
          filterArray.push('ServerName eq \'\'');
        } else {
          filterArray.push(`contains(tolower(ServerName),tolower('${encodeURIComponent(searchServerName)}'))`);
        }
      }
      if (searchTableCount) {
        if (searchTableCount.toLowerCase() === 'na') {
          filterArray.push('NoOfTables eq \'\'');
        } else {
          filterArray.push(`contains(tolower(NoOfTables),tolower('${encodeURIComponent(searchTableCount)}'))`);
        }
      }

      if (searchNoOfRows) {
        if (searchNoOfRows.toLowerCase() === 'na') {
          filterArray.push('NoOfRows eq \'\'');
        } else {
          filterArray.push(`contains(tolower(NoOfRows),tolower('${encodeURIComponent(searchNoOfRows)}'))`);
        }
      }
      if (searchMissingSync) {
        if (searchMissingSync.toLowerCase() === 'na') {
          filterArray.push('MissingSync eq \'\'');
        } else {
          filterArray.push(`contains(tolower(MissingSync),tolower('${encodeURIComponent(searchMissingSync)}'))`);
        }
      }
      if (searchNoOfSync) {
        if (searchNoOfSync.toLowerCase() === 'na') {
          filterArray.push('NoOfSync eq \'\'');
        } else {
          filterArray.push(`NoOfSync eq ${encodeURIComponent(searchNoOfSync)}`);
        }
      }
      if (searchLastSyncedTime) {
        if (searchLastSyncedTime.toLowerCase() === 'na') {
          filterArray.push('LastSyncedTime eq \'\'');
        } else {
          filterArray.push(`LastSyncedTime eq ${encodeURIComponent(searchLastSyncedTime)}`);
        }
      }
      // console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  createDBSync: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/DBSync/Create`, data, accessToken);
  },
  getDeleteDBSyncList: (accessToken, id) => {
    const { dbSyncId } = id;
    return requests.postToDeleteWithToken(`${apiUrl}/api/DbSync/Delete?dbSyncId=${dbSyncId}`, accessToken);
  },
}

const VisionDocs = {
  createDocs: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Document/Create`, data, accessToken);
  },
  updateDocs: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/Document/Update`, data, accessToken);
  },
  // getVisionDocsList
  getVisionDocsList: (accessToken, currentPage, PageSize = 15, searchTitle, searchAssignUserCount, searchCreatedBy, searchCreatedTimeStamp, searchAccess, searchUpdatedBy, searchUpdatedTimeStamp) => {

    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/DocumentList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    if (searchTitle || searchAssignUserCount || searchCreatedBy || searchCreatedTimeStamp || searchAccess || searchUpdatedBy || searchUpdatedTimeStamp) {

      url += `&$filter=`;
      const filterArray = [];
      if (searchTitle) {
        if (searchTitle.toLowerCase() === 'n/a') {
          filterArray.push('Title eq null');
        } else {
          filterArray.push(`contains(tolower(Title),tolower('${encodeURIComponent(searchTitle)}'))`);
        }
      }
      if (searchAccess) {
        if (searchAccess.toLowerCase() === 'n/a') {
          filterArray.push('Access eq null');
        } else {
          filterArray.push(`contains(tolower(Access),tolower('${encodeURIComponent(searchAccess)}'))`);
        }
      }
      if (searchAssignUserCount) {
        if (searchAssignUserCount.toLowerCase() === 'n/a') {
          filterArray.push('AssignUserCount eq null');
        } else {
          filterArray.push(`AssignUserCount eq ${encodeURIComponent(searchAssignUserCount)}`);
        }
      }
      if (searchCreatedTimeStamp) {
        if (searchCreatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        } else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedTimeStamp)}`);
        }
      }
      if (searchUpdatedTimeStamp) {
        if (searchUpdatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedTimeStamp eq null');
        } else {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(searchUpdatedTimeStamp)}`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchUpdatedBy) {
        if (searchUpdatedBy.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(UpdatedBy),tolower('${encodeURIComponent(searchUpdatedBy)}'))`);
        }
      }

      console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getVisionDocsDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/Document/Details?documentId=${id}`, accessToken);
  },
  getUserNameList: (accessToken, searchValue) => {
    return requests.getWithToken(`${apiUrl}/odata/DocumentAssignUserList?count=true&$filter=contains(tolower(UserName),tolower('${encodeURIComponent(searchValue)}'))`, accessToken);
  },
  getVisionDocsDelete: (accessToken, id) => {
    return requests.deleteWithToken(`${apiUrl}/api/Document/Delete?DocumentId=${id}`, accessToken);
  },
  getVisionDocsOverAllDelete: (accessToken, id, appID) => {
    return requests.deleteWithToken(`${apiUrl}/api/OverAllDocument/Delete?DocumentId=${id}&AppId=${appID}`, accessToken);
  },
}

const OverAllDocs = {
  getOverAllDocsList: (accessToken, filteredData, currentPage, PageSize = 15, searchTitle, searchAssignUserCount, searchCreatedBy, searchCreatedTimeStamp, appId, searchAccess, searchUpdatedBy, searchUpdatedTimeStamp) => {

    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { Title, Access, AssignUsersCount, CreatedTimeStamp, UpdatedTimeStamp, CreatedBy, UpdatedBy } = filteredData || {};
    let url = `${apiUrl}/odata/OverAllDocumentList?AppId=${appId}&count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    if (UpdatedBy || CreatedBy || Title || Access || AssignUsersCount || CreatedTimeStamp || UpdatedTimeStamp || searchTitle || searchAssignUserCount || searchCreatedBy || searchCreatedTimeStamp || searchAccess || searchUpdatedBy || searchUpdatedTimeStamp) {

      url += `&$filter=`;
      const filterArray = [];
      if (searchTitle || Title) {
        if (searchTitle?.toLowerCase() === 'n/a' || Title?.toLowerCase() === 'n/a') {
          filterArray.push('Title eq null');
        }
        else if (Title) {
          filterArray.push(`contains(tolower(Title),tolower('${encodeURIComponent(Title)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Title),tolower('${encodeURIComponent(searchTitle)}'))`);
        }
      }
      if (searchAccess || Access) {
        if (searchAccess?.toLowerCase() === 'n/a' || Access?.toLowerCase() === 'n/a') {
          filterArray.push('Access eq null');
        }
        else if (Access) {
          filterArray.push(`contains(tolower(Access),tolower('${encodeURIComponent(Access)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Access),tolower('${encodeURIComponent(searchAccess)}'))`);
        }
      }
      if (searchAssignUserCount || AssignUsersCount) {
        if (searchAssignUserCount?.toLowerCase() === 'n/a' || AssignUsersCount?.toLowerCase() === 'n/a') {
          filterArray.push('AssignUsersCount eq null');
        }
        else if (AssignUsersCount) {
          filterArray.push(`AssignUsersCount eq ${encodeURIComponent(AssignUsersCount)}`);
        }
        else {
          filterArray.push(`AssignUsersCount eq ${encodeURIComponent(searchAssignUserCount)}`);
        }
      }
      if (searchCreatedTimeStamp || CreatedTimeStamp) {
        if (searchCreatedTimeStamp?.toLowerCase() === 'n/a' || CreatedTimeStamp?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        } else if (CreatedTimeStamp) {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(CreatedTimeStamp)}`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedTimeStamp)}`);
        }
      }
      if (searchUpdatedTimeStamp || UpdatedTimeStamp) {
        if (searchUpdatedTimeStamp?.toLowerCase() === 'n/a' || UpdatedTimeStamp?.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedTimeStamp eq null');
        }
        else if (UpdatedTimeStamp) {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(UpdatedTimeStamp)}`);
        }
        else {
          filterArray.push(`UpdatedTimeStamp eq ${encodeURIComponent(searchUpdatedTimeStamp)}`);
        }
      }
      if (searchCreatedBy || CreatedBy) {
        if (searchCreatedBy?.toLowerCase() === 'n/a' || CreatedBy?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        }
        else if (CreatedBy) {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(CreatedBy)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchUpdatedBy || UpdatedBy) {
        if (searchUpdatedBy?.toLowerCase() === 'n/a' || UpdatedBy?.toLowerCase() === 'n/a') {
          filterArray.push('UpdatedBy eq null');
        }
        else if (UpdatedBy) {
          filterArray.push(`contains(tolower(UpdatedBy),tolower('${encodeURIComponent(UpdatedBy)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(UpdatedBy),tolower('${encodeURIComponent(searchUpdatedBy)}'))`);
        }
      }

      console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getUserNameList: (accessToken, appId, searchValue) => {
    return requests.getWithToken(`${apiUrl}/odata/OverAllDocumentAssignUser?AppId=${appId}&count=true&$filter=contains(tolower(UserName),tolower('${encodeURIComponent(searchValue)}'))`, accessToken);
  },
  createOverAllDocs: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/OverAllDocument/Create`, data, accessToken);
  },
  updateDocs: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/OverAllDocument/Update`, data, accessToken);
  },
  getOverAllDocsDetails: (accessToken, id, appid) => {
    return requests.getWithToken(`${apiUrl}/api/OverAllDocument/Details?DocumentId=${id}&AppId=${appid}`, accessToken);
  },
}

const Crm = {
  //--------------------------------------------------------CRM-DASHBOARD-------------------------------------------------------
  getDashboardCrm: (accessToken, fromDate, toDate, toggle) => {
    if (toggle) {
      return requests.getWithToken(`${apiUrl}/api/CrmDashboard?DashboardType=${toggle}`, accessToken);
    } else {
      return requests.getWithToken(`${apiUrl}/api/CrmDashboard?StartDate=${fromDate}&EndDate=${toDate}`, accessToken);
    }
  },

  //------------------------------------Visit -------------------------------------------------------------------------------
  createVisit: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmVisitMaster/Create`, data, accessToken);
  },
  updateVisit: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmVisitMaster/Update`, data, accessToken);
  },
  getVisitPrimaryContactAsynclist: (accessToken, searchValue, visitNameId) => {

    let url = `${apiUrl}/odata/CrmContactDropDownList?count=true&$select=DisplayName,Id,ContactEmail`;
    const filterArray = [];
    if (visitNameId && searchValue) {
      url += `&$filter=`;
      const nameFilter = `(contains(tolower(ContactEmail), tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(DisplayName), tolower('${encodeURIComponent(searchValue)}')))and VisitSourceId eq '${encodeURIComponent(visitNameId)}'`
      filterArray.push(nameFilter);
    }
    else if (searchValue) {
      url += `&$filter=`;
      const nameFilter = `(contains(tolower(ContactEmail), tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(DisplayName), tolower('${encodeURIComponent(searchValue)}')))`
      filterArray.push(nameFilter);
    }
    else if (visitNameId) {
      url += `&$filter=VisitSourceId eq '${encodeURIComponent(visitNameId)}'`
    }

    let searchTerm = "";
    if (filterArray.length > 0) {
      searchTerm = filterArray.join(' and ');
    }

    if (searchTerm.length > 0)
      url += `${searchTerm}`;
    return requests.getWithToken(url, accessToken);
  },
  getVisitTypeAsynclist: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmVisitTypeList?count=true&$select=Id,VisitTypeName,IsActive&$filter=IsActive eq true`;
    if (searchValue) {
      url += ` and contains(tolower(VisitTypeName),tolower('${encodeURIComponent(searchValue)}'))`
    }
    return requests.getWithToken(url, accessToken);
  },
  getVisitTypeListAsynclist: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmVisitTypeList?count=true`;
    if (searchValue) {
      url += `&$filter=contains(tolower(VisitTypeName),tolower('${encodeURIComponent(searchValue)}'))`
    }
    return requests.getWithToken(url, accessToken);
  },
  getVisitNameAsynclist: (accessToken, searchValue) => {
    // const filterArray = [];
    let url = `${apiUrl}/odata/CrmVisitSourceList?count=true&$filter=IsActive eq true`;
    if (searchValue) {
      url += ` and (contains(tolower(VisitSourceName), tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(VisitSourceCategory), tolower('${encodeURIComponent(searchValue)}')))`
      // filterArray.push(nameFilter);
    }
    // let searchTerm = "";
    // if (filterArray.length > 0) {
    //   searchTerm = filterArray.join(' and ');
    // }
    // console.log("searchterm:", searchTerm);
    // if (searchTerm.length > 0)
    //   url += `${searchTerm}`;
    return requests.getWithToken(url, accessToken);
  },
  getVisitList: (accessToken, filteredData, currentPage, PageSize = 15, searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName, searchStartDate, searchEndDate, searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp, searchTransportMode, searchFromDate, searchToDate, searchVisitStatus, searchVisitAddress) => {
    currentPage = parseInt(currentPage, 10);

    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filteredData:", filteredData);

    const { toDate, fromDate, VisitSourceName, EndDate, StartDate, OwnerUserEmail, Distance, EndLocation, StartLocation, VisitTypeName, Subject, TransportMode, VisitSourceCategory, CreatedTimeStamp, CreatedBy, PrimaryContactEmail, UpdatedTimeStamp } = filteredData || {};
    let url = `${apiUrl}/odata/CrmVisitMasterList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;

    if (UpdatedTimeStamp || (fromDate && toDate) || VisitSourceName || EndDate || StartDate || OwnerUserEmail || Distance || EndLocation || StartLocation || VisitTypeName || Subject || TransportMode || CreatedBy || VisitSourceCategory || CreatedTimeStamp || PrimaryContactEmail || searchvisitSourceName || searchOwnerUserName || searchPrimaryContactEmail || searchSubject || searchVisitTypeName || searchStartDate || searchEndDate || searchStartLocation || searchEndLocation || searchDistance || searchCreatedBy || searchCreatedTimeStamp || searchTransportMode || (searchFromDate && searchToDate) || searchVisitStatus || searchVisitAddress) {

      url += `&$filter=`;
      const filterArray = [];
      if (searchvisitSourceName || VisitSourceName) {
        if (searchvisitSourceName?.toLowerCase() === 'n/a' || VisitSourceName?.toLowerCase() === 'n/a') {
          filterArray.push('VisitSourceCategory eq null');
        }
        else if (VisitSourceName) {
          const nameFilter = `(contains(tolower(VisitSourceName), tolower('${encodeURIComponent(VisitSourceName)}')) or contains(tolower(VisitSourceCategory), tolower('${encodeURIComponent(VisitSourceName)}')))`
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(VisitSourceName), tolower('${encodeURIComponent(searchvisitSourceName)}')) or contains(tolower(VisitSourceCategory), tolower('${encodeURIComponent(searchvisitSourceName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchCreatedTimeStamp || CreatedTimeStamp || UpdatedTimeStamp) {
        if (searchCreatedTimeStamp?.toLowerCase() === 'n/a' || CreatedTimeStamp?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        }
        else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((CreatedTimeStamp ne null and CreatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and CreatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (UpdatedTimeStamp ne null and UpdatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and UpdatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else if (CreatedTimeStamp) {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(CreatedTimeStamp)}`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedTimeStamp)}`);
        }
      }
      if (searchCreatedBy || CreatedBy) {
        if (searchCreatedBy?.toLowerCase() === 'n/a' || CreatedBy?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        }
        else if (CreatedBy) {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(CreatedBy)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchTransportMode || TransportMode) {
        if (searchTransportMode?.toLowerCase() === 'n/a' || TransportMode?.toLowerCase() === 'n/a') {
          filterArray.push('TransportMode eq null');
        }
        else if (TransportMode) {
          filterArray.push(`contains(tolower(TransportMode),tolower('${encodeURIComponent(TransportMode)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(TransportMode),tolower('${encodeURIComponent(searchTransportMode)}'))`);
        }
      }
      if (searchSubject || Subject) {
        if (searchSubject?.toLowerCase() === 'n/a' || Subject?.toLowerCase() === 'n/a') {
          filterArray.push('Subject eq null');
        }
        else if (Subject) {
          filterArray.push(`contains(tolower(Subject),tolower('${encodeURIComponent(Subject)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Subject),tolower('${encodeURIComponent(searchSubject)}'))`);
        }
      }
      if (searchVisitTypeName || VisitTypeName) {
        if (searchVisitTypeName?.toLowerCase() === 'n/a' || VisitTypeName?.toLowerCase() === 'n/a') {
          filterArray.push('VisitTypeName eq null');
        }
        else if (VisitTypeName) {
          filterArray.push(`VisitTypeName eq '${encodeURIComponent(VisitTypeName)}'`);
        }
        else {
          filterArray.push(`VisitTypeName eq '${encodeURIComponent(searchVisitTypeName)}'`);
        }
      }
      if (searchStartLocation || StartLocation) {
        if (searchStartLocation?.toLowerCase() === 'n/a' || StartLocation?.toLowerCase() === 'n/a') {
          filterArray.push('StartLocation eq null');
        }
        else if (StartLocation) {
          filterArray.push(`contains(tolower(StartLocation),tolower('${encodeURIComponent(StartLocation)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(StartLocation),tolower('${encodeURIComponent(searchStartLocation)}'))`);
        }
      }
      if (searchEndLocation || EndLocation) {
        if (searchEndLocation?.toLowerCase() === 'n/a' || EndLocation?.toLowerCase() === 'n/a') {
          filterArray.push('EndLocation eq null');
        }
        else if (EndLocation) {
          filterArray.push(`contains(tolower(EndLocation),tolower('${encodeURIComponent(EndLocation)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(EndLocation),tolower('${encodeURIComponent(searchEndLocation)}'))`);
        }
      }
      // if (VisitStatus) {
      //   if (searchEndLocation?.toLowerCase() === 'n/a' || EndLocation?.toLowerCase() === 'n/a') {
      //     filterArray.push('EndLocation eq null');
      //   }
      //   else if (EndLocation) {
      //     filterArray.push(`contains(tolower(EndLocation),tolower('${encodeURIComponent(EndLocation)}'))`);
      //   }
      //   else {
      //     filterArray.push(`contains(tolower(EndLocation),tolower('${encodeURIComponent(searchEndLocation)}'))`);
      //   }
      // }
      if (searchDistance || Distance) {
        if (searchDistance?.toLowerCase() === 'n/a' || Distance?.toLowerCase() === 'n/a') {
          filterArray.push('Distance eq null');
        }
        else if (Distance) {
          filterArray.push(`Distance eq ${encodeURIComponent(Distance)}`);
        }
        else {
          filterArray.push(`Distance eq ${encodeURIComponent(searchDistance)}`);
        }
      }

      if (searchOwnerUserName || OwnerUserEmail) {
        if (searchOwnerUserName?.toLowerCase() === 'n/a' || OwnerUserEmail?.toLowerCase() === 'n/a') {
          filterArray.push('(OwnerUserEmail eq null) or (OwnerUserName eq null)');
        }
        else if (OwnerUserEmail) {
          const nameFilter = `(contains(tolower(OwnerUserEmail), tolower('${encodeURIComponent(OwnerUserEmail)}')) or contains(tolower(OwnerUserName), tolower('${encodeURIComponent(OwnerUserEmail)}')))`
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(OwnerUserEmail), tolower('${encodeURIComponent(searchOwnerUserName)}')) or contains(tolower(OwnerUserName), tolower('${encodeURIComponent(searchOwnerUserName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (PrimaryContactEmail) {
        if (PrimaryContactEmail?.toLowerCase() === 'n/a' || PrimaryContactEmail?.toLowerCase() === 'n/a') {
          filterArray.push('(primaryContactName eq null) or (PrimaryContactEmail eq null)');
        }
        else {
          const nameFilter = `(contains(tolower(primaryContactName), tolower('${encodeURIComponent(PrimaryContactEmail)}')) or contains(tolower(PrimaryContactEmail), tolower('${encodeURIComponent(PrimaryContactEmail)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchStartDate || StartDate) {
        if (searchStartDate?.toLowerCase() === 'n/a' || StartDate?.toLowerCase() === 'n/a') {
          filterArray.push('StartDate eq null');
        }
        else if (StartDate) {
          filterArray.push(`StartDate eq ${encodeURIComponent(StartDate)}`);
        }
        else {
          filterArray.push(`StartDate eq ${encodeURIComponent(searchStartDate)}`);
        }
      }
      if (searchEndDate || EndDate) {
        if (searchEndDate?.toLowerCase() === 'n/a' || EndDate?.toLowerCase() === 'n/a') {
          filterArray.push('EndDate eq null');
        }
        else if (EndDate) {
          filterArray.push(`EndDate eq ${encodeURIComponent(EndDate)}`);
        }
        else {
          filterArray.push(`EndDate eq ${encodeURIComponent(searchEndDate)}`);
        }
      }
      if ((searchFromDate && searchToDate) || (fromDate && toDate)) {
        filterArray.push(`((StartDate ge ${encodeURIComponent(searchFromDate || fromDate)} and StartDate le ${encodeURIComponent(searchToDate || toDate)}) or (EndDate ge ${encodeURIComponent(searchFromDate || fromDate)} and EndDate le ${encodeURIComponent(searchToDate || toDate)}))`);
      }
      if (searchVisitStatus) {
        if (searchVisitStatus?.toLowerCase() === 'n/a') {
          filterArray.push('VisitStatus eq null');
        }
        else {
          filterArray.push(`VisitStatus eq '${encodeURIComponent(searchVisitStatus)}'`);
        }
      }
      if (searchVisitAddress) {
        if (searchVisitAddress?.toLowerCase() === 'n/a') {
          filterArray.push('VisitAddress eq null');
        }
        else {
          filterArray.push(`contains(tolower(VisitAddress),tolower('${encodeURIComponent(searchVisitAddress)}'))`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  },

  getVisitExportList: (accessToken, filter, searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName, searchStartDate, searchEndDate, searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp, searchTransportMode, searchFromDate, searchToDate, searchVisitStatus, searchVisitAddress) => {
    let url = `${apiUrl}/CrmVisitMasterList/Export?orderBy=visitSourceName asc`;
    const { CreatedTimeStamp, UpdatedTimeStamp } = filter || {};
    if (CreatedTimeStamp || UpdatedTimeStamp || searchvisitSourceName || searchOwnerUserName || searchPrimaryContactEmail || searchSubject || searchVisitTypeName || searchStartDate || searchEndDate || searchStartLocation || searchEndLocation || searchDistance || searchCreatedBy || searchCreatedTimeStamp || searchTransportMode || (searchFromDate && searchToDate) || searchVisitStatus || searchVisitAddress) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchvisitSourceName) {
        if (searchvisitSourceName.toLowerCase() === 'n/a') {
          filterArray.push('visitSourceCategory eq null');
        } else {
          const nameFilter = `(contains(tolower(visitSourceName), tolower('${encodeURIComponent(searchvisitSourceName)}')) or contains(tolower(visitSourceCategory), tolower('${encodeURIComponent(searchvisitSourceName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchCreatedTimeStamp || CreatedTimeStamp || UpdatedTimeStamp) {
        if (searchCreatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('createdTimeStamp eq null');
        }
        else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((createdTimeStamp ne null and createdTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and createdTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (updatedTimeStamp ne null and updatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and updatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else {
          filterArray.push(`createdTimeStamp eq ${encodeURIComponent(searchCreatedTimeStamp)}`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('createdBy eq null');
        } else {
          filterArray.push(`contains(tolower(createdBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchTransportMode) {
        if (searchTransportMode.toLowerCase() === 'n/a') {
          filterArray.push('transportMode eq null');
        } else {
          filterArray.push(`contains(tolower(transportMode),tolower('${encodeURIComponent(searchTransportMode)}'))`);
        }
      }
      if (searchSubject) {
        if (searchSubject.toLowerCase() === 'n/a') {
          filterArray.push('subject eq null');
        } else {
          filterArray.push(`contains(tolower(subject),tolower('${encodeURIComponent(searchSubject)}'))`);
        }
      }
      if (searchVisitTypeName) {
        if (searchVisitTypeName.toLowerCase() === 'n/a') {
          filterArray.push('visitTypeName eq null');
        } else {
          filterArray.push(`visitTypeName eq '${encodeURIComponent(searchVisitTypeName)}'`);
        }
      }
      if (searchStartLocation) {
        if (searchStartLocation.toLowerCase() === 'n/a') {
          filterArray.push('startLocation eq null');
        } else {
          filterArray.push(`contains(tolower(startLocation),tolower('${encodeURIComponent(searchStartLocation)}'))`);
        }
      }
      if (searchEndLocation) {
        if (searchEndLocation.toLowerCase() === 'n/a') {
          filterArray.push('endLocation eq null');
        } else {
          filterArray.push(`contains(tolower(endLocation),tolower('${encodeURIComponent(searchEndLocation)}'))`);
        }
      }
      if (searchDistance) {
        if (searchDistance.toLowerCase() === 'n/a') {
          filterArray.push('distance eq null');
        } else {
          filterArray.push(`distance eq ${encodeURIComponent(searchDistance)}`);
        }
      }

      if (searchOwnerUserName) {
        if (searchOwnerUserName.toLowerCase() === 'n/a') {
          filterArray.push('(ownerUserEmail eq null) or (ownerUserName eq null)');
        } else {
          const nameFilter = `(contains(tolower(ownerUserEmail), tolower('${encodeURIComponent(searchOwnerUserName)}')) or contains(tolower(ownerUserName), tolower('${encodeURIComponent(searchOwnerUserName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchStartDate) {
        if (searchStartDate.toLowerCase() === 'n/a') {
          filterArray.push('startDate eq null');
        } else {
          filterArray.push(`startDate eq ${encodeURIComponent(searchStartDate)}`);
        }
      }
      if (searchEndDate) {
        if (searchEndDate.toLowerCase() === 'n/a') {
          filterArray.push('endDate eq null');
        } else {
          filterArray.push(`endDate eq ${encodeURIComponent(searchEndDate)}`);
        }
      }
      if (searchFromDate && searchToDate) {
        filterArray.push(`((startDate ge ${encodeURIComponent(searchFromDate)} and startDate le ${encodeURIComponent(searchToDate)}) or (endDate ge ${encodeURIComponent(searchFromDate)} and endDate le ${encodeURIComponent(searchToDate)}))`);
      }
      if (searchPrimaryContactEmail) {
        if (searchPrimaryContactEmail?.toLowerCase() === 'n/a') {
          filterArray.push('(primaryContactName eq null) or (PrimaryContactEmail eq null)');
        }
        else {
          const nameFilter = `(contains(tolower(primaryContactName), tolower('${encodeURIComponent(searchPrimaryContactEmail)}')) or contains(tolower(PrimaryContactEmail), tolower('${encodeURIComponent(searchPrimaryContactEmail)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchVisitStatus) {
        if (searchVisitStatus?.toLowerCase() === 'n/a') {
          filterArray.push('VisitStatus eq null');
        }
        else {
          filterArray.push(`VisitStatus eq '${encodeURIComponent(searchVisitStatus)}'`);
        }
      }
      if (searchVisitAddress) {
        if (searchVisitAddress?.toLowerCase() === 'n/a') {
          filterArray.push('VisitAddress eq null');
        }
        else {
          filterArray.push(`contains(tolower(VisitAddress),tolower('${encodeURIComponent(searchVisitAddress)}'))`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
    }
    return requests.getWithToken(url, accessToken);
  }
  ,
  getVisitDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/CrmVisitMaster/Details?VisitId=${id}`, accessToken);
  },
  //----------------------------------ACCOUNTS--------------------------------------------------------------------------------
  createAccount: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmAccountsMaster/Create`, data, accessToken);
  },
  getLeadDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/CrmLeadMaster/Details?LeadMasterId=${id}`, accessToken);
  },

  getAccountExportList: (accessToken, filter, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo, searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue, searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo) => {
    const { CreatedTimeStamp, UpdatedTimeStamp } = filter || {};
    let url = `${apiUrl}/CrmAccountsMasterList/Export?$count=true&$orderby=companyName asc`;
    if (UpdatedTimeStamp || CreatedTimeStamp || searchCompanyName || searchAccountOwnerUserEmail || searchOrgName || searchRegionName || searchWebsite || searchPhoneNo || searchIsActive || searchIndustryName || searchResponsibility || searchGst || searchAccountCategoryValue || searchAccountCategoryName || searchDesignation || searchCreatedDate || searchCreatedBy || searchAddress1 || searchCity || searchState || searchCountry || searchCode || searchAccountContact || searchLandLineNo) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchDesignation) {
        if (searchDesignation.toLowerCase() === 'n/a') {
          filterArray.push('Designation eq null');
        } else {
          filterArray.push(`contains(tolower(Designation),tolower('${encodeURIComponent(searchDesignation)}'))`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedDate || CreatedTimeStamp || UpdatedTimeStamp) {
        if (searchCreatedDate.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        }
        else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((CreatedTimeStamp ne null and CreatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and CreatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (UpdatedTimeStamp ne null and UpdatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and UpdatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedDate)}`);
        }
      }
      if (searchIsActive) {
        if (searchIsActive.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(searchIsActive)}`);
        }
      }
      if (searchPhoneNo) {
        if (searchPhoneNo.toLowerCase() === 'n/a') {
          filterArray.push('PhoneNo eq null');
        } else {
          filterArray.push(`contains(tolower(PhoneNo),tolower('${encodeURIComponent(searchPhoneNo)}'))`);
        }
      }
      if (searchAccountCategoryValue) {
        if (searchAccountCategoryValue.toLowerCase() === 'n/a') {
          filterArray.push('AccountCategoryValue eq null');
        } else {
          filterArray.push(`contains(tolower(AccountCategoryValue),tolower('${encodeURIComponent(searchAccountCategoryValue)}'))`);
        }
      }
      if (searchAccountCategoryName) {
        if (searchAccountCategoryName.toLowerCase() === 'n/a') {
          filterArray.push('AccountCategoryName eq null');
        } else {
          filterArray.push(`contains(tolower(AccountCategoryName),tolower('${encodeURIComponent(searchAccountCategoryName)}'))`);
        }
      }
      if (searchGst) {
        if (searchGst.toLowerCase() === 'n/a') {
          filterArray.push('Gst eq null');
        } else {
          filterArray.push(`contains(tolower(Gst),tolower('${encodeURIComponent(searchGst)}'))`);
        }
      }
      if (searchIndustryName) {
        if (searchIndustryName.toLowerCase() === 'n/a') {
          filterArray.push('IndustryName eq null');
        } else {
          filterArray.push(`contains(tolower(IndustryName),tolower('${encodeURIComponent(searchIndustryName)}'))`);
        }
      }
      if (searchResponsibility) {
        if (searchResponsibility.toLowerCase() === 'n/a') {
          filterArray.push('Responsibility eq null');
        } else {
          filterArray.push(`contains(tolower(Responsibility),tolower('${encodeURIComponent(searchResponsibility)}'))`);
        }
      }
      if (searchCompanyName) {
        if (searchCompanyName.toLowerCase() === 'n/a') {
          filterArray.push('CompanyEmail eq null');
        } else {
          // filterArray.push(`contains(tolower(CompanyEmail),tolower('${searchCompanyName}'))`);
          const nameFilter = `(contains(tolower(CompanyEmail), tolower('${encodeURIComponent(searchCompanyName)}')) or contains(tolower(CompanyName), tolower('${encodeURIComponent(searchCompanyName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchAccountOwnerUserEmail) {
        if (searchAccountOwnerUserEmail.toLowerCase() === 'n/a') {
          filterArray.push('AccountOwnerUserEmail eq null');
        } else {
          // filterArray.push(`contains(tolower(CompanyEmail),tolower('${searchCompanyName}'))`);
          const nameFilter = `(contains(tolower(AccountOwnerUserEmail), tolower('${encodeURIComponent(searchAccountOwnerUserEmail)}')) or contains(tolower(AccountOwnerUserName), tolower('${encodeURIComponent(searchAccountOwnerUserEmail)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchOrgName) {
        if (searchOrgName.toLowerCase() === 'n/a') {
          filterArray.push('OrgName eq null');
        } else {
          filterArray.push(`contains(tolower(OrgName),tolower('${encodeURIComponent(searchOrgName)}'))`);
        }
      }
      if (searchRegionName) {
        if (searchRegionName.toLowerCase() === 'n/a') {
          filterArray.push('RegionName eq null');
        } else {
          filterArray.push(`contains(tolower(RegionName),tolower('${encodeURIComponent(searchRegionName)}'))`);
        }
      }
      if (searchWebsite) {
        if (searchWebsite.toLowerCase() === 'n/a') {
          filterArray.push('Website eq null');
        } else {
          filterArray.push(`contains(tolower(Website),tolower('${encodeURIComponent(searchWebsite)}'))`);
        }
      }
      if (searchCity) {
        if (searchCity.toLowerCase() === 'n/a') {
          filterArray.push('City eq null');
        } else {
          filterArray.push(`contains(tolower(City),tolower('${encodeURIComponent(searchCity)}'))`);
        }
      }
      if (searchState) {
        if (searchState.toLowerCase() === 'n/a') {
          filterArray.push('State eq null');
        } else {
          filterArray.push(`contains(tolower(State),tolower('${encodeURIComponent(searchState)}'))`);
        }
      }
      if (searchCountry) {
        if (searchCountry.toLowerCase() === 'n/a') {
          filterArray.push('Country eq null');
        } else {
          filterArray.push(`contains(tolower(Country),tolower('${encodeURIComponent(searchCountry)}'))`);
        }
      }
      if (searchCode) {
        if (searchCode.toLowerCase() === 'n/a') {
          filterArray.push('Code eq null');
        } else {
          filterArray.push(`contains(tolower(Code),tolower('${encodeURIComponent(searchCode)}'))`);
        }
      }
      if (searchAddress1) {
        if (searchAddress1.toLowerCase() === 'n/a') {
          filterArray.push('Address1 eq null');
        } else {
          filterArray.push(`contains(tolower(Address1),tolower('${encodeURIComponent(searchAddress1)}'))`);
        }
      }
      if (searchAccountContact) {
        if (searchAccountContact.toLowerCase() === 'n/a') {
          filterArray.push('AccountContact eq null');
        } else {
          filterArray.push(`contains(tolower(AccountContact),tolower('${encodeURIComponent(searchAccountContact)}'))`);
        }
      }
      if (searchLandLineNo) {
        if (searchLandLineNo.toLowerCase() === 'n/a') {
          filterArray.push('LandLineNo eq null');
        } else {
          filterArray.push(`contains(tolower(LandLineNo),tolower('${encodeURIComponent(searchLandLineNo)}'))`);
        }
      }
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },

  getAccountsList: (accessToken, currentPage, PageSize = 15, filter, searchCompanyName, searchAccountOwnerUserEmail, searchOrgName, searchRegionName, searchWebsite, searchPhoneNo, searchIsActive, searchIndustryName, searchDesignation, searchResponsibility, searchGst, searchAccountCategoryValue, searchAccountCategoryName, searchCreatedDate, searchCreatedBy, searchAddress1, searchCity, searchState, searchCountry, searchCode, searchAccountContact, searchLandLineNo) => {

    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log('searchCreatedBy:', searchCreatedBy);

    const { CreatedTimeStamp, UpdatedTimeStamp, Designation, CreatedBy, IsActive, PhoneNo, AccountCategoryValue, AccountCategoryName, Gst, IndustryName, Responsibility, CompanyEmail, AccountOwnerUserEmail, OrgName, RegionName, Website, City, State, Country, Code, Address1, AccountContact, LandLineNo } = filter || {};
    let url = `${apiUrl}/odata/CrmAccountsMasterList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    if (CreatedTimeStamp || UpdatedTimeStamp || Designation || CreatedBy || IsActive || PhoneNo || AccountCategoryValue || AccountCategoryName || Gst || IndustryName || Responsibility || CompanyEmail || AccountOwnerUserEmail || OrgName || RegionName || Website || City || State || Country || Code || Address1 || AccountContact || LandLineNo) {
      url += `&$filter=`;
      const filterArray = [];
      if (Designation) {
        if (Designation?.toLowerCase() === 'n/a') {
          filterArray.push('Designation eq null');
        } else {
          filterArray.push(`contains(tolower(Designation),tolower('${encodeURIComponent(Designation)}'))`);
        }
      }
      if (CreatedBy) {
        if (CreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(CreatedBy)}'))`);
        }
      }
      if (searchCreatedDate || CreatedTimeStamp) {
        if (CreatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        }
        else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((CreatedTimeStamp ne null and CreatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and CreatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (UpdatedTimeStamp ne null and UpdatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and UpdatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(CreatedTimeStamp)}`);
        }
      }
      if (IsActive) {
        if (IsActive.toLowerCase() === 'n/a') {
          filterArray.push('IsActive eq null');
        } else {
          filterArray.push(`IsActive eq ${encodeURIComponent(IsActive)}`);
        }
      }
      if (PhoneNo) {
        if (PhoneNo.toLowerCase() === 'n/a') {
          filterArray.push('PhoneNo eq null');
        } else {
          filterArray.push(`contains(tolower(PhoneNo),tolower('${encodeURIComponent(PhoneNo)}'))`);
        }
      }
      if (AccountCategoryValue) {
        if (AccountCategoryValue.toLowerCase() === 'n/a') {
          filterArray.push('AccountCategoryValue eq null');
        } else {
          filterArray.push(`contains(tolower(AccountCategoryValue),tolower('${encodeURIComponent(AccountCategoryValue)}'))`);
        }
      }
      if (AccountCategoryName) {
        if (AccountCategoryName.toLowerCase() === 'n/a') {
          filterArray.push('AccountCategoryName eq null');
        } else {
          filterArray.push(`contains(tolower(AccountCategoryName),tolower('${encodeURIComponent(AccountCategoryName)}'))`);
        }
      }
      if (Gst) {
        if (Gst.toLowerCase() === 'n/a') {
          filterArray.push('Gst eq null');
        } else {
          filterArray.push(`contains(tolower(Gst),tolower('${encodeURIComponent(Gst)}'))`);
        }
      }
      if (IndustryName) {
        if (IndustryName.toLowerCase() === 'n/a') {
          filterArray.push('IndustryName eq null');
        } else {
          filterArray.push(`contains(tolower(IndustryName),tolower('${encodeURIComponent(IndustryName)}'))`);
        }
      }
      if (Responsibility) {
        if (Responsibility.toLowerCase() === 'n/a') {
          filterArray.push('Responsibility eq null');
        } else {
          filterArray.push(`contains(tolower(Responsibility),tolower('${encodeURIComponent(Responsibility)}'))`);
        }
      }
      if (CompanyEmail) {
        if (CompanyEmail.toLowerCase() === 'n/a') {
          filterArray.push('CompanyEmail eq null');
        } else {
          // filterArray.push(`contains(tolower(CompanyEmail),tolower('${searchCompanyName}'))`);
          const nameFilter = `(contains(tolower(CompanyEmail), tolower('${encodeURIComponent(CompanyEmail)}')) or contains(tolower(CompanyName), tolower('${encodeURIComponent(CompanyEmail)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (AccountOwnerUserEmail) {
        if (AccountOwnerUserEmail.toLowerCase() === 'n/a') {
          filterArray.push('AccountOwnerUserEmail eq null');
        } else {
          // filterArray.push(`contains(tolower(CompanyEmail),tolower('${searchCompanyName}'))`);
          const nameFilter = `(contains(tolower(AccountOwnerUserEmail), tolower('${encodeURIComponent(AccountOwnerUserEmail)}')) or contains(tolower(AccountOwnerUserName), tolower('${encodeURIComponent(AccountOwnerUserEmail)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (OrgName) {
        if (OrgName.toLowerCase() === 'n/a') {
          filterArray.push('OrgName eq null');
        } else {
          filterArray.push(`contains(tolower(OrgName),tolower('${encodeURIComponent(OrgName)}'))`);
        }
      }
      if (RegionName) {
        if (RegionName.toLowerCase() === 'n/a') {
          filterArray.push('RegionName eq null');
        } else {
          filterArray.push(`contains(tolower(RegionName),tolower('${encodeURIComponent(RegionName)}'))`);
        }
      }
      if (Website) {
        if (Website.toLowerCase() === 'n/a') {
          filterArray.push('Website eq null');
        } else {
          filterArray.push(`contains(tolower(Website),tolower('${encodeURIComponent(Website)}'))`);
        }
      }
      if (City) {
        if (City.toLowerCase() === 'n/a') {
          filterArray.push('City eq null');
        } else {
          filterArray.push(`contains(tolower(City),tolower('${encodeURIComponent(City)}'))`);
        }
      }
      if (State) {
        if (State.toLowerCase() === 'n/a') {
          filterArray.push('State eq null');
        } else {
          filterArray.push(`contains(tolower(State),tolower('${encodeURIComponent(State)}'))`);
        }
      }
      if (Country) {
        if (Country.toLowerCase() === 'n/a') {
          filterArray.push('Country eq null');
        } else {
          filterArray.push(`contains(tolower(Country),tolower('${encodeURIComponent(Country)}'))`);
        }
      }
      if (Code) {
        if (Code.toLowerCase() === 'n/a') {
          filterArray.push('Code eq null');
        } else {
          filterArray.push(`contains(tolower(Code),tolower('${encodeURIComponent(Code)}'))`);
        }
      }
      if (Address1) {
        if (Address1.toLowerCase() === 'n/a') {
          filterArray.push('Address1 eq null');
        } else {
          filterArray.push(`contains(tolower(Address1),tolower('${encodeURIComponent(Address1)}'))`);
        }
      }
      if (AccountContact) {
        if (AccountContact.toLowerCase() === 'n/a') {
          filterArray.push('AccountContact eq null');
        } else {
          filterArray.push(`contains(tolower(AccountContact),tolower('${encodeURIComponent(AccountContact)}'))`);
        }
      }
      if (LandLineNo) {
        if (LandLineNo.toLowerCase() === 'n/a') {
          filterArray.push('LandLineNo eq null');
        } else {
          filterArray.push(`contains(tolower(LandLineNo),tolower('${encodeURIComponent(LandLineNo)}'))`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  importAccounts: (accessToken, data) => {
    const formData = new FormData();
    formData.append("AccountFile", data);
    return requests.postMultiPartWithToken(`${apiUrl}/api/CrmAccountsMaster/Import/Create`, formData, accessToken);
  },
  getAccountDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/CrmAccountsMaster/Details?CompanyId=${id}`, accessToken);
  },
  updateAccount: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmAccountsMaster/Update`, data, accessToken);
  },
  getCompanyName: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmLeadMasterDropDownList?count=true&$select=LeadName,Id&$filter=LeadStatus eq 'Active'`;
    if (searchValue) {
      url += ` and contains(tolower(LeadName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getIndustryName: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgIndustryList?count=true&$select=Id,Name,IsActive`;
    if (searchValue) {
      url += `&$filter=contains(tolower(Name),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getAccountCategoryName: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmAccountCategoryList?count=true&$select=Id,TypeName,Range`;
    if (searchValue) {
      url += `&$filter=contains(tolower(TypeName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getAccountCategoryValue: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmAccountCategoryList?count=true`;
    if (searchValue) {
      url += `&$filter=contains(tolower(Range),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getRegionName: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgRegionList?count=true&$select=Id,RegionName,IsActive`;
    if (searchValue) {
      url += `&$filter=contains(tolower(RegionName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getLeadDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/CrmLeadMaster/Details?LeadMasterId=${id}`, accessToken);
  },

  getAccountContactLists: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/odata/CrmContactList?count=true&$filter=CompanyId eq '${id}'`, accessToken);
  },
  //----------------------------------CONTACTS--------------------------------------------------------------------------------
  createContacts: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmContactMaster/Create`, data, accessToken);
  },

  getContactsExportList: (accessToken, filter, searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy) => {
    const { UpdatedTimeStamp, CreatedTimeStamp } = filter || {};
    let url = `${apiUrl}/CrmContactList/Export?$count=true&$orderby=displayName asc`;

    if (CreatedTimeStamp || UpdatedTimeStamp || searchDisplayName || searchCompanyName || searchEmail || searchPhoneNo || searchTitle || searchDesignation || searchDepartment || searchLandLineNo || searchCity || searchCreatedDate || searchCreatedBy) {

      url += `&$filter=`;
      const filterArray = [];
      if (searchDepartment) {
        if (searchDepartment.toLowerCase() === 'n/a') {
          filterArray.push('Department eq null');
        } else {
          filterArray.push(`contains(tolower(Department),tolower('${encodeURIComponent(searchDepartment)}'))`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedDate || UpdatedTimeStamp || CreatedTimeStamp) {
        if (searchCreatedDate.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        } else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((CreatedTimeStamp ne null and CreatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and CreatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (UpdatedTimeStamp ne null and UpdatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and UpdatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedDate)}`);
        }
      }
      if (searchDesignation) {
        if (searchDesignation.toLowerCase() === 'n/a') {
          filterArray.push('Designation eq null');
        } else {
          filterArray.push(`contains(tolower(Designation),tolower('${encodeURIComponent(searchDesignation)}'))`);
        }
      }
      if (searchLandLineNo) {
        if (searchLandLineNo.toLowerCase() === 'n/a') {
          filterArray.push('LandLineNo eq null');
        } else {
          filterArray.push(`contains(tolower(LandLineNo),tolower('${encodeURIComponent(searchLandLineNo)}'))`);
        }
      }
      if (searchTitle) {
        if (searchTitle.toLowerCase() === 'n/a') {
          filterArray.push('Title eq null');
        } else {
          filterArray.push(`contains(tolower(Title),tolower('${encodeURIComponent(searchTitle)}'))`);
        }
      }
      if (searchDisplayName) {
        if (searchDisplayName.toLowerCase() === 'n/a') {
          filterArray.push('DisplayName eq null');
        } else {
          filterArray.push(`contains(tolower(DisplayName),tolower('${encodeURIComponent(searchDisplayName)}'))`);
        }
      }
      if (searchCompanyName) {
        if (searchCompanyName.toLowerCase() === 'n/a') {
          filterArray.push('CompanyEmail eq null');
        } else {
          // filterArray.push(`contains(tolower(CompanyEmail),tolower('${searchCompanyName}'))`);
          const nameFilter = `(contains(tolower(CompanyEmail), tolower('${encodeURIComponent(searchCompanyName)}')) or contains(tolower(CompanyName), tolower('${encodeURIComponent(searchCompanyName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchEmail) {
        if (searchEmail.toLowerCase() === 'n/a') {
          filterArray.push('ContactEmail eq null');
        } else {
          // filterArray.push(`contains(tolower(Email),tolower('${searchEmail}'))`);
          // const nameFilter = `(contains(tolower(ContactEmail), tolower('${searchEmail}')) or contains(tolower(DisplayName), tolower('${searchEmail}')))`
          const nameFilter = `(contains(tolower(ContactEmail), tolower('${encodeURIComponent(searchEmail)}')) or contains(tolower(DisplayName), tolower('${encodeURIComponent(searchEmail)}')) or contains(tolower(Title),tolower('${encodeURIComponent(searchEmail)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchPhoneNo) {
        if (searchPhoneNo.toLowerCase() === 'n/a') {
          filterArray.push('PhoneNo eq null');
        } else {
          filterArray.push(`contains(tolower(PhoneNo),tolower('${encodeURIComponent(searchPhoneNo)}'))`);
        }
      }
      if (searchCity) {
        if (searchCity.toLowerCase() === 'n/a') {
          filterArray.push('City eq null');
        } else {
          filterArray.push(`contains(tolower(City),tolower('${encodeURIComponent(searchCity)}'))`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getContactsList: (accessToken, filteredData, currentPage, PageSize = 15, searchDisplayName, searchCompanyName, searchEmail, searchPhoneNo, searchTitle, searchDesignation, searchDepartment, searchLandLineNo, searchCity, searchCreatedDate, searchCreatedBy) => {
    currentPage = parseInt(currentPage, 10);
    const { Department, CreatedBy, CreatedTimeStamp, Designation, LandLineNo, Title, DisplayName, CompanyEmail, ContactEmail,
      PhoneNo, City, UpdatedTimeStamp } = filteredData || {};
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filteredData:", filteredData);

    let url = `${apiUrl}/odata/CrmContactList?count=true&$orderby=CreatedTimeStamp desc&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}`;

    if (City || PhoneNo || ContactEmail || CompanyEmail || DisplayName || Title || LandLineNo || Designation || Department || CreatedBy || CreatedTimeStamp || searchDisplayName ||
      searchCompanyName || searchEmail || searchPhoneNo || searchTitle || searchDesignation || searchDepartment || searchLandLineNo ||
      searchCity || searchCreatedDate || searchCreatedBy) {

      url += `&$filter=`;
      const filterArray = [];
      if (searchDepartment || Department) {
        if (searchDepartment?.toLowerCase() === 'n/a' || Department?.toLowerCase() === 'n/a') {
          filterArray.push('Department eq null');
        }
        else if (Department) {
          filterArray.push(`contains(tolower(Department),tolower('${encodeURIComponent(Department)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Department),tolower('${encodeURIComponent(searchDepartment)}'))`);
        }
      }
      if (searchCreatedBy || CreatedBy) {
        if (searchCreatedBy?.toLowerCase() === 'n/a' || CreatedBy?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        }
        else if (CreatedBy) {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(CreatedBy)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedDate || CreatedTimeStamp) {
        if (searchCreatedDate?.toLowerCase() === 'n/a' || CreatedTimeStamp?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        }
        else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((CreatedTimeStamp ne null and CreatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and CreatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (UpdatedTimeStamp ne null and UpdatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and UpdatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else if (CreatedTimeStamp) {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(CreatedTimeStamp)}`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedDate)}`);
        }
      }
      if (searchDesignation || Designation) {
        if (searchDesignation?.toLowerCase() === 'n/a' || Designation?.toLowerCase() === 'n/a') {
          filterArray.push('Designation eq null');
        }
        else if (Designation) {
          filterArray.push(`contains(tolower(Designation),tolower('${encodeURIComponent(Designation)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Designation),tolower('${encodeURIComponent(searchDesignation)}'))`);
        }
      }
      if (searchLandLineNo || LandLineNo) {
        if (searchLandLineNo?.toLowerCase() === 'n/a' || LandLineNo?.toLowerCase() === 'n/a') {
          filterArray.push('LandLineNo eq null');
        }
        else if (LandLineNo) {
          filterArray.push(`contains(tolower(LandLineNo),tolower('${encodeURIComponent(LandLineNo)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LandLineNo),tolower('${encodeURIComponent(searchLandLineNo)}'))`);
        }
      }
      if (searchTitle || Title) {
        if (searchTitle?.toLowerCase() === 'n/a' || Title?.toLowerCase() === 'n/a') {
          filterArray.push('Title eq null');
        }
        else if (Title) {
          filterArray.push(`contains(tolower(Title),tolower('${encodeURIComponent(Title)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Title),tolower('${encodeURIComponent(searchTitle)}'))`);
        }
      }
      if (searchDisplayName || DisplayName) {
        if (searchDisplayName?.toLowerCase() === 'n/a' || DisplayName?.toLowerCase() === 'n/a') {
          filterArray.push('DisplayName eq null');
        }
        else if (DisplayName) {
          filterArray.push(`contains(tolower(DisplayName),tolower('${encodeURIComponent(DisplayName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(DisplayName),tolower('${encodeURIComponent(searchDisplayName)}'))`);
        }
      }
      if (searchCompanyName || CompanyEmail) {
        if (searchCompanyName?.toLowerCase() === 'n/a' || CompanyEmail?.toLowerCase() === 'n/a') {
          filterArray.push('CompanyEmail eq null');
        }
        else if (CompanyEmail) {
          const nameFilter = `(contains(tolower(CompanyEmail), tolower('${encodeURIComponent(CompanyEmail)}')) or contains(tolower(CompanyName), tolower('${encodeURIComponent(CompanyEmail)}')))`
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(CompanyEmail), tolower('${encodeURIComponent(searchCompanyName)}')) or contains(tolower(CompanyName), tolower('${encodeURIComponent(searchCompanyName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchEmail || ContactEmail) {
        if (searchEmail?.toLowerCase() === 'n/a' || ContactEmail?.toLowerCase() === 'n/a') {
          filterArray.push('ContactEmail eq null');
        }
        else if (ContactEmail) {
          const nameFilter = `(contains(tolower(ContactEmail), tolower('${encodeURIComponent(ContactEmail)}')) or contains(tolower(DisplayName), tolower('${encodeURIComponent(ContactEmail)}')) or contains(tolower(Title),tolower('${encodeURIComponent(ContactEmail)}')))`
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(ContactEmail), tolower('${encodeURIComponent(searchEmail)}')) or contains(tolower(DisplayName), tolower('${encodeURIComponent(searchEmail)}')) or contains(tolower(Title),tolower('${encodeURIComponent(searchEmail)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchPhoneNo || PhoneNo) {
        if (searchPhoneNo?.toLowerCase() === 'n/a' || PhoneNo?.toLowerCase() === 'n/a') {
          filterArray.push('PhoneNo eq null');
        }
        else if (PhoneNo) {
          filterArray.push(`contains(tolower(PhoneNo),tolower('${encodeURIComponent(PhoneNo)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(PhoneNo),tolower('${encodeURIComponent(searchPhoneNo)}'))`);
        }
      }
      if (searchCity || City) {
        if (searchCity?.toLowerCase() === 'n/a' || City?.toLowerCase() === 'n/a') {
          filterArray.push('City eq null');
        }
        else if (City) {
          filterArray.push(`contains(tolower(City),tolower('${encodeURIComponent(City)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(City),tolower('${encodeURIComponent(searchCity)}'))`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getTypeAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgOppTypeList?count=true&$select=IsActive,Id,OppTypeName`;
    if (searchValue) {
      url += `&$filter=contains(tolower(OppTypeName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getActionAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgOppActionList?count=true&$select=Id,IsActive,OppActionName`;
    if (searchValue) {
      url += `&$filter=contains(tolower(OppActionName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getCurrentPhaseAsync: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgOppCurrentPhaseList?count=true&$select=Id,PhaseName,Value,IsActive`;
    if (searchValue) {
      url += `&$filter=contains(tolower(PhaseName),tolower('${encodeURIComponent(searchValue)}'))`
    }
    return requests.getWithToken(url, accessToken);
  },
  getMainCategoryAsync: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/OrgOppMainCategoriesList?count=true`;
    if (searchValue) {
      url += `&$filter=contains(tolower(OppCategory),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getAccountsAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmAccountMasterDropDownList?count=true&$select=CompanyName,Id,IsActive`;
    if (searchValue) {
      url += `&$filter=contains(tolower(CompanyName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getContactsAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmContactList?count=true`;
    if (searchValue) {
      url += `&$filter=contains(tolower(CompanyName),tolower('${encodeURIComponent(searchValue)}'))`
    }
    return requests.getWithToken(url, accessToken);
  },
  getContactAsyncUserlist: (accessToken, Id, searchValue) => {
    let url = `${apiUrl}/odata/CrmContactList?count=true&$select=Id,FirstName,ContactEmail,DisplayName,CompanyId&$filter=contains(tolower(CompanyId),tolower('${Id}'))`;
    if (searchValue) {
      url += ` and (contains(tolower(ContactEmail),tolower('${encodeURIComponent(searchValue)}')) or contains(tolower(DisplayName),tolower('${encodeURIComponent(searchValue)}')))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getSubCategoryAsyncList: (accessToken, oppCategory, searchValue) => {
    let url = `${apiUrl}/odata/OrgOppCategoryList?count=true&$select=OppSubCategory,Id,IsActive&$filter=contains(tolower(OppCategory),tolower('${oppCategory}'))`;
    if (searchValue) {
      url += ` and contains(tolower(OppSubCategory),tolower('${encodeURIComponent(searchValue)}'))`;
    }

    return requests.getWithToken(url, accessToken);
  },
  getContactsDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/CrmContactMaster/Details?ContactId=${id}`, accessToken);
  },
  updateContacts: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmContactMaster/Update`, data, accessToken);
  },
  //----------------------------------LEADS--------------------------------------------------------------------------------
  getCrmUserAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmUserList?$count=true&$select=Id,DisplayName,UserEmail`;
    if (searchValue) {
      url += `&$filter=contains(tolower(DisplayName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getLeadCategoryAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgLeadCategoryList?$count=true&$select=Id,LeadCategoryName&$filter=(IsActive eq true)`;
    if (searchValue) {
      url += `and (contains(tolower(LeadCategoryName),tolower('${encodeURIComponent(searchValue)}')))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getLeadSourceAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgLeadSourceList?$count=true&$select=LeadSourceName,Id&$filter=(IsActive eq true)`
    if (searchValue) {
      url += ` and (contains(tolower(LeadSourceName),tolower('${encodeURIComponent(searchValue)}')))`
    }
    return requests.getWithToken(url, accessToken);
  },
  getLeadFollowActionAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgFollowUpActionList?$count=true&$select=FollowUpActionName,Id&$filter=(IsActive eq true)`;
    if (searchValue) {
      url += ` and (contains(tolower(FollowUpActionName),tolower('${encodeURIComponent(searchValue)}')))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  createLeads: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmLeadMaster/Create`, data, accessToken);
  },
  importLeads: (accessToken, data) => {
    const formData = new FormData();
    formData.append("LeadFile", data);
    return requests.postMultiPartWithToken(`${apiUrl}/api/CrmLeadMaster/Import/Create`, formData, accessToken);
  },
  getLeadsList: (accessToken, filteredData, currentPage, PageSize = 15, searchLeadName, searchLeadContact,
    searchLeadSource, searchEmail, searchPhoneNo, searchLeadOwnerUserName,
    searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName,
    searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy) => {
    currentPage = parseInt(currentPage, 10);

    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    console.log("filteredData:", filteredData);

    const { leadStatus, LeadOwnerUserEmail, LeadOwnerUserName, PhoneNo, email, LeadSourceName, leadContact, CreatedBy,
      LeadResponsibility, LeadDesignation, LeadWebsite, FollowUpActionName, CategoryName, QualificationLevel, CreatedTimeStamp,
      LeadName, UpdatedTimeStamp } = filteredData || {};

    let url = `${apiUrl}/odata/CrmLeadMasterList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;

    if (UpdatedTimeStamp || leadStatus || LeadOwnerUserEmail || PhoneNo || email || LeadResponsibility || CreatedBy || leadContact || LeadWebsite ||
      LeadDesignation || LeadSourceName || FollowUpActionName || CategoryName || QualificationLevel || LeadName ||
      CreatedTimeStamp || LeadOwnerUserName || searchLeadName || searchLeadContact || searchEmail || searchPhoneNo ||
      searchLeadOwnerUserName || searchLeadSource || searchLeadOwnerUserEmail || searchLeadStatus || searchQualificationLevel
      || searchCategoryName || searchFollowUpActionName || searchLeadWebsite || searchLeadDesignation || searchLeadResponsibility
      || searchCreatedDate || searchCreatedBy) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchCreatedBy || CreatedBy) {
        if (searchCreatedBy?.toLowerCase() === 'n/a' || CreatedBy?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        }
        else if (CreatedBy) {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(CreatedBy)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchLeadResponsibility || LeadResponsibility) {
        if (searchLeadResponsibility?.toLowerCase() === 'n/a' || LeadResponsibility?.toLowerCase() === 'n/a') {
          filterArray.push('LeadResponsibility eq null');
        }
        else if (LeadResponsibility) {
          filterArray.push(`contains(tolower(LeadResponsibility),tolower('${encodeURIComponent(LeadResponsibility)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeadResponsibility),tolower('${encodeURIComponent(searchLeadResponsibility)}'))`);
        }
      }
      if (searchLeadDesignation || LeadDesignation) {
        if (searchLeadDesignation?.toLowerCase() === 'n/a' || LeadDesignation?.toLowerCase() === 'n/a') {
          filterArray.push('LeadDesignation eq null');
        }
        else if (LeadDesignation) {
          filterArray.push(`contains(tolower(LeadDesignation),tolower('${encodeURIComponent(LeadDesignation)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeadDesignation),tolower('${encodeURIComponent(searchLeadDesignation)}'))`);
        }
      }
      if (searchLeadWebsite || LeadWebsite) {
        if (searchLeadWebsite?.toLowerCase() === 'n/a' || LeadWebsite?.toLowerCase() === 'n/a') {
          filterArray.push('LeadWebsite eq null');
        }
        else if (LeadWebsite) {
          filterArray.push(`contains(tolower(LeadWebsite),tolower('${encodeURIComponent(LeadWebsite)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeadWebsite),tolower('${encodeURIComponent(searchLeadWebsite)}'))`);
        }
      }
      if (searchFollowUpActionName || FollowUpActionName) {
        if (searchFollowUpActionName?.toLowerCase() === 'n/a' || FollowUpActionName?.toLowerCase() === 'n/a') {
          filterArray.push('FollowUpActionName eq null');
        }
        else if (FollowUpActionName) {
          filterArray.push(`FollowUpActionName eq '${encodeURIComponent(FollowUpActionName)}'`);
        }
        else {
          filterArray.push(`FollowUpActionName eq '${encodeURIComponent(searchFollowUpActionName)}'`);
        }
      }
      if (searchCategoryName || CategoryName) {
        if (searchCategoryName?.toLowerCase() === 'n/a' || CategoryName?.toLowerCase() === 'n/a') {
          filterArray.push('CategoryName eq null');
        }
        else if (CategoryName) {
          filterArray.push(`CategoryName eq '${encodeURIComponent(CategoryName)}'`);
        }
        else {
          filterArray.push(`CategoryName eq '${encodeURIComponent(searchCategoryName)}'`);
        }
      }
      if (searchQualificationLevel || QualificationLevel) {
        if (searchQualificationLevel?.toLowerCase() === 'n/a' || QualificationLevel?.toLowerCase() === 'n/a') {
          filterArray.push('QualificationLevel eq null');
        }
        else if (QualificationLevel) {
          filterArray.push(`QualificationLevel eq '${encodeURIComponent(QualificationLevel)}'`);
        }
        else {
          filterArray.push(`QualificationLevel eq '${encodeURIComponent(searchQualificationLevel)}'`);
        }
      }
      if (searchCreatedDate || CreatedTimeStamp || UpdatedTimeStamp) {
        if (searchCreatedDate?.toLowerCase() === 'n/a' || CreatedTimeStamp?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        }
        else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((CreatedTimeStamp ne null and CreatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and CreatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (UpdatedTimeStamp ne null and UpdatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and UpdatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else if (CreatedTimeStamp) {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(CreatedTimeStamp)}`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedDate)}`);
        }
      }
      if (searchLeadName || LeadName) {
        if (searchLeadName?.toLowerCase() === 'n/a' || LeadName?.toLowerCase() === 'n/a') {
          filterArray.push('LeadName eq null');
        }
        else if (LeadName) {
          filterArray.push(`contains(tolower(LeadName),tolower('${encodeURIComponent(LeadName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeadName),tolower('${encodeURIComponent(searchLeadName)}'))`);
        }
      }
      if (searchLeadContact || leadContact) {
        if (searchLeadContact?.toLowerCase() === 'n/a' || leadContact?.toLowerCase() === 'n/a') {
          filterArray.push('LeadContact eq null');
        }
        else if (leadContact) {
          const nameFilter = `(contains(tolower(LeadContact), tolower('${encodeURIComponent(leadContact)}')) or contains(tolower(PhoneNo), tolower('${encodeURIComponent(leadContact)}')))`
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(LeadContact), tolower('${encodeURIComponent(searchLeadContact)}')) or contains(tolower(PhoneNo), tolower('${encodeURIComponent(searchLeadContact)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchLeadSource || LeadSourceName) {
        if (searchLeadSource?.toLowerCase() === 'n/a' || LeadSourceName?.toLowerCase() === 'n/a') {
          filterArray.push('LeadSourceName eq null');
        }
        else if (LeadSourceName) {
          filterArray.push(`LeadSourceName eq '${encodeURIComponent(LeadSourceName)}'`);
        }
        else {
          filterArray.push(`LeadSourceName eq '${encodeURIComponent(searchLeadSource)}'`);
        }
      }
      if (searchEmail || email) {
        if (searchEmail?.toLowerCase() === 'n/a' || email?.toLowerCase() === 'n/a') {
          filterArray.push('Email eq null');
        }
        else if (email) {
          // filterArray.push(`(contains(tolower(Email),tolower('${searchEmail}'))) or (contains(tolower(LeadName),tolower('${searchEmail}')))`);
          const nameFilter = `(contains(tolower(Email), tolower('${encodeURIComponent(email)}')) or contains(tolower(LeadName), tolower('${encodeURIComponent(email)}')))`
          filterArray.push(nameFilter);
        }
        else {
          // filterArray.push(`(contains(tolower(Email),tolower('${searchEmail}'))) or (contains(tolower(LeadName),tolower('${searchEmail}')))`);
          const nameFilter = `(contains(tolower(Email), tolower('${encodeURIComponent(searchEmail)}')) or contains(tolower(LeadName), tolower('${encodeURIComponent(searchEmail)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchPhoneNo || PhoneNo) {
        if (searchPhoneNo?.toLowerCase() === 'n/a' || PhoneNo?.toLowerCase() === 'n/a') {
          filterArray.push('PhoneNo eq null');
        }
        else if (PhoneNo) {
          filterArray.push(`contains(tolower(PhoneNo),tolower('${encodeURIComponent(searchPhoneNo)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(PhoneNo),tolower('${encodeURIComponent(searchPhoneNo)}'))`);
        }
      }
      if (searchLeadOwnerUserName) {
        if (searchLeadOwnerUserName?.toLowerCase() === 'n/a' || LeadOwnerUserName?.toLowerCase() === 'n/a') {
          filterArray.push('LeadOwnerUserName eq null');
        }
        else if (LeadOwnerUserName) {
          filterArray.push(`contains(tolower(LeadOwnerUserName),tolower('${encodeURIComponent(LeadOwnerUserName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeadOwnerUserName),tolower('${encodeURIComponent(searchLeadOwnerUserName)}'))`);
        }
      }
      if (searchLeadOwnerUserEmail || LeadOwnerUserEmail) {
        if (searchLeadOwnerUserEmail?.toLowerCase() === 'n/a' || LeadOwnerUserEmail?.toLowerCase() === 'n/a') {
          filterArray.push('LeadOwnerUserEmail eq null');
        }
        else if (LeadOwnerUserEmail) {
          filterArray.push(`contains(tolower(LeadOwnerUserEmail),tolower('${encodeURIComponent(LeadOwnerUserEmail)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(LeadOwnerUserEmail),tolower('${encodeURIComponent(searchLeadOwnerUserEmail)}'))`);
        }
      }
      if (searchLeadStatus || leadStatus) {
        if (searchLeadStatus?.toLowerCase() === 'n/a' || leadStatus?.toLowerCase() === 'n/a') {
          filterArray.push('LeadStatus eq null');
        }
        else if (leadStatus) {
          filterArray.push(`LeadStatus eq '${encodeURIComponent(leadStatus)}'`);
        }
        else {
          filterArray.push(`LeadStatus eq '${encodeURIComponent(searchLeadStatus)}'`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },

  getLeadsExportList: (accessToken, filter, searchLeadName, searchLeadContact, searchLeadSource, searchEmail, searchPhoneNo, searchLeadOwnerUserName,
    searchLeadOwnerUserEmail, searchLeadStatus, searchQualificationLevel, searchCategoryName, searchFollowUpActionName, searchLeadWebsite, searchLeadDesignation, searchLeadResponsibility, searchCreatedDate, searchCreatedBy) => {
    let url = `${apiUrl}/CrmLeadMasterList/Export?$count=true&$orderby=leadName asc`;
    const { leadStatus, LeadOwnerUserEmail, LeadOwnerUserName, PhoneNo, email, LeadSourceName, leadContact, CreatedBy,
      LeadResponsibility, LeadDesignation, LeadWebsite, FollowUpActionName, CategoryName, QualificationLevel, CreatedTimeStamp,
      LeadName, UpdatedTimeStamp } = filter || {};
    if (UpdatedTimeStamp || CreatedTimeStamp || searchLeadName || searchLeadContact || searchEmail || searchPhoneNo || searchLeadOwnerUserName || searchLeadSource || searchLeadOwnerUserEmail || searchLeadStatus || searchQualificationLevel || searchCategoryName || searchFollowUpActionName || searchLeadWebsite || searchLeadDesignation || searchLeadResponsibility || searchCreatedDate || searchCreatedBy) {
      url += `&$filter=`;
      const filterArray = [];
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchLeadResponsibility) {
        if (searchLeadResponsibility.toLowerCase() === 'n/a') {
          filterArray.push('LeadResponsibility eq null');
        } else {
          filterArray.push(`contains(tolower(LeadResponsibility),tolower('${encodeURIComponent(searchLeadResponsibility)}'))`);
        }
      }
      if (searchLeadDesignation) {
        if (searchLeadDesignation.toLowerCase() === 'n/a') {
          filterArray.push('LeadDesignation eq null');
        } else {
          filterArray.push(`contains(tolower(LeadDesignation),tolower('${encodeURIComponent(searchLeadDesignation)}'))`);
        }
      }
      if (searchLeadWebsite) {
        if (searchLeadWebsite.toLowerCase() === 'n/a') {
          filterArray.push('LeadWebsite eq null');
        } else {
          filterArray.push(`contains(tolower(LeadWebsite),tolower('${encodeURIComponent(searchLeadWebsite)}'))`);
        }
      }
      if (searchFollowUpActionName) {
        if (searchFollowUpActionName.toLowerCase() === 'n/a') {
          filterArray.push('FollowUpActionName eq null');
        } else {
          filterArray.push(`FollowUpActionName eq '${encodeURIComponent(searchFollowUpActionName)}'`);
        }
      }
      if (searchCategoryName) {
        if (searchCategoryName.toLowerCase() === 'n/a') {
          filterArray.push('CategoryName eq null');
        } else {
          filterArray.push(`CategoryName eq '${encodeURIComponent(searchCategoryName)}'`);
        }
      }
      if (searchQualificationLevel) {
        if (searchQualificationLevel.toLowerCase() === 'n/a') {
          filterArray.push('QualificationLevel eq null');
        } else {
          filterArray.push(`QualificationLevel eq '${encodeURIComponent(searchQualificationLevel)}'`);
        }
      }
      if (searchCreatedDate || UpdatedTimeStamp || CreatedTimeStamp) {
        if (searchCreatedDate.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        }
        else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((CreatedTimeStamp ne null and CreatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and CreatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (UpdatedTimeStamp ne null and UpdatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and UpdatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedDate)}`);
        }
      }
      if (searchLeadName) {
        if (searchLeadName.toLowerCase() === 'n/a') {
          filterArray.push('LeadName eq null');
        } else {
          filterArray.push(`contains(tolower(LeadName),tolower('${encodeURIComponent(searchLeadName)}'))`);
        }
      }
      if (searchLeadContact) {
        if (searchLeadContact.toLowerCase() === 'n/a') {
          filterArray.push('LeadContact eq null');
        } else {
          const nameFilter = `(contains(tolower(LeadContact), tolower('${encodeURIComponent(searchLeadContact)}')) or contains(tolower(PhoneNo), tolower('${encodeURIComponent(searchLeadContact)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchLeadSource) {
        if (searchLeadSource.toLowerCase() === 'n/a') {
          filterArray.push('LeadSourceName eq null');
        } else {
          filterArray.push(`LeadSourceName eq '${encodeURIComponent(searchLeadSource)}'`);
        }
      }
      if (searchEmail) {
        if (searchEmail.toLowerCase() === 'n/a') {
          filterArray.push('Email eq null');
        } else {
          // filterArray.push(`(contains(tolower(Email),tolower('${searchEmail}'))) or (contains(tolower(LeadName),tolower('${searchEmail}')))`);
          const nameFilter = `(contains(tolower(Email), tolower('${encodeURIComponent(searchEmail)}')) or contains(tolower(LeadName), tolower('${encodeURIComponent(searchEmail)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchPhoneNo) {
        if (searchPhoneNo.toLowerCase() === 'n/a') {
          filterArray.push('PhoneNo eq null');
        } else {
          filterArray.push(`contains(tolower(PhoneNo),tolower('${encodeURIComponent(searchPhoneNo)}'))`);
        }
      }
      if (searchLeadOwnerUserName) {
        if (searchLeadOwnerUserName.toLowerCase() === 'n/a') {
          filterArray.push('LeadOwnerUserName eq null');
        } else {
          filterArray.push(`contains(tolower(LeadOwnerUserName),tolower('${encodeURIComponent(searchLeadOwnerUserName)}'))`);
        }
      }
      if (searchLeadOwnerUserEmail) {
        if (searchLeadOwnerUserEmail.toLowerCase() === 'n/a') {
          filterArray.push('LeadOwnerUserEmail eq null');
        } else {
          filterArray.push(`contains(tolower(LeadOwnerUserEmail),tolower('${encodeURIComponent(searchLeadOwnerUserEmail)}'))`);
        }
      }
      if (searchLeadStatus) {
        if (searchLeadStatus.toLowerCase() === 'n/a') {
          filterArray.push('LeadStatus eq null');
        } else {
          filterArray.push(`LeadStatus eq '${encodeURIComponent(searchLeadStatus)}'`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
    //   return requests.getWithToken(
    //   `${apiUrl}/CrmLeadMasterList/Export?$count=true&$orderby=CreatedTimeStamp desc`,
    //   access_token
    // );
  },
  filterLeadCategoryAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgLeadCategoryList?$count=true`
    if (searchValue) {
      url += `&$filter=contains(tolower(LeadCategoryName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  filterLeadSourceAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgLeadSourceList?$count=true`;
    if (searchValue) {
      url += `&$filter=contains(tolower(LeadSourceName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  filterLeadFollowActionAsyncSelect: (accessToken, searchValue) => {
    let url = `${apiUrl}/odata/CrmOrgFollowUpActionList?$count=true`;
    if (searchValue) {
      url += `&$filter=contains(tolower(FollowUpActionName),tolower('${encodeURIComponent(searchValue)}'))`;
    }
    return requests.getWithToken(url, accessToken);
  },
  getLeadsDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/CrmLeadMaster/Details?LeadMasterId=${id}`, accessToken);
  },
  convertAccount: (accessToken, id) => {
    return requests.postWithTokenOnly(`${apiUrl}/api/CrmAccountsMaster/ConvertToAccount?LeadId=${id}`, accessToken);
  },
  updateLeads: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmLeadMaster/Update`, data, accessToken);
  },
  createOpportunity: (accessToken, data) => {
    const formData = new FormData();
    formData.append('OppourtunityName', data.oppourtunityName);
    formData.append('CompanyId', data.companyId);
    formData.append('ContactId', data.contactId);
    formData.append('ExpectedValue', data.expectedValue);
    formData.append('StartDate', data.startDate);
    formData.append('EndDate', data.endDate);
    formData.append('OpCurrency', data.opCurrency);
    formData.append('OpCategoryId', data.opCategoryId);
    formData.append('OpTypeId', data.opTypeId);
    formData.append('OpActionId', data.opActionId);
    formData.append('OpStatus', data.opStatus);
    formData.append('OpCurrentPhaseId', data.OpCurrentPhaseId);
    formData.append('Description', data.description);
    // formData.append('IndexFile', data.IndexFile);

    if (data.IndexFile && Array.isArray(data.IndexFile)) {
      data.IndexFile.forEach(file => {
        formData.append('IndexFile', file); // Note the use of 'IndexFile[]'
      });
    }
    return requests.postMultiPartWithToken(`${apiUrl}/api/CrmOpportunity/Create`, formData, accessToken);
  },
  updateOpportunity: (accessToken, data) => {
    const formData = new FormData();
    formData.append('Id', data.id);
    formData.append('CompanyId', data.companyId);
    formData.append('ContactId', data.contactId);
    formData.append('ExpectedValue', data.expectedValue);
    formData.append('StartDate', data.startDate);
    formData.append('EndDate', data.endDate);
    formData.append('OpCurrency', data.opCurrency);
    formData.append('OpCategoryId', data.opCategoryId);
    formData.append('OpTypeId', data.opTypeId);
    formData.append('OpActionId', data.opActionId);
    formData.append('OpStatus', data.opStatus);
    formData.append('OpCurrentPhaseId', data.opCurrentPhaseId);
    formData.append('OpportunityName', data.opportunityName);
    formData.append('Description', data.description);

    if (data.IndexFile && Array.isArray(data.IndexFile)) {
      data.IndexFile.forEach(file => {
        formData.append('IndexFile', file); // Note the use of 'IndexFile[]'
      });
    }
    return requests.postMultiPartWithToken(`${apiUrl}/api/CrmOpportunity/Update`, formData, accessToken);
  },

  getOpportunityExportList: (accessToken, filter, searchContactName, searchCompanyName, searchExpectedValue, searchStage, searchTotalDays, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy) => {

    let url = `${apiUrl}/CrmOpportunityList/Export?$count=true&$orderby=companyName asc`;
    const { CreatedTimeStamp, UpdatedTimeStamp } = filter || {};
    if (CreatedTimeStamp || UpdatedTimeStamp || searchContactName || searchCompanyName || searchExpectedValue || searchStage || searchTotalDays || searchOpportunityName || searchOpCategoryName || searchOpPendingDays || searchOpTypeName || searchOpActionName || searchOpStatus || searchOpCurrentPhase || searchWeightedValue || searchStartDate || searchEndDate || searchCreatedDate || searchCreatedBy) {

      url += `&$filter=`;
      const filterArray = [];
      if (searchOpportunityName) {
        if (searchOpportunityName.toLowerCase() === 'n/a') {
          filterArray.push('OpportunityName eq null');
        } else {
          filterArray.push(`contains(tolower(OpportunityName),tolower('${encodeURIComponent(searchOpportunityName)}'))`);
        }
      }
      if (searchCreatedBy) {
        if (searchCreatedBy.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        } else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedDate || CreatedTimeStamp || UpdatedTimeStamp) {
        if (searchCreatedDate.toLowerCase() === 'n/a' || CreatedTimeStamp.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        }
        else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((CreatedTimeStamp ne null and CreatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and CreatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (UpdatedTimeStamp ne null and UpdatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and UpdatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedDate)}`);
        }
      }
      if (searchWeightedValue) {
        if (searchWeightedValue.toLowerCase() === 'n/a') {
          filterArray.push('WeightedValue eq null');
        } else {
          filterArray.push(`contains(tolower(WeightedValue),tolower('${encodeURIComponent(searchWeightedValue)}'))`);
        }
      }
      if (searchOpActionName) {
        if (searchOpActionName.toLowerCase() === 'n/a') {
          filterArray.push('OpActionName eq null');
        } else {
          filterArray.push(`contains(tolower(OpActionName),tolower('${encodeURIComponent(searchOpActionName)}'))`);
        }
      }
      if (searchOpCategoryName) {
        if (searchOpCategoryName.toLowerCase() === 'n/a') {
          filterArray.push('OpCategoryName eq null');
        } else {
          const nameFilter = `(contains(tolower(OpCategoryName), tolower('${encodeURIComponent(searchOpCategoryName)}')) or contains(tolower(OpSubCategory), tolower('${encodeURIComponent(searchOpCategoryName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchContactName) {
        if (searchContactName.toLowerCase() === 'n/a') {
          filterArray.push('ContactName eq null');
        } else {
          const nameFilter = `(contains(tolower(ContactEmail), tolower('${encodeURIComponent(searchContactName)}')) or contains(tolower(ContactName), tolower('${encodeURIComponent(searchContactName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchCompanyName) {
        if (searchCompanyName.toLowerCase() === 'n/a') {
          filterArray.push('CompanyName eq null');
        } else {
          filterArray.push(`contains(tolower(CompanyName),tolower('${encodeURIComponent(searchCompanyName)}'))`);
        }
      }
      if (searchExpectedValue) {
        if (searchExpectedValue.toLowerCase() === 'n/a') {
          filterArray.push('ExpectedValue eq null');
        } else {
          filterArray.push(`contains(tolower(ExpectedValue),tolower('${encodeURIComponent(searchExpectedValue)}'))`);
        }
      }
      if (searchStage) {
        if (searchStage.toLowerCase() === 'n/a') {
          filterArray.push('Stage eq null');
        } else {
          filterArray.push(`contains(tolower(Stage),tolower('${encodeURIComponent(searchStage)}'))`);
        }
      }
      if (searchTotalDays) {
        if (searchTotalDays.toLowerCase() === 'n/a') {
          filterArray.push('TotalDays eq null');
        } else {
          filterArray.push(`TotalDays eq ${encodeURIComponent(searchTotalDays)}`);
        }
      }
      if (searchOpPendingDays) {
        if (searchOpPendingDays.toLowerCase() === 'n/a') {
          filterArray.push('PendingDays eq null');
        } else {
          filterArray.push(`PendingDays eq ${encodeURIComponent(searchOpPendingDays)}`);
        }
      }
      if (searchOpTypeName) {
        if (searchOpTypeName.toLowerCase() === 'n/a') {
          filterArray.push('OpTypeName eq null');
        } else {
          filterArray.push(`contains(tolower(OpTypeName),tolower('${encodeURIComponent(searchOpTypeName)}'))`);
        }
      }
      if (searchOpStatus) {
        if (searchOpStatus.toLowerCase() === 'n/a') {
          filterArray.push('OpStatus eq null');
        } else {
          filterArray.push(`contains(tolower(OpStatus),tolower('${encodeURIComponent(searchOpStatus)}'))`);
        }
      }
      if (searchOpCurrentPhase) {
        if (searchOpCurrentPhase.toLowerCase() === 'n/a') {
          filterArray.push('OpCurrentPhase eq null');
        } else {
          filterArray.push(`contains(tolower(OpCurrentPhase),tolower('${encodeURIComponent(searchOpCurrentPhase)}'))`);
        }
      }
      if (searchStartDate) {
        if (searchStartDate.toLowerCase() === 'n/a') {
          filterArray.push('StartDate eq null');
        } else {
          filterArray.push(`StartDate eq ${encodeURIComponent(searchStartDate)}`);
        }
      }
      if (searchEndDate) {
        if (searchEndDate.toLowerCase() === 'n/a') {
          filterArray.push('EndDate eq null');
        } else {
          filterArray.push(`EndDate eq ${encodeURIComponent(searchEndDate)}`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      // console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getOpportunityList: (accessToken, filteredData, currentPage, PageSize = 15, searchContactName, searchCompanyName, searchExpectedValue, searchStage, searchTotalDays, searchOpportunityName, searchOpCategoryName, searchOpPendingDays, searchOpTypeName, searchOpActionName, searchOpStatus, searchOpCurrentPhase, searchWeightedValue, searchStartDate, searchEndDate, searchCreatedDate, searchCreatedBy) => {
    currentPage = parseInt(currentPage, 10);
    console.log("filteredData:", filteredData);

    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    const { UpdatedTimeStamp, ContactEmail, EndDate, StartDate, OpCurrentPhase, OpStatus, OpTypeName, PendingDays, TotalDays, Stage, ExpectedValue, CompanyName, OpportunityName, CreatedBy, CreatedTimeStamp, WeightedValue, OpActionName, OpCategoryName, ContactName } = filteredData || {};
    let url = `${apiUrl}/odata/CrmOpportunityList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;

    if (UpdatedTimeStamp || ContactEmail || EndDate || StartDate || OpCurrentPhase || OpStatus || OpTypeName || PendingDays || TotalDays || Stage || ExpectedValue || CompanyName || OpportunityName || OpActionName || CreatedTimeStamp || OpCategoryName || ContactName || WeightedValue || CreatedBy || searchContactName || searchCompanyName || searchExpectedValue || searchStage || searchTotalDays || searchOpportunityName || searchOpCategoryName || searchOpPendingDays || searchOpTypeName || searchOpActionName || searchOpStatus || searchOpCurrentPhase || searchWeightedValue || searchStartDate || searchEndDate || searchCreatedDate || searchCreatedBy) {

      url += `&$filter=`;
      const filterArray = [];
      if (searchOpportunityName || OpportunityName) {
        if (searchOpportunityName?.toLowerCase() === 'n/a' || OpportunityName?.toLowerCase() === 'n/a') {
          filterArray.push('OpportunityName eq null');
        }
        else if (OpportunityName) {
          filterArray.push(`contains(tolower(OpportunityName),tolower('${encodeURIComponent(OpportunityName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OpportunityName),tolower('${encodeURIComponent(searchOpportunityName)}'))`);
        }
      }
      if (searchCreatedBy || CreatedBy) {
        if (searchCreatedBy?.toLowerCase() === 'n/a' || CreatedBy?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedBy eq null');
        }
        else if (CreatedBy) {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(CreatedBy)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(CreatedBy),tolower('${encodeURIComponent(searchCreatedBy)}'))`);
        }
      }
      if (searchCreatedDate || CreatedTimeStamp) {
        if (searchCreatedDate?.toLowerCase() === 'n/a' || CreatedTimeStamp?.toLowerCase() === 'n/a') {
          filterArray.push('CreatedTimeStamp eq null');
        }
        else if (CreatedTimeStamp && UpdatedTimeStamp) {
          filterArray.push(`((CreatedTimeStamp ne null and CreatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and CreatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}) or (UpdatedTimeStamp ne null and UpdatedTimeStamp ge ${encodeURIComponent(CreatedTimeStamp)} and UpdatedTimeStamp le ${encodeURIComponent(UpdatedTimeStamp)}))`);
        }
        else if (CreatedTimeStamp) {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(CreatedTimeStamp)}`);
        }
        else {
          filterArray.push(`CreatedTimeStamp eq ${encodeURIComponent(searchCreatedDate)}`);
        }
      }
      if (searchWeightedValue || WeightedValue) {
        if (searchWeightedValue?.toLowerCase() === 'n/a' || WeightedValue?.toLowerCase() === 'n/a') {
          filterArray.push('WeightedValue eq null');
        }
        else if (WeightedValue) {
          filterArray.push(`contains(tolower(WeightedValue),tolower('${encodeURIComponent(WeightedValue)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(WeightedValue),tolower('${encodeURIComponent(searchWeightedValue)}'))`);
        }
      }
      if (searchOpActionName || OpActionName) {
        if (searchOpActionName?.toLowerCase() === 'n/a' || OpActionName?.toLowerCase() === 'n/a') {
          filterArray.push('OpActionName eq null');
        }
        else if (OpActionName) {
          filterArray.push(`contains(tolower(OpActionName),tolower('${encodeURIComponent(OpActionName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OpActionName),tolower('${encodeURIComponent(searchOpActionName)}'))`);
        }
      }
      if (searchOpCategoryName || OpCategoryName) {
        if (searchOpCategoryName?.toLowerCase() === 'n/a' || OpCategoryName?.toLowerCase() === 'n/a') {
          filterArray.push('OpCategoryName eq null');
        }
        else if (OpCategoryName) {
          const nameFilter = `(contains(tolower(OpCategoryName), tolower('${encodeURIComponent(OpCategoryName)}')) or contains(tolower(OpSubCategory), tolower('${encodeURIComponent(OpCategoryName)}')))`
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(OpCategoryName), tolower('${encodeURIComponent(searchOpCategoryName)}')) or contains(tolower(OpSubCategory), tolower('${encodeURIComponent(searchOpCategoryName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchContactName || ContactName) {
        if (searchContactName?.toLowerCase() === 'n/a' || ContactName?.toLowerCase() === 'n/a') {
          filterArray.push('ContactName eq null');
        }
        else if (ContactEmail) {
          const nameFilter = `(contains(tolower(ContactEmail), tolower('${encodeURIComponent(ContactEmail)}')) or contains(tolower(ContactName), tolower('${encodeURIComponent(ContactEmail)}')))`
          filterArray.push(nameFilter);
        }
        else {
          const nameFilter = `(contains(tolower(ContactEmail), tolower('${encodeURIComponent(searchContactName)}')) or contains(tolower(ContactName), tolower('${encodeURIComponent(searchContactName)}')))`
          filterArray.push(nameFilter);
        }
      }
      if (searchCompanyName || CompanyName) {
        if (searchCompanyName?.toLowerCase() === 'n/a' || CompanyName?.toLowerCase() === 'n/a') {
          filterArray.push('CompanyName eq null');
        }
        else if (CompanyName) {
          filterArray.push(`contains(tolower(CompanyName),tolower('${encodeURIComponent(CompanyName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(CompanyName),tolower('${encodeURIComponent(searchCompanyName)}'))`);
        }
      }
      if (searchExpectedValue || ExpectedValue) {
        if (searchExpectedValue?.toLowerCase() === 'n/a' || ExpectedValue?.toLowerCase() === 'n/a') {
          filterArray.push('ExpectedValue eq null');
        }
        else if (ExpectedValue) {
          filterArray.push(`contains(tolower(ExpectedValue),tolower('${encodeURIComponent(ExpectedValue)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(ExpectedValue),tolower('${encodeURIComponent(searchExpectedValue)}'))`);
        }
      }
      if (searchStage || Stage) {
        if (searchStage?.toLowerCase() === 'n/a' || Stage?.toLowerCase() === 'n/a') {
          filterArray.push('Stage eq null');
        }
        else if (Stage) {
          filterArray.push(`contains(tolower(Stage),tolower('${encodeURIComponent(Stage)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(Stage),tolower('${encodeURIComponent(searchStage)}'))`);
        }
      }
      if (searchTotalDays || TotalDays) {
        if (searchTotalDays?.toLowerCase() === 'n/a' || TotalDays?.toLowerCase() === 'n/a') {
          filterArray.push('TotalDays eq null');
        }
        else if (TotalDays) {
          filterArray.push(`TotalDays eq ${encodeURIComponent(TotalDays)}`);
        }
        else {
          filterArray.push(`TotalDays eq ${encodeURIComponent(searchTotalDays)}`);
        }
      }
      if (searchOpPendingDays || PendingDays) {
        if (searchOpPendingDays?.toLowerCase() === 'n/a' || PendingDays?.toLowerCase() === 'n/a') {
          filterArray.push('PendingDays eq null');
        }
        else if (PendingDays) {
          filterArray.push(`PendingDays eq ${encodeURIComponent(PendingDays)}`);
        }
        else {
          filterArray.push(`PendingDays eq ${encodeURIComponent(searchOpPendingDays)}`);
        }
      }
      if (searchOpTypeName || OpTypeName) {
        if (searchOpTypeName?.toLowerCase() === 'n/a' || OpTypeName?.toLowerCase() === 'n/a') {
          filterArray.push('OpTypeName eq null');
        }
        else if (OpTypeName) {
          filterArray.push(`contains(tolower(OpTypeName),tolower('${encodeURIComponent(OpTypeName)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OpTypeName),tolower('${encodeURIComponent(searchOpTypeName)}'))`);
        }
      }
      if (searchOpStatus || OpStatus) {
        if (searchOpStatus?.toLowerCase() === 'n/a' || OpStatus?.toLowerCase() === 'n/a') {
          filterArray.push('OpStatus eq null');
        }
        else if (OpStatus) {
          filterArray.push(`contains(tolower(OpStatus),tolower('${encodeURIComponent(OpStatus)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OpStatus),tolower('${encodeURIComponent(searchOpStatus)}'))`);
        }
      }
      if (searchOpCurrentPhase || OpCurrentPhase) {
        if (searchOpCurrentPhase?.toLowerCase() === 'n/a' || OpCurrentPhase?.toLowerCase() === 'n/a') {
          filterArray.push('OpCurrentPhase eq null');
        }
        else if (OpCurrentPhase) {
          filterArray.push(`contains(tolower(OpCurrentPhase),tolower('${encodeURIComponent(OpCurrentPhase)}'))`);
        }
        else {
          filterArray.push(`contains(tolower(OpCurrentPhase),tolower('${encodeURIComponent(searchOpCurrentPhase)}'))`);
        }
      }
      if (searchStartDate || StartDate) {
        if (searchStartDate?.toLowerCase() === 'n/a' || StartDate?.toLowerCase() === 'n/a') {
          filterArray.push('StartDate eq null');
        }
        else if (StartDate) {
          filterArray.push(`StartDate eq ${encodeURIComponent(StartDate)}`);
        }
        else {
          filterArray.push(`StartDate eq ${encodeURIComponent(searchStartDate)}`);
        }
      }
      if (searchEndDate || EndDate) {
        if (searchEndDate?.toLowerCase() === 'n/a' || EndDate?.toLowerCase() === 'n/a') {
          filterArray.push('EndDate eq null');
        }
        else if (EndDate) {
          filterArray.push(`EndDate eq ${encodeURIComponent(EndDate)}`);
        }
        else {
          filterArray.push(`EndDate eq ${encodeURIComponent(searchEndDate)}`);
        }
      }

      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      // console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      console.log(url);
    }
    return requests.getWithToken(url, accessToken);
  },
  getOpportunityDetails: (accessToken, id) => {
    return requests.getWithToken(`${apiUrl}/api/CrmOpportunity/Details?DealId=${id}`, accessToken);
  },
  getOpportunityDetailsDownload: (accessToken, id) => {
    return requests.getFileWithToken(`${apiUrl}/api/CrmOpportunity/Get/Document?OpportunityDocumentId=${id}`, accessToken);
  },
  getOpportunityDetailsDownloadZip: (accessToken, id) => {
    return requests.getFileWithToken(`${apiUrl}/api/CrmOpportunity/GetAll/Documents?OpportunityId=${id}`, accessToken);
  },
  getOpportunityDetailsDocumentDelete: (accessToken, id) => {
    return requests.deletebodyWithToken(`${apiUrl}/api/CrmOpportunity/Delete/Document`, id, accessToken);
  },
}
const CrmSettings = {

  //----------------------------------LEAD MANDATORY--------------------------------------------------------------------------------
  getLeadMandatoryList: (accessToken) => {
    let url = `${apiUrl}/odata/CrmMandatoryFieldsList?count=true&$orderby=FieldName asc&filter=Module eq 'Leads'&$select=Id,JsonPropertyName,IsMandatory,FieldName,CreatedTimeStamp,CreatedBy`;
    return requests.getWithToken(url, accessToken);
  },
  updateLeadMandatory: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateMandatoryFilds`, data, accessToken);
  },
  //----------------------------------LEAD-CATEGORY--------------------------------------------------------------------------------
  createLeadCategory: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOrgLeadCategory`, data, accessToken);
  },
  getLeadCategoryList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmOrgLeadCategoryList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateLeadCategory: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOrgLeadCategory`, data, accessToken);
  },
  //----------------------------------FOLLOW-UP-ACTION--------------------------------------------------------------------------------
  createFollowUpAction: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOrgFollowUpAction`, data, accessToken);
  },
  getFollowUpActionList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmOrgFollowUpActionList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateFollowUpAction: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOrgFollowUpAction`, data, accessToken);
  },
  //----------------------------------LEAD-SOURCE--------------------------------------------------------------------------------
  createLeadSource: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOrgLeadSource`, data, accessToken);
  },
  getLeadSourceList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmOrgLeadSourceList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateLeadSource: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOrgLeadSource`, data, accessToken);
  },
  //----------------------------------ACCOUNT-CATEGORY--------------------------------------------------------------------------------
  createAccountCategory: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/AddAccountCategory`, data, accessToken);
  },
  getAccountCategoryList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmAccountCategoryList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateAccountCategory: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/AddAccountCategory`, data, accessToken);
  },
  //----------------------------------REGION--------------------------------------------------------------------------------
  createRegion: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOrgRegion`, data, accessToken);
  },
  getRegionList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmOrgRegionList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateRegion: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOrgRegion`, data, accessToken);
  },
  //----------------------------------INDUSTRY--------------------------------------------------------------------------------
  createIndustry: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateIndustry`, data, accessToken);
  },
  getIndustryList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmOrgIndustryList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateIndustry: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateIndustry`, data, accessToken);
  },
  //----------------------------------Opportunity-Category--------------------------------------------------------------------------------
  createOpportunityCategory: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/AddOppCategory`, data, accessToken);
  },
  getOpportunityCategoryList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/OrgOppCategoryList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateOpportunityCategory: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOppCategory`, data, accessToken);
  },
  //----------------------------------Opportunity-Type--------------------------------------------------------------------------------
  createOpportunityType: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOppType`, data, accessToken);
  },
  getOpportunityTypeList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmOrgOppTypeList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateOpportunityType: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOppType`, data, accessToken);
  },
  //----------------------------------Opportunity-Action-Time--------------------------------------------------------------------------------
  createOpportunityActionTime: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOppAction`, data, accessToken);
  },
  getOpportunityActionTimeList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmOrgOppActionList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateOpportunityActionTime: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOppAction`, data, accessToken);
  },
  //----------------------------------Opportunity-Current-Phase--------------------------------------------------------------------------------
  createOpportunityCurrentPhase: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOppCurrentPhase`, data, accessToken);
  },
  getOpportunityCurrentPhaseList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmOrgOppCurrentPhaseList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  updateOpportunityCurrentPhase: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateOppCurrentPhase`, data, accessToken);
  },
  //-------------------------------------Visit Type ----------------------------------------------------------------------------------
  getVisitTypeList: (accessToken, currentPage, PageSize = 15) => {
    currentPage = parseInt(currentPage, 10);
    if (Number.isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let url = `${apiUrl}/odata/CrmVisitTypeList?count=true&$skip=${(currentPage - 1) * PageSize}&$top=${PageSize}&$orderby=CreatedTimeStamp desc`;
    return requests.getWithToken(url, accessToken);
  },
  createVisitType: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateVisitType`, data, accessToken);
  },
  updateVisitType: (accessToken, data) => {
    return requests.postWithToken(`${apiUrl}/api/CrmSettings/UpdateVisitType`, data, accessToken);
  },
}



export default {
  Auth,
  TimeSheet,
  MyTeamTimeSheet,
  AllTimeSheet,
  TimesheetDashboard,
  LeaveTracker,
  LeaveType,
  LeaveMaster,
  LeaveTrackerDashboard,
  LeavetackerReport,
  Organization,
  AppMaster,
  OrgUser,
  Groups,
  AdminUser,
  Role,
  Employee,
  ProjectTracker,
  MassMailer,
  DBSync,
  HolidayMaster,
  WeeklyOff,
  ProjectDashboard,
  TimeSheetApproval,
  VisionDocs,
  OverAllDocs,
  Crm,
  CrmSettings,
  OrgLogo
};
