const PayRollSidebarData = [
  
    {
        label: "Payroll",
        isMainMenu: true,
    },
    {
        label: "Salary",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/salary",
    },

 
]
export default PayRollSidebarData;