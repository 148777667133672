//--------------------LEADS-MANDATORY-LIST-----------------------------------
export const SET_LEAD_MANDATORY_LIST = "SET_LEAD_MANDATORY_LIST";
export const LEAD_MANDATORY_LIST_PENDING = "LEAD_MANDATORY_LIST_PENDING";
export const LEAD_MANDATORY_LIST_ERROR = "LEAD_MANDATORY_LIST_ERROR";
export const LEAD_MANDATORY_LIST_ERROR_CLEAR = "LEAD_MANDATORY_LIST_ERROR_CLEAR";

//--------------------LEADS-UPDATE-----------------------------------
export const LEAD_MANDATORY_UPDATE_OPEN = "LEAD_MANDATORY_UPDATE_OPEN";
export const LEAD_MANDATORY_UPDATE_CLOSE = "LEAD_MANDATORY_UPDATE_CLOSE";
export const LEAD_MANDATORY_UPDATE_PENDING = "LEAD_MANDATORY_UPDATE_PENDING";
export const LEAD_MANDATORY_UPDATE_ERROR = "LEAD_MANDATORY_UPDATE_ERROR";
export const LEAD_MANDATORY_UPDATE_SUCCESS = "LEAD_MANDATORY_UPDATE_SUCCESS";
export const LEAD_MANDATORY_UPDATE_ERROR_CLEAR = "LEAD_MANDATORY_UPDATE_ERROR_CLEAR";
export const SET_LEAD_MANDATORY_DETAILS = "SET_LEAD_MANDATORY_DETAILS";