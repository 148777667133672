import { resolve } from "superagent/lib/request-base"
import { getToken } from "../../../../helper/common"
import agent from "../../../services/agent"
import { showSnackbarStatus } from "../../../snackbar/actions"
import {
    CLEAR_GANTT_CHART_LIST,
    CLEAR_GANTT_LIST_ERROR,
    CLEAR_GANTT_MILESTONE_UPDATE_ERROR,
    SELECTED_GANTT_PROJECT,
    SET_GANTT_CHART_LIST,
    SET_GANTT_LIST_ERROR,
    SET_GANTT_LIST_PENDING,
    SET_GANTT_MILESTONE_UPDATE_ERROR,
    SET_GANTT_MILESTONE_UPDATE_PENDING,
    UPDATE_GANTT_CHART_LIST_SUCCESS
} from "./constants"


export const setGanttListPending = () => ({
    type: SET_GANTT_LIST_PENDING
})
export const setGanttMilestoneUpdatePending = () => ({
    type: SET_GANTT_MILESTONE_UPDATE_PENDING
})
export const setGanttChartList = (ganttList) => ({
    type: SET_GANTT_CHART_LIST,
    payload: { ganttList },
})
export const clearGanttList = () => ({
    type: CLEAR_GANTT_CHART_LIST,
})
export const updateGanttChartListSuccess = () => ({
    type: UPDATE_GANTT_CHART_LIST_SUCCESS,
})
export const clearGanttListError = () => ({
    type: CLEAR_GANTT_LIST_ERROR,
})
export const clearGanttMilestoneUpdateError = () => ({
    type: CLEAR_GANTT_MILESTONE_UPDATE_ERROR,
})

export const GanttChartListError = (errorMessage) => ({
    type: SET_GANTT_LIST_ERROR,
    payload: { errorMessage },
})
export const GanttMilestoneUpdateError = (errorMessage) => ({
    type: SET_GANTT_MILESTONE_UPDATE_ERROR,
    payload: { errorMessage },
})
export const GanttSelectedProject = (project) => ({
    type: SELECTED_GANTT_PROJECT,
    payload: { project },
})

export const getProjectGanttListAction = (id) => (dispatch) => {
    return getToken(dispatch).then((accessToken) => {
        dispatch(setGanttListPending());
        dispatch(clearGanttListError());
        return agent.ProjectTracker.getGanttProjectList(accessToken, id)
            .then((GanttList) => {
                dispatch(setGanttChartList(GanttList));
                return Promise.resolve(GanttList)
            })
            .catch((err) => {
                console.log('getProjectGanttListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(GanttChartListError(error))
            });
    })
}

export const updateGanttMilestoneDate = (taskData, projectId) => (dispatch) => {
    return getToken(dispatch).then((accessToken) => {
        dispatch(setGanttMilestoneUpdatePending());
        return agent.ProjectTracker.getGanttMilestoneUpdate(accessToken, taskData)
            .then(() => {
                dispatch(clearGanttMilestoneUpdateError());
                dispatch(getProjectGanttListAction(projectId));
                dispatch(showSnackbarStatus('Gantt chart was updated successfully'));
            })
            .catch((err) => {
                console.log('updateGanttMilestoneDate server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(getProjectGanttListAction(projectId));
                return Promise.reject(error);
                // dispatch(GanttMilestoneUpdateError(error));
                // dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    })
}