import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearCreateOpportunityCategoryError,
    createNewOpportunityCategory
} from '../../../../../../store/Modules/crm/Settings/OpportunityCategory/actions';

const AddOpportunityCategory = (props) => {
    const { isOpportunityCategoryCreatePending, errorMessage } = props;
    const [opportunityCategoryDetails, setOpportunityCategoryDetails] = useState({ oppSubCategory: [''] });
    const [errors, setErrors] = useState();
    const [oppSubCategoryData, setOppSubCategory] = useState(['']);

    useEffect(() => {
        props.clearCreateOpportunityCategoryError();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setOpportunityCategoryDetails({
            ...opportunityCategoryDetails,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: null
        })
    }

    const handleOppSubCategory = (e, index) => {
        const { name, value } = e.target;
        oppSubCategoryData[index] = value

        setOpportunityCategoryDetails({
            ...opportunityCategoryDetails,
            [name]: oppSubCategoryData
        })

        const { oppSubCategory } = errors || {};
        if ((oppSubCategory && oppSubCategory.length) > 0) {
            oppSubCategory[index] = null;
        }

        setErrors({
            ...errors,
            [name]: oppSubCategory
        })
    }

    const addOppSubCategoryCount = (e) => {
        e.preventDefault();
        const newSubCategory = ['']
        setOppSubCategory(oppSubCategoryData.concat(newSubCategory));
        setOpportunityCategoryDetails({
            ...opportunityCategoryDetails,
            oppSubCategory: oppSubCategoryData.concat(newSubCategory)
        })
    }
    // const deleteOppSubCategoryCount = (e, index) => {
    //     e.preventDefault();
    //     oppSubCategoryData.splice(index, 1)
    //     setOppSubCategory(oppSubCategoryData);
    //     setOpportunityCategoryDetails({
    //         ...opportunityCategoryDetails,
    //         oppSubCategory: oppSubCategoryData
    //     })
    // }
    const deleteOppSubCategoryCount = (e, index) => {
        e.preventDefault();
        const updatedSubCategoryData = [...oppSubCategoryData];
        updatedSubCategoryData.splice(index, 1);

        const updatedErrors = { ...errors };
        if (updatedErrors.oppSubCategory) {
            updatedErrors.oppSubCategory.splice(index, 1);
        }

        setOppSubCategory(updatedSubCategoryData);
        setOpportunityCategoryDetails({
            ...opportunityCategoryDetails,
            oppSubCategory: updatedSubCategoryData
        });
        setErrors(updatedErrors);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { oppCategory, oppSubCategory } = opportunityCategoryDetails || { oppSubCategory: [''] };
        let oppSubCategoryErrors = [];

        if (!oppCategory) {
            currentErrors.oppCategory = 'Opportunity category name is required';
        }
        (oppSubCategory && oppSubCategory.map((i, index) => {
            console.log("i", i);

            if (!i) {
                oppSubCategoryErrors[index] = 'Opportunity sub-category name is required';
            }
        }))
        if (oppSubCategoryErrors && oppSubCategoryErrors.length > 0) {
            currentErrors.oppSubCategory = oppSubCategoryErrors;
        }
        setErrors(currentErrors);
        console.log("currentErrors:", currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.createOpportunityCategory(opportunityCategoryDetails)
        }
    }


    console.log("error:", errors);
    console.log("opportunityCategoryDetails:", opportunityCategoryDetails);


    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            {/* <Form> */}
            <FormGroup>
                <Label>Opportunity Category Name <span className='text-danger'>*</span></Label>
                <Input
                    type="text"
                    name="oppCategory"
                    placeHolder="Enter opportunity category name"
                    onChange={validateOnchange}
                    value={opportunityCategoryDetails && opportunityCategoryDetails.oppCategory}
                    invalid={errors && errors.oppCategory}
                />
                <FormFeedback>{errors && errors.oppCategory}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <div className='d-flex justify-content-between mb-2'>
                    <Label>Opportunity Sub-Category Name <span className='text-danger'>*</span></Label>
                    <Button color='primary' size='sm' onClick={addOppSubCategoryCount}>+ Add</Button>
                </div>
                {oppSubCategoryData.map((i, index) => (
                    <Row className='mb-2'>
                        <Col md='10'>
                            <Input
                                type="text"
                                name="oppSubCategory"
                                placeHolder="Enter opportunity sub-category name"
                                onChange={(e) => handleOppSubCategory(e, index)}
                                value={opportunityCategoryDetails && opportunityCategoryDetails.oppSubCategory && opportunityCategoryDetails.oppSubCategory[index]}
                                invalid={errors && errors.oppSubCategory && errors.oppSubCategory[index]}
                            />
                            <FormFeedback>{errors && errors.oppSubCategory && errors.oppSubCategory[index]}</FormFeedback>
                        </Col>
                        <Col md='2' className='text-center'>
                            {((oppSubCategoryData && oppSubCategoryData.length) > 1) &&
                                <Button
                                    color='danger'
                                    size='sm'
                                    onClick={(e) => deleteOppSubCategoryCount(e, index)}
                                >
                                    <span class="mdi mdi-trash-can fs-5" />
                                </Button>}
                        </Col>
                    </Row>
                ))}
            </FormGroup>
            <FormGroup>
                <Button
                    color='success'
                    className='me-2'
                    onClick={handleSubmit}
                    disabled={isOpportunityCategoryCreatePending}
                >
                    {isOpportunityCategoryCreatePending ?
                        <div>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                            Submitting...
                        </div>
                        : "Submit"}
                </Button>
                <Button
                    color='danger'
                    onClick={props.handleClose}
                >Cancel
                </Button>
            </FormGroup>
            {/* </Form> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    isOpportunityCategoryCreatePending: state.crmOpportunityCategory.isOpportunityCategoryCreatePending,
    errorMessage: state.crmOpportunityCategory.opportunityCategoryCreateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    createOpportunityCategory: (opportunityCategoryDetails) => dispatch(createNewOpportunityCategory(opportunityCategoryDetails)),
    clearCreateOpportunityCategoryError: () => dispatch(clearCreateOpportunityCategoryError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddOpportunityCategory);