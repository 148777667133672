import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { deleteDBSync } from '../../../store/Modules/DB/actions';
import VWButton from '../../../components/vwButton/VWButton';
import VWMessage from '../../../components/vwMessage/vwMessage';

const DBSyncDelete = (props) => {
    const [DBSyncDeleteAction, setDBSyncDelete] = useState({});

    useEffect(() => {
        const { data } = props;

        setDBSyncDelete(data)
    }, [])

    const handleDeleteActionDBSync = () => {
        props.deleteDBSync(DBSyncDeleteAction);
    };


    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <h5>Are you sure you want to delete ?</h5>
            <br></br>
            <VWButton
                messageKey="deleteDBSyncActionButton"
                defaultMessage="  Delete  "
                buttonType="success"
                baseClassName="btn"
                className="me-2"
                onClick={handleDeleteActionDBSync}
            // isLoading={props.isPending}
            // isDisabled={props.isPending
            ></VWButton>
            <VWButton
                messageKey="cancelMyTimeSheetButton"
                defaultMessage="Cancel"
                buttonType="secondary"
                baseClassName="btn"
                onClick={props.handleClose}
            // isDisabled={props.isPending}
            ></VWButton>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    errorMessage: state.dbSync.errorMessage,
    isPending: state.dbSync.isPending,

});
const mapDispatchToProps = (dispatch) => ({
    deleteDBSync: (dbsyncDeleteAction) => dispatch(deleteDBSync(dbsyncDeleteAction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DBSyncDelete);