export const CLOSE_CREATE_ORG_USER_MODAL = "CLOSE_CREATE_ORG_USER_MODAL"
export const OPEN_CREATE_ORG_USER_MODAL = "OPEN_CREATE_ORG_USER_MODAL"
export const SET_ORG_USER_LIST = "SET_ORG_USER_LIST"
export const SET_ORG_USER_LIST_ERROR = "SET_ORG_USER_LIST_ERROR"
export const CLEAR_ORG_USER_LIST_ERROR = "CLEAR_ORG_USER_LIST_ERROR"
export const SET_ORG_USER_DETAILS = "SET_ORG_USER_DETAILS"
export const SET_ORG_USER_DETAILS_ERROR = "SET_ORG_USER_DETAILS_ERROR"
export const CLEAR_ORG_USER_DETAILS_ERROR = "CLEAR_ORG_USER_DETAILS_ERROR"
export const CREATE_ORG_USER_ERROR = "CREATE_ORG_USER_ERROR"
export const CREATE_ORG_USER_PENDING = "CREATE_ORG_USER_PENDING"
export const CLEAR_CREATE_ORG_USER_ERROR = "CLEAR_CREATE_ORG_USER_ERROR"
export const CREATE_ORG_USER_SUCCESS = "CREATE_ORG_USER_SUCCESS"
export const OPEN_EDIT_ORG_USER_MODAL = "OPEN_EDIT_ORG_USER_MODAL"
export const CLOSE_EDIT_ORG_USER_MODAL = "CLOSE_EDIT_ORG_USER_MODAL"
export const CLEAR_UPDATE_ORG_USER_ERROR = "CLEAR_UPDATE_ORG_USER_ERROR"
export const UPDATE_ORG_USER_PENDING = "UPDATE_ORG_USER_PENDING"
export const UPDATE_ORG_USER_SUCCESS = "UPDATE_ORG_USER_SUCCESS"
export const UPDATE_ORG_USER_ERROR = "UPDATE_ORG_USER_ERROR"
export const SET_ORG_USER_PENDING = "SET_ORG_USER_PENDING"
export const SET_ORG_USER_DETAILS_PENDING = "SET_ORG_USER_DETAILS_PENDING"
export const SET_ORG_USER_EXPORT_PENDING = "SET_ORG_USER_EXPORT_PENDING"
export const SET_ORG_USER_EXPORT_LIST = "SET_ORG_USER_EXPORT_LIST"
export const CLEAR_ORG_USER_EXPORT_LIST_ERROR = "CLEAR_ORG_USER_EXPORT_LIST_ERROR"
export const SET_ORG_USER_EXPORT_LIST_ERROR = "SET_ORG_USER_EXPORT_LIST_ERROR"

//----------------------------------------RESET-PASSWORD--------------------------------------------------------------------------------------------
export const SEND_RESET_PASSWORD_PENDING = "SEND_RESET_PASSWORD_PENDING";
export const SEND_RESET_PASSWORD_SUCCESS = "SEND_RESET_PASSWORD_SUCCESS";
export const SEND_RESET_PASSWORD_ERROR = "SEND_RESET_PASSWORD_ERROR";
export const CLEAR_SEND_RESET_PASSWORD_ERROR = "CLEAR_SEND_RESET_PASSWORD_ERROR";

//-----------------------------------------------------DIRECT-PASSWORD-FORM---------------------------------------------------------------------------------
export const OPEN_CHANGE_PASSWORD_BY_ADMIN_MODAL = "OPEN_CHANGE_PASSWORD_BY_ADMIN_MODAL";
export const CLOSE_CHANGE_PASSWORD_BY_ADMIN_MODAL = "CLOSE_CHANGE_PASSWORD_BY_ADMIN_MODAL";
export const CHANGE_PASSWORD_BY_ADMIN_PENDING = "CHANGE_PASSWORD_BY_ADMIN_PENDING";
export const CHANGE_PASSWORD_BY_ADMIN_ERROR = "CHANGE_PASSWORD_BY_ADMIN_ERROR";
export const CLEAR_CHANGE_PASSWORD_BY_ADMIN_ERROR = "CLEAR_CHANGE_PASSWORD_BY_ADMIN_ERROR";

export const ORG_USER_EXPORT_HEADERS = [
    "ORG USER",
    "ORG USER EMAIL",
    "CREATED BY",
    "CREATED DATE",
    "USER STATUS",
    "ACCOUNT STATUS",
    "IS EMPLOYEE",
    "IS GLOBAL READ ONLY USER",
    "EMAIL NOTIFICATION STATUS",
];