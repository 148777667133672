import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import ExcelJs from "exceljs";
import { saveAs } from "file-saver";

const adjustColumnWidth = (ws, width) => {
    const colInfo = ws['!cols'] || [];
    for (let i = 0; i < width.length; i++) {
        colInfo[i] = { wch: width[i] };
    }
    ws['!cols'] = colInfo;
};

const excelPrint = (tableData, title, header, headerSize) => {
    const wsa = XLSX.utils.aoa_to_sheet([header]);
    const ws = XLSX.utils.sheet_add_json(wsa, tableData, { skipHeader: true, origin: "A2" });
    if (headerSize) {
        adjustColumnWidth(ws, headerSize);
    }
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, title);
    const excelBuffer = XLSX.write(wb, {
        bookType: "biff8",
        type: "array",
    });

    // const wb = { Sheets: {}, SheetNames: ["data"] };
    // wb.Sheets.data = ws;
    // const excelBuffer = XLSX.write(wb, {
    //     bookType: "biff8",
    //     type: "array",
    // });

    const data = new Blob([excelBuffer]);
    FileSaver.saveAs(data, `${title}.xls`);
}

const excelSheetExport = async (headers, list, title) => {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet(title || "My Sheet");

    const dataToImport = headers && headers.map((col) => {
        return {
            header: col.headerName,
            key: col.key,
            width: col.headerWidhth
        }
    })

    worksheet.columns = dataToImport;
    worksheet.addRows(list);
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
        // Set font style
        cell.font = {
            name: "TimesNewRoman",
            bold: true,
            size: 14,
            color: { argb: "FFFFFF" },
        };

        // Set alignment
        // cell.alignment = { vertical: "middle", horizontal: "center" };

        // Set background color
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "9AA6B2" },
        };
    });

    console.log("rows: ", worksheet.getRow(1))
    // Create a downloadable file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, `${title}.xlsx`);
}

export {
    excelPrint, excelSheetExport
};