import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

import { logoutUser } from "../../store/actions";

//redux


const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser())
})


const Logout = () => {
  const dispatch = useDispatch();

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.login.isUserLogout,
  }));


  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  if (isUserLogout) {
    console.log("isUserLogout")
    return <Navigate to="/" />;
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default connect(null, mapDispatchToProps)(Logout);