import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

//import images
import logoicon from "../../assets/images/Vizo-logo.png";
import visionware_logo from "../../assets/images/Visionware-Logo-R.png"
// import vizo_logo from "../../assets/images/Vizo logo_Rec.png"
import vizo_logo from "../../assets/images/Vizo logo (9).png"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  getCurrentProfile,
} from "../../store/actions";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import { CardTitle } from "reactstrap";


const Header = (props) => {
  const [search, setsearch] = useState(false);
  const { OrgLogoDetails } = props;

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  const { isAuth } = props;
  const location = useLocation();

  // useEffect(() => {
  //   props.getCurrentProfile()
  // }, [])


  const isSuperAdmin = props.currentProfile && props.currentProfile.userAppDetails &&
    props.currentProfile.userAppDetails
      .filter(item => item.appName.toLowerCase() === "admin")
      .map(userApp => {
        const adminRoles = userApp.appRoleDetails.filter(role => role.roleName.toLowerCase() === "super admin");
        const isSuperAdmin = adminRoles.length > 0;
        return isSuperAdmin;
      })
      .some(isSuperAdmin => isSuperAdmin);


  const OAuth_apiUrl = window.__ENV && window.__ENV.OAuth_apiUrl;
  const client_id = window.__ENV && window.__ENV.client_id;

  const OAuthLogout = () => {
    const OAuthLogout = `${OAuth_apiUrl}/v2/logout?client_id=${client_id}&returnTo=${encodeURIComponent(window.location.origin)}`
    // await fetch(OAuthLogout)
    // navigate(OAuthLogout)
    window.location.replace(OAuthLogout)
    console.log("OAuthLogout:", OAuthLogout);

  }

  if (!props.isAuth) {
    OAuthLogout();
    return <Navigate to='/' />;
  } else {
    return (
      <React.Fragment>
        <header id="page-topbar" style={{ height: '60px' }}>
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box text-center">
                <Link to="/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoicon} alt="logo-sm-light" height="40" className="mb-2" />
                  </span>
                  <span className="logo-lg">
                    <img src={(props.currentProfile && props.currentProfile.orgLogo) ? props.currentProfile && props.currentProfile.orgLogo : vizo_logo} height="43px" width="auto" className="mb-2" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                id="vertical-menu-btn"
                onClick={() => {
                  tToggle();
                }}
              >
                <i className="ri-menu-2-line align-middle"></i>
              </button>

              <div className="mt-4">
                {isSuperAdmin && location.pathname.startsWith("/admin") ? (
                  <CardTitle>
                    <strong>
                      {(location.pathname.includes("/admin/orguser") ||
                        location.pathname.includes("/admin/groups") ||
                        location.pathname.includes("/admin/roles")) ?
                        `ADMIN - ${props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}` :
                        (location.pathname.includes("/admin/appmaster") ||
                          location.pathname.includes("/admin/organization") ||
                          location.pathname.includes("/admin/roles")) ?
                          `SUPER ADMIN` :
                          ''
                      }
                    </strong>
                  </CardTitle>
                ) : location.pathname.startsWith("/admin") && (
                  <CardTitle><strong>ADMIN - {props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}

                {location.pathname.startsWith("/employees") && (
                  <CardTitle><strong>EMPLOYEE - {props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}
                {location.pathname.startsWith("/projectportal") && (
                  <CardTitle><strong>PROJECT PORTAL - {props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}
                {location.pathname.startsWith("/leavetracker") && (
                  <CardTitle><strong>LEAVE TRACKER - {props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}
                {location.pathname.startsWith("/timesheets") && (
                  <CardTitle><strong>TIMESHEETS - {props.currentProfile && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}
                {location.pathname.startsWith("/visiondocs") && (
                  <CardTitle><strong>VISION DOCS - {props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}
                {location.pathname.startsWith("/massmailer") && (
                  <CardTitle><strong>MASS MAILER - {props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}
                {location.pathname.startsWith("/profile") && (
                  <CardTitle><strong>USER PROFILE - {props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}
                {location.pathname.startsWith("/dbsync") && (
                  <CardTitle><strong>DB SYNC - {props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}
                {location.pathname.startsWith("/crm") && (
                  <CardTitle><strong>CRM - {props.currentProfile && props.currentProfile.orgName && props.currentProfile.orgName.toUpperCase()}</strong></CardTitle>
                )}
              </div>
            </div>


            <div className="d-flex">
              <div
                className="dropdown d-inline-block"
                onClick={() => {
                }}
              >
              </div>
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  };
}

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  const { isAuth, currentProfile, userAppDetails } = state.login;
  const { OrgLogoDetails } = state.OrgPortalManagement;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType, isAuth, currentProfile, userAppDetails, OrgLogoDetails };
};



export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  getCurrentProfile
})(withTranslation()(Header));
