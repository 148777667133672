import { getToken, modifyMilestoneList, modifyProjectList, modifyProjectUsersList } from "../../../../helper/common";
import trimmedValues from "../../../../helper/trim";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_GENERAL_TASK_LIST, CLEAR_GENERAL_TASK_LIST_ERROR, CLEAR_UPDATE_GENERAL_TASK_ERROR, GENERAL_TASK_IMAGE_MODAL_CLOSE, GENERAL_TASK_IMAGE_MODAL_OPEN, GENERAL_TASK_LIST_ERROR, SET_GENERAL_TASK_LIST, SET_GENERAL_TASK_LIST_PENDING, UPDATE_GENERAL_TASK_ERROR, UPDATE_GENERAL_TASK_PENDING, UPDATE_GENERAL_TASK_SUCCESS } from "./constants";

export const setGeneralTaskListPending = () => ({
    type: SET_GENERAL_TASK_LIST_PENDING
})
export const setGeneralTaskList = (generalTaskList) => ({
    type: SET_GENERAL_TASK_LIST,
    payload: { generalTaskList }
})
export const clearGeneralTaskList = () => ({
    type: CLEAR_GENERAL_TASK_LIST
})
export const clearGeneralTaskListError = () => ({
    type: CLEAR_GENERAL_TASK_LIST_ERROR
})
export const GeneralTaskImageModalOpen = (imageIndex) => ({
    type: GENERAL_TASK_IMAGE_MODAL_OPEN,
    payload: imageIndex
})
export const GeneralTaskImageModalClose = () => ({
    type: GENERAL_TASK_IMAGE_MODAL_CLOSE
})
export const generalTaskListError = (errorMessage) => ({
    type: GENERAL_TASK_LIST_ERROR,
    payload: { errorMessage },
})
export const clearUpdateGeneralTaskError = () => ({
    type: CLEAR_UPDATE_GENERAL_TASK_ERROR,
});
export const updateGeneralTaskPending = () => ({
    type: UPDATE_GENERAL_TASK_PENDING,
});
export const updateGeneralTaskSuccess = () => ({
    type: UPDATE_GENERAL_TASK_SUCCESS,
});
export const updateGeneralTaskError = (errorMessage) => ({
    type: UPDATE_GENERAL_TASK_ERROR,
    payload: { errorMessage },
});

export const getProjectNameAsyncSelect = (searchValue, callback) =>
    (dispatch) => {
        getToken(dispatch).then((accessToken) => {
            agent.ProjectTracker.getProjectName(accessToken, searchValue)
                .then((ProjectList) => {
                    const { value } = ProjectList;
                    console.log("value:", value);
                    const updatedProjectList = modifyProjectList(value);
                    //eslint-disable-next-line
                    callback && callback(updatedProjectList);
                })
                .catch((err) => {
                    console.log('getProjectNameAsyncSelect server error', err);
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                    callback && callback();
                });
        });
    };
export const getMilestoneSelectAction = (projectId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getMilestoneName(accessToken, projectId, searchValue)
            .then((UserList) => {
                const { value } = UserList;
                console.log("userList: ", value)
                const updatedMilestoneList = modifyMilestoneList(value);
                //eslint-disable-next-line
                callback && callback(updatedMilestoneList);
            })
            .catch((err) => {
                console.log('getMilestoneSelectAction server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                //eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getAssignedUserAsyncSelect = (projectId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getProjectUser(accessToken, projectId, searchValue)
            .then((UserList) => {
                const { value } = UserList;
                const updatedUserList = modifyProjectUsersList(value);
                console.log("updatedUserList:", updatedUserList);

                //eslint-disable-next-line
                // dispatch(setProjectUserList(updatedUserList))
                callback && callback(updatedUserList);
            })
            .catch((err) => {
                console.log('getAssignedUserAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                //eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getGeneralTaskList = (page, PageSize, searchTaskName, searchDescription) => (dispatch) => {
    dispatch(setGeneralTaskListPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getGeneralTaskList(accessToken, page, PageSize, searchTaskName, searchDescription)
            .then((generalTaskLists) => {
                console.log("generalTaskLists:", generalTaskLists);
                dispatch(setGeneralTaskList(generalTaskLists));
                dispatch(clearGeneralTaskListError());
            })
            .catch((err) => {
                console.log('getGeneralTaskList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                }
                dispatch(generalTaskListError(error))
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
}



export const EditGeneralTask = (formFields) => (dispatch) => {
    const dataToSend = trimmedValues({
        ...formFields
    })
    console.log("dataToSend:", dataToSend);

    dispatch(clearUpdateGeneralTaskError());
    dispatch(updateGeneralTaskPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.EditGeneralTask(accessToken, dataToSend)
            .then(() => {
                dispatch(updateGeneralTaskSuccess());
                dispatch(getGeneralTaskList());
                dispatch(showSnackbarStatus('General Task updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateGeneralTaskError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateGeneralTaskError('Network Error'));
                }
                console.log('Edit Update server error', err);
                // dispatch(updateGeneralTaskError(null));
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};