//-------------------------------------------------PROJECT-CREATE---------------------------------------------------------------------------------------
export const OPEN_CREATE_PROJECT_MODAL = "OPEN_CREATE_PROJECT_MODAL"
export const CLOSE_CREATE_PROJECT_MODAL = "CLOSE_CREATE_PROJECT_MODAL"
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR"
export const CLEAR_CREATE_PROJECT_ERROR = "CLEAR_CREATE_PROJECT_ERROR"
export const CREATE_PROJECT_PENDING = "CREATE_PROJECT_PENDING"
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS"

//-------------------------------------------------PROJECT-UPDATE---------------------------------------------------------------------------------------
export const OPEN_EDIT_PROJECT_MODAL = "OPEN_EDIT_PROJECT_MODAL"
export const CLOSE_EDIT_PROJECT_MODAL = "CLOSE_EDIT_PROJECT_MODAL"
export const CLEAR_UPDATE_PROJECT_ERROR = "CLEAR_UPDATE_PROJECT_ERROR"
export const UPDATE_PROJECT_PENDING = "UPDATE_PROJECT_PENDING"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR"

//-------------------------------------------------PROJECT-LIST---------------------------------------------------------------------------------------
export const SET_PROJECT_PENDING = "SET_PROJECT_PENDING"
export const SET_PROJECT_LIST = "SET_PROJECT_LIST"
export const CLEAR_PROJECT_LIST_ERROR = "CLEAR_PROJECT_LIST_ERROR"
export const PROJECT_LIST_ERROR = "PROJECT_LIST_ERROR"
export const CLEAR_PROJECT_LIST = "CLEAR_PROJECT_LIST"

//-------------------------------------------------PROJECT-EXPORT---------------------------------------------------------------------------------------
export const SET_PROJECT_EXPORT_PENDING = "SET_PROJECT_EXPORT_PENDING"
export const PROJECT_EXPORT_SUCCESS = "PROJECT_EXPORT_SUCCESS"
export const CLEAR_PROJECT_EXPORT_LIST_ERROR = "CLEAR_PROJECT_EXPORT_LIST_ERROR"
export const PROJECT_EXPORT_LIST_ERROR = "PROJECT_EXPORT_LIST_ERROR"

//-------------------------------------------------PROJECT-DETAILS---------------------------------------------------------------------------------------
export const SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS"
export const CLEAR_PROJECT_DETAILS_ERROR = "CLEAR_PROJECT_DETAILS_ERROR"
export const SET_PROJECT_DETAILS_ERROR = "SET_PROJECT_DETAILS_ERROR"
export const SET_PROJECT_DETAILS_PENDING = "SET_PROJECT_DETAILS_PENDING"
export const SET_PROJECT_PIE_CHART_DETAILS = "SET_PROJECT_PIE_CHART_DETAILS"
export const SET_PROJECT_PIE_CHART_DETAILS_PENDING = "SET_PROJECT_PIE_CHART_DETAILS_PENDING"
export const PROJECT_PIE_CHART_DETAILS_ERROR = "PROJECT_PIE_CHART_DETAILS_ERROR"
export const CLEAR_PROJECT_PIE_CHART_DETAILS_ERROR = "CLEAR_PROJECT_PIE_CHART_DETAILS_ERROR"

//-------------------------------------------------PROJECT-DETAILS---------------------------------------------------------------------------------------
export const OPEN_SORT_MODAL = "OPEN_SORT_MODAL"
export const CLOSE_SORT_MODAL = "CLOSE_SORT_MODAL"
//------------------------------------------------GLOBAL-CONSTANT------------------------------------------------------------------------------------
export const PROJECT_EXPORT_HEADERS = [
    "PROJECT NAME",
    "PROJECT MANAGER",
    "DESCRIPTION",
    "START DATE",
    "END DATE",
];

export const MILESTONE_SORTING_OPTIONS = [
    { label: 'Milestone', value: 'MilestoneName' },
    { label: 'Status', value: 'Status' },
    { label: 'Start Date', value: 'StartDate' },
    { label: 'End Date', value: 'EndDate' },
    { label: 'Milestone Owner', value: 'MileStoneOwnerUserName' },
    { label: 'Task Count', value: 'TaskCount' },
]

export const STATUS_FULL_OPTIONS = [
    { label: "New", value: "New" },
    { label: "InProgress", value: "InProgress" },
    { label: "OnHold", value: "OnHold" },
    { label: "Internal Testing", value: "Internal Testing" },
    { label: "Internal Testing Done", value: "Internal Testing Done" },
    { label: "Waiting For Approval", value: "Waiting For Approval" },
    { label: "Done", value: "Done" },
]
export const STATUS_MILESTONE_OWNER_OPTIONS = [
    { label: "New", value: "New" },
    { label: "InProgress", value: "InProgress" },
    { label: "OnHold", value: "OnHold" },
    { label: "Internal Testing", value: "Internal Testing" },
    { label: "Internal Testing Done", value: "Internal Testing Done" },
]
export const STATUS_USER_OPTIONS = [
    { label: "New", value: "New" },
    { label: "InProgress", value: "InProgress" },
    { label: "OnHold", value: "OnHold" },
    { label: "Internal Testing", value: "Internal Testing" }
]