import { DASHBOARD_CLEAR_ERROR, DASHBOARD_ERROR_MESSAGE, DONUT_DASHBOARD_ERROR_MESSAGE, SET_DASHBOARD_LEAVETRACKER, SET_DASHBOARD_PENDING, SET_DONUT_DASHBOARD_LEAVETRACKER, SET_DONUT_DASHBOARD_PENDING } from "./constants";

const initialState = {
    DashboardCounts: {},
    isDashboardPending: false,
    dashboardErrorMessage: null,
    donutDashboardErrorMessage: null,
    isDonutDashboardPending: false,
    donutDashboardCounts:{}
};
export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_DASHBOARD_LEAVETRACKER: {
            const { dashboard } = action.payload;
            return {
                ...localState,
                isDashboardPending: false,
                DashboardCounts: dashboard,
            }
        }
        case SET_DONUT_DASHBOARD_LEAVETRACKER: {
            const { dashboard } = action.payload;
            return {
                ...localState,
                isDonutDashboardPending: false,
                donutDashboardCounts: dashboard,
            }
        }
        case DASHBOARD_ERROR_MESSAGE: {
            const { dashboardErrorMessage } = action.payload;
            return {
                ...localState,
                isDashboardPending: false,
                dashboardErrorMessage: dashboardErrorMessage,
            }
        }
        case DONUT_DASHBOARD_ERROR_MESSAGE: {
            const { dashboardErrorMessage } = action.payload;
            return {
                ...localState,
                isDonutDashboardPending: false,
                donutDashboardErrorMessage: dashboardErrorMessage,
            }
        }
        case DASHBOARD_CLEAR_ERROR: {
            return {
                ...localState,
                dashboardErrorMessage: null,
            }
        }

        case SET_DASHBOARD_PENDING: {
            return {
                ...localState,
                dashboardErrorMessage: null,
                isDashboardPending: true
            }
        }
        case SET_DONUT_DASHBOARD_PENDING: {
            return {
                ...localState,
                donutDashboardErrorMessage: null,
                isDonutDashboardPending: true
            }
        }
        default: {
            return localState;
        }
    }
};