import { getToken, modifyAppTypeList } from "../../../../helper/common";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_CREATE_ORGANIZATION_ERROR, CLEAR_ORG_USER_BASED_ORG_LIST_ERROR, CLEAR_ORGANIZATION_DETAILS_ERROR, CLEAR_ORGANIZATION_EXPORT_LIST_ERROR, CLEAR_ORGANIZATION_LIST_ERROR, CLEAR_UPDATE_ORGANIZATION_ERROR, CLOSE_CREATE_ORGANIZATION_MODAL, CLOSE_EDIT_ORGANIZATION_MODAL, CREATE_ORGANIZATION_ERROR, CREATE_ORGANIZATION_PENDING, CREATE_ORGANIZATION_SUCCESS, OPEN_CREATE_ORGANIZATION_MODAL, OPEN_EDIT_ORGANIZATION_MODAL, SET_ORG_USER_BASED_ORG_ERROR, SET_ORG_USER_BASED_ORG_LIST, SET_ORGANIZATION_DETAILS, SET_ORGANIZATION_DETAILS_ERROR, SET_ORGANIZATION_DETAILS_PENDING, SET_ORGANIZATION_EXPORT_LIST, SET_ORGANIZATION_EXPORT_LIST_ERROR, SET_ORGANIZATION_EXPORT_PENDING, SET_ORGANIZATION_LIST, SET_ORGANIZATION_LIST_ERROR, SET_ORGANIZATION_PENDING, SET_ORGANIZATION_USER_BASED_ORG_LIST_PENDING, UPDATE_ORGANIZATION_ERROR, UPDATE_ORGANIZATION_PENDING, UPDATE_ORGANIZATION_SUCCESS } from "./constants";

export const openCreateOrganizationModalAction = () => ({
    type: OPEN_CREATE_ORGANIZATION_MODAL,
});
export const closeCreateOrganizationModalAction = () => ({
    type: CLOSE_CREATE_ORGANIZATION_MODAL,
});
export const openEditOrganizationModal = () => ({
    type: OPEN_EDIT_ORGANIZATION_MODAL,
});
export const closeEditOrganizationModal = () => ({
    type: CLOSE_EDIT_ORGANIZATION_MODAL,
});
export const setOrganizationList = (organizationList) => ({
    type: SET_ORGANIZATION_LIST,
    payload: { organizationList },
})
export const setOrganizationExportList = (organizationExportList) => ({
    type: SET_ORGANIZATION_EXPORT_LIST,
    payload: { organizationExportList },
})
export const orgListError = (errorMessage) => ({
    type: SET_ORGANIZATION_LIST_ERROR,
    payload: { errorMessage },
})
export const orgExportListError = (errorMessage) => ({
    type: SET_ORGANIZATION_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const clearOrgListError = () => ({
    type: CLEAR_ORGANIZATION_LIST_ERROR,
})
export const clearOrgExportListError = () => ({
    type: CLEAR_ORGANIZATION_EXPORT_LIST_ERROR,
})
export const setOrganizationDetails = (OrganizationDetails) => ({
    type: SET_ORGANIZATION_DETAILS,
    payload: { OrganizationDetails },
})

export const orgDetailsError = (errorMessage) => ({
    type: SET_ORGANIZATION_DETAILS_ERROR,
    payload: { errorMessage },
})
export const clearOrgDetailsError = () => ({
    type: CLEAR_ORGANIZATION_DETAILS_ERROR,
})

export const createOrganizationError = (errorMessage) => ({
    type: CREATE_ORGANIZATION_ERROR,
    payload: { errorMessage },
});
export const createOrganizationPending = () => ({
    type: CREATE_ORGANIZATION_PENDING,
});
export const clearCreateOrganizationError = () => ({
    type: CLEAR_CREATE_ORGANIZATION_ERROR,
});
export const createOrganizationSuccess = () => ({
    type: CREATE_ORGANIZATION_SUCCESS,
});
export const clearUpdateOrganizationError = () => ({
    type: CLEAR_UPDATE_ORGANIZATION_ERROR,
});
export const updateOrganizationPending = () => ({
    type: UPDATE_ORGANIZATION_PENDING,
});
export const updateOrganizationSuccess = () => ({
    type: UPDATE_ORGANIZATION_SUCCESS,
});
export const updateOrganizationError = (errorMessage) => ({
    type: UPDATE_ORGANIZATION_ERROR,
    payload: { errorMessage },
});
export const setOrganizationPending = () => ({
    type: SET_ORGANIZATION_PENDING,
})
export const setOrganizationExportPending = () => ({
    type: SET_ORGANIZATION_EXPORT_PENDING,
})
export const setOrganizationDetailsPending = () => ({
    type: SET_ORGANIZATION_DETAILS_PENDING,
})
export const setOrgUserBasedOrgListPending = () => ({
    type: SET_ORGANIZATION_USER_BASED_ORG_LIST_PENDING,
})
export const clearOrgUserBasedOrgError = () => ({
    type: CLEAR_ORG_USER_BASED_ORG_LIST_ERROR,
})
export const setOrgUserBasedOrgDetails = (OrgUserList) => ({
    type: SET_ORG_USER_BASED_ORG_LIST,
    payload: { OrgUserList },
})
export const orgUserBasedOrgError = (errorMessage) => ({
    type: SET_ORG_USER_BASED_ORG_ERROR,
    payload: { errorMessage },
})

export const getExportOrgList = (searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive) => (dispatch) => {
    dispatch(setOrganizationExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.Organization.getOrganizationExportlist(accessToken, searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive)
            .then((organizationList) => {
                // dispatch(setOrganizationExportList(organizationList));
                dispatch(clearOrgExportListError());
                return Promise.resolve(organizationList);
            })
            .catch((err) => {
                console.log('getExportOrgList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgExportListError(error))
            });
    });
};
export const getOrganizationListAction = (filteredData, page, PageSize, searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive) => (dispatch) => {
    dispatch(setOrganizationPending());
    getToken(dispatch).then((accessToken) => {
        agent.Organization.getOrganizationlist(accessToken, filteredData, page, PageSize, searchOrgName, searchOrgContactPerson, searchOrgContactEmail, searchOrgContactNumber, searchOrgAddress, searchOrgCreatedBy, searchOrgUserCount, searchIsActive)
            .then((organizationList) => {
                dispatch(setOrganizationList(organizationList));
                dispatch(clearOrgListError());
            })
            .catch((err) => {
                console.log('getOrganizationListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgListError(error))
            });
    });
};

export const getOrganizationDetailsAction = (id) => (dispatch) => {
    dispatch(setOrganizationDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.Organization.getOrganizationDetails(accessToken, id)
            .then((OrganizationDetails) => {
                dispatch(setOrganizationDetails(OrganizationDetails));
                dispatch(clearOrgDetailsError());
            })
            .catch((err) => {
                console.log('getOrganizationDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgDetailsError(error))
            });
    });
}
export const getOrgUserBasedOnOrgList = (id, page, PageSize, filter) => (dispatch) => {
    dispatch(setOrgUserBasedOrgListPending());
    getToken(dispatch).then((accessToken) => {
        agent.Organization.getOrgUserBasedOrgList(accessToken, id, page, PageSize, filter)
            .then((OrgUserList) => {
                console.log("OrgUserList:", OrgUserList);
                dispatch(setOrgUserBasedOrgDetails(OrgUserList));
                dispatch(clearOrgUserBasedOrgError());
            })
            .catch((err) => {
                console.log('getOrgUserBasedOnOrgList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(orgUserBasedOrgError(error))
            });
    });
}

export const getAppTypeAsyncSelect = (searchValue, callback) => (dispatch) => {
    console.log("searchValue:", searchValue)
    getToken(dispatch).then((accessToken) => {
        agent.Organization.getApptypeList(accessToken, searchValue)
            .then((AppTypeList) => {
                const { value } = AppTypeList;
                const updatedAppList = modifyAppTypeList(value);
                //eslint-disable-next-line
                callback && callback(updatedAppList);
            })
            .catch((err) => {
                console.log('getAppTypeAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                //eslint-disable-next-line
                callback && callback();
            });
    });
};


export const createOrganization = (formFields, filteredData) => (dispatch) => {
    const _ = require('lodash');
    // console.log("formFields:", _.isEmpty(formFields));
    let dataToSend = {}
    if (!formFields || _.isEmpty(formFields)) {
        dispatch(createOrganizationError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = {
            ...formFields,
        }
    }
    dispatch(createOrganizationPending());
    dispatch(clearCreateOrganizationError());
    getToken(dispatch).then((accessToken) => {
        agent.Organization.createOrganization(accessToken, dataToSend)
            .then(() => {
                dispatch(createOrganizationSuccess());
                dispatch(clearForm());
                dispatch(closeCreateOrganizationModalAction());
                dispatch(getOrganizationListAction(filteredData));
                // dispatch(getExportOrgList());
                dispatch(showSnackbarStatus('Organization created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    const { message } = err.response.text;
                    // if (err && err.status === 400) {
                    console.log("err.status:", err.status)
                    dispatch(createOrganizationError(err.response && err.response.text));
                    // dispatch(createOrganizationError("Please Fill All Required Fileds"));
                    // }
                    return;
                }
                console.log('Create Organization server error', err);
                dispatch(clearForm());
                dispatch(closeCreateOrganizationModalAction());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};

export const EditOrganization = (formFields) => (dispatch) => {
    const dataToSend = {
        ...formFields,
        Id: formFields.id
    }
    dispatch(clearUpdateOrganizationError());
    dispatch(updateOrganizationPending());
    getToken(dispatch).then((accessToken) => {
        agent.Organization.editOrganization(accessToken, dataToSend)
            .then(() => {
                dispatch(updateOrganizationSuccess());
                dispatch(closeEditOrganizationModal());
                dispatch(getOrganizationDetailsAction(formFields.id));
                dispatch(showSnackbarStatus('Organization details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    if (err && err.response && err.response.text === "Error occure while updating organization") {
                        dispatch(updateOrganizationError("This name is already used. Please choose a different name."));
                    } else {
                        dispatch(updateOrganizationError(err && err.response && err.response.text));
                    }
                    return;
                }
                console.log('Edit Organization server error', err);
                dispatch(updateOrganizationError(null));
                dispatch(closeEditOrganizationModal());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};