import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import {
    clearUpdateLeadSourceError,
    updateLeadSource,
    updateLeadSourceSuccess
} from '../../../../../../store/Modules/crm/Settings/LeadSource/actions';

const EditLeadSource = (props) => {
    const { leadSourceDetails, isLeadSourceUpdatePending, errorMessage } = props;
    const { Id, LeadSourceName, IsActive } = leadSourceDetails || {};

    const [leadSourceDetail, setLeadSourceDetail] = useState();
    const [Active, setActive] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        setLeadSourceDetail({
            ...leadSourceDetail,
            id: Id,
            leadSourceName: LeadSourceName,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateLeadSourceError();
        props.updateLeadSourceSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setLeadSourceDetail({
            ...leadSourceDetail,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        })
    }
    const handleActiveStatusChange = () => {
        setLeadSourceDetail({
            ...leadSourceDetail,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { leadSourceName } = leadSourceDetail || {};

        if (!leadSourceName) {
            currentErrors.leadSourceName = 'Lead source name is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateLeadSource(leadSourceDetail)

        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label>Lead Source Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="leadSourceName"
                        placeHolder="Enter lead source name"
                        onChange={validateOnchange}
                        value={leadSourceDetail && leadSourceDetail.leadSourceName}
                        invalid={errors && errors.leadSourceName}
                    />
                    <FormFeedback>{errors && errors.leadSourceName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isLeadSourceUpdatePending}
                    >
                        {isLeadSourceUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    leadSourceDetails: state.crmLeadSource.leadSourceDetails,
    isLeadSourceUpdatePending: state.crmLeadSource.isLeadSourceUpdatePending,
    errorMessage: state.crmLeadSource.leadSourceUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateLeadSource: (leadSourceDetail) => dispatch(updateLeadSource(leadSourceDetail)),
    clearUpdateLeadSourceError: () => dispatch(clearUpdateLeadSourceError()),
    updateLeadSourceSuccess: () => dispatch(updateLeadSourceSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditLeadSource);