import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Label
} from 'reactstrap';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';
import { clearUpdateTaskPercentageError, updateTaskPercentage, updateTaskPercentageSuccess } from '../../../../../../store/Modules/ProjectPortal/Settings/TaskPercentageStatus/actions';

const EditTaskPercentageStatus = (props) => {
    const { taskPercentageDetails, isTaskPercentageUpdatePending, errorMessage } = props;
    const { Id, StatusName, Percentage } = taskPercentageDetails || {};

    const [taskStatusPercentageDetails, setTaskPercentageDetails] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        setTaskPercentageDetails({
            ...taskStatusPercentageDetails,
            id: Id,
            Percentage: Percentage,
            StatusName: StatusName
        });
        props.clearUpdateTaskPercentageError();
        props.updateTaskPercentageSuccess();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
      
        setTaskPercentageDetails({
            ...taskStatusPercentageDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        });
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
            let currentErrors = {};
            const { Percentage } = taskStatusPercentageDetails || {};

            
            if (!Percentage) {
                currentErrors.Percentage = 'Task percentage is required';
            }
            setErrors(currentErrors);
            if (Object.keys(currentErrors).length === 0) {
                props.updateTaskPercentage(taskStatusPercentageDetails);
            }
    }
  return (
      <>
          {errorMessage && (
              <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
          )}
          <Form>
              <FormGroup>
                  <Label>Status Name</Label>
                  <Input
                      type="text"
                      name="StatusName"
                      placeHolder="Enter status name"
                    //   onChange={validateOnchange}
                      value={taskStatusPercentageDetails && taskStatusPercentageDetails.StatusName}
                    //   invalid={errors && errors.StatusName}
                    disabled
                  />
              </FormGroup>
              <FormGroup>
                  <Label>Percentage <span className='text-danger'>*</span></Label>
                  <InputGroup>
                      <Input
                          type="number"
                          className='border-end-0'
                          name="Percentage"
                          placeHolder="Enter task percentage"
                          onChange={(e) => {
                              const value = e.target.value;

                              if (value === "") {
                                  validateOnchange(e);
                              } else if (/^(0|0[0-9]|0[1-9][0-9]|[1-9][0-9]?|100)$/.test(value)) {
                                  // Allow "0", "01" to "09", "099", and valid numbers from 1 to 100
                                  validateOnchange(e);
                              } else if (/^00[0-9]*$/.test(value)) {
                                  // Block inputs like "001", "00010", etc.
                                  e.preventDefault();
                              } else {
                                  // Block any other invalid inputs
                                  e.preventDefault();
                              }
                          }}
                          value={taskStatusPercentageDetails && taskStatusPercentageDetails.Percentage}
                          invalid={errors && errors.Percentage}
                          onKeyDown={
                              (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                          }
                          onWheel={(e) => e.target.blur()}
                          onBlur={(e) => {
                              let value = parseFloat(e.target.value);
                              if (value < 0) {
                                  e.target.value = 0;
                              } else if (value > 100) {
                                  e.target.value = 100;
                              }
                          }}
                      />
                      <Button
                          disabled
                          style={errors && errors.Percentage ?
                              {
                                  borderColor: 'red',
                                  borderRadius: "0px 5px 5px 0px",
                                  borderLeft: 'none'

                              }
                              : {
                                  borderRadius: "0px 5px 5px 0px",
                                  borderColor: 'silver',
                              }
                          }
                          className='bg-white border-start-0'
                      >
                          <div className='text-dark fs-5 fw-bold'> % </div>
                      </Button>
                      <FormFeedback>{errors && errors.Percentage}</FormFeedback>
                  </InputGroup>
              </FormGroup>
              <FormGroup>
                  <Button
                      color='success'
                      className='me-2'
                      onClick={handleSubmit}
                      disabled={isTaskPercentageUpdatePending}
                  >
                      {isTaskPercentageUpdatePending ?
                          <div>
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                              Submitting...
                          </div>
                          : "Submit"}
                  </Button>
                  <Button
                      color='danger'
                      onClick={props.handleClose}
                  >Cancel
                  </Button>
              </FormGroup>
          </Form >
      </>
  )
}
const mapStateToProps = (state) => ({
    taskPercentageDetails: state.TaskPercentage.taskPercentageDetails,
    isTaskPercentageUpdatePending: state.TaskPercentage.isTaskPercentageUpdatePending,
    errorMessage: state.TaskPercentage.opportunityCurrentPhaseUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateTaskPercentage: (taskStatusPercentageDetails) => dispatch(updateTaskPercentage(taskStatusPercentageDetails)),
    clearUpdateTaskPercentageError: () => dispatch(clearUpdateTaskPercentageError()),
    updateTaskPercentageSuccess: () => dispatch(updateTaskPercentageSuccess()),
})
export default connect(mapStateToProps, mapDispatchToProps)(EditTaskPercentageStatus)