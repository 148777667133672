import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Input,
    InputGroup,
    Table
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faFont } from "@fortawesome/free-solid-svg-icons";
import NoRecord from "../../../../components/NoRecords/noRecords";
import VWModel from "../../../../components/Modal/VWModal";
import { getPager } from "../../../../helper/common";
import VWPagination from "../../../../components/vwPagination/vwPagination";
import VWMessage from "../../../../components/vwMessage/vwMessage";
import CreateMailConfiguration from "./createMailConfiguration";
import {
    closeAddMailSettingsModal,
    closeEditMailSettingsModal,
    getMailSettingsList,
    openAddMailSettingsModal,
    openEditMailSettingsModal,
    setMailSettingsDetails
} from "../../../../store/Modules/MassMailer/MailSettings/actions";
import EditMailConfiguration from "./editMailConfiguration";
import VWSpinner from "../../../../components/vwSpinner/vwSpinner";
import { MAIL_SETTINGS_STATUS_OPTIONS } from "../../../../store/Modules/MassMailer/MailSettings/constants";
import '../../viewList.css';
import moment from "moment-timezone";
import UITooltip from "../../../../CommonData/Data/UITooltip";

const customStyles = {
    container: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: 'normal'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'normal'
    }),
};

const style = {
    table: {
        width: '100%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'separate',
    },
    th: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#fff',
    },
};

const CampaignList = (props) => {
    const { mailSettingList } = props || {};
    const [pageSize] = useState(10);
    const [filter, setFilter] = useState();
    const [pager, setPager] = useState({ currentPage: 1 });
    const [filterSelectionOption, setFilterSelectionOption] = useState('name');

    //--------------------------------------------------FILTER----------------------------------------------------------------------------------------
    const buildQueryEmailAddress = () => {
        const { EmailAddress } = filter || {};
        let searchEmailAddress = '';
        if (EmailAddress) {
            searchEmailAddress += `${EmailAddress}`;
        }
        return searchEmailAddress
    }

    const buildQueryFromDisplayName = () => {
        const { FromDisplayName } = filter || {};
        let searchFromDisplayName = '';
        if (FromDisplayName) {
            searchFromDisplayName += `${FromDisplayName}`;
        }
        return searchFromDisplayName
    }

    const buildQuerySMTPPort = () => {
        const { SMTPPort } = filter || {};
        let searchSMTPPort = '';
        if (SMTPPort) {
            searchSMTPPort += `${SMTPPort}`;
        }
        return searchSMTPPort
    }

    const buildQueryUserName = () => {
        const { UserName } = filter || {};
        let searchUserName = '';
        if (UserName) {
            searchUserName += `${UserName}`;
        }
        return searchUserName
    }
    const buildQueryPassword = () => {
        const { Password } = filter || {};
        let searchPassword = '';
        if (Password) {
            searchPassword += `${Password}`;
        }
        return searchPassword
    }
    const buildQueryIsActive = () => {
        const { IsActive } = filter || {};
        let searchIsActive = '';
        if (IsActive) {
            searchIsActive += `${IsActive}`;
        }
        return searchIsActive
    }
    const buildQueryCreatedDate = () => {
        const { CreatedTimeStamp } = filter || {};
        let searchCreatedTimeStamp = '';
        if (CreatedTimeStamp) {
            searchCreatedTimeStamp += `${CreatedTimeStamp}`;
        }
        return searchCreatedTimeStamp
    }
    const buildQueryCreatedBy = () => {
        const { CreatedBy } = filter || {};
        let searchCreatedBy = '';
        if (CreatedBy) {
            searchCreatedBy += `${CreatedBy}`;
        }
        return searchCreatedBy
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, pageSize);
        setPager(currentPager)
    }, [props.totalCount])

    const setPage = (page = 1) => {
        props.getMailSettingsList(page, pageSize, buildQueryEmailAddress(), buildQueryFromDisplayName(), buildQuerySMTPPort(), buildQueryUserName(),
            buildQueryPassword(), buildQueryIsActive(), buildQueryCreatedDate(), buildQueryCreatedBy());
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / pageSize);
            const visiblePages = 10;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
                pageSize
            };
        });
    }

    useEffect(() => {
        const currentPageNumber = pager && pager.currentPage
        setPage(currentPageNumber)
    }, [])

    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (e) => {
        const input = e.target;
        const value = input.value;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [input.name]: value
        })
    }

    const searchStatusDD = (Value) => {
        const { label, value } = Value || {};
        setFilter({
            ...filter,
            IsActive: value,
        })
    }
    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }

    //-------------------------------------------------------------FILTER-SELECTION----------------------------------------------------------------------------
    const handleTextSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('date');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }
    const handleDateSelect = (e) => {
        e.preventDefault();
        setFilterSelectionOption('name');
        setFilter({
            ...filter,
            CreatedBy: null,
            CreatedTimeStamp: null,
        });
    }

    // ------------------------------------------------------SORTING---------------------------------------------------------------------------------
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedMailSettingsList = [...props.mailSettingsList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    //----------------------------------------------- Modal Open & Close------------------------------------------------------------------------------
    const handleAddOpenMailSetting = () => {
        props.openAddMailSettingsModal();
    }

    const handleClose = () => {
        props.closeAddMailSettingsModal();
    };

    const handleEditOpenMailSetting = (id) => {
        const { openEditMailSettingsModal, setMailSettingsDetails } = props;
        const selectedMailSetting = sortedMailSettingsList.find((setting) => {
            return setting.Id === id;
        });
        setMailSettingsDetails(selectedMailSetting);
        openEditMailSettingsModal();
    }

    const handleEditClose = () => {
        props.closeEditMailSettingsModal();
    };

    return (
        <>
            <div className="page-content">
                <Card >
                    <CardTitle tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center">
                        <strong>MAIL SETTINGS</strong>
                        <div className="content-heading d-flex flex-row-reverse">
                            <Link
                                type="button"
                                className="btn btn-info"
                                onClick={handleAddOpenMailSetting}
                            >
                                Create
                            </Link>&nbsp;
                        </div>
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody className="p-4">
                        <div style={{ height: "60vh", overflowX: "auto" }}>
                            {props.errorMessage && (
                                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert" />
                            )}
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                    <tr>
                                        <th onClick={() => handleSort('FromDisplayName')}>
                                            Email Address &nbsp;
                                            {sortOrder === 'FromDisplayName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('SMTPPort')}>
                                            Port No. &nbsp;
                                            {sortOrder === 'SMTPPort' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('UserName')}>
                                            Username &nbsp;
                                            {sortOrder === 'UserName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('Password')}>
                                            Password &nbsp;
                                            {sortOrder === 'Password' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('IsActive')}>
                                            Status &nbsp;
                                            {sortOrder === 'IsActive' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('CreatedBy')}>
                                            Created &nbsp;
                                            {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        {/* <th onClick={() => handleSort('CreatedTimeStamp')}>
                                            Created Date &nbsp;
                                            {sortOrder === 'CreatedTimeStamp' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th> */}

                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                            Update
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='EmailAddress'
                                                onChange={searchInputValidation}
                                                value={filter && filter.EmailAddress}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th>
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='SMTPPort'
                                                onChange={searchInputValidation}
                                                value={filter && filter.SMTPPort}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th>
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='UserName'
                                                onChange={searchInputValidation}
                                                value={filter && filter.UserName}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th>
                                            <Input
                                                type='text'
                                                placeholder='Search'
                                                name='Password'
                                                onChange={searchInputValidation}
                                                value={filter && filter.Password}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </th>
                                        <th>
                                            <Select
                                                styles={customStyles}
                                                isClearable
                                                name="IsActive"
                                                placeholder="Search"
                                                options={MAIL_SETTINGS_STATUS_OPTIONS}
                                                onChange={searchStatusDD}
                                            />
                                        </th>
                                        <th>
                                            <InputGroup style={{ width: "200px" }}>
                                                {filterSelectionOption === 'name' ?
                                                    <UITooltip id={`text`} key={`text`} content={`Text Filter`}>
                                                        <Button onClick={handleTextSelect}><FontAwesomeIcon icon={faFont} /></Button>
                                                    </UITooltip>
                                                    : <UITooltip id={`date`} key={`date`} content={`Date Filter`}>
                                                        <Button onClick={handleDateSelect}><FontAwesomeIcon icon={faCalendarAlt} /></Button>
                                                    </UITooltip>
                                                }
                                                <Input
                                                    type={filterSelectionOption === 'name' ? 'text' : 'date'}
                                                    placeholder='Search'
                                                    name={filterSelectionOption === 'name' ? 'CreatedBy' : 'CreatedTimeStamp'}
                                                    onChange={searchInputValidation}
                                                    onKeyPress={handleKeyPress}
                                                    value={filter ? filterSelectionOption === 'name' ? filter.CreatedBy || '' : filter.CreatedTimeStamp || '' : ""}
                                                />
                                            </InputGroup>
                                        </th>
                                        <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!props.isMailSettingsListPending && (props.mailSettingsList && props.mailSettingsList.length > 0) ?
                                        sortedMailSettingsList && sortedMailSettingsList.map((data) => (
                                            <tr>
                                                <td>{data.FromDisplayName || "NA"}
                                                    <br />
                                                    <small>{data.EmailAddress || "N/A"}</small>
                                                </td>
                                                <td>{data.SMTPPort || "N/A"}</td>
                                                <td>{data.UserName || "N/A"}</td>
                                                <td>{data.Password || "N/A"}</td>
                                                <td>
                                                    {data.IsActive ? (
                                                        <Badge color="success">Active</Badge>
                                                    ) : (
                                                        <Badge color="danger">Inactive</Badge>
                                                    )}
                                                </td>
                                                <td>
                                                    {data.CreatedBy || "N/A"}<br />
                                                    <small>{data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                                </td>
                                                <td className="sticky-column" style={{ backgroundColor: "white" }}>
                                                    <Button
                                                        className='fw-bold'
                                                        size='sm'
                                                        color='info'
                                                        onClick={() => handleEditOpenMailSetting(data.Id)}
                                                    >EDIT
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                        : props.isMailSettingsListPending ?
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <VWSpinner />
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {props.mailSettingsList && props.mailSettingsList.length > 0 ?
                            <VWPagination
                                pager={pager}
                                setPage={setPage}
                                totalRecords={props.totalCount}
                                pageSize={pageSize}
                            />
                            : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="CREATE NEW MAIL CONFIGURATION"
                    isOpen={props.isCreateMailSettingsOpen}
                    handleClose={handleClose}
                >
                    <CreateMailConfiguration
                        handleClose={handleClose}
                    />
                </VWModel>
                <VWModel
                    header="EDIT NEW MAIL CONFIGURATION"
                    isOpen={props.isUpdateMailSettingsOpen}
                    handleClose={handleEditClose}
                >
                    <EditMailConfiguration
                        handleClose={handleEditClose}
                    />
                </VWModel>
            </div >
        </>
    )
}

const mapStateToProps = (state) => ({
    mailSettingsList: state.mailSetting.mailSettingsList,
    totalCount: state.mailSetting.mailSettingsListCount,
    isMailSettingsListPending: state.mailSetting.isMailSettingsListPending,
    errorMessage: state.mailSetting.mailSettingsListErrorMessage,
    isCreateMailSettingsOpen: state.mailSetting.isCreateMailSettingsOpen,
    isUpdateMailSettingsOpen: state.mailSetting.isUpdateMailSettingsOpen,
})

const mapDispatchToProps = (dispatch) => ({
    getMailSettingsList: (page, pageSize, searchEmailAddress, searchFromDisplayName, searchSMTPPort, searchUserName, searchPassword, searchIsActive, searchCreatedDate, searchCreatedTimeStamp, searchCreatedBy) =>
        dispatch(getMailSettingsList(page, pageSize, searchEmailAddress, searchFromDisplayName, searchSMTPPort, searchUserName, searchPassword, searchIsActive, searchCreatedDate, searchCreatedTimeStamp, searchCreatedBy)),
    closeAddMailSettingsModal: () => dispatch(closeAddMailSettingsModal()),
    openAddMailSettingsModal: () => dispatch(openAddMailSettingsModal()),
    setMailSettingsDetails: (settingDetails) => dispatch(setMailSettingsDetails(settingDetails)),
    openEditMailSettingsModal: () => dispatch(openEditMailSettingsModal()),
    closeEditMailSettingsModal: () => dispatch(closeEditMailSettingsModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);