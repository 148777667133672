import { CLEAR_CREATE_VISION_DOCS, CLEAR_CREATE_VISION_DOCS_ERROR, CLEAR_DELETE_VISION_DOCS_ERROR, CLEAR_UPDATE_VISION_DOCS_ERROR, CLEAR_VISION_DOCS_DELETE_ERROR, CLEAR_VISION_DOCS_DETAILS, CLEAR_VISION_DOCS_DETAILS_ERROR, CLEAR_VISION_DOCS_LIST_ERROR, CLOSE_CREATE_VISION_DOCS_HEADER_MODAL, CLOSE_CREATE_VISION_DOCS_MODAL, CLOSE_UPDATE_VISION_DOCS_HEADER_MODAL, CLOSE_UPDATE_VISION_DOCS_MODAL, CLOSE_VISION_DOCS_DELETE, CLOSE_VISION_DOCS_MODAL, CREATE_VISION_DOCS_PENDING, CREATE_VISION_DOCS_SUCCESS, CREATE_VISIONDOCS_CONTENT, CREATE_VISIONDOCS_ERROR, CREATE_VISIONDOCS_TITLE, IS_CREATE_VISION_DOCS_PENDING, IS_CREATE_VISION_DOCS_SUCCESS, IS_UPDATE__VISION_DOCS_SUCCESS, IS_UPDATE_VISION_DOCS_PENDING, IS_UPDATE_VISION_DOCS_SUCCESS, OPEN_CREATE_VISION_DOCS_HEADER_MODAL, OPEN_CREATE_VISION_DOCS_MODAL, OPEN_UPDATE_VISION_DOCS_HEADER_MODAL, OPEN_UPDATE_VISION_DOCS_MODAL, OPEN_VISION_DOCS_DELETE, OPEN_VISION_DOCS_MODAL, SET_DOCS_LIST, SET_TOGGLE_VIEW, SET_VISION_DOCS_DETAILS, SET_VISION_DOCS_LIST, UPDATE_VISION_DOCS_PENDING, UPDATE_VISION_DOCS_SUCCESS, UPDATE_VISIONDOCS_ERROR, VISION_DOCS_CREATE_ID, VISION_DOCS_DELETE_LIST_ERROR, VISION_DOCS_DELETE_PENDING, VISION_DOCS_DETAILS_ERROR, VISION_DOCS_DETAILS_PENDING, VISION_DOCS_LIST_ERROR, VISION_DOCS_LIST_PENDING } from "./constants";


const initialState = {
    DocsList: [],
    errorMessage: null,
    isPending: false,
    isPendingList: false,
    ListErrorMessage: null,
    isPendingDetails: false,
    detailsErrorMessage: null,
    isPendingUpdate: false,
    toggleViewBool: true,
    updateErrorMessage: null,
    isOpenVisionDocsModal: false,
    isOpenUpdateVisionDocsModal: false,
    isUpdateEditDocsSuccess: false,
    DocsDetails: null,
    isCreateEditDocsSuccess: false,
    openCreateVisionDocsHeaderModal: false,
    CreateDocsID: null,
    documentContent: null,
    documentTitle: null,
    openUpdateVisionDocsHeaderModal: false,
    isOpenVisionDocsDelete:false,
    DocsDeleteError: null,
    isvisionDocsDeletePending:false
};

export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_VISION_DOCS_LIST: {
            const { list } = action.payload;
            // console.log("docsList:", docsList);
            return {
                ...localState,
                DocsList: list && list.value,
                totalRecords: list["@odata.count"],
            }
        }
        case VISION_DOCS_CREATE_ID: {
            const { id } = action.payload;
            console.log("id:", id.id);
            return {
                ...localState,
                CreateDocsID: id.id,
            }
        }
        case CLEAR_CREATE_VISION_DOCS: {
            console.log("trigger working");
            return {
                ...localState,
                CreateDocsID: null,
                documentContent:'',
                documentTitle: '',
            }
        }
        case SET_TOGGLE_VIEW: {
            const { toggleView } = action.payload;
            // console.log("docsList:", docsList);
            return {
                ...localState,
                toggleViewBool: toggleView
            }
        }
        case SET_VISION_DOCS_DETAILS: {
            const { details } = action.payload;
            // console.log("docsList:", docsList);
            return {
                ...localState,
                DocsDetails: details,
            }
        }
        case CLEAR_VISION_DOCS_DETAILS: {
            return {
                ...localState,
                DocsDetails: null,
            }
        }
        case CREATE_VISIONDOCS_CONTENT: {
            const { content } = action.payload;
            return {
                ...localState,
                documentContent: content,
            }
        }
        case CREATE_VISIONDOCS_TITLE: {
            const { title } = action.payload;
            return {
                ...localState,
                documentTitle: title,
            }
        }
        case CREATE_VISIONDOCS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                errorMessage,
                isPending: false,
            };
        }
        case VISION_DOCS_DELETE_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                DocsDeleteError: errorMessage,
            };
        }
        case CLEAR_DELETE_VISION_DOCS_ERROR: {
            return {
                ...localState,
                DocsDeleteError:null
            };
        }
        case UPDATE_VISIONDOCS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                updateErrorMessage: errorMessage,
                isPendingUpdate: false,
            };
        }
        case VISION_DOCS_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                ListErrorMessage: errorMessage,
                isPendingList: false,
            };
        }
        case VISION_DOCS_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                detailsErrorMessage: errorMessage,
                isPendingDetails: false,
            };
        }
        case CREATE_VISION_DOCS_PENDING: {
            return {
                ...localState,
                isPending: true,
            };
        }
        case IS_UPDATE_VISION_DOCS_SUCCESS: {
            return {
                ...localState,
                isUpdateEditDocsSuccess: true,
            };
        }
        case IS_CREATE_VISION_DOCS_SUCCESS: {
            return {
                ...localState,
                isCreateEditDocsSuccess: true,
            };
        }
        case IS_UPDATE_VISION_DOCS_PENDING: {
            return {
                ...localState,
                isUpdateEditDocsSuccess: false,
            };
        }
        case IS_CREATE_VISION_DOCS_PENDING: {
            return {
                ...localState,
                isCreateEditDocsSuccess: false,
            };
        }
        case OPEN_CREATE_VISION_DOCS_MODAL: {
            return {
                ...localState,
                isOpenVisionDocsModal: true,
            };
        }
        case OPEN_CREATE_VISION_DOCS_HEADER_MODAL: {
            return {
                ...localState,
                openCreateVisionDocsHeaderModal: true,
            };
        }
        case OPEN_UPDATE_VISION_DOCS_HEADER_MODAL: {
            return {
                ...localState,
                openUpdateVisionDocsHeaderModal: true,
            };
        }
        case CLOSE_UPDATE_VISION_DOCS_HEADER_MODAL: {
            return {
                ...localState,
                openUpdateVisionDocsHeaderModal: false,
            };
        }
        case CLOSE_CREATE_VISION_DOCS_HEADER_MODAL: {
            return {
                ...localState,
                openCreateVisionDocsHeaderModal: false,
            };
        }
        case OPEN_UPDATE_VISION_DOCS_MODAL: {
            return {
                ...localState,
                isOpenUpdateVisionDocsModal: true,
            };
        }
        case CLOSE_UPDATE_VISION_DOCS_MODAL: {
            return {
                ...localState,
                isOpenUpdateVisionDocsModal: false,
            };
        }
        case CLOSE_CREATE_VISION_DOCS_MODAL: {
            return {
                ...localState,
                isOpenVisionDocsModal: false,
            };
        }
        case UPDATE_VISION_DOCS_PENDING: {
            return {
                ...localState,
                isPendingUpdate: true,
            };
        }
        case UPDATE_VISION_DOCS_SUCCESS: {
            return {
                ...localState,
                isPendingUpdate: false,
            };
        }
        case VISION_DOCS_DETAILS_PENDING: {
            return {
                ...localState,
                isPendingDetails: true,
            };
        }
        case CLEAR_CREATE_VISION_DOCS_ERROR: {
            return {
                ...localState,
                errorMessage: null,
            };
        }
        case CLEAR_UPDATE_VISION_DOCS_ERROR: {
            return {
                ...localState,
                updateErrorMessage: null,
            };
        }
        case CLEAR_VISION_DOCS_DETAILS_ERROR: {
            return {
                ...localState,
                detailsErrorMessage: null,
            };
        }
        case CLEAR_VISION_DOCS_LIST_ERROR: {
            return {
                ...localState,
                ListErrorMessage: null,
                isPendingList: false
            };
        }
        case CREATE_VISION_DOCS_SUCCESS: {
            return {
                ...localState,
                isPending: false,
            };
        }
        case VISION_DOCS_LIST_PENDING: {
            return {
                ...localState,
                isPendingList: true,
            };
        }
        case OPEN_VISION_DOCS_DELETE: {
            return {
                ...localState,
                isOpenVisionDocsDelete: true,
            };
        }
        case CLOSE_VISION_DOCS_DELETE: {
            return {
                ...localState,
                isOpenVisionDocsDelete: false,
            };
        }
        case VISION_DOCS_DELETE_PENDING: {
            return {
                ...localState,
                isvisionDocsDeletePending: true,
            };
        }
        case CLEAR_VISION_DOCS_DELETE_ERROR: {
            return {
                ...localState,
                isvisionDocsDeletePending: false,
                DocsDeleteError:null
            };
        }
        default: {
            return localState;
        }
    }
};
