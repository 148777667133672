const AdminSidebarDataSuperAdmin = [

    {
        label: "Organization",
        isMainMenu: true,
    },
    {
        label: "All Organizations",
        icon: "mdi mdi-earth",
        isHasArrow: true,
        url: "/admin/organization",
    },
    {
        label: "App Master",
        icon: "mdi mdi-cellphone",
        isHasArrow: true,
        url: "/admin/appmaster",
    },

    // {
    //     label: "User Management",
    //     isMainMenu: true,
    // },
    // {
    //     label: "Admin User",
    //     icon: "mdi mdi-shield-account-outline",
    //     isHasArrow: true,
    //     url: "/admin/adminuser",
    // },
    // {
    //     label: "Organization User",
    //     icon: "mdi mdi-account-tie-outline",
    //     isHasArrow: true,
    //     url: "/admin/orguser",
    // },
    // {
    //     label: "Groups",
    //     icon: "mdi mdi-account-group-outline",
    //     isHasArrow: true,
    //     url: "/admin/groups",
    // },
    {
        label: "Role Management",
        isMainMenu: true,
    },
    {
        label: "Roles",
        icon: "mdi mdi-briefcase-account-outline",
        isHasArrow: true,
        url: "/admin/roles",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/admin/documents",
    },

]

const AdminSidebarDataAdministrator = [

    {
        label: "Organization",
        isMainMenu: true,
    },
    {
        label: "My Organization",
        icon: "mdi mdi-earth",
        isHasArrow: true,
        url: "/admin/organization",
    },
    {
        label: "App Master",
        icon: "mdi mdi-cellphone",
        isHasArrow: true,
        url: "/admin/appmaster",
    },


    {
        label: "User Management",
        isMainMenu: true,
    },

    {
        label: "Organization User",
        icon: "mdi mdi-account-tie-outline",
        isHasArrow: true,
        url: "/admin/orguser",
    },
    {
        label: "Groups",
        icon: "mdi mdi-account-group-outline",
        isHasArrow: true,
        url: "/admin/groups",
    },
    {
        label: "Role Management",
        isMainMenu: true,
    },
    {
        label: "Roles",
        icon: "mdi mdi-briefcase-account-outline",
        isHasArrow: true,
        url: "/admin/roles",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/admin/documents",
    },
    {
        label: "Portal Management",
        isMainMenu: true,
    },
    {
        label: "Portal Logo",
        icon: "mdi mdi-image-plus",
        isHasArrow: true,
        url: "/admin/portal-logo",
    },


]
const AdminSidebarDataMultiAdministrator = [

    {
        label: "Organization",
        isMainMenu: true,
    },
    {
        label: "All Organization",
        icon: "mdi mdi-earth",
        isHasArrow: true,
        url: "/admin/organization",
    },
    {
        label: "App Master",
        icon: "mdi mdi-cellphone",
        isHasArrow: true,
        url: "/admin/appmaster",
    },


    {
        label: "User Management",
        isMainMenu: true,
    },

    {
        label: "Organization User",
        icon: "mdi mdi-account-tie-outline",
        isHasArrow: true,
        url: "/admin/orguser",
    },
    {
        label: "Groups",
        icon: "mdi mdi-account-group-outline",
        isHasArrow: true,
        url: "/admin/groups",
    },
    {
        label: "Role Management",
        isMainMenu: true,
    },
    {
        label: "Roles",
        icon: "mdi mdi-briefcase-account-outline",
        isHasArrow: true,
        url: "/admin/roles",
    },
    {
        label: "Documents",
        icon: "mdi mdi-file-document-outline",
        isHasArrow: true,
        url: "/admin/documents",
    },

]
export { AdminSidebarDataSuperAdmin, AdminSidebarDataAdministrator, AdminSidebarDataMultiAdministrator };