import React from 'react'
import { Card, CardBody, Col, Input, Label, Row, Table } from 'reactstrap'
import Async from 'react-select/async';

const salaryCreate = () => {
    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <Label>Employee Name</Label>
                                    <Async
                                        key="selected_state"
                                        id="selected_state"
                                        name="selected_state"
                                        placeholder="Select Employee Name"
                                        //cacheOptions
                                        defaultOptions
                                    />
                                </Col>
                                <Col md={6}>
                                    <Label>Annual CTC</Label>
                                    <Input type='text' placeholder='   Annual CTC' />
                                </Col>
                            </Row>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>SALARY COMPONENTS</th>
                                        <th>CALCULATION TYPE</th>
                                        <th>MONTHLY AMOUNT</th>
                                        <th>ANNUAL AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Basic</td>
                                        <td><Input type='text' placeholder='50 |     % of CTC' /></td>
                                        <td><Input type='text' placeholder='0' /></td>
                                        <td><Input type='text' placeholder='0' /></td>
                                    </tr>
                                    <tr>
                                        <td>House Rent Allowance</td>
                                        <td><Input type='text' placeholder='50 |     % of Basic' /></td>
                                        <td><Input type='text' placeholder='0' /></td>
                                        <td><Input type='text' placeholder='0' /></td>
                                    </tr>
                                    <tr>
                                        <td>Conveyance Allowance</td>
                                        <td>Fixed amount</td>
                                        <td><Input type='text' placeholder='0' /></td>
                                        <td><Input type='text' placeholder='0' /></td>
                                    </tr>
                                    <tr>
                                        <td>Fixed Allowance</td>
                                        <td>Fixed amount</td>
                                        <td><Input type='text' placeholder='0' /></td>
                                        <td><Input type='text' placeholder='0' /></td>
                                    </tr>
                                    <Card>
                                        <CardBody>
                                            <Table>
                                                <thead>
                                                    
                                                   
                                                    <th >Cost to Company</th>
                                                  
                                                   
                                                    
                                                </thead>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </tbody>
                            </Table>

                        </CardBody>
                    </Card>

                </div>
            </React.Fragment>

        </>
    )
}

export default salaryCreate