
//--------------------VISIT-LIST-----------------------------------
export const VISIT_LIST_PENDING = "VISIT_LIST_PENDING";
export const VISIT_EXPORT_LIST_PENDING = "VISIT_EXPORT_LIST_PENDING";
export const VISIT_LIST_ERROR = "VISIT_LIST_ERROR";
export const VISIT_EXPORT_LIST_ERROR = "VISIT_EXPORT_LIST_ERROR";
export const VISIT_LIST_ERROR_CLEAR = "VISIT_LIST_ERROR_CLEAR";
export const VISIT_EXPORT_LIST_ERROR_CLEAR = "VISIT_EXPORT_LIST_ERROR_CLEAR";
export const SET_VISIT_LIST = "SET_VISIT_LIST";
export const SET_VISIT_EXPORT_LIST = "SET_VISIT_EXPORT_LIST";


//-------------------VISIT - CREATE------------------------------------
export const VISIT_CREATE_OPEN = "VISIT_CREATE_OPEN";
export const VISIT_CREATE_CLOSE = "VISIT_CREATE_CLOSE";
export const VISIT_CREATE_PENDING = "VISIT_CREATE_PENDING";
export const VISIT_CREATE_ERROR = "VISIT_CREATE_ERROR";
export const VISIT_CREATE_ERROR_CLEAR = "VISIT_CREATE_ERROR_CLEAR";
export const VISIT_CREATE_SUCCESS = "VISIT_CREATE_SUCCESS";

//------------------VISIT - UPDATE------------------------------------
export const VISIT_UPDATE_OPEN = "VISIT_UPDATE_OPEN";
export const VISIT_UPDATE_CLOSE = "VISIT_UPDATE_CLOSE";
export const VISIT_UPDATE_PENDING = "VISIT_UPDATE_PENDING";
export const VISIT_UPDATE_ERROR = "VISIT_UPDATE_ERROR";
export const VISIT_UPDATE_ERROR_CLEAR = "VISIT_UPDATE_ERROR_CLEAR";
export const VISIT_UPDATE_SUCCESS = "VISIT_UPDATE_SUCCESS";

//------------------VISIT - DETAILS----------------------------------

export const VISIT_DETAILS_PENDING = "VISIT_DETAILS_PENDING";
export const VISIT_DETAILS_ERROR = "VISIT_DETAILS_ERROR";
export const VISIT_DETAILS_ERROR_CLEAR = "VISIT_DETAILS_ERROR_CLEAR";
export const SET_VISIT_DETAILS = "SET_VISIT_DETAILS";

//--------------------OPTIONS-----------------------------------------
export const HOURS = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
]

export const MINUTES = [
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
    { value: '32', label: '32' },
    { value: '33', label: '33' },
    { value: '34', label: '34' },
    { value: '35', label: '35' },
    { value: '36', label: '36' },
    { value: '37', label: '37' },
    { value: '38', label: '38' },
    { value: '39', label: '39' },
    { value: '40', label: '40' },
    { value: '41', label: '41' },
    { value: '42', label: '42' },
    { value: '43', label: '43' },
    { value: '44', label: '44' },
    { value: '45', label: '45' },
    { value: '46', label: '46' },
    { value: '47', label: '47' },
    { value: '48', label: '48' },
    { value: '49', label: '49' },
    { value: '50', label: '50' },
    { value: '51', label: '51' },
    { value: '52', label: '52' },
    { value: '53', label: '53' },
    { value: '54', label: '54' },
    { value: '55', label: '55' },
    { value: '56', label: '56' },
    { value: '57', label: '57' },
    { value: '58', label: '58' },
    { value: '59', label: '59' },
]

export const TIME_POSTFIX = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
]

//----------------------------------------EXPORT------------------------------------------------

export const VISIT_EXPORT_HEADERS = [
    'VISIT NAME',
    'VISIT USER NAME',
    'VISIT USER EMAIL',
    'CONTACT NAME',
    'CONTACT EMAIL',
    'SUBJECT',
    'VISIT TYPE',
    'START DATE',
    'END DATE',
    'START LOCATION',
    'END LOCATION',
    'DISTANCE (IN KMs)',
    'TRANSPORT MODE',
    'CREATED BY',
    'CREATED ON',
    'VISIT STATUS',
    'VISIT ADDRESS'
]