import React from 'react'
import OverAllDocsList from '../../OverAllDocs/OverAllDocsList'
import { connect } from 'react-redux';

const ProjectDocs = (props) => {
    // const AppID = '0202241115302907263'

  const { userAppDetails } = props.profile;
  console.log("userAppDetails:", userAppDetails);
  const App = userAppDetails.filter((i) => {
    return (i.appName === "ProjectPortal") ? i.appId : ""
  })
  return (
    <OverAllDocsList data={App[0] && App[0].appId} />
  )
}

const mapStateToProps = (state) => ({
  profile: state.login.currentProfile,
})
export default connect(mapStateToProps, null)(ProjectDocs)