import {
    CLEAR_CONTACT_DETAILS_ERROR,
    CLEAR_CONTACT_LIST_ERROR,
    CONTACT_DETAILS_ERROR,
    CONTACT_LIST_ERROR,
    SET_CONTACT_DETAILS,
    SET_CONTACT_DETAILS_PENDING,
    SET_CONTACT_LIST, SET_CONTACT_PENDING,
    SET_EXPORT_CONTACT_LIST,
} from "./constants";

const initialState = {
    //---------------------------------------------MM-CONTACT-LIST----------------------------------------------------------------------------------
    contactList: [],
    contactExportList: [],
    contactListCount: null,
    isContactListPending: null,
    contactListError: null,
    //---------------------------------------------MM-CONTACT-DETAILS----------------------------------------------------------------------------------
    contactDetails: {},
    contactDetailsError: null,
    isContactDetailsPending: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //---------------------------------------------MM-CONTACT-LIST----------------------------------------------------------------------------------
        case SET_CONTACT_LIST: {
            const { contactList } = action.payload;
            const contactsList = contactList.value;
            const contactListCount = contactList['@odata.count'];
            return {
                ...localState,
                contactList: contactsList,
                contactListCount: contactListCount,
                isContactListPending: false,
                contactListError: null,
            }
        }
        case SET_EXPORT_CONTACT_LIST: {
            const contactList = action.payload;
            return {
                ...localState,
                contactExportList: contactList,
            }
        }
        case SET_CONTACT_PENDING: {
            return {
                ...localState,
                contactListError: null,
                isContactListPending: true
            }
        }
        case CONTACT_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                contactListError: errorMessage,
                isContactListPending: false,
                contactList: [],
                contactListCount: 0
            };
        }
        case CLEAR_CONTACT_LIST_ERROR: {
            return {
                ...localState,
                contactListError: null,
            };
        }
        //---------------------------------------------MM-CONTACT-DETAILS-------------------------------------------------------------------------------
        case SET_CONTACT_DETAILS: {
            const { contactDetails } = action.payload;
            return {
                ...localState,
                contactDetails: contactDetails,
                contactDetailsError: null,
                isContactDetailsPending: false,
            }
        }
        case SET_CONTACT_DETAILS_PENDING: {
            return {
                ...localState,
                contactDetailsError: null,
                isContactDetailsPending: true
            }
        }
        case CONTACT_DETAILS_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                contactDetails: null,
                contactDetailsError: errorMessage,
                isContactDetailsPending: false,
            };
        }
        case CLEAR_CONTACT_DETAILS_ERROR: {
            return {
                ...localState,
                contactDetailsError: null,
            };
        }

        default: {
            return localState;
        }
    }
};
