import React, { useEffect } from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Table
} from "reactstrap";
import { connect } from "react-redux";
import moment from 'moment';
import VWModel from "../../../../../../components/Modal/VWModal";
import VWMessage from "../../../../../../components/vwMessage/vwMessage";
import NoRecord from "../../../../../../components/NoRecords/noRecords";
import VWPagination from "../../../../../../components/vwPagination/vwPagination";
import { useState } from "react";
import { getPager } from "../../../../../../helper/common";
import VWSpinner from "../../../../../../components/vwSpinner/vwSpinner";
import {
    clearUpdateLeadCategoryError,
    closeAddLeadCategoryModal,
    closeEditLeadCategoryModal,
    getLeadCategoryList,
    IsLeadCategoryInineUpdate,
    openAddLeadCategoryModal,
    openEditLeadCategoryModal,
    setLeadCategoryDetails,
    updateLeadCategory,
    updateLeadCategorySuccess
} from "../../../../../../store/Modules/crm/Settings/LeadCategory/actions";
import EditLeadCategory from "./editLeadCategory";
import AddLeadCategory from "./addLeadCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck, faEdit, faSave, faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { setIsInlineUpdate } from "../../../../../../store/Modules/crm/Settings/SettingsInlineUpdate/actions";

const LeadCategory = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });

    const setPage = (page = 1) => {
        props.getLeadCategoryList(page, PageSize);
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.setIsInlineUpdate();
        props.clearUpdateLeadCategoryError();
        props.updateLeadCategorySuccess();
    }, [])
    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    const sortedLeadCategoryList = [...props.leadCategoryList].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        if (sortOrder === 'IsActive') {
            return sortDirection === 'asc' ? b.IsActive - a.IsActive : a.IsActive - b.IsActive;
        }
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    //---------------------------------------- Modal Open & Close----------------------------------------------------
    const handleAddOpenLeadCategory = () => {
        props.openAddLeadCategoryModal();
    }
    const handleClose = () => {
        props.closeAddLeadCategoryModal();
    };

    const handleCategoryEdit = (id) => {
        const { setLeadCategoryDetails, openEditLeadCategoryModal } = props;
        const selected_Category = sortedLeadCategoryList.find((category) => {
            return category.Id === id;
        });
        setLeadCategoryDetails(selected_Category);
        openEditLeadCategoryModal();
    }
    const handleEditClose = () => {
        props.closeEditLeadCategoryModal();
    };

    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();
    const [editCategoryList, setEditCategoryList] = useState({ leadCategoryName: '' });

    const handleEditClick = (itemId, IsActive, LeadCategory) => {
        props.setIsInlineUpdate(itemId);
        setActive(IsActive);
        setEditCategoryList({ ...editCategoryList, leadCategoryName: LeadCategory, isActive: IsActive, Id: itemId });
        setErrors({});
    };
    const handleClearClick = () => {
        props.setIsInlineUpdate(null);
    };

    const handleActiveStatusChange = () => {
        setEditCategoryList({
            ...editCategoryList,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }
    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setEditCategoryList({
            ...editCategoryList,
            [name]: value
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            [editCategoryList.Id]: {
                ...prevErrors[editCategoryList.Id],
                [name]: value ? null : `Category name is required`
            }
        }));
    }
    console.log("isInlineEditID:", props.isInlineEditID);
    console.log("editCategoryList:", editCategoryList);

    const handleSave = (e) => {
        // e.preventDefault();
        let currentErrors = {};
        const { leadCategoryName } = editCategoryList || {};

        if (!leadCategoryName) {
            currentErrors.leadCategoryName = 'Category name is required';
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [editCategoryList.Id]: currentErrors
        }));

        if (Object.keys(currentErrors).length === 0) {
            props.updateLeadCategory(editCategoryList)
        }
    }

    return (
        <React.Fragment>
            <div>
                <Card >
                    <CardTitle className=" fs-5 border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center fw-bold">
                        LEAD CATEGORY
                        <Button
                            className='fw-bold'
                            size='sm'
                            color='info'
                            onClick={handleAddOpenLeadCategory} >
                            ADD
                        </Button>
                    </CardTitle>
                </Card>
                <Card>
                    <CardBody>
                        <div style={{ height: "380px", overflowX: "auto" }}>
                            {(props.errorMessage || props.leadCategoryUpdateErrorMessage) ? <div className='w-100'>
                                {(props.errorMessage || props.leadCategoryUpdateErrorMessage) && (
                                    <VWMessage type="danger" defaultMessage={props.errorMessage || props.leadCategoryUpdateErrorMessage} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "1" }}>
                                    <tr>
                                        <th onClick={() => handleSort('LeadCategoryName')} className='hand-cursor'>
                                            Category Name &nbsp;
                                            {sortOrder === 'LeadCategoryName' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('IsActive')} className='hand-cursor'>
                                            Status &nbsp;
                                            {sortOrder === 'IsActive' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th onClick={() => handleSort('CreatedBy')} className='hand-cursor'>
                                            Created By &nbsp;
                                            {sortOrder === 'CreatedBy' && (<i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.leadCategoryList && props.leadCategoryList.length > 0 ?
                                        sortedLeadCategoryList && sortedLeadCategoryList.map((data) => (
                                            <tr class="middle-align">
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <FormGroup>
                                                            <Input
                                                                // style={{ width: "max-content" }}
                                                                size='md'
                                                                type="text"
                                                                name="leadCategoryName"
                                                                placeHolder="Enter category name"
                                                                onChange={validateOnchange}
                                                                value={editCategoryList.leadCategoryName}
                                                                invalid={errors && errors[editCategoryList.Id]?.leadCategoryName}
                                                            />
                                                            <FormFeedback>{errors && errors[editCategoryList.Id]?.leadCategoryName}</FormFeedback>
                                                        </FormGroup>
                                                    </td>
                                                    :
                                                    <td>{data.LeadCategoryName || "N/A"}</td>
                                                }
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <FormGroup>
                                                            <ul className="form-settings-unstyled-list form-settings-status-list">
                                                                <li
                                                                    onClick={handleActiveStatusChange}
                                                                    className={`form-settings-status-item ${!Active ? 'active' : ''}`}
                                                                >
                                                                    Inactive
                                                                </li>
                                                                <li
                                                                    onClick={handleActiveStatusChange}
                                                                    className={`form-settings-status-item ${Active ? 'active' : ''}`}
                                                                >
                                                                    Active
                                                                </li>
                                                            </ul>
                                                        </FormGroup>
                                                    </td>
                                                    :
                                                    <td>
                                                        {data.IsActive ? (
                                                            <Badge color="success">Active</Badge>
                                                        ) : (
                                                            <Badge color="danger">Inactive</Badge>
                                                        )}
                                                    </td>
                                                }
                                                <td>
                                                    {data.CreatedBy || "N/A"}<br />
                                                    <small>{data.CreatedTimeStamp ? moment(data.CreatedTimeStamp).format('DD MMM YYYY') : "N/A"}</small>
                                                </td>
                                                {(props.isInlineEditID === data.Id) ?
                                                    <td>
                                                        <>
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                style={{ color: "green", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleSave()}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faXmark}
                                                                style={{ color: "red", cursor: "pointer", marginLeft: '10px', fontSize: '18px' }}
                                                                onClick={() => handleClearClick(data.Id)}
                                                            />
                                                        </>
                                                    </td>
                                                    :
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            style={{ color: "black", cursor: "pointer", marginLeft: '10px' }}
                                                            onClick={() => handleEditClick(data.Id, data.IsActive, data.LeadCategoryName)}
                                                        />
                                                    </td>
                                                }
                                            </tr>
                                        ))
                                        : props.isLeadCategoryListPending ?
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <VWSpinner />
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan={50}>
                                                    <NoRecord />
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                    <CardFooter>
                        {props.leadCategoryList && props.leadCategoryList.length > 0 ?
                            <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                        }
                    </CardFooter>
                </Card>
                <VWModel
                    header="ADD LEAD CATEGORY"
                    modalSize="medium"
                    isOpen={props.isCreateLeadCategoryOpen}
                    handleClose={handleClose}
                >
                    <AddLeadCategory
                        handleClose={handleClose}
                    />
                </VWModel>
                <VWModel
                    header="EDIT LEAD CATEGORY"
                    modalSize="medium"
                    isOpen={props.isUpdateLeadCategoryOpen}
                    handleClose={handleEditClose}
                >
                    <EditLeadCategory
                        handleClose={handleEditClose}
                    />
                </VWModel>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    leadCategoryList: state.crmLeadCategory.leadCategoryList,
    totalCount: state.crmLeadCategory.leadCategoryListCount,
    isLeadCategoryListPending: state.crmLeadCategory.isLeadCategoryListPending,
    errorMessage: state.crmLeadCategory.leadCategoryListErrorMessage,
    isCreateLeadCategoryOpen: state.crmLeadCategory.isCreateLeadCategoryOpen,
    isUpdateLeadCategoryOpen: state.crmLeadCategory.isUpdateLeadCategoryOpen,
    isInlineEditID: state.SettingsInlineEdit.isInlineEditID,
    isLeadCategoryUpdatePending: state.crmLeadCategory.isLeadCategoryUpdatePending,
    leadCategoryUpdateErrorMessage: state.crmLeadCategory.leadCategoryUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    getLeadCategoryList: (page, PageSize) => dispatch(getLeadCategoryList(page, PageSize)),
    openAddLeadCategoryModal: () => dispatch(openAddLeadCategoryModal()),
    closeAddLeadCategoryModal: () => dispatch(closeAddLeadCategoryModal()),
    openEditLeadCategoryModal: () => dispatch(openEditLeadCategoryModal()),
    closeEditLeadCategoryModal: () => dispatch(closeEditLeadCategoryModal()),
    setLeadCategoryDetails: (categoryDetails) => dispatch(setLeadCategoryDetails(categoryDetails)),
    setIsInlineUpdate: (itemId) => dispatch(setIsInlineUpdate(itemId)),
    updateLeadCategory: (categoryDetails) => dispatch(updateLeadCategory(categoryDetails)),
    clearUpdateLeadCategoryError: () => dispatch(clearUpdateLeadCategoryError()),
    updateLeadCategorySuccess: () => dispatch(updateLeadCategorySuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeadCategory);