import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { closeEditOrgUserModal, getOrgUserDetailsAction, openEditOrgUserModal } from '../../../../../store/Modules/Admin/OrgUser/actions';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { Badge, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import VWModel from '../../../../../components/Modal/VWModal';
import OrgUserEdit from '../OrgUser/OrgUserEdit';
import { closeUpdateAdminUserModalAction, openUpdateAdminUserModalAction } from '../../../../../store/Modules/Admin/AdminUser/actions';
import EditAdminUser from './EditAdminUser';

const AdminUserDetails = (props) => {

    const params = useParams();
    const { id } = params;

    useEffect(() => {
        props.getOrgUserDetails(id);
    }, [props.getOrgUserDetails, id]);

    //-------------------------------------Modal open & close---------------------------------------------------
    const handleOpenEditAdminUserModel = () => {
        const { openEditAdminUserModalAction } = props;
        openEditAdminUserModalAction();
    };

    const handleCloseEditOrgUserModal = () => {
        const { closeEditAdminUserModalAction } = props;
        closeEditAdminUserModalAction();
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardTitle
                        tag="h4"
                        className="border-bottom px-4 py-3 mb-0 d-flex justify-content-between align-items-center"
                    >
                        Admin User Details
                        <Link type="button"
                            className="btn btn-outline-secondary"
                            onClick={handleOpenEditAdminUserModel}
                        >
                            Edit
                        </Link>
                    </CardTitle>
                    <CardBody className="p-4">
                        <Row className="justify-content-center">
                            <Col xl="6">
                                <div className="card b">
                                    <div className="card-header">
                                        <h4 className="my-2">
                                            <span className='text-capitalize'>{props.OrgUserDetails && props.OrgUserDetails.orgName || "NA"}</span>
                                        </h4>
                                    </div>
                                    <div className="card-body bt">
                                        <div className="row pl-4">
                                            <div className="col-lg-12">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <strong>Org Name</strong>
                                                                </td>
                                                                <td>{props.OrgUserDetails && props.OrgUserDetails.orgName || "NA"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>User Email</strong>
                                                                </td>
                                                                <td>{props.OrgUserDetails && props.OrgUserDetails.userEmail || "NA"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>First Name</strong>
                                                                </td>
                                                                <td>{props.OrgUserDetails && props.OrgUserDetails.firstName || "NA"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Last Name</strong>
                                                                </td>
                                                                <td>{props.OrgUserDetails && props.OrgUserDetails.lastName || "NA"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong> isPortal Enable</strong>
                                                                </td>
                                                                <td>{props.OrgUserDetails && props.OrgUserDetails.isPortalEnable ? (
                                                                    <Badge color="success" className="text-dark-white">
                                                                        Active
                                                                    </Badge>
                                                                ) : (
                                                                    <Badge color="danger" className="text-dark-white">
                                                                        InActive
                                                                    </Badge>
                                                                )}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>CreatedBy</strong>
                                                                </td>
                                                                <td>{props.OrgUserDetails && props.OrgUserDetails.createdBy || "NA"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Created Date</strong>
                                                                </td>
                                                                <td>
                                                                    {props.OrgUserDetails && props.OrgUserDetails.createdDateTime
                                                                        ? moment(props.OrgUserDetails && props.OrgUserDetails.createdDateTime).format(
                                                                            'DD MMM YYYY',
                                                                        )
                                                                        : 'NA'}
                                                                </td></tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Last Login Date</strong>
                                                                </td>
                                                                <td>
                                                                    {props.OrgUserDetails && props.OrgUserDetails.lastLoginTimeStamp
                                                                        ? moment(props.OrgUserDetails && props.OrgUserDetails.lastLoginTimeStamp).format(
                                                                            'DD MMM YYYY',
                                                                        )
                                                                        : 'NA'}
                                                                </td></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <VWModel
                        header="EDIT ADMIN USER"
                        isOpen={props.isEditAdminUserModalOpen}
                        handleClose={handleCloseEditOrgUserModal}
                    >
                        <EditAdminUser handleClose={handleCloseEditOrgUserModal} />
                    </VWModel>
                </Card>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    OrgUserDetails: state.OrgUser.OrgUserDetails,
    isEditAdminUserModalOpen: state.AdminUser.isUpdateAdminUserModalOpen,

});

const mapDispatchToProps = (dispatch) => ({
    getOrgUserDetails: (Id) => dispatch(getOrgUserDetailsAction(Id)),
    openEditAdminUserModalAction: () => dispatch(openUpdateAdminUserModalAction()),
    closeEditAdminUserModalAction: () => dispatch(closeUpdateAdminUserModalAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserDetails)