import {
    MAIL_SETTINGS_CREATE_CLOSE,
    MAIL_SETTINGS_CREATE_ERROR,
    MAIL_SETTINGS_CREATE_ERROR_CLEAR,
    MAIL_SETTINGS_CREATE_OPEN,
    MAIL_SETTINGS_CREATE_PENDING,
    MAIL_SETTINGS_CREATE_SUCCESS,
    MAIL_SETTINGS_LIST_ERROR,
    MAIL_SETTINGS_LIST_ERROR_CLEAR,
    MAIL_SETTINGS_LIST_PENDING,
    MAIL_SETTINGS_UPDATE_CLOSE,
    MAIL_SETTINGS_UPDATE_ERROR,
    MAIL_SETTINGS_UPDATE_ERROR_CLEAR,
    MAIL_SETTINGS_UPDATE_OPEN,
    MAIL_SETTINGS_UPDATE_PENDING,
    MAIL_SETTINGS_UPDATE_SUCCESS,
    SET_MAIL_SETTINGS_DETAILS,
    SET_MAIL_SETTINGS_LIST,
} from "./constants";

const initialState = {
    //--------------------MAIL-SETTING-CREATE-----------------------------------
    isCreateMailSettingsOpen: false,
    mailSettingsCreateErrorMessage: null,
    isMailSettingsCreateSuccess: false,
    isMailSettingsCreatePending: false,
    //--------------------MAIL-SETTING-UPDATE-----------------------------------
    mailSettingsDetails: null,
    isUpdateMailSettingsOpen: false,
    mailSettingsUpdateErrorMessage: null,
    isMailSettingsUpdateSuccess: false,
    isMailSettingsUpdatePending: false,
    //--------------------MAIL-SETTING-LIST-------------------------------------
    mailSettingsList: [],
    mailSettingsListCount: 0,
    isMailSettingsListPending: false,
    mailSettingsListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------MAIL-SETTING-CREATE-----------------------------------
        case MAIL_SETTINGS_CREATE_OPEN: {
            return {
                ...localState,
                isCreateMailSettingsOpen: true
            }
        }
        case MAIL_SETTINGS_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateMailSettingsOpen: false
            }
        }
        case MAIL_SETTINGS_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                mailSettingsCreateErrorMessage: null,
                isMailSettingsCreatePending: false,
            }
        }
        case MAIL_SETTINGS_CREATE_PENDING: {
            return {
                ...localState,
                isMailSettingsCreatePending: true,
            }
        }
        case MAIL_SETTINGS_CREATE_SUCCESS: {
            return {
                ...localState,
                isMailSettingsCreateSuccess: true,
                isMailSettingsCreatePending: false
            }
        }
        case MAIL_SETTINGS_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                mailSettingsCreateErrorMessage: errorMessage,
                isMailSettingsCreatePending: false,
            }
        }

        //--------------------MAIL-SETTING-UPDATE-----------------------------------
        case SET_MAIL_SETTINGS_DETAILS: {
            const { settingDetails } = action.payload;
            return {
                ...localState,
                mailSettingsDetails: settingDetails
            }
        }
        case MAIL_SETTINGS_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateMailSettingsOpen: true
            }
        }
        case MAIL_SETTINGS_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateMailSettingsOpen: false
            }
        }
        case MAIL_SETTINGS_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                mailSettingsUpdateErrorMessage: null,
            }
        }
        case MAIL_SETTINGS_UPDATE_PENDING: {
            return {
                ...localState,
                isMailSettingsUpdatePending: true,
            }
        }
        case MAIL_SETTINGS_UPDATE_SUCCESS: {
            return {
                ...localState,
                isMailSettingsUpdateSuccess: true,
                isMailSettingsUpdatePending: false
            }
        }
        case MAIL_SETTINGS_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                mailSettingsUpdateErrorMessage: errorMessage,
                isMailSettingsUpdatePending: false
            }
        }
        //--------------------MAIL-SETTING-CATEGORY-LIST-------------------------------------
        case SET_MAIL_SETTINGS_LIST: {
            const { mailSettingsList } = action.payload;
            return {
                ...localState,
                mailSettingsList: mailSettingsList && mailSettingsList['value'],
                mailSettingsListCount: mailSettingsList && mailSettingsList['@odata.count'],
                isMailSettingsListPending: false,
                mailSettingsListErrorMessage: null,
            }
        }
        case MAIL_SETTINGS_LIST_ERROR: {
            const { errorMessage } = action.payload;
            console.log("errorMessage: ", errorMessage);
            return {
                ...localState,
                mailSettingsListErrorMessage: errorMessage,
                isMailSettingsListPending: false,
                mailSettingsList: []
            }
        }
        case MAIL_SETTINGS_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                mailSettingsListErrorMessage: null,
            }
        }
        case MAIL_SETTINGS_LIST_PENDING: {
            return {
                ...localState,
                isMailSettingsListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};