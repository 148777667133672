import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { EditOrganization, getAppTypeAsyncSelect, updateOrganizationSuccess } from '../../../../store/Modules/Admin/organization/actions';
import VWMessage from '../../../../components/vwMessage/vwMessage';
import { Button, FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';
import Async from 'react-select/async';
import VWButton from '../../../../components/vwButton/VWButton';
import FormValidator from '../../../../helper/formValidator';

const OrganizationEdit = (props) => {
    const [prevAppType, setprevAppType] = useState([{ label: '', value: '' }]);
    const [formFields, setFormFields] = useState({});
    const [validation, setValidation] = useState({ emailValid: true });
    const [errors, setErrors] = useState({});
    const [isActive, setisActive] = useState(true);

    useEffect(() => {
        props.updateOrganizationSuccess();
        setFormFields(props.OrganizationDetails);
        setisActive(props.OrganizationDetails && props.OrganizationDetails.isActive)
        if (props.OrganizationDetails && props.OrganizationDetails.appDetails) {
            const AppTypeList = props.OrganizationDetails.appDetails.map((item) => {
                return {
                    label: item.appName,
                    value: item.appId,
                };
            });
            setprevAppType(AppTypeList);
            const AppID = AppTypeList.map((item) => item.value)
            setFormFields({ ...props.OrganizationDetails, AppId: AppID });
        }
    }, [])
    //---------------------------------- APP Type-------------------------------------------------------------------
    const handleLoadAppType = (inputValue, callback) => {
        const { getAppTypeAsyncSelectAction } = props;
        return getAppTypeAsyncSelectAction(inputValue.length > 0 ? inputValue : '', callback);
    };
    //----------------------------------- Update Input Field-------------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value:null;
        console.log("value:", value);
        
        if (input.name === 'orgName') {
            setErrors({ ...errors, orgName: null });
        }

        let result = FormValidator.validate(input);
        setFormFields({
            ...formFields, [input.name]: value, errors: {
                ...(formFields && formFields.errors),
                [input.name]: result,
            },
        });
    };
    //------------------------------------- Update App Name---------------------------------------------------------
    const handleEditAppName = (value) => {
        const AppID = value.map((item) => item.value)
        setFormFields({ ...formFields, AppId: AppID });
        setprevAppType(value);
        setErrors({ ...errors, AppId: null });
    }
    //---------------------------- Status Update -----------------------------------------------------------------
    const handleActiveStatusChange = () => {
        setisActive(!isActive);
        setFormFields({ ...formFields, isActive: !isActive })
    };
    //--------------------------------------------Update ------------------------------------------------------------
    const handleEditOrganization = () => {
        const newErrors = {};
        if (!(formFields && formFields.orgName) || !(formFields.orgName && formFields.orgName.trim())) {
            newErrors.orgName = 'Organization name is required';
        }
        if (!(formFields && formFields.AppId && formFields.AppId.length > 0)) {
            newErrors.AppId = 'Apps is required';
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0 && validation.emailValid === true) {
            props.EditOrganizationAction(formFields);
        }

    };
console.log("formfields:",formFields);

    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <FormGroup>
                <Label>
                    Organization Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="orgName"
                    placeholder="Enter organization name"
                    onChange={validateOnChange}
                    value={formFields && formFields.orgName}
                    invalid={errors && errors.orgName}
                />
                {<FormFeedback>{errors && errors.orgName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>
                    Organization Contact Person
                </Label>
                <Input
                    type="text"
                    name="orgContactPerson"
                    placeholder="Enter organization contact person"
                    onChange={validateOnChange}
                    value={formFields && formFields.orgContactPerson}
                />
            </FormGroup>
            <FormGroup>
                <Label>
                    Organization Contact #
                </Label>
                <Input
                    type="text"
                    name="orgContactNumber"
                    placeholder="Enter organization contact #"
                    onChange={validateOnChange}
                    value={formFields && formFields.orgContactNumber}
                />
            </FormGroup>
            <FormGroup>
                <Label>
                    Organization Address
                </Label>
                <Input
                    type="textarea"
                    name="orgAddress"
                    placeholder="Enter organization address"
                    onChange={validateOnChange}
                    value={formFields && formFields.orgAddress}
                />
            </FormGroup>
            <FormGroup>
                <Label>
                    Apps <span className="text-danger"> *</span>
                </Label>
                <Async
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: (errors && errors.AppId) ? '#FF3D60' : "#CED4DA"
                        })
                    }}
                    name="AppId"
                    placeholder="Select apps"
                    isSearchable
                    isMulti
                    defaultOptions
                    value={prevAppType}
                    loadOptions={handleLoadAppType}
                    onChange={handleEditAppName}
                />
                <FormText sx={{ marginLeft: "10px" }}>
                    <div className="text-danger">
                        {errors && errors.AppId}
                    </div>
                </FormText>
            </FormGroup>
            <FormGroup>
                <Label>Status</Label>
                <ul className="form-unstyled-list form-status-list">
                    <li
                        onClick={handleActiveStatusChange}
                        className={`form-status-item ${!isActive ? 'active' : ''}`}
                    >
                        Inactive
                    </li>
                    <li
                        onClick={handleActiveStatusChange}
                        className={`form-status-item ${isActive ? 'active' : ''}`}
                    >
                        Active
                    </li>
                </ul>
            </FormGroup>
            <br></br>
            <div className="form-group">
                <FormGroup>
                    <Button
                        className="me-2"
                        color="success"
                        onClick={handleEditOrganization}
                        disabled={props.isPending}
                    >
                        {props.isPending ? <>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            &nbsp; Submitting...
                        </>
                            : <>Submit</>}
                    </Button>
                    <Button
                        color="danger"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                </FormGroup>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.Organization.isUpdateOrganizationPending,
    errorMessage: state.Organization.updateOrganizationErrorMessage,
    OrganizationDetails: state.Organization.OrganizationDetails,
});
const mapDispatchToProps = (dispatch) => ({
    getAppTypeAsyncSelectAction: (searchValue, callback) =>
        dispatch(getAppTypeAsyncSelect(searchValue, callback)),
    EditOrganizationAction: (formFields) => dispatch(EditOrganization(formFields)),
    updateOrganizationSuccess: () => dispatch(updateOrganizationSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationEdit)