//--------------------OPPORTUNITY_CATEGORY-CREATE-----------------------------------
export const OPPORTUNITY_ACTION_TIME_CREATE_OPEN = "OPPORTUNITY_ACTION_TIME_CREATE_OPEN";
export const OPPORTUNITY_ACTION_TIME_CREATE_CLOSE = "OPPORTUNITY_ACTION_TIME_CREATE_CLOSE";
export const OPPORTUNITY_ACTION_TIME_CREATE_PENDING = "OPPORTUNITY_ACTION_TIME_CREATE_PENDING";
export const OPPORTUNITY_ACTION_TIME_CREATE_ERROR = "OPPORTUNITY_ACTION_TIME_CREATE_ERROR";
export const OPPORTUNITY_ACTION_TIME_CREATE_ERROR_CLEAR = "OPPORTUNITY_ACTION_TIME_CREATE_ERROR_CLEAR";
export const OPPORTUNITY_ACTION_TIME_CREATE_SUCCESS = "OPPORTUNITY_ACTION_TIME_CREATE_SUCCESS";

//--------------------OPPORTUNITY_CATEGORY-UPDATE-----------------------------------
export const OPPORTUNITY_ACTION_TIME_UPDATE_OPEN = "OPPORTUNITY_ACTION_TIME_UPDATE_OPEN";
export const OPPORTUNITY_ACTION_TIME_UPDATE_CLOSE = "OPPORTUNITY_ACTION_TIME_UPDATE_CLOSE";
export const OPPORTUNITY_ACTION_TIME_UPDATE_PENDING = "OPPORTUNITY_ACTION_TIME_UPDATE_PENDING";
export const OPPORTUNITY_ACTION_TIME_UPDATE_ERROR = "OPPORTUNITY_ACTION_TIME_UPDATE_ERROR";
export const OPPORTUNITY_ACTION_TIME_UPDATE_SUCCESS = "OPPORTUNITY_ACTION_TIME_UPDATE_SUCCESS";
export const OPPORTUNITY_ACTION_TIME_UPDATE_ERROR_CLEAR = "OPPORTUNITY_ACTION_TIME_UPDATE_ERROR_CLEAR";
export const SET_OPPORTUNITY_ACTION_TIME_DETAILS = "SET_OPPORTUNITY_ACTION_TIME_DETAILS";

//--------------------OPPORTUNITY_CATEGORY-LIST-----------------------------------
export const SET_OPPORTUNITY_ACTION_TIME_LIST = "SET_OPPORTUNITY_ACTION_TIME_LIST";
export const OPPORTUNITY_ACTION_TIME_LIST_PENDING = "OPPORTUNITY_ACTION_TIME_LIST_PENDING";
export const OPPORTUNITY_ACTION_TIME_LIST_ERROR = "OPPORTUNITY_ACTION_TIME_LIST_ERROR";
export const OPPORTUNITY_ACTION_TIME_LIST_ERROR_CLEAR = "OPPORTUNITY_ACTION_TIME_LIST_ERROR_CLEAR";

