import ExcelJs from "exceljs";
import { saveAs } from "file-saver";

export const importExcelSheet = async (importArr, title) => {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet");

    const dataToImport = importArr && importArr.map((col) => {
        return {
            header: col.headerName,
            key: col.headerName,
            width: col.headerWidhth
        }
    })

    worksheet.columns = dataToImport;
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
        // Set font style
        cell.font = {
            name: "TimesNewRoman",
            bold: true,
            size: 14,
            color: { argb: "FFFFFF" },
        };

        // Set alignment
        // cell.alignment = { vertical: "middle", horizontal: "center" };

        // Set background color
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "9AA6B2" },
        };
    });

    console.log("rows: ", worksheet.getRow(1))
    // Create a downloadable file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, `${title}.xlsx`);
}