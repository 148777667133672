import { act } from "react-dom/test-utils";
import type { UserDetailsState } from "../../types/userDetails";
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_CHANGE_PASSWORD_ERROR,
  CLEAR_TRANSFER_DEVICE_ERROR,
  CLEAR_UPDATE_USER_ERROR,
  CLOSE_CHANGE_PASSWORD_MODAL,
  CLOSE_EDIT_USER_MODAL,
  CLOSE_TRANSFER_DEVICE_MODAL,
  GET_USER_DETAILS_PENDING,
  GET_USER_DETAILS_SUCCESS,
  OPEN_CHANGE_PASSWORD_MODAL,
  OPEN_EDIT_USER_MODAL,
  OPEN_TRANSFER_DEVICE_MODAL,
  SEND_RESET_PASSWORD_ERROR,
  SEND_RESET_PASSWORD_PENDING,
  SEND_RESET_PASSWORD_SUCCESS,
  SET_ASSIGNABLE_TRANSFER_USERS_LIST_ASYNC,
  SET_USER_DETAILS,
  SET_USER_DETAILS_INITIAL_STATE,
  TRANSFER_DEVICE_ERROR,
  TRANSFER_DEVICE_PENDING,
  TRANSFER_DEVICE_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_PENDING,
  UPDATE_USER_SUCCESS,
  SET_SUBORDINATES_USERS_LIST_ASYNC,
  TRANSFER_USER_PENDING,
  TRANSFER_USER_SUCCESS,
  TRANSFER_USER_ERROR,
  CLEAR_TRANSFER_USER_ERROR,
  TRASFER_USERS_FORM
} from "../users/actionTypes";
import { SET_LOADING } from "./actionTypes";

const initialState = {
  isGetUserDetailsPending: false,
  isResetPasswordPending: false,
  isChangePasswordModalOpen: false,
  isChangePasswordPending: false,
  isEditUserModalOpen: false,
  errorMessage: "",
  updateUserErrorMessage: "",
  isUpdateUserPending: false,
  isTransferDeviceModalOpen: false,
  user: '',
  transferErrorMessage: null,
  isTransferDevicePending: false,
  isTransferUserPending: false,
  assignableTransferUsersList: [],
  searchValueAsync: "",
  transferForm: {},
  loading: true
};

export default (
  localState = initialState,
  action: Action
) => {
  switch (action.type) {
    case SET_USER_DETAILS_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case SET_USER_DETAILS: {
      const { user } = action.payload;
      // console.log(user)
      return {
        ...localState,
        user: user,
      };
    }
    case TRASFER_USERS_FORM: {
      const { transferForm } = action.payload;
      return {
        ...localState,
        transferForm: transferForm,

      };
    }
    case SET_LOADING: {
      const { loading } = action.payload;
      return {
        ...localState,
        loading: loading

      };
    }
    case GET_USER_DETAILS_PENDING: {
      return {
        ...localState,
        isGetUserDetailsPending: true,
      };
    }
    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...localState,
        isGetUserDetailsPending: false,
      };
    }
    case SEND_RESET_PASSWORD_PENDING: {
      return {
        ...localState,
        isResetPasswordPending: true,
      };
    }
    case SEND_RESET_PASSWORD_SUCCESS: {
      return {
        ...localState,
        isResetPasswordPending: false,
      };
    }
    case SEND_RESET_PASSWORD_ERROR: {
      return {
        ...localState,
        isResetPasswordPending: false,
      };
    }
    case OPEN_CHANGE_PASSWORD_MODAL: {
      return {
        ...localState,
        isChangePasswordModalOpen: true,
      };
    }
    case CLOSE_CHANGE_PASSWORD_MODAL: {
      return {
        ...localState,
        isChangePasswordModalOpen: false,
        errorMessage: null
      };
    }
    case CHANGE_PASSWORD_PENDING: {
      return {
        ...localState,
        isChangePasswordPending: true,
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...localState,
        isChangePasswordPending: false,
      };
    }
    case CHANGE_PASSWORD_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isChangePasswordPending: false,
      };
    }
    case CLEAR_CHANGE_PASSWORD_ERROR: {
      return {
        ...localState,
        errorMessage: null,
        isChangePasswordPending: false,
      };
    }
    case OPEN_EDIT_USER_MODAL: {
      return {
        ...localState,
        isEditUserModalOpen: true,
      };
    }
    case CLOSE_EDIT_USER_MODAL: {
      return {
        ...localState,
        isEditUserModalOpen: false,
        updateUserErrorMessage: null,
      };
    }
    case UPDATE_USER_PENDING: {
      return {
        ...localState,
        isUpdateUserPending: true,
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...localState,
        isUpdateUserPending: false,
      };
    }
    case UPDATE_USER_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateUserErrorMessage: errorMessage,
        isUpdateUserPending: false,
      };
    }
    case CLEAR_UPDATE_USER_ERROR: {
      return {
        ...localState,
        updateUserErrorMessage: null,
        isUpdateUserPending: false,
      };
    }
    case CLOSE_TRANSFER_DEVICE_MODAL: {
      return {
        ...localState,
        isTransferDeviceModalOpen: false,
      };
    }
    case OPEN_TRANSFER_DEVICE_MODAL: {
      return {
        ...localState,
        isTransferDeviceModalOpen: true,
      };
    }
    case TRANSFER_DEVICE_PENDING: {
      return {
        ...localState,
        transferErrorMessage: null,
        isTransferDevicePending: true,
      };
    }
    case TRANSFER_DEVICE_SUCCESS: {
      return {
        ...localState,
        isTransferDevicePending: false,
      };
    }
    case TRANSFER_DEVICE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        transferErrorMessage: errorMessage,
        isTransferDevicePending: false,
      };
    }
    case CLEAR_TRANSFER_DEVICE_ERROR: {
      return {
        ...localState,
        transferErrorMessage: null,
        isTransferDevicePending: false,
      };
    }
    case SET_ASSIGNABLE_TRANSFER_USERS_LIST_ASYNC: {
      const { usersListAsync, searchValueAsync } = action.payload;
      return {
        ...localState,
        assignableTransferUsersList: usersListAsync,
        searchValueAsync
      };
    }
    case SET_SUBORDINATES_USERS_LIST_ASYNC: {
      const { usersListAsync, searchValueAsync } = action.payload;
      return {
        ...localState,
        subordinateUsersList: usersListAsync,
        searchValueAsync
      };
    }
    case TRANSFER_USER_PENDING: {
      return {
        ...localState,
        transferErrorMessage: null,
        isTransferUserPending: true,
      };
    }
    case TRANSFER_USER_SUCCESS: {
      return {
        ...localState,
        isTransferUserPending: false,
      };
    }
    case TRANSFER_USER_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        transferErrorMessage: errorMessage,
        isTransferUserPending: false,
      };
    }
    case CLEAR_TRANSFER_USER_ERROR: {
      return {
        ...localState,
        transferErrorMessage: null,
        isTransferUserPending: false,
      };
    }
    default: {
      return localState;
    }
  }
};
