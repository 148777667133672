import { getToken, modifyOrgListList } from "../../../../helper/common";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { setOrgUserDetails } from "../OrgUser/actions";
import { CLEAR_CREATE_ADMIN_USER_ERROR, CLEAR_UPDATE_ADMIN_USER_ERROR, CLOSE_CREATE_ADMIN_USER_MODAL, CLOSE_UPDATE_ADMIN_USER_MODAL, CREATE_ADMIN_USER_ERROR, CREATE_ADMIN_USER_PENDING, CREATE_ADMIN_USER_SUCCESS, OPEN_CREATE_ADMIN_USER_MODAL, OPEN_UPDATE_ADMIN_USER_MODAL, SET_ADMIN_USER_LIST, UPDATE_ADMIN_USER_ERROR, UPDATE_ADMIN_USER_PENDING, UPDATE_ADMIN_USER_SUCCESS } from "./constants";

export const openCreateAdminUserModalAction = () => ({
    type: OPEN_CREATE_ADMIN_USER_MODAL,
});
export const openUpdateAdminUserModalAction = () => ({
    type: OPEN_UPDATE_ADMIN_USER_MODAL,
});
export const closeCreateAdminUserModalAction = () => ({
    type: CLOSE_CREATE_ADMIN_USER_MODAL,
});
export const closeUpdateAdminUserModalAction = () => ({
    type: CLOSE_UPDATE_ADMIN_USER_MODAL,
});
export const setAdminUserList = (adminUserList) => ({
    type: SET_ADMIN_USER_LIST,
    payload: { adminUserList },
})
export const createAdminUserError = (errorMessage) => ({
    type: CREATE_ADMIN_USER_ERROR,
    payload: { errorMessage },
});
export const createOrgAdminPending = () => ({
    type: CREATE_ADMIN_USER_PENDING,
});
export const clearCreateAdminUserError = () => ({
    type: CLEAR_CREATE_ADMIN_USER_ERROR,
});
export const createAdminUserSuccess = () => ({
    type: CREATE_ADMIN_USER_SUCCESS,
});
export const clearUpdateAdminUserError = () => ({
    type: CLEAR_UPDATE_ADMIN_USER_ERROR,
});
export const updateAdminUserPending = () => ({
    type: UPDATE_ADMIN_USER_PENDING,
});
export const updateAdminUserSuccess = () => ({
    type: UPDATE_ADMIN_USER_SUCCESS,
});
export const updateAdminUserError = (errorMessage) => ({
    type: UPDATE_ADMIN_USER_ERROR,
    payload: { errorMessage },
});

export const getAdminUserListAction = (page, PageSize, searchOrgName, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, searchRoleDetails) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.AdminUser.getAdminUserlist(accessToken, page, PageSize, searchOrgName, searchUserEmail, searchCreatedBy, searchCreatedDateTime, searchIsPortalEnable, searchRoleDetails).then((adminUserList) => {
            dispatch(setAdminUserList(adminUserList));
        })
            .catch((err) => {
                console.log('getAdminUserListAction server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
export const getOrgNameAsyncSelectActions =
    (searchValue, callback) =>
        (dispatch) => {
            getToken(dispatch).then((accessToken) => {
                agent.OrgUser.getOrgNameList(accessToken, searchValue)
                    .then((OrganisationList) => {
                        const { value } = OrganisationList;
                        const updatedOrgList = modifyOrgListList(value);
                        //eslint-disable-next-line
                        callback && callback(updatedOrgList);
                    })
                    .catch((err) => {
                        console.log('getOrgNameAsyncSelectActions server error', err);
                        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                        //eslint-disable-next-line
                        callback && callback();
                    });
            });
        };

export const createAdminUser = (formFields) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createAdminUserError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = {
            ...formFields,
        }
    }
    dispatch(createOrgAdminPending());
    dispatch(clearCreateAdminUserError());
    getToken(dispatch).then((accessToken) => {
        agent.AdminUser.createAdminUser(accessToken, dataToSend)
            .then(() => {
                dispatch(createAdminUserSuccess());
                dispatch(clearForm());
                dispatch(closeCreateAdminUserModalAction());
                dispatch(getAdminUserListAction());
                dispatch(showSnackbarStatus('Admin User created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createAdminUserError(err.response && err.response.text));
                    return;
                }
                console.log('create AdminUser server error', err);
                dispatch(clearForm());
                dispatch(closeCreateAdminUserModalAction());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
export const getOrgUserDetailsAction = (id) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.OrgUser.getOrgUserDetails(accessToken, id).then(
            (OrgUserDetails) => {
                // console.log("OrgUserDetails:", OrgUserDetails)
                dispatch(setOrgUserDetails(OrgUserDetails));
            }
        )
    }).catch((err) => {
        console.log('getOrganizationDetailsAction server error', err);
        dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
    });
}

export const EditAdminUserUpdate = (formFields) => (dispatch) => {
    const dataToSend = {
        ...formFields,
        Id: formFields.id
    }
    dispatch(clearUpdateAdminUserError());
    dispatch(updateAdminUserPending());
    getToken(dispatch).then((accessToken) => {
        agent.OrgUser.editOrgUser(accessToken, dataToSend)
            .then(() => {
                dispatch(updateAdminUserSuccess());
                dispatch(closeUpdateAdminUserModalAction());
                dispatch(getOrgUserDetailsAction(formFields.id));
                dispatch(showSnackbarStatus('Organization details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateAdminUserError(err && err.response && err.response.text));
                    return;
                }
                console.log('Edit Organization server error', err);
                dispatch(updateAdminUserError(null));
                dispatch(closeUpdateAdminUserModalAction());
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};