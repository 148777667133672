import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import Async from 'react-select/async';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const events = [
    { title: 'Meeting', start: new Date() }
]

export function DemoApp() {
    return (
        <div>
            <div className="page-content">
                <Card>
                    <CardBody>
                <Row>
                    <Col md={3}>
                        <div>
                            <Async
                                key="selected_state"
                                id="selected_state"
                                name="selected_state"
                                placeholder="Select Employee Name"
                                //cacheOptions
                                defaultOptions
                                isDisabled
                            />
                        </div>
                    </Col>
                </Row>
                </CardBody>
                </Card>
                <br/>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView='dayGridMonth'
                    // weekends={false}
                    events={events}
                    eventContent={renderEventContent}
                    headerToolbar={{
                        left: 'prev,next',
                        center: 'title',
                        right: ''
                      }}
                />
            </div>
        </div>
    )
}

// a custom render function
function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
        </>
    )
}

export default DemoApp;