import React from "react";
import { Routes, Route } from "react-router-dom";

// redux
import { connect, useSelector } from "react-redux";

//constants
import { layoutTypes } from "../constants/layout";

// layouts
import NonAuthLayout from "../Layout/NonAuthLayout";
import VerticalLayout from "../Layout/VerticalLayout/index";
import HorizontalLayout from "../Layout/HorizontalLayout/index";
import { AuthProtected } from "./AuthProtected";

import { authProtectedRoutes, publicRoutes, CRMSettingsData, PROJECTPORTALSettingsData } from "./routes";
import CRMSettings from "../Pages/Modules/Crm/Settings/layout";
import Error404 from "../Pages/Utility/Error404-Page";
import ProjectPortalSettings from "../Pages/Modules/Projects/Settings/layout";


const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const Index = (props) => {

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));

  const Layout = getLayout(layoutType);

  // console.log("publicRoutes:", publicRoutes);
  // console.log("authProtectedRoutes:", authProtectedRoutes);
  // console.log("isAuth:", props.isAuth);

  let PublicRoutes = publicRoutes;

  if (props.isAuth) {
    PublicRoutes = [...publicRoutes.slice(0, 1), ...publicRoutes.slice(26)];
  }

  // console.log(PublicRoutes);

  // remove all console in production
  if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
  }

  return (
    <Routes>
      <Route>
        {PublicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Route>

      <Route>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <Layout>{route.component}</Layout>
              </AuthProtected>}
            key={idx}
            exact={true}
          />
        ))}
      </Route>
      <Route>
        {CRMSettingsData.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <Layout>
                  <CRMSettings>{route.component}</CRMSettings>
                </Layout>
              </AuthProtected>}
            key={idx}
            exact={true}
          />
        ))}
      </Route>
      <Route>
        {PROJECTPORTALSettingsData.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <Layout>
                  <ProjectPortalSettings>{route.component}</ProjectPortalSettings>
                </Layout>
              </AuthProtected>}
            key={idx}
            exact={true}
          />
        ))}
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
const mapStateToProps = (state) => ({
  profile: state.login.currentProfile,
  isAuth: state.login.isAuth,
})

export default connect(mapStateToProps, null)(Index);
