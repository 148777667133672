import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Card } from "reactstrap";

const DonutChart = ({ datas, filter }) => {
    console.log('datas:', datas);
    console.log('filter:', filter);

    const { totalPendingLeaveCount, totalTakenLeaveCount } = datas || {};
    const isBothZero = totalPendingLeaveCount === 0 && totalTakenLeaveCount === 0;
    const data = {
        labels: ["Pending", "Taken"],
        datasets: [
            {
                data: [totalPendingLeaveCount, totalTakenLeaveCount],
                backgroundColor: ['#f1b44c', '#3d8ef8'],
                borderColor: ['#f1b44c', '#3d8ef8'],
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        cutout: '70%',
        animation: {
            animateScale: true,
            animateRotate: true,
        },
    };

    return (
        <React.Fragment>
            {isBothZero ?
                <Card className='d-flex align-item-center p-4'>
                    <div
                    >{
                            filter ?
                                <>
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ color: 'red' }} /> <span style={{ color: 'red' }}>You didn't take any leave for this particular category.</span>
                                </>
                                :
                                <>
                                    <FontAwesomeIcon icon={faCircleInfo} style={{ color: 'red' }} /> <span style={{ color: 'red' }}>You didn't take any leave across any category.</span>
                                </>
                        }
                    </div>
                </Card>
                :
                <Doughnut width={537} height={268} data={data} options={options} className="chartjs-chart" />
            }
        </React.Fragment>
    )
}

export default DonutChart;



