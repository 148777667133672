import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { clearUpdateAccountCategoryError, updateAccountCategory } from '../../../../../../store/Modules/crm/Settings/AccountCategory/actions';
import VWMessage from '../../../../../../components/vwMessage/vwMessage';

const EditAccountCategory = (props) => {
    const { accountCategoryDetails, isAccountCategoryUpdatePending, errorMessage } = props;
    const { Id, TypeName, Range, IsActive } = accountCategoryDetails || {};

    const [categoryDetails, setCategoryDetails] = useState();
    const [errors, setErrors] = useState();
    const [Active, setActive] = useState();

    useEffect(() => {
        setCategoryDetails({
            ...categoryDetails,
            id: Id,
            typeName: TypeName,
            range: Range,
            isActive: IsActive
        });
        setActive(IsActive)
        props.clearUpdateAccountCategoryError();
    }, [])

    const validateOnchange = (e) => {
        const { name, value } = e.target;
        setCategoryDetails({
            ...categoryDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: null
        })
    }

    const handleActiveStatusChange = () => {
        setCategoryDetails({
            ...categoryDetails,
            isActive: !Active
        })
        setActive(!Active)
        setErrors({
            ...errors,
            isActive: null
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let currentErrors = {};
        const { typeName, range } = categoryDetails || {};

        if (!typeName) {
            currentErrors.typeName = 'Type name is required';
        }
        if (!range) {
            currentErrors.range = 'Range is required';
        }
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length === 0) {
            props.updateAccountCategory(categoryDetails)

        }
    }
    return (
        <>
            {errorMessage && (
                <VWMessage type="danger" defaultMessage={errorMessage} baseClassName="alert" />
            )}
            <Form>
                <FormGroup>
                    <Label> Type Name <span className='text-danger'>*</span></Label>
                    <Input
                        type="text"
                        name="typeName"
                        placeHolder="Enter type name"
                        onChange={validateOnchange}
                        value={categoryDetails && categoryDetails.typeName}
                        invalid={errors && errors.typeName}
                        disabled
                    />
                    <FormFeedback>{errors && errors.typeName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Range <span className='text-danger'>*</span></Label>
                    <Input
                        type="number"
                        name="range"
                        placeHolder="Enter range"
                        onChange={validateOnchange}
                        value={categoryDetails && categoryDetails.range}
                        invalid={errors && errors.range}
                        onKeyDown={
                            (e) => ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault()
                        }
                        onWheel={(e) => e.target.blur()}
                    />
                    <FormFeedback>{errors && errors.range}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="col-form-label">Status</Label>
                    <ul className="form-unstyled-list form-status-list">
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${!Active ? 'active' : ''}`}
                        >
                            Inactive
                        </li>
                        <li
                            onClick={handleActiveStatusChange}
                            className={`form-status-item ${Active ? 'active' : ''}`}
                        >
                            Active
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup>
                    <Button
                        color='success'
                        className='me-2'
                        onClick={handleSubmit}
                        disabled={isAccountCategoryUpdatePending}
                    >
                        {isAccountCategoryUpdatePending ?
                            <div>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden='true' />
                                Submitting...
                            </div>
                            : "Submit"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={props.handleClose}
                    >Cancel
                    </Button>
                </FormGroup>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    accountCategoryDetails: state.crmAccountCategory.accountCategoryDetails,
    isAccountCategoryUpdatePending: state.crmAccountCategory.isAccountCategoryUpdatePending,
    errorMessage: state.crmAccountCategory.accountCategoryUpdateErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    updateAccountCategory: (categoryDetails) => dispatch(updateAccountCategory(categoryDetails)),
    clearUpdateAccountCategoryError: () => dispatch(clearUpdateAccountCategoryError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountCategory);