/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import VWSnackbar from './vwSnackbar';
import { showSnackbarStatus } from '../../store/snackbar/actions';

const mapStateToProps = (state) => ({
    snackbar: state.snackbar,
});
const mapDispatchToProps = (dispatch) => ({
    closeSnackbar: () => dispatch(showSnackbarStatus()),
});

const SnackbarWrapper = (props) => {

    const { snackbar } = props;
    const { isActive, message } = snackbar || {};
    const [snackbarStatus, setsnackbarStatus] = useState();

    useEffect(() => {
        setsnackbarStatus(isActive)
    }, [isActive])

    useEffect(() => {
        if (snackbarStatus) {
            setTimeout(() => {
                setsnackbarStatus(false);
                props.closeSnackbar();
            }, 1000)
        }
    }, [snackbarStatus])

    return (
        <VWSnackbar type="inline" isOpen={snackbarStatus}>
            {/* <p>{props.snackbar.message}</p> */}
            <p>{message}</p>
        </VWSnackbar>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarWrapper);
