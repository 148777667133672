import { getToken, modifyContactList, modifyVisitNameList, modifyVisitTypeList } from "../../../../helper/common"
import trimmedValues from "../../../../helper/trim"
import agent from "../../../services/agent"
import { showSnackbarStatus } from "../../../snackbar/actions"
import { SET_VISIT_DETAILS, SET_VISIT_EXPORT_LIST, SET_VISIT_LIST, VISIT_CREATE_CLOSE, VISIT_CREATE_ERROR, VISIT_CREATE_ERROR_CLEAR, VISIT_CREATE_OPEN, VISIT_CREATE_PENDING, VISIT_CREATE_SUCCESS, VISIT_DETAILS_ERROR, VISIT_DETAILS_ERROR_CLEAR, VISIT_DETAILS_PENDING, VISIT_EXPORT_LIST_ERROR, VISIT_EXPORT_LIST_ERROR_CLEAR, VISIT_EXPORT_LIST_PENDING, VISIT_LIST_ERROR, VISIT_LIST_ERROR_CLEAR, VISIT_LIST_PENDING, VISIT_UPDATE_CLOSE, VISIT_UPDATE_ERROR, VISIT_UPDATE_ERROR_CLEAR, VISIT_UPDATE_OPEN, VISIT_UPDATE_PENDING, VISIT_UPDATE_SUCCESS } from "./constants"

//-------------------EDIT _VISIT--------------------------------------
export const openVisitUpdate = () => ({
    type: VISIT_UPDATE_OPEN
})
export const closeVisitUpdate = () => ({
    type: VISIT_UPDATE_CLOSE
})
export const updateVisitPending = () => ({
    type: VISIT_UPDATE_PENDING
})
export const updateVisitError = (errorMessage) => ({
    type: VISIT_UPDATE_ERROR,
    payload: { errorMessage }
})
export const clearUpdateVisitError = () => ({
    type: VISIT_UPDATE_ERROR_CLEAR,
})
export const updateVisitSuccess = () => ({
    type: VISIT_UPDATE_SUCCESS,
})

export const UpdateVisit = (formFields) => (dispatch) => {
    let dataToSend = {};
    const formattedData = {
        ...formFields,
        startTime: !formFields.isAllDayEvent ? `${formFields.startHour}:${formFields.startMinute} ${formFields.startPrefix}` : null,
        endTime: !formFields.isAllDayEvent ? `${formFields.endHour}:${formFields.endMinute} ${formFields.endPrefix}` : null,
    }
    if (!formattedData) {
        dispatch(updateVisitError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({
            ...formattedData,
        })
    }
    dispatch(updateVisitPending());
    dispatch(clearUpdateVisitError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.updateVisit(accessToken, dataToSend)
            .then(() => {
                dispatch(updateVisitSuccess());
                dispatch(closeVisitUpdate());
                dispatch(getVisitDetails(formFields && formFields.id));
                dispatch(showSnackbarStatus('Visit updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(updateVisitError(err.response && err.response.text));
                    return;
                }
                else {
                    dispatch(updateVisitError('Network Error'));
                }
                console.log('Update Visit server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
//--------------------VISIT-DETAILS-----------------------------------
export const visitDetailsPending = () => ({
    type: VISIT_DETAILS_PENDING
})
export const visitDetailError = (errorMessage) => ({
    type: VISIT_DETAILS_ERROR,
    payload: { errorMessage }
})
export const clearVisitDetailsError = () => ({
    type: VISIT_DETAILS_ERROR_CLEAR,
})
export const setVisitDetails = (visitDetails) => ({
    type: SET_VISIT_DETAILS,
    payload: { visitDetails }
})

export const getVisitDetails = (id) => (dispatch) => {
    dispatch(visitDetailsPending());
    dispatch(clearVisitDetailsError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getVisitDetails(accessToken, id)
            .then((result) => {
                dispatch(setVisitDetails(result))
            })
            .catch((err) => {
                let error;
                if (err.response) {
                    error = err.response.text;
                    dispatch(showSnackbarStatus(error));
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(visitDetailError(error));
                console.log('Visit details server error', error);

            });
    });
};



//--------------------VISIT-CREATE-----------------------------------
export const openVisitCreate = () => ({
    type: VISIT_CREATE_OPEN
})
export const closeVisitCreate = () => ({
    type: VISIT_CREATE_CLOSE
})
export const createVisitPending = () => ({
    type: VISIT_CREATE_PENDING
})
export const createVisitError = (errorMessage) => ({
    type: VISIT_CREATE_ERROR,
    payload: { errorMessage }
})
export const clearCreateVisitError = () => ({
    type: VISIT_CREATE_ERROR_CLEAR,
})
export const createVisitSuccess = () => ({
    type: VISIT_CREATE_SUCCESS,
})


export const CreateVisit = (formFields, filteredData) => (dispatch) => {
    let dataToSend = {}
    const formattedData = {
        ...formFields,
        startTime: !formFields.isAllDayEvent ? `${formFields.startHour}:${formFields.startMinute} ${formFields.startPrefix}` : null,
        endTime: !formFields.isAllDayEvent ? `${formFields.endHour}:${formFields.endMinute} ${formFields.endPrefix}` : null,
    }
    if (!formattedData) {
        dispatch(createVisitError('Please fill required details.'));
    }
    else {
        dataToSend = trimmedValues({
            ...formattedData,
        })
    }
    dispatch(createVisitPending());
    dispatch(clearCreateVisitError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.createVisit(accessToken, dataToSend)
            .then(() => {
                dispatch(createVisitSuccess());
                dispatch(closeVisitCreate());
                dispatch(getVisitList(filteredData));
                // dispatch(getVisitExportList());
                dispatch(showSnackbarStatus('Visit created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    dispatch(createVisitError(err.response && err.response.text));
                    return;
                }
                else {
                    dispatch(createVisitError('Network Error'));
                }
                console.log('Create Visit server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};


export const getVisitPrimaryContactAsyncSelect = (visitNameId, searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getVisitPrimaryContactAsynclist(accessToken, searchValue, visitNameId)
            .then((contactLists) => {
                const { value } = contactLists;
                const updatedContactList = modifyContactList(value);
                console.log("value:", value);

                //eslint-disable-next-line
                callback && callback(updatedContactList);
            })
            .catch((err) => {
                console.log('getVisitPrimaryContactAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getVisitTypeListAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getVisitTypeListAsynclist(accessToken, searchValue)
            .then((VisitTypeLists) => {
                const { value } = VisitTypeLists;
                const updatedVisitTypeList = modifyVisitTypeList(value);
                console.log("value:", value);

                //eslint-disable-next-line
                callback && callback(updatedVisitTypeList);
            })
            .catch((err) => {
                console.log('getVisitTypeAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getVisitTypeAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getVisitTypeAsynclist(accessToken, searchValue)
            .then((VisitTypeLists) => {
                const { value } = VisitTypeLists;
                const updatedVisitTypeList = modifyVisitTypeList(value);
                console.log("value:", value);

                //eslint-disable-next-line
                callback && callback(updatedVisitTypeList);
            })
            .catch((err) => {
                console.log('getVisitTypeAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
export const getVisitNameAsyncSelect = (searchValue, callback) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getVisitNameAsynclist(accessToken, searchValue)
            .then((VisitNameLists) => {
                const { value } = VisitNameLists;
                const updatedVisitNameList = modifyVisitNameList(value);
                console.log("value:", value);

                //eslint-disable-next-line
                callback && callback(updatedVisitNameList);
            })
            .catch((err) => {
                console.log('getVisitNameAsyncSelect server error', err);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));//eslint-disable-next-line
                callback && callback();
            });
    });
};
//--------------------VISIT-LIST-----------------------------------
export const visitListPending = () => ({
    type: VISIT_LIST_PENDING
})
export const visitExportListPending = () => ({
    type: VISIT_EXPORT_LIST_PENDING
})
export const visitListError = (errorMessage) => ({
    type: VISIT_LIST_ERROR,
    payload: { errorMessage }
})
export const visitExportListError = (errorMessage) => ({
    type: VISIT_EXPORT_LIST_ERROR,
    payload: { errorMessage }
})
export const clearVisitListError = () => ({
    type: VISIT_LIST_ERROR_CLEAR,
})
export const clearVisitExportListError = () => ({
    type: VISIT_EXPORT_LIST_ERROR_CLEAR,
})
export const setVisitList = (visitList) => ({
    type: SET_VISIT_LIST,
    payload: { visitList }
})
export const setVisitExportList = (visitExportList) => ({
    type: SET_VISIT_EXPORT_LIST,
    payload: { visitExportList }
})


export const getVisitExportListAction = (filter,searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName, searchStartDate, searchEndDate, searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp, searchTransportMode, searchFromDate, searchToDate, searchVisitStatus, searchVisitAddress) => (dispatch) => {
    dispatch(visitExportListPending());
    dispatch(clearVisitExportListError());
    return getToken(dispatch).then((accessToken) => {
        return agent.Crm.getVisitExportList(accessToken, filter,searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName, searchStartDate, searchEndDate, searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp, searchTransportMode, searchFromDate, searchToDate, searchVisitStatus, searchVisitAddress)
            .then((result) => {
                dispatch(setVisitExportList(result))
                return Promise.resolve(result);
            })
            .catch((err) => {
                let error;
                if (err.response) {
                    error = err.response && err.response.body && err.response.body.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(visitExportListError(error));
                console.log('Visit Export server error', error);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};
export const getVisitList = (filteredData, page, PageSize, searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName, searchStartDate, searchEndDate, searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp, searchTransportMode, searchFromDate, searchToDate, searchVisitStatus, searchVisitAddress) => (dispatch) => {
    dispatch(visitListPending());
    dispatch(clearVisitListError());
    getToken(dispatch).then((accessToken) => {
        agent.Crm.getVisitList(accessToken, filteredData, page, PageSize, searchvisitSourceName, searchOwnerUserName, searchPrimaryContactEmail, searchSubject, searchVisitTypeName, searchStartDate, searchEndDate, searchStartLocation, searchEndLocation, searchDistance, searchCreatedBy, searchCreatedTimeStamp, searchTransportMode, searchFromDate, searchToDate, searchVisitStatus, searchVisitAddress)
            .then((result) => {
                console.log("result:", result);

                dispatch(setVisitList(result))
            })
            .catch((err) => {
                let error;
                if (err.response) {
                    error = err.response && err.response.body && err.response.body.error && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                }
                dispatch(visitListError(error));
                console.log('Create Visit server error', error);
                dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
            });
    });
};