import {
    FOLLOW_UP_ACTION_CREATE_CLOSE,
    FOLLOW_UP_ACTION_CREATE_ERROR,
    FOLLOW_UP_ACTION_CREATE_ERROR_CLEAR,
    FOLLOW_UP_ACTION_CREATE_OPEN,
    FOLLOW_UP_ACTION_CREATE_PENDING,
    FOLLOW_UP_ACTION_CREATE_SUCCESS,
    FOLLOW_UP_ACTION_LIST_ERROR,
    FOLLOW_UP_ACTION_LIST_ERROR_CLEAR,
    FOLLOW_UP_ACTION_LIST_PENDING,
    FOLLOW_UP_ACTION_UPDATE_CLOSE,
    FOLLOW_UP_ACTION_UPDATE_ERROR,
    FOLLOW_UP_ACTION_UPDATE_ERROR_CLEAR,
    FOLLOW_UP_ACTION_UPDATE_OPEN,
    FOLLOW_UP_ACTION_UPDATE_PENDING,
    FOLLOW_UP_ACTION_UPDATE_SUCCESS,
    SET_FOLLOW_UP_ACTION_DETAILS,
    SET_FOLLOW_UP_ACTION_LIST,
} from "./constants";

const initialState = {
    //--------------------ACCOUNT-CATEGORY-CREATE-----------------------------------
    isCreateFollowUpActionOpen: false,
    followUpActionCreateErrorMessage: null,
    isFollowUpActionCreateSuccess: false,
    isFollowUpActionCreatePending: false,
    //--------------------ACCOUNT-CATEGORY-UPDATE-----------------------------------
    followUpActionDetails: null,
    isUpdateFollowUpActionOpen: false,
    followUpActionUpdateErrorMessage: null,
    isFollowUpActionUpdateSuccess: false,
    isFollowUpActionUpdatePending: false,
    //--------------------ACCOUNT-CATEGORY-LIST-------------------------------------
    followUpActionList: [],
    followUpActionListCount: 0,
    isFollowUpActionListPending: false,
    followUpActionListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------ACCOUNT-CATEGORY-CREATE-----------------------------------
        case FOLLOW_UP_ACTION_CREATE_OPEN: {
            return {
                ...localState,
                isCreateFollowUpActionOpen: true
            }
        }
        case FOLLOW_UP_ACTION_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateFollowUpActionOpen: false
            }
        }
        case FOLLOW_UP_ACTION_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                followUpActionCreateErrorMessage: null,
            }
        }
        case FOLLOW_UP_ACTION_CREATE_PENDING: {
            return {
                ...localState,
                isFollowUpActionCreatePending: true,
            }
        }
        case FOLLOW_UP_ACTION_CREATE_SUCCESS: {
            return {
                ...localState,
                isFollowUpActionCreateSuccess: true,
                isFollowUpActionCreatePending: false
            }
        }
        case FOLLOW_UP_ACTION_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                followUpActionCreateErrorMessage: errorMessage,
                isFollowUpActionCreatePending: false,
            }
        }

        //--------------------ACCOUNT-CATEGORY-UPDATE-----------------------------------
        case SET_FOLLOW_UP_ACTION_DETAILS: {
            const { followUpActionDetails } = action.payload;
            return {
                ...localState,
                followUpActionDetails: followUpActionDetails
            }
        }
        case FOLLOW_UP_ACTION_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateFollowUpActionOpen: true
            }
        }
        case FOLLOW_UP_ACTION_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateFollowUpActionOpen: false
            }
        }
        case FOLLOW_UP_ACTION_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                followUpActionUpdateErrorMessage: null,
            }
        }
        case FOLLOW_UP_ACTION_UPDATE_PENDING: {
            return {
                ...localState,
                isFollowUpActionUpdatePending: true,
            }
        }
        case FOLLOW_UP_ACTION_UPDATE_SUCCESS: {
            return {
                ...localState,
                isFollowUpActionUpdateSuccess: true,
                isFollowUpActionUpdatePending: false
            }
        }
        case FOLLOW_UP_ACTION_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                followUpActionUpdateErrorMessage: errorMessage,
                isFollowUpActionUpdatePending: false
            }
        }
        //--------------------ACCOUNT-CATEGORY-CATEGORY-LIST-------------------------------------
        case SET_FOLLOW_UP_ACTION_LIST: {
            const { followUpActionList } = action.payload;
            return {
                ...localState,
                followUpActionList: followUpActionList && followUpActionList['value'],
                followUpActionListCount: followUpActionList && followUpActionList['@odata.count'],
                isFollowUpActionListPending: false,
                followUpActionListErrorMessage: null,
            }
        }
        case FOLLOW_UP_ACTION_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                followUpActionListErrorMessage: errorMessage,
                isFollowUpActionListPending: false,
                followUpActionList: []
            }
        }
        case FOLLOW_UP_ACTION_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                followUpActionListErrorMessage: null,
            }
        }
        case FOLLOW_UP_ACTION_LIST_PENDING: {
            return {
                ...localState,
                isFollowUpActionListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};