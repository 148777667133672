import {
    getToken,
} from "../../../../helper/common";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import {
    CLEAR_REPORT_LIST_ERROR,
    CLEAR_REPORT_USER_LIST_ERROR,
    REPORT_EXPORT_ERROR,
    REPORT_EXPORT_SUCCESS,
    REPORT_LIST_ERROR,
    REPORT_USER_LIST_ERROR,
    SET_REPORT_EXPORT_PENDING,
    SET_REPORT_LIST,
    SET_REPORT_LIST_PENDING,
    SET_REPORT_USER_LIST,
    SET_REPORT_USER_LIST_PENDING
} from "./constants";

//-----------------------------------------------------------LIST-----------------------------------------------------------------------------------
export const setReportListPending = () => ({
    type: SET_REPORT_LIST_PENDING
});
export const setReportUserListPending = () => ({
    type: SET_REPORT_USER_LIST_PENDING
});

export const setReportList = (reportList) => ({
    type: SET_REPORT_LIST,
    payload: { reportList }
});
export const setReportUserList = (reportUserList) => ({
    type: SET_REPORT_USER_LIST,
    payload: { reportUserList }
});

export const reportListError = (errorMessage) => ({
    type: REPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const reportUserListError = (errorMessage) => ({
    type: REPORT_USER_LIST_ERROR,
    payload: { errorMessage },
})

export const clearReportListError = () => ({
    type: CLEAR_REPORT_LIST_ERROR
});
export const clearReportUserListError = () => ({
    type: CLEAR_REPORT_USER_LIST_ERROR
});

export const setReportExportPending = () => ({
    type: SET_REPORT_EXPORT_PENDING
});

export const setReportExportSuccess = () => ({
    type: REPORT_EXPORT_SUCCESS,
});

export const reportExportListError = (errorMessage) => ({
    type: REPORT_EXPORT_ERROR,
    payload: { errorMessage },
});

export const getReportListExport = (filter) => (dispatch) => {
    dispatch(setReportExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.ProjectTracker.getReportListExport(accessToken, filter)
            .then((taskList) => {
                dispatch(setReportExportSuccess());
                // dispatch(clearTaskExportListError());
                return Promise.resolve(taskList);
            })
            .catch((err) => {
                console.log('getTaskListExportAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(reportExportListError(error))
            });
    });
}
export const getReportUserListExport = (filter) => (dispatch) => {
    dispatch(setReportExportPending());
    return getToken(dispatch).then((accessToken) => {
        return agent.ProjectTracker.getReportUserListExport(accessToken, filter)
            .then((userList) => {
                dispatch(setReportExportSuccess());
                // dispatch(clearTaskExportListError());
                return Promise.resolve(userList);
            })
            .catch((err) => {
                console.log('getReportUserListExport server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(reportExportListError(error))
            });
    });
}
export const getReportList = (page, PageSize,  filter) => (dispatch) => {
    dispatch(setReportListPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getReportList(accessToken, page, PageSize, filter)
            .then((reportList) => {
                dispatch(setReportList(reportList));
                dispatch(clearReportListError());
            })
            .catch((err) => {
                console.log('getTaskList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(reportListError(error))
            });
    });
}
export const getProjectUserReportList = (page, PageSize,filter) => (dispatch) => {
    dispatch(setReportUserListPending());
    getToken(dispatch).then((accessToken) => {
        agent.ProjectTracker.getProjectUserReportList(accessToken, page, PageSize, filter)
            .then((reportList) => {
                dispatch(setReportUserList(reportList));
                dispatch(clearReportUserListError());
            })
            .catch((err) => {
                console.log('getProjectUserReportList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                    console.log(error)
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(reportUserListError(error))
            });
    });
}
