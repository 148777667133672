import React from "react";
import ReactApexChart from "react-apexcharts";
import { Target } from "react-feather";


const PieChart = ({ labels, value }) => {

    console.log("dataValue:", value);
    console.log("dataLable:", labels);
    const colors = [
        { labels: "New", value: "#64B5F6" },
        { labels: "InProgress", value: "#FFB300" },
        { labels: "OnHold", value: "#FF5252" },
        { labels: "Internal Testing", value: "#FF4081" },
        { labels: "Internal Testing Done", value: "#7C4DFF" },
        { labels: "Waiting For Approval", value: "#FF7043" },
        { labels: "Done", value: "#00796B" },
    ]

    const filterColor = colors.filter((color) =>
        color.labels.includes(color.labels)
    )
    console.log("filterColor:", filterColor)
    // console.log("filterValue:", filterColor.map((items) => items && items.value))

    const PieChartData = {
        series: value,
        options: {
            // labels: labels,
            labels: filterColor && filterColor.map((items) => items.labels),
            // colors: ["rgb(17, 196, 110)", "#f1b44c", "rgb(61, 142, 248)", "#16FF00", "#45FFCA", "#FA7070", "#EA1179"],
            colors: filterColor && filterColor.map((items) => items.value),
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
                offsetY: -10,
            },
            responsive: [
                {
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 240,
                        },
                        legend: {
                            show: false,
                        },
                    },
                },
            ],
        },
    }

    console.log("PieChartData:", PieChartData)

    return (

        <React.Fragment>
            {value ?
                <ReactApexChart
                    options={PieChartData && PieChartData.options}
                    series={value}
                    // series={filterColor.map((items) => items.labels)}
                    type="pie"
                    width={260}

                    // height="250"
                    className="apex-charts"
                /> : ""}
        </React.Fragment>
    )
}

export default PieChart;
