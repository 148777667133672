import React, { useEffect, useState } from "react";
import Unsubscribe from "./unSubscribePage";
import InvalidUser from "./InvalidUser";
import AlreadyUnsubscribed from "./alreadyUnsubscribed";
import { connect } from "react-redux";
import { getEmail } from "../../../../store/Modules/MassMailer/Unsubscribe/actions";
import bgPattern from '../../../../assets/images/Mass-Mailer/mail-pattern.png'

const UnsubscribeHome = (props) => {
    const { mail, mailErrorMessage } = props;
    const { email, id, subscriptionStatus } = mail || {};

    const path = window.location.search;
    const searchParams = new URLSearchParams(path);
    const statusParams = searchParams.get('v');

    useEffect(() => {
        if (statusParams)
            props.getEmail(statusParams);
    }, [])

    console.log("mailErrorMessage: ", mailErrorMessage);
    console.log("statusParams: ", statusParams);

    return (
        <div className=" d-flex justify-content-center align-items-center"
            style={{
                backgroundImage: `url(${bgPattern})`,
                backgroundRepeat: "repeat",
                backgroundSize: "contain",
                height: "100vh",
            }}>
            {mailErrorMessage || !statusParams ?
                <InvalidUser />
                : subscriptionStatus === 'OPTIN' ?
                    <Unsubscribe />
                    : <AlreadyUnsubscribed email={email} />
            }
            <div className="position-absolute bottom-0 start-0 vw-100 py-3 text-center fs-5 fw-bold bg-white">
                {new Date().getFullYear()} &copy; {window.__ENV && window.__ENV.credits}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    mail: state.unsubscribe.mail,
    mailErrorMessage: state.unsubscribe.mailErrorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    getEmail: (statusParams) => dispatch(getEmail(statusParams)),
})
export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeHome);