import React, { useEffect, useState } from 'react'
import VWMessage from '../../../../components/vwMessage/vwMessage'
import { Button, Col, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { clearUpdateHolidayMasterError, updateHoliday, updateHolidayMasterSuccess } from '../../../../store/Modules/LeaveTracker/HolidayMaster/actions'
import moment from 'moment-timezone'
import VWButton from '../../../../components/vwButton/VWButton'

const HolidayEdit = (props) => {

    const [formFields, setFormFields] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        props.updateHolidayMasterSuccess();
        props.clearUpdateHolidayMasterError();
        if (props.holidayMasterDetails) {
            setFormFields(props.holidayMasterDetails);
        }
    }, [])


    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value && input.value.length > 0 ? input.value : null;
        setFormFields({ ...formFields, [input.name]: value });
        if (input.name === 'holidayName') {
            setErrors({ ...errors, holidayName: '' });
        }
        if (input.name === 'holidayDate') {
            setErrors({ ...errors, holidayDate: '' });
        }
    };
    //--------------------------------------Update Leave Form --------------------------------------------------
    const handleHolidayEdit = () => {
        const newErrors = {};
        if (!formFields.holidayName || !formFields.holidayName.trim()) {
            newErrors.holidayName = 'Holiday Name is required';
        }
        if (!formFields.holidayDate || !formFields.holidayDate.trim()) {
            newErrors.holidayDate = ' Holiday Date is required';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            props.EditHolidayAction(formFields);
        }
    };
    return (
        <React.Fragment>
            {props.errorMessage && (
                <VWMessage type="danger" defaultMessage={props.errorMessage} baseClassName="alert"></VWMessage>
            )}
            <>
                <Row>
                    <Col >
                        <div className="form-group">
                            <label className="col-form-label">Holiday Name <span className="text-danger">*</span></label>
                            <Input
                                type="text"
                                name="holidayName"
                                placeholder="Enter Holiday Name"
                                onChange={validateOnChange}
                                value={formFields.holidayName}
                                invalid={errors && errors.holidayName}
                            />
                            {<FormFeedback>{errors && errors.holidayName}</FormFeedback>}
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">Holiday Date <span className="text-danger">*</span></label>
                            <Input
                                type="date"
                                name="holidayDate"
                                placeholder="Select Holiday Date"
                                onChange={validateOnChange}
                                value={moment(formFields.holidayDate).format("YYYY-MM-DD")}
                                invalid={errors && errors.holidayDate}
                            />
                            {<FormFeedback>{errors && errors.holidayDate}</FormFeedback>}
                        </div>
                    </Col>
                </Row>
                <br></br>
                <div className="form-group">
                    <FormGroup>
                        <Button
                            className="me-2"
                            color="success"
                            onClick={handleHolidayEdit}
                            disabled={props.isPending}
                        >
                            {props.isPending ? <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                &nbsp; Submitting...
                            </>
                                : <>Submit</>}
                        </Button>
                        <Button
                            color="danger"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </FormGroup>
                </div>
            </>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => ({
    isPending: state.holidayMaster.isUpdateHolidayMasterPending,
    errorMessage: state.holidayMaster.updateHolidayMasterErrorMessage,
    holidayMasterDetails: state.holidayMaster.holidayMasterDetails,
});

const mapDispatchToProps = (dispatch) => ({
    EditHolidayAction: (formfield) => dispatch(updateHoliday(formfield)),
    clearUpdateHolidayMasterError: () => dispatch(clearUpdateHolidayMasterError()),
    updateHolidayMasterSuccess: () => dispatch(updateHolidayMasterSuccess()),

});

export default connect(mapStateToProps, mapDispatchToProps)(HolidayEdit)