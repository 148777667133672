import React, { useEffect, useState } from 'react'
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { useDropzone } from 'react-dropzone';
import './profile.css'
import { connect } from 'react-redux';
import { editProfileDetails, getCurrentProfile } from '../../../store/actions';

const DisplayPicture = (props) => {
    const { currentProfile } = props;
    const [dpImage, setDpImage] = useState(null);
    const [errors, setErrors] = useState(null);
    const [formFields, setFormFields] = useState({});

    useEffect(() => {
        setFormFields({ ...formFields, firstName: currentProfile.firstName, lastName: currentProfile.lastName, displayName: currentProfile.displayName, profilePicture: currentProfile && currentProfile.profilePicture ? currentProfile && currentProfile.profilePicture : null });
        setDpImage(currentProfile && currentProfile.profilePicture);
    }, [])
    const validateMaxDimensions = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                resolve();
            };
            img.onerror = () => {
                reject('Invalid image file.');
            };
        });
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            validateMaxDimensions(file)
                .then(() => convertToBase64(file))
                .then((base64) => {
                    setErrors(null);
                    setDpImage(base64); // Store image as Base64
                    setFormFields((prevFields) => ({
                        ...prevFields,
                        profilePicture: base64, // Update formFields with profilePicture
                    }));
                })
                .catch((err) => setErrors(err));
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxFiles: 1,
    });
    //---------------------------------------------------------------------------------------------------------------------------------------------
    const validateOnChange = (event) => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;
        setFormFields({ ...formFields, [input.name]: value });

        if (input.name === 'firstName') {
            setErrors({ ...errors, firstName: '' });
        }
        if (input.name === 'lastName') {
            setErrors({ ...errors, lastName: '' });
        }
    };

    const handleUpdateProfile = (e) => {
        e.preventDefault();
        props.editProfileDetails(formFields);
    };
    const handleRemoveImage = () => {
        setDpImage(null);
        setFormFields({
            ...formFields,
            profilePicture: null // Clear profilePicture
        });
    };
    console.log("dpImage:", dpImage);
    console.log("formfields:", formFields);

    return (
        <React.Fragment>
            <FormGroup>
                <div className='d-flex justify'>
                    <Label>
                        <div>Upload Image</div>
                    </Label>
                    {formFields.profilePicture ?
                        <div
                            className='remove-image-label'
                            onClick={handleRemoveImage}
                            style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: 'auto', color: 'red' }}
                        >
                            Remove Image
                        </div>
                        : ''}
                </div>
                <div className='d-flex justify-content-center'>
                    <div {...getRootProps()} className="dp-container">
                        <input {...getInputProps()} />
                        <div className="dp-wrapper">
                            {dpImage ? (
                                <img src={dpImage} alt="Display" className="dp-image" width="100%" />
                            ) : (
                                <div className="dp-placeholder">Click to upload</div>
                            )}
                        </div>
                    </div>
                </div>
                {errors?.profilePicture && <FormFeedback className="d-block text-danger">{errors && errors.profilePicture}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>
                    First Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    onChange={validateOnChange}
                    value={formFields && formFields.firstName}
                    invalid={errors && errors.firstName}
                />
                {<FormFeedback>{errors && errors.firstName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>
                    Last Name <span className="text-danger"> *</span>
                </Label>
                <Input
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                    onChange={validateOnChange}
                    invalid={errors && errors.lastName}
                    value={formFields && formFields.lastName}
                />
                {<FormFeedback>{errors && errors.lastName}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <Label>
                    Display Name
                </Label>
                <Input
                    type="text"
                    name="displayName"
                    placeholder="Enter display name"
                    onChange={validateOnChange}
                    invalid={errors && errors.displayName}
                    value={formFields && formFields.displayName}
                />
            </FormGroup>
            <FormGroup>
                <Button
                    className="me-2"
                    color="success"
                    onClick={handleUpdateProfile}
                    disabled={props.isPending}
                >
                    {props.isPending ? <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        &nbsp; Submitting...
                    </>
                        : <>Submit</>}
                </Button>
                <Button
                    color="danger"
                    onClick={props.handleClose}
                >
                    Cancel
                </Button>
            </FormGroup>
        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    currentProfile: state.login.currentProfile,
    updateProfileDetailsErrorMessage: state.login.updateProfileDetailsErrorMessage,
    isPending: state.login.isUpdateProfileDetailsPending,
})
const mapDispatchToProps = (dispatch) => ({
    editProfileDetails: (formfields) => dispatch(editProfileDetails(formfields)),
})
export default connect(mapStateToProps, mapDispatchToProps)(DisplayPicture)