const settingsSidebarData = [

    {
        label: "Leads",
        icon: "mdi mdi mdi-briefcase-outline",
        isHasArrow: true,
        url: "/crm/settings/leads/lead-category",
        issubMenubadge: true,
        subItem: [
            {
                label: "Lead Category",
                parent: "Leads",
                icon: "mdi mdi-briefcase-account-outline",
                isHasArrow: true,
                url: "/crm/settings/leads/lead-category",
            },
            {
                label: "Follow Up  Action",
                parent: "Leads",
                icon: "mdi mdi-phone-log-outline",
                isHasArrow: true,
                url: "/crm/settings/leads/follow-up-action",
            },
            {
                label: "Lead Source",
                parent: "Leads",
                icon: "mdi mdi-human-greeting-proximity",
                isHasArrow: true,
                url: "/crm/settings/leads/lead-source",
            },
            {
                label: "Mandatory Fields",
                parent: "Leads",
                icon: "mdi mdi-star-circle-outline",
                isHasArrow: true,
                url: "/crm/settings/leads/mandatory-fields",
            },

        ]
    },
    {
        label: "Accounts",
        icon: "mdi mdi mdi-smart-card-outline",
        isHasArrow: true,
        url: "/crm/settings/accounts/account-category",
        issubMenubadge: true,
        subItem: [
            {
                label: "Account Category",
                parent: "Accounts",
                icon: "mdi mdi-account-details-outline",
                isHasArrow: true,
                url: "/crm/settings/accounts/account-category",
            },
            {
                label: "Region",
                parent: "Accounts",
                icon: "mdi mdi-map-marker-outline",
                isHasArrow: true,
                url: "/crm/settings/accounts/region",
            },
            {
                label: "Industry Type",
                parent: "Accounts",
                icon: "mdi mdi-domain",
                isHasArrow: true,
                url: "/crm/settings/accounts/industry-type",
            },

        ]
    },
    {
        label: "Opportunity",
        icon: "mdi mdi-tag-outline",
        isHasArrow: true,
        url: "/crm/settings/opportunity/opportunity-category",
        issubMenubadge: true,
        subItem: [
            {
                label: "Opportunity Category",
                parent: "Opportunity",
                icon: "mdi mdi-format-list-bulleted-type",
                isHasArrow: true,
                url: "/crm/settings/opportunity/opportunity-category",
            },
            {
                label: "Opportunity Type",
                parent: "Opportunity",
                icon: "mdi mdi-shape-outline",
                isHasArrow: true,
                url: "/crm/settings/opportunity/opportunity-type",
            },
            {
                label: "Opportunity Action",
                parent: "Opportunity",
                icon: "mdi mdi-cellphone-message",
                isHasArrow: true,
                url: "/crm/settings/opportunity/opportunity-action",
            },
            {
                label: "Opportunity Current Phase",
                parent: "Opportunity",
                icon: "mdi mdi-transit-connection-variant",
                isHasArrow: true,
                url: "/crm/settings/opportunity/opportunity-current-phase",
            },

        ]
    },
    {
        label: "Visits",
        icon: "mdi mdi-eye-outline",
        isHasArrow: true,
        url: "/crm/settings/visits/visit-type",
        issubMenubadge: true,
        subItem: [
            {
                label: "Visit Type",
                parent: "Visit",
                icon: "mdi mdi-eye-circle",
                isHasArrow: true,
                url: "/crm/settings/visits/visit-type",
            },
        ]
    }

]
export { settingsSidebarData };