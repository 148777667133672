import {
    ACCOUNT_CATEGORY_CREATE_CLOSE,
    ACCOUNT_CATEGORY_CREATE_ERROR,
    ACCOUNT_CATEGORY_CREATE_ERROR_CLEAR,
    ACCOUNT_CATEGORY_CREATE_OPEN,
    ACCOUNT_CATEGORY_CREATE_PENDING,
    ACCOUNT_CATEGORY_CREATE_SUCCESS,
    ACCOUNT_CATEGORY_LIST_ERROR,
    ACCOUNT_CATEGORY_LIST_ERROR_CLEAR,
    ACCOUNT_CATEGORY_LIST_PENDING,
    ACCOUNT_CATEGORY_UPDATE_CLOSE,
    ACCOUNT_CATEGORY_UPDATE_ERROR,
    ACCOUNT_CATEGORY_UPDATE_ERROR_CLEAR,
    ACCOUNT_CATEGORY_UPDATE_OPEN,
    ACCOUNT_CATEGORY_UPDATE_PENDING,
    ACCOUNT_CATEGORY_UPDATE_SUCCESS,
    SET_ACCOUNT_CATEGORY_DETAILS,
    SET_ACCOUNT_CATEGORY_LIST,
} from "./constants";

const initialState = {
    //--------------------ACCOUNT-CATEGORY-CREATE-----------------------------------
    isCreateAccountCategoryOpen: false,
    accountCategoryCreateErrorMessage: null,
    isAccountCategoryCreateSuccess: false,
    isAccountCategoryCreatePending: false,
    //--------------------ACCOUNT-CATEGORY-UPDATE-----------------------------------
    accountCategoryDetails: null,
    isUpdateAccountCategoryOpen: false,
    accountCategoryUpdateErrorMessage: null,
    isAccountCategoryUpdateSuccess: false,
    isAccountCategoryUpdatePending: false,
    //--------------------ACCOUNT-CATEGORY-LIST-------------------------------------
    accountCategoryList: [],
    accountCategoryListCount: 0,
    isAccountCategoryListPending: false,
    accountCategoryListErrorMessage: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        //--------------------ACCOUNT-CATEGORY-CREATE-----------------------------------
        case ACCOUNT_CATEGORY_CREATE_OPEN: {
            return {
                ...localState,
                isCreateAccountCategoryOpen: true
            }
        }
        case ACCOUNT_CATEGORY_CREATE_CLOSE: {
            return {
                ...localState,
                isCreateAccountCategoryOpen: false
            }
        }
        case ACCOUNT_CATEGORY_CREATE_ERROR_CLEAR: {
            return {
                ...localState,
                accountCategoryCreateErrorMessage: null,
            }
        }
        case ACCOUNT_CATEGORY_CREATE_PENDING: {
            return {
                ...localState,
                isAccountCategoryCreatePending: true,
            }
        }
        case ACCOUNT_CATEGORY_CREATE_SUCCESS: {
            return {
                ...localState,
                isAccountCategoryCreateSuccess: true,
                isAccountCategoryCreatePending: false
            }
        }
        case ACCOUNT_CATEGORY_CREATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                accountCategoryCreateErrorMessage: errorMessage,
                isAccountCategoryCreatePending: false,
            }
        }

        //--------------------ACCOUNT-CATEGORY-UPDATE-----------------------------------
        case SET_ACCOUNT_CATEGORY_DETAILS: {
            const { categoryDetails } = action.payload;
            return {
                ...localState,
                accountCategoryDetails: categoryDetails
            }
        }
        case ACCOUNT_CATEGORY_UPDATE_OPEN: {
            return {
                ...localState,
                isUpdateAccountCategoryOpen: true
            }
        }
        case ACCOUNT_CATEGORY_UPDATE_CLOSE: {
            return {
                ...localState,
                isUpdateAccountCategoryOpen: false
            }
        }
        case ACCOUNT_CATEGORY_UPDATE_ERROR_CLEAR: {
            return {
                ...localState,
                accountCategoryUpdateErrorMessage: null,
            }
        }
        case ACCOUNT_CATEGORY_UPDATE_PENDING: {
            return {
                ...localState,
                isAccountCategoryUpdatePending: true,
            }
        }
        case ACCOUNT_CATEGORY_UPDATE_SUCCESS: {
            return {
                ...localState,
                isAccountCategoryUpdateSuccess: true,
                isAccountCategoryUpdatePending: false
            }
        }
        case ACCOUNT_CATEGORY_UPDATE_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                accountCategoryUpdateErrorMessage: errorMessage,
                isAccountCategoryUpdatePending: false
            }
        }
        //--------------------ACCOUNT-CATEGORY-CATEGORY-LIST-------------------------------------
        case SET_ACCOUNT_CATEGORY_LIST: {
            const { accountCategoryList } = action.payload;
            return {
                ...localState,
                accountCategoryList: accountCategoryList && accountCategoryList['value'],
                accountCategoryListCount: accountCategoryList && accountCategoryList['@odata.count'],
                isAccountCategoryListPending: false,
                accountCategoryListErrorMessage: null,
            }
        }
        case ACCOUNT_CATEGORY_LIST_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...localState,
                accountCategoryListErrorMessage: errorMessage,
                isAccountCategoryListPending: false,
                accountCategoryList: []
            }
        }
        case ACCOUNT_CATEGORY_LIST_ERROR_CLEAR: {
            return {
                ...localState,
                accountCategoryListErrorMessage: null,
            }
        }
        case ACCOUNT_CATEGORY_LIST_PENDING: {
            return {
                ...localState,
                isAccountCategoryListPending: true,
            }
        }

        default: {
            return localState;
        }
    }
};