import { getToken } from "../../../../helper/common";
import { clearForm } from "../../../form/actions";
import agent from "../../../services/agent";
import { showSnackbarStatus } from "../../../snackbar/actions";
import { CLEAR_APP_MASTER_DETAILS_ERROR, CLEAR_APP_MASTER_EXPORT_LIST_ERROR, CLEAR_APP_MASTER_LIST_ERROR, CLEAR_CREATE_APP_MASTER_ERROR, CLEAR_UPDATE_APP_MASTER_ERROR, CLOSE_CREATE_APP_MASTER_MODAL, CLOSE_EDIT_APP_MASTER_MODAL, CREATE_APP_MASTER_ERROR, CREATE_APP_MASTER_PENDING, CREATE_APP_MASTER_SUCCESS, OPEN_CREATE_APP_MASTER_MODAL, OPEN_EDIT_APP_MASTER_MODAL, SET_APP_DETAILS_ERROR, SET_APP_EXPORT_LIST_ERROR, SET_APP_LIST_ERROR, SET_APP_MASTER_DETAILS, SET_APP_MASTER_DETAILS_PENDING, SET_APP_MASTER_EXPORT_LIST, SET_APP_MASTER_EXPORT_PENDING, SET_APP_MASTER_LIST, SET_APP_MASTER_PENDING, UPDATE_APP_MASTER_ERROR, UPDATE_APP_MASTER_PENDING, UPDATE_APP_MASTER_SUCCESS } from "./constants";

export const openCreateAppMasterModalAction = () => ({
    type: OPEN_CREATE_APP_MASTER_MODAL,
});
export const closeCreateAppMasterModalAction = () => ({
    type: CLOSE_CREATE_APP_MASTER_MODAL,
});
export const openEditAppMasterModal = () => ({
    type: OPEN_EDIT_APP_MASTER_MODAL,
});
export const closeEditAppMasterModal = () => ({
    type: CLOSE_EDIT_APP_MASTER_MODAL,
});

export const setAppMasterExportList = (appmasterExportList) => ({
    type: SET_APP_MASTER_EXPORT_LIST,
    payload: { appmasterExportList },
})

export const setAppMasterList = (appmasterList) => ({
    type: SET_APP_MASTER_LIST,
    payload: { appmasterList },
})
export const clearAppMasterListError = () => ({
    type: CLEAR_APP_MASTER_LIST_ERROR,
})
export const clearAppMasterExportListError = () => ({
    type: CLEAR_APP_MASTER_EXPORT_LIST_ERROR,
})
export const appMasterListError = (errorMessage) => ({
    type: SET_APP_LIST_ERROR,
    payload: { errorMessage },
})
export const appMasterExportListError = (errorMessage) => ({
    type: SET_APP_EXPORT_LIST_ERROR,
    payload: { errorMessage },
})
export const setAppMasterDetails = (appMasterDetails) => ({
    type: SET_APP_MASTER_DETAILS,
    payload: { appMasterDetails },
})
export const clearAppMasterDetailsError = () => ({
    type: CLEAR_APP_MASTER_DETAILS_ERROR,
})
export const appMasterDetailsError = (errorMessage) => ({
    type: SET_APP_DETAILS_ERROR,
    payload: { errorMessage },
})
export const createAppMasterPending = () => ({
    type: CREATE_APP_MASTER_PENDING,
});
export const clearCreateAppMasterError = () => ({
    type: CLEAR_CREATE_APP_MASTER_ERROR,
});
export const createAppMasterSuccess = () => ({
    type: CREATE_APP_MASTER_SUCCESS,
});
export const createAppMasterError = (errorMessage) => ({
    type: CREATE_APP_MASTER_ERROR,
    payload: { errorMessage },
});
export const clearUpdateAppMasterError = () => ({
    type: CLEAR_UPDATE_APP_MASTER_ERROR,
});
export const updateAppMasterPending = () => ({
    type: UPDATE_APP_MASTER_PENDING,
});
export const updateAppMasterSuccess = () => ({
    type: UPDATE_APP_MASTER_SUCCESS,
});
export const updateAppMasterError = (errorMessage) => ({
    type: UPDATE_APP_MASTER_ERROR,
    payload: { errorMessage },
});
export const setAppMasterPending = () => ({
    type: SET_APP_MASTER_PENDING,
})
export const setAppMasterExportPending = () => ({
    type: SET_APP_MASTER_EXPORT_PENDING,
})
export const setAppMasterDetailsPending = () => ({
    type: SET_APP_MASTER_DETAILS_PENDING,
})

export const getExportAppMasterList = (isSuperAdmin, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, searchorganizationCount, searchAssignedTimeStamp) => (dispatch) => {
    dispatch(setAppMasterExportPending());
   return getToken(dispatch).then((accessToken) => {
       return agent.AppMaster.getAppMasterExportlist(accessToken, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, isSuperAdmin, searchorganizationCount, searchAssignedTimeStamp)
            .then((appMasterList) => {
                // dispatch(setAppMasterExportList(appMasterList));
                dispatch(clearAppMasterExportListError());
                return Promise.resolve(appMasterList);
            })
            .catch((err) => {
                console.log('getExportAppMasterList server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(appMasterExportListError(error))
            });
    });
};
export const getAppMasterListAction = (isSuperAdmin, filteredData, page, PageSize, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, searchorganizationCount, searchAssignedTimeStamp) => (dispatch) => {
    dispatch(setAppMasterPending());
    getToken(dispatch).then((accessToken) => {
        agent.AppMaster.getAppMasterlist(accessToken, filteredData,page, PageSize, searchAppName, searchId, searchLatestVersion, searchCreatedDateTime, searchIsActive, isSuperAdmin, searchorganizationCount, searchAssignedTimeStamp)
            .then((appMasterList) => {
                dispatch(setAppMasterList(appMasterList));
                dispatch(clearAppMasterListError());
            })
            .catch((err) => {
                console.log('getAppMasterListAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.body && err.response.body.error.message;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(appMasterListError(error));
            });
    });
};

export const getAppMasterDetailsAction = (id) => (dispatch) => {
    dispatch(setAppMasterDetailsPending());
    getToken(dispatch).then((accessToken) => {
        agent.AppMaster.getAppMasterDetails(accessToken, id).then(
            (AppMasterDetails) => {
                dispatch(setAppMasterDetails(AppMasterDetails));
                dispatch(clearAppMasterDetailsError());
            })
            .catch((err) => {
                console.log('getAppMasterDetailsAction server error', err);
                let error;
                if (err.status) {
                    error = err.response && err.response.text;
                }
                else {
                    error = "Network Error"
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                dispatch(appMasterDetailsError(error))
            });
    });
}

export const createAppMaster = (formFields, data, filteredData) => (dispatch) => {
    let dataToSend = {}
    if (!formFields) {
        dispatch(createAppMasterError('Please fill required details.'));
        return;
    }
    else {
        dataToSend = {
            ...formFields,
        }
    }
    dispatch(createAppMasterPending());
    dispatch(clearCreateAppMasterError());
    getToken(dispatch).then((accessToken) => {
        agent.AppMaster.createAppMaster(accessToken, dataToSend)
            .then(() => {
                dispatch(createAppMasterSuccess());
                dispatch(clearForm());
                dispatch(closeCreateAppMasterModalAction());
                dispatch(getAppMasterListAction(data, filteredData));
                // dispatch(getExportAppMasterList(data));
                dispatch(showSnackbarStatus('App created successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log("err:", err.response.text);
                    // const { message } = err.response.text;
                    dispatch(createAppMasterError(err.response && err.response.text));
                    return;
                } else {
                    dispatch(createAppMasterError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Create App Master server error', err);
                dispatch(clearForm());
                dispatch(closeCreateAppMasterModalAction());
            });
    });
};

export const EditAppMaster = (formFields) => (dispatch) => {
    const dataToSend = {
        ...formFields,
        Id: formFields.id
    }
    dispatch(clearUpdateAppMasterError());
    dispatch(updateAppMasterPending());
    getToken(dispatch).then((accessToken) => {
        agent.AppMaster.editAppMaster(accessToken, dataToSend)
            .then(() => {
                dispatch(updateAppMasterSuccess());
                dispatch(closeEditAppMasterModal());
                dispatch(getAppMasterDetailsAction(formFields.id));
                dispatch(showSnackbarStatus('App details updated successfully'));
            })
            .catch((err) => {
                if (err && err.response) {
                    // const { message } = err.response.body;
                    console.log("err:", err && err.response && err.response.text)
                    dispatch(updateAppMasterError(err && err.response && err.response.text));
                    return;
                } else {
                    dispatch(updateAppMasterError('Network Error'));
                    dispatch(showSnackbarStatus('Something went wrong. Please try again later'));
                }
                console.log('Edit App server error', err);
                dispatch(updateAppMasterError(null));
                dispatch(closeEditAppMasterModal());
            });
    });
};