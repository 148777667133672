export const SET_MAIL = "SET_MAIL";
export const MAIL_ERROR_CLEAR = "MAIL_ERROR_CLEAR";
export const MAIL_ERROR = "MAIL_ERROR";
export const MAIL_PENDING = "MAIL_PENDING";

//-------------------------------------------------------------MAIL-UNSUBSCRIBE-----------------------------------------------------------------------
export const SUBMIT_UNSUBSCRIBE_PENDING = "SUBMIT_UNSUBSCRIBE_PENDING";
export const SUBMIT_UNSUBSCRIBE_ERROR_CLEAR = "SUBMIT_UNSUBSCRIBE_ERROR_CLEAR";
export const SUBMIT_UNSUBSCRIBE_ERROR = "SUBMIT_UNSUBSCRIBE_ERROR";
export const SUBMIT_UNSUBSCRIBE_SUCCESS = "SUBMIT_UNSUBSCRIBE_SUCCESS";
