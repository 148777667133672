import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Badge, Button, Card, CardBody, CardFooter, CardTitle, Input, Table } from 'reactstrap'
import VWModel from '../../../../../components/Modal/VWModal'
import { connect } from 'react-redux'
import { clearForm } from '../../../../../store/form/actions'
import CreateGroup from './CreateGroup'
import { clearGroupExportListError, closeCreateGroupModalAction, getGroupExportList, getGroupListAction, openCreateGroupModalAction } from '../../../../../store/Modules/Admin/Group/actions'
import { getPager } from '../../../../../helper/common'
import VWPagination from '../../../../../components/vwPagination/vwPagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import NoRecord from '../../../../../components/NoRecords/noRecords'
import VWMessage from '../../../../../components/vwMessage/vwMessage'
import VWSkeleton from '../../../../../components/vwSkeleton/vwSkeleton'
import VWSpinner from '../../../../../components/vwSpinner/vwSpinner'
import { clearSetPath } from '../../../../../store/actions'
import '../../../../Modules/viewList.css'
import { excelPrint, excelSheetExport } from '../../../../../helper/export'
import { ORG_GROUP_EXPORT_HEADERS } from '../../../../../store/Modules/Admin/Group/constants'
import { ElementToolTip } from '../../../Projects/vwToolTip'

const Groups = (props) => {
    const [PageSize] = useState(20);
    const [Pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState();
    const { errorMessage, GroupLists, isGroupListPending, isGroupCreateModalOpen, isGroupExportListPending, groupExportListError } = props || {};

    const { userAppDetails } = props.profile;
    const App = userAppDetails.filter((i) => {
        return (i.appName === "Admin") ? i.appId : ""
    })
    console.log("App:", App[0] && App[0].appId);

    const buildQueryGroupName = () => {
        const { GroupName } = filter || {};
        let searchGroupName = '';
        if (GroupName) {
            searchGroupName += `${GroupName}`;
        }
        return (searchGroupName)
    }
    const buildQueryGroupEmail = () => {
        const { GroupEmail } = filter || {};
        let searchGroupEmail = '';
        if (GroupEmail) {
            searchGroupEmail += `${GroupEmail}`;
        }
        return searchGroupEmail
    }
    const buildQueryGroupOwnerEmail = () => {
        const { GroupOwnerEmail } = filter || {};
        let searchGroupOwnerEmail = '';
        if (GroupOwnerEmail) {
            searchGroupOwnerEmail += `${GroupOwnerEmail}`;
        }
        return searchGroupOwnerEmail
    }

    const setPage = (page = 1) => {
        props.getGroupList('', filter, page, PageSize, buildQueryGroupName(), buildQueryGroupEmail(), buildQueryGroupOwnerEmail());
        setPager(prevPager => {
            const totalPages = Math.ceil(props.totalCount / PageSize);
            const visiblePages = 4;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
            }
        })
    }

    useEffect(() => {
        const currentPager = getPager(props.totalCount, 1, PageSize)
        setPager(currentPager)
    }, [props.totalCount])

    useEffect(() => {
        const currentPageNumber = Pager && Pager.currentPage;
        setPage(currentPageNumber);
        props.ClearPath();
        props.clearGroupExportListError();
    }, [])

    const debounceTime = window.__ENV && window.__ENV.debounceTime;
    useEffect(() => {
        if (filter) {
            const timeout = setTimeout(() => {
                handleSearchClick();
            }, debounceTime);
            setDebounceTimeout(timeout);
        }
    }, [filter])
    //------------------------------------------Filter-----------------------------------------------------------
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const searchInputValidation = (event) => {
        const { value, name } = event.target;
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
        setFilter({
            ...filter,
            [name]: value,
        })
    }

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }

    //-----------------------------------------Modal open & close-----------------------------------------------------

    const handleOpenCreateGroup = () => {
        props.openCreateGroupModal();
    };
    const handleClose = () => {
        props.clearFormAction()
        props.closeCreateGroupModal();
    };
    // ----------------------------------------SORTING-----------------------------------------------------------//
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };
    const sortedGroupList = [...props.GroupLists].sort((a, b) => {
        const aValue = a[sortOrder] || 'NA';
        const bValue = b[sortOrder] || 'NA';
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });
    const style = {
        table: {
            width: '100%',
            display: 'table',
            borderSpacing: 0,
            borderCollapse: 'separate',
        },
        th: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: 'sticky',
            backgroundColor: '#fff',
        },
    };

    const navigate = useNavigate();
    const OnDoubleClickAction = (Id) => {
        navigate(`/admin/groups/details/${Id}`);
    };


    //-------------------------------------------------Export---------------------------------------------------------------------------
    const handleExports = () => {
        props.getGroupExportList(buildQueryGroupName(), buildQueryGroupEmail(), buildQueryGroupOwnerEmail())
            .then((response) => {
                if (response) {
                    const exporGroupExportList = [];
                    const headers = ORG_GROUP_EXPORT_HEADERS;
                    const headerSizes = [30, 50, 50, 20, 20];
                    const headerExcel = [
                        { headerName: "GROUP NAME", key: "groupName", headerWidhth: 30 },
                        { headerName: "GROUP EMAIL", key: "groupEmail", headerWidhth: 50 },
                        { headerName: "GROUP OWNER EMAIL", key: "groupOwnerEmail", headerWidhth: 50 },
                    ]
                    response && response.forEach((element) => {
                        exporGroupExportList.push({
                            groupName: element.groupName || "N/A",
                            groupEmail: element.groupEmail || "N/A",
                            groupOwnerEmail: element.groupOwnerEmail || "N/A",
                        });
                    });
                    excelSheetExport(headerExcel, exporGroupExportList, "Group-List");
                    // excelPrint(exporGroupExportList, "Group-List", headers, headerSizes);
                }
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <>
                    <Card >
                        <CardTitle
                            tag="h4"
                            className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                        >
                            <strong className='d-flex align-items-center'> GROUPS -&nbsp;<Badge color="danger" id={'GroupCount'} style={{ cursor: 'pointer' }}>{props && props.totalCount}</Badge>
                                <ElementToolTip id={'GroupCount'} name={'Groups Count'} /></strong>

                            <div className="content-heading">
                                <div className="ml-auto">
                                    <Link
                                        type="button"
                                        className="btn btn-info"
                                        onClick={handleOpenCreateGroup}
                                    >
                                        CREATE
                                    </Link>&nbsp;
                                    <Button
                                        className="me-4"
                                        color="success"
                                        onClick={handleExports}
                                        disabled={GroupLists && GroupLists.length <= 0 || isGroupExportListPending}
                                    >
                                        {isGroupExportListPending ? 'EXPORTING...' : 'EXPORT'}
                                    </Button>
                                </div>
                            </div>
                        </CardTitle>
                    </Card>
                    <Card>
                        <CardBody className="p-4">
                            {(errorMessage || groupExportListError) ? <div className='w-100'>
                                {(errorMessage || groupExportListError) && (
                                    <VWMessage type="danger" defaultMessage={errorMessage || groupExportListError} baseClassName="alert" ></VWMessage>
                                )}
                            </div> : ""}
                            <div style={{ height: "60vh", overflowX: "auto" }}>
                                <Table className="table table-striped table-hover table-nowrap w-100 " size="sm" style={style.table}>
                                    <thead className='overflow-hidden sticky-top' style={{ backgroundColor: "white", overflow: "hidden", zIndex: "10" }}>
                                        <tr>
                                            <th className='hand-cursor'
                                                onClick={() => handleSort('GroupName')}
                                            >
                                                Group Name &nbsp;
                                                {sortOrder === 'GroupName' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}
                                            </th>
                                            <th className='hand-cursor'
                                                onClick={() => handleSort('GroupEmail')}
                                            >Email &nbsp;
                                                {sortOrder === 'GroupEmail' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />)}
                                            </th>
                                            <th className='hand-cursor'
                                                onClick={() => handleSort('GroupOwnerEmail')}
                                            >
                                                Owner Email &nbsp;
                                                {sortOrder === 'GroupOwnerEmail' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}
                                            </th>
                                            <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}>
                                                View
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <Input
                                                    type='text'
                                                    placeholder='Search'
                                                    name='GroupName'
                                                    onChange={searchInputValidation}
                                                    value={filter && filter.GroupName}
                                                    onKeyPress={handleKeyPress}
                                                /></th>
                                            <th >
                                                <Input
                                                    type='text'
                                                    placeholder='Search'
                                                    name='GroupEmail'
                                                    onChange={searchInputValidation}
                                                    value={filter && filter.GroupEmail}
                                                    onKeyPress={handleKeyPress}
                                                /></th>
                                            <th >
                                                <Input
                                                    type='text'
                                                    placeholder='Search'
                                                    name='GroupOwnerEmail'
                                                    onChange={searchInputValidation}
                                                    value={filter && filter.GroupOwnerEmail}
                                                    onKeyPress={handleKeyPress}
                                                /></th>
                                            <th className="sticky-column" style={{ backgroundColor: "white", overflow: "hidden", zIndex: "20" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isGroupListPending && (GroupLists && GroupLists.length > 0) ?
                                            sortedGroupList && sortedGroupList.map((data) => (
                                                <tr onDoubleClick={() => OnDoubleClickAction(data.Id)}>
                                                    <td className='ps-3'>{data.GroupName || "N/A"}</td>
                                                    <td className='ps-3'>{data.GroupEmail || "N/A"}</td>
                                                    <td className='ps-3'>{data.GroupOwnerEmail || 'N/A'}</td>
                                                    <td className="sticky-column" style={{ backgroundColor: "white" }}> <Link
                                                        type="button"
                                                        className="btn btn-sm  btn-light"
                                                        to={`/admin/groups/details/${data.Id}`}
                                                    >
                                                        <FontAwesomeIcon icon={faSearch} style={{ color: "black", }} />
                                                    </Link></td>
                                                </tr>

                                            ))
                                            : isGroupListPending ?
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <VWSpinner />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="sticky-spinner-row">
                                                    <div className="position-relative">
                                                        <div className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                position: 'fixed',
                                                                top: '50%',
                                                                left: '60%',
                                                                transform: 'translate(-50%, -50%)',
                                                                zIndex: 1,
                                                                width: '100vw',
                                                                height: '100vh',
                                                                pointerEvents: 'none',
                                                            }}>
                                                            <NoRecord />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter>
                            {GroupLists && GroupLists.length > 0 ?
                                <VWPagination pager={Pager} setPage={setPage} totalRecords={props.totalCount} pageSize={PageSize} /> : null
                            }
                        </CardFooter>
                    </Card>
                    <VWModel
                        header="CREATE GROUP"
                        isOpen={isGroupCreateModalOpen}
                        handleClose={handleClose}
                    >
                        <CreateGroup
                            filterData={filter}
                            handleClose={handleClose}
                            appId={App[0] && App[0].appId}
                        />
                    </VWModel>
                </>
            </div>
        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    isGroupCreateModalOpen: state.AdminGroup.isCreateGroupModalOpen,
    GroupLists: state.AdminGroup.GroupList,
    totalCount: state.AdminGroup.totalRecords,
    errorMessage: state.AdminGroup.groupListError,
    isGroupListPending: state.AdminGroup.isGroupListPending,
    GroupExportList: state.AdminGroup.GroupExportList,
    groupExportListError: state.AdminGroup.groupExportListError,
    isGroupExportListPending: state.AdminGroup.isGroupExportListPending,
    profile: state.login.currentProfile,
});
const mapDispatchToProps = (dispatch) => ({
    ClearPath: () => dispatch(clearSetPath()),
    closeCreateGroupModal: () => dispatch(closeCreateGroupModalAction()),
    openCreateGroupModal: () => dispatch(openCreateGroupModalAction()),
    clearFormAction: () => dispatch(clearForm()),
    clearGroupExportListError: () => dispatch(clearGroupExportListError()),
    getGroupList: (appId, filter, page, PageSize, searchGroupName, searchGroupEmail, searchGroupOwnerEmail) => dispatch(getGroupListAction(appId, filter, page, PageSize, searchGroupName, searchGroupEmail, searchGroupOwnerEmail)),
    getGroupExportList: (searchGroupName, searchGroupEmail, searchGroupOwnerEmail) => dispatch(getGroupExportList(searchGroupName, searchGroupEmail, searchGroupOwnerEmail)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Groups)